<script>
    import { Router, Link, Route } from "svelte-routing";
    import ListRoute from "./list.svelte";
    import View from "./view.svelte";
    import StudentDetailedReport from "./studentDetailedResult.svelte";
</script>

<Router>
    <Route path="">
        <ListRoute></ListRoute>
    </Route>
    <Route path="view/:id/*" let:params>
        <View testId="{params.id}"></View>
    </Route>
    <Route path="view/studentdetailedreport/:studentId/:id" let:params>
        <StudentDetailedReport testId="{params.id}" studentId="{params.studentId}"></StudentDetailedReport>
    </Route>
</Router>
