<script>
  import { setContext, onMount, tick } from "svelte";
  import { links, link, navigate } from "svelte-routing";
  import { activeMenu } from "../../stores";
  import FeedbackComments from "../../comments/index.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  export let id;
  let isLoading;
  let errorObj = {};
  let studentName, feedbackId;
  let elem_dropdownType, elem_dropdownSeverity, elem_dropdownStatus,elemStatusSelect;
  let selectizeInstanceStatus
  let selectedStatus
  // async function api_handleApiCalls(action, data) {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   var payload = JSON.stringify({
  //     accessToken: localStorage.getItem("accessToken"),
  //     action,
  //     data,
  //   });
  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: payload,
  //   };
  //   try {
  //     var resObj = await fetch(API_URL, requestOptions);
  //     var response = await resObj.json();
  //     return response;
  //   } catch (error) {
  //     console.error("error", error);
  //     return { error: error };
  //   }
  // }
  async function fetchStudentFeedback() {
    let data = { id };
    let result = await api_handleApiCalls("feedback.fetchStudentFeedback", data);
    console.log(result);
    if (result.status) {
      studentName = result.data.studentName;
      feedbackId = result.data.feedbackId;
    if(result.data.status == "completed")   selectizeInstanceStatus.disable()

      setValues({
        severity: result.data.severity,
        type: result.data.type,
        status: result.data.status,
        query: result.data.query,
      });
     
       _$(elemStatusSelect).prop("disabled", result.data.status == "completed");
    }
     else if (result.errMsg) {
      pError("", result.errMsg);
    } else if (result.error) {
      pError("", result.error);
    } else {
      pError("", "Something went wrong");
    }
  }
  async function statusChanged(status) {
  
    let data = { status, id };
    // api_handleApiCalls call
    let result = await api_handleApiCalls("feedback.updateStatus", data);
    console.log("result",result)
    if (result.status) {
      pSuccess("", "Feedback status updated successfully");
      await fetchStudentFeedback();
    } else if (result.errMsg) {
      pError("", result.errMsg);
    } else if (result.error) {
      pError("", result.error);
    } else {
      pError("", "Something went wrong");
    }
  }
  function initSelect2(element, onSelect) {
    // console.log({ data });
    if (_$(element).hasClass("select2-hidden-accessible")) {
      // Select2 had been initialized, destroy the old instance
      _$(element).select2("destroy");
    }
    _$(element).select2();
    if (onSelect) {
      //   _$(element).on("select2:select", onSelect);
      _$(element).on("select2:close", onSelect);
    }
  }
  async function initCkeditor(editorInstance) {
    try {
      CKEDITOR.replace(editorInstance, {
        readyOnly: true,
        placeholder: "Enter your feedback",
        on: {
          loaded: reload,
        },
      });
      await tick();
    } catch (error) {
      console.error(error);
    }
  }
  async function setValues(values) {
    selectedStatus = values.status

  selectizeInstanceStatus.setValue(selectedStatus, true)
    _$(elem_dropdownSeverity).val(values.severity).trigger("change");
    _$(elem_dropdownType).val(values.type).trigger("change");
    CKEDITOR.instances["feedback_editor"].setData(values.query);
    await tick();
  }
  function clearFields() {
    setValues({
      severity: 2,
      type: "general",
      status: "new",
      query: "",
    });
  }
  async function reload() {
    if (id && !isLoading) {
      isLoading = true;
      await tick();
      await fetchStudentFeedback();
      // fetchComments()
      isLoading = false;
      await tick();
    }
  }
  onMount(async function () {
    activeMenu.set("studentFeedback");
    initSelect2(elem_dropdownSeverity);
    initSelect2(elem_dropdownType);
    initCkeditor("feedback_editor");
    // clearFields();
    if (id) {
      _$(elem_dropdownSeverity).prop("disabled", true);
      _$(elem_dropdownType).prop("disabled", true);
    } else {
      _$(elem_dropdownSeverity).prop("disabled", false);
      _$(elem_dropdownType).prop("disabled", false);
    }
   bindEvents(selectedStatus)
  });
  
  
  function bindEvents(){
  
    
    var statusList = [
    {
        text: 'Open',
        value: "open"
    },
    {
        text: 'Being Discussed',
        value: "discuss"
    },
    {
        text: 'Completed',
        value: "completed"
    }
];
var selectizeStatus =_$(elemStatusSelect).selectize({
    "placeholder": 'Select Status...',
    "options":statusList,
    onChange: function(value) {
       statusChanged(value);
    
     
    }
    
});
selectizeInstanceStatus = selectizeStatus[0].selectize

  }
  
</script>

<div class="content-body animated scale">
  <!-- breadcrumbs -->
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20
      mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active">
            <a href="/dashboard/feedback">Feedback</a>
          </li>
          <li class="breadcrumb-item">View</li>
          <li class="breadcrumb-item active" aria-current="page">
            {studentName + "'s feedback (#" + feedbackId + ')' || ''}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1 text-capitalize">{studentName + "'s feedback #" + feedbackId || ''}</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
        View Feedback, and make/see comments on it
      </p>
    </div>
    <div  class="">
    <div class="form-group d-inline-block" >Status</div>
      <div class="form-group d-inline-block">
          <select bind:this={elemStatusSelect} class="form-control " style="width:200px">
          </select>
         
      </div>
  </div>
    <!-- <div class="d-inline-block">
      <div class="form-group d-inline-block">Status</div>
      <div class="form-group d-inline-block">
        <select class="form-control select2" bind:this={elem_dropdownStatus}>
          <option value="open">Open</option>
          <option value="discuss">Being Discussed</option>
          <option value="completed">Completed</option>
        </select>
      </div>
    </div> -->
  </div>
  <hr class="full-width" />
  <div class="mg-b-20 ">
    <div class="row row-sm">
      <div class="col-md-7 col-sm-5">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Feedback Details
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Severity</label>
                <select
                  class="form-control select2"
                  bind:this={elem_dropdownSeverity}>
                  <option value="1">Very High</option>
                  <option value="2">High</option>
                  <option value="3" selected>Medium</option>
                  <option value="4">Low</option>
                  <option value="5">Very Low</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Type</label>
                <select
                  class="form-control select2"
                  bind:this={elem_dropdownType}>
                  <option value="general">General</option>
                  <option value="account">Account</option>
                  <option value="exam">Exam</option>
                  <option value="attendance">Attendance</option>
                  <option value="assignment">Assignment</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Query
                  {#if id == 'new'}
                    <span class="required-field">*</span>
                  {/if}</label>
                <textarea
                  type="text"
                  disabled={id}
                  name="feedback_editor"
                  class="form-control feedback"
                  class:parsley-error={errorObj.query} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-5 col-ms-12">
        <div class="col-sm-12">
          <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
            Feedback Comments
          </h6>
          <hr style="margin-top: 5px;" />
        </div>
        <div class="col-sm-12 ht-250">
          <div class="pd-r-10 ht-100p">
            <!-- comments here -->
            <FeedbackComments {id} />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
