<script>
    import { createEventDispatcher, onMount, beforeUpdate } from 'svelte';
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import BatchList from "../../students/addupdate/batchList.svelte";

    const dispatch = createEventDispatcher();

    export let studentId;

    onMount(async () => {
        feather.replace();
        dispatch('setActiveTab', {
            tabName: 'Batches'
        });
    })

    function refreshData() {
        renderBatchList();
    }


</script>

<BatchList isComponent={true} _id={studentId}/>
