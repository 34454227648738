<script>
    import { Router, Link, links, link, Route, navigate } from "svelte-routing";
    import { pSuccess, pError, pConfirm} from "../utils/notify.svelte";

    import { onMount, createEventDispatcher, tick  } from "svelte";
    import CatSelect from "./categorySelect.svelte";
    import { activeMenu ,_selectedQuestionsIds ,updateSelectedQuestion} from "../stores";
    import ButtonLoader from "../utils/button_loader.svelte"
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import MODULES from "../utils/modules.js"
    export let parentId;
    export let selectedQuestionIds
    let showModal = false;
    let questionsDataTable;
    let demoDataTable;
    let modalCmp;
    let filterModalThis;
    let qType;
    let language, tags, pmarks, nmarks, questionType;
    let breadCrumb = [];

    let btnDisable = false
    let isLoading = false
    const dispatch = createEventDispatcher();
    
    function printBtnClick() {
		dispatch('clickPrint', {questionsIds: Array.from($_selectedQuestionsIds)});
	}

    async function api_addQuestion(data) {

        let action = "questions.add";
        var response = await api_handleApiCalls(action, data);
        return response

    }

    async function api_getPathTillRootByNodeId(data) {

        let action = "folder.getPathTillRootByNodeId";
        var response = await api_handleApiCalls(action, data);
        return response

    }

    function parseSorts(sortOrder) {
        // parses datatable order to mongodb sort object
        let sort = {};
        // sorting orders
        let order;
        if (sortOrder.dir === "desc") order = 1;
        else order = -1;
        if (sortOrder.column === 1) {
            sort.qId = order;
        } else if (sortOrder.column === 2) {
            sort._id = order;
        } else if (sortOrder.column === 3) {
            sort._id = order;
        } else if (sortOrder.column === 4) {
            sort._id = order;
        } else {
            sort._id = order;
        }
        return sort;
    }

    let debounce = (f, w) => { let t; return function fn(...args) { const later = () => { t = null; f(...args); }; clearTimeout(t); t = setTimeout(later, w); }; };

    /* Question Selection START */
  const qSelectCheckboxClickHandler = (all) => {
            _$(".select-question")
            .toArray()
            .map((element) => {
                let elem_checkbox = _$(element);
                let questionId = elem_checkbox.attr("qId");
                if (all) elem_checkbox.prop("checked", true);
                else elem_checkbox.prop("checked", false);

            });
        }
        const checkIfAllSelected = (dataLength) => {
            let selectedArr = _$(".select-question")
            .toArray()
            .filter((element) => {
                let elem_checkbox = _$(element);
                let qId = elem_checkbox.attr("qId");
                return $_selectedQuestionsIds.has(qId)
            });

            if (selectedArr.length == dataLength && dataLength != 0) _$('#select_all_question').prop("checked", true);
            else _$('#select_all_question').prop("checked", false);
        }
        const selectAllUnselect = async (checked, selectedArr) => {
            let _setTemp = $_selectedQuestionsIds
            if (checked) {
                selectedArr.forEach(obj => _setTemp.add(obj._id))
            } else {
                selectedArr.forEach(obj => _setTemp.delete(obj._id))
            }
            updateSelectedQuestion(_setTemp)
            await tick()
            qSelectCheckboxClickHandler(checked)
        }
    /* Question Selection END */

    function bindDatatable() {
        let search = ''
        questionsDataTable = _$(demoDataTable).DataTable({
            responsive: false,
            "columnDefs": [
                { "orderable": false, "targets": [0] },
            ],
            language: {
                processing: "",
                searchPlaceholder: 'Search question',
                sSearch: '',
                lengthMenu: '_MENU_ Questions/page',
            },
            ajax: function (data, callback, settings) {
                data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
                var filterObj = { ...getFilterData() }
                filterObj.start = data.start
                filterObj.length = data.length
                filterObj.search = data.search.value
                filterObj.draw = data.draw
                filterObj.sort = data.sort;
                filterObj.flag = 'GET_QUESTIONS_BY_IDS'
                filterObj.selectedQuestionIds = Array.from($_selectedQuestionsIds)

                let callDebounce = debounce(async function () {

                    _$.ajax({
                        url: API_URL,
                        method: 'post',
                        data: {
                            action: 'questions.list',
                            accessToken: localStorage.getItem("accessToken"),
                            data: filterObj
                        },
                        success: function (resData) {
                            var result = []
                            if (resData.status) {
                                var paginationData = resData.data;
                                result = {
                                    recordsTotal: Number(paginationData.totalCount),
                                    recordsFiltered: Number(paginationData.filteredCount),
                                    data: paginationData.data
                                }
                                if (resData?.data?.data.length) {
                                    // emptyState = true
                                }
                                MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                                callback(result);
                            }
                            else {
                                console.log("Something went wrong");
                            }
                        },
                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                            // emptyState = true
                        }
                    })

                }, 250);

                MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                callDebounce()
            },
            columns: [
                {
                    data: "_id",
                    render: (data, type, full) => {
                        if ($_selectedQuestionsIds && $_selectedQuestionsIds.has(data)) {
                        return `
                                    <input type="checkbox" checked class="select-question itsSelected" qId="${data}">
                            `;
                        } else {
                        return `
                                    <input type="checkbox" class="select-question" qId="${data}">
                            `;
                        }

                    },
                },
                {
                    data: 'qId', render: function (data, type, full) {

                        var htmlStr
                        if (full.qId_synced) {

                            htmlStr = "#" + data + "(" + (full.qId_synced).toString() + ")";
                            return htmlStr
                        } else {
                            return "#" + data;
                        }
                    }
                },
                {
                    data: '_id', render: function (data, type, full) {
                        try {
                            var lang = full.languages.find(lng => lng.default == true).title
                            let question = full.data.question[lang]

                            let customSearchQuestion
                            if (data.search && search.length > 0) {
                                let searchRGX = new RegExp(search, 'gi')
                                customSearchQuestion = question.replace(searchRGX, `<span style="color: blue">${search}</span>`)
                            }

                            if (customSearchQuestion) return customSearchQuestion

                            return question;
                        } catch (error) {
                            return '-'
                        }
                    }
                },
                {
                    data: '_id', render: function (data, type, full) {

                        let qTypeFullName;
                        switch (full.qType) {
                            case 'multipleResponse':
                                qTypeFullName = "Multiple Response";
                                break;
                            case 'multipleChoice':
                                qTypeFullName = "Multiple Choice";
                                break;
                            case 'numerical':
                                qTypeFullName = "Numerical";
                                break;
                            case 'decimalType':
                                qTypeFullName = "Decimal";
                                break;
                            case 'fillInTheBlanks':
                                qTypeFullName = "Fill in the Blanks";
                                break;
                            case 'trueFalse':
                                qTypeFullName = "True Or False";
                                break;
                            default:
                                qTypeFullName = "-"
                        }

                        return qTypeFullName;

                    }
                },
                {
                    data: '_id', render: function (data, type, full) {
                        return full.additionalInfo.pmarks ? full.additionalInfo.pmarks : '-';

                    }
                },
                {
                    data: '_id', render: function (data, type, full) {
                        return full.questionOccuranceCount ? full.testOccuranceCount : 0;
                    }
                },
                // {
                //     data: '_id', render: function (data, a, b, c) {
                //         // return `
                //         // <div class="d-md-flex flex-row">
                //         //     <a href="#" class="rename mg-r-5 btn-edit-question" question-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-15 mg-r-5" data-feather="edit"></i></a>
                //         // </div>
                //         // `;
                //         var view = "-";
                //         var archive = "";
                //         view = `<a href="#" class="rename mg-r-5 btn-edit-question" qId="${data}" title="Edit">
                //                 <i class="wd-20 mg-r-5" data-feather="edit"></i>
                //             </a>`;
                //         // archive = `<a href="#" class="rename mg-r-5 btn-delete-question" data-id="${data}" title="Delete">
                //         //         <i class="wd-20 mg-r-5" data-feather="trash"></i>
                //         //     </a>`

                //         return `
                //             <div class="d-md-flex flex-row justify-content-center">
                //                 ${view}
                //             </div>
                //         `;
                //     }
                // },


            ],
            "drawCallback": function (settings) {
                feather.replace({ width: "1em", height: "1em" })

                let qList = Array.isArray(settings.aoData) ? settings.aoData.map(obj=>obj._aData) : []
                checkIfAllSelected(qList.length)

                _$('#select_all_question').unbind("click").click(function (e) {
                    e.stopPropagation();
                    selectAllUnselect(e.target.checked, qList)
                });

                _$('.btn-edit-question').unbind('click').click(function (e) {
                    e.preventDefault();
                    var questionId = _$(this).attr("qId");
                    let callbackUrl = "/dashboard/questions/printQuestions"
                    navigate(`/dashboard/questions/view/${questionId}?callbackUrl=${callbackUrl}`)
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                    return false;
                })

                _$(".select-question").unbind("click").click( async function (e) {
                    var qId = _$(this).attr("qId");
                    var checked = e.target.checked
                    if (checked) {
                        $_selectedQuestionsIds.add(qId)
                    } else {
                        $_selectedQuestionsIds.delete(qId)
                    }
                    _$('#select_all_question').prop("checked", false);
                    $_selectedQuestionsIds = $_selectedQuestionsIds
                    await tick()
                });

                
                MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
            },
            "processing": true,
            "serverSide": true,
        });
        _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
    }
    async function api_removeQuestion(questionId) {
        var resData = await api_handleApiCalls('questions.removeQuestion', {id: questionId})
        return resData
    }
    const proceedRemoveQuestion = (questionId) => {

        pConfirm("Warning!", "Are you sure you want to delete this question, this action will delete question permanently", async function () {
            let resData = await api_removeQuestion(questionId)
            if (resData.status) {
                pSuccess("Success", resData.data)
            } else {
                pError("Error", resData.errMsg)
            }
            redrawQuestionsTable()
        })

    }

   
    function showHideFilterModal(action) {
        action ? (_$(filterModalThis).modal('show')) : (_$(filterModalThis).modal('hide'))
    }

    function getFilterData() {
        return {
            language,
            tags,
            pmarks,
            nmarks,
            questionType
        }
    }

    function applyFilter() {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        redrawQuestionsTable();
    }

    function redrawQuestionsTable() {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        if (questionsDataTable) { questionsDataTable.draw() }
    }
    function clearFilter() {
        questionType = null;
        tags = null;
        pmarks = null;
        nmarks = null;
        language = null;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        redrawQuestionsTable();
    }

    onMount(async () => {
        feather.replace();
        activeMenu.set('questionBank')
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        bindDatatable();
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    })

    let handleClickProceed = async function () {
        if (!qType) return;
        _$(modalCmp).modal('hide');
        navigate(("/dashboard/questions/create/" + qType + "/" + parentId))
    }

    const clearAllSelectedQuestions = () => {
        $_selectedQuestionsIds.clear()
        $_selectedQuestionsIds = $_selectedQuestionsIds

        qSelectCheckboxClickHandler()
        checkIfAllSelected(0)

    }

</script>

<div class="content-body">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-25">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/questions/questionBank">Questions</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        List
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                Question List
            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">Your personal question bank</p>

        </div>
        <div class="d-block">
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i data-feather="mail"
                            class="wd-10 mg-r-5"></i> Email</button> -->
            <div class="d-sm-flex align-items-center">
                    <div class="mr-lg-4 d-sm-flex align-items-center">
                        <button id="btn-print-selected-q" href="#" disabled={$_selectedQuestionsIds.size == 0} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                            on:click={printBtnClick}>
                            <i data-feather="printer" class="wd-10 mg-r-5"></i> Print {$_selectedQuestionsIds.size} Questions
                        </button>
                        <a href="#" disabled={$_selectedQuestionsIds.size == 0} class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-outline-light text-dark"
                            on:click={clearAllSelectedQuestions}>
                            Clear Selection
                        </a>
                    </div>
            </div>
        </div>

    </div>
    <hr class="full-width">
    <!-- <div class="row">
        <div class="col">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                    <li class="breadcrumb-item"><a href="/dashboard/questions/questionBank/" use:link>Folders </a></li>
                    {#each breadCrumb as item (item._id)}
                    <li class="breadcrumb-item"><a href={'/dashboard/questions/questionBank/'+item._id}
                            use:link>{item.name}</a></li>
                    {/each}
                    <li class="breadcrumb-item active" aria-current="page">Data</li>
                </ol>
            </nav>
        </div>
    </div>
    <hr class="full-width" style="margin-top: 20px;"> -->

    <!-- <hr class="mg-y-20"> -->
    <div data-label="Example" class="df-example demo-table">
        <table bind:this={demoDataTable} class="table">
            <thead>
                <tr>
                    <th style="width: 10%;">
                        <input type="checkbox" id="select_all_question" name="selectAll" /> Select All
                      </th>
                    <th class="wd-10">ID</th>
                    <th class="wd-40p-f">Question</th>
                    <th class="wd-15">Type</th>
                    <th class="wd-15">Marks</th>
                    <th class="wd-15">Occurance Count</th>
                    <!-- <th class="wd-10">Actions </th> -->
                </tr>
            </thead>
            <tbody></tbody>

        </table>
    </div>
</div>


<div class="modal effect-scale" bind:this={modalCmp} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mx-wd-sm-650" role="document">
        <div class="modal-content bd-0 bg-transparent">
            <div class="modal-body pd-0">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="row no-gutters">

                    <div class="col-12 bg-white rounded-right">
                        <div class="ht-100p d-flex flex-column justify-content-center pd-20 pd-sm-30 pd-md-40">
                            <div class="d-flex">
                                <span class="tx-color-04"><i data-feather="server"
                                        class="wd-40 ht-40 stroke-wd-3 mg-b-20"></i></span>
                                <div class="media-body mg-sm-l-20">
                                    <h4 class="tx-18 tx-sm-20 mg-b-2">New question</h4>
                                    <p class="tx-13 tx-color-03 mg-b-0">Create a new question of the type you want</p>
                                </div>
                                <!-- <h3 class="tx-16 tx-sm-20 tx-md-24 mg-b-15 mg-md-b-20 mg-l-15">Create new question</h3> -->
                            </div>
                            <div class="tx-14 tx-md-16 tx-color-02">
                                <div class="form-group" style="    margin-bottom: 10px;">
                                    <label class="d-block m-0">What type of question would you like to create ?</label>
                                </div>
                            </div>

                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio1" value="multipleChoice" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio1">
                                        Multiple Choice
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio2" value="multipleResponse" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio2">
                                        Multiple Response
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio3" value="numerical" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio3">
                                        Number Type
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio4" value="trueFalse" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio4">
                                        True False
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio5" value="fillInTheBlanks" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio5">
                                        Fill in the Blanks
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio6" value="matchTheFollowing" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio6">
                                        Match the Following
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio7" value="matchMatrix" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio7">
                                        Match Matrix
                                    </label>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-secondary tx-12" data-dismiss="modal">
                                    <i data-feather="x" class="wd-10 mg-r-5"></i>
                                    CLOSE
                                </button>
                                <button href="" disabled={qType ? false : true} on:click={handleClickProceed}
                                    class="btn btn-primary btn-uppercase">
                                    <i data-feather="arrow-right" class="wd-10 mg-r-5"></i>
                                    <ButtonLoader isLoading={isLoading} btnText="Proceed" />
                                </button>
                            </div>
                        </div><!-- col -->
                    </div><!-- row -->
                </div><!-- modal-body -->
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div><!-- modal -->
</div>

<!-- Filter Modal -->
<div class="modal effect-scale" bind:this={filterModalThis} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <div class="form-group">
                    <label class="d-block flex-grow-1">Question Type:</label>
                    <div class="d-md-flex">
                        <div class="custom-control custom-radio mg-r-15">
                            <input type="radio" id="multipleChoice" bind:group={questionType}
                                class="custom-control-input" value='multipleChoice'>
                            <label class="custom-control-label" for="multipleChoice">Multiple Choice</label>
                        </div>

                        <div class="custom-control custom-radio mg-r-15">
                            <input type="radio" bind:group={questionType} id="multipleResponse"
                                class="custom-control-input" value='multipleResponse'>
                            <label class="custom-control-label" for="multipleResponse">Multiple Response</label>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="d-block">Tags:</label>
                    <input type="text" bind:value={tags} class="form-control">
                </div>

                <div class="form-group">
                    <label class="d-block">Language:</label>
                    <input type="text" bind:value={language} class="form-control follow-up-date" />
                </div>

                <div class="form-group">
                    <label class="d-block">Positive Marks:</label>
                    <input type="number" bind:value={pmarks} class="form-control follow-up-date" />
                </div>

                <div class="form-group">
                    <label class="d-block">Negative Marks:</label>
                    <input type="number" bind:value={nmarks} class="form-control follow-up-date" />
                </div>

                <div class="d-md-flex justify-content-end mg-t-25">
                    <button disabled={isLoading} on:click={clearFilter}
                        class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal">CLEAR
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button disabled={isLoading} on:click={applyFilter} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
                        data-dismiss="modal">
                        <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                        <i data-feather="filter" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Modal -->
