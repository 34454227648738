<script>
  // import { link } from "svelte-routing";
  // export let onActionBtnClick;
  // let hovering;

  // function enter() {
  //   hovering = true;
  // }

  // function leave() {
  //   hovering = false;
  // }
  // _$(function () {
  //   "use script";

  //   window.darkMode = function () {
  //     _$(".btn-white").addClass("btn-dark").removeClass("btn-white");
  //   };

  //   window.lightMode = function () {
  //     _$(".btn-dark").addClass("btn-white").removeClass("btn-dark");
  //   };

  //   var hasMode = Cookies.get("df-mode");
  //   if (hasMode === "dark") {
  //     darkMode();
  //   } else {
  //     lightMode();
  //   }
  // });
  export let mode = "empty";
</script>

<div class="wd-100p tx-center align-items-center justify-content-center bd">
  {#if mode == 'search'}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">Source not found.</p>
  {:else}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">
      Welcome! Add your first enquiry source.
    </p>
  {/if}
  <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
</div>

<!-- <div class="content content-fixed content-auth-alt bd bg-gray-100">
  <div class="container ht-100p tx-center">
    <div
      class="ht-100p d-flex flex-column align-items-center
        justify-content-center">
      <h1 class="tx-gray-500 tx-15 tx-sm-18 tx-lg-21 mg-xl-b-5">
        {#if mode == 'search'}
          Enquiry source not found
        {:else}Add your first enquiry source{/if}
      </h1>
      <img
        src="/images/noresult.png"
        class="wd-25p op-6 mg-t-10 mg-b-10"
        alt="empty" />
      <p class="tx-14 tx-sm-15 tx-lg-16 tx-gray-400 mg-b-20">
        {#if mode == 'search'}
          Try other keywords
        {:else}
          <button
            on:mouseenter={enter}
            on:mouseleave={leave}
            type="button"
            on:click={onActionBtnClick}
            class="btn {hovering ? 'bd-primary' : 'bd-gray-400 btn-white'} btn-sm pd-x-15 btn-uppercase mg-l-5 tx-gray-400"><i
              data-feather="plus"
              class="wd-10 mg-r-5" />
            Add new enquiry source</button>
        {/if}
      </p>
    </div>
  </div>
</div> -->
