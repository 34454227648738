<script>
    import { Router, Link, Route } from "svelte-routing";
    import ListResponses from "./list/index.svelte";
    import AddUpdate from "./addupdate/index.svelte";
</script>

<Router>

    
    <Route path="info/:id" let:params>
        <AddUpdate _id="{params.id}"></AddUpdate>
    </Route>
    <Route path="">
        <ListResponses></ListResponses>
    </Route>
</Router>



