<script>
    import { Router, Link, Route } from "svelte-routing";
    import Assignment from "./addUpdate/addUpdateAssignment.svelte";
    import StudentsProgressList from './studentsProgress/studentsProgressList.svelte'

    import List from "./list/listAssignment.svelte";
    import { onMount } from "svelte";
    import MODULES from "../utils/modules.js"
    import Forbidden from "../forbidden.svelte"

    function init() {
        // console.log("redirecting ");
        feather.replace();
    }
    onMount(init);

</script>

<Router>
    <Route path="">
        <List></List>
    </Route>
    <Route path="edit/:id" let:params>
        {#if MODULES.hasAccess(MODULES.VIEW_ASSIGNMENT)}
        <Assignment _id="{params.id}"></Assignment>

        {:else}
        <Forbidden />
        {/if}
    </Route>
    <Route path="/:assignmentId/assignmentprogress" let:params>
        {#if MODULES.hasAccess(MODULES.VIEW_ASSIGNMENT)}
        <StudentsProgressList assignmentId="{params.assignmentId}"></StudentsProgressList>

        {:else}
        <Forbidden />
        {/if}
    </Route>
</Router>
