<script>
    export let percentage
</script>


<div class="media mt-2">
    <i data-feather="pie-chart" class="wd-20 ht-20"></i>
    <div class="media-body mg-l-10">
        <div class="tx-12 mg-b-4">{percentage}% Completed</div>
        {#if percentage < 30}
            <div class="progress ht-3 mg-b-10">  
                <div class={`progress-bar bg-danger wd-${percentage}p`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        {:else if percentage < 70}
            <div class="progress ht-3 mg-b-10">  
                <div class={`progress-bar bg-warning wd-${percentage}p`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        {:else}
            <div class="progress ht-3 mg-b-10">  
                <div class={`progress-bar bg-success wd-${percentage}p`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        {/if}
    </div><!-- media-body -->
</div><!-- media -->

