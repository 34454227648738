import { userData } from "../stores";

let localUserData = {
    modules : []
};

const unsubscribe = userData.subscribe(value => {
    localUserData = value;
});


function isInArray(value, array) {
    return array.indexOf(value) > -1;
}

class Enum {
    constructor(enumObj) {
        const handler = {
            get(target, name) {
                if (typeof target[name] != 'undefined') {
                    return target[name]
                }
                throw new Error(`No such enumerator: ${name}`)
            },
            set() {
                throw new Error('Cannot add/update properties on an Enum instance after it is defined')
            }
        }
        return new Proxy(enumObj, handler)
    }
}

const modules = new Enum({
    hasAccess: function (moduleName){
        return  isInArray(modules[moduleName], localUserData.modules);
    },
    // LHS will be used in UI, RHS is coming from DB
    ADMIN_DASHBOARD: "ADMIN_DASHBOARD",
    ADD_BATCH: "ADD_BATCH",
    UPDATE_BATCH: "UPDATE_BATCH",
    ARCHIVE_BATCH: "ARCHIVE_BATCH",
    EDIT_BATCH: "EDIT_BATCH",
    VIEW_BATCH_DETAILS: "VIEW_BATCH_DETAILS",
    MANAGE_BATCH: "MANAGE_BATCH",
    ASSIGN_BATCH: "ASSIGN_BATCH",
    DEACTIVATE_ASSIGNED_BATCH: "DEACTIVATE_ASSIGNED_BATCH",
    REMOVE_ASSIGNED_BATCH: "REMOVE_ASSIGNED_BATCH",
    UPDATE_ASSIGNED_BATCH: "UPDATE_ASSIGNED_BATCH",
    MANAGE_JOIN_REQUEST: "MANAGE_JOIN_REQUEST",
    DELETE_BATCH: "DELETE_BATCH",
    UPDATE_ASSIGNED_BATCH_FEE: "UPDATE_ASSIGNED_BATCH_FEE",
    ACCEPT_REJECT_JOB_REQUEST: "ACCEPT_REJECT_JOB_REQUEST",
    MANAGE_STUDENT: "MANAGE_STUDENT",
    IMPORT_EXPORT_STUDENT: "IMPORT_EXPORT_STUDENT",
    DEACTIVATE_STUDENT: "DEACTIVATE_STUDENT",
    ARCHIVE_STUDENT: "ARCHIVE_STUDENT",
    CANCEL_STUDENT_ADMISSION: "CANCEL_STUDENT_ADMISSION",
    DELETE_STUDENT: "DELETE_STUDENT",
    ADD_STUDENT: "ADD_STUDENT",
    VIEW_STUDENT_DETAILS: "VIEW_STUDENT_DETAILS",
    VIEW_GROUPS_STUDENT: "VIEW_GROUPS_STUDENT",
    MANAGE_PAYMENT: "MANAGE_PAYMENT",
    ACCEPT_PAYMENT: "ACCEPT_PAYMENT",
    EXPORT_PAYMENT: "EXPORT_PAYMENT",
    SHOW_STAT_PAYMENT: "SHOW_STAT_PAYMENT",
    SEND_NOTIFICATIONS_PAYMENT: "SEND_NOTIFICATIONS_PAYMENT",
    UPDATE_UNPAID_RECEIPTS: "UPDATE_UNPAID_RECEIPTS",
    UPDATE_PAID_RECEIPTS: "UPDATE_PAID_RECEIPTS",
    PRINT_RECEIPTS: "PRINT_RECEIPTS",
    MANAGE_RECEIPT_SERIES: "MANAGE_RECEIPT_SERIES",
    ADD_RECEIPT_SERIES_PAYMENT: "ADD_RECEIPT_SERIES_PAYMENT",
    UPDATE_RECEIPT_SERIES_PAYMENT: "UPDATE_RECEIPT_SERIES_PAYMENT",
    VIEW_RECEIPT_SERIES_PAYMENT: "VIEW_RECEIPT_SERIES_PAYMENT",
    DELETE_RECEIPT_SERIES_PAYMENT: "DELETE_RECEIPT_SERIES_PAYMENT",
    DELETE_PAID_RECEIPT: "DELETE_PAID_RECEIPT",
    REFUND_PAYMENT: "REFUND_PAYMENT",
    MANAGE_ENQUIRY: "MANAGE_ENQUIRY",
    ADD_ENQUIRY: "ADD_ENQUIRY",
    ARCHIVE_ENQUIRY: "ARCHIVE_ENQUIRY",
    UPDATE_ENQUIRY: "UPDATE_ENQUIRY",
    DELETE_ENQUIRY: "DELETE_ENQUIRY",
    CONVERT_ENQUIRY: "CONVERT_ENQUIRY",
    SHOW_ARCHIEVED_ENQUIRY: "SHOW_ARCHIEVED_ENQUIRY",
    VIEW_ENQUIRY_DETAILS: "VIEW_ENQUIRY_DETAILS",
    MANAGE_SYSTEM_USERS: "MANAGE_SYSTEM_USERS",
    ADD_SYSTEM_USERS: "ADD_SYSTEM_USERS",
    UPDATE_SYSTEM_USERS: "UPDATE_SYSTEM_USERS",
    DELETE_SYSTEM_USERS: "DELETE_SYSTEM_USERS",
    ALLOW_TO_UPDATE_ACCESS_SYSTEM_USERS: "ALLOW_TO_UPDATE_ACCESS_SYSTEM_USERS",
    ALLOW_TO_UPDATE_BATCH_ACCESS_SYSTEM_USERS: "ALLOW_TO_UPDATE_BATCH_ACCESS_SYSTEM_USERS",
    VIEW_SYSTEM_USERS_DETAILS: "VIEW_SYSTEM_USERS_DETAILS",
    UPDATE_STUDENT: "UPDATE_STUDENT",
    MANAGE_CLASSES: "MANAGE_CLASSES",
    ADD_CLASSES: "ADD_CLASSES",
    DELETE_CLASSES: "DELETE_CLASSES",
    EDIT_CLASSES: "EDIT_CLASSES",
    DELETE_CLASS_TIMINGS: "DELETE_CLASS_TIMINGS",
    EDIT_CLASS_TIMINGS:"EDIT_CLASS_TIMINGS",
    MANAGE_ATTENDANCE: "MANAGE_ATTENDANCE",
    MARK_ATTENDANCE: "MARK_ATTENDANCE",
    MANAGE_CLASS_TIMINGS: "MANAGE_CLASS_TIMINGS",
    ADD_CLASS_TIMINGS: "ADD_CLASS_TIMINGS",
    MANAGE_HOLIDAYS: "MANAGE_HOLIDAYS",
    ADD_HOLIDAYS: "ADD_HOLIDAYS",
    EDIT_HOLIDAYS: "EDIT_HOLIDAYS",
    DELETE_HOLIDAYS: "DELETE_HOLIDAYS",
    VIEW_HOLIDAY_DETAILS: "VIEW_HOLIDAY_DETAILS",
    MANAGE_COURSE: "MANAGE_COURSE",
    ADD_COURSE: "ADD_COURSE",
    EDIT_COURSE: "EDIT_COURSE",
    DELETE_COURSE: "DELETE_COURSE",
    MANAGE_ASSIGNMENT: "MANAGE_ASSIGNMENT",
    ADD_ASSIGNMENT: "ADD_ASSIGNMENT",
    EDIT_ASSIGNMENT: "EDIT_ASSIGNMENT",
    DELETE_ASSIGNMENT: "DELETE_ASSIGNMENT",
    VIEW_ASSIGNMENT: "VIEW_ASSIGNMENT",
    MANAGE_FEEDBACK: "MANAGE_FEEDBACK",
    ADD_FEEDBACK: "ADD_FEEDBACK",
    EDIT_FEEDBACK: "EDIT_FEEDBACK",
    MANAGE_DISCUSSION_FORUM: "MANAGE_DISCUSSION_FORUM",
    ADD_DISCUSSION: "ADD_DISCUSSION",
    EDIT_DISCUSSION: "EDIT_DISCUSSION",
    VIEW_DISCUSSION_DETAILS: "VIEW_DISCUSSION_DETAILS",
    MANAGE_QUESTION_BANK: "MANAGE_QUESTION_BANK",
    ADD_FOLDER_QUESTION_BANK: "ADD_FOLDER_QUESTION_BANK",
    ADD_SUB_FOLDER_QUESTION_BANK: "ADD_SUB_FOLDER_QUESTION_BANK",
    ADD_QUESTIONS_QUESTION_BANK: "ADD_QUESTIONS_QUESTION_BANK",
    DELETE_QUESTION_BANK: "DELETE_QUESTION_BANK",
    RENAME_QUESTION_BANK: "RENAME_QUESTION_BANK",
    MANAGE_TEST: "MANAGE_TEST",
    ADD_TEST: "ADD_TEST",
    EDIT_TEST: "EDIT_TEST",
    DELETE_TEST: "DELETE_TEST",
    MANAGE_MY_TEST: "MANAGE_MY_TEST",
    MANAGE_STUDY_MATERIAL: "MANAGE_STUDY_MATERIAL",
    VIEW_BATCH_STUDY_MATERIAL: "VIEW_BATCH_STUDY_MATERIAL",
    ADD_FOLDER_STUDY_MATERIAL: "ADD_FOLDER_STUDY_MATERIAL",
    VIEW_STUDY_MATERIAL: "VIEW_STUDY_MATERIAL",
    DELETE_STUDY_MATERIAL: "DELETE_STUDY_MATERIAL",
    UPLOAD_STUDY_MATERIAL: "UPLOAD_STUDY_MATERIAL",
    RENAME_STUDY_MATERIAL: "RENAME_STUDY_MATERIAL",
    MANAGE_STUDY_REPORT: "MANAGE_STUDY_REPORT",
    MANAGE_TASKS: "MANAGE_TASKS",
    MANAGE_LEAVES: "MANAGE_LEAVES",
    ADD_LEAVES: "ADD_LEAVES",
    EDIT_LEAVES: "EDIT_LEAVES",
    DELETE_LEAVES: "DELETE_LEAVES",
    MANAGE_TELE_CALLER_LOGS: "MANAGE_TELE_CALLER_LOGS",
    ADD_NEW_CALL_TELE_CALLER_LOGS: "ADD_NEW_CALL_TELE_CALLER_LOGS",
    VIEW_TELE_CALLER_LOGS: "VIEW_TELE_CALLER_LOGS",
    ARCHIVE_TELE_CALLER_LOGS: "ARCHIVE_TELE_CALLER_LOGS",
    MANAGE_DAILY_STAFF_REPORTING: "MANAGE_DAILY_STAFF_REPORTING",
    ADD_DAILY_STAFF_REPORTING: "ADD_DAILY_STAFF_REPORTING",
    EDIT_DAILY_STAFF_REPORTING: "EDIT_DAILY_STAFF_REPORTING",
    DELETE_DAILY_STAFF_REPORTING: "DELETE_DAILY_STAFF_REPORTING",
    MANAGE_GENERAL_SETTINGS: "MANAGE_GENERAL_SETTINGS",
    MANAGE_SOCIAL_SETTINGS: "MANAGE_SOCIAL_SETTINGS",
    MANAGE_POINTS_SETTINGS: "MANAGE_POINTS_SETTINGS",
    MANAGE_APP_SLIDER: "MANAGE_APP_SLIDER",
    ADD_APP_SLIDER: "ADD_APP_SLIDER",
    UPDATE_APP_SLIDER: "UPDATE_APP_SLIDER",
    DELETE_APP_SLIDER: "DELETE_APP_SLIDER",
    VIEW_APP_SLIDER: "VIEW_APP_SLIDER",
    MANAGE_ANNOUNCEMENTS: "MANAGE_ANNOUNCEMENTS",
    ADD_ANNOUNCEMENTS: "ADD_ANNOUNCEMENTS",
    VIEW_ANNOUNCEMENTS: "VIEW_ANNOUNCEMENTS",
    ARCHIVE_ANNOUNCEMENTS: "ARCHIVE_ANNOUNCEMENTS",
    MANAGE_DOUBT_SESSION: "MANAGE_DOUBT_SESSION",
    ADD_DOUBT_SESSION: "ADD_DOUBT_SESSION",
    EDIT_DOUBT_SESSION: "EDIT_DOUBT_SESSION",
    DELETE_DOUBT_SESSION: "DELETE_DOUBT_SESSION",
    MANAGE_FAQ: "MANAGE_FAQ",
    ADD_FAQ: "ADD_FAQ",
    EDIT_FAQ: "EDIT_FAQ",
    DELETE_FAQ: "DELETE_FAQ",
    MANAGE_TEMPLATES: "MANAGE_TEMPLATES",
    MANAGE_SMS_TEMPLATES: "MANAGE_SMS_TEMPLATES",
    MANAGE_ANNOUNCEMENT_TEMPLATE: "MANAGE_ANNOUNCEMENT_TEMPLATE",
    MANAGE_TELE_CALLER_SCRIPTS: "MANAGE_TELE_CALLER_SCRIPTS",
    MANAGE_TEST_INST: "MANAGE_TEST_INST",
    MANAGE_ANALYTICAL_CATEGORY: "MANAGE_ANALYTICAL_CATEGORY",
    MANAGE_ENQUIRY_RESPONSES: "MANAGE_ENQUIRY_RESPONSES",
    MANAGE_ENQUIRY_SOURCE: "MANAGE_ENQUIRY_SOURCE",
    MANAGE_STUDY_MONITORING_SOURCE: "MANAGE_STUDY_MONITORING_SOURCE",
    MANAGE_MY_PROFILE: "MANAGE_MY_PROFILE",
    UPDATE_MY_PROFILE: "UPDATE_MY_PROFILE",
    ADMIN_DASHBOARD: "ADMIN_DASHBOARD",
    MANAGE_BRANCH: "MANAGE_BRANCH",
    ADD_BRANCH: "ADD_BRANCH",
    EDIT_BRANCH: "EDIT_BRANCH",
    DELETE_BRANCH: "DELETE_BRANCH",
    ADD_TASK: "ADD_TASK",
    EDIT_TASK: "EDIT_TASK",
    ARCHIVE_TASK: "ARCHIVE_TASK",
    VIEW_STUDY_REPORT: "VIEW_STUDY_REPORT",
    ADMIN_DASHBOARD_VIEW_LATEST_ENQ: "ADMIN_DASHBOARD_VIEW_LATEST_ENQ",
    ADMIN_DASHBOARD_VIEW_LATEST_STUDENTS: "ADMIN_DASHBOARD_VIEW_LATEST_STUDENTS",
    ADMIN_DASHBOARD_VIEW_LATEST_FEEDBACKS: "ADMIN_DASHBOARD_VIEW_LATEST_FEEDBACKS",
    ADMIN_DASHBOARD_VIEW_LATEST_BATCHJOININGREQ: "ADMIN_DASHBOARD_VIEW_LATEST_BATCHJOININGREQ",
    ADMIN_DASHBOARD_VIEW_LATEST_LIVELEC: "ADMIN_DASHBOARD_VIEW_LATEST_LIVELEC",
    ADMIN_DASHBOARD_VIEW_LATEST_TEST: "ADMIN_DASHBOARD_VIEW_LATEST_TEST",
    ADMIN_DASHBOARD_VIEW_LATEST_HOLIDAY: "ADMIN_DASHBOARD_VIEW_LATEST_HOLIDAY",
    ADMIN_DASHBOARD_VIEW_LATEST_STUDYREPORT: "ADMIN_DASHBOARD_VIEW_LATEST_STUDYREPORT",
    ADMIN_DASHBOARD_VIEW_LATEST_FOLLOWUP:"ADMIN_DASHBOARD_VIEW_LATEST_FOLLOWUP",
    ADMIN_DASHBOARD_VIEW_LATEST_FILEUPLOADS: "ADMIN_DASHBOARD_VIEW_LATEST_FILEUPLOADS",
    SCHEDULE: "SCHEDULE",
    MANAGE_QUESTIONS_UPLOAD: "MANAGE_QUESTIONS_UPLOAD",
    DOWNLOAD_QUESTIONS_TEMPLATE: "DOWNLOAD_QUESTIONS_TEMPLATE",
    SELECT_QUESTIONS_LOCATION: "SELECT_QUESTIONS_LOCATION",
    UPLOAD_QUESTIONS_TEMPLATE: "UPLOAD_QUESTIONS_TEMPLATE"
})


export default modules;