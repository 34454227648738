<script>
    import { Router, Link, links, Route, navigate } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import FolderExplorer from "../../folderCmp/questionBankExplorer.svelte";
    import { API_URL } from "../../../config.js"

    export let testId = "";
    export let onModalSelectQuestions = [];

    let showModal = false;
    let questionsDataTable;
    let isLoadingQuestions = true

    let selectedFolderWithName = [];
    let selectedFolderWithNameFormatted = "";
    let demoDataTable, filterQuestionType, filterQuestionLevel;
    let batchList = []
    let elem_lengthMenu
    let selectizeLengthInstance
    let qList = [];
    let filter = {

    }
    let start = 0, length = 10
    let selectedParentId = undefined;
    let filterClear = false

    export let selectedQuestions = [];
    let preselectedQuestion = selectedQuestions;

    $: selectedQuestions && onChangeSQ();

    let onChangeSQ = function () {
    }

    let selectedIdArr = new Set();
    let tempDisplayList = new Set();



    let onCBChange = function (ele, aQuestion) {
        let obj = {}
        if (aQuestion.qType == 'numerical') {
            obj = {
                ele: ele, q_id: aQuestion._id,
                questionText: aQuestion.data.question.English,
                answer: aQuestion.data.answer,
                additionalInfo: aQuestion.additionalInfo,
                qId: aQuestion.qId
            }
        }
        else {
            obj = {
                ele: ele, q_id: aQuestion._id,
                questionText: aQuestion.data.question.English,
                answer: aQuestion.data.answer,
                qOptions: aQuestion.data.option_a ? {
                    option_a: aQuestion.data.option_a.English,
                    option_b: aQuestion.data.option_b.English,
                    option_c: aQuestion.data.option_c.English,
                    option_d: aQuestion.data.option_d.English
                }

                    : {},
                additionalInfo: aQuestion.additionalInfo,
                qId: aQuestion.qId
            }
            if (aQuestion.data.option_e) obj.qOptions['option_e'] = aQuestion.data.option_e.English
            if (aQuestion.data.option_f) obj.qOptions['option_f'] = aQuestion.data.option_f.English
            if (aQuestion.data.option_g) obj.qOptions['option_g'] = aQuestion.data.option_g.English
            if (aQuestion.data.option_h) obj.qOptions['option_h'] = aQuestion.data.option_h.English

        }

        selectAllUnselectAll()

        if (obj.ele.target.checked) {
            selectedIdArr.add({ ...obj })
            tempDisplayList.add(obj.q_id);
        } else {
            // selectedIdArr.delete(q_id);
            selectedIdArr.forEach(x => x.q_id === obj.q_id ? selectedIdArr.delete(x) : x)
            tempDisplayList.delete(obj.q_id);
        }

        selectedIdArr = selectedIdArr;
        tempDisplayList = tempDisplayList;

    }

    let onChooseSelectedQuestion = function () {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        onModalSelectQuestions(Array.from(selectedIdArr));

    }

    async function api_getBatch(data) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var payload = JSON.stringify(
            {
                "action": "batch.listBatches",
                accessToken: localStorage.getItem("accessToken"),
                "data": data
            }
        );
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: payload,
        };
        try {
            var resObj = await fetch(API_URL, requestOptions);
            var response = await resObj.json();
            return response;

        } catch (error) {
            return { error: error };
        }
    }

    function bindDatatable() {
        questionsDataTable = _$(demoDataTable).DataTable({
            responsive: true,
            "dom": 'ip',
            "pageLength": length,

            // "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
            language: {
                processing: "",
                searchPlaceholder: 'Search # ID',
                sSearch: '',
                "paging": true,

                "lengthChange": true,
                lengthMenu: ' Questions/page',
            },
            ajax: function (data, callback, settings) {
                let excludeQuestionsIds = preselectedQuestion;
                _$.ajax({
                    url: API_URL,
                    method: 'post',
                    data: {
                        action: 'test.listQuestionsForTestId',
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                            filter,
                            testId,
                            excludeQuestionsIds,
                            selectedParentId
                        }
                    },
                    success: async function (resData) {
                        isLoadingQuestions = true
                        var result = []
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(paginationData.filteredCount),
                                data: paginationData.data
                            }
                            if (resData?.data?.data.length) {
                                // emptyState = true
                            }
                            qList = [];
                            await tick();
                            qList = paginationData.data;
                            qList = qList.sort(function (a, b) {
                                var keyA = a.qId_synced || a.qId,
                                    keyB = b.qId_synced || b.qId;
                                // Compare the 2 dates
                                if (keyA < keyB) return -1;
                                if (keyA > keyB) return 1;
                                return 0;
                            });
                            qList.map(question => {
                                if (!filterClear && selectedQuestions.includes(question._id)) {
                                    if (!(tempDisplayList && tempDisplayList.size) || !tempDisplayList.has(question._id)) {
                                        selectedIdArr = new Set([...selectedIdArr, {
                                            q_id: question._id,
                                            questionText: question.data.question.English,


                                        }])
                                        tempDisplayList = new Set([...tempDisplayList, question._id])
                                    }
                                }
                            })
                            // let counter = 0
                            // qList.map(question=> {
                            //     if (tempDisplayList.has(question._id)) {
                            //         counter++
                            //     }
                            // })
                            // if(counter == qList.length && qList.length) {
                            //     _$('#sq_selectAll').prop("checked", true);
                            // } else _$('#sq_selectAll').prop("checked", false);     
                            isLoadingQuestions = false
                            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                            callback(result);
                        }
                        else {
                            console.log("Something went wrong");
                        }
                        filterClear = false;
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        filterClear = false;
                        // emptyState = true
                    }
                })
            },
            columns: [
                {
                    data: 'qId', render: function (data, type, full) {
                        return "";
                    }
                }

            ],
            "drawCallback": function () {
                feather.replace()
                MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                selectAllUnselectAll()
                // _$('.btn-edit-question').unbind('click').click(function (e) {
                //     e.preventDefault();
                //     var questionId = _$(this).attr("question-id");
                //     navigate(("/dashboard/questions/view/" + questionId))
                //     return false;
                // })
            },
            "processing": true,
            "serverSide": true,
        });
        _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
    }

    $: selectedFolderWithName && onChangeSelectedFolder();

    let onChangeSelectedFolder = async function () {

        if (selectedFolderWithName.length == 0) return;
        selectedParentId = selectedFolderWithName[selectedFolderWithName.length - 1].id;
        filter.folder = selectedFolderWithName
        delete filter.folder.name
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        _$(demoDataTable).DataTable().ajax.reload(null, false);
        if (selectedFolderWithName.length == 0) {
            selectedFolderWithNameFormatted = `
                <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                    <li class="breadcrumb-item active" aria-current="page">All Questions</li>
                </ol>
                </nav>
            `
        } else {
            let navHtml = ` <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style3 mg-b-0">`;
            for (let i = 0; i < selectedFolderWithName.length; i++) {
                const aFolder = selectedFolderWithName[i];
                navHtml += `<li class="breadcrumb-item active" aria-current="page">${aFolder.name}</li>`
            }
            navHtml += `</ol>
                </nav>`;
            selectedFolderWithNameFormatted = navHtml;
        }
    }
    onMount(async () => {
        feather.replace();
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        bindDatatable()
        bindSelect2()
        selectedIdArr = new Set();
        tempDisplayList = new Set()

        var batchRes = await api_getBatch({ start: '0' });
        if (batchRes.status) {
            batchList = batchRes.data.data;
        }

        new PerfectScrollbar('.folder-list-sec', {
            suppressScrollX: true
        });
        // new PerfectScrollbar('.q-list-sec', {
        //     suppressScrollX: true
        // });

        var tags = _$('.chips-tag').selectize({
            delimiter: ',',
            plugins: ['remove_button'],
            persist: false,
            render: {
                option_create: function (data, escape) {
                    return '<div class="create" style="padding-left:15px"><i class="icon ion-md-search" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Search by tag : </i><strong>' + escape(data.input) + '</strong></div>';
                }
            },
            create: function (input) {
                return { value: input, text: input }
            },
            onChange: function (value) {
                filter.tags = value;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                _$(demoDataTable).DataTable().ajax.reload(null, false);
            },
        });

        // _$('.slectizedd').selectize({ maxItems: 1 });
        _$(filterQuestionType).selectize({
            maxItems: 1,
            onChange: function (value) {
                filter.qType = value;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                _$(demoDataTable).DataTable().ajax.reload(null, false);
            }
        });
        _$(filterQuestionLevel).selectize({
            maxItems: 1,
            onChange: function (value) {
                filter.level = value == "all" ? "" : value;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                _$(demoDataTable).DataTable().ajax.reload(null, false);
            }
        });
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);

    })

    let selectAll = (ele, qList) => {
        if (ele.target.checked) {

            for (let o = 0; o < qList.length; o++) {
                const aQ = qList[o];
                if (tempDisplayList.has(aQ._id)) continue

                var qOptions = null
                if (aQ.data.option_a) {
                    qOptions = {
                        option_a: aQ.data.option_a.English,
                        option_b: aQ.data.option_b.English,
                        option_c: aQ.data.option_c.English,
                        option_d: aQ.data.option_d.English,
                    }
                }
                if (aQ.data.option_e) {
                    qOptions['option_e'] = aQ.data.option_e.English
                }
                if (aQ.data.option_f) {
                    qOptions['option_f'] = aQ.data.option_f.English
                }
                if (aQ.data.option_g) {
                    qOptions['option_g'] = aQ.data.option_g.English
                }
                if (aQ.data.option_h) {
                    qOptions['option_h'] = aQ.data.option_h.English
                }
                selectedIdArr.add({ q_id: aQ._id, questionText: aQ.data.question.English, qOptions, answer: aQ.data.answer, additionalInfo: aQ.additionalInfo, qId: aQ.qId })
                tempDisplayList.add(aQ._id);

            }
        } else {

            for (let o = 0; o < qList.length; o++) {
                const aQ = qList[o];

                // selectedIdArr.delete(aQ._id);
                selectedIdArr.forEach(x => x.q_id === aQ._id ? selectedIdArr.delete(x) : x)

                tempDisplayList.delete(aQ._id);
            }
        }

        selectedIdArr = selectedIdArr;
        tempDisplayList = tempDisplayList;

    }

    async function selectAllUnselectAll() {
        await tick()
        let selectedArr = _$(".select-question").toArray().filter((element) => {
            return filterClear ? [...tempDisplayList].includes(element.id) : _$(element).is(':checked');
        });
        if (selectedArr.length == qList.length && qList.length != 0) _$("#sq_selectAll").prop("checked", true);
        else _$("#sq_selectAll").prop("checked", false);
    }

    async function bindSelect2() {

        selectizeLengthInstance = _$(elem_lengthMenu).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",

            persist: false,
            onChange: function (value) {
                length = value
                questionsDataTable.page.len(length).draw();

            }
        });

    }

</script>

<div data-label="Example" class="df-example demo-table" style="height: 100%;">
    <div class="row" style="height: 100%;">
        <div class="col-sm-2 folder-list-sec" style="height: 100%;">
            <FolderExplorer mode='compact' bind:selectedFolderWithName={selectedFolderWithName} shouldNotNavigate={true}
                viewOnlyMode={true} />
        </div>
        <div class="col-sm-10 " style="height: 100%; display: flex; flex-direction: column; overflow: hidden;">

            <div>
                <div style="margin-bottom: 20px;">
                    {@html selectedFolderWithNameFormatted}
                </div>
                <div class="row row-xs">
                    <div class="col-sm">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">#</span>
                            </div>
                            <input type="number" class="form-control" id="qId" placeholder="QuestionId"
                                value={filter.qId || '' } on:input={(event)=> {
                            filter.qId = parseInt(event.target.value) || ''
                            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                            _$(demoDataTable).DataTable().ajax.reload(null, false);
                            }}>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i data-feather="tag" style="width: 15px;height: 17px;"></i>
                                </span>
                            </div>
                            <input class="info form-control chips-tag has-prepend-icon" type="text"
                                data-role="tagsinput" name="chips-tag" placeholder="Search tags...">
                        </div>
                    </div>
                    <div class="col-sm">
                        <select id="qType" name="qTYpe" type="text" class="slectizedd info form-control"
                            bind:this={filterQuestionType}>
                            <option value="">Select Type</option>
                            <option value="all">All Types</option>
                            <option value="multipleChoice">Multiple Choice</option>
                            <option value="multipleResponse">Multiple Response</option>
                            <option value="numerical">Integer Type</option>
                            <option value="numberType">Number Type</option>
                            <!-- <option value="singleDigit">Single Digit</option>
                                <option value="decimalType">Decimal Type</option> -->
                            <option value="trueFalse">True False</option>
                            <option value="fillInTheBlanks">Fill in the Blanks</option>
                        </select>
                    </div>
                    <div class="col-sm">
                        <select id="qType" name="qTYpe" type="text" class="slectizedd info form-control"
                            bind:this={filterQuestionLevel}>
                            <option value="">Select Level</option>
                            <option value="all">All Levels</option>
                            <option value="easy">Easy</option>
                            <option value="mid">Medium</option>
                            <option value="hard">Hard</option>
                        </select>
                    </div>
                    <div class="col-sm">
                        <div class="form-row no-gutters">
                            <div class="form-group col-sm">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">+</span>
                                    </div>
                                    <input type="number" class="form-control pd-0 pd-l-5" id="pmarksEle"
                                        placeholder="+ve" value={filter.pmarks || '' } on:input={(event)=> {
                                    filter.pmarks = event.target.value
                                    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                                    _$(demoDataTable).DataTable().ajax.reload(null, false);
                                    }}>
                                </div>
                            </div>
                            <div class="form-group col-sm">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">-</span>
                                    </div>
                                    <input type="number" class="form-control" id="nmarksEle" placeholder="-ve"
                                        value={filter.nmarks || '' } on:input={(event)=> {
                                    filter.nmarks = event.target.value
                                    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                                    _$(demoDataTable).DataTable().ajax.reload(null, false);
                                    }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25" style="width: 100%;"
                            on:click={()=> {
                            filter = {}
                            _$('.chips-tag')[0].selectize.setValue("")
                            _$(filterQuestionLevel)[0].selectize.setValue("")
                            _$(filterQuestionType)[0].selectize.setValue("");
                            selectedParentId = undefined;
                            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                            filterClear = true
                            _$(demoDataTable).DataTable().ajax.reload(null, false);
                            selectedFolderWithNameFormatted = `
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                                    <li class="breadcrumb-item active" aria-current="page">All Questions</li>
                                </ol>
                            </nav>
                            `
                            }}><i data-feather="x-circle" class="wd-10 mg-r-5"></i>
                            Clear Filter
                        </button>
                    </div>
                </div>
            </div>
            <div>

            </div>
            <div class="d-flex">
                <div class="mg-r-10 d-flex" style=" margin-bottom: 10px;">
                    <select bind:this={elem_lengthMenu} class="form-control mg-r-10 mg-b-2 justify-content-start"
                        style="width:70px;    ">
                        <option value="10" selected>10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    <label class=" justify-content-end align-items-center">Questions/page</label>


                </div>

                <div class="custom-control custom-checkbox ">
                    <input type="checkbox" class="custom-control-input" id="sq_selectAll" name="selected_q"
                        on:change={(ele)=>selectAll(ele, qList)}
                    value="all-selected">
                    <label class="custom-control-label" for="sq_selectAll">Select All</label>
                </div>

            </div>

            <div class="q-list-sec" style="flex: 1;overflow: auto;">
                <input type="checkbox" class="custom-control-input" name="selected_q" bind:group={selectedQuestions}
                    value="0" hidden>
                {#if isLoadingQuestions}
                <div class="mg-20 pd-10">
                    <div class="placeholder-paragraph">
                        <div class="line" />
                    </div>
                </div>
                <div class="mg-20 pd-10">
                    <div class="placeholder-paragraph">
                        <div class="line" />
                    </div>
                </div>
                <div class="mg-20 pd-10">
                    <div class="placeholder-paragraph">
                        <div class="line" />
                    </div>
                </div>
                <div class="mg-20 pd-10">
                    <div class="placeholder-paragraph">
                        <div class="line" />
                    </div>
                </div>
                <div class="mg-20 pd-10">
                    <div class="placeholder-paragraph">
                        <div class="line" />
                    </div>
                </div>
                {:else}
                {#each qList as aQuestion}
                <div class="question-display"
                    style="border-radius: 4px; border : 1px solid #ddd; display: flex;margin-bottom: 15px; flex-direction: column;">
                    <div class="d-flex flex-row mg-t-20 mg-r-20 mg-l-20 mg-b-0">
                        <div class="custom-control custom-checkbox mg-r-5">
                            <input type="checkbox" class="custom-control-input select-question" id="sq_{aQuestion._id}"
                                name="selected_q" on:change={(ele)=>{onCBChange(ele, aQuestion)}}
                            checked={tempDisplayList.has(aQuestion._id)}
                            value="{aQuestion._id}" />
                            <label class="custom-control-label" for="sq_{aQuestion._id}"></label>
                        </div>
                        <div>
                            <span style="font-weight: bold; margin-right: 10px;">
                                {aQuestion.qId_synced
                                ? `${aQuestion.qId} (${aQuestion.qId_synced}) :`
                                : `${aQuestion.qId} :`}
                            </span>
                            {@html aQuestion.data && aQuestion.data.question && aQuestion.data.question.English
                            ? aQuestion.data.question.English
                            : '-'}
                        </div>

                    </div>

                    <div class="d-flex flex-row">
                        {#if aQuestion.data.option_a}
                        <div class="column">
                            <div class="row mg-20">
                                <div class="col-6 d-flex p-2">a) &nbsp; {@html aQuestion.data && aQuestion.data.option_a
                                    && aQuestion.data.option_a.English ? aQuestion.data.option_a.English : '-'}</div>
                                <div class="col-6 d-flex p-2">b) &nbsp; {@html aQuestion.data && aQuestion.data.option_b
                                    && aQuestion.data.option_b.English ? aQuestion.data.option_b.English : '-'}</div>
                                <div class="col-6 d-flex p-2">c) &nbsp; {@html aQuestion.data && aQuestion.data.option_c
                                    && aQuestion.data.option_c.English ? aQuestion.data.option_c.English : '-'}</div>
                                <div class="col-6 d-flex p-2">d) &nbsp; {@html aQuestion.data && aQuestion.data.option_d
                                    && aQuestion.data.option_d.English ? aQuestion.data.option_d.English : '-'}</div>
                                {#if aQuestion.data && aQuestion.data.option_e}
                                <div class="col-6 d-flex p-2">e) &nbsp; {@html aQuestion.data && aQuestion.data.option_e
                                    &&
                                    aQuestion.data.option_e.English ? aQuestion.data.option_e.English : '-'}</div>

                                {/if}
                                {#if aQuestion.data && aQuestion.data.option_f}
                                <div class="col-6 d-flex p-2">f) &nbsp; {@html aQuestion.data && aQuestion.data.option_f
                                    &&
                                    aQuestion.data.option_f.English ? aQuestion.data.option_f.English : '-'}</div>

                                {/if}
                                {#if aQuestion.data && aQuestion.data.option_g}
                                <div class="col-6 d-flex p-2">g) &nbsp; {@html aQuestion.data && aQuestion.data.option_g
                                    &&
                                    aQuestion.data.option_g.English ? aQuestion.data.option_g.English : '-'}</div>

                                {/if}
                                {#if aQuestion.data && aQuestion.data.option_h}
                                <div class="col-6 d-flex p-2">h) &nbsp; {@html aQuestion.data && aQuestion.data.option_h
                                    &&
                                    aQuestion.data.option_h.English ? aQuestion.data.option_h.English : '-'}</div>

                                {/if}
                            </div>
                            <div class="mg-l-20 mg-b-10 col d-flex tx-bold">Ans: &nbsp; {@html aQuestion.data &&
                                aQuestion.data.answer ? aQuestion.data.answer : '-'}</div>
                            <div class="mg-l-20 mg-b-10 col d-flex tx-bold">Occurrence in Exams:
                                {aQuestion.testOccurrence}</div>
                        </div>
                        {:else}
                        <div class="column">
                            <div class="row mg-20">
                                <div class="col d-flex tx-bold">Ans: &nbsp; {@html aQuestion.data &&
                                    aQuestion.data.answer ? aQuestion.data.answer : '-'}</div>
                            </div>
                            <div class="d-flex tx-bold">Occurrence in Exams: {aQuestion.testOccurrence}</div>
                        </div>
                        {/if}
                    </div>
                </div>
                {:else}
                <div class="empty-state-img">
                    <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
                    <p class="tx-bold">No questions found OR all questions already added to test</p>
                </div>
                {/each}
                {/if}

            </div>

            <div data-label="Example" class="df-example demo-table">
                <table bind:this={demoDataTable} class="table" style="display: none;">
                    <thead>
                        <tr>
                            <th class="wd-20p">ID</th>
                        </tr>
                    </thead>
                    <tbody></tbody>
                </table>
            </div>
            <div style="padding-top: 15px;">
                {#if selectedIdArr.size > 0}
                <button on:click={onChooseSelectedQuestion} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25"
                    style="width: 100%;">
                    <i data-feather="pocket" class="wd-10 mg-r-5"></i>
                    Choose {selectedIdArr.size} Selected Question
                </button>
                {:else}
                <button on:click={onChooseSelectedQuestion} disabled
                    class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25" style="width: 100%;">
                    <i data-feather="pocket" class="wd-10 mg-r-5"></i>
                    Choose Selected Question
                </button>
                {/if}

            </div>
        </div>
    </div>
</div>


<style>
    .empty-state-img {
        text-align: center;
        width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
</style>
