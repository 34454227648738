<script>
    import { Router, Link, Route } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import { activeMenu } from "../stores";
    import MODULES from "../utils/modules.js"
    import Forbidden from "../forbidden.svelte"
    import Schedule from "./schedule.svelte"
    import ViewLectureAttendance from "./viewLectureAttendance.svelte"

    function init() {
        activeMenu.set("schedule");
        feather.replace();
    }
    onMount(init);
</script>

<Router>
    <!-- <Route path=":id" let:params>
        {#if MODULES.hasAccess(MODULES.UPDATE_SYSTEM_USERS)}
        <AddUpdate id={params.id} />
        {:else}
        <Forbidden />
        {/if}
    </Route> -->
    <Route path="">
        <Schedule />
    </Route>

    <Route path="viewattendance/:id" let:params>
        <ViewLectureAttendance lectureId="{params.id}" />
    </Route>
</Router>
