<script>
  import { Router,links , Link, Route, navigate } from "svelte-routing";
  import { onMount, tick,afterUpdate } from 'svelte';
  import { activeMenu } from "../stores";
  import moment from 'moment';
  import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
  import ButtonLoader from "../utils/button_loader.svelte"
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import BatchSelectComponent from "../utils/batchSelectComponent.svelte";
  import ExcelJS from 'exceljs'

  let elem_datatable;
  let isLoading = false;
  let startTime;
  let date="";
  let filterModalThis;
  export let batch_id;
  let batch_timing_id;
  let batchName =""
  let dateObj= date ?new Date(date).toISOString():"";
  let batchesList, classList, topic, attendanceType, attendanceDate;
  let filterElemBatch, selectizeBatchInstance, filterElemClass, 
      selectizeClassInstance, elem_endTimePicker, elem_startTimePicker;
  let studentList = {data:{
  data: []
}};
let exportLoading = false;
let studentAttendanceList = []
const pageSize = 10;
let length = pageSize;
let search = {
  value: ""
};
let msg = '';
let batchList = [];

var rangeCalendar;
let emptyState = false;
let studentDataTable;
 async function rangeChanged(target,range){
     date = range.start;
     _$('.tableDiv').removeClass('d-none');
    _$(`#btn_All_Timings`).addClass('btn-dark').removeClass('btn-outline-dark')
    _$(`#btn${batch_timing_id}`).removeClass('btn-dark').addClass('btn-outline-dark');

    if(_$(`#btn_All_Timings`).hasClass('btn-dark'))   {
    // _$(`#btn${batch_timing_id}`).removeClass('btn-dark').addClass('btn-outline-dark');
    _$(`#btn_All_Timings`).addClass('btn-dark').removeClass('btn-outline-dark')

      batch_timing_id =  ""
      redrawStudentTable()

    }
    
    // _$(`#btn_All_Timings`)

    // await getHolidayByDate();
    //  if(studentDataTable) { studentDataTable.draw() }
     
     let temp = new Date().setHours(0,0,0,0);
      let rangeDate = new Date(range.start).setHours(0,0,0,0);
      if(new Date(rangeDate).getTime() >new Date(temp).getTime()){
        rangeCalendar.setStartDate(temp);
      }

      if(new Date(rangeDate).getTime() === new Date(temp).getTime()){
        _$('.selectTodaysDate').addClass('d-none');
      }
      else{

        _$('.selectTodaysDate').removeClass('d-none');
      }
      // bindDatatable();
      redrawStudentTable()

      await getBatchesFromBatchTiming(date, batch_id)
      // if(studentDataTable) studentDataTable.draw()

  }

  async function api_getBatchesList() {
        var resData = await api_handleApiCalls('batch.getMyBatches', {})
        if (resData.status) {

            return resData.data
        } else {
            return []
        }
    }

    async function api_getClassesList() {
      var data = {
        start: 0,
        length: pageSize,
        search,
        sort: parseSorts({ 0: "dsc" }),
      }
        var resData = await api_handleApiCalls("instituteClass.fetchInstituteClassList", data)
        if (resData.status) {
          if(resData.data.data.length) return resData.data.data

          else return [];
        } else {
            return []
        }
    }

    function bindSelect2s () {
        
        selectizeBatchInstance = _$(filterElemBatch).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "select batches",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: batchesList,
        });

        selectizeClassInstance = _$(filterElemClass).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            placeholder: "select class",
            plugins: ["remove_button"],
            persist: false,
            options: classList,
        });

        _$(elem_startTimePicker).timepicker({
            timeFormat: "h:mm p",
            interval: 15,
            dynamic: false,
            dropdown: true,
            scrollbar: true,
            template: "modal",
            default: "12:00 AM",
            change: async function (time) {
                if (time) {
                 startTime = new Date(time);
                } else {
                 startTime = null;
                }
                await tick();
            },
        });

        _$(elem_endTimePicker).timepicker({
            timeFormat: "h:mm p",
            interval: 15,
            dynamic: false,
            dropdown: true,
            scrollbar: true,
            template: "modal",
            default: "12:00 AM",
            change: async function (time) {
                if (time) {
                 endTime = new Date(time);
                } else {
                 endTime = null;
                }
                await tick();
            },
        });

    }

    async function getFilterDataList(){
          batchesList = await api_getBatchesList()
          classList = await api_getClassesList()
          bindSelect2s()
    }

    function getFilterData() {
      //   let batchId = _$(selectizeBatchInstance).val()
      //   let subjectId =  _$(selectizeClassInstance).val()
      //  let startTime = _$(elem_startTimePicker).val()
      //  let endTime = _$(elem_endTimePicker).val()
        return {
          attendanceType,
          // subjectId,
          topic,
        //   batchId,
        // attendanceDate,
        // startTime,
        // endTime
         }
    }

    function clearFilter() {
        attendanceType = null;
        // selectizeBatchInstance[0].selectize.clear();
        // selectizeClassInstance[0].selectize.clear();
      //  _$(elem_startTimePicker).timepicker("setTime", null);
      //  _$(elem_endTimePicker).timepicker("setTime", null);
      // elem_startTimePicker = "12:00 AM";
      // elem_endTimePicker = "12:00 AM";
       topic = null;
       attendanceDate = null;
       if (studentDataTable) { studentDataTable.draw() }

    }

    function applyFilter() {
        redrawStudentTable();
    }

    function redrawStudentTable() {
        if (studentDataTable) { studentDataTable.draw() }
    }

  onMount(async () => {
    activeMenu.set('viewAttendance');

    if (!batch_id) {
      return;
    }
    feather.replace();
    _$('[data-toggle="tooltip"]').tooltip();
    let res = await api_getBatchById();
    if (res.status) {
      batchName = res.data.name;
    }
    rangeCalendar = _$('#selectDate').rangeCalendar({
      locale: "en",
      theme: "default-theme",
      themeContext: this,
      startDate: new Date(),
      endDate: moment().add(13, 'months'),
      start: "-360",
      startRangeWidth: 1,
      minRangeWidth: 1,
      maxRangeWidth: 1,
      weekends: true,
      autoHideMonths: false,
      visible: true,
      trigger: null,
      changeRangeCallback: rangeChanged
    });
    date = new Date();
    rangeCalendar.setStartDate(date);
    getBatchesFromBatchTiming(date, batch_id);
    bindDatatable();


    // getFilterDataList();

  });

  async function api_getBatchByDate(date, batchId) {

    let data = { date: new Date(date).getDay(), batch_id: batchId };
    let action = 'batchtiming.getBatchtimingByDate';
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_getBatchById() {

      let data = { _id: batch_id };
      let action = 'batch.getBatchById';
      var response = await api_handleApiCalls(action, data);
      return response;

    }
  let getBatchesFromBatchTiming = async (date , batchId) => {
    let result = await api_getBatchByDate(date, batchId);
    // console.log('result ', result);
    if (result.status) {
      batchList = result.data;
    } else {

    }
    msg = "No Class Timing is selected."
    // console.log('inn getBatchesFromBatchTiming ', new Date(date), result);
  }
async function bindDatatable () {
      studentDataTable = _$(elem_datatable).DataTable({
        stateSave: true,
            "stateSaveCallback": function (settings, data) {
                localStorage.setItem(window.location.pathname, JSON.stringify(data))
            },
            "stateLoadCallback": function (settings) {
                return JSON.parse(localStorage.getItem(window.location.pathname))
            },
          responsive: false,
          language: {
            infoFiltered: "",
              processing: "",
              searchPlaceholder: 'Student Name',
              sSearch: '',
              lengthMenu: '_MENU_ Students/page',
          },
          ajax: async function (data, callback, settings) {
            data.sort = parseSorts(data.order ? data.order[0] : { 0: "asc" });
            let holidays = await api_getHolidayByDate();
            var filterObj = { ...getFilterData() }
                filterObj.start = data.start
                filterObj.length = data.length
                filterObj.search = data.search.value
                filterObj.draw = data.draw
                filterObj.sort = data.sort;
                filterObj.date = date;
                filterObj.batchId = batch_id;
                filterObj.batch_timing_id  = batch_timing_id ? batch_timing_id :""
          _$.ajax({
              url: API_URL,
              method: 'post',
              data: {
                  action: 'attendance.viewAttendanceList',
                  accessToken: localStorage.getItem("accessToken"),
                  data: filterObj
              },
              success: function (resData) {
                  var result = []
                  if (resData.status) {
                      var paginationData = resData.data;
                      studentAttendanceList = paginationData.data 
                      // console.log("studentAtendanceList",studentAttendanceList)
                      result = {
                          recordsTotal: Number(paginationData.totalCount),
                          recordsFiltered: Number(paginationData.filteredCount),
                          data: paginationData.data
                      }
                      if (!resData.data.length && !batch_id) {
                          emptyState = true
                      }
                      callback(result);
                  }
                  else {
                      console.log("Something went wrong");
                  }
              },
          })
          },
          columns: [
          { data: '_id',
          render: (data, mode, row, settings) => {
          let image;
          if (row.studentDetails.profilePic) {
            // if image found display profilePic avatar
            image = `<img src="${
              row.studentDetails.profilePic
            }" class="avatar mg-r-15" alt="${row.studentName[0].toUpperCase()}">`;
          } else {
            // if image not found display initials avatar
            image = `<div class="avatar mg-r-15"><span class="avatar-initial rounded-circle">${row.studentName
              .split(" ")
              .map((str) => str[0] || "")
              .join("")}</span></div>`;
          }
          return `
          <div class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0" >
            ${image}
            <div class="wd-80p bg-transparent">
              <h6 class="tx-13 mg-b-2 tx-capitalize"><a href="/dashboard/student/view/${row.studentDetails._id}/attendance">${row.studentName}</a></h6>
                  <span class="d-block tx-color-03"><i data-feather="phone"></i>${row.studentDetails.mobileNo}</span>
            </div>
          </div>`;
        },
      //  defaultContent : '<i>No data found<i>'
      },
          { data: 'batchDetails.name', defaultContent : '<i>No data found<i>' },
          { data: '', render:function (data, a, full, c){
            let name = ''
            if(full.batchtimingDetails && full.batchtimingDetails.moduleSubjects && full.batchtimingDetails.moduleSubjects.length){
              full.batchtimingDetails.moduleSubjects.map(function (aModuleSubject) {
              full.subjectList.map(function(item) { 
                if(item._id == aModuleSubject){
                    if(name != ''){
                      name = name + ","+ item.name
                    }else{
                      name= item.name;
                    }
                }
              });
            })
            return name;
            }
            else return ""
            
          }
           },
           { data: '_id', 
            render: function  (data, a, full, c) {
              return full.batchtimingDetails && full.batchtimingDetails.topic ? full.batchtimingDetails.topic : "-"
              
          } },
          { data: '_id', 
          render: function  (data, a, full, c) {
            if(full.batchtimingDetails && full.batchtimingDetails.startTime){
              let d = new Date(full.batchtimingDetails.startTime)
              return moment(d).format('LT')
            }
            else return "-"  
              
          } },
          { data: '_id', 
          render: function  (data, a, full, c) {
            if(full.batchtimingDetails && full.batchtimingDetails.endTime){
              let d = new Date(full.batchtimingDetails.endTime)
              return moment(d).format('LT')
            }
            else return "-"    
              
          } },
          { data: 'attendance', defaultContent : '<i>No data found<i>' },
          { data: 'notes', defaultContent : '<i>-<i>' },

          ],
          "processing": true,
          "serverSide": true,                
          });
      _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
  }

  let getHolidayByDate = async () =>{
        let result = await api_getHolidayByDate();
        if(result.status){
          _$('.tableDiv').addClass('d-none');
        }else{
          _$('.tableDiv').removeClass('d-none');
        }
    }
  async function api_getHolidayByDate() {
      let action = 'holidays.getHolidayByDate';
        var response = await api_handleApiCalls(action,  {date:date});
        return response;

    }
   
  function parseSorts(sortOrder) {
// parses datatable order to mongodb sort object
let sort = {};
// sorting orders
let order;
if (sortOrder.dir === "asc") order = 1;
else order = -1;
// if (sortOrder.column === 1) {
//   sort.name = order;
// } else if (sortOrder.column === 2) {
//   sort.createdOn = order;
// } else {n
//   sort.seqId = order;
// }
sort.seqId = order;
return sort;
}

let selectTodaysDate = async(e) =>{
  date = new Date();
  rangeCalendar.setStartDate(date);
  // _$('.selectTodaysDate').addClass('d-none');
}

function showHideFilterModal(action) {
        action ? (_$(filterModalThis).modal('show')) : (_$(filterModalThis).modal('hide'))
    }
    function handleBatchChange(event) {
    if (event.detail.batch_id) {
      navigate("/dashboard/viewAttendance/" + event.detail.batch_id)
    } else {
      navigate("/dashboard/viewAttendance");
    }
  }


  async function selectBatch(e) {

    batch_timing_id = e.target.dataset.id;

    _$('.batchtimingbtn').removeClass('btn-dark').addClass('btn-outline-dark');
    _$('#btn_All_Timings').removeClass('btn-dark').addClass('btn-outline-dark');

    _$(`#btn${batch_timing_id}`).addClass('btn-dark').removeClass('btn-outline-dark');
    // console.log('select batch id', batch_timing_id);
    msg = 'No student is avalaible in this class.'
    // redrawStudentTable()
    if (studentDataTable) { studentDataTable.draw() }

  }

  async function api_exportAttendance(data) {
    exportLoading = true;

    var response = await api_handleApiCalls("attendance.attendanceExport", data);
    exportLoading = false;

    if (response.status) {
      return response.data;
    } else {
      pError("Error!", response.errMsg);
      return null;
    }
  }
  var btnExportAttendance = async () => {
    if (exportLoading) return;
    exportLoading = true;
    let data = {
      batch_id: batch_id,
      batch_timing_id: batch_timing_id,
      date: date,
    };
    // let attendancedata = await api_exportAttendance(data);
    // let  attendancedata = studnetAttendanceList
    // console.log("enquirydata:::",enquirydata)
    // console.log("studentAttendanceList",studentAttendanceList);
    let allAttendance = studentAttendanceList;
    // console.log("allAttendance:::", allAttendance);
    if (allAttendance) {
      generateWorkBook(allAttendance);
    }

    exportLoading = false;
  };

  async function generateWorkBook(attendanceDataList) {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet("Attendance Data", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });

    let fileName = "Attendance_Data_Export_" + new Date().getTime() + "_.xlsx";

    let columnsArr = [
      { header: "Sr No.", key: "serialNo", width: 15 },
      { header: "Student Name", key: "name", width: 15 },
      { header: "Mobile No", key: "mobileNo", width: 15 },
      { header: "Parent Contact Number", key: "parentContactNo", width: 15 },

      { header: "Email", key: "email", width: 15 },
      { header: "Batch", key: "batch", width: 15 },

      { header: "Subject", key: "subject", width: 15 },

      { header: "Topic", key: "topic", width: 15 },
      { header: "Class Start Time", key: "startTime", width: 15 },
      { header: "Class End Time", key: "endTime", width: 15 },
      { header: "Attendance Date", key: "date", width: 15 },

      { header: "Attendance", key: "attendance", width: 15 },
      { header: "Notes", key: "notes", width: 15 },

      // { header: "Class", key: "class", width: 15 },
      // { header: "Assigned Batches", key: "batch", width: 15 },
      // { header: "Assigned Counselor", key: "counselor", width: 15 },
      // { header: "Enquiry Date", key: "attendanceDate", width: 20 },
      // { header: "Next Follow up Date", key: "followupDate", width: 15 },
      // { header: "Created By", key: "createdBy", width: 15 },
    ];

    worksheet.columns = columnsArr;

    for (let idx = 0; idx < attendanceDataList.length; idx++) {
      let attendanceDataArr = [];
      let attendanceResult = attendanceDataList[idx];

      let attendanceDate = moment(new Date(attendanceResult.attendanceDate)).format(
        "DD/MM/YYYY"
      );

    

      attendanceDataArr.push(idx + 1);
      attendanceDataArr.push(attendanceResult.studentDetails.name ? attendanceResult.studentDetails.name : "");
      attendanceDataArr.push(attendanceResult.studentDetails.mobileNo ? attendanceResult.studentDetails.mobileNo : "");
      attendanceDataArr.push(attendanceResult?.parentContactNo ? attendanceResult?.parentContactNo : "");
      
      attendanceDataArr.push(attendanceResult.studentDetails.email ? attendanceResult.studentDetails.email : "");
      attendanceDataArr.push(attendanceResult.batchDetails.name ? attendanceResult.batchDetails.name : "");
      attendanceDataArr.push(attendanceResult.subjectName? attendanceResult.subjectName: "");

      attendanceDataArr.push(attendanceResult.batchtimingDetails.topic? attendanceResult.batchtimingDetails.topic: "");

      attendanceDataArr.push(attendanceResult.batchtimingDetails.startTime? moment(attendanceResult.batchtimingDetails.startTime).format('LT'): "");
      attendanceDataArr.push(attendanceResult.batchtimingDetails.endTime? moment(attendanceResult.batchtimingDetails.endTime).format('LT'): "");
      attendanceDataArr.push(attendanceDate ? attendanceDate:"")
      attendanceDataArr.push(attendanceResult.attendance? attendanceResult.attendance: "");
      attendanceDataArr.push(attendanceResult.notes? attendanceResult.notes: "");


      worksheet.addRow(attendanceDataArr);
    }

    const buffer = await workbook.xlsx.writeBuffer();

    saveFile(
      new Blob([buffer], { type: "application/octet-stream" }),
      fileName
    );
    //   await workbook.xlsx.writeFile(fileName);
  }
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    // var view = new Uint8Array(buf);
    var view = s;
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const saveFile = async (blob, fileName) => {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };
  function uniqueId() {
    // always start with a letter (for DOM friendlyness)
    var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
    do {
      // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
      var ascicode = Math.floor(Math.random() * 42 + 48);
      if (ascicode < 58 || ascicode > 64) {
        // exclude all chars between : (58) and @ (64)
        idstr += String.fromCharCode(ascicode);
      }
    } while (idstr.length < 32);

    return idstr.toLowerCase();
  }
</script>

<style>
  .removeBorder{
    border-right: hidden;
  }
</style>
<div class="content-body fadeIn">
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div>
          <nav use:links aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                  <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                     View Attendance
                      </li>
              </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1"> 
              View Attendance
          </h4>
          <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Review the attendance record or student attendance report.</p>
          <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5 tx-uppercase">{batchName? batchName:""}</p>

          <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">{date ? moment(date).format('LL') :  moment(new Date()).format('LL')}</p>
        </div>
        <div class="d-flex">
          <div class="d-block">
            <div class="btn-group flex-fill ">
            <button
            disabled={exportLoading}
            class="btn btn-sm pd-x-15 btn-uppercase btn-secondary mg-l-5  mg-r-5 "
            type="button"
            on:click={btnExportAttendance}
          >
            <ButtonLoader
              isLoading={exportLoading}
              btnText={exportLoading ? "Processing..." : "Export"}
            />
          </button>
            <!-- <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 selectTodaysDate" on:click={selectTodaysDate}>Today's Date</button> -->
            <button class="btn btn-sm mg-l-10 mg-r-10 pd-x-15 btn-primary btn-uppercase" on:click={()=>{showHideFilterModal(true)}}> <i data-feather="filter" class="mg-r-5"></i> Filters</button>
          </div>
          </div>
          <BatchSelectComponent batch_id={batch_id} on:change={handleBatchChange} >
          </BatchSelectComponent>
        </div>

  </div>
  <hr class="full-width">
  <div id="selectDate"></div>
  <hr class="full-width">

  {#if batch_id}

  <div class="row attendance ht-300">
    <div class="col-md-3 pd-0">
      <div class="card ht-300">
        <div class="card-header pd-10 d-md-flex align-items-center justify-content-between">
          <h6><i data-feather="clock"></i> Class Timings</h6>
          <!-- <buttonclass="btn btn-icon btn-primary btn-xs mg-l-15" data-toggle="tooltip"
            title="Add class timing">
            <span><i data-feather="plus"></i></span>
          </button> -->
        </div>
        <div class="ht-100p batchtiming">
        
          <button on:click={selectBatch}  id='btn_All_Timings'
            class="mg-10 wd-90p btn batchtimingbtn btn-sm btn-outline-dark btn-uppercase">
            Batch : {batchName}
           <br />
            All Timings
            <!-- {#if aBatchTiming.topic}
            Class Topic : {aBatchTiming.topic}<br>
            {/if}
            {moment(aBatchTiming.startTime).format('LT')} - {moment(aBatchTiming.endTime).format('LT')} -->
          </button>
          {#if batchList && batchList.length}
          {#each batchList as aBatchTiming}
          <button on:click={selectBatch} data-subjectList={aBatchTiming.subjectList}
            data-subjectId={aBatchTiming.moduleSubjects} data-id={aBatchTiming._id}
            data-startTime={aBatchTiming.startTime} data-endTime={aBatchTiming.endTime}
            data-batchName="{aBatchTiming.batchDetails[0].name}" id='btn{aBatchTiming._id}'
            class="mg-10 wd-90p btn batchtimingbtn btn-sm btn-outline-dark btn-uppercase">
            {aBatchTiming.moduleSubjects.map(function (aModuleSubject) {
            var aSubject = aBatchTiming.subjectList.find(function(item) { return item._id == aModuleSubject});
            return aSubject.name;
            }).join(", ")}<br />
            {#if aBatchTiming.topic}
            Class Topic : {aBatchTiming.topic}<br>
            {/if}
            {moment(aBatchTiming.startTime).format('LT')} - {moment(aBatchTiming.endTime).format('LT')}
          </button>

          {/each}
          <!-- {:else}
          <div class="d-flex justify-content-center">

            <div class="empty-state-img justify-content-center align-items-center tx-center">
              <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
              <p class="tx-bold tx-center">No Classes found</p>
            </div>

          </div> -->
          {/if}
          </div>
      </div>
    </div>
    <div class="col-md-9 pd-1 ht-100p">

    <div class="tableDiv">

      <table bind:this={elem_datatable} class="table wd-100p"> 
        <thead>
          <tr>
            <th class="wd-15p">Student</th>
            <th class="wd-10p">Batch</th>
            <th class="wd-10p">Class</th>
            <th class="wd-10p">Topic</th>
            <th class="wd-10p">Start Time</th>
            <th class="wd-10p">End Time</th>
            <th class="wd-10p">Attendance</th>
            <th class="wd-10p">Notes</th>
          </tr>
        </thead>
        <tbody />
      </table>
      {#if emptyState}
      <div class="empty-state-img">
        <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
        <p class="tx-bold">No Student Attendance found</p>
      </div>
      {/if}
    </div>
    </div>
  </div>
{:else}
<div class="d-flex justify-content-center">
  <div class="empty-state-img justify-content-center align-items-center tx-center">
    <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
    <p class="tx-bold tx-center">Select Batch first</p>
  </div>
</div>

{/if}
</div>

<!-- Filter Modal -->
<div class="modal effect-scale" bind:this={filterModalThis} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <div class="form-group">
                    <label class="d-block flex-grow-1">Attendance Type</label>
                    <div class="d-md-flex">
                        <div class="custom-control custom-radio mg-r-15">
                            <input type="radio" id="present" bind:group={attendanceType} class="custom-control-input"
                                value='present'>
                            <label class="custom-control-label" for="present">Present</label>
                        </div>
                        <div class="custom-control custom-radio mg-r-15">
                            <input type="radio" bind:group={attendanceType} id="absent" class="custom-control-input"
                                value='absent'>
                            <label class="custom-control-label" for="absent">Absent</label>
                        </div>
                        <div class="custom-control custom-radio mg-r-15">
                          <input type="radio" bind:group={attendanceType} id="late" class="custom-control-input"
                              value='late'>
                          <label class="custom-control-label" for="late">Late</label>
                      </div>
                      <div class="custom-control custom-radio mg-r-15">
                        <input type="radio" bind:group={attendanceType} id="excused" class="custom-control-input"
                            value='excused'>
                        <label class="custom-control-label" for="excused">Excused</label>
                    </div>
                    </div>
                </div>
<!-- 
                <div class="form-group">
                    <label class="d-block">Date :</label>
                    <input type="date" onkeydown="return false" bind:value={attendanceDate} data-date-format="DD MM YYYY" class="form-control" placeholder="dd/mm/yyyy"/>
                </div>

                <div class="form-group row">
                    <div class="pd-l-10">
                        <label class="d-block">Start Time:</label>
                        <input type="text"  bind:this={elem_startTimePicker} class="form-control  wd-200"/>
                    </div>
    
                    <div class="pd-l-10">
                        <label class="d-block">End Time:</label>
                        <input type="text"  bind:this={elem_endTimePicker} class="form-control wd-200"/>
                    </div>
                </div> -->

                <div class="form-group">
                  <label class="d-block">Topic :</label>
                  <input type="text"  bind:value={topic} class="form-control" placeholder="Enter topic"/>
                </div>

                <!-- <div class="form-group">
                    <label class="d-block">Select Batch:</label>
                    <select disabled={isLoading} bind:this={filterElemBatch} class="form-control select2">
                        <option>Select batches</option>
                    </select>
                </div>

                <div class="form-group">
                  <label class="d-block">Select Class:</label>
                  <select disabled={isLoading} bind:this={filterElemClass} class="form-control select2">
                      <option>Select Class</option>
                  </select>
              </div> -->

                <div class="d-md-flex justify-content-end mg-t-25">
                    <button disabled={isLoading} on:click={clearFilter}
                        class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal">CLEAR
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button disabled={isLoading} on:click={applyFilter} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
                        data-dismiss="modal">
                        <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                        <i data-feather="filter" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Modal -->
