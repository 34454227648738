<script>
    import { Router, Link, Route, link, navigate } from "svelte-routing";
    import { onMount, tick, onDestroy } from 'svelte';
    import { _accessToken } from "../store.js";
    import { API_URL } from "../config.js"

    let userList = [];


    async function selectUser(selectedItem) {
        var accessTokenList = window.localStorage.getItem("accessTokenList").split(",");
        var accessToken = accessTokenList[selectedItem];
        _accessToken.set(accessToken);
        window.localStorage.setItem("accessToken", accessToken);


        var browser = (function () {
            var test = function (regexp) { return regexp.test(window.navigator.userAgent) }
            switch (true) {
                case test(/edg/i): return "Microsoft Edge";
                case test(/trident/i): return "Microsoft Internet Explorer";
                case test(/firefox|fxios/i): return "Mozilla Firefox";
                case test(/opr\//i): return "Opera";
                case test(/ucbrowser/i): return "UC Browser";
                case test(/samsungbrowser/i): return "Samsung Browser";
                case test(/chrome|chromium|crios/i): return "Google Chrome";
                case test(/safari/i): return "Apple Safari";
                default: return "Other";
            }
        })();

        var myHeaders1 = new Headers();
        myHeaders1.append("Content-Type", "application/json");
        var payload1 = JSON.stringify({
            action: "auth.updateUserAccessToken",
            accessToken: accessToken,
            data: {
                accessToken: accessToken,
                deviceName: 'Browser',
                deviceModel: browser
            }
        });
        var requestOptions1 = {
            method: "POST",
            headers: myHeaders1,
            body: payload1,
        };


        await fetch(API_URL, requestOptions1);


        navigate("/dashboard", { replace: true });

    }
    async function getUsersByTokenList() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var payload = JSON.stringify({
            action: "auth.getUsersByTokenList",
            data: {
                accessTokenList: window.localStorage.getItem("accessTokenList").split(","),
            },
        });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: payload,
        };
        try {
            var resObj = await fetch(API_URL, requestOptions);
            var response = await resObj.json();
            console.log(response);
            if (response.status) {
                userList = response.data;
                await tick()
            } else {
                errorDetails.otp = response.errMsg;
            }
        } catch (error) {
            console.error("error", error);
            console.log({ error: error });
        }
        if (scroll1) scroll1.update()
    }
    var scroll1
    async function init() {
        scroll1 = new PerfectScrollbar('#scroll1', {
            suppressScrollX: true
        });
        getUsersByTokenList()
    }
    onMount(init);




</script>

<style>
    .list-group {
        max-height: 60vh;
    }
</style>

<div class="sign-wrapper mg-lg-l-50 mg-xl-l-60 fadeIn">
    <div class="wd-100p">
        <h3 class="tx-color-01 mg-b-5">Select User</h3>
        <p class="tx-color-03 tx-16 mg-b-40">Please Select a User To continue.</p>


        <div id="scroll1" class="list-group pos-relative">
            {#each userList as user, index (user.userId)}
            <a href="#" on:click|preventDefault={()=> selectUser(index)} class="list-group-item d-flex
                align-items-center">
                <img src={user.profilePic ? user.profilePic : "/images/man.png" } class="wd-30 rounded-circle mg-r-15"
                    alt="">
                <div>
                    <h6 class="tx-13 tx-inverse tx-semibold mg-b-0">{user.name}</h6>
                    <span class="d-block tx-11 text-muted">{user.role} - {user.orgName}</span>
                </div>
            </a>
            {/each}
        </div>
    </div>

</div><!-- sign-wrapper -->
