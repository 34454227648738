<script>
    import { Router, Link, link, links, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import { activeMenu } from "../../stores";
    import FolderModal from "../../folderCmp/questionBankModal.svelte";
    import { pConfirm, pSuccess, pError, pAlert } from "../../utils/notify.svelte";
    import ButtonLoader from "../../utils/button_loader.svelte"
    // import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import MODULES from "../../utils/modules";
    import AddUpdateQuestion from '../editQuestion/index.svelte'
    import { API_URL } from "../../../config.js"

    export let hideBreadcrumbs = false;
    export let onQuestionsUploadDone;
    let showModal = false;
    let linkArr = [];
    let linkArrFull = [];
    let isEditQuestion = false;
    let selectedQuestionSequence
    let selectedQuestionData
    let breadCrumb = [];
    let i = 1;
    let isLoading = false
    let elemQuestionType
    let elemQuestionLang
    let elemPreviewQuestionsModal
    let elemQuestionUploadErrorModal
    let elemDownloadQtemplete
    let downloadTempleteOf
    let questionsLang
    let questionsType
    let questionsDataArray = []
    let selectizeInstanceQuestionLang
    let selectizeInstanceQuestionType
    let hideQTypes = 0

    let emptyState = true
    let elemQuestionsTable
    let questionsTableDTInstance
    let elem_uploadFile
    let importLoading

    async function api_handleApiCalls(action, data) {
        isLoading = true
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var payload = JSON.stringify({
            action: action,
            accessToken: localStorage.getItem("accessToken"),
            data: data,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: payload,
        };

        try {
            var resObj = await fetch(API_URL, requestOptions);
            var response = await resObj.json();
            isLoading = false
            return response;

        } catch (error) {
            isLoading = false
            return { errMsg: error.message };

        }
    }

    let getFromBetween = {
        results: [],
        string: "",
        getFromBetween: function (sub1, sub2) {
            if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0)
                return false;
            var SP = this.string.indexOf(sub1) + sub1.length;
            var string1 = this.string.substr(0, SP);
            var string2 = this.string.substr(SP);
            var TP = string1.length + string2.indexOf(sub2);
            return this.string.substring(SP, TP);
        },
        removeFromBetween: function (sub1, sub2) {
            if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0)
                return false;
            var removal = sub1 + this.getFromBetween(sub1, sub2) + sub2;
            this.string = this.string.replace(removal, "");
        },
        getAllResults: function (sub1, sub2) {
            if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0)
                return;
            var result = this.getFromBetween(sub1, sub2);
            this.results.push(result);
            this.removeFromBetween(sub1, sub2);
            if (this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
                this.getAllResults(sub1, sub2);
            } else
                return;
        }
        ,
        get: function (string, sub1, sub2) {
            this.results = [];
            this.string = string;
            this.getAllResults(sub1, sub2);
            return this.results;
        }
    };
    async function resetQuestionsUpload() {
        questionsDataArray = []
        verifiedFieldAnwser = {}
        unwantedField = {}
        verifiedFieldOption = {}
        compulsoryField = {}
        linkArr = []
        linkArrFull = []
        breadCrumb = []
        await tick()
        bindQuestionsDatatable()
        await tick()
    }
    let Core = {
        formatRichQuestionToSpan: function (question) {
            question = (question.replace(/\/\[.*?\\]\s?/g, '').replace(/\[.*?\]\s?/g, ' [-equation-] ')).replace(/\(.*?\)\s?/g, ' [-equation-] ').replace(/\\/g, '');
            question = question.replace(/<img[^>]*>/g, " [-image-] ");
            question = '<p>' + question + '</p>';
            question = _$(question).text();
            return question;
        }
    }
    let verifiedFieldAnwser = {}
    let unwantedField = {}
    let verifiedFieldOption = {}
    let compulsoryField = {}
    let notMandatoryFields = {}
    let questionsUploadUtils = {
        getQuestionListFromString: function (result) {

            var typeOfQuestion = questionsType;


            // var html = result.value.replace(/<p>/g, ' ').replace(/<\/p>/g, ' ').replace(/ colspan="2"/g, '');

            var html = result.value.replace(/<p>/g, ' ').replace(/<\/p>/g, ' ').replace(/ colspan="2"/g, '').replace(/~/g, 'backsim ').replace(/{leftrightarrows/g, '{\\leftrightarrows ');

            html = html.replace(/\\\[/g, '<span class="math-tex">\\(').replace(/\\\]/g, '\\)</span>')


            html = html.replace(/<img/g, " <img style='max-width:100% !important;height: unset !important;' ");


            var tableData = getFromBetween.get(html, '<table>', '</table>')[0].replace(/&lt;/g, '<').replace(/&gt;/g, '>');
            var tableRows = getFromBetween.get(tableData, '<tr>', '</tr>');

            var errorObj = {
                blankRows: 0,
                invalidRows: [],
                invalidCols: [],
            }
            var questionsValidatedArr = []
            var aRow = tableRows[0];
            var cellArray = getFromBetween.get(aRow.toString(), '<td>', '</td>');
            for (var i = 0; i < cellArray.length; i++) {

                var aCell = cellArray[i].trim();

                if (aCell.includes('Option')) { verifiedFieldOption[aCell] = i; }
                else if (aCell.includes('Key')) { verifiedFieldAnwser['Answer'] = i; }
                else if (aCell.includes('Level')) { notMandatoryFields['Level'] = i }
                else if (aCell.includes('Source')) { notMandatoryFields['Sources'] = i }
                else if (aCell.includes('Solution')) { notMandatoryFields['Explanations'] = i }
                else if (aCell.includes('Tags')) { compulsoryField['Tags'] = i }
                else if (aCell.includes('Sr') || aCell.includes('Positive') || aCell.includes('Negative') || aCell.includes('Exam') || aCell.includes('Question')) {

                    if (typeOfQuestion == 'fillInTheBlanks' || typeOfQuestion == 'trueFalse' || typeOfQuestion == 'integerType' || typeOfQuestion == 'numerical') {

                        if (aCell.includes('Option')) { unwantedField[aCell] = i; }

                    }
                    compulsoryField[aCell] = i;

                } else {
                    unwantedField[aCell] = i;
                }
            }




            for (var i = 1; i <= tableRows.length; i++) {
                var aRow = tableRows[i];
                if (!aRow) {
                    break;
                }

                var cellArray = getFromBetween.get(aRow.toString(), '<td>', '</td>');



                var qLevel = cellArray[notMandatoryFields["Level"]] ? cellArray[notMandatoryFields["Level"]].trim() : null; //getFromBetween.get(cellArray[1], '<p>', '</p>')[0];
                var level;

                if (!qLevel) {
                    level = 'mid';
                } else if (qLevel.toUpperCase() == "EASY") {
                    level = 'easy';
                } else if (qLevel.toUpperCase() == "HARD") {
                    level = 'hard';
                } else {
                    level = 'mid';
                }




                // var keyData="";
                var keyString = "";
                var invalidDataColumn
                keyString = cellArray[verifiedFieldAnwser["Answer"]].trim();

                if (cellArray[compulsoryField["Sr No."]].trim() == "") {
                    cellArray[compulsoryField["Sr No."]] = "(row:" + i + ")";
                }

                var isBlank = true;
                for (var x = 1; x < cellArray.length; x++) {
                    if (cellArray[x].trim() != "") {
                        isBlank = false;
                        break;
                    }
                }

                var missingColArray = [];
                var invalidColArray = [];
                var unwantedFieldErr = false;
                var mandErr = false;
                var mandArr = [];

                function checkForMandatoryColumns(arr) {

                    function varifyCommonField() {
                        for (var variable in compulsoryField) {

                            //   for (var k = 0; k < arr.length; k++) {
                            //     for (var l = 0; l < mandArr.length; l++) {
                            //       if (k == mandArr[l].cell && arr[k].trim() == "") {
                            //         missingColArray.push(mandArr[l].cellName);
                            //         mandErr = true;
                            //       }
                            //     }
                            //   }
                            // }


                            if (compulsoryField.hasOwnProperty(variable)) {

                                if (variable != "Explanations" && variable != "Exam Id" && variable != "Sr No.") {
                                    if (arr[compulsoryField[variable]] == "") {


                                        missingColArray.push(variable);
                                        mandErr = true;
                                    }
                                }
                            }
                        }

                    }
                    function varifyAnswer() {
                        for (var variable in verifiedFieldAnwser) {
                            if (verifiedFieldAnwser.hasOwnProperty(variable)) {
                                if (arr[verifiedFieldAnwser[variable]] == "") {
                                    missingColArray.push(variable);
                                    mandErr = true;
                                }
                            }
                        }
                    }
                    function varifyOption() {
                        for (var variable in verifiedFieldOption) {
                            if (verifiedFieldOption.hasOwnProperty(variable)) {
                                if (arr[verifiedFieldOption[variable]] == "") {
                                    missingColArray.push(variable);
                                    mandErr = true;
                                }
                            }
                        }
                    }
                    function varifyFields() {
                        for (var variable in unwantedField) {
                            if (unwantedField.hasOwnProperty(variable)) {
                                if (arr[unwantedField[variable]] == "") {
                                    invalidColArray.push(variable);
                                    unwantedFieldErr = true;
                                }
                            }
                        }
                    }

                    switch (typeOfQuestion) {
                        case "fillInTheBlanks":
                            varifyCommonField();
                            varifyAnswer();
                            varifyFields();
                            break;

                        case "multipleChoice":
                            varifyCommonField();
                            varifyOption();
                            varifyAnswer();
                            varifyFields();
                            break;
                        case "multipleResponse":
                            varifyCommonField();
                            varifyOption();
                            varifyAnswer();
                            varifyFields();
                            break;
                        case "singleDigit":
                            varifyCommonField();
                            varifyAnswer();
                            break;
                        case "trueFalse":
                            varifyCommonField();
                            varifyAnswer();
                            varifyFields();
                            break;
                        case "numerical":
                            varifyCommonField();
                            varifyAnswer();
                            varifyFields();
                            break;
                        case "decimanlType":
                            varifyCommonField();
                            varifyAnswer();
                            break;
                        default:

                    }

                }

                if (!isBlank) { }
                checkForMandatoryColumns(cellArray);

                /* Validate answer Fields */
                if (typeOfQuestion == 'numerical') {
                    function IsNumeric(data) {
                        return parseFloat(data) == data;
                    }
                    let answer = cellArray[verifiedFieldAnwser["Answer"]].trim()
                    if (!IsNumeric(Number(answer))) invalidDataColumn = 'Answer'
                }

                if (isBlank) {
                    errorObj.blankRows++;
                } else if (mandErr) {
                    var errString = " Question With Sr No : <b>" + cellArray[compulsoryField["Sr No."]].trim() + "</b> does not have data in following columns : ";
                    errString = errString + '<b>' + missingColArray.join(',') + '</b>';
                    errorObj.invalidRows.push(errString);
                } else if (invalidDataColumn) {
                    var errString = " Question With Sr No : <b>" + cellArray[compulsoryField["Sr No."]].trim() + `</b> contains invalid data in <b>${invalidDataColumn}</b> column `;
                    errorObj.invalidRows.push(errString);
                } else if (unwantedFieldErr) {
                    var errString = " These are unwanted columns for the Question type you selected please download the template for specific question type and upload accordingly. : ";
                    errString = errString + '<b>' + invalidColArray.join(',') + '</b>';
                    errorObj.invalidCols.push(errString);
                } else {

                    function getAnswer(qType) {
                        var answer

                        switch (qType) {

                            case 'multipleChoice':
                                answer = cellArray[verifiedFieldAnwser["Answer"]].trim().toLowerCase().split(',')[0]
                                break;

                            case 'multipleResponse':
                                answer = cellArray[verifiedFieldAnwser["Answer"]].trim().toLowerCase().split(',')
                                break;
                            case 'numerical':
                                answer = cellArray[verifiedFieldAnwser["Answer"]].trim().toString()
                                break;

                        }

                        return answer
                    }



                    var rootLevelId = "";
                    var firstLevelId = "";

                    var secondLevelId = "";
                    var thirdLevelId = "";
                    var categorieArr = cellArray[compulsoryField["Tags"]].trim().split(',');
                    var sourcesArr = cellArray[notMandatoryFields["Sources"]] ? cellArray[notMandatoryFields["Sources"]].trim().split(',') : []
                    var aQuestion = {
                        languages: [],
                        data: {
                            question: {},
                            explanation: {},

                        },
                        additionalInfo: {},
                        tags: [],
                        sources: [],
                        link: [],
                    }
                    var aLang = questionsLang;
                    var sData = {
                        title: aLang,
                        default: true
                    };
                    aQuestion.languages.push(sData)
                    aQuestion.qType = typeOfQuestion
                    aQuestion.sequence = cellArray[compulsoryField["Sr No."]].trim();
                    aQuestion.data.question[aLang] = cellArray[compulsoryField["Question"]].trim();
                    aQuestion.parentId = linkArrFull[0][linkArrFull[0].length - 1].id;
                    let explanationStr = cellArray[notMandatoryFields["Explanations"]] ? cellArray[notMandatoryFields["Explanations"]].toString().trim() : ''
                    explanationStr = explanationStr.toLowerCase() != 'na' ? explanationStr : ''
                    aQuestion.data.explanation[aLang] = explanationStr
                    aQuestion.data.answer = getAnswer(questionsType)
                    aQuestion.data.attachEssay = {}
                    aQuestion.data.isEssayAttached = []
                    aQuestion.data.optionCount = Object.keys(verifiedFieldOption).length

                    if (questionsType == 'multipleChoice' || questionsType == 'multipleResponse') {
                        for (const aOption in verifiedFieldOption) {
                            let _option = aOption ? aOption.trim().toLowerCase() : ''
                            let docOptionStr = cellArray[verifiedFieldOption[aOption]] ? cellArray[verifiedFieldOption[aOption]].toString().trim() : ''
                            if (!docOptionStr || docOptionStr == '' || docOptionStr.toLowerCase() == 'na')
                                continue;

                            let langObj = { [aLang]: docOptionStr }

                            switch (_option) {
                                case 'option1':
                                case 'option a':
                                    aQuestion.data.option_a = langObj
                                    break;
                                case 'option2':
                                case 'option b':
                                    aQuestion.data.option_b = langObj
                                    break;
                                case 'option3':
                                case 'option c':
                                    aQuestion.data.option_c = langObj
                                    break;
                                case 'option4':
                                case 'option d':
                                    aQuestion.data.option_d = langObj
                                    break;
                                case 'option5':
                                case 'option e':
                                    aQuestion.data.option_e = langObj
                                    break;

                                default:
                                    break;
                            }
                        }
                    }


                    aQuestion.isPracticeQuestion = false

                    aQuestion.additionalInfo.level = level;
                    if (cellArray[compulsoryField["Positive Marks"]]) {
                        aQuestion.additionalInfo.pmarks = Number(cellArray[compulsoryField["Positive Marks"]].trim())

                    } else {
                        aQuestion.additionalInfo.pmarks = 1
                    }
                    if (cellArray[compulsoryField["Negative Marks"]]) {
                        aQuestion.additionalInfo.nmarks = Number(cellArray[compulsoryField["Negative Marks"]].trim())

                    } else {
                        aQuestion.additionalInfo.nmarks = 0
                    }

                    aQuestion.link = linkArrFull
                    aQuestion.tags = categorieArr
                    aQuestion.sources = sourcesArr ? sourcesArr : []
                    questionsValidatedArr.push(aQuestion)
                }



            }

            if (errorObj.invalidRows.length != 0) {
                var errorString = "";
                if (errorObj.blankRows != 0) {
                    errorString = errorString + "<br/>Uploaded file contains " + errorObj.blankRows + " blank Rows <br/>";
                }
                if (errorObj.invalidRows != []) {
                    errorString = errorString + " Invalid Rows Report :- <br/><br/>";
                    errorString = errorString + '' + errorObj.invalidRows.join('<br/>');
                }
                if (errorObj.invalidCols != []) {
                    errorString = errorString + " Invalid Columns Report :- <br/><br/>";
                    errorString = errorString + '' + errorObj.invalidCols.join('<br/>');
                }
                var questionStatus = "File contains <b>" + errorObj.blankRows + "</b> blank Rows, <b>" + errorObj.invalidRows.length + "</b> invalid rows and <b>" + questionsValidatedArr.length + "</b> valid Rows +" + "and " + invalidColArray.length + "</b> invalid columns and <b>" + "<br/><br/>";
                _$('#error-desc').html(questionStatus + errorString);
                _$(elemQuestionUploadErrorModal).modal('show');
            } else {
                return questionsValidatedArr
            }


        },
        renderQuestions: async function (result) {

            if (!Boolean(questionsType)) return pError('Error!', 'Please Select Question Type.')
            if (!Boolean(questionsLang)) return pError('Error!', 'Please Select Question Language.')
            var dataArray = questionsUploadUtils.getQuestionListFromString(result);
            questionsDataArray = dataArray
            questionsDataArray = questionsDataArray
            await tick()
            questionsUploadUtils.renderQuestionsTable(questionsDataArray)
        },
        renderQuestionsTable: function (dataArr) {

            bindQuestionsDatatable(dataArr)
        }
    }

    function showPreviewQuestionsModal(show = true) {
        show ? _$(elemPreviewQuestionsModal).modal('show') : _$(elemPreviewQuestionsModal).modal('hide')
    }

    let onCloseModal = async function (event) {

        _$('.modal-backdrop').remove()
        showModal = !showModal;

        if (!event.detail.selectedFolder[0]) return;
        linkArrFull.push(event.detail.selectedFolder);

        breadCrumb = event.detail.selectedFolder;

        let batchName = event.detail.selectedFolder[0].name;
        let fullPath = []

        for (let i = 1; i < event.detail.selectedFolder.length; i++) {
            const aFolder = event.detail.selectedFolder[i];
            fullPath.push(aFolder.name)
        }

        linkArr = []

        linkArr.push({
            batchName: batchName,
            fullPath: fullPath
        });
        linkArr = linkArr;

        await tick();
        feather.replace({ width: "1em", height: "1em" });
    }

    let initSelectizeQuestionType = async function (typeSelected) {
        // let questionTypes = [
        //     { 
        //         name: 'Multiple Choice',
        //         value: 'multipleChoice'
        //      },
        //      { 
        //         name: 'Multiple Response',
        //         value: 'multipleResponse'
        //      },
        //      { 
        //         name: 'Number Type',
        //         value: 'numerical'
        //      },
        //      { 
        //         name: 'True False',
        //         value: 'trueFalse'
        //      },
        //      { 
        //         name: 'Fill in the Blanks',
        //         value: 'fillInTheBlanks'
        //      },
        //      { 
        //         name: 'Match the Following',
        //         value: 'matchTheFollowing'
        //      },
        //      { 
        //         name: 'Match Matrix',
        //         value: 'matchMatrix'
        //      }
        // ]

        let questionTypes = [
            {
                name: 'Multiple Choice',
                value: 'multipleChoice'
            },
            {
                name: 'Multiple Response',
                value: 'multipleResponse'
            },
            {
                name: 'Integer (Numerical)',
                value: 'numerical'
            }
            //  ,
            // {
            //     name: 'Numerical',
            //     value: 'numerical'
            // }

        ]

        selectizeInstanceQuestionType = _$(elemQuestionType).selectize({
            maxItems: 1,
            valueField: 'value',
            labelField: 'name',
            searchField: 'name',
            options: questionTypes,
            create: false,
            items: typeSelected ? [typeSelected] : [],
            onChange: async function (value) {
                questionsType = value
                await tick()
                feather.replace({ width: "1em", height: "1em" });
            }
        });

    }
    let initSelectizeQuestionLang = async function (typeSelected) {

        let questionsType = [
            {
                name: 'English',
                value: 'English'
            },
            {
                name: 'Hindi',
                value: 'Hindi'
            },
        ]

        selectizeInstanceQuestionLang = _$(elemQuestionLang).selectize({
            maxItems: 1,
            valueField: 'value',
            labelField: 'name',
            searchField: 'name',
            options: questionsType,
            create: false,
            items: typeSelected ? [typeSelected] : [],
            onChange: async function (value) {
                questionsLang = value
                await tick()
                feather.replace({ width: "1em", height: "1em" });
            }
        });
        // selectizeInstanceQuestionLang[0].selectize.setValue(typeSelected)
    }
    let btnSelectFile = async () => {
        if (!questionsType) return pAlert("Question Type", "Please Select Question Type")
        if (!questionsLang) return pAlert("Question Language", "Please Select Question Language")

        _$(elem_uploadFile).val('')
        await tick()

        _$(elem_uploadFile).click()

    }
    function bindQuestionsDatatable(questionsListArr = [], isUpdate = false) {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        var _bind = {
            previewQuestionBtn: function (data) {
                _$('.preview-question').unbind().click(function () {
                    var srNo = _$(this).attr('id');
                    var aLang = questionsLang;
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                    for (var i = 0; i < data.length; i++) {
                        var aData = data[i]
                        if (srNo == aData.sequence) {
                            _$('.question-number-holder').html("Question No." + " " + aData.sequence);
                            _$('.question-data-holder').html(aData.data[aLang].question);
                            _$('.option-1').html(aData.data[aLang].option1);
                            _$('.option-2').html(aData.data[aLang].option2);
                            _$('.option-3').html(aData.data[aLang].option3);
                            _$('.option-4').html(aData.data[aLang].option4);
                            if (aData.data[aLang].option5 != "" && aData.data[aLang].option5) {
                                _$('.option5-div').show();
                                _$('.option-5').html(aData.data[aLang].option5);
                            } else {
                                _$('.option5-div').hide();
                            }
                            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                            if (aData.data[aLang].answer.toUpperCase().trim() == 'A') {
                                _$('.option-1').parent().parent().parent().css({ 'position': 'relative', 'background-color': 'rgb(183, 249, 186)', 'border': '1px solid rgb(76, 175, 80)' })
                                _$('.op1').slideDown();
                                _$('.op1').html('Answer');

                            }
                            if (aData.data[aLang].answer.toUpperCase().trim() == 'B') {
                                _$('.option-2').parent().parent().parent().css({ 'position': 'relative', 'background-color': 'rgb(183, 249, 186)', 'border': '1px solid rgb(76, 175, 80)' })
                                _$('.op2').slideDown();
                                _$('.op2').html('Answer');
                            }
                            if (aData.data[aLang].answer.toUpperCase().trim() == 'C') {
                                _$('.option-3').parent().parent().parent().css({ 'position': 'relative', 'background-color': 'rgb(183, 249, 186)', 'border': '1px solid rgb(76, 175, 80)' })
                                _$('.op3').slideDown();
                                _$('.op3').html('Answer');
                            }
                            if (aData.data[aLang].answer.toUpperCase().trim() == 'D') {
                                _$('.option-4').parent().parent().parent().css({ 'position': 'relative', 'background-color': 'rgb(183, 249, 186)', 'border': '1px solid rgb(76, 175, 80)' })
                                _$('.op4').slideDown();
                                _$('.op4').html('Answer');
                            }
                            if (aData.data[aLang].answer.toUpperCase().trim() == 'E') {
                                _$('.option-5').parent().parent().parent().css({ 'position': 'relative', 'background-color': 'rgb(183, 249, 186)', 'border': '1px solid rgb(76, 175, 80)' })
                                _$('.op5').slideDown();
                                _$('.op5').html('Answer');
                            }
                            _$('.question-hints-holder').html(aData.data[aLang].explanation);
                            showPreviewQuestionsModal(true)
                        }
                    }
                })
            },
            deleteBtn: function (data) { },
            buttons: function (data) { }
        }

        if (isUpdate && questionsTableDTInstance) {
            questionsTableDTInstance.clear();
            questionsTableDTInstance.rows.add(questionsListArr);
            questionsTableDTInstance.draw();
            return;
        }

        if (questionsTableDTInstance && questionsTableDTInstance.destroy) {
            questionsTableDTInstance.destroy();
        }
        var aLang = questionsLang ? questionsLang : 'English';


        questionsTableDTInstance = _$(elemQuestionsTable).DataTable({
            data: questionsListArr,
            "stateSaveCallback": function (settings, data) {
                localStorage.setItem(window.location.pathname, JSON.stringify(data))
            },
            "stateLoadCallback": function (settings) {
                return JSON.parse(localStorage.getItem(window.location.pathname))
            },
            "iDisplayLength": 100,
            columns: [
                {
                    data: 'sequence',


                },

                {
                    data: 'data',
                    render: function (data, type, full, meta) {
                        return data.question[aLang]
                    }
                },
                {
                    data: 'data',
                    render: function (data, type, full, meta) {
                        return data.answer
                    }
                },
                {
                    data: 'additionalInfo',
                    render: function (data, type, full, meta) {
                        return (typeof data.level == 'string') ? data.level.toUpperCase() : data.level
                    }
                },

                {
                    data: "sequence",
                    render: function (data, type, full, meta) {
                        var view = "-";
                        var archive = "";
                        view = `<a href="#" class="rename mg-r-5 btn-edit-question" question-sequence="${data}" question-data="${full}" title="Edit">
                                <i class="wd-20 mg-r-5" data-feather="edit"></i>
                            </a>`;
                        archive = `<a href="#" class="rename mg-r-5 btn-delete-question" data-sequence="${data}" title="Delete">
                                <i class="wd-20 mg-r-5" data-feather="trash"></i>
                            </a>`

                        return `
                            <div class="d-md-flex flex-row justify-content-center">
                                ${view}
                                ${archive}
                            </div>
                        `;
                    },
                },
            ],
            "columnDefs": [

            ],
            "language": {
                "loadingRecords": "Please wait - loading..."
            },
            "drawCallback": function () {
                feather.replace({ width: "1em", height: "1em" });
                _bind.deleteBtn();
                MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                _bind.previewQuestionBtn(questionsListArr);
                _bind.buttons();
                _$(".btn-edit-question")
                    .unbind("click")
                    .click(function (e) {
                        e.preventDefault();

                        var questionSequence = _$(this).attr("question-sequence");

                        let questionData = questionsListArr.find(item => item.sequence == questionSequence)

                        onClickEditQuestion(questionSequence, questionData)
                        // navigate("/dashboard/questions/view/" + questionId);
                        // MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                        return false;
                    });
                _$('.btn-delete-question').unbind('click').click(function (e) {
                    var questionSequence = _$(this).attr("data-sequence");
                    let index = questionsListArr.findIndex(item => item.sequence == questionSequence)

                    onClickDeleteQUestion(index)

                    // proceedRemoveQuestion(questionId)
                })
            },
            "processing": false,
            "oLanguage": {
                "sStripClasses": "",
                "sSearch": "",
                "sEmptyTable": "Upload a file first",
                "sSearchPlaceholder": "Question",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_",
            },
            bAutoWidth: false
        });
        _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });



    }
    async function onClickDeleteQUestion(index) {
        pConfirm("Warning!", "Are you sure you want to delete this question, this action will delete question permanently", async function () {
            questionsDataArray.splice(index, 1)
            pSuccess("Success", "Question Deleted Successfully")
            for (var i = 0; i < questionsDataArray.length; i++) {
                questionsDataArray[i].sequence = i + 1
            }
            questionsDataArray = questionsDataArray
            await tick()
            bindQuestionsDatatable(questionsDataArray)

        })

    }
    function redrawQuestionsTable() {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        if (questionsTableDTInstance) questionsTableDTInstance.draw();
    }

    async function onClickEditQuestion(questionSequence, questionData) {
        isEditQuestion = true
        selectedQuestionData = questionData
        selectedQuestionSequence = questionSequence
        // hide here
    }
    let handleFileSelect = (event) => {

        if (Boolean(questionsType)) {
            // console.log("file type-:::::::::::::",event.target.files[0])
            var file = event.target.files[0];
            // console.log("File---",file)

            var reader = new FileReader();
            reader.onload = function (loadEvent) {
                // console.log("loadEvent;;;;;",loadEvent)
                var arrayBuffer = loadEvent.target.result;
                importLoading = false
                let fileExtension = event.target.files[0].name.split('.');
                // console.log("arrayBuffer-:::::::::::::",arrayBuffer)

                if (fileExtension[fileExtension.length - 1] == 'doc' || fileExtension[fileExtension.length - 1] == 'docx') {
                    // console.log("fileExtension",fileExtension)
                    mammoth.convertToHtml({ arrayBuffer: arrayBuffer }).then(questionsUploadUtils.renderQuestions).done();

                } else {
                    pError('error', 'Please select a doc file.');
                }
            };

            try {
                importLoading = true
                reader.readAsArrayBuffer(file);
            } catch (error) {
                console.log("error:::", error)
                importLoading = false
            }

        } else {
            pError('Validation Error!', 'Please select question type.')
        }

    }
    let bulkStartUploadQuestionsBtn = async () => {

        if (breadCrumb.length == 0) return pAlert("Warning", "Select the location in Question Bank for upload")
        if (questionsDataArray.length == 0) return pAlert("Warning", "Upload File First")
        if (isLoading) return;
        isLoading = true;
        await tick()

        let isUploaded = await api_uploadQuestions(questionsDataArray)
        if (isUploaded) {
            pSuccess("Success!", isUploaded.message);

            if (onQuestionsUploadDone) onQuestionsUploadDone(isUploaded.insertedIds)
            else resetQuestionsUpload();
        }
        isLoading = false
    }
    let btnSampleTempleteDownload = () => {

        // Temp Check dont have all templete
        _$(elemDownloadQtemplete).click()
    }

    async function api_uploadQuestions(qArr) {
        var resData = await api_handleApiCalls('questions.import', { questionsArr: qArr })
        if (resData.status) {
            return resData.data
        } else {

            pError("Error!", resData.errMsg)
            return null
        }
    }
    function initSelectizes() {
        initSelectizeQuestionType()
        initSelectizeQuestionLang()
    }
    onMount(async () => {
        activeMenu.set('questionsUpload')
        feather.replace({ width: "1em", height: "1em" });
        bindQuestionsDatatable()
        initSelectizes()
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    })

    async function onUpdate() {

        isEditQuestion = false

        let index = questionsDataArray.findIndex(item => item.sequence == selectedQuestionData.sequence)
        questionsDataArray[index] = selectedQuestionData// is this correct,yes
        questionsDataArray = questionsDataArray
        await tick()

        bindQuestionsDatatable(questionsDataArray)
        initSelectizeQuestionLang(questionsLang)
        initSelectizeQuestionType(questionsType)

    }
</script>


<!-- Modal -->
<div class="modal effect-scale" bind:this="{elemQuestionUploadErrorModal}" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
            <div class="modal-body pd-20 pd-sm-35">
                <a href="" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>

                <span class="tx-color-04"><i data-feather="help-circle"
                        class="wd-40 ht-40 stroke-wd-3 mg-b-20"></i></span>
                <h3 class=" mg-b-15 mg-md-b-20">Upload File</h3>
                <p class="tx-color-03">Please check uploaded file</p>

                <div class="tx-color-02" id="error-desc">


                </div>

                <div class="d-md-flex justify-content-end tx-13 mg-t-30">
                    <button type="button" class="btn btn-sm btn-white tx-13 mg-md-r-40"
                        data-dismiss="modal">Cancel</button>


                </div>

            </div>

        </div>
    </div>
</div>
<!-- Modal -->

{#if isEditQuestion}
<AddUpdateQuestion selectedQuestionSequence={selectedQuestionSequence} selectedQuestionData={selectedQuestionData}
    on:update={onUpdate} />
{:else}
<div class="content-body fadeIn">
    {#if !hideBreadcrumbs}
    <div class="d-sm-flex align-items-center justify-content-between mg-b-25">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Questions Upload</li>

                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                Questions Upload
            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">Bulk Upload questions</p>

        </div>
        <div class="btn-group">
            <button class="btn btn-secondary btn-sm pd-x-15 dropdown-toggle" type="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                Download Template
            </button>
            <ul class="dropdown-menu filterByDropdown">
                <a href="/questions-import/multipleChoice.docx" class="dropdown-item" qType="T">Multiple Choice</a>
                <a href="/questions-import/multipleResponse.docx" class="dropdown-item" qType="Y">Multiple Response</a>
                <a href="/questions-import/numericalTypeQuestions.docx" class="dropdown-item" qType="N">Integer
                    (Numerical)</a>
                <!-- <a href="/questions-import/numerical.docx" class="dropdown-item" qType="LD7">Number Type</a> -->
                <a href="#" disabled class="dropdown-item disabled" qType="LD30">Fill in the Blanks</a>
                <a href="#" disabled class="dropdown-item disabled" qType="TM">Match the Following</a>
                <a href="#" disabled class="dropdown-item disabled" qType="LM">Match Matrix</a>
            </ul>
        </div>

    </div>
    <hr class="full-width">

    {/if}
    <div class="row">
        <div class="col" style="    display: flex;align-items: center;">

            {#if breadCrumb.length > 0}
            <button type="button" class="btn btn-xs btn-primary btn-icon mg-r-20" on:click={()=>{breadCrumb=[];}}>
                <i data-feather="x"></i>
            </button>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                    <li class="breadcrumb-item"><a href="#">Folders </a></li>
                    {#each breadCrumb as item (item.id)}
                    <li class="breadcrumb-item"><a href='#'>{item.name}</a>
                    </li>
                    {/each}
                </ol>
            </nav>
            {:else}
            {#if MODULES.hasAccess(MODULES.SELECT_QUESTIONS_LOCATION)}
            <button on:click={()=> showModal = !showModal}
                class="btn btn-xs btn-primary btn-uppercase animated fadeIn link-btn">
                <i data-feather="link" class="wd-10 mg-r-5"></i> Select Location in Question Bank
            </button>
            {/if}
            {/if}

        </div>
    </div>
    <hr class="full-width" style="margin-top: 20px;">
    <!-- <hr class="mg-y-20"> -->
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div class="row row-sm mg-b-20">
            <div class="col-sm-12">
                <input type="file" bind:this={elem_uploadFile} on:change={handleFileSelect} accept=".doc, .docx"
                    class="d-none">
                <div class="d-flex flex-row align-items-center flex-wrap">


                    <select bind:this={elemQuestionType} class="info slectized form-control wd-200 mg-r-5 mg-md-b-5"
                        placeholder="Select Question Type">
                        <option value="">Select Question Type</option>
                    </select>

                    {#if hideBreadcrumbs}
                    {#if Boolean(questionsType)}
                    {#if MODULES.hasAccess(MODULES.DOWNLOAD_QUESTIONS_TEMPLATE)}
                    <a class="d-block tx-white btn btn-secondary btn-sm pd-x-15 link-btn mg-md-b-5  mg-r-5"
                        href="/questions-import/{questionsType == " numerical"? "numericalTypeQuestions"
                        :questionsType}.docx" download>
                        <i data-feather="download" class="mg-r-5"></i> Download Template
                    </a>
                    {/if}
                    {:else}
                    {#if MODULES.hasAccess(MODULES.DOWNLOAD_QUESTIONS_TEMPLATE)}
                    <button disabled
                        class="d-block tx-white btn btn-secondary btn-sm pd-x-15 link-btn mg-md-b-5 mg-r-5">
                        <i data-feather="download" class="mg-r-5"></i> Download Template
                    </button>
                    {/if}
                    {/if}
                    {/if}

                    <select bind:this={elemQuestionLang} class="info slectized form-control wd-150 mg-r-5 mg-md-b-5"
                        placeholder="Select Language">
                        <option value="">Select Language</option>
                    </select>

                    {#if breadCrumb.length > 0}
                    <button on:click={btnSelectFile}
                        class="btn btn-sm pd-x-15 btn-secondary btn-uppercase animated mg-r-5 mg-md-b-5 fadeIn link-btn">
                        <i data-feather="file-text" class="wd-10 mg-r-5"></i>
                        <ButtonLoader isLoading={importLoading} btnText={importLoading ? 'Processing...' : 'Select File'
                            } />
                    </button>
                    {/if}

                    {#if MODULES.hasAccess(MODULES.UPLOAD_QUESTIONS_TEMPLATE)}
                    <button on:click={bulkStartUploadQuestionsBtn} disabled={isLoading}
                        class="mg-l-auto btn btn-sm pd-x-15 btn-primary btn-uppercase animated mg-r-5 mg-md-b-5 fadeIn link-btn">
                        <i data-feather="upload" class="wd-10 mg-r-5"></i>
                        <ButtonLoader isLoading={isLoading} btnText='Start Upload' />
                    </button>
                    {/if}
                </div>

            </div>
        </div>

    </div>

    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div data-label="Example" class="df-example animated fadeIn">
            <table bind:this={elemQuestionsTable} class="table table-striped" style="width: 100%;">
                <thead>
                    <tr>
                        <th class="wd-10p">Sr No</th>
                        <th class="wd-45p">Question</th>
                        <th class="wd-20p">Answer</th>
                        <th class="wd-10p">Level</th>
                        <th class="wd-10p">Actions</th>
                    </tr>
                </thead>
                <tbody></tbody>

            </table>

        </div>
    </div>

</div>
{/if}
{#if showModal}
<FolderModal on:close={onCloseModal} />
{/if}
