<script>
    import { Router, Link, link, links, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    export let questionId;
    export let newQuestion = false;
    import FolderModal from "../folderCmp/folderModal.svelte";
    import MultipleChoiceCmp from "./questionTypes/multipleChoice.svelte";
    import MultipleResponseCmp from "./questionTypes/multipleResponse.svelte";
    import NumericalCmp from "./questionTypes/numerical.svelte";
    import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
    import ButtonLoader from "../utils/button_loader.svelte";
    import QuestionsReportedCmp from "../questionsReportedCmp/index.svelte";
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import { API_URL } from "../../config.js"
    let isLoading = false;
    export let qType;
    export let parentId;
    let questionTypeObj;
    let reportModalId = 0;
    import CatSelect from "./categorySelect.svelte";
    let showCatSelect = false;
    let tempIsPracticeQuestion = [];
    let breadCrumb = [];
    let questionData = {
        averageResponseTime: '',
        analyticalCategoryIds: [],
        languages: [],
        qId: "",
        qType: "",
        data: {
            question: {},
            explanation: {},
            answer: "",
            attachEssay: {},
            isEssayAttached: [],
            optionCount: 4
        },
        isPracticeQuestion: false,
        additionalInfo: {
            pmarks: 1,
            nmarks: 0,
            level: 'mid'
        }
    };
    let linkArr = [];
    let linkArrFull = [];

    let modalCmp;
    let qIdSearch = ''
    let errorObj = {};
    let languageName;

    let elem_analyticalCategoryPicker;

    let selectedLang = "English"

    let showModal;

    let onCloseModal = async function (event) {
        _$('.modal-backdrop').remove()
        showModal = !showModal;

        if (!event.detail.selectedFolder[0]) return;
        linkArrFull.push(event.detail.selectedFolder);

        let batchName = event.detail.selectedFolder[0].name;
        let fullPath = []
        for (let i = 1; i < event.detail.selectedFolder.length; i++) {
            const aFolder = event.detail.selectedFolder[i];
            fullPath.push(aFolder.name)
        }
        linkArr.push({
            batchName: batchName,
            fullPath: fullPath
        });
        linkArr = linkArr;
        await tick();
        feather.replace()
    }
    let toBeSavedQuestionObj = {};

    async function api_getPathTillRootByNodeId(data) {

        let action = "folder.getPathTillRootByNodeId";
        var response = await api_handleApiCalls(action, data);
        return response

    }

    let createNewLanguage = async function () {
        if (isLoading) return;
        isLoading = true;
        await tick()
        delete errorObj.languageName;
        if (!languageName || languageName.trim().length == 0) {
            errorObj.languageName = "Language name cannot blank.";
        }
        if (languageName == "English") {
            errorObj.languageName = "Language name cannot be 'English'.";
        }
        for (let i = 0; i < questionData.languages.length; i++) {
            const aLanguage = questionData.languages[i];
            if (aLanguage.title == languageName) {
                errorObj.languageName = "Language with name '" + languageName + "' already exist.";
            }
        }

        if (!errorObj.languageName) {

            questionData.languages.push({
                title: languageName,
                questions: []
            })

            questionData = { ...questionData };

            await tick();
            selectedLang == languageName;

            _$(modalCmp).modal('hide');
            _$('.modal-backdrop').remove()
            languageName = "";
            isLoading = false;
            return false;
        }
        isLoading = false;
        errorObj = { ...errorObj };
    }


    async function api_updateQuestion(data) {

        let action = "questions.update";
        var response = await api_handleApiCalls(action, data);
        return response

    }


    async function api_addQuestion(data) {

        let action = "questions.add";
        var response = await api_handleApiCalls(action, data);
        return response

    }

    async function api_fetchAnalyticalCategoryList(data) {

        let action = "analyticalCategory.fetchAnalyticalCategoryList";
        var response = await api_handleApiCalls(action, data);
        return response

    }


    var _core = {
        api_getQuestionById: async function (data) {

            let action = "questions.getQuestionById";
            var response = await api_handleApiCalls(action, data);
            return response.data

        },
        getTagsData: function (cb) {
            _$.post({
                url: API_URL,
                data: {
                    accessToken: localStorage.getItem("accessToken"),
                    action: 'questions.getAllTags'
                },
                success: function (resData) {
                    cb(resData);
                },
                error: function (err) {
                    //myToast('error', 'Get Question', 'Server Error');
                }
            });
        },
        getSourcesData: function (cb) {
            _$.post({
                url: API_URL,
                data: {
                    accessToken: localStorage.getItem("accessToken"),
                    action: 'questions.getAllSources'
                },
                success: function (resData) {
                    cb(resData);
                },
                error: function (err) {
                    //myToast('error', 'Get Question', 'Server Error');
                }
            });
        },

    }



    var _render = {
        generateTags: function (perSelectedTags) {
            _core.getTagsData(function (res) {
                if (res.status) {
                    // _$('.chips-tag').attr('value',res.data.tags.join(','));
                    var tagsOptions = []
                    if (res.data && Array.isArray(res.data.tags)) {
                        res.data.tags.map((tag, i) => tagsOptions.push({ value: res.data.tags[i], text: res.data.tags[i] }))
                    }

                    if (Array.isArray(perSelectedTags)) {
                        perSelectedTags.map((tag) => tagsOptions.push({ value: tag, text: tag }))
                    }

                    var tags = _$('.chips-tag').selectize({
                        delimiter: ',',
                        plugins: ['remove_button'],
                        persist: false,
                        options: tagsOptions,
                        items: perSelectedTags || [],
                        render: {
                            option_create: function (data, escape) {
                                return '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New tag : </i><strong>' + escape(data.input) + '</strong></div>';
                            }
                        },
                        create: function (input) {
                            return { value: input.trim(), text: input }
                        },
                        onChange: function (value) {
                            console.log("onchange tag : ", value)
                        }
                    });



                }

            })
        },
        generateSources: function (perSelectedSources) {
            _core.getSourcesData(function (res) {
                if (res.status) {
                    // _$('.chips-tag').attr('value',res.data.tags.join(','));
                    var sourcesOptions = []
                    if (res.data && Array.isArray(res.data.sources)) {
                        res.data.sources.map((source, i) => sourcesOptions.push({ value: res.data.sources[i], text: res.data.sources[i] }))
                    }

                    if (Array.isArray(perSelectedSources)) {
                        perSelectedSources.map((source) => sourcesOptions.push({ value: source, text: source }))
                    }

                    var sources = _$('.chips-source').selectize({
                        delimiter: ',',
                        plugins: ['remove_button'],
                        persist: false,
                        options: sourcesOptions,
                        items: perSelectedSources || [],
                        render: {
                            option_create: function (data, escape) {
                                return '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New source : </i><strong>' + escape(data.input) + '</strong></div>';
                            }
                        },
                        create: function (input) {
                            return { value: input.trim(), text: input }
                        },
                        onChange: function (value) {
                            console.log("onchange source : ", value)
                        }
                    });



                }

            })
        },
        analyticalCategorySelections: async function (selectedCategories = []) {


            let resData = await api_fetchAnalyticalCategoryList({
                search: { value: '' },
            });
            let allOptionsFirstTimeArr = resData.data.data

            _$(elem_analyticalCategoryPicker).selectize({
                maxItems: null,
                delimiter: ",",
                plugins: ["remove_button"],
                persist: false,
                create: false,
                valueField: "_id",
                labelField: "name",
                searchField: "name",
                options: allOptionsFirstTimeArr || [],
                items: selectedCategories,
                preload: true,
                render: {
                    option: function (item, escape) {
                        let selected = questionData.analyticalCategoryIds ? questionData.analyticalCategoryIds.includes(item._id) ? "selected" : "" : ""
                        //   if (item._id == "new") {
                        //     return `<div class="option text-capitalize" data-value="${item._id}"> <a href="javascript:void(0)" style="width: 100%;" class="btn btn-secondary">${item.name}</a> </div>`;
                        //   } else {
                        return `<div class="option  text-capitalize ${selected}" 
                        data-selectable="" data-value="${item._id}">${item.name}</div>`;
                        //   }
                    },
                },
                load: async function (query, callback) {
                    let analyticalCategoriesData = await api_fetchAnalyticalCategoryList({
                        search: { value: query },
                    });
                    if (analyticalCategoriesData.status) {
                        let selectizeData = analyticalCategoriesData.data.data
                        callback(selectizeData);
                    } else {
                        callback([]);
                    }
                },
                onChange: function (value) {
                    questionData.analyticalCategoryIds = value;
                },
            });
        }
    }

    function validateQuestion(finalObj) {
        const {
            averageResponseTime,
            additionalInfo,
            data,
            qType,
            languages
        } = finalObj
        if (typeof additionalInfo != "object") { pError("!Validation error", "Positive marks cant be less than 1"); return null }
        if (!additionalInfo.pmarks || additionalInfo.pmarks < 1) { pError("!Validation error", "Positive marks cant be less than 1 or empty"); return null }
        if (additionalInfo.nmarks === null || additionalInfo.nmarks === undefined || additionalInfo.nmarks < 0) { pError("!Validation error", "Negative marks cant be negative number or empty"); return null }


        if (qType == 'multipleChoice') {
            let qOptionsArr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h']
            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
            for (let lngIdx = 0; lngIdx < languages.length; lngIdx++) {
                const aLang = languages[lngIdx].title;

                if (!data.question[aLang]) { pError("!Validation error", "In the " + aLang + " language question found empty, please fill out question data"); selectedLang = aLang; return null }

                for (let idx = 0; idx < data.optionCount; idx++) {
                    const qSeq = qOptionsArr[idx];
                    if (!data['option_' + qSeq] || !data['option_' + qSeq][aLang]) {
                        pError("!Validation error", "In the " + aLang + " language Option (" + qSeq.toUpperCase() + ") found empty, please fill out data or you can remove option");
                        selectedLang = aLang;
                        return
                    }
                }

            }



        }
        if (data && !data.answer) { pError("!Validation error", "Please select answer from options"); return null }
        if (averageResponseTime && (!averageResponseTime || averageResponseTime < 1)) { pError("!Validation error", "Average Response Time should be at least 1 second"); return null }

        return finalObj
    }

    let saveQuestion = async function (e) {
        let finalObj = questionData;
        finalObj.tags = _$('.chips-tag').val().split(',');
        finalObj.sources = _$('.chips-source').val().split(',')
        finalObj.isPracticeQuestion = (tempIsPracticeQuestion.length > 0 ? true : false),
            finalObj.link = linkArrFull;
        finalObj.parentId = parentId;

        let validatedFinalObj = validateQuestion(finalObj)
        if (isLoading) return;
        isLoading = true;
        await tick()

        if (validatedFinalObj) {
            if (newQuestion) {
                let res = await api_addQuestion(finalObj);
                if (res.status) {
                    pSuccess("", "Question Added successfully");
                    const urlParams = new URLSearchParams(window.location.search);
                    let callback = urlParams.get("callbackUrl")

                    if (callback) navigate(callback)
                    // navigate(("/dashboard/questions/view/" + res.data.questionId))
                }
            } else {
                let res = await api_updateQuestion(finalObj);

                if (res.status) {

                    pSuccess("", "Question Updated successfully");
                    const urlParams = new URLSearchParams(window.location.search);
                    let callback = urlParams.get("callbackUrl")
                    callback = callback.replaceAll("-", "&")



                    if (callback) navigate(callback)
                }
            }

        }
        isLoading = false;


    }

    let onReportClick = function () {

        reportModalId = 1

    }

    // let onCloseModal = function (){

    // reportModalId = 0; 

    // } 

    let saveQuestion111 = async function () {
        // console.log(questionData);

        var langLength = _$('.q-lan-holder .nav-link').length;

        var questionObj = {};
        for (var i = 0; i < langLength; i++) {
            var parentId = _$(_$('.card.question-page')[i]).attr('id');
            let optionData = questionTypeObj.getOptionDataObj(parentId);
            var lanName = _$(_$('.q-lan-holder .nav-link')[i]).attr('lang').toLowerCase();
            var answer = questionTypeObj.getAnswer(parentId);

            questionObj[lanName] = {
                ...optionData,
                ...answer
            };
            questionObj[lanName].explanation = CKEDITOR.instances['explanation_' + lanName].getData();
            questionObj[lanName].question = CKEDITOR.instances['commonQuestion_' + lanName].getData();
        }

        // console.log('questionObj ', questionObj);


        let qObj = {
            _id: questionId,
            data: questionObj,
            isPracticeQuestion: (tempIsPracticeQuestion.length > 0 ? true : false),
            additionalInfo: {
                level: questionData.additionalInfo.level,
                pmarks: questionData.additionalInfo.pmarks,
                nmarks: questionData.additionalInfo.nmarks,
                tags: _$('.chips-tag').val().split(','),
                sources: _$('.chips-source').val().split(",")
            },
            qType: questionData.qType
        }


        let res = await api_updateQuestion(qObj);
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    }


    let createLanguages = function () {
        if (questionData.languages && questionData.languages.length > 0) {

        } else {
            questionData.languages = [{
                title: "English",
                default: true
            }];
        }
        tick();

        setTimeout(function () {
            _$(".manage-language-nav-header .nav-item:first-child").click();
            feather.replace();
        }, 100)
    }

    let openAddSecModal = function () {
        _$(modalCmp).modal('show');
    }

    onMount(async () => {
        createLanguages()
        questionData.qType = qType;
        // const urlParams = new URLSearchParams(window.location.search);
        //             let callback =  urlParams.get("callbackUrl")

        //             let qIdSearch =  urlParams.get("qIdSearch")

        //             if (qIdSearch)    qIdSearch = qIdSearch
        //             else qIdSearch = ''


        feather.replace();
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        if (questionId) {
            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
            var resQuestionData = await _core.api_getQuestionById({ questionId });
            questionData = resQuestionData;
            _render.analyticalCategorySelections(questionData.analyticalCategoryIds)
            _render.generateTags(questionData.tags);
            _render.generateSources(questionData.sources);

            if (questionData.isPracticeQuestion) {
                tempIsPracticeQuestion = ["true"];
            } else {
                tempIsPracticeQuestion = [];
            }
            parentId = questionData.parentId;
            questionData.link = questionData.link ? questionData.link : []
            linkArrFull = [];
            linkArr = [];
            for (let i = 0; i < questionData.link.length; i++) {
                let aLink = questionData.link[i]
                linkArrFull.push(aLink);
                let batchName = aLink[0].name;
                let fullPath = []
                for (let i = 1; i < aLink.length; i++) {
                    const aFolder = aLink[i];
                    fullPath.push(aFolder.name)
                }
                linkArr.push({
                    batchName: batchName,
                    fullPath: fullPath
                });
                linkArr = linkArr;
            }
            await tick();
            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
            feather.replace()
        }

        else {
            _render.analyticalCategorySelections()
            _render.generateTags();
            _render.generateSources();
        }



        new PerfectScrollbar('.contact-list', {
            suppressScrollX: true
        });
        // new PerfectScrollbar('.q-type-cmp', {
        //     suppressScrollX: true
        // });
        let treeRes = await api_getPathTillRootByNodeId({
            nodeId: parentId
        });
        breadCrumb = treeRes.data.fullPath.reverse();
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    })




</script>
<div class="content-body fadeIn"
    style="    overflow: hidden; display: flex; flex-direction: column; padding-bottom: 0;">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-15 mg-lg-b-15">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/questions/questionBank" use:links>Question</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {newQuestion ? "Create" : "#"+questionData.qId}
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                Question {newQuestion ? "Create" : "#"+questionData.qId}
            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">{questionData.qType}</p>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                    <li class="breadcrumb-item"><a href="/dashboard/questions/questionBank/" use:link>Question
                            Bank</a></li>
                    {#each breadCrumb as item (item._id)}
                    <li class="breadcrumb-item"><a href={'/dashboard/questions/questionBank/'+item._id}
                            use:link>{item.name}</a>
                    </li>
                    {/each}
                    <!-- <li class="breadcrumb-item active" aria-current="page">Data</li> -->
                </ol>
            </nav>
        </div>
        <div class="d-block">
            <div class="d-sm-flex">
                <!-- <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={onReportClick}>
                    <i data-feather="alert-circle" class="wd-10 mg-r-5"></i> Report
                </button> -->
                <QuestionsReportedCmp questionId={questionId} btnName="Report" />
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" disabled={isLoading}
                    on:click={saveQuestion}>
                    <i data-feather="file" class="wd-10 mg-r-5"></i>
                    <ButtonLoader isLoading={isLoading} btnText="Save" />
                </button>
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                    <i data-feather="eye" class="wd-10 mg-r-5"></i> Preview
                </button>
            </div>
        </div>
    </div>
    <div class="contact-wrapper contact-wrapper-two"
        style="margin-left: -25px;margin-right: -25px;border-top: 1px solid #e5e8ee; flex: 1; overflow: hidden;">
        <div class="row m-0" style="height:100%">
            <div class="col-md-4 p-0" style="border-right: 1px solid #e5e8ee;background: white; height: 100%;">
                <div class="pd-y-20 pd-x-10 contact-list " style="height: 100%;">

                    <div class="media" style="padding: 0px 20px;padding-top: 10px;">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="pmarksEle">Positive<span class="required-field">*</span></label>
                                <input type="number" min="{1}" bind:value={questionData.additionalInfo.pmarks}
                                    class="form-control" id="pmarksEle" placeholder="+ve Marks">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="nmarksEle">Negative</label>
                                <input type="number" min="{0}" bind:value={questionData.additionalInfo.nmarks}
                                    class="form-control" id="nmarksEle" placeholder="-ve Marks">
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 10px 20px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-4 m-0">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="q-level-easy" bind:group={questionData.additionalInfo.level}
                                        value="easy" name="q-level" class="custom-control-input">
                                    <label class="custom-control-label" for="q-level-easy">Easy</label>
                                </div>
                            </div>
                            <div class="form-group col-md-4 m-0">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="q-level-mid" bind:group={questionData.additionalInfo.level}
                                        value="mid" name="q-level" class="custom-control-input">
                                    <label class="custom-control-label" for="q-level-mid">Medium</label>
                                </div>
                            </div>
                            <div class="form-group col-md-4 m-0">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="q-level-hard" bind:group={questionData.additionalInfo.level}
                                        value="hard" name="q-level" class="custom-control-input">
                                    <label class="custom-control-label" for="q-level-hard">Hard</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 0px 20px;padding-top: 10px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-12">
                                <label for="inputAnalyticalCategory">Analytical Categories </label>
                                <select bind:this={elem_analyticalCategoryPicker} class="form-control"
                                    placeholder="Analytical category" />
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 0px 20px;padding-top: 10px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-12">
                                <label for="avgResponseCatg">Average response time (In seconds)</label>
                                <input type="number" min="{1}" bind:value={questionData.averageResponseTime}
                                    class="form-control" id="avgResponseCatg" placeholder="In seconds">
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 0px 20px;padding-top: 10px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-12">
                                <label for="inputEmail4">Tags</label>
                                <input class="info form-control chips-tag" type="text" data-role="tagsinput"
                                    name="chips-tag" placeholder="Add Tag for easy searching">
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 0px 20px;padding-top: 10px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-12">
                                <label for="inputEmail4">Sources</label>
                                <input class="info form-control chips-source" type="text" data-role="sourceinput"
                                    name="chips-source" placeholder="Add Sources for easy searching">
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 10px 20px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-12 m-0">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input"
                                        bind:group={tempIsPracticeQuestion} value=true id="customCheck1">
                                    <label class="custom-control-label" for="customCheck1">Include As Practice
                                        Question</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <label id="contactA" class="contact-list-divider">Assign to Folder(Link)</label> -->

                    <!-- <div class="card-header d-flex align-items-center justify-content-between">
                        <h6 class="mg-b-0">Linked To Study Material</h6>
                        <div class="d-flex align-items-center tx-18">
                            <button on:click="{() => showModal = !showModal}"
                                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                                <i data-feather="link" class="wd-10 mg-r-5"></i> New Link
                            </button>
                        </div>
                    </div>
                    <ul class="list-group list-group-flush tx-13">
                        {#each linkArr as item}
                        <li class="list-group-item d-flex pd-sm-x-20">
                            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600"><i
                                        data-feather="link" class="wd-10"></i></span></div>
                            <div class="pd-l-10">
                                <p class="tx-medium mg-b-0">{item.fullPath.join(" / ")}</p>
                                <small class="tx-12 tx-color-03 mg-b-0">{item.batchName}</small>
                            </div>
                            <div class="mg-l-auto d-flex align-self-center">
                                <nav class="nav nav-icon-only">
                                    <a href="#" class="nav-link d-sm-block">
                                        <i data-feather="trash-2" class="wd-10 mg-r-5"></i>
                                    </a>
                                </nav>
                            </div>
                        </li>
                        {/each}
                    </ul> -->
                </div>
            </div>
            <div class="col-md-8 p-0 " style="height: 100%;display: flex;flex-direction: column;">


                <div data-label="Example" class="df-example" style="background: white;">
                    <ul class="nav nav-line manage-language-nav-header" id="myTab5" role="tablist">
                        {#if questionData.languages}
                        {#each questionData.languages as item}
                        <li class="nav-item" style="margin-left: 25px;" on:click={()=> {
                            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                            selectedLang = item.title
                            }}>
                            <a class="nav-link" href="#" class:active={selectedLang==item.title}>{item.title}</a>
                        </li>
                        {/each}
                        {/if}
                        <li class="nav-item " style="margin-left: 25px;border-left: 1px solid #ddd;padding-left: 25px;"
                            on:click={()=>selectedLang = "manage-language"} >
                            <a class="nav-link" href="#" class:active={selectedLang=="manage-language" }>
                                <i data-feather="settings" style="height: 15px;"></i>
                                Manage Languages
                            </a>
                        </li>
                    </ul>
                </div>



                <div style="padding: 25px; overflow: auto;">



                    {#if questionData.languages}
                    {#each questionData.languages as aLanguages}
                    <div class="a-language sec-nav" class:showDiv={selectedLang==aLanguages.title}>
                        {#if questionData.qType == "multipleChoice"}
                        <MultipleChoiceCmp bind:questionData={questionData} langObj={aLanguages.title} />
                        {:else if questionData.qType == "multipleResponse"}
                        <MultipleResponseCmp bind:questionData={questionData} langObj={aLanguages.title} />
                        {:else if questionData.qType == "numerical"}
                        <NumericalCmp bind:questionData={questionData} langObj={aLanguages.title} />
                        {:else}
                        <p>Please implement this questionData.qType : {questionData.qType ? questionData.qType :' - '}
                        </p>
                        {/if}
                    </div>
                    {/each}
                    {/if}


                    <div class="a-language" class:showDiv={selectedLang=="manage-language" }>
                        {#if questionData.languages}
                        <!-- {#if !questionData.languages[0].default} -->
                        <div style="margin-bottom:20px;">
                            <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase open-create-language-modal"
                                on:click={()=>openAddSecModal()}>
                                <i data-feather="plus" class="wd-10 mg-r-5"></i>
                                Add New Language
                            </button>
                        </div>
                        {#each questionData.languages as item}
                        <div data-label="Language {item.title}" class="fieldset-label sec-field-groups"
                            style="padding-top: 20px;margin-bottom:20px;">
                            <div class="form-group row">
                                <label for="sec-{item.title}-title" class="col-sm-2 col-form-label">Title</label>
                                <div class="col-sm-10">
                                    <input type="text" value="{item.title}" class="form-control"
                                        id="sec-{item.title}-title" placeholder="Language Title" on:change={(e)=>{
                                    item.title = e.target.value;
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/each}
                        <!-- {:else}
                                        <div
                                            style="border:1px solid #ddd; padding:60px 30px; display:flex;align-items:center;justify-content:center;flex-direction:column">
                                            <h4 id="language4" class="mg-b-10">Create your first Language</h4>
                                            <p class="mg-b-30 tx-center">Currently all the question falls under one default language.<br /> You can create
                                                multiple language to conduct test of different subject</p>
                                            <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase open-create-language-modal" on:click={openAddSecModal}><i data-feather="plus"
                                                    class="wd-10 mg-r-5" ></i>
                                                Add new Language
                                            </button>
                                        </div>
                                    {/if} -->
                        {/if}
                    </div>





                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal effect-scale" id="q-languages" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel6">Select Desired Languages</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div class="row row-sm mg-b-20">
                        <div class="col-sm-12">
                            <div class="title font-weight-bold mb-1">
                                Language
                            </div>
                            <select name="quetionLang" id="quetionLang" type="text" class="info form-control">
                                <option value="">Please Select Language</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Cancel</button>
                <button type="button" on:click={_render.langTabs} data-dismiss="modal"
                    class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">Save</button>
            </div>
        </div>
    </div>
</div>
{#if showModal}
<FolderModal on:close={onCloseModal} />
{/if}


<div class="modal effect-scale" bind:this={modalCmp} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bd-0 bg-transparent">
            <div class="modal-body pd-0">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>

                <div class="row no-gutters">

                    <div class="col-12 bg-white rounded-right">
                        <div class="ht-100p d-flex flex-column justify-content-center pd-20 pd-sm-30 pd-md-40">
                            <div class="d-flex">
                                <span class="tx-color-04"><i data-feather="layers"
                                        class="wd-40 ht-40 stroke-wd-3 mg-b-20"></i></span>
                                <h3 class="tx-16 tx-sm-20 tx-md-24 mg-b-15 mg-md-b-20 mg-l-15">New Language</h3>
                            </div>
                            <div class="tx-14 tx-md-16 tx-color-02">
                                <div class="form-group">
                                    <label class="d-block">Name</label>
                                    <input type="text" bind:value={languageName} class="form-control"
                                        class:parsley-error={errorObj.languageName}
                                        placeholder="Enter language name..." />
                                    {#if errorObj.languageName}
                                    <div class="parsley-errors-list">{errorObj.languageName}</div>
                                    {/if}
                                </div>
                            </div>
                            <button href="" on:click={createNewLanguage}
                                class="btn btn-primary btn-block btn-uppercase">
                                <i data-feather="plus" class="wd-10 mg-r-5"></i>
                                <ButtonLoader isLoading={isLoading} btnText="ADD" />
                            </button>
                        </div>
                    </div><!-- col -->
                </div><!-- row -->
            </div><!-- modal-body -->
        </div><!-- modal-content -->
    </div><!-- modal-dialog -->
</div><!-- modal -->
<!-- {#if  reportModalId == 1 }
<QuestionsReportedCmp dataFromReport={requiredData}/> 
{/if}
{#if }
<QuestionsReportedCmp on:close={onCloseModal} />
{/if} -->

<style>
    .a-language {
        display: none;
    }

    .a-language.showDiv {
        display: block;
    }
</style>
