<script>
    import { Router, Link, links, Route, navigate } from "svelte-routing";
    import { afterUpdate, onDestroy, onMount } from "svelte";
    import STPModal from "../TestInstructionsTextEditor/modal.svelte"
    export let testObj;
    export let updateParent;
    let showModal = false;
    let isLoading = false;
    export let errorObj = {};

    let difficulty, duration, examInstructions
    let difficultyDD;
    let difficultyEle;
    let testTimeEle;

    let clearError = function (fieldName) {
        if (errorObj[fieldName]) {
            delete errorObj[fieldName]
        }
        errorObj = errorObj;
    };

    let onCloseModal = function (event) {
        isLoading = true;
        showModal = !showModal;
        console.log("sptObject : ", event.detail.sptObject)
        testObj.examInstructions = event.detail.sptObject.content
        console.log("testObj : ", testObj.examInstructions)
        CKEDITOR.instances.examInstructions.setData(testObj.examInstructions);

        _$('.modal-backdrop').remove()
        isLoading = false;
    }
    // let openModal = function () {

    // }
    onMount(function () {
        console.log(errorObj)
        testObj.menu = 'details';
        console.log("Details : ", testObj);
        testObj.isDetails = true;



        feather.replace();

        let toolbar = [
            [
                "NewPage",
                "Preview",
                "-",
                "Bold",
                "Italic",
                "Strike",
                "NumberedList",
                "BulletedList",
                "-",
                "Table",
                "HorizontalRule",
                "Styles",
                "Format",
                "Maximize",
                "Image"
            ]
        ]

        if (CKEDITOR.instances.examInstructions)
            CKEDITOR.instances.examInstructions.destroy(false);

        CKEDITOR.replace('examInstructions', {
            toolbar: toolbar,
            height: 385
        });


        if (testObj.examInstructions) {
            CKEDITOR.instances.examInstructions.setData(testObj.examInstructions);
        }

        CKEDITOR.instances.examInstructions.on('blur', function (e) {
            clearError("examInstructions")
        });
        CKEDITOR.instances.examInstructions.on('change', function (e) {
            testObj.examInstructions = CKEDITOR.instances.examInstructions.getData();
            examInstructions = testObj.examInstructions
        });
        CKEDITOR.instances.examInstructions.on('instanceReady', function (e) {
            if (examInstructions != testObj.examInstructions) {
                CKEDITOR.instances.examInstructions.setData(testObj.examInstructions)
                examInstructions = testObj.examInstructions
                setTimeout(() => {
                    CKEDITOR.instances.examInstructions.setReadOnly(testObj.publish);
                }, 300);
            }
        })


        updateParent();



        testTimeEle = new Cleave('.test-duration', {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0, // integer only
            // delimiter: '',
            onValueChanged: function (e) {
                if (e.target.rawValue == "") {
                    e.target.rawValue = 0;
                    if (testTimeEle)
                        testTimeEle.setRawValue(0);
                }
                e.target.rawValue = Number(e.target.rawValue);
                duration = e.target.rawValue;
                testObj.duration = e.target.rawValue;
            }
        });


    })

    afterUpdate(function () {
        if (!difficultyDD) {
            difficultyDD = _$(difficultyEle).selectize({
                maxItems: 1,
                create: false,
                items: [testObj.difficulty || 'medium'],
                onChange: function (value) {
                    difficulty = value.toLowerCase();
                    testObj.difficulty = value.toLowerCase();
                }
            });
        }
        if (testObj.publish) {
            _$(difficultyEle)[0].selectize.disable();
        } else {
            _$(difficultyEle)[0].selectize.enable();
        }
        if (difficulty != testObj.difficulty) {
            difficultyDD[0].selectize.setValue(testObj.difficulty)
            difficulty = testObj.difficulty
        }
        if (duration != testObj.duration) {
            testTimeEle.setRawValue(testObj.duration)
            duration = testObj.duration
        }
    })

    onDestroy(() => {
        if (CKEDITOR.instances.examInstructions && CKEDITOR.instances.examInstructions.loaded) {
            CKEDITOR.instances.examInstructions.destroy()
        }
    })

</script>
<div class="fadeIn" style="display: flex;flex-direction: column;">
    {#if isLoading}

    <div class="mg-20 pd-10">
        <div class="placeholder-paragraph">
            <div class="line" />
        </div>

    </div>
    {/if}
    <div style="width: 1;border-bottom: 1px solid #e5e8ee; display: flex;">
        <div style="display: flex;align-items: center;justify-content: center;margin-left: 20px;" class="colored-svg">
            <i data-feather="clipboard" class="wd-40"></i>
        </div>
        <div class="media-body mg-l-10 pd-y-10">
            <h6 class="mg-b-2 tx-16">Test Details</h6>
            <span class="d-block tx-11 tx-color-03">Timing, Level, etc</span>
        </div>
        <div class="pd-y-10">
            <button class="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-r-10" disabled="true">
                <i data-feather="chevron-left" class="wd-10 mg-r-5"></i> Prev
            </button>
            <button class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-r-10" on:click={()=>{
                updateParent(true); navigate("/dashboard/tests/view/"+testObj._id+"/questionList")}}>
                Next <i data-feather="chevron-right" class="wd-10 mg-l-5"></i>
            </button>
        </div>
    </div>

    <div style="flex: 1; display: flex;">
        <div style="flex:1; padding: 25px;">
            <div class="form-group">
                <label for="formGroupExampleInput" class="d-block">Test Name <span
                        class="required-field">*</span></label>
                <input type="text" bind:value={testObj.name} class="form-control" on:blur={()=> clearError("name")}
                placeholder="Enter test name" class:parsley-error={errorObj.name}
                disabled={testObj.publish}>
                {#if errorObj.name}
                <div class="parsley-errors-list">{errorObj.name}</div>
                {/if}
            </div>
            <div class="form-group" style="display: none;">
                <label for="formGroupExampleInput" class="d-block">Display Name</label>
                <input type="text" bind:value={testObj.displayName} class="form-control" on:blur={()=>
                clearError("displayName")} placeholder="If you need to show some different name to student"
                class:parsley-error={errorObj.displayName}
                disabled={testObj.publish}>
                {#if errorObj.displayName}
                <div class="parsley-errors-list">{errorObj.displayName}</div>
                {/if}
            </div>
            <div class="form-group">
                <label for="formGroupExampleInput" class="d-block">Description</label>
                <textarea class="form-control" bind:value={testObj.description} rows="3"
                    placeholder="Describe this test" disabled={testObj.publish}></textarea>
            </div>
            <div class="form-group">
                <label for="formGroupExampleInput" class="d-block">Difficulty</label>

                <select bind:this={difficultyEle} class="info slectizedd form-control">
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                </select>


                <!-- <input type="text" class="form-control"  placeholder="Difficulty of test"> -->
            </div>
            <div class="form-group">
                <label for="formGroupExampleInput" class="d-block">Is Duration Divided Across Sections? <span
                        class="required-field">*</span></label>
                <div class="form-row">
                    <div class="form-group col-md-4 m-0">
                        <div class="custom-control custom-radio">
                            <input type="radio" id="durationDivisionCheckYes" name="customRadio"
                                class="custom-control-input" disabled={true}
                                bind:group={testObj.isDurationDividedAcrossSections} on:change={()=>
                            {if(testObj.isDurationDividedAcrossSections == "yes") testObj.duration = ""} } value="yes">
                            <label class="custom-control-label" for="durationDivisionCheckYes">Yes</label>
                        </div>
                    </div>
                    <div class="form-group col-md-4 m-0">
                        <div class="custom-control custom-radio">
                            <input type="radio" id="durationDivisionCheckNo" name="customRadio"
                                class="custom-control-input" bind:group={testObj.isDurationDividedAcrossSections}
                                value="no" disabled={testObj.publish}>
                            <label class="custom-control-label" for="durationDivisionCheckNo">No</label>
                        </div>
                    </div>
                </div>
                {#if errorObj.isDurationDividedAcrossSections}
                <div class="parsley-errors-list">{errorObj.isDurationDividedAcrossSections}</div>
                {/if}
            </div>

            <div class="form-group">
                <label for="formGroupExampleInput" class="d-block">Duration (in Mins) <span
                        class="required-field">*</span></label>
                <div class="input-group mg-b-10">
                    <input type="text" class="form-control test-duration text-right" on:blur={()=>
                    clearError("duration")} placeholder="Enter duration" class:parsley-error={errorObj.duration}
                    disabled={!testObj.isDurationDividedAcrossSections || testObj.isDurationDividedAcrossSections ==
                    'yes' ||
                    testObj.publish}>
                    <div class="input-group-append">
                        <span class="input-group-text">Minutes</span>
                    </div>
                </div>
                {#if errorObj.duration}
                <div class="parsley-errors-list">{errorObj.duration}</div>
                {/if}
            </div>
        </div>
        <div style="flex:1; padding: 25px;">
            <div class="form-group">
                <label for="formGroupExampleInput" class="d-flex" style="align-items: baseline">
                    <div>Instructions <span class="required-field">*</span></div>
                    <div class="d-block mg-l-auto">
                        <div class="d-sm-flex">
                            <button class="btn btn-xs pd-x-15 btn-primary btn-uppercase mg-l-5"
                                on:click={()=>{showModal=true;}}
                                disabled={testObj.publish}>
                                <i data-feather="copy" class="wd-10 mg-r-5"></i> Select Instructions
                            </button>
                        </div>
                    </div>
                </label>
                <div>
                    <textarea class="form-control" name="examInstructions" rows="3" placeholder="Describe this test"
                        disabled={testObj.publish}></textarea>
                </div>
                {#if errorObj.examInstructions}
                <div class="parsley-errors-list">{errorObj.examInstructions}</div>
                {/if}
            </div>
        </div>
    </div>
</div>

{#if showModal}
<div class="mg-b-20">
    <STPModal on:close={onCloseModal} />
</div>
{/if}
