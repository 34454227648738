import moment from "moment";

function parseNotificationMessage(notificationBody) {
    if (notificationBody) {
        notificationBody = JSON.parse(notificationBody)
        let { message, placeholders } = notificationBody
        let keys = Object.keys(placeholders)
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];
            let replaceWith
            let regexp = new RegExp(`\\[${key}\\]`, "gi")
            if(key.toLowerCase().includes("date")) {
                replaceWith = moment(new Date(placeholders[key])).format("DD-MMM-YYYY")
            } else if (key.toLowerCase().includes("time")) {
                replaceWith = moment(new Date(placeholders[key])).format("hh:mm A")
            } else {
                replaceWith = placeholders[key]
            }
            console.log({regexp, replaceWith})
            message = message.replace(regexp, replaceWith)
        }
        return message
    } else {
        return "";
    }
}


export default parseNotificationMessage

let notification = {
    body: JSON.stringify({
        message: "[a] [b][c]",
        placeholders: {
            a: "hello",
            b: "world",
            c: "!"
        }
    })
}

console.log(parseNotificationMessage(notification.body))