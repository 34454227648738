<script>
import { onMount, tick } from "svelte";
import api_handleApiCalls from "../utils/apiCallHandler";
import moment from "moment";
import {pError, pSuccess} from '../utils/notify.svelte'
    export let parentId
    export let batchId
    export let studentId
    let readingEntriesArr = []
    let solvingEntriesArr = []
    let ReadingDoubtsArr = []
    let SolvingDoubtsArr = []

    let topicData = null
    let activeTab = 'Reading'
    let isLoading = true
   async function setActiveTab(tabName) {
        activeTab = tabName;
        onRefreshComponent()
    }

    const getMonitoringRecordByType = async (type='solving') => {
        let response = await api_handleApiCalls('studyMonitoring.getMonitoringDataById', {parentId, studentId, type, batchId})
        if(response.status) return response.data
        else return []
    }
    const checkMarkCompleted =  async () => {
        let response = await api_handleApiCalls('studyMonitoring.topicDataByStudentId', {parentId, studentId})
        if(response.status) topicData = response.data
        else topicData = null
    }
    const getReadingRaisedDoubts = async () => {
        let response = await api_handleApiCalls('studyMonitoring.getRaisedDoubts', {parentId, studentId, batchId, type: 'reading'})
        if(response.status) {
            ReadingDoubtsArr = response.data
        } else {
            ReadingDoubtsArr = []
        }
        onRefreshComponent()
    }
    const getSolvingRaisedDoubts = async () => {
        let response = await api_handleApiCalls('studyMonitoring.getRaisedDoubts', {parentId, studentId, batchId, type: 'solving'})
        if(response.status) {
            SolvingDoubtsArr = response.data
        } else {
            SolvingDoubtsArr = []
        }
        onRefreshComponent()
    };

    const markDoubtSolved = async (doubtId, type) => {
        let response = await api_handleApiCalls('studyMonitoring.markDoubtSolved', {doubtId})
        if(response.status) {
            pSuccess('Success', 'Doubt Marked Solved Successfully')
            if(type == 'reading') getReadingRaisedDoubts()
            else getSolvingRaisedDoubts()
        } else {
            pError('Error', 'Something went wrong, Please try again')
        }
    };
    
    
    
    onMount(async ()=>{
        fetchReadingData()
        fetchSolvingData()
        checkMarkCompleted()
        getReadingRaisedDoubts()
        getSolvingRaisedDoubts()
    })

    const fetchReadingData =    async () => {
        readingEntriesArr = await getMonitoringRecordByType('reading')
        onRefreshComponent()
    }
    const fetchSolvingData =    async () => {
        solvingEntriesArr = await getMonitoringRecordByType('solving')
        onRefreshComponent()
    }

    const onRefreshComponent = async () => {
        await tick()
        feather.replace()
        return true
    };
    

</script>

<div class="col-md-12 animated fadeIn">
    {#if topicData}
        <div class="d-flex align-items-center mg-b-15">
            <div class="avatar d-none d-sm-block mr-2 wd-10 ht-10">
                <span class="avatar-initial rounded-circle bg-success"><i class="icon ion-check"></i></span>
            </div> 
                <span class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1">This topic is marked done by student</span>
            </div>
        {:else}
        <div class="d-flex align-items-center mg-b-15">
            <div class="avatar d-none d-sm-block mr-2 wd-10 ht-10">
                <span class="avatar-initial rounded-circle bg-danger"><i class="icon ion-check"></i></span>
            </div>
               <span class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1">This topic is not marked done yet by student</span> 
        </div>
    {/if}
    
    <ul class="nav nav-line " id="myTab" role="tablist" style="margin-bottom: 20px;">
        <li class="nav-item" on:click={()=> setActiveTab('Reading')} >
            <a class="nav-link" class:active={(activeTab=='Reading' )} data-tab-name="Reading"
                data-toggle="tab" href="#">Reading Entries ({readingEntriesArr.length})</a>
        </li>
        <li class="nav-item" on:click={()=> setActiveTab('ReadingDoubts')} >
            <a class="nav-link" class:active={(activeTab=='ReadingDoubts' )} data-tab-name="ReadingDoubts"
                data-toggle="tab" href="#">Reading's Doubts ({ReadingDoubtsArr.length})</a>
        </li>
        <li class="nav-item" on:click={()=> setActiveTab('Solving')} >
            <a class="nav-link" class:active={(activeTab=='Solving' )} data-tab-name="Solving"
                data-toggle="tab" href="#">Solving Entries ({solvingEntriesArr.length})</a>
        </li>
        <li class="nav-item" on:click={()=> setActiveTab('SolvingDoubts')} >
            <a class="nav-link" class:active={(activeTab=='SolvingDoubts' )} data-tab-name="SolvingDoubts"
                data-toggle="tab" href="#">Solving's Doubts ({SolvingDoubtsArr.length})</a>
        </li>
    </ul>
    <ul class="list-group list-group-flush tx-13">
        {#if activeTab == 'Reading'}
            {#each readingEntriesArr as reading}
                <li class="list-group-item d-flex pd-sm-x-20 animated fadeIn">
                    <div class="avatar d-none d-sm-block"><span class="avatar-initial rounded-circle bg-teal"><i class="icon ion-md-book"></i></span></div>
                    <div class="pd-sm-l-10">
                        <p class="mg-b-0"><small class="text-muted">Source: </small>{reading.source}</p>
                        <small class="tx-12 tx-color-03 mg-b-0"></small>
                    </div>
                    <div class="mg-l-auto text-right">
                        <p class="tx-medium mg-b-0">{moment(reading.date).format('DD-MM-YYYY , hh:mm A')}</p>
                        <!-- <p class="tx-medium mg-b-0">Correct Questions: {reading.numberOfCorrectQuestions}</p> -->
                    </div>
                </li>
            {:else}
                <p class="text-center animated fadeIn">No Reading Entries Found</p>
            {/each}
        {:else if activeTab == 'ReadingDoubts'}
            {#each ReadingDoubtsArr as doubt}
                <li class="list-group-item d-flex align-items-center pd-sm-x-20 animated fadeIn">
                    <div class="avatar d-none d-sm-block ht-20 wd-20">
                        <span class={doubt.solved ? "avatar-initial rounded bg-success" : "avatar-initial rounded bg-danger"}></span>
                    </div>
                    <div class="pd-sm-l-10">
                        <p class="mg-b-0"><small class="text-muted">Raised On: </small>{moment(doubt.createdOn).format('DD-MM-YYYY , hh:mm A')}</p>
                        {#if doubt.solved}
                        <p class="mg-b-0"><small class="text-muted">Solved On: </small>{moment(doubt.solvedOn).format('DD-MM-YYYY , hh:mm A')}</p>

                        {/if}
                        <small class="tx-12 tx-color-03 mg-b-0">Status: {doubt.solved?'Solved':'Not Solved'}</small>
                    </div>
                    <div class="mg-l-auto text-right">
                        <!-- <p class="tx-medium mg-b-0">{moment(doubt.createdOn).format('MMM dd, YYYY, H:MMA')}</p> -->
                        <!-- <p class="tx-medium mg-b-0">Correct Questions: {reading.numberOfCorrectQuestions}</p> -->
                        {#if doubt.solved}
                            <p class="tx-medium mg-b-0"><small class="text-muted">Solved By: </small>{doubt.user[0] ? doubt.user[0].name: ''}</p> 
                            <p class="tx-medium text-muted mg-b-0"> {doubt.user[0]? doubt.user[0].mobileNo.replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3'): ''}</p>
                            {:else}
                            <!-- <button on:click={()=>markDoubtSolved(doubt._id, 'reading')} class="btn btn-primary btn-xs">Mark as Solved</button> -->
                            <a href="#" on:click={()=>markDoubtSolved(doubt._id, 'reading')}>  Mark as Solved</a>
                        {/if}
                    </div>
                </li>
            {:else}
                <p class="text-center animated fadeIn">No Reading's Doubts Found</p>
            {/each}
        {:else if activeTab == 'Solving'}
            {#each solvingEntriesArr as solving}
                <li class="list-group-item d-flex pd-sm-x-20 animated fadeIn">
                    <div class="avatar d-none d-sm-block"><span class="avatar-initial rounded-circle bg-teal"><i class="icon ion-md-help-circle"></i></span></div>

                    <div class="pd-sm-l-10">
                        <p class="mg-b-0"><small class="text-muted">Source: </small>{solving.source}</p>
                        <small class="tx-12 tx-color-03 mg-b-0">{moment(solving.date).format('DD-MM-YYYY , hh:mm A')}</small>
                    </div>
                    <div class="mg-l-auto text-right">
                        <p class="tx-medium mg-b-0">Attempted Questions: {solving.numberOfQuestions}</p>
                        <p class="tx-medium mg-b-0">Correct Questions: {solving.numberOfCorrectQuestions}</p>
                    </div>
                </li>
            {:else}
                <p class="text-center animated fadeIn">No Solving Entries found</p>
            {/each}
        {:else}
            {#each SolvingDoubtsArr as doubt}
                <li class="list-group-item d-flex align-items-center pd-sm-x-20 animated fadeIn">
                    <div class="avatar d-none d-sm-block ht-20 wd-20">
                        <span class={doubt.solved ? "avatar-initial rounded bg-success" : "avatar-initial rounded bg-danger"}></span>
                    </div>
                    <div class="pd-sm-l-10">
                        <p class="mg-b-0"><small class="text-muted">Doubt Raised for Number of Question: </small>{doubt.numberOfQuestions?doubt.numberOfQuestions:'0'}</p>
                        <p class="mg-b-0"><small class="text-muted">Raised On: </small>{moment(doubt.createdOn).format('DD-MM-YYYY , hh:mm A')}</p>
                        {#if doubt.solved}
                        <p class="mg-b-0"><small class="text-muted">Solved On: </small>{moment(doubt.solvedOn).format('DD-MM-YYYY , hh:mm A')}</p>

                        {/if}
                        <small class="tx-12 tx-color-03 mg-b-0">Status: {doubt.solved?'Solved':'Not Solved'}</small>
                    </div>
                    <div class="mg-l-auto text-right">
                        <!-- <p class="tx-medium mg-b-0">{moment(doubt.createdOn).format('MMM dd, YYYY, H:MMA')}</p> -->
                        <!-- <p class="tx-medium mg-b-0">Correct Questions: {reading.numberOfCorrectQuestions}</p> -->
                        {#if doubt.solved}
                            <p class="tx-medium mg-b-0"><small class="text-muted">Solved By: </small>{doubt.user[0] ? doubt.user[0].name: ''}</p> 
                            <p class="tx-medium text-muted mg-b-0"> {doubt.user[0]? doubt.user[0].mobileNo.replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3'): ''}</p>
                            {:else}
                            <a href="#" on:click={()=>markDoubtSolved(doubt._id, 'solving')} class="btn btn-primary btn-xs">Mark as Solved</a>
                        {/if}
                    </div>
                </li>
            {:else}
                <p class="text-center animated fadeIn">No Solving's Doubts Found</p>
            {/each}
        {/if}
        
      </ul>
</div>
