<script>
   
  </script>
  
  <style>

  </style>
  
  <div class="content-body fadeIn">
    <!-- breadcrumbs -->
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20
        mg-lg-b-25 mg-xl-b-30">
      <div>
        <nav use:links aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item">
              <a href="/dashboard/enquiries">enquiries</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              response
            </li>
          </ol>
        </nav>
        <h4 class="mg-b-0 tx-spacing--1">Response</h4>
      </div>
    </div>
    <!-- form inputs -->
    <form class="mg-b-20 mg-lg-b-25 mg-xl-b-30"  on:submit={submitForm}>
      <div class="row row-sm mg-b-10">
        <div class="col-sm-12">
          <fieldset class="form-fieldset">
            <legend>Response</legend>
            
          </fieldset>
        </div>
      </div>
    </form>
    <!-- form buttons -->
    <div class="d-sm-flex align-items-center justify-content-end">
      <div class="d-block">
        <button
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            Save
            <i
            data-feather="save"
            class="wd-10 mg-l-5">
        </button>
      </div>
    </div>
  </div>
  
