<script>
  let addNewClassModal;
  import ButtonLoader from "../../utils/button_loader.svelte";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import { onMount, tick } from "svelte";
  let isLoading = false;
  let bankName = null;
  let ifsc = null;
  let branchName = null;
  let allbankdetails = [];
 
  
  let editbanks = [];
  let editifsc = ''
  let editibank = ''
  let oldB = ''
  let oldIF = ''
  export let reload;
  let errorObj = {};
  let editerrorObj = {};

  onMount(async () => {
    fetchIntegrationSetting();
    feather.replace({ width: "1em", height: "1em", margin: "1em" });
  });
  async function fetchIntegrationSetting() {
    if (isLoading) return;
    isLoading = true;
    await tick();
    let result = await api_handleApiCalls("settings.fetchbank", {});
    console.log("fetch", result);
    allbankdetails = result.data[0].bankdetail;

    if (result.status) {
    } else {
      pError("", result.errMsg);
    }
    isLoading = false;
    await tick();
    feather.replace({ width: "1em", height: "1em", margin: "1em" });
  }
  async function handledelete(bank) {
    let slicedetails = [];
    for (var i = 0; i < allbankdetails.length; i++) {
      if (allbankdetails[i].bankName == bank) {
        slicedetails = allbankdetails;
        const allbankdetails1 = slicedetails.splice(i, 1);
        console.log("updated array", slicedetails);
       
      }
    }
      pConfirm(
        "Delete bank?",
        "Are you sure you want to delete this bank?",
        async function () {
          let result1 = await api_delete(slicedetails);
          if (result1.status) {
            pSuccess(null, result1.data);
            await fetchIntegrationSetting();
            if(reload) reload()
          } else {
            pError("Error!", result1.errMsg);
          }
        }
      );
    
  }
  function clearValidation() {
    errorObj = {};
  }

  function clearEditValidation() {
    editerrorObj = {};
  }
  async function clearsave() {
    clearEditValidation()
  }
  async function showModal() {
    _$(addNewClassModal).modal("show");
    clearValidation();
    _$(".first-input").focus();
    // clearFields();
    // await tick();
  }

  async function Editsave() {

      // if(editerrorObj["bank"] != undefined){
      //   return;
      // }
      // if(editerrorObj["newifsc"] != undefined){
      //   return;
      // }
      // if(editerrorObj["branchName"] != undefined){
      //   return;
      // }

    //   if(bankName == ''|| ifsc  == '' ||branchName == '') {
    //   return;
    // }
    // if(bankName == null|| ifsc  == null ||branchName == null) {
    //   return;
    // }
    if(editerrorObj["bank"]){
      return;
    }
    if(editerrorObj["newifsc"]){
      return;
    }
    if(editerrorObj["branchName"]){
      return;
    }
    
      
   
      editbanks = allbankdetails;
    let result = await editbankapi(editbanks);

    //   await tick();

    if (result.status) {
      pSuccess(null, result.data);
      editerrorObj = {};
      await fetchIntegrationSetting()
      if (reload) reload();

    } else {
      pError("Error!", result.errMsg);
    }
  
  }
  const validationdata = (value) => {
    // console.log("values",value)
    if(value.length ==  0){
      editerrorObj["bank"] = "Please Enter Bank Name";
      return;
    }else{
      editerrorObj["bank"] = "";

    }
  }
    const validationdata1 = (value) => {

      var reg = /^[A-Za-z]{4}[0-9]{6,7}$/;
      var ifscCheck = value.match(reg)
      if(ifscCheck == null){
        editerrorObj["newifsc"] ="Please Enter Valid Ifsc Code";
        return;
      }
    // console.log("values",value)
    if(value.length ==  0){
      editerrorObj["newifsc"] ="Please Enter Ifsc Code";
      return;
    }else{
      editerrorObj["newifsc"] = "";

    }
    // if(editifsc == '' || null){
    //   editerrorObj["newifsc"] = "Please enter Ifsc Code";
    //   return;
    // }
    
    
  }

  const validationdata2 = (value) => {
    // console.log("values",value)
    if(value.length ==  0){
      editerrorObj["branchName"] ="Please Enter Branch Name";
      return;
    }else{
      editerrorObj["branchName"] = "";

  }
}

  const ifscupdate = (newifsc, oldifsc) => {
   
    for (var i = 0; i < allbankdetails.length; i++) {
      if (allbankdetails[i].ifsc == oldifsc) {
        allbankdetails[i].ifsc = newifsc;
        editifsc = newifsc
         oldIF = oldifsc
        // console.log(allbankdetails)
      }
    }
  };

  const bankupdate = (newbank, oldbank) => {
    for (var i = 0; i < allbankdetails.length; i++) {
      if (allbankdetails[i].ifsc == oldbank) {
        allbankdetails[i].bankName = newbank;
        editibank = newbank
         oldB = oldbank
  
        
      }
    }
  };

  const branchupdate = (newbranch, oldbranch) => {
    for (var i = 0; i < allbankdetails.length; i++) {
      if (allbankdetails[i].ifsc == oldbranch) {
        allbankdetails[i].branchName = newbranch;
      
        editibank = newbranch
         oldB = oldbranch
  
        
      }
    }
  };

  async function editbankapi(data) {
    console.log("dat>>>", data)
    let action = "settings.editbank";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function submitForm(event) {
   await clearValidation()
    event.preventDefault();
    if(!bankName){
      errorObj["bankName"] ="Please Enter Bank name";
      return
    }
    if(!ifsc){
      errorObj["bank"] ="Please Enter IFSC code";
      return
    } 
    if(!branchName){
      errorObj["bank"] ="Please Enter Branch name";
      return
    }
      

    if(ifsc){
    var reg1 = /^[A-Za-z]{4}[0-9]{6,7}$/;
      var ifscCheck1 = ifsc.match(reg1)
      if(ifscCheck1 == null){
        errorObj["ifsc"] ="Please enter Valid Ifsc Code";
        return;
      }
      // console.log("allbankdetails.length", allbankdetails)
  //     if(allbankdetails.length){
  // for(let i = 0; i<=allbankdetails.length - 1; i++){
  //   if(allbankdetails[i].ifsc == ifsc ){
  //     errorObj["ifsc"] ="IFSC not availble";
  //     return;
  //   }
    
  // }
  //     }
      


// if(duplicate == true){
//   errorObj["ifsc"] ="Ifsc code  not available";
//   return;
// }

    }

    let data = { bankName, ifsc, branchName };
    //   if (classObj._id) data._id = classObj._id;
    // alert(data.bankname, data.ifsc);
    console.log("bank", data);

    isLoading = true;
    let result = await api_save(data);
    isLoading = false;
    //   await tick();

    if (result.status) {
      pSuccess(null, result.data);
      hideModal();
      await fetchIntegrationSetting();
      // if (reload) reload();
    } else {
      pError("Error!", result.errMsg);
    }
  }
  async function api_save(data) {
    let action = "settings.addbank";
    var response = await api_handleApiCalls(action, data);
    
    return response;
  }
  async function api_delete(data) {
    let action = "settings.deletebank";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function hideModal() {
    _$(addNewClassModal).modal("hide");
    clearFields();
    await tick();
    feather.replace({ width: "1em", height: "1em", margin: "1em" });
  }

  function clearFields() {
    // form fields

    bankName = "";
    ifsc = "";
    branchName = "";
  }
</script>

<div class="row flex-row-reverse mr-3">
  <div class="d-inline-block">
    <button
      class="btn pd-x-15 pd-y-8 btn-primary btn-uppercase mg-l-5 btn-sm"
      on:click="{showModal}"
    >
      Add new Bank
    </button>
  </div>
</div>
<div class="m-3">
  
  {#each allbankdetails as bank, i}
  
  <li class="list-group-item list-group-item-action">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column bd-highlight mb-1">
      <h6 class="font-weight-lighter"><b class="font-weight-normal">Bank Name:</b>{bank.bankName}</h6>
      <h6 class="font-weight-lighter"><b class="font-weight-normal">IFSC Code:</b>{bank.ifsc}</h6>
      <h6 class=" font-weight-lighter"><b class="font-weight-normal">Branch Name:</b> {bank.branchName}</h6>
    </div>
      <nav>
        <!-- <a
        class="d-inline nav-link"
      target="_blank"><i data-feather="external-link" />
        Facebook Page</a>
      <a
        data-toggle="collapse"
        href="#facebook"
        role="button"
        aria-expanded="false"
        aria-controls="facebook"
        class="d-inline mg-l-5 nav-link"> -->
        <div class="d-flex flex-row bd-highlight mb-3">
          <div class="bd-highlight">
            <a
              data-toggle="collapse"
              href="#{bank.ifsc}"
              role="button"
              aria-expanded="true"
              aria-controls="{bank.ifsc}"
              class="d-inline mg-l-5 nav-link"
            >
              <i data-feather="edit"
            /></a>
          </div>
          <div
            class="bd-highlight"
            on:click="{handledelete(bank.bankName)}"
          >
            <i data-feather="trash" class="wd-15 mg-r-5" />
          </div>
        </div>
      </nav>
    </div>
    <div class="collapse mg-t-10" id="{bank.ifsc}">
      <div class="form-group">
        <label for="formGroupExampleInput" class="d-block">Bank Name </label>
        <input on:blur={({ target: { value } }) => bankupdate(value,bank.ifsc)}
        on:change={({ target: { value } })  =>  validationdata(value)}
        type="text" bind:value="{bank.bankName}" class="form-control"
        class:parsley-error="{editerrorObj.bank}"
        placeholder="Bank name" aria-label="Bank name"
        aria-describedby="button-addon2" />
        {#if editerrorObj.bank}
      <div class="parsley-errors-list">*{editerrorObj.bank}</div>
      {/if}
        <br />

        <label for="formGroupExampleInput" class="d-block">IFSC Code</label>
        <input on:blur={({ target: { value } }) => ifscupdate(value,bank.ifsc)}
        on:change={({ target: { value } })  =>  validationdata1(value)}
        type="text" bind:value="{bank.ifsc}" class="form-control" 
        class:parsley-error="{editerrorObj.newifsc}"
        placeholder="Ifsc code" aria-label="Ifsc Code"
        aria-describedby="button-addon2" maxlength='11' />
        
      {#if editerrorObj.newifsc}
      <div class="parsley-errors-list">*{editerrorObj.newifsc}</div>
      {/if}
      <br />
      <label for="formGroupExampleInput" class="d-block">Branch Name </label>
        <input on:blur={({ target: { value } }) => branchupdate(value,bank.ifsc)}
        on:change={({ target: { value } })  =>  validationdata2(value)}
        type="text" bind:value="{bank.branchName}" class="form-control"
        class:parsley-error="{editerrorObj.branchName}"
        placeholder="Branch name" aria-label="Branch name"
        aria-describedby="button-addon2" />
        {#if editerrorObj.branchName}
      <div class="parsley-errors-list">*{editerrorObj.branchName}</div>
      {/if}
        <br />
      <div class="d-flex flex-row flex-row-reverse ">
      <!-- <div class="input-group-append mg-t-10 d-flex m-3">
        <button
          on:click="{clearsave}"
          class="btn btn-outline-light"
          type="button"
          id="button-addon2"
        >
          CANCEL
        </button>
      </div> -->
        <div class=" mg-t-10 d-flex ">
          <button
            on:click="{Editsave}"
            class="btn btn-outline-light"
            type="button"
            id="button-addon2"
          >
            Save
          </button>
        </div>
        </div>

      </div>
    </div>
  </li>
  {/each}
</div>

<!-- Modal -->
<div
  class="modal effect-scale"
  bind:this="{addNewClassModal}"
  id="modal-new-batch"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered mx-wd-sm-650" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header pd-b-0">
        <div class="media align-items-center pd-12">
          <span class="tx-color-04"
            ><i data-feather="package" class="wd-60 ht-60"
          /></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Add new bank detail</h4>
          </div>
        </div>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span><br />
        </button>
      </div>
      <div class="modal-body">
        <div class="row no-gutters pd-12">
          <div class="col-12 bg-white rounded-right">
            <div class="tx-color-02">
              <div class="form-group">
                <label class="d-block">
                  Bank Name <span class="required-field">*</span></label
                >
                <input
                  bind:value="{bankName}"
                  type="text"
                  class="form-control first-input tx-capitalize"
                  id="classes-name"
                  class:parsley-error="{errorObj.bankName}"
                  placeholder="Enter Bank name" 
                />
                {#if errorObj.bankName}
                <div class="parsley-errors-list">*{errorObj.bankName}</div>
                {/if}
               
              </div>
              <div class="form-group">
                <label class="d-block"
                  >IFSC Code <span class="required-field">*</span></label
                >
                <input
                  bind:value="{ifsc}"
                  class:parsley-error="{errorObj.ifsc}"
                  type="text"
                  class="form-control first-input tx-capitalize"
                  id="classes-name"
                  placeholder="Enter Ifsc code" maxlength="11"
                />
                {#if errorObj.ifsc}
                <div class="parsley-errors-list">*{errorObj.ifsc}</div>
                {/if}
              </div>
              <div class="form-group">
                <label class="d-block"
                  >Branch Name <span class="required-field">*</span></label
                >
                <input
                  bind:value="{branchName}"
                  class:parsley-error="{errorObj.branchName}"
                  type="text"
                  class="form-control first-input tx-capitalize"
                  id="classes-name"
                  placeholder="Enter Branch Name"
                />
                {#if errorObj.branchName}
                <div class="parsley-errors-list">*{errorObj.branchName}</div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer pd-t-0">
        <div class="col-12 d-flex justify-content-end pd-12">
          <button
            type="button"
            class="btn btn-secondary tx-12"
            data-dismiss="modal"
          >
            CANCEL <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button
            type="button"
            on:click="{submitForm}"
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          >
            <ButtonLoader btnText="Proceed" />
            <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
