<script>
    import { Router, Link, Route } from "svelte-routing";
    import ListRoute from "./list.svelte";
    import VewJoinRequest from "./viewJoinRequest.svelte";
</script>

<Router>
    <Route path="">
        <ListRoute></ListRoute>
    </Route>
    <Route path="view/:id" let:params>
        <VewJoinRequest questionId="{params.id}"></VewJoinRequest>
    </Route>
</Router>
