<script>
    import { onMount } from "svelte";
    export let arrList 
    export let showHeader
    function getFormatedDate(date) {
        if(!date && date == '') {
            return '-'
        }
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('-');      

    }
    onMount(function(){
        feather.replace({width: "1em", height: "1em"})
    })

    function displayDateStrByDateObj (date) {
        let _thisDate = new Date(date)

        if(_thisDate && _thisDate.toString().toLowerCase() != 'invalid date'){
            return _thisDate.toLocaleString();
        }

        return '-'
         
    }

</script>
<style>
     .mail-group-label {
    display: block;
    padding: 6px 0px;    
    font-size: .875rem;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    font-weight: 500;
    letter-spacing: .5px;
    color: #222;
    margin: 1px 0;
 }
 .media {
    margin-top: 5px;
    padding: 2px 0px;
    border-bottom: 1px solid #e5e9f2;
 }
 .avatar .avatar-initial {
    font-size: 0.7rem;
    text-transform: initial;
 }
 .empty-state-img {
    text-align: center;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>
    
        <div class="d-flex flex-row align-items-center justify-content-between mail-group-label bg-gray-200" style="padding: 8px 12px;">
            <label class="mg-b-0">Follow Up History</label>
            {#if showHeader}
            <a href="#modal-new-followup" data-toggle="modal" data-animation="effect-scale" class="nav-link pd-0 d-sm-block"><i data-feather="plus-circle"></i> Add follow up</a>
            {/if}
        </div>
    {#if arrList && (arrList.length == 0) }
        {#if showHeader}
            <div class="empty-state-img">
            <img src="/images/noresult.png" style="width: 50%;" alt="empty" />
            <p class="tx-bold"></p>
            </div>
        {/if}
      {/if}
      {#if arrList}
        <ul class="list-unstyled media-list pd-5 mg-b-0" style="max-height: 1068px;
        overflow-y: auto;">
            {#each arrList as followup}
                <li class="media">
                    <div class="avatar">        
                        {#if followup.status == 'hot'}
                            <span class="avatar-initial rounded-circle bg-gray-800">Hot</span>
                        {:else if followup.status == 'warm'}
                            <span class="avatar-initial rounded-circle bg-gray-600">Warm</span>
                        {:else if followup.status == 'cold'}
                            <span class="avatar-initial rounded-circle bg-gray-400">Cold</span>
                        {:else}
                            <span class="avatar-initial rounded-circle bg-gray-300">-</span>
                        {/if}
                    </div>
                    <div class="media-body mg-l-15">
                    <div class="tx-color-02 d-flex align-items-center justify-content-between mg-b-2">
                        <span class="tx-12">Response: {followup.response}</span>
                        <span class="tx-11">Date: {displayDateStrByDateObj(followup.followUpDate)}</span>
                    </div>
                    <h6 class="tx-13 tx-13 tx-bold">Next Follow Up Date: {displayDateStrByDateObj(followup.nextFollowupDate ? followup.nextFollowupDate  :" - ")}</h6>
                    <p class="tx-12 tx-color-02 mg-b-0">Remark: {followup.remark}</p>
                    </div><!-- media-body -->
                </li>
            {/each}
        </ul>

        {:else}  
                <div data-label="Example" class="df-example">
                    <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                      <div class="line"></div>
                    </div>
                    <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                        <div class="line"></div>
                      </div>
                      <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                        <div class="line"></div>
                      </div>
                      <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                        <div class="line"></div>
                      </div>
                      <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                        <div class="line"></div>
                      </div>
                      <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                        <div class="line"></div>
                      </div>
                      <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                        <div class="line"></div>
                      </div>
                  </div><!-- df-example -->
        {/if}
