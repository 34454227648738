
/**
   * Constructor for ImportedExcelData class.
   *
   * @param {Array} xlsx_row_object - An array containing exported rows from an Excel sheet.
   * @param {Array} validColumnNames - An array of valid column names.
   * @param {String} keyColName - Under this column, the value of each row cell is used as an identifier; the default identifier range is '0 to n', and you can access the data with its corresponding value.
   */
export default class ResultImportedExcelData {

  #rowsMapSet 
  #extraColumnFound = false
  #missingColumns = false
  #errMsgForMissingColumn = []
  #errMsgForExtraColumn = []
  #sheetCorrectColumns = {}
  #formattedData = []
  #errorMissingCellData = ""
  #isSheetHaveData = false

  constructor(xlsx_row_object){

    let validColumnNames = [
        "Roll Number",
        "Name",
        "Total Questions",
        "Total Positive Marks",
        "Total Negative Marks",
        "Total Attempted",
        "Total Correct Answered",
        "Total Wrong Answered",
        "Total Not Answered",
        "Total Not Visited",
        "Total Marks",
        "Maximum Marks",
    ]

    xlsx_row_object.map((row, idx) => {
      this.#isSheetHaveData = true

      this.#formattedData.push({
        rollNumber: row["Roll Number"],
        name: row["Name"],
        total: {
            totalQuestions: row["Total Questions"],
            totalPMarks: row["Total Positive Marks"],
            totalNMarks: row["Total Negative Marks"],
            totalAttempted: row["Total Attempted"],
            totalCorrectAns: row["Total Correct Answered"],
            totalWrongAns: row["Total Wrong Answered"],
            totalNotAnswered: row["Total Not Answered"],
            totalNotVisited: row["Total Not Visited"],
            totalMarks: row["Total Marks"],
            maximumMarks: row["Maximum Marks"],
        }
      });

      let rowNo = (idx + 1).toString()
      validColumnNames.map(name=>{
        if(!row[name]){
          this.#errorMissingCellData += `\nColumn: ${name} Row: ${rowNo}`
        }
      })
    });
    
    validColumnNames.map((colName) => {
      this.#sheetCorrectColumns[colName] = { found: true };
    });

  } 

  /**
   * 
   * @param {Array} worksheetColumns An array containing column names exported from a sheet.
   * @returns {Object} An object with properties `isValid` (a boolean) and `errorMsg` (a string).
   */
  validateHeaders(worksheetColumns) {
    let headers = this.#getHeaders(worksheetColumns);
    let isValid = true

    if (Array.isArray(headers) && headers.length) {
      let sheetObjHeaders = headers;
      for (let index = 0; index < sheetObjHeaders.length; index++) {
        const aColumn = sheetObjHeaders[index];

        if (!this.#sheetCorrectColumns[aColumn] && aColumn != "Details") {
          this.#errMsgForExtraColumn.push(aColumn);
          isValid = false;
        }
      }

      for (let aColumn in this.#sheetCorrectColumns) {
        if (sheetObjHeaders.indexOf(aColumn) == -1) {
          this.#errMsgForMissingColumn.push(aColumn);
          isValid = false;
        }
      }
    } else {
      isValid = false;
    }

    let validationErrorMsg;
    if (this.#errMsgForMissingColumn.length) {
      let msgStr =
        this.#errMsgForMissingColumn > 1
          ? "Missing column names. "
          : "Missing column name. ";
      msgStr = msgStr + "" + this.#errMsgForMissingColumn.join(",\n");
      validationErrorMsg = msgStr + "\n";
    }

    if (this.#errMsgForExtraColumn.length) {
      let msgStr =
        this.#errMsgForExtraColumn.length > 1
          ? "Invalid column names. "
          : "Invalid column name. ";
      let joinStr = this.#errMsgForExtraColumn.join(",\n");

      if (joinStr && joinStr != "") {
        msgStr = msgStr + "" + joinStr;
        validationErrorMsg = validationErrorMsg ? validationErrorMsg : "";
        validationErrorMsg += msgStr;
      }
    }

    return {
        isValid,
        errorMsg: validationErrorMsg
    };
  }

  /**
   * 
   * @returns A String
   */
  getMissingValues () {
    return this.#errorMissingCellData
  }

  hasData () {
    return this.#isSheetHaveData
  }

  getFormattedData () {
    return this.#formattedData
  }

  #getHeaders(worksheet) {
    const header = [];

    if (!worksheet["!ref"]) {
      return header;
    }

    const columnCount = XLSX.utils.decode_range(worksheet["!ref"]).e.c + 1;
    for (let i = 0; i < columnCount; ++i) {
      if (worksheet[`${XLSX.utils.encode_col(i)}1`]) {
        header[i] = worksheet[`${XLSX.utils.encode_col(i)}1`].v;
      } else {
        continue;
      }
    }
    return header;
  }
}