<script>
    import { onMount, tick } from 'svelte';
    import { navigate } from "svelte-routing";
    export let item

    let avatarCharacter = 'A'
    var randomeKey = Math.floor(Math.random() * 3);

    
    onMount(async () =>{
        avatarCharacter = item.name?.charAt(0).toUpperCase()
        await tick();
        feather.replace();
    })

</script>

    <div class="avatar">
        {#if item.profilePic}
            <img src="{item.profilePic}" class="rounded-circle" alt="profile-pic"/>
        {:else}

            {#if randomeKey == 0}
                <span class="avatar-initial rounded-circle bg-primary op-5">{avatarCharacter}</span>
            {:else if 1 == randomeKey}
                <span class="avatar-initial rounded-circle bg-indigo op-5">{avatarCharacter}</span>
            {:else if 2 == randomeKey}
                <span class="avatar-initial rounded-circle bg-primary op-5">{avatarCharacter}</span>
            {:else}
                <span class="avatar-initial rounded-circle bg-gray-600 op-5">{avatarCharacter}</span>
            {/if}

        {/if}
    </div>
    <div class="pd-l-10">
        <p class="tx-medium mg-b-0">{item.name}</p>
        <small class="tx-12 tx-color-03 mg-b-0">{item.mobileNo}</small>
    </div>
    <div class="mg-l-auto d-flex align-self-center">
        <nav class="nav nav-icon-only">
            <i data-feather="chevron-right" class="nav-link"></i>
        </nav>
    </div>
