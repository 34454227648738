<script>
  import { onMount, afterUpdate, tick } from "svelte";
  import { Router, Link, Route, links, link, navigate } from "svelte-routing";
  import moment from "moment";
  import { activeMenu } from "../../stores/index.js";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import { pSuccess, pError } from "../../utils/notify.svelte";
  import STPModal from "../../AnnouncementsTextEditor/modal.svelte";
  import Scheduler from "../../utils/scheduler.svelte";

  export let _id = null;
  let showScheduler = false,
    isLoading = false,
    showModal = false;
  let announcementObj = {
    mandatory: "no",
    notification_app: true,
    notification_sms: true,
    message: "welcome to IMS",
    title: "",
  };
  let channelsList = [];
  let selectedStudentsList = [];
  let selectedParentsList = [];
  let selectedSysUsersList = [];
  let errorObj = {};

  // elements
  let elem_batchSelection,
    elem_studentSelection,
    elem_channelSelection,
    elem_parentSelection,
    elem_sysUserSelection;
  let elem_scrollStudentsList, elem_scrollParentsList, elem_scrollSysUserList;
  let isSortByName
  async function clearError(fieldName) {
    if (errorObj[fieldName]) {
      delete errorObj[fieldName];
    }
    errorObj = errorObj;
    // await tick()
  }

  async function api_getStudentsByIds(data) {

    let action = "students.getStudentsByIds";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_getParentsByIds(data) {

    let action = "parents.getParentsByIds";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_getSystemUserByIds(data) {

    let action = "systemUser.getSystemUserByIds";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_listStudents(data) {

    let action = "students.findStudentsExcludingIds";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_listParents(data) {

    let action = "parents.getParentsExcludingIds";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_listSysUsers(data) {

    let action = "systemUser.getSystemUsersExcludingIds";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_listBatches(data) {

    let action = "batch.listBatches";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_listChannels(data) {

    let action = "chat.getChannelList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_makeAnnouncement(data) {

    let action = "announcements.makeAnnouncement";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  
  let onCloseModal = function (event) {
    showModal = !showModal;
    console.log("sptObject : ", event.detail.sptObject);
    announcementObj.message = event.detail.sptObject.content;
    console.log("announcementObj.message ", announcementObj.message);
    _$(".modal-backdrop").remove();
  };
  let initStudentDropdown = async function () {
    await tick();
    _$(elem_studentSelection).selectize({
      preload: true,
      create: false,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      load: async function (query, callback) {
        this.clearOptions();
        let data = {
          start: 0,
          length: 10,
          search: query,
          excludeIds: announcementObj.studentIds || [],
          noPagination:true,
          isSortByName:true
        };
        let result = await api_listStudents(data);
        if (result.status) {
          callback(result.data.data);
        } else {
          callback([]);
        }
      },
      onBlur: function () {
        clearError("recipient");
      },
      onChange: async function (value) {
        if (!value) {
          return;
        } else {
          if (!announcementObj.studentIds) announcementObj.studentIds = [];
          announcementObj.studentIds.push(value);
          let result = await api_getStudentsByIds({
            _ids: announcementObj.studentIds,
            isSortByName:true
          });
          if (result.status) {
            selectedStudentsList = result.data;
          }
          _$(elem_studentSelection)[0].selectize.setValue("");
          await tick();
          _$(elem_studentSelection)[0].selectize.onSearchChange("");
        }
      },
    });
  };
  let initParentDropdown = async function () {
    await tick();
    _$(elem_parentSelection).selectize({
      preload: true,
      create: false,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      load: async function (query, callback) {
        this.clearOptions();
        let data = {
          start: 0,
          length: 10,
          search: query,
          excludeIds: announcementObj.parentIds || [],
          noPagination:true,
          isSortByName:true
        };
        let result = await api_listParents(data);
        if (result.status) {
          callback(result.data.data);
        } else {
          callback([]);
        }
      },
      onBlur: function () {
        clearError("recipient");
      },
      onChange: async function (value) {
        if (!value) {
          return;
        } else {
          if (!announcementObj.parentIds) announcementObj.parentIds = [];
          announcementObj.parentIds.push(value);
          let result = await api_getParentsByIds({
            _ids: announcementObj.parentIds,
          });
          if (result.status) {
            selectedParentsList = result.data.data;
          }
          _$(elem_parentSelection)[0].selectize.setValue("");
          await tick();
          _$(elem_parentSelection)[0].selectize.onSearchChange("");
        }
      },
    });
  };
  let initSysUserDropdown = async function () {
    await tick();
    _$(elem_sysUserSelection).selectize({
      preload: true,
      create: false,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      load: async function (query, callback) {
        this.clearOptions();
        let data = {
          start: 0,
          length: 10,
          search: { value: query },
          excludeIds: announcementObj.systemUserIds || [],
          noPagination:true,
          isSortByName:true
        };
        let result = await api_listSysUsers(data);
        if (result.status) {
          callback(result.data.data);
        } else {
          callback([]);
        }
      },
      onBlur: function () {
        clearError("recipient");
      },
      onChange: async function (value) {
        if (!value) {
          return;
        } else {
          if (!announcementObj.systemUserIds)
            announcementObj.systemUserIds = [];
          announcementObj.systemUserIds.push(value);
          let result = await api_getSystemUserByIds({
            _ids: announcementObj.systemUserIds,
          });
          if (result.status) {
            selectedSysUsersList = result.data.data;
          }
          _$(elem_sysUserSelection)[0].selectize.setValue("");
          await tick();
          _$(elem_sysUserSelection)[0].selectize.onSearchChange("");
        }
      },
    });
  };
  let initBatchDropdown = function () {
    _$(elem_batchSelection).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
      onBlur: function () {
        clearError("recipient");
      },
      load: async function (query, callback) {
        this.clearOptions();
        let data = {
          start: 0,
          length: 10,
          search: query,
          getModuleSubjects: false,
          sort: {
            field: "_id",
            dir: -1,
          },
          noPagination:true,
          isSortByName:true
        };
        let result = await api_listBatches(data);
        if (result.status) {
          callback(result.data.data);
        } else {
          callback([]);
        }
      },
      onChange: async function (value) {
        if (!value) return;
        announcementObj.batchIds = value;
        await tick();
        if (!value) _$(elem_batchSelection)[0].selectize.onSearchChange("");
      },
    });
  };
  let initChannelsDropdown = async function () {
    let channelsResult = await api_listChannels();
    if (channelsResult.status) {
      console.log(channelsResult.data);
      channelsList = channelsResult.data.data;
    } else {
      channelsList = [];
      console.error(channelsResult);
    }
    _$(elem_channelSelection).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: channelsList,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
      onBlur: function () {
        clearError("recipient");
      },
      onChange: async function (values) {
        // if(!value) _$(elem_channelSelection)[0].selectize.onSearchChange("");
        announcementObj.channelIds = values;
      },
    });
  };

  // let scheduleAnnouncement = async function () {
  //   if (isLoading) return;
  //   isLoading = true;
  //   await tick();
  //   if (announcementObj.message && announcementObj.message.trim()) {
  //     let result = await api_makeAnnouncement(announcementObj);
  //     console.log("scheduleAnnouncement", result);
  //     if (result.status) {
  //       pSuccess("", result.data);
  //     } else {
  //       pError("", result.errMsg || result.error || "something went wrong");
  //     }
  //   } else {
  //     return pError("", "Announcement message is required");
  //   }
  //   isLoading = false;
  //   await tick();
  // };
  let makeAnnouncement = async function () {
    if (isLoading) return;
    isLoading = true;
    await tick();
    let recipientAssigned =
      (announcementObj.batchIds && announcementObj.batchIds.length) ||
      (announcementObj.channelIds && announcementObj.channelIds.length) ||
      (announcementObj.studentIds && announcementObj.studentIds.length) ||
      (announcementObj.parentIds && announcementObj.parentIds.length) ||
      (announcementObj.systemUserIds && announcementObj.systemUserIds.length);
    if (!recipientAssigned) {
      isLoading = false;
      errorObj.recipient = "Select at least one recipient";
    } else if (!(announcementObj.message && announcementObj.message.trim())) {
      isLoading = false;
      errorObj.message = "Announcement message is required";
    } else if (!(announcementObj.title && announcementObj.title.trim())) {
      isLoading = false;
      errorObj.title = "Announcement title is required";
    } else {
      let result = await api_makeAnnouncement(announcementObj);
      console.log("makeAnnouncement", result);
      if (result.status) {
        pSuccess("", result.data);
        // get insertedId here
        navigate("/dashboard/announcements");
      } else {
        pError("", result.errMsg || result.error || "something went wrong");
      }
    }
    isLoading = false;
    await tick();
  };
  let scheduleChanged = async function (event) {
    console.log(" scheduleChanged : ", event.detail.schedule);
    announcementObj.schedule = event.detail.schedule;
    announcementObj.scheduled = true;
    await tick();
  };
  let clearSchedule = async function () {
    announcementObj.schedule = {};
    announcementObj.scheduled = false;
    await tick();
  };
  let reload = async function () {
    let result = await api_getAnnouncementDetails({ _id });
    if (result.status) {
      announcementObj = result.data.announcementObj;
      statusObj = result.data.statusObj;
    } else {
      pError("", result.errMsg || result.error || "something went wrong");
    }
  };
  afterUpdate(function () {
    feather.replace();
  });
  onMount(function () {
    console.log("mounted announcements add update");
    activeMenu.set("announcements");
    if (_id) {
      console.log("_id: ", _id);
      reload();
    }
    initBatchDropdown();
    initStudentDropdown();
    initParentDropdown();
    initSysUserDropdown();
    initChannelsDropdown();

    new PerfectScrollbar(elem_scrollStudentsList, {
      suppressScrollX: true,
    });
    new PerfectScrollbar(elem_scrollParentsList, {
      suppressScrollX: true,
    });
    new PerfectScrollbar(elem_scrollSysUserList, {
      suppressScrollX: true,
    });
  });
</script>

<style>
  .empty-state-img {
    text-align: center;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
</style>

<div class="content-body fadeIn">
  <div class="d-sm-flex align-itemd-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/announcements">Announcements</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            New Announcement
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">New Announcements</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
        make new announcement
      </p>
    </div>
    <div class="d-block">
      <button disable={isLoading} on:click={makeAnnouncement}
        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
        <i data-feather="send" class="wd-10 mg-r-5" />
        <ButtonLoader {isLoading} btnText={announcementObj.scheduled ? 'Schedule Announcement' : 'Make Announcement' } />
      </button>
    </div>
  </div>
  <hr class="full-width" />
  <div class="mg-b-20 ">
    <div class="row row-sm">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Announcement Details
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-sm-12">

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="formGroupExampleInput" class="d-flex" style="align-items: baseline">
                  <div>
                    Announcement Title
                    <span class="required-field">*</span>
                  </div>
                </label>
                <input type="text" class="form-control" bind:value={announcementObj.title} class:parsley-error={errorObj.title}
                placeholder="Announcement title" on:blur={()=> clearError('title') }>
                {#if errorObj.title}
                  <div class="parsley-errors-list">{errorObj.title}</div>
                {/if}
              </div>
            </div>

            <!-- <div class="form-row">
              <div class="form-group col-md-12">
                <label for="formGroupExampleInput" class="d-flex" style="align-items: baseline">
                  <div>
                    Announcement Message
                    <span class="required-field">*</span>
                  </div>
                  <div class="d-block mg-l-auto">
                    <div class="d-sm-flex">
                      <button class="btn btn-xs pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={()=> {
                        showModal = true;
                        }}>
                        <i data-feather="copy" class="wd-10 mg-r-5" />
                        Copy From Template
                      </button>
                    </div>
                  </div>
                </label>
                <textarea type="text" class="form-control" rows="3" style="resize: none"
                  bind:value={announcementObj.message} on:blur={()=> clearError('message')}
                  class:parsley-error={errorObj.message}
                  placeholder="Enter announcement message here" />
                {#if errorObj.message}
                  <div class="parsley-errors-list">{errorObj.message}</div>
                {/if}
              </div>
            </div> -->
          </div>
          <div class="col-sm-12">
            <div class="form-row">
              <div class = "form-group col-md-6 col-sm-12 d-none">
                <div class = "custom-control custom-checkbox d-none">
                  <input
                    type="checkbox"
                    id="notification-sms"
                    bind:checked={announcementObj.notification_sms}
                    name="notification-sms"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="notification-sms">
                    Send SMS notifications
                    <span class="required-field">*</span>
                  </label>
                </div>
              </div>
              <div class="form-group col-md-6 col-sm-12">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="notification-app"
                    bind:checked={announcementObj.notification_app}
                    name="notification-app"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="notification-app">
                    Send App notifications?
                    <span class="required-field">*</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-row">
              <label class="col-form-label col-sm-6">
                Is Announcement Mandatory?
                <span class="required-field">*</span></label>
              <div
                class="col-sm-6 d-sm-flex align-items-center justify-content-between ">
                <div class="d-sm-flex">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="announcement-mandatory-yes"
                      value="yes"
                      bind:group={announcementObj.mandatory}
                      name="announcement-mandatory-yes"
                      class="custom-control-input" />
                    <label
                      class="custom-control-label"
                      for="announcement-mandatory-yes">
                      Yes
                    </label>
                  </div>
                  <div class="custom-control custom-radio mg-sm-l-30">
                    <input
                      type="radio"
                      id="announcement-mandatory-no"
                      value="no"
                      bind:group={announcementObj.mandatory}
                      name="announcement-mandatory-no"
                      class="custom-control-input" />
                    <label
                      class="custom-control-label"
                      for="announcement-mandatory-no">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">


        <hr style="margin-top: 21px;" />
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="formGroupExampleInput" class="d-flex" style="align-items: baseline">
              <div>
                Announcement Message
                <span class="required-field">*</span>
              </div>
              <div class="d-block mg-l-auto">
                <div class="d-sm-flex">
                  <button class="btn btn-xs pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={()=> {
                    showModal = true;
                    }}>
                    <i data-feather="copy" class="wd-10 mg-r-5" />
                    Copy From Template
                  </button>
                </div>
              </div>
            </label>
            <textarea type="text" class="form-control" rows="1" style="resize: none" maxlength="300"
              bind:value={announcementObj.message} on:blur={()=> clearError('message')}
              class:parsley-error={errorObj.message}
              placeholder="Enter announcement message here" />
            {#if errorObj.message}
              <div class="parsley-errors-list">{errorObj.message}</div>
            {/if}
          </div>
        </div>
        <div class="row d-none">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Schedule Announcement For Later
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-sm-12">
            <div class="form-row">
              <div class="col-sm-12 col-md-3">Scheduled On</div>
              {#if announcementObj.schedule && announcementObj.schedule.scheduledOn}
                <div class="col-sm-12 col-md-3">
                  {moment(announcementObj.schedule.scheduledOn).format('ll')}
                </div>
                <div class="col-sm-12 col-md-3">
                  {moment(announcementObj.schedule.scheduledOn).format('LT')}
                </div>
                <button
                  class="btn btn-sm btn-icon btn-danger mg-l-auto"
                  on:click={clearSchedule}>
                  <i data-feather="x" />
                </button>
              {:else}
                <div class="col-sm-12 col-md-6">--</div>
                <button
                  class="btn btn-sm btn-icon btn-primary mg-l-auto"
                  on:click={() => (showScheduler = true)}>
                  <i data-feather="clock" />
                </button>
              {/if}
              <!-- <div class="form-group col-sm-12">
                <span class="mg-r-10">Repeat</span>
                <span class="mg-r-10">No Repeat</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mg-b-20 ">
    <div class="row row-sm">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Announcement Recipient
            </h6>
            <p
              class="parsley-errors-list"
              class:text-muted={!errorObj.recipient}>
              {#if errorObj.recipient}
                {errorObj.recipient}
              {:else}
                Select at least one Batch/ Channel/ Students/ Parents/ System
                Users
              {/if}
            </p>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-sm-4">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Batches</label>
                <select
                  bind:this={elem_batchSelection}
                  class="info slectizedd form-control"
                  placeholder="Select Batches">
                  <option value="">Select Batches</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Groups</label>
                <select
                  bind:this={elem_channelSelection}
                  class="info slectizedd form-control"
                  placeholder="Select groups">
                  <option value="">Select Channels</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-sm-4" />
          <div class="col-sm-4">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label class="d-block">Student</label>
                <select
                  bind:this={elem_studentSelection}
                  class="info slectizedd form-control"
                  placeholder="Select Student">
                  <option value="">Select Student</option>
                </select>
              </div>
            </div>
            <div class="col-sm-12">
              <div
                class="pos-relative ht-300"
                bind:this={elem_scrollStudentsList}>
                {#each selectedStudentsList as student, index}
                  <div
                    style="border-radius: 4px; border : 1px solid #ddd;    display: flex;align-items: center; margin-bottom: 15px;">
                    <div class="col-sm-11 pd-15">{student.name}</div>
                    <div
                      class="col-sm-1 d-flex align-self-center justify-content-end">
                      <nav class="nav nav-icon-only">
                        <a
                          href="javascript:void(0)"
                          class="nav-link d-sm-block"
                          on:click={() => {
                            let deleteFrom = announcementObj.studentIds.findIndex((id) => id == selectedStudentsList[index]._id);
                            selectedStudentsList.splice(index, 1);
                            selectedStudentsList = selectedStudentsList;
                            announcementObj.studentIds.splice(deleteFrom, 1);
                            announcementObj.studentIds = announcementObj.studentIds;
                            _$(elem_studentSelection)[0].selectize.onSearchChange('');
                          }}>
                          <i data-feather="trash" />
                        </a>
                      </nav>
                    </div>
                  </div>
                {:else}
                  <div class="empty-state-img">
                    <img
                      src="/images/empty.svg"
                      style="width: 25%;"
                      alt="empty" />
                    <p class="tx-bold">Please select some students.</p>
                  </div>
                {/each}
                <!-- table to use datatable's pagination -->
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label class="d-block">Parent</label>
                <select
                  bind:this={elem_parentSelection}
                  class="info slectizedd form-control"
                  placeholder="Select Parent">
                  <option value="">Select Parent</option>
                </select>
              </div>
            </div>
            <div class="col-sm-12">
              <div
                class="pos-relative ht-300"
                bind:this={elem_scrollParentsList}>
                {#each selectedParentsList as parent, index}
                  <div
                    style="border-radius: 4px; border : 1px solid #ddd;    display: flex;align-items: center; margin-bottom: 15px;">
                    <div class="col-sm-11 pd-15">{parent.name}</div>
                    <div
                      class="col-sm-1 d-flex align-self-center justify-content-end">
                      <nav class="nav nav-icon-only">
                        <a
                          href="javascript:void(0)"
                          class="nav-link d-sm-block"
                          on:click={() => {
                            let deleteFrom = announcementObj.parentIds.findIndex((id) => id == selectedParentsList[index]._id);
                            selectedParentsList.splice(index, 1);
                            selectedParentsList = selectedParentsList;
                            announcementObj.parentIds.splice(deleteFrom, 1);
                            announcementObj.parentIds = announcementObj.parentIds;
                            _$(elem_parentSelection)[0].selectize.onSearchChange('');
                          }}>
                          <i data-feather="trash" />
                        </a>
                      </nav>
                    </div>
                  </div>
                {:else}
                  <div class="empty-state-img">
                    <img
                      src="/images/empty.svg"
                      style="width: 25%;"
                      alt="empty" />
                    <p class="tx-bold">Please select some parents.</p>
                  </div>
                {/each}
              </div>
              <!-- table to use datatable's pagination -->
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label class="d-block">System User</label>
                <select
                  bind:this={elem_sysUserSelection}
                  class="info slectizedd form-control"
                  placeholder="Select System User">
                  <option value="">Select System User</option>
                </select>
              </div>
            </div>
            <div class="col-sm-12">
              <div
                class="pos-relative ht-300"
                bind:this={elem_scrollSysUserList}>
                {#each selectedSysUsersList as sysUser, index}
                  <div
                    style="border-radius: 4px; border : 1px solid #ddd;    display: flex;align-items: center; margin-bottom: 15px;">
                    <div class="col-sm-11 pd-15">{sysUser.name}</div>
                    <div
                      class="col-sm-1 d-flex align-self-center justify-content-end">
                      <nav class="nav nav-icon-only">
                        <a
                          href="javascript:void(0)"
                          class="nav-link d-sm-block"
                          on:click={() => {
                            let deleteFrom = announcementObj.systemUserIds.findIndex((id) => id == selectedSysUsersList[index]._id);
                            selectedSysUsersList.splice(index, 1);
                            selectedSysUsersList = selectedSysUsersList;
                            announcementObj.systemUserIds.splice(deleteFrom, 1);
                            announcementObj.systemUserIds = announcementObj.systemUserIds;
                            _$(elem_studentSelection)[0].selectize.onSearchChange('');
                          }}>
                          <i data-feather="trash" />
                        </a>
                      </nav>
                    </div>
                  </div>
                {:else}
                  <div class="empty-state-img">
                    <img
                      src="/images/empty.svg"
                      style="width: 25%;"
                      alt="empty" />
                    <p class="tx-bold">Please select some system users.</p>
                  </div>
                {/each}
              </div>
              <!-- table to use datatable's pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {JSON.stringify(announcementObj, null, 2)} -->
  </div>
</div>
<Scheduler on:close={scheduleChanged} bind:show={showScheduler} />

{#if showModal}
  <div class="mg-b-20">
    <STPModal on:close={onCloseModal} allowSelection={true} />
  </div>
{/if}
