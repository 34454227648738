<script>
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import { activeMenu } from "../stores";
  import api_handleApiCalls from "../utils/apiCallHandler.js";

  export let testObj;
  export let updateParent;
  let batchWiseResultObj = {};
  let batchArr = [];
  let tableArr = [];
  let testSections = [];
  let emptyState = false;
  let aBatchId;

  testSections = testObj?.sections || [];

  async function api_getBatchWiseTest(_id) {
    let action = "test.getBatchWiseResult";
    const response = await api_handleApiCalls(action, { testId: _id });
    return response;
  }

  function forBatchArrIndex(batchObj) {
    return batchObj._id == aBatchId;
  }

  function forABatchDetails(batchId) {
    aBatchId = batchId;
    const indx = batchArr.findIndex(forBatchArrIndex);
    return batchArr[indx]?.name || "-";
  }

  onMount(async function () {
    testObj.menu = "batchwiseresult";
    if (updateParent) updateParent();

    activeMenu.set("tests");

    const result = await api_getBatchWiseTest(testObj._id);

    if (result.status) {
      batchWiseResultObj = result.data.batchWiseResult;
      batchArr = result.data.batchArr;
    }

    if (!batchArr.length) {
      emptyState = true;
      tableArr = [];
      return;
    } else emptyState = false;

    tableArr = Object.keys(batchWiseResultObj);

    for (let i = 0; i < tableArr.length; i++) {
      const batch = batchWiseResultObj[tableArr[i]];

      for (let j = 0; j < batch.length; j++) {
        if (j === 0) var percentileMarks = batch[j]?.total?.totalMarks || 0;

        batch[j].totalMarksForPercentile = percentileMarks;
        batch[j].sections = testSections;
      }

      batchWiseResultObj[tableArr[i]] = batch;

      for (let q = 0; q < testObj.sections.length; q++) {
        const sectionqIdArr = testObj.sections[q].questions;
        const sectionTitle = testObj.sections[q].title;

        for (let j = 0; j < batchWiseResultObj[tableArr[i]].length; j++) {
          let sectionalAttempt = 0;
          let sectionalCorrect = 0;
          let sectionalWrong = 0;
          let sectionalScore = 0;

          if (!batchWiseResultObj[tableArr[i]][j].sectionalData) {
            batchWiseResultObj[tableArr[i]][j].sectionalData = {};
          }

          batchWiseResultObj[tableArr[i]][j].testStats.forEach((item) => {
            for (let k = 0; k < sectionqIdArr.length; k++) {
              if (item.questionId == sectionqIdArr[k]) {
                if (item.answer) sectionalAttempt++;
                if (item.correct) sectionalCorrect++;
                if (!item.correct && item.hasOwnProperty("correct")) sectionalWrong++;
                sectionalScore += item.marks || 0;
              }
            }
          });

          batchWiseResultObj[tableArr[i]][j].sections.forEach((item) => {
            if (item.title === sectionTitle) {
              batchWiseResultObj[tableArr[i]][j].sectionalData[sectionTitle] = {
                attempt: sectionalAttempt,
                correct: sectionalCorrect,
                wrong: sectionalWrong,
                score: sectionalScore,
              };
            }
          });
        }
      }
    }
  });

  function handleNameClick(studentId) {
    const testId = testObj._id;
    navigate(`/dashboard/tests/view/studentdetailedreport/${studentId}/${testId}`);
  }
</script>

<div class="content-body fadeIn">
  <div class="pd-l-20 pd-r-20 pd-b-20">
    <div class="df-example demo-table" style="width: 100%;">
      {#each tableArr as batchTable}
      <table class="table table-bordered mg-b-20 pd-20" style="width: 100%;">
        <thead>
          <tr>
            <th colspan="10" style="border: none;">
              <h4 class="mg-5">{forABatchDetails(batchTable)}</h4>
            </th>
          </tr>
          <tr>
            <th rowspan="2">Rank</th>
            <th rowspan="2" style="width: 40%;">Student Name</th>
            {#each testSections as section}
            <th colspan="4">
              <span>{section.title}</span>
              <div>(Total Questions: {section.questions.length})</div>
            </th>
            {/each}
            <th rowspan="2">Total Score</th>
            <th rowspan="2">Percentile (%)</th>
            <th rowspan="2">Percentage (%)</th>
            <th rowspan="2">Accuracy (%)</th>
            <th rowspan="2">Time Taken</th>
          </tr>
          <tr>
            {#each testSections as section}
            <th>A</th>
            <th>C</th>
            <th>W</th>
            <th>Score</th>
            {/each}
          </tr>
        </thead>
        <tbody>
          {#each batchWiseResultObj[batchTable] as result}
          <tr>
            <td>{result.rank || "-"}</td>
            <td style="cursor: pointer;" on:click={()=> handleNameClick(result.userData?._id)}
              >
              <img src={result.userData?.profilePic || "/images/man.png" } class="avatar rounded-circle mg-r-15"
                alt={result.userData?.name?.[0]?.toUpperCase() || "-" } />
              <div>
                <h6>{result.userData?.name || "-"}</h6>
                <span>{result.userData?.mobileNo || "-"}</span>
              </div>
            </td>
            {#each testSections as section}
            <td>{result.sectionalData?.[section.title]?.attempt || 0}</td>
            <td>{result.sectionalData?.[section.title]?.correct || 0}</td>
            <td>{result.sectionalData?.[section.title]?.wrong || 0}</td>
            <td>{result.sectionalData?.[section.title]?.score || 0}</td>
            {/each}
            <td>{result.total?.totalMarks || 0}</td>
            <td>
              {result.totalMarksForPercentile
              ? `${((result.total?.totalMarks / result.totalMarksForPercentile) * 100).toFixed(2)}%`
              : "0%"}
            </td>
            <td>
              {result.total?.maximumMarks
              ? `${((result.total?.totalMarks / result.total.maximumMarks) * 100).toFixed(2)}%`
              : "0%"}
            </td>
            <td>
              {result.total?.totalAttempted
              ? `${((result.total.totalCorrectAns / result.total.totalAttempted) * 100).toFixed(2)}%`
              : "0%"}
            </td>
            <td>
              {((Math.abs(new Date(result.startedAt) - new Date(result.endedOn))) / 60000).toFixed(2)}{" "}
              min
            </td>
          </tr>
          {/each}
        </tbody>
      </table>
      {/each}
    </div>

    {#if emptyState}
    <div style="text-align: center;">
      <img src="/images/empty.svg" style="width: 200px" alt="empty" />
      <p>No test results found.</p>
    </div>
    {/if}
  </div>
</div>
