<script>
    import { Router, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick, onDestroy } from 'svelte';
    import { _mobileNo, _verifyOtpToken, _otp, _registerOtpToken } from "../store.js";
    import { pSuccess, pError } from "../dashboard/utils/notify.svelte"
    import ButtonLoader from "../dashboard/utils/button_loader.svelte"

    let isLoading = false;
    let otp;
    let otpEle;
    let local_verifyOtpToken;
    let local_otp;
    const errorDetails = {};

    _verifyOtpToken.subscribe(value => {
        local_verifyOtpToken = value;
    });

    _otp.subscribe(value => {
        local_otp = value;
    });


    function clearFields() {
        otp = "";
        clearValidations();
    }

    function clearValidations() {
        delete errorDetails.otp;
    }
    function validate() {
        clearValidations();
        tick();
        // var moNoReg = new RegExp("^[7-9][0-9]{9}$")
        // if (!moNoReg.test(otp) || otp.length != 10) {
        if (String(otp).length != 6) {
            errorDetails.otp = "Please enter valid OTP";
        }
        if (Object.values(errorDetails).length > 0) {
            return false;
        } else {
            return true;
        }
    }

    async function verifyOtp() {
        if (isLoading) return;
        isLoading = true
        tick()
        const isValid = validate();
        if (isValid) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var payload = JSON.stringify({
                action: "auth.verifyOtp",
                data: {
                    otpToken: local_verifyOtpToken,
                    otpCode: otp
                },
            });
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: payload,
            };
            try {
                var resObj = await fetch(API_URL, requestOptions);
                var response = await resObj.json();
                console.log(response);
                _registerOtpToken.set(response.data.regToken);
                isLoading = false;
                pSuccess("", "Mobile number verified successfully. Please fill the form to complete registration")
                tick()
                navigate("/signup/institute-info", { replace: true });

            } catch (error) {
                pError("", response.errMsg || response.error || "something went wrong")
                console.error("error", error);
            }
        }
        isLoading = false;
        tick()
    }
    async function init() {
        clearFields();
        otpEle.focus();
        otp = local_otp;
        console.log(local_otp, local_verifyOtpToken)
    }
    onMount(init);
    onDestroy(clearFields);


</script>


<div class="sign-wrapper mg-lg-r-50 mg-xl-r-60 fadeIn" style="flex: 1;">
    <div class="pd-t-20 wd-100p">
        <h4 class="tx-color-01 mg-b-5">Create New Account</h4>
        <p class="tx-color-03 tx-16 mg-b-40">It's free to signup and only takes a minute.</p>

        <div class="form-group">
            <label>OTP</label>
            <input bind:this={otpEle} value={otp} type="number" on:keyup={(e)=> e.key === 'Enter' && verifyOtp()}
            on:input={function (e) {
            this.value = this.value.slice(0, 6);
            otp = this.value;
            }}
            on:blur={clearValidations}
            class:parsley-error={errorDetails.otp}
            class="form-control" placeholder="Enter your OTP">
            {#if errorDetails.otp}
            <div class="parsley-errors-list">{errorDetails.otp}</div>
            {/if}

        </div>



        <button on:click={verifyOtp} class="btn btn-brand-02 btn-block" disabled={isLoading}>
            <ButtonLoader isLoading={isLoading} btnText="Verify" />
        </button>

    </div>
</div><!-- sign-wrapper -->
