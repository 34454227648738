<script>
  import { Router, Link, Route } from "svelte-routing";
  import { onMount } from "svelte";
  import List from "./list/list.svelte";

  function init() {
    console.log("redirecting ");
  }
  onMount(init);
</script>

<Router>
  <Route path="">
    <List />
  </Route>
</Router>
