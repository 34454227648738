<script>
  import { Router, Link, Route } from "svelte-routing";
  import List from "./list/list.svelte";
  import AddUpdate from "./addupdate/addupdate.svelte";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../stores";
  import MODULES from "../utils/modules.js" 
  import Forbidden from "../forbidden.svelte"

  function init() {
    console.log("mounting systemUser list");
    activeMenu.set("systemuser");
  }
  onMount(init);
</script>

<Router>
  <Route path=":id" let:params>
    {#if MODULES.hasAccess(MODULES.VIEW_SYSTEM_USERS_DETAILS)}
    <AddUpdate id={params.id} />
    {:else}
    <Forbidden />
    {/if}
  </Route>
  <Route path="">
    <List />
  </Route>
</Router>
