<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import { activeMenu } from "../../stores";
    import FolderExplorer from "../../folderCmp/folderExplorer.svelte";
    import ButtonLoader from "../../utils/button_loader.svelte";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import { userData } from "../../stores";
    import MODULES from "../../utils/modules.js"
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import ExcelJS from 'exceljs'
    import { API_URL } from "../../../config.js"

    let localUserData = {
        fullName: "...",
        role: "...",
        avatar: "/images/man.png",
        access: {
            home: false,
            students: false,
            settings: false,
        },
    };

    var folderExplorerViewOnlyMode = false

    const unsubscribe = userData.subscribe((value) => {
        localUserData = value;
    });

    export let _id;
    let todays_date

    let elemSelectClass
    let selectizeInstanceClass

    let batchObj = {
        _id: _id,
        name: "",
        code: "",
        startDate: null,
        amount: "",
        taxApplicable: false,
        feesIncludingTax: false,
        taxPercent: 0,
        isFree: 'no',
        actualBatchFee: 0,
        totalPayableAmount: 0,
        deductedTax: 0,
        prefix: "",
        sequence: 0
    }


    let flagobj = {
        isfree1: 'no',
        isfree2: 'no',
        isfree3: 'yes',
        isfree4: 'yes'

    }

    let _actualBatchFee = 0;
    let _totalPayableFee = 0;
    let _deductedTax = 0;

    let isLoading = false;
    let exportLoading = false;

    let batchAmountEle;
    let batchTaxEle;
    let tempConvertValuesEle;

    let errorObj = {};
    let eleBranch;
    let eleTaxApplicable;
    let eleCollectTax;

    let selectizeTaxApplicable;
    let selectizeFeesIncludingTax;
    let batchStartDateStr = "";

    let elePrefix = "";
    let eleSequence = "";
    let minTomorrow

    let branchEle;
    let dateFrom, dateTo
    let commentSearch = ""
    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    }

    function convertDateObjToStr(date) {
        var month = '' + (date.getMonth() + 1);
        var day = '' + date.getDate();
        var year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var dateString = [year, month, day].join('-');
        return dateString;
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');

    }
    function convertDateStrToObj(selectedDate) {
        selectedDate = selectedDate.split("-");
        var year = selectedDate[0];
        var month = selectedDate[1] - 1;
        var day = selectedDate[2];
        return new Date(year, month, day);
    }

    let updateBatchCode = () => {
        if (!batchObj.code) batchObj.code = (batchObj.name).substr(0, 4).toUpperCase();
    }

    async function api_addUpdateBatch(data) {
        let action = "batch.addUpdateBatch";
        var response = await api_handleApiCalls(action, data);
        return response;
    }


    async function api_getBatchById(data) {
        let action = 'batch.getBatchById';
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_fetchInstituteBranchList(data) {

        let action = "instituteBranch.fetchInstituteBranchList";
        var response = await api_handleApiCalls(action, data);
        return response;
    }

    let loadBranchBranch = async function (branchId) {

        var branchRes = await api_fetchInstituteBranchList({ start: 0, length: 100, sort: { name: 1 } });

        let branchArr = branchRes.data.data;

        branchEle = _$(eleBranch).selectize({
            maxItems: 1,
            valueField: '_id',
            labelField: 'name',
            searchField: 'name',
            options: branchArr,
            placeholder: 'Select branch',
            create: false,
            items: [branchId]
        });

    }

    let updateNetTotalAmount = function () {

        let amount = Number(batchObj.amount);
        let taxApplicable = batchObj.taxApplicable;
        let taxPercent = Number(batchObj.taxPercent);
        let feesIncludingTax = batchObj.feesIncludingTax;

        _deductedTax = 0;
        batchObj.deductedTax = 0;

        if (!taxApplicable) {

            tempConvertValuesEle.setRawValue(amount);
            _actualBatchFee = tempConvertValuesEle.getFormattedValue()
            batchObj.actualBatchFee = amount;

            _totalPayableFee = tempConvertValuesEle.getFormattedValue();
            batchObj.totalPayableAmount = amount;
        } else {
            if (feesIncludingTax) {
                tempConvertValuesEle.setRawValue(amount);

                _totalPayableFee = tempConvertValuesEle.getFormattedValue();
                batchObj.totalPayableAmount = amount;

                let deductedTax = Math.ceil(((amount * taxPercent) / (taxPercent + 100)));
                let actualAmt = amount - deductedTax;

                tempConvertValuesEle.setRawValue(actualAmt);

                _actualBatchFee = tempConvertValuesEle.getFormattedValue();
                batchObj.actualBatchFee = actualAmt;

                _deductedTax = deductedTax;
                batchObj.deductedTax = deductedTax;

            } else {
                let taxAmt = Math.ceil((amount * taxPercent) / 100 + amount);

                tempConvertValuesEle.setRawValue(taxAmt);
                _totalPayableFee = tempConvertValuesEle.getFormattedValue();

                batchObj.totalPayableAmount = taxAmt;

                tempConvertValuesEle.setRawValue(amount);

                _actualBatchFee = tempConvertValuesEle.getFormattedValue();
                batchObj.actualBatchFee = taxAmt;
            }
        }
    }

    let validateBatch = () => {
        errorObj = {};
        var newErrorObj = {};
        if (!batchObj.name) {
            newErrorObj.name = "Batch Name is required Field.";
        }
        if (!batchObj.code) {
            newErrorObj.code = "Batch code is required Field.";
        }
        if (batchObj.isFree == "no" && !batchObj.amount) {
            newErrorObj.amount = "Batch amount is required if this batch is not free.";
        }
        if (batchObj.code && batchObj.code.length < 3) {
            newErrorObj.code = "Batch code should be Minimum 2 Charaters";
        }

        if (!batchObj.batchClass) {
            newErrorObj.batchClass = "Batch Class is required";
        }

        if (!batchObj.startDate) {
            newErrorObj.startDate = "Batch startDate is required Field.";
        }
        if (batchObj.isFree == "no" && !batchObj.amount) {
            newErrorObj.amount = "Batch amount is required if this batch is not free.";
        }

        if (!batchObj.prefix) {
            newErrorObj.prefix = "Please enter batch prefix.";
        }
        if (!batchObj.sequence) {
            newErrorObj.sequence = "Please enter batch sequence.";
        }
        if (batchObj.sequence < 0 || batchObj.sequence < 1) {
            newErrorObj.sequence = "Please enter a valid sequence, it should not be a fractional number nor less than zero";
        }

        errorObj = newErrorObj;
        if (Object.keys(errorObj).length > 0) {
            isLoading = false;
            return false;
        }
        return true;
    }
    async function setInitialDate() {
        let _tomorrows_date = new Date()
        _tomorrows_date.setDate(_tomorrows_date.getDate() + 1)

        todays_date = formatDate(new Date().toString())
        minTomorrow = formatDate(_tomorrows_date.toString())
        batchObj.startDate = formatDate(todays_date);


        batchObj.startDate = convertDateStrToObj(batchObj.startDate);
        await tick()
    }
    let updateBatch = async function () {

        if (batchObj.isFree == 'yes') {

            batchAmountEle.setRawValue(Number(0));
            batchTaxEle.setRawValue(Number(0));
        }

        batchObj.startDate = convertDateStrToObj(batchStartDateStr);
        batchObj.branch = _$(eleBranch).val();
        batchObj.batchClass = _$(elemSelectClass).val();
        isLoading = true;
        if (validateBatch()) {

            var batchRes = await api_addUpdateBatch(batchObj);
            if (batchRes.status) {
                // create success
                PNotify.success({
                    title: 'Success',
                    text: batchRes.data,
                    delay: 3000
                })
                setTimeout(function () {
                    window.history.back();
                }, 500)
            } else {
                PNotify.error({
                    title: 'Error',
                    text: batchRes.errMsg,
                    delay: 3000
                })
            }
        }
    }
    // $: batchObj.taxPercent && updateNetTotalAmount();


    async function api_getClassList(data) {
        var resData = await api_handleApiCalls('instituteClass.fetchInstituteClasses', data);
        if (resData.status) {
            return resData.data;
        } else {
            return [];
        }
    }

    async function bindClassSelectize(selectedClassId = '') {
        let classList = await api_getClassList({ orgId: localUserData.orgId });
        console.log('classList:', classList); // Ensure this logs the expected data

        selectizeInstanceClass = _$(elemSelectClass).selectize({
            maxItems: 1,
            valueField: '_id',
            labelField: 'name',
            searchField: 'name',
            options: classList,
            placeholder: 'Select class',
            create: true,
            items: [selectedClassId]
        });

        console.log('Selected Class ID:', selectedClassId);
        console.log('After setting:', selectizeInstanceClass[0].selectize.getValue());

    }



    onMount(async () => {
        feather.replace()
        setInitialDate()
        activeMenu.set('batch');
        if (!(MODULES.hasAccess(MODULES.EDIT_BATCH))) {
            folderExplorerViewOnlyMode = true
        }
        var batchDetailsRes = await api_getBatchById({ _id: _id });
        if (batchDetailsRes.status) {
            var batchData = batchDetailsRes.data;
            if (!batchData.isFree) batchData.isFree = "no";
            batchObj = {
                _id: batchData._id,
                name: batchData.name,
                code: batchData.code,
                startDate: batchData.startDate,
                amount: batchData.amount,
                taxApplicable: batchData.taxApplicable,
                feesIncludingTax: batchData.feesIncludingTax,
                actualBatchFee: batchData.actualBatchFee,
                taxPercent: batchData.taxPercent,
                isFree: batchData.isFree,
                prefix: batchData.prefix ? batchData.prefix : "",
                sequence: batchData.sequence ? batchData.sequence : ""
            };
            bindClassSelectize(batchData.batchClass)
            loadBranchBranch(batchData.branch);
            batchObj.startDate = new Date(batchObj.startDate);
            batchStartDateStr = convertDateObjToStr(batchObj.startDate);
            if (batchData.isInSyncWith) {
                batchObj.isInSyncWith = batchData.isInSyncWith;
            }

            tempConvertValuesEle = new Cleave('.tempConvertValues', {
                numeral: true,
                prefix: '₹ ',
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'lakh',
                rawValueTrimPrefix: true
            });

            batchAmountEle = new Cleave('.batch-amount-ele', {
                numeral: true,
                prefix: '₹ ',
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'lakh',
                rawValueTrimPrefix: true,
                onValueChanged: function (e) {
                    if (e.target.rawValue == "") {
                        e.target.rawValue = 0;
                        if (batchAmountEle)
                            batchAmountEle.setRawValue(0);
                    }
                    e.target.rawValue = Number(e.target.rawValue);
                    batchObj.amount = e.target.rawValue;
                    updateNetTotalAmount();
                }
            });
            batchAmountEle.setRawValue(batchData.amount);

            batchTaxEle = new Cleave('.batch-tax-ele', {
                numeral: true,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'lakh',
                rawValueTrimPrefix: true,
                onValueChanged: function (e) {
                    e.target.rawValue = Number(e.target.rawValue);
                    if (e.target.rawValue > 100) {
                        batchObj.taxPercent = 100;
                        batchTaxEle.setRawValue(100);
                    } else {
                        batchObj.taxPercent = e.target.rawValue;
                    }
                    updateNetTotalAmount();
                }
            });
            batchTaxEle.setRawValue(batchData.taxPercent);

            // eleSequence =new Cleave(".batch-sequence",{
            //     numeral:true,
            //     numeralPositiveOnly: true,
            //     rawValueTrimPrefix:true,
            //     onValueChanged: function (e) {
            //         e.target.rawValue = Number(e.target.rawValue);
            //         batchObj.sequence = e.target.rawValue;
            //         eleSequence.setRawValue(e.target.rawValue);
            //     }
            // })
            // eleSequence.setRawValue(batchData.batchSequence);

            selectizeTaxApplicable = _$(eleTaxApplicable).selectize({
                maxItems: 1,
                create: false,
                items: [batchObj.taxApplicable?.toString()],
                onChange: function (value) {
                    batchObj.taxApplicable = JSON.parse(value.toLowerCase());
                    if (batchObj.taxApplicable) {
                        selectizeFeesIncludingTax[0].selectize.enable();

                    } else {
                        selectizeFeesIncludingTax[0].selectize.disable();
                    }
                    updateNetTotalAmount();
                }
            });

            selectizeFeesIncludingTax = _$(eleCollectTax).selectize({
                maxItems: 1,
                create: false,
                items: [batchObj.feesIncludingTax?.toString()],
                onChange: function (value) {
                    batchObj.feesIncludingTax = JSON.parse(value.toLowerCase());
                    updateNetTotalAmount();
                    // console.log("feesIncludingTax : ",value);
                }
            });



            if (batchObj.taxApplicable) {
                selectizeFeesIncludingTax[0].selectize.enable();

            } else {
                selectizeFeesIncludingTax[0].selectize.disable();
            }

        }
    })

    function viewStudents() {
        navigate(("/dashboard/students/search/" + JSON.stringify({
            batches: [_id]
        })))
    }

    // function handleDateKeydown(){
    //     return false;
    // }
    const handleClickExport = async () => {
        if (exportLoading) return;


        const data = {
            batchId: _id,
            dateFrom: new Date(dateFrom),
            dateTo: new Date(dateTo),
            search: commentSearch ? commentSearch : ""
        }
        exportLoading = true;
        const res = await api_handleApiCalls('batch.batchwiseConversationExportStudentList', data)
        exportLoading = false;

        if (res.status) {
            let studentCoversationList = res.data
            let allComments = studentCoversationList;
            //   if (allComments ) {
            generateWorkBook(allComments);
            //   }

        }
        else {
            exportLoading = false;

        }
    }
    async function generateWorkBook(commentsDataList) {

        const workbook = new ExcelJS.Workbook();

        const worksheet = workbook.addWorksheet("Batchwise Conversation Data", {
            pageSetup: { paperSize: 9, orientation: "landscape" },
        });

        let fileName = "Batchwise_Conversation_Data_Export_" + new Date().getTime() + "_.xlsx";

        let columnsArr = [
            { header: "Sr No.", key: "serialNo", width: 15 },
            { header: "Name of Student", key: "name", width: 15 },
            { header: "Mobile Number", key: "mobileNo", width: 15 },
            { header: "Parent Contact Number", key: "parentContactNo", width: 15 },

            { header: "Batch", key: "batch", width: 15 },
            { header: "Admin Name/ Counselor Name", key: "adminName", width: 15 },
            { header: "Chat Date and Time ", key: "adminName", width: 15 },
            { header: "Chat", key: "topic", width: 15 },
            { header: "Attachment", key: "subject", width: 50 },

        ];

        worksheet.columns = columnsArr;

        for (let idx = 0; idx < commentsDataList.length; idx++) {
            let commentsResult = commentsDataList[idx];

            // if(commentsResult?.comments && commentsResult.comments.length > 0){
            for (let index = 0; index < commentsResult.comments.length; index++) {
                const commentsColumnArr = [];
                const comment = commentsResult.comments[index];

                /* Adding Column */
                commentsColumnArr.push(idx + 1); // Col - 1
                commentsColumnArr.push(commentsResult?.student && commentsResult?.student?.name ? commentsResult?.student?.name : ""); // Col - 2
                commentsColumnArr.push(commentsResult?.student && commentsResult?.student?.mobileNo ? commentsResult?.student?.mobileNo : "");
                commentsColumnArr.push(commentsResult?.parentContactNo ? commentsResult?.parentContactNo : "");
                commentsColumnArr.push(commentsResult?.batch ? commentsResult?.batch : "");
                commentsColumnArr.push(commentsResult?.counselorName ? commentsResult?.counselorName : "");
                let commentDate = ''
                if (comment.createdOn) commentDate = moment(new Date(comment.createdOn)).format("DD/MM/YYYY hh:mm");
                commentsColumnArr.push(commentDate ? commentDate : "");
                commentsColumnArr.push(comment.comment ? comment.comment : "");
                commentsColumnArr.push(comment.type == 'file' && comment.attachment && comment.attachment.downloadUrl
                    ? comment.attachment.downloadUrl : "");

                /* Row */
                worksheet.addRow(commentsColumnArr);
            }
            //   }






        }

        const buffer = await workbook.xlsx.writeBuffer();

        saveFile(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName
        );
        //   await workbook.xlsx.writeFile(fileName);
    }
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        // var view = new Uint8Array(buf);
        var view = s;
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    const saveFile = async (blob, fileName) => {
        const a = document.createElement("a");
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.addEventListener("click", (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };
    function uniqueId() {
        // always start with a letter (for DOM friendlyness)
        var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
        do {
            // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
            var ascicode = Math.floor(Math.random() * 42 + 48);
            if (ascicode < 58 || ascicode > 64) {
                // exclude all chars between : (58) and @ (64)
                idstr += String.fromCharCode(ascicode);
            }
        } while (idstr.length < 32);

        return idstr.toLowerCase();
    }
</script>

<style>
    .hide-section {
        display: none;
    }
</style>
<div class="content-body fadeIn">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 ">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/batch">Batches</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/batch">Update</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {batchObj.name}
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                {batchObj.name}
            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">Update batch details</p>
        </div>
        <div class="d-flex flex-md-row flex-xs-col justify-content-between align-items-center">
            <div class="col-sm fixed ">
                <div class="input-group">

                    <input type="text" class="form-control" id="testName" placeholder="Search Comment"
                        bind:value={commentSearch }>
                    <!-- <div class="input-group-prepend">
                    <span class="input-group-text"><i data-feather="search"></i></span>
                  </div> -->
                </div>
            </div>

            <div class=" form-group mg-r-6">
                <label class=" ">Date from:</label>
                <input type="date" onkeydown="return false" bind:value={dateFrom} class="form-control follow-up-date "
                    placeholder="Date from" />
            </div>

            <div class=" form-group mg-r-6">
                <label class="">Date to:</label>
                <input type="date" onkeydown="return false" bind:value={dateTo} class="form-control follow-up-date "
                    placeholder="Date to" />
            </div>
            <button class="btn btn-sm pd-x-15 btn-secondary btn-uppercase mg-l-5" on:click={handleClickExport}
                disabled={exportLoading}>
                <!-- <i data-feather="save" class="wd-10 mg-r-5"></i> -->
                <ButtonLoader isLoading={exportLoading} btnText="Export" />
            </button>
            <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={viewStudents}>
                <ButtonLoader isLoading={false} btnText="View Students" />
            </button>
            {#if MODULES.hasAccess(MODULES.EDIT_BATCH)}

            <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={updateBatch}
                disabled={isLoading}>
                <i data-feather="save" class="wd-10 mg-r-5"></i>
                <ButtonLoader isLoading={isLoading} btnText="Save" />
            </button>
            {/if}
        </div>
    </div>
    <hr class="full-width">
    <div class="mg-b-20 ">
        <div class="row row-sm">
            <div class="col-md-7 col-sm-5">
                <div class="row">
                    <div class="col-sm-12">
                        <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Batch Details </h6>
                        <hr style="margin-top: 5px;">
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="d-block">Name<span class="required-field">*</span></label>
                                <input type="text" bind:value={batchObj.name} class="form-control"
                                    on:change={updateBatchCode} class:parsley-error={errorObj.name}
                                    placeholder="Enter batch name here" />
                                {#if errorObj.name}
                                <div class="parsley-errors-list">{errorObj.name}</div>
                                {/if}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="d-block">Batch Joining Code<span class="required-field">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter batch code here"
                                    bind:value={batchObj.code} class:parsley-error={errorObj.code}
                                    on:blur={()=>clearError("code")}/>
                                {#if errorObj.code}
                                <div class="parsley-errors-list">{errorObj.code}</div>
                                {/if}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="d-block">Batch Start Date<span class="required-field">*</span></label>
                                <input type="date" bind:value={batchStartDateStr} class="form-control" min={todays_date}
                                    class:parsley-error={errorObj.startDate} onkeydown="return false"
                                    placeholder="Select Batch Start Date" on:blur={()=>clearError("startDate")} />
                                {#if errorObj.startDate}
                                <div class="parsley-errors-list">{errorObj.startDate}</div>
                                {/if}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="classSelect" class="d-block">Class/Stream<span
                                    class="required-field">*</span></label>
                            <select class=" col-md-12" id="classSelect" bind:this={elemSelectClass}></select>
                            {#if errorObj.batchClass}
                            <div class="parsley-errors-list">{errorObj.batchClass}</div>
                            <!-- content here -->
                            {/if}
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="d-block">Branch</label>
                                <select bind:this={eleBranch} class="info slectizedd form-control"
                                    placeholder="Select Branch for this batch">
                                    <option value="">Select Branch</option>
                                </select>

                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Roll Number Sequence</h6>
                        <hr style="margin-top: 5px;">
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="d-block">Roll Number Prefix <span class="required-field">*</span></label>
                                <input type="text" bind:value={batchObj.prefix} class="form-control "
                                    class:parsley-error={errorObj.prefix} placeholder="Please Enter Prefix" />
                                {#if errorObj.prefix}
                                <div class="parsley-errors-list">{errorObj.prefix}</div>
                                {/if}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="d-block">Roll Number Sequence<span class="required-field">*</span></label>
                                <input min="0" type="number" step="1" bind:value={batchObj.sequence}
                                    class="form-control batch-sequence" class:parsley-error={errorObj.sequence}
                                    placeholder="Please Enter Sequence" />
                                <!-- on:blur={()=>clearError("startDate")} {#if errorObj.startDate}
                                    <div class="parsley-errors-list">{errorObj.startDate}</div>
                                {/if} -->
                                {#if errorObj.sequence}
                                <div class="parsley-errors-list">{errorObj.sequence}</div>
                                {/if}
                            </div>
                        </div>
                    </div>
                    <!-- class:parsley-error={errorObj.startDate} -->
                    {#if !(batchObj.isFree=="yes")}
                    <div class="col-sm-12">
                        <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Fees Structure</h6>
                        <hr style="margin-top: 5px;">
                    </div>
                    <div class="col-sm-12 mg-b-15">
                        <div class="row">
                            <div class="col-md-7 col-sm-12">
                                Is this a Demo or Free Batch ?
                            </div>
                            <div class="col-md-5 col-sm-12">
                                <div class="form-row">
                                    <div class="form-group col-md-4 m-0">
                                        <div class="custom-control custom-radio">
                                            <input type="radio" id="isFreeNo" value="no" bind:group={batchObj.isFree}
                                                name="isFree" class="custom-control-input" disabled>
                                            <label class="custom-control-label" for="isFreeNo">No</label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4 m-0">
                                        <div class="custom-control custom-radio">
                                            <input type="radio" id="isFreeYes" value="yes" bind:group={batchObj.isFree}
                                                name="isFree" class="custom-control-input" disabled>
                                            <label class="custom-control-label" for="isFreeYes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" class:hide-section={batchObj.isFree=="yes" }>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="d-block">Batch Fees<span class="required-field">*</span></label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">₹</span>
                                    </div>
                                    <input style="text-align: right;" class:parsley-error={errorObj.amount} type="text"
                                        class="form-control batch-amount-ele" placeholder="Amount" />
                                </div>
                                {#if errorObj.amount}
                                <div class="parsley-errors-list">{errorObj.amount}</div>
                                {/if}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" class:hide-section={batchObj.isFree=="yes" }>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label>Tax</label>
                                <select bind:this={eleTaxApplicable} class="info slectizedd form-control">
                                    <option value={false}>Is Not Applicable</option>
                                    <option value={true}>Is Applicable</option>
                                </select>
                            </div>
                            <div class="form-group col-md-4 d-flex align-items-end">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <input type="text" disabled={!batchObj.taxApplicable}
                                        class="form-control batch-tax-ele" placeholder="How many percent?" />
                                </div>
                            </div>
                            <div class="form-group col-md-4 d-flex align-items-end">
                                <select bind:this={eleCollectTax} class="info slectizedd form-control">
                                    <option value={false}>Collect Tax from Student</option>
                                    <option value={true}>Tax is paid by Institute</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" class:hide-section={batchObj.isFree=="yes" }>
                        <!-- <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Summery</h6> -->
                        <hr style="margin-top: 5px;">
                        <input type="text" class="tempConvertValues" hidden>
                    </div>
                    {/if}

                    <div class="col-sm-12 col-md-6" class:hide-section={batchObj.isFree=="yes" }>
                        <h3 class="tx-normal tx-rubik tx-spacing--2 mg-b-5">{_actualBatchFee}
                        </h3>
                        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Actual Batch Fees
                        </h6>
                        <p class="mg-b-0 tx-12 tx-color-03">This is batch fee if tax is paid by Institute.
                            {_deductedTax} ₹</p>

                    </div><!-- col -->
                    <div class="col-sm-12 col-md-6 mg-t-20 mg-sm-t-0" style="text-align: right;"
                        class:hide-section={batchObj.isFree=="yes" }>
                        <h3 class="tx-normal tx-rubik tx-spacing--2 mg-b-5">{_totalPayableFee}</h3>
                        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Total Payable Fees
                        </h6>
                        <p class="mg-b-0 tx-12 tx-color-03">Final amount that student would need to pay.</p>
                    </div><!-- col -->
                    <div class="col-sm-12">
                        <hr style="margin-top: 20px;">
                    </div>
                </div>
            </div>
            <div class="col-sm-5 col-ms-12">
                <div class="row">
                    <div class="col-sm-12">
                        <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Batch Subjects

                            {#if batchObj.isInSyncWith}
                            <span style="font-weight: lighter;">(Study material synchronized with following)</span>
                            {:else}
                            <span style="font-weight: lighter;">(Study material folder structure)</span>
                            {/if}
                        </h6>

                        <hr style="margin-top: 5px;">
                    </div>
                    <div class="col-sm-12">
                        {#if batchObj.isInSyncWith}
                        <FolderExplorer mode='compact' shouldNotNavigate={true} _batchId={batchObj.isInSyncWith._id}
                            viewOnlyMode={folderExplorerViewOnlyMode}></FolderExplorer>
                        {:else}
                        <FolderExplorer mode='compact' shouldNotNavigate={true} _batchId={_id}
                            viewOnlyMode={folderExplorerViewOnlyMode}></FolderExplorer>
                        {/if}
                    </div>
                </div>
                <div class="col-sm-12">

                    <hr style="margin-top: 5px;">
                </div>
                <div class="col-sm-12 mg-b-15">
                    <div class="row">
                        <div class="col-md-7 col-sm-12">
                            Auto Approve Batch Join Requests
                        </div>
                        <div class="col-md-5 col-sm-12">
                            <div class="form-row">
                                <div class="form-group col-md-4 m-0">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="isFreeNoo" value="no" bind:group={flagobj.isfree1}
                                            name="isFreee" class="custom-control-input">
                                        <label class="custom-control-label" for="isFreeNoo">No</label>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 m-0">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="isFreeYess" value="yes" bind:group={flagobj.isfree1}
                                            name="isFreee" class="custom-control-input">
                                        <label class="custom-control-label" for="isFreeYess">Yes</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mg-b-15">
                    <div class="row">
                        <div class="col-md-7 col-sm-12">
                            Deactivate (Student's) Batch if installments not paid
                        </div>
                        <div class="col-md-5 col-sm-12">
                            <div class="form-row">
                                <div class="form-group col-md-4 m-0">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="isFreeNoo1" value="no" bind:group={flagobj.isfree2}
                                            name="isFree1" class="custom-control-input">
                                        <label class="custom-control-label" for="isFreeNoo1">No</label>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 m-0">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="isFreeYess1" value="yes" bind:group={flagobj.isfree2}
                                            name="isFree1" class="custom-control-input">
                                        <label class="custom-control-label" for="isFreeYess1">Yes</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mg-b-15">
                    <div class="row">
                        <div class="col-md-7 col-sm-12">
                            Allow Join Requests
                        </div>
                        <div class="col-md-5 col-sm-12">
                            <div class="form-row">
                                <div class="form-group col-md-4 m-0">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="isFreeNoo2" value="no" bind:group={flagobj.isfree3}
                                            name="isFree2" class="custom-control-input">
                                        <label class="custom-control-label" for="isFreeNoo2">No</label>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 m-0">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="isFreeYess2" value="yes" bind:group={flagobj.isfree3}
                                            name="isFree2" class="custom-control-input">
                                        <label class="custom-control-label" for="isFreeYess2">Yes</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mg-b-15">
                    <div class="row">
                        <div class="col-md-7 col-sm-12">
                            Mark this as default demo for this class
                        </div>
                        <div class="col-md-5 col-sm-12">
                            <div class="form-row">
                                <div class="form-group col-md-4 m-0">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="isFreeNoo3" value="no" bind:group={flagobj.isfree4}
                                            name="isFree3" class="custom-control-input">
                                        <label class="custom-control-label" for="isFreeNoo3">No</label>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 m-0">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="isFreeYess3" value="yes" bind:group={flagobj.isfree4}
                                            name="isFree3" class="custom-control-input">
                                        <label class="custom-control-label" for="isFreeYess3">Yes</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    </div>
</div>
