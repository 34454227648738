<script>
    import { onMount, tick } from "svelte";
    import ButtonLoader from "../utils/button_loader.svelte"
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
    export let studentId

    let elem_nextFolloUpTimepicker;
    let minFollowUpDate = new Date();
    let errorObj = {}
    let studentResponse;
    let followUpDate;
    let followUpTime;
    let isLoading = false;
    let arrList = []

    async function api_saveStudentFollowUpResponse(dataObj) {
        var resData = await api_handleApiCalls('students.studentsFollowUp', dataObj)
        return resData
    }

    async function api_listFollowUpResponse(dataObj) {
        var resData = await api_handleApiCalls('students.listStudentsFollowUp', dataObj)
        return resData
    }


    function formatAMPM(dateObj) {
        let date = new Date(dateObj)
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
      
        let timeObj = {
            hours,
            minutes,
            ampm
        }
        return timeObj
    }

    function getFormatedDate(date) {
        if(!date && date == '') {
            return '-'
        }
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('-');      

    }

    function clearFields(){
        followUpDate = null;
        followUpTime = null;
        studentResponse = null;
        _$(elem_nextFolloUpTimepicker).timepicker("setTime", "12:00 AM");
    }

    function clearError(key) {

        switch (key) {
            case "followUpTime":
               delete errorObj.followUpTime
                break;

            case "followUpDate":
               delete errorObj.followUpDate
                break;

            case "studentResponse":
               delete errorObj.studentResponse
                break;
            
            case "all":
               errorObj = {}
                break;
        
            default:
                break;
        }
        
    }

    async function reloadList() {
        var res = await api_listFollowUpResponse ({studentId,});
        console.log("res:::::", res);
        if (res.status) {
           arrList =  res.data
        }
    }

    onMount(async function(){
        feather.replace();
        _$(elem_nextFolloUpTimepicker).timepicker({
            timeFormat: "h:mm p",
            interval: 15,
            dynamic: false,
            dropdown: true,
            scrollbar: true,
                template: "modal",
                // minTime: thisHours,
                change: async function (time) {
                    if (time) {
                        followUpTime = new Date(time);
                    } else {
                        followUpTime = null;
                    }
                    clearError("followUpTime");
                    await tick();

                },
        });

        reloadList();
        feather.replace({width: "1em", height: "1em"})
    })


    function validateResponse(){
        
        if(!followUpDate) errorObj.followUpDate = "Follow up date and time are mandatory fields.";
        if(!studentResponse) errorObj.studentResponse = "Student response is mandatory field"

        if(Object.keys(errorObj).length) return false
        else return true
    }

    async function handleClickSaveResponse(){

        if(isLoading) return
        isLoading = true
       
        if(validateResponse()){
            var dataObj = {
                followUpDate,
                followUpTime,
                studentResponse,
                studentId,
            }
            var res = await api_saveStudentFollowUpResponse(dataObj);
            if (res.status) {
                _$("#modal-new-followup").modal('hide');
                pSuccess("Successfull!!", "Student follow up added successfully.")
                isLoading = false;
            }else{
                pError("Error!!!!, Something went wrong.")
                isLoading = false;
            }
        }else{
            isLoading = false
        }
        reloadList();
    }

    function onModalClick(){
        clearError("all")
        clearFields();
        _$("#modal-new-followup").modal('show');
    }

    function displayDateStrByDateObj (date) {
        let _thisDate = new Date(date)

        if(_thisDate && _thisDate.toString().toLowerCase() != 'invalid date'){
            return _thisDate.toLocaleString();
        }

        return '-'
         
    }

</script>
<style>
     .mail-group-label {
    display: block;
    padding: 6px 0px;    
    font-size: .875rem;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    font-weight: 500;
    letter-spacing: .5px;
    color: #222;
    margin: 1px 0;
 }
 .media {
    margin-top: 5px;
    padding: 2px 0px;
    border-bottom: 1px solid #e5e9f2;
 }
 .avatar .avatar-initial {
    font-size: 0.7rem;
    text-transform: initial;
 }
 .empty-state-img {
    text-align: center;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>
    
<div class="d-flex flex-row align-items-center justify-content-between mail-group-label bg-gray-200" style="padding: 8px 12px;">
    <label class="mg-b-0">Follow Up History</label>
    <a href="#modal-new-followup" data-toggle="modal" on:click="{onModalClick}" data-animation="effect-scale" class="nav-link pd-0 d-sm-block"><i data-feather="plus-circle"></i> Add follow up</a>
</div>
{#if arrList && (arrList.length == 0) }
            <div class="empty-state-img">
            <img src="/images/empty.svg" style="width: 300px" alt="empty" />
            <p class="tx-bold">No follow up responses found</p>
            </div>
{/if}
{#if arrList}
    <ul class="list-unstyled media-list pd-5 mg-b-0" style="max-height: 1068px;
    overflow-y: auto;">
        {#each arrList as followup}
            <li class="media">
                <span class="tx-color-03 d-sm-block"><i data-feather="chevrons-right" class="wd-30"></i></span>
                <div class="media-body mg-t-10">
                    <div class="tx-color-02 d-flex align-items-center justify-content-between mg-b-2">
                        <h6 class="tx-13">Response : {followup.StudentResponse}</h6>
                        <span class="tx-11">Date: {displayDateStrByDateObj(followup.followUpDateTime)}</span>
                    </div>
                </div><!-- media-body -->
            </li>
        {/each}
    </ul>

{:else}  
        <div data-label="Example" class="df-example">
            <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
              <div class="line"></div>
            </div>
            <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                <div class="line"></div>
              </div>
              <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                <div class="line"></div>
              </div>
              <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                <div class="line"></div>
              </div>
              <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                <div class="line"></div>
              </div>
              <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                <div class="line"></div>
              </div>
              <div class="placeholder-media wd-100p wd-sm-55p wd-md-45p">
                <div class="line"></div>
              </div>
          </div><!-- df-example -->
{/if}

<!-- Modal -->
    <div class="modal fade" id="modal-new-followup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel6"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header">
                <span class="tx-color-03 d-none d-sm-block"><i data-feather="message-square" class="wd-60 ht-60"></i></span>
                <div class="d-flex flex-column mg-l-15">
                <h4 class="mg-b-0 tx-spacing--1">Follow ups</h4>
                <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Add new follow up schedule.</p>
            </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                
                <div class="form-row">
                
                <div class="form-group hasDatepicker col-md-6">
                    <label class="d-block">Student Follow Up Date
                            <span class="tx-danger">*</span>
                    </label>
                    <div class="d-flex">
                        <div>
                            <input type="date" onkeydown="return false" min={minFollowUpDate} bind:value={followUpDate} class="form-control"/>
                        </div>
                        <div class="mg-l-8">
                            <input
                                bind:this={elem_nextFolloUpTimepicker}
                                on:keyup={(e) => e.key === 'Enter' && submitForm(e)}
                                type="text"
                                onkeydown="return false"
                                class="form-control first-input"
                                placeholder="Select time" />
                        </div>
                    </div>
                    <div>
                        {#if errorObj.followUpDate}
                        <div class="parsley-errors-list">{errorObj.followUpDate}</div>
                        {/if}
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <label class="d-block">Response
                        <span class="tx-danger">*</span>
                    </label>
                    <textarea class="form-control" on:blur={()=>clearError("studentResponse")} bind:value={studentResponse} rows="4" placeholder="Remarks"></textarea>
                    {#if errorObj.studentResponse}
                        <div class="parsley-errors-list">{errorObj.studentResponse}</div>
                    {/if}
                </div>
                <div class="form-group col-md-12">
                    {#if errorObj.fupServerErrMsg}
                        <div class="parsley-errors-list">{errorObj.fupServerErrMsg}</div>
                    {/if}
                </div>
                
            </div>
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Cancel</button>
            <button type="button" disabled={isLoading} on:click={handleClickSaveResponse} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                <i data-feather="save"></i>
                <ButtonLoader isLoading={isLoading} btnText="Save"/>
            </button>
        </div>
        
    </div>
    </div>
    </div>
<!-- Modal -->
