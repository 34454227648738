<script>
  export let mode;
  export let batchTiming;
  // export let msg;
  let msg="";
  if(batchTiming){
    msg="No Class Timing is selected.";
  }else{
    msg="No Student Found.";
  }
  export let searchResult;
</script>

<!-- <div class="wd-100p tx-center align-items-center justify-content-center bd">
  
  {#if mode == 'search'}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">Student not found.</p>
  {:else}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">
      {msg}
    </p>
  {/if}
  <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
</div> -->

<div class="ht-300 wd-100p d-flex align-items-center justify-content-center bd" style="flex-direction:column;">
  <div class="" style="width:200px">
      <img src="/images/empty.svg" class="img-fluid" alt="No Result" />
      <p class="tx-bold mg-b-0" style="text-align:center;">
        {msg}</p>
  </div>
  <!-- <div class="" style="text-align:center;">Click <a href=""  class="">here</a> to assign a batch and enable payments</div> -->

</div>
