<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import { activeMenu } from "../../../stores";
    import FolderModal from "../../../folderCmp/folderModal.svelte";
    import ButtonLoader from "../../../utils/button_loader.svelte";
    import api_handleApiCalls from "../../../utils/apiCallHandler.js";
    export let _id;
    let isLoading = false;
    let showModal = false;
    let elem_uploadLectureInput;
    let elem_tags;

    let fileEle;
    let files;
    let _fileName;
    let fileObj;
    let linkArr = [];
    let moduleSubjectList = [];
    let facultyList = [];
    let lectureObj = {
        _id: _id,
        name: "",
        facultyId: "",
        tags: [],
    }
    let displayText = "Add Recorded Lecture";
    let elemModuleSubj;
    let elemFaculty;
    if (_id) {
        displayText = "Update Recorded Lecture"
    }
    let errorObj = {};
    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    }

    async function api_loadLinksForFile(data) {

        let action = "files.loadLinksForFile";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_addFileLinkToFolder(data) {

        let action = "files.addFileLinkToFolder";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_removeFileLinkToFolder(data) {

        let action = "files.removeFileLinkToFolder";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_getUrlForUpload(data) {

        let action = "storage.getUrlForUpload";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_updateRecordedLecFileUrl(data) {

        let action =  "studyMaterial.updateRecordedLecFileUrl";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_getModuleSub(data) {

        let action =  "categories.getModuleSubjectList";
        var response = await api_handleApiCalls(action, {});
        return response;

    }

    async function api_addUpdateRecordedLecture(data) {

        let action =  "studyMaterial.addUpdateRecordedLecture";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_getRecordedLectureById(data) {

        let action =  'studyMaterial.getRecordedLectureById';
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_getFaculty(data) {

        let action =  "systemUser.fetchSystemUserList";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    function updateSuccess(success) {
        PNotify.success({
            title: success.title ? success.title : "Success",
            text: success.msg,
            delay: 3000
        })
    }

    function updateErrors(error) {
        PNotify.error({
            title: error.title ? error.title : "Error",
            text: error.msg,
            delay: 3000
        })
    }
    let deleteLink = async (linkId) => {
        var linkRes = await api_removeFileLinkToFolder({ _id: linkId });
        if (linkRes.status) {
            updateSuccess({ title: "Success", msg: linkRes.data });
            renderLinks();
        } else {
            // Failed to create 
            updateErrors({ title: "Error", msg: (linkRes?.errMsg ?? "Something went wrong") })
        }

    }

    let validateLecture = () => {
        errorObj = {};
        if (!lectureObj.name /* || !lectureObj.moduleId || !lectureObj.subjectId */ || !lectureObj.file) {
            var newErrorObj = {};
            if (!lectureObj.name) {
                newErrorObj.name = "Name is required Field.";
            }
            if (!lectureObj.facultyId) {
                newErrorObj.facultyId = "Faculty is required Field.";
            }
            // if (lectureObj.moduleSubjects.length == 0) {
            //     newErrorObj.subjectId = "Module/Subject is required Field.";
            // }
            errorObj = newErrorObj;

        }
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;


    }

    let addUpdateImageBtn = async () => {
        if (isLoading) return;
        isLoading = true;
        let moduleSubIdList = []
        // _$(elemModuleSubj).select2("data").map(function (aSelectedValue) {
        //     return aSelectedValue.id;
        // });
        lectureObj.moduleSubjects = [];
        moduleSubIdList.forEach(moduleSubId => {
            var moduleId = moduleSubId.split("_")[0];
            var subjectId = moduleSubId.split("_")[1];
            lectureObj.moduleSubjects.push({ moduleId: moduleId, subjectId: subjectId })
        });
        let facultyIds = _$(elemFaculty).select2("data")[0].id;
        lectureObj.facultyId = facultyIds;
        lectureObj.tags = _$(elem_tags).val().split(",");
        if (validateLecture()) {

            var lectureRes = await api_addUpdateRecordedLecture(lectureObj);
            // fileObj = lectureRes;
            if (lectureRes.status) {
                // create success
                updateSuccess({ title: "Success", msg: lectureRes.data });
                setTimeout(() => {
                    window.history.back();
                }, 1000);
            } else {
                // Failed to create 
                updateErrors({ title: "Error", msg: (lectureRes?.errMsg ?? "Something went wrong") })
                isLoading = false;
                return false
            }
        }
        isLoading = false;
    }

    let renderLinks = async function () {
        var templinkArr = await api_loadLinksForFile({
            fileId: _id
        })
        linkArr = templinkArr.data.fileFolderLinkArr;
        tick();
        setTimeout(function () {
            feather.replace();
        }, 10)
    }

    let bindEvents = function () {
        var selectedSubjId = {};
        if (!lectureObj.moduleSubjects) lectureObj.moduleSubjects = [];
        lectureObj.moduleSubjects.forEach(aModuleSubject => {
            selectedSubjId[aModuleSubject.subjectId] = true;
        });
        _$(elemModuleSubj).select2({
            minimumResultsForSearch: Infinity, placeholder: "Select Module / Subject",
            data: moduleSubjectList.map(function (aModule) {
                var isSelected = false;
                return {
                    id: (aModule.moduleId + '_' + aModule.subjectId),
                    text: aModule.moduleSubjStr,
                    selected: (selectedSubjId[aModule.subjectId] ? true : false)
                }
            })
        });

        _$(elemFaculty).select2({
            minimumResultsForSearch: Infinity,
            data: facultyList.map(function (aFaculty) {
                return {
                    id: (aFaculty._id),
                    text: aFaculty.name,
                    selected: (aFaculty._id == lectureObj.facultyId)
                }
            })
        });
        if (!lectureObj.tags) lectureObj.tags = [];
        var tags = _$(elem_tags).selectize({
            delimiter: ',',
            plugins: ['remove_button'],
            persist: false,
            options: lectureObj.tags.map(function (input) {
                return { value: input, text: input }
            }),
            items: lectureObj.tags,
            render: {
                option_create: function (data, escape) {
                    return '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New tag : </i><strong>' + escape(data.input) + '</strong></div>';
                }
            },
            create: function (input) {
                return { value: input, text: input }
            }
        });

        // for (var i = 0; i < lectureObj.tags.length; i++) {
        //         tags[0].selectize.addOption({ selected: true, value: lectureObj.tags[i], text: lectureObj.tags[i] });
        //     }


    }

    let onCloseModal = async function (event) {
        showModal = !showModal;
        console.log("selectedFolder : ", event.detail.selectedFolder)

        // if (event?.detail?.selectedFolder?.length > 0) {
        if (event && event.detail && event.detail.selectedFolder && event.detail.selectedFolder.length > 0) {
            var selectedFolder = event.detail.selectedFolder;

            var linkObj = {
                fileId: _id,
                folderId: selectedFolder[selectedFolder.length - 1].id,
                batchId: selectedFolder[selectedFolder.length - 1].batchId,
                selectedFolder: selectedFolder
            }
            var resp = await api_addFileLinkToFolder(linkObj);
            renderLinks();
        }
        _$('.modal-backdrop').remove()
    }

    let refreshLinkList = function () {
        renderLinks();
    }

    let filePondInstance;
    let filepondOptions = {
        allowDownloadByUrl: true,
        acceptedFileTypes: ["video/*"],
        server: {
            process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                // fieldName is the name of the input field
                // file is the actual file object to send
                const formData = new FormData();
                formData.append(fieldName, file, file.name);

                let originalFileName = file.name;
                let _fileName = file.name.split(".");

                var fileExtension = _fileName[_fileName.length - 1];
                var fileSize = file.size
                //  var formData = new FormData();
                // formData.append('file', file, "newFileName"+_fileName);

                var res = await api_getUrlForUpload({
                    name: originalFileName,
                    type: file.type,
                    uploadType: "STUDY_LECTURE",
                    fileId: _id
                });
                console.log("res : ", res)
                if (res.status) {
                    _$.ajax({
                        xhr: function () {
                            var xhr = new window.XMLHttpRequest();
                            xhr.upload.addEventListener("progress", function (e) {
                                // var percentComplete = (evt.loaded / evt.total) * 100;
                                progress(e.lengthComputable, e.loaded, e.total);
                                // console.log("percentComplete : ", percentComplete)
                            }, false);
                            return xhr;
                        },
                        url: res.data.url,
                        type: "PUT",
                        data: file,
                        dataType: "text",
                        cache: false,
                        contentType: file.type,
                        processData: false
                    })
                        .done(async function (resss) {
                            await api_updateRecordedLecFileUrl({
                                fileId: _id,
                                originalFileName: originalFileName,
                                fileExtension: fileExtension,
                                fileSize: fileSize,
                                url: res.data.url,
                            })
                            console.log("asdfasd : ", resss)
                            console.info('YEAH', res.data.url.split('?')[0].substr(6));
                            load("Done");
                        })
                        .fail(function (err) {
                            error('oh no');
                            console.error('damn...', err);
                        })
                } else {
                    // todo show erro toast
                }
                return {
                    abort: () => {
                        // This function is entered if the user has tapped the cancel button
                        // request.abort();

                        // Let FilePond know the request has been cancelled
                        abort();
                    }
                };
            }
        },
        onaddfile: function (error, file) {
            if (!file.file.customUrl) {
                filePondInstance.processFile().then(file => {
                });
            }
        }
    }

    onMount(async () => {
        activeMenu.set('studyMaterial')
        _$.fn.filepond.setOptions({
            server: {
                url: "{{ url('upload')}}",
                process: {
                    headers: {
                        'X-CSRF-TOKEN': "csrf"
                    },
                }
            }
        });
        _$.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
        _$.fn.filepond.registerPlugin(FilePondPluginGetFile);

        filePondInstance = FilePond.create(elem_uploadLectureInput, filepondOptions);

        var moduleSubjectRes = await api_getModuleSub();
        if (moduleSubjectRes.status) {
            moduleSubjectList = moduleSubjectRes.data;
        }
        const filter = {
            "draw": 1,
            "columns": [
                {
                    "data": "name",
                    "name": "",
                    "searchable": true,
                    "orderable": true,
                    "search": {
                        "value": "",
                        "regex": false
                    }
                },
                {
                    "data": "email",
                    "name": "",
                    "searchable": true,
                    "orderable": true,
                    "search": {
                        "value": "",
                        "regex": false
                    }
                },
                {
                    "data": "_id",
                    "name": "",
                    "searchable": true,
                    "orderable": false,
                    "search": {
                        "value": "",
                        "regex": false
                    }
                }
            ],
            "start": 0,
            "length": 100,
            "search": {
                "value": "",
                "regex": false
            },
            "sort": {
                "userSeqId": 1
            },
            "accessProfileId": ""
        }
        var facultyRes = await api_getFaculty(filter);
        if (facultyRes.status) {
            facultyList = facultyRes.data.data;
        }
        var _fileObj;
        if (_id) {
            var lectureRes = await api_getRecordedLectureById({ _id: _id });
            _fileObj = lectureRes;
            if (lectureRes.status) {
                var lecutureData = lectureRes.data;
                console.log('lecutureData ', lecutureData.facultyId);
                lectureObj = {
                    _id: lecutureData._id,
                    name: lecutureData.name,
                    facultyId: lecutureData.facultyId,
                    moduleSubjects: lecutureData.moduleSubjects,
                    tags: lecutureData.tags,
                };
            }
        }


        renderLinks();


        if (_id && _fileObj?.data?.originalFileName) {
            _$(elem_uploadLectureInput).filepond("files",
                [
                    {
                        source: '12345',
                        options: {
                            type: 'local',
                            file: {
                                name: _fileObj.data.originalFileName,
                                size: _fileObj.data.fileSize,
                                customUrl: _fileObj.data.downloadUrl,
                                type: 'image/' + _fileObj.data.fileExtension
                            }
                        }
                    }
                ]
            );
        }

        _$(".may-pond .filepond--item-panel").css('background', '#369763')
        _$(elem_uploadLectureInput).filepond('allowMultiple', false);
        // _$(elem_uploadLectureInput).on('FilePond:addfile', function (e) {
        //     _$.fn.filepond.setOptions(server);
        // });
        bindEvents();
    });

</script>
<style>
    /* ###### 4.6 Parsley ###### */
    input.parsley-error,
    input.parsley-error:focus,
    textarea.parsley-error,
    textarea.parsley-error:focus {
        box-shadow: none !important;
        border-color: #dc3545;
    }

    input.parsley-success,
    input.parsley-success:focus,
    textarea.parsley-success,
    textarea.parsley-success:focus {
        box-shadow: none !important;
        border-color: #10b759;
    }

    .parsley-checkbox.parsley-error,
    .parsley-checkbox.parsley-success {
        display: inline-block;
        padding: 10px;
        border-radius: 0.25rem;
    }

    .parsley-checkbox.parsley-error {
        border: 1px solid #dc3545;
    }

    .parsley-checkbox.parsley-success {
        border: 1px solid #10b759;
    }

    .custom-select.parsley-error,
    .custom-select.parsley-success {
        box-shadow: none !important;
    }

    .custom-select.parsley-error {
        border-color: #dc3545;
    }

    .custom-select.parsley-success {
        border-color: #10b759;
    }

    .parsley-errors-select-custom .select2-selection {
        border-color: #dc3545 !important;
    }

    .parsley-errors-list {
        color: #dc3545;
        list-style: none;
        font-size: 12px;
        padding: 0;
        margin-bottom: -12px;
    }

    .parsley-errors-list li {
        margin: 5px 0 0;
    }
</style>
<div class="content-body fadeIn">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/studyMaterial">Study Material</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {displayText}
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                {displayText}
            </h4>
        </div>
    </div>
    <!-- <hr class="mg-y-20"> -->
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div class="row row-sm mg-b-10">
            <div class="col-sm-12 ">
                <fieldset class="form-fieldset">
                    <legend>Basic</legend>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label class="d-block"> Name</label>
                            <input bind:value={lectureObj.name} type="text" on:blur={()=>clearError("name")}
                            class="form-control" class:parsley-error={errorObj.name} placeholder="Enter your name"/>
                            {#if errorObj.name}
                            <div class="parsley-errors-list">{errorObj.name}</div>
                            <!-- content here -->
                            {/if}
                        </div>
                        <div class="form-group col-md-6 d-none" on:click={()=>
                            clearError("subjectId")} class:parsley-errors-select-custom={errorObj.subjectId}>
                            <label class="d-block">Module & Subject</label>
                            <select multiple bind:this={elemModuleSubj} class="custom-select moduleSub">
                            </select>
                            {#if errorObj.subjectId}
                            <div class="parsley-errors-list">{errorObj.subjectId}</div>
                            <!-- content here -->
                            {/if}
                        </div>
                        <div class="form-group col-md-6" on:click={()=>
                            clearError("facultyId")} class:parsley-errors-select-custom={errorObj.facultyId}>
                            <label class="d-block">Faculty</label>
                            <select bind:this={elemFaculty} class="custom-select faculty">
                                <option value="" selected>Select Faculty</option>
                            </select>
                            {#if errorObj.facultyId}
                            <div class="parsley-errors-list">{errorObj.facultyId}</div>
                            <!-- content here -->
                            {/if}
                        </div>
                        <div class="form-group col-md-6">
                            <label class="d-block">Tags</label>
                            <input bind:this={elem_tags} bind:value={lectureObj.tags} on:blur={()=>clearError("tags")}
                            type="text" class="form-control" class:parsley-error={errorObj.tags} placeholder="Enter
                            tags"/>
                            {#if errorObj.tags}
                            <div class="parsley-errors-list">{errorObj.tags}</div>
                            {/if}
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-sm-12 ">
                <div class="card ht-100p" style="    margin-top: 9px;">
                    <div>
                        <input type="file" bind:this={elem_uploadLectureInput} class="my-pond"
                            data-instant-upload="false" data-allow-image-preview="false" name="filepond" />
                    </div>
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <h6 class="mg-b-0">Linked To Study Material</h6>
                        <div class="d-flex align-items-center tx-18">
                            <button on:click="{() => showModal = !showModal}"
                                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                                <i data-feather="link" class="wd-10 mg-r-5"></i> New Link
                            </button>
                        </div>
                    </div>
                    <ul class="list-group list-group-flush tx-13">
                        {#each linkArr as item}
                        <li class="list-group-item d-flex pd-sm-x-20">
                            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600"><i
                                        data-feather="link" class="wd-10"></i></span></div>
                            <div class="pd-l-10">
                                <p class="tx-medium mg-b-0">{item.fullPath.join(" / ")}</p>
                                <small class="tx-12 tx-color-03 mg-b-0">{item.batchName}</small>
                            </div>
                            <div class="mg-l-auto d-flex align-self-center">
                                <nav class="nav nav-icon-only">
                                    <a on:click={()=> deleteLink(item._id) } href="#" class="nav-link d-none
                                        d-sm-block">
                                        <i data-feather="trash-2" class="wd-10 mg-r-5"></i>
                                    </a>
                                </nav>
                            </div>
                        </li>
                        {/each}
                    </ul>
                    <div class="card-footer text-center tx-13">
                        <button on:click={refreshLinkList} class="btn">Refresh <i
                                class="icon ion-md-refresh mg-l-5"></i></button>
                    </div>
                    <!-- card-footer -->
                </div>
                <!-- card -->
            </div>
        </div>
    </div>
    <div class="d-sm-flex align-items-center justify-content-end ">
        <div class="d-block">
            <button on:click={addUpdateImageBtn} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" disabled={isLoading}>
                <ButtonLoader isLoading={isLoading} btnText="SAVE" />
                <i data-feather="save" class="wd-10 mg-l-5"></i>
            </button>
        </div>
    </div>
</div>
{#if showModal}
<FolderModal on:close={onCloseModal} />
{/if}
