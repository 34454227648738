<script>
    import { Router, Link, Route } from "svelte-routing";
    import ListRoute from "./list.svelte";
    import AddUpdate from "./addUpdate.svelte";
    import PrintQuestions from './viewSelectedQuestions.svelte'
    import QuestionBank from "./questionBank.svelte";
    import MODULES from "../utils/modules.js"
    import Forbidden from "../forbidden.svelte" 
    import api_handleApiCalls from '../utils/apiCallHandler'
    import {pSuccess, pError} from '../utils/notify.svelte'
import { tick } from "svelte";
import { _selectedQuestionsIds, updateSelectedQuestion } from "../stores";

    const onPrintClick = (event) => {
        // alert("Printing.." + event.detail.questionsIds.length)
        api_printSelectedQuestions(event.detail.questionsIds)
    }

    async function api_printSelectedQuestions(questionsIds=[]) {
        _$('#btn-print-selected-q').prop('disabled', true)
        let response = await api_handleApiCalls('test.generatePDFByQuestionIds', questionsIds)
        _$('#btn-print-selected-q').prop('disabled', false)

        if (response.status) {
            pSuccess("Success", response.data)
            updateSelectedQuestion(new Set())
            await tick()
            uncheckAllQuestions()
        } else {
            pSuccess("Error", response.errMsg)
        }
    }

    function uncheckAllQuestions() {
        _$("#select_all_question").prop('checked', false)
        _$(".select-question").prop( "checked", false);
    }
</script>

<Router>
    <Route path="list/:id"  let:params>
        <ListRoute on:clickPrint={onPrintClick} parentId="{params.id}"></ListRoute>
    </Route>
    <Route path="printQuestions"  let:params>
        <PrintQuestions on:clickPrint={onPrintClick} parentId="{params.id}"></PrintQuestions>
    </Route>
    <Route path="questionBank">
        <QuestionBank></QuestionBank>
    </Route>
    <Route path="questionBank/:id" let:params>
        <QuestionBank parentId="{params.id}"></QuestionBank>
    </Route>
    <Route path="add">
        <AddUpdate ></AddUpdate>
    </Route>
    <Route path="view/:id" let:params>
        <AddUpdate questionId="{params.id}"></AddUpdate>
    </Route>
    <!-- <Route path="create/:qType" let:params>
        <AddUpdate qType="{params.qType}" newQuestion={true}></AddUpdate>
    </Route> -->
    <Route path="create/:qType/:parentId" let:params>
        <AddUpdate qType="{params.qType}" newQuestion={true} parentId={params.parentId}></AddUpdate>
    </Route>
</Router>
