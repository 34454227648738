<script>
import { onMount } from "svelte";
import moment from 'moment'

    export let startFrom=new Date().getTime()
    export let onTimeOut= function () {
        console.log("No onTimeOut event handled");
    }

    let now = new Date().getTime();
    let timeStr = "-";
    onMount( () => {
        var timer = setInterval(()=> {
            now = new Date().getTime();

            let duration = moment.duration( startFrom - now, 'milliseconds');
            timeStr = duration.days() + ' Days: ' + duration.hours()+ ' hr: ' + duration.minutes()+ ' m: ' + duration.seconds() + ' s';
            if ( now > startFrom ) {
                clearInterval(timer);
                onTimeOut();
                timeStr = "EXPIRED";
            }

        }, 1000)
    })

    </script>
    <slot name="container" time={now} timeStr={timeStr}>
    </slot>
