<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import { activeMenu } from "../../../stores";
    import FolderModal from "../../../folderCmp/folderModal.svelte";
    import ButtonLoader from "../../../utils/button_loader.svelte";
    import api_handleApiCalls from "../../../utils/apiCallHandler.js";

    export let _id;
    let isLoading = false;
    let moduleSubjectList = [];
    let linkArr = [];
    let showModal = false;
    let refLinkObj = {
        _id: _id,
        name: "",
        refUrl: "",
        moduleId: "",
        subjectId: "",
        tags: "",
    }

    let displayText = "Add Reference Link";

    let elemModuleSubj;
    if (_id) {
        displayText = "Update Reference Link"
    }

    let errorObj = {};
    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    }

    function updateSuccess(success) {
        PNotify.success({
            title: success.title ? success.title : "Success",
            text: success.msg,
            delay: 3000
        })
    }

    function updateErrors(error) {
        PNotify.error({
            title: error.title ? error.title : "Error",
            text: error.msg,
            delay: 3000
        })
    }


    let validateRefLink = () => {
        errorObj = {};
        if (!refLinkObj.name || !refLinkObj.refUrl || !refLinkObj.tags /* || !refLinkObj.moduleId || !refLinkObj.subjectId */) {
            var newErrorObj = {};
            if (!refLinkObj.name) {
                newErrorObj.name = "Ref Link Name is required Field.";
            }
            if (!refLinkObj.refUrl) {
                newErrorObj.refUrl = "Ref Link url is required Field.";
            }
            // if (!refLinkObj.subjectId) {
            //     newErrorObj.subjectId = "Ref Link Module/Subject is required Field.";
            // }

            errorObj = newErrorObj;

        }
        if (Object.keys(errorObj).length > 0) {
            isLoading = false;
            return false;
        }
        return true;


    }

    let addUpdateRefLinkBtn = async () => {
        if(isLoading)return
        isLoading = true;
        // let moduleSubId = _$(elemModuleSubj).select2("data")[0].id;
        // console.log("moduleSubId", moduleSubId);
        // refLinkObj.moduleId = moduleSubId.split("_")[0];
        // refLinkObj.subjectId = moduleSubId.split("_")[1];

        if (validateRefLink()) {

            var refLinkRes = await api_addUpdateRefLink(refLinkObj);
            if (refLinkRes.status) {
                // create success
                updateSuccess({ title: "Success", msg: refLinkRes.data });
                setTimeout(() => {
                    window.history.back();
                }, 1000);
            } else {
                // Failed to create 
                updateErrors({ title: "Error", msg: refLinkObj.errMsg })

            }
            isLoading = false;
        }
        await tick();
        isLoading = false;
        
    }
    async function api_getModuleSub(data) {

        let action = "categories.getModuleSubjectList";
        var response = await api_handleApiCalls(action, {});
        return response;

    }
    async function api_addUpdateRefLink(data) {

        let action = "studyMaterial.addUpdateRefLink";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_getRefLinkById(data) {

        let action = 'studyMaterial.getRefLinkById';
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    function bindEvents() {
        _$(elemModuleSubj).select2({
            minimumResultsForSearch: Infinity,
            data: moduleSubjectList.map(function (aModule) {
                return {
                    id: (aModule.moduleId + '_' + aModule.subjectId),
                    text: aModule.moduleSubjStr,
                    selected: (aModule.subjectId == refLinkObj.subjectId)
                }
            })
        });
    }

    async function api_addFileLinkToFolder(data) {

        let action ="files.addFileLinkToFolder";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    let onCloseModal = async function (event) {
        showModal = !showModal;
        console.log("selectedFolder : ", event.detail.selectedFolder)

        // if (event?.detail?.selectedFolder?.length > 0) {
        if (event && event.detail && event.detail.selectedFolder && event.detail.selectedFolder.length > 0) {
            var selectedFolder = event.detail.selectedFolder;

            var linkObj = {
                fileId: _id,
                folderId: selectedFolder[selectedFolder.length - 1].id,
                batchId: selectedFolder[selectedFolder.length - 1].batchId,
                selectedFolder: selectedFolder
            }
            var resp = await api_addFileLinkToFolder(linkObj);
            renderLinks();
        }
        _$('.modal-backdrop').remove()
    }
    async function api_loadLinksForFile(data) {

        let action = "files.loadLinksForFile";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    let renderLinks = async function () {
        var templinkArr = await api_loadLinksForFile({
            fileId: _id
        })
        linkArr = templinkArr.data.fileFolderLinkArr;
        tick();
        setTimeout(function () {
            feather.replace();
        }, 10)
    }

    onMount(async () => {
        activeMenu.set('studyMaterial')

        var moduleSubjectRes = await api_getModuleSub();
        if (moduleSubjectRes.status) {
            moduleSubjectList = moduleSubjectRes.data;
        }
        if (_id) {
            var refLinkDetailsRes = await api_getRefLinkById({ _id: _id });

            if (refLinkDetailsRes.status) {
                var refLinkData = refLinkDetailsRes.data;
                refLinkObj = {
                    _id: refLinkData._id,
                    name: refLinkData.name,
                    refUrl: refLinkData.refUrl,
                    moduleId: refLinkData.moduleId,
                    subjectId: refLinkData.subjectId,
                    tags: refLinkData.tags,
                };
            }
        }
        renderLinks();
        bindEvents();
    });

</script>



<style>
    /* ###### 4.6 Parsley ###### */
    input.parsley-error,
    input.parsley-error:focus,
    textarea.parsley-error,
    textarea.parsley-error:focus {
        box-shadow: none !important;
        border-color: #dc3545;
    }

    input.parsley-success,
    input.parsley-success:focus,
    textarea.parsley-success,
    textarea.parsley-success:focus {
        box-shadow: none !important;
        border-color: #10b759;
    }

    .parsley-checkbox.parsley-error,
    .parsley-checkbox.parsley-success {
        display: inline-block;
        padding: 10px;
        border-radius: 0.25rem;
    }

    .parsley-checkbox.parsley-error {
        border: 1px solid #dc3545;
    }

    .parsley-checkbox.parsley-success {
        border: 1px solid #10b759;
    }

    .custom-select.parsley-error,
    .custom-select.parsley-success {
        box-shadow: none !important;
    }

    .custom-select.parsley-error {
        border-color: #dc3545;
    }

    .custom-select.parsley-success {
        border-color: #10b759;
    }


    .parsley-errors-select-custom .select2-selection {
        border-color: #dc3545 !important;
    }

    .parsley-errors-list {
        color: #dc3545;
        list-style: none;
        font-size: 12px;
        padding: 0;
        margin-bottom: -12px;
    }

    .parsley-errors-list li {
        margin: 5px 0 0;
    }
</style>
<div class="content-body fadeIn">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/studyMaterial">Study Material</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {displayText}
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                {displayText}
            </h4>
        </div>

    </div>
    <!-- <hr class="mg-y-20"> -->
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div class="row row-sm mg-b-10">
            <div class="col-sm-12 col-md-6">
                <fieldset class="form-fieldset">
                    <legend>Basic</legend>
                    <!-- <div class="form-row"> -->
                    <div class="form-group ">
                        <label class="d-block"> Name</label>
                        <input bind:value={refLinkObj.name} type="text" on:blur={()=>clearError("name")}
                        class="form-control" class:parsley-error={errorObj.name} placeholder="Enter your name"/>
                        {#if errorObj.name}
                        <div class="parsley-errors-list">{errorObj.name}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                    <div class="form-group hasDatepicker ">
                        <label class="d-block">Url</label>
                        <input bind:value={refLinkObj.refUrl} type="text" on:blur={()=>clearError("refUrl")}
                        class="form-control" class:parsley-error={errorObj.refUrl} placeholder="Enter Url"/>
                        {#if errorObj.refUrl}
                        <div class="parsley-errors-list">{errorObj.refUrl}</div>
                        <!-- content here -->
                        {/if}
                    </div>

                    <div class="form-group ">
                        <label class="d-block">Tags</label>
                        <input bind:value={refLinkObj.tags} on:blur={()=>clearError("tags")} type="text"
                        class="form-control" class:parsley-error={errorObj.tags} placeholder="Enter tags"/>
                        {#if errorObj.tags}
                        <div class="parsley-errors-list">{errorObj.tags}</div>
                        {/if}
                    </div>
                    <!-- </div> -->
                </fieldset>

            </div>
            <div class="col-sm-12 col-md-6">
                <div class="card ht-100p" style="    margin-top: 9px;">

                    <div class="card-header d-flex align-items-center justify-content-between">
                        <h6 class="mg-b-0">Linked To Study Material</h6>
                        <div class="d-flex align-items-center tx-18">
                            <button on:click="{() => showModal = !showModal}"
                                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                                <i data-feather="link" class="wd-10 mg-r-5"></i> New Link
                            </button>
                        </div>
                    </div>
                    <ul class="list-group list-group-flush tx-13">
                        {#each linkArr as item}
                        <li class="list-group-item d-flex pd-sm-x-20">
                            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600"><i
                                        data-feather="link" class="wd-10"></i></span></div>
                            <div class="pd-l-10">
                                <p class="tx-medium mg-b-0">{item.fullPath.join(" / ")}</p>
                                <small class="tx-12 tx-color-03 mg-b-0">{item.batchName}</small>
                            </div>
                            <div class="mg-l-auto d-flex align-self-center">
                                <nav class="nav nav-icon-only">
                                    <a on:click|preventDefault={()=> deleteLink(item._id) } href="#" class="nav-link
                                        d-none d-sm-block">
                                        <i data-feather="trash-2" class="wd-10 mg-r-5"></i>
                                    </a>
                                </nav>
                            </div>
                        </li>
                        {/each}
                    </ul>
                    <div class="card-footer text-center tx-13">
                        <button on:click={()=> {return;}} class="btn">Refresh <i
                                class="icon ion-md-refresh mg-l-5"></i></button>
                    </div>



                    <!-- card-footer -->
                </div>
                <!-- card -->
            </div>
        </div>
    </div>

    <div class="d-sm-flex align-items-center justify-content-end ">
        <div class="d-block">
            <button on:click={addUpdateRefLinkBtn} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
                    data-feather="file" class="wd-10 mg-r-5"></i>
                {displayText}
            </button>
        </div>
    </div>
</div>
{#if showModal}
<FolderModal on:close={onCloseModal} />
{/if}
