<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../../stores";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import { BACKEND_URL } from "../../../config.js";
  export let _id;

  let assignmentObj = {
    topic: "",
    submissionDate: "",
    submissionTime: "",
    notes: "",
  };
  let assignmentStatus;

  let remarkAssignmentObj = {
    remarkFile: [],
  };

  let assignmentStatus1;
  let assignmentRemark;
  let errorObj = {};
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  };

  let fileObj;
  let elem_uploadAttachmentInput;
  let elem_submitAssignmentFile;
  let assignmentId;

  let submitAssignmentObj = {
    notes: "",
  };

  let multipleFilesArr = [];

  async function api_getListOfFiles(data) {
    let action = "storage.getUrlForUpload";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_updateFileName(data) {
    let action = "storage.updateFileName";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getUrlForUpload(data) {
    try {
      const response = await fetch(
        `${BACKEND_URL}/fileupload-api/get-presigned-url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              contentType: "ASSIGNMENTS",
              fileName: data.name,
              fileExtension: data.name.split(".").pop(),
            },
            accessToken: localStorage.getItem("accessToken"),
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      // Extract both URLs from the response
      return {
        status: true,
        data: {
          url: result.presignedUrl,
          downloadUrl: result.downloadUrl || result.fileUrl || result.url,
          fileId: result.fileId,
        },
      };
    } catch (error) {
      console.error("Error getting presigned URL:", error);
      return {
        status: false,
        errMsg: "Failed to get upload URL",
      };
    }
  }

  async function api_updateSubmittedFiles(data) {
    let action = "assignmentprogress.updateSubmittedFileUrl";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/;
    return specialChars.test(str);
  }

  async function api_RemoveAttachmentFiles(data) {
    let action = "assignment.removeAttachmentFile";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_RemoveSubmittedFile(data) {
    let action = "assignmentprogress.removeSubmittedFile";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  let filepondOptions = {
    allowDownloadByUrl: true,
    acceptedFileTypes: ["image/*", "application/*", "text/*"],
    allowMultiple: true,
    maxFiles: 10,
    allowRevert: true,
    onremovefile: function (err, file) {
      PNotify.notice({
        title: "Delete Attachment",
        text: "Do you want to remove the file from attachments?",
        icon: "fas fa-question-circle",
        hide: false,
        closer: true,
        sticker: false,
        destroy: true,
        modules: new Map([
          ...PNotify.defaultModules,
          [
            PNotifyConfirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Yes",
                  primary: true,
                  click: async (notice) => {
                    notice.close();
                    try {
                      var obj = {
                        originalFileName: file.filename || file.file.name,
                        fileExtension:
                          file.fileExtension || file.file.name.split(".").pop(),
                        assignmentId: assignmentId,
                        assignmentProgressId: _id,
                      };

                      // Use the appropriate API for removing submitted files
                      var res = await api_RemoveSubmittedFile(obj);

                      if (res.status) {
                        // Remove the file from the multipleFilesArr if it exists there
                        multipleFilesArr = multipleFilesArr.filter(
                          (f) => f.name !== obj.originalFileName,
                        );

                        pSuccess(
                          "Successful!!",
                          "Successfully removed the file.",
                        );
                      } else {
                        pError(
                          "Error!!",
                          res.errMsg || "Something went wrong.",
                        );
                      }
                    } catch (error) {
                      console.error("Error removing file:", error);
                      pError("Error", "Failed to remove the file.");
                    }
                  },
                },
                {
                  text: "No",
                  click: (notice) => {
                    notice.close();
                    if (_id && _submitedFileObj && _submitedFileObj.length) {
                      let files = [];
                      _submitedFileObj.forEach((element) => {
                        files.push({
                          source: "12345",
                          options: {
                            type: "local",
                            file: {
                              name: element.originalFileName,
                              size: element.fileSize,
                              customUrl: element.downloadUrl,
                              type: "*",
                            },
                          },
                        });
                      });
                      _$(elem_submitAssignmentFile).filepond("files", files);
                    }
                  },
                },
              ],
            },
          ],
        ]),
      });
    },
    server: {
      process: async (
        fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort,
        transfer,
        options,
      ) => {
        const formData = new FormData();
        formData.append(fieldName, file, file.name);

        let originalFileName = file.name;
        if (containsSpecialChars(originalFileName)) {
          PNotify.error({
            title: "Error",
            text: "Please change the file name and upload again.",
            delay: 3000,
          });
          abort();
          return;
        }

        var res = await api_getUrlForUpload({
          name: originalFileName,
          type: file.type,
          uploadType: "ASSIGNMENTS",
          fileId: _id,
        });

        if (res.status) {
          fileObj = {
            name: file.name,
            size: file.size,
            url: res.data.url, // Use the URL from the API response
          };

          _$.ajax({
            xhr: function () {
              var xhr = new window.XMLHttpRequest();
              xhr.upload.addEventListener(
                "progress",
                function (e) {
                  progress(e.lengthComputable, e.loaded, e.total);
                },
                false,
              );
              return xhr;
            },
            url: res.data.url,
            type: "PUT",
            data: file,
            dataType: "text",
            cache: false,
            contentType: file.type,
            processData: false,
          })
            .done(function () {
              load("Done");
            })
            .fail(function (err) {
              error("Upload failed");
              console.error("Upload error:", err);
            });

          multipleFilesArr.push({
            name: file.name,
            size: file.size,
            url: res.data.url, // Use the URL from the API response
          });
        } else {
          PNotify.error({
            title: "Error",
            text: "Failed to get upload URL",
            delay: 3000,
          });
          abort();
        }
      },
    },
  };

  onMount(async () => {
    activeMenu.set("studentAssignment");
    _$.fn.filepond.setOptions({
      server: {
        url: "{{ url('upload')}}",
        process: {
          headers: {
            "X-CSRF-TOKEN": "csrf",
          },
        },
      },
    });
    _$.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
    _$.fn.filepond.registerPlugin(FilePondPluginGetFile);
    _$(elem_submitAssignmentFile).filepond(filepondOptions);
    var _submitedFileObj;

    if (_id) {
      let result = await api_getAssignmentProgressById();
      if (result.status) {
        assignmentId = result.data[0].assignmentDetails._id;
        _submitedFileObj = result.data[0].submittedFile
          ? result.data[0].submittedFile
          : [];

        // Initialize multipleFilesArr with existing submitted files
        if (_submitedFileObj && _submitedFileObj.length > 0) {
          multipleFilesArr = _submitedFileObj.map((file) => ({
            name: file.originalFileName,
            size: file.fileSize,
            url: file.url || file.downloadUrl,
          }));
        }

        assignmentObj = {
          topic: result.data[0].assignmentDetails.topic,
          submissionDate: result.data[0].assignmentDetails.submissionDate
            ? moment(result.data.submissionDate).format("ll")
            : "-",
          submissionTime: result.data[0].assignmentDetails.submissionTime
            ? moment(result.data[0].assignmentDetails.submissionTime).format(
                "LT",
              )
            : "-",
          notes: result.data[0].assignmentDetails.notes
            ? result.data[0].assignmentDetails.notes
            : "-",
          attachments: result.data[0].assignmentDetails.attachments
            ? result.data[0].assignmentDetails.attachments
            : "",
        };
        submitAssignmentObj = {
          notes: result.data[0].notes,
        };

        remarkAssignmentObj = {
          assignmentStatus1: result.data[0].assignmentStatus,
          assignmentRemark: result.data[0].assignmentRemark
            ? result.data[0].assignmentRemark
            : "No remark",
          remarkFile: result.data[0].remarkFile,
        };

        assignmentStatus = result.data[0].assignmentStatus;
        if (assignmentStatus != "pending" || "submitted") {
          _$(".remarkDiv").removeClass("d-none");
        }
      } else {
        PNotify.error({
          title: "Error",
          text: result.errMsg,
          delay: 3000,
        });
      }
    }

    _$(elem_submitAssignmentFile).filepond("allowMultiple", true);

    if (_id && _submitedFileObj && _submitedFileObj.length) {
      let files = [];
      _submitedFileObj.forEach((element) => {
        files.push({
          source: "12345",
          options: {
            type: "local",
            file: {
              name: element.originalFileName,
              size: element.fileSize,
              customUrl: element.downloadUrl,
              type: ["application/*", "image/*", "text/*"],
            },
          },
        });
      });
      _$(elem_submitAssignmentFile).filepond("files", files);
    }

    _$(".may-pond .filepond--item-panel").css("background", "#369763");
  });

  async function api_getAssignmentProgressById() {
    let action = "assignment.getAssignmentProgressById";
    var response = await api_handleApiCalls(action, { id: _id });
    return response;
  }

  async function api_addUpdateAssignment(data) {
    let action = "assignment.submitAssignment";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  let validateAssignment = () => {
    errorObj = {};
    if (!submitAssignmentObj.notes) {
      var newErrorObj = {};
      if (!submitAssignmentObj.notes) {
        newErrorObj.notes = "Assignment notes is required Field.";
      }

      errorObj = newErrorObj;
    }
    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;
  };

  let addUpdateAssignmentBtn = async () => {
    if (validateAssignment()) {
      submitAssignmentObj.id = _id;
      var assignmentRes = await api_addUpdateAssignment(submitAssignmentObj);
      if (assignmentRes.status) {
        if (fileObj) {
          await api_updateSubmittedFiles([
            {
              fileId: _id,
              originalFileName: fileObj.name,
              fileExtension: fileObj.name.split(".").pop(),
              fileSize: fileObj.size,
              url: fileObj.url || "",
            },
          ]);
        }
        // create success
        PNotify.success({
          title: "Success",
          text: "Assignment Saved Successfully",
          delay: 3000,
        });
        window.history.back();
      } else {
        PNotify.error({
          title: "Error",
          text: assignmentRes.errMsg,
          delay: 3000,
        });
      }
    }
  };

  let submitBtn = async () => {
    if (validateAssignment()) {
      submitAssignmentObj.id = _id;
      submitAssignmentObj.assignmentStatus = "submitted";

      var assignmentRes = await api_addUpdateAssignment(submitAssignmentObj);
      if (assignmentRes.status) {
        // Only proceed with file updates if there are files to submit
        if (multipleFilesArr.length > 0) {
          // Prepare the array of files to be sent
          var filesData = multipleFilesArr.map((file) => ({
            fileId: _id,
            originalFileName: file.name,
            fileExtension: file.name.split(".").pop(),
            fileSize: file.size,
            url: file.url || "",
          }));

          console.log("Submitting files:", filesData);

          // Send only the array of files in the API call
          const fileUpdateResult = await api_updateSubmittedFiles(filesData);

          if (!fileUpdateResult.status) {
            pError(
              "Warning",
              "Assignment was submitted but there was an issue with file uploads. Please check your submissions.",
            );
          }
        }

        PNotify.success({
          title: "Success",
          text: assignmentRes.data || "Assignment submitted successfully",
          delay: 3000,
        });
        window.history.back();
      } else {
        PNotify.error({
          title: "Error",
          text: assignmentRes.errMsg || "Failed to submit assignment",
          delay: 3000,
        });
      }
    }
  };
</script>

<div class="content-body fadeIn">
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
  >
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/studentAssignment">Assignment</a>
          </li>
          <li class="breadcrumb-item">
            <a href="/dashboard/studentAssignment">View Assignment</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {assignmentObj.topic}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        {assignmentObj.topic}
      </h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
        {#if assignmentStatus == "pending"}
          <span class="badge badge-secondary">{assignmentStatus}</span>
        {:else if assignmentStatus == "submitted"}
          <span class="badge badge-primary">{assignmentStatus}</span>
        {:else if assignmentStatus == "approved"}
          <span class="badge badge-success">{assignmentStatus}</span>
        {:else if assignmentStatus == "rejected"}
          <span class="badge badge-danger">{assignmentStatus}</span>
        {:else if assignmentStatus == "resubmit"}
          <span class="badge badge-warning">{assignmentStatus}</span>
        {/if}
      </p>
    </div>
    <div class="d-block">
      <!-- <button
        on:click={addUpdateAssignmentBtn}
        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        ><i data-feather="save" class="wd-10 mg-r-5"></i>
        Save
      </button> -->
    </div>
  </div>
  <hr class="full-width" />
  <!-- <hr class="mg-y-20"> -->
  <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div class="row row-sm mg-b-10">
      <div class="col-sm-6">
        <fieldset class="form-fieldset">
          <legend>Submit Assignment</legend>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="d-block"
                >Notes <span class="required-field">*</span></label
              >
              <textarea
                bind:value={submitAssignmentObj.notes}
                type="text"
                on:blur={() => clearError("notes")}
                class="form-control"
                class:parsley-error={errorObj.notes}
                placeholder="Enter your notes"
              />
              {#if errorObj.notes}
                <div class="parsley-errors-list">{errorObj.notes}</div>
              {/if}
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="d-block">Upload</label>

              <input
                type="file"
                bind:this={elem_submitAssignmentFile}
                class="my-pond"
                data-instant-upload="false"
                data-allow-image-preview="false"
                name="filepond"
                multiple
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <!-- <img src="..." class="card-img-top" alt="..."> -->
          <div class="card-header">
            <h5>Assignment</h5>
          </div>
          <div class="card-body">
            <h6 class="card-title">
              <span class="tx-bold">Topic:</span>
              {assignmentObj.topic}
            </h6>
            <p class="card-text">
              <span class="tx-bold">Submission Date:</span
              >{assignmentObj.submissionDate}
            </p>
            <p class="card-text">
              <span class="tx-bold">Submission Time:</span
              >{assignmentObj.submissionTime}
            </p>
            <p class="card-text">
              <span class="tx-bold">Note:</span>{assignmentObj.notes}
            </p>

            <div class="form-group row col-md-12">
              <label class="tx-bold">Attachments :</label>
              {#if assignmentObj.attachments}
                <ul>
                  {#each assignmentObj.attachments as file}
                    <li>
                      <a href={file.downloadUrl} target="_blank"
                        >{file.originalFileName}</a
                      >
                    </li>
                  {/each}
                </ul>
              {:else}
                -
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h5>Remark:</h5>
    </div>
    <div class="card-body">
      <p class="card-text">
        <span class="tx-bold"
          >Remark:
        </span>{remarkAssignmentObj.assignmentRemark}
      </p>
      <div class="form-group row col-md-12">
        <label class="tx-bold">Remark File: </label>
        {#if remarkAssignmentObj.remarkFile}
          <ul>
            {#each remarkAssignmentObj.remarkFile as file}
              <li>
                <a href={file.downloadUrl} target="_blank"
                  >{file.originalFileName}</a
                >
              </li>
            {/each}
          </ul>
        {:else}
          -
        {/if}
      </div>
    </div>
  </div>

  <div class="d-sm-flex align-items-center justify-content-end">
    <div class="d-block">
      <!-- <button
        on:click={addUpdateAssignmentBtn}
        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        ><i data-feather="save" class="wd-10 mg-r-5"></i>
        Save
      </button> -->
      <button
        on:click={submitBtn}
        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        ><i data-feather="send" class="wd-10 mg-r-5"></i>
        Submit
      </button>
    </div>
  </div>
</div>
