<script>
    import {onMount, tick} from 'svelte';
    import { navigate } from 'svelte-routing';
    import api_handleApiCalls from '../utils/apiCallHandler';
    import { pError, pSuccess } from '../utils/notify.svelte';
    import moment from  'moment'
    export let parentId
    export let batchId
    export let tillFullPath = []
    let isLoading = true
    let markLoading = false
    let elemStudentsTable
    let folderObj = {}
    let instanceSelectizeStudentTable

    const getFolderData = async (batchId, parentId) => {
        isLoading = true
        let response = await api_handleApiCalls("folder.getFolderNamesForIds", [batchId, parentId])
        if(!response.status) window.history.back()
        isLoading = false

        let batchName = response.data.batchObj.name;
        let idListArr = response.data.idListArr.reverse();
        folderObj = idListArr[0]

        await tick()
        if(folderObj.taught) datatableInit()

    }

    const markAsTaught =    async (id) => {
        if(!id) return 
        markLoading = true
        let response = await api_handleApiCalls("studyMonitoring.markTaught", {batchId, parentId})
        markLoading = false
        await tick()
        if(response.status) {
            pSuccess("Success!", response.data) 
            getFolderData(batchId, parentId)
        }
        else pError("Error!", response.errMsg)   
    }

    onMount(()=>{
        getFolderData(batchId, parentId)
    })

    const viewStudentClick = (id)   =>  {
        navigate(`/dashboard/student/view/${id}/studyMonitoringChart/${tillFullPath.join('-')}`)
    }

    function getFilterData() {
        
        return {
         batchId,
         parentId
        };
      }
    // studyMonitoring.getMarkedMonitoringData
    const datatableInit = () => {
        if(instanceSelectizeStudentTable) instanceSelectizeStudentTable.destroy()
        instanceSelectizeStudentTable   =   _$(elemStudentsTable).DataTable({
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                localStorage.setItem(window.location.pathname, JSON.stringify(data));
            },
            stateLoadCallback: function (settings) {
                return JSON.parse(localStorage.getItem(window.location.pathname));
            },
            "processing": true,
            "serverSide": true,
            responsive: false,
            select: true,
            "columnDefs": [
                { "orderable": false, "targets": [0] },
            ],
            language: {
                processing: "",
                searchPlaceholder: "Student Name | Number",
                sSearch: "",
                lengthMenu: "_MENU_ Students/page",
            },
            ajax: function (data, callback, settings) {
                var filterObj = {  ...getFilterData()};
                  
                   filterObj.start = data.start;
                   filterObj.length = data.length;
                   filterObj.search = data.search.value;
                   filterObj.draw = data.draw;
                   filterObj.sort = data.sort;
                _$.ajax({
                    url: API_URL,
                    method: "post",
                    data: {
                        action: "studyMonitoring.getMarkedMonitoringData",
                        accessToken: localStorage.getItem("accessToken"),
                        data: filterObj,
                    },
                    success: async function (resData) {
                        var result = [];
                        if (resData.status) {
                        var paginationData = resData.data;
                        result = {
                            recordsTotal: Number(paginationData.totalCount),
                            recordsFiltered: Number(paginationData.filteredCount),
                            data: paginationData.data,
                        };
                        if (!resData.data.length) {

                        }
                            await tick();
                            callback(result);
                        } else {
                            await tick();
                            callback({
                                recordsTotal: 0,
                                recordsFiltered: 0,
                                data: [],
                            });
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {

                    },
                });
            },
            columns: [
                    {
                        data: "student",
                        render: (data, type, full) => {
                            return data.name ? data.name : '-'
                        },
                    },
                    {
                        data: "student",
                        render: (data, type, full) => {
                            return (data && data.mobileNo) ? data.mobileNo : '-'
                        },
                    },
                    {
                        data: "createdOn",
                        render: (data, type, full) => {
                            let d = new Date(data);
                            let date = moment(d).format("DD-MM-YYYY hh:mm A");
                            return date
                        },
                    },
                    {
                        data: "student",
                        render: (data, type, full) => {
                            return `<a 
                                        href="#" 
                                        class="btn-view-student 
                                        mg-r-5" 
                                        data-id="${data._id}" 
                                        data-toggle="tooltip" 
                                        data-placement="top" 
                                        title="View Batch Students"> 
                                            View <i data-feather="arrow-up-right" class="wd-15 mg-r-5"></i> 
                                        </a>`
                        },
                    },
            ],
            "drawCallback": function () { 
                feather.replace()
                _$('.btn-view-student').unbind('click').click(function (e) {
                    e.preventDefault();
                    var dataId = _$(this).attr("data-id");
                    viewStudentClick(dataId)
                })
            },
        })
    }
    
    
</script>
<style>
    .center-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }
</style>

<div class="col-md-12 pd-5" style="margin-top: 1rem;">
        <div style="margin-bottom: 3rem;"><span class="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15">List of Students who marked done this topic</span></div>
        {#if !isLoading}
            {#if !folderObj.taught}
                <div class="center-container">
                    <p>{folderObj.name} is not set as taught, to set as taught click below button </p>
                    <button disabled={markLoading} on:click={()=>markAsTaught(folderObj._id)} class="btn btn-xs btn-success">Mark {folderObj.name} as Taught</button>
                </div>
                {:else}
                <table  bind:this={elemStudentsTable} class="table table-reposive mt-5">
                    <thead>
                        <tr>
                            <th>Student Name</th>
                            <th>Student Mobile</th>
                            <th>Mark Completed on</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                </table>
            
            {/if}
        {/if}

    

</div>
