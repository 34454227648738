<script>
  export let data = [];
  // $: console.log("previous fees data", data);
  let convertToINR = function (input) {
    let amount = Number(input);
    return amount.toLocaleString("en-IN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: "currency",
      currency: "INR",
    });
  };
</script>

<div class="card" style="margin-bottom: 20px;">
  <div class="card-header d-flex justify-content-between">
    <h6 class="lh-5 mg-b-0 text-capitalize">Previous</h6>
  </div>
  <div class="card-body pd-x-20 pd-b-10">
    <div class="table-responsive">
      <table class="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
        <thead>
          <tr class="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
            <th class="">Batch Name</th>
            <th class="">Actual Fees</th>
            <th class="">Agreed Fees</th>
            <th class="">Discount</th>
            <th class="">Total</th>
          </tr>
        </thead>
        <tbody>
          {#each data as datum}
            <tr>
              <td class="tx-color-03 align-middle">{datum.batch.name}</td>
              <td class="align-middle"
                >{convertToINR(
                  datum.isFree == "yes"
                    ? datum.totalPayableAmount
                    : datum.batchFees
                )}</td
              >
              <td class="align-middle"
                >{convertToINR(
                  datum.isFree == "yes"
                    ? datum.totalPayableAmount
                    : datum.agreedFees
                )}</td
              >
              <td class="align-middle"
                >{convertToINR(
                  datum.isFree == "yes"
                    ? datum.totalPayableAmount
                    : datum.batchFees - datum.agreedFees
                )}</td
              >
              <td class="align-middle"
                >{convertToINR(datum.totalPayableAmount)}</td
              >
            </tr>
          {/each}
        </tbody>
        <tfoot
          style="border-top: 1px solid rgba(72, 94, 144, 0.16); border-right-color: rgba(72, 94, 144, 0.16); border-bottom-color: rgba(72, 94, 144, 0.16); border-left-color: rgba(72, 94, 144, 0.16);"
        >
          <tr>
            <td class="tx-color-03 align-middle">Total</td>
            <td class="align-middle">
              {data.length
                ? convertToINR(
                    data.reduce(
                      (total, datum) =>
                        total +
                        (datum.isFree == "yes"
                          ? datum.totalPayableAmount
                          : datum.batchFees),
                      0
                    )
                  )
                : 0}
            </td>
            <td class="align-middle">
              {data.length
                ? convertToINR(
                    data.reduce(
                      (total, datum) =>
                        total +
                        (datum.isFree == "yes"
                          ? datum.totalPayableAmount
                          : datum.agreedFees),
                      0
                    )
                  )
                : 0}
            </td>
            <td class="align-middle">
              {data.length
                ? convertToINR(
                    data.reduce(
                      (total, datum) =>
                        total +
                        (datum.isFree == "yes"
                          ? datum.totalPayableAmount
                          : datum.batchFees - datum.agreedFees),
                      0
                    )
                  )
                : 0}
            </td>
            <td class="align-middle">
              {data.length
                ? convertToINR(
                    data.reduce(
                      (total, datum) => total + datum.totalPayableAmount,
                      0
                    )
                  )
                : 0}</td
            >
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
