<script>
  import { onMount, tick } from "svelte";

  import { Router, Link, Route } from "svelte-routing";
  import QuestionUploader from "../../questionUpload/list/questionList.svelte";

  export let uploadedIds = [];
  let isUploadDone = false;
  let demoDataTable, filterQuestionType, filterQuestionLevel;

  let qList = [];
  let filter = {};

  export let selectedQuestions = [];

  $: selectedQuestions && onChangeSQ();

  let onChangeSQ = function () {
    // console.log("selectedQuestions : ", selectedQuestions);
  };

  let selectedIdArr = [];
  let tempDisplayList = [];

  let onCBChange = function (ele,aQuestion) {
    let obj ={}
      
    if(aQuestion.qType == 'numerical'){
            obj={
                ele :  ele ,q_id : aQuestion._id, 
                questionText : aQuestion.data.question.English, 
                answer: aQuestion.data.answer,
                additionalInfo: aQuestion.additionalInfo,
                qId:aQuestion.qId
            }   
        }
        else{
            obj = {
                ele :  ele ,q_id : aQuestion._id, 
                questionText : aQuestion.data.question.English, 
                answer: aQuestion.data.answer,
                qOptions: aQuestion.data.option_a ? {
                        option_a: aQuestion.data.option_a.English,
                        option_b: aQuestion.data.option_b.English,
                        option_c: aQuestion.data.option_c.English, 
                        option_d: aQuestion.data.option_d.English
                    }
                                                        
                    : {},
                    additionalInfo: aQuestion.additionalInfo,
                    qId:aQuestion.qId
            }
            if(aQuestion.data.option_e) obj.qOptions['option_e'] =  aQuestion.data.option_e.English
            if(aQuestion.data.option_f) obj.qOptions['option_f'] =  aQuestion.data.option_f.English
            if(aQuestion.data.option_g) obj.qOptions['option_g'] =  aQuestion.data.option_g.English
            if(aQuestion.data.option_h) obj.qOptions['option_h'] =  aQuestion.data.option_h.English

        }   
                       
if (ele.target.checked) {
selectedIdArr.push({...obj});
tempDisplayList.push(obj.q_id);
} else {
selectedIdArr = selectedIdArr.filter((e) => e.q_id !== obj.q_id);
tempDisplayList = tempDisplayList.filter((e) => e !== obj.q_id);
}

selectedIdArr = selectedIdArr;
tempDisplayList = tempDisplayList;

  };

  let onChooseSelectedQuestion = function () {
    // console.log("selectedIdArr---------:::",selectedIdArr)
    onModalSelectQuestions(selectedIdArr);
  };

  function bindDatatable() {
    _$(demoDataTable).DataTable({
      responsive: true,
      dom: "ip",
      language: {
        processing: "",
        searchPlaceholder: "Search # ID",
        sSearch: "",
        lengthMenu: "_MENU_ Questions/page",
      },
      ajax: async function (data, callback, settings) {
        var result = [];
        let resData = await api_getQuestionsListByIds({
          start: data?.start,
          length: data?.length,
          search: data?.search?.value,
          ids: uploadedIds || [],
          filter,
        });

        if (resData.status) {
          var paginationData = resData.data;
          result = {
            recordsTotal: Number(paginationData.totalCount),
            recordsFiltered: Number(paginationData.filteredCount),
            data: paginationData.data,
          };
          qList = [];
          await tick();
          qList = paginationData.data;
          qList.map((question) => {
            if (
              !(tempDisplayList && tempDisplayList.length) ||
              !tempDisplayList.includes(question._id)
            ) {
              selectedIdArr = [
                ...selectedIdArr,
                {
                  q_id: question._id,
                  questionText: question.data.question.English,
                },
              ];
              tempDisplayList = [...tempDisplayList, question._id];
            }
          });
          MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
          callback(result);
        } else {
          console.log("Something went wrong");
        }
      },
      columns: [
        {
          data: "qId",
          render: function (data, type, full) {
            return "";
          },
        },
      ],
      drawCallback: function () {
        feather.replace();
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        // _$('.btn-edit-question').unbind('click').click(function (e) {
        //     e.preventDefault();
        //     var questionId = _$(this).attr("question-id");
        //     navigate(("/dashboard/questions/view/" + questionId))
        //     return false;
        // })
      },
      processing: true,
      serverSide: true,
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }
  async function api_getQuestionsListByIds(data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var payload = JSON.stringify({
      action: "test.getQuestionsListByIds",
      accessToken: localStorage.getItem("accessToken"),
      data: data,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };
    try {
      var resObj = await fetch(API_URL, requestOptions);
      var response = await resObj.json();
      return response;
    } catch (error) {
      console.error("error", error);
      return { error: error };
    }
  }

  let onQuestionsUploadDone = async function (uploadedQuestionIds) {
    isUploadDone = true;
    uploadedIds = [...uploadedIds, ...Object.values(uploadedQuestionIds)];
    let resData = await api_getQuestionsListByIds({
      start: 0,
      length: uploadedIds.length,
      search: "",
      ids: uploadedIds,
    });
    if (resData.status) {
      qList = [];
      await tick();
      qList = resData.data.data;
      // console.log("qlist::::::::::::::::::",qList)
      let obj = {}
      qList.map((question) => {
        if (
          !(tempDisplayList && tempDisplayList.length) ||
          !tempDisplayList.includes(question._id)
        ) {
          if(question.qType == 'numerical'){
            obj={
                q_id : question._id, 
                questionText : question.data.question.English, 
                answer: question.data.answer,
                additionalInfo: question.additionalInfo,
                qId:question.qId
            }   
        }else{
          obj = {
              q_id : question._id, 
                questionText : question.data.question.English, 
                answer: question.data.answer,
                qOptions: question.data.option_a ? {
                        option_a: question.data.option_a.English,
                        option_b: question.data.option_b.English,
                        option_c: question.data.option_c.English, 
                        option_d: question.data.option_d.English
                    }
                                                        
                    : {},
                    additionalInfo: question.additionalInfo,
                    qId:question.qId
            }
            if(question.data.option_e) obj.qOptions['option_e'] =  question.data.option_e.English
            if(question.data.option_f) obj.qOptions['option_f'] =  question.data.option_f.English
            if(question.data.option_g) obj.qOptions['option_g'] =  question.data.option_g.English
            if(question.data.option_h) obj.qOptions['option_h'] =  question.data.option_h.English

        }
          
          selectedIdArr = [
            ...selectedIdArr,
            obj
            
            
          ];
          tempDisplayList = [...tempDisplayList, question._id];
        }
      });
    
    }
    await tick();
    bindDatatable();
    var tags = _$(".chips-tag").selectize({
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create" style="padding-left:15px"><i class="icon ion-md-search" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Search by tag : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
      create: function (input) {
        return { value: input, text: input };
      },
      onChange: function (value) {
        filter.tags = value;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        _$(demoDataTable).DataTable().ajax.reload(null, false);
      },
    });

    // _$('.slectizedd').selectize({ maxItems: 1 });
    _$(filterQuestionType).selectize({
      maxItems: 1,
      onChange: function (value) {
        filter.qType = value;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        _$(demoDataTable).DataTable().ajax.reload(null, false);
      },
    });
    _$(filterQuestionLevel).selectize({
      maxItems: 1,
      onChange: function (value) {
        filter.level = value == "all" ? "" : value;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        _$(demoDataTable).DataTable().ajax.reload(null, false);
      },
    });
    await tick();
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    _$(demoDataTable).DataTable().ajax.reload(null, false);
  };
  export let onModalSelectQuestions = {};

  onMount(function () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  });
</script>

<style>
  .empty-state-img {
    text-align: center;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
</style>

<div data-label="Example" class="df-example demo-table" style="height: 100%;">
  <div class="row" style="height: 100%;">
    <div class="col-sm-2 folder-list-sec" style="height: 100%;">
      <!-- some filter or list (vertical) -->
      <div class="d-flex justify-content-end" style="flex-direction:column">
        <!-- buttons -->
        <button
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25 mg-b-20"
          style="width: 100%;"
          disabled={!isUploadDone}
          on:click={() => {
          
            isUploadDone = false;
            //   onModalSelectQuestions(tempDisplayList);
          }}>
          upload more
        </button>
        <button
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25 mg-b-20"
          style="width: 100%;"
          on:click={() => {
          
            onChooseSelectedQuestion()
            // onModalSelectQuestions(tempDisplayList);
          }}>
          Add to test
        </button>
      </div>
    </div>
    <div
      class="col-sm-10 "
      style="height: 100%; display: flex; flex-direction: column; overflow: hidden;">
      {#if isUploadDone}
        <div>
          <!-- filters -->
          <div style="margin-bottom: 10px;">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                <li class="breadcrumb-item active">
                  Select Questions
                </li>
              </ol>
            </nav>
          </div>
          <div class="row row-xs">
            <div class="col-sm">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">#</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  id="qId"
                  placeholder="QuestionId"
                  value={filter.qId || ''}
                  on:input={(event) => {
                    filter.qId = parseInt(event.target.value) || '';
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                    _$(demoDataTable).DataTable().ajax.reload(null, false);
                  }} />
              </div>
            </div>
            <div class="col-sm">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i data-feather="tag" style="width: 15px;height: 17px;" />
                  </span>
                </div>
                <input
                  class="info form-control chips-tag has-prepend-icon"
                  type="text"
                  data-role="tagsinput"
                  name="chips-tag"
                  placeholder="Search tags..." />
              </div>
            </div>
            <div class="col-sm">
              <select
                id="qType"
                name="qTYpe"
                type="text"
                class="slectizedd info form-control"
                bind:this={filterQuestionType}>
                <option value="">Select Type</option>
                <option value="all">All Types</option>
                <option value="multipleChoice">Multiple Choice</option>
                <option value="multipleResponse">Multiple Response</option>
                <option value="singleDigit">Integer Type</option>
                <option value="decimalType">Decimal Type</option>
                <option value="trueFalse">True False</option>
                <option value="fillInTheBlanks">Fill in the Blanks</option>
              </select>
            </div>
            <div class="col-sm">
              <select
                id="qType"
                name="qTYpe"
                type="text"
                class="slectizedd info form-control"
                bind:this={filterQuestionLevel}>
                <option value="">Select Level</option>
                <option value="all">All Levels</option>
                <option value="easy">Easy</option>
                <option value="mid">Medium</option>
                <option value="hard">Hard</option>
              </select>
            </div>
            <div class="col-sm">
              <div class="form-row no-gutters">
                <div class="form-group col-sm">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">+</span>
                    </div>
                    <input
                      type="number"
                      class="form-control pd-0 pd-l-5"
                      id="pmarksEle"
                      placeholder="+ve"
                      value={filter.pmarks || ''}
                      on:input={(event) => {
                        filter.pmarks = event.target.value;
                        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                        _$(demoDataTable).DataTable().ajax.reload(null, false);
                      }} />
                  </div>
                </div>
                <div class="form-group col-sm">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">-</span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      id="nmarksEle"
                      placeholder="-ve"
                      value={filter.nmarks || ''}
                      on:input={(event) => {
                        filter.nmarks = event.target.value;
                        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                        _$(demoDataTable).DataTable().ajax.reload(null, false);
                      }} />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <button
                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25"
                style="width: 100%;"
                on:click={() => {
                  filter = {};
                  _$('.chips-tag')[0].selectize.setValue('');
                  _$(filterQuestionLevel)[0].selectize.setValue('');
                  _$(filterQuestionType)[0].selectize.setValue('');
                  MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                  _$(demoDataTable).DataTable().ajax.reload(null, false);
                }}><i data-feather="x-circle" class="wd-10 mg-r-5" />
                Clear Filter
              </button>
            </div>
          </div>
          <!-- filters -->
        </div>
        <!-- questions list -->
        <div class="q-list-sec" style="flex: 1;overflow: auto;">
          <input
            type="checkbox"
            class="custom-control-input"
            name="selected_q"
            bind:group={selectedQuestions}
            value="0"
            hidden />
          {#each qList as aQuestion}
            <div
              class="question-display"
              style="border-radius: 4px; border : 1px solid #ddd;    display: flex;align-items: center; margin-bottom: 15px;">
              <div style="    width: 35px;">
                <div class="custom-control custom-checkbox">
                  {#if tempDisplayList.indexOf(aQuestion._id) > -1}
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="sq_{aQuestion._id}"
                      name="selected_q"
                      on:change={(ele) => onCBChange(ele, aQuestion)}
                      checked
                      value={aQuestion._id} />
                    <label
                      class="custom-control-label"
                      for="sq_{aQuestion._id}" />
                  {:else}
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="sq_{aQuestion._id}"
                      name="selected_q"
                      on:change={(ele) => onCBChange(ele, aQuestion)}
                      value={aQuestion._id} />
                    <label
                      class="custom-control-label"
                      for="sq_{aQuestion._id}" />
                  {/if}
                </div>
              </div>
              <div style="padding : 15px; padding-left: 0; ">
                {@html aQuestion.data && aQuestion.data.question && aQuestion.data.question.English ? aQuestion.data.question.English : '-'}
              </div>
            </div>
          {:else}
            <div class="empty-state-img">
              <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
              <p class="tx-bold">
                No questions found OR all questions already added to test
              </p>
            </div>
          {/each}
        </div>
        <!-- questions list -->
        <!-- table pagination -->
        <div>
          <table bind:this={demoDataTable} class="table" style="display: none;">
            <thead>
              <tr>
                <th class="wd-20p">ID</th>
              </tr>
            </thead>
            <tbody />
          </table>
        </div>
        <!-- table pagination -->
        <!-- buttons -->
        <div style="padding-top: 15px;">
          {#if selectedIdArr.length > 0}
            <button
              on:click={onChooseSelectedQuestion}
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25"
              style="width: 100%;">
              <i data-feather="pocket" class="wd-10 mg-r-5" />
              Choose
              {selectedIdArr.length}
              Selected Question
            </button>
          {:else}
            <button
              on:click={onChooseSelectedQuestion}
              disabled
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25"
              style="width: 100%;">
              <i data-feather="pocket" class="wd-10 mg-r-5" />
              Choose Selected Question
            </button>
          {/if}
        </div>
        <!-- buttons -->
      {:else}
        <QuestionUploader bind:onQuestionsUploadDone hideBreadcrumbs={true} />
      {/if}
    </div>
  </div>
</div>

<div class="wd-100p ht-100p" />
