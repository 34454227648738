<script>
  import { Router, Link, Route } from "svelte-routing";
  import { onMount } from "svelte";
  import List from "./list/list.svelte";
  import AddUpdate from "./addupdate/addupdate.svelte";
  import { activeMenu } from "../stores";

function init() {
    console.log("redirecting ");
    activeMenu.set("studentstudyreport");
}
onMount(init);

</script>


<Router>
<Route path=":id" let:params>
    {#if params.id== "new"}
    <AddUpdate id={null} />
    {:else}
    <AddUpdate id={params.id} />
    {/if}
</Route>
<Route path="">
    <List />
</Route>
</Router>


