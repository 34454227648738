<script>
    import { createEventDispatcher, onMount, beforeUpdate } from 'svelte';
    import StudentForms from '../students/addupdate/index.svelte'
    const dispatch = createEventDispatcher();
    export let studentId
    onMount(async () => {
        feather.replace();
        dispatch('setActiveTab', {
            tabName: 'Details'
        });
    })
</script>

<StudentForms _id={studentId} isComponent={true} />
