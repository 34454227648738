<script>
  import { Router, Link, link, Route, navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import {
    hasOptedForPoints,
    STUDY_MONITORING_ACCESS,
  } from "../../orgAccessControl";
  import { userData, activeMenu } from "../stores";
  import MODULES from "../utils/modules";

  let localUserData = {
    fullName: "...",
    role: "...",
    avatar: "/images/man.png",
    access: {
      home: false,
      students: false,
      settings: false,
    },
  };
  let roleIsStudent = false;
  let _activeMenu = "home";

  let isPoints = false;
  const unsubscribe = userData.subscribe((value) => {
    localUserData = value;
    roleIsStudent = value.role == "student";
    isPoints = hasOptedForPoints(value);
  });

  const unsubscribe1 = activeMenu.subscribe((value) => {
    _activeMenu = value;
  });

  function signOut() {
    window.localStorage.removeItem("accessToken");
    navigate("/");
  }

  onMount(function () {
    const scroll1 = new PerfectScrollbar("#scroll1", {
      suppressScrollX: true,
    });

    _$('[data-toggle="tooltip"]').tooltip();
    if (_$(".aside-backdrop").length === 0) {
      _$("body").append('<div class="aside-backdrop"></div>');
    }

    var mql = window.matchMedia("(min-width:992px) and (max-width: 1199px)");

    function doMinimize(e) {
      if (e.matches) {
        _$(".aside").addClass("minimize");
      } else {
        _$(".aside").removeClass("minimize");
      }

      // asideBody.update()
    }

    mql.addListener(doMinimize);
    doMinimize(mql);

    _$(".aside-menu-link").on("click", function (e) {
      e.preventDefault();

      if (window.matchMedia("(min-width: 992px)").matches) {
        _$(this).closest(".aside").toggleClass("minimize");
      } else {
        _$("body").toggleClass("show-aside");
      }

      // asideBody.update()
    });

    _$(".nav-aside .with-sub").on("click", ".nav-link", function (e) {
      e.preventDefault();

      _$(this).parent().siblings().removeClass("show");
      _$(this).parent().toggleClass("show");

      // asideBody.update()
    });

    _$("body").on("mouseenter", ".minimize .aside-body", function (e) {
      console.log("e");
      _$(this).parent().addClass("maximize");
    });

    _$("body").on("mouseleave", ".minimize .aside-body", function (e) {
      _$(this).parent().removeClass("maximize");

      // asideBody.update()
    });

    _$("body").on("click", ".aside-backdrop", function (e) {
      _$("body").removeClass("show-aside");
    });
  });
</script>

<aside class="aside aside-fixed fadeIn">
  <div class="aside-header">
    <a
      href="/dashboard"
      class="aside-logo"
      style="font-size: 18px !important;text-transform: capitalize;    line-height: 1;"
      >{localUserData.orgName}</a
    >
    <a href="/dashboard" class="aside-menu-link">
      <i data-feather="menu" />
      <i data-feather="x"></i>
    </a>
  </div>
  <div id="scroll1" class=" aside-body pos-relative scrollbar-sm">
    <div class="aside-loggedin">
      <div class="d-flex align-items-center justify-content-start">
        <a href="/dashboard" class="avatar"
          ><img src={localUserData.avatar} class="rounded-circle" alt="" /></a
        >
        <div class="aside-alert-link">
          <!-- <a
            href="/dashboard"
            class="new"
            data-toggle="tooltip"
            title="You have 2 unread messages"><i
              data-feather="message-square" /></a>
          <a
            href="/dashboard"
            class="new"
            data-toggle="tooltip"
            title="You have 4 new notifications"><i data-feather="bell" /></a> -->
          <a href="javascript:void(0);" on:click={signOut} title="Sign out"
            ><i data-feather="log-out" /></a
          >
        </div>
      </div>
      <div class="aside-loggedin-user">
        <a
          href="#loggedinMenu"
          class="d-flex align-items-center justify-content-between mg-b-2"
          data-toggle="collapse"
        >
          <h6 class="tx-semibold mg-b-0">{localUserData.fullName}</h6>
          <!-- <i data-feather="chevron-down" /> -->
        </a>
        <p class="tx-color-03 tx-12 mg-b-0">
          {localUserData.role} | {localUserData.mobileNo}
        </p>
      </div>
      <div class="collapse" id="loggedinMenu">
        <ul class="nav nav-aside mg-b-0">
          <!--  TODO change access type -->
          {#if localUserData.access.home}
            <li class="nav-item">
              <a href="/editprofile" class="nav-link"
                ><i data-feather="edit" />
                <span>Edit Profile</span></a
              >
            </li>
            <li class="nav-item">
              <a href="/viewprofile" use:link class="nav-link"
                ><i data-feather="user" />
                <span>View Profile</span></a
              >
            </li>

            <li class="nav-item">
              <a href="/" class="nav-link"
                ><i data-feather="settings" />
                <span>Account Settings</span></a
              >
            </li>
            <li class="nav-item">
              <a href="/" class="nav-link"
                ><i data-feather="help-circle" />
                <span>Help Center</span></a
              >
            </li>
          {/if}
          <li class="nav-item">
            <a href="/" use:link class="nav-link"
              ><i data-feather="log-out" />
              <span>Sign Out</span></a
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- aside-loggedin -->
    <ul class="nav nav-aside">
      {#if roleIsStudent}
        <li class="nav-label">Home</li>
        {#if MODULES.hasAccess(MODULES.MANAGE_MY_TEST)}
          <li class="nav-item" class:active={_activeMenu == "myTests"}>
            <a href="/dashboard/myTests" use:link class="nav-link"
              ><i data-feather="clipboard" />
              <span>My Tests</span></a
            >
          </li>
        {/if}
        <li class="nav-item" class:active={_activeMenu == "practiceTest"}>
          <a href="/dashboard/practiceTest" use:link class="nav-link"
            ><i data-feather="clipboard" />
            <span>Practice Test</span></a
          >
        </li>
        <li class="nav-item" class:active={_activeMenu == "examReport"}>
          <a href="/dashboard/exam-reports" use:link class="nav-link"
            ><i data-feather="file-text" />
            <span>Tests Results</span></a
          >
        </li>

        <li
          class="nav-item"
          class:active={_activeMenu == "liveLectureForStudent"}
        >
          <a href="/dashboard/lectures" use:link class="nav-link"
            ><i data-feather="youtube" />
            <span>Lectures</span></a
          >
        </li>

        <li class="nav-item" class:active={_activeMenu == "studentAttendance"}>
          <a href="/dashboard/studentAttendance" use:link class="nav-link">
            <i data-feather="calendar" /><span>Student Attendance</span>
          </a>
        </li>

        <li class="nav-item" class:active={_activeMenu == "studentAssignment"}>
          <a href="/dashboard/studentAssignment" use:link class="nav-link">
            <i data-feather="globe"></i><span>Student Assignment</span>
          </a>
        </li>

        <li class="nav-item" class:active={_activeMenu == "studentstudyreport"}>
          <a href="/dashboard/reports/study" use:link class="nav-link">
            <i data-feather="trello"></i><span>Study Reports</span>
          </a>
        </li>

        <li class="nav-item" class:active={_activeMenu == "holidays"}>
          <a href="/dashboard/holidays" use:link class="nav-link">
            <i data-feather="umbrella" /><span>Holidays</span>
          </a>
        </li>

        <li class="nav-item" class:active={_activeMenu == "studentFeedback"}>
          <a href="/dashboard/studentFeedback" use:link class="nav-link">
            <i data-feather="edit-3"></i><span>Student Feedback</span>
          </a>
        </li>
      {:else}
        {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD) || MODULES.hasAccess(MODULES.MANAGE_BATCH) || MODULES.hasAccess(MODULES.MANAGE_JOIN_REQUEST) || MODULES.hasAccess(MODULES.MANAGE_ATTENDANCE) || MODULES.hasAccess(MODULES.MANAGE_CLASS_TIMINGS) || MODULES.hasAccess(MODULES.MANAGE_BRANCH) || MODULES.hasAccess(MODULES.MANAGE_COURSE) || MODULES.hasAccess(MODULES.MANAGE_CLASSES)}
          <li class="nav-label">Dashboard</li>
        {/if}

        {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD)}
          <li class="nav-item" class:active={_activeMenu == "home"}>
            <a href="/dashboard/" use:link class="nav-link">
              <i data-feather="home" />
              <span>Home</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_BATCH)}
          <li class="nav-item" class:active={_activeMenu == "batch"}>
            <a href="/dashboard/batch" use:link class="nav-link">
              <i data-feather="grid" /><span>Batches</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_JOIN_REQUEST)}
          <li class="nav-item" class:active={_activeMenu == "batchJoinRequest"}>
            <a href="/dashboard/batchJoinRequest" use:link class="nav-link">
              <i data-feather="user-plus"></i><span>Join Request</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_ATTENDANCE)}
          <li class="nav-item" class:active={_activeMenu == "attendance"}>
            <a href="/dashboard/attendance" use:link class="nav-link">
              <i data-feather="calendar" /><span>Attendance</span>
            </a>
          </li>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_ATTENDANCE)}
          <li class="nav-item" class:active={_activeMenu == "viewAttendance"}>
            <a href="/dashboard/viewAttendance" use:link class="nav-link">
              <i data-feather="calendar" /><span>View Attendance</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_CLASS_TIMINGS)}
          <li class="nav-item" class:active={_activeMenu == "batchtiming"}>
            <a href="/dashboard/classtiming" use:link class="nav-link">
              <i data-feather="clock" /><span>Class Timing</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_HOLIDAYS)}
          <li class="nav-item" class:active={_activeMenu == "holidays"}>
            <a href="/dashboard/holidays" use:link class="nav-link">
              <i data-feather="umbrella" /><span>Holidays</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_BRANCH)}
          <li class="nav-item" class:active={_activeMenu == "branch"}>
            <a href="/dashboard/branch" use:link class="nav-link">
              <i data-feather="share-2" /><span>Branch</span></a
            >
          </li>
        {/if}

        <!-- {#if MODULES.hasAccess(MODULES.MANAGE_COURSE)}
        <li class="nav-item" class:active={_activeMenu=='course' }>
          <a href="/dashboard/course" use:link class="nav-link">
            <i data-feather="package" /><span>Course</span></a>
        </li>
        {/if} -->

        {#if MODULES.hasAccess(MODULES.MANAGE_CLASSES)}
          <li class="nav-item" class:active={_activeMenu == "class"}>
            <a href="/dashboard/class" use:link class="nav-link">
              <i data-feather="target" /><span>Classes</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_ENQUIRY) || MODULES.hasAccess(MODULES.MANAGE_ASSIGNMENT) || MODULES.hasAccess(MODULES.MANAGE_PAYMENT) || MODULES.hasAccess(MODULES.MANAGE_FEEDBACK) || MODULES.hasAccess(MODULES.MANAGE_DISCUSSION_FORUM)}
          <li class="nav-label">Enquires & Students</li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_ENQUIRY)}
          <li class="nav-item" class:active={_activeMenu == "enquiries"}>
            <a href="/dashboard/enquiries" use:link class="nav-link">
              <i data-feather="help-circle" /><span>Enquiries</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_STUDENT)}
          <li class="nav-item" class:active={_activeMenu == "students"}>
            <a href="/dashboard/students" use:link class="nav-link">
              <i data-feather="user" /><span>Students</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_ASSIGNMENT)}
          <li class="nav-item" class:active={_activeMenu == "assignment"}>
            <a href="/dashboard/assignment" use:link class="nav-link">
              <i data-feather="file-text" /><span>Assignment</span>
            </a>
          </li>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_ASSIGNMENT)}
          <li
            class="nav-item"
            class:active={_activeMenu == "assignmentprogress"}
          >
            <a href="/dashboard/assignmentprogress" use:link class="nav-link">
              <i data-feather="file-text" /><span>Assignment Progress</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_PAYMENT)}
          <li class="nav-item" class:active={_activeMenu == "payment"}>
            <a href="/dashboard/payment" use:link class="nav-link">
              <i data-feather="zap" />
              <span>Payment</span></a
            >
          </li>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_PAYMENT)}
          <li class="nav-item" class:active={_activeMenu == "pdc"}>
            <a href="/dashboard/pdc" use:link class="nav-link">
              <i data-feather="zap" />
              <span>PDC Management</span></a
            >
          </li>
        {/if}
        {#if localUserData && STUDY_MONITORING_ACCESS.indexOf(localUserData.orgCode.toString()) != -1}
          <!-- {#if localUserData && (STUDY_MONITORING_ACCESS.indexOf(localUserData.orgCode.toString()) != -1 ||
      localUserData.orgCode === "1123")} -->
          <li class="nav-item" class:active={_activeMenu == "studyMonitoring"}>
            <a href="/dashboard/studyMonitoring" use:link class="nav-link">
              <i data-feather="globe"></i><span>Study Monitoring</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_FEEDBACK)}
          <li class="nav-item" class:active={_activeMenu == "feedback"}>
            <a href="/dashboard/feedback" use:link class="nav-link">
              <i data-feather="edit-3"></i><span>Feedback</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_DISCUSSION_FORUM)}
          <li class="nav-item" class:active={_activeMenu == "discussionForum"}>
            <a href="/dashboard/discussionForum" use:link class="nav-link">
              <i data-feather="help-circle"></i><span>Discussion Forum</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_QUESTION_BANK) || MODULES.hasAccess(MODULES.MANAGE_TEST) || MODULES.hasAccess(MODULES.MANAGE_STUDY_MATERIAL) || MODULES.hasAccess(MODULES.MANAGE_STUDY_REPORT)}
          <li class="nav-label">Study Material</li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_QUESTION_BANK)}
          <li class="nav-item" class:active={_activeMenu == "questionBank"}>
            <a
              href="/dashboard/questions/questionBank"
              use:link
              class="nav-link"
              ><i data-feather="server" />
              <span>Questions Bank</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.UPLOAD_QUESTIONS_TEMPLATE)}
          <li class="nav-item" class:active={_activeMenu == "questionsUpload"}>
            <a href="/dashboard/questions_upload" use:link class="nav-link">
              <i data-feather="upload" /><span>Questions Upload</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_QUESTION_BANK)}
          <li
            class="nav-item"
            class:active={_activeMenu == "questionsreported"}
          >
            <a href="/dashboard/questionsreported" use:link class="nav-link">
              <i data-feather="clipboard" /><span>Questions Reported</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_QUESTION_BANK)}
          <li
            class="nav-item"
            class:active={_activeMenu == "questionsreportedtype"}
          >
            <a
              href="/dashboard/questionsreportedtype"
              use:link
              class="nav-link"
            >
              <i data-feather="help-circle" /><span>Questions Report Type</span
              ></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_TEST)}
          <li class="nav-item" class:active={_activeMenu == "tests"}>
            <a href="/dashboard/tests" use:link class="nav-link">
              <i data-feather="clipboard" />
              <span>Tests</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_STUDY_MATERIAL)}
          <li class="nav-item" class:active={_activeMenu == "studyMaterial"}>
            <a href="/dashboard/studyMaterial" use:link class="nav-link">
              <i data-feather="book-open" /><span>Study Material</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_STUDY_REPORT)}
          <li class="nav-item" class:active={_activeMenu == "studyreport"}>
            <a href="/dashboard/reports/study" use:link class="nav-link">
              <i data-feather="trello"></i><span>Study Reports</span>
            </a>
          </li>
        {/if}

        <li class="nav-label">Apti Booster</li>

        <li class="nav-item" class:active={_activeMenu == "aptiBooster"}>
          <a href="/dashboard/aptiBooster" use:link class="nav-link">
            <i data-feather="target" /><span>School Board</span></a
          >
        </li>

        <li class="nav-item" class:active={_activeMenu == "aptiStudent"}>
          <a href="/dashboard/aptiStudent/student" use:link class="nav-link">
            <i data-feather="user" /><span>Apti Student</span>
          </a>
        </li>

        {#if MODULES.hasAccess(MODULES.MANAGE_SYSTEM_USERS) || MODULES.hasAccess(MODULES.MANAGE_TASKS) || MODULES.hasAccess(MODULES.MANAGE_LEAVES) || MODULES.hasAccess(MODULES.MANAGE_TELE_CALLER_LOGS) || MODULES.hasAccess(MODULES.MANAGE_DAILY_STAFF_REPORTING)}
          <li class="nav-label">Faculty management</li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_SYSTEM_USERS)}
          <li class="nav-item" class:active={_activeMenu == "systemuser"}>
            <a href="/dashboard/systemuser" use:link class="nav-link">
              <i data-feather="users" /><span>System User</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.SCHEDULE)}
          <li class="nav-item" class:active={_activeMenu == "schedule"}>
            <a href="/dashboard/schedule" use:link class="nav-link">
              <i data-feather="users" /><span>Schedule</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_TASKS)}
          <li class="nav-item" class:active={_activeMenu == "taskmanagement"}>
            <a href="/dashboard/tasks" use:link class="nav-link">
              <i data-feather="layers" /><span>Task Management</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_LEAVES)}
          <li class="nav-item" class:active={_activeMenu == "leaveRequest"}>
            <a href="/dashboard/leave-request" use:link class="nav-link">
              <i data-feather="briefcase" /><span>Leave Request</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_TELE_CALLER_LOGS)}
          <li class="nav-item" class:active={_activeMenu == "telelogs"}>
            <a href="/dashboard/telelogs" use:link class="nav-link"
              ><i data-feather="phone-call" />
              <span>Tele Callers Logs</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_DAILY_STAFF_REPORTING)}
          <li class="nav-item" class:active={_activeMenu == "daily-reports"}>
            <a href="/dashboard/reportings" use:link class="nav-link">
              <i data-feather="trello" />
              <span>Daily Staff Reporting</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_MY_PROFILE) || MODULES.hasAccess(MODULES.MANAGE_GENERAL_SETTINGS) || MODULES.hasAccess(MODULES.MANAGE_SOCIAL_SETTINGS) || MODULES.hasAccess(MODULES.MANAGE_POINTS_SETTINGS) || MODULES.hasAccess(MODULES.MANAGE_APP_SLIDER) || MODULES.hasAccess(MODULES.MANAGE_ANNOUNCEMENTS) || MODULES.hasAccess(MODULES.MANAGE_DOUBT_SESSION) || MODULES.hasAccess(MODULES.MANAGE_FAQ)}
          <li class="nav-label">Organization</li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_MY_PROFILE)}
          <li class="nav-item" class:active={_activeMenu == "myprofile"}>
            <a href="/dashboard/myprofile" use:link class="nav-link">
              <i data-feather="user" />
              <span>My Profile</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_GENERAL_SETTINGS)}
          <li class="nav-item" class:active={_activeMenu == "settingsGeneral"}>
            <a href="/dashboard/settings/general" use:link class="nav-link">
              <i data-feather="settings" /><span>General</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_SOCIAL_SETTINGS)}
          <li class="nav-item" class:active={_activeMenu == "settingsSocial"}>
            <a href="/dashboard/settings/social" use:link class="nav-link">
              <i data-feather="globe" /><span>Social</span>
            </a>
          </li>
        {/if}
        {#if isPoints && MODULES.hasAccess(MODULES.MANAGE_GENERAL_SETTINGS)}
          <li class="nav-item" class:active={_activeMenu == "settingsPoints"}>
            <a href="/dashboard/settings/points" use:link class="nav-link">
              <i data-feather="award" /><span>Points</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_APP_SLIDER)}
          <li class="nav-item" class:active={_activeMenu == "appslider"}>
            <a href="/dashboard/appslider" use:link class="nav-link">
              <i data-feather="sliders" /><span>App Slider</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_ANNOUNCEMENTS)}
          <li class="nav-item" class:active={_activeMenu == "announcements"}>
            <a href="/dashboard/announcements" use:link class="nav-link">
              <i data-feather="volume-2" />
              <span>Announcements</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_DOUBT_SESSION)}
          <li class="nav-item" class:active={_activeMenu == "doubtSession"}>
            <a href="/dashboard/doubtSession" use:link class="nav-link">
              <i data-feather="help-circle" />
              <span>Doubt Session</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_FAQ)}
          <li class="nav-item" class:active={_activeMenu == "faq"}>
            <a href="/dashboard/faq" use:link class="nav-link">
              <i data-feather="alert-circle" />
              <span>FAQ!</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_SMS_TEMPLATES) || MODULES.hasAccess(MODULES.MANAGE_ANNOUNCEMENT_TEMPLATE) || MODULES.hasAccess(MODULES.MANAGE_TELE_CALLER_SCRIPTS) || MODULES.hasAccess(MODULES.MANAGE_TEST_INST) || MODULES.hasAccess(MODULES.MANAGE_ANALYTICAL_CATEGORY) || MODULES.hasAccess(MODULES.MANAGE_ENQUIRY_RESPONSES) || MODULES.hasAccess(MODULES.MANAGE_ENQUIRY_SOURCE) || MODULES.hasAccess(MODULES.MANAGE_STUDY_MONITORING_SOURCE)}
          <li class="nav-label">General template</li>
        {/if}

        <!-- {#if MODULES.hasAccess(MODULES.MANAGE_SMS_TEMPLATES)}
        <li class="nav-item" class:active={_activeMenu=='smstemplate' }>
          <a href="/dashboard/smstemplate" use:link class="nav-link"><i data-feather="message-circle" />
            <span>SMS Template</span></a>
        </li>
        {/if} -->

        {#if MODULES.hasAccess(MODULES.MANAGE_ANNOUNCEMENT_TEMPLATE)}
          <li
            class="nav-item"
            class:active={_activeMenu == "announcementtemplate"}
          >
            <a href="/dashboard/announcementtemplate" use:link class="nav-link"
              ><i data-feather="message-circle" />
              <span>Announcement Template</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_TELE_CALLER_SCRIPTS)}
          <li class="nav-item" class:active={_activeMenu == "telescript"}>
            <a href="/dashboard/telescript" use:link class="nav-link"
              ><i data-feather="phone-call" />
              <span>Tele Calling Script</span></a
            >
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_TEST_INST)}
          <li class="nav-item" class:active={_activeMenu == "testinstructions"}>
            <a href="/dashboard/tests/instructions" use:link class="nav-link"
              ><i data-feather="clipboard" />
              <span>Test Instructions</span></a
            >
          </li>
        {/if}

        <!-- {#if MODULES.hasAccess(MODULES.MANAGE_ANALYTICAL_CATEGORY)}
        <li class="nav-item" class:active={_activeMenu=='analytical-category' }>
          <a href="/dashboard/analyticalCategory" use:link class="nav-link"><i data-feather="server" />
            <span>Analytical Category</span></a>
        </li>
        {/if} -->

        {#if MODULES.hasAccess(MODULES.MANAGE_ENQUIRY_RESPONSES)}
          <li class="nav-item" class:active={_activeMenu == "response"}>
            <a href="/dashboard/enquiries/response" use:link class="nav-link">
              <i data-feather="refresh-ccw" /><span>Enquiry response</span>
            </a>
          </li>
        {/if}

        {#if MODULES.hasAccess(MODULES.MANAGE_ENQUIRY_SOURCE)}
          <li class="nav-item" class:active={_activeMenu == "enquiry-source"}>
            <a href="/dashboard/enquiries/source" use:link class="nav-link">
              <i data-feather="anchor" /><span>Enquiry Sources</span>
            </a>
          </li>
        {/if}

        <!-- {#if MODULES.hasAccess(MODULES.MANAGE_STUDY_MONITORING_SOURCE)} -->
        {#if localUserData && STUDY_MONITORING_ACCESS.indexOf(localUserData.orgCode.toString()) != -1}
          <li
            class="nav-item"
            class:active={_activeMenu == "studymonitoringsources"}
          >
            <a
              href="/dashboard/studymonitoringsources"
              use:link
              class="nav-link"
            >
              <i data-feather="zap" />
              <span>Study Monitoring Sources</span></a
            >
          </li>
        {/if}
        <!-- {/if} -->
        <!-- 
          <li class="nav-item" style="display: none;" class:active={_activeMenu=='accessProfileFeatures' }>
            <a href="/dashboard/accessProfileFeatures" use:link class="nav-link">
              <i data-feather="settings" /><span>Access Profile Features</span>
            </a>
          </li> -->

        <!-- {#if localUserData.access.integration}
                          <li
                            class="nav-item"
                            class:active={_activeMenu == 'settingsIntegration'}>
                            <a href="/dashboard/settings/integration"  use:link class="nav-link">
                              <i data-feather="map" /><span>Integration</span>
                            </a>
                          </li>
                        {/if} -->
        <!-- {#if localUserData.access.accessProfile}
                          <li class="nav-item" class:active={_activeMenu == 'accessProfile'}>
                            <a href="/dashboard/accessProfile" use:link class="nav-link">
                              <i data-feather="shield" /><span>Access Profile</span>
                            </a>
                          </li>
                        {/if} -->

        <!-- {#if localUserData.access.enquiryreports}
                              <li class="nav-label">Report</li>
                              {/if}
                        
                              {#if localUserData.access.enquiryreports}
                              <li class="nav-item" class:active={_activeMenu == 'reports-enquiry'}>
                                <a href="/dashboard/reports/enquiries" use:link class="nav-link">
                                  <i data-feather="trello" />
                                  <span>Reports</span>
                                </a>
                              </li>
                            {/if} -->

        <!-- <li class="nav-item" class:active={_activeMenu == 'questions'}>
                <a href="/dashboard/questions" use:link class="nav-link"><i
                    data-feather="server" />
                  <span>Questions</span></a>
                        </li> -->
      {/if}
    </ul>
  </div>
</aside>

<style>
</style>
