<script>
    import { Router, Link, links, Route } from "svelte-routing";
    import { onMount, beforeUpdate, afterUpdate, tick } from 'svelte';

    import STPModal from "../../singleParamTextEditor/modal.svelte";


    export let langObj = 'en';

    export let questionData = {}

    let showModal = false;

    let essayOpenedOnce = false;

    beforeUpdate(() => {
        console.log('the component is about to update', questionData.data.isEssayAttached);
    });

    afterUpdate(() => {
        console.log('the component just updated', questionData.data.isEssayAttached);
    });

    // $: questionData.data.isEssayAttached && questionData.data.isEssayAttached[0] && onSelectEssayClick("fromLabel");

    // $: questionData.data.isEssayAttached && !questionData.data.isEssayAttached[0] && clearEssay();


    let cbChange = async function (e) {
        if (e.target.checked) {
            onSelectEssayClick({ forceShow: true });
        } else {
            clearEssay();
        }

    }

    let clearEssay = function () {
        // essayOpenedOnce = false;
        // console.log("questionData.data : ", questionData.data);
        // questionData.data.attachEssay[langObj] = {}
    }
    let onCloseModal = function (event) {
        _$('.modal-backdrop').remove()
        showModal = false;
        if (event.detail?.sptObject) {
            questionData.data.attachEssay[langObj] = event.detail?.sptObject
        }
    }

    let onSelectEssayClick = function (config) {
        console.log("questionData.data : ", questionData.data)

        if (config.forceShow) {
            essayOpenedOnce = true;
            showModal = true;
        } else {
            if (questionData.data.isEssayAttached && questionData.data.isEssayAttached[0]) {
                essayOpenedOnce = true;
                showModal = true;
            }
        }

    }

    onMount(async function () {
        _$('.essay-select').selectize({ maxItems: 1 });
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);

        if (!questionData.data) questionData.data = {};

        CKEDITOR.inline('question_' + langObj, { height: 200 });
        CKEDITOR.inline('option_' + langObj + "_a", { height: 200 });
        CKEDITOR.inline('option_' + langObj + "_b", { height: 200 });
        CKEDITOR.inline('option_' + langObj + "_c", { height: 200 });
        CKEDITOR.inline('option_' + langObj + "_d", { height: 200 });
        CKEDITOR.inline('option_' + langObj + "_e", { height: 200 });
        CKEDITOR.inline('option_' + langObj + "_f", { height: 200 });
        CKEDITOR.inline('option_' + langObj + "_g", { height: 200 });
        CKEDITOR.inline('option_' + langObj + "_h", { height: 200 });
        CKEDITOR.inline('explanation_' + langObj, { height: 200 });

        await tick();

        if(questionData.data?.question && questionData.data?.question[langObj]){
            CKEDITOR.instances['question_' + langObj].setData(questionData.data.question[langObj]);
        }
        if(questionData.data?.option_a && questionData.data?.option_a[langObj]){
            CKEDITOR.instances['option_' + langObj + "_a"].setData(questionData.data.option_a[langObj]);
        }
        if(questionData.data?.option_b && questionData.data?.option_b[langObj]){
            CKEDITOR.instances['option_' + langObj + "_b"].setData(questionData.data.option_b[langObj]);
        }
        if(questionData.data?.option_c && questionData.data?.option_c[langObj]){
            CKEDITOR.instances['option_' + langObj + "_c"].setData(questionData.data.option_c[langObj]);
        }
        if(questionData.data?.option_d && questionData.data?.option_d[langObj]){
            CKEDITOR.instances['option_' + langObj + "_d"].setData(questionData.data.option_d[langObj]);
        }
        if(questionData.data?.option_e && questionData.data?.option_e[langObj]){
            CKEDITOR.instances['option_' + langObj + "_e"].setData(questionData.data.option_e[langObj]);
        }
        if(questionData.data?.option_f && questionData.data?.option_f[langObj]){
            CKEDITOR.instances['option_' + langObj + "_f"].setData(questionData.data.option_f[langObj]);
        }
        if(questionData.data?.option_g && questionData.data?.option_g[langObj]){
            CKEDITOR.instances['option_' + langObj + "_g"].setData(questionData.data.option_g[langObj]);
        }
        if(questionData.data?.option_h && questionData.data?.option_h[langObj]){
            CKEDITOR.instances['option_' + langObj + "_h"].setData(questionData.data.option_h[langObj]);
        }
        if(questionData.data?.explanation && questionData.data?.explanation[langObj]){
            CKEDITOR.instances['explanation_' + langObj].setData(questionData.data.explanation[langObj]);
        }

        let correctHtml = function(str){
            var mySubString = str.substring(
                str.indexOf("<img") + 1, 
                str.lastIndexOf("/>")
            );
            var count = (mySubString.match(/style/g) || []).length;
            if(count)  return str
            else  return str.replace(/<img/g, " <img style='width:100% !important;height: unset !important;' ");
            
        }


        CKEDITOR.instances['question_' + langObj].on('change', function (e) {
            if (!questionData.data.question) {
                questionData.data.question = {};
            }
            questionData.data.question[langObj] = correctHtml(CKEDITOR.instances['question_' + langObj].getData());
        });

        CKEDITOR.instances['option_' + langObj + "_a"].on('change', function (e) {
            if (!questionData.data.option_a) {
                questionData.data.option_a = {};
            }
            questionData.data.option_a[langObj] = correctHtml(CKEDITOR.instances['option_' + langObj + "_a"].getData());
        });
        CKEDITOR.instances['option_' + langObj + "_b"].on('change', function (e) {
            if (!questionData.data.option_b) {
                questionData.data.option_b = {};
            }
            questionData.data.option_b[langObj] = correctHtml(CKEDITOR.instances['option_' + langObj + "_b"].getData());
        });
        CKEDITOR.instances['option_' + langObj + "_c"].on('change', function (e) {
            if (!questionData.data.option_c) {
                questionData.data.option_c = {};
            }
            questionData.data.option_c[langObj] = correctHtml(CKEDITOR.instances['option_' + langObj + "_c"].getData());
        });
        CKEDITOR.instances['option_' + langObj + "_d"].on('change', function (e) {
            if (!questionData.data.option_d) {
                questionData.data.option_d = {};
            }
            questionData.data.option_d[langObj] = correctHtml(CKEDITOR.instances['option_' + langObj + "_d"].getData());
        });
        CKEDITOR.instances['option_' + langObj + "_e"].on('change', function (e) {
            if (!questionData.data.option_e) {
                questionData.data.option_e = {};
            }
            questionData.data.option_e[langObj] = correctHtml(CKEDITOR.instances['option_' + langObj + "_e"].getData());
        });
        CKEDITOR.instances['option_' + langObj + "_f"].on('change', function (e) {
            if (!questionData.data.option_f) {
                questionData.data.option_f = {};
            }
            questionData.data.option_f[langObj] = correctHtml(CKEDITOR.instances['option_' + langObj + "_f"].getData());
        });
        CKEDITOR.instances['option_' + langObj + "_g"].on('change', function (e) {
            if (!questionData.data.option_g) {
                questionData.data.option_g = {};
            }
            questionData.data.option_g[langObj] = correctHtml(CKEDITOR.instances['option_' + langObj + "_g"].getData());
        });
        CKEDITOR.instances['option_' + langObj + "_h"].on('change', function (e) {
            if (!questionData.data.option_h) {
                questionData.data.option_h = {};
            }
            questionData.data.option_h[langObj] = correctHtml(CKEDITOR.instances['option_' + langObj + "_h"].getData());
        });

        CKEDITOR.instances['explanation_' + langObj].on('change', function (e) {
            if (!questionData.data.explanation) {
                questionData.data.explanation = {};
            }
            questionData.data.explanation[langObj] = correctHtml(CKEDITOR.instances['explanation_' + langObj].getData());
        });
        await tick();
        feather.replace();


    })

</script>

<style>
    .multiChoiceHeader.selectedOption {
        border-color: var(--primary);
    }

    .selectedOption .optionsType {
        background: var(--primary);
    }

    .selectedOption .cke_editable_inline {
        border-color: var(--primary);
    }

    .multiChoiceHeader {
        border: 1px solid var(--gray-dark);
        text-align: center;
        width: 50px;
        flex: 1;
        max-width: 50px;
        display: flex;
        flex-direction: column;
    }

    .optiontextArea {
        flex: 1;
        display: flex;
    }

    .optiontextArea textarea {
        height: 60px;
        flex: 1;
    }

    .optionsType {
        background: var(--gray-dark);
        color: #fff;
        margin-bottom: 0;
    }

    .optionInputHolder {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .optionInputHolder input {
        width: 22px;
        height: 22px;
    }

    .essay-div {
        background-color: #f5f6fa;
        cursor: pointer;
    }

    .essay-div.enable {
        background-color: white;
        ;
    }
</style>

<div class="row">
    <div class="col-sm-12 font-weight-bold">
        Question<span class="required-field">*</span> :
            <span style="color: blue;">(add a space after end of each line)</span>
      
    </div>
    <div class="col-sm-12">
        <textarea class="editortextarea" name="question_{langObj}"></textarea>
    </div>
    <div class="col-sm-12 font-weight-bold mt-3">
        Options<span class="required-field">*</span> :
    </div>

    <div class="col-sm-12">
        <div class="input-group mb-3">
            <div class="multiChoiceHeader" class:selectedOption={questionData.data.answer=="a" }>
                <h5 class="optionsType">A</h5>
                <div class="optionInputHolder">
                    <input class="multi-radio multipleChoiceOption" type="radio" name="option_{langObj}"
                        bind:group={questionData.data.answer} value="a">
                </div>
            </div>
            <div class="optiontextArea">
                <textarea class="editortextarea" name="option_{langObj}_a"></textarea>
            </div>
        </div>
    </div>

    <div class="col-sm-12">
        <div class="input-group mb-3">
            <div class="multiChoiceHeader" class:selectedOption={questionData.data.answer=="b" }>
                <h5 class="optionsType">B</h5>
                <div class="optionInputHolder">
                    <input class="multi-radio multipleChoiceOption" type="radio" name="option_{langObj}"
                        bind:group={questionData.data.answer} value="b">
                </div>
            </div>
            <div class="optiontextArea">
                <textarea class="editortextarea" name="option_{langObj}_b"></textarea>
            </div>
        </div>
    </div>

    <div class="col-sm-12">
        <div class="input-group mb-3">
            <div class="multiChoiceHeader" class:selectedOption={questionData.data.answer=="c" }>
                <h5 class="optionsType">C</h5>
                <div class="optionInputHolder">
                    <input class="multi-radio multipleChoiceOption" type="radio" name="option_{langObj}"
                        bind:group={questionData.data.answer} value="c">
                </div>
            </div>
            <div class="optiontextArea">
                <textarea class="editortextarea" name="option_{langObj}_c"></textarea>
            </div>
        </div>
    </div>

    <div class="col-sm-12">
        <div class="input-group mb-3">
            <div class="multiChoiceHeader" class:selectedOption={questionData.data.answer=="d" }>
                <h5 class="optionsType">D</h5>
                <div class="optionInputHolder">
                    <input class="multi-radio multipleChoiceOption" type="radio" name="option_{langObj}"
                        bind:group={questionData.data.answer} value="d">
                </div>
            </div>
            <div class="optiontextArea">
                <textarea class="editortextarea" name="option_{langObj}_d"></textarea>
            </div>
        </div>
    </div>
    <div class="col-sm-12  op-row" class:showOp={questionData.data.optionCount> 4}>
        <div class="input-group mb-3">
            <div class="multiChoiceHeader" class:selectedOption={questionData.data.answer=="e" }>
                <h5 class="optionsType">E</h5>
                <div class="optionInputHolder">
                    <input class="multi-radio multipleChoiceOption" type="radio" name="option_{langObj}"
                        bind:group={questionData.data.answer} value="e">
                </div>
            </div>
            <div class="optiontextArea">
                <textarea class="editortextarea" name="option_{langObj}_e"></textarea>
            </div>
        </div>
    </div>

    <div class="col-sm-12 op-row" class:showOp={questionData.data.optionCount> 5}>
        <div class="input-group mb-3">
            <div class="multiChoiceHeader" class:selectedOption={questionData.data.answer=="f" }>
                <h5 class="optionsType">F</h5>
                <div class="optionInputHolder">
                    <input class="multi-radio multipleChoiceOption" type="radio" name="option_{langObj}"
                        bind:group={questionData.data.answer} value="f">
                </div>
            </div>
            <div class="optiontextArea">
                <textarea class="editortextarea" name="option_{langObj}_f"></textarea>
            </div>
        </div>
    </div>

    <div class="col-sm-12 op-row" class:showOp={questionData.data.optionCount> 6}>
        <div class="input-group mb-3">
            <div class="multiChoiceHeader" class:selectedOption={questionData.data.answer=="g" }>
                <h5 class="optionsType">G</h5>
                <div class="optionInputHolder">
                    <input class="multi-radio multipleChoiceOption" type="radio" name="option_{langObj}"
                        bind:group={questionData.data.answer} value="g">
                </div>
            </div>
            <div class="optiontextArea">
                <textarea class="editortextarea" name="option_{langObj}_g"></textarea>
            </div>
        </div>
    </div>

    <div class="col-sm-12 op-row" class:showOp={questionData.data.optionCount> 7}>
        <div class="input-group mb-3">
            <div class="multiChoiceHeader" class:selectedOption={questionData.data.answer=="h" }>
                <h5 class="optionsType">H</h5>
                <div class="optionInputHolder">
                    <input class="multi-radio multipleChoiceOption" type="radio" name="option_{langObj}"
                        bind:group={questionData.data.answer} value="h">
                </div>
            </div>
            <div class="optiontextArea">
                <textarea class="editortextarea" name="option_{langObj}_h"></textarea>
            </div>
        </div>
    </div>

    <div class="col-sm-12" style="flex-direction: row;display: flex;justify-content: flex-end;">
        {#if questionData.data.optionCount == 8}
            <button style="background: transparent; border: none;outline: none !important;" disabled
                on:click={ async ()=>{questionData.data.optionCount++;  await tick(); feather.replace()}}>
                <i data-feather="plus" style="height: 15px;"></i> Add More Option
            </button>
        {:else}
            <button style="background: transparent; border: none;outline: none !important;" 
                on:click={ async ()=>{questionData.data.optionCount++;  await tick(); feather.replace()}}>
                <i data-feather="plus" style="height: 15px;"></i> Add More Option
            </button>
        {/if}
        {#if questionData.data.optionCount == 4}
            <button style="background: transparent; border: none;outline: none !important;" disabled
                on:click={ async ()=>{questionData.data.optionCount--; await tick(); feather.replace()}} >
                <i data-feather="minus" style="height: 15px;"></i> Remove Option
            </button>
        {:else}
            <button style="background: transparent; border: none;outline: none !important;" 
                on:click={ async ()=>{questionData.data.optionCount--; await tick(); feather.replace()}} >
                <i data-feather="minus" style="height: 15px;"></i> Remove Option
            </button>
        {/if}
    </div>


    <div class="col-sm-12 font-weight-bold mt-3">
        Explanation :
        <span style="color: blue;">
            (add a space after end of each line) <br>
        </span>
    </div>
    <div class="col-sm-12">
        <textarea class="editortextarea" name="explanation_{langObj}"></textarea>
    </div>

    <div class="col-sm-12 font-weight-bold mt-3">
        Essay :
    </div>
    <div class="col-sm-12 " style="flex-direction: row;display: flex;justify-content: flex-start;">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text" style="padding-left: 20px;">
                    <div class="custom-control custom-checkbox pd-l-15">
                        <input type="checkbox" 
                            class="custom-control-input" 
                            id="customCheck00" 
                            on:change={cbChange}
                            bind:group={questionData.data.isEssayAttached} 
                            value=true>
                        <label class="custom-control-label" for="customCheck00">Attach Essay</label>
                    </div>
                </div>
            </div>
            <div class="form-control essay-div" on:click={onSelectEssayClick} 
                class:enable={questionData.data.isEssayAttached && questionData.data.isEssayAttached[0]}>
                {#if questionData.data.isEssayAttached && questionData.data.isEssayAttached[0]}
                    {questionData.data.attachEssay[langObj]?._id ? questionData.data.attachEssay[langObj]._id : ""}
                {/if}
            </div>
            {#if questionData.data.isEssayAttached && questionData.data.isEssayAttached[0]}
                <div class="input-group-append">
                    <button class="btn btn-outline-light" on:click={onSelectEssayClick} type="button" id="button-addon2">
                        {questionData.data.attachEssay[langObj]?._id ? "Change" : "Select"}
                    </button>
                </div>

            {/if}
        </div>
    </div>
</div>

{#if showModal}
<STPModal on:close={onCloseModal} />
{/if}
