<script>
  import { Router, links, link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../../stores";
  import ThemePicker from "../themePicker/themePicker.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import { pSuccess, pError, pConfirm } from "../../utils/notify.svelte";
  // import ThemeCards from "../themePicker/themeCards.svelte";

  export let reload;
  const settings = {};
  let isLoading, isUpdating;
  let scroll;
  function updateUIAfterFetchPointsSettings () {
    feather.replace({ width: "1em", height: "1em" });
    if (isUpdating) {
      if(isUpdating == 'internal-test') {
        _$(".multi-collapse#save-" + isUpdating).addClass('show')
        _$(".multi-collapse#" + isUpdating).addClass('show')
      } else {
        _$(".collapse#" + isUpdating).addClass('show')
      }
    }
  }
  async function fetchPointsSettings() {
    if (isLoading) return;
    isLoading = true;
    await tick();
    let result = await api_handleApiCalls("settings.fetchPointsSettings", {});
    console.log("fetchPointsSettings", result);
    if (result.status) {
      settings.ALLOW_LOYALTY_POINTS = result.data.ALLOW_LOYALTY_POINTS || false;
      settings.pointsConfig = result.data.pointsConfig;
      /* {
        "Internal_Test_Rank_1": 100,
        "Internal_Test_Rank_2": 50,
        "Internal_Test_Rank_3": 40,
        "Internal_Test_Rank_4_to_10": 20,
        "Internal_Test_Rank_11_to_20": 10,
        "Internal_Test_Rank_21_and_on": 5,
        "Self_Competition": 10,
        "Competitive_Exams": 100,
        "Enrolling": 100,
        "Completing_An_Year": 100,
        "Share": 100
    } */
      await tick();
    } else {
      pError("", result.errMsg);
    }
    isLoading = false;
    await tick();
    updateUIAfterFetchPointsSettings()
  }
  async function setAllowLoyaltyPoints(value) {
    let result = await api_handleApiCalls("settings.setAllowLoyaltyPoints", {
      value,
    });
    console.log("setAllowLoyaltyPoints", result);
    if (result.status) {
      pSuccess("", result.data);
      fetchPointsSettings();
      if (reload) reload();
    } else {
      pError("", result.errMsg);
    }
  }
  async function setLoyaltyPoints(btnId) {
    isUpdating = btnId
    let result = await api_handleApiCalls("settings.setLoyaltyPoints", {
      pointsConfig: settings.pointsConfig,
    });
    console.log("setAllowLoyaltyPoints", result);
    if (result.status) {
      pSuccess("", result.data);
      fetchPointsSettings();
      if (reload) reload();
    } else {
      pError("", result.errMsg);
    }
  }
  function goToNextInput (event) {
    // event.key == 'Enter' && _$(this).next('input').focus()
  }
  onMount(function () {
    // console.log("points");
    activeMenu.set("settingsPoints");
    fetchPointsSettings();
  });
</script>

<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xs-12">
    <ul class="list-group">
      {#if isLoading}
      <li class="list-group-item list-group-item-action">
        <div class="mg-20 pd-10">
          <div class="placeholder-paragraph">
            <div class="line mg-b-10" />
            <div class="line mg-b-10" />
          </div>
        </div>
      </li>
      {:else}
      <li class="list-group-item list-group-item-action">
        <div class="d-flex justify-content-between">
          <div>Allow Points</div>
          <div>
            <div class="custom-control custom-switch">
              <input type="checkbox" checked={settings.ALLOW_LOYALTY_POINTS} on:change={(event)=>
              setAllowLoyaltyPoints(event.target.checked)}
              class="custom-control-input"
              id="allow-loyalty-points"
              />
              <label class="custom-control-label" for="allow-loyalty-points">
                {@html "&nbsp"}
              </label>
            </div>
          </div>
        </div>
      </li>
      {#if settings.ALLOW_LOYALTY_POINTS}
      <li class="list-group-item fadeIn">
        <div class="d-flex justify-content-between text-capitalize">
          <div class="tx-gray-700">Internal Test</div>
          <nav>
            <button id="save-internal-test" disabled={isLoading}
              class="btn btn-outline-light multi-collapse internal-test collapse fadeIn" type="button"
              on:click={(event)=> setLoyaltyPoints("internal-test")}
              >
              Save
            </button>
            <a href=".multi-collapse.internal-test" data-target=".multi-collapse.internal-test" data-toggle="collapse"
              role="button" aria-expanded="true" aria-controls="internal-test save-internal-test"
              class="d-inline mg-l-5 nav-link">
              <i data-feather="edit" /></a>
          </nav>
        </div>
        <ul class="list-group multi-collapse internal-test collapse mg-t-10" id="internal-test">
          <li class="list-group-item list-group-item-action">
            <div class="d-flex justify-content-between text-capitalize">
              <div class="wd-100p align-self-center">Rank 1</div>
              <div class="input-group">
                <input type="number" bind:value={settings.pointsConfig.Internal_Test_Rank_1} class="form-control"
                  placeholder="Internal Test Rank 1st Points" aria-label="Internal Test Rank 1st Points"
                  on:keyup={goToNextInput}  />
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-action">
            <div class="d-flex justify-content-between text-capitalize">
              <div class="wd-100p align-self-center">Rank 2</div>
              <div class="input-group">
                <input type="number" bind:value={settings.pointsConfig.Internal_Test_Rank_2} class="form-control"
                  placeholder="Rank 2nd Points" aria-label="Rank 2nd Points" 
                  on:keyup={goToNextInput} />
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-action">
            <div class="d-flex justify-content-between text-capitalize">
              <div class="wd-100p align-self-center">Rank 3</div>
              <div class="input-group">
                <input type="number" bind:value={settings.pointsConfig.Internal_Test_Rank_3} class="form-control"
                  placeholder="Rank 3rd Points" aria-label="Rank 3rd Points" 
                  on:keyup={goToNextInput} />
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-action">
            <div class="d-flex justify-content-between text-capitalize">
              <div class="wd-100p align-self-center">Rank 4 to 10</div>
              <div class="input-group">
                <input type="number" bind:value={settings.pointsConfig .Internal_Test_Rank_4_to_10} class="form-control"
                  placeholder="Rank 4th to 10th Points" aria-label="Rank 4th to 10th Points"
                  on:keyup={goToNextInput}  />
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-action">
            <div class="d-flex justify-content-between text-capitalize">
              <div class="wd-100p align-self-center">Rank 11 to 20</div>
              <div class="input-group">
                <input type="number" bind:value={settings.pointsConfig .Internal_Test_Rank_11_to_20}
                  class="form-control" placeholder="Rank 11th to 20th Points" aria-label="Rank 11th to 20th Points"
                  on:keyup={goToNextInput} />
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-action">
            <div class="d-flex justify-content-between text-capitalize">
              <div class="wd-100p align-self-center">Rank 21 and on</div>
              <div class="input-group">
                <input type="number" bind:value={settings.pointsConfig .Internal_Test_Rank_21_and_on}
                  class="form-control" placeholder="Rank 20th and on Points" aria-label="Rank 20th and on Points"
                  on:keyup={(event) => event.key == 'Enter' && setLoyaltyPoints('share')}  />
              </div>
            </div>
          </li>
        </ul>
      </li>
      <li class="list-group-item fadeIn">
        <div class="d-flex justify-content-between text-capitalize">
          <div class="tx-gray-700">Self Competition</div>
          <nav>
            <a href="#self-competition" 
              data-toggle="collapse" role="button" aria-expanded="true"
              aria-controls="self-competition" class="d-inline mg-l-5 nav-link">
              <i data-feather="edit" /></a>
          </nav>
        </div>
        <div class="collapse mg-t-10" id="self-competition">
          <div class="input-group">
            <input type="number" disabled={true} bind:value={settings.pointsConfig.Self_Competition}
              class="form-control" placeholder="Self Competition" aria-label="Self Competition"
              on:keyup={(event) => event.key == 'Enter' && setLoyaltyPoints('share')}  />
            <button
              class="btn btn-outline-light" disabled={true || isLoading}
              type="button" on:click={(event)=> setLoyaltyPoints('self-competition')}
              >
              Save
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item fadeIn">
        <div class="d-flex justify-content-between text-capitalize">
          <div class="tx-gray-700">Competitive Exams</div>
          <nav>
            <a href="#competitive-exams" 
              data-toggle="collapse" role="button" aria-expanded="true"
              aria-controls="competitive-exams" class="d-inline mg-l-5 nav-link">
              <i data-feather="edit" /></a>
          </nav>
        </div>
        <div class="collapse mg-t-10" id="competitive-exams">
          <div class="input-group">
            <input type="number" bind:value={settings.pointsConfig.Competitive_Exams}
              class="form-control" placeholder="Competitive Exams" aria-label="Competitive Exams"
              on:keyup={(event) => event.key == 'Enter' && setLoyaltyPoints('competitive-exams')}  />
            <button
              class="btn btn-outline-light" disabled={isLoading} type="button" on:click={(event)=> setLoyaltyPoints('competitive-exams')}
              >
              Save
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item fadeIn">
        <div class="d-flex justify-content-between text-capitalize">
          <div class="tx-gray-700">Enrolling</div>
          <nav>
            <a href="#enrolling" data-toggle="collapse"
              role="button" aria-expanded="true" aria-controls="enrolling save-enrolling"
              class="d-inline mg-l-5 nav-link">
              <i data-feather="edit" /></a>
          </nav>
        </div>
        <div class="collapse mg-t-10" id="enrolling">
          <div class="input-group">
            <input type="number" bind:value={settings.pointsConfig.Enrolling} class="form-control"
              placeholder="Enrolling" aria-label="Enrolling" 
              on:keyup={(event) => event.key == 'Enter' && setLoyaltyPoints('enrolling')} />
            <button class="btn btn-outline-light"
              type="button" disabled={isLoading} on:click={(event)=> setLoyaltyPoints('enrolling')}
              >
              Save
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item fadeIn">
        <div class="d-flex justify-content-between text-capitalize">
          <div class="tx-gray-700">Completing An Year</div>
          <nav>
            <a href="#completing-an-year"
              data-toggle="collapse" role="button" aria-expanded="true"
              aria-controls="completing-an-year" class="d-inline mg-l-5 nav-link">
              <i data-feather="edit" /></a>
          </nav>
        </div>
        <div class="collapse mg-t-10" id="completing-an-year">
          <div class="input-group">
            <input type="number" bind:value={settings.pointsConfig.Completing_An_Year}
              class="form-control" placeholder="Completing An Year" aria-label="Completing An Year" 
              on:keyup={(event) => event.key == 'Enter' && setLoyaltyPoints('completing-an-year')} />
            <button
              class="btn btn-outline-light" type="button"
              disabled={isLoading} on:click={(event)=> setLoyaltyPoints('completing-an-year')}
              >
              Save
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item fadeIn">
        <div class="d-flex justify-content-between text-capitalize">
          <div class="tx-gray-700">Share</div>
          <nav>
            <a href="#share" data-toggle="collapse" role="button"
              aria-expanded="true" aria-controls="share" class="d-inline mg-l-5 nav-link">
              <i data-feather="edit" /></a>
          </nav>
        </div>
        <div class="collapse mg-t-10" id="share">
          <div class="input-group">
            <input type="number" bind:value={settings.pointsConfig.Share} class="form-control"
              placeholder="Share Points" aria-label="Share Points"
              on:keyup={(event) => event.key == 'Enter' && setLoyaltyPoints('share')} />
            <button class="btn btn-outline-light"
              disabled={isLoading} type="button" on:click={(event)=> setLoyaltyPoints('share')}
              >
              Save
            </button>
          </div>
        </div>
      </li>
      {/if}
      {/if}
    </ul>
  </div>
</div>
