<script>
    import { Router, Link, link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';

    import GetMobileNumber from "./getMobileNumber.svelte";
    import VerifyOtp from "./oldVerifyOtp.svelte";
    import ChooseUser from "./chooseUser.svelte";

    onMount(() => {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken) {
            navigate("/dashboard");
        }
    });

    function login() {
        navigate("/dashboard");
    }

</script>

<link rel="stylesheet" href="/main/assets/css/dashforge.auth.css">


<div class="content content-fixed content-auth" style="flex: 1;">
    <div class="container">
        <div class="media align-items-stretch justify-content-center ht-100p pos-relative">
            <div class="media-body align-items-center d-none d-lg-flex">
                <div class="mx-wd-600">
                    <img src="/images/signin.png" class="img-fluid" alt="">
                </div>
            </div><!-- media-body -->
            <Router>
                <Route path="/">
                    <GetMobileNumber />
                </Route>

                <Route path="/choose-user">
                    <ChooseUser />
                </Route>

                <Route path="/verify-otp">
                    <VerifyOtp />
                </Route>
            </Router>
        </div><!-- media -->
    </div><!-- container -->
</div><!-- content -->
