<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, onDestroy, tick, afterUpdate } from "svelte";
  import { pSuccess, pError, pAlert } from "../../utils/notify.svelte";
  import ImageUploader from "../../utils/imageUploader.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import EmptyPermissions from "../empty/emptyPermissions.svelte";
  import EmptyBatchList from "../empty/emptyBatchList.svelte";
  import EmptyAccessProfiles from "../empty/emptyAccessProfiles.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js" 
  import { userData  } from "../../stores";

  export let id;
  let overrideAccessProfileModal;
  let elem_selectReportsTo,
    elem_mobileNoInput,
    mobileNoCleave,
    selectedSelectize;
  let modalEditAccess = {};
  let errorEditAccess = {};
  let batchListForSelectAndUnSelectAll = [];
  let localUserData
  const unsubscribe = userData.subscribe((value) => {
            localUserData = value;
  });
  let elemSelectClass, selectizeInstanceClass, resClass
  let selectizeBranchInstance, filterElemBranch,branchList
  let scroll1,
    scrollBatchList,
    scrollCustomAccessProfile,
    scrollOverrideAccessProfile;
  let isLoadingSave;
  let isLoading = false;
  let isLoadingAddAccessProfile;
  let newAccessProfileModal;
  let addNewUserModal;
  let selectedRole; 
  let elem_orgAccessProfiles;
  let orgAccessProfiles = [];
  let permissions = {};
  let modalFields = { name: "" };
  let displayTitle;
  let breadCrumbTitle;
  let accessProfilesList = [];
  let batchList = [];
  let batchListLengthOffset = 10000;
  let batchListFilters = {
    start: 0,
    length: batchListLengthOffset,
    search: { value: "" },
    sort: { seqId: 1 },
  };
  let firstInputField;
  let firstInputField1;
  let newAccessProfileInput;
  $: systemUserDetails = {
    _id: "",
    name: "",
    profilePic: "",
    mobileNo: "",
    email: "",
    role: "orgAdmin"
  };

  let systemUserDetails1 = {
    name: "",
    
  };
  const rolesData1 = [
    { value: "", text: "All Roles" },
    { value: "orgAdmin1", text: "Org Admin" },
    { value: "admin1", text: "Admin" },
    { value: "batchManager1", text: "Batch Manager" },
    { value: "faculty1", text: "Faculty" },
    { value: "telecaller1", text: "Tele Caller" },
  ];

  let updatePasswordObj = {
    password: '',
    confirmPassword: ''
  }
  let errorDetails = {};

  let treeJson=[];

  async function api_fetchBatchList(data) {

    let action = "systemUser.fetchBatchList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_saveCustomBatchAccessProfile(data) {

    let action = "systemUser.saveCustomBatchAccessProfile";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_getBranchesList() {
        var resData = await api_handleApiCalls('batch.getBranchNameList', {})
        if (resData.status) {

            return resData.data
        } else {
            return []
        }
    }

  async function api_assignBatch(data) {

    let action = "systemUser.assignBatch";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_unassignBatch(data) {

    let action = "systemUser.unassignBatch";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_fetchPermissions(data) {

    let action = "systemUser.fetchPermissions";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_fetchAccessProfileList(data) {

    let action = "systemUser.fetchAccessProfilesList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_fetchApplicationModules(data) {

    let action = "systemUser.fetchApplicationModules";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_getbyid(data) {

    let action = "systemUser.fetchSystemUser";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_save(data) {

    let action = "systemUser.overridePermission";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_edit(data) {

let action = "systemUser.editPermission";
var response = await api_handleApiCalls(action, data);
return response;

}



  async function api_getClassList(data) {
        var resData = await api_handleApiCalls('instituteClass.fetchInstituteClasses', data)
        if (resData.status) {
            return resData.data
        } else {
            return []
        }
    }

   

  async function filterSelectize(){
    branchList = await api_getBranchesList()
    selectizeBranchInstance = _$(filterElemBranch).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: 'Search by branch',
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: branchList,
            onChange: async function(values){
              //  console.log("values branches>>>>", values);
               if(values.length) {
                 batchList = await api_fetchBatchList({branchIds: values})
                 if(batchList.status){
                  batchList = batchList.data;
                  delete batchList.start;
                  delete batchList.length;
                 }
               } else initBatchList()
               
            }
    });

    let resClass = await api_getClassList({
            orgId : localUserData.orgId
    }) 

    selectizeInstanceClass = _$(elemSelectClass).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: 'Search by class',
            plugins: ["remove_button"],
            options: resClass,
            onChange: async function(values){
              //  console.log("values cllasses>>>>", values);
               if(values.length) {
                 batchList = await api_fetchBatchList({classIds: values})
                 if(batchList.status){
                  batchList = batchList.data;
                  delete batchList.start;
                  delete batchList.length;
                 }
                } else initBatchList()
            }
    });

  } 

  function validateAccessProfile() {
    clearValidations();
    let sampleObj = {
      name: "",
      templateId: "",
      userId: "5f7c05e9376c5441444848d5",
      batchId: "5f7c450616e91d3f18b3d850",
      override: [
        // override values
        { accessProfileId: "", access: {} },
      ],
      // copy access
      access: {
        "batch-add": false,
        "batch-edit": false,
        "batch-delete": false,
      },
    };

    var r9CgKDSHhyFVbCH2zkSDduhBDhwYqLHhVm = new RegExp(`^[A-Za-z]`);

    modalFields.name = modalFields.name.trim();
    if (!Boolean(modalFields.name)) {
      errorDetails.accessProfileName = "Name must not be empty";
    } else if (!modalFields.name.match(r9CgKDSHhyFVbCH2zkSDduhBDhwYqLHhVm)) {
      errorDetails.accessProfileName = "Name must start with alphabet";
    }

    if (modalFields.templateId) {
      if (!modalFields.templateId.match(/^[0-9a-fA-F]{24}$/)) {
        errorDetails.templateId = "Selected Template Id is invalid";
      }
    }

    // if (userId) {
    //   if (!userId.match(/^[0-9a-fA-F]{24}$/)) {
    //     errorDetails.userId = "Selected User Id is invalid";
    //   }
    // }

    // if (validateId(batchId)) {
    //   if (!batchId.match(/^[0-9a-fA-F]{24}$/)) {
    //     errorDetails.batchId = "Selected Batch Id is invalid";
    //   }
    // }

    // if (typeof override == "object") {
    //   if (override.accessProfileId) {
    //     if (!batchId.match(/^[0-9a-fA-F]{24}$/)) {
    //       errorDetails.permissions =
    //         "Selected Override Access Profile Id is invalid";
    //     }
    //   }

    //   if (typeof override.access == "object") {
    //     let keys = Object.keys(override.access);
    //     if (keys.length) {
    //       if (!keys.find((key) => typeof override.access[key] != "boolean")) {
    //         errorDetails.permissions =
    //           "Override access permissions are invalid";
    //       }
    //     }
    //   }
    // }

    if (typeof modalFields.access == "object") {
      let keys = Object.keys(modalFields.access);
      if (keys.lenght) {
        if (!keys.find((key) => typeof modalFields.access[key] != "boolean")) {
          errorDetails.permission = "Access profile permissions are invalid";
        }
      }
    }

    if (Object.values(errorDetails).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async function addNewCustomAccessProfile(event) {
    if (!isLoadingAddAccessProfile) {
      isLoadingAddAccessProfile = true;
      await tick();
      modalFields.access = {};
      permissions.map(
        (item) => (modalFields.access[`${item.id}`] = Boolean(item.checked))
      );

      let isValidAccessProfile = validateAccessProfile();
      // console.log({ isValidAccessProfile });
      if (isValidAccessProfile) {
        let newAccessProfile = modalFields;
        newAccessProfile.userId = id;
        // console.log(newAccessProfile);
        let result = await api_saveCustomBatchAccessProfile(newAccessProfile);
        if (result.status) {
          pSuccess("", result.data.message);
          // close this modal
          _$(newAccessProfileModal).modal("hide");
          const clearProfileFields = false;
          clearFields(clearProfileFields, true);
          await tick();
          reload(true);
          _$(".template-access-profile-select")[0].selectize.setValue("");
        } else {
          pError("", result.errMsg || result.error || "Something went wrong");
        }
      }
      isLoadingAddAccessProfile = false;
      await tick();
    }
  }

  function validate() {
    //   clear previous validations before validating anew
    clearValidations();

    var regexpAlphabetOnly = new RegExp(`^[A-Za-z]+$`);
    var regexpName = new RegExp(`^[A-Za-z\\s]+$`);
    var regexpMobileNumber = new RegExp(`^[6-9]{1,1}[0-9]{9,9}$`);
    var regexpEmail = new RegExp(`^[\\w-\\.]+@([\\w-]+\\.*)+\\.[\\w-]{2,4}$`);
    // name
    systemUserDetails.name = systemUserDetails.name.trim();
    if (!Boolean(systemUserDetails.name)) {
      errorDetails.name = "Full Name can not be empty";
    } else if (!systemUserDetails.name.match(regexpName)) {
      errorDetails.name =
        "Full Name must have only A-Z or a-z characters and space";
    } else if (systemUserDetails.name.length < 3) {
      errorDetails.name = "Full Name should be minimum 3 characters long";
    } else if (systemUserDetails.name.length > 80) {
      errorDetails.name = "Full Name should not exceed 80 characters long";
    }

    // mobilenumber
    systemUserDetails.mobileNo = systemUserDetails.mobileNo.trim();
    if (!Boolean(systemUserDetails.mobileNo)) {
      errorDetails.mobileNo = "Mobile Number can not be empty";
    } else if (!`${systemUserDetails.mobileNo}`.match(regexpMobileNumber)) {
      errorDetails.mobileNo = "Invalid Mobile Number";
    } else if (`${systemUserDetails.mobileNo}`.length < 10) {
      errorDetails.mobileNo = "Mobile Number should be 10 digits long";
    } else if (`${systemUserDetails.mobileNo}`.length > 10) {
      errorDetails.mobileNo = "Mobile Number should be 10 digits long";
    }

    // email
    if (Boolean(systemUserDetails.email)) {
      systemUserDetails.email = systemUserDetails.email.trim();
      if (!systemUserDetails.email.match(regexpEmail)) {
        errorDetails.email = "Invalid Email Address";
      }
    }

    if (!Boolean(systemUserDetails.role)) {
      errorDetails.role = "Please select a role for the user";
    }

    // if (Boolean(systemUserDetails.reportsTo)) {
    // }

    if (Object.values(errorDetails).length > 0) {
      return false;
    } else {
      return true;
    }
  }


  let getDisabledIds = function (selectedIds) {
    let plainArr = getPlainArrFromTree(treeJson);
    let disabledModules = [];
    plainArr.forEach(aArr => {
      let found = false;
      selectedIds.forEach(aSelectedId => {
        if (aSelectedId == aArr.id) {
          found = true;
        }
      });

      if (!found) {
        disabledModules.push(aArr.nodeId);
      }
    });

    return disabledModules

  }
  async function submitForm1(event) {

let selectedIds =  _$.jstree.reference('#jstree1').get_selected();

let disabledModules = getDisabledIds(selectedIds);
// console.log("disabledModules : ", disabledModules);
if (event) event.preventDefault();
if (isLoading) return;
isLoading = true;
await tick();
const isValid = validate();
// console.log({ isValid, errorObj });
if (isValid) {
  {
    // console.log("adding", { systemUserDetails, errorObj });
  }

  systemUserDetails1.disabledModules = disabledModules;
  systemUserDetails1.accessProfileId = selectedRole;

  orgAccessProfiles.forEach(aAccessProfile => {
    if(aAccessProfile._id.toString() == selectedRole){
      systemUserDetails1.role = aAccessProfile.name;
      systemUserDetails1._id = systemUserDetails._id
    }
  });





  // save systemuser api call
  let result = await api_edit(systemUserDetails1);
  if (result.status) {
    pSuccess("", "Access changed successfully");
    _$(addNewUserModal).modal("hide");
    clearFields();
    navigate("systemuser/" + result.data);
  } else {
    pError("", result.errMsg);
  }
}
isLoading = false;
await tick();
}

  async function submitForm(event) {
    event.preventDefault();
    if (!isLoadingSave) {
      isLoadingSave = true;
      await tick();
      const isValid = validate();
      console.log({ isValid, errorDetails });
      if (isValid) {
        console.log("updating", { systemUserDetails, errorDetails });
        // let data = systemUserDetails;
        // delete data.profilePic
        // save systemuser api_handleApiCalls call
        let selectedIds = null
        let disabledModules = null
        
        if(systemUserDetails.role != "orgAdmin"){
          selectedIds = _$.jstree.reference('#jstree').get_selected();
          disabledModules = getDisabledIds(selectedIds);
        } 
        systemUserDetails.disabledModules = disabledModules;
        
        let result = await api_save(systemUserDetails);
        if (result.status) {
          pSuccess("", "System user details updated successfully");
        } else {
          pError("", result.errMsg || result.error || "Something went wrong");
        }
      }
      isLoadingSave = false;
      await tick();
    }
  }

  function clearFields(clearProfileFields, clearModalFields) {
    if (clearProfileFields) {
      // id
      systemUserDetails._id = "";
      // form fields
      systemUserDetails.name = "";
      systemUserDetails.mobileNo = "";
      systemUserDetails.email = "";
      systemUserDetails.reportsTo = "";
    }
    systemUserDetails.batchList = [];

    if (clearModalFields) {
      modalFields = {
        name: "",
        templateId: "",
        userId: id,
        batchId: "",
        override: [{ accessProfileId: "", access: {} }],
      };
    }
    clearValidations();
  }

  async function clearValidations() {
    var keys = Object.keys(errorDetails);
    keys.map((key) => delete errorDetails[key]);
    errorDetails = errorDetails;
    await tick();
  }

  async function addRemoveBatch(selectedId) {
    if (
      systemUserDetails.batchList &&
      systemUserDetails.batchList.length &&
      systemUserDetails.batchList.find(
        (item) => item.active && item.batchId == selectedId
      )
    ) {
      // call api_handleApiCalls to remove accessprofileid from user
      let result = await api_unassignBatch({ userId: id, batchId: selectedId });
      if (result.status) {
        pSuccess("", result.data);
        reload(true);
      } else {
        pError("", result.errMsg);
      }
    } else {
      // call api_handleApiCalls to add accessprofileid to user
      let result = await api_assignBatch({ userId: id, batchId: selectedId });
      if (result.status) {
        pSuccess("", result.data);
        reload(true);
      } else {
        pError("", result.errMsg);
      }
    }
  }

  function initMobileNumberField() {
    // elem_mobileNoInput
    mobileNoCleave = new Cleave(elem_mobileNoInput, {
      phone: true,
      phoneRegionCode: "IN",
      onValueChanged: (e) => {
        systemUserDetails.mobileNo = e.target.rawValue;
      },
    });
  }

  async function selectTemplateFunction(value) {
    if (!value) {
      modalFields.templateId = value;
      return;
    }
    modalFields.templateId = value;
    console.log({ templateId: modalFields.templateId });
    if (modalFields.templateId) {
      //  copy template exists; therefore bring its permissions
      let list = await api_fetchPermissions({
        accessProfileId: modalFields.templateId,
        batchId: modalFields.batchId,
        userId: id,
        new: true,
      });
      if (list.status) {
        permissions = list.data.data.permissions;
        console.log({ permissions });
      } else {
        pError("", list.errMsg);
      }
    } else {
      //  no copy template means bring all empty applicationModules
      let list = await api_fetchApplicationModules();
      if (list.status) {
        permissions = list.data.data;
        console.log({ permissions });
      } else {
        permissions = [];
      }
    }
  }

  async function handleUpdateProfilePic() {
    if (systemUserDetails.profilePic) {
      let result = await api_handleApiCalls("systemUser.setProfilePic", {
        userId: id,
        profilePic: systemUserDetails.profilePic,
      });
      console.log("setProfilePic", result);
      if (result.status) {
        pSuccess("", result.data);
        reload();
      } else {
        pError("", result.errMsg);
      }
    } else {
      pError("Error!", "Failed to update the profile picture.");
    }
  }

  let dropdownsInitOnce = false;
  async function initDropdown() {
    let rolesData;
    let accessProfiles = await api_fetchAccessProfileList();
    if (
      accessProfiles.status &&
      accessProfiles.data &&
      accessProfiles.data.data &&
      accessProfiles.data.data.length
    ) {
      accessProfiles.data.data = accessProfiles.data.data.map((item) => {
        console.log(item.name);
        switch (item.name) {
          case "orgAdmin":
            return { ...item, name: "Org Admin" };
          case "admin":
            return { ...item, name: "Admin" };
          case "faculty":
            return { ...item, name: "Faculty" };
          case "batchManager":
            return { ...item, name: "Batch Manager" };
          case "telecaller":
            return { ...item, name: "Tele Caller" };
          default:
            return item;
        }
      });

      rolesData = accessProfiles.data.data;
      accessProfilesList = accessProfiles.data.data;
      await tick();
      // find accessProfile dropdowns ".access-profile-select"
      let accessProfileSelectionList = _$(".access-profile-select");
      // loop
      for (let index = 0; index < accessProfileSelectionList.length; index++) {
        const element = _$(accessProfileSelectionList[index]);
        //  get id from ap-{batch._id}
        let batchId = element.attr("id").split("ap-")[1];
        if (!batchId) continue;
        //  find if it exists in systemUserDetails.batchList
        let batch = systemUserDetails.batchList.find(
          (item) => item.active && item.batchId == batchId
        );
        if (!batch) continue;
        //    if found, find accessProfileId for this item
        let selectedAccessProfileId = batch.accessProfileId;

        //    then map rolesData with this in new temp array
        let selectedRolesData = accessProfiles.data.data;

        if (!selectedRolesData) selectedRolesData = [];
        selectedRolesData.push({
          _id: "new",
          name: "+ Add new access profile",
        });
        let selected = selectedRolesData.find(
          (item) => item._id == selectedAccessProfileId
        );
        if (selected && selected._id) selected = [selected._id];
        else selected = [];
        _$(element).selectize({
          maxItems: 1,
          valueField: "_id",
          labelField: "name",
          searchField: "name",
          options: rolesData,
          create: false,
          items: [...selected],
          onChange: async function (value) {
            if (!value) return false;
            if (value == "new") {
              modalFields.name = "";
              _$(newAccessProfileModal).modal("show");
              _$(newAccessProfileInput).focus();
              selectedSelectize = _$(this)[0];
              selectedSelectize.setValue("");
              await tick();
              modalFields.batchId = batchId; //.split("ap-")[1];
              // TODO: make it clearFields
              modalFields.templateId = null;
              // open modal
              let list = await api_fetchApplicationModules();
              if (list.status) {
                permissions = list.data.data;
                console.log({ permissions });
              } else {
                permissions = [];
              }
              await tick();
              if (scrollCustomAccessProfile) scrollCustomAccessProfile.update();
              else {
                scrollCustomAccessProfile = new PerfectScrollbar(
                  ".scroll-custom-access-profile",
                  {
                    suppressScrollX: true,
                  }
                );
              }
              return false;
            } else if (!selectedSelectize && value) {
              let accessProfileId = value;
              let result = await api_handleApiCalls("systemUser.setBatchAccessProfile", {
                userId: id,
                batchId,
                accessProfileId,
              });
              if (result.status) {
                pSuccess("", "accessProfile assigned successfully");
                // api_handleApiCalls call
                console.log({ accessProfileId });
                reload(true);
              } else {
                pError("", result.errMsg);
              }
              return true;
            }
          },
          render: {
            option: function (item, callback) {
              let selected = selectedRolesData.find(
                (item) => item._id == selectedAccessProfileId
              );
              if (selected && selected._id) selected = "selected";
              else selected = "";
              if (item._id == "new") {
                return `<div class="option text-capitalize" data-value="${item._id}"> <a href="javascript:void(0)" style="width: 100%;" class="btn btn-secondary">${item.name}</a> </div>`;
              } else {
                return `<div class="option  text-capitalize ${selected}" 
                data-selectable="" data-value="${item._id}">${item.name}</div>`;
              }
            },
          },
        });
        accessProfilesList.pop();
      }
      await tick();
      // let deleteIndex = rolesData && rolesData.length && rolesData.findIndex((item) => item && item._id && item._id == "new");
      // if (deleteIndex != -1) delete rolesData[deleteIndex];
      if (!dropdownsInitOnce) {
        dropdownsInitOnce = true;
        _$(".template-access-profile-select").selectize({
          maxItems: 1,
          valueField: "_id",
          labelField: "name",
          searchField: "name",
          options: rolesData1,
          create: false,
          items: [],
          onChange: selectTemplateFunction,
          sortField: "text",
        });
      }
    } else {
      accessProfilesList = [];
    }
    // scroll bar
    if (scrollBatchList) scrollBatchList.update();
    else
      scrollBatchList = new PerfectScrollbar(".scroll-batch-list", {
        suppressScrollX: true,
      });
    feather.replace({ width: "1em", height: "1em" });
    await tick();
    _$(".btn-add-new").on("click", async function (event) {
      event.preventDefault();
      modalFields.name = "";
      _$(newAccessProfileModal).modal("show");
      _$(newAccessProfileInput).focus();
      await tick();
      modalFields.batchId = id.split("ap-")[1];
      // TODO: make it clearFields
      modalFields.templateId = null;
      // open modal
      let list = await api_fetchApplicationModules();
      if (list.status) {
        permissions = list.data.data;
        console.log({ permissions });
      } else {
        permissions = [];
      }
      await tick();
      if (scrollCustomAccessProfile) scrollCustomAccessProfile.update();
      else {
        scrollCustomAccessProfile = new PerfectScrollbar(
          ".scroll-custom-access-profile",
          {
            suppressScrollX: true,
          }
        );
      }
    });
  }

  async function initBatchList() {
    batchList = await api_fetchBatchList(batchListFilters);
    console.log("batch list length",batchList); 
    batchListForSelectAndUnSelectAll = batchList
    if (batchList.status) {
      batchList = batchList.data;
      delete batchList.start;
      delete batchList.length;
      console.log(batchList);
    } else {
      batchList = [];
    }
    initDropdown();
    // await tick();
  }

  async function reload(updateOnlyList) {
    // fetch systemuser data by id
    const clearProfileFields = !updateOnlyList;
    clearFields(clearProfileFields);
    let response = await api_getbyid({ _id: id });
    if (response.status) {
      if (updateOnlyList) systemUserDetails.batchList = response.data.batchList;
      else systemUserDetails = response.data;
      if(systemUserDetails.role != 'orgAdmin') loadTree(id);
      await filterSelectize()
      mobileNoCleave.setRawValue(systemUserDetails.mobileNo);
      let otherSystemUsers = await api_handleApiCalls("systemUser.fetchOtherSystemUsersList", {
        userId: id,
        start: 0,
        length: 100,
        sort: { name: 1 },
      });
      if (otherSystemUsers.status) {
        otherSystemUsers = otherSystemUsers.data.data;
        _$(elem_selectReportsTo).selectize({
          maxItems: 1,
          valueField: "_id",
          labelField: "name",
          searchField: "name",
          options: otherSystemUsers,
          create: false,
          items: systemUserDetails.reportsTo
            ? [systemUserDetails.reportsTo]
            : [],
          onChange: function (value) {
            systemUserDetails.reportsTo = value;
          },
        });
      }
      breadCrumbTitle = systemUserDetails.name;
      displayTitle = systemUserDetails.name;
      await initBatchList();
      // await tick();
    } else {
      pError("", result.errMsg || result.error || "Something went wrong");
    }
  }

  function bindModalAnimations() {
    // new access profile modal
    _$(newAccessProfileModal).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
    });

    _$(newAccessProfileModal).on("hidden.bs.modal", function (e) {
      _$(this).removeClass("effect-scale");
      let batch = systemUserDetails.batchList.find(
        (item) => item.active && item.batchId == modalFields.batchId
      );
      if (!batch) {
        console.log("batch not found in system user batchlist");
        return false;
      }
      //    if found, find accessProfileId for this item
      let selectedAccessProfileId = batch.accessProfileId;

      //    then map rolesData with this in new temp array
      let selectedRolesData = [...accessProfilesList];

      if (!selectedRolesData) selectedRolesData = [];
      selectedRolesData.push({
        _id: "new",
        name: "+ Add new access profile",
      });
      let selected = selectedRolesData.find(
        (item) => item._id == selectedAccessProfileId
      );

      if (selected && selected._id && selectedSelectize)
        selectedSelectize.setValue(selected._id);
      selectedSelectize = null;
    });

    // override access profile modal
    _$(overrideAccessProfileModal).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
    });

    _$(overrideAccessProfileModal).on("hidden.bs.modal", function (e) {
      _$(this).removeClass("effect-scale");
    });
  }

  function validateOverride() {
    if (typeof modalEditAccess.permissions == "object") {
      let keys = Object.keys(modalEditAccess.permissions);
      if (keys.lenght) {
        if (
          !keys.find(
            (key) => typeof modalEditAccess.permissions[key] != "boolean"
          )
        ) {
          errorEditAccess.permission = "Access profile permissions are invalid";
        }
      }
    }

    if (Object.values(errorEditAccess).length > 0) {
      return false;
    } else {
      return true;
    }
  }
  async function overrideAccessProfile(overrideId, checked) {
    errorEditAccess = {};
    let isValid = validateOverride();
    if (isValid) {
      if (
        !modalEditAccess.userId.match(/^[0-9a-fA-F]{24}$/) ||
        typeof overrideId != "string" ||
        typeof checked != "boolean"
      ) {
        console.error("userId is not valid ", { userId, overrideId, checked });
        pError("", "Something went wrong");
      } else {
        let data = {
          userId: modalEditAccess.userId,
          accessProfileId: modalEditAccess.accessProfileId,
          batchId: modalEditAccess.batchId,
          checked,
          id: overrideId,
        };

        let result = await api_handleApiCalls("systemUser.overridePermission", data);
        if (result.status) {
          pSuccess("", "permission changed successfully");
          loadEditAccess();
        } else if (result.error) {
          errorEditAccess.apiError = "something went wrong";
          pError("", "Something went wrong");
        } else {
          pError("", result.errMsg);
        }
      }
    }
  }

  async function loadEditAccess() {
    errorEditAccess.apiError = "";
    if (!modalEditAccess.batchId) {
      pAlert("Cannot Edit Access", "Please set assign batch first");
      return;
    }
    if (!modalEditAccess.accessProfileId) {
      pAlert("Cannot Edit Access", "Please select access profile first");
      return;
    }
    // loop
    let data = {
      accessProfileId: modalEditAccess.accessProfileId,
      batchId: modalEditAccess.batchId,
      userId: modalEditAccess.userId,
    };
    let result = await api_handleApiCalls("systemUser.fetchPermissions", data);
    if (result.status) {
      modalEditAccess.batchName = result.data.batchName;
      modalEditAccess.accessProfileName = result.data.accessProfileName;
      modalEditAccess.permissions = result.data.data.permissions;
      // open access profile modal
      _$(overrideAccessProfileModal).modal("show");
      await tick();
      if (scrollOverrideAccessProfile) scrollOverrideAccessProfile.update();
      else
        scrollOverrideAccessProfile = new PerfectScrollbar(
          ".scroll-override-access-profile",
          {
            suppressScrollX: true,
          }
        );
    } else if (result.error) {
      errorEditAccess.apiError = "something went wrong";
      pError("", "Something went wrong");
    } else {
      pError("", result.errMsg);
    }
  }
  async function init() {
    clearFields();
    //   btn text & page heading
    displayTitle = "Update System User";
    systemUserDetails._id = id;
    initMobileNumberField();
    bindModalAnimations();
    reload();
    firstInputField.focus();
  }

    let getPlainArrFromTree = function (aTreeNode) {
        let plainArr = [];
        let etrOverTree = function (_aTreeNode) {
          _aTreeNode.forEach(_aNode => {
            plainArr.push(_aNode);
            if (_aNode.children && _aNode.children.length > 0) {
              etrOverTree(_aNode.children);
            }
          });
        }
        etrOverTree(aTreeNode)
        return plainArr;
      }

  let loadTree = async function(userId){
    let roleTerrResult = (await api_handleApiCalls('roles.getModuleForUser', {
      userId: userId
    }));
    console.log("--------------------- role tree : ", roleTerrResult);
    treeJson = roleTerrResult.data;

    var to = false;

    _$("#jstree").jstree('destroy');

    _$('#tree_search').keyup(function () {
      if (to) { clearTimeout(to); }
      to = setTimeout(function () {
        var v = _$('#tree_search').val();
        _$('#jstree').jstree(true).search(v);
      }, 250);
    });
    _$('#jstree').jstree({
      "plugins": ["wholerow", "checkbox", "search", "sort"],
      "checkbox": {
        cascade: 'down',
        "three_state": false,
      },
      "search": {
        "show_only_matches": true
      },
      'core': {
        'data': treeJson,
        "dblclick_toggle": false
      }
    }).on('changed.jstree', function (e, data) {
      var i, j, r = [];
      for (i = 0, j = data.selected.length; i < j; i++) {
        r.push(data.instance.get_node(data.selected[i]).text);
      }
      console.log(" --------------------- data : ", data)

      let plainArr = getPlainArrFromTree(treeJson);
      // console.log("plainArr  : ", plainArr)
      if (data.action == 'ready') {
        plainArr.forEach(aTreeNode => {
          if (!aTreeNode.state.selected) {
            _$.jstree.reference('#jstree').deselect_node(aTreeNode.id);
          }
        });
      }

      if (data.node && data.node.children_d && data.node.children_d.length > 0) {
        let nodeId = data.node.id;
        for (let c = 0; c < data.node.children_d.length; c++) {
          const aChildren = data.node.children_d[c];
          if (data.action == "deselect_node") {
            _$.jstree.reference('#jstree').disable_node(aChildren);
          }
          if (data.action == "select_node") {
            _$.jstree.reference('#jstree').enable_node(aChildren);
          }
        }
      }

    })
    // setTimeout(function(){
    //   _$.jstree.reference('#jstree').close_all(null,500);
    // },500)
  }


  let openAddUserModal = async () => {
    clearFields();
    _$(elem_orgAccessProfiles).selectize({
      preload: true,
      create: false,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      load: async function (query, callback) {
        this.clearOptions();
        let data = {
          search: query,
        };
        let result = (await api_handleApiCalls('roles.getOrgAccessProfiles', data));
        if (result.status) {
          orgAccessProfiles = result.data;
          callback(result.data);
        } else {
          orgAccessProfiles = [];
          callback([]);
        }
      },
      onBlur: function () {

      },
      onChange: async function (value) {
        if (!value) {
          selectedRole = undefined;
          return;
        } else {
          selectedRole = value;
          let roleTerrResult = (await api_handleApiCalls('roles.getModuleForAccessProfile', {
            roleId: value
          }));
          // console.log("--------------------- role tree : ", roleTerrResult);
          treeJson = roleTerrResult.data;

          var to = false;

          _$("#jstree1").jstree('destroy');

          _$('#tree_search1').keyup(function () {
            if (to) { clearTimeout(to); }
            to = setTimeout(function () {
              var v = _$('#tree_search1').val();
              _$('#jstree1').jstree(true).search(v);
            }, 250);
          });
          _$('#jstree1').jstree({
            "plugins": ["wholerow", "checkbox", "search"],
            "checkbox": {
              cascade: 'down',
              "three_state": false,
            },
            "search": {
              "show_only_matches": true
            },
            'core': {
              'data': treeJson,
              "dblclick_toggle": false
            }
          }).on('changed.jstree1', function (e, data) {
            var i, j, r = [];
            for (i = 0, j = data.selected.length; i < j; i++) {
              r.push(data.instance.get_node(data.selected[i]).text);
            }
            // console.log(" --------------------- data : ", data)

            let plainArr = getPlainArrFromTree(treeJson);
            // console.log("plainArr  : ", plainArr)
            if (data.action == 'ready') {
              plainArr.forEach(aTreeNode => {
                if (!aTreeNode.state.selected) {
                  _$.jstree1.reference('#jstree1').deselect_node(aTreeNode.id);
                }
              });
            }

            if (data.node && data.node.children_d && data.node.children_d.length > 0) {
              let nodeId = data.node.id;
              for (let c = 0; c < data.node.children_d.length; c++) {
                const aChildren = data.node.children_d[c];
                if (data.action == "deselect_node") {
                  _$.jstree1.reference('#jstree1').disable_node(aChildren);
                }
                if (data.action == "select_node") {
                  _$.jstree1.reference('#jstree1').enable_node(aChildren);
                }
              }
            }

          })


        }
      },
    });

    _$(addNewUserModal).modal('show');
    _$(firstInputField1).focus();

  }

  const updatePasswordModalShow = (show) => {
    _$('#modalUpdatePassword').modal(show?'show':'hide')
  }

  const updatePasswordClickHandler = async () => {
    if(!updatePasswordObj.password) return errorDetails['password'] = "Enter 6-8 characters valid password"
    if(updatePasswordObj.password.length < 6) return errorDetails['password'] = "Enter 6-8 characters valid password"
    if(updatePasswordObj.password != updatePasswordObj.confirmPassword) return errorDetails['confirmPassword'] = "Password and confirm password dose not match"


    let response = await api_handleApiCalls('auth.updatePasswordByUserId', {
      userId: id,
      password: updatePasswordObj.password
    })

    if(response.status){
      pSuccess('Success', response.data)
      errorDetails['password'] = ''
      errorDetails['confirmPassword'] = ''
      updatePasswordObj = {
        password: '',
        confirmPassword: ''
      }
      updatePasswordModalShow(false)
    } else {
      pError('Error', response.errMsg)
    }
  }


  async function handleSelectAllClick(){

    batchListForSelectAndUnSelectAll = batchListForSelectAndUnSelectAll.data.data.map(i=>{
      return i._id
    })
    let res = await api_assignBatch({userId: id , batchIdList: batchListForSelectAndUnSelectAll});
    if(res.status){
      pSuccess("Success!", res.data);
      reload(true);
    }else{
      pError("Error!", res.data)
    }
  }

  async function handleUnSelectAllClick(){

    batchListForSelectAndUnSelectAll = batchListForSelectAndUnSelectAll.data.data.map(i=>{
      return i._id
    })
    let res = await api_unassignBatch({userId: id ,batchIdList: batchListForSelectAndUnSelectAll});
    if(res.status){
      pSuccess("Success!", res.data);
      reload(true);
    }else{
      pError("Error!", res.data)
    }
  }

  onMount(init);
  afterUpdate(function () {
    // _$('[data-toggle="tooltip"]').tooltip();
  });
  onDestroy(clearFields);

  function clearPasswordFields(){
    updatePasswordObj.password =""
    updatePasswordObj.confirmPassword =""
  }
</script>

<style>
  .tx-capitalize {
    text-transform: capitalize;
  }

  .card-wrap {
    flex-wrap: wrap;
  }
</style>

<div class="content-body fadeIn ">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20
      mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/systemuser">System User</a>
          </li>
          <li class="breadcrumb-item">update</li>
          <li class="breadcrumb-item active" aria-current="page">
            {breadCrumbTitle}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1 tx-capitalize">{displayTitle}</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
        Update user's details, assign batch, select roles (access profiles)
      </p>
    </div>
    <div class="d-inline-block">
      
      {#if MODULES.hasAccess(MODULES.UPDATE_STUDENT)}
      {#if MODULES.hasAccess(MODULES.MANAGE_SYSTEM_USERS)}
      {#if systemUserDetails.role!='orgAdmin'}

        <button on:click={updatePasswordModalShow} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
          data-feather="lock" class="wd-10 mg-r-5"></i>
        Update Password</button>
  
  
          <button on:click={openAddUserModal} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
              data-feather="user-plus" class="wd-10 mg-r-5"></i>
            Change Role</button>
          {/if}
          {/if}
          
         
      
      <button disabled={isLoadingSave} on:click={submitForm}
        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="save" class="wd-10 mg-r-5" />
        <ButtonLoader isLoading={isLoadingSave} btnText="Save" />
      </button>
      {/if}
    </div>
  </div>
  <hr class="full-width" />
  <!-- form inputs -->
  <div class="mg-b-20 scroll0 pos-relative ht-100p">
    <div class="row row-sm mg-b-10">
      <!-- <div class=" scroll1 pos-relative ht-400"> scroll here?</div> -->
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 col-xl-7 mg-b-10">
        <div class="form-row mg-b-10">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Personal Information
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-md-12 form-group">
            <ImageUploader bind:profilePic={systemUserDetails.profilePic} on:updatePic={handleUpdateProfilePic}>
              <div slot="upload-container" let:src let:openCroppie={croppieOpen} let:openCamera={cameraOpen}>
                <div id="contactAvatar" class="pos-relative float-left">
                  <div class="avatar avatar-xl">
                    <img {src} alt="profile-pic" />
                  </div>
                  <a href="#" on:click|preventDefault class="contact-edit-photo" data-toggle="dropdown"
                    title="Upload Photo"><i data-feather="edit-2" /></a>
                  <ul class="dropdown-menu filterByDropdown">
                    <li class="dropdown-item" on:click={croppieOpen}>
                      <i data-feather="image" class="mg-r-5" />Uplaod Image
                    </li>
                    <li class="dropdown-item" on:click={cameraOpen}>
                      <i data-feather="camera" class="mg-r-5" />Take Picture
                    </li>
                  </ul>
                </div>
              </div>
            </ImageUploader>
          </div>
          <div class="col-md-9 form-group" style="display: none;">
            <!-- content here -->
            <label class="d-block" for="name">User Role<span class="required-field">*</span></label>
            <div class="d-md-flex flex-wrap">
              <div class="custom-control custom-radio col-md-4">
                <input type="radio" id="role-admin" bind:group={systemUserDetails.role}
                  disabled={systemUserDetails.role=='orgAdmin' } class="custom-control-input" value="admin1" />
                <label class="custom-control-label" for="role-admin">Admin</label>
              </div>

              <div class="custom-control custom-radio col-md-4">
                <input type="radio" id="role-batch-manager" bind:group={systemUserDetails.role}
                  disabled={systemUserDetails.role=='orgAdmin' } class="custom-control-input" value="batchManager1" />
                <label class="custom-control-label" for="role-batch-manager">Batch Manager</label>
              </div>

              <div class="custom-control custom-radio col-md-4">
                <input type="radio" id="role-faculty" bind:group={systemUserDetails.role}
                  disabled={systemUserDetails.role=='orgAdmin' } class="custom-control-input" value="faculty1" />
                <label class="custom-control-label" for="role-faculty">Faculty</label>
              </div>

              <div class="custom-control custom-radio col-md-4">
                <input type="radio" id="role-tele-caller" bind:group={systemUserDetails.role}
                  disabled={systemUserDetails.role=='orgAdmin' } class="custom-control-input" value="telecaller1" />
                <label class="custom-control-label" for="role-tele-caller">Tele Caller</label>
              </div>
            </div>
            {#if errorDetails.role}
            <div class="parsley-errors-list">{errorDetails.role}</div>
            {/if}
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12" autocomplete="off">
            <label class="d-block" for="name">Full Name
              <span class="required-field">*</span></label>
            <input type="text" autocomplete="off" class="form-control" id="name" aria-label="Full name" placeholder="Enter full name"
              bind:this={firstInputField} on:keydown={(event)=> event.key == 'Enter' && submitForm()}
            bind:value={systemUserDetails.name}
            class:parsley-error={errorDetails.name} />
            {#if errorDetails.name}
            <div class="parsley-errors-list">{errorDetails.name}</div>
            {/if}
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label class="d-block" for="mobilenumber">Mobile Number
              <span class="required-field">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">IN (+91)</span>
              </div>
              <input type="text" class="form-control" bind:this={elem_mobileNoInput} id="mobilenumber"
                class:parsley-error={errorDetails.mobileNo} placeholder="Enter mobile number" />
              {#if errorDetails.mobileNo}
              <div class="parsley-errors-list col-sm-6 col-md-6 col-lg-6">
                {errorDetails.mobileNo}
              </div>
              {/if}
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label class="d-block" for="email">Email</label>
            <input bind:value={systemUserDetails.email} type="text" class="form-control" id="email"
              on:keydown={(event)=> event.key == 'Enter' && submitForm()}
            class:parsley-error={errorDetails.email}
            placeholder="Enter email" />
            {#if errorDetails.email}
            <div class="parsley-errors-list">{errorDetails.email}</div>
            {/if}
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label class="d-block" for="reportsTo">Reports To</label>
            <select class="form-control" id="reportsTo" bind:this={elem_selectReportsTo}
              class:parsley-error={errorDetails.reportsTo} placeholder="Reports to">
              <option value="">Reports To</option>
            </select>
            {#if errorDetails.email}
            <div class="parsley-errors-list">{errorDetails.email}</div>
            {/if}
          </div>
          {#if systemUserDetails.role!='orgAdmin'}
          <div class="col-sm-12">
            <div class="row mg-b-10">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
                  Assign Batches
                </h6>
                <hr style="margin-top: 5px;" />
              </div>
              <div class="col-sm-2 col-md-2 col-lg-6 pd-b-15">
                <label class="d-block">Class/Stream</label>
                <select bind:this={elemSelectClass}></select>
              </div>
              <div class="col-sm-2 col-md-2 col-lg-6 pd-b-15">
                <label class="d-block">Branch</label>
                <select bind:this={filterElemBranch}></select>
                <!-- Class Filter -->
              </div>
              <form class="search-form col-sm-10 col-md-10 col-lg-6" on:submit={async (event)=> {
                event.preventDefault();
                initBatchList();
                }}>
                <input type="search" class="form-control" placeholder="Batch name, code name"
                  bind:value={batchListFilters.search.value} on:input={async (event)=> {
                initBatchList();
                }} />
                <button class="btn" type="submit"><i data-feather="search" /></button>
              </form>
              <div class="row col-sm-2 col-md-2 col-lg-6" style="display: flex; justify-content: flex-end;">
                <button type="button" style="float: right;" on:click={handleSelectAllClick} class="btn btn-xs btn-primary mg-r-10 mg-l-45" ><i data-feather="check-circle" class="wd-10 mg-r-5" /> Assign All</button>
                <button type="button" style="float: right;" on:click={handleUnSelectAllClick}  class="btn btn-xs btn-primary mg-r-5"><i data-feather="x-circle" class="wd-10 mg-r-5" />Unassign All</button>
              </div>
            </div>
            <div class="d-block scroll-batch-list pos-relative">
              <div class="d-sm-flex card-wrap">
                {#if batchList && batchList.data && batchList.data.length}
                {#each batchList.data as batch (batch._id)}
                <div class="card wd-xs-100p wd-sm-100p wd-md-100p wd-lg-100p wd-xl-100p">
                  <div class="card-body">
                    <div class="custom-control custom-switch d-block
                                  mg-r-10">
                      <input type="checkbox" class="custom-control-input batch-select" id={batch._id} checked={systemUserDetails
                        && systemUserDetails.batchList && systemUserDetails.batchList.find((item)=> item.active && item.batchId ==
                      batch._id)}
                      class:parsley-error={errorDetails.batchList}
                      on:click={() => addRemoveBatch(batch._id)} />
                      <label class="custom-control-label tx-12" for={batch._id}>{batch.name}
                        <span class="tx-10">({batch.code})</span></label>
                    </div>
          
                    <!-- {#if systemUserDetails && systemUserDetails.batchList && systemUserDetails.batchList.find((item) =>
                              item.active
                              && item.batchId == batch._id)}
                              {#if accessProfilesList && accessProfilesList.length}
                              <div class="form-group col-xs-12 col-sm-10 col-md-10 col-lg-10 col-xl-10
                                    d-inline-block">
                                <label class="d-inline-block" for="ap-{batch._id}">Access Profile</label>
                                <select class="form-control access-profile-select col-xs-12 col-sm-12
                                      col-md-12 col-lg-12 col-xl-12" id="ap-{batch._id}"
                                  placeholder="Please select access profile" class:parsley-error={errorDetails.role}>
                                  <option />
                                </select>
                              </div>
                              <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 d-inline-flex flex-row-reverse">
                                <button on:click={async ()=> {
                                  modalEditAccess.userId = id;
                                  modalEditAccess.batchId = batch._id;
                                  modalEditAccess.accessProfileId = systemUserDetails.batchList.find((item) => item.batchId ==
                                  batch._id).accessProfileId || false;
                                  modalEditAccess.templateId = modalFields.templateId;
                                  loadEditAccess();
                                  }}
                                  title="Edit Access"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  class="btn btn-icon btn-sm pd-x-15 {systemUserDetails.batchList.find((item) => item.batchId ==
                                  batch._id).accessProfileId ? 'btn-primary' : 'btn-secondary'} btn-uppercase mg-l-5
                                  d-inline-block"><i data-feather="edit" class="wd-10 mg-r-5" />
                                </button>
                              </div>
                              {:else}
                              <EmptyAccessProfiles />
                              {/if}
                              {/if} -->
                  </div>
                </div>
                {/each}
                {:else if batchListFilters.search.value}
                <EmptyBatchList mode="search" />
                {:else}
                <EmptyBatchList mode="empty" />
                {/if}
              </div>
            </div>
            {#if errorDetails.batchList}
            <div class="parsley-errors-list">{errorDetails.batchList}</div>
            {/if}
          </div>
          {/if}
         
        </div>
        <!-- form buttons -->
        <div class="d-flex flex-row-reverse">
         
          <button on:click={submitForm} disabled={isLoadingSave}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="save" class="wd-10 mg-r-5" />
            <ButtonLoader isLoading={isLoadingSave} btnText="Save" />
          </button>
        </div>
      </div>
      <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
        {#if systemUserDetails.role!='orgAdmin'}
          <div class="form-group col-sm-12 col-md-12 col-lg-12" style="    margin-bottom: 10px;">
            <label class="d-block" for="name">Select Modules for this roles</label>
            <input type="text" class="form-control" style="margin-bottom: 10px;" placeholder="Type to filter modules..." id="tree_search" />
            <div style="display: flex;justify-content: flex-end;">
              <!-- <button on:click={()=>{_$.jstree.reference('#jstree').select_all(false);}} class="btn btn-sm pd-x-15 btn-secondary
                btn-uppercase mg-l-5"><i data-feather="maximize-2" class="wd-10 mg-r-5" />
                Select All
              </button>
              <div style="flex:1"></div> -->
              <button on:click={()=>{_$.jstree.reference('#jstree').open_all(null,500);}} class="btn btn-sm pd-x-15 btn-secondary
                btn-uppercase mg-l-5"><i data-feather="maximize-2" class="wd-10 mg-r-5" />
                Expand
              </button>
              <button on:click={()=>{_$.jstree.reference('#jstree').close_all(null,500);}} class="btn btn-sm pd-x-15 btn-secondary
                btn-uppercase mg-l-5"><i data-feather="minimize-2" class="wd-10 mg-r-5" />
                Collapse
              </button>
            </div>
            
          </div>
          <div id="jstree" style="padding:0 15px;">
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>

<!-- Modal New AccessProfile -->
<div bind:this={newAccessProfileModal} class="modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header">
        <h6 class="modal-title" id="exampleModalLabel6">
          Add New Access Profile
        </h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label class="d-block" for="name">Name
              <span class="required-field">*</span></label>
            <input bind:this={newAccessProfileInput} bind:value={modalFields.name} on:keyup={(e)=> e.key === 'Enter' &&
            addNewCustomAccessProfile()}
            type="text"
            id="name"
            class="form-control tx-capitalize"
            class:parsley-error={errorDetails.accessProfileName}
            placeholder="Enter name" />
            {#if errorDetails.accessProfileName}
            <div class="parsley-errors-list">
              {errorDetails.accessProfileName}
            </div>
            {/if}
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label class="d-block" for="template">Copy Template</label>
            <select class="form-control template-access-profile-select" id="template"
              placeholder="Select access profile to duplicate" class:parsley-error={errorDetails.templateId}>
              <option />
            </select>
            {#if errorDetails.templateId}
            <div class="parsley-errors-list">{errorDetails.templateId}</div>
            {/if}
          </div>
          {#if errorDetails.apiError}
          <div class="col-md-12">
            <div class="parsley-errors-list">{errorDetails.apiError}</div>
          </div>
          {/if}
        </div>
        <!-- {#if permissions && permissions.length}
        <label class="d-block" for="name">Access Profile
          <span class="required-field">*</span></label>
        <ul class="list-group scroll-custom-access-profile scrollbar-lg ht-200 pos-relative">
          {#each permissions as permission (permission._id)}
          <li class="list-group-item d-flex align-items-center bd">
            <div class="col-sm-6 col-md-6 col-lg-6">{permission.group}</div>
            <div class="custom-control custom-switch mg-r-10">
              <input type="checkbox" class="custom-control-input ap-module-select" id="pp-{permission._id}"
                checked={permission.checked} class:parsley-error={errorDetails.permissionList} on:click={async (e)=> {
              permissions.find((item) => item._id == permission._id).checked = e.target.checked;
              }} />
              <label class="custom-control-label tx-12" for="pp-{permission._id}">{permission.name}</label>
            </div>
          </li>
          {/each}
        </ul>
        {:else if !modalFields.templateId}
        <EmptyPermissions mode="not-selected" />
        {:else}
        <EmptyPermissions mode="empty" />
        {/if} -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Cancel</button>
        <button type="button" disabled={isLoadingAddAccessProfile} on:click={addNewCustomAccessProfile}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
          <ButtonLoader isLoading={isLoadingAddAccessProfile} btnText="Proceed" />
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->

<!-- Modal Edit Access -->
<div bind:this={overrideAccessProfileModal} class="modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header">
        <h6 class="modal-title" id={'modal-' + modalEditAccess.accessProfileId}>
          Edit Access Profile
          {@html '&gt;'}
          {modalEditAccess.batchName}
          {@html '&gt;'}
          {modalEditAccess.accessProfileName}
        </h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {#if modalEditAccess.permissions && modalEditAccess.permissions.length}
        <label class="d-block rounded bg-danger tx-white pd-5" for="name">Access Profile Override</label>
        <ul class="list-group scroll-override-access-profile scrollbar-lg ht-200 pos-relative">
          {#each modalEditAccess.permissions as permission (permission._id)}
          <li class="list-group-item d-flex align-items-center bd">
            <div class="col-sm-6 col-md-6 col-lg-6">{permission.group}</div>
            <div class="custom-control custom-switch mg-r-10">
              <input type="checkbox" class="custom-control-input ap-module-select"
                id="p-{modalEditAccess.accessProfileId}-{permission._id}" checked={permission.checked}
                class:parsley-error={errorEditAccess.permission} on:click={(event)=>
              overrideAccessProfile(permission.id, event.target.checked)} />
              <label class="custom-control-label tx-12 d-inline-block"
                for="p-{modalEditAccess.accessProfileId}-{permission._id}">{permission.name}</label>
            </div>
          </li>
          {/each}
        </ul>
        {:else if !modalEditAccess.templateId}
        <EmptyPermissions mode="not-selected" />
        {:else}
        <EmptyPermissions mode="empty" />
        {/if}
        {#if errorEditAccess.apiError}
        <div class="col-md-12">
          <div class="parsley-errors-list">{errorEditAccess.apiError}</div>
        </div>
        {/if}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">
          Done
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->


<div bind:this={addNewUserModal} class="modal" id="modal-new-user" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="min-width: 1000px;overflow: auto;    height: 90%;">
    <div class="modal-content tx-14" style="height: 100%;">
      <div class="modal-header pd-b-0 pd-r-25">
        <div class="media align-items-center pd-12">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="users" class="wd-60 ht-60" /></span>
          <!-- <span class="tx-color-03 d-none d-sm-block"><i data-feather="grid" class="wd-60 ht-60"></i></span> -->
          <!-- <h3 class=" model-title pd-l-15 pd-t-2" id="exampleModalLabel6"></h3> -->
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">System User</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Create new system user</p>
          </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span><br />
        </button>
      </div>

      <div class="modal-body" style="height: 100%;overflow: hidden;">
        <div class="row" style="height: 100%;">
          <div class="col-sm-6">
            <div  class="form-row pd-t-8 pd-12" autocomplete="off">
              <div  class="form-group col-sm-12 col-md-12 col-lg-12">
                <label class="d-block" for="name">Full Name</label>
                <input type="text" class="form-control" autocomplete="off" id="name" on:keyup={(e)=> e.key === 'Enter' && submitForm1(systemUserDetails.name)}
                aria-label="Full name"
                placeholder="Enter full name"
                bind:this={firstInputField1}
                bind:value={systemUserDetails.name}
                 />
                
              </div>
              
            
              <div class="form-group col-sm-12">
                <label class="d-block">Roles</label>
                <select bind:this={elem_orgAccessProfiles} class="info slectizedd form-control" placeholder="Select Role">
                  <option value="">Select Role</option>
                </select>
                
              </div>
            
              <!-- content here -->
              <div class="form-group col-sm-12 col-md-12 col-lg-12" style="display: none;">
                <label class="d-block" for="name">User Role<span class="required-field">*</span></label>
                <div class="d-md-flex flex-wrap">
                  <div class="custom-control custom-radio col-md-4">
                    <input type="radio" id="role-admin1" bind:group={systemUserDetails1.role} class="custom-control-input"
                      value="admin" />
                    <label class="custom-control-label" for="role-admin1">Admin</label>
                  </div>
            
                  <div class="custom-control custom-radio col-md-4">
                    <input type="radio" id="role-batch-manager1" bind:group={systemUserDetails1.role} class="custom-control-input"
                      value="batchManager" />
                    <label class="custom-control-label" for="role-batch-manager1">Batch Manager</label>
                  </div>
            
                  <div class="custom-control custom-radio col-md-4">
                    <input type="radio" id="role-faculty1" bind:group={systemUserDetails1.role} class="custom-control-input"
                      value="faculty" />
                    <label class="custom-control-label" for="role-faculty1">Faculty</label>
                  </div>
            
                  <div class="custom-control custom-radio col-md-4">
                    <input type="radio" id="role-tele-caller1" bind:group={systemUserDetails1.role} class="custom-control-input"
                      value="telecaller" />
                    <label class="custom-control-label" for="role-tele-caller1">Tele
                      Caller</label>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div class="col-sm-6" style="height: 100%;">
              <div class="form-group col-sm-12 col-md-12 col-lg-12" style="    margin-bottom: 10px;">
                <label class="d-block" for="name">Select Modules for this roles</label>
                <input type="text" class="form-control" placeholder="Type to filter modules..." id="tree_search1"/>
              </div>
            <div id="jstree1" style="padding:0 15px;    overflow: auto;height: calc(100% - 80px);">
            </div>
          </div>
        </div>
       
       
      </div>
      <div class="modal-footer pd-t-0">
        <div class="pd-12">
          <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Cancel
            <i data-feather="x" class="wd-10 mg-l-5" />
          </button>
          <button type="button" disabled={isLoading} on:click={submitForm1}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <ButtonLoader {isLoading} btnText="Proceed" />
            <i data-feather="arrow-right" class="wd-10 mg-l-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal effect-scale" id="modalUpdatePassword" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content tx-14" style="height: 100%;">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span><br />
        </button>
        <h4 class="tx-18 tx-sm-20 mg-b-2">Update Password</h4>
        <p class="tx-13 tx-color-03 mg-b-0">Password updating for {systemUserDetails.name}</p>
        <hr>
        <div class="form-group">
          <span class="mb-2 d-block">Enter Password <span class="required-field">*</span></span>
          <input type="password" 
            bind:value={updatePasswordObj.password} 
            placeholder="Enter Password" 
            on:input={function (e) {
              this.value = this.value.slice(0, 8);
              updatePasswordObj.password = this.value;
            }}
            class="form-control"
          />
          {#if errorDetails.password}
            <div class="parsley-errors-list">{errorDetails.password}</div>
          {/if}
        </div>
        <div class="form-group">
          <span class="mb-2 d-block">Confirm Password <span class="required-field">*</span></span>
          <input type="password" 
            bind:value={updatePasswordObj.confirmPassword} 
            placeholder="Confirm Password" 
            class="form-control"
            on:input={function (e) {
              this.value = this.value.slice(0, 8);
              updatePasswordObj.confirmPassword = this.value;
            }}
            />
          {#if errorDetails.confirmPassword}
            <div class="parsley-errors-list">{errorDetails.confirmPassword}</div>
          {/if}
        </div>
        <button class="btn tx-13 btn-primary" on:click={updatePasswordClickHandler}><i data-feather="save" class="wd-10 mg-l-5" /> Update</button>
        <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal"  on:click={clearPasswordFields}>Cancel
          <i data-feather="x" class="wd-10 mg-l-5" />
        </button>
      </div>
    </div>
  </div>
</div>
