<script>
    import { Router, Link, Route } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import FAQ from "./faq/faq.svelte";
    import { activeMenu } from "../stores";
    export let from="";
    onMount(function(){
    console.log("mounting systemUser list");
    activeMenu.set("faq"+from);
  })
</script>

<Router>
    <Route path="" >
        <FAQ></FAQ>
    </Route>
     
    
   
</Router>
