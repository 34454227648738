<script>
  import { onMount } from "svelte";
  import { Router, Link, Route } from "svelte-routing";
  import { activeMenu } from "../stores";
  import List from "./list/index.svelte";
  import Update from "./update/index.svelte";
  import MODULES from "../utils/modules.js"
  import Forbidden from "../forbidden.svelte"
  import ArchievedList from "./archivedList/index.svelte";

  onMount(function () {
    activeMenu.set("taskmanagement");
  });
</script>

<Router>
  <Route path="edit/:id" let:params>
    {#if MODULES.hasAccess(MODULES.EDIT_TASK)}
    <Update _id={params.id} />
    {:else}
    <Forbidden />
    {/if}
  </Route>
  <Route path="archived">
    <ArchievedList></ArchievedList>
  </Route>
  <Route path="">
    <List />
  </Route>
</Router>
