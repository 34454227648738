<script>
  import { Router, Link, links, Route, navigate } from "svelte-routing";

  import { onMount } from "svelte";
  import { activeMenu } from "../../stores";
  import ButtonLoader from "../../utils/button_loader.svelte"
  import { pSuccess, pError } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import { API_URL } from "../../../config.js"


  let showModal = false;
  let testDataTable;
  let demoDataTable;
  let emptyState = false;
  let testName;
  let testType;

  let modalCmp;

  let btnDisable = false;
  let isLoading = false;
  let noPagination
  let filterModalThis;
  let filterElemBatch;
  let filterByPrefix;
  let filterElemUser;
  let filterStartDate;
  let filterQuestionLevel, filterReportType, pmarks, count, nmarks
  let elem_filterPositiveMarks, elem_filterNegativeMarks

  function hideFilterModel(params) {
    _$("#filter-modal").modal("hide");
    assignmentObj.topic = "";
  }
  function showFilterModel(params) {
    _$("#filter-modal").modal("show");
  }


  let showHideFilterModal = () => {
    _$('#filter-modal').modal('show');
  }

  let errorObj = {}

  async function api_resolveQuestionById(data) {

    let action = "questionsReported.resolveReportedQuestion";
    var response = await api_handleApiCalls(action, data);
    return response.data;

  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.seqId = order;
    } else if (sortOrder.column === 2) {
      sort.aQuestion = order;
    } else if (sortOrder.column === 3) {
      sort.questionCount = order;
    } else {
      sort._id = order;
    }
    return sort;
  }
  function bindViewActions() {
    _$('.btn-view-question').off('click');
    _$('.btn-view-question').unbind('click').click(function (e) {
      e.preventDefault();
      var reportedQuestionsId = _$(this).attr("question_id");

      navigate(("questionsreported/edit/" + reportedQuestionsId))

    })
    _$('.btn-view-display').off('click');
    _$('.btn-view-display').click(function () {

      var reportedQuestionsId = _$(this).attr("data-id");

      navigate(("questionsreported/edit/" + reportedQuestionsId))

    })
  }
  const bindDeleteAction = () => {
    _$('.btn-delete-question').off('click');
    _$('.btn-delete-question').unbind('click').click(function (e) {
      e.preventDefault();

      var reportedId = _$(this).attr("question_id");
      const notice = PNotify.notice({
        title: "Resolve reported question",
        text: "Are you sure you want to resolve this question ?",
        hide: false,
        closer: false,
        sticker: false,
        modules: new Map([
          ...PNotify.defaultModules,
          [PNotifyConfirm, { confirm: true }],
        ]),
      })


      notice.on("pnotify:confirm", async () => {
        try {
          var resolvequestion = await api_resolveQuestionById({ _id: reportedId });
          if (resolvequestion) {
            PNotify.success({
              title: 'Success',
              text: "Question resolved successfully",
              delay: 3000
            })
          } else {
            PNotify.error({
              title: 'Error',
              text: resolvequestion.errMsg,
              delay: 3000
            })
          }

        } catch (error) {
          console.error(error);
        }
        testDataTable.ajax.reload();
      })

    })
  }
  function bindDatatable() {
    testDataTable = _$(demoDataTable).DataTable({
      responsive: true,
      language: {
        processing: "Loading...",
        searchPlaceholder: 'ID | Report Type',
        sSearch: '',
        lengthMenu: '_MENU_ Questions reported/page',
      },
      ajax: function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        var filterObj = { ...getFilterData() }
        filterObj.start = data.start
        filterObj.length = data.length
        filterObj.search = data.search.value
        filterObj.draw = data.draw
        filterObj.sort = data.sort;
        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: 'questionsReported.fetchReportedQuestionsList',
            accessToken: localStorage.getItem("accessToken"),
            data: filterObj
          },
          success: function (resData) {
            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.recordsTotal),
                recordsFiltered: Number(paginationData.recordsFiltered),
                data: paginationData.data
              }
              if (!resData.data.data.length) {
                emptyState = true
              }
              callback(result);
            }
            else {
              console.log("Something went wrong");
              emptyState = true
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },
      columns: [
        {
          data: "aQuestion", render: function (data, type, full) {

            var htmlStr
            if (full.aQuestion.qId) {

              htmlStr = "#" + (full.aQuestion.qId).toString()
              return htmlStr
            } else {
              return "#" + full.aQuestion.qId;
            }

          }
        },
        {
          data: "aQuestion", render: function (data, type, full) {
            let question = data.data.question[data.languages[0].title];
            question = _$('<p>').html(question).find('img').css("width", "85px").end().html();
            return `<div style="max-width:600px; " class="btn-view-display" data-id='${full.report_id}'>
                 ${question}
              </div>`
          }
        },
        {
          data: "_id", render: function (data, type, full) {

            return `<div style="max-width:400px; max-height: 38px !important; overflow:hidden;" class="btn-view-display" data-id='${full.report_id}'>
                 ${full.reportType.reportType}
              </div>`
          }
        },
        {
          data: "questionCount", render: function (data, type, full) {

            return `<div style="text-align: center;">
              ${data}
              </div>`;

          }
        },

        {
          data: '_id', render: function (data, type, full) {
            return `
            <div class="d-md-flex flex-row justify-content-center mg-2">
            <button class="btn btn-xs btn-primary mg-r-5 btn-view-question" question_id="${full.report_id}">VIEW</button>
            <button class="btn btn-xs btn-primary btn-delete-question" question_id="${data}">RESOLVE</button>
            </div>
            `;
          }
        },
      ],
      "drawCallback": function () {
        feather.replace()
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);

        bindViewActions()
        bindDeleteAction()
        testDataTable.on('responsive-resize.dt', function (e, datatable, columns) {
          var count = columns.reduce(function (a, b) {
            return b === false ? a + 1 : a;
          }, 0);
          bindViewActions()
          bindDeleteAction()
        });
        testDataTable.on('responsive-display.dt', function (e, datatable, row, showHide, update) {
          bindViewActions()
          bindDeleteAction()
        });

      },
      "processing": true,
      "serverSide": true,
    });
    _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
  }



  onMount(async () => {
    feather.replace();
    activeMenu.set('questionsreported');
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    bindFilterSelect()
    bindDatatable()
    initCleave()
  })
  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var dateString = [year, month, day].join("-");
    return dateString;
  }

  async function bindFilterSelect() {


    _$(filterQuestionLevel).selectize({
      maxItems: 1,
      onChange: function (value) {
        filter.level = value == "all" ? "" : value;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        _$(demoDataTable).DataTable().ajax.reload(null, false);
      }
    });
    let reportTypeList = await api_getReportTypeList();


    _$(filterReportType).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "reportType",
      searchField: "reportType",
      placeholder: "select batches",
      delimiter: ",",
      persist: false,
      options: reportTypeList,
      maxItems: 1,
      onChange: function (value) {

      }
    });
  }

  async function api_getReportTypeList() {

    let action = "questionsReported.fetchReportedQuestionsTypeList";
    var response = await api_handleApiCalls(action, {});
    return response.data.data;

  }


  function getFilterData() {
    let reportTypeId = _$(filterReportType).val()
    let level = _$(filterQuestionLevel).val()


    // submissionDateFrom = dateFrom
    // submissionDateTo  = dateTo
    return {
      reportTypeId,
      level,
      pmarks,
      nmarks,
      count,

    }
  }
  function clearFilter() {
    _$(filterReportType).val("")
    _$(filterQuestionLevel).val(""),

      pmarks = "",
      nmarks = "",
      count = ""
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);

    redrawQuestionTable();

  }
  function applyFilter() {
    redrawQuestionTable();
  }
  function redrawQuestionTable() {
    if (testDataTable) { testDataTable.draw() }
  }


  function initCleave() {
    let pMarksFilterEle = new Cleave(elem_filterPositiveMarks, {
      numeral: true,
      numeralPositiveOnly: true,
      rawValueTrimPrefix: true,
      numeralThousandsGroupStyle: 'none',
      numeralDecimalScale: 0,
      onValueChanged: function (e) {
        if (e.target.rawValue == "") {
          e.target.rawValue = "";
        } else e.target.rawValue = Number(e.target.rawValue);
        pmarks = e.target.rawValue;
      }
    });
    pMarksFilterEle.setRawValue(pmarks);
    let nMarksFilterEle = new Cleave(elem_filterNegativeMarks, {
      numeral: true,
      numeralPositiveOnly: true,
      rawValueTrimPrefix: true,
      numeralThousandsGroupStyle: 'none',
      numeralDecimalScale: 0,
      onValueChanged: function (e) {
        if (e.target.rawValue == "") {
          e.target.rawValue = "";
        } else e.target.rawValue = Number(e.target.rawValue);
        nmarks = e.target.rawValue;
      }
    });
    nMarksFilterEle.setRawValue(nmarks);

  }
</script>

<div class="content-body fadeIn">
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Questions Reported
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        Questions Reported
      </h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search reported questions. </p>
    </div>

    <div class="d-block">

      <button on:click={showHideFilterModal} class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"> <i
          data-feather="filter" class="mg-r-5"></i> Filters</button>

    </div>

  </div>
  <hr class="full-width">
  <!-- <hr class="mg-y-20"> -->
  <div data-label="Example" class="df-example ">

    <table bind:this={demoDataTable} class="table table-hover table-sm" style="width:100%">
      <thead>
        <tr>
          <th class="wd-10p">ID</th>
          <th class="wd-40p">Questions</th>
          <th class="wd-30p">Reported Type</th>

          <th class="wd-10p"> Count</th>
          <!-- <th>Report Type</th> -->
          <th class="wd-10p">Action</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
  <!-- <div class="d-flex justify-content-center">
        {#if emptyState }
        <div class="empty-state-img wd-200">
          <img src="/images/empty.svg" style="width: 200px" alt="empty" />
          <p class="tx-bold">No reported questions found</p>
        </div>
        {/if}
      </div> -->
</div>

<!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->

      <hr class="full-width">
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

        <div class="form-group">
          <label class="d-block">Question level:</label>
          <select id="qType" name="qTYpe" type="text" class="slectizedd info form-control"
            bind:this={filterQuestionLevel}>
            <option value="">Select Level</option>
            <option value="all">All Levels</option>
            <option value="easy">Easy</option>
            <option value="mid">Medium</option>
            <option value="hard">Hard</option>
          </select>
        </div>
        <!-- <div class="form-group">
          <label class="d-block">Studen name:</label>
          <input type="text" class="form-control follow-up-date" placeholder="Enter studen name"/>
        </div> -->
        <div class="form-group">
          <label class="d-block">Report Type:</label>
          <select disabled={isLoading} class="form-control select2" bind:this="{filterReportType}">
            <option value="">Select Type</option>
          </select>
        </div>
        <div class="form-group">
          <label class="d-block">Count:</label>
          <input type="number" class="form-control follow-up-date" placeholder="Enter Count" bind:value={count} />
        </div>
        <div class="form-group row">
          <div class="col-sm-6">
            <label class="d-block">Positive Mark:</label>
            <input type="number" class="form-control follow-up-date" placeholder="Enter Positive Mark"
              bind:this={elem_filterPositiveMarks} />
          </div>
          <div class="col-sm-6">
            <label class="d-block">Negative Mark:</label>
            <input type="number" class="form-control follow-up-date" placeholder="Enter Negative Mark"
              bind:this={elem_filterNegativeMarks} />
          </div>
        </div>



        <div class="d-md-flex justify-content-end mg-t-25">
          <button disabled={isLoading} class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal"
            on:click="{clearFilter}">CLEAR
            <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button disabled={isLoading} on:click="{applyFilter}" class="btn btn-sm pd-x-15 btn-primary mg-l-5"
            data-dismiss="modal">
            <ButtonLoader isLoading={isLoading} btnText="APPLY" />
            <i data-feather="filter" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Modal -->
