<script>
  import { Router, Link, links, Route, navigate } from "svelte-routing";
  import { afterUpdate, onMount, tick } from "svelte";
  import FolderModal from "../folderCmp/folderModal.svelte";
  import ButtonLoader from "../utils/button_loader.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import { hasOptedForPoints } from "../../orgAccessControl";
  import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
  import { checkForBrokenLink } from "./utils/utils";
  import { userData } from "../stores";
  import {
    CBSE,
    MAHARASHTRA_BOARD,
    CLASS_3,
    CLASS_4,
    CLASS_5,
    CLASS_6,
    CLASS_7,
    CLASS_8,
    CLASS_9,
    CLASS_10,
    BACKEND_URL,
  } from "../utils/constants.js";
  import { getClassKey } from "../utils/classMapping.js";
  export let testObj;
  export let errorObj = {};
  export let updateParent;
  let loadedOnce = false;

  let preSelectId = [];
  let selectedStudents = [];

  let elemSelectStudent;
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  };

  let emailLoading = false;

  let boardIds = [];
  let classIds = [];
  let subjectIds = [];
  let chapterIds = [];

  let boardOptions = [CBSE, MAHARASHTRA_BOARD];
  let classOptions = [
    CLASS_3,
    CLASS_4,
    CLASS_5,
    CLASS_6,
    CLASS_7,
    CLASS_8,
    CLASS_9,
    CLASS_10,
  ];
  let selectedBoard = null;
  let selectedClass = null;
  let selectedSubjectIds = [];
  let selectedChapterIds = [];
  let loadingSubjects = false;
  let loadingChapters = false;

  // Ensure subjects and chapters are empty initially
  let subjects = [];
  let chapters = [];

  async function fetchBoardData() {
    // Replace with your actual API call to fetch board data
    let response = await api_handleApiCalls("getBoardData", {});
    if (response.status) {
      boardIds = response.data.map((board) => board.id);
    }
  }

  async function fetchClassData() {
    // Replace with your actual API call to fetch class data
    let response = await api_handleApiCalls("getClassData", {});
    if (response.status) {
      classIds = response.data.map((cls) => cls.id);
    }
  }

  async function fetchSubjectData() {
    if (!selectedBoard || !selectedClass) {
      subjects = [];
      chapters = [];
      selectedSubjectIds = [];
      return;
    }

    loadingSubjects = true;
    const classKey = getClassKey(selectedClass);
    const url = `${BACKEND_URL}/apti-booster/admin/subject/?standard=${classKey}&board=${selectedBoard}`;
    try {
      let response = await fetch(url);
      if (response.ok) {
        let data = await response.json();
        subjects = data.data || [];

        // Preserve selections that still exist in new subject list
        selectedSubjectIds = selectedSubjectIds.filter((id) =>
          subjects.some((subj) => subj._id === id),
        );
      }
    } finally {
      loadingSubjects = false;
      await tick();
      initializeSelectize();
      // Fetch chapters after subjects are loaded
      await fetchChapterData();
    }
  }

  async function fetchChapterData() {
    if (!selectedSubjectIds.length) {
      chapters = [];
      loadingChapters = false;
      await tick();
      initializeChapterSelectize();
      return;
    }

    loadingChapters = true;
    const url = `${BACKEND_URL}/apti-booster/admin/chapter/?subjectIds=${selectedSubjectIds.join(",")}`;

    try {
      let response = await fetch(url);
      if (response.ok) {
        let data = await response.json();
        chapters =
          data.data?.filter((chapter) =>
            selectedSubjectIds.includes(chapter.subjectId),
          ) || [];

        // Preserve existing chapter selections that are still valid
        selectedChapterIds = selectedChapterIds.filter((id) =>
          chapters.some((chap) => chap._id === id),
        );
      }
    } finally {
      loadingChapters = false;
      await tick();
      initializeChapterSelectize();
    }
  }

  async function assignTestToAptiBooster() {
    const url = `${BACKEND_URL}/apti-booster/admin/test/assign-to-apti-booster/`;
    const payload = {
      testId: testObj._id,
      boardIds: [selectedBoard],
      classIds: [getClassKey(selectedClass)],
      subjectIds: selectedSubjectIds,
      chapterIds: selectedChapterIds,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        pSuccess("Success", "Test assigned to Apti Booster successfully");
      } else {
        pError("Error", data.errMsg || data.message || "Failed to assign test");
      }
    } catch (error) {
      console.error("API Error:", error);
      pError("Error", "Connection failed");
    }
  }

  async function api_getChannelList(data) {
    let action = "chat.getChannelList";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getStudentsList(data) {
    let action = "user.getAllUsers";
    data = {
      start: 0,
      length: 10,
      search: data.search,
    };
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getBatchList() {
    let action = "batch.getMyBatches";
    var response = await api_handleApiCalls(action, {});
    return response;
  }

  async function api_getOptionList(data) {
    let action = "dropdown.getOptionsForIds";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  function loadBatchesOptions(data, preSelectId) {
    var oldSelected = [];

    let selectizeElement =
      _$("select#assignBatch") &&
      _$("select#assignBatch")[0] &&
      _$("select#assignBatch")[0].selectize;
    if (selectizeElement) {
      oldSelected =
        selectizeElement.getValue() == "" ? [] : selectizeElement.getValue();
      selectizeElement.destroy();
    }

    preSelectId = preSelectId ? preSelectId : [];
    var batchSelectizeEle = _$(_$("#assignBatch")[0]).selectize({
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      plugins: ["remove_button"],
      maxItems: 100,
      options: data,
      create: false,
      onChange: function (values) {
        testObj.batchArr = values;
      },
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create"><i class="icon icon-plus" style="font-size:14px;"></i><i style="font-size:12px;">Add New Language with name : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
    });
    selectizeElement =
      batchSelectizeEle &&
      batchSelectizeEle[0] &&
      batchSelectizeEle[0].selectize;
    if (data[0] && selectizeElement) {
      selectizeElement.setValue(data[0].value);
    }

    if (preSelectId) {
      if (Array.isArray(preSelectId)) {
        for (var i = 0; i < preSelectId.length; i++) {
          oldSelected.push(preSelectId[i]);
        }
      } else {
        oldSelected.push(preSelectId);
      }
      if (selectizeElement) selectizeElement.setValue(oldSelected, false);
    }
    _$("select#assignBatch").trigger("change");
    if (testObj.publish) {
      if (selectizeElement) selectizeElement.disable();
    } else {
      if (selectizeElement) selectizeElement.enable();
    }
  }

  function loadChannelsOptions(data, preSelectId) {
    var oldSelected = [];
    let selectizeElement =
      _$("select#assignChannel") &&
      _$("select#assignChannel")[0] &&
      _$("select#assignChannel")[0].selectize;
    if (selectizeElement) {
      oldSelected =
        selectizeElement.getValue() == "" ? [] : selectizeElement.getValue();
      selectizeElement.destroy();
    }

    preSelectId = preSelectId ? preSelectId : [];
    var channelSelectizeEle = _$(_$("#assignChannel")[0]).selectize({
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      plugins: ["remove_button"],
      maxItems: 100,
      options: data,
      create: false,
      onChange: function (values) {
        testObj.groupsLinkArr = values;
      },
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create"><i class="icon icon-plus" style="font-size:14px;"></i><i style="font-size:12px;">Add New Language with name : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
    });
    selectizeElement =
      channelSelectizeEle &&
      channelSelectizeEle[0] &&
      channelSelectizeEle[0].selectize;
    if (data[0] && selectizeElement) {
      channelSelectizeEle[0].selectize.setValue(data[0].value);
    }

    if (preSelectId) {
      if (Array.isArray(preSelectId)) {
        for (var i = 0; i < preSelectId.length; i++) {
          oldSelected.push(preSelectId[i]);
        }
      } else {
        oldSelected.push(preSelectId);
      }
      if (selectizeElement) selectizeElement.setValue(oldSelected, false);
    }
    _$("select#assignChannel").trigger("change");
    if (testObj.publish) {
      if (selectizeElement) selectizeElement.disable();
    } else {
      if (selectizeElement) selectizeElement.enable();
    }
  }

  let assignStudent = async function (e) {
    await tick();
    let students = [];

    let studentOptionsRes = await api_getOptionList({
      collection: "users",
      nameField: "name",
      values: testObj.studentsLinkArr,
    });

    let selectizeElement =
      _$("select#assignStudent") &&
      _$("select#assignStudent")[0] &&
      _$("select#assignStudent")[0].selectize;
    if (selectizeElement) {
      let oldSelected =
        selectizeElement.getValue() == "" ? [] : selectizeElement.getValue();
      selectizeElement.destroy();
    }

    var studentSelectizeEle = _$("select#assignStudent").selectize({
      plugins: ["remove_button"],
      maxItems: 100,
      options: studentOptionsRes.data,
      create: false,
      preload: true,
      valueField: "_id",
      items:
        Array.isArray(studentOptionsRes.data) && studentOptionsRes.data.length
          ? studentOptionsRes.data.map((e) => e._id)
          : [],
      labelField: "name",
      searchField: "name",
      placeholder: "Select Users",
      onChange: function (values) {
        testObj.studentsLinkArr = values;
      },
      load: async function (query, callback) {
        let result = await api_getStudentsList({ search: query });
        students = [];
        selectedStudents = [];
        if (result.status) {
          result = result.data.data;
          students = result.filter((student) => {
            if (!testObj.studentsLinkArr) testObj.studentsLinkArr = [];
            if (
              !testObj.studentsLinkArr.find(
                (item) => item && item._id == student._id,
              )
            ) {
              return student;
            }
          });
        }
        callback(students);
      },
    });
    selectizeElement =
      _$("select#assignStudent") &&
      _$("select#assignStudent")[0] &&
      _$("select#assignStudent")[0].selectize;
    if (testObj.publish) {
      if (selectizeElement) selectizeElement.disable();
    } else {
      if (selectizeElement) selectizeElement.enable();
    }
  };

  let loadBatchList = async function () {
    let resBatchList = await api_getBatchList();
    if (resBatchList.status) {
      loadBatchesOptions(resBatchList.data, testObj.batchArr);
    } else {
      console.log("error ", resBatchList.errMsg);
    }
  };

  let loadChannels = async function () {
    let resChannelList = await api_getChannelList();
    if (resChannelList.status) {
      loadChannelsOptions(resChannelList.data.data, testObj.groupsLinkArr);
    } else {
      console.log("error ", resChannelList.errMsg);
    }
  };

  let showModal = false;
  let closeStudyMaterialModal = async function (event) {
    _$(".modal-backdrop").remove();
    showModal = !showModal;
    if (!(Array.isArray(testObj.linkArr) && testObj.linkArr.length))
      testObj.linkArr = [];
    let isDuplicate = testObj.linkArr.find(
      (linkItem) =>
        linkItem.fullPathId.map((folder) => folder.id).join("") ==
        event.detail.selectedFolder.map((folder) => folder.id).join(""),
    );
    if (!isDuplicate) {
      if (!event.detail.selectedFolder[0]) return;
      let batchName = event.detail.selectedFolder[0].name;
      let fullPath = [];
      for (let i = 1; i < event.detail.selectedFolder.length; i++) {
        const aFolder = event.detail.selectedFolder[i];
        fullPath.push(aFolder.name);
        if (i == event.detail.selectedFolder.length - 1) {
          let folderIndex = event.detail.selectedFolder.length - 1;
          if (
            event.detail.selectedFolder[folderIndex] &&
            event.detail.selectedFolder[folderIndex].id
          ) {
            if (!Array.isArray(testObj.practiceTestParentId)) {
              testObj.practiceTestParentId = [];
            }
            testObj.practiceTestParentId.push(
              event.detail.selectedFolder[folderIndex].id,
            );
          }
        }
      }
      testObj.linkArr.push({
        batchName: batchName,
        batchId: event.detail.selectedFolder[0].id,
        fullPathId: event.detail.selectedFolder,
        fullPath: fullPath,
      });
      testObj.linkArr = testObj.linkArr;
    } else {
      pError("", "This Folder is already linked");
    }

    await tick();
    feather.replace();
  };
  let onCloseModal = async function (event) {
    // if (selectedLinkType == "study_material") {
    closeStudyMaterialModal(event);
    // }
  };
  let prevPublishState = false;
  afterUpdate(async function () {
    if (testObj.loaded && !loadedOnce) {
      loadedOnce = true;
      testObj.menu = "assign";
      testObj.isAssignPoints =
        testObj.isAssignPoints || (isPoints ? "yes" : "no");
      // testObj.isSelfCompetition = testObj.isSelfCompetition || "no";
      testObj.isPracticeTest = testObj.isPracticeTest || "no";
      testObj.practiceTestParentId = testObj.practiceTestParentId || [];
      if (!(testObj.linkArr && Array.isArray(testObj.linkArr))) {
        testObj.linkArr = [];
      }
      updateParent();
      feather.replace();
      // testObj = {
      //     ...testObj,
      //     linkArr: testObj.batchArr || [],
      //     groupsLinkArr: testObj.groupsLinkArr || [],
      //     studentsLinkArr: testObj.studentsLinkArr || [],
      // }
    } else feather.replace();
  });

  $: testObj.isPracticeTest &&
    (() => {
      if (
        testObj.isPracticeTest &&
        testObj.isPracticeTest.toLowerCase() == "no"
      ) {
        assignStudent();
        loadBatchList();
        loadChannels();

        if (testObj.publish != prevPublishState) {
          prevPublishState = testObj.publish;
          if (testObj.publish) {
            if (
              _$("select#assignBatch") &&
              _$("select#assignBatch")[0] &&
              _$("select#assignBatch")[0].selectize &&
              _$("select#assignBatch")[0].selectize.disable
            )
              _$("select#assignBatch")[0].selectize.disable();
            if (
              _$("select#assignChannel") &&
              _$("select#assignChannel")[0] &&
              _$("select#assignChannel")[0].selectize &&
              _$("select#assignChannel")[0].selectize.disable
            )
              _$("select#assignChannel")[0].selectize.disable();
            if (
              _$("select#assignStudent") &&
              _$("select#assignStudent")[0] &&
              _$("select#assignStudent")[0].selectize &&
              _$("select#assignStudent")[0].selectize.disable
            )
              _$("select#assignStudent")[0].selectize.disable();
          } else {
            if (
              _$("select#assignBatch") &&
              _$("select#assignBatch")[0] &&
              _$("select#assignBatch")[0].selectize &&
              _$("select#assignBatch")[0].selectize.enable
            )
              _$("select#assignBatch")[0].selectize.enable();
            if (
              _$("select#assignChannel") &&
              _$("select#assignChannel")[0] &&
              _$("select#assignChannel")[0].selectize &&
              _$("select#assignChannel")[0].selectize.enable
            )
              _$("select#assignChannel")[0].selectize.enable();
            if (
              _$("select#assignStudent") &&
              _$("select#assignStudent")[0] &&
              _$("select#assignStudent")[0].selectize &&
              _$("select#assignStudent")[0].selectize.enable
            )
              _$("select#assignStudent")[0].selectize.enable();
          }
        }
      } /* else {
        showModal = true;
      } */
    })();

  function testPracticeStatuChanged() {
    checkShowModal();
    if (testObj.isPracticeTest == "yes") {
      clearBatchLinkArr();
      clearGroupLinkArr();
      clearStudentLinkArr();
    }
    if (testObj.isPracticeTest == "no") {
      clearLinkArr();
    }
  }
  function checkShowModal() {
    showModal = !(testObj && testObj.practiceTestParentId);
  }
  function clearBatchLinkArr() {
    testObj.batchArr = [];
    updateParent();
  }
  function clearGroupLinkArr() {
    testObj.groupsLinkArr = [];
    updateParent();
  }
  function clearStudentLinkArr() {
    testObj.studentsLinkArr = [];
    updateParent();
  }
  function removeFromLinkArrByIndex(index) {
    testObj.practiceTestParentId.splice(index, 1);
    testObj.linkArr.splice(index, 1);
    testObj.linkArr = testObj.linkArr;
    testObj.practiceTestParentId = testObj.practiceTestParentId;
    updateParent();
  }
  function clearLinkArr() {
    testObj.linkArr = [];
    testObj.practiceTestParentId = [];
    updateParent();
  }
  let isPoints = false;
  const unsubscribe = userData.subscribe(async (value) => {
    if (hasOptedForPoints(value)) {
      let pointsConfig = await api_handleApiCalls("loyaltyPoints.getConfig");
      if (pointsConfig.status) {
        isPoints = pointsConfig.data.ALLOW_LOYALTY_POINTS;
      }
    }
  });
  onMount(async function () {
    testObj.menu = "assign";
    testObj.isPublish = true;
    testObj.isAssignPoints =
      testObj.isAssignPoints || (isPoints ? "yes" : "no");
    // testObj.isSelfCompetition = testObj.isSelfCompetition || "no";
    testObj.isPracticeTest = testObj.isPracticeTest || "no";
    testObj.practiceTestParentId = testObj.practiceTestParentId || [];
    if (!(testObj.linkArr && Array.isArray(testObj.linkArr))) {
      testObj.linkArr = [];
    }

    feather.replace();
    updateParent();
    await fetchSubjectData();
    await fetchChapterData();
    unsubscribe();
  });

  onMount(() => {
    if (
      testObj.isPracticeTest === "apti-booster" &&
      selectedBoard &&
      selectedClass
    ) {
      fetchSubjectData();
    }
  });

  async function sendMail() {
    emailLoading = true;
    if (
      !(
        testObj.batchArr.length ||
        testObj.groupsLinkArr.length ||
        testObj.studentsLinkArr.length
      )
    ) {
      errorObj.mailError =
        "Select batches or groups or students to send the test link via email.";
      return;
    } else {
      errorObj = {};
    }
    let action = "test.generateTestLinkToMail";
    var response = await api_handleApiCalls(action, {
      testId: testObj._id,
      userIds: testObj.studentsLinkArr,
      batchIds: testObj.batchArr,
      groupIds: testObj.groupsLinkArr,
    });
    emailLoading = false;
  }

  const btnHandlerFixBrokenLinks = async () => {
    if (testObj.linkArr) {
      for (let idx = 0; idx < testObj.linkArr.length; idx++) {
        const obj = testObj.linkArr[idx];
        if (obj.fullPathId) {
          let parentFolder = obj.fullPathId[obj.fullPathId.length - 1];
          if (
            parentFolder &&
            testObj.practiceTestParentId.indexOf(
              obj.fullPathId[obj.fullPathId.length - 1].id,
            ) == -1
          ) {
            testObj.practiceTestParentId.push(
              obj.fullPathId[obj.fullPathId.length - 1].id,
            );
          }
        }
      }
    }

    testObj.practiceTestParentId = testObj.practiceTestParentId;
    updateParent();
    await tick();

    pSuccess("Success", "Links updated, please save the changes");
  };

  // Utility function to completely reset a selectize field
  async function resetSelectizeField(selectId) {
    await tick();

    // Get the container div
    const containerDiv = _$(`#${selectId}Container`);

    // Clear the container
    containerDiv.empty();

    // Create a new select element with the same ID
    const newSelect = _$("<select></select>")
      .attr("id", selectId)
      .addClass("info form-control");

    // Append the new select element to the container
    containerDiv.append(newSelect);

    return newSelect;
  }

  async function initializeSelectize() {
    // Completely reset the subject select field
    const subjectSelect = await resetSelectizeField("subjectSelect");

    // Add placeholder option
    subjectSelect.append('<option value="" disabled>Select Subjects</option>');

    // Add subject options
    subjects.forEach((subject) => {
      subjectSelect.append(
        `<option value="${subject._id}">${subject.name}</option>`,
      );
    });

    // Initialize new selectize
    const subjectSelectize = subjectSelect.selectize({
      plugins: ["remove_button"],
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      maxItems: null, // Allow multiple selections
      options: subjects,
      create: false,
      placeholder: "Select Subjects",
      onChange: function (values) {
        selectedSubjectIds = values;
        // Reset chapter selection when subjects change
        chapters = [];
        // Trigger fetching chapters for the selected subjects
        fetchChapterData();
      },
    });

    // Set previously selected values if any
    if (selectedSubjectIds && selectedSubjectIds.length > 0) {
      const selectizeControl = subjectSelectize[0].selectize;
      if (selectizeControl) {
        selectizeControl.setValue(selectedSubjectIds);
      }
    }

    // Disable if published
    if (testObj.publish) {
      const selectizeControl = subjectSelectize[0].selectize;
      if (selectizeControl) {
        selectizeControl.disable();
      }
    }
  }

  async function initializeChapterSelectize() {
    // Completely reset the chapter select field
    const chapterSelect = await resetSelectizeField("chapterSelect");

    // Add placeholder option
    chapterSelect.append('<option value="" disabled>Select Chapters</option>');

    // Add chapter options
    chapters.forEach((chapter) => {
      chapterSelect.append(
        `<option value="${chapter._id}">${chapter.name}</option>`,
      );
    });

    // Initialize new selectize
    const chapterSelectize = chapterSelect.selectize({
      plugins: ["remove_button"],
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      maxItems: null, // Allow multiple selections
      options: chapters,
      create: false,
      placeholder: "Select Chapters",
      onChange: function (values) {
        selectedChapterIds = values;
      },
    });

    // Set previously selected values if any
    if (selectedChapterIds && selectedChapterIds.length > 0) {
      const selectizeControl = chapterSelectize[0].selectize;
      if (selectizeControl) {
        selectizeControl.setValue(selectedChapterIds);
      }
    }

    // Disable if published
    if (testObj.publish) {
      const selectizeControl = chapterSelectize[0].selectize;
      if (selectizeControl) {
        selectizeControl.disable();
      }
    }
  }

  // Initialize selectize components after board and class are selected
  $: if (selectedBoard && selectedClass) {
    fetchSubjectData();
  }

  // Save selected data in testObj
  function saveSelections() {
    testObj.selectedBoard = selectedBoard;
    testObj.selectedClass = selectedClass;
    testObj.selectedSubjectIds = selectedSubjectIds;
    testObj.selectedChapterIds = selectedChapterIds;
  }

  // Call saveSelections whenever selections change
  $: if (
    selectedBoard ||
    selectedClass ||
    selectedSubjectIds.length ||
    selectedChapterIds.length
  ) {
    saveSelections();
  }

  // Restore selections from testObj if available
  onMount(() => {
    if (testObj.selectedBoard) selectedBoard = testObj.selectedBoard;
    if (testObj.selectedClass) selectedClass = testObj.selectedClass;
    if (testObj.selectedSubjectIds)
      selectedSubjectIds = testObj.selectedSubjectIds;
    if (testObj.selectedChapterIds)
      selectedChapterIds = testObj.selectedChapterIds;
  });
</script>

<div class="fadeIn" style="display: flex;flex-direction: column;">
  <div style="width: 1;border-bottom: 1px solid #e5e8ee; display: flex;">
    <div
      style="display: flex;align-items: center;justify-content: center;margin-left: 20px;"
      class="colored-svg"
    >
      <i data-feather="link-2" class="wd-40" />
    </div>
    <div class="media-body mg-l-10 pd-y-10">
      <h6 class="mg-b-2 tx-16">Assign</h6>
      {#if errorObj.batchArr || errorObj.groupsLinkArr || errorObj.studentsLinkArr || errorObj.linkArr}
        <span
          class="parsley-errors-list"
          class:d-none={!(
            errorObj.batchArr ||
            errorObj.groupsLinkArr ||
            errorObj.studentsLinkArr ||
            errorObj.linkArr
          )}
        >
          {#if errorObj.linkArr}
            Please select folder for practice test
          {:else}
            Please assign either study material(s) or group(s) or student(s) to
            test
          {/if}
        </span>
      {:else}
        <span class="d-block tx-11 tx-color-03">Batch, student, etc</span>
      {/if}
    </div>
    <div class="pd-y-10">
      <button
        class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-r-10"
        on:click={() => {
          updateParent(true);
          navigate("/dashboard/tests/view/" + testObj._id + "/questionList");
        }}
      >
        <i data-feather="chevron-left" class="wd-10 mg-r-5" />
        Prev
      </button>
      <button
        class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-r-10"
        on:click={() => {
          updateParent(true);
          navigate("/dashboard/tests/view/" + testObj._id + "/notifications");
        }}
      >
        Next
        <i data-feather="chevron-right" class="wd-10 mg-l-5" />
      </button>
    </div>
  </div>

  {#if isPoints}
    <div class="d-block col pd-20">
      <div class="form-row mg-b-10">
        <label for="is-assign-points" class="col-sm-4 col-lg-3 mb-0 mg-r-0">
          Assign Points <span class="required-field">*</span>
        </label>
        <div class="form-group col-sm-4 col-lg-2 mb-0 mg-r-0">
          <div class="custom-control custom-radio">
            <input
              type="radio"
              id="assignPointsYes"
              name="customRadioAssignPoints"
              class="custom-control-input"
              disabled={testObj.publish}
              bind:group={testObj.isAssignPoints}
              value="yes"
            />
            <label class="custom-control-label" for="assignPointsYes">Yes</label
            >
          </div>
        </div>
        <div class="form-group col-sm-4 col-lg-2 mb-0 mg-r-0">
          <div class="custom-control custom-radio">
            <input
              type="radio"
              id="assignPointsNo"
              name="customRadioAssignPoints"
              class="custom-control-input"
              disabled={testObj.publish}
              bind:group={testObj.isAssignPoints}
              value="no"
            />
            <label class="custom-control-label" for="assignPointsNo">No</label>
          </div>
        </div>
      </div>
      <!-- {#if isPoints && testObj.isAssignPoints == "yes"}
    <div class="form-row mg-b-10 fadeIn">
      <label for="is-practice-test" class="col-sm-4 col-lg-3 mb-0 mg-r-0">
        Is Self Competition <span class="required-field">*</span>
      </label>
      <div class="form-group col-sm-4 col-lg-2 mb-0 mg-r-0">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="selfCompetitionYes"
            name="customRadioSelfCompetition"
            class="custom-control-input"
            disabled={testObj.publish}
            bind:group={testObj.isSelfCompetition}
            value="yes"
          />
          <label class="custom-control-label" for="selfCompetitionYes">Yes</label
          >
        </div>
      </div>
      <div class="form-group col-sm-4 col-lg-2 mb-0 mg-r-0">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="selfCompetitionNo"
            name="customRadioSelfCompetition"
            class="custom-control-input"
            disabled={testObj.publish}
            bind:group={testObj.isSelfCompetition}
            value="no"
          />
          <label class="custom-control-label" for="selfCompetitionNo">No</label>
        </div>
      </div>
    </div>
    {/if} -->
    </div>
  {/if}
  <div class="d-block col pd-20">
    <div class="form-row mg-t-20">
      <label for="is-practice-test" class="col-sm-4 col-lg-3 mb-0 mg-r-0">
        Is Practice Test <span class="required-field">*</span>
      </label>
      <div class="form-group col-sm-4 col-lg-2 mb-0 mg-r-0">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="practiceTestYes"
            name="customRadioPracticeTest"
            class="custom-control-input"
            disabled={testObj.publish}
            bind:group={testObj.isPracticeTest}
            on:change={testPracticeStatuChanged}
            value="yes"
          />
          <label class="custom-control-label" for="practiceTestYes">Yes</label>
        </div>
      </div>
      <div class="form-group col-sm-4 col-lg-2 mb-0 mg-r-0">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="practiceTestNo"
            name="customRadioPracticeTest"
            class="custom-control-input"
            disabled={testObj.publish}
            bind:group={testObj.isPracticeTest}
            on:change={testPracticeStatuChanged}
            value="no"
          />
          <label class="custom-control-label" for="practiceTestNo">No</label>
        </div>
      </div>
      <div class="form-group col-sm-4 col-lg-2 mb-0 mg-r-0">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="practiceTestAptiBooster"
            name="customRadioPracticeTest"
            class="custom-control-input"
            disabled={testObj.publish}
            bind:group={testObj.isPracticeTest}
            on:change={testPracticeStatuChanged}
            value="apti-booster"
          />
          <label class="custom-control-label" for="practiceTestAptiBooster"
            >Apti Booster</label
          >
        </div>
      </div>
    </div>
    <h6 class="lh-5 mg-b-0" />
    <button
      disabled={emailLoading}
      class="btn btn-sm pd-x-15 btn-primary float-right mg-l-5 mg-t-10 wd-200"
      style="display: none;"
      on:click={sendMail}
    >
      <i data-feather="mail" class="wd-10 mg-r-8" />
      <ButtonLoader isLoading={emailLoading} btnText="Test Link Via E-mail " />
    </button>
    {#if testObj.isPracticeTest == "yes"}
      <div class="row col-8 justify-content-between mb-1">
        <label class="d-block mg-t-20">
          Selected Location <span class="required-field">*</span>
        </label>

        <div class="d-flex align-items-end">
          <button
            disabled={testObj.publish}
            on:click={() => (showModal = !showModal)}
            class="btn btn-xs pd-x-15 btn-primary btn-uppercase mg-l-5"
          >
            <i data-feather="link" class="wd-10 mg-r-5" />
            New Link
          </button>
          {#if testObj.linkArr && testObj.practiceTestParentId}
            {#if testObj.linkArr.length != testObj.practiceTestParentId.length}
              <button
                on:click={btnHandlerFixBrokenLinks}
                class="btn btn-xs btn-secondary btn-uppercase mg-l-5"
                ><i data-feather="link-2" class="wd-10 mg-r-5" /> Refresh links</button
              >
            {/if}
          {/if}
        </div>
      </div>
      <ul class="row col-8 list-group list-group-flush tx-13">
        {#if testObj.linkArr}
          {#each testObj.linkArr as item, index}
            <li class="list-group-item d-flex pd-sm-x-20">
              <div class="avatar">
                <span class="avatar-initial rounded-circle bg-gray-600"
                  ><i data-feather="link" class="wd-10" /></span
                >
              </div>
              <div class="pd-l-10">
                <p class="tx-medium mg-b-0">
                  {item.fullPath.join(" / ")}
                </p>
                <small class="tx-12 tx-color-03 mg-b-0">{item.batchName}</small>
                {#if testObj.isPracticeTest == "yes"}
                  {#if item.fullPathId && !checkForBrokenLink(item.fullPathId[item.fullPathId.length - 1], testObj.practiceTestParentId)}
                    <span class="mg-r-2 mg-l-2">-</span>
                    <span class="text-danger tx-12">
                      <i data-feather="link-2" class="wd-15"></i> Not Linked
                    </span>
                  {/if}
                {/if}
              </div>
              <div class="mg-l-auto d-flex align-self-center">
                <nav class="nav nav-icon-only">
                  <a
                    href="#"
                    class="nav-link d-sm-block"
                    on:click={() => removeFromLinkArrByIndex(index)}
                  >
                    <i data-feather="trash-2" class="wd-10 mg-r-5" />
                  </a>
                </nav>
              </div>
            </li>
          {:else}
            no link
          {/each}
        {/if}
      </ul>
      {#if showModal}
        <FolderModal on:close={onCloseModal} {showModal} />
      {/if}
    {/if}
    {#if testObj.isPracticeTest == "no"}
      <div class="row fadeIn mg-t-20">
        {#if errorObj.mailError}
          <div class="parsley-errors-list mg-l-15">{errorObj.mailError}</div>
        {/if}
        <div
          class="form-group col-md-12"
          id="selectBatch"
          on:click={() => clearError("assignedToBatches")}
          class:parsley-errors-select-custom={errorObj.assignedToBatches}
        >
          <label class="d-block">Select Batches</label>
          <select
            name="assignBatch"
            id="assignBatch"
            type="text"
            class="info form-control"
          >
            <option value="">Select Batches</option>
          </select>
          {#if errorObj.assignedToBatches}
            <div class="parsley-errors-list">{errorObj.assignedToBatches}</div>
            <!-- content here -->
          {/if}
        </div>

        <div
          class="form-group col-md-12"
          id="selectChannel"
          on:click={() => clearError("assignedToChannels")}
          class:parsley-errors-select-custom={errorObj.assignedToChannels}
        >
          <label class="d-block">Select Groups</label>
          <select
            name="assignChannel"
            id="assignChannel"
            type="text"
            class="info form-control"
          >
            <option value="">Select Groups</option>
          </select>
          {#if errorObj.assignedToChannels}
            <div class="parsley-errors-list">{errorObj.assignedToChannels}</div>
            <!-- content here -->
          {/if}
        </div>

        <div
          class="form-group col-md-12"
          id="selectStudent"
          on:click={() => clearError("assignedToStudent")}
          class:parsley-errors-select-custom={errorObj.assignedToStudent}
        >
          <label class="d-block"
            >Select Users (Students, Faculty, Admin)
          </label>
          <select
            name="assignStudent"
            id="assignStudent"
            type="text"
            class="info form-control"
          >
            <option value="">Select Users</option>
          </select>
          {#if errorObj.assignedToStudent}
            <div class="parsley-errors-list">{errorObj.assignedToStudent}</div>
            <!-- content here -->
          {/if}
        </div>
      </div>
    {/if}
    {#if testObj.isPracticeTest == "apti-booster"}
      <div class="form-row fadeIn mg-t-20">
        <div class="form-group col-md-12">
          <label lass="d-block" for="boardSelect">Select Board</label>
          <select
            class="info form-control"
            id="boardSelect"
            bind:value={selectedBoard}
            on:change={fetchSubjectData}
            disabled={testObj.publish}
          >
            {#each boardOptions as board}
              <option value={board}>{board}</option>
            {/each}
          </select>
        </div>
      </div>
      <div class="form-row fadeIn mg-t-20">
        <div class="form-group col-md-12">
          <label for="classSelect">Select Class</label>
          <select
            id="classSelect"
            class="info form-control"
            bind:value={selectedClass}
            on:change={fetchSubjectData}
            disabled={testObj.publish}
          >
            {#each classOptions as cls}
              <option value={cls}>{cls}</option>
            {/each}
          </select>
        </div>
      </div>
      <div class="form-row fadeIn">
        <div class="form-group col-md-12">
          <label for="subjectSelect" class="block font-semibold text-gray-700">
            Select Subjects
          </label>
          {#if loadingSubjects}
            <p class="text-gray-500">Loading subjects...</p>
          {:else if subjects.length === 0}
            <p class="text-gray-500">
              No subjects available for this board and class
            </p>
          {:else}
            <!-- The select element will be managed by resetSelectizeField and initializeSelectize -->
            <div id="subjectSelectContainer">
              <select
                id="subjectSelect"
                class="info form-control"
                disabled={testObj.publish}
              ></select>
            </div>
          {/if}
        </div>
      </div>

      <div class="form-row fadeIn">
        <div class="form-group col-md-12">
          <label for="chapterSelect" class="block font-semibold text-gray-700">
            Select Chapters
          </label>
          {#if loadingChapters}
            <p class="text-gray-500">Loading chapters...</p>
          {:else if !selectedSubjectIds.length}
            <p class="text-gray-500">
              Please select at least one subject first
            </p>
          {:else if chapters.length === 0}
            <p class="text-gray-500">
              No chapters found for the selected subjects
            </p>
          {:else}
            <!-- The select element will be managed by resetSelectizeField and initializeChapterSelectize -->
            <div id="chapterSelectContainer">
              <select
                id="chapterSelect"
                class="info form-control"
                disabled={testObj.publish}
              ></select>
            </div>
          {/if}
        </div>
      </div>
      {#if testObj.publish}
        <div class="text-muted mt-2">
          This test is published - Apti Booster assignments cannot be modified
        </div>
      {/if}
    {/if}
  </div>
  <h6 class="mg-b-0" />
</div>

<style>
  .empty-state-img {
    text-align: center;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  ol.breadcrumb {
    flex: 1;
  }

  ol.breadcrumb li:last-child {
    flex: 1;
    text-align: right;
  }

  .custom-multi-select {
    height: auto;
    min-height: 100px; /* Adjust as needed */
    overflow-y: auto;
  }
</style>
