<script>
    import { createEventDispatcher } from 'svelte';
    export let value = '';
    const dispatch = createEventDispatcher();
    const select = num => () => value += num;
    const clear = num => () => value += '.';
    const backspace = () => value = value.slice(0, -1);
    const submit = () => dispatch('submit');
</script>

<div class="keypad">
    <button on:click={select(1)}>1</button>
    <button on:click={select(2)}>2</button>
    <button on:click={select(3)}>3</button>
    <button on:click={select(4)}>4</button>
    <button on:click={select(5)}>5</button>
    <button on:click={select(6)}>6</button>
    <button on:click={select(7)}>7</button>
    <button on:click={select(8)}>8</button>
    <button on:click={select(9)}>9</button>
    <button disabled={!value} on:click={clear()}>.</button>
    <button on:click={select(0)}>0</button>
    <button disabled={!value} on:click={backspace}>Clear</button>
</div>

<style>
    .keypad {
        display: grid;
        grid-template-columns: repeat(3, 5em);
        grid-template-rows: repeat(4, 3em);
        grid-gap: 0.5em
    }

    .keypad button {
        margin: 0;
        border: 1px solid #ddd;

    }
</style>
