<script>
  import { Router, Link, link, Route, navigate } from "svelte-routing";
  import { onMount, tick, onDestroy } from "svelte";
  import ButtonLoader from "../dashboard/utils/button_loader.svelte";
  import api_handleApiCalls from '../dashboard/utils/apiCallHandler';
  import { _mobileNo, _signToken, _otp, _registerOtpToken, _accessToken } from "../store.js";
  import { pError, pSuccess } from "../dashboard/utils/notify.svelte";
  import { API_URL } from "../config"


  let isLoading = false;
  let loginLoading = false;
  let mobileNo;
  let password
  let mobileNoEle;
  let errorDetails = {};
  let isLoginWithPassword = false


  function clearFields() {
    mobileNo = "";
    clearValidations();
  }

  function validate() {
    clearValidations();
    tick();
    // var moNoReg = new RegExp("^[7-9][0-9]{9}$")
    // if (!moNoReg.test(mobileNo) || mobileNo.length != 10) {
    if (String(mobileNo).length != 10) {
      errorDetails.mobileNo = "Please Enter 10 Digit Valid Mobile Number";
    }
    if (Object.values(errorDetails).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  const setError = (key, error) => { errorDetails[key] = error }
  const clearValidations = () => { errorDetails = {} }

  async function sendOTP() {
    const MOBILENORGEX = RegExp(/^[6-9][0-9]{9}$/);
    if (!MOBILENORGEX.test(mobileNo)) return setError('mobileNo', "Please Enter 10 Digit Valid Mobile Number")

    if (isLoading) return;
    isLoading = true;
    tick()
    const isValid = validate();
    if (!isValid) {
      isLoading = false;
      return false;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var payload = JSON.stringify({
      action: "auth.sendOptForLogin",
      data: {
        mobileNo,
        mode: window.APP_MODE ? window.APP_MODE : 'prod'
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };
    try {
      var resObj = await fetch(API_URL, requestOptions);
      var response = await resObj.json();

      if (response.status) {
        _mobileNo.set(mobileNo);
        _otp.set(response.data.otpCode);
        isLoading = false;
        _signToken.set(response.data.signToken);
        navigate("/login/verify-otp");
      } else {
        errorDetails.mobileNo = response.errMsg;
      }
    } catch (error) {
      console.error("error", error);
      console.log({ error: error });
    }
    isLoading = false;
  }
  async function init() {
    clearFields();
    _mobileNo.subscribe(value => mobileNo = value || '')
    mobileNoEle.focus();
  }

  const checkHasPassword = async (password) => {

    const MOBILENORGEX = RegExp(/^[6-9][0-9]{9}$/);
    if (!MOBILENORGEX.test(mobileNo)) return setError('mobileNo', "Please Enter 10 Digit Valid Mobile Number")

    loginLoading = true;
    let res = await api_handleApiCalls('auth.checkHasPassword', {
      mobileNo
    })
    loginLoading = false;

    if (res.status && res.data) {
      console.log("error", JSON.stringify(res, null, 4));
      if (res.data.isRegistered) {
        if (res.data.hasPassword) {
          isLoginWithPassword = true
        } else {
          pError("Error", res.data.message)
        }
      } else {
        // Let him signup here
        pError('Error', "No user associated with this mobile number")
      }
    }
  }

  const clickHandlerLoginButton = async () => {
    const MOBILENORGEX = RegExp(/^[6-9][0-9]{9}$/);
    if (String(mobileNo).length != 10) return setError('mobileNo', "Please Enter 10 Digit Valid Mobile Number")
    if (!MOBILENORGEX.test(mobileNo)) return setError('mobileNo', "Please Enter 10 Digit Valid Mobile Number")
    if (!password) return setError('password', "Enter 8-12 characters long valid password")
    if (String(password).length < 8 || String(password).length > 12) return setError('password', "Enter 8-12 characters long valid password")
    // if(String(password).length < 6) return setError('password', "Enter 6-8 characters long valid password")

    loginLoading = true
    let res = await api_handleApiCalls('auth.loginWithPassword', {
      mobileNo,
      password
    })
    loginLoading = false

    try {
      if (res.status) {
        var accessToken = "";

        if (res.data.accessToken && res.data.accessToken.length == 0) {
          alert("Token not found. Failed to login, Please contact admin")
        }
        if (res.data.accessToken && res.data.accessToken.length == 1) {
          accessToken = res.data.accessToken[0];
          _accessToken.set(accessToken);
          window.localStorage.setItem("accessToken", accessToken);


          var browser = (function () {
            var test = function (regexp) { return regexp.test(window.navigator.userAgent) }
            switch (true) {
              case test(/edg/i): return "Microsoft Edge";
              case test(/trident/i): return "Microsoft Internet Explorer";
              case test(/firefox|fxios/i): return "Mozilla Firefox";
              case test(/opr\//i): return "Opera";
              case test(/ucbrowser/i): return "UC Browser";
              case test(/samsungbrowser/i): return "Samsung Browser";
              case test(/chrome|chromium|crios/i): return "Google Chrome";
              case test(/safari/i): return "Apple Safari";
              default: return "Other";
            }
          })();

          var myHeaders1 = new Headers();
          myHeaders1.append("Content-Type", "application/json");
          var payload1 = JSON.stringify({
            action: "auth.updateUserAccessToken",
            accessToken: accessToken,
            data: {
              accessToken: accessToken,
              deviceName: 'Browser',
              deviceModel: browser
            }
          });
          var requestOptions1 = {
            method: "POST",
            headers: myHeaders1,
            body: payload1,
          };


          await fetch(API_URL, requestOptions1);


          navigate("/dashboard", { replace: true });
        } else {
          window.localStorage.setItem("accessTokenList", res.data.accessToken)
          navigate("/login/choose-user", { replace: true });
        }
      } else {
        password = ''
        setError('password', res.errMsg)

      }


    } catch (error) {
      console.error("error", error);
      console.log({ error: error });
    }
  }

  onMount(init);
  onDestroy(clearFields);
</script>
<style>
  .fields-mg {
    margin: 0;
    margin-bottom: 1rem;
  }

  .orText {
    margin: 0.6rem;
  }
</style>
<div class="sign-wrapper mg-lg-l-50 mg-xl-l-60 fadeIn">
  <div class="wd-100p">
    <h3 class="tx-color-01 mg-b-5">Sign In</h3>
    <p class="tx-color-03 tx-16 mg-b-40">
      Welcome back! Please signin to continue.
    </p>

    {#if isLoginWithPassword}
    <div class="from-group fields-mg">
      <p>+91 {mobileNo}</p>
    </div>
    <div class="from-group fields-mg">
      <label for="password">Password</label>
      <input type="password" on:keyup={(e)=> e.key === 'Enter' && clickHandlerLoginButton()}
      class="form-control"
      value={password||''}
      on:blur={clearValidations}
      on:input={function (e) {
      this.value = this.value.slice(0, 12);
      password = this.value;
      }}
      placeholder="Enter Password"/>
      {#if errorDetails.password}
      <div class="parsley-errors-list">{errorDetails.password}</div>
      {/if}
    </div>

    <button disabled={loginLoading|isLoading} on:click={clickHandlerLoginButton}
      class="btn btn-brand-02 btn-block btn-block">
      <ButtonLoader isLoading={loginLoading} btnText="Login" />
    </button>
    <div class="text-muted text-center orText">OR</div>
    <button disabled={loginLoading|isLoading} on:click={()=>{isLoginWithPassword = false}} class="btn btn-brand-02
      btn-block btn-block">
      <ButtonLoader isLoading={isLoading} btnText="Login With OTP" />
    </button>

    {:else}
    <div>
      <div class="form-group fields-mg">
        <label>Mobile Number</label>
        <input bind:this={mobileNoEle} value={mobileNo} type="number" on:blur={clearValidations} class="form-control"
          on:keyup={(e)=> e.key === 'Enter' && sendOTP()}
        on:input={function (e) {
        this.value = this.value.slice(0, 10);
        mobileNo = this.value;
        }}
        placeholder="Enter your Mobile Number"
        class:parsley-error={errorDetails.mobileNo} />
        {#if errorDetails.mobileNo}
        <div class="parsley-errors-list">{errorDetails.mobileNo}</div>
        {/if}
      </div>

      <button disabled={loginLoading|isLoading} on:click={sendOTP} class="btn btn-brand-02 btn-block btn-block">
        <ButtonLoader isLoading={isLoading} btnText="Request OTP" />
      </button>
      <div class="text-muted text-center orText">OR</div>
      <button disabled={loginLoading|isLoading} on:click={checkHasPassword}
        class="btn btn-brand-02 btn-block btn-block">
        <ButtonLoader isLoading={loginLoading} btnText="Login With Password" />
      </button>


      <p class="text-center text-muted mt-2">(Login with password only if you have set password through profile section)
      </p>
    </div>
    {/if}


    <!-- <div class="divider-text">or</div>
        <button class="btn btn-outline-facebook btn-block">Sign In With Facebook</button>
        <button class="btn btn-outline-twitter btn-block">Sign In With Twitter</button> -->
    <!-- <div class="tx-13 mg-t-20 tx-center">
      Don't have an account?
      <a href="/signup" use:link>Create an Account</a>
    </div> -->
  </div>
</div>
<!-- sign-wrapper -->
