import { API_URL } from "../../config";

export default async function api_handleApiCalls(action, data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var payload = JSON.stringify({
    action: action,
    accessToken: localStorage.getItem("accessToken"),
    data: data,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: payload,
  };

  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 30000); // Timeout after 5 seconds

  try {
    // console.log("Making API call with payload:", payload);
    var resObj = await fetch(API_URL, {
      ...requestOptions,
      signal: controller.signal,
    });
    clearTimeout(timeoutId);
    // console.log("API call made, awaiting response...");
    var response = await resObj.json();
    // console.log("API Response:", response);
    return response;
  } catch (error) {
    if (error.name === "AbortError") {
      console.error("API call was aborted due to timeout");
    } else {
      console.error("Error in api_handleApiCalls:", error);
    }
    return { errMsg: error.message };
  }
}
