<script>
  import { setContext, onMount, tick } from "svelte";
  import { links, link, navigate } from "svelte-routing";
  import { activeMenu } from "../../stores";
  import FeedbackComments from "../../comments/index.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  export let id;
  let feedbackId;
  let isLoadingSave, isLoading;
  let errorObj = {};
  let elem_dropdownType, elem_dropdownSeverity, elem_dropdownStatus;
  
  async function fetchStudentFeedback() {
    let data = { id };
    let result = await api_handleApiCalls("feedback.fetchStudentFeedback", data);
    console.log(result);
    if (result.status) {
      feedbackId = result.data.feedbackId;
      setValues({
        severity: result.data.severity,
        type: result.data.type,
        status: result.data.status,
        query: result.data.query,
      });
      _$(elem_dropdownStatus).prop(
        "disabled",
        result.data.status == "completed"
      );
    } else if (result.errMsg) {
      pError("", result.errMsg);
    } else if (result.error) {
      pError("", result.error);
    } else {
      pError("", "Something went wrong");
    }
  }
  async function statusChanged() {
    let status = _$(elem_dropdownStatus).find(":selected").val();
    let data = { status, id };
    // api_handleApiCalls call
    let result = await api_handleApiCalls("feedback.updateStatus", data);
    if (result.status) {
      pSuccess("", "Feedback status updated successfully");
      await fetchStudentFeedback();
    } else if (result.errMsg) {
      pError("", result.errMsg);
    } else if (result.error) {
      pError("", result.error);
    } else {
      pError("", "Something went wrong");
    }
  }
  async function saveStudentFeedback() {
    console.log(isLoadingSave);
    if (isLoadingSave) return;
    isLoadingSave = true;
    await tick();
    // validate
    let errorObj = {};
    let values = {};
    if (CKEDITOR.instances["feedback_editor"].getData()) {
      values.query = CKEDITOR.instances["feedback_editor"].getData();
    } else {
      errorObj.queryError = "Feedback cannot be empty";
    }
    values.severity = _$(elem_dropdownSeverity).find(":selected").val();
    values.status = _$(elem_dropdownStatus).find(":selected").val();
    values.type = _$(elem_dropdownType).find(":selected").val();

    let isValid = !errorObj.queryError;
    if (isValid) {
      // api_handleApiCalls call
      let result = await api_handleApiCalls("feedback.saveStudentFeedback", values);
      console.log(result);
      if (result.status) {
        pSuccess("", "Feedback sent successfully");
        navigate("./");
      } else if (result.errMsg) {
        pError("", result.errMsg);
      } else if (result.error) {
        pError("", result.error);
      } else {
        pError("", "Something went wrong");
      }
    } else {
      console.log(errorObj);
    }
    isLoadingSave = false;
    await tick();
  }
  function initSelect2(element, onSelect) {
    // console.log({ data });
    if (_$(element).hasClass("select2-hidden-accessible")) {
      // Select2 had been initialized, destroy the old instance
      _$(element).select2("destroy");
    }
    _$(element).select2();
    if (onSelect) {
      //   _$(element).on("select2:select", onSelect);
      _$(element).on("select2:close", onSelect);
    }
  }
  async function initCkeditor(editorInstance, onEnter, readyOnly) {
    try {
      CKEDITOR.replace(editorInstance, {
        readyOnly,
        placeholder: "Enter your feedback",
        on: {
          loaded: reload,
        },
      });
      await tick();
    } catch (error) {
      console.error(error);
    }
  }
  async function setValues(values) {
    _$(elem_dropdownSeverity).val(values.severity).trigger("change");
    _$(elem_dropdownStatus).val(values.status).trigger("change");
    _$(elem_dropdownType).val(values.type).trigger("change");
    CKEDITOR.instances["feedback_editor"].setData(values.query);
    await tick();
  }
  function clearFields() {
    setValues({
      severity: 2,
      type: "general",
      status: "new",
      query: "",
    });
  }
  async function reload() {
    if (id && !isLoading) {
      isLoading = true;
      await tick();
      await fetchStudentFeedback();
      // fetchComments()
      isLoading = false;
      await tick();
    }
  }
  onMount(async function () {
    console.log("opening student feedback form");
    console.log("id: ", { id });
    activeMenu.set("studentFeedback");
    initSelect2(elem_dropdownSeverity);
    initSelect2(elem_dropdownType);
    initSelect2(elem_dropdownStatus, statusChanged);
    initCkeditor("feedback_editor", saveStudentFeedback, Boolean(id));
    // clearFields();
    _$(elem_dropdownSeverity).prop("disabled", id);
    _$(elem_dropdownType).prop("disabled", id);
    _$(elem_dropdownStatus).prop("disabled", !id);
  });
</script>

<div class="content-body animated scale">
  <!-- breadcrumbs -->
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20
      mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active">
            <a href="/dashboard/studentFeedback">Feedback</a>
          </li>
          {#if id}
            <li class="breadcrumb-item">View</li>
            <li class="breadcrumb-item active" aria-current="page">
              {'#' + feedbackId || ''}
            </li>
          {:else}
            <li class="breadcrumb-item active" aria-current="page">New</li>
          {/if}
        </ol>
      </nav>
      {#if !id}
        <h4 class="mg-b-0 tx-spacing--1 text-capitalize">
          Submit New Feedback
        </h4>
        <p class="tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
          Add New Feedback
        </p>
      {:else}
        <h4 class="mg-b-0 tx-spacing--1 text-capitalize">
          Feedback History ({'#' + feedbackId || ''})
        </h4>
        <p class="tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
          View Feedback, and make/see comments on it
        </p>
      {/if}
    </div>
    <div class="d-inline-block">
      <div class="d-inline-block">Status</div>
      <div class="form-group d-inline-block">
        <select class="form-control select2" bind:this={elem_dropdownStatus}>
          <!-- <option value="new">New</option> -->
          <option value="open">Open</option>
          <option value="discuss">Being Discussed</option>
          <option value="completed">Completed</option>
        </select>
      </div>
      <!-- {#if !id}
        <div class="d-inline-block">
          <button
            type="button"
            on:click={saveStudentFeedback}
            disabled={isLoadingSave}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
              data-feather="save"
              class="wd-10 mg-r-5" />
            <ButtonLoader isLoading={isLoadingSave} btnText="Save Changes" />
          </button>
        </div>
      {/if} -->
    </div>
  </div>
  <hr class="full-width" />
  <div class="mg-b-20 ">
    <div class="row row-sm">
      <div class="col-md-7 col-sm-5">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Feedback Details
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Severity</label>
                <select
                  class="form-control select2"
                  bind:this={elem_dropdownSeverity}>
                  <option value="1">Very High</option>
                  <option value="2">High</option>
                  <option value="3" selected>Medium</option>
                  <option value="4">Low</option>
                  <option value="5">Very Low</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Type</label>
                <select
                  class="form-control select2"
                  bind:this={elem_dropdownType}>
                  <option value="general">General</option>
                  <option value="account">Account</option>
                  <option value="exam">Exam</option>
                  <option value="attendance">Attendance</option>
                  <option value="assignment">Assignment</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Query
                  {#if id == 'new'}
                    <span class="required-field">*</span>
                  {/if}</label>
                <textarea
                  type="text"
                  disabled={id}
                  name="feedback_editor"
                  class="form-control feedback"
                  class:parsley-error={errorObj.queryError} />
                {#if errorObj.queryError}
                  <div class="col-md-12">
                    <div class="parsley-errors-list">{errorObj.queryError}</div>
                  </div>
                {/if}
              </div>
            </div>
          </div>
        </div>
        {#if !id}
          <div class="d-flex flex-row-reverse">
            <button
              type="button"
              on:click={saveStudentFeedback}
              disabled={isLoadingSave}
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
                data-feather="save"
                class="wd-10 mg-r-5" />
              <ButtonLoader isLoading={isLoadingSave} btnText="Save Changes" />
            </button>
          </div>
        {/if}
      </div>
      {#if id}
        <div class="col-sm-5 col-ms-12">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Feedback Comments
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-sm-12 ht-250">
            <div class="pd-r-10 ht-100p">
              <!-- comments here -->
              <FeedbackComments {id} />
            </div>
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>
