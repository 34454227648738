<script>
  import { createEventDispatcher, onMount, beforeUpdate } from 'svelte';
  import { pConfirm, pSuccess, pError, pAlert } from "../utils/notify.svelte"
  import ButtonLoader from "../utils/button_loader.svelte"
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import { userData } from "../stores";
  import MODULES from "../utils/modules.js"
  import { API_URL } from "../../config.js"

  const dispatch = createEventDispatcher();
  let isLoading = false;
  export let studentId;
  let demoDataTable;
  let studentDataTable;
  let roleIsTelecaller = false;
  let emptyState = false;
  let weekNo = moment().format('w');
  let tempAttendanceObj = {
    notes: "",
    isAllSubjectsAssigned: false
  };

  const unsubscribe = userData.subscribe(value => {
    roleIsTelecaller = value.role == "telecaller"
  });

  onMount(async () => {
    feather.replace();
    unsubscribe();
    // console.log("roleIsTelecaller",roleIsTelecaller);
    dispatch('setActiveTab', {
      tabName: 'Attendance'
    });
    bindDatatable();
    // studentDataTable.DataTable({paging: false})
  })
  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.name = order;
      /* } else if (sortOrder.column === 2) {
      sort.mobileNo = order; */
    } else if (sortOrder.column === 2) {
      sort.email = order;
      // } else if (sortOrder.column === 4) {
      //   sort.role = order;
      // } else if (sortOrder.column === 5) {
      // } else if (sortOrder.column === 3) {
      //   sort.createdOn = order;
    } else {
      sort.userSeqId = order;
    }
    return sort;
  }

  function bindDatatable() {

    studentDataTable = _$(demoDataTable).DataTable({
      responsive: true,
      "paging": false,
      "bInfo": false,
      // "pagingType": "simple",
      // "language": {
      //     "paginate": {
      //       "previous": `<button on:click={prevBtnClick} style="display:none;" type="button"  class="btn paginationBtn btn-secondary d-none tx-13 mg-5">Prev</button>`,
      //       "next": `<button on:click={nextBtnClick} type="button" class="btn paginationBtn btn-primary d-none tx-13 mg-5">Next</button>`
      //     }
      //   },
      language: {
        processing: "",
        searchPlaceholder: 'Search Class Name',
        sSearch: '',
        lengthMenu: '_MENU_ items/page',

      },

      ajax: function (data, callback, settings) {

        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });

        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: 'attendance.getAttendanceListWeekWise',
            accessToken: localStorage.getItem("accessToken"),
            data: {
              search: data.search.value,
              sort: data.sort,
              studentId: studentId,
              weekNo: weekNo,
            }
          },
          success: function (resData) {
            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                // recordsTotal: Number(paginationData.totalCount),
                // recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData
              }
              emptyState = false
              if (!resData.data.length) {
                emptyState = true
              }
              callback(result);
            }
            else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },
      columns: [
        {
          data: 'attendanceDate', render: function (data, a, b, c) {
            let d = new Date(data)
            return `${moment(d).format('dddd')},<br/> ${moment(d).format('ll')}`
          }
        },
        { data: 'batchName', defaultContent: '<i>No data found<i>' },
        { data: 'topic', defaultContent: '<i> - <i>' },

        {
          data: 'batchId', render: function (data, mode, row, settings) {
            let startTime = new Date(row.startTime);
            let endTime = new Date(row.endTime)
            return `${row.subjectName} <br/>
                      ${moment(startTime).format('LT')}  - ${moment(endTime).format('LT')}`;
          }
        },
        {
          data: 'batchId', defaultContent: "-", render: (data, mode, row, settings) => {
            if (row.holiday) {
              return '-';
            } else {
              if (!row.attendance) return "-";
              if (row.attendance == 'present') return `<span class="badge badge-success">${row.attendance}</span>`;
              if (row.attendance == 'absent') return `<span class="badge badge-danger">${row.attendance}</span>`;
              if (row.attendance == 'late') return `<span class="badge badge-warning">${row.attendance}</span>`;
              if (row.attendance == 'excused') return `<span class="badge badge-light">${row.attendance}</span>`;
            }
          },
        },
        {
          data: 'batchId', render: (data, mode, row, settings) => {
            if (row.holiday) {
              return '-';
            } else {
              return row.notes;
            }
          },
        },
        {
          data: "batchId",
          orderable: false,
          render: (data, mode, row, settings) => {
            // console.log('data dfsdf',row.notes);
            if (row.holiday) {
              return row.holiday;
            } else {

              let today = new Date().setHours(0, 0, 0, 0);
              if (new Date(row.attendanceDate).setHours(0, 0, 0, 0) > new Date(today)) {

                // console.log('in ',row);
                if (MODULES.hasAccess(MODULES.UPDATE_STUDENT)) {
                  return `<button
                  disabled
                  batch_id=${row.batchId}
                  batch_timing_id=${row.batch_timing_id}
                  studentId=${studentId}
                  date=${row.attendanceDate}
                  attendance=${row.attendance}
                  notes=${row.notes}
                  isAllSubjectsAssigned=${row.isAllSubjectsAssigned}
                  class="btn btn-xs btn-icon
                  wd-30 ht-30 btn-primary btn-uppercase btn-mark-attendance mg-5"
                  >
                      <i data-feather="edit-2" ></i> </button>
                      `;
                }
              } else {
                if (MODULES.hasAccess(MODULES.UPDATE_STUDENT)) {
                  return `
                  <button 
                    batch_id=${row.batchId}
                    batch_timing_id=${row.batch_timing_id}
                    studentId=${studentId}
                    date=${row.attendanceDate}
                    attendance=${row.attendance}
                    notes=${row.notes}
                    startTime=${row.startTime}
                    endTime=${row.endTime}
                    subjectName=${row.subjectName}
                    subjectId=${row.subjectId}
                    batchName=${row.batchName}
                    isAllSubjectsAssigned=${row.isAllSubjectsAssigned}
                    notAssignedSubjectList=${row.notAssignedSubjectList}
                    on:click={openAddAppSlider} class="btn btn-xs btn-icon
                    wd-30 ht-30 btn-primary btn-uppercase btn-mark-attendance mg-5">
                    <i data-feather="edit-2" ></i>
                    </button>`;
                }
              }
            }
          },
        },

      ],
      "drawCallback": function () {
        feather.replace({ width: "1em", height: "1em" })
        _$('.btn-mark-attendance').unbind('click').click(function (e) {
          e.preventDefault();
          // if(roleIsTelecaller) return;
          tempAttendanceObj = {
            batch_id: _$(this).attr("batch_id"),
            batch_timing_id: _$(this).attr("batch_timing_id"),
            studentId: studentId,
            date: _$(this).attr("date"),
            attendance: _$(this).attr("attendance"),
            notes: _$(this).attr("notes"),
            startTime: _$(this).attr("startTime"),
            endTime: _$(this).attr("endTime"),
            subjectName: _$(this).attr("subjectName"),
            subjectId: _$(this).attr("subjectId"),
            batchName: _$(this).attr("batchName"),
            isAllSubjectsAssigned: _$(this).attr("isAllSubjectsAssigned"),
            notAssignedSubjectList: _$(this).attr("notAssignedSubjectList"),
            topic: _$(this).attr("topic")

          }
          // console.log('tempobj ',tempAttendanceObj);

          var attendance = _$(this).attr("attendance");

          if (attendance != '-') {
            _$('.attendancebtn').removeClass('btn-dark').addClass('btn-outline-dark');
            _$(`#${attendance}`).addClass('btn-dark').removeClass('btn-outline-dark');
          } else {
            _$('.attendancebtn').removeClass('btn-dark').addClass('btn-outline-dark');
          }
          showModel();

        })
      },
      "processing": true,
      "serverSide": true,
      "paging": false,
    });
    _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
  }
  function reload() {
    _$(studentDataTable).DataTable().ajax.reload(null, false);
  }
  function hideModel(params) {
    _$("#mark-attendance-modal").modal("hide");
    // tempAttendanceObj = {}
  }
  function showModel(params) {
    // clearFields()
    _$("#mark-attendance-modal").modal("show");
  }

  async function api_saveAttendance(data) {

    let action = 'attendance.saveAttendance';
    var response = await api_handleApiCalls(action, data);
    return response

  }

  function clearFields() {
    tempAttendanceObj = {
      notes: "",
      isAllSubjectsAssigned: false
    };
    // _$('.attendancebtn').removeClass('btn-dark').addClass('btn-outline-dark');

  }

  async function markAttendance() {
    // console.log('tempAttendanceObj ',tempAttendanceObj);

    let res = await api_saveAttendance(tempAttendanceObj);
    if (res.status) {
      hideModel();
      _$('.attendancebtn').removeClass('btn-dark').addClass('btn-outline-dark');
      studentDataTable.draw();
      pSuccess("", res.data);
    } else {
      pError("", res.errMsg);
    }
  }

  async function selectAttendance(e) {
    let attendance = e.target.id;
    tempAttendanceObj['attendance'] = attendance;
    _$('.attendancebtn').addClass('btn-outline-dark');
    _$(`#${attendance}`).removeClass('btn-outline-dark');

  }
  let prevBtnClick = () => {
    if (isLoading) return;
    isLoading = true;
    weekNo = Number(weekNo) - 1;
    studentDataTable.draw()
    isLoading = false;
  }
  let nextBtnClick = () => {
    if (isLoading) return;
    isLoading = true;
    weekNo = Number(weekNo) + 1;
    studentDataTable.draw()
    isLoading = false;
  }

  let addSubToStudent = async (e) => {
    // console.log('in addsubtostudent ',tempAttendanceObj);
    let data = {
      subjectIds: tempAttendanceObj.notAssignedSubjectList.split(','),
      studentId: studentId,
    }

    pConfirm("Assign Class Timing Subject", "Are you sure you want to assign subject to student?",
      function () { assignSubBtn(data) }
    )
  }

  async function assignSubBtn(data) {
    //  console.log('assign sub ',data);
    let result = await api_assignSubToStudent(data)
    // console.log('result ',result);
    if (result.status) {
      hideModel()
      studentDataTable.draw()
      pSuccess(null, result.data)
    } else {
      pError("Error", result.errMsg)
    }
  }

  async function api_assignSubToStudent(data) {

    let sendData = {
      ...data,
      batchId: tempAttendanceObj.batch_id
    }
    let action = 'students.assignSubToStudent';
    var response = await api_handleApiCalls(action, sendData);
    return response

  }

</script>

<style>
  .dataTables_paginate.paging_simple_numbers {
    display: none;
  }
</style>
<div data-label="Example" class="df-example demo-table">
  <table bind:this={demoDataTable} class="table">
    <thead>
      <tr>
        <th class="wd-15p">Date</th>
        <th class="wd-10p">Batch</th>
        <th class="wd-10p">Class Name</th>

        <th class="wd-20p">Class Time</th>
        <!-- <th class="wd-15p">Time </th> -->
        <th class="wd-10p">Attendance </th>
        <th class="wd-5p">Notes </th>
        <th class="wd-5p">Actions</th>
      </tr>
    </thead>
    <tbody></tbody>

  </table>
  <div class="row d-flex justify-content-end mg-2">
    {#if MODULES.hasAccess(MODULES.UPDATE_STUDENT)}
    <button on:click={prevBtnClick} type="button" class="btn btn-secondary tx-13 mg-5" disabled={isLoading}>
      <ButtonLoader isLoading={isLoading} btnText="Prev Week" />
    </button>
    {/if}
    {#if MODULES.hasAccess(MODULES.UPDATE_STUDENT)}
    <button on:click={nextBtnClick} type="button" class="btn btn-primary tx-13 mg-5" disabled={isLoading}>
      <ButtonLoader isLoading={isLoading} btnText="Next Week" />
    </button>
    {/if}
  </div>
  {#if emptyState }
  <div class="empty-state-img" style="text-align: center;">
    <img src="/images/empty.svg" style="width: 200px" alt="empty" />
    <p class="tx-bold">No attendance marked</p>
  </div>
  {/if}
</div>
<!-- <StudentForms _id={studentId} isComponent={true} /> -->

<div class="modal fade" id="mark-attendance-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel6">Mark Attendance</h4>
        {#if tempAttendanceObj.isAllSubjectsAssigned == "false"}
        <button data-toggle="tooltip" title="Assign class timing subject's to student" on:click={addSubToStudent} class="
                   btn btn-danger pd-6 mg-5 ht-20 wd-20 rounded-circle tx-12 lh-1">
          !
        </button>

        {/if}
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="form-group col-md-12">
            <button on:click={selectAttendance} id='present'
              class="btn attendancebtn btn-success  btn-uppercase pd-10 mg-10 wd-20p">
              Present
            </button>
            <button on:click={selectAttendance} id='absent'
              class="btn attendancebtn btn-danger  btn-uppercase pd-10 mg-10 wd-20p">
              Absent
            </button>
            <button on:click={selectAttendance} id='late'
              class="btn attendancebtn btn-warning  btn-uppercase pd-10 mg-10 wd-20p">
              Late
            </button>
            <button on:click={selectAttendance} id='excused'
              class="btn attendancebtn btn-light  btn-uppercase pd-10 mg-10 wd-20p">
              Excused
            </button>
            <div>
              <label class="d-block">Note</label>
              <textarea bind:value={tempAttendanceObj.notes} on:keyup={e=> e.key === 'Enter' && markAttendance(e)    }
              type="text" 
              class="form-control"  placeholder="Write a note"/>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary tx-12"
          data-dismiss="modal"
        >
          CLOSE
          <i data-feather="x" class="wd-10 mg-l-5"></i>
        </button>
        <button
          type="button"
          on:click="{markAttendance}"
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
          Save
          <i data-feather="save" class="wd-10 mg-l-5"></i>
        </button>
      </div>
    </div>
  </div>
</div>
