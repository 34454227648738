<script>
    import { Router, Link, links, Route } from "svelte-routing";
    import { onMount, beforeUpdate, afterUpdate, tick } from 'svelte';

    import STPModal from "../../singleParamTextEditor/modal.svelte";

    import Keypad from "./keypad.svelte";

    export let langObj = 'en';

    export let questionData = {}

    let showModal = false;

    let essayOpenedOnce = false;

    beforeUpdate(() => {
        console.log('the component is about to update', questionData.data.isEssayAttached);
    });

    afterUpdate(() => {
        console.log('the component just updated', questionData.data.isEssayAttached);
    });

    let cbChange = async function (e) {
        if (e.target.checked) {
            onSelectEssayClick({ forceShow: true });
        } else {
            clearEssay();
        }

    }

    let clearEssay = function () {
        // essayOpenedOnce = false;
        // console.log("questionData.data : ", questionData.data);
        // questionData.data.attachEssay[langObj] = {}
    }
    let onCloseModal = function (event) {
        _$('.modal-backdrop').remove()
        showModal = false;
        if (event.detail?.sptObject) {
            questionData.data.attachEssay[langObj] = event.detail?.sptObject
        }
    }

    let onSelectEssayClick = function (config) {
        console.log("questionData.data : ", questionData.data)

        if (config.forceShow) {
            essayOpenedOnce = true;
            showModal = true;
        } else {
            if (questionData.data.isEssayAttached && questionData.data.isEssayAttached[0]) {
                essayOpenedOnce = true;
                showModal = true;
            }
        }

    }

    onMount(async function () {
        _$('.essay-select').selectize({ maxItems: 1 });
        if (!questionData.data) questionData.data = {};

        CKEDITOR.inline('question_' + langObj, { height: 200 });

        CKEDITOR.inline('explanation_' + langObj, { height: 200 });

        await tick();

        if (questionData.data?.question && questionData.data?.question[langObj]) {
            CKEDITOR.instances['question_' + langObj].setData(questionData.data.question[langObj]);
        }

        if (questionData.data?.explanation && questionData.data?.explanation[langObj]) {
            CKEDITOR.instances['explanation_' + langObj].setData(questionData.data.explanation[langObj]);
        }

         let correctHtml = function (str) {
            var mySubString = str.substring(
                str.indexOf("<img") + 1, 
                str.lastIndexOf("/>")
            );
            var count = (mySubString.match(/style/g) || []).length;
            if(count)  return str
            else  return str.replace(/<img/g, " <img style='width:100% !important;height: unset !important;' ");
        }


        CKEDITOR.instances['question_' + langObj].on('change', function (e) {
            if (!questionData.data.question) {
                questionData.data.question = {};
            }
            questionData.data.question[langObj] = correctHtml(CKEDITOR.instances['question_' + langObj].getData());
        });



        CKEDITOR.instances['explanation_' + langObj].on('change', function (e) {
            if (!questionData.data.explanation) {
                questionData.data.explanation = {};
            }
            questionData.data.explanation[langObj] = correctHtml(CKEDITOR.instances['explanation_' + langObj].getData());
        });
        await tick();
        feather.replace();


    })

</script>

<style>
    .multiChoiceHeader.selectedOption {
        border-color: var(--primary);
    }

    .selectedOption .optionsType {
        background: var(--primary);
    }

    .selectedOption .cke_editable_inline {
        border-color: var(--primary);
    }

    .multiChoiceHeader {
        border: 1px solid var(--gray-dark);
        text-align: center;
        width: 50px;
        flex: 1;
        max-width: 50px;
        display: flex;
        flex-direction: column;
    }

    .optiontextArea {
        flex: 1;
        display: flex;
    }

    .optiontextArea textarea {
        height: 60px;
        flex: 1;
    }

    .optionsType {
        background: var(--gray-dark);
        color: #fff;
        margin-bottom: 0;
    }

    .optionInputHolder {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .optionInputHolder input {
        width: 22px;
        height: 22px;
    }

    .essay-div {
        background-color: #f5f6fa;
        cursor: pointer;
    }

    .essay-div.enable {
        background-color: white;
        ;
    }
</style>

<div class="row">
    <div class="col-sm-12 font-weight-bold">
        Question <span class="required-field">*</span> :
    </div>
    <div class="col-sm-12">
        <textarea class="editortextarea" name="question_{langObj}"></textarea>
    </div>
    <div class="col-sm-12 font-weight-bold mt-3">
        Answer <span class="required-field">*</span> :
    </div>

    <div class="col-sm-12">
        <input type="text" readonly min="{1}" bind:value={questionData.data.answer} class="form-control" id="ansElement" placeholder="Type correct answer...">
        <!-- <textarea class="editortextarea" name="answer_{langObj}"></textarea> -->
    </div>

    <div class="col-sm-12 mg-t-10">
        <Keypad bind:value={questionData.data.answer}/>
    </div>

    <div class="col-sm-12 font-weight-bold mt-3">
        Explanation :
    </div>
    <div class="col-sm-12">
        <textarea class="editortextarea" name="explanation_{langObj}"></textarea>
    </div>

    <div class="col-sm-12 font-weight-bold mt-3">
        Essay :
    </div>
    <div class="col-sm-12 " style="flex-direction: row;display: flex;justify-content: flex-start;">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text" style="padding-left: 20px;">
                    <div class="custom-control custom-checkbox pd-l-15">
                        <input type="checkbox" class="custom-control-input" id="customCheck00" on:change={cbChange}
                            bind:group={questionData.data.isEssayAttached} value=true>
                        <label class="custom-control-label" for="customCheck00">Attach Essay</label>
                    </div>
                </div>
            </div>
            <div class="form-control essay-div" on:click={onSelectEssayClick}
                class:enable={questionData.data.isEssayAttached && questionData.data.isEssayAttached[0]}>
                {#if questionData.data.isEssayAttached && questionData.data.isEssayAttached[0]}
                {questionData.data.attachEssay[langObj]?._id ? questionData.data.attachEssay[langObj]._id : ""}
                {/if}
            </div>
            {#if questionData.data.isEssayAttached && questionData.data.isEssayAttached[0]}
            <div class="input-group-append">
                <button class="btn btn-outline-light" on:click={onSelectEssayClick} type="button" id="button-addon2">
                    {questionData.data.attachEssay[langObj]?._id ? "Change" : "Select"}
                </button>
            </div>

            {/if}
        </div>
    </div>
</div>

{#if showModal}
<STPModal on:close={onCloseModal} />
{/if}
