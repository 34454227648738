<script>
  import { Router, Link, Route } from "svelte-routing";
  import { onMount } from "svelte";
  import List from "./list/list.svelte";
  import AddUpdate from "./addupdate/addupdate.svelte";

  function init() {
    console.log("redirecting ");
    feather.replace();
  }
  onMount(init);
</script>

<Router>
  <!-- <Route path=":id" let:params>
    <AddUpdate id={params.id} />
  </Route> -->
  <Route path="">
    <List />
  </Route>
</Router>
