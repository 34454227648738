<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick, afterUpdate } from 'svelte';
    import { userData } from "../stores";
    import { activeMenu } from "../stores";
    import moment from 'moment'
    import ButtonLoader from "../utils/button_loader.svelte"
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import MODULES from "../utils/modules.js" 
    let isLoading = false
    let roleIsOrgAdmin;
    var errorObj = {}
    let _id = '';
    let listAppSliders = [];
    let elem_uploadImageInput = "";
    let validateAppSlider = ()=>{
        errorObj = {};
        var newErrorObj = {};
        if (!appSliderObj.title) {
                newErrorObj.title = "Title is required Field.";
            }
        if (!appSliderObj.description) {
                newErrorObj.description = "Description is required Field.";
            } if (!appSliderObj.uploadType) {
                newErrorObj.uploadType = "Upload Type is required Field."
            }
            // if (appSliderObj.uploadType == 'image') {
            //     newErrorObj.uploadType = "image is required Field.";
            // }
            if (!appSliderObj.sequence) {
                newErrorObj.sequence = "Sequence could not be empty";
            }
            if (appSliderObj.uploadType == 'video') {
                if (!appSliderObj.videoLink) {
                    newErrorObj.videoLink = "Video link is required Field.";
                } else {
                    let valid = validateYouTubeUrl(appSliderObj.videoLink);
                    if (!valid) {
                        newErrorObj.videoLink = "Please enter correct youtube link.";
                    }
                }

            }
            errorObj = newErrorObj;
            
            if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;

    }
    let validateAppSliderObj = () => {
        errorObj = {};
        
            var newErrorObj = {};
            if (!appSliderObj.title) {
                newErrorObj.title = "Title is required Field.";
            }
            
      errorObj = newErrorObj;

        
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;


    }

    function updateSuccess(success) {
        PNotify.success({
            title: success.title ? success.title : "Success",
            text: success.msg,
            delay: 3000
        })
    }

    function updateErrors(error) {
        PNotify.error({
            title: error.title ? error.title : "Error",
            text: error.msg,
            delay: 3000
        })
    }

    function hideModel(params) {
        _$("#appSlider-add-modal").modal("hide");
        // tempAccessprofileId = '';
        appSliderObj.title = '';
        appSliderObj.description = "";
    }
    function showModel(params) {

        _$("#appSlider-add-modal").modal("show")
    }
    async function api_updateImageFileUrl(data) {

        let action = "storage.updateFileNameInAppSlider";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_getListOfAppSliders() {

        let action = "appSlider.list";
        var response = await api_handleApiCalls(action, null);
        return response;

    }

    async function api_getAppSliderById(id) {

        let action = "appSlider.getAppSliderById";
        var response = await api_handleApiCalls(action,  { id: id });
        return response;

    }

    async function api_deleteAppSlider(id) {

        let action = "appSlider.delete";
        var response = await api_handleApiCalls(action,  { id: id });
        return response;

    }

    var appSliderObj = {
        title: "",
        description: '',
        sequence:'',
        uploadType: '',
        videoLink: '',
        id: ''
    }
    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    }
    function clearFields() {
        appSliderObj = {
            title: "",
            description: '',
            uploadType: '',
            videoLink: '',
            id: ""
        }
        _$(elem_uploadImageInput).val('');
        _$('#videoUpload').addClass('d-none');
        _$('#imageUpload').addClass('d-none');

    }

    let editBtn = async (e) => {
        if (isLoading) return;
        isLoading = true;
        await tick()
        let id = e.target.dataset.id;
        console.log('e', e, id);
        let res = await api_getAppSliderById(id);
        if (res.status) {
            // appSliderObj.title = 
            appSliderObj = {
                id: id,
                title: res.data.title,
                description: res.data.description,
                uploadType: res.data.uploadType,
                videoLink: res.data.videoLink
            }
            if (res.data.uploadType == 'video') {
                _$('#videoUpload').removeClass('d-none');
            } else {
                _$('#imageUpload').removeClass('d-none');
            }
            showModel(appSliderObj)
            // listAppSliders = res.data;
            console.log('res edut ', res);
        } else {
            console.log(res.errMsg);
        }
        isLoading = false;
        return false;
    }

    let deleteBtn = async (e) => {

        const notice = PNotify.notice({
            title: "Delete App-slider",
            text: "Are you sure you want to delete this App-slider ?",
            hide: false,
            closer: false,
            sticker: false,
            modules: new Map([
                ...PNotify.defaultModules,
                [PNotifyConfirm, { confirm: true }],
            ]),
        })

        notice.on("pnotify:confirm", async () => {
            try {
                let id = e.target.dataset.id;
                let res = await api_deleteAppSlider(id);
                if (res) {
                    let result = await api_getListOfAppSliders();
                    if (result.status) {
                        listAppSliders = result.data;
                        await tick();
                        console.log('listdata', listAppSliders);
                    } else {
                        console.log(result.errMsg);
                    }
                    console.log('delete ', id);
                    PNotify.success({
                        title: 'Success',
                        text: "App slider deleted successfully",
                        delay: 3000
                    })
                } else {
                    PNotify.error({
                        title: 'Error',
                        text: res.errMsg,
                        delay: 3000
                    })
                }
                listAppSliders = res.data;
                
            } catch (error) {
                console.error(error);
            }

        })

        // let id = e.target.dataset.id;
        // let res = await api_deleteAppSlider(id);
        // if (res.status) {
        //     let res = await api_getListOfAppSliders();
        //     if (res.status) {
        //         listAppSliders = res.data;
        //         console.log('list ', listAppSliders);
        //     } else {
        //         console.log(res.errMsg);
        //     }
        //     // listAppSliders = res.data;
        //     console.log('delete ', id);
        // } else {
        //     console.log(res.errMsg);
        // }
    }

    let selectUploadType = (e) => {
        // console.log();
        if (e.target.dataset.label == 'image') {
            _$('#imageUpload').removeClass('d-none');
            _$('#videoUpload').addClass('d-none');
            appSliderObj.uploadType = 'image'
        }
        if (e.target.dataset.label == 'video') {
            _$('#videoUpload').removeClass('d-none');
            _$('#imageUpload').addClass('d-none');
            appSliderObj.uploadType = 'video'
        }

    }

    let filepondOptions = {
        allowDownloadByUrl: true,
        acceptedFileTypes: ["image/*"],

        server: {
            process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                // fieldName is the name of the input field
                // file is the actual file object to send
                const formData = new FormData();
                formData.append(fieldName, file, file.name);

                let originalFileName = file.name;
                let _fileName = file.name.split(".");

                var fileExtension = _fileName[_fileName.length - 1];
                var fileSize = file.size
                //  var formData = new FormData();
                // formData.append('file', file, "newFileName"+_fileName);

                var res = await api_getListOfFiles({
                    name: originalFileName,
                    type: file.type,
                    uploadType: "APP_SLIDER",
                    fileId: appSliderObj.id
                });
                console.log("res : ", res)
                if (res.status) {
                    _$.ajax({
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('x-amz-acl', 'public-read');
                        },
                        xhr: function () {
                            var xhr = new window.XMLHttpRequest();
                            xhr.upload.addEventListener("progress", function (e) {
                                // var percentComplete = (evt.loaded / evt.total) * 100;
                                progress(e.lengthComputable, e.loaded, e.total);
                                // console.log("percentComplete : ", percentComplete)
                            }, false);
                            return xhr;
                        },
                        url: res.data.url,
                        type: "PUT",
                        data: file,
                        dataType: "text",
                        cache: false,
                        contentType: file.type,
                        processData: false
                    })
                        .done(async function (resss) {

                            await api_updateImageFileUrl({
                                fileId: appSliderObj.id,
                                originalFileName: originalFileName,
                                fileExtension: fileExtension,
                                fileSize: fileSize,
                                url: res.data.url,
                            })
                            console.log("asdfasd : ", resss)
                            console.info('YEAH', res.data.url.split('?')[0].substr(6));
                            load("Done");
                        })
                        .fail(function (err) {
                            error('oh no');
                            console.error('damn...', err);
                        })
                } else {
                    // todo show erro toast
                }
                return {
                    abort: () => {
                        // This function is entered if the user has tapped the cancel button
                        // request.abort();

                        // Let FilePond know the request has been cancelled
                        abort();
                    }
                };
            }
        }
    }

    afterUpdate(() => {
        feather.replace()
    })

    const unsubscribe = userData.subscribe(value => {
        roleIsOrgAdmin = value.role == "student"
    });
    onMount(async () => {
        activeMenu.set('appslider');
        unsubscribe();
        let res = await api_getListOfAppSliders();
        if (res.status) {
            listAppSliders = res.data;
            console.log('list', listAppSliders);
        } else {
            console.log(res.errMsg);
        }
        _$.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
        _$.fn.filepond.registerPlugin(FilePondPluginGetFile);
        _$.fn.filepond.setOptions({

            server: {
                url: "{{ url('upload')}}",
                process: {
                    headers: {
                        'X-CSRF-TOKEN': "csrf"
                    },
                }
            }
        });
        _$(elem_uploadImageInput).filepond(filepondOptions);
        clearFields();
        // if (_id && _fileObj?.data?.originalFileName) {
        //     imagesrc = true;
        //     _$(elem_uploadImageInput).filepond("files",
        //         [
        //             {
        //                 source: '12345',
        //                 options: {
        //                     type: 'local',
        //                     file: {
        //                         name: _fileObj.data.originalFileName,
        //                         size: _fileObj.data.fileSize,
        //                         customUrl: _fileObj.data.downloadUrl,
        //                         type: 'image/*'
        //                           }
        //                 }
        //             }
        //         ]
        //     );
        // }

        _$(".may-pond .filepond--item-panel").css('background', '#369763')
        _$(elem_uploadImageInput).filepond('allowMultiple', false);
        // _$(elem_uploadImageInput).on('FilePond:addfile', function (e) {
        //     _$.fn.filepond.setOptions(server);
        // });
    })

    async function api_getListOfFiles(data) {

        let action = "storage.getUrlForUpload";
        var response = await api_handleApiCalls(action, data);
        return response;
    }

    async function api_addUpdateAppSlider(data) {

        let action = "appSlider.addUpdateAppSlider";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    let addUpdateAppSlider = async () => {
        if (isLoading) return;
        isLoading = true;
        await tick()
        //    console.log(appsliderObj);
        if (validateAppSlider()) {
            // if(tempAccessprofileId != ''){
            //     accessProfileObj['accessProfileId'] = tempAccessprofileId;
            // }
            var appSliderRes = await api_addUpdateAppSlider(appSliderObj);
            if (appSliderRes.status) {
                updateSuccess({ title: "Success", msg: appSliderRes.data });
                let res = await api_getListOfAppSliders();
                if (res.status) {
                    listAppSliders = res.data;
                    console.log('list ', listAppSliders);
                } else {
                    console.log(res.errMsg);
                }

                hideModel();


                //     setTimeout(() => {
                //     navigate(`/dashboard/studyMaterial/accessProfile/edit/${accessProfileRes.data[1]}`)
                // }, 1000);
            } else {
                updateErrors({ title: "Error", msg: appSliderRes.errMsg })

            }
        }
        isLoading = false;
        return false;
    }

    let addUpdateAppSliderName = async () => {
        if (isLoading) return;
        isLoading = true;
        await tick()

        if (validateAppSliderObj()) {

            var appSliderRes = await api_addUpdateAppSlider(appSliderObj);
            if (appSliderRes.status) {
                console.log('sdjfkdsjfslkdjfdskfjds ----------> ', appSliderRes.data[1]);
                let ress = await api_getAppSliderById(appSliderRes.data[1]);

                if (ress.status) {
                    // appSliderObj.title = 
                    console.log('sdfds ', ress);
                    appSliderObj = {
                        id: appSliderRes.data[1],
                        title: ress.data.title,
                        description: ress.data.description,
                        sequence: ress.data.sequence,
                        uploadType: ress.data.uploadType,
                        videoLink: ress.data.videoLink
                    }
                    updateSuccess({ title: "Success", msg: appSliderRes.data[0] });

                    _$("#appSlidername-add-modal").modal("hide")

                    showModel()

                } else {
                    updateErrors({ title: "Error", msg: appSliderRes.errMsg })

                }
            }
        }
        isLoading = false;
        return false;
    }


    function validateYouTubeUrl(url) {
        // var url = $('#youTubeUrl').val();
        if (url != undefined || url != '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length == 11) {
                return true;
                // Do anything for being valid
                // if need to change the url to embed url then use below line
                // $('#ytplayerSide').attr('src', 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0');
            }
            else {
                return false
                // Do anything for not being valid
            }
        }
    }

    function getId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return match && match[2].length === 11 ? match[2] : null;
    }

    function createDateForTimeline(date) {
        let timelineDate = moment(date).fromNow();
        return timelineDate;
    }

    let openAddAppSlider = () => {
        clearFields();
        _$("#appSlidername-add-modal").modal("show")
        // showModel()
        // _$('#appSlider-add-modal').show();
    }
</script>


<style>
    .youtubeVideo {
        width: 100%;
    }
</style>

<div class="content-body fadeIn">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">App Slider</li>
                </ol>
            </nav>
            <h4 class="mg-b-0">App Slider</h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Manage mobile app home screen horizontal slider.</p>
        </div>
        {#if !roleIsOrgAdmin}
        {#if MODULES.hasAccess(MODULES.ADD_APP_SLIDER) }
        <button on:click={openAddAppSlider} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <i data-feather="plus" class="wd-10 mg-r-5"></i>
            Add App Slider
        </button>
        {/if}
        {/if}
    </div>
    <hr class="full-width">
    <div data-label="Example" class="df-example">
        <div class="card-columns">
            {#if listAppSliders && listAppSliders.length}
            {#each listAppSliders as aAppSlider}
            <div class="card">
                {#if aAppSlider.uploadType == 'image' && aAppSlider.imageLink}
                <img src={aAppSlider.imageLink.origin}{aAppSlider.imageLink.pathname} class="card-img-top" alt="..." />
                {:else}
                <iframe class="youtubeVideo" src="//www.youtube.com/embed/{getId(aAppSlider.videoLink)}"
                    allowfullscreen="true">
                </iframe>
                {/if}
                <div class="card-body">
                    <h5 class="card-title">{aAppSlider.title}</h5>
                    <p class="card-text">{aAppSlider.description}</p>
                </div>
                <div class="card-footer tx-13">
                    <div class="d-flex flex-row">
                        <p class="card-text flex-grow-1"><small class="text-muted">Last updated
                                {createDateForTimeline(aAppSlider.createdOn)}</small></p>
                        <div class="flex-grow-2 row">
                            {#if MODULES.hasAccess(MODULES.VIEW_APP_SLIDER) }
                            <div data-toggle="tooltip" data-placement="right" title="Edit" data-id="{aAppSlider._id}"
                                on:click={editBtn}>
                                <a href="" class="btn btn-sm btn-primary btn-uppercase mg-5 disabled" id="notes"
                                    data-toggle="modal">
                                    <i class="far fa-edit"></i>
                                </a>
                            </div>
                            {/if}
                            {#if MODULES.hasAccess(MODULES.DELETE_APP_SLIDER) }
                            <div data-toggle="tooltip" data-placement="right" title="Delete" data-id="{aAppSlider._id}"
                                on:click={deleteBtn}>
                                <a href="" class="btn btn-sm btn-primary btn-uppercase mg-5 disabled" id="notes"
                                    data-toggle="modal">
                                    <i class="fas fa-trash-alt"></i>
                                </a>
                            </div>
                            {/if}
                        </div>
                    </div>

                </div>
            </div>
            {/each}
            {/if}

        
                  
            
        </div>
    </div>

    <div>

    </div>

    {#if !listAppSliders.length}
    <div class="d-flex align-items-center justify-content-center " style="flex-direction:column;">
        <div class="" style="width:250px">
            <img src="/images/empty.svg" class="img-fluid" alt="No Result" />
            <p class=" mg-b-0 tx-color-03" style="text-align:center;">No App Slider found.</p>
        </div>
          </div>
    {/if}

</div>

<div class="modal fade" id="appSlidername-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header pd-b-0">
                <div class="media align-text-center pd-12">
                    <span class="tx-color-04"> <i data-feather="sliders" class="wd-50 ht-50"></i></span>

                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">App slider</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Add new app slider</p>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="form-row pd-12">
                    <div class="form-group col-md-12">
                        <label class="d-block">Title</label>
                        <input bind:value={appSliderObj.title} type="text" on:keyup={(e)=> e.key === 'Enter' && addUpdateAppSliderName()}
                            on:blur={()=>clearError("{appSliderObj.title}")} class="form-control"
                          class:parsley-error={errorObj.title} placeholder="App Slider Title"
                        />
                        {#if errorObj.title}
                        <div class="parsley-errors-list">{errorObj.title}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                </div>
            </div>
            <div class="modal-footer pd-t-0">
                <div class="pd-12">
                    <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Close <i data-feather="x"
                            class="wd-10 mg-l-5"></i></button>
                    {#if MODULES.hasAccess(MODULES.UPDATE_APP_SLIDER) ||  MODULES.hasAccess(MODULES.ADD_APP_SLIDER) }
                    <button type="button" on:click={addUpdateAppSliderName} class="btn btn-primary tx-13">
                        <ButtonLoader isLoading={isLoading} btnText="SAVE" /> <i data-feather="save"
                            class="wd-10 mg-l-5"></i>
                    </button>
                    {/if}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="appSlider-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header">
                <span class="tx-color-04"> <i data-feather="sliders" class="wd-40 ht-40 stroke-wd-3 mg-b-20"></i></span>
                <h4 class="modal-title pd-l-10" id="exampleModalLabel6">Edit app slider</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label class="d-block">Title</label>
                        <input bind:value={appSliderObj.title} type="text" on:keyup={(e)=> e.key === 'Enter' && addUpdateAppSlider()}
                            on:blur={()=>clearError("{appSliderObj.title}")} class="form-control"
                        class:parsley-error={errorObj.title} placeholder="App Slider Title" />
                        {#if errorObj.title}
                        <div class="parsley-errors-list">{errorObj.title}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                    <div class="form-group col-md-12">
                        <label class="d-block">Description</label>
                        <input bind:value={appSliderObj.description} type="text" on:keyup={(e)=> e.key === 'Enter' && addUpdateAppSlider()}
                            on:blur={()=>clearError("{appSliderObj.description}")} class="form-control"
                        class:parsley-error={errorObj.description} placeholder="App Slider Description" />
                        {#if errorObj.description}
                        <div class="parsley-errors-list">{errorObj.description}</div>
                        <!-- content here -->
                        {/if}
                    </div>

                    <div class="form-group col-md-12">
                        <label class="d-block">Sequence</label>
                        <input bind:value={appSliderObj.sequence} type="number"
                            on:blur={()=>clearError("{appSliderObj.sequence}")} class="form-control"
                        class:parsley-error={errorObj.sequence} placeholder="App Slider Sequence" on:keyup={(e)=> e.key === 'Enter' && addUpdateAppSlider()}/>
                        {#if errorObj.sequence}
                        <div class="parsley-errors-list">{errorObj.sequence}</div>
                        <!-- content here -->
                        {/if}
                    </div>

                    <div class="form-group col-md-12">
                        <label class="d-block">Upload Type</label>
                        {#if errorObj.uploadType}
                        <div class="parsley-errors-list">{errorObj.uploadType}</div>
                        <!-- content here -->
                        {/if}
                        <div class="form-row">
                            <div class="custom-control mg-10 custom-radio ">
                                <input type="radio" checked={appSliderObj.uploadType=='image' } data-label="image"
                                    on:change={selectUploadType} id="image" name="uploadType"
                                    class="custom-control-input">
                                <label class="custom-control-label" for="image">Image</label>
                            </div>
                            <div class="custom-control mg-10 custom-radio ">
                                <input type="radio" checked={appSliderObj.uploadType=='video' } data-label="video"
                                    on:change={selectUploadType} id="video" name="uploadType"
                                    class="custom-control-input">
                                <label class="custom-control-label" for="video">Video</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-12 d-none" id="imageUpload">
                        <label class="d-block">Upload</label>
                        {#if errorObj.videoLink}
                        <div class="parsley-errors-list">{errorObj.videoLink}</div>
                        <!-- content here -->
                        {/if}
                        <input type="file" bind:this={elem_uploadImageInput} class="my-pond" data-instant-upload="false"
                            data-allow-image-preview="false" name="filepond" />
                    </div>
                    <div class="form-group col-md-12 d-none" id="videoUpload">
                        <label class="d-block">Youtube Video Link</label>
                        <input bind:value={appSliderObj.videoLink} type="text"
                            on:blur={()=>clearError("{appSliderObj.videoLink}")} class="form-control"
                        class:parsley-error={errorObj.videoLink} placeholder="https://www.youtube.com/" on:keyup={(e)=> e.key === 'Enter' && addUpdateAppSlider()}/>
                        {#if errorObj.videoLink}
                        <div class="parsley-errors-list">{errorObj.videoLink}</div>
                        {/if}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">CLOSE
                    <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                {#if MODULES.hasAccess(MODULES.UPDATE_APP_SLIDER) ||  MODULES.hasAccess(MODULES.ADD_APP_SLIDER) }
                <button type="button" on:click={addUpdateAppSlider} class="btn btn-primary tx-13">
                    <ButtonLoader isLoading={isLoading} btnText="SAVE" />
                    <i data-feather="save" class="wd-10 mg-l-5"></i>
                </button>
                {/if}
            </div>
        </div>
    </div>
</div>
