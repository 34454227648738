<script>
    import ButtonLoader from "../utils/button_loader.svelte";
    import api_handleApiCalls from "../utils/apiCallHandler";
    import { onMount, createEventDispatcher } from "svelte";
    import {pError, pSuccess} from '../utils/notify.svelte'
    export let subjectName;
    export let syncInSubjectId;
    export let syncInBatchId;
    export let hide;
    let isLoading;
    let elemSelectBatch;
    let elemSelectSubject;
    let batchesList = [];
    let syncWithBatchId;
    let syncWithSubjectId;
    let instanceSelectizeBatch
    let instanceSelectizeSubject;

    const dispatch = createEventDispatcher();

    $: syncInSubjectId, reFetchBatch()

    const reFetchBatch = () => {
        initSelectizeBatchSelect()
    }

    const movingIsDone = () => {
        _$('#modal-sync-subject').modal('hide')
        dispatch('refresh', {})
    }

    const proccedToSync = async () => {
        if(!syncWithSubjectId) return pError('Error', "Subject is required to sync")
        isLoading = true;
        let resData = await api_handleApiCalls("folder.syncSubWithOtherSub", {
            syncWithParentFolderId: syncWithSubjectId,
            syncInBatchId: syncInBatchId,
            syncInParentFolderId: syncInSubjectId,
        });
        isLoading = false;
        if(resData.status){
            movingIsDone()
            pSuccess('Success', "Data sync success")
        } else {
            pError('Error', resData.errMsg)
        }
    };

    async function api_getBatchesList() {
        isLoading = true;
        let resData = await api_handleApiCalls("batch.getMyBatches", {});
        isLoading = false;
        if (resData.status) {
            let batches = resData.data;
            return batches.filter(batch=>batch._id != syncInBatchId)
        } else {
            return [];
        }
    }
    async function api_getSubjectList(batchId) {
        isLoading = true;
        var resData = await api_handleApiCalls("folder.getSubjectsForBatch", {
            batchId,
        });
        isLoading = false;
        if (resData.status) {
            return resData.data;
        } else {
            return [];
        }
    }

    async function bindSelect2s(batchesList) {
        syncWithSubjectId = ""
        if(instanceSelectizeBatch) instanceSelectizeBatch.destroy()
        instanceSelectizeBatch  =   _$(elemSelectBatch).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            persist: false,
            options: batchesList,
            onChange: async function (values) {
                syncWithBatchId = values;
                bindSelectSubject(syncWithBatchId);
            },
        });
        instanceSelectizeBatch = instanceSelectizeBatch[0]?.selectize
    }

    const bindSelectSubject = async (syncInBatchId) => {
        syncWithSubjectId = "";
        let subjectList = [];
        if (syncInBatchId)
            subjectList = await api_getSubjectList(syncInBatchId);

        if (instanceSelectizeSubject) instanceSelectizeSubject.destroy();
        instanceSelectizeSubject = _$(elemSelectSubject).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            persist: false,
            options: subjectList,
            onChange: async function (values) {
                syncWithSubjectId = values;
            },
        });
        instanceSelectizeSubject = instanceSelectizeSubject[0]?.selectize;
    };

    async function initSelectizeBatchSelect() {
        batchesList = await api_getBatchesList();
        bindSelect2s(batchesList);
        bindSelectSubject();
    }

    onMount(() => {
        initSelectizeBatchSelect();
    });
</script>

    <a
        href="#modal-sync-subject"
        data-toggle="modal"
        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 animated fade-in"
        style="justify-content: space-between;font-weight: 500; font-size: 11px; padding: 6px 20px;"
        data-animation="effect-scale"
    >
        <i data-feather="refresh-cw" class="wd-10 mg-r-5" /> Sync with other subject
    </a>

<!---Model for edit-->
<div
    class="modal effect-scale"
    id="modal-sync-subject"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel6"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body pd-25">
                <a
                    href=""
                    role="button"
                    class="close pos-absolute t-15 r-15 z-index-10"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </a>
                <h4>Sync Subject</h4>
                <p>Select Subject from batch to Sync current subject with</p>
                <hr class="full-width" />
                <div class="mr-t-10 mr-b-10">
                    <div class="form-group">
                        <label class="d-block">Select Batch:</label>
                        <select
                            disabled={isLoading}
                            bind:this={elemSelectBatch}
                            class="form-control"
                        >
                            <option>Select batches</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="d-block">Select Subject:</label>
                        <select
                            disabled={isLoading || !syncWithBatchId}
                            bind:this={elemSelectSubject}
                            class="form-control"
                        >
                            <option>Select Subject</option>
                        </select>
                    </div>
                </div>
                <div class="d-md-flex justify-content-end tx-12 mg-t-30">
                    <button
                        type="button"
                        class="btn  pd-x-15 btn-secondary tx-12"
                        data-dismiss="modal"
                        >CANCEL
                        <i data-feather="x" class="wd-10 mg-l-5 " />
                    </button>
                    <button
                        type="button"
                        disabled={isLoading}
                        proceed="no"
                        on:click={proccedToSync}
                        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                    >
                        <ButtonLoader {isLoading} btnText="Proceed to sync" />
                        <i data-feather="arrow-right" class="wd-10 mg-l-5" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
