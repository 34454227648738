<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import { activeMenu } from "../../stores";
    import ButtonLoader from "../../utils/button_loader.svelte"
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import MODULES from "../../utils/modules.js"
    import { API_URL } from "../../../config.js"
    let displayTitle;
    export let isAdmin
    let userIdArr;
    let isLoading
    let emptyState = false;
    let elemLeaveRequestTable
    let elemModalNewLeaveRequest
    let elemLeaveForSelectize
    let instanceLeaveForSelectize
    let selectizeBatchInstance1
    let filterElemBatch1;
    let dataTableInstance
    let filterModalThis
    let approval, staffName, startDate, createdOn
    let todays_date
    let minTomorrow
    let errorObj = {}
    let isSortByName
    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    }
    let multipleDayLeaveReason
    const initialLeaveRequestObj = {
        startDate: '',
        leaveDuration: 'singleDay',
        startDateSession: 'fullDay',
        endDate: '',
        leaveFor: '',
        endDateSession: 'fullDay',
        multipleDayLeaveReason: ''
    }
    let leaveRequestObj = { ...initialLeaveRequestObj }


    let showNewLeaveRequestModal = (action = true) => {

        action ? _$(elemModalNewLeaveRequest).modal('show') : _$(elemModalNewLeaveRequest).modal('hide');
        if (action) {
            _$(elemModalNewLeaveRequest).off("hidden.bs.modal")
            _$(elemModalNewLeaveRequest).on("hidden.bs.modal", () => {
                clearFormData()
            })
            // _$(elemModalNewLeaveRequest).on("modal.bs.hide", () => {
            //     clearFormData()
            // })
        }
    }


    function hideFilterModel(params) {
        _$("#filter-modal").modal("hide");
        assignmentObj.topic = "";
    }
    function showFilterModel(params) {
        _$("#filter-modal").modal("show");
    }


    let showHideFilterModal = (action) => {

        action ? (_$(filterModalThis).modal('show')) : (_$(filterModalThis).modal('hide'))
    }

    var handleDeleteBtnClick = async (id) => {


        deleteEnquiryObj = {}
        let aEnquiry = await getEnquiryNameId(id)

        if (aEnquiry) {
            if (id === aEnquiry._id) {

                deleteEnquiryObj = aEnquiry
                archiveEnquiryId = aEnquiry._id
                showDeleteEnquiryModal(true)

            }

        }


        await tick()


    }

    var handleSaveBtnClick = async () => {
        // if(isLoading) return
        // isLoading = true
        leaveRequestObj.leaveFor = _$(instanceLeaveForSelectize).val()

        let validData = getValidateLeaveRequest(leaveRequestObj)
        validData.multipleDayLeaveReason = leaveRequestObj.multipleDayLeaveReason
        if (validData) {
            api_createLeaveRequest(validData)
        }
        // isLoading=  false



    }

    async function api_getById(data) {
        let resData = await api_handleApiCalls("leaveRequest.getById", data)
        if (resData.status) {
            return resData.data
        } else {
            pError("Error!", resData.errMsg)
            return null
        }
    }
    async function api_ReportToList(data = { isSortByName: true, noPagination: true }) {
        var resData = await api_handleApiCalls('systemUser.fetchSystemUserList', data)
        console.log("resData>>", resData);
        if (resData.status) {

            return resData.data.data
        } else {
            return []
        }
    }


    async function setSelectizeLeaveFor() {
        let orgUsers = []

        let response = await api_handleApiCalls('leaveRequest.getOrgUsers', { isSortByName: true })
        if (response.status) {

            if (response.data.length == 0) pError("Error!", "User not found, Please add some users first")

            orgUsers = response.data

        } else {
            pError("Error!", response.errMsg)
            orgUsers = []
        }

        initSelectizeLeaveFor(orgUsers)

    }

    async function initSelectizeLeaveFor(orgUsers = []) {
        instanceLeaveForSelectize = _$(elemLeaveForSelectize).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            persist: false,
            options: orgUsers,
            render: {
                option_create: function (data, escape) {
                    return (
                        '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
                        escape(data.input) +
                        "</strong></div>"
                    );
                },
            },
            onBlur: function () {
                clearError('recipient')
            },
            onChange: async function (values) {
            },
        });
    }

    async function api_createLeaveRequest(data) {
        if (isLoading) return
        isLoading = true
        var response = await api_handleApiCalls('leaveRequest.create', data)

        if (response.status) {

            clearFormData()
            pSuccess("Success!", response.data)
            isLoading = false
            redrawLeaveRequestTable()
            showNewLeaveRequestModal(false)
        } else {
            isLoading = false

            errorObj.apiError = response.errMsg
            pError("Error!", response.errMsg)
        }
    }

    async function api_deleteLeaveRequest(data) {
        var response = await api_handleApiCalls('leaveRequest.delete', data)

        if (response.status) {
            pSuccess("Success!", response.data)
            redrawLeaveRequestTable()
        } else {
            pError("Error!", response.errMsg)
        }
    }
    function deleteLeaveRequest(id) {
        pConfirm("Leave Request Delete!", "Are you sure you want to delete this leave request", function () {
            api_deleteLeaveRequest({ _id: id })
        })
    }

    async function api_approveLeaveRequest(data) {
        var response = await api_handleApiCalls('leaveRequest.approval', data)

        if (response.status) {
            pSuccess("Success!", response.data)
            redrawLeaveRequestTable()
        } else {
            pError("Error!", response.errMsg)
        }
    }
    function approveLeaveRequest(id, approval, userId) {
        let data = {
            _id: id,
            approval: approval,
            userId: userId
        }

        let title = approval ? 'Approve' : 'Cancel'
        pConfirm("Leave Request " + title + "!", "Are you sure you want to " + title + " this leave request", function () {
            api_approveLeaveRequest(data)
        })

    }

    async function editLeaveRequest(id) {
        displayTitle = "Edit leave request";
        let resObj = await api_getById({ _id: id })

        if (resObj && (id == resObj._id)) {

            let formatedStartDate = formatDate(resObj.startDate)
            let formatedEndDate = resObj.endDate ? formatDate(resObj.endDate) : ''
            leaveRequestObj = {
                _id: resObj._id,
                startDate: formatedStartDate,
                startDateSession: resObj.startDateSession,
                endDate: formatedEndDate,
                endDateSession: resObj.endDateSession ? resObj.endDateSession : '',
                leaveFor: resObj.leaveFor,
                leaveDuration: resObj.leaveDuration,
                multipleDayLeaveReason: resObj.multipleDayLeaveReason
            }
            if (instanceLeaveForSelectize) instanceLeaveForSelectize[0].selectize.setValue(resObj.leaveFor)
            await tick()
            showNewLeaveRequestModal(true)
        }

    }

    async function bindSelect2() {


        selectizeBatchInstance1 = _$(filterElemBatch1).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "select faculty",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: userIdArr,
        });
    }
    function getValidateLeaveRequest(data) {
        var newErrorObj = {}
        const {
            startDate,
            leaveDuration,
            startDateSession,
            endDate,
            leaveFor,
            endDateSession,
        } = data




        if (isAdmin) {
            if (!leaveFor && !Boolean(leaveFor)) newErrorObj.leaveFor = 'Please Select "Leave For" User.';
        }

        if (!leaveDuration && !Boolean(leaveDuration)) newErrorObj.leaveDuration = "Please Select Leave Duration.";

        if (leaveDuration == 'singleDay') {

            if (!startDate && new Date(startDate).toString().toLowerCase() == "invalid date") newErrorObj.startDate = "Invalid Start Date";
            if (!startDateSession && startDateSession == "") newErrorObj.startDateSession = "Please Select Start Date Session";
        }

        if (leaveDuration == 'multipleDay') {

            if (!endDate && new Date(endDate).toString().toLowerCase() == "invalid date") newErrorObj.endDate = "Invalid End Date";
            if (!endDateSession && endDateSession == "") newErrorObj.endDateSession = "Please Select End Date Session";

        }

        errorObj = newErrorObj
        if (Object.keys(errorObj).length > 0) {

            pError("Validation Error!", Object.values(errorObj)[0])
            return null;
        }
        return {
            startDate: new Date(startDate),
            leaveDuration,
            startDateSession,
            endDate: new Date(endDate),
            leaveFor,
            endDateSession,
            _id: data._id
        }
    }



    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');

    }

    function redrawLeaveRequestTable() {
        if (dataTableInstance) { dataTableInstance.draw() }
    }

    function clearFilter() {
        approval = null;
        selectizeBatchInstance1[0].selectize.clear()
        startDate = null;
        createdOn = null
        redrawLeaveRequestTable();
    }


    function applyFilter() {
        redrawLeaveRequestTable();
    }
    function getFilterData() {
        let userIdArr = _$(selectizeBatchInstance1).val()
        return {
            userIdArr,
            approval,
            startDate, createdOn
        }
    }




    function getFilteredOrder(data) {
        var sort
        try {

            if (Boolean(data)) {
                let index = data.order[0].column
                let dir = data.order[0].dir

                sort = {
                    name: data.columns[index].data,
                    dir: dir
                }
            }

        } catch (error) {

        }
        return sort
    }

    function clearFormData() {
        leaveRequestObj = { ...initialLeaveRequestObj }
        if (instanceLeaveForSelectize) instanceLeaveForSelectize[0].selectize.clear()
    }


    function capitalizeFirstLetter(string) {
        string = string ? string : 'A'
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    function getFormatedDate(date) {
        if (!date && date == '') {
            return '-'
        }
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');

    }

    function parseSorts(sortOrder) {
        // parses datatable order to mongodb sort object
        let sort = {};
        // sorting orders
        let order;
        if (sortOrder.dir === "desc") order = 1;
        else order = -1;
        if (sortOrder.column === 1) {
            sort.username = order;
        } else if (sortOrder.column === 2) {
            sort.leaveDuration = order;
        } else if (sortOrder.column === 3) {
            sort._id = order;
        } else if (sortOrder.column === 4) {
            sort.approval = order;
        } else if (sortOrder.column === 5) {
            sort.createdByUserName = order;
        } else if (sortOrder.column === 6) {
            sort.createdOn = order;
        } else {
            sort._id = order;
        }
        return sort;
    }

    function bindDatatable() {
        dataTableInstance = _$(elemLeaveRequestTable).DataTable({
            stateSave: true,
            "stateSaveCallback": function (settings, data) {
                localStorage.setItem(window.location.pathname, JSON.stringify(data))
            },
            "stateLoadCallback": function (settings) {
                return JSON.parse(localStorage.getItem(window.location.pathname))
            },

            "searching": false,
            "bLengthChange": false,
            responsive: true,
            "order": [[3, "desc"]],
            language: {
                processing: "",

            },
            ajax: function (data, callback, settings) {
                data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
                var filterObj = { ...getFilterData() }
                filterObj.start = data.start
                filterObj.length = data.length
                filterObj.search = data.search.value
                filterObj.draw = data.draw
                filterObj.sort = data.sort;
                _$.ajax({
                    url: API_URL,
                    method: 'post',
                    data: {
                        action: 'leaveRequest.list',
                        accessToken: localStorage.getItem("accessToken"),
                        data: filterObj
                    },
                    success: function (resData) {
                        var result = []
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(paginationData.filteredCount),
                                data: paginationData.data
                            }
                            if (!resData.data.length) {
                                emptyState = true
                            }
                            callback(result);
                        }
                        else {
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        emptyState = true
                    }
                })
            },
            columns: [
                {
                    data: 'username',
                    defaultContent: '<span>-</span>',
                    render: (data, type, full) => {
                        let image;
                        // if image found display profilePic avatar
                        image = `<img src="${full.profilePic ? full.profilePic : "/images/man.png"
                            }" class="avatar mg-r-15" alt="${data[0].toUpperCase()}">`;
                        return `
                            <div class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0" >
                                ${image}
                            <div class="wd-80p bg-transparent btn-view-display" data-id="${full._id}">
                            <h6 class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent">${data || "-"
                            }</h6>
                            <span class="d-block tx-11 text-muted bg-transparent">
                            <i data-feather="phone"></i> 
                            ${full.mobileNo || "-"}
                            </span>
                            </div>
                            </div>`;
                    },
                },
                {
                    data: 'leaveDuration',
                    defaultContent: '-',
                    render: function (data, type, row) {
                        var leaveDuration = '<span class="tx-12 badge badge-pill badge-dark">Multiple Day</span>'

                        if (data == 'singleDay')
                            leaveDuration = '<span class="tx-12 badge badge-pill badge-light">Single Day</span>'

                        return leaveDuration
                    }
                },
                {
                    data: '_id',
                    render: function (data, type, row) {
                        var startLeaveSession = 'Full Day'
                        var startColor = 'bd-warning'

                        if (row.startDateSession == 'firstHalf') { startLeaveSession = 'First Half'; startColor = 'bd-primary' }

                        if (row.startDateSession == 'secondHalf') { startLeaveSession = 'Second Half'; startColor = 'bd-primary' }


                        var leaveDetails = `
                        <div class="mg-b-15">
                            <div>Start Date: ${getFormatedDate(row.startDate)}</div> 
                            <div class="d-flex align-items-center">
                                <span class="wd-12 ht-12 rounded-circle bd bd-3 ${startColor} mg-r-8"></span>
                                <span>${startLeaveSession}</span>   
                            </div>  
                        </div>
                    `
                        if (row.leaveDuration == 'multipleDay') {
                            var endLeaveSession = 'Full Day'

                            if (row.endDateSession == 'firstHalf') { endLeaveSession = 'First Half'; startColor = 'bd-primary' }

                            if (row.endDateSession == 'secondHalf') { endLeaveSession = 'Second Half'; startColor = 'bd-primary' }

                            leaveDetails += `<div>
                            <div>End Date: ${getFormatedDate(row.endDate)}</div>   
                            <div class="d-flex align-items-center">
                                <span class="wd-12 ht-12 rounded-circle bd bd-3 ${startColor} mg-r-8"></span>
                                <span>${endLeaveSession}</span>   
                            </div>   
                        </div>`
                        }
                        return leaveDetails
                    }
                },
                {
                    data: 'approval',
                    render: function (data, a, b, c) {
                        var renderHtml = '<span>PENDING</span>'

                        if (data)
                            renderHtml = '<span>' + data.toUpperCase() + '</span>'

                        return renderHtml

                    }
                },
                {
                    data: 'createdByUserName',
                },
                {
                    data: 'createdOn',
                    render: function (data, a, b, c) {


                        var date = '<span>-</span>'
                        if (data) {
                            date = getFormatedDate(data)
                        }

                        return date
                    }
                },

                {
                    data: '_id', render: function (data, type, row) {
                        var htmlStr = ''
                        if (!isAdmin && row.approval == 'approved') {
                            return `-`
                        }
                        if (MODULES.hasAccess(MODULES.EDIT_LEAVES)) {
                            if (isAdmin && row.approval == 'pending') {
                                htmlStr = `
                                    <a href="#" class="approve mg-r-5 btn-approve-leave" leave-id="${data}" user-id="${row.userId}"data-toggle="tooltip" data-placement="top" title="Approve Leave Request"><i class="wd-20" data-feather="check-circle"></i></a>
                                    <a href="#" class="cancel mg-r-5 btn-cancel-leave" leave-id="${data}" user-id="${row.userId}" data-toggle="tooltip" data-placement="top" title="Cancel Leave Request"><i class="wd-20" data-feather="x-circle"></i></a>
                                `
                            }

                            if (isAdmin && row.approval == 'cancel')
                                htmlStr = `<a href="#" class="approve mg-r-5 btn-approve-leave" leave-id="${data}"  user-id="${row.userId}" data-toggle="tooltip" data-placement="top" title="Approve Leave Request"><i class="wd-20" data-feather="check-circle"></i></a>`

                            if (isAdmin && row.approval == 'approved')
                                htmlStr = `<a href="#" class="cancel mg-r-5 btn-cancel-leave" leave-id="${data}" user-id="${row.userId}" data-toggle="tooltip" data-placement="top" title="Cancel Leave Request"><i class="wd-20" data-feather="x-circle"></i></a>`
                        }

                        var edit = "-"
                        var del = ""
                        if (MODULES.hasAccess(MODULES.EDIT_LEAVES) && !(MODULES.hasAccess(MODULES.DELETE_LEAVES))) {
                            edit = `<a href="#" class="rename mg-r-5 btn-edit-leave" leave-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-20" data-feather="edit"></i></a>`
                        } else if (MODULES.hasAccess(MODULES.DELETE_LEAVES) && !(MODULES.hasAccess(MODULES.EDIT_LEAVES))) {
                            del = `  <a href="#" class="delete mg-r-5 btn-remove-leave" leave-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete"><i class="wd-20" data-feather="trash"></i></a>`
                        } else if (!(MODULES.hasAccess(MODULES.DELETE_LEAVES)) && !(MODULES.hasAccess(MODULES.EDIT_LEAVES))) {
                            edit = "-"
                            del = ""
                        } else {
                            edit = `<a href="#" class="rename mg-r-5 btn-edit-leave" leave-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-20" data-feather="edit"></i></a>`
                            del = `  <a href="#" class="delete mg-r-5 btn-remove-leave" leave-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete"><i class="wd-20" data-feather="trash"></i></a>`
                        }

                        return `
                        <div class="d-md-flex flex-row">
                            ${edit}
                            ${del}
                            ${htmlStr}
                        </div>
                        `;


                    }
                },

            ],
            columnDefs: [
                { orderable: false, targets: [1, -1] }
            ],
            "drawCallback": function () {
                feather.replace({ width: "1em", height: "1em" })
                _$('.btn-edit-leave').unbind('click').click(function (e) {
                    e.preventDefault();
                    var leaveId = _$(this).attr("leave-id");
                    editLeaveRequest(leaveId)
                })
                _$('.btn-view-display').click(function () {
                    var leaveId = _$(this).attr("data-id");
                    editLeaveRequest(leaveId)
                })
                _$('.btn-approve-leave').unbind('click').click(function (e) {
                    e.preventDefault();
                    var leaveId = _$(this).attr("leave-id");
                    var userId = _$(this).attr("user-id")
                    approveLeaveRequest(leaveId, true, userId)

                })

                _$('.btn-cancel-leave').unbind('click').click(function (e) {
                    e.preventDefault();
                    var leaveId = _$(this).attr("leave-id");
                    var userId = _$(this).attr("user-id")

                    approveLeaveRequest(leaveId, false, userId)

                })

                _$('.btn-remove-leave').unbind('click').click(function (e) {
                    e.preventDefault();
                    var leaveId = _$(this).attr("leave-id");

                    deleteLeaveRequest(leaveId)

                })
            },
            "processing": true,
            "serverSide": true,
        });
        _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
    }

    function bindUiEvents() {

    }
    function clearDeleteEnquiryObj() {
        deleteEnquiryObj = {
            _id: null,
            name: '',
            mobileNo: ''
        }
    }

    async function setInitialDate() {
        let _tomorrows_date = new Date()
        _tomorrows_date.setDate(_tomorrows_date.getDate() + 1)

        todays_date = formatDate(new Date().toString())
        minTomorrow = formatDate(_tomorrows_date.toString())
        initSelectizeLeaveFor.endDate = formatDate(_tomorrows_date.toString())
        initSelectizeLeaveFor.startDate = todays_date
        await tick()
    }
    onMount(async () => {
        feather.replace({ width: "1em", height: "1em" });
        activeMenu.set('leaveRequest')
        userIdArr = await api_ReportToList()
        initDashboard();
        bindSelect2();
        bindUiEvents()
        setInitialDate()
        bindDatatable()

        if (isAdmin)
            setSelectizeLeaveFor()
    });

</script>
<style>
    .empty-state-img {
        text-align: center;
        width: 100%;
        left: 50%;
    }
</style>

<!-- Modal -->
<div class="modal effect-scale" bind:this={elemModalNewLeaveRequest} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
            <div class="modal-body pd-0">

                <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                    <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="media align-items-center">
                        <span class="tx-color-03 d-none d-sm-block"><i data-feather="briefcase"
                                class="wd-60 ht-60"></i></span>
                        <div class="media-body mg-sm-l-20">
                            <h4 class="tx-18 tx-sm-20 mg-b-2">{displayTitle}</h4>
                            <p class="tx-13 tx-color-03 mg-b-0">Create new leave request</p>
                        </div>
                    </div><!-- media -->
                </div><!-- modal-header -->

                <div class="mg-20">
                    <div class=" tx-color-02">

                        {#if isAdmin}
                        <div class="form-group">
                            <label class="d-block">Leave For<span class="tx-danger">*</span></label>
                            <select bind:this={elemLeaveForSelectize} class="info slectizedd form-control"
                                placeholder="Select user">
                                <option value="">Select User</option>
                            </select>
                        </div>
                        {/if}
                        <div class="form-group mg-t-30">
                            <label class="d-block">Leave Duration<span class="tx-danger">*</span></label>
                            <div class="d-flex mg-t-10">
                                <div class="custom-control custom-radio mg-r-10">
                                    <input type="radio" id="leaveD1" bind:group={leaveRequestObj.leaveDuration}
                                        class="custom-control-input" value='singleDay'>
                                    <label class="custom-control-label" for="leaveD1">Single Day</label>
                                </div>

                                <div class="custom-control custom-radio mg-r-10">
                                    <input type="radio" bind:group={leaveRequestObj.leaveDuration} id="leaveD2"
                                        class="custom-control-input" value='multipleDay'>
                                    <label class="custom-control-label" for="leaveD2">Multiple Day</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="d-block">Start Date</label>
                            <input type="date" onkeydown="return false" class="form-control" min={todays_date}
                                bind:value={leaveRequestObj.startDate}>
                        </div>
                        <div class="form-group row pd-l-10">

                            <div class="custom-control custom-radio col-sm-4">
                                <input type="radio" id="startDay1" bind:group={leaveRequestObj.startDateSession}
                                    class="custom-control-input" value='fullDay'>
                                <label class="custom-control-label" for="startDay1">Full Day</label>
                            </div>

                            <div class="custom-control custom-radio col-sm-4">
                                <input type="radio" bind:group={leaveRequestObj.startDateSession} id="startDay2"
                                    class="custom-control-input" value='firstHalf'>
                                <label class="custom-control-label" for="startDay2">First Half</label>
                            </div>
                            <div class="custom-control custom-radio col-sm-4">
                                <input type="radio" bind:group={leaveRequestObj.startDateSession} id="startDay3"
                                    class="custom-control-input" value='secondHalf'>
                                <label class="custom-control-label" for="startDay3">Second Half</label>
                            </div>
                        </div>


                        {#if leaveRequestObj.leaveDuration != 'singleDay'}
                        <div class="form-group mg-t-30 animated fadeIn">
                            <label class="d-block">End Date</label>
                            <input type="date" onkeydown="return false" class="form-control" min={todays_date}
                                bind:value={leaveRequestObj.endDate}>
                        </div>
                        <div class="form-group row pd-l-10">

                            <div class="custom-control custom-radio col-sm-4">
                                <input type="radio" id="endDay1" bind:group={leaveRequestObj.endDateSession}
                                    class="custom-control-input" value='fullDay'>
                                <label class="custom-control-label" for="endDay1">Full Day</label>
                            </div>

                            <div class="custom-control custom-radio col-sm-4">
                                <input type="radio" bind:group={leaveRequestObj.endDateSession} id="endDay2"
                                    class="custom-control-input" value='firstHalf'>
                                <label class="custom-control-label" for="endDay2">First Half</label>
                            </div>
                            <div class="custom-control custom-radio col-sm-4">
                                <input type="radio" bind:group={leaveRequestObj.endDateSession} id="endDay3"
                                    class="custom-control-input" value='secondHalf'>
                                <label class="custom-control-label" for="endDay3">Second Half</label>
                            </div>
                        </div>

                        <div class="form-group mg-t-30 animated fadeIn">
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <label class="d-block">Reason</label>
                                    <textarea rows="2" type="text" id="reason" class="form-control"
                                        bind:value={leaveRequestObj.multipleDayLeaveReason} />
                                </div>
                            </div>
                        </div>
                        {/if}
                    </div>
                </div>
                <div class="d-md-flex justify-content-end tx-12 mg-t-35 pd-20">
                    <button type="button" disabled={isLoading} class="btn btn-secondary tx-12 mg-r-5"
                        data-dismiss="modal">
                        CLOSE <i data-feather="x" class="wd-10 mg-l-5"></i></button>
                    <button type="button" disabled={isLoading} proceed="no" on:click={handleSaveBtnClick}
                        class="btn btn-sm pd-x-15 btn-primary btn-uppercase">
                        <ButtonLoader isLoading={isLoading} btnText={Boolean(leaveRequestObj._id) ? `Save & Update
                            Request` : `Save & Create Request`} />
                        <i data-feather="save" class="wd-10 mg-l-5"></i>
                    </button>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

<!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <div class="form-group">
                    <label class="d-block">Staff Name:</label>
                    <select disabled={isLoading} bind:this={filterElemBatch1} class="form-control select2"
                        multiple="multiple">
                        <option> Select Staff Name</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="d-block">Leave Date:</label>
                    <input type="date" onkeydown="return false" bind:value={startDate}
                        class="form-control follow-up-date" placeholder="Student created on date" />
                </div>
                <div class="form-group">
                    <label class="d-block">Approval status:</label>
                    <!-- <input type="text" class="form-control follow-up-date" placeholder="Approval status"/> -->
                    <div class="form-row">
                        <div class="form-group col-md-6 m-0">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="approvalStatusApprove" value="approved" name="approvalStatus"
                                    bind:group={approval} class="custom-control-input">
                                <label class="custom-control-label" for="approvalStatusApprove">Approved</label>
                            </div>
                        </div>
                        <div class="form-group col-md-6 m-0">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="approvalStatusNotApprove" value="pending" name="approvalStatus"
                                    bind:group={approval} class="custom-control-input">
                                <label class="custom-control-label" for="approvalStatusNotApprove">Pending</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="d-block">Created On Date:</label>
                    <input type="date" onkeydown="return false" bind:value={createdOn}
                        class="form-control follow-up-date" placeholder="Student created on date" />
                </div>
                <div class="d-md-flex justify-content-end mg-t-25">
                    <button disabled={isLoading} on:click={clearFilter}
                        class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal">CLEAR
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button disabled={isLoading} on:click={applyFilter} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
                        data-dismiss="modal">
                        <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                        <i data-feather="filter" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

<div class="content-body fadeIn">


    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Leave Request</li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">Leave Request</h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Create a new application for leave or
                search.</p>
        </div>
        <div class="d-block">
            {#if MODULES.hasAccess(MODULES.ADD_LEAVES)}
            <a href="#" on:click={()=>{
                displayTitle="Leave request";
                showNewLeaveRequestModal()
                }} data-toggle="modal" class="btn btn-sm pd-x-15 tx-white btn-primary btn-uppercase mg-l-5"
                data-animation="effect-scale">
                <i data-feather="briefcase" class="wd-10 mg-r-5"></i> New Leave Request
            </a>
            {/if}
            <button on:click={()=>{showHideFilterModal(true)}} class="btn btn-sm mg-l-10 pd-x-15 btn-primary
                btn-uppercase"> <i data-feather="filter" class="mg-r-5"></i> Filters</button>
        </div>
    </div>
    <hr class="full-width">
    <div class="row row-xs">

        <div class="col-sm-12">
            <div class="mg-b-25 mg-t-15">
                <div class="df-example demo-table">
                    <table bind:this={elemLeaveRequestTable} class="table mg-b-0" style="width: 100%;">
                        <thead>
                            <tr>
                                <th class="">Name</th>
                                <th class="">Leave Duration</th>
                                <th class="">Leave Details</th>
                                <th class="">Leave Approval</th>
                                <th class="">Created By</th>
                                <th class="">Created On</th>
                                <th class="">Actions </th>
                            </tr>
                        </thead>
                        <tbody></tbody>
                    </table>
                </div>
                <div class="d-flex justify-content-center">
                    {#if emptyState }
                    <div class="empty-state-img wd-200">
                        <img src="/images/empty.svg" style="width: 200px" alt="empty" />
                        <p class="tx-bold">No leave request found</p>
                    </div>
                    {/if}
                </div>
            </div>
        </div>
    </div>



</div>
