<script>
    import { Router, Link, Route } from "svelte-routing";
    import Assignment from "./addUpdate/addUpdateAssignment.svelte";
    import List from "./list/listAssignment.svelte";
    import { onMount } from "svelte";

    function init() {
    console.log("redirecting ");
    feather.replace();
  }
  onMount(init);

</script>

<Router>
    <Route path="">
        <List></List>
    </Route>
    <Route path="edit/:id" let:params>
        <Assignment _id="{params.id}"></Assignment>
    </Route>
   
</Router>
