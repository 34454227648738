<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, onDestroy, tick, afterUpdate, getContext } from "svelte";
  import { pSuccess, pError, pAlert } from "../../utils/notify.svelte";
  import { activeMenu } from "../../stores";
  import ButtonLoader from "../../utils/button_loader.svelte"
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js"
  import { API_URL } from "../../../config.js"
  let elem_composeScript;
  let displaytitle;
  let faqByUser = {
    question: "",
    answer: ""
  }
  let errorDetails = {};
  let scriptData;
  let isLoading = false;
  async function clearValidations() {
    errorDetails = {}
    await tick();
  }
  async function clearQuestionValidation() {
    delete errorDetails["question"];
    errorDetails = errorDetails;
    await tick();
  }

  async function clearAnswerValidation() {
    console.log("answer validation")
    delete errorDetails["answer"];
    errorDetails = errorDetails;
    await tick();
  }

  let displayTitle = `FAQ's`;
  // let breadCrumbTitle;
  // let faqArr = [];
  let data = {};
  let demoDataTable;
  let testDataTable;
  let toolbar = [
    [
      "NewPage",
      "Preview",
      "-",
      "Bold",
      "Italic",
      "Strike",
      "NumberedList",
      "BulletedList",
      "-",
      "Table",
      "HorizontalRule",
      "Styles",
      "Format",
      "Maximize",
      "Image"

    ]
  ]

  function validate(data) {
    clearValidations();
    console.log('validation function');
    var regexpStartingWithAlphabet = new RegExp(`^[A-Za-z]`);


    data.question = data.question.trim();
    if (!Boolean(data.question)) {
      errorDetails.question = "question can not be empty";
    } else if (!data.question.match(regexpStartingWithAlphabet)) {
      errorDetails.question = "question must begin with A-Z or a-z characters";
    } else if (data.question.length < 5 && data.question.length > 50) {
      errorDetails.question = "question should be minimum 5 characters long and must be smaller than 50 chars";
    }
    console.log('validation for question');
    data.answer = CKEDITOR.instances.script_editor.getData().trim();
    if (!Boolean(data.answer)) {
      errorDetails.answer = "Answers cannot be empty";
    }
    else if (!data.answer.match()) {
      errorDetails.answer = "question must begin with A-Z or a-z characters";
    } console.log('validation for answer');
    if (Object.values(errorDetails).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async function api_saveFaqParam(data) {

    let action = "faq.saveFAQ";
    var response = await api_handleApiCalls(action, data);
    if (response.errMsg) {
      PNotify.error({
        title: 'Error',
        text: response.errMsg,
        delay: 3000
      })
    } else {
      PNotify.success({
        title: 'success',
        text: "successfully added the FAQ",
        delay: 3000
      })
      testDataTable.ajax.reload();
      _$(elem_composeScript).modal("hide");
      resetFaqObject();
    }
    return response;
  }

  let addNewFaq = async function () {
    displaytitle = "FAQ";
    faqByUser = {
      question: "",
      answer: ""
    }
    _$(elem_composeScript).modal("show");

  }

  let editOldFaq = async function (faq_id) {
    displaytitle = "Edit previous FAQ";
    var response = await api_getFaqById(faq_id);
    if (response && response.question) {
      faqByUser.question = response.question;
      faqByUser.answer = response.answer;
      CKEDITOR.instances.script_editor.setData(faqByUser.answer)
      faqByUser._id = response._id;
      _$(elem_composeScript).modal("show");
    }
  }

  let onFaqAdd = async function () {
    if (isLoading) return;
    isLoading = true;
    await tick()
    faqByUser.answer = CKEDITOR.instances.script_editor.getData();
    var data = {};
    console.log(faqByUser);
    let isValid = await validate(faqByUser);
    if (!isValid) {
      isLoading = false;
      return false;
    }
    let res = await api_saveFaqParam(faqByUser)
    if (res.data?.insertedId) {
      selectedId = res.data.insertedId;
      mode = "edit";
    }
    isLoading = false;
  }
  async function api_deleteFaqByID(_id) {

    let action = "faq.removeFAQ";
    var response = await api_handleApiCalls(action, { _id });
    return response.data.data;

  }

  let resetFaqObject = function () {
    faqByUser = {
      question: "",
      answer: ""
    }
    CKEDITOR.instances.script_editor.setData(faqByUser.answer)
  }

  async function api_getFaqById(_id) {

    let action = "faq.fetchFAQ";
    var response = await api_handleApiCalls(action, { _id: _id });
    return response.data;

  }


  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.seqId = order;
    } else if (sortOrder.column === 2) {
      sort.question = order;
    } else if (sortOrder.column === 3) {
      sort.answer = order;
    } else {
      sort._id = order;
    }
    return sort;
  }

  onMount(async function () {
    console.log("check check");
    feather.replace();
    activeMenu.set('faq')
    bindDatatable()
    CKEDITOR.replace("script_editor", {
      toolbar: toolbar,
      height: "250px"
    });
    _$(elem_composeScript).on('hidden.bs.modal', function (e) {
      resetFaqObject();
    });
    CKEDITOR.instances.script_editor.on('focus', function (event) {
      clearAnswerValidation();
    });
  });

  function bindDatatable() {
    testDataTable = _$(demoDataTable).DataTable({
      responsive: true,
      language: {
        processing: "",
        searchPlaceholder: 'FAQ',
        sSearch: '',
        lengthMenu: '_MENU_ FAQ/page',
      },
      ajax: function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: 'faq.fetchFAQList',
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: data.length,
              search: data.search.value,
              sort: data.sort
            }
          },
          success: function (resData) {
            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.recordsTotal),
                recordsFiltered: Number(paginationData.recordsFiltered),
                data: paginationData.data
              }
              if (resData?.data?.data.length) {
                // emptyState = true
              }
              callback(result);
            }
            else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            // emptyState = true
          }
        })
      },
      columns: [
        {
          data: 'seqId', render: function (data, type, full) {
            return `</div class="d-flex justify-content-center">${data}</div>`;

          }
        },
        {
          data: 'question', render: function (data, type, full) {

            return `<div class="btn-edit-test" faq-id="${full._id}"  style="max-height: 38px !important; overflow:hidden; cursor: pointer;">
              ${data}...
            </div> `;
          }
        },
        {
          data: 'answer', render: function (data, type, full) {
            return `<div style="max-height: 38px !important; overflow:hidden;">
              ${data}...
            </div> `;
          }
        },
        {
          data: '_id', render: function (data, a, b, c) {
            var view = "-"
            var archive = ""
            if (MODULES.hasAccess(MODULES.EDIT_FAQ) && !(MODULES.hasAccess(MODULES.DELETE_FAQ))) {
              view = `<a href="#" class="rename mg-r-5 btn-edit-test" faq-id="${data}" data-toggle="tooltip" data-placement="bottom" title="Edit FAQ"><i class="wd-15 mg-r-5" data-feather="edit"></i></a>`
            } else if (MODULES.hasAccess(MODULES.DELETE_FAQ) && !(MODULES.hasAccess(MODULES.EDIT_FAQ))) {
              archive = `<a href="#" class="rename mg-r-5 btn-delete-test" faq-id="${data}" data-toggle="tooltip" data-placement="bottom" title="Delete FAQ"><i class="wd-15 mg-r-5" data-feather="trash-2"></i></a>`
            } else if (!(MODULES.hasAccess(MODULES.DELETE_FAQ)) && !(MODULES.hasAccess(MODULES.EDIT_FAQ))) {
              view = "-"
              archive = ""
            } else {
              view = `<a href="#" class="rename mg-r-5 btn-edit-test" faq-id="${data}" data-toggle="tooltip" data-placement="bottom" title="Edit FAQ"><i class="wd-15 mg-r-5" data-feather="edit"></i></a>`
              archive = `<a href="#" class="rename mg-r-5 btn-delete-test" faq-id="${data}" data-toggle="tooltip" data-placement="bottom" title="Delete FAQ"><i class="wd-15 mg-r-5" data-feather="trash-2"></i></a>`
            }
            return `
            <div class="d-md-flex flex-row justify-content-center">
            ${view}
            ${archive}
            </div>
            `;
          }
        },
      ],
      "drawCallback": function () {
        feather.replace()
        _$('.btn-edit-test').unbind('click').click(
          async function (e) {
            e.preventDefault();
            var faqId = _$(this).attr("faq-id");
            await editOldFaq(faqId);

          })
        _$('.btn-delete-test').unbind('click').click(function (e) {
          e.preventDefault();
          var faqId = _$(this).attr("faq-id");
          const notice = PNotify.notice({
            title: "Delete FAQ",
            text: "Are you sure you want to delete this FAQ ?",
            hide: false,
            closer: false,
            sticker: false,
            modules: new Map([
              ...PNotify.defaultModules,
              [PNotifyConfirm, { confirm: true }],
            ]),
          })


          notice.on("pnotify:confirm", async () => {
            try {
              var deleteFaq = await api_deleteFaqByID({ _id: faqId });
              if (!deleteFaq) {
                console.log(deleteFaq);
                PNotify.success({
                  title: 'Success',
                  text: "FAQ deleted successfully",
                  delay: 3000
                })
              } else {
                PNotify.error({
                  title: 'Error',
                  text: deleteFaq.errMsg,
                  delay: 3000
                })
              }

            } catch (error) {
              console.error(error);
            }
            testDataTable.ajax.reload();
            resetFaqObject();
          })

        })

      },
      "processing": true,
      "serverSide": true,
    });
    _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
  }


</script>
<div class="content-body fadeIn">

  <div class="d-sm-flex align-items-center justify-content-between mg-b-15 mg-lg-b-15">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item"><a href="/dashboard/faq" use:links>FAQ</a></li>

        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        <small>FAQs</small>
      </h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search or add frequently asked questions </p>
    </div>
    {#if MODULES.hasAccess(MODULES.ADD_FAQ)}
    <div class="d-block">
      <div class="d-sm-flex">
        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={()=> addNewFaq()}>
          <i data-feather="plus" class="wd-10 mg-r-5"></i> Add FAQ
        </button>
      </div>
    </div>
    {/if}
  </div>

  <hr class="full-width">
  <div data-label="Example" class="df-example ">
    <table bind:this={demoDataTable} class="table table-hover table-sm">
      <thead>
        <tr>
          <th style="width: 5%; text-align: center;">#</th>
          <th style="width: 30%;">Questions</th>
          <th style="width: 30%;">Answers</th>
          <th style="width: 8%; text-align: center;">Action</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>


</div>


<div class="modal" bind:this={elem_composeScript} tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header pd-b-0">
        <div class="media align-items-center pd-12">
          <span class="tx-color-04"><i data-feather="alert-circle" class="wd-60 ht-60" /></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">{displaytitle}</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Add new question.</p>
          </div>
        </div>
        <nav class="nav nav-icon-only">
          <a href="javascript:void(0)" class="mg-r-5 mg-r-5-close" on:click={()=> {
            _$(elem_composeScript).modal('hide');
            feather.replace({ width: '18px', height: '18px' });
            console.log('reset after this');
            }}><i data-feather="x" /></a>
        </nav>
      </div>
      <div class="modal-body">
        <div class="form-group align-items-center pd-12">
          <label>
            Question
            <span class="required-field">*</span>
          </label>
          <input type="text" bind:value={faqByUser.question} on:keyup={(event)=> {event.key =='Enter' && onFaqAdd()}}
          on:focus={clearQuestionValidation}
          class:parsley-error={errorDetails.question}
          class="form-control"
          placeholder="Enter question" />
          {#if errorDetails.question}
          <div class="parsley-errors-list">{errorDetails.question}</div>
          {/if}
        </div>
        <div class="form-group align-items-center pd-12">
          <label>
            Answer
            <span class="required-field">*</span>
          </label>
          <textarea rows={5} on:keyup={(e)=> e.key === 'Enter' && onFaqAdd()}
               on:input={(e) => {
                answer = CKEDITOR.instances.script_editor.getData();
              }}
              
              name={'script_editor'}
              class:parsley-error={errorDetails.answer}
              class="form-control"
              placeholder="Write your answer here" />
            {#if errorDetails.answer}
              <div class="parsley-errors-list">{errorDetails.answer}</div>
            {/if}
          </div>
        </div>
        <div class="modal-footer pd-t-0">
          <div class="pd-12">
            <button
                type="button"
                class="btn btn-secondary tx-12"
                data-dismiss="modal">
                
                CANCEL <i data-feather="x" class="wd-10 mg-l-5"></i></button>
            <button
              class="btn btn-primary"
              on:click={onFaqAdd} disabled={isLoading}>
              <ButtonLoader isLoading={isLoading} btnText="SAVE"/>
              <i data-feather="save" class="wd-10 mg-l-5"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
