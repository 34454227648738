<script>
  import { links, link } from "svelte-routing";
  import { onMount, tick, afterUpdate } from "svelte";
  import { activeMenu } from "../../stores";
  import Empty from "../empty/empty.svelte";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";

  let isLoading = false;
  let isLoadingList = false;
  let addNewSourceModal;
  let enquirySourceObj = { name: "", _id: null };
  let errorObj = { name: "" };
  let displayText = "Enquiry Sources List";
  let enquirySourceList = { data: [] };
  let search = { value: "" };
  const pageSize = 100;
  let length = pageSize;

  const dummyData = [
    { _id: 0, name: "name", createdOn: new Date() },
    { _id: 1, name: "name1", createdOn: new Date() },
  ];

  async function api_delete(data) {

      let action = "enquirySource.removeEnquirySource";
        var response = await api_handleApiCalls(action, data);
        return response;
  }

  async function api_list(data) {

    let action = "enquirySource.fetchEnquirySourceList";
    var response = await api_handleApiCalls(action, data);
    return response;
   
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    // if (sortOrder.column === 1) {
    //   sort.name = order;
    // } else if (sortOrder.column === 2) {
    //   sort.createdOn = order;
    // } else {
    //   sort.seqId = order;
    // }
    sort.seqId = order;
    return sort;
  }

  async function fetchEnquirySourceList(offset = 0, event) {
    let data = {
      start: 0,
      length: length + offset >= pageSize ? length + offset : pageSize,
      search,
      sort: parseSorts({ 0: "dsc" }),
    };
    console.info(data);
    // fetch call
    isLoadingList = true;
    await tick();
    let response = await api_list(data);
    console.info({ response });
    if (response.status) {
      var paginationData = response.data;
      length = paginationData.data.length;
      enquirySourceList = {
        recordsTotal: Number(paginationData.recordsTotal),
        recordsFiltered: Number(paginationData.recordsFiltered),
        data: paginationData.data,
      };
    } else {
      console.error("something went wrong");
      length = 0;
      enquirySourceList = {
        recordsTotal: 0,
        recordsFiltered: 0,
        data: [],
      };
    }
    isLoadingList = false;
    await tick();
  }

  async function deleteSource(enquirySourceId) {
    pConfirm("Delete Enquiry Source?", " ", async function () {
      console.info("Deleting");
      //   delete api
      let result = await api_delete({ _id: enquirySourceId });
      if (result.status) {
        pSuccess(null, result.data);
        await fetchEnquirySourceList();
      } else if (result.errMsg) {
        pError(result.errMsg);
      } else if (result.error) {
        pError(result.error);
      } else {
        pError(null, "Something went wrong");
      }
    });
  }

  async function showModal() {
    _$(addNewSourceModal).modal("show");
    clearFields();
    await tick();
  }

  async function hideModal() {
    _$(addNewSourceModal).modal("hide");
    clearFields();
    await tick();
  }

  async function api_save(data) {

    let action = "enquirySource.saveEnquirySource";
    var response = await api_handleApiCalls(action, data);
    return response;
   
  }

  async function api_getbyid(data) {

    let action = "enquirySource.fetchEnquirySource";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  function validate() {
    //   clear previous validations before validating anew
    clearValidations();
    tick();

    enquirySourceObj.name = enquirySourceObj.name.trim();
    const { name } = enquirySourceObj;
    var regexpStartingWithAlphabet = new RegExp(`^[A-Za-z]`);
    // var regexpEnglish = new RegExp(`^[A-Za-z][\\w\\s]*$`);

    if (!Boolean(name)) {
      errorObj.name = "Name can not be empty";
    } else if (!name.match(regexpStartingWithAlphabet)) {
      errorObj.name = "Name must begin with A-Z or a-z characters";
    } /*  else if (!name.match(regexpEnglish)) {
      errorObj.name =
        "Name can only have alphanumeric characters and spaces";
    } */ else if (
      name.length < 3
    ) {
      errorObj.name = "Name should be minimum 3 characters long";
    } else if (name.length > 80) {
      errorObj.name = "Name should not exceed 80 characters long";
    }

    if (Object.values(errorObj).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async function submitForm(event) {
    event.preventDefault();
    if (isLoading) return;
    isLoading = true;
    await tick();
    const isValid = validate();
    console.log({ isValid, errorObj });
    if (isValid) {
      console.log("adding", { enquirySourceObj, errorObj });
      let data = {};
      data.name = enquirySourceObj.name;
      if (enquirySourceObj._id) data._id = enquirySourceObj._id;
      // save enquiry source api call
      let result = await api_save(enquirySourceObj);
      console.log(result);
      if (result.status) {
        pSuccess(null, result.data);
        // window.history.back();
        hideModal();
        await reload();
      } else if (result.errMsg) {
        pError(null, result.errMsg);
      } else if (result.error) {
        pError(null, result.error);
      } else {
        pError(null, "Something went wrong");
      }
    }
    isLoading = false;
    await tick();
  }

  async function editSource(_id) {
    await showModal();
    let response = await api_getbyid({ _id });
    enquirySourceObj.name = response.data.name;
    enquirySourceObj._id = response.data._id;
  }

  function clearFields() {
    // id
    if (enquirySourceObj._id) delete enquirySourceObj._id;
    // form fields
    enquirySourceObj.name = "";
    clearValidations();
  }

  function clearValidations() {
    errorObj = {};
  }

  async function reload() {
    await fetchEnquirySourceList();
    feather.replace({ width: "1em", height: "1em" });
  }

  function bindModalAnimations() {
    // new enquiry source modal
    _$(addNewSourceModal).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
    });

    _$(addNewSourceModal).on("hidden.bs.modal", function (e) {
      _$(this).removeClass("effect-scale");
    });
  }

  async function init() {
    console.log("mounting enquirySource list");
    activeMenu.set("enquiry-source");
    bindModalAnimations();
    await reload();
  }

  onMount(init);
  afterUpdate(function () {
    feather.replace({ width: "1em", height: "1em" });
  });
</script>

<style>
  .long-name-wrap {
    word-break: keep-all;
    overflow-wrap: break-word;
    max-width: 90%;
    min-width: 90%;
  }

  .enquiry-source-cards-wrap {
    flex-wrap: wrap;
  }

  .card * {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
  }

  .card .hidden-dropdown {
    display: none;
  }

  .card:hover .hidden-dropdown,
  .card:focus .hidden-dropdown {
    display: inherit;
  }

  .more-btn {
    float: right;
    clear: both;
    width: 1em;
    height: 1em;
  }
</style>

<div class="content-body fadeIn">
  <!-- breadcrumbs -->
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20
      mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {displayText}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">{displayText}</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">View the enquiry sources or add a new source.</p>
    </div>
    <div class="d-inline-block">
      <nav use:links class="d-inline-block">
        <div class="d-inline-block">
          <div class="search-form">
            <input
              type="search"
              class="form-control"
              placeholder="Enquiry Source"
              bind:value={search.value}
              on:input={async (event) => await fetchEnquirySourceList(0, event)} />
            <button
              class="btn"
              on:click={async (event) => await fetchEnquirySourceList(0, event)}><i
                data-feather="search" /></button>
          </div>
        </div>
        <div class="d-inline-block">
          <button
            type="button"
            on:click={showModal}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
              data-feather="plus"
              class="wd-10 mg-r-5" />
            Add new Enquiry Source
          </button>
        </div>
      </nav>
    </div>
  </div>
  <hr class="full-width">
  {#if isLoadingList}
    <div class="row row-xs mg-b-20">
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
    </div>
  {:else if enquirySourceList && enquirySourceList.data && enquirySourceList.data.length}
    <div class="row row-xs mg-b-20">
      {#each enquirySourceList.data as source (source._id)}
        <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
          <div class="media media-folder">
            <div class="avatar avatar-sm">
              <div
                class="avatar-initial rounded-circle bg-white bd bd-1 bd-primary tx-primary">
                {source.name[0]}
              </div>
            </div>
            <div class="media-body">
              <h6>
                <a href="javascript:void(0)" class="link-02">{source.name}</a>
              </h6>
            </div>
            <div class="dropdown-file">
              <a href="" class="dropdown-link" data-toggle="dropdown">
                <i data-feather="more-vertical" /></a>
              <nav class="dropdown-menu dropdown-menu-right" use:links>
                <a
                  href="javascript:void(0)"
                  on:click={() => editSource(source._id)}
                  class="dropdown-item details"><i
                    data-feather="edit" />Edit</a>
                <a
                  href="javascript:void(0)"
                  on:click={() => deleteSource(source._id)}
                  class="dropdown-item details"><i
                    data-feather="trash" />Delete</a>
              </nav>
            </div>
          </div>
        </div>
      {/each}
    </div>
  {:else}
    <Empty
      mode={search.value ? 'search' : 'empty'}
      onActionBtnClick={showModal} />
  {/if}
</div>

<!-- Modal -->
<div
  class="modal effect-scale"
  bind:this={addNewSourceModal}
  id="modal-new-batch"
  tabindex="-1"
  role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mx-wd-sm-650" role="document">
    <div class="modal-content ">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="anchor" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">{enquirySourceObj._id ? 'Edit source details' : 'Enquiry source'}</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Add new enquiry source.</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->

      <hr class="full-width">
      <div class="modal-body pd-0">
        <div class="row no-gutters">
          <div class="col-12 bg-white rounded-right">
            <div
              class="ht-100p d-flex flex-column justify-content-center pd-l-25 pd-r-25 pd-b-25">
             
              <div class=" tx-color-02">
                <div class="form-group">
                  <label class="d-block">Name
                    <span class="required-field">*</span></label>
                  <input
                    bind:value={enquirySourceObj.name}
                    on:keyup={(e) => e.key === 'Enter' && submitForm(e)}
                    type="text"
                    class="form-control first-input"
                    id="sourse-name"
                    class:parsley-error={errorObj.name}
                    placeholder="Enter name" />
                  {#if errorObj.name}
                    <div class="parsley-errors-list">{errorObj.name}</div>
                  {/if}
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-secondary tx-12"
                  data-dismiss="modal">CANCEL
                  <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  on:click={submitForm}
                  class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                  <ButtonLoader {isLoading} btnText="Proceed" />
                  <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
