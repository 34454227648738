<script>
    import { links, link, navigate } from "svelte-routing";
    import { onMount, tick, afterUpdate } from "svelte";
    import { activeMenu } from "../../stores";
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import ButtonLoader from "../../utils/button_loader.svelte";
    import UserMultiSelectCmp from "../../utils/userMultiSelectCmp.svelte";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import { API_URL } from "../../../config.js"

    export let _id
    let isLoading = false;
    let role = { roles: "users" }
    let showUserModal = false
    let emptyState = false
    let userIdArr = []
    const classObj = { name: "" };
    let errorObj = {};
    let displayText = "Class List";
    let classesList = { data: [] };
    let search = { value: "" };
    let sessionDataTable;
    let elem_sessionDataTable
    let selectedUserIdArr = []
    const pageSize = 100;

    async function api_removeUserFromGroup(data) {

        let action = "chat.removeUserFromGroup";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_addUserIdsToChannel() {

        let action = "chat.addUserIdsToChannel";
        var response = await api_handleApiCalls(action, { channelIds: userIdArr, channelId: _id });
        return response;

    }

    function parseSorts(sortOrder) {
        // parses datatable order to mongodb sort object
        let sort = {};
        // sorting orders
        let order;
        if (sortOrder.dir === "asc") order = 1;
        else order = -1;
        sort.seqId = order;
        return sort;
    }



    let onCloseModal = async function (event) {
        showUserModal = !showUserModal;
        userIdArr = event.detail.selectedUsersIds
        var res = await api_addUserIdsToChannel();
        if (res.status) {
            pSuccess("", res.data.msg);
        } else {
            pError("", res.errMsg || res.error || "something went wrong");
        }
    };



    async function reload() {
        //   await fetchCourseList();
        await bindDatatable();
        feather.replace({ width: "1em", height: "1em" });
    }

    function bindDatatable() {
        sessionDataTable = _$(elem_sessionDataTable).DataTable({

            responsive: false,
            language: {
                processing: "",
                searchPlaceholder: 'Members name',
                sSearch: '',
                lengthMenu: '_MENU_ Members/page',
            },
            ajax: function (data, callback, settings) {
                data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
                _$.ajax({
                    url: API_URL,
                    method: 'post',
                    data: {
                        action: 'chat.getUserListByChannelId',
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                            draw: data.draw,
                            sort: data.sort,
                            channelId: _id
                        },

                    },
                    success: function (resData) {
                        var result = []
                        if (resData.status) {
                            var paginationData = {}
                            paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.recordsTotal),
                                recordsFiltered: Number(paginationData.recordsFiltered),
                                data: paginationData.userList
                            }
                            let userList = paginationData.userList
                            selectedUserIdArr = userList.map(item => item._id)
                            if (!resData.data.userList.length) {
                                emptyState = true
                            } else {
                                emptyState = false
                            }
                            callback(result);
                        }
                        else {
                            console.log("Something went wrong");
                        }
                    }
                })
            },
            columns: [

                {
                    data: 'name',
                    defaultContent: "-",
                    render: (data, type, full) => {
                        let image;
                        // if image found display profilePic avatar
                        image = `<img src="${full.profilePic ? full.profilePic : "/images/man.png"
                            }" class="avatar mg-r-15" alt="${data[0].toUpperCase()}">`;
                        return `
            <div style = "cursor:pointer" class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 btn-edit-student" student-id="${full._id}">
              ${image}
              <div class="wd-80p bg-transparent">
                <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent btn-edit-student tx-capitalize" student-id="${full._id}">${data || "-"
                            }</h6>
                <span class="d-block tx-11 text-muted bg-transparent">
                    <i style="width: 15px; height: 15px;" data-feather="phone"></i> 
                    ${full.mobileNo || "-"}
                </span>
              </div>
            </div>`;
                    },
                },
                {
                    data: 'role', render: function (data, type, row, meta) {
                        return `<div style="text-align: center">${data}</div>`;
                    }
                },
                {
                    data: '_id', render: function (data, type, row, meta) {
                        return `
                        <div class="d-md-flex flex-row justify-content-center">
                            <a href="#" class="rename btn-remove-student mg-r-5" student-id="${data}" data-toggle="tooltip" data-placement="top" title="remove user from group"><i data-feather="user-minus" class="wd-15 mg-r-5"></i></a>
                        </div>
                    `;

                    }
                },

            ],
            "drawCallback": function () {
                feather.replace()
                _$('.btn-remove-student').unbind('click').click(function (e) {
                    e.preventDefault();
                    var studentId = _$(this).attr("student-id");
                    const notice = PNotify.notice({
                        title: "Remove user",
                        text: "Are you sure you want to remove this member from the current group ?",
                        hide: false,
                        closer: false,
                        sticker: false,
                        modules: new Map([
                            ...PNotify.defaultModules,
                            [PNotifyConfirm, { confirm: true }],
                        ]),
                    })


                    notice.on("pnotify:confirm", async () => {
                        try {
                            var deleteSessionRes = await api_removeUserFromGroup({ studentId: studentId, _id: _id });
                            if (deleteSessionRes.status) {
                                PNotify.success({
                                    title: 'Success',
                                    text: "Member Deleted Successfully",
                                    delay: 3000
                                })
                            } else {
                                PNotify.error({
                                    title: 'Error',
                                    text: deleteSessionRes.errMsg,
                                    delay: 3000
                                })
                            }

                        } catch (error) {
                            console.error(error);
                        }
                        sessionDataTable.ajax.reload();

                    })

                })

                // bind actions 
            },
            "processing": true,
            "serverSide": true,
        });
        _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
    }


    async function init() {
        activeMenu.set("students");
        await bindDatatable();
        //   reload();
    }

    onMount(init);

    afterUpdate(function () {
        feather.replace({ width: "1em", height: "1em" });
    });


</script>

<style>
    /* .long-name-wrap {
      word-break: break-all;
      overflow-wrap: break-word;
      min-widht: 90%
      max-widht: 90%
    } */

    .classes-cards-wrap {
        flex-wrap: wrap;
    }

    .no-select {
        -webkit-user-select: none;
        /* Chrome all / Safari all */
        -moz-user-select: none;
        /* Firefox all */
        -ms-user-select: none;
        /* IE 10+ */
        user-select: none;
        /* Likely future */
    }
</style>


<div class="content-body fadeIn">
    <!-- <Link style="display: none;" to="about">About</Link> -->
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/students/groups">Groups</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Group Members</li>

                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">Group Members</h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search groups or create a new group.</p>
        </div>

        <button class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase" on:click={()=>{showUserModal =
            !showUserModal}}> <i data-feather="user-plus" class="mg-r-5"></i>
            Add Members</button>
    </div>

    <hr class="full-width">


    <div>
        <table bind:this={elem_sessionDataTable} class="table">
            <thead>
                <tr>
                    <th style="text-align: center; width: 15%;">Name</th>
                    <th style="width: 20%; text-align: center;">Role</th>
                    <th style="text-align: center; width: 10%;">Actions</th>
                </tr>
            </thead>
            <tbody></tbody>

        </table>

    </div>

</div>
{#if showUserModal}
<UserMultiSelectCmp filters={role} on:done={onCloseModal} userIdArr={selectedUserIdArr}></UserMultiSelectCmp>
{/if}
