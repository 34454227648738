<script>
  import { onMount, tick, createEventDispatcher } from "svelte";
  import FolderExplorer from "./folderExplorer.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  export let showModal = false;

  let modalCmp;
  let batchList = [];
  let batchSelectElement;
  let saveBtnEle;
  let selectedFolder = [];
  let selectedBatch;

  const dispatch = createEventDispatcher();

  async function api_getBatch(data) {
    let action = "batch.getMyBatches";
    try {
      const response = await api_handleApiCalls(action, data);
      console.log("API Response:", response);
      return response;
    } catch (error) {
      console.error("Error in API call:", error);
    }
  }

  let onSelectedFolder = function () {
    dispatch("close", {
      selectedFolder: selectedFolder,
    });
  };

  onMount(async () => {
    try {
      console.log("Fetching batch data...");
      const batchRes = await api_getBatch({
        start: "0",
        noPagination: true,
      });

      if (batchRes?.status && Array.isArray(batchRes?.data) && batchRes.data.length > 0) {
        batchList = batchRes.data;
        console.log("Batch List Fetched:", batchList);
        await tick(); // Wait for DOM updates
      } else {
        console.warn("No batch data found.");
      }

      console.log("Full API Response:", batchRes);

      // Initialize Selectize after batchList updates
      if (batchSelectElement) {
        try {
          const $select = _$(batchSelectElement).selectize({
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            options: batchList,
            preload: true,
            create: false,
            placeholder: "Select a Batch",
            onChange: function (value) {
              selectedBatch = value;
              console.log("Selected Batch:", selectedBatch);
            },
            onInitialize: function () {
              if (batchList.length) {
                this.addOption(
                  batchList.map((batch) => ({
                    _id: batch._id,
                    name: batch.name,
                  }))
                );
              }
            },
          });

          _$(modalCmp).modal("show");

          _$(modalCmp).on("hide.bs.modal", function () {
            dispatch("close", { selectedFolder: [] });
          });
        } catch (error) {
          console.error("Error initializing Selectize:", error);
        }
      }
    } catch (error) {
      console.error("Error during onMount:", error);
    }
  });
</script>

<div class="modal effect-scale" bind:this={modalCmp} id="pdf-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="#" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block">
            <i data-feather="folder-plus" class="wd-60 ht-60"></i>
          </span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Select folder</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Select the folder which you want to add.</p>
          </div>
        </div>
      </div>
      <hr class="full-width" />
      <div class="modal-body">
        <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div class="row row-sm mg-b-20">
            <div class="col-sm-12">
              <div class="form-group col-sm-12">
                <label class="d-block">Select a Batch</label>
                <select bind:this={batchSelectElement} class="moduleSub form-control" style="width: 100%;">
                  <option value="" disabled selected>Select Batch</option>
                  {#each batchList as batch}
                  <option value={batch._id}>{batch.name}</option>
                  {/each}
                </select>
              </div>

              <div class="form-group col-sm-12">
                <label class="d-block">Select a Folder</label>
                {#if selectedBatch}
                <FolderExplorer bind:selectedFolder _batchId={selectedBatch} />
                {/if}
              </div>

              <div class="form-group col-sm-12">
                <label class="d-block">Selected Location:</label>
                {#if selectedBatch}
                <nav aria-label="breadcrumb" class="tree-bc">
                  <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                    {#each selectedFolder as item}
                    <li class="breadcrumb-item active">{item.name}</li>
                    {/each}
                  </ol>
                </nav>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer pd-r-25">
        <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">
          CLOSE
          <i data-feather="x" class="wd-10 mg-l-5"></i>
        </button>
        {#if selectedFolder.length === 0}
        <button type="button" class="btn btn-primary tx-13" on:click={onSelectedFolder} bind:this={saveBtnEle} disabled>
          LINK HERE
          <i data-feather="link" class="wd-10 mg-l-5"></i>
        </button>
        {:else}
        <button type="button" class="btn btn-primary tx-13" on:click={onSelectedFolder} bind:this={saveBtnEle}>
          LINK HERE
          <i data-feather="link" class="wd-10 mg-l-5"></i>
        </button>
        {/if}
      </div>
    </div>
  </div>
</div>
