<script>
  import { Router, Link, links, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import Details from "./details.svelte";
  import Settings from "./settings.svelte";
  import QuestionList from "./questionList.svelte";
  import Assign from "./assign.svelte";
  import Notifications from "./notifications.svelte";
  import Publish from "./publish.svelte";
  import TestResults from "./myExamReports.svelte";
  import ReportedQuestions from "./reportedQuestions.svelte";
  import BatchWiseResult from "./batchWiseResult.svelte";
  import QuestionAnalytics from "./questionanalytics.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import { BACKEND_URL } from "../utils/constants.js";
  import { getClassKey } from "../utils/classMapping.js";

  import {
    validateAssigns,
    validateDetails,
    validatePublish,
    validateQuestions,
    validateNotifications,
    validateSettings,
    validateGeneratePDF,
  } from "./utils/utils";
  import { pSuccess, pError } from "../utils/notify.svelte";
  import ButtonLoader from "../utils/button_loader.svelte";
  import GeneratePdf from "./generatePdf.svelte";

  $: isPublished = testObj.publish || false;
  $: (!isPublished || testObj.menu == "generatePDF") && feather.replace();
  let saveButtonLoading,
    unpublishButtonLoading,
    publishButtonLoading,
    printButtonLoading;
  export let testId;
  let testDataLoading = false;
  let elem_menuScroll;
  let elem_detailsTabScroll;
  let testLoading = false;

  async function api_getTestById(data) {
    testLoading = true;
    let action = "test.getTestById";
    var response = await api_handleApiCalls(action, data);
    testLoading = false;
    return response;
  }

  async function api_updateTest(data) {
    let action = "test.update";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_publishTest(data) {
    let action = "test.publish";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_printPaper(data) {
    let action = "test.printTestPaper";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  let testObj = {
    _id: testId,
    loaded: false,
    qTextObj: {},
    settings: [],
    linkArr: [],
    groupsLinkArr: [],
    studentsLinkArr: [],
    notification_app: false,
    notification_sms: false,
    pdfObj: {},
    // practiceTestParentId:[]
    // publishAnnouncement: {
    //   message:
    //     `Test "${testObj.displayName || testObj.name || "[testname]"}" is assigned to you. It will start on ${testObj.startDate? moment(new Date(testObj.startDate)).format(DD-MMM-YYY): "[startdate]"} at ${testObj.startDate? moment(new Date(testObj.startDate)).format("hh:mm A"): "[starttime]"}`,
    //     // message: `You have been assigend a test. [test-name] ([test-display-name])`
    // },
    // announcementBeforeExam: {
    //   message:`Your test "${testObj.displayName || testObj.name || "[testname]"}" is starting today at ${testObj.startDate? moment(new Date(testObj.startDate)).format("hh:mm A"): "[starttime]"}.`,
    //   timeToNotify: '00:00 AM',
    // },
  };

  let tabError = {
    details: false,
    settings: false,
    questionList: false,
    assign: false,
    notifications: false,
    publish: false,
    generatePDF: false,
  };
  let detailsErrors = {},
    settingsErrors = {},
    questionsErrors = {},
    assignLinksErrors = {},
    publishErrors = {},
    generatePDFErrors = {},
    notificationErrors = {};

  let updateParent = function (saveTestDetails = false) {
    console.log("Parent : ", testObj);
    testObj = testObj;
    tick();
    if (saveTestDetails && (!isPublished || testObj.menu == "generatePDF")) {
      onSaveClicked(true);
    }
  };

  let onUpdateQuestion = async function (silent, allowPublish = false) {
    console.log("onUpdateQuestion : ", testObj);

    let testFinaleObj = { ...testObj, allowPublish };
    delete testFinaleObj.qTextObj;
    let startDate, startTime, endDate, endTime, resultDate, resultTime; //, pdfDate, pdfTime;

    if (testFinaleObj.startDate) startDate = new Date(testFinaleObj.startDate);
    if (testFinaleObj.startTime) startTime = new Date(testFinaleObj.startTime);
    if (startDate && startTime) {
      startDate.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
      testFinaleObj.startDate = new Date(startDate);
    } else if (startDate && !startTime) {
      startDate.setHours(0, 0, 0, 0);
      testFinaleObj.startDate = startDate;
    }
    delete testFinaleObj.startTime;

    if (testFinaleObj.endDate) endDate = new Date(testFinaleObj.endDate);
    if (testFinaleObj.endTime) endTime = new Date(testFinaleObj.endTime);
    if (endDate && endTime) {
      endDate.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);
      testFinaleObj.endDate = new Date(endDate);
    } else if (endDate && !endTime) {
      endDate.setHours(0, 0, 0, 0);
      testFinaleObj.endDate = endDate;
    }
    delete testFinaleObj.endTime;

    if (testFinaleObj.resultDate)
      resultDate = new Date(testFinaleObj.resultDate);
    if (testFinaleObj.resultTime)
      resultTime = new Date(testFinaleObj.resultTime);
    if (resultDate && resultTime) {
      resultDate.setHours(resultTime.getHours(), resultTime.getMinutes(), 0, 0);
      testFinaleObj.resultDate = new Date(resultDate);
    }
    delete testFinaleObj.resultTime;

    // if (testFinaleObj.pdfObj.date) pdfDate = new Date(testFinaleObj.pdfObj.date);
    // if (testFinaleObj.pdfObj.time) pdfTime = new Date(testFinaleObj.pdfObj.time);
    // if (pdfDate && pdfTime) {
    //   pdfDate.setHours(pdfTime.getHours(), pdfTime.getMinutes(), 0, 0);
    //   testFinaleObj.pdfObj.date = new Date(pdfDate);
    // } else if(pdfDate && !pdfTime){
    //   pdfDate.setHours(0,0,0,0)
    //   testFinaleObj.pdfObj.date = pdfDate;
    // }
    // if(testFinaleObj.pdfObj.time) delete testFinaleObj.pdfObj.time;
    if (testFinaleObj.pdfObj.date)
      testFinaleObj.pdfObj.date = new Date(testFinaleObj.pdfObj.date);
    // console.debug("testObj => ", testFinaleObj)

    let res = await api_updateTest(testFinaleObj);
    console.log("Test Saved : ", res);
    if (res.status) {
      if (!silent) {
        pSuccess("", "Changes saved");
        reload();
      }
      // setTimeout(() => {
      // }, 1000);
    } else {
      pError("", res.errMsg || res.error || "Something went wrong");
    }
  };

  let publishTest = async function () {
    console.log("publishTest : ", testObj);

    let testFinaleObj = { ...testObj };
    delete testFinaleObj.qTextObj;
    let startDate, startTime, endDate, endTime;

    if (testFinaleObj.startDate) startDate = new Date(testFinaleObj.startDate);
    if (testFinaleObj.startTime) startTime = new Date(testFinaleObj.startTime);
    if (startDate && startTime) {
      startDate.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
      testFinaleObj.startDate = startDate;
    } else if (startDate && !startTime) {
      startDate.setHours(0, 0, 0, 0);
      testFinaleObj.startDate = startDate;
    }
    delete testFinaleObj.startTime;

    if (testFinaleObj.endDate) endDate = new Date(testFinaleObj.endDate);
    if (testFinaleObj.endTime) endTime = new Date(testFinaleObj.endTime);
    if (endDate && endTime) {
      endDate.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);
      testFinaleObj.endDate = endDate;
    } else if (endDate && !endTime) {
      endDate.setHours(0, 0, 0, 0);
      testFinaleObj.endDate = endDate;
    }
    delete testFinaleObj.endTime;

    if (testFinaleObj.resultDate)
      resultDate = new Date(testFinaleObj.resultDate);
    if (testFinaleObj.resultTime)
      resultTime = new Date(testFinaleObj.resultTime);
    if (resultDate && resultTime) {
      resultDate.setHours(resultTime.getHours(), resultTime.getMinutes(), 0, 0);
      testFinaleObj.resultDate = resultDate;
    }
    delete testFinaleObj.resultTime;

    testFinaleObj.publish = true;

    let res = await api_updateTest(testFinaleObj);
    console.log("Test published : ", res);
    if (res.status) {
      pSuccess("", "Test published");
      reload();
    } else {
      pError("", res.errMsg || res.error || "Something went wrong");
    }
  };

  let resetErrors = function () {
    tabError.details = false;
    tabError.settings = false;
    tabError.questionList = false;
    tabError.assign = false;
    tabError.publish = false;
    tabError.generatePDF = false;
    detailsErrors = {
      allowSave: true,
      allowPublish: false,
      errors: {},
      source: "",
    };
    settingsErrors = {
      allowSave: true,
      allowPublish: false,
      errors: {},
      source: "",
    };
    questionsErrors = {
      allowSave: true,
      allowPrint: false,
      allowPublish: false,
      errors: {},
      source: "",
    };
    assignLinksErrors = {
      allowSave: true,
      allowPublish: false,
      errors: {},
      source: "",
    };
    publishErrors = {
      allowSave: true,
      allowPublish: false,
      errors: {},
      source: "",
    };
    generatePDFErrors = {
      allowSave: true,
      allowPrint: false,
      allowPublish: false,
      errors: {},
      source: "",
    };
  };
  // resets and initializes error objects without waiting for mount. IMPORTANT
  resetErrors();

  let onSaveClicked = async function (silent = false) {
    if (saveButtonLoading) return;
    saveButtonLoading = true;
    await tick();
    console.log("onSaveClicked : ", testObj, testObj.menu);
    // save api call
    resetErrors();
    detailsErrors = validateDetails({ ...testObj, source: "save" });
    settingsErrors = validateSettings({ ...testObj, source: "save" });
    questionsErrors = validateQuestions({ ...testObj, source: "save" });
    assignLinksErrors = validateAssigns({ ...testObj, source: "save" });
    notificationErrors = validateNotifications({ ...testObj, source: "save" });
    publishErrors = validatePublish({ ...testObj, source: "save" });

    if (
      detailsErrors.allowSave &&
      settingsErrors.allowSave &&
      questionsErrors.allowSave &&
      assignLinksErrors.allowSave &&
      notificationErrors.allowSave &&
      publishErrors.allowSave
    ) {
      let allowPublish =
        detailsErrors.allowPublish &&
        settingsErrors.allowPublish &&
        questionsErrors.allowPublish &&
        assignLinksErrors.allowPublish &&
        notificationErrors.allowPublish &&
        publishErrors.allowPublish;
      onUpdateQuestion(silent, allowPublish);
      console.log("save success");
    } else {
      console.error(
        detailsErrors,
        settingsErrors,
        questionsErrors,
        assignLinksErrors,
        notificationErrors,
        publishErrors,
      );
      tabError.details = !detailsErrors.allowSave;
      tabError.settings = !settingsErrors.allowSave;
      tabError.questionList = !questionsErrors.allowSave;
      tabError.assign = !assignLinksErrors.allowSave;
      tabError.notifications = !notificationErrors.allowSave;
      tabError.publish = !publishErrors.allowSave;

      // redirect to tab where error found
      if (tabError.details && testObj.menu == "details") {
        // do nothing
      } else if (tabError.details && testObj.menu != "details") {
        navigate("/dashboard/tests/view/" + testId + "");
      } else if (tabError.settings && testObj.menu == "settings") {
        // do nothing
      } else if (tabError.settings && testObj.menu != "settings") {
        navigate("/dashboard/tests/view/" + testId + "/settings");
      } else if (tabError.questionList && testObj.menu == "questionList") {
        // do nothing
      } else if (tabError.questionList && testObj.menu != "questionList") {
        navigate("/dashboard/tests/view/" + testId + "/questionList");
      } else if (tabError.assign && testObj.menu == "assign") {
        // do nothing
      } else if (tabError.assign && testObj.menu != "assign") {
        navigate("/dashboard/tests/view/" + testId + "/assign");
      } else if (tabError.notifications && testObj.menu == "notifications") {
        // do nothing
      } else if (tabError.notifications && testObj.menu != "notifications") {
        navigate("/dashboard/tests/view/" + testId + "/notifications");
      } else if (tabError.publish && testObj.menu == "publish") {
        // do nothing
      } else if (tabError.publish && testObj.menu != "publish") {
        navigate("/dashboard/tests/view/" + testId + "/publish");
      }
    }
    saveButtonLoading = false;
    await tick();
  };
  let onUnpublishClicked = async function () {
    if (unpublishButtonLoading) return;
    unpublishButtonLoading = true;
    await tick();
    let testFinaleObj = { ...testObj };
    testFinaleObj.publish = false;
    delete testFinaleObj.qTextObj;
    let startDate, startTime, endDate, endTime, resultDate, resultTime;

    if (testFinaleObj.startDate) startDate = new Date(testFinaleObj.startDate);
    if (testFinaleObj.startTime) startTime = new Date(testFinaleObj.startTime);
    if (startDate && startTime) {
      startDate.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
      testFinaleObj.startDate = startDate;
    }
    delete testFinaleObj.startTime;

    if (testFinaleObj.endDate) endDate = new Date(testFinaleObj.endDate);
    if (testFinaleObj.endTime) endTime = new Date(testFinaleObj.endTime);
    if (endDate && endTime) {
      endDate.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);
      testFinaleObj.endDate = endDate;
    }
    delete testFinaleObj.endTime;

    if (testFinaleObj.resultDate)
      resultDate = new Date(testFinaleObj.resultDate);
    if (testFinaleObj.resultTime)
      resultTime = new Date(testFinaleObj.resultTime);
    if (resultDate && resultTime) {
      resultDate.setHours(resultTime.getHours(), resultTime.getMinutes(), 0, 0);
      testFinaleObj.resultDate = resultDate;
    }
    delete testFinaleObj.resultTime;
    let res = await api_updateTest(testFinaleObj);
    console.log("Test Saved : ", res);
    if (res.status) {
      await reloadAndUpdate();
    } else {
      pError("", res.errMsg || res.error || "Something went wrong");
      unpublishButtonLoading = false;
      await tick();
    }
  };

  // New function to reload and update
  let reloadAndUpdate = async function () {
    // First reload the test to get latest data
    let obj = await api_getTestById({ testId });
    console.log(
      "Test reload obj ------------------------------------------------- : ",
      obj.data,
    );
    if (!testObj.menu) obj.data.menu = "details";
    else obj.data.menu = testObj.menu;
    testObj = obj.data;
    testObj.loaded = true;
    testObj.startTime = testObj.startDate;
    testObj.endTime = testObj.endDate;
    testObj.resultTime = testObj.resultDate;
    testObj.qTextObj = testObj.qTextObj || {};
    testObj.settings = testObj.settings || [];
    testObj.batchArr = testObj.batchArr || [];
    testObj.groupsLinkArr = testObj.groupsLinkArr || [];
    testObj.studentsLinkArr = testObj.studentsLinkArr || [];
    testObj.linkArr = testObj.linkArr || [];
    testObj.practiceTestParentId = testObj.practiceTestParentId || [];
    testObj.isPracticeTest = testObj.isPracticeTest || "no";
    testObj.isDurationDividedAcrossSections =
      testObj.isDurationDividedAcrossSections || "no";
    testObj.notification_app = Boolean(testObj.notification_app);
    testObj.notification_sms = Boolean(testObj.notification_sms);
    testObj.publishAnnouncement = testObj.publishAnnouncement || {
      message: `Test ${testObj.displayName || testObj.name || "[testname]"} is assigned to you. It will start on ${testObj.startDate ? moment(new Date(testObj.startDate)).format(DD - MMM - YYY) : "[startdate]"} at ${testObj.startDate ? moment(new Date(testObj.startDate)).format("hh:mm A") : "[starttime]"}`,
      announcementId:
        (testObj.publishAnnouncement &&
          testObj.publishAnnouncement.announcementId) ||
        null,
    };
    testObj.announcementBeforeExam = testObj.announcementBeforeExam || {
      message: `Your test ${testObj.displayName || testObj.name || "[testname]"} is starting today at ${testObj.startDate ? moment(new Date(testObj.startDate)).format("hh:mm A") : "[starttime]"}.`,
      timeToNotify: null,
      announcementId:
        (testObj.announcementBeforeExam &&
          testObj.announcementBeforeExam.announcementId) ||
        null,
    };
    if (testObj.displayResultImmediately === undefined) {
      testObj.displayResultImmediately = true;
    }
    if (testObj.pdfObj) {
      // testObj.pdfObj.time = testObj.pdfObj.date
    } else testObj.pdfObj = {};

    await tick();

    // Now call test.update again with the reloaded data
    let testFinaleObj = { ...testObj };
    delete testFinaleObj.qTextObj;
    let startDate, startTime, endDate, endTime, resultDate, resultTime;

    if (testFinaleObj.startDate) startDate = new Date(testFinaleObj.startDate);
    if (testFinaleObj.startTime) startTime = new Date(testFinaleObj.startTime);
    if (startDate && startTime) {
      startDate.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
      testFinaleObj.startDate = startDate;
    }
    delete testFinaleObj.startTime;

    if (testFinaleObj.endDate) endDate = new Date(testFinaleObj.endDate);
    if (testFinaleObj.endTime) endTime = new Date(testFinaleObj.endTime);
    if (endDate && endTime) {
      endDate.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);
      testFinaleObj.endDate = endDate;
    }
    delete testFinaleObj.endTime;

    if (testFinaleObj.resultDate)
      resultDate = new Date(testFinaleObj.resultDate);
    if (testFinaleObj.resultTime)
      resultTime = new Date(testFinaleObj.resultTime);
    if (resultDate && resultTime) {
      resultDate.setHours(resultTime.getHours(), resultTime.getMinutes(), 0, 0);
      testFinaleObj.resultDate = resultDate;
    }
    delete testFinaleObj.resultTime;

    let secondUpdateRes = await api_updateTest(testFinaleObj);
    if (secondUpdateRes.status) {
      pSuccess("", "Changes saved");
      feather.replace();
    } else {
      pError(
        "",
        secondUpdateRes.errMsg ||
          secondUpdateRes.error ||
          "Something went wrong during final update",
      );
    }

    unpublishButtonLoading = false;
    await tick();
  };

  let onPublishClicked = async function () {
    if (publishButtonLoading) return;

    resetErrors();
    detailsErrors = validateDetails({ ...testObj, source: "publish" });
    settingsErrors = validateSettings({ ...testObj, source: "publish" });
    questionsErrors = validateQuestions({ ...testObj, source: "publish" });
    assignLinksErrors = validateAssigns({ ...testObj, source: "publish" });
    notificationErrors = validateNotifications({
      ...testObj,
      source: "publish",
    });
    publishErrors = validatePublish({ ...testObj, source: "publish" });

    if (
      detailsErrors.allowPublish &&
      settingsErrors.allowPublish &&
      questionsErrors.allowPublish &&
      assignLinksErrors.allowPublish &&
      notificationErrors.allowPublish &&
      publishErrors.allowPublish
    ) {
      const callPublishApi = async (notifyNow) => {
        publishButtonLoading = true;
        await tick();

        await onUpdateQuestion(true, true);
        console.log("publish success");

        // Check if this is an Apti Booster test
        if (testObj.isPracticeTest === "apti-booster") {
          // First assign to Apti Booster
          try {
            const url = `${BACKEND_URL}/apti-booster/admin/test/assign-to-apti-booster/`;
            const payload = {
              testId: testObj._id,
              boardIds: [testObj.selectedBoard],
              classIds: [getClassKey(testObj.selectedClass)],
              subjectIds: testObj.selectedSubjectIds,
              chapterIds: testObj.selectedChapterIds,
            };

            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            });

            const data = await response.json();

            if (!response.ok) {
              pError(
                "Error",
                data.errMsg ||
                  data.message ||
                  "Failed to assign test to Apti Booster",
              );
              publishButtonLoading = false;
              await tick();
              return;
            }
          } catch (error) {
            console.error("API Error:", error);
            pError("Error", "Failed to assign test to Apti Booster");
            publishButtonLoading = false;
            await tick();
            return;
          }
        }

        // Then publish the test
        let res = await api_publishTest({
          publish: true,
          notifyNow,
          _id: testObj._id,
        });

        publishButtonLoading = false;
        await tick();

        if (res.status) {
          if (testObj.isPracticeTest === "apti-booster") {
            pSuccess(
              "",
              "Exam published and assigned to Apti Booster successfully",
            );
          } else {
            pSuccess("", "Exam published successfully");
          }
          reload();
        } else {
          pError("", res.errMsg || res.error || "something went wrong");
        }
      };

      PNotify.notice({
        title: "Publish & notify now?",
        text:
          testObj.isPracticeTest === "apti-booster"
            ? "This will also assign the test to Apti Booster. You have three options to choose from."
            : "You have three options to choose from.",
        icon: "fas fa-question-circle",
        hide: false,
        closer: true,
        sticker: false,
        destroy: true,
        modules: new Map([
          ...PNotify.defaultModules,
          [
            PNotifyConfirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Yes",
                  primary: true,
                  click: (notice) => {
                    notice.close();
                    callPublishApi(true);
                  },
                },
                {
                  text: "Publish only",
                  click: (notice) => {
                    notice.close();
                    callPublishApi();
                  },
                },
                {
                  text: "Cancel",
                  click: (notice) => {
                    notice.close();
                    console.log("Publish canceled, ");
                  },
                },
              ],
            },
          ],
        ]),
      });
    } else {
      console.error(
        detailsErrors,
        settingsErrors,
        questionsErrors,
        assignLinksErrors,
        notificationErrors,
        publishErrors,
      );
      tabError.details = !detailsErrors.allowPublish;
      tabError.settings = !settingsErrors.allowPublish;
      tabError.questionList = !questionsErrors.allowPublish;
      tabError.assign = !assignLinksErrors.allowPublish;
      tabError.notifications = !notificationErrors.allowPublish;
      tabError.publish = !publishErrors.allowPublish;

      // redirect to tab where error found
      if (tabError.details && testObj.menu == "details") {
        // do nothing
      } else if (tabError.details && testObj.menu != "details") {
        navigate("/dashboard/tests/view/" + testId + "");
      } else if (tabError.settings && testObj.menu == "settings") {
        // do nothing
      } else if (tabError.settings && testObj.menu != "settings") {
        navigate("/dashboard/tests/view/" + testId + "/settings");
      } else if (tabError.questionList && testObj.menu == "questionList") {
        // do nothing
      } else if (tabError.questionList && testObj.menu != "questionList") {
        navigate("/dashboard/tests/view/" + testId + "/questionList");
      } else if (tabError.assign && testObj.menu == "assign") {
        // do nothing
      } else if (tabError.assign && testObj.menu != "assign") {
        navigate("/dashboard/tests/view/" + testId + "/assign");
      } else if (tabError.notifications && testObj.menu == "notifications") {
        // do nothing
      } else if (tabError.notifications && testObj.menu != "notifications") {
        navigate("/dashboard/tests/view/" + testId + "/notifications");
      } else if (tabError.publish && testObj.menu == "publish") {
        // do nothing
      } else if (tabError.publish && testObj.menu != "publish") {
        navigate("/dashboard/tests/view/" + testId + "/publish");
      }
    }
  };
  let reload = async function () {
    let obj = await api_getTestById({ testId });
    console.log(
      "Test reload obj ------------------------------------------------- : ",
      obj.data,
    );
    if (!testObj.menu) obj.data.menu = "details";
    else obj.data.menu = testObj.menu;
    testObj = obj.data;
    testObj.loaded = true;
    testObj.startTime = testObj.startDate;
    testObj.endTime = testObj.endDate;
    testObj.resultTime = testObj.resultDate;
    testObj.qTextObj = testObj.qTextObj || {};
    testObj.settings = testObj.settings || [];
    testObj.batchArr = testObj.batchArr || [];
    testObj.groupsLinkArr = testObj.groupsLinkArr || [];
    testObj.studentsLinkArr = testObj.studentsLinkArr || [];
    testObj.linkArr = testObj.linkArr || [];
    testObj.practiceTestParentId = testObj.practiceTestParentId || [];
    testObj.isPracticeTest = testObj.isPracticeTest || "no";
    testObj.isDurationDividedAcrossSections =
      testObj.isDurationDividedAcrossSections || "no";
    testObj.notification_app = Boolean(testObj.notification_app);
    testObj.notification_sms = Boolean(testObj.notification_sms);
    testObj.publishAnnouncement = testObj.publishAnnouncement || {
      message: `Test ${testObj.displayName || testObj.name || "[testname]"} is assigned to you. It will start on ${testObj.startDate ? moment(new Date(testObj.startDate)).format(DD - MMM - YYY) : "[startdate]"} at ${testObj.startDate ? moment(new Date(testObj.startDate)).format("hh:mm A") : "[starttime]"}`,
      announcementId:
        (testObj.publishAnnouncement &&
          testObj.publishAnnouncement.announcementId) ||
        null,
    };
    testObj.announcementBeforeExam = testObj.announcementBeforeExam || {
      message: `Your test ${testObj.displayName || testObj.name || "[testname]"} is starting today at ${testObj.startDate ? moment(new Date(testObj.startDate)).format("hh:mm A") : "[starttime]"}.`,
      timeToNotify: null,
      announcementId:
        (testObj.announcementBeforeExam &&
          testObj.announcementBeforeExam.announcementId) ||
        null,
    };
    if (testObj.displayResultImmediately === undefined) {
      testObj.displayResultImmediately = true;
    }
    if (testObj.pdfObj) {
      // testObj.pdfObj.time = testObj.pdfObj.date
    } else testObj.pdfObj = {};

    await tick();
    feather.replace();
  };

  let onPrintButtonClick = async function () {
    resetErrors();
    questionsErrors = validateQuestions({ ...testObj, source: "print" });
    generatePDFErrors = validateGeneratePDF({ ...testObj, source: "print" });
    tabError.questionList = !questionsErrors.allowPrint;
    tabError.generatePDF = !generatePDFErrors.allowPrint;

    if (!(questionsErrors.allowPrint && generatePDFErrors.allowPrint)) {
      // Handle errors if any
      console.error(questionsErrors, generatePDFErrors);
      if (tabError.questionList && testObj.menu == "questionList") {
        // do nothing
      } else if (tabError.questionList && testObj.menu != "questionList") {
        navigate("/dashboard/tests/view/" + testId + "/questionList");
      } else if (tabError.generatePDF && testObj.menu == "generatePDF") {
        // do nothing
      } else if (tabError.generatePDF && testObj.menu != "generatePDF") {
        navigate("/dashboard/tests/view/" + testId + "/generatePDF");
      }
      return;
    }

    await onSaveClicked();
    let xMax, yMax;
    if (document.all) {
      xMax = screen.width;
      yMax = screen.height;
    } else if (document.layers) {
      xMax = window.outerWidth;
      yMax = window.outerHeight;
    } else {
      xMax = window.outerWidth - 20;
      yMax = window.outerHeight - 70;
    }

    // Open the print page in a new window
    let printWindow = window.open(
      `/test/${testObj._id}/print`,
      "_blank",
      "scrollbars=yes,width=" +
        xMax +
        ",height=" +
        yMax +
        ",top=0,left=0,resizable=yes",
    );
    if (!printWindow) {
      console.error("Failed to open print window");
      return;
    }

    // var popup = window.open(printWindow);
    // if (popup == null)
    // alert('Please change your popup settings');
    // else {
    //   popup.moveTo(0, 0);
    //   popup.resizeTo(screen.width, screen.height);
    // }

    // print paper
    // console.log("onPublishClicked : ", testObj._id);

    // const testId = testObj._id;

    // if (printButtonLoading) return;

    // printButtonLoading = true;

    // printButtonLoading = true;

    // let res = await api_printPaper(testId);

    // if (res.status) {
    //   console.log("Responded ")
    // } else {
    //   pError("", res.errMsg || res.error || "Something went wrong");
    // }
    // printButtonLoading = false;
    // await tick();
  };

  function initScroll() {
    const scroll1 = new PerfectScrollbar(elem_menuScroll, {
      suppressScrollX: true,
    });
  }

  function initDetailsScroll() {
    const scroll2 = new PerfectScrollbar(elem_detailsTabScroll, {
      // suppressScrollY: true,
      // suppressScrollX: true,
    });
  }

  onMount(async function () {
    initScroll();
    initDetailsScroll();
    testDataLoading = false;
    await reload();
    testDataLoading = true;
  });
</script>

<div
  class="content-body fadeIn"
  style="position: relative;    padding-bottom: 0;"
>
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-15 mg-lg-b-15 mg-xl-b-15"
  >
    <div style="width: 12rem;">
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item"><a href="/dashboard/tests">Tests</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            #{testObj.testId}
          </li>
        </ol>
      </nav>
      <div
        data-toggle="tooltip"
        title={testObj.name ? testObj.name.toUpperCase() : ""}
      >
        <h4
          class="mg-b-0 tx-spacing--1"
          style="width:600px;  white-space: nowrap;
        overflow: hidden; text-overflow:ellipsis ;"
        >
          {#if testObj.name}
            {testObj.name.toUpperCase()}
          {/if}
        </h4>
      </div>

      <p
        class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5 hideType"
      >
        {#if testObj.type}
          {testObj.type.toUpperCase()}
        {/if}
      </p>
    </div>
    <div class="d-block">
      <button
        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25"
        on:click={onPrintButtonClick}
        ><i data-feather="printer" class="wd-10 mg-r-5" />
        <ButtonLoader isLoading={printButtonLoading} btnText="Print" />
      </button>
      {#if !isPublished || testObj.menu == "generatePDF"}
        <button
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25"
          disabled={isPublished && testObj.menu != "generatePDF"}
          on:click={() =>
            (!isPublished || testObj.menu == "generatePDF") && onSaveClicked()}
          ><i data-feather="save" class="wd-10 mg-r-5" />
          <ButtonLoader isLoading={saveButtonLoading} btnText="Save" />
        </button>
      {/if}
      {#if isPublished}
        <button
          class="btn btn-sm pd-x-15 btn-danger btn-uppercase"
          on:click={onUnpublishClicked}
          ><i data-feather="anchor" class="wd-10 mg-r-5" />
          <ButtonLoader
            isLoading={unpublishButtonLoading}
            btnText="Unpublish"
          />
        </button>
      {:else}
        <button
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase"
          on:click={onPublishClicked}
          ><i data-feather="anchor" class="wd-10 mg-r-5" />
          <ButtonLoader isLoading={publishButtonLoading} btnText="Publish" />
        </button>
      {/if}
    </div>
  </div>

  <div
    style="    margin-left: -25px; height: calc(100% - 81px);
        		margin-right: -25px; display: flex;
                border-top: 1px solid #e5e8ee;"
  >
    <div
      style="width : 300px; border-right: 1px solid #e5e8ee; max-height: 100% !important"
      class="pos-relative d-block scroll scroll-sm"
      bind:this={elem_menuScroll}
    >
      <div class="contact-list pd-t-20 pd-x-20 question-wiz">
        <div
          class="media"
          class:active={testObj.menu == "details"}
          class:bd={tabError.details}
          class:bd-danger={tabError.details}
          on:click={() => {
            if (!isPublished) onSaveClicked(true);
            navigate("/dashboard/tests/view/" + testId);
          }}
        >
          <div>
            <div class="avatar avatar-sm">
              <span class="avatar-initial rounded-circle">
                <i data-feather="clipboard" class="wd-15" />
              </span>
            </div>
          </div>
          <div class="media-body mg-l-10">
            <h6 class="tx-13 mg-b-3">Test Details</h6>
            <span class="tx-12">Timing, Level, etc</span>
          </div>
        </div>
        <div
          class="media"
          style="display: none;"
          class:active={testObj.menu == "settings"}
          class:bd={tabError.settings}
          class:bd-danger={tabError.settings}
          on:click={() => {
            if (!isPublished) onSaveClicked(true);
            navigate("/dashboard/tests/view/" + testId + "/settings");
          }}
        >
          <div>
            <div class="avatar avatar-sm">
              <span class="avatar-initial rounded-circle">
                <i data-feather="settings" class="wd-15" />
              </span>
            </div>
          </div>

          <div class="media-body mg-l-10">
            <h6 class="tx-13 mg-b-3">Settings</h6>
            <span class="tx-12">Shuffle Questions, options, etc</span>
          </div>
        </div>
        <div
          class="media"
          class:active={testObj.menu == "questionList"}
          class:bd={tabError.questionList}
          class:bd-danger={tabError.questionList}
          on:click={() => {
            if (!isPublished) onSaveClicked(true);
            navigate("/dashboard/tests/view/" + testId + "/questionList");
          }}
        >
          <div>
            <div class="avatar avatar-sm">
              <span class="avatar-initial rounded-circle">
                <i data-feather="list" class="wd-15" />
              </span>
            </div>
          </div>
          <div class="media-body mg-l-10">
            <h6 class="tx-13 mg-b-3">Question Select</h6>
            <span class="tx-12">Set question for this test</span>
          </div>
        </div>
        <div
          class="media"
          class:active={testObj.menu == "assign"}
          class:bd={tabError.assign}
          class:bd-danger={tabError.assign}
          on:click={() => {
            if (!isPublished) onSaveClicked(true);
            navigate("/dashboard/tests/view/" + testId + "/assign");
          }}
        >
          <div>
            <div class="avatar avatar-sm">
              <span class="avatar-initial rounded-circle">
                <i data-feather="link-2" class="wd-15" />
              </span>
            </div>
          </div>
          <div class="media-body mg-l-10">
            <h6 class="tx-13 mg-b-3">Assign</h6>
            <span class="tx-12">Batch, student, etc</span>
          </div>
        </div>
        <div
          class="media"
          class:active={testObj.menu == "notifications"}
          class:bd={tabError.notifications}
          class:bd-danger={tabError.notifications}
          on:click={() => {
            if (!isPublished) onSaveClicked(true);
            navigate("/dashboard/tests/view/" + testId + "/notifications");
          }}
        >
          <div>
            <div class="avatar avatar-sm">
              <span class="avatar-initial rounded-circle">
                <i data-feather="volume-2" class="wd-15" />
              </span>
            </div>
          </div>
          <div class="media-body mg-l-10">
            <h6 class="tx-13 mg-b-3">Notifications</h6>
            <span class="tx-12">on publish, before test, etc</span>
          </div>
        </div>
        <div
          class="media"
          class:active={testObj.menu == "publish"}
          class:bd={tabError.publish}
          class:bd-danger={tabError.publish}
          on:click={() => {
            if (!isPublished) onSaveClicked(true);
            navigate("/dashboard/tests/view/" + testId + "/publish");
          }}
        >
          <div>
            <div class="avatar avatar-sm">
              <span class="avatar-initial rounded-circle">
                <i data-feather="anchor" class="wd-15" />
              </span>
            </div>
          </div>
          <div class="media-body mg-l-10">
            <h6 class="tx-13 mg-b-3">Publish</h6>
            <span class="tx-12">Start Date, End date</span>
          </div>
        </div>
        <div
          class="media"
          class:active={testObj.menu == "generatePDF"}
          class:bd={tabError.generatePDF}
          class:bd-danger={tabError.generatePDF}
          on:click={() => {
            onSaveClicked(true);
            navigate("/dashboard/tests/view/" + testId + "/generatePDF");
          }}
        >
          <div>
            <div class="avatar avatar-sm">
              <span class="avatar-initial rounded-circle">
                <i data-feather="external-link" class="wd-15" />
              </span>
            </div>
          </div>
          <div class="media-body mg-l-10">
            <h6 class="tx-13 mg-b-3">Generate PDF</h6>
            <span class="tx-12">Settings for generating PDF of test</span>
          </div>
        </div>
        <div
          class="media"
          class:active={testObj.menu == "testResult"}
          on:click={() => {
            if (!isPublished) onSaveClicked(true);
            navigate("/dashboard/tests/view/" + testId + "/testResult");
          }}
        >
          <div>
            <div class="avatar avatar-sm">
              <span class="avatar-initial rounded-circle">
                <i data-feather="file-text" class="wd-15" />
              </span>
            </div>
          </div>
          <div class="media-body mg-l-10">
            <h6 class="tx-13 mg-b-3">Test Result</h6>
            <span class="tx-12">All results of this test</span>
          </div>
        </div>
        <div
          class="media"
          class:active={testObj.menu == "batchwiseresult"}
          on:click={() => {
            if (!isPublished) onSaveClicked(true);
            navigate("/dashboard/tests/view/" + testId + "/batchwiseresult");
          }}
        >
          <div>
            <div class="avatar avatar-sm">
              <span class="avatar-initial rounded-circle">
                <i data-feather="grid" class="wd-15" />
              </span>
            </div>
          </div>
          <div class="media-body mg-l-10">
            <h6 class="tx-13 mg-b-3">Batch Wise Test Result</h6>
            <span class="tx-12">All batches results of this test</span>
          </div>
        </div>
        <div
          class="media"
          class:active={testObj.menu == "questionanalytics"}
          on:click={() => {
            if (!isPublished) onSaveClicked(true);
            navigate("/dashboard/tests/view/" + testId + "/questionanalytics");
          }}
        >
          <div>
            <div class="avatar avatar-sm">
              <span class="avatar-initial rounded-circle">
                <i data-feather="grid" class="wd-15" />
              </span>
            </div>
          </div>
          <div class="media-body mg-l-10">
            <h6 class="tx-13 mg-b-3">Questions Analytics</h6>
            <span class="tx-12">Questions wise analysis</span>
          </div>
        </div>
        <div
          class="media"
          class:active={testObj.menu == "questionsReported"}
          on:click={() => {
            if (!isPublished) onSaveClicked(true);
            navigate("/dashboard/tests/view/" + testId + "/questionsReported");
          }}
        >
          <div>
            <div class="avatar avatar-sm">
              <span class="avatar-initial rounded-circle">
                <i data-feather="clipboard" class="wd-15" />
              </span>
            </div>
          </div>
          <div class="media-body mg-l-10">
            <h6 class="tx-13 mg-b-3">Reported Questions</h6>
            <span class="tx-12">Invalid questions, answers, etc</span>
          </div>
        </div>
      </div>
      <div class="contact-list pd-t-20 pd-x-20 question-wiz">
        {#if isPublished}
          <div
            class="alert alert-success publish-success"
            style="display: block;"
          >
            <strong class="tx-16">Status</strong>
            <span
              class="tx-14"
              style="margin-top: .8rem;padding-top: .8rem;font-size: 1.2rem;font-weight: 400;"
              >: Published</span
            >
          </div>
          <!-- <span class="tx-12">{testDuration || ""}</span> -->
        {:else}
          <div class="alert alert-warning publish-warn no-box-shadow">
            <strong class="tx-16">Status</strong>
            <span
              class="tx-14"
              style="margin-top: .8rem;padding-top: .8rem;font-size: 1.2rem;font-weight: 400;"
              >: Unpublished</span
            >
          </div>
          <!-- <span class="tx-12">{testDuration || ""}</span> -->
        {/if}
      </div>
    </div>
    <div
      style="flex:1; display: flex; flex-direction: column;"
      class="scroll scroll-sm"
      bind:this={elem_detailsTabScroll}
    >
      {#if testLoading}
        <div class="loader">Loading...</div>
      {/if}
      {#if testDataLoading}
        <Router>
          <Route path="">
            <Details
              bind:errorObj={detailsErrors.errors}
              {updateParent}
              {testObj}
            />
          </Route>
          <!-- <Route path="settings" >
                            <Settings bind:errorObj={settingsErrors.errors} updateParent={updateParent} bind:testObj={testObj}></Settings>
                        </Route> -->
          <Route path="questionList">
            <QuestionList
              bind:errorObj={questionsErrors.errors}
              {updateParent}
              {testObj}
            />
          </Route>
          <Route path="assign">
            <Assign
              bind:errorObj={assignLinksErrors.errors}
              {updateParent}
              {testObj}
            />
          </Route>
          <Route path="notifications">
            <Notifications
              bind:errorObj={notificationErrors.errors}
              {updateParent}
              {testObj}
            />
          </Route>
          <Route path="publish">
            <Publish
              bind:errorObj={publishErrors.errors}
              {updateParent}
              {testObj}
            />
          </Route>
          <Route path="generatePDF">
            <GeneratePdf
              bind:errorObj={generatePDFErrors.errors}
              {updateParent}
              {testObj}
            />
          </Route>
          <Route path="testResult">
            <TestResults
              bind:errorObj={publishErrors.errors}
              {updateParent}
              mode={"testResult"}
              {testObj}
            />
          </Route>
          <Route path="batchwiseresult">
            <BatchWiseResult
              bind:errorObj={publishErrors.errors}
              {testObj}
              {updateParent}
            />
          </Route>
          <Route path="questionanalytics">
            <QuestionAnalytics
              bind:errorObj={publishErrors.errors}
              {testObj}
              {updateParent}
            />
          </Route>
          <Route path="questionsReported">
            <ReportedQuestions {updateParent} mode={"cmp"} {testObj} />
          </Route>
          <!-- <Route path="studentdetailedreport">
          <StudentDetailedReport
          />
        </Route> -->
        </Router>
      {/if}
    </div>
  </div>
  <!-- <hr class="mg-y-20"> -->
  <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30" style="display: none;">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="home-tab"
          data-toggle="tab"
          href="#details"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          <i data-feather="info" class="wd-15 mg-r-10" />
          Exam Details</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="profile-tab"
          data-toggle="tab"
          href="#settings"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          <i data-feather="settings" class="wd-15 mg-r-10" />
          Settings</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="contact-tab"
          data-toggle="tab"
          href="#qSeelection"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          <i data-feather="list" class="wd-15 mg-r-10" />
          Question Selection</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="contact-tab"
          data-toggle="tab"
          href="#assign"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          <i data-feather="paperclip" class="wd-15 mg-r-10" />
          Assign</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="contact-tab"
          data-toggle="tab"
          href="#notifications"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          <i data-feather="volume" class="wd-15 mg-r-10" />
          Notifications</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="contact-tab"
          data-toggle="tab"
          href="#publish"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          <i data-feather="globe" class="wd-15 mg-r-10" />
          Publish</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="contact-tab"
          data-toggle="tab"
          href="#generatePDF"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          <i data-feather="external-link" class="wd-15 mg-r-10" />
          Generate PDF</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="contact-tab"
          data-toggle="tab"
          href="#testResult"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          <i data-feather="globe" class="wd-15 mg-r-10" />
          Test's Results</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="contact-tab"
          data-toggle="tab"
          href="#batchwiseresult"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          <i data-feather="globe" class="wd-15 mg-r-10" />
          Batches Wise Result</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="contact-tab"
          data-toggle="tab"
          href="#questionanalytics"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          <i data-feather="activity" class="wd-15 mg-r-10" />
          Questions Analytics</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="contact-tab"
          data-toggle="tab"
          href="#questionsReported"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          <i data-feather="globe" class="wd-15 mg-r-10" />
          Reported Questions</a
        >
      </li>
    </ul>
    <div class="tab-content bd bd-gray-300 bd-t-0 pd-20" />
  </div>
</div>

<style>
  [data-title] {
    font-size: 16px;
    position: relative;
    cursor: pointer;
  }

  [data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    bottom: -40px;
    padding: 8px;
    background: #f1f3f3;
    color: black;
    font-size: 14px;
    white-space: nowrap;
  }

  [data-title]:hover::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 8px;
    border: 8px solid transparent;
    /* border-bottom: 8px solid #000; */
  }
</style>
