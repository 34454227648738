<script>
  export let mode = "empty";
  export let year;
</script>

<div class="wd-100p tx-center align-items-center justify-content-center bd">
    <div class="" style="width:200px">
        <img src="/images/empty.svg" style="margin-left: 280%;" class="img-fluid" alt="No Result" />
    </div>
  {#if mode == 'search'}
    <p class="tx-bold">Holiday not found.</p>
  {:else if year == new Date().getFullYear() && mode == 'today'}
    <p class="tx-bold">
      Welcome! There are no holidays today. Add new
      holiday.
    </p>
  {:else if year == new Date().getFullYear() && mode == 'expired'}
    <p class="tx-bold">
      Welcome! There are no expired holidays in year
      {year}.
    </p>
  {:else if year == new Date().getFullYear() && mode == 'upcoming'}
    <p class="tx-bold">
      Welcome! There are no upcoming holidays in year
      {year}. Add upcoming holiday of this year.
    </p>
  {:else}
    <p class="tx-bold">There are no Holidays of year {year}.
      {#if year >= new Date().getFullYear()}
      Add new holiday.
      {/if}
    </p>
  {/if}
  
</div>
