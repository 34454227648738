<script>
    import { Router, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
    import ButtonLoader from "../utils/button_loader.svelte";

    let elem_startTimePicker;
    let elem_endTimePicker;
    let elem_sysUserSelection;
    let selectizeBatchInstance;
    let isLoading = false;
    let elem_sysUserFilterSelection;
    let studyMaterialRefId
    let selectedBatchForFolders = [];
    let elem_uploadAttachmentInput
    let youtubeLink
    let zoomMeetId = ""
    let show_lecture_on_web = false
    let enableLiveChat = false;
    let zoomMeetPasscode = ""
    let zoomThumbnail = "";
    let venue = ""
    var classStartTime = {
        HH: "",
        MM: "",
        AMPM: "",
    };
    var classEndTime = {
        HH: "",
        MM: "",
        AMPM: "",
    };
    let isYoutube = false
    let isEdit = false;
    let sessionType = ""
    let modalTitle = "Create New Class";
    let sessionPlatform = ""
    let selectedBatch = [];
    let selectedBatchDataMap = {};
    let finalSelectedBatchIds = [];
    let filteredFaculty = [];
    let filteredFacultyObj = {};
    let classTitle = "";;
    let classDescription = "";
    let selectedFaculty;
    let selectedStart;
    let selectedEnd;
    let editDoc_id;

    let colors = [
        'blue',
        'indigo',
        'purple',
        'pink',
        'red',
        'orange',
        'yellow',
        'green',
        'teal',
        'cyan',
        'white',
        'gray',
        'gray-dark',
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
        'light',
        'dark'
    ]

    let errorObj = {};
    let fullBatchList = [];

    let classDuration = "";
    function convertToDate(classTime) {
        var hours = Number(classTime.HH);
        var minutes = Number(classTime.MM);
        var AMPM = classTime.AMPM;
        if (AMPM == "PM" && hours < 12) hours = hours + 12;
        if (AMPM == "AM" && hours == 12) hours = hours - 12;
        var date = new Date(classTime.date);
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
    }

    function generateClassDuration() {
        if (
            classStartTime.HH != null &&
            classStartTime.AMPM != null &&
            classStartTime.date != null &&
            classEndTime.HH != null &&
            classEndTime.AMPM != null &&
            classEndTime.date != null
        ) {
            var startDate = convertToDate(classStartTime);
            var endDate = convertToDate(classEndTime);

            if (endDate < startDate) {
                endDate.setDate(endDate.getDate() + 1);
            }

            var diff = endDate - startDate;
            var msec = diff;
            var hh = Math.floor(msec / 1000 / 60 / 60);
            msec -= hh * 1000 * 60 * 60;
            var mm = Math.floor(msec / 1000 / 60);
            msec -= mm * 1000 * 60;
            var ss = Math.floor(msec / 1000);
            msec -= ss * 1000;

            classDuration = `${hh} Hours ${mm} mins`;
        } else {
            classDuration = ``;
        }
    }

    async function api_fetchScheduleById(_id) {

        let action = "schedule.fetchScheduleById";
        var response = await api_handleApiCalls(action, { _id });
        return response.data;

    }

    async function api_getBatchList() {

        let action = "batch.getMyBatches";
        var response = await api_handleApiCalls(action, {});
        return response;

    }

    async function api_getUrlForUpload(data) {

        // let action = "storage.getUrlForUpload";
        // var response = await api_handleApiCalls(action, data);
        // return response;                                                                

    }

    async function api_listSysUsers(data) {

        let action = "systemUser.getSystemUsersExcludingIds";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    let enableYLiveChat = async (isChecked) => {

        var linkRes = await api_handleApiCalls("schedule.updateChat", { _id: editDoc_id, enableChat: isChecked });
        if (linkRes.status) {
            pSuccess("Successfull!", "Live chat enabled Successfully!");
        } else {
            pError("Error!", "Something went wrong!")
        }
    }



    let loadBatchList = async function () {
        let resBatchList = await api_getBatchList();
        if (resBatchList.status) {
            fullBatchList = resBatchList.data;
            loadBatchesOptions(fullBatchList)
            // loadBatchesFinalOptions(fullBatchList)
        } else {
            console.log('error ', res.errMsg);
        }
    }

    function loadBatchesOptions(data, preSelectId) {
        var oldSelected = [];

        if (_$('select#assignBatch')[0].selectize) {
            oldSelected = _$('select#assignBatch')[0].selectize.getValue() == ""
                ? []
                : _$('select#assignBatch')[0].selectize.getValue();
            _$('select#assignBatch')[0].selectize.destroy();
        }

        preSelectId = preSelectId ? preSelectId : [];
        var batchSelectizeEle = _$(_$('#assignBatch')[0]).selectize({
            valueField: '_id',
            labelField: 'name',
            searchField: 'name',
            plugins: ['remove_button'],
            maxItems: 20,
            options: data,
            create: false,
            onChange: async function (values, a, b, c) {
                _$('#calendar').fullCalendar('refetchEvents');
                selectedBatch = values;
                await tick();
                selectedBatchDataMap = {};
                for (let x = 0; x < fullBatchList.length; x++) {
                    const aBatch = fullBatchList[x];
                    for (let y = 0; y < values.length; y++) {
                        const aValue = values[y];
                        if (aBatch._id == aValue) {
                            selectedBatchDataMap[aValue] = {
                                color: colors[y],
                                batchData: aBatch
                            }
                        }
                    }
                }
                loadBatchesFinalOptions(fullBatchList, selectedBatch);

            },
            render: {
                option_create: function (data, escape) {
                    return '<div class="create"><i class="icon icon-plus" style="font-size:14px;"></i><i style="font-size:12px;">Add New Language with name : </i><strong>' + escape(data.input) + '</strong></div>';
                }
            }
        });
        if (data[0]) {
            batchSelectizeEle[0].selectize.setValue(data[0].value)
        }

        if (preSelectId) {
            if (Array.isArray(preSelectId)) {
                for (var i = 0; i < preSelectId.length; i++) {
                    oldSelected.push(preSelectId[i])
                    finalSelectedBatchIds.push(preSelectId[i])
                }
            } else {
                oldSelected.push(preSelectId);
                finalSelectedBatchIds.push(preSelectId)
            }
            _$('select#assignBatch')[0].selectize.setValue(oldSelected, false)
        }
        _$('select#assignBatch').trigger("change");
    }

    function loadBatchesFinalOptions(data, preSelectId) {
        var oldSelected = [];

        if (_$('select#assignBatch_final_selected')[0].selectize) {
            _$('select#assignBatch_final_selected')[0].selectize.destroy();
        }


        preSelectId = preSelectId ? preSelectId : [];
        var batchSelectizeEle = _$(_$('#assignBatch_final_selected')[0]).selectize({
            valueField: '_id',
            labelField: 'name',
            searchField: 'name',
            plugins: ['remove_button'],
            maxItems: 100,
            options: data,
            create: false,
            onBlur: function () {
                clearError('finalSelectedBatchIds')
            },
            onChange: function (values, other) {
                finalSelectedBatchIds = values;
                _$(elem_sysUserSelection) && _$(elem_sysUserSelection)[0] && _$(elem_sysUserSelection)[0].selectize.onSearchChange(_$(elem_sysUserSelection)[0].selectize.query)
            },
            render: {
                option_create: function (data, escape) {
                    return '<div class="create"><i class="icon icon-plus" style="font-size:14px;"></i><i style="font-size:12px;">Add New Language with name : </i><strong>' + escape(data.input) + '</strong></div>';
                }
            }
        });

        _$('select#assignBatch_final_selected')[0].selectize.setValue([], false)

        if (preSelectId) {
            if (Array.isArray(preSelectId)) {
                for (var i = 0; i < preSelectId.length; i++) {
                    oldSelected.push(preSelectId[i])
                    finalSelectedBatchIds.push(preSelectId[i])
                }
            } else {
                oldSelected.push(preSelectId);
                finalSelectedBatchIds.push(preSelectId)
            }
            _$('select#assignBatch_final_selected')[0].selectize.setValue(oldSelected, false)
        }
        _$('select#assignBatch_final_selected').trigger("change");
    }

    function clearFields() {
        sessionType = ''
        sessionPlatform = ''
        classTitle = ''
        selectedStart = null
        venue = ''
        selectedEnd = null
        zoomMeetId = ''
        zoomMeetPasscode = ''
        zoomThumbnail = ""
        youtubeLink = ''
        classDescription = ''
        selectedFaculty = ''
        selectedBatch = ''
        finalSelectedBatchIds = []
        _$(elem_sysUserSelection)[0].selectize.setValue("");
        studyMaterialRefId = '';
        show_lecture_on_web = false;
        enableLiveChat = false
    }

    let initSysUserDropdown = async function () {
        await tick();
        _$(elem_sysUserSelection).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            load: async function (query, callback) {
                this.clearOptions();
                let data = {
                    start: 0,
                    length: 10,
                    search: { value: query },
                    noPagination: true,
                    includeBatchIds: _$('select#assignBatch_final_selected') && _$('select#assignBatch_final_selected')[0] && _$('select#assignBatch_final_selected')[0].selectize ? _$('select#assignBatch_final_selected')[0].selectize.getValue() : []
                };
                let result = await api_listSysUsers(data);
                if (result.status) {
                    callback(result.data.data);
                } else {
                    callback([]);
                }
            },
            onBlur: function () {
                clearError('selectedFaculty')
            },
            onChange: async function (value, a, b) {
                if (!value) {

                    return;
                } else {
                    selectedFaculty = value;
                }
            },
        });
    };


    let initFilterSysUserDropdown = async function () {
        await tick();
        selectizeBatchInstance = _$(elem_sysUserFilterSelection).selectize({
            preload: true,
            create: false,
            maxItems: 10,
            valueField: "_id",
            plugins: ['remove_button'],
            labelField: "name",
            searchField: "name",
            load: async function (query, callback) {
                this.clearOptions();
                let data = {
                    start: 0,
                    // length: 10,
                    search: { value: query },
                    noPagination: true,
                };
                let result = await api_listSysUsers(data);
                if (result.status) {
                    callback(result.data.data);
                } else {
                    callback([]);
                }
            },
            onBlur: function () {
                // clearError("recipient");
            },
            onChange: async function (value) {
                if (!value) {

                    return;
                } else {

                    let objArr = {};

                    for (const presentId in this.options) {
                        if (Object.hasOwnProperty.call(this.options, presentId)) {
                            const aUserObj = this.options[presentId];

                            for (let y = 0; y < value.length; y++) {
                                const aValue = value[y];

                                if (presentId == aValue) {
                                    objArr[presentId] = aUserObj;
                                }
                            }
                        }
                    }
                    filteredFacultyObj = objArr;
                    filteredFaculty = value;
                    _$('#calendar').fullCalendar('refetchEvents');
                }
            },
        });
    };

    let refreshCalender = function () {
        _$('#calendar').fullCalendar('refetchEvents');
    };

    let deleteEvent = async function (ele) {
        // delete-event
        var modal = _$('#modalCalendarEvent');
        let eventID = modal.find('.delete-event').data("eventId");
        let eventType = modal.find('.delete-event').data("eventType");
        pConfirm(
            "Delete Class ?",
            "Are you sure you want to delete this classes?",
            async function () {
                //   delete api
                let result = await api_handleApiCalls('schedule.delete', {
                    _id: eventID, type: eventType
                });
                if (result.status) {
                    pSuccess(null, result.data);
                    _$('#calendar').fullCalendar('refetchEvents');
                } else if (result.errMsg) {
                    pError(null, result.errMsg);
                } else if (result.error) {
                    pError(null, result.error);
                } else {
                    pError(null, "Something went wrong");
                }

                _$(".close-view-class").click();
            }
        );
    }

    function clearAllErrors() {
        errorObj = {}
    }

    function clearError(key) {
        switch (key) {
            case "classTitle":
                delete errorObj.classTitle
                break;
            case "selectedStart":
                delete errorObj.selectedStart
                break;
            case "selectedEnd":
                delete errorObj.selectedEnd
                break;
            case "finalSelectedBatchIds":
                delete errorObj.finalSelectedBatchIds
                break;
            case "selectedFaculty":
                delete errorObj.selectedFaculty
                break;
            case "sessionType":
                delete errorObj.sessionType
                break;
            case "sessionPlatform":
                delete errorObj.sessionPlatform
                break;
            case "youtubeLink":
                delete errorObj.youtubeLink
                break;
            case "zoomMeetPasscode":
                delete errorObj.zoomMeetPasscode
                break;
            case "zoomThumbnail":
                delete errorObj.zoomThumbnail
                break;
            case "zoomMeetId":
                delete errorObj.zoomMeetId
                break;
            case "venue":
                delete errorObj.venue
                break;
            default:
                errorObj = {}
        }
    }

    function validateYouTubeUrl(url) {
        // var url = $('#youTubeUrl').val();
        if (url != undefined || url != '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length == 11) {
                return true;
                // Do anything for being valid
                // if need to change the url to embed url then use below line
                // $('#ytplayerSide').attr('src', 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0');
            }
            else {
                return false
                // Do anything for not being valid
            }
        }
    }

    function validateEvent() {
        if (!classTitle) errorObj.classTitle = "Title is required field."
        if (!selectedStart) errorObj.selectedStart = "Start date is required field."
        if (!selectedEnd) errorObj.selectedEnd = "End date is required field."
        if (selectedEnd && selectedStart) {
            var e = new Date(moment(selectedEnd).format())
            var s = new Date(moment(selectedStart).format())
            if (!(e > s)) errorObj.selectedEnd = "End Date should be greater than start date."

        }

        if (!finalSelectedBatchIds.length) errorObj.finalSelectedBatchIds = "Atleast one batch needed to be selected."
        if (!selectedFaculty) errorObj.selectedFaculty = "Faculty should be selected."
        if (!sessionType) errorObj.sessionType = "Type is required field."
        if (sessionType == "online") {
            if (!sessionPlatform) errorObj.sessionPlatform = "Platform is required field."
            if (sessionPlatform == "youtube") {
                if (!youtubeLink) {
                    errorObj.youtubeLink = "Youtube link is required field."
                }
                else {
                    let valid = validateYouTubeUrl(youtubeLink);
                    if (!valid) {
                        errorObj.youtubeLink = "Please enter correct youtube link."
                    }
                }
            }
            if (sessionPlatform == "zoom") {
                if (!zoomMeetPasscode) errorObj.zoomMeetPasscode = "Zoom meeting passcode must be entered."
                if (!zoomMeetId) errorObj.zoomMeetId = "Zoom meeting ID must be entered."
            }
        }
        if (sessionType == "offline") {
            if (!venue) errorObj.venue = "Venue must be entered."
        }

        if (Object.values(errorObj).length > 0) {
            isLoading = false
            return false;
        } else {
            return true;
        }
    }


    let createClasses = async function () {
        if (isLoading) return;
        isLoading = true;
        await tick()

        if (validateEvent()) {

            var event = {
                _id: editDoc_id,
                title: classTitle,
                start: new Date(moment(selectedStart).format()),
                end: new Date(moment(selectedEnd).format()),
                description: classDescription,
                backgroundColor: '#d9e8ff',
                borderColor: '#0168fa',
                batchId: finalSelectedBatchIds,
                facultyId: selectedFaculty,
                type: sessionType,
                studyMaterialRefId,
                platform: sessionPlatform,
                youtubeLink: youtubeLink,
                zoomMeetPasscode: zoomMeetPasscode,
                zoomMeetId: zoomMeetId,
                zoomThumbnail: zoomThumbnail,
                venue: venue
            };


            if (event.type == "online") {
                event.show_liveLecture_on_web = show_lecture_on_web,
                    event.enableLiveChat = enableLiveChat
            }
            var response = await api_handleApiCalls('schedule.addUpdateClass', event);

            if (response.status) {
                isLoading = false
                clearFields();
                pSuccess("Success!", response.data);
            } else {
                isLoading = false
                pError("Error!", response.errMsg)
            }
            _$('#calendar').fullCalendar('refetchEvents');
            _$(".close-create-class").click();
        }
    }

    function clearFilter() {
        _$('select#assignBatch')[0].selectize.clear();
        selectizeBatchInstance[0].selectize.clear()
    }


    function init() {
        _$('[data-toggle="tooltip"]').tooltip();
        FilePond.setOptions({
            server: {
                url: "{{ url('upload') }}",
                process: {
                    headers: {
                        'X-CSRF-TOKEN': "csrf"
                    },
                },
            },
        });
        _$.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
        _$.fn.filepond.registerPlugin(FilePondPluginGetFile);
        _$(elem_uploadAttachmentInput).filepond(filepondOptions);

        _$(elem_uploadAttachmentInput).filepond('allowMultiple', false);
        _$(".my-pond .filepond--item-panel").css('background', '#369763')

        initDashboard();
        initSysUserDropdown();
        initFilterSysUserDropdown();
        feather.replace();
        loadBatchList();

        // Initialize scrollbar for sidebar
        new PerfectScrollbar('#calendarSidebarBody', {
            suppressScrollX: true
        });

        _$('#calendarSidebarShow').on('click', function (e) {
            e.preventDefault()
            _$('body').toggleClass('calendar-sidebar-show');

            _$(this).addClass('d-none');
            _$('#mainMenuOpen').removeClass('d-none');
        })

        _$(document).on('click touchstart', function (e) {
            e.stopPropagation();
            // closing of sidebar menu when clicking outside of it
            if (!_$(e.target).closest('.burger-menu').length) {
                var sb = _$(e.target).closest('.calendar-sidebar').length;
                if (!sb) {
                    _$('body').removeClass('calendar-sidebar-show');
                    _$('#mainMenuOpen').addClass('d-none');
                    _$('#calendarSidebarShow').removeClass('d-none');
                }
            }
        });

        var curYear = moment().format('YYYY');
        var curMonth = moment().format('MM');

        // Initialize tooltip
        _$('[data-toggle="tooltip"]').tooltip()

        // Sidebar calendar
        _$('#calendarInline').datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            beforeShowDay: function (date) {

                // add leading zero to single digit date
                var day = date.getDate();
                // console.log(day);
                return [true, (day < 10 ? 'zero' : '')];
            }
        });

        _$('#calendar').fullCalendar({
            height: 'parent',
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay,listWeek'
            },
            navLinks: true,
            selectable: true,
            selectLongPressDelay: 100,
            overlap: false,
            editable: false,
            nowIndicator: true,
            defaultView: 'agendaWeek',
            timeZone: 'Asia/Kolkata',
            slotDuration: '00:15:00',
            slotLabelInterval: 15,
            // slotLabelFormat: 'h(:mm)a',
            slotMinutes: 15,
            // minTime: '05:00:00',
            viewRender: (function () {

                var lastViewName;
                return function (view, element) {
                    setTimeout(function () {
                        _$('#calendar').fullCalendar('rerenderEvents');
                    }, 100)
                }
            })(),
            views: {
                agenda: {
                    columnHeaderHtml: function (mom) {
                        return '<span>' + mom.format('ddd') + '</span>' +
                            '<span>' + mom.format('DD') + '</span>';
                    }
                },
                day: { columnHeader: true },
                listMonth: {
                    listDayFormat: 'ddd DD',
                    listDayAltFormat: false
                },
                listWeek: {
                    listDayFormat: 'ddd DD',
                    listDayAltFormat: false
                },
                agendaThreeDay: {
                    type: 'agenda',
                    duration: { days: 3 },
                    titleFormat: 'MMMM YYYY'
                }
            },

            // eventDidMount: function (info) {
            //     var tooltip = new Tooltip(info.el, {
            //         title: info.event.extendedProps.description,
            //         placement: 'top',
            //         trigger: 'hover',
            //         container: 'body'
            //     });
            // },

            events: async function (start, end, timezone, callback) {

                var response = await api_handleApiCalls("schedule.fetch", {
                    start, end, batchId: _$("#assignBatch")[0].selectize ? _$("#assignBatch")[0].selectize.getValue() : undefined,
                    facultyId: filteredFaculty
                });
                response.data = response.data ? response.data : [];
                for (let i = 0; i < response.data.length; i++) {
                    let aEventData = response.data[i];
                    aEventData.id = aEventData._id;

                    let colorDivHtml = "";
                    aEventData.batchData = []
                    let htmlStr = "";
                    for (let o = 0; o < aEventData.batchId.length; o++) {
                        const aBatchId = aEventData.batchId[o];

                        for (let t = 0; t < fullBatchList.length; t++) {
                            const aFullBatch = fullBatchList[t];
                            if (aFullBatch._id == aBatchId) {
                                aEventData.batchData.push(aFullBatch)
                                htmlStr += '<span class="badge badge-primary mg-r-5">' + aFullBatch.name + '</span>';
                                break;
                            }
                        }

                        if (selectedBatchDataMap[aBatchId]) {
                            colorDivHtml += "<div class='batch-sm-color-div " + selectedBatchDataMap[aBatchId].color + "'></div>";
                        }

                    }

                    aEventData.originalDescription = aEventData.description;
                    aEventData.description = '<div class="fc-cust-desc">' + colorDivHtml + '</div>';
                    aEventData.batchTags = htmlStr;
                    aEventData.start = new Date(aEventData.start)
                    aEventData.end = new Date(aEventData.end)
                }

                callback(response.data);
            },

            eventAfterAllRender: function (view) {
                if (view.name === 'listMonth' || view.name === 'listWeek') {
                    var dates = view.el.find('.fc-list-heading-main');
                    dates.each(function () {
                        var text = _$(this).text().split(' ');
                        var now = moment().format('DD');

                        _$(this).html(text[0] + '<span>' + text[1] + '</span>');
                        if (now === text[1]) { _$(this).addClass('now'); }
                    });


                }

                _$(".fc-slats").find('tr[data-time="01:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="02:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="03:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="04:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="05:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="06:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="07:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="08:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="09:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="10:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="11:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="12:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="13:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="14:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="15:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="16:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="17:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="18:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="19:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="20:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="21:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="22:00:00"]').css({ "border-top": "2px solid #ddd" });
                _$(".fc-slats").find('tr[data-time="23:00:00"]').css({ "border-top": "2px solid #ddd" });


                _$(".fc-slats").find('tr[data-time="01:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="02:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="03:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="04:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="05:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="06:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="07:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="08:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="09:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="10:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="11:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="12:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="13:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="14:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="15:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="16:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="17:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="18:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="19:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="20:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="21:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="22:30:00"]').css({ "border-top": "2px solid #f3f3f3" });
                _$(".fc-slats").find('tr[data-time="23:30:00"]').css({ "border-top": "2px solid #f3f3f3" });


            },

            eventDrop: function (event, dayDelta, revertFunc, element) {

                function msToTime(ms) {
                    let seconds = (ms / 1000).toFixed(1);
                    let minutes = (ms / (1000 * 60)).toFixed(1);
                    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
                    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
                    if (seconds < 60) return seconds + " Sec";
                    else if (minutes < 60) return minutes + " Min";
                    else if (hours < 24) return hours + " Hrs";
                    else return days + " Days"
                }

                if (!confirm(event.title + " was moved by " + msToTime(dayDelta._milliseconds) + "Are you sure about this change?")) {
                    revertFunc();
                }
            },




            eventRender: function (event, element) {

                // _$(element).tooltip({ title:  });

                let htmlStr = `

                <div style="display:flex;flex-direction:column;width:350px">
                    <div ><span style="font-weight:bold;margin-bottom:5px;">${event.title}</span> (by<span style="font-style: italic;"> ${event.facultyName ? event.facultyName : "-"}</span>)</div>
                    <div>${event.originalDescription}</div>
                    <div>${event.batchTags}</div>
                </div>
                `

                tippy(element[0], {
                    allowHTML: true,
                    interactive: true,
                    sticky: true,
                    maxWidth: 350,
                    // placement: 'right',
                    // only check the "reference" rect for changes
                    sticky: 'reference',
                    zIndex: 999999999,
                    arrow: true,
                    // followCursor: true,
                    content: htmlStr,
                    // trigger: 'click',
                    // hideOnClick: true,
                });
                element.find(".fc-title").html(event.facultyName)

                if (event.batchTags) {
                    element.find('.fc-list-item-title').append('<span class="fc-desc">' + event.batchTags + '</span>');
                    element.find('.fc-content').append('<span class="fc-desc">' + event.batchTags + '</span>');
                }

                var eBorderColor = (event.source.borderColor) ? event.source.borderColor : event.borderColor;
                element.find('.fc-list-item-time').css({
                    color: eBorderColor,
                    borderColor: eBorderColor
                });

                element.find('.fc-list-item-title').css({
                    borderColor: eBorderColor
                });

                element.css('borderLeftColor', eBorderColor);
            },
        });

        var calendar = _$('#calendar').fullCalendar('getCalendar');

        // if (window.matchMedia('(min-width: 576px)').matches) {
        //     calendar.changeView('agendaWeek');
        // }

        // if (window.matchMedia('(min-width: 992px)').matches) {
        //     calendar.changeView('month');
        // }

        // calendar.option('windowResize', function (view) {
        //     if (view.name === 'listWeek') {
        //         if (window.matchMedia('(min-width: 992px)').matches) {
        //             calendar.changeView('month');
        //         } else {
        //             calendar.changeView('listWeek');
        //         }
        //     }
        // });

        // Display calendar event modal
        calendar.on('eventClick', function (calEvent, jsEvent, view) {
            // loadBatchesFinalOptions(fullBatchList, selectedBatch);
            // return;
            var modal = _$('#modalCalendarEvent');

            modal.modal('show');
            modal.find('.event-title').text(calEvent.title);
            modal.find('.delete-event').data("eventId", calEvent.id);
            modal.find('.delete-event').data("eventType", calEvent.type);

            if (calEvent.batchId) {
                modal.find('.event-batches').html(calEvent.batchTags);
                // modal.find('.event-batch').prev().removeClass('d-none');
            }

            if (calEvent.originalDescription) {
                modal.find('.event-desc').html(calEvent.originalDescription);
                modal.find('.event-desc').prev().removeClass('d-none');
            } else {
                modal.find('.event-desc').html('');
                modal.find('.event-desc').prev().addClass('d-none');
            }
            modal.find('.event-faculty').html(calEvent.facultyName);
            var start = new Date(calEvent.start)
            var end = new Date(calEvent.end)
            modal.find('.event-start-date').text(moment(start).format('LLL'));
            modal.find('.event-end-date').text(moment(end).format('LLL'));

            //styling
            modal.find('.modal-header').css('backgroundColor', (calEvent.source.borderColor) ? calEvent.source.borderColor : calEvent.borderColor);
            if (calEvent.type == "online" && calEvent.platform == "youtube") {
                isYoutube = true
            }
            else {
                isYoutube = false

            }
            delete calEvent.source;
            modal.find('.edit-event').data("event", JSON.stringify(calEvent));

            _$(".edit-event").unbind().click(async function () {

                var modal = _$('#modalCalendarEvent');
                modal.modal('hide');
                feather.replace({ width: "1em", height: "1em" });
                isEdit = true;
                modalTitle = "Update Class";

                let eventsData = JSON.parse(_$(this).data('event'));
                editDoc_id = eventsData._id;


                var classObj = await api_fetchScheduleById(eventsData._id)

                var selectize = _$(elem_sysUserSelection)[0].selectize;
                if (selectize.options[eventsData.facultyId]) {
                    selectize.setValue(eventsData.facultyId);
                } else {
                    selectize.addOption({ name: eventsData.facultyName, _id: eventsData.facultyId });
                    selectize.setValue(eventsData.facultyId);
                }

                loadBatchesFinalOptions(fullBatchList, eventsData.batchId);
                clearAllErrors();
                feather.replace();
                _$('#modalCreateEvent').modal('show');

                let startDate = moment(eventsData.start)
                let endDate = moment(eventsData.end)


                var today = startDate.format('YYYY-MM-DD')
                // var now = new Date(startDate);
                // var day = ("0" + now.getDate()).slice(-2);
                // var month = ("0" + (now.getMonth() + 1)).slice(-2);
                // var today = now.getFullYear() + "-" + (month) + "-" + (day);
                _$('#eventStartDate').val(today);

                var today = endDate.format('YYYY-MM-DD')
                // var now = new Date(endDate);
                // var day = ("0" + now.getDate()).slice(-2);
                // var month = ("0" + (now.getMonth() + 1)).slice(-2);
                // var today = now.getFullYear() + "-" + (month) + "-" + (day);
                _$('#eventEndDate').val(today);

                _$(elem_startTimePicker).timepicker("setTime", startDate.format('LT'));
                _$(elem_endTimePicker).timepicker("setTime", endDate.format('LT'));


                selectedStart = startDate;
                selectedEnd = endDate;

                classTitle = eventsData.title;
                classDescription = eventsData.originalDescription;
                sessionType = eventsData.type
                sessionPlatform = classObj.platform

                if (eventsData.type == 'online' && classObj.platform == 'youtube') {
                    youtubeLink = classObj.youtubeLink
                }
                if (eventsData.type == 'online' && classObj.platform == 'zoom') {
                    zoomMeetId = classObj.zoomMeetId
                    zoomMeetPasscode = classObj.zoomMeetPasscode
                    zoomThumbnail = classObj.zoomThumbnail

                }

                if (eventsData.type == 'offline') venue = classObj.venue

                studyMaterialRefId = classObj.studyMaterialRefId ? classObj.studyMaterialRefId : ""
                enableLiveChat = classObj.enableLiveChat ? classObj.enableLiveChat : false;
                show_lecture_on_web = classObj.show_live_lecture_on_web ? classObj.show_live_lecture_on_web : false;
            })


            _$(".view-attendance").unbind().click(async function () {

                navigate("/dashboard/schedule/viewattendance/" + calEvent._id)
            })

        });

        // display current date
        var dateNow = calendar.getDate();
        calendar.option('select', function (startDate, endDate) {

            isEdit = false;
            modalTitle = "Create New Class";
            editDoc_id = undefined;
            clearFields();

            if (filteredFaculty.length > 0) {
                var selectize = _$(elem_sysUserSelection)[0].selectize;

                if (selectize.options[filteredFaculty[0]]) {
                    selectize.setValue(filteredFaculty[0]);
                } else {
                    selectize.addOption({ name: filteredFacultyObj[filteredFaculty[0]].name, _id: filteredFaculty[0] });
                    selectize.setValue(filteredFaculty[0]);
                }
            } else {
                var selectize = _$(elem_sysUserSelection)[0].selectize;
                selectize.clear();
            }

            loadBatchesOptions(fullBatchList, selectedBatch)
            loadBatchesFinalOptions(fullBatchList, selectedBatch);
            clearAllErrors();
            feather.replace();


            _$('#modalCreateEvent').modal('show');

            var today = startDate.format('YYYY-MM-DD')
            // var now = new Date(startDate);
            // var day = ("0" + now.getDate()).slice(-2);
            // var month = ("0" + (now.getMonth() + 1)).slice(-2);
            // var today = now.getFullYear() + "-" + (month) + "-" + (day);
            _$('#eventStartDate').val(today);

            today = endDate.format('YYYY-MM-DD')
            // var now = new Date(endDate);
            // var day = ("0" + now.getDate()).slice(-2);
            // var month = ("0" + (now.getMonth() + 1)).slice(-2);
            // var today = now.getFullYear() + "-" + (month) + "-" + (day);
            _$('#eventEndDate').val(today);

            _$(elem_startTimePicker).timepicker("setTime", startDate.format('LT'));
            _$(elem_endTimePicker).timepicker("setTime", endDate.format('LT'));

            selectedStart = startDate
            selectedEnd = endDate


            // start: curYear + '-' + curMonth + '-08T08:30:00',
            // end: curYear + '-' + curMonth + '-08T13:00:00',
            // title: 'ThemeForest Meetup',
            // description: 'In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...'



            // _$('#eventStartTime').val().trigger('change');
            // _$('#eventEndTime').val(endDate.format('LT')).trigger('change');
        });

        _$('.select2-modal').select2({
            minimumResultsForSearch: Infinity,
            dropdownCssClass: 'select2-dropdown-modal',
        });

        _$('.calendar-add').on('click', function (e) {
            e.preventDefault()
            // clearFields();
            feather.replace();
            _$('#modalCreateEvent').modal('show');
        });


        _$(elem_startTimePicker).timepicker({
            timeFormat: "h:mm p",
            interval: 15,
            dynamic: false,
            dropdown: true,
            scrollbar: true,
            // template: "modal",
            defaultTime: "12:00 AM",
            change: async function (time) {
                let scheduleTime;
                if (time) {
                    scheduleTime = new Date(time);
                    classStartTime.HH = scheduleTime.getHours();
                    classStartTime.MM = scheduleTime.getMinutes();
                    classStartTime.AMPM = classStartTime.HH >= 12 ? "PM" : "AM";
                    classStartTime.date = _$('#eventStartDate').val();
                    selectedStart = convertToDate(classStartTime);
                } else {
                    scheduleTime = null;
                }
                await tick();
            },
        });


        _$(elem_endTimePicker).timepicker({
            timeFormat: "h:mm p",
            interval: 15,
            dynamic: false,
            dropdown: true,
            scrollbar: true,
            // template: "modal",
            defaultTime: "12:00 AM",
            change: async function (time) {
                let scheduleTime;
                if (time) {
                    scheduleTime = new Date(time);
                    classEndTime.HH = scheduleTime.getHours();
                    classEndTime.MM = scheduleTime.getMinutes();
                    classEndTime.AMPM = classEndTime.HH >= 12 ? "PM" : "AM";
                    classEndTime.date = _$('#eventEndDate').val();
                    selectedEnd = convertToDate(classEndTime);
                } else {
                    scheduleTime = null;
                }
                generateClassDuration();
                await tick();
            },
        });



    }


    let filepondOptions = {
        allowDownloadByUrl: true,
        acceptedFileTypes: ["image/*", "application/*", "text/*"],
        allowRevert: true,
        onremovefile: function (err, file) {
            PNotify.notice({
                title: "Delete Attachment",
                text: "Do you want to remove the file from attachments?",
                icon: "fas fa-question-circle",
                hide: false,
                closer: true,
                sticker: false,
                destroy: true,
                modules: new Map([
                    ...PNotify.defaultModules,
                    [
                        PNotifyConfirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Yes",
                                    primary: true,
                                    click: async (notice) => {
                                        notice.close()
                                        var obj = {
                                            originalFileName: file.filename,
                                            fileExtension: file.fileExtension,
                                            assignmentId: _id
                                        }
                                        var res = await api_RemoveAttachmentFiles(obj);

                                        if (res.status) {
                                            pSuccess("Successful!!", "Successfully removed an attachment.")
                                        } else {
                                            pError("Error!!", "Something went wrong.")
                                        }
                                    }
                                },

                                {
                                    text: "No",
                                    click: (notice) => {
                                        notice.close()
                                    }
                                },
                            ],
                        },
                    ],
                ]),
            });
        },
        server: {
            process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                // fieldName is the name of the input field
                // file is the actual file object to send
                const formData = new FormData();
                formData.append(fieldName, file, file.name);


                let originalFileName = file.name;
                let _fileName = file.name.split(".");

                var fileExtension = _fileName[_fileName.length - 1];
                var fileSize = file.size
                //  var formData = new FormData();
                // formData.append('file', file, "newFileName"+_fileName);

                var res = {}

                // await api_getUrlForUpload({
                //     name: originalFileName,
                //     type: file.type,
                //     uploadType: "ZoomThumbnail",
                //     fileId: _id
                // });
                if (res.status) {
                    _$.ajax({
                        xhr: function () {
                            var xhr = new window.XMLHttpRequest();
                            xhr.upload.addEventListener("progress", function (e) {
                                // var percentComplete = (evt.loaded / evt.total) * 100;
                                progress(e.lengthComputable, e.loaded, e.total);
                            }, false);
                            return xhr;
                        },
                        url: res.data.url,
                        type: "PUT",
                        data: file,
                        dataType: "text",
                        cache: false,
                        contentType: file.type,
                        processData: false
                    })
                        .done(async function (resss) {
                            // await api_updateAttachmentFileUrl({
                            //     fileId: _id,
                            //     originalFileName: originalFileName,
                            //     fileExtension: fileExtension,
                            //     fileSize: fileSize,
                            //     url: res.data.url,
                            // })
                            load("Done");
                        })
                        .fail(function (err) {
                            error('oh no');
                        })
                } else {
                    // todo show erro toast
                }
                return {
                    abort: () => {
                        // This function is entered if the user has tapped the cancel button
                        // request.abort();

                        // Let FilePond know the request has been cancelled
                        abort();
                    }
                };
            }
        }
    }

    function handleClickEndDate() {
        clearError('selectedEnd');
        selectedEnd = _$('#eventEndDate').val()
    }

    function handleClickStartDate() {

        clearError('selectedStart');
        selectedStart = _$('#eventStartDate').val()
    }

    function onClickOffline() {
        clearError('sessionType');
        sessionPlatform = "";
    }


    onMount(init);

</script>

<style>
    .ui-state-default {
        background: unset;
    }
</style>

<div class="content-body fadeIn" style="padding: 0;">
    <div class="d-sm-flex calendar-wrapper" style="height: 100%;">
        <div class="calendar-sidebar">
            <div class="calendar-sidebar-header">
                <span style="flex:1">
                    <div>
                        <h4 class="mg-b-0 tx-spacing--1">Schedule</h4>
                        <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0">Overview</p>
                    </div>
                </span>
                <!-- <i data-feather="search"></i>
                <div class="search-form">
                    <input type="search" class="form-control" placeholder="Search calendar">
                </div> -->


                <!-- <div class="fc-button-group">
                    <button type="button" class="fc-prev-button fc-button fc-state-default fc-corner-left" aria-label="prev" on:click={()=>refreshCalender()}>
                        <span class="fc-icon fc-icon-left-single-arrow"></span>
                    </button>
                    <button type="button"
                        class="fc-next-button fc-button fc-state-default fc-corner-right" aria-label="next">
                        <span class="fc-icon fc-icon-right-single-arrow"></span>
                    </button>
                </div> -->


                <!-- <button class="btn btn-sm btn-primary btn-icon calendar-add">
                    <div data-toggle="tooltip" title="Create New Event"><i data-feather="plus"></i></div>
                </button> -->

                <button class="btn btn-sm btn-primary btn-icon calendar-refresh mg-l-15" on:click={clearFilter}>
                    <div data-toggle="tooltip" title="Refresh Events"><i data-feather="refresh-cw"></i></div>
                </button>

            </div><!-- calendar-sidebar-header -->
            <div id="calendarSidebarBody" class="calendar-sidebar-body">
                <div class="calendar-inline" style="display: none;">
                    <div id="calendarInline"></div>
                </div><!-- calendar-inline -->

                <div class="pd-y-20 pd-x-15">
                    <label class="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 pd-l-10 mg-b-10">Select
                        Batch</label>
                    <select name="assignBatch" id="assignBatch" type="text" class="info form-control">
                        <option value="">Select Batches</option>
                    </select>
                </div>

                <!-- <div class="pd-y-20 pd-x-15">
                    <label class="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 pd-l-10 mg-b-10">Batches
                        Info</label>
                    <nav class="calendar-nav">

                        {#each selectedBatch as item}
                        <a href="javascript:void(0)" class="calendar show">
                            <div class="{'batch-color-div '+ selectedBatchDataMap[item]?.color}"></div>
                            {selectedBatchDataMap[item]?.batchData.name}
                        </a>
                        {/each}
                    </nav>
                </div> -->




                <div class="pd-y-20 pd-x-15">
                    <label class="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 pd-l-10 mg-b-10">Select
                        Faculty</label>
                    <select bind:this={elem_sysUserFilterSelection} class="info slectizedd form-control"
                        placeholder="Select Faculty">
                        <option value="">Select Faculty</option>
                    </select>


                </div>

                <!-- <div class="pd-y-20 pd-x-15">
                    <label class="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 pd-l-10 mg-b-10">Batches
                        Info</label>
                    <nav class="calendar-nav">
                
                        {#each selectedBatch as item}
                        <a href="javascript:void(0)" class="calendar show">
                            <div class="{'batch-color-div '+ selectedBatchDataMap[item]?.color}"></div>
                            {selectedBatchDataMap[item]?.batchData.name}
                        </a>
                        {/each}
                
                    </nav>
                </div> -->



                <div class="pd-20 mg-b-20" style="display: none;">
                    <label class="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 mg-b-15">Upcoming
                        Events</label>
                    <div class="schedule-group">
                        <a href="#" class="schedule-item bd-l bd-2 bd-danger">
                            <h6>Company Standup Meeting</h6>
                            <span>8:00am - 9:00am, Engineering Room</span>
                        </a><!-- schedule-item -->
                        <a href="#" class="schedule-item bd-l bd-2 bd-success">
                            <h6>Start Dashboard Concept</h6>
                            <span>9:30am - 11:30am, Office Desk</span>
                        </a><!-- schedule-item -->
                        <a href="#" class="schedule-item bd-l bd-2 bd-primary">
                            <h6>Chat Design Presentation</h6>
                            <span>2:30pm - 3:00pm, Visual Room</span>
                        </a><!-- schedule-item -->
                    </div><!-- schedule-group -->
                </div>
            </div><!-- calendar-sidebar-body -->
        </div><!-- calendar-sidebar -->

        <div class="calendar-content">
            <div id="calendar" class="calendar-content-body"></div>
        </div><!-- calendar-content -->
    </div><!-- calendar-wrapper -->

    <div class="modal calendar-modal-create fade effect-scale" style="overflow: auto;" id="modalCreateEvent"
        role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body pd-20 pd-sm-30">
                    <button type="button" class="close pos-absolute t-20 r-20 close-create-class" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true"><i data-feather="x"></i></span>
                    </button>

                    <h5 class="tx-18 tx-sm-20 mg-b-20 mg-sm-b-30">{modalTitle}</h5>

                    <form id="formCalendar" method="post" action="app-calendar.svelte">
                        <div class="form-group">
                            <label for="title" class="d-block">Title <span class="required-field">*</span></label>
                            <input bind:value={classTitle} type="text" on:click={()=>clearError('classTitle')}
                            id="title" class="form-control"
                            placeholder="Add title" />
                            {#if errorObj.classTitle}
                            <div class="parsley-errors-list">{errorObj.classTitle}</div>
                            {/if}

                        </div><!-- form-group -->
                        <!-- <div class="form-group d-flex align-items-center">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input"
                                    checked>
                                <label class="custom-control-label" for="customRadio1">Event</label>
                            </div>
                            <div class="custom-control custom-radio mg-l-20">
                                <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input"
                                    checked>
                                <label class="custom-control-label" for="customRadio2">Reminder</label>
                            </div>
                        </div> -->
                        <div class="form-group">
                            <label class="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Start
                                Date <span class="required-field">*</span></label>
                            <div class="row row-xs">
                                <div class="col-7">
                                    <input id="eventStartDate" type="date" value="" on:change={handleClickStartDate}
                                        class="form-control" placeholder="Select date">
                                    {#if errorObj.selectedStart}
                                    <div class="parsley-errors-list">{errorObj.selectedStart}</div>
                                    {/if}
                                </div><!-- col-7 -->
                                <div class="col-5">
                                    <input bind:this={elem_startTimePicker} on:click={()=>clearError('selectedStart')}
                                    class:parsley-error={errorObj.selectedStart} onkeydown="return false" on:keyup={e=>
                                    e.key
                                    === 'Enter' && validateClass()}
                                    type="text"
                                    class="form-control batch-time first-input"
                                    placeholder="Enter time" />


                                    <!-- <select class="custom-select">
                                        <option selected>Select Time</option>
                                    </select> -->
                                </div><!-- col-5 -->
                            </div><!-- row -->
                        </div><!-- form-group -->
                        <div class="form-group">
                            <label class="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">End
                                Date <span class="required-field">*</span></label>
                            <div class="row row-xs">
                                <div class="col-7">
                                    <input id="eventEndDate" type="date" on:change={handleClickEndDate} value=""
                                        class="form-control" placeholder="Select date">
                                    {#if errorObj.selectedEnd}
                                    <div class="parsley-errors-list">{errorObj.selectedEnd}</div>
                                    {/if}
                                </div><!-- col-7 -->
                                <div class="col-5">

                                    <input bind:this={elem_endTimePicker} on:click={()=>clearError('selectedEnd')}
                                    class:parsley-error={errorObj.selectedStart} onkeydown="return false" on:keyup={e=>
                                    e.key
                                    === 'Enter' && validateClass()}
                                    type="text"
                                    class="form-control batch-time first-input"
                                    placeholder="Enter time" />



                                    <!-- <select class="custom-select">
                                        <option selected>Select Time</option>
                                    </select> -->
                                </div><!-- col-5 -->
                            </div><!-- row -->
                        </div><!-- form-group -->

                        <div class="form-row">
                            <div class="form-group col-sm-12">
                                <label class="d-block">Batch <span class="required-field">*</span></label>
                                <select name="assignBatch_final_selected" id="assignBatch_final_selected" type="text"
                                    class="info form-control">
                                    <option value="">Select Batches</option>
                                </select>
                                {#if errorObj.finalSelectedBatchIds}
                                <div class="parsley-errors-list">{errorObj.finalSelectedBatchIds}</div>
                                {/if}
                            </div>
                            <div class="form-group col-sm-12">
                                <label class="d-block">Faculty <span class="required-field">*</span></label>
                                <select bind:this={elem_sysUserSelection} class="info slectizedd form-control"
                                    placeholder="Select Faculty">
                                    <option value="">Select Faculty</option>
                                </select>
                                {#if errorObj.selectedFaculty}
                                <div class="parsley-errors-list">{errorObj.selectedFaculty}</div>
                                {/if}
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group mg-l-10">
                                <label class="d-block tx-sans flex-grow-1">Type <span
                                        class="required-field">*</span></label>
                                <div class="d-md-flex">
                                    <div class="custom-control custom-radio mg-r-15">
                                        <input type="radio" id="type-online" bind:group={sessionType}
                                            on:click={()=>clearError('sessionType')} class="custom-control-input"
                                        value='online'>
                                        <label class="custom-control-label" for="type-online">Online</label>
                                    </div>

                                    <div class="custom-control custom-radio mg-r-15">
                                        <input type="radio" bind:group={sessionType} id="type-offline"
                                            on:click={onClickOffline} class="custom-control-input" value='offline'>
                                        <label class="custom-control-label" for="type-offline">Offline</label>
                                    </div>
                                </div>
                                {#if errorObj.sessionType}
                                <div class="parsley-errors-list">{errorObj.sessionType}</div>
                                {/if}
                            </div>
                            {#if sessionType == "online"}
                            <div class="form-group mg-l-100">
                                <label class="d-block tx-sans flex-grow-1">Platform <span
                                        class="required-field">*</span></label>
                                <div class="d-md-flex">
                                    <div class="custom-control custom-radio mg-r-15">
                                        <input type="radio" id="type-youtube"
                                            on:click={()=>clearError('sessionPlatform')} bind:group={sessionPlatform}
                                        class="custom-control-input" value='youtube'>
                                        <label class="custom-control-label" for="type-youtube">Youtube</label>
                                    </div>

                                    <div class="custom-control custom-radio mg-r-15">
                                        <input type="radio" bind:group={sessionPlatform}
                                            on:click={()=>clearError('sessionPlatform')} id="type-zoom"
                                        class="custom-control-input" value='zoom'>
                                        <label class="custom-control-label" for="type-zoom">Zoom</label>
                                    </div>
                                </div>
                                {#if errorObj.sessionPlatform}
                                <div class="parsley-errors-list">{errorObj.sessionPlatform}</div>
                                {/if}
                            </div>
                            {/if}
                            {#if sessionType == "offline"}
                            <div class="form-group wd-500 mg-l-5 mg-r-5">
                                <label for="venue" class="d-block tx-sans flex-grow-1">Venue <span
                                        class="required-field">*</span></label>
                                <input type="text" id="venue" bind:value={venue} class="form-control"
                                    on:click={()=>clearError('venue')} placeholder="Enter Venue For Session">
                                {#if errorObj.venue}
                                <div class="parsley-errors-list">{errorObj.venue}</div>
                                {/if}
                            </div>
                            {/if}

                        </div>

                        {#if sessionPlatform == "youtube" && sessionType == "online"}
                        <div class="form-group">
                            <label for="youtubeLink" class="d-block tx-sans flex-grow-1">Youtube Link <span
                                    class="required-field">*</span></label>
                            <input type="url" id="youtubeLink" bind:value={youtubeLink} class="form-control"
                                on:click={()=>clearError('youtubeLink')} placeholder="Enter Youtube Link">
                            {#if errorObj.youtubeLink}
                            <div class="parsley-errors-list">{errorObj.youtubeLink}</div>
                            {/if}
                        </div>
                        {/if}

                        {#if sessionPlatform == "zoom" && sessionType == "online"}
                        <div class="form-group">
                            <label for="meeting-id" class="d-block tx-sans flex-grow-1">Zoom Meeting ID <span
                                    class="required-field">*</span></label>
                            <input type="number" id="meeting-id" bind:value={zoomMeetId} class="form-control"
                                on:click={()=>clearError('zoomMeetId')} placeholder="Enter Zoom Meeting ID">
                            {#if errorObj.zoomMeetId}
                            <div class="parsley-errors-list">{errorObj.zoomMeetId}</div>
                            {/if}

                            <label for="meeting-passcode" class="d-block tx-sans flex-grow-1 pd-t-15">Zoom Meeting
                                Passcode <span class="required-field">*</span></label>
                            <input type="text" id="meeting-passcode" bind:value={zoomMeetPasscode}
                                on:click={()=>clearError('zoomMeetPasscode')} class="form-control" placeholder="Enter
                            Zoom Meeting Passcode">
                            {#if errorObj.zoomMeetPasscode}
                            <div class="parsley-errors-list">{errorObj.zoomMeetPasscode}</div>
                            {/if}

                            <label for="meeting-passcode" class="d-block tx-sans flex-grow-1 pd-t-15">Zoom Meeting
                                Thumbnail </label>
                            <input type="text" id="meeting-passcode" bind:value={zoomThumbnail}
                                on:click={()=>clearError('zoomThumbnail')} class="form-control" placeholder="Enter Zoom
                            Meeting Thumbnail URL">
                            {#if errorObj.zoomThumbnail}
                            <div class="parsley-errors-list">{errorObj.zoomThumbnail}</div>
                            {/if}


                        </div>
                        {/if}
                        <fieldset class="form-fieldset mg-t-20 d-none">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="meeting-passcode"
                                        class="d-block tx-sans flex-grow-1 pd-t-15">Description</label>

                                    <input type="file" bind:this={elem_uploadAttachmentInput} class="my-pond"
                                        data-instant-upload="false" data-allow-image-preview="false" name="filepond" />
                                </div>
                            </div>
                        </fieldset>

                        <div class="form-group">
                            <textarea bind:value={classDescription} class="form-control" rows="2"
                                placeholder="Write some description (optional)"></textarea>
                        </div><!-- form-group -->

                        <div class="form-row pd-l-0">
                            {#if sessionType == "online"}
                            <div class="d-flex col">
                                <label class="d-block">Show lecture on web: </label>
                                <div class="ml-4">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="showLectureOnWeb"
                                            bind:checked={show_lecture_on_web} />
                                        <!-- on:change={(event) => editDoc_id ? showLecturesOnWeb(event.target.checked) : null}  -->
                                        <label class="custom-control-label" for="showLectureOnWeb">
                                            {@html '&nbsp'}
                                        </label>

                                    </div>
                                </div>
                            </div>
                            {/if}
                            <div class="d-flex">
                                <span class="justify-content-end">Class Duration : {classDuration}</span>
                            </div>
                        </div>

                        {#if sessionType == "online"}
                        <div class="d-flex col pd-b-0 pd-l-0 pd-t-10">
                            <label class="d-block">Enable Chat</label>
                            <div class="ml-4">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="allow-multiple-device-login"
                                        bind:checked={enableLiveChat} on:change={(event)=> editDoc_id ?
                                    enableYLiveChat(event.target.checked) : null} />
                                    <label class="custom-control-label" for="allow-multiple-device-login">
                                        {@html '&nbsp'}
                                    </label>

                                </div>
                            </div>
                        </div>
                        {/if}

                    </form>
                </div><!-- modal-body -->
                <div class="modal-footer">

                    {#if isEdit}
                    <button type="submit" class="btn btn-primary mg-r-5" disabled={isLoading} on:click={createClasses}>
                        <i data-feather="save" class="mg-r-10"></i>
                        <ButtonLoader isLoading={isLoading} btnText="Update Class" />
                    </button>
                    {:else}
                    <button type="submit" class="btn btn-primary mg-r-5" disabled={isLoading} on:click={createClasses}>
                        <i data-feather="plus" class="mg-r-10"></i>
                        <ButtonLoader isLoading={isLoading} btnText="Add Class" />
                    </button>
                    {/if}

                    <a href="" class="btn btn-secondary" data-dismiss="modal"> <i data-feather="x" class="mg-r-10"></i>
                        Discard</a>
                </div><!-- modal-footer -->
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div><!-- modal -->

    <div class="modal calendar-modal-event fade effect-scale" id="modalCalendarEvent" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="event-title"></h6>
                    <nav class="nav nav-modal-event">
                        <span href="#" class="nav-link close-view-class" style="cursor:pointer" data-dismiss="modal"><i
                                data-feather="x"></i></span>
                    </nav>
                </div><!-- modal-header -->
                <div class="modal-body">
                    <div class="row row-sm">
                        <div class="col-sm-6">
                            <label class="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Start
                                Date</label>
                            <p class="event-start-date"></p>
                        </div>
                        <div class="col-sm-6">
                            <label class="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">End
                                Date</label>
                            <p class="event-end-date"></p>
                        </div><!-- col-6 -->
                    </div><!-- row -->

                    <label class="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Batches</label>
                    <p class="event-batches tx-gray-900 mg-b-40"></p>

                    <label class="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Description</label>
                    <p class="event-desc tx-gray-900 mg-b-40"></p>
                    <label class="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Faculty</label>
                    <p class="event-faculty tx-gray-900 mg-b-40"></p>

                    <div style="display: flex;">
                        <button class="btn btn-danger delete-event pd-x-20 mg-r-20" on:click={deleteEvent}
                            data-dismiss="modal"><i data-feather="trash-2" class="mg-r-5"></i> Delete</button>
                        <button class={isYoutube ? "btn btn-primary view-attendance  pd-x-20 mg-r-20 d-none"
                            : "btn btn-primary view-attendance pd-x-20 mg-r-20" } data-dismiss="modal"><i
                                data-feather="calendar" class="mg-r-5"></i> Attendance</button>
                        <button class="btn btn-primary edit-event pd-x-20 mg-r-20" data-dismiss="modal"><i
                                data-feather="edit-3" class="mg-r-5"></i> Edit</button>
                        <button class="btn btn-secondary pd-x-20" data-dismiss="modal"><i data-feather="x"
                                class="mg-r-5"></i>Close</button>
                    </div>


                </div><!-- modal-body  on:click={()=> navigate("/dashboard/enquiries/lectureattendance/"+) }-->
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div><!-- modal -->
</div>
