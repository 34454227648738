<script>
  import { Router, links, link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../../stores";
  import ThemePicker from "../themePicker/themePicker.svelte";
  import { api } from "../api.svelte";
  import { pSuccess, pError } from "../../utils/notify.svelte";
  import { API_URL } from "../../../config.js"

  export let reload;
  const settings = {};
  let isLoading;



  async function testApi(action, data) {
    try {
      console.log("Using Access Token:", localStorage.getItem("accessToken"));
      console.log("API Endpoint:", API_URL);
      const result = await api(action, data);
      console.log("API Result:", result);
    } catch (error) {
      console.error("API Test Error:", error);
    }
  }

  // Test updating a setting
  testApi('settings.setAllowMultipleDeviceLogins', { value: true });

  // Your existing functions and mounts...
  console.log(localStorage.getItem("accessToken"));
  console.log("hello");





  async function handleApiCall(action, data = {}) {
    try {
      const response = await api(action, data);
      if (response.status) {
        if (response.data) {
          pSuccess("Success", typeof response.data === 'string' ? response.data : "Operation successful");
        }
        return response;
      } else {
        const errorMessage = response.errMsg || "Operation failed";
        pError("Error", errorMessage);
        return false;
      }
    } catch (error) {
      pError("Error", error);
      return false;
    }
  }




  async function fetchGeneralSettings() {
    if (isLoading) return;
    isLoading = true;

    try {
      const result = await handleApiCall("settings.fetchGeneralSettings");

      if (result && result.status && result.data) {
        settings.ALLOW_MULTIPLE_DEVICE_LOGIN = result.data.ALLOW_MULTIPLE_DEVICE_LOGIN || "";
        settings.ALLOW_SIGNUP = result.data.ALLOW_SIGNUP || "";
        settings.SHOW_FULL_FEES_TO_STUDENTS = result.data.SHOW_FULL_FEES_TO_STUDENTS || "";
        settings.INACTIVE_IF_NOT_PAID = result.data.INACTIVE_IF_NOT_PAID || "";
        settings.WEB_SITE = result.data.WEB_SITE || "";
        settings.TERMS_URL = result.data.TERMS_URL || "";
        settings.THEME = result.data.THEME || "";
        settings.WATERMARK = result.data.WATERMARK || "";
        settings.ADDRESS = result.data.ADDRESS || "";
        settings.ALLOW_SCREEN_RECORD = result.data.ALLOW_SCREEN_RECORD || "";
      }
    } finally {
      isLoading = false;
      await tick();
      feather.replace({ width: "1em", height: "1em" });
    }
  }

  async function updateSetting(setting, value) {
    const result = await handleApiCall(`settings.set${setting}`, { value });
    if (result && result.status) {
      await fetchGeneralSettings();
      if (reload) reload();
    }
  }

  // Setting update functions
  const setAllowMultipleDeviceLogins = (value) => updateSetting("AllowMultipleDeviceLogins", value);
  const setAllowScreenRecord = (value) => updateSetting("AllowScreenRecord", value);
  const setAllowSignup = (value) => updateSetting("AllowSignup", value);
  const setShowFullFeesToStudents = (value) => updateSetting("ShowFullFeesToStudents", value);
  const setInactiveIfNotPaid = (value) => updateSetting("InactiveIfNotPaid", value);
  const setTheme = (value) => updateSetting("Theme", value);
  const setOrganizationWebsite = (value) => updateSetting("OrganizationWebsite", value);
  const setOrganizationTerms = (value) => updateSetting("OrganizationTerms", value);
  const setOrganizationAddress = (value) => updateSetting("OrganizationAddress", value);
  const setOrganizationWatermark = (value) => updateSetting("OrganizationWatermark", value);

  onMount(() => {
    activeMenu.set("settingsGeneral");
    fetchGeneralSettings();
  });
</script>

<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xs-12">
    <ul class="list-group">
      {#if isLoading}
      <li class="list-group-item list-group-item-action">
        <div class="mg-20 pd-10">
          <div class="placeholder-paragraph">
            <div class="line mg-b-10" />
            <div class="line mg-b-10" />
          </div>
        </div>
      </li>
      {:else}
      <li class="list-group-item list-group-item-action">
        <div class="d-flex justify-content-between">
          <div>Allow Multiple Device Login</div>
          <div>
            <div class="custom-control custom-switch">
              <input type="checkbox" checked={settings.ALLOW_MULTIPLE_DEVICE_LOGIN} on:change={(event)=>
              setAllowMultipleDeviceLogins(event.target.checked)}
              class="custom-control-input"
              id="allow-multiple-device-login" />
              <label class="custom-control-label" for="allow-multiple-device-login">
                {@html '&nbsp'}
              </label>
            </div>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-action">
        <div class="d-flex justify-content-between">
          <div>Show Full Fee To Students</div>
          <div>
            <div class="custom-control custom-switch">
              <input checked={settings.SHOW_FULL_FEES_TO_STUDENTS} on:change={(event)=>
              setShowFullFeesToStudents(event.target.checked)}
              type="checkbox"
              class="custom-control-input"
              id="show-full-fees" />
              <label class="custom-control-label" for="show-full-fees">
                {@html '&nbsp'}
              </label>
            </div>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-action">
        <div class="d-flex justify-content-between">
          <div>Allow Signup</div>
          <div>
            <div class="custom-control custom-switch">
              <input checked={settings.ALLOW_SIGNUP} on:change={(event)=> setAllowSignup(event.target.checked)}
              type="checkbox"
              class="custom-control-input"
              id="allow-signup" />
              <label class="custom-control-label" for="allow-signup">
                {@html '&nbsp'}
              </label>
            </div>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-action">
        <div class="d-flex justify-content-between">
          <div>Inactive If Not Paid</div>
          <div>
            <div class="custom-control custom-switch">
              <input checked={settings.INACTIVE_IF_NOT_PAID} on:change={(event)=>
              setInactiveIfNotPaid(event.target.checked)}
              type="checkbox"
              class="custom-control-input"
              id="inactive-if-not-paid" />
              <label class="custom-control-label" for="inactive-if-not-paid">
                {@html '&nbsp'}
              </label>
            </div>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-action">
        <div class="d-flex justify-content-between">
          <div>Allow Screen Record</div>
          <div>
            <div class="custom-control custom-switch">
              <input type="checkbox" checked={settings.ALLOW_SCREEN_RECORD} on:change={(event)=>
              setAllowScreenRecord(event.target.checked)}
              class="custom-control-input"
              id="ALLOW_SCREEN_RECORD" />
              <label class="custom-control-label" for="ALLOW_SCREEN_RECORD">
                {@html '&nbsp'}
              </label>
            </div>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-action">
        <div class="d-flex justify-content-between">
          <div>Organization Website URL</div>
          <nav>
            <a class="d-inline nav-link" href={settings.WEB_SITE} target="_blank">
              <i data-feather="external-link" />
              Organization website
            </a>
            <a data-toggle="collapse" href="#website" role="button" aria-expanded="true" aria-controls="website"
              class="d-inline mg-l-5 nav-link">
              <i data-feather="edit" />
            </a>
          </nav>
        </div>
        <div class="collapse mg-t-10" id="website">
          <div class="input-group">
            <input type="text" bind:value={settings.WEB_SITE} on:keyup={(event)=> event.key === 'Enter' &&
            setOrganizationWebsite(event.target.value)}
            class="form-control"
            placeholder="Website URL. Example, https://www.google.com/"
            aria-label="Website"
            aria-describedby="button-addon2" />
            <div class="input-group-append">
              <button on:click={()=> setOrganizationWebsite(settings.WEB_SITE)}
                class="btn btn-outline-light"
                type="button"
                id="button-addon2">Save</button>
            </div>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-action">
        <div class="d-flex justify-content-between">
          <div>Terms & Conditions URL</div>
          <nav>
            <a class="d-inline nav-link" href={settings.TERMS_URL} target="_blank">
              <i data-feather="external-link" />
              Terms & Conditions URL
            </a>
            <a data-toggle="collapse" href="#terms" role="button" aria-expanded="true" aria-controls="terms"
              class="d-inline mg-l-5 nav-link">
              <i data-feather="edit" />
            </a>
          </nav>
        </div>
        <div class="collapse mg-t-10" id="terms">
          <div class="input-group">
            <input type="text" on:keyup={(event)=> event.key === 'Enter' && setOrganizationTerms(event.target.value)}
            class="form-control"
            bind:value={settings.TERMS_URL}
            placeholder="Terms & Conditions. Example, https://www.google.com/"
            aria-label="Terms & Conditions"
            aria-describedby="button-addon2" />
            <div class="input-group-append">
              <button on:click={()=> setOrganizationTerms(settings.TERMS_URL)}
                class="btn btn-outline-light"
                type="button"
                id="button-addon2">Save</button>
            </div>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-action">
        <div class="d-flex justify-content-between">
          <div>Select Theme</div>
          <div>
            <ThemePicker theme={settings.THEME} themeChanged={setTheme} />
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-action">
        <div class="d-flex justify-content-between">
          <div>Address</div>
          <nav>
            <a data-toggle="collapse" href="#address" role="button" aria-expanded="true" aria-controls="address"
              class="d-inline mg-l-5 nav-link">
              <i data-feather="edit" />
            </a>
          </nav>
        </div>
        <div class="collapse mg-t-10" id="address">
          <div class="input-group">
            <input type="text" on:keyup={(event)=> event.key === 'Enter' && setOrganizationAddress(event.target.value)}
            class="form-control"
            bind:value={settings.ADDRESS}
            placeholder="Organization Address"
            aria-label="Organization Address"
            aria-describedby="button-addon2" />
            <div class="input-group-append">
              <button on:click={()=> setOrganizationAddress(settings.ADDRESS)}
                class="btn btn-outline-light"
                type="button"
                id="button-addon2">Save</button>
            </div>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-action">
        <div class="d-flex justify-content-between">
          <div>Watermark</div>
          <nav>
            <a data-toggle="collapse" href="#watermark" role="button" aria-expanded="true" aria-controls="watermark"
              class="d-inline mg-l-5 nav-link">
              <i data-feather="edit" />
            </a>
          </nav>
        </div>
        <div class="collapse mg-t-10" id="watermark">
          <div class="input-group">
            <input type="text" on:keyup={(event)=> event.key === 'Enter' &&
            setOrganizationWatermark(event.target.value)}
            class="form-control"
            bind:value={settings.WATERMARK}
            placeholder="Organization Watermark"
            aria-label="Organization Watermark"
            aria-describedby="button-addon2" />
            <div class="input-group-append">
              <button on:click={()=> setOrganizationWatermark(settings.WATERMARK)}
                class="btn btn-outline-light"
                type="button"
                id="button-addon2">Save</button>
            </div>
          </div>
        </div>
      </li>
      {/if}
    </ul>
  </div>
</div>
