<script>
  import { Router, Link, Route, link, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { _userObj } from "../store.js";

  import api_handleApiCalls from "./apiCallHandler.js";

  let qList = [];
  let loaded = false;

  export let token;

  export let params;
  let testId;

  let testObj;

  let page = [];

  let imgURL = "";

  let defaultFontsize = 12;

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  let pageArr = [];

  let pageNumberArr = [];

  async function api_getTestById(data) {
    let action = "test.getTestById";
    var response = await api_handleApiCalls(action, data, token);
    return response;
  }

  async function api_fetchOrgInfo() {
    let action = "settings.fetchOrgInfo";
    var response = await api_handleApiCalls(action, {}, token);
    return response;
  }

  $: checkPage(pageArr);

  let checkPage = function (pageArr) {
    let qPageCount = 0;
    let kPageCount = 0;
    let sPageCount = 0;

    for (let i = 0; i < pageArr.length; i++) {
      const aPage = pageArr[i];
      if (!pageNumberArr[i]) {
        pageNumberArr[i] = {};
      }
      if (aPage.type == "questions") {
        qPageCount++;
        pageNumberArr[i].pageNo = qPageCount;
        pageNumberArr[i].type = "questions";
      } else if (aPage.type == "keys") {
        kPageCount++;
        pageNumberArr[i].pageNo = kPageCount;
        pageNumberArr[i].type = "keys";
      } else {
        sPageCount++;
        pageNumberArr[i].pageNo = sPageCount;
        pageNumberArr[i].type = "solutions";
      }
    }

    for (let i = 0; i < pageNumberArr.length; i++) {
      const aPageNumber = pageNumberArr[i];
      if (aPageNumber.type == "questions") {
        aPageNumber.totalPages = qPageCount;
      } else if (aPageNumber.type == "keys") {
        aPageNumber.totalPages = kPageCount;
      } else {
        aPageNumber.totalPages = sPageCount;
      }
    }
  };

  onMount(async () => {
    if (!/chrome/.test(navigator.userAgent.toLowerCase())) {
      alert("Generate PDF will only work on latest Chrome browser.");
      window.close();
      return;
    }
    testId = params.testId;

    let orgInfo = await api_fetchOrgInfo();

    imgURL = orgInfo.data.LOGO_URL;

    let tokenRes = await api_getTestById({
      testId: testId,
      questions: true,
    });

    tokenRes.data.questions.reverse();
    await navigate("/test/" + testId + "/print");
    let newList = [];

    qList = tokenRes.data.questions;

    for (let p = 0; p < tokenRes.data.sections.length; p++) {
      let aSec = tokenRes.data.sections[p];
      for (let l = 0; l < aSec.questions.length; l++) {
        let aQId = aSec.questions[l];

        for (let s = 0; s < tokenRes.data.questions.length; s++) {
          let aqInList = tokenRes.data.questions[s];
          if (aqInList._id == aQId) {
            newList.push(aqInList);
            break;
          }
        }
      }
    }
    qList = newList;

    testObj = tokenRes.data;

    testObj.pdfObj.date = new Date(testObj.pdfObj.date).toDateString();
    function formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    }

    testObj.pdfObj.time = formatAMPM(new Date(testObj.pdfObj.time));

    defaultFontsize = testObj.pdfObj.fontSize ? testObj.pdfObj.fontSize : 12;

    // document.getElementsByTagName('head').write("<style>body { font-size:"+defaultFontsize+"px }</style>");
    document.body.style.fontSize = defaultFontsize + "px";

    var optionFormat = {
      option_a: "a",
      option_b: "b",
      option_c: "c",
      option_d: "d",
      option_e: "e",
      option_f: "f",
      option_g: "g",
      option_h: "h",

      ans_a: "a",
      ans_b: "b",
      ans_c: "c",
      ans_d: "d",
      ans_e: "e",
      ans_f: "f",
      ans_g: "g",
      ans_h: "h",
    };
    if (testObj.pdfObj.optionFormat == "1234") {
      optionFormat.option_a = "1";
      optionFormat.option_b = "2";
      optionFormat.option_c = "3";
      optionFormat.option_d = "4";
      optionFormat.option_e = "5";
      optionFormat.option_f = "6";
      optionFormat.option_g = "7";
      optionFormat.option_h = "8";

      optionFormat.ans_a = "1";
      optionFormat.ans_b = "2";
      optionFormat.ans_c = "3";
      optionFormat.ans_d = "4";
      optionFormat.ans_e = "5";
      optionFormat.ans_f = "6";
      optionFormat.ans_g = "7";
      optionFormat.ans_h = "8";
    }

    let loadingPageIndex = 0;

    if (!pageArr[loadingPageIndex]) {
      pageArr[loadingPageIndex] = {
        type: "questions",
        elements: [],
        showHeader: true,
      };
    }

    let blankDivMinHeight = 10;

    let revelSpeed = 250;

    let sectionRender = tokenRes.data.sections;

    let checkForNewPage = async function (type, text, questionId) {
      if (questionId && sectionRender.length > 1) {
        for (let y = 0; y < sectionRender.length; y++) {
          const aSec = sectionRender[y];
          if (!aSec.rendered && aSec.questions.includes(questionId)) {
            let marginTop = 30;
            if (y == 0) {
              marginTop = 5;
            }
            let htmlStr = `<table style="width: 100%;border-bottom: 1px solid black;margin: ${marginTop}px 0 10px 0;"><tr style="border-bottom:1px solid black"><td><div style="text-transform: capitalize;font-weight:bold">${aSec.title}</div></td><td style=""><div style="text-align: right;font-size: smaller;">${aSec.questions.length} Questions<div></td></tr></table>`;
            text = htmlStr + text;
            aSec.rendered = true;
          }
        }
      }

      pageArr[loadingPageIndex].elements.push({
        html: text,
        type: type,
      });
      pageArr[loadingPageIndex].elements = pageArr[loadingPageIndex].elements;
      await tick();

      MathJax.Hub.Queue(["Typeset", MathJax.Hub]);

      await timeout(revelSpeed);

      if (page[loadingPageIndex].offsetHeight <= 0) {
        let popped = pageArr[loadingPageIndex].elements.pop();

        await tick();
        await timeout(revelSpeed);
        loadingPageIndex++;
        if (!pageArr[loadingPageIndex]) {
          pageArr[loadingPageIndex] = {
            type: type,
            elements: [popped],
            showHeader: false,
          };
        }

        await tick();
        await timeout(revelSpeed);
      } else if (page[loadingPageIndex].offsetHeight < blankDivMinHeight) {
        loadingPageIndex++;
        if (!pageArr[loadingPageIndex]) {
          pageArr[loadingPageIndex] = {
            type: type,
            elements: [],
            showHeader: false,
          };
        }

        await tick();
        await timeout(revelSpeed);
      } else {
      }
    };

    for (let i = 0; i < qList.length; i++) {
      const aQuestion = qList[i];

      var questionTxt =
        `<table class="question-content" style="margin-top:${
          i == 0 ? "0" : "15px"
        }">` +
        "<tr>" +
        '<td class="question-actual"><strong>[' +
        ("Q. " +
          (i + 1) +
          (testObj.pdfObj?.paperMode == "teachers-copy"
            ? ", ID: " + aQuestion.qId
            : "")) +
        "]&nbsp;&nbsp;</strong>" +
        aQuestion.data.question.English.replace(/<\/?p>/gi, "") +
        "</td>" +
        "</tr>" +
        "</table><br/>";

      await checkForNewPage("questions", questionTxt, aQuestion._id);

      if (aQuestion.qType == "multipleChoice") {
        aQuestion.data.answer = optionFormat["ans_" + aQuestion.data.answer];

        let option_a =
          '<table class="options"><tbody>' +
          "<tr >" +
          '<td class="question-number ' +
          (aQuestion.data.answer == "a" &&
          testObj.pdfObj?.paperMode == "teachers-copy"
            ? "highlight-ans"
            : "") +
          '"><span class="text-bold" >(' +
          optionFormat.option_a +
          ")</span> </td>" +
          '<td class="option-a">' +
          aQuestion.data.option_a?.English +
          "</td>" +
          "</tr>" +
          "</tbody></table>";

        await checkForNewPage("questions", option_a);

        let option_b =
          '<table class="options"><tbody>' +
          "<tr >" +
          '<td class="question-number ' +
          (aQuestion.data.answer == "b" &&
          testObj.pdfObj?.paperMode == "teachers-copy"
            ? "highlight-ans"
            : "") +
          '"><span class="text-bold" >(' +
          optionFormat.option_b +
          ")</span> </td>" +
          '<td class="option-b">' +
          aQuestion.data.option_b?.English +
          "</td>" +
          "</tr>" +
          "</tbody></table><br/>";

        await checkForNewPage("questions", option_b);

        let option_c =
          '<table class="options"><tbody>' +
          "<tr >" +
          '<td class="question-number ' +
          (aQuestion.data.answer == "c" &&
          testObj.pdfObj?.paperMode == "teachers-copy"
            ? "highlight-ans"
            : "") +
          '"><span class="text-bold" >(' +
          optionFormat.option_c +
          ")</span> </td>" +
          '<td class="option-c">' +
          aQuestion.data.option_c?.English +
          "</td>" +
          "</tr>" +
          "</tbody></table>";

        await checkForNewPage("questions", option_c);

        let option_d =
          '<table class="options" ><tbody>' +
          "<tr >" +
          '<td class="question-number ' +
          (aQuestion.data.answer == "d" &&
          testObj.pdfObj?.paperMode == "teachers-copy"
            ? "highlight-ans"
            : "") +
          '"><span class="text-bold" >(' +
          optionFormat.option_d +
          ")</span> </td>" +
          '<td class="option-d">' +
          aQuestion.data.option_d?.English +
          "</td>" +
          "</tr>" +
          "</tbody></table><br/>";

        await checkForNewPage("questions", option_d);
        if (aQuestion.data.option_e?.English) {
          let option_e =
            '<table class="options" ><tbody>' +
            "<tr >" +
            '<td class="question-number ' +
            (aQuestion.data.answer == "e" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_e +
            ")</span> </td>" +
            '<td class="option-e">' +
            aQuestion.data.option_e?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table><br/>";

          await checkForNewPage("questions", option_e);
        }
        if (aQuestion.data.option_f?.English) {
          let option_f =
            '<table class="options" ><tbody>' +
            "<tr >" +
            '<td class="question-number ' +
            (aQuestion.data.answer == "f" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_f +
            ")</span> </td>" +
            '<td class="option-f">' +
            aQuestion.data.option_f?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table><br/>";

          await checkForNewPage("questions", option_f);
        }
        if (aQuestion.data.option_g?.English) {
          let option_g =
            '<table class="options" ><tbody>' +
            "<tr >" +
            '<td class="question-number ' +
            (aQuestion.data.answer == "g" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_g +
            ")</span> </td>" +
            '<td class="option-g">' +
            aQuestion.data.option_g?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table><br/>";

          await checkForNewPage("questions", option_g);
        }
        if (aQuestion.data.option_h?.English) {
          let option_h =
            '<table class="options" ><tbody>' +
            "<tr >" +
            '<td class="question-number ' +
            (aQuestion.data.answer == "h" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_h +
            ")</span> </td>" +
            '<td class="option-h">' +
            aQuestion.data.option_h?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table><br/>";

          await checkForNewPage("questions", option_h);
        }
      } else if (aQuestion.qType == "multipleResponse") {
        if (aQuestion.data.answer && aQuestion.data.answer.length) {
          aQuestion.data.answer = aQuestion.data.answer.map(
            (ans) => optionFormat["ans_" + ans],
          );

          let option_a =
            '<table class="options"><tbody>' +
            "<tr >" +
            '<td id="optionA" class="question-number ' +
            (aQuestion.data.answer == "a" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_a +
            ")</span> </td>" +
            '<td class="option-a">' +
            aQuestion.data.option_a?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table>";

          await checkForNewPage("questions", option_a);

          let option_b =
            '<table class="options"><tbody>' +
            "<tr >" +
            '<td class="question-number ' +
            (aQuestion.data.answer == "b" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_b +
            ")</span> </td>" +
            '<td class="option-b">' +
            aQuestion.data.option_b?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table><br/>";

          await checkForNewPage("questions", option_b);

          let option_c =
            '<table class="options"><tbody>' +
            "<tr >" +
            '<td class="question-number ' +
            (aQuestion.data.answer == "c" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_c +
            ")</span> </td>" +
            '<td class="option-c">' +
            aQuestion.data.option_c?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table>";

          await checkForNewPage("questions", option_c);

          let option_d =
            '<table class="options" ><tbody>' +
            "<tr >" +
            '<td class="question-number ' +
            (aQuestion.data.answer == "d" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_d +
            ")</span> </td>" +
            '<td class="option-d">' +
            aQuestion.data.option_d?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table><br/>";

          await checkForNewPage("questions", option_d);
          if (aQuestion.data.option_e?.English) {
            let option_e =
              '<table class="options" ><tbody>' +
              "<tr >" +
              '<td class="question-number ' +
              (aQuestion.data.answer == "e" &&
              testObj.pdfObj?.paperMode == "teachers-copy"
                ? "highlight-ans"
                : "") +
              '"><span class="text-bold" >(' +
              optionFormat.option_e +
              ")</span> </td>" +
              '<td class="option-e">' +
              aQuestion.data.option_e?.English +
              "</td>" +
              "</tr>" +
              "</tbody></table><br/>";

            await checkForNewPage("questions", option_e);
          }
          if (aQuestion.data.option_f?.English) {
            let option_f =
              '<table class="options" ><tbody>' +
              "<tr >" +
              '<td class="question-number ' +
              (aQuestion.data.answer == "f" &&
              testObj.pdfObj?.paperMode == "teachers-copy"
                ? "highlight-ans"
                : "") +
              '"><span class="text-bold" >(' +
              optionFormat.option_f +
              ")</span> </td>" +
              '<td class="option-f">' +
              aQuestion.data.option_f?.English +
              "</td>" +
              "</tr>" +
              "</tbody></table><br/>";

            await checkForNewPage("questions", option_f);
          }
          if (aQuestion.data.option_g?.English) {
            let option_g =
              '<table class="options" ><tbody>' +
              "<tr >" +
              '<td class="question-number ' +
              (aQuestion.data.answer == "g" &&
              testObj.pdfObj?.paperMode == "teachers-copy"
                ? "highlight-ans"
                : "") +
              '"><span class="text-bold" >(' +
              optionFormat.option_g +
              ")</span> </td>" +
              '<td class="option-g">' +
              aQuestion.data.option_g?.English +
              "</td>" +
              "</tr>" +
              "</tbody></table><br/>";

            await checkForNewPage("questions", option_g);
          }
          if (aQuestion.data.option_h?.English) {
            let option_h =
              '<table class="options" ><tbody>' +
              "<tr >" +
              '<td class="question-number ' +
              (aQuestion.data.answer == "h" &&
              testObj.pdfObj?.paperMode == "teachers-copy"
                ? "highlight-ans"
                : "") +
              '"><span class="text-bold" >(' +
              optionFormat.option_h +
              ")</span> </td>" +
              '<td class="option-h">' +
              aQuestion.data.option_h?.English +
              "</td>" +
              "</tr>" +
              "</tbody></table><br/>";

            await checkForNewPage("questions", option_h);
          }
        } else {
          aQuestion.data.answer = optionFormat["ans_" + aQuestion.data.answer];
          let option_a =
            '<table class="options"><tbody>' +
            "<tr >" +
            '<td class="question-number ' +
            (aQuestion.data.answer == "a" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_a +
            ")</span> </td>" +
            '<td class="option-a">' +
            aQuestion.data.option_a?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table>";

          await checkForNewPage("questions", option_a);

          let option_b =
            '<table class="options"><tbody>' +
            "<tr >" +
            '<td class="question-number ' +
            (aQuestion.data.answer == "b" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_b +
            ")</span> </td>" +
            '<td class="option-b">' +
            aQuestion.data.option_b?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table><br/>";

          await checkForNewPage("questions", option_b);

          let option_c =
            '<table class="options"><tbody>' +
            "<tr >" +
            '<td class="question-number ' +
            (aQuestion.data.answer == "c" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_c +
            ")</span> </td>" +
            '<td class="option-c">' +
            aQuestion.data.option_c?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table>";

          await checkForNewPage("questions", option_c);

          let option_d =
            '<table class="options" ><tbody>' +
            "<tr >" +
            '<td class="question-number ' +
            (aQuestion.data.answer == "d" &&
            testObj.pdfObj?.paperMode == "teachers-copy"
              ? "highlight-ans"
              : "") +
            '"><span class="text-bold" >(' +
            optionFormat.option_d +
            ")</span> </td>" +
            '<td class="option-d">' +
            aQuestion.data.option_d?.English +
            "</td>" +
            "</tr>" +
            "</tbody></table><br/>";

          await checkForNewPage("questions", option_d);
          if (aQuestion.data.option_e?.English) {
            let option_e =
              '<table class="options" ><tbody>' +
              "<tr >" +
              '<td class="question-number ' +
              (aQuestion.data.answer == "e" &&
              testObj.pdfObj?.paperMode == "teachers-copy"
                ? "highlight-ans"
                : "") +
              '"><span class="text-bold" >(' +
              optionFormat.option_e +
              ")</span> </td>" +
              '<td class="option-e">' +
              aQuestion.data.option_e?.English +
              "</td>" +
              "</tr>" +
              "</tbody></table><br/>";

            await checkForNewPage("questions", option_e);
          }
          if (aQuestion.data.option_f?.English) {
            let option_f =
              '<table class="options" ><tbody>' +
              "<tr >" +
              '<td class="question-number ' +
              (aQuestion.data.answer == "f" &&
              testObj.pdfObj?.paperMode == "teachers-copy"
                ? "highlight-ans"
                : "") +
              '"><span class="text-bold" >(' +
              optionFormat.option_f +
              ")</span> </td>" +
              '<td class="option-f">' +
              aQuestion.data.option_f?.English +
              "</td>" +
              "</tr>" +
              "</tbody></table><br/>";

            await checkForNewPage("questions", option_f);
          }
          if (aQuestion.data.option_g?.English) {
            let option_g =
              '<table class="options" ><tbody>' +
              "<tr >" +
              '<td class="question-number ' +
              (aQuestion.data.answer == "g" &&
              testObj.pdfObj?.paperMode == "teachers-copy"
                ? "highlight-ans"
                : "") +
              '"><span class="text-bold" >(' +
              optionFormat.option_g +
              ")</span> </td>" +
              '<td class="option-g">' +
              aQuestion.data.option_g?.English +
              "</td>" +
              "</tr>" +
              "</tbody></table><br/>";

            await checkForNewPage("questions", option_g);
          }
          if (aQuestion.data.option_h?.English) {
            let option_h =
              '<table class="options" ><tbody>' +
              "<tr >" +
              '<td class="question-number ' +
              (aQuestion.data.answer == "h" &&
              testObj.pdfObj?.paperMode == "teachers-copy"
                ? "highlight-ans"
                : "") +
              '"><span class="text-bold" >(' +
              optionFormat.option_h +
              ")</span> </td>" +
              '<td class="option-h">' +
              aQuestion.data.option_h?.English +
              "</td>" +
              "</tr>" +
              "</tbody></table><br/>";

            await checkForNewPage("questions", option_h);
          }
        }
      } else if (aQuestion.qType == "numerical") {
        let option_d =
          '<table class="options" ><tbody>' +
          "<tr >" +
          '<td class="question-number"><span class="text-bold" >Ans : </span> </td>' +
          '<td class="option-d">' +
          "_______________________" +
          "</td>" +
          "</tr>" +
          "</tbody></table><br/>";

        await checkForNewPage("questions", option_d);
      } else {
      }

      if (testObj.pdfObj?.paperMode == "teachers-copy") {
        if (aQuestion.qType == "multipleResponse") {
          let solutionTxt =
            '<table class="options" ><tbody>' +
            "<tr >" +
            '<td class="option-e"> Solution : ' +
            aQuestion.data.answer +
            "</td>" +
            "</tr>" +
            "</tbody></table><br/>";

          await checkForNewPage("questions", solutionTxt);
        } else {
          let solutionTxt =
            '<table class="options" ><tbody>' +
            "<tr >" +
            '<td class="option-d"> Solution : ' +
            aQuestion.data.answer +
            "</td>" +
            "</tr>" +
            "</tbody></table><br/>";

          await checkForNewPage("questions", solutionTxt);
        }

        let explanationTxt =
          '<table class="options" ><tbody>' +
          "<tr >" +
          '<td class="option-d"> Explanation : ' +
          aQuestion.data.explanation.English +
          "</td>" +
          "</tr>" +
          "</tbody></table><br/>";

        await checkForNewPage("questions", explanationTxt);
      }
    }

    if (testObj.pdfObj?.paperMode != "teachers-copy") {
      loadingPageIndex++;

      if (!pageArr[loadingPageIndex]) {
        pageArr[loadingPageIndex] = {
          type: "keys",
          elements: [],
          showHeader: true,
        };
      }

      for (let i = 0; i < qList.length; i++) {
        const aQuestion = qList[i];

        let keyText =
          '<div class="answer-key-ele">  (' +
          (i + 1) +
          ") : " +
          aQuestion.data.answer +
          "</div>";

        await checkForNewPage("keys", keyText);
      }

      loadingPageIndex++;

      if (!pageArr[loadingPageIndex]) {
        pageArr[loadingPageIndex] = {
          type: "solutions",
          elements: [],
          showHeader: true,
        };
      }

      for (let i = 0; i < qList.length; i++) {
        const aQuestion = qList[i];

        let explanationTxt =
          '<table class="options" style="width:100%" ><tbody>' +
          "<tr >" +
          '<td class="option-d"> (' +
          (i + 1) +
          ") : " +
          aQuestion.data.answer +
          " <br/>Explanation : " +
          aQuestion.data.explanation.English +
          "</td>" +
          "</tr>" +
          "</tbody></table><br/>";

        await checkForNewPage("solutions", explanationTxt);
      }
    }
    loaded = true;

    setTimeout(() => {
      window.print();
    }, 9000);
  });
</script>

<svelte:head>
  <title
    >{testObj?.name} | {testObj?.testId} | {testObj?.pdfObj?.date}, {testObj
      ?.pdfObj?.time}</title
  >
</svelte:head>

<div class="print-container">
  {#each pageArr as aPage, i}
    <div class="book">
      <div class="page">
        <div class="subpage" style="padding-bottom: 1cm;">
          <div class="background">
            <p class="bg-text">{testObj.pdfObj?.watermark}</p>
          </div>

          {#if aPage.showHeader}
            <div style="height : 100px; border:1px solid black; display:flex;">
              <div
                style="border : 1px solid black; margin:-1px; width:130px;display:flex;align-items:center;justify-content:center;"
              >
                <img style="height: 80%;" src={imgURL} alt="" />
              </div>
              <div style="flex:1">
                <div
                  style="border-bottom: 1px solid grey;display: flex;flex-direction: column;height: 70px;margin-right: -1px;position:relative;display:flex;align-items:center;justify-content:center; font:bold; font-size:x-large"
                >
                  {testObj?.name}{aPage.type == "keys"
                    ? " - Keys"
                    : aPage.type == "solutions"
                      ? " - Solutions"
                      : ""}

                  <div
                    style="position:absolute;top:0;right:0;left:0; flex: 1 1 0%;height: 25px;margin-right: -1px;display: flex;justify-content: space-between;padding: 0 10px;margin-top: -2px;align-items: center;"
                  >
                    <div style="font-size: 16px;">
                      Class : {testObj.pdfObj?.class}
                    </div>
                    <div style="font-size: 16px;">
                      Dt : {testObj.pdfObj?.date}, {testObj.pdfObj?.time}
                    </div>
                  </div>
                </div>
                <div
                  style="border-top: 2px solid grey;flex: 1 1 0%;height: 30px;margin-right: -1px;display: flex;padding: 0 10px;margin-top: -2px;align-items: center;font-size:small"
                >
                  <div style="flex:1;display:flex;justify-content:flex-start">
                    Duration : {testObj.pdfObj?.duration}
                  </div>
                  <div style="flex:1;display:flex;justify-content:center">
                    Test ID : {testObj.testId}
                  </div>
                  <div style="flex:1;display:flex;justify-content:flex-end">
                    Marks : {testObj.pdfObj?.marks}
                  </div>
                </div>
              </div>
            </div>
          {/if}

          <div
            style="height: calc(100% - {aPage.showHeader
              ? 150
              : 50}px); display:flex; flex-direction:column; padding-top:10px; padding-bottom:1cm;"
          >
            {#if testObj.pdfObj?.syllabus && i == 0}
              <div style="margin-bottom: 10px;">
                <div style="font-size:15px;font-style: italic;">SYLLABUS :</div>
                <div
                  class="page-top-ele"
                  style="border-bottom : 1px solid black;padding-bottom:4px"
                >
                  {@html testObj.pdfObj?.syllabus}
                </div>
              </div>
            {/if}

            {#if testObj.pdfObj?.instructions && i == 0}
              <div style="margin-bottom: 10px;">
                <div style="font-size:15px;font-style: italic;">
                  INSTRUCTIONS :
                </div>
                <div
                  class="page-top-ele"
                  style="border-bottom : 1px solid black;padding-bottom:4px"
                >
                  {@html testObj.pdfObj?.instructions}
                </div>
              </div>
            {/if}

            <div
              style="column-count: {aPage.type == 'keys'
                ? '5'
                : testObj.pdfObj.paperLayout == 'single-column'
                  ? '1'
                  : '2'};column-rule-color: black;column-rule-style: solid;column-rule-width: 1px;break-inside: auto;"
            >
              {#each aPage.elements as aElement}
                <div style="break-inside: auto;">
                  {@html aElement.html}
                </div>
              {/each}
            </div>
            <div style="flex:1;" bind:this={page[i]} />
          </div>
          <!-- <div style=" height : 38px;display:flex;align-items:flex-end;">
            <div
              style="font-size: 12px;display: flex;justify-content: space-between;align-items: flex-end;width: 100%;border-top: 1px solid;padding-bottom: 10px;padding-top:3px;"
            >
              <div>
                {testObj.pdfObj?.address}
              </div>
              <div>
                Page {pageNumberArr[i].pageNo}/{pageNumberArr[i].totalPages}
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  {/each}
</div>
