<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, onDestroy, tick, afterUpdate, getContext } from "svelte";
  import moment from "moment"
  import { activeMenu } from "../stores";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import MODULES from "../utils/modules.js"
  import { API_URL } from "../../config.js"

  export let testId;
  export let studentId;
  var testDetails = {}
  var userDetails = {}
  var batchDetails = {}
  let scriptData;
  let isLoading = false;
  let questionStats = {}

  let data = {};
  let demoDataTable;
  let testDataTable;
  let emptyState;

  onMount(async function () {
    console.log("check check");
    feather.replace();
    bindDatatable()
  });

  function bindDatatable() {
    testDataTable = _$(demoDataTable).DataTable({
      responsive: false,
      "bPaginate": false, //hide pagination
      "bFilter": false, //hide Search bar
      "bInfo": false, // hide showing entries
      "ordering": false,
      language: {
        processing: "",
        // searchPlaceholder: 'Subject || Topic',
        lengthMenu: '_MENU_ Attempted/page',
      },
      ajax: function (data, callback, settings) {
        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: "test.getStudentTestDetails",
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: data.length,
              search: data.search.value,
              testId: testId,
              userId: studentId
            }
          },
          success: function (resData) {
            var result = []
            if (resData.status) {
              console.log("resData:::::", resData);
              testDetails = resData.data.testData
              userDetails = resData.data.userData[0]
              batchDetails = resData.data.batchData[0]
              questionStats = resData.data.questionsStats
              var paginationData = resData.data.data[0].testStats;
              result = {
                recordsTotal: Number(paginationData.length),
                recordsFiltered: Number(paginationData.length),
                data: paginationData
              }

              callback(result);
            }
            else {
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },
      columns: [

        {
          data: 'questionId', render: function (data, type, full) {
            return full.questionInfo.qId
          }
        },
        {
          data: 'questionId', render: function (data, type, full) {
            return full.questionInfo.data.question.English
          }
        },
        {
          data: 'questionInfo', render: function (data, type, full) {
            return full.questionInfo.data.option_a.English
          }
        },
        {
          data: 'questionInfo', render: function (data, type, full) {
            return full.questionInfo.data.option_b.English
          }
        },
        {
          data: 'questionInfo', render: function (data, type, full) {
            return full.questionInfo.data.option_c.English
          }
        },
        {
          data: 'questionInfo', render: function (data, type, full) {
            return full.questionInfo.data.option_d.English
          }
        },
        {
          data: 'questionInfo', render: function (data, type, full) {
            return full.questionInfo.data.answer
          }
        },
        {
          data: 'questionId', render: function (data, type, full) {
            return full.answer ? full.answer : "-"
          }
        },
        {
          data: 'questionId', render: function (data, type, full) {
            return full.marks ? full.marks : "-"
          }
        },
        {
          data: 'questionId', render: function (data, type, full) {

            return full.timer ? full.timer : "-"
          }
        },
        {
          data: 'questionId', render: function (data, type, full) {
            console.log("full::::::", full);
            return full.peercomparision.totalAttempted.toFixed(2) + " %"

          }
        },
        {
          data: 'questionId', render: function (data, type, full) {

            return full.peercomparision.totalCorrect.toFixed(2) + " %"

          }
        },
        {
          data: 'questionId', render: function (data, type, full) {

            return full.peercomparision.totalWrong.toFixed(2) + " %"

          }
        },
        {
          data: '_id', render: function (data, type, full) {
            return full.sectionName ? full.sectionName : '-'
          }
        },
        {
          data: '_id', render: function (data, type, full) {
            return "-"
          }
        },
        {
          data: '_id', render: function (data, type, full) {
            return full.questionInfo.additionalInfo.level
          }
        },
        {
          data: '_id', render: function (data, type, full) {
            return full.questionInfo.qType
          }
        },
      ],
      "drawCallback": function () {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        feather.replace()
      },
      "processing": true,
      "serverSide": true,
    });
    _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
  }


</script>
<div class="content-body fadeIn">

  <div class="d-sm-flex align-items-center justify-content-between mg-b-15 mg-lg-b-15">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>

        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        <small>Student Detailed Result Report of {testDetails.name}</small>
      </h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Student's Question Wise Test Report</p>
    </div>
  </div>

  <hr class="full-width">
  <!-- bind:this={demoDataTable} -->
  <table class="table table-hover table-bordered">
    <tbody>
      <tr>
        <td>Student Name: <span class="tx-success"> {userDetails.name}</span></td>
        <td>Roll No.: <span class="tx-success"> {userDetails.enrollmentNo? userDetails.enrollmentNo: "-"}</span> </td>
      </tr>
      <tr>
        <td>Class: <span class="tx-success">
            {
            batchDetails && batchDetails.className && batchDetails.className.name ? batchDetails.className.name :
            (userDetails.className? userDetails.className.name? userDetails.className.name: "-" : "-")
            }
          </span></td>
        <td>Batch / Package: <span class="tx-success">
            {
            batchDetails && batchDetails.batchName && batchDetails.batchName.name ? batchDetails.batchName.name :
            (userDetails.batchName? userDetails.batchName.name? userDetails.batchName.name: "-" : "-")
            }
          </span></td>
      </tr>
      <tr>
        <td>Test Name: <span class="tx-success"> {testDetails.name}</span></td>
        <td>Test ID: <span class="tx-success"> {testDetails.testId}</span></td>
      </tr>
      <tr>
        <td>Test Category: <span class="tx-success"> Test Catagory</span></td>
        <td>Test Attempt Date: <span class="tx-success"> {moment(testDetails.startDate).format("DD/MM/YYYY")}</span>
        </td>
      </tr>
    </tbody>
    <tbody></tbody>
  </table>
  <div data-label="Example" class="df-example ">
    <table bind:this={demoDataTable} class="table table-sm"><!--bind:this={demoDataTable}-->
      <thead>
        <!-- <tr></tr> -->
        <tr>
          <th rowspan="2">Q. No.</th>
          <th rowspan="2" class="wd-250">Question</th>
          <th rowspan="2">Option a</th>
          <th rowspan="2">Option b</th>
          <th rowspan="2">Option c</th>
          <th rowspan="2">Option d</th>
          <th rowspan="2">Ans. Key</th>
          <th rowspan="2">Your Respo.</th>
          <th rowspan="2">Score</th>
          <th rowspan="2">Time</th>
          <th colspan="3" class="wd-250"><span>Peer Comparision</span></th>
          <th rowspan="2">Subject</th>
          <th rowspan="2">Chapter/Topic</th>
          <th rowspan="2">Level</th>
          <th rowspan="2">Q. Type</th>
        </tr>
        <tr>
          <th>Attpt %</th>
          <th>Correct %</th>
          <th>Wrong %</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>


</div>
