const { BACKEND_URL } = require("../../config");

// Board Constants and Class Constants combined into a single export
module.exports = {
  // Board Constants
  CBSE: "CBSE",
  MAHARASHTRA_BOARD: "Maharashtra Board",

  // Class Constants
  CLASS_3: "Class 3",
  CLASS_4: "Class 4",
  CLASS_5: "Class 5",
  CLASS_6: "Class 6",
  CLASS_7: "Class 7",
  CLASS_8: "Class 8",
  CLASS_9: "Class 9",
  CLASS_10: "Class 10",

  BACKEND_URL,
};
