<script>
    import { Router, Link, Route } from "svelte-routing";
    import ListRoute from "./list/list.svelte";
    import AddUpdate from "./addupdate/update.svelte";
    import MODULES from "../utils/modules.js" 
    import Forbidden from "../forbidden.svelte"
</script>

<Router>

    <Route path="add" >
        <AddUpdate></AddUpdate>
    </Route>
    <Route path="edit/:id" let:params>
        {#if MODULES.hasAccess(MODULES.VIEW_BATCH_DETAILS)}
        <AddUpdate _id="{params.id}"></AddUpdate>
        {:else}
        <Forbidden />
        {/if}
    </Route>
    <Route path="">
        <ListRoute></ListRoute>
    </Route>
</Router>



