<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick, afterUpdate } from 'svelte';
  import { activeMenu } from "../../../stores";
  import ButtonLoader from "../../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../../utils/apiCallHandler.js";
  let externalLinkList = { data: [] };
  let isLoading = false;
  let search = { value: "" };
  const pageSize = 10;
  let length = pageSize;
  function editBtnHander(id) {
    navigate(`/dashboard/studyMaterial/externalLink/edit/${id}`)
  }
  var errorObj = {}
  var modelTitle = "Add new External Link"

  let validateExternalLink = () => {
    errorObj = {};
    if (!externalLinkObj.name || !externalLinkObj.urlToDrive) {
      var newErrorObj = {};
      if (!externalLinkObj.name) {
        newErrorObj.name = "External Name is required Field.";
      }
      if (!externalLinkObj.urlToDrive) {
        newErrorObj.name = "External url is required Field.";
      }
      errorObj = newErrorObj;

    }
    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;


  }

  function updateSuccess(success) {
    PNotify.success({
      title: success.title ? success.title : "Success",
      text: success.msg,
      delay: 3000
    })
  }

  function updateErrors(error) {
    PNotify.error({
      title: error.title ? error.title : "Error",
      text: error.msg,
      delay: 3000
    })
  }

  async function api_addExternalLink(data) {

    let action = "studyMaterial.addUpdateExternalLink";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    // if (sortOrder.column === 1) {
    //   sort.name = order;
    // } else if (sortOrder.column === 2) {
    //   sort.createdOn = order;
    // } else {
    //   sort.seqId = order;
    // }
    sort.seqId = order;
    return sort;
  }

  async function fetchExternalLink(offset = 0, event) {
    let data = {
      start: 0,
      length: length + offset >= pageSize ? length + offset : pageSize,
      search,
      sort: parseSorts({ 0: "dsc" }),
    };
    console.info(data);
    // fetch call
    let response = await api_listExternalLink(data);
    console.info({ response });
    if (response.status) {
      var paginationData = response.data;
      length = paginationData.data.length;
      externalLinkList = {
        filtered_count: Number(paginationData.filtered_count),
        total_count: Number(paginationData.total_count),
        data: paginationData.data,
      };
    } else {
      console.error("something went wrong");
      length = 0;
      externalLinkList = {
        filtered_count: 0,
        total_count: 0,
        data: [],
      };
    }
    await tick();
  }

  async function api_listExternalLink(data) {

    let action = "studyMaterial.listExternalLink";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function bindDelete(recordedLectureId) {
    // Setup
    //     PNotify.notice({
    //       title: 'Confirmation Needed',
    //       text: 'Are you sure? You want to delete this external link.',
    //       hide: false,
    //       closer: false,
    //       sticker: false,
    //       modules: new Map([
    //         ...PNotify.defaultModules,
    //         [PNotifyConfirm, {confirm: true,async function() {
    //     let result = await api_delete({ _id: recordedLectureId });
    //   if (result.status) {
    //     // reload table
    //     // OR
    //     await api_listExternalLink();
    //   } else if (result.errMsg) {
    //     updateErrors({msg:result.errMsg});
    //   } else if (result.error) {
    //     updateErrors({msg:result.error});
    //   } else {
    //     updateErrors({msg:"Something went wrong"});
    //   }}}]
    //       ])
    //     });
    //   // On confirm
    //   notice.get().on('pnotify.confirm', 

    // On cancel
    //   notice.get().on('pnotify.cancel', function() {
    //       alert('Oh ok. Chicken, I see.');

    //   });

    //   notice.removeAll()
    let isDelete = confirm(
      "Delete Live Lecture ? This will also delete associated data"
    );

    // console.info("Deleting", { isDelete });
    if (isDelete) {
      //   delete api
      let result = await api_delete({ _id: recordedLectureId });
      if (result.status) {
        // reload table
        // OR
        // afterUpdate()
        // fetchExternalLink();

      } else if (result.errMsg) {
        updateErrors({ msg: result.errMsg });
      } else if ({ msg: result.error }) {
        updateErrors({ msg: result.error });
      } else {
        updateErrors("Something went wrong");
      }
    }
  }

  async function api_delete(data) {

    let action = "studyMaterial.deleteExternalLink";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  function hideModel(params) {
    _$('#externalLink-add-modal').modal("hide")

  }

  let addNewExternalLink = async () => {
    if (isLoading) return;
    isLoading = true;
    if (validateExternalLink()) {

      var externalLinkRes = await api_addExternalLink(externalLinkObj);
      if (externalLinkRes.status) {
        // create success
        updateSuccess({ title: "Success", msg: externalLinkRes.data[0] });
        // setTimeout(() => {
        hideModel();
        setTimeout(() => {
          navigate(`/dashboard/studyMaterial/externalLink/edit/${externalLinkRes.data[1]}`)
        }, 1000);
        // }, 1000);
      } else {
        // Failed to create 
        updateErrors({ title: "Error", msg: externalLinkObj.errMsg })
        isLoading = false;
        return false;
      }
    }
    isLoading = false;
  }

  var externalLinkObj = {
    name: "",
    urlToDrive: ""
  }
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  }

  onMount(async () => {
    initDashboard();

    // initFileManager();            
    activeMenu.set('studyMaterial')
    await fetchExternalLink();
  });
  //   afterUpdate(async function () {
  //   feather.replace({ width: "1em", height: "1em" });
  //   _$('[data-toggle="tooltip"]').tooltip();
  //   await  fetchExternalLink();
  // });

</script>



<div class="content-body fadeIn">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item"><a href="/dashboard/studyMaterial">Study Material</a></li>
            <li class="breadcrumb-item active" aria-current="page">External Link</li>
          </ol>
        </nav>
        <h4 class="mg-b-0">External Link List</h4>
      </div>
      <a href="#externalLink-add-modal" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" data-toggle="modal">
        <i data-feather="plus" class="wd-10 mg-r-5"></i> Add External Link
      </a>

      <!-- <div class="search-form mg-t-20 mg-sm-t-0">
              <input type="search" class="form-control" placeholder="Search people">
              <button class="btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
            </div> -->
    </div>
  </div><!-- container -->
  <hr class="full-width">


  <div>
    {#if externalLinkList && externalLinkList.data && externalLinkList.data.length}
    {#each externalLinkList.data as aExternalLink (aExternalLink._id)}
    <div class="card mg-10">
      <div class="card-body d-flex justify-content-left">
        <i class="d-flex justify-content-left fas fa-external-link-alt fa-2x tx-success"></i>
        <div class="col" on:click={()=> editBtnHander(aExternalLink._id)}>
          {aExternalLink.name}
        </div>
        <div class="col-sm-1 col-md-1 col-lg-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="pointer"> <i data-feather="more-vertical" /></span>
          <nav class="dropdown-menu" use:links>
            <a href="/dashboard/studyMaterial/externalLink/edit/{aExternalLink._id}" class="dropdown-item"><i
                data-feather="edit-2" />
              {@html '&nbsp;'}Edit</a>
            <button class="dropdown-item" on:click={()=> bindDelete(aExternalLink._id)}
              data-id={aExternalLink._id}><i data-feather="trash" />
              {@html '&nbsp;'}Remove
            </button>
          </nav>
        </div>
      </div>
    </div>

    {:else}
    <span>No External Link's Found. Please Add a external link.</span>
    {/each}
    {/if}
  </div>
  <p>
    Showing {length}/ {externalLinkList.filtered_count} from total {externalLinkList.total_count}
    external links {#if externalLinkList.filtered_count > length}
    <button class="btn btn-secondary d-inline-block mg-10" title="load more" on:click={async ()=> {
      console.log(`loading ${pageSize} more`);
      await fetchExternalLink(pageSize);
      }}>
      Load more <i data-feather="more-horizontal" />
    </button>
    {/if}
  </p>

</div>


<div class="modal fade" id="externalLink-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="external-link" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">{modelTitle}</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Add external links.</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->
      <hr class="full-width">
      <div class="modal-body  pd-l-25 pd-r-25 pd-b-25 pd-t-0">

        <div class="form-row">
          <div class="form-group col-md-12">
            <label class="d-block">External Link Name<span class="required-field">*</span></label>
            <input bind:value={externalLinkObj.name} type="text" on:blur={()=>clearError("name")} class="form-control"
            class:parsley-error={errorObj.name} placeholder="External Link Name"/>
            {#if errorObj.name}
            <div class="parsley-errors-list">{errorObj.name}</div>
            <!-- content here -->
            {/if}
          </div>
          <div class="form-group col-md-12">
            <label class="d-block">URL To Drive<span class="required-field">*</span></label>
            <input bind:value={externalLinkObj.urlToDrive} type="text" on:blur={()=>clearError("urlToDrive")}
            class="form-control" class:parsley-error={errorObj.urlToDrive} placeholder="URL To Drive"/>
            {#if errorObj.urlToDrive}
            <div class="parsley-errors-list">{errorObj.urlToDrive}</div>
            <!-- content here -->
            {/if}
          </div>
        </div>
      </div>
      <div class="modal-footer  pd-l-25 pd-r-25 pd-b-25 pd-t-0">
        <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CLOSE
          <i data-feather="x" class="wd-10 mg-l-5"></i>
        </button>
        <button type="button" on:click={addNewExternalLink} class="btn btn-primary tx-12">
          <ButtonLoader isLoading={isLoading} btnText="SAVE" />
          <i data-feather="save" class="wd-10 mg-l-5"></i>
        </button>
      </div>
    </div>
  </div>
</div>
