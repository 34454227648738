<script>
  import { Router, Link, links, Route } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { pSuccess, pError, pConfirm } from "../utils/notify.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  let title = "",
    content = "";
  let search;
  export let selectedId = "";
  export let sptObject = {},
    errorObj = {};
  let inputFieldToFocus;
  let sptList = [];
  let isLoading;

  let mode = "create";

  let setSelected = function (aSptItem) {
    selectedId = aSptItem._id;
    sptObject = aSptItem;
    title = aSptItem.title;
    content = aSptItem.content;
    mode = "edit";
  };

  async function api_list(data) {

    let action = "smsTemplate.fetchSmsTemplatesList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_addUpdate(data) {

    let action = "smsTemplate.saveSmsTemplate";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_delete(data) {

    let action = "smsTemplate.removeSmsTemplate";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  let onSave = async function () {
    if (isLoading) return;
    isLoading = true;
    if (!title.trim()) {
      errorObj.title = "Title is required field";
    }
    if (!content.trim()) {
      errorObj.content = "Template is required field";
    }
    if (!(errorObj.title || errorObj.content)) {
      var data = {};
      if (selectedId) data._id = selectedId;
      data.title = title;
      data.content = content;
      let res = await api_addUpdate(data);
      if (res.status) {
        pSuccess("", "Template saved successfully");
      } else {
        pError("", res.errMsg || res.error || "Something went wrong");
      }
      reloadList();
      if (res.data?.insertedId) {
        selectedId = res.data.insertedId;
        mode = "edit";

        sptObject = {
          _id: selectedId,
          title: title,
          content: content,
        };
      }
    }
    isLoading = false;
  };

  let toolbar = [
    [
      "NewPage",
      "Preview",
      "-",
      "Bold",
      "Italic",
      "Strike",
      "NumberedList",
      "BulletedList",
      "-",
      "Table",
      "HorizontalRule",
      "Styles",
      "Format",
      "Maximize",
      "Image",
    ],
  ];

  let reloadList = async function () {
    let res = await api_list({ search });
    console.log("res : ", res);
    sptList = res.data.result;
  };
  onMount(async function () {
    reloadList();

    feather.replace();

    new PerfectScrollbar(".slist", {
      suppressScrollX: true,
    });
    new PerfectScrollbar(".e-spt", {
      suppressScrollX: true,
    });

    await tick();

    _$(inputFieldToFocus).focus();
  });
</script>

<style>
  .spt-header {
    height: 55px;
    background-color: #fff;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .spt-header .search-form .form-control {
    border-width: 0;
    background-color: transparent;
    font-size: inherit;
    padding: 0;
    color: #1b2e4b;
  }

  .title {
    border: none;
    outline: none;
    box-shadow: none;
  }

  .title:focus {
    box-shadow: none !important;
  }

  .title:hover {
    box-shadow: 0 0 0 0.2rem rgba(1, 104, 250, 0.25) !important;
  }

  .spt-header .search-form {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .spt-list {
    cursor: pointer;
    padding: 15px 20px;
    cursor: pointer;
    border-left: 4px solid #f8f9fc;
    z-index: 1000;
    border-bottom: 1px solid #e5e9f2;
  }

  .spt-list:hover {
    border-left-color: #0153c7;
  }

  .spt-list.active {
    border-left-color: #0168fa;
    background-color: #ecf4ff;
  }

  .conten-header {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 55px;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
</style>

<div class="row no-gutters" style="height: 100%; ">
  <div class="col-sm-12 col-md-4" style=" border-right: 1px solid #ddd;display: flex;    flex-direction: column; background-color: #f8f9fc; 
    height: 100%;">
    <div class="spt-header">
      <i data-feather="search" />
      <div class="search-form">
        <input type="search" class="form-control" placeholder="Template" bind:value={search}
          on:input={reloadList} />
      </div>
      <!-- search-form -->
      <div class="mg-10">
        <button style="background: transparent; border: none;outline: none !important;" on:click={()=> {
          title = '';
          content = '';
          selectedId = '';
          mode = 'create';
          _$(inputFieldToFocus).focus();
          }}
          class="nav-link"><i data-feather="plus" style="height: 15px;" />
          New
        </button>
      </div>
    </div>

    <div style="flex: 1;" class="slist">
      <ul class="list-unstyled media-list mg-b-0">
        {#each sptList as item}
        <li class="media unread spt-list" class:active={selectedId==item._id} on:click={()=> setSelected(item)}>
          <div class="media-body mg-l-15">
            <h6 class="tx-13">{item.title}</h6>
            <p class="tx-12 tx-color-03 mg-b-0">{item.content}</p>
          </div>
          <!-- media-body -->
        </li>
        {/each}
      </ul>
    </div>
  </div>
  <div class="col-sm-12 col-md-8 e-spt" style="padding-top:56px;
    height: 100%;">
    <div class="conten-header" style="display: flex;justify-content: space-between;    z-index: 1; ">
      <input type="text" bind:this={inputFieldToFocus} class="form-control" class:bd={errorObj.title}
        class:parsley-error={errorObj.title} bind:value={title} placeholder={errorObj.title || 'Enter template title' }
        class:title={!errorObj.title} on:blur={async ()=> {
      delete errorObj.title;
      errorObj = errorObj;
      await tick()
      }} />

      <button style="background: transparent; border: none;outline: none !important;" class="nav-link"
        on:click={onSave}><i data-feather="save" style="height: 15px;" />
        {mode == 'create' ? 'Create' : 'Update'}</button>
      <button style="background: transparent; border: none;outline: none !important;" class:nav-link={selectedId}
        disabled={!selectedId} on:click|stopPropagation={async ()=> {
        console.log('deleting', selectedId);
        if (!selectedId) return;
        pConfirm(
        'Confirm delete?',
        'Do you really want to delete this template?',
        async () => {
        let data = { _id: selectedId };
        let result = await api_delete(data);
        if (result.status) {
        title = '';
        selectedId = '';
        content = '';
        mode = 'create';
        reloadList();
        pSuccess('', result.data);
        } else if (result.errMsg) {
        pError('', result.errMsg);
        } else {
        pError('', 'something went wrong');
        }
        }
        );
        }}><i data-feather="trash" style="height: 15px;" />
        Delete</button>
      <!-- {#if errorObj.title}
                <div class="parsley-errors-list mg-b-10">{errorObj.title}</div>
            {/if} -->
    </div>
    <textarea class:parsley-error={errorObj.content} class="form-control" name="essaySPT" rows="3" style="resize: none"
      placeholder={errorObj.content?errorObj.content:"Type sms template here"} bind:value={content} on:blur={async ()=> {
        delete errorObj.content;
        errorObj = errorObj;
        await tick()
      }} />
    <div class="">
      <span class="badge badge-pill badge-secondary">
        {content.length}
        characters
      </span>
      <span class="badge badge-pill badge-secondary">
        {#if content}{parseInt(content.length / 160 + 1)} page{:else}0 page{/if}
      </span>
    </div>
    <!-- div class:bd={errorObj.content} class:bd-danger={errorObj.content} class:parsley-error={errorObj.content}>
        </div -->
  </div>
</div>
