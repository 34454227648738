<script>
  import { Router, Link, links, link, Route, navigate } from "svelte-routing";

  import { onMount ,tick} from "svelte";
  import CatSelect from "./categorySelect.svelte";
  import { activeMenu, _selectedQuestionsIds ,updateSelectedQuestion} from "../stores";
  import ButtonLoader from "../utils/button_loader.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import { pSuccess, pError, pConfirm } from "../utils/notify.svelte";
  import { createEventDispatcher } from 'svelte';

  import MODULES from "../utils/modules.js";
  export let questionId;
  export let questionTags;
  export let questionType
  let questionsDataTable;
  let demoDataTable;
  let isLoading = false
  let questionArr =[]
  let dataLength = []
   let start = 0  , length = 10
   let qIdSearch =''
   let qTagsSearch = ''
   let qTypeSearch =''
  const dispatch = createEventDispatcher();
  // $:questionId ,  qIdSearch = questionId
  // $:questionTags,  qTagsSearch = questionTags
  // $:questionType, qTypeSearch = questionType
  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.qId = order;
    } else if (sortOrder.column === 2) {
      sort._id = order;
    } else if (sortOrder.column === 3) {
      sort._id = order;
    } else if (sortOrder.column === 4) {
      sort._id = order;
    } else {
      sort._id = order;
    }
    return sort;
  }

  let debounce = (f, w) => {
    let t;
    return function fn(...args) {
      const later = () => {
        t = null;
        f(...args);
      };
      clearTimeout(t);
      t = setTimeout(later, w);
    };
  };

  function getFilterData() {

    return {
      questionId: questionId,
      questionTags,
    };
  }

  onMount(async () => {
    settingUpTable()
   
    // bindDatatable([]);
  });

  $: questionId, questionTags, questionType, callDebounce();

  let callDebounce = debounce(async function () {
    qIdSearch = questionId
    qTypeSearch = questionType
    qTagsSearch = questionTags
    await getQuestionListByQuestionId(questionId, questionTags,questionType);
  }, 500);

  let getQuestionListByQuestionId = async function (qId, tag ,questionType)  {
    if (qId || tag || questionType) {
      isLoading = true
       questionArr = await api_getQuestionListByQuestionId({
        questionId: qId,
        tags: tag,
        questionType:questionType,
        start
      });
      if(questionArr.length) {
      
            questionArr = questionArr.sort(function (a, b) {
          var keyA =  a.qId_synced || a.qId,
          keyB =  b.qId_synced || b.qId;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        settingUpTable(questionArr);
      }
      else settingUpTable([])
      
    } else {
      settingUpTable([]);
    }
  };

  async function api_getQuestionListByQuestionId(data) {

    let action = "questions.getQuestionListByQuestionId";
    var response = await api_handleApiCalls(action, data);
    if (response.status) {
      isLoading = false
      return response.data.data;
    } else {
      isLoading = false
      return [];
    }
  }
  /* Question Selection START */
  const qSelectCheckboxClickHandler = (all) => {
    // if($_selectedQuestionsIds >= )
            _$(".select-question")
            .toArray()
            .map((element) => {
                let elem_checkbox = _$(element);
                let questionId = elem_checkbox.attr("questionId");
                if (all) elem_checkbox.prop("checked", true);
                else elem_checkbox.prop("checked", false);

            });
        }
        const checkIfAllSelected = (questionArr) => {
            let totalQUestionCount = _$(".select-question").length
            let countOfCheckedCheckbox = _$(".select-question:checked").length

            if (totalQUestionCount == countOfCheckedCheckbox) _$('#select_all_question').prop("checked", true)
            else _$('#select_all_question').prop("checked", false);
          }
          const selectAllUnselect = async (checked,) => {
          /* To select only rendered  questions*/
          let selectedArr = _$(".select-question").toArray().map(element =>{
          let elem_checkbox = _$(element);
          let questionId = elem_checkbox.attr("questionId");
          return questionId
        })

            let _setTemp = $_selectedQuestionsIds
            if (checked) {
                selectedArr.forEach(obj => _setTemp.add(obj))
            } else {
                selectedArr.forEach(obj => _setTemp.delete(obj))
            }
            updateSelectedQuestion(_setTemp)
            await tick()
            qSelectCheckboxClickHandler(checked)
        }
    /* Question Selection END */
  function bindDatatable(data) {
    let questionList = data
    if (questionsDataTable) questionsDataTable.destroy();
    questionsDataTable = _$("#questionDatatable").DataTable({
      language: {
        "processing": "Loading...",
        searchPlaceholder: 'Search question',
        sSearch: '',
        lengthMenu: '_MENU_ Questions/page',
      },
      data: data,
      columns: [
      {
        data: "_id",
        render: (data, type, full) => {
            if ($_selectedQuestionsIds && $_selectedQuestionsIds.has(data)) {
            return `
                        <input type="checkbox" checked class="select-question itsSelected" questionId="${data}" q>
                `;
            } else {
            return `
                        <input type="checkbox" class="select-question" questionId="${data}">
                `;
            }

        },
    },
        {
          data: "_id",
          render: function (data, type, full) {
            var htmlStr;
            if (full.qId_synced) {
              htmlStr = "PR " +  "ID: #" + full.qId_synced.toString() + " (#"+ full.qId.toString() + ")";
              return htmlStr;
            } else {
              return "#" + full.qId;
              
            }
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            try {
              var lang = full.languages.find(
                (lng) => lng.default == true
              ).title;
              let question = full.data.question[lang];
              return question;
            } catch (error) {
              return "-";
            }
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            let qTypeFullName;
            switch (full.qType) {
              case "multipleResponse":
                qTypeFullName = "Multiple Response";
                break;
              case "multipleChoice":
                qTypeFullName = "Multiple Choice";
                break;
              case "numerical":
                qTypeFullName = "Numerical";
                break;
              case "decimalType":
                qTypeFullName = "Decimal";
                break;
              case "fillInTheBlanks":
                qTypeFullName = "Fill in the Blanks";
                break;
              case "trueFalse":
                qTypeFullName = "True Or False";
                break;
              default:
                qTypeFullName = "-";
            }

            return qTypeFullName;
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            return full.additionalInfo.pmarks
              ? full.additionalInfo.pmarks
              : "-";
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            return full.testOccuranceCount ? full.testOccuranceCount : 0;
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            var view = "-";
            var archive = "";
            view = `<a href="#" class="rename mg-r-5 btn-edit-question" question-id="${data}" title="Edit" qId ="${full.qId}">
                                <i class="wd-20 mg-r-5" data-feather="edit"></i>
                            </a>`;
            archive = `<a href="#" class="rename mg-r-5 btn-delete-question" data-id="${data}" title="Delete">
                                <i class="wd-20 mg-r-5" data-feather="trash"></i>
                            </a>`

            return `
                            <div class="d-md-flex flex-row justify-content-center">
                                ${view}
                                ${archive}
                            </div>
                        `;
          },
        },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });

        let selectedArray =  _$(".select-question").toArray().map(element =>{
          let elem_checkbox = _$(element);
          let questionId = elem_checkbox.attr("questionId");
          if($_selectedQuestionsIds.has(questionId))  elem_checkbox.prop("checked", true);
          else elem_checkbox.prop("checked", false);
        })
       
        
         checkIfAllSelected(questionArr)
        _$('#select_all_question').unbind("click").click(function (e) {
          e.stopPropagation();
          selectAllUnselect(e.target.checked)
        });
        _$(".btn-edit-question")
          .unbind("click")
          .click(function (e) {
            e.preventDefault();
            var questionId = _$(this).attr("question-id");
            // var qId =  _$(this).attr("qId");
            const paramsString = '';
            let searchParams = new URLSearchParams(paramsString);
            let callbackUrl = `/dashboard/questions/questionBank?`
            if(qIdSearch && qTagsSearch && qTypeSearch){
              searchParams.set('qIdSearch',qIdSearch)
              searchParams.set('qTagsSearch',qTagsSearch)
              searchParams.set('qTypeSearch',qTypeSearch)
            }
            if(qIdSearch && qTagsSearch && !qTypeSearch) {
              searchParams.set('qIdSearch',qIdSearch)
              searchParams.set('qTagsSearch',qTagsSearch)
            }
            if(qIdSearch && qTypeSearch && !qTagsSearch){
              searchParams.set('qIdSearch',qIdSearch)
              searchParams.set('qTypeSearch',qTypeSearch)
            }
            if(qTagsSearch && qTypeSearch && !qIdSearch){
              searchParams.set('qTagsSearch',qTagsSearch)
              searchParams.set('qTypeSearch',qTypeSearch)
            }
            if(qIdSearch && !qTagsSearch && !qTypeSearch){
              searchParams.set('qIdSearch',qIdSearch)

            }
            if(qTypeSearch && !qTagsSearch && !qIdSearch){
              searchParams.set('qTypeSearch',qTypeSearch)
            }
            if(!qTypeSearch && qTagsSearch && !qIdSearch){
              searchParams.set('qTagsSearch',qTagsSearch)
            }
            callbackUrl += searchParams.toString()
            callbackUrl = callbackUrl.replaceAll("&", "-")

           navigate(`/dashboard/questions/view/${questionId}?callbackUrl=${callbackUrl}`)
            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
            return false;
          });
        _$('.btn-delete-question').unbind('click').click(function (e) {
          let questionId = _$(this).attr('data-id')
          proceedRemoveQuestion(questionId)
        })
        _$(".select-question").unbind("click").click( async function (e) {
                    var qId = _$(this).attr("questionId");
                    var checked = e.target.checked
                    let _tempQS = $_selectedQuestionsIds
                    if (checked) {
                        _tempQS.add(qId)
                    } else {
                        _tempQS.delete(qId)
                    }
                    updateSelectedQuestion(_tempQS)
                    _$('#select_all_question').prop("checked", false);
                    // $_selectedQuestionsIds = $_selectedQuestionsIds
                    await tick()
                });
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
      },
    });
    
  }
  function redrawQuestionsTable() {
    if (questionsDataTable) { questionsDataTable.draw() }
  }
  async function api_removeQuestion(questionId) {
    var resData = await api_handleApiCalls('questions.removeQuestion', { id: questionId })
    return resData
  }
  const proceedRemoveQuestion = (id) => {

    pConfirm("Warning!", "Are you sure you want to delete this question, this action will delete question permanently", async function () {
      let resData = await api_removeQuestion(id)
      if (resData.status) {
        pSuccess("Success", resData.data)
        getQuestionListByQuestionId(questionId, questionTags)
        dispatch('refresh')
      } else {
        pError("Error", resData.errMsg)
      }
      redrawQuestionsTable()
    })

  }
 
  const settingUpTable = (data) => { 
       
        _$('#table-container').html(`
        <div data-label="Example" class="df-example demo-table" style="padding-bottom: 40px;">
            <table id="questionDatatable" class="table studentsListTable bg-white animated fadeIn">
                <thead>
                    <tr>
                      <th style="width: 10%;">
                        <input type="checkbox" id="select_all_question" name="selectAll" /> Select All
                      </th>
                      <th class="wd-5">ID</th>
                      <th class="wd-40p-f">Question</th>
                      <th class="wd-15">Type</th>
                      <th class="wd-15">Marks</th>
                      <th class="wd-10">Occurance Count</th>
                      <th class="wd-10">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    
                </tbody>
            </table>
          </div>
        `)

        bindDatatable(data)
     }

</script>

<!-- <hr class="mg-y-20"> -->
<div data-label="Example" class="df-example demo-table">
  
    {#if isLoading}
        <div class="d-flex justify-content-center " role="status">Loading...</div>
    {:else}
    <div id="table-container">
          
    </div>
    {/if}
</div>
