<script>
  // your script goes here
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../../stores";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import { pSuccess, pError, pConfirm } from "../../utils/notify.svelte";

  export let reload;
  const settings = {};
  let isLoading;
  async function fetchSocialSettings() {
    if (isLoading) return;
    isLoading = true;
    await tick();
    let result = await api_handleApiCalls("settings.fetchSocialSettings", {});
    console.log("fetchSocialSettings", result);
    if (result.status) {
      settings.FACEBOOK_PAGE = result.data.FACEBOOK_PAGE || "";
      settings.INSTAGRAM_PAGE = result.data.INSTAGRAM_PAGE || "";
      settings.WHATSAPP_NUMBER = result.data.WHATSAPP_NUMBER || ""; 
      settings.GMAIL_PAGE = result.data.GMAIL_PAGE || "";
      settings.YOUTUBE_CHANNEL = result.data.YOUTUBE_CHANNEL || "";
    } else {
      pError("", result.errMsg);
    }
    isLoading = false;
    await tick();
    feather.replace({ width: "1em", height: "1em" });
  }
  async function setFacebookPageURL(value) {
    let result = await api_handleApiCalls("settings.setFacebookPageURL", { value });
    console.log("setFacebookPageURL", result);
    if (result.status) {
      pSuccess("", result.data);
      fetchSocialSettings();
      if (reload) reload();
    } else {
      pError("", result.errMsg);
    }
  }
  async function setInstagramPageURL(value) {
    let result = await api_handleApiCalls("settings.setInstagramPageURL", { value });
    console.log("setInstagramPageURL", result);
    if (result.status) {
      pSuccess("", result.data);
      fetchSocialSettings();
      if (reload) reload();
    } else {
      pError("", result.errMsg);
    }
  }
  async function setWhatsAppNumber(value) {
    let result = await api_handleApiCalls("settings.setWhatsAppNumber", { value });
    console.log("setWhatsAppNumber", result);
    if (result.status) {
      pSuccess("", result.data);
      fetchSocialSettings();
      if (reload) reload();
    } else {
      pError("", result.errMsg);
    }
  }

  async function setGmailPageURL(value) {
    let result = await api_handleApiCalls("settings.setGmailPageURL", { value });
    console.log("setGmailPageURL", result);
    if (result.status) {
      pSuccess("", result.data);
      fetchSocialSettings();
      if (reload) reload();
    } else {
      pError("", result.errMsg);
    }
  }
  async function setYoutubeChannel(value) {
    let result = await api_handleApiCalls("settings.setYoutubeChannel", { value });
    console.log("setYoutubeChannel", result);
    if (result.status) {
      pSuccess("", result.data);
      fetchSocialSettings();
      if (reload) reload();
    } else {
      pError("", result.errMsg);
    }
  }
  onMount(function () {
    console.log("social");
    activeMenu.set("settingsSocial");
    fetchSocialSettings();
  });
</script>

<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xs-12">
    <ul class="list-group">
      {#if isLoading}
      <li class="list-group-item list-group-item-action">
        <div class="mg-20 pd-10">
          <div class="placeholder-paragraph">
            <div class="line mg-b-10" />
            <div class="line mg-b-10" />
          </div>
        </div>
      </li>  
      {:else}
        <li class="list-group-item list-group-item-action">
          <div class="d-flex justify-content-between">
            <div>Facebook</div>
            <nav>
              <a
                class="d-inline nav-link"
                href={settings.FACEBOOK_PAGE || '#'}
                target="_blank"><i data-feather="external-link" />
                Facebook Page</a>
              <a
                data-toggle="collapse"
                href="#facebook"
                role="button"
                aria-expanded="false"
                aria-controls="facebook"
                class="d-inline mg-l-5 nav-link">
                <i data-feather="edit" /></a>
            </nav>
          </div>
          <div class="collapse mg-t-10" id="facebook">
            <div class="input-group">
              <input
                type="text"
                bind:value={settings.FACEBOOK_PAGE}
                on:keyup={(event) => event.key == 'Enter' && setFacebookPageURL(event.target.value)}
                class="form-control"
                placeholder="Facebook Page URL. Example, https://facebook.com/"
                aria-label="Facebook Page"
                aria-describedby="button-addon2" />
              <div class="input-group-append">
                <button
                  on:click={(event) => setFacebookPageURL(settings.FACEBOOK_PAGE)}
                  class="btn btn-outline-light"
                  type="button"
                  id="button-addon2">Save</button>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item list-group-item-action">
          <div class="d-flex justify-content-between">
            <div>Instagram</div>
            <nav>
              <a
                class="d-inline nav-link"
                href={settings.INSTAGRAM_PAGE || '#'}
                target="_blank"><i data-feather="external-link" />
                Instagram Page</a>
              <a
                data-toggle="collapse"
                href="#instagram"
                role="button"
                aria-expanded="false"
                aria-controls="instagram"
                class="d-inline mg-l-5 nav-link">
                <i data-feather="edit" /></a>
            </nav>
          </div>
          <div class="collapse mg-t-10" id="instagram">
            <div class="input-group">
              <input
                type="text"
                bind:value={settings.INSTAGRAM_PAGE}
                on:keyup={(event) => event.key == 'Enter' && setInstagramPageURL(event.target.value)}
                class="form-control"
                placeholder="Instagram Page URL. Example, https://www.instagram.com/"
                aria-label="Instagram Page"
                aria-describedby="button-addon2" />
              <div class="input-group-append">
                <button
                  on:click={(event) => setInstagramPageURL(settings.INSTAGRAM_PAGE)}
                  class="btn btn-outline-light"
                  type="button"
                  id="button-addon2">Save</button>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item list-group-item-action">
          <div class="d-flex justify-content-between">
            <div>Whatsapp</div>
            <nav>
              <a
                class="d-inline nav-link"
                href={settings.WHATSAPP_NUMBER ? 'https://wa.me/91' + settings.WHATSAPP_NUMBER : '#'}
                target="_blank"><i data-feather="external-link" />
                Whatsapp Number</a>
              <a
                data-toggle="collapse"
                href="#whatsapp"
                role="button"
                aria-expanded="false"
                aria-controls="whatsapp"
                class="d-inline mg-l-5 nav-link">
                <i data-feather="edit" /></a>
            </nav>
          </div>
          <div class="collapse mg-t-10" id="whatsapp">
            <div class="input-group">
              <input
                type="number"
                maxlength="10"
                bind:value={settings.WHATSAPP_NUMBER}
                on:keyup={(event) => {
                  if (event.key == 'Enter') setWhatsAppNumber(event.target.value);
                  else {
                    this.value = this.value.slice(0, this.maxLength);
                    settings.WHATSAPP_NUMBER = this.value;
                  }
                }}
                class="form-control"
                placeholder="WhatsApp Number"
                aria-label="WhatsApp Number"
                aria-describedby="button-addon2" />
              <div class="input-group-append">
                <button
                  on:click={(event) => setWhatsAppNumber(settings.WHATSAPP_NUMBER)}
                  class="btn btn-outline-light"
                  type="button"
                  id="button-addon2">Save</button>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item list-group-item-action">
          <div class="d-flex justify-content-between">
            <div>Gmail</div>
            <nav>
              <a
                class="d-inline nav-link"
                href={settings.GMAIL_PAGE || '#'}
                target="_blank"><i data-feather="external-link" />
                Gmail</a>
              <a
                data-toggle="collapse"
                href="#gmail"
                role="button"
                aria-expanded="false"
                aria-controls="gmail"
                class="d-inline mg-l-5 nav-link">
                <i data-feather="edit" /></a>
            </nav>
          </div>
          <div class="collapse mg-t-10" id="gmail">
            <div class="input-group">
              <input
                type="text"
                bind:value={settings.GMAIL_PAGE}
                on:keyup={(event) => event.key == 'Enter' && setGmailPageURL(event.target.value)}
                class="form-control"
                placeholder="Gmail Page URL. Example, https://gmail.com/"
                aria-label="gmail Page"
                aria-describedby="button-addon2" />
              <div class="input-group-append">
                <button
                  on:click={(event) => setGmailPageURL(settings.GMAIL_PAGE)}
                  class="btn btn-outline-light"
                  type="button"
                  id="button-addon2">Save</button>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item list-group-item-action">
          <div class="d-flex justify-content-between">
            <div>YouTube</div>
            <nav>
              <a
                class="d-inline nav-link"
                href={settings.YOUTUBE_CHANNEL || '#'}
                target="_blank"><i data-feather="external-link" />
                YouTube Channel</a>
              <a
                data-toggle="collapse"
                href="#youtube"
                role="button"
                aria-expanded="false"
                aria-controls="youtube"
                class="d-inline mg-l-5 nav-link">
                <i data-feather="edit" /></a>
            </nav>
          </div>
          <div class="collapse mg-t-10" id="youtube">
            <div class="input-group">
              <input
                type="text"
                bind:value={settings.YOUTUBE_CHANNEL}
                on:keyup={(event) => event.key == 'Enter' && setYoutubeChannel(event.target.value)}
                class="form-control"
                placeholder="YouTube Channel URL. Example, https://www.youtube.com/"
                aria-label="YouTube Channel"
                aria-describedby="button-addon2" />
              <div class="input-group-append">
                <button
                  on:click={(event) => setYoutubeChannel(settings.YOUTUBE_CHANNEL)}
                  class="btn btn-outline-light"
                  type="button"
                  id="button-addon2">Save</button>
              </div>
            </div>
          </div>
        </li>
      {/if}
    </ul>
  </div>
</div>
