<script>
    import { Router, Link, Route } from "svelte-routing";
    import { onMount } from "svelte";
    import List from "./list/list.svelte";
    import View from "./view/index.svelte"
    import MODULES from "../utils/modules.js"
    import Forbidden from "../forbidden.svelte"  
    function init() {
      console.log("redirecting ");
    }
    onMount(init);
  </script>
  
  <Router>
    <Route path=":id" let:params>
      {#if params.id== "new"}
      {#if MODULES.hasAccess(MODULES.ADD_DISCUSSION)}
      <View id={null} />
      {:else}
      <Forbidden />
      {/if}
      {:else}
      {#if MODULES.hasAccess(MODULES.VIEW_DISCUSSION_DETAILS)}
      <View id={params.id} />
      {:else}
      <Forbidden />
      {/if}
      {/if}
  </Route>
    <Route path="">
      <List />
    </Route>
  </Router>
