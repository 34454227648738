<script>
  import { Router, Link, links, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu, fileExplorerFilter } from "../stores";
  import ButtonLoader from "../utils/button_loader.svelte";
  import Library from "./library/library.svelte";
  import PDF from "./pdf/index.svelte";
  import Image from "./images/index.svelte";
  import ExternalLink from "./externalLink/index.svelte";
  import RefLink from "./refLink/index.svelte";
  import LiveLecture from "./liveLecture/index.svelte";
  import RecordedLecture from "./recordedLecture/index.svelte";
  import Folder from "./folder/index.svelte";
  import YoutubeLecture from "./youtubeLectures/index.svelte";
  import UnlinkedFiles from "./unlinkedFiles/index.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import FolderExplorer from "../folderCmp/folderExplorer.svelte";
  import AddNewFile from "./AddFile/index.svelte";
  import MODULES from "../utils/modules.js";
  import Forbidden from "../forbidden.svelte";


  let batchList = [],
    facultyList = [];

  let filterModalThis;
  let filterElemBatch,
    filterElemStudyMaterialType,
    filterElemFaculty,
    filterElemStartTime,
    filterElemEndTime;
  let selectizeBatchInstance,
    selectizeStudyMaterialTypeInstance,
    selectizeFacultyInstance,
    timePickerStartTimeInstance,
    timePickerEndTimeInstance;
  let filterByPrefix;
  let filterStartDate;
  const initialFilterObj = {
    batchIds: "",
    studyMaterialType: "",
    facultyId: "",
    startDate: "",
    startTime: "",
    endTime: null,
    clearFilter: true
  }
  let filterObj = initialFilterObj;
  $: (!filterObj.endTime || filterObj.endTime < filterObj.startTime) &&
    updateDateTime();


  let isLoading = false;
  let storage;
  function updateDateTime() {
    filterObj.endTime = new Date(filterObj.startTime);
    filterObj.endTime.setHours(new Date(filterObj.startTime).getHours() + 1);
    let minResultTime = new Date(filterObj.endTime);
    if (minResultTime.getMinutes() % 15) {
      // get ceiling of 15 minutes ran
      let minutes = minResultTime.getMinutes();
      let divisions = minutes / 15;
      if (divisions % 1) divisions = parseInt(divisions + 1);
      minutes = divisions * 15;
      minResultTime.setMinutes(minutes);
    }
    minResultTime = moment(new Date(minResultTime)).format("hh:mm A") || "0";
    let endTimePickerConfig = getTimepickerConfig(
      minResultTime,
      timeChanged,
      "endTime"
    );
    timePickerEndTimeInstance =
      _$(filterElemEndTime).timepicker(endTimePickerConfig);

    // console.log("filterObj => ", JSON.stringify(filterObj, null, 4));
  }
  function convertDateStrToDateObj(date) {
    if (!date) return;
    date = date.split("-");

    var month = parseInt(date[1] - 1);
    var day = parseInt(date[2]);
    var year = parseInt(date[0]);

    date = new Date();
    if (
      date.getFullYear() == year &&
      date.getMonth() == month &&
      date.getDate() == day
    ) {
      date.setFullYear(year);
      date.setMonth(month);
      date.setDate(day);
    } else {
      date.setFullYear(year);
      date.setMonth(month);
      date.setDate(day);
      date.setHours(0, 0, 0);
    }
    return date;
  }
  function convertDateObjToDateStr(date) {
    if (!date) return null;
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [year, month, day].join("-");
    return dateString;
  }

  function hideFilterModel(params) {
    _$("#filter-modal").modal("hide");
    assignmentObj.topic = "";
  }
  function showFilterModel(params) {
    _$("#filter-modal").modal("show");
  }

  let showHideFilterModal = () => {
    _$("#filter-modal").modal("show");
  };

  async function api_getBatch(data) {
    let action = "batch.listBatches";
    var response = await api_handleApiCalls(action, data);
    return response;
  }
  async function api_getFacultyList(data) {
    let action = "systemUser.fetchSystemUserList";
    var response = await api_handleApiCalls(action, {});
    return response;
  }
  function clearFilter() {
    bindDateTime(true)
    // if (_$(filterElemStartTime).timepicker)
    //   _$(filterElemStartTime).timepicker("setTime", null);

    // if (_$(filterElemEndTime).timepicker)
    //   _$(filterElemEndTime).timepicker("setTime", null);
    if (
      selectizeBatchInstance &&
      selectizeBatchInstance[0] &&
      selectizeBatchInstance[0].selectize
    )
      selectizeBatchInstance[0].selectize.clear();
    if (
      selectizeStudyMaterialTypeInstance &&
      selectizeStudyMaterialTypeInstance[0] &&
      selectizeStudyMaterialTypeInstance[0].selectize
    )
      selectizeStudyMaterialTypeInstance[0].selectize.clear();
    if (
      selectizeFacultyInstance &&
      selectizeFacultyInstance[0] &&
      selectizeFacultyInstance[0].selectize
    )
      selectizeFacultyInstance[0].selectize.clear();
    filterObj = { ...initialFilterObj }
    fileExplorerFilter.update(() => null)
  }
  const unsubscribe = fileExplorerFilter.subscribe((filter) => {
    // console.log('filter => ', JSON.stringify(filter, null, 4));
    filterObj = filter || initialFilterObj
  })
  unsubscribe()
  function applyFilter() {
    fileExplorerFilter.update((oldValue) => {
      // console.log('oldValue => ', JSON.stringify(oldValue, null, ));
      filterObj.clearFilter = false

      return filterObj
    })
    // console.log(filterObj);
  }
  function bindSelectize() {
    selectizeBatchInstance = _$(filterElemBatch).selectize({
      preload: true,
      allowEmptyOption: true,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "Select Batch",
      options: batchList,
      onChange: (values) => {
        // console.log("selected batch : ", values);
        filterObj.batchIds = values;
      },
    });
    selectizeStudyMaterialTypeInstance = _$(
      filterElemStudyMaterialType
    ).selectize({
      preload: true,
      allowEmptyOption: true,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "Select Study Material Type",
      items: [],
      onChange: (values) => {
        // console.log("selected type : ", values);
        filterObj.studyMaterialType = values;
      },
    });
    selectizeFacultyInstance = _$(filterElemFaculty).selectize({
      allowEmptyOption: true,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "Select Faculty",
      options: facultyList,
      onChange: (values) => {
        // console.log("selected faculty : ", values);
        filterObj.facultyId = values;
      },
    });
  }
  function getTimepickerConfig(defaultTime, timeChanged, key) {
    return {
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      defaultTime: defaultTime ? defaultTime : "12:00 AM",
      startTime: "12:00 AM",
      change: (time) => timeChanged(key, time),
    };
  }
  async function timeChanged(key, time) {
    if (time != filterObj[key]) {
      if (time) {
        filterObj[key] = new Date(time);
      } else {
        filterObj[key] = null;
      }
    }
  }
  function bindDateTime(reset = false) {
    let startTimePickerConfig = getTimepickerConfig(
      !reset && filterObj.startTime
        ? moment(new Date(filterObj.startTime)).format("hh:mm A")
        : "12:00 AM",
      timeChanged,
      "startTime"
    );
    // if (timePickerStartTimeInstance) {
    //   _$(filterElemStartTime).timepicker('destroy');
    // }
    timePickerStartTimeInstance = _$(filterElemStartTime).timepicker(
      startTimePickerConfig
    );
    let endTimePickerConfig = getTimepickerConfig(
      !reset && filterObj.endTime
        ? moment(new Date(filterObj.endTime)).format("hh:mm A")
        : "12:00 AM",
      timeChanged,
      "endTime"
    );
    // if(_$(filterElemEndTime).timepicker) {
    //   _$(filterElemEndTime).timepicker('destory');
    // }
    timePickerEndTimeInstance =
      _$(filterElemEndTime).timepicker(endTimePickerConfig);
  }
  function changeCurrenPath() {
    currentPath = location.pathname

    console.log("currentPath---", currentPath)
  }

  onMount(async function () {
    activeMenu.set("studyMaterial");
    fileExplorerFilter.set(null)

    console.log("filterObj,::::------", filterObj)
    feather.replace();
    new PerfectScrollbar(".filemgr-sidebar-body", {
      suppressScrollX: false,
    });
    new PerfectScrollbar(".filemgr-content-body", {
      suppressScrollX: true,
    });

    var batchRes = await api_getBatch({ start: 0, length: 10000 });
    if (batchRes.status) {
      batchList = batchRes.data.data;
    }
    
      // Ensure that batchList is always an array
    if (!Array.isArray(batchList)) {
        batchList = []; // Fallback to an empty array if it's not
    }



    let facultyRes = await api_getFacultyList();
    // console.debug(facultyRes);
    if (facultyRes.status) {
      facultyList = facultyRes.data.data;
    }
    bindSelectize();
    bindDateTime();
    storage = await api_getStorageInfo({});
    storage = storage.data.toFixed(2);
  });

  async function api_getStorageInfo(data) {
    let action = "studyMaterial.getStorageInfo";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  function handleViewUnlinked() {
    navigate("/dashboard/studyMaterial/unlinkedfiles");
  }
</script>

<div class="content-body pd-0">
  <div class="filemgr-wrapper filemgr-wrapper-two">
    <div class="filemgr-sidebar">
      <div class="filemgr-sidebar-body" style="bottom:100px;">
        <!-- <div class="pd-t-20 pd-b-10 pd-x-10">
                    <label class="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">My
                        Drive</label>
                    <nav class="nav nav-sidebar tx-13">
                        <a href="" class="nav-link active"><i data-feather="folder"></i> <span>All Files</span></a>
                        <a href="" class="nav-link"><i data-feather="monitor"></i> <span>My Devices</span></a>
                        <a href="" class="nav-link"><i data-feather="clock"></i> <span>Recents</span></a>
                        <a href="" class="nav-link"><i data-feather="star"></i> <span>Important</span></a>
                        <a href="" class="nav-link"><i data-feather="trash"></i> <span>Deleted Files</span></a>
                    </nav>
                </div> -->

        <div class="contact-list pd-t-20 pd-x-20 question-wiz">
          <div class="media active" on:click={()=> {
            _$(".action-span").removeClass("node-selected");
            navigate("/dashboard/studyMaterial");
            }}
            >
            <div>
              <div class="avatar avatar-sm">
                <span class="avatar-initial rounded-circle">
                  <i data-feather="home" class="wd-15" />
                </span>
              </div>
            </div>
            <div class="media-body mg-l-10">
              <h6 class="tx-13 mg-b-3">Batch View</h6>
              <span class="tx-12">List all batches</span>
            </div>
          </div>
        </div>

        <div class="pd-10">
          <label class="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">Batch Folders</label>

          {#each batchList as item}
          {#if item.isRootBatch || item.isFree == "yes"}
          <FolderExplorer mode="compact" _batchId={item._id} />
          {/if}
          {/each}
        </div>

        <div class="pd-10" style="display: none;">
          <label class="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">File Library</label>
          <nav use:links class="nav nav-sidebar tx-13">
            <a href="/dashboard/studyMaterial/pdf" class="nav-link"><i data-feather="file" />
              <span>Documents</span></a>
            <a href="/dashboard/studyMaterial/images" class="nav-link"><i data-feather="image" />
              <span>Images</span></a>
            <a href="/dashboard/studyMaterial/recordedLecture" class="nav-link"><i data-feather="video" />
              <span>Videos</span></a>
            <a href="/dashboard/studyMaterial/liveLecture" class="nav-link"><i data-feather="video" />
              <span>Live Videos</span></a>
            <a href="/dashboard/studyMaterial/externalLink" class="nav-link"><i data-feather="video" />
              <span>Reference Links</span></a>
            <a href="/dashboard/studyMaterial/refLink" class="nav-link"><i data-feather="video" />
              <span>Reference Links</span></a>
            <a href="" class="nav-link"><i data-feather="music" /> <span>Audio</span></a>
            <a href="" class="nav-link"><i data-feather="package" /> <span>Zip Files</span></a>
          </nav>
        </div>
      </div>
      <!-- filemgr-sidebar-body -->
      <div style="
            position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100px;
                border-top: 1px solid rgba(72,94,144,0.16);
                display: flex;
                align-items: center;
                padding: 0 20px;
            ">
        <div class="pd-y-10 pd-x-20">
          <button type="button" class="btn btn-xs btn-primary mg-t-40 mg-b-5" on:click={handleViewUnlinked}>View
            Unlinked Files</button>

          <label class="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 mg-b-15">Storage Status</label>

          <div class="media">
            <i data-feather="database" class="wd-30 ht-30" />
            <div class="media-body mg-l-10">
              <div class="tx-12 mg-b-4">{storage} GB used of 5 GB</div>
              <div class="progress ht-3 mg-b-20">
                <div class="progress-bar wd-15p" role="progressbar" aria-valuenow={storage} aria-valuemin="0"
                  aria-valuemax="5" />
              </div>
            </div>
            <!-- media-body -->
          </div>
          <!-- media -->
          <!-- <p class="tx-12">Get 2TB (2,000GB) of storage now and get 40% off. Offers ends soon. <a href="">Learn more</a></p> -->
        </div>
      </div>
    </div>
    <!-- filemgr-sidebar -->

    <div class="filemgr-content">
      <div class="filemgr-content-header">
        <h4 class="mg-b-0" style="width: 85%;">
          <Router>
            <Route path="">All Files</Route>
            <Route path="pdf/*">Documents</Route>
            <Route path="images/*">Images</Route>
            <Route path="externalLink/*">External Links</Route>
            <Route path="refLink/*">Reference Links</Route>
            <Route path="liveLecture/*">Live Lectures</Route>
            <Route path="recordedLecture/*">Recorded Lectures</Route>
            <Route path="unlinkedfiles">Files With No Links</Route>
            <Route path="addnewfile">Add New File</Route>
            <Route path="folder/*">
              <div class="folder-header" />
            </Route>
          </Router>
        </h4>


        <button on:click={showHideFilterModal} class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase">
          <i data-feather="filter" class="mg-r-5" /> Filters</button>

        <!-- <nav class="nav d-none d-sm-flex mg-l-auto">
                    <a href="" class="nav-link"><i data-feather="list"></i></a>
                    <a href="" class="nav-link"><i data-feather="alert-circle"></i></a>
                    <a href="" class="nav-link"><i data-feather="settings"></i></a>
                </nav> -->
      </div>
      <!-- filemgr-content-header -->
      <div class="filemgr-content-body">
        <Router>
          <Route path="">
            <Library />
          </Route>
          <Route path="pdf/*">
            <PDF />
          </Route>
          <Route path="images/*">
            <Image />
          </Route>
          <Route path="externalLink/*">
            <ExternalLink />
          </Route>
          <Route path="refLink/*">
            <RefLink />
          </Route>
          <Route path="liveLecture/*">
            <LiveLecture />
          </Route>
          <Route path="recordedLecture/*">
            <RecordedLecture />
          </Route>
          <Route path="youtubeLecture/*">
            <YoutubeLecture />
          </Route>
          <Route path="unlinkedfiles">
            <UnlinkedFiles />
          </Route>
          <Route path="addnewfile/:_type/:idList" let:params>
            <AddNewFile _type={params._type} idList={params.idList.split("-")} />
          </Route>
          <Route path="folder/:idList" let:params>
            {#if MODULES.hasAccess(MODULES.VIEW_BATCH_STUDY_MATERIAL)}
            <Folder idList={params.idList.split("-")} />
            {:else}
            <Forbidden />
            {/if}
          </Route>
        </Router>
      </div>
      <!-- filemgr-content-body -->
    </div>
    <!-- filemgr-content -->
  </div>
  <!-- filemgr-wrapper -->
</div>

<!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60" /></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
          </div>
        </div>
        <!-- media -->
      </div>
      <!-- modal-header -->

      <hr class="full-width" />
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
        <div class="form-group" id="batchSelectize">

          <label class="d-block">Batch name:</label>
          <select disabled={isLoading} bind:this={filterElemBatch} class="info slectizedd form-control" />

        </div>
        <div class="form-group">
          <label class="d-block">Study material type :</label>
          <select disabled={isLoading} class="info slectizedd form-control" bind:this={filterElemStudyMaterialType}>
            <optgroup label="Files">
              <option value="image">Image</option>
              <option value="pdf">PDF</option>
            </optgroup>
            <optgroup label="Lecture">
              <option value="recordedLecture">Recorded Lecture</option>
              <!-- <option value="liveLecture-zoom">Live Zoom Lecture</option>
              <option value="liveLecture-youtube">Live Youtube Lecture</option> -->
            </optgroup>
            <optgroup label="Links">
              <option value="youtubeLecture">Youtube Links</option>
              <option value="refLink">Reference Links</option>
              <option value="externalLink">Enternal Links</option>
            </optgroup>
          </select>
        </div>
        <div class="form-group">
          <label class="d-block">Faculty name:</label>
          <select disabled={isLoading} bind:this={filterElemFaculty} class="info slectizedd form-control" />
        </div>
        <div class="form-group">
          <label class="d-block">Created on:</label>
          <input type="date" onkeydown="return false" value={convertDateObjToDateStr(filterObj.startDate)}
            on:change={event=> filterObj.startDate = event.target.value}
          class="form-control follow-up-date"
          placeholder="Start Date"
          />
        </div>
        <!-- <div class="form-group row">
          <div class="col-sm-6">
            <label class="d-block">Start Time:</label>
            <input
              onkeydown="return false"
              bind:this={filterElemStartTime}
              class="form-control filter-start-time"
              placeholder="Start Time"
            />
          </div>
          <div class="col-sm-6">
            <label class="d-block">End Time:</label>
            <input
              onkeydown="return false"
              bind:this={filterElemEndTime}
              class="form-control follow-up-date"
              placeholder="End Time"
            />
          </div>
        </div> -->

        <div class="d-md-flex justify-content-end mg-t-25">
          <button disabled={isLoading} on:click={clearFilter} class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5"
            data-dismiss="modal">CLEAR
            <i data-feather="x" class="wd-10 mg-l-5" />
          </button>
          <button disabled={isLoading} class="btn btn-sm pd-x-15 btn-primary mg-l-5" on:click={applyFilter}
            data-dismiss="modal">
            <ButtonLoader {isLoading} btnText="APPLY" />
            <i data-feather="filter" class="wd-10 mg-l-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
