<script>
    import {onMount, tick} from 'svelte'
    export let title
    export let count
    export let time 

    onMount(async () => {
        await tick();
        feather.replace({width: "1em", height: "1em"});
    })
</script>

<div class="card card-body">
    <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">{title ? title : ''}</h6>
    <div class="d-flex d-lg-block d-xl-flex align-items-end">
        <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{count}</h3>
        <p class="tx-11 tx-color-03 mg-b-0"><span class="">{time ? time : ''} </span><i class="wd-15" data-feather="calendar"></i></p> <!--<i class="wd-15" data-feather="calendar"></i>-->
    </div>
    <div class="chart-three">
        <div id="flotChart3" class="flot-chart ht-30"></div>
    </div><!-- chart-three -->
</div>
