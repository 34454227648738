<script>
    import { Router, Link, links, Route, navigate } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import { activeMenu } from "../stores";
    import { pSuccess, pError } from "../utils/notify.svelte";
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import ButtonLoader from "../utils/button_loader.svelte";
    import CountDownTimer from "../utils/countdownTimer.svelte"
    import CheckTest from "./checkTest.svelte";
    import { testURL } from "../../config.js"

    let testList = []

    let filterModalThis;
    let filterElemBatch;
    let filterByPrefix;
    let filterElemUser;
    let filterStartDate;
    let isLoading = false;

    let setIntervalForResult;
    let remainingTimeForResult = 0;

    function hideFilterModel(params) {
        _$("#filter-modal").modal("hide");
        assignmentObj.topic = "";
    }
    function showFilterModel(params) {
        _$("#filter-modal").modal("show");
    }


    let showHideFilterModal = () => {
        _$('#filter-modal').modal('show');
    }

    async function api_getMyTests() {

        let action = "test.getMyTests";
        var response = await api_handleApiCalls(action, null);
        return response;

    }
    async function api_generateStartToken(data) {

        let action = "test.generateStartToken";
        var response = await api_handleApiCalls(action, data);
        return response;

    }


    async function startTest(testId) {
        let res = await api_generateStartToken({ testId });

        let xMax = window.outerWidth - 20;
        let yMax = window.outerHeight - 70;

        if (res.data.doneTest) {
            let doneTestId = res.data.doneTest._id;
            let startToken = res.data.startToken;
            // Conditionally add startToken to the URL
            let url = startToken
                ? `${testURL}/result/${doneTestId}/${startToken}`
                : `${testURL}/result/${doneTestId}`;

            let examWindow = window.open(
                `${testURL}/result/${doneTestId}/${startToken}`,
                url,
                '_blank',
                `scrollbars=yes,width=${xMax},height=${yMax},top=0,left=0,resizable=yes`
            );

            let timer = setInterval(function () {
                if (examWindow.closed) {
                    clearInterval(timer);
                    // window.location.reload();
                }
            }, 500);
        } else {
            let startToken = res.data.startToken;
            // Conditionally add startToken to the URL
            let url = startToken
                ? `${testURL}/start/${startToken}`
                : `${testURL}/start`;

            let examWindow = window.open(
                url,
                '_blank',
                `scrollbars=yes,width=${xMax},height=${yMax},top=0,left=0,resizable=yes`
            );

            let timer = setInterval(function () {
                if (examWindow.closed) {
                    clearInterval(timer);
                    // window.location.reload();
                }
            }, 500);
        }
    }



    function testTimer(testStartTime) {

        var testStart = new Date(testStartTime)
        var todaysDate = new Date();

        // todaysDate = new Date(todaysDate.setHours(testStart.getHours(), testStart.getMinutes(), testStart.getSeconds(), 0))


        if (todaysDate >= testStart) {
            return true
        } else {
            return false
        }

        // return 
    }

    let getMomentString = (date, type) => {
        if (type == 'date')
            return moment(new Date(date)).format("DD-MMM-YYYY")
        else if (type == 'time')
            return moment(new Date(date)).format("hh:mm A")
        else return moment(new Date(date)).format("DD-MMM-YYYY, hh:mm A")
    }
    const init = async () => {
        let obj = await api_getMyTests();
        testList = obj.data.testList;
        activeMenu.set('myTests')
        if (Array.isArray(testList)) {
            for (let tIndx = 0; tIndx < testList.length; tIndx++) {
                var aTest = testList[tIndx];
                let tQCounts = 0

                if (Array.isArray(aTest.sections)) {

                    for (let sIdx = 0; sIdx < aTest.sections.length; sIdx++) {
                        const element = aTest.sections[sIdx];
                        tQCounts += element.questions.length
                    }

                }

                aTest.tQCounts = tQCounts
                testList[tIndx] = aTest

            }
        }

        await tick()
        feather.replace();
    }
    onMount(init)


</script>

<div class="content-body fadeIn" style="position: relative;    padding-bottom: 0;">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-15 mg-lg-b-15 mg-xl-b-15">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        My Test
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                My test
            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
                All tests
            </p>
        </div>
        <div class="d-block">
            <!-- <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25"
                on:click={onSaveClicked}
            ><i data-feather="save"
                    class="wd-10 mg-r-5"></i>
                Save
            </button>
            <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase "
            on:click={onPublishClicked}><i data-feather="anchor"
                    class="wd-10 mg-r-5"></i>
                Publish
            </button> -->
            <button on:click={showHideFilterModal} class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"> <i
                    data-feather="filter" class="mg-r-5"></i> Filters</button>
        </div>
    </div>

    <hr class="full-width">

    <div class="card-columns">


        {#each testList as item}
        <div class="card">


            <div class="card-body">
                <h5 class="card-title">{item.name ||''}</h5>
                <p class="card-text">{item.description||''}</p>
                <p class="card-text">
                    Test Date/Time: {item.startDate ? moment(new Date(item.startDate)).format("DD/MM/YYYY, hh:mm A") :""
                    }
                </p>


            </div>
            <div class="card-footer tx-13">
                <div class="d-flex flex-row">
                    <div class="flex-grow-2 row">
                        <div data-toggle="tooltip" data-placement="left" title="Start Test" data-id="{item._id}">

                            {#if (new Date(item.startDate) > new Date()) }

                            <CountDownTimer startFrom={ new Date(item.startDate).getTime()} onTimeOut={()=>{init();}}>
                                <button slot="container" let:time let:timeStr disabled
                                    class="btn btn-sm btn-secondary btn-uppercase  mg-5">
                                    Starts in {timeStr}
                                    <br />
                                </button>
                            </CountDownTimer>
                            {:else if item.doneTestObj}
                            <button class="btn btn-sm btn-primary btn-uppercase  mg-5"
                                on:click={startTest(item._id)}>View Test Result
                            </button>
                            {:else}
                            <CheckTest onStartBtnClick={startTest} testObj={item} expiryDate={new Date(item.endDate)}
                                checkOnTimeOut={()=>{init();}} />

                                {/if}
                        </div>
                    </div>
                </div>

            </div>
        </div>

        {/each}

    </div>
    {#if !testList.length}
    <div class="wd-100p tx-center align-items-center justify-content-center ">
        <img src="/images/empty.svg" style="width:200px" class="img-fluid" alt="No Result" />
        <p class="tx-bold">No test assigned.</p>

    </div>
    {/if}


</div>

<!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <div class="form-group">
                    <label class="d-block">Test name:</label>
                    <input type="text" class="form-control follow-up-date" placeholder="Batch name" />
                </div>

                <div class="form-group">
                    <label class="d-block">Date:</label>
                    <input type="date" onkeydown="return false" bind:value={filterStartDate}
                        class="form-control follow-up-date" placeholder="Student created on date" />
                </div>
                <div class="form-group">
                    <label class="d-block">Marks:</label>
                    <input type="number" class="form-control follow-up-date" placeholder="Marks" />
                </div>

                <div class="d-md-flex justify-content-end mg-t-25">
                    <button disabled={isLoading} class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5"
                        data-dismiss="modal">CLEAR
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button disabled={isLoading} class="btn btn-sm pd-x-15 btn-primary mg-l-5" data-dismiss="modal">
                        <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                        <i data-feather="filter" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Modal -->
