<script>
  export let mode = "empty";
</script>

<div class="wd-100p tx-center align-items-center justify-content-center bd">
  {#if mode == 'search'}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">Analytical category not found.</p>
  {:else}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">
      Welcome! Add your first analytical category.
    </p>
  {/if}
  <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
</div>
