<script>
    import { Router, Link, links, Route, navigate } from "svelte-routing";
    import { onMount, tick, onDestroy } from 'svelte';
    import { _mobileNo, _verifyOtpToken, _otp } from "../store.js";
    import { pSuccess, pError } from "../dashboard/utils/notify.svelte"
    import ButtonLoader from "../dashboard/utils/button_loader.svelte";

    let isLoading = false;
    let mobileNo;
    let mobileNoEle;
    const errorDetails = {};
    function clearFields() {
        mobileNo = "";
        clearValidations();
    }

    function clearValidations() {
        delete errorDetails.mobileNo;
    }
    function validate() {
        clearValidations();
        tick();
        // var moNoReg = new RegExp("^[7-9][0-9]{9}$")
        // if (!moNoReg.test(mobileNo) || mobileNo.length != 10) {
        if (String(mobileNo).length != 10) {
            errorDetails.mobileNo = "Mobile number must be 10 digits";
        }
        if (Object.values(errorDetails).length > 0) {
            return false;
        } else {
            return true;
        }
    }


    async function sendOTP() {
        if(isLoading) return
        isLoading = true
        tick()
        const isValid = validate();
        if(isValid) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var payload = JSON.stringify({
                action: "auth.sendOtpForRegistration",
                data: {
                    mobileNo
                },
            });
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: payload,
            };
            try {
                var resObj = await fetch(API_URL, requestOptions);
                var response = await resObj.json();
                console.log( response);

                if(response.status){
                    _mobileNo.set(mobileNo);
                    _verifyOtpToken.set(response.data.otpToken);
                    _verifyOtpToken.set(response.data.otpToken);
                    _otp.set(response.data.otpCode);
                    // pSuccess("", "OTP sent to your mobile number")
                    isLoading = false
                    navigate("/signup/verify-otp");
                }else{
                    errorDetails.mobileNo = response.errMsg;
                }
            } catch (error) {
                pError("", response.errMsg || response.error || "something went wrong")
                console.error("error", error);
            }
        }
        isLoading = false
    }
    async function init() {
        
        clearFields();
        _mobileNo.subscribe(value=> mobileNo = value || '')
        mobileNoEle.focus();
    }
    onMount(init);
    onDestroy(clearFields);

</script>

<div class="sign-wrapper mg-lg-r-50 mg-xl-r-60 fadeIn" style="    flex: 1;">
    <div class="pd-t-20 wd-100p">
        <h4 class="tx-color-01 mg-b-5">Create New Account</h4>
        <p class="tx-color-03 tx-16 mg-b-40">It's free to signup and only takes a minute.</p>

        <div class="form-group">
            <label>Mobile Number</label>
            <input bind:this={mobileNoEle} value={mobileNo} type="number" class="form-control"
                on:keyup={(e) => e.key === 'Enter' && sendOTP()}
                on:input={function (e) {
                    this.value = this.value.slice(0, 10);
                    mobileNo = this.value;
                  }}
                on:blur={clearValidations}
                placeholder="Enter your Mobile Number" class:parsley-error={errorDetails.mobileNo}>
            {#if errorDetails.mobileNo}
                <div class="parsley-errors-list">{errorDetails.mobileNo}</div>
            {/if}
        </div>

        <div class="form-group tx-12">
            By clicking <strong>Create an account</strong> below, you agree to our terms of service and
            privacy statement.
        </div><!-- form-group -->

        <button on:click={sendOTP} class="btn btn-brand-02 btn-block" disabled={isLoading}>
            <ButtonLoader isLoading={isLoading} btnText="Next"/>
        </button>
        <div class="divider-text">or</div>
        <!-- <button class="btn btn-outline-facebook btn-block">Sign Up With Facebook</button>
        <button class="btn btn-outline-twitter btn-block">Sign Up With Twitter</button> -->
        <div class="tx-13 mg-t-20 tx-center">Already have an account? <a  use:links href="/login">Sign In</a>
        </div>
    </div>
</div><!-- sign-wrapper -->
