<script>
    import {createEventDispatcher, onMount} from 'svelte';
    import PaymentTable from './paymentTable.svelte';
    const dispatch = createEventDispatcher();
    export let aBatch;
    export let studentId;
    export let batchList = []
    export let allPaidBatches =  new Set()
    
    let modalEle;
    let instalment_modalEle;
    let totalPaidAmount = 0;
    let balanceAmount = 0;
    let selectedbatches
    export let selectAllChecked 
    onMount(async () => {
        feather.replace();
        dispatch('refreshData');
        // dispatch('printSelectedBatchReceipts',);
        // console.log("batch list::",batchList)
        // renderBatchList();
    })

    let addNewPayment = function(){
        _$(modalEle).modal('show')
    }

    let addNewInstalment = function(){
        _$(instalment_modalEle).modal('show')
    }
    function selectedBatches({detail}){
        // console.log("selected batches",detail)
        selectedbatches =  detail.selectedBatches
        // dispatch('printSelectedBatchReceipts',{selectedBatches:detail.selectedBatches})
        // console.log("selected batches",eventselectedbatches)
    }
</script>


<!-- Modal -->
<div class="modal fade"  bind:this={instalment_modalEle} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel6"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel6">
                    New Payment
                </h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label class="d-block">Name*</label>
                        
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">Proceed</button>
        </div>
      </div>
    </div>
  </div>
<!-- Modal -->

    
<PaymentTable on:refreshData={()=> dispatch('refreshData')} allPaidBatches={allPaidBatches} on:printSelectedBatchReceipts on:selectedBatches={selectedBatches} bind:totalPaidAmount={totalPaidAmount} bind:balanceAmount={balanceAmount} studentBatchObj={aBatch} batchId={aBatch.batchId} studentId={studentId}  selectAllCbkChecked= {selectAllChecked} batchList={batchList}></PaymentTable>
