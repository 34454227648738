<script>
  import { Router, Link, links, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import { userData } from "../../stores";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  export let studentId;
  let pointsHistory = {
    totalPoints: 0,
    recordsFiltered: 0,
    recordsTotal: 0,
    data: [],
  };
  let roleIsTelecaller;
  let addPointModal;
  let allowPoints = false;
  let MAX_CAP = 0;
  let elem_pointEventTypes,
    elem_selectSchoolCollege,
    elem_tests,
    elem_batches,
    elem_usersReferredBy,
    elem_usersReferredTo,
    elem_customPoints,
    elem_scrollPointsList,
    elem_prevRemarks,
    scrollbar;

  async function api_addUpdatePoint(data) {
    let action = "loyaltyPoints.awardPoints";
    // console.log(action, { data });
    var response = await api_handleApiCalls(action, data);
    return response;
  }
  // console.log("studentID", studentId);
  if (!studentId) window.history.back();
  export let _id;
  let isLoading;
  $: (isLoading || !isLoading) &&
    feather.replace({ widht: "1em", height: "1em" });
  let pointObj;

  let displayText = "Add Points";

  let errorObj = {};
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = {
      ...errorObj,
    }; // reset state
  };
  let validatePoint = () => {
    errorObj = {};
    var newErrorObj = {};
    if (pointObj) {
      if (!pointObj.event) {
        newErrorObj.event = "Points Event Type is required Field.";
      } else {
        if (checkIsCustom(pointObj.event)) {
          if (!pointObj.remark) newErrorObj.remark = "Remark is required field";
          if (!pointObj.points) newErrorObj.points = "Points is required field";
        } else if (checkIsCompetitiveExam(pointObj.event)) {
          if (!pointObj.competitiveExam.examName)
            newErrorObj.examName = "Exam Name is required field";
          if (
            !pointObj.competitiveExam.marksObtained ||
            pointObj.marksObtained == 0
          )
            newErrorObj.marksObtained = "Marks Obtained is required field";
        } else if (checkIsCompletingAnYear(pointObj.event)) {
          // if(!pointObj.testId) newErrorObj.testId = "Test is required field";
        } else if (checkIsEnrolling(pointObj.event)) {
          if (!pointObj.batchId)
            newErrorObj.batchId = "Batch is required field";
        } else if (checkIsInternalTest(pointObj.event)) {
          if (!pointObj.testId) newErrorObj.testId = "Test is required field";
        } else if (checkIsSelfCompetition(pointObj.event)) {
          // if (!pointObj.batchId)
          //   newErrorObj.batchId = "Batch is required field";
        } else if (checkIsShare(pointObj.event)) {
          if (!pointObj.referredBy || pointObj.referredTo) {
            newErrorObj.referredBy =
              "Please select Referred By or Referred To user";
            newErrorObj.referredTo =
              "Please select Referred By or Referred To user";
          }
        }
      }
    }
    errorObj = newErrorObj;
    if (Object.keys(errorObj).length > 0) {
      isLoading = false;
      return false;
    }
    return true;
  };
  let isInternalTest = false,
    isCompetitiveExam = false,
    isEnrolling = false,
    isCompletingAnYear = false,
    isShare = false,
    isCustom = false,
    isSelfCompetition = false;
  $: isInternalTest && selectizeUtil.bindTests(pointObj.testId);
  $: isCompetitiveExam &&
    selectizeUtil.bindSelectSchoolCollege(
      pointObj.competitiveExam.instituteName
    );

  $: isEnrolling && selectizeUtil.bindBatches(pointObj.batchId);
  $: isCompletingAnYear = false;
  $: isShare && selectizeUtil.bindUsers(pointObj.referredBy, pointObj.userId);
  $: isCustom &&
    elem_customPoints &&
    (() => {
      selectizeUtil.bindPrevRemarks(pointObj.remark);
      _$(elem_customPoints).attr("max", MAX_CAP);
      _$(elem_customPoints).attr("maxlength", Math.log10(MAX_CAP));
    })();
  // $: isSelfCompetition && selectizeUtil.bindTests(pointObj.testId);;
  const checkIsShare = (event) => event == "Share";
  const checkIsSelfCompetition = (event) => event == "Self_Competition";
  const checkIsCompletingAnYear = (event) => event == "Completing_An_Year";
  const checkIsEnrolling = (event) => event == "Enrolling";
  const checkIsCompetitiveExam = (event) => event == "Competitive_Exams";
  const checkIsInternalTest = (event) =>
    event && event.startsWith("Internal_Test");
  const checkIsCustom = (event) => event == "Custom";
  const selectizeUtil = {
    bindPointEventTypes: async () => {
      let pointTypes = await api_handleApiCalls("loyaltyPoints.getEvents");
      if (pointTypes.status) pointTypes = pointTypes.data;
      let options = Object.keys(pointTypes)
        .map((key) => {
          return {
            value: pointTypes[key],
            label: key.replace(/_/g, " "),
          };
        })
        // remove later
        .filter((item) => item.value != "Self_Competition");
      _$(elem_pointEventTypes).selectize({
        maxItems: 1,
        valueField: "value",
        labelField: "label",
        searchField: "label",
        placeholder: "Select Event Type",
        options,
        create: false,
        items: [],
        onChange: async function (value) {
          resetPoint();

          pointObj.event = value;
          isCustom = checkIsCustom(value);
          isInternalTest = checkIsInternalTest(value);
          isCompetitiveExam = checkIsCompetitiveExam(value);
          isEnrolling = checkIsEnrolling(value);
          isCompletingAnYear = checkIsCompletingAnYear(value);
          isShare = checkIsShare(value);
          isSelfCompetition = checkIsSelfCompetition(value);

          console.table({
            isCustom,
            isInternalTest,
            isCompetitiveExam,
            isEnrolling,
            isCompletingAnYear,
            isShare,
            isSelfCompetition,
          });
        },
      });
    },
    bindSelectSchoolCollege: async (selected) => {
      var selectizeInsanceThis;
      var schoolCollegesList = [];
      var response = await api_handleApiCalls(
        "dropdown.listSchoolColleges",
        {}
      );
      if (response.status) schoolCollegesList = response.data;

      var _selectizeInstitute = _$(elem_selectSchoolCollege).selectize({
        preload: true,
        maxItems: 1,
        valueField: "_id",
        labelField: "name",
        placeholder: "School/College/University",
        searchField: "name",
        items: selected ? [selected] : [],
        options: schoolCollegesList,
        create: true,
        sortField: "name",
        create: async function (input) {
          input =
            typeof input == "string"
              ? input.charAt(0).toUpperCase() + input.slice(1)
              : null;

          var response = await api_handleApiCalls(
            "dropdown.createSchoolCollege",
            {
              name: input,
            }
          );

          if (response.status) {
            response = response.data;
            selectizeInsanceThis.addOption({
              _id: response._id,
              name: response.name,
            });
            selectizeInsanceThis.refreshOptions();
            selectizeInsanceThis.setValue(response._id);
          }
        },
        onChange: function (value) {
          pointObj.competitiveExam.instituteName = value;
        },
      });
      selectizeInsanceThis = _selectizeInstitute[0].selectize;
    },
    bindTests: async (selected) => {
      let tests = await api_handleApiCalls("test.list", {
        start: 0,
        sort: { startDate: -1 },
      });
      if (tests.status) tests = tests.data.data;
      _$(elem_tests).selectize({
        maxItems: 1,
        valueField: "value",
        labelField: "label",
        searchField: "label",
        placeholder: "Select Test",
        options: tests.map((test) => {
          return { label: test.displayName || test.name, value: test._id };
        }),
        create: false,
        items: selected ? [selected] : [],
        onChange: async function (value) {
          pointObj.testId = value;
        },
      });
    },
    bindBatches: async (selected) => {
      let batches = await api_handleApiCalls("batch.listBatches", {
        start: 0,
        sort: { startDate: -1 },
        noPagination:true
      });
      if (batches.status) batches = batches.data.data;
      _$(elem_batches).selectize({
        maxItems: 1,
        valueField: "value",
        labelField: "label",
        searchField: "label",
        placeholder: "Select Batch",
        options: batches.map((batch) => {
          return { label: batch.name, value: batch._id };
        }),
        create: false,
        items: selected ? [selected] : [],
        onChange: async function (value) {
          pointObj.batchId = value;
        },
      });
    },
    bindUsers: async (selectedReferredBy, selectedReferredTo) => {
      let users = await api_handleApiCalls("user.getAllUsers", {});
      if (users.status) {
        users = users.data.data.filter((user) => user._id != studentId);
      }
      _$(elem_usersReferredBy).selectize({
        maxItems: 1,
        valueField: "value",
        labelField: "label",
        searchField: "label",
        placeholder: "Select User",
        options: users.map((user) => {
          return { label: user.name, value: user._id };
        }),
        create: false,
        items: selectedReferredBy ? [selectedReferredBy] : [],
        onChange: async function (value) {
          if (value) {
            _$(elem_usersReferredTo)[0].selectize.disable();
            pointObj.referredBy = value;
            pointObj.referredTo = studentId;
            pointObj.userId = studentId;
          } else {
            _$(elem_usersReferredTo)[0].selectize.enable();
            pointObj.referredBy = "";
            pointObj.referredTo = "";
            pointObj.userId = "";
          }
        },
      });
      _$(elem_usersReferredTo).selectize({
        maxItems: 1,
        valueField: "value",
        labelField: "label",
        searchField: "label",
        placeholder: "Select User",
        options: users.map((user) => {
          return { label: user.name, value: user._id };
        }),
        create: false,
        items: selectedReferredTo ? [selectedReferredTo] : [],
        onChange: async function (value) {
          if (value) {
            _$(elem_usersReferredBy)[0].selectize.disable();
            pointObj.referredBy = studentId;
            pointObj.referredTo = value;
            pointObj.userId = value;
          } else {
            _$(elem_usersReferredBy)[0].selectize.enable();
            pointObj.referredBy = "";
            pointObj.referredTo = "";
            pointObj.userId = "";
          }
        },
      });
    },
    bindPrevRemarks: async (selected) => {
      let prevRemarks = [];
      _$(elem_prevRemarks).selectize({
        preload: true,
        maxItems: 1,
        valueField: "value",
        labelField: "label",
        searchField: "label",
        placeholder: "Select Remark",
        load: async function (query, callback) {
          this.clearOptions();
          let data = {
            search: query || undefined,
          };
          prevRemarks = await api_handleApiCalls(
            "loyaltyPoints.getCustomRemarks",
            data
          );
          let callbackData = [];
          if (prevRemarks.status) {
            callbackData = prevRemarks.data.map(({ remark, points, _id }) => {
              return { label: `${points} Points : ${remark}`, value: _id };
            });
            prevRemarks = prevRemarks.data;
          }
          callback(callbackData);
        },
        create: false,
        items: selected ? [selected] : [],
        onChange: async function (value) {
          let found = prevRemarks.find((remark) => remark._id == value);
          if (found) {
            pointObj.remark = found.remark;
            pointObj.points = found.points;
          }
        },
      });
    },
  };
  async function initSelectize() {
    selectizeUtil.bindPointEventTypes();
  }

  function resetPoint() {
    pointObj = {
      _id: "",
      event: "",
      points: "",
      userId: "",
      batchId: "",
      testId: "",
      referredBy: "",
      remark: "",
      competitiveExam: {
        examName: "",
        marksObtained: 0,
        instituteName: "",
      },
    };
  }
  function resetPointForm() {
    if (_$(elem_pointEventTypes).length)
      _$(elem_pointEventTypes)[0].selectize.clear();
    if (_$(elem_selectSchoolCollege).length)
      _$(elem_selectSchoolCollege)[0].selectize.clear();
    if (_$(elem_batches).length) _$(elem_batches)[0].selectize.clear();
    if (_$(elem_tests).length) _$(elem_tests)[0].selectize.clear();
    if (_$(elem_usersReferredBy).length)
      _$(elem_usersReferredBy)[0].selectize.clear();
    if (_$(elem_usersReferredTo).length)
      _$(elem_usersReferredTo)[0].selectize.clear();
    if (_$(elem_prevRemarks).length) _$(elem_prevRemarks)[0].selectize.clear();
    resetPoint();
    displayText = "Add Point";
  }
  async function addUpdatePoint() {
    // console.log("addUpdatePoint", { pointObj });
    if (isLoading) return;
    isLoading = true;
    if (validatePoint()) {
      let newPointObj = {
        event: pointObj.event,
        remark: pointObj.remark ? pointObj.remark : undefined,
        points: pointObj.remark ? pointObj.points : undefined,
        testId: pointObj.testId ? pointObj.testId : undefined,
        batchId: pointObj.batchId ? pointObj.batchId : undefined,
        referredBy: pointObj.referredBy ? pointObj.referredBy : undefined,
        referredTo: pointObj.referredTo ? pointObj.referredTo : undefined,
        competitiveExam: pointObj.competitiveExam
          ? pointObj.competitiveExam
          : undefined,
        userIds: pointObj.userId
          ? pointObj.referredBy
            ? [pointObj.userId, pointObj.referredBy]
            : [pointObj.userId]
          : [studentId],
      };
      let resData = await api_addUpdatePoint(newPointObj);
      isLoading = false;
      await tick();
      if (resData.status) {
        _$(addPointModal).modal("hide");
        PNotify.success({
          title: "Success",
          text: resData.msg,
          delay: 3000,
        });
        loadPoints();
      } else {
        PNotify.error({
          title: "Error",
          text: resData.errMsg,
          delay: 3000,
        });
      }
    } else {
      isLoading = false;
    }
  }

  async function editPoint(pointId) {
    // console.log("pointId", pointId);
    // if (pointId) {
    //   let resData = await api_addUpdatePoint({
    //     _id: pointId
    //   });
    //   if (resData.status) {
    //     let patObj = resData.data[0];
    //     displayText = "Update Point"
    //     pointObj = {
    //       _id: patObj._id,
    //       name: patObj.name,
    //       occupation: patObj.occupation,
    //       event: patObj.event,
    //       mobileNo: patObj.mobileNo,
    //       altMobileNo: patObj.altMobileNo,
    //       studentId
    //     }
    //     _$(eventType).val(patObj.event);
    //     _$(addPointModal).modal('show');
    //   }
    //    else {
    //     PNotify.error({
    //       title: 'Error',
    //       text: resData.errMsg,
    //       delay: 3000
    //     })
    //   }
    // }
  }
  async function removePoints(pointId) {
    // console.log("pointId", pointId);
    const notice = PNotify.notice({
      title: "Remove Points",
      text: "Are you sure you want to remove points?",
      hide: false,
      closer: false,
      sticker: false,
      modules: new Map([
        ...PNotify.defaultModules,
        [PNotifyConfirm, { confirm: true }],
      ]),
    });

    notice.on("pnotify:confirm", async () => {
      try {
        var removePointsResult = await api_handleApiCalls(
          "loyaltyPoints.removePointsById",
          { _id: pointId }
        );
        if (removePointsResult.status) {
          PNotify.success({
            title: "Success",
            text: removePointsResult.data,
            delay: 3000,
          });
        } else {
          PNotify.error({
            title: "Error",
            text: removePointsResult.errMsg,
            delay: 3000,
          });
        }
        loadPoints();
      } catch (error) {
        // console.error(error);
      }
    });
  }
  const loadMore = () => {
    // console.log("loadMore", pointsHistory);
    if (pointsHistory.recordsFiltered > pointsHistory.data.length)
      loadPoints(pointsHistory.start + pointsHistory.length);
  };
  const refreshList = () => {
    // console.log("loadMore", pointsHistory);
    loadPoints(0, pointsHistory.data.length);
  };
  const loadPoints = async (start = 0, length = 5) => {
    isLoading = true;
    var resData = await api_handleApiCalls(
      "loyaltyPoints.getPointsHistoryByUserId",
      {
        userId: studentId,
        start,
        length,
      }
    );
    // console.log("loadPoints", resData.data);
    if (resData.status && resData.data) {
      if (start > 0) {
        pointsHistory = {
          ...resData.data,
          data: pointsHistory.data.concat(resData.data.data),
        };
      } else pointsHistory = resData.data;

      await tick();
      if (scrollbar) scrollbar.update();
      else {
        scrollbar = new PerfectScrollbar(elem_scrollPointsList, {
          suppressScrollX: true,
        });
      }
    } else {
      PNotify.error({
        title: "Error",
        text: resData.errMsg,
        delay: 2000,
      });
    }
    isLoading = false;
  };
  const unsubscribe = userData.subscribe((value) => {
    roleIsTelecaller = value.role == "telecaller";
  });

  const getConfig = async () => {
    let pointsConfig = await api_handleApiCalls("loyaltyPoints.getConfig");
    if (pointsConfig.status) {
      allowPoints = pointsConfig.data.ALLOW_LOYALTY_POINTS;
      await tick();
      if (allowPoints) {
        // pointsConfig = pointsConfig.data.pointsConfig;
        MAX_CAP = pointsConfig.data.pointsConfig.MAX_CAP;
        initSelectize();
        loadPoints();
        _$(addPointModal).on("show.bs.modal", function (e) {
          _$(this).addClass("effect-scale");
        });
        _$(addPointModal).on("hidden.bs.modal", function (e) {
          errorObj = {};
          resetPointForm();
          _$(this).removeClass(function (index, className) {
            return (className.match(/(^|\s)effect-\S+/g) || []).join(" ");
          });
        });
      }
    }
  };

  onMount(async () => {
    resetPoint();
    unsubscribe();
    setTimeout(() => {
      getConfig();
    }, 1000);
  });
</script>

{#if allowPoints}
  <div class="d-flex align-items-center justify-content-between mg-b-20">
    <h6 class="tx-13 tx-spacng-1 tx-uppercase tx-semibold mg-b-0">
      Points {pointsHistory.totalPoints}
    </h6>
    {#if isLoading}
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      />
    {:else}
      <a
        href="javascript:void(0)"
        on:click={refreshList}
        class="link-03 tx-bold"
        style="color: #0168fa;"
      >
        <i data-feather="refresh-cw" class="wd-10 mg-r-5" /></a
      >
    {/if}
    {#if !roleIsTelecaller}
      <a
        href="#addPoint"
        data-toggle="modal"
        class="link-03 tx-bold"
        style="color: #0168fa;"
      >
        <i data-feather="plus" class="wd-10 mg-r-5" />Add Points</a
      >
    {/if}
  </div>
  <ul
    class="list-unstyled media-list mg-b-15 pos-relative ht-250 scrollbar-sm"
    bind:this={elem_scrollPointsList}
  >
    {#each pointsHistory.data as point (point.seqId)}
      <li class="media align-items-center" point-id={point._id} key={point._id}>
        <div class="d-flex" on:click={editPoint(point._id)}>
          <div class="avatar" title={point.event.replace(/_/g, " ")}>
            <span class="avatar-initial rounded-circle bg-dark">
              {point.event.charAt(0)}
            </span>
          </div>
          <div class="media-body pd-l-15">
            {#if checkIsCustom(point.event)}
              <p class="tx-medium mg-b-0 text-capitalize">
                <a href="javascript:void(0);" class="link-01">
                  {point.remark}
                </a>
              </p>
            {:else if checkIsInternalTest(point.event)}
              <p class="tx-medium mg-b-0 text-capitalize">
                <a href="javascript:void(0);" class="link-01">
                  {point.test.name}
                </a>
              </p>
            {:else if checkIsEnrolling(point.event)}
              <p class="tx-medium mg-b-0 text-capitalize">
                <a href="javascript:void(0);" class="link-01">
                  {point.batch.name}
                </a>
              </p>
            {:else if checkIsCompetitiveExam(point.event)}
              <p class="tx-medium mg-b-0 text-capitalize">
                <a href="javascript:void(0);" class="link-01">
                  {point.competitiveExam.examName}
                </a>
              </p>
            {:else if checkIsShare(point.event)}
              {#if point.referredByUser._id == studentId && point.referredToUser._id != studentId}
                <p class="tx-medium mg-b-0 text-capitalize">
                  <a href="javascript:void(0);" class="link-01">
                    to {point.referredToUser.name}
                  </a>
                </p>
              {:else if point.referredByUser._id != studentId && point.referredToUser._id == studentId}
                <p class="tx-medium mg-b-0 text-capitalize">
                  <a href="javascript:void(0);" class="link-01">
                    by {point.referredByUser.name}
                  </a>
                </p>
              {:else if point.referredBy._id == point.referredToUser._id && point.referredToUser._id == studentId}
                <p class="tx-medium mg-b-0 text-capitalize">
                  <a href="javascript:void(0);" class="link-01">
                    by {point.referredBy.name}
                  </a>
                </p>
              {:else if point.referredBy._id == point.referredToUser._id && point.referredToUser._id !== studentId}
                <p class="tx-medium mg-b-0 text-capitalize">
                  <a href="javascript:void(0);" class="link-01">
                    to {point.referredTo.name}
                  </a>
                </p>
              {/if}
            {:else}
              <p class="tx-medium mg-b-0 text-capitalize">
                <a href="javascript:void(0);" class="link-01">
                  {point.event.replace(/_/g, " ")}
                </a>
              </p>
            {/if}
            <span class="tx-12 tx-color-03">{point.points} </span>
          </div>
        </div>
        <div class="mg-l-auto d-flex align-self-start">
          <span class="nav nav-icon-only">
            <a
              href="javascript:void(0);"
              on:click={removePoints(point._id)}
              class="nav-link d-none d-sm-block"
              style="color:  #0168fa;"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Delete"><i data-feather="trash" class="wd-20 mg-r-5" /></a
            >
          </span>
        </div>
      </li>
    {/each}
  </ul>
  {#if pointsHistory.recordsFiltered > pointsHistory.data.length}
    <a href="javascript:void(0);" on:click={loadMore} class=""> Load More </a>
  {/if}
  {#if !roleIsTelecaller}
    <div
      class="modal fade"
      id="addPoint"
      bind:this={addPointModal}
      tabindex="-1"
      role="dialog"
      aria-labelledby="addPointLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
            <a
              href=""
              role="button"
              class="close pos-absolute t-15 r-15 z-index-10"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </a>
            <div class="media align-items-center">
              <span class="tx-color-03 d-none d-sm-block"
                ><i data-feather="users" class="wd-60 ht-60" /></span
              >
              <div class="media-body mg-sm-l-20">
                <h4 class="tx-18 tx-sm-20 mg-b-2">{displayText}</h4>
                <p class="tx-13 tx-color-03 mg-b-0">Add details below.</p>
              </div>
            </div>
            <!-- media -->
          </div>
          <!-- modal-header -->
          <hr class="full-width" />
          <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0 ">
            <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
              <div class="row row-sm mg-b-10">
                <div class="col-sm-12">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="d-block"
                        >Event<span class="tx-danger">*</span></label
                      >
                      <select
                        class="info slectizedd form-control"
                        bind:this={elem_pointEventTypes}
                        on:click={() => clearError("event")}
                      />
                      {#if errorObj.event}
                        <div class="parsley-errors-list">{errorObj.event}</div>
                      {/if}
                    </div>
                    {#if isInternalTest}
                      <div class="form-group col-md-12 fadeIn">
                        <label class="d-block"
                          >Test<span class="tx-danger">*</span></label
                        >
                        <select
                          class="info slectizedd form-control"
                          bind:this={elem_tests}
                          on:click={() => clearError("testId")}
                        />
                        {#if errorObj.testId}
                          <div class="parsley-errors-list">
                            {errorObj.testId}
                          </div>
                        {/if}
                      </div>
                    {/if}
                    {#if isCompetitiveExam}
                      <div class="form-group col-md-12 fadeIn">
                        <label class="d-block">School/College/University</label>
                        <select
                          class="info slectizedd form-control"
                          bind:this={elem_selectSchoolCollege}
                        />
                      </div>
                      <div class="form-group col-md-12 fadeIn">
                        <label class="d-block"
                          >Exam Name<span class="tx-danger">*</span></label
                        >
                        <input
                          type="text"
                          on:keyup={(e) =>
                            e.key === "Enter" && addUpdatePoint()}
                          bind:value={pointObj.competitiveExam.examName}
                          on:blur={() => clearError("occupation")}
                          class="form-control"
                          placeholder="Enter exam name"
                        />

                        {#if errorObj.examName}
                          <div class="parsley-errors-list">
                            {errorObj.examName}
                          </div>
                        {/if}
                      </div>
                      <div class="form-group col-md-12 fadeIn">
                        <label class="d-block"
                          >Marks<span class="tx-danger">*</span></label
                        >
                        <input
                          type="number"
                          on:keyup={(e) =>
                            e.key === "Enter" && addUpdatePoint()}
                          bind:value={pointObj.competitiveExam.marksObtained}
                          on:blur={() => clearError("occupation")}
                          class="form-control"
                          placeholder="Enter obtained marks"
                        />
                        {#if errorObj.marksObtained}
                          <div class="parsley-errors-list">
                            {errorObj.marksObtained}
                          </div>
                        {/if}
                      </div>
                    {/if}
                    {#if isEnrolling}
                      <div class="form-group col-md-12 fadeIn">
                        <label class="d-block"
                          >Batch<span class="tx-danger">*</span></label
                        >
                        <select
                          class="info slectizedd form-control"
                          bind:this={elem_batches}
                          on:click={() => clearError("batchId")}
                        />
                        {#if errorObj.batchId}
                          <div class="parsley-errors-list">
                            {errorObj.batchId}
                          </div>
                        {/if}
                      </div>
                    {/if}
                    {#if isShare}
                      <div class="form-group col-md-12 fadeIn">
                        <label class="d-block"
                          >Referred By<span class="tx-danger">*</span></label
                        >
                        <select
                          class="info slectizedd form-control"
                          bind:this={elem_usersReferredBy}
                          on:click={() => clearError("userId")}
                        />
                        {#if errorObj.referredBy}
                          <div class="parsley-errors-list">
                            {errorObj.referredBy}
                          </div>
                        {/if}
                      </div>
                      <div class="form-group col-md-12 fadeIn text-center">
                        OR
                      </div>
                      <div class="form-group col-md-12 fadeIn">
                        <label class="d-block"
                          >Referred To<span class="tx-danger">*</span></label
                        >
                        <select
                          class="info slectizedd form-control"
                          bind:this={elem_usersReferredTo}
                          on:click={() => clearError("userId")}
                        />
                        {#if errorObj.referredTo}
                          <div class="parsley-errors-list">
                            {errorObj.referredTo}
                          </div>
                        {/if}
                      </div>
                    {/if}
                    {#if isCustom}
                      <div class="form-group col-md-12 fadeIn">
                        <label class="d-block">Select From Previous</label>
                        <select
                          class="info slectizedd form-control"
                          bind:this={elem_prevRemarks}
                        />
                      </div>
                      <div class="form-group col-md-12 fadeIn text-center">
                        OR
                      </div>
                      <div class="form-group col-md-12 fadeIn">
                        <label class="d-block"
                          >Points<span class="tx-danger">*</span></label
                        >
                        <input
                          type="number"
                          class="form-control"
                          bind:this={elem_customPoints}
                          bind:value={pointObj.points}
                          placeholder="Enter points"
                        />
                        {#if errorObj.points}
                          <div class="parsley-errors-list">
                            {errorObj.points}
                          </div>
                        {/if}
                      </div>
                      <div class="form-group col-md-12 fadeIn">
                        <label class="d-block"
                          >Remark<span class="tx-danger">*</span></label
                        >
                        <textarea
                          class="form-control"
                          rows="2"
                          style="resize:none"
                          bind:value={pointObj.remark}
                        />
                        {#if errorObj.remark}
                          <div class="parsley-errors-list">
                            {errorObj.remark}
                          </div>
                        {/if}
                      </div>
                    {/if}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary tx-12"
              data-dismiss="modal"
              >CLOSE
              <i data-feather="x" class="wd-10 mg-l-5" />
            </button>
            <button
              type="button"
              disabled={isLoading}
              on:click={addUpdatePoint}
              class="btn btn-primary tx-12"
            >
              <ButtonLoader {isLoading} btnText="SAVE" />
              <i data-feather="save" class="wd-10 mg-l-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  {/if}
{/if}

<style>
  .ps__rail-x,
  .ps__rail-y,
  .ps > .ps__scrollbar-x-rail,
  .ps > .ps__scrollbar-y-rail {
    opacity: 0.6 !important;
  }
</style>
