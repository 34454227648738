<script>
    import { createEventDispatcher, onMount, tick, beforeUpdate } from 'svelte';
    import { links } from "svelte-routing";
    const dispatch = createEventDispatcher();
    import ImageUploader from "../utils/imageUploader.svelte";
    import {userData} from "../stores";
    import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
    import ButtonLoader from "../utils/button_loader.svelte"
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import MODULES from "../utils/modules.js"
    export let studentId;

    let profilePic = "/images/man.png";
    export let inactive
    export let admissionCancel
    let roleIsTelecaller;
    let name = "";
    let address = "";
    let city = "";
    let dob = "";
    let email = "";
    let enrollmentNo = "";
    let gender ="";
    let instituteName = "";
    let lastExamName = "";
    let marksObtained = "";
    let mobile = "";
    let Alternatemobileno = "";
    let pincode = "";
    let state = "";
    let createdOn = "";
    let createdBy = "";
    let updatedBy = "";
    let updatedOn = "";
    let batches =[];
    let studentInactiveModal;
    let studentAdmissionCancelModal;
    let isLoading = false;
    let inactiveReason;
    let admissionCancelReason;
    let elemBtnDropdownSettings = []
    let testCount;

    
    async function api_updateProfilePic(data) {
        let action = "studentView.updateProfilePic";
        var response = await api_handleApiCalls(action, data);
        return response;
    }
    async function api_getStudentProfile(data) {

        let action = "studentView.getStudentProfile";
        var response = await api_handleApiCalls(action, data);
        return response;
           
        }

        async function api_getTestCount(userId) {

          let action = "test.getMyTestCount";
          var response = await api_handleApiCalls(action, userId);
          return response.data;

        }
    async function api_getStudentsBatchesList(data) {

        let action = "students.getAssignedBatches";
        var response = await api_handleApiCalls(action, data);
        return response;

    }
    function getFormatedDate(date) {
        if(!date && date == '') {
            return '-'
        }
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('-');      

    }
    async function handleUpdateProfilePhoto() {
        console.log("handleUpdateProfilePhoto -> profilePic", profilePic);
        // TODO: upload logo in profile (shankar)
        if(profilePic) {
            
            let uData = {
                profilePic: profilePic,
                studentId: studentId
            }
            var response = await api_updateProfilePic(uData)
            if(response.status) {
                pSuccess("Success!", response.data)
                refreshView()
            } else {
                pError("Error!", response.errMsg)
            }
        } else {
            pError("Error!", "Failed to update the profile picture.")
        }

    }
    let fetchUserDetails = async function (studentId) {
            var studentPaymentInfo = await api_getStudentProfile({
                studentId
            })
            console.log("studentPaymentInfo : ", studentPaymentInfo)

            var studentDetails = studentPaymentInfo.data.studentsData[0];


            (studentDetails.profilePic) ? (profilePic = studentDetails.profilePic) : null;
            name = studentDetails.name ? studentDetails.name : "";
            address = studentDetails.address ? studentDetails.address : "";
            city = studentDetails.city ? studentDetails.city : "";
            dob = studentDetails.dob ? studentDetails.dob : "";
            email = studentDetails.email ? studentDetails.email : "";
            enrollmentNo = studentDetails.enrollmentNo ? studentDetails.enrollmentNo : "";
            gender = studentDetails.gender ? studentDetails.gender : "";
            instituteName = studentDetails.instituteName ? studentDetails.instituteName : "";
            lastExamName = studentDetails.lastExamName ? studentDetails.lastExamName : "";
            marksObtained = studentDetails.marksObtained ? studentDetails.marksObtained : "";
            mobile = studentDetails.mobileNo ? studentDetails.mobileNo : "";
            Alternatemobileno = studentDetails.Alternatemobileno ? studentDetails.Alternatemobileno : "";
            pincode = studentDetails.pincode ? studentDetails.pincode : "";
            state = studentDetails.state ? studentDetails.state : "";
            createdOn = studentDetails.createdOn ? getFormatedDate(studentDetails.createdOn) : "-";
            createdBy = studentDetails.createdByUserName ? studentDetails.createdByUserName : "-";
            updatedBy = studentDetails.updatedByUserName ? studentDetails.updatedByUserName : "-";
            updatedOn = studentDetails.updatedOn ? getFormatedDate(studentDetails.updatedOn) : "-";
        }
    async function getStudentBathes (id) {
        var response = await api_getStudentsBatchesList({_id: id})
        console.log("response===========================> ", response);
        if(response.status) {
            batches = response.data
        } else {
            batches = []
        }

        await tick()
    }

    async function api_makeInactive(id, inactive, inactiveReason) {
        console.log(inactiveReason);
        var data = {
            _id: id, 
            inactive: inactive,
            inactiveReason: inactiveReason,
        }
        console.log(data);
        var resData = await api_handleApiCalls('students.makeInactive', data)
        if (resData.status) {
            return resData.data
        } else {

            pError("Error", resData.errMsg)
            return '-'
        }
    }
    
    function refreshView () {
        fetchUserDetails(studentId);
    }

    async function btnActiveInactiveClickHandler(activeAction){
        
        if(activeAction){
           inactiveReason = "";

        }
        else{
            if(!inactiveReason) {
                pError("Error!", "Please Enter the Reason" )
                return
            }  
        }
        
        let res = await api_makeInactive(studentId, inactive, inactiveReason)
        if (res) {
            pSuccess("Success!", res)
            setInactiveStatus()
        }
    }

    async function btnCancelAdmissionClickHandler(cancelIt){
        
        if(!cancelIt) admissionCancelReason = "";
        

    
    


        var data = {
            id: studentId, 
            admissionCancel: cancelIt,
            admissionCancelReason: admissionCancelReason,
        }

        var resData = await api_handleApiCalls('students.cancelAdmission', data)
        console.log("admissionCancelReason ---",  resData)
        if (resData.status) {
            admissionCancel = cancelIt
            pSuccess("Success!", resData.data)
            showHideCancelAdmissionReasonModal(false)
            await tick()
            feather.replace({ width: "1em", height: "1em" });
        } else {
            pError("Error", resData.errMsg)
        }

    }

    function showHideInactiveReasonModal(action) {
        action ? (_$(studentInactiveModal).modal('show')) : (_$(studentInactiveModal).modal('hide'))
    }
    function showHideCancelAdmissionReasonModal(action) {
        action ? (_$(studentAdmissionCancelModal).modal('show')) : (_$(studentAdmissionCancelModal).modal('hide'))
    }
    
    async function setInactiveStatus() {
        inactive = await api_getStudentInactiveStatusById(studentId)
        await tick()
        feather.replace({ width: "1em", height: "1em" });
    }

    async function api_getStudentInactiveStatusById(id) {
        var resData = await api_handleApiCalls('students.getStudentInactiveStatus', { _id: id })
        if (resData.status) {
            resData = resData.data
            return resData.inactive
        } else {
            pError("Error", resData.errMsg)
            return '-'
        }
    }
    // let oldStudentId;
    // beforeUpdate(async () => {
    //     if (oldStudentId && oldStudentId != studentId) {
    //         console.log("beforeUpdate : ", oldStudentId, studentId)
    //         fetchUserDetails(studentId);
    //         getStudentBathes(studentId)
    //         testCount = await api_getTestCount(studentId);
    //     }
    //     oldStudentId = studentId;
    // });
    const unsubscribe = userData.subscribe(value => {
        roleIsTelecaller = value.role == "telecaller"
    });

    onMount(async () => {
        unsubscribe();
        feather.replace({width: "1em", height: "1em"})
        dispatch('setActiveTab', {
            tabName: 'Details'
        });
        setInactiveStatus()
        fetchUserDetails(studentId);
        getStudentBathes(studentId)
        testCount = await api_getTestCount(studentId);
    })

    function onClickClearReason(){
        admissionCancelReason =""

    }
    function clearFields() {
            inactiveReason = ""
        }


        // Must use FileSaver.js 2.0.2 because 2.0.3 has issues.
        function onClickDownloadProfilePic() {
            let img = document.getElementById('profilePic');

            let imagePath = img.getAttribute('src');
            let fileName = "Image.png"

            downloadProfilePic(imagePath, fileName);
        };

        function getFileName(str) {
            return str.substring("data:image/".length, str.indexOf(";base64"))
        }
        function downloadProfilePic(imagePath, fileName) {
            var element = document.createElement('a');
            element.setAttribute('href', imagePath);
            element.setAttribute('download', fileName);
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
       
</script>

<style>
    .media-body{
        margin: 0;
        margin-bottom: 10px;
    }
</style>

<div class="profile-sidebar profile-sidebar-two pd-lg-r-15">

    <div class="row">
        <div class="col-sm-3 col-md-2 col-lg">
            <ImageUploader bind:profilePic={profilePic} on:updatePic={handleUpdateProfilePhoto}>
                <div slot="upload-container" let:src={src} let:openCroppie={croppieOpen} let:openCamera={cameraOpen}>
                    <div id="contactAvatar" class="pos-relative float-left">
                        <div class="avatar avatar-xl"><img class="rounded-circle" src={src} alt="student-logo" id="profilePic"/></div>
                        <a href="#" on:click|preventDefault class="contact-edit-photo" data-toggle="dropdown" title="Upload Photo"><i data-feather="edit-2"></i></a>
                        <ul class="dropdown-menu filterByDropdown">
                            <li class="dropdown-item" on:click={croppieOpen}><i data-feather="image" class="mg-r-5"></i>Uplaod Image</li>
                            <li class="dropdown-item" on:click={cameraOpen}><i data-feather="camera" class="mg-r-5"></i>Take Picture</li>
                            <li class="dropdown-item" on:click={onClickDownloadProfilePic} id="buttonProfilePic"><i data-feather="download" class="mg-r-5"></i>Download Profile Photo</li>

                        </ul>
                    </div>
                </div>
            </ImageUploader>
            
        </div><!-- col -->
        <div class="col-md-12 mg-t-20 mg-sm-t-0 mg-lg-t-25">
            
            <p class="mg-b-20">
                    {#if inactive}
                        <span href="#" class="badge badge-light tx-12">
                            <i data-feather="eye-off"></i> Inactive 
                        </span>
                    {:else}
                        <span class="badge badge-dark tx-12">
                            <i data-feather="eye"></i> Activated
                        </span>
                    {/if}
            </p>
            <h5 class="mg-b-2 tx-spacing--1">{name}</h5>
            <p class="tx-color-03 mg-b-25 tx-spacing-1"><i data-feather="phone" class="mg-r-5"></i>{mobile}</p>
            <!-- <div class="d-flex mg-b-25">
                 <a use:links href="" class="btn btn-xs btn-white flex-fill mg-r-5">Payment</a> 
                <div class="btn-group">
                    <button class="btn btn-xs btn-primary flex-fill dropdown-toggle" bind:this={elemBtnDropdownSettings} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Settings
                    </button> 
                    <ul class="dropdown-menu filterByDropdown">
                        <li class="dropdown-item"><a use:links href="/dashboard/student/view/{studentId}/fees"><i data-feather="eye-off" class="mg-r-5"></i>Inactive</a></li>
                        <li class="dropdown-item"><a use:links href="fees"><i data-feather="trash" class="mg-r-5"></i>Delete</a></li>
                    </ul>
                </div>
            </div> -->

            <div class="d-flex d-none">
                <div class="profile-skillset flex-fill d-none">
                    <h4>{testCount}</h4>
                    <label>Test</label>
                </div>
                <!-- <div class="profile-skillset flex-fill">
                    <h4><a href="" class="link-01">34</a></h4>
                    <label>Videos</label>
                </div>
                <div class="profile-skillset flex-fill">
                    <h4><a href="" class="link-01">99%</a></h4>
                    <label>Attn.</label>
                </div> -->
            </div>
        </div><!-- col -->
        <div class="col-sm-6 col-md-5 col-lg mg-t-40" style="min-width: 100%;">
            <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Batches</label>
            <ul class="list-inline list-inline-skills">
                {#if batches.length > 0}
                    {#each batches as aBatch}
                        <li class="list-inline-item">{aBatch.batch.name}</li>
                    {/each}
                {:else}
                    No Batches Assigned yet
                {/if}
            </ul>
        </div><!-- col -->
        <div class="col-sm-6 col-md-5 col-lg-12 mg-t-40">
            <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Contact
                Information</label>
            <ul class="list-unstyled profile-info-list">
                <li>
                    <span class="align-self-start">
                        <i class="tx-10" data-feather="home"></i>
                    </span> 
                    <span class="tx-color-02">
                        {#if address}
                            {address},
                        {/if}

                        {#if city}
                            {city}
                        {/if}

                        {#if state}
                            , {state}
                        {/if}

                        {#if pincode}
                            , {pincode}
                        {/if}
                        {#if !address && !city && !state && !pincode}
                         -
                        {/if}
                    </span>
                </li>
                <li>
                    <span class="align-self-start">
                        <i class="tx-10" data-feather="phone"></i>
                    </span> 
                    <a href="">{mobile?mobile:'-'}</a></li>
                <li>
                    <span class="align-self-start">
                        <i class="tx-10" data-feather="phone"></i>
                    </span> 
                    <a href="">{Alternatemobileno?Alternatemobileno:'-'}</a></li>
                <li>
                    <span class="align-self-start">
                        <i class="tx-10" data-feather="mail"></i>
                    </span> 
                    <a style="overflow: hidden;" href="">{email?email:'-'}</a>
                </li>
            </ul>
        </div><!-- col -->
        <!-- <div class="col-sm-6 col-md-5 col-lg mg-t-40">
            <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Websites
                &amp; Social Channel</label>
            <ul class="list-unstyled profile-info-list">
                <li><i data-feather="globe"></i> <a href="">http://fenchiumao.me/</a></li>
                <li><i data-feather="github"></i> <a href="">@fenchiumao</a></li>
                <li><i data-feather="twitter"></i> <a href="">@fenmao</a></li>
                <li><i data-feather="instagram"></i> <a href="">@fenchiumao</a></li>
                <li><i data-feather="facebook"></i> <a href="">@fenchiumao</a></li>
            </ul>
        </div> -->
        <!-- col -->
        
        <div class="col-sm-6 col-md-5 col-lg mg-t-40">
            <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Account Summery</label>
                <div class="media">
                    <div class="media-body mg-l-15">
                        <p class="tx-medium mg-b-0">Created By</p>
                        <p class="mg-b-0 tx-11 tx-color-03 tx-medium">{createdBy}</p>
                    </div>
                </div>
                <div class="media">
                    <div class="media-body mg-l-15">
                        <p class="tx-medium mg-b-0">Created On</p>
                        <p class="mg-b-0 tx-11 tx-color-03 tx-medium">{createdOn}</p>
                    </div>
                </div>
                <div class="media">
                    <div class="media-body mg-l-15">
                        <p class="tx-medium mg-b-0">Updated By</p>
                        <p class="mg-b-0 tx-11 tx-color-03 tx-medium">{updatedBy}</p>
                    </div>
                </div>
                <div class="media">
                    <div class="media-body mg-l-15">
                        <p class="tx-medium mg-b-0">Updated On</p>
                        <p class="mg-b-0 tx-11 tx-color-03 tx-medium">{updatedOn}</p>
                    </div>
                </div>
        </div><!-- col -->
        {#if !roleIsTelecaller}
            {#if !admissionCancel}
                <div class="d-none d-md-block mg-t-20 col-12">
                    {#if MODULES.hasAccess(MODULES.DELETE_STUDENT) && MODULES.hasAccess(MODULES.UPDATE_STUDENT)}
                    {#if inactive}
                    <button on:click={()=>{btnActiveInactiveClickHandler(true)}}
                        class="btn btn-sm pd-x-15 btn-primary btn-uppercase activate">
                        <i data-feather="eye" class="wd-10 mg-r-5"></i>
                        Activate
                    </button>
        
                    {:else}
                    <button on:click={()=>{showHideInactiveReasonModal(true)}}
                        class="btn btn-xs pd-x-15 btn-primary btn-uppercase inactivate">
                        <i data-feather="eye-off" class="wd-10 mg-r-5"></i>
                        Inactive
                    </button>
                    {/if}
                    {/if}
                </div>
            {/if}
        
        {/if}

        {#if !roleIsTelecaller}
        <div class="d-none d-md-block mg-t-20 col-12">
            {#if MODULES.hasAccess(MODULES.DELETE_STUDENT) && MODULES.hasAccess(MODULES.UPDATE_STUDENT)}
            {#if admissionCancel}
            <button on:click={()=>{btnCancelAdmissionClickHandler(false)}}
                class="btn btn-sm pd-x-15 btn-primary btn-uppercase activate">
                <i data-feather="repeat" class="wd-10 mg-r-5"></i>
                Re-join
            </button>

            {:else}
            <button on:click={()=>{showHideCancelAdmissionReasonModal(true)}}
                class="btn btn-xs pd-x-15 btn-primary btn-uppercase inactivate">
                <i data-feather="x" class="wd-10 mg-r-5"></i>
                Cancel Admission
            </button>
            {/if}
            {/if}
        </div>
        {/if}
    </div><!-- row -->

</div><!-- profile-sidebar -->
<!-- Inactive reason Modal -->
<div class="modal effect-scale" bind:this={studentInactiveModal} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="eye-off" class="wd-45 ht-45"></i></span>
                    <div class="media-body mg-sm-l-20 mg-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2 mg-l-20">Inactive Reason</h4>
                        <p class="tx-13 tx-color-03 mg-b-0 mg-l-20">Enter reason to inactive this student</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <div class="form-group">
                    <label for ="reason" class="d-block">Reason<span class="required-field">*</span></label>
                    <textarea rows="4" type="text" id="reason" class="form-control" bind:value={inactiveReason}/>

                </div>

                <div class="d-md-flex justify-content-end mg-t-25">
                    <button disabled={isLoading} 
                        class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal" on:click={clearFields}>CLEAR
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button disabled={isLoading} on:click={()=>{btnActiveInactiveClickHandler(false)}} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
                        data-dismiss="modal">
                        <ButtonLoader isLoading={isLoading} btnText="DEACTIVATE" />
                        <i data-feather="eye-off" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Modal -->


<!-- Admission cancel reason Modal -->
<div class="modal effect-scale" bind:this={studentAdmissionCancelModal} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="x" class="wd-45 ht-45"></i></span>
                    <div class="media-body mg-sm-l-20 mg-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2 mg-l-20">Cancel Admission Reason</h4>
                        <p class="tx-13 tx-color-03 mg-b-0 mg-l-20">Enter reason to Cancel Admission for this student</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <div class="form-group">
                    <label for ="reason" class="d-block">Reason<span class="required-field">*</span></label>
                    <textarea rows="4" type="text" id="reason" class="form-control" bind:value={admissionCancelReason}/>
                </div>

                <div class="d-md-flex justify-content-end mg-t-25">
                    <button disabled={isLoading} 
                        class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal" on:click={onClickClearReason}>CLEAR
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button disabled={isLoading} on:click={()=>{btnCancelAdmissionClickHandler(true)}} class="btn btn-sm pd-x-15 btn-primary mg-l-5">
                        <ButtonLoader isLoading={isLoading} btnText="CANCEL ADMISSION" />
                        <i data-feather="eye-off" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Modal -->
