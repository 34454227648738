<script>
  import { onMount } from "svelte";
  import { link, links, navigate } from "svelte-routing";
  import Empty from "../empty/empty2.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";

  let elem_datatable;
  let isEmpty;
 
  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [year, month, day].join("-");
    return dateString;
  }
  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    // if(sortOrder.column === 0){
    //   sort.reportId = order;
    // }
    if (sortOrder.column === 2) {
      sort.noOfQuestionsSolved = order;
    } else if (sortOrder.column === 3) {
      sort.parentVerified = order;
      // } else if (sortOrder.column === 4) {
      //   sort.createdOn = order;
    } else {
      sort._id = -1;
    }
    return sort;
  }
  function initDatatable() {
    _$(elem_datatable).DataTable({
      // dom: 'flr<"toolbar">tip',
     
      language: {
        processing: "",
        searchPlaceholder: "Report ID",
        sSearch: "",
        lengthMenu: "_MENU_ items/page",
      },
      ajax: async function (data, callback, settings) {
        data={
          sort : parseSorts(data.order ? data.order[0] : {} ),
          start:data.start,
          length:data.length,
          search:data.search
        }
       
        delete data.order;
        // console.info(data);
        // fetch call
        let response = await api_handleApiCalls(
          "studyReport.fetchStudentStudyReportList",
          data
        );
        // console.info({ response });
        if (response.status) {
          var paginationData = response.data;
          var result = {
            recordsTotal: Number(paginationData.recordsTotal),
            recordsFiltered: Number(paginationData.recordsFiltered),
            data: paginationData.data,
          };
          if (!paginationData.data.length) {
                isEmpty = true;
              } 
              else isEmpty = false;
          // isEmpty = !Boolean(response.data.length);
          callback(result);
        } else {
          console.error("something went wrong");
          var result = {
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          };
          isEmpty = true;
          callback(result);
        }
      },
      columns: [
        { data: "reportId" },
        {
          data: "folders",
          orderable: false,
          render: function (data, type, full) {
            if (!data) return "-";
            var htmlStr = `<div class="media ">
                        <div class="media-body mg-l-10">
                            <h6 class="tx-13 mg-b-3" style="text-transform: capitalize;">${data
                              .map((item) => item.name)
                              .join(" > ")}</span>
                        </div><!-- media-body -->
                    </div>`;
            return htmlStr;
          },
        },
        { data: "noOfQuestionsSolved" },
        {
          data: "parentVerified",
          render: (data) =>
            `<div style="text-transform:capitalize; text-align:center;">${
              data ? "Yes" : "No"
            }</div>`,
          defaultContent: false,
        },
        // { data: "createdOn", render: (data) => convertDateObjToStr(data) },
        {
          data: "_id",
          orderable: false,
          render: (data, a, b, c) => {
            // console.table({ data, a, b, c });
            return `
                  <span>
                    <a href="javascript:void(0)" data-id="${data}" class='btn-view mg-r-5' data-toggle="tooltip" data-placement="bottom" title="Edit"><i data-feather="eye" ></i> </a>
                    <!--a href="javascript:void(0)" class='btn-archive mg-r-5' data-id='${data}' data-toggle="tooltip" data-placement="bottom" title="Delete"><i data-feather="trash" ></i> </a-->
                  </span>
                    `;
          },
        },
      ],
      // columnDefs: [
      //   { targets: 0, width: "12%" },
      //   { targets: 1, width: "8%" },
      //   { targets: 2, width: "8%" },
      //   { targets: 3, width: "40%" },
      //   { targets: 4, width: "12%" },
      //   { targets: 5, width: "8%" },
      //   { targets: 6, width: "12%" },
      // ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        _$(".btn-view").on("click", function (event) {
          let id = _$(this).data("id");
          // _$(this).tooltip("dispose");
          let route = `/dashboard/reports/study/${id}`;
          navigate(route);
        });
        // _$(".btn-archive").on("click", async function (event) {
        //   let id = _$(this).data("id");
        //   // _$(this).tooltip("dispose");
        //   pConfirm(
        //     "Delete user?",
        //     "Are you sure you want to delete this user?",
        //     async function () {
        //       console.info("Deleting");
        //       //   delete api_handleApiCalls
        //       let result = await api_delete({ _id: Id });
        //       if (result.status) {
        //         // reload table
        //         pSuccess("", result.data);
        //         reload();
        //       } else {
        //         pError("", result.errMsg);
        //       }
        //     }
        //   );
        // });
        _$("#table tbody").on("click", "tr td", function (e) {
          // responsive expand button clicked
          feather.replace({ width: "1em", height: "1em" });
          _$(".btn-view").text("View");
          _$(".btn-archive").text("Archive");
        });
        // _$('[data-toggle="tooltip"]').tooltip();
      },
      serverSide: true,
      responsive: false,
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }
  onMount(function () {
    feather.replace();
    initDatatable();
  });
</script>

<div class="content-body animated scale">
  <!-- breadcrumbs -->
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Study Report
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Study Report</h4>
    </div>
    <div class="d-inline-block">
      <a
        href="/dashboard/reports/study/new"
        class="btn pd-x-15 btn-primary btn-uppercase mg-l-5 btn-sm"
        use:link><i data-feather="plus" class="wd-10 mg-r-5" />
        Submit New Report
      </a>
    </div>
  </div>
  <div data-label="Example" class="df-example demo-table">
    <table bind:this={elem_datatable} class="table" style="width:100%">
      <thead>
        <tr>
          <th>Report ID</th>
          <th>Category</th>
          <th>No of Questions Solved</th>
          <th>Parent Verified</th>
          <!-- <th>Submitted On</th> -->
          <th>Actions</th>
        </tr>
      </thead>
      <tbody />
    </table>
    {#if isEmpty}
      <Empty />
    {/if}
  </div>
</div>
