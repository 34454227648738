<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import moment from "moment";
  import { activeMenu, userData } from "../../stores";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import {
    pConfirm,
    pSuccess,
    pError,
    pAlert,
  } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js";
  import Forbidden from "../../forbidden.svelte";
  import ExcelJS from "exceljs";
  import { API_URL } from "../../../config.js";

  export let searchQuery;
  let demoDataTable;
  let notdeletedStudentDataTable;
  let studentsDataTableInstance;
  let studentDataTable;
  let deletestudents = [];
  let removeduplicateid = [];
  let globaldata = [];
  let allSelectedStudents = new Set();
  $: deleteStudentCount = allSelectedStudents.size;
  $: inactivateCount = allSelectedStudents.size;
  let modalConvertEnquiry;
  let studentName, mobileno;
  let Alternatemobileno;
  let roleIsTelecaller;
  let studentsImportDataTable;
  let gender, actInact;
  let elem_studentsImportTable;
  let importStudentDataAfterUpload;
  let importAssignBatchDataAfterUpload;
  let elem_uploadFile;
  let orgId;
  let modalStudentsImportTable;
  let parents;
  let channelId;
  let search = { value: "" };
  let importLoading = false;
  let deleteLoading = false;
  let batchesList = [];
  let usersListForGroup = [];
  let filterModalThis;
  let filterElemBatch;
  let filterElemBoard;
  let filterByParent;
  let filterElemUser;
  let rollNoPrefix;
  let filterCreatedOn;
  let selectizeInstanceClass;
  let selectizeBatchInstance;
  let selectizeBoardInstance;
  let selectizeParentInstance;
  let selectizeUserlorInstance;
  let selectizeGroups;
  let elemSelectClass;
  let elemSelectGroup;
  let errorObj = {};
  let _errorObj = {};
  let isLoading = false;
  let emptyState = false;
  let enquiryObj;
  let addDemoBatch = true;
  let elemDemoBatchesSelect;
  let selectizeDemoBatches;
  let nameStr = "";
  let studentList = [];
  let checked = false;
  let selectedIdArr = new Set();
  let tempDisplayList = new Set();
  let modalEle;
  let notDeletedStudentData = [];
  async function api_createNewStudent(data) {
    var response = await api_handleApiCalls("students.create", data);

    if (response.status) {
      if (response.data.available) {
        enquiryObj = response.data.data;
        await tick();
        feather.replace({ width: "1em", height: "1em" });
      } else {
        _$("#modal-new-student").modal("hide");
        clearFields();
        navigate("/dashboard/student/view/" + response.data._id);
      }
    } else {
      errorObj.apiError = response.errMsg;
    }
  }
  function clearFields() {
    studentName = "";
    mobileno = "";
    Alternatemobileno = "";
    _$(elemSelectClass)[0].selectize.clear();
    // _$(selectizeDemoBatches).clear()
  }
  async function api_convertStudent(data) {
    var response = await api_handleApiCalls("students.create", data);

    if (response.status) {
      _$(modalConvertEnquiry).modal("hide");
      navigate("/dashboard/student/view/" + response.data._id);
    } else {
      pError("Error!", response.errMsg);
      _errorObj.apiError = response.errMsg;
    }
  }
  async function api_exportStudents(isTemplete, filterObj, selectedStudents) {
    importLoading = true;
    var response = await api_handleApiCalls("students.studentExport", {
      isTemplate: isTemplete,
      filter: filterObj,
      selected: selectedStudents,
    });
    importLoading = false;
    if (response.status) {
      return response.data;
    } else {
      pError("Error!", response.errMsg);
      return null;
    }
  }

  async function generateWorkBook(
    isTemplate,
    studentsReportList,
    assignedBatchList,
  ) {
    console.log("---------------2------");

    const workbook = new ExcelJS.Workbook();
    const studentDataWorkSheet = workbook.addWorksheet("Students Data", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });
    const assignedbatchDataWorkSheet = workbook.addWorksheet(
      "Assign Batch Data",
      {
        pageSetup: { paperSize: 9, orientation: "landscape" },
      },
    );
    let fileName = "";
    if (isTemplate) {
      fileName = "Students_Import_Template.xlsx";
    } else {
      fileName = "Export_Students_Data_" + new Date().getTime() + ".xlsx";
    }
    console.log("---------------3------");

    let columnsArr = [
      { header: "Sr no", key: "serialNo", width: 20 },
      { header: "Student Id", key: "studentId", width: 20 },
      { header: "Name", key: "name", width: 15 },
      { header: "Mobile Number", key: "mobileNo", width: 20 },
      { header: "Email", key: "email", width: 20 },
      { header: "Password", key: "password", width: 20 },
      { header: "Gender", key: "gender", width: 20 },
      { header: "DOB", key: "dob", width: 20 },
      { header: "Address", key: "address", width: 20 },
      { header: "State", key: "state", width: 20 },
      { header: "City", key: "city", width: 20 },
      { header: "Pincode", key: "pincode", width: 20 },
      { header: "Enrollment No", key: "enrollmentNo", width: 20 },
      { header: "Institute Name", key: "iName", width: 20 },
      { header: "Board", key: "status", width: 20 },
      { header: "Last conducted exam", key: "exam", width: 20 },
      { header: "Marks", key: "marks", width: 20 },
      { header: "Parents", key: "parents", width: 20 },
      { header: "Active", key: "active", width: 20 },
      { header: "Assign Batch Ref no", key: "batchRef", width: 20 },
      { header: "Convert Enquiry If Found", key: "enquiry", width: 20 },
      // { header: "Details", key: "details", width: 20 },
    ];
    console.log("---------------4------");

    let columnsBatchArr = [
      { header: "Ref no", key: "refNo", width: 20 },
      { header: "Batch Code", key: "batchCode", width: 15 },
      { header: "Subjects", key: "subjects", width: 60 },
      { header: "Agreed Fees", key: "agreedFees", width: 20 },
      { header: "Remark", key: "remark", width: 20 },
      { header: "Student Joining Date", key: "studentJoiningDate", width: 20 },
      // { header: "Details", key: "details", width: 20 },
    ];
    console.log("---------------5------");

    studentDataWorkSheet.columns = columnsArr;
    assignedbatchDataWorkSheet.columns = columnsBatchArr;

    for (let idx = 0; idx < studentsReportList.length; idx++) {
      let studentDataArr = [];
      let studentDataResult = studentsReportList[idx];
      studentDataArr.push(studentDataResult.srNo ? studentDataResult.srNo : "");
      studentDataArr.push(
        studentDataResult.studentId ? studentDataResult.studentId : "",
      );
      studentDataArr.push(studentDataResult.name ? studentDataResult.name : "");
      studentDataArr.push(
        studentDataResult.mobileNumber ? studentDataResult.mobileNumber : "",
      );
      studentDataArr.push(
        studentDataResult.email ? studentDataResult.email : "",
      );
      studentDataArr.push(
        studentDataResult.password ? studentDataResult.password : "",
      );
      studentDataArr.push(
        studentDataResult.gender ? studentDataResult.gender : "",
      );
      studentDataArr.push(studentDataResult.DOB ? studentDataResult.DOB : "");
      studentDataArr.push(
        studentDataResult.address ? studentDataResult.address : "",
      );
      studentDataArr.push(
        studentDataResult.state ? studentDataResult.state : "",
      );
      studentDataArr.push(
        studentDataResult.state
          ? studentDataResult.city
            ? studentDataResult.city
            : ""
          : "",
      );

      studentDataArr.push(
        studentDataResult.pincode ? studentDataResult.pincode : "",
      );
      studentDataArr.push(
        studentDataResult.enrollmentNo ? studentDataResult.enrollmentNo : "",
      );
      studentDataArr.push(
        studentDataResult.instituteName ? studentDataResult.instituteName : "",
      );
      studentDataArr.push(
        studentDataResult.board ? studentDataResult.board : "",
      );

      studentDataArr.push(
        studentDataResult.lastConductedExam
          ? studentDataResult.lastConductedExam
          : "",
      );
      studentDataArr.push(
        studentDataResult.marks ? studentDataResult.marks : "",
      );
      studentDataArr.push(
        studentDataResult.parents ? studentDataResult.parents : "",
      );
      studentDataArr.push(
        studentDataResult.active ? studentDataResult.active : "",
      );
      studentDataArr.push(
        studentDataResult.assignBatchRefNo
          ? studentDataResult.assignBatchRefNo
          : "",
      );
      studentDataArr.push(
        studentDataResult.convertEnquiryIfFound
          ? studentDataResult.convertEnquiryIfFound
          : "",
      );
      studentDataWorkSheet.addRow(studentDataArr);
    }
    console.log("---------------6------");

    for (let idx = 0; idx < assignedBatchList.length; idx++) {
      let assignedBatchDataArr = [];
      let assignedBatchDataResult = assignedBatchList[idx];
      let studentJoiningDate = moment(
        new Date(assignedBatchDataResult.studentJoiningDate),
      ).format("DD/MM/YYYY");

      assignedBatchDataArr.push(
        assignedBatchDataResult.refNo ? assignedBatchDataResult.refNo : "",
      );
      assignedBatchDataArr.push(
        assignedBatchDataResult.batchCode
          ? assignedBatchDataResult.batchCode
          : "",
      );
      assignedBatchDataArr.push(
        assignedBatchDataResult.subjects
          ? assignedBatchDataResult.subjects
          : "",
      );
      assignedBatchDataArr.push(
        assignedBatchDataResult.agreedFees
          ? assignedBatchDataResult.agreedFees
          : "",
      );
      assignedBatchDataArr.push(
        assignedBatchDataResult.remark ? assignedBatchDataResult.remark : "",
      );
      assignedBatchDataArr.push(studentJoiningDate ? studentJoiningDate : "");
      assignedbatchDataWorkSheet.addRow(assignedBatchDataArr);
    }
    console.log("---------------7------");

    const buffer = await workbook.xlsx.writeBuffer();
    console.log("---------------8-----");

    saveFile(
      new Blob([buffer], { type: "application/octet-stream" }),
      fileName,
    );
    console.log("---------------9------");

    //   await workbook.xlsx.writeFile(fileName);
  }
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    // var view = new Uint8Array(buf);
    var view = s;
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const saveFile = async (blob, fileName) => {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };
  function uniqueId() {
    // always start with a letter (for DOM friendlyness)
    var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
    do {
      // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
      var ascicode = Math.floor(Math.random() * 42 + 48);
      if (ascicode < 58 || ascicode > 64) {
        // exclude all chars between : (58) and @ (64)
        idstr += String.fromCharCode(ascicode);
      }
    } while (idstr.length < 32);

    return idstr.toLowerCase();
  }

  async function makeInactive(ids) {
    var resData = await api_handleApiCalls(
      "students.makemultipleStudentInative",
      { _id: ids },
    );
    if (resData.status) {
      pSuccess("", resData.data);
      redrawStudentTable();
      allSelectedStudents = new Set();
      return resData.inactive;
    } else {
      pError("", resData.errMsg);

      allSelectedStudents = new Set();
      return "-";
    }
  }
  async function deleteStudents(ids) {
    deleteLoading = true;

    var resData = await api_handleApiCalls("students.deleteMultipleStudents", {
      studentIdArr: ids,
    });

    if (resData.status) {
      pSuccess("", resData.data.msg);
      deleteLoading = false;

      if (resData.data.data && resData.data.data.length) {
        notDeletedStudentData = resData.data.data;
        _$(modalEle).modal("show");
        bindStudentsDatatable(notDeletedStudentData);
      } else _$(modalEle).modal("hide");
      redrawStudentTable();
      allSelectedStudents = new Set();
      return resData.inactive;
    } else {
      pError("", resData.errMsg);
      deleteLoading = false;

      allSelectedStudents = new Set();
      return "-";
    }
    deleteLoading = false;
  }
  // let addSubToStudent = async (e) => {

  //  pConfirm(`Are you sure you want to delete${globaldata.length} student?`)
  //                         await makeInactive(globaldata)

  function addSubToStudent() {
    pConfirm(
      "Inactive Student",
      `Are you sure you want to Inactive ${allSelectedStudents.size} student?`,
      function () {
        makeInactive(Array.from(allSelectedStudents));
        _$('input[type="checkbox"]').prop("checked", false);
      },
    );
  }
  function deleteMultipleStudent() {
    pConfirm(
      "Delete Students",
      `Are you sure you want to Delete ${allSelectedStudents.size} students?`,
      function () {
        deleteStudents(Array.from(allSelectedStudents));
        _$('input[type="checkbox"]').prop("checked", false);
      },
    );
  }
  async function getStudentValues() {
    var newErrorObj = {};
    const MOBILENORGEX = RegExp(/^[6-9][0-9]{9}$/);
    const NAMEREGEX = RegExp(/^[a-zA-Z ]+$/);

    let demoBatchId = addDemoBatch ? _$(selectizeDemoBatches).val() : null;
    let batchClass = _$(elemSelectClass)[0].selectize.getValue();
    if (!Boolean(studentName)) {
      newErrorObj.studentName = "Please Enter Full Name.";
    }

    if (!Boolean(mobileno)) {
      newErrorObj.mobileno = "Please Enter Mobile Number";
    }

    if (!Boolean(batchClass)) {
      newErrorObj.batchClass = "Please select the class";
    }

    if (!NAMEREGEX.test(studentName)) {
      newErrorObj.studentName = "Enter Valid Full Name";
    }

    if (!MOBILENORGEX.test(mobileno)) {
      newErrorObj.mobileno = "Please Enter 10 Digit Valid Mobile Number";
    }
    if (Alternatemobileno) {
      if (!MOBILENORGEX.test(Alternatemobileno)) {
        newErrorObj.Alternatemobileno =
          "Please Enter 10 Digit Valid Mobile Number";
      }
    }

    if (addDemoBatch && !demoBatchId) {
      newErrorObj.demoBatchId = "Please select default demo batch";
    }

    errorObj = newErrorObj;
    if (Object.keys(errorObj).length > 0) {
      return null;
    }

    return {
      name: studentName,
      mobileNo: mobileno,
      Alternatemobileno: Alternatemobileno,
      demoBatchId: demoBatchId,
      classId: batchClass,
    };
  }

  function getValidateEnquiryData(data) {
    if (!data._id || !Boolean(data._id)) {
      pError("Error!", "Enquiry id not found, please try again");
      return null;
    }

    let demoBatchId = addDemoBatch ? _$(selectizeDemoBatches).val() : null;

    if (addDemoBatch && !demoBatchId) {
      pError(
        "Error!",
        "No demo batch selected, Make sure you have selected a default demo batch",
      );
      return null;
    }

    return { enquiryId: data._id, demoBatchId: demoBatchId };
  }
  function proccedConvertEnquiry() {
    // navigate(("students/info/archive-enquiry/" + enquiryId))

    let enquiryData = getValidateEnquiryData(enquiryObj);
    if (enquiryData) {
      api_convertStudent(enquiryData);
    }
  }
  function handleClickConvert(e) {
    e.preventDefault();
    _$("#modal-new-student").modal("hide");
    _$(modalConvertEnquiry).modal("show");
  }
  var btnExportStudents = async () => {
    importLoading = true;
    var timetaken = "Time taken by addCount function";
    console.time(timetaken);

    let response = await api_exportStudents();
    console.timeEnd(timetaken);

    importLoading = false;

    if (response) {
      let reportsArr = response.reportsArr;
      let assignBatchSheetArr = response.assignBatchSheetArr;

      if (reportsArr && assignBatchSheetArr) {
        console.time();

        generateWorkBook(false, reportsArr, assignBatchSheetArr);
        console.timeEnd();
      }
    }
  };
  var btnExportFilteredStudents = async () => {
    importLoading = true;
    var filterObj = { ...getFilterData() };
    let response = await api_exportStudents(false, filterObj);

    importLoading = false;
    if (response) {
      let reportsArr = response.reportsArr;
      let assignBatchSheetArr = response.assignBatchSheetArr;

      if (reportsArr && assignBatchSheetArr) {
        generateWorkBook(false, reportsArr, assignBatchSheetArr);
      }
    }
  };

  var btnExportSelectedStudents = async () => {
    if (allSelectedStudents.size == 0) {
      return pAlert("Warning", "Select Students First");
    } else {
      importLoading = true;

      let selectedStudents = Array.from(allSelectedStudents);
      let response = await api_exportStudents(false, {}, selectedStudents);
      importLoading = false;
      if (response) {
        let reportsArr = response.reportsArr;
        let assignBatchSheetArr = response.assignBatchSheetArr;
        console.log("---------------1------");
        if (reportsArr && assignBatchSheetArr) {
          generateWorkBook(false, reportsArr, assignBatchSheetArr);
        }
      }
    }
  };

  var btnExportStudentsTemplete = async () => {
    importLoading = true;
    let response = await api_exportStudents(true);
    importLoading = false;
    if (response) {
      let reportsArr = response.reportsArr;
      let assignBatchSheetArr = response.assignBatchSheetArr;

      if (reportsArr && assignBatchSheetArr) {
        generateWorkBook(true, reportsArr, assignBatchSheetArr);
      }
    }
  };
  function showHideImportStudentModal(isShow) {
    isShow
      ? _$(modalStudentsImportTable).modal("show")
      : _$(modalStudentsImportTable).modal("hide");
  }
  function redrawStudentTable() {
    if (studentDataTable) {
      studentDataTable.draw();
    }
  }
  async function handleClickProceed() {
    var studentData = await getStudentValues();

    if (studentData) {
      api_createNewStudent(studentData);
    }
  }

  async function api_getBatchesList() {
    var resData = await api_handleApiCalls("batch.getMyBatches", {});
    if (resData.status) {
      return resData.data;
    } else {
      return [];
    }
  }

  const makeCheckAll = (all) => {
    _$(".select-student")
      .toArray()
      .map((element) => {
        let elem_checkbox = _$(element);
        let studentId = elem_checkbox.attr("student-id");
        if (all) elem_checkbox.prop("checked", true);
        else elem_checkbox.prop("checked", false);
      });
  };

  const checkIfAllSelected = () => {
    let selectedArr = _$(".select-student")
      .toArray()
      .filter((element) => {
        let elem_checkbox = _$(element);
        let studentId = elem_checkbox.attr("student-id");
        return allSelectedStudents.has(studentId);
      });

    if (selectedArr.length == studentList.length && studentList.length != 0)
      _$("#select_all_student").prop("checked", true);
    else _$("#select_all_student").prop("checked", false);
  };
  const selectAllUnselect = async (checked, selectedArr) => {
    if (checked) {
      selectedArr.forEach((obj) => allSelectedStudents.add(obj._id));
    } else {
      selectedArr.forEach((obj) => allSelectedStudents.delete(obj._id));
    }
    allSelectedStudents = allSelectedStudents;
    await tick();
    makeCheckAll(checked);
  };
  async function selectstudent(id, checked) {
    if (checked) {
      allSelectedStudents.add(id);
    } else {
      allSelectedStudents.delete(id);
    }
    _$("#select_all_student").prop("checked", false);

    allSelectedStudents = allSelectedStudents;
    await tick();

    // if (studentinfo.status == "true") {
    //   deletestudents.push(studentinfo.id);
    // } else {
    //   var index = deletestudents.findIndex(function (o) {
    //     return o == studentinfo.id;
    //   });
    //   if (index !== -1) deletestudents.splice(index, 1);

    //   // deletestudents.remove(studentinfo.id)
    //   // alert("removed")
    // }

    // removeduplicateid = new Set(deletestudents);
    // globaldata = [...removeduplicateid];
  }

  function clearFilter() {
    if (searchQuery) {
      navigate("/dashboard/students");
    } else {
      filterCreatedOn = null;
      selectizeBatchInstance[0].selectize.clear();
      selectizeBoardInstance[0].selectize.clear();
      gender = null;
      parents = null;
      rollNoPrefix = null;
      actInact = null;
      redrawStudentTable();
    }
  }

  async function api_getClassList(data) {
    var resData = await api_handleApiCalls(
      "instituteClass.fetchInstituteClasses",
      data,
    );
    if (resData.status) {
      return resData.data;
    } else {
      return [];
    }
  }

  async function deleteStudent(id) {
    var response = await api_handleApiCalls("students.delete", { _id: id });

    if (response.status) {
      if (studentDataTable) {
        studentDataTable.draw();
      }
      pSuccess(null, response.data);
      // let isFound = allSelectedStudents.has(id)
      if (allSelectedStudents.has(id)) {
        allSelectedStudents.delete(id);
      }
      allSelectedStudents = allSelectedStudents;
    } else {
      pError("Error", response.errMsg);
    }
  }
  let getDemoBatches = async function (batchClass) {
    var response = await api_handleApiCalls("batch.getDemoBatches", {
      batchClass: batchClass,
    });
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
  let getBoards = async function () {
    var response = await api_handleApiCalls("dropdown.getBoardList", {});
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };

  async function navigatearchivelist() {
    navigate("/dashboard/students/archived");
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.name = order;
    } else if (sortOrder.column === 2) {
      sort.enrollmentNo = order;
    } else if (sortOrder.column === 4) {
      sort.createdOn = order;
    } else {
      sort._id = order;
    }
    return sort;
  }

  function bindStudentsDatatable(data) {
    if (studentsDataTableInstance) studentsDataTableInstance.destroy();
    studentsDataTableInstance = _$(notdeletedStudentDataTable).DataTable({
      language: {
        processing: "Loading...",
        // searchPlaceholder: 'Search question',
        // sSearch: '',
      },
      searching: false,
      paging: true,
      info: false,
      lengthChange: false,
      data: data,
      columns: [
        {
          data: "name",
          defaultContent: "-",
          render: (data, type, full) => {
            let image;
            // if image found display profilePic avatar
            image = `<img src="${
              full.profilePic ? full.profilePic : "/images/man.png"
            }" class="avatar mg-r-15" alt="${data[0].toUpperCase()}">`;
            return `
                          <div style = "cursor:pointer" class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 " student-id="${
                            full._id
                          }">
                            ${image}
                            <div class="wd-80p bg-transparent">
                              <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent  tx-capitalize" student-id="${
                                full._id
                              }">${data || "-"} </h6>
                          </div>`;
          },
        },
        {
          data: "email",
          defaultContent: "-",
          render: (data, type, full) => {
            if (!data) {
              data = "-";
              return `
                 <div style="text-align:center;">
                  ${data}
                  </div>
                `;
            }
            return `
                <div style="text-align:center;">
                 ${data}
                </div>
                `;
          },
        },
        {
          data: "mobileNo",
          defaultContent: "-",
          render: (data, type, full) => {
            if (!data) {
              data = "-";
              return `
                 <div style="text-align:center;">
                  ${data}
                  </div>
                `;
            }
            return `
                <div style="text-align:center;">
                 ${data}
                </div>
                `;
          },
        },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
      },
    });
  }

  function bindDatatable() {
    studentDataTable = _$(demoDataTable).DataTable({
      stateSave: true,
      processing: true,
      serverSide: true,
      responsive: false,
      select: true,

      stateSaveCallback: function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data));
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname));
      },
      columnDefs: [{ orderable: false, targets: [0] }],
      language: {
        processing:
          '<i class="fa fa-spinner text-dark fa-spin fa-2x fa-fw"></i><span class="sr-only">Loading...</span> ',
        searchPlaceholder: "Student Name | Number",
        sSearch: "",
        lengthMenu: "_MENU_ Students/page",
      },
      ajax: function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        var filterObj = { ...getFilterData() };
        if (searchQuery) {
          let searchBatches = [];
          try {
            searchBatches = JSON.parse(searchQuery).batches;
            filterObj.batches = searchBatches;
          } catch (error) {}
        }
        filterObj.start = data.start;
        filterObj.length = data.length;
        filterObj.search = data.search.value;
        filterObj.draw = data.draw;
        filterObj.sort = data.sort;
        _$.ajax({
          url: API_URL,
          method: "post",
          data: {
            action: "students.list",
            accessToken: localStorage.getItem("accessToken"),
            data: filterObj,
          },
          success: async function (resData) {
            var result = [];
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data,
              };
              if (!resData.data.length) {
                emptyState = true;
              }
              studentList = paginationData.data;
              studentList = studentList;
              await tick();
              callback(result);
            } else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true;
          },
        });
      },
      columns: [
        {
          data: "_id",

          render: (data, type, full) => {
            if (allSelectedStudents && allSelectedStudents.has(data)) {
              return `
                        <input type="checkbox" checked class="select-student itsSelected" student-id="${data}">
                `;
            } else {
              return `
                        <input type="checkbox" class="select-student" student-id="${data}">
                `;
            }
          },
        },

        {
          data: "name",
          defaultContent: "-",
          render: (data, type, full) => {
            let image;
            // if image found display profilePic avatar
            image = `<img src="${
              full.profilePic ? full.profilePic : "/images/man.png"
            }" class="avatar mg-r-15" alt="${data[0].toUpperCase()}">`;
            if (MODULES.hasAccess(MODULES.VIEW_STUDENT_DETAILS)) {
              return `
                          <div style = "cursor:pointer" class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 btn-edit-student" student-id="${
                            full._id
                          }">
                            ${image}
                            <div class="wd-80p bg-transparent">
                              <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent btn-edit-student tx-capitalize" student-id="${
                                full._id
                              }">${data || "-"} </h6>
                              <span class="d-block tx-11 text-muted bg-transparent">
                                  <i data-feather="phone"></i>
                                  ${full.mobileNo || "-"}
                              </span>
                              <span>
                                  ${
                                    full.inactive == false
                                      ? `<span style="height: 10px;
                width: 10px;
                background-color: #449D44;
                border-radius: 50%;
                display: inline-block;"></span><span style="margin-left:1px;width:1px,height:1px">active</span>`
                                      : `<span style="height: 10px;
                width: 10px;
                background-color: #D9534F;
                border-radius: 50%;
                display: inline-block;"></span><span style="margin-left:1px;width:1px,height:1px">Inactive</span>`
                                  }</span>
                            </div>
                          </div>`;
            } else {
              return `
                          <div style = "cursor:pointer" class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 " student-id="${
                            full._id
                          }">
                            ${image}
                            <div class="wd-80p bg-transparent">
                              <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent  tx-capitalize" student-id="${
                                full._id
                              }">${data || "-"} </h6>
                              <span class="d-block tx-11 text-muted bg-transparent">
                                  <i data-feather="phone"></i>
                                  ${full.mobileNo || "-"}
                              </span>
                              <span>
                                  ${
                                    full.inactive == false
                                      ? `<span style="height: 10px;
                width: 10px;
                background-color: #449D44;
                border-radius: 50%;
                display: inline-block;"></span><span style="margin-left:1px;width:1px,height:1px">active</span>`
                                      : `<span style="height: 10px;
                width: 10px;
                background-color: #D9534F;
                border-radius: 50%;
                display: inline-block;"></span><span style="margin-left:1px;width:1px,height:1px">Inactive</span>`
                                  }</span>
                            </div>
                          </div>`;
            }
          },
        },

        {
          data: "enrollmentNo",
          defaultContent: "-",
          render: (data, type, full) => {
            if (!data) {
              data = "-";
              return `
                 <div style="text-align:center;">
                  ${data}
                  </div>
                `;
            }
            return `
                <div style="text-align:center;">
                 ${data}
                </div>
                `;
          },
        },
        {
          data: "batchList",
          orderable: false,
          defaultContent: "-",
          render: (data, type, full) => {
            var htmlStr = "";
            for (let j = 0; j < full.batchList.length; j++) {
              let batchName = full.batchList[j];
              htmlStr += `
                    <div class="badge badge-secondary tx-capitalize" style="font-size:12px;margin-right:4px;margin-bottom:4px; text-align: center;">${batchName}</div>
                    `;
            }
            return htmlStr.length ? htmlStr : "-";
          },
        },
        {
          data: "createdOn",
          render: function (data, a, b, c) {
            let d = new Date(data);
            let date = moment(d).format("DD-MM-YYYY");

            return `<div style="text-align: center; ">${date}</div>`;
          },
        },
        {
          data: "_id",
          render: function (data, a, b, c) {
            var view = "";
            var archive = "";
            if (
              MODULES.hasAccess(MODULES.VIEW_STUDENT_DETAILS) &&
              !MODULES.hasAccess(MODULES.DELETE_STUDENT)
            ) {
              view = `<a href="#" data-toggle="tooltip" data-placement="top" title="Student Edit" class="rename btn-edit-student mg-r-5" student-id="${data}"><i data-feather="edit" class="wd-20 mg-r-5"></i></a>`;
            } else if (
              MODULES.hasAccess(MODULES.DELETE_STUDENT) &&
              !MODULES.hasAccess(MODULES.VIEW_STUDENT_DETAILS)
            ) {
              archive = `<a href="#" data-toggle="tooltip" data-placement="top" title="Remove Student" class="delete btn-remove-student mg-r-5" student-id="${data}"><i data-feather="trash" class="wd-20 mg-r-5"></i></a>`;
            } else if (
              !MODULES.hasAccess(MODULES.DELETE_STUDENT) &&
              !MODULES.hasAccess(MODULES.VIEW_STUDENT_DETAILS)
            ) {
              view = "";
              archive = "";
            } else {
              view = `<a href="#" data-toggle="tooltip" data-placement="top" title="Student Edit" class="rename btn-edit-student mg-r-5" student-id="${data}"><i data-feather="edit" class="wd-20 mg-r-5"></i></a>`;
              archive = `<a href="#" data-toggle="tooltip" data-placement="top" title="Remove Student" class="delete btn-remove-student mg-r-5" student-id="${data}"><i data-feather="trash" class="wd-20 mg-r-5"></i></a>`;
            }
            return `
                            <div class="d-md-flex flex-row justify-content-center">
                                ${view}
                                ${archive}
                            </div>
                        `;
          },
        },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        checkIfAllSelected();
        _$(".btn-edit-student")
          .unbind("click")
          .click(function (e) {
            e.preventDefault();

            var studentId = _$(this).attr("student-id");
            navigate("/dashboard/student/view/" + studentId);
          });
        _$(".btn-remove-student")
          .unbind("click")
          .click(function (e) {
            if (roleIsTelecaller) {
              return;
            }
            e.preventDefault();
            var studentId = _$(this).attr("student-id");

            pConfirm(
              "Delete Student",
              "Are you sure you want to delete student",
              function () {
                deleteStudent(studentId);
              },
            );
          });

        _$(".select-student")
          .unbind("click")
          .click(function (e) {
            var studentId = _$(this).attr("student-id");
            var checked = e.target.checked;
            selectstudent(studentId, checked);
          });

        _$("#select_all_student")
          .unbind("click")
          .click(function (e) {
            e.stopPropagation();
            selectAllUnselect(e.target.checked, studentList);
          });
      },
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }

  function bindEvents() {
    // show modal with effect
    _$("#modal-new-student").on("show.bs.modal", function (event) {
      feather.replace({ width: "1em", height: "1em" });
      var animation = _$(event.relatedTarget).data("animation");
      _$(this).addClass(animation);
    });

    // hide modal with effect
    _$("#modal-new-student").on("hidden.bs.modal", function (e) {
      errorObj = {};
      _$(this).removeClass(function (index, className) {
        return (className.match(/(^|\s)effect-\S+/g) || []).join(" ");
      });
    });
  }

  function showHideFilterModal(action) {
    action
      ? _$(filterModalThis).modal("show")
      : _$(filterModalThis).modal("hide");
  }
  function valiateStudentsImportSheetHeaders(sheetDataArr, headers) {
    let isValid = true;
    let extraColumnFound = false;
    let missingColumns = false;
    let errMsgForMissingColumn = [];
    let errMsgForExtraColumn = [];

    let sheetCorrectColumns = {
      "Sr no": { found: true },
      "Student Id": { found: true },
      Name: { found: true },
      "Mobile Number": { found: true },
      Email: { found: true },
      Password: { found: true },
      Gender: { found: true },
      DOB: { found: true },

      Address: { found: true },
      State: { found: true },
      City: { found: true },
      Pincode: { found: true },

      "Enrollment No": { found: true },
      "Institute Name": { found: true },
      Board: { found: true },
      "Last conducted exam": { found: true },
      Marks: { found: true },
      Parents: { found: true },
      Active: { found: true },
      "Assign Batch Ref no": { found: true },
      "Convert Enquiry If Found": { found: true },
    };

    if (Array.isArray(headers) && headers.length) {
      let sheetObjHeaders = headers;
      for (let index = 0; index < sheetObjHeaders.length; index++) {
        const aColumn = sheetObjHeaders[index];

        if (!sheetCorrectColumns[aColumn] && aColumn != "Details") {
          errMsgForExtraColumn.push(aColumn);
          isValid = false;
        }
      }

      for (let aColumn in sheetCorrectColumns) {
        if (sheetObjHeaders.indexOf(aColumn) == -1) {
          errMsgForMissingColumn.push(aColumn);
          isValid = false;
        }
      }
    } else {
      isValid = false;
    }

    let validationErrorMsg;
    if (errMsgForMissingColumn.length) {
      let msgStr =
        errMsgForMissingColumn > 1
          ? "Missing column names. "
          : "Missing column name. ";
      msgStr = msgStr + "" + errMsgForMissingColumn.join(",");

      validationErrorMsg = msgStr + "\n";
    }

    if (errMsgForExtraColumn.length) {
      let msgStr =
        errMsgForExtraColumn.length > 1
          ? "Invalid column names. "
          : "Invalid column name. ";
      let joinStr = errMsgForExtraColumn.join(",");

      if (joinStr && joinStr != "") {
        msgStr = msgStr + "" + joinStr;
        validationErrorMsg = validationErrorMsg ? validationErrorMsg : "";
        validationErrorMsg += msgStr;
      }
    }

    if (validationErrorMsg) {
      pError("Students Work Sheet Error!", validationErrorMsg);
    }

    return isValid;
  }

  function getFilterData() {
    let createdOn = filterCreatedOn;
    let batches = _$(selectizeBatchInstance).val();
    let boardId = _$(selectizeBoardInstance).val();
    if (batches && batches.length == 0) {
      let searchBatches = [];
      try {
        searchBatches = JJSON.parse(searchQuery).batches;
      } catch (error) {}
      batches = searchBatches;
    }
    if (boardId && boardId.length) {
      boardId = boardId[0];
    } else boardId = null;
    return {
      batches,
      boardId,
      rollNoPrefix,
      createdOn,
      gender,
      actInact,
      parents,
    };
  }

  async function api_getStudentsList(data) {
    let action = "user.getAllParents";
    data = {
      start: 0,
      length: 10,
      search: data.search,
    };
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  function valiateAssignBatchImportSheetHeaders(sheetDataArr, headers) {
    let isValid = true;
    let extraColumnFound = false;
    let missingColumns = false;
    let errMsgForMissingColumn = [];
    let errMsgForExtraColumn = [];

    let sheetCorrectColumns = {
      "Ref no": { found: true },
      "Batch Code": { found: true },
      Subjects: { found: true },
      "Student Joining Date": { found: true },
      "Agreed Fees": { found: true },
      Remark: { found: true },
    };

    if (Array.isArray(headers) && headers.length) {
      let sheetObjHeaders = headers;
      for (let index = 0; index < sheetObjHeaders.length; index++) {
        const aColumn = sheetObjHeaders[index];

        if (!sheetCorrectColumns[aColumn] && aColumn != "Details") {
          errMsgForExtraColumn.push(aColumn);
          isValid = false;
        }
      }

      for (let aColumn in sheetCorrectColumns) {
        if (sheetObjHeaders.indexOf(aColumn) == -1) {
          errMsgForMissingColumn.push(aColumn);
          isValid = false;
        }
      }
    } else {
      isValid = false;
    }

    let validationErrorMsg;
    if (errMsgForMissingColumn.length) {
      let msgStr =
        errMsgForMissingColumn > 1
          ? "Missing column names. "
          : "Missing column name. ";
      msgStr = msgStr + "" + errMsgForMissingColumn.join(",");

      validationErrorMsg = msgStr + "\n";
    }

    if (errMsgForExtraColumn.length) {
      let msgStr =
        errMsgForExtraColumn.length > 1
          ? "Invalid column names. "
          : "Invalid column name. ";
      let joinStr = errMsgForExtraColumn.join(",");

      if (joinStr && joinStr != "") {
        msgStr = msgStr + "" + joinStr;
        validationErrorMsg = validationErrorMsg ? validationErrorMsg : "";
        validationErrorMsg += msgStr;
      }
    }

    if (validationErrorMsg) {
      pError("Assign Batch Work Sheet Error!", validationErrorMsg);
    }

    return isValid;
  }

  function prefixArray(prefixType) {
    return "prefix" in prefixType;
  }

  async function getFilterDataList() {
    batchesList = await api_getBatchesList();
    bindSelect2s();
  }

  async function bindSelect2s() {
    selectizeBatchInstance = _$(filterElemBatch).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: batchesList,
    });

    // selectizeParentInstance = _$(filterByParent).selectize({
    //   preload: true,
    //   create: false,
    //   maxItems: null,
    //   valueField: "_id",
    //   labelField: "name",
    //   searchField: "name",
    //   delimiter: ",",
    //   plugins: ["remove_button"],
    //   persist: false,
    //   onChange: function (values) {
    //     parents = values;
    //   },
    //   load: async function (query, callback) {
    //     let result = await api_getStudentsList({ search: query });
    //     var parents = [];
    //     if (result.status) {
    //       result = result.data.data;
    //       parents = result.filter((parent) => {
    //         if (result.find((item) => item && item._id == parent._id)) {
    //           return parent;
    //         }
    //       });
    //     }
    //     callback(parents);
    //   },
    // });

    let resClass = await api_getClassList({
      orgId: orgId,
    });

    selectizeInstanceClass = _$(elemSelectClass).selectize({
      preload: true,
      create: true,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      persist: false,
      placeholder: "Select class",
      options: resClass,
      onChange: function (values) {
        var classId = values;
        initDemoBatchSelectize(classId);
      },
    });
  }

  async function initBoardSelectize() {
    let boards = await getBoards();
    if (selectizeBoardInstance) selectizeBoardInstance[0].selectize.destroy();
    selectizeBoardInstance = _$(filterElemBoard).selectize({
      preload: true,
      create: false,
      maxItems: 1,
      placeholder: "Select board",
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      persist: false,
      items: [],
      options: boards,
    });
  }
  async function initDemoBatchSelectize(classId) {
    let demoBatches = await getDemoBatches(classId);
    demoBatches = demoBatches;
    if (selectizeDemoBatches) selectizeDemoBatches[0].selectize.destroy();
    selectizeDemoBatches = _$(elemDemoBatchesSelect).selectize({
      preload: true,
      create: false,
      maxItems: 1,
      placeholder: "Select demo batch",
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      persist: false,
      items: demoBatches[0] ? [demoBatches[0]._id] : [],
      options: demoBatches,
    });
  }

  var btnImportStudentClick = async () => {
    importStudentDataAfterUpload = [];

    importAssignBatchDataAfterUpload = [];

    await tick();

    _$(elem_uploadFile).val("");

    _$(elem_uploadFile).click();
  };

  function applyFilter() {
    redrawStudentTable();
  }

  var btnStartImportStudent = async () => {
    let studentData = {
      studentData: importStudentDataAfterUpload,
      assignBatchData: importAssignBatchDataAfterUpload,
    };

    importLoading = true;
    var response = await api_handleApiCalls(
      "students.studentImport",
      studentData,
    );
    importLoading = false;

    if (response.status) {
      redrawStudentTable();
      showHideImportStudentModal(false);

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = response.data.url;
      link.setAttribute("download", ""); // This will keep the original filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      pSuccess("Success", "Students imported successfully");
    } else {
      pError("Error", response.errMsg);
    }
  };

  const unsubscribe = userData.subscribe((value) => {
    roleIsTelecaller = value.role == "telecaller";
    orgId = value.orgId;
  });

  let populateFilterText = async function () {
    if (searchQuery) {
      let searchBatches = [];
      try {
        searchBatches = JSON.parse(searchQuery).batches;

        var response = await api_handleApiCalls("batch.getBatchesByIds", {
          batchIds: searchBatches,
        });

        if (response.status) {
          nameStr = [];

          for (let u = 0; u < response.data.length; u++) {
            const aBatch = response.data[u];
            nameStr.push(aBatch.name);
          }

          nameStr = nameStr.join(", ");
        }
      } catch (error) {}
    }
  };
  onMount(async () => {
    feather.replace({ width: "1em", height: "1em" });
    if (searchQuery) {
      populateFilterText();
    }
    unsubscribe();
    initDemoBatchSelectize();
    initBoardSelectize();
    initDashboard();
    activeMenu.set("students");
    bindEvents();
    bindDatatable();
    getFilterDataList();
    selectedIdArr = new Set();
    tempDisplayList = new Set();
    // _$(".select_all_student").attr("checked", false); // Unchecks it
  });

  function getHeaders(ws) {
    var header = [];

    if (!ws["!ref"]) {
      return header;
    }

    var columnCount = XLSX.utils.decode_range(ws["!ref"]).e.c + 1;
    for (let i = 0; i < columnCount; ++i) {
      if (ws[`${XLSX.utils.encode_col(i)}1`]) {
        header[i] = ws[`${XLSX.utils.encode_col(i)}1`].v;
      } else {
        continue;
      }
    }
    return header;
  }
  function drawSheetImportTable(XL_row_object) {
    studentsImportDataTable = _$(elem_studentsImportTable).DataTable({
      data: XL_row_object,
      columnDefs: [
        {
          targets: 0,
          render: function (data, type, full, meta) {
            return full["Sr no"] ? full["Sr no"] : "";
          },
        },
        {
          targets: 1,
          render: function (data, type, full, meta) {
            return full["Student Id"] ? full["Student Id"] : "";
          },
        },
        {
          targets: 2,
          render: function (data, type, full, meta) {
            return full["Name"] ? full["Name"] : "";
          },
        },
        {
          targets: 3,
          render: function (data, type, full, meta) {
            return full["Mobile Number"] ? full["Mobile Number"] : "";
          },
        },
        {
          targets: 4,
          render: function (data, type, full, meta) {
            return full["Gender"] ? full["Gender"] : "";
          },
        },

        {
          targets: 5,
          render: function (data, type, full, meta) {
            return full["DOB"] ? full["DOB"] : "";
          },
        },
        {
          targets: 6,
          render: function (data, type, full, meta) {
            return `<span class="d-block">${
              full["Address"] ? full["Address"] : ""
            }</span>
                                <span class="d-block">${
                                  full["City"] ? " " + full["City"] + ", " : ""
                                }</span>
                                <span class="d-block">${
                                  full["State"] ? full["State"] : ""
                                }</span>`;
          },
        },
        {
          targets: 7,
          render: function (data, type, full, meta) {
            return full["Active"] ? full["Active"] : "";
          },
        },
        {
          targets: 8,
          render: function (data, type, full, meta) {
            let parents = full["Parents"] ? full["Parents"].toString() : null;
            var parentHtml = "";

            if (parents) {
              let pipParent = parents.split("|");
              if (Array.isArray(pipParent)) {
                pipParent.map((aParent) => {
                  let parentData = [];

                  if (Boolean(aParent)) {
                    parentData = aParent.split(",");
                  }

                  parentHtml += `<div class="media">
                                                            <div class="media-body mg-l-15">
                                                                <div class="tx-color-02 d-flex align-items-center justify-content-between mg-b-2">
                                                                    <span class="tx-12 tx-bold">${parentData[0] ? parentData[0] : ""} ${parentData[2] ? "(" + parentData[2] + ")" : ""}</span>
                                                                </div>
                                                                <span class="tx-13 tx-13">${parentData[1] ? parentData[1] : ""}</span>
                                                            </div>
                                                            </div>`;
                });
              }
            }
            return parentHtml;
          },
        },
      ],
      processing: false,
      serverSide: false,
    });
  }
  function bindFileChange(evt) {
    var selectedFile = evt.target.files[0];

    if (event.target.files.length == 0) {
      pError("File Error!", "Please Select File");
      return;
    }

    var fileExtension = event.target.files[0].name.split(".");
    if (fileExtension[fileExtension.length - 1] != "xlsx") {
      pError("File Error!", "Select Only Excel File having extension .xlsx");
      return;
    }

    var reader = new FileReader();
    reader.onload = function (event) {
      importLoading = false;
      var data = event.target.result;

      var workbook = XLSX.read(data, {
        type: "binary",
      });

      let studentsWorkSheetName = workbook.SheetNames[0];
      let assignBatchWorkSheetName = workbook.SheetNames[1];
      let missingWorkSheetMsg;
      let isStudentValid;
      let isAssignBatchValid;
      let assignBatch_XL_row_object;
      let students_XL_row_object;

      if (assignBatchWorkSheetName) {
        assignBatch_XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[assignBatchWorkSheetName],
          { raw: false },
        );
        var headers = getHeaders(workbook.Sheets[assignBatchWorkSheetName]);
        isAssignBatchValid = valiateAssignBatchImportSheetHeaders(
          assignBatch_XL_row_object,
          headers,
        );
      } else {
        missingWorkSheetMsg = "Assign Batch Work Sheet is Missing";
      }

      if (studentsWorkSheetName) {
        students_XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[studentsWorkSheetName],
          { raw: false },
        );
        var headers = getHeaders(workbook.Sheets[studentsWorkSheetName]);
        isStudentValid = valiateStudentsImportSheetHeaders(
          students_XL_row_object,
          headers,
        );

        if (isStudentValid && isAssignBatchValid) {
          // var formaattedText = XLSX.SSF.format(workbook.Sheets[sheet_2g].A2.z, aRow["Period start time"]);
          // var dateObj = customDateStrFormat_2g(formaattedText);

          // function customDateStrFormat_2g(dateTimeString) {
          // var splitted = dateTimeString.split(" ")
          // var spl_date = splitted[0].split(".") // (3) ["03", "06", "2020"]
          // var spl_time = splitted[1].split(":") // (3) ["19", "00", "00"]
          // return new Date(spl_date[2], (Number(spl_date[0]) - 1), spl_date[1], spl_time[0], spl_time[1], spl_time[2])
          // }

          importStudentDataAfterUpload = students_XL_row_object;
          importAssignBatchDataAfterUpload = assignBatch_XL_row_object;

          if (studentsImportDataTable) studentsImportDataTable.destroy();

          drawSheetImportTable(students_XL_row_object);

          showHideImportStudentModal(true);
        }
      } else {
        missingWorkSheetMsg = "Students Work Sheet is Missing";
      }

      if (missingWorkSheetMsg) {
        pError("Error!", missingWorkSheetMsg);
      }
    };

    reader.onerror = function (event) {
      importLoading = false;
    };

    try {
      importLoading = true;
      reader.readAsBinaryString(selectedFile);
    } catch (error) {
      importLoading = false;
    }
  }

  async function handleGroup() {
    navigate("/dashboard/students/groups");
  }
  function onClickCancel() {
    _$("#modal-new-student").modal("hide");
    clearFields();
  }
</script>

<!-- Modal -->
<div
  class="modal fade"
  bind:this={modalConvertEnquiry}
  tabindex="-1"
  role="dialog"
  aria-labelledby="convert-enquiry"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header">
        <h6 class="modal-title" id="convert-enquiry">
          Do you want to convert Enquiry to Student, this will archive current
          Enquiry
        </h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {#if enquiryObj}
          <ul class="list-group list-group-flush tx-13">
            <li class="list-group-item d-flex pd-sm-x-20" style="border: none;">
              <div class="avatar">
                <span class="avatar-initial rounded-circle bg-gray-600"
                  >{enquiryObj.name ? enquiryObj.name.charAt(0) : "A"}</span
                >
              </div>
              <div class="pd-l-10">
                <p class="tx-medium mg-b-0">{enquiryObj.name}</p>
                <small class="tx-12 tx-color-03 mg-b-0"
                  ><i
                    class="mg-r-5"
                    data-feather="phone-call"
                  />{enquiryObj.mobileNo}</small
                >
              </div>
              <div class="mg-l-auto d-flex align-self-center" />
            </li>
          </ul>
        {/if}
      </div>
      <div class="modal-footer">
        {#if _errorObj.apiError}
          <div class="parsley-errors-list">{_errorObj.apiError}</div>
        {/if}
        <button
          type="button"
          class="btn btn-secondary tx-12"
          data-dismiss="modal"
          >CANCEL
          <i data-feather="arrow-right" class="wd-10 mg-l-5" />
        </button>
        <button
          type="button"
          disabled={isLoading}
          on:click={proccedConvertEnquiry}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        >
          <ButtonLoader {isLoading} btnText="Proceed" />
          <i data-feather="arrow-right" class="wd-10 mg-l-5" />
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->

<!-- Modal -->
<div
  class="modal effect-scale"
  id="modal-new-student"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel6"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a
          href=""
          role="button"
          class="close pos-absolute t-15 r-15 z-index-10"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"
            ><i data-feather="user-plus" class="wd-60 ht-60" /></span
          >
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Student</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Add new student</p>
          </div>
        </div>
        <!-- media -->
      </div>
      <!-- modal-header -->

      <hr class="full-width" />
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
        <div class=" tx-color-02">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="d-block"
                >Name <span class="required-field">*</span></label
              >
              <input
                bind:value={studentName}
                type="text"
                class="form-control"
                maxlength="50"
                class:parsley-error={errorObj.studentName}
                placeholder="Enter full name"
                on:keyup={(e) => e.key === "Enter" && handleClickProceed()}
              />
              {#if errorObj.studentName}
                <div class="parsley-errors-list">{errorObj.studentName}</div>
                <!-- content here -->
              {/if}
            </div>
            <div class="form-group col-md-12">
              <label class="d-block"
                >Mobile <span class="required-field">*</span></label
              >
              <input
                type="tel"
                maxlength="10"
                bind:value={mobileno}
                class="form-control"
                class:parsley-error={errorObj.mobileno}
                placeholder="Enter mobile number"
                on:keyup={(e) => e.key === "Enter" && handleClickProceed()}
              />
              {#if errorObj.mobileno}
                <div class="parsley-errors-list">{errorObj.mobileno}</div>
                <!-- content here -->
              {/if}
            </div>
            <div class="form-group col-md-12">
              <label class="d-block">Alternate Mobile</label>
              <input
                type="tel"
                maxlength="10"
                bind:value={Alternatemobileno}
                class="form-control"
                class:parsley-error={errorObj.Alternatemobileno}
                placeholder="Enter mobile number"
                on:keyup={(e) => e.key === "Enter" && handleClickProceed()}
              />
              {#if errorObj.Alternatemobileno}
                <div class="parsley-errors-list">
                  {errorObj.Alternatemobileno}
                </div>
                <!-- content here -->
              {/if}
            </div>

            <div class="form-group col-md-12">
              <label class="d-block"
                >Class/Stream<span class="required-field">*</span></label
              >
              <select
                class="form-control"
                bind:this={elemSelectClass}
                class:parsley-error={errorObj.batchClass}
              />
              {#if errorObj.batchClass}
                <div class="parsley-errors-list">{errorObj.batchClass}</div>
                <!-- content here -->
              {/if}
            </div>

            <div class="col-md-12">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  bind:checked={addDemoBatch}
                  class="custom-control-input"
                  id="idAddDemoBatch"
                  on:click={() => {
                    if (addDemoBatch) {
                      _$(elemDemoBatchesSelect)[0].selectize.disable();
                    } else {
                      _$(elemDemoBatchesSelect)[0].selectize.enable();
                    }
                  }}
                />
                <label class="custom-control-label" for="idAddDemoBatch"
                  >Add to demo batch</label
                >
              </div>
            </div>
            <div class="col-md-12 animated bounce mg-t-10">
              <label class="d-block"
                >Select default demo batch to assign <span
                  class="required-field">{addDemoBatch ? "*" : ""}</span
                ></label
              >
              <select
                class:parsley-error={errorObj.demoBatchId}
                bind:this={elemDemoBatchesSelect}
                class=""
              />
              {#if errorObj.demoBatchId}
                <div class="parsley-errors-list">{errorObj.demoBatchId}</div>
                <!-- content here -->
              {/if}
            </div>
            <div class="col-md-12">
              {#if errorObj.apiError}
                <div class="parsley-errors-list">{errorObj.apiError}</div>
              {/if}
            </div>
            <div class="form-group col-md-12 mg-t-10">
              {#if enquiryObj}
                <h6 class="modal-title mg-b-15">
                  <i data-feather="help-circle" class="mg-r-5" /> Enquiry is available
                </h6>
                <ul class="list-group list-group-flush tx-13">
                  <li
                    class="list-group-item d-flex pd-sm-x-20 bg-light"
                    style="border: none;"
                  >
                    <div class="avatar">
                      <span class="avatar-initial rounded-circle bg-gray-600"
                        >{enquiryObj.name
                          ? enquiryObj.name.charAt(0)
                          : "A"}</span
                      >
                    </div>
                    <div class="pd-l-10">
                      <p class="tx-medium mg-b-0">{enquiryObj.name}</p>
                      <small class="tx-12 tx-color-03 mg-b-0"
                        ><i
                          class="mg-r-5 wd-15"
                          data-feather="phone-call"
                        />{enquiryObj.mobileNo}</small
                      >
                    </div>
                    <div class="mg-l-auto d-flex align-self-center">
                      <nav class="nav nav-icon-only">
                        <a
                          href=""
                          on:click={handleClickConvert}
                          class="btn btn-primary rounded-pill d-sm-block tx-white"
                          ><i data-feather="external-link" />
                          Convert</a
                        >
                      </nav>
                    </div>
                  </li>
                </ul>
              {/if}
            </div>
          </div>
        </div>

        <div class="d-md-flex justify-content-end">
          <button
            type="button"
            class="btn pd-x-15 btn-secondary tx-12"
            data-dismiss="modal"
            on:click={onClickCancel}
            >CANCEL
            <i data-feather="x" class="wd-10 mg-l-5" />
          </button>
          <button
            type="button"
            disabled={isLoading}
            on:click={handleClickProceed}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          >
            <ButtonLoader {isLoading} btnText="Proceed" />
            <i data-feather="arrow-right" class="wd-10 mg-l-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->

<!-- Modal -->
<div
  class="modal effect-scale"
  bind:this={modalStudentsImportTable}
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel6"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content tx-14">
      <div class="modal-body pd-25">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <h4 class="tx-16 tx-bold mg-b-30">Import Data</h4>
        <table class="" />
        <div class="table-responsive" style="overflow-x: auto;">
          <table
            bind:this={elem_studentsImportTable}
            width="100%"
            class="table table-padded"
          >
            <thead>
              <tr>
                <th class="wd-20">Sr no</th>
                <th class="wd-20">Id</th>
                <th class="wd-25">Name</th>
                <th class="wd-30">Mobile Number</th>
                <th class="wd-25">Gender</th>
                <th class="wd-20">DOB</th>
                <th class="wd-25">Address</th>
                <th class="wd-20">Active</th>
                <th class="wd-40">Parents</th>
              </tr>
            </thead>
            <tbody />
          </table>
        </div>

        <div class="d-md-flex justify-content-end mg-t-30">
          <button
            type="button"
            class="btn btn-sm pd-x-15 btn-white tx-13"
            data-dismiss="modal">Cancel</button
          >
          <button
            type="button"
            disabled={importLoading}
            on:click={btnStartImportStudent}
            class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5"
          >
            <ButtonLoader isLoading={importLoading} btnText="Start Import" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->

<!-- Filter Modal -->
<div
  class="modal effect-scale"
  bind:this={filterModalThis}
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel6"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a
          href=""
          role="button"
          class="close pos-absolute t-15 r-15 z-index-10"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"
            ><i data-feather="filter" class="wd-60 ht-60" /></span
          >
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
          </div>
        </div>
        <!-- media -->
      </div>
      <!-- modal-header -->

      <hr class="full-width" />
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
        <div class="form-group">
          <label class="d-block flex-grow-1">Gender:</label>
          <div class="d-md-flex">
            <div class="custom-control custom-radio mg-r-15">
              <input
                type="radio"
                id="Male"
                bind:group={gender}
                class="custom-control-input"
                value="Male"
              />
              <label class="custom-control-label" for="Male">Male</label>
            </div>

            <div class="custom-control custom-radio mg-r-15">
              <input
                type="radio"
                bind:group={gender}
                id="female"
                class="custom-control-input"
                value="Female"
              />
              <label class="custom-control-label" for="female">Female</label>
            </div>
            <div class="custom-control custom-radio">
              <input
                type="radio"
                bind:group={gender}
                id="others"
                class="custom-control-input"
                value="Other"
              />
              <label class="custom-control-label" for="others">Others</label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="d-block flex-grow-1">Active / Inactive</label>
          <div class="d-md-flex">
            <div class="custom-control custom-radio mg-r-15">
              <input
                type="radio"
                id="active"
                bind:group={actInact}
                class="custom-control-input"
                value="active"
              />
              <label class="custom-control-label" for="active">Active</label>
            </div>
            <div class="custom-control custom-radio mg-r-15">
              <input
                type="radio"
                bind:group={actInact}
                id="inactive"
                class="custom-control-input"
                value="inactive"
              />
              <label class="custom-control-label" for="inactive">Inactive</label
              >
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="d-block">Created On:</label>
          <input
            type="date"
            bind:value={filterCreatedOn}
            class="form-control follow-up-date"
            placeholder="Student created on date"
          />
        </div>

        <div class="form-group">
          <label class="d-block">Roll No. Prefix:</label>
          <input
            type="text"
            bind:value={rollNoPrefix}
            class="form-control"
            placeholder="Student roll number prefix"
          />
        </div>

        <!-- <div class="form-group">
          <label class="d-block">Parents:</label>
          <select bind:this={filterByParent} class="form-control select2" />
        </div> -->

        <div class="form-group">
          <label class="d-block">Batch:</label>
          <select
            disabled={isLoading}
            bind:this={filterElemBatch}
            class="form-control select2"
            multiple="multiple"
          >
            <option>Select batches</option>
          </select>
        </div>

        <div class="form-group">
          <label class="d-block">Board:</label>
          <select
            disabled={isLoading}
            bind:this={filterElemBoard}
            class="form-control select2"
            multiple="multiple"
          >
            <option>Select board</option>
          </select>
        </div>

        <div class="d-md-flex justify-content-end mg-t-25">
          <button
            disabled={isLoading}
            on:click={clearFilter}
            class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5"
            data-dismiss="modal"
            >CLEAR
            <i data-feather="x" class="wd-10 mg-l-5" />
          </button>
          <button
            disabled={isLoading}
            on:click={applyFilter}
            class="btn btn-sm pd-x-15 btn-primary mg-l-5"
            data-dismiss="modal"
          >
            <ButtonLoader {isLoading} btnText="APPLY" />
            <i data-feather="filter" class="wd-10 mg-l-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->

<div class="content-body fadeIn">
  <!-- <Link style="display: none;" to="about">About</Link> -->
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
  >
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">students</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Students</h4>
      {#if searchQuery}
        <p class=" tx-14 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
          Showing students from batch : <strong>{nameStr}</strong>
        </p>
      {:else}
        <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
          Search students list or add a new student.
        </p>
      {/if}
    </div>
    <hr class="full-width" />
    <div class="d-flex">
      <input
        bind:this={elem_uploadFile}
        class="d-none"
        on:change={bindFileChange}
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />

      <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i data-feather="mail"
                    class="wd-10 mg-r-5"></i> Email</button>
            <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><i data-feather="printer"
                    class="wd-10 mg-r-5"></i> Print</button> -->
      {#if !roleIsTelecaller}
        {#if MODULES.hasAccess(MODULES.VIEW_GROUPS_STUDENT)}
          {#if MODULES.hasAccess(MODULES.DELETE_STUDENT)}
            <button
              disabled={deleteLoading}
              class="btn btn-sm pd-x-15 btn-uppercase btn-primary mg-l-5"
              type="button"
              on:click={() => {
                deleteMultipleStudent();
              }}
            >
              <ButtonLoader
                isLoading={deleteLoading}
                btnText={deleteLoading
                  ? "Processing..."
                  : ` Delete
          ${deleteStudentCount}`}
              />
            </button>
          {/if}
          <button
            class="btn btn-sm mg-l-10 pd-lg-x-10 btn-primary btn-uppercase"
            on:click={() => {
              addSubToStudent();
            }}
          >
            Inactive {inactivateCount}</button
          >
          <button
            class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"
            on:click={() => {
              navigatearchivelist();
            }}
          >
            Show Archived Students</button
          >
          <button
            class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"
            on:click={() => {
              handleGroup();
            }}
          >
            <i data-feather="user-plus" class="mg-r-5" />
            Groups</button
          >
        {/if}
        {#if MODULES.hasAccess(MODULES.ADD_STUDENT)}
          <a
            href="#modal-new-student"
            data-toggle="modal"
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
            data-animation="effect-scale"
          >
            <i data-feather="user-plus" class="wd-10 mg-r-5" /> Add Student
          </a>
        {/if}
        <div class="dropdown mg-l-5">
          {#if MODULES.hasAccess(MODULES.IMPORT_EXPORT_STUDENT)}
            <button
              disabled={importLoading}
              class="btn btn-sm pd-x-15 btn-uppercase btn-secondary mg-l-5 dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <ButtonLoader
                isLoading={importLoading}
                btnText={importLoading ? "Processing..." : "Import/Export"}
              />
            </button>
          {/if}
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" on:click={btnExportStudents} href="#"
              ><i data-feather="file-text" class="wd-10 mg-r-5" />Export All
              Students</a
            >
            <a
              class="dropdown-item"
              on:click={btnExportFilteredStudents}
              href="#"
              ><i data-feather="file-text" class="wd-10 mg-r-5" />Export
              Filtered Students</a
            >
            <a
              class="dropdown-item"
              on:click={btnExportSelectedStudents}
              href="#"
              ><i data-feather="file-text" class="wd-10 mg-r-5" />Export
              Selected Students</a
            >
            <a class="dropdown-item" on:click={btnImportStudentClick} href="#"
              ><i data-feather="file-text" class="wd-10 mg-r-5" />Import
              Students</a
            >
            <a
              class="dropdown-item"
              on:click={btnExportStudentsTemplete}
              href="#"
              ><i data-feather="file-text" class="wd-10 mg-r-5" />Download
              Import Template</a
            >
          </div>
        </div>
        <button
          class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"
          on:click={() => {
            showHideFilterModal(true);
          }}
        >
          <i data-feather="filter" class="mg-r-5" />
          Filters</button
        >
      {/if}
    </div>
  </div>
  <hr class="full-width" />

  <div data-label="Example" class="df-example demo-table">
    <table bind:this={demoDataTable} class="table" id="example">
      <thead>
        <tr>
          <th style="width: 10%;">
            <input type="checkbox" id="select_all_student" name="selectAll" /> Select
            All
          </th>
          <th style="width: 15%;">Student Name</th>
          <th style="width: 15%; text-align: center">Roll No.</th>
          <th style="width: 30%; text-align: left;">Batches</th>
          <!-- <th class="wd-25p">Contact Number</th> -->
          <th style="text-align: center; width: 10%;">Added On</th>
          <th style="text-align: center; width: 10%;">Actions</th>
        </tr>
      </thead>
      <tbody />
    </table>
    {#if emptyState}
      <div class="empty-state-img">
        <img src="/images/empty.svg" style="width: 25%;" alt="empty" />
        <p class="tx-bold">Students not available</p>
      </div>
    {/if}
  </div>
</div>

<!-- Modal -->
<div
  class="modal effect-scale"
  bind:this={modalEle}
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel6"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a
          href=""
          role="button"
          class="close pos-absolute t-15 r-15 z-index-10"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <!-- <span class="tx-color-03 d-none d-sm-block"><i data-feather="list" class="wd-60 ht-60" /></span> -->
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Student</h4>
            <p class="tx-13 tx-color-03 mg-b-0">
              We are not able delete students, the pending payments found for
              following students please clear the pending payments and then
              proceed to delete
              <a href="/dashboard/payment">Click here to go to Payments</a>
            </p>
          </div>
        </div>
        <!-- media -->
      </div>
      <!-- modal-header -->

      <hr class="full-width" />
      <div class="modal-body">
        <div data-label="Example" class="df-example demo-table">
          <table
            bind:this={notdeletedStudentDataTable}
            class="table"
            id="example"
            style="width:100%"
          >
            <thead>
              <tr>
                <th style="width: 15%;text-align: center">Student Name</th>
                <th style="width: 15%; text-align: center">Email</th>
                <th style="width: 30%; text-align: center;">Mobile No.</th>
                <!-- <th class="wd-25p">Contact Number</th>
              <th style="text-align: center; width: 10%;">Added On</th>
              <th style="text-align: center; width: 10%;">Actions</th> -->
              </tr>
            </thead>
            <tbody />
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->

<style>
  .empty-state-img {
    text-align: center;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
