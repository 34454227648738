<script>
    import { Router, Link, Route } from "svelte-routing";
    import { onMount } from "svelte";
    import { userData} from "../stores";
    import List from "./list/leaveRequestList.svelte";
    export let userRole
    function init() {
      console.log("leaveRequestList isOrgAdmin =======> ", userRole);
    }
    onMount(init);
  </script>
  
  <Router>
    <Route path="">
        {#if userRole == 'orgAdmin'}
            <List isAdmin={true}/>
        {:else}
            <List isAdmin={false}/>
        {/if}
    </Route>
  </Router>
