<script>
    import { Router, Link, links, Route, navigate } from "svelte-routing";
    import { onMount, tick, afterUpdate, beforeUpdate } from "svelte";

    import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
    import api_handleApiCalls from "../utils/apiCallHandler.js";


    export let _batchId;

    let thisCmp;

    let batchList = [];
    let elemBatchId;
    let saveBtnEle;

    export let selectedFolder = [];
    export let selectedFolderWithName = [];
    export let mode;
    export let shouldNotNavigate = false;
    export let viewOnlyMode = false;
    let selectedBatch;
    function randomId() {
        var digits = '0123456789';
        let rId = '';
        for (let i = 0; i < 10; i++) {
            rId += digits[Math.floor(Math.random() * 10)];
        }
        return rId;
    }
    var cmpRandomId = randomId();

    var lastBatchId = _batchId;


    async function api_getChildren(data) {

        let action = "folder.getChildren";
        var response = await api_handleApiCalls(action, data);
        return response.data;

    }

    async function api_createNode(data) {

        let action = "folder.createNode";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_getFirstLevelForBatch(data) {

        let action ="folder.getFirstLevelForBatch";
        var response = await api_handleApiCalls(action, data);
        return response;

    }


    async function api_deleteFolder(data) {

        let action ="folder.deleteFolder";
        var response = await api_handleApiCalls(action, data);
        return response;

    }
    async function api_editFolder(data) {

        let action = "folder.editFolder";
        var response = await api_handleApiCalls(action, data);
        return response;

    }


    let bindNewNode = async function () {
        _$(thisCmp).find(".new-node input").on('keypress', async function (e) {
            if (e.which == 13) {
                let batchId = _$(this).data('batch-id');
                let parentId = _$(this).data('parent-id');
                let name = _$(this).val();
                await api_createNode({
                    batchId,
                    parentId,
                    name
                });
                _$(thisCmp).find(".custom-tree-name[data-id=" + parentId + "]").addClass("no-animation");
                _$(thisCmp).find(".custom-tree-name[data-id=" + parentId + "]").click();
            }
        });
    };

    let bindCheckboxClick = async function () {
        _$(thisCmp).find(".tree-cb input").unbind().click(function () {


            _$(thisCmp).find(".tree-cb input").prop("checked", false);
            _$(thisCmp).find(".action-span").removeClass('span-selected');

            _$(this).prop("checked", true);
            _$(this).parents('.action-span').addClass('span-selected')
            var parentList = _$(this).parents('ul');

            selectedFolder = [];

            var batchId = _$(this).parents('.action-span').find('.custom-tree-name').data('batch-id')
            var id = _$(this).parents('.action-span').find('.custom-tree-name').data('id')
            var name = _$(this).parents('.action-span').find('.custom-tree-name').html();
            selectedFolder.push({
                batchId, id, name
            })


            for (let i = 0; i < parentList.length; i++) {
                const aParent = parentList[i];
                _$(aParent).prev().find('input').prop("checked", true);
                if (_$(aParent).hasClass('custom-tree-holder')) {
                    _$(aParent).prev().find('.action-span').addClass('span-selected');

                    var batchId = _$(aParent).prev().find('.custom-tree-name').data('batch-id')
                    var id = _$(aParent).prev().find('.custom-tree-name').data('id')
                    var name = _$(aParent).prev().find('.custom-tree-name').html();
                    selectedFolder.push({
                        batchId, id, name
                    })

                } else {
                    _$(aParent).prev().addClass('span-selected')
                    var batchId = _$(aParent).prev().find('.custom-tree-name').data('batch-id')
                    var id = _$(aParent).prev().find('.custom-tree-name').data('id')
                    var name = _$(aParent).prev().find('.custom-tree-name').html();
                    selectedFolder.push({
                        batchId, id, name
                    })
                }
            }

            selectedFolder.reverse();
            if (selectedFolder.length != 0) {
                _$(saveBtnEle).removeAttr('disabled');
            } else {
                _$(saveBtnEle).attr('disabled', 'disabled');
            }
            tick();
        })
    }
    let bindAddNewNodeBtn = async function () {

        let performDeleteOps = async function (_this) {
            let folderId = _$(_this).data('id');

            pConfirm(
                "Delete Folder?",
                "Please ensure you remove all items/folder inside this folder",
                async function () {
                    let response = await api_deleteFolder({
                        folderId
                    })

                    console.log("delete folder response : ", response);
                    if (response.status) {
                        _$(_$(_this).parents('.action-span').parent().parent().parent().find(".custom-tree-name")[0]).click();
                        pSuccess("", "Folder removed successfully");
                    } else {
                        pError("", response.errMsg);
                    }
                    await tick();
                },
                async function () {
                    await tick();
                }
            );


        }
        let performEditOps = function (_this) {
            let batchId = _$(_this).data('batch-id');
            let _id = _$(_this).data('id'); //folder id
            let existingText = _$(_this).parents('.action-span').find('.custom-tree-name').text();
            const notice = PNotify.notice({
                title: 'Update Name',
                text: 'Specify new name for "' + existingText + '" folder?',
                icon: 'fas fa-question-circle',
                hide: false,
                closer: true,
                sticker: false,
                destroy: true,
                modules: new Map([
                    ...PNotify.defaultModules,
                    [PNotifyConfirm, {
                        prompt: true
                    }]
                ])
            });
            notice.on('pnotify:confirm', e => {
                api_editFolder({
                    _id, name: e.detail.value
                })
                
                // _$(".pnotify").remove()
                if(!e.detail.value ==""){
                pSuccess("", "Folder name updated successfully");
                _$(_this).parents('.action-span').find('.custom-tree-name').html(e.detail.value);
                }
                else{
                    pError("", "Folder name should not be empty");
                }
            });
            notice.on('pnotify:cancel', () => {
                // _$(".pnotify").remove()
                // notice.update({
                //     title: 'You Don\'t Want a Side',
                //     text: 'No soup for you!',
                //     icon: true,
                //     closer: true,
                //     sticker: true,
                //     type: 'info',
                //     hide: true,
                //     modules: new Map(PNotify.defaultModules)
                // });
            });


        }
        let performAddOps = function (_this) {
            let batchId = _$(_this).data('batch-id');
            let parentId = _$(_this).data('id');
            _$(".new-node").remove();
            if (_$(_this).hasClass('is-root')) {
                let htmlStr = `
                <li class="new-node"> 
                    <span style="display: inline-flex; width:100%;align-items: center;">
                        <i data-feather="folder"></i>
                        <input type='text' data-batch-id="${batchId}" data-parent-id="${parentId}" style="flex:1"/>
                        <i data-feather="x"></i>
                    </span>
                    </li>`
                _$(thisCmp).find(".custom-tree").find(".custom-tree-holder").prepend(htmlStr)
            } else {
                let htmlStr = `
                <li class="new-node"> 
                    <span style="display: inline-flex; width:100%;align-items: center;">
                        <i data-feather="folder"></i>
                        <input type='text' data-batch-id="${batchId}" data-parent-id="${parentId}" style="flex:1"/>
                        <i data-feather="x"></i>
                    </span>
                    </li>`
                _$(thisCmp).find('.children-of-parent[data-id="' + parentId + '"]').prepend(htmlStr)
            }
            feather.replace();
            _$(thisCmp).find(".new-node input").focus();
            bindNewNode();
        }

        _$('.cm-actions[data-context-cmp-id="' + cmpRandomId + '"] button').unbind().click(function () {
            performAddOps(this)
            _$(".custom-menu").hide(100);
        })
        _$(thisCmp).find(".add-folder").unbind().click(function () {
            performAddOps(this)
        })
        _$(thisCmp).find(".delete-folder").unbind().click(function () {
            performDeleteOps(this)
        })
        _$(thisCmp).find(".edit-folder").unbind().click(function () {
            performEditOps(this)
        })
    }

    let bindNameClick = async function () {
        _$(thisCmp).find(".custom-tree-name").unbind().click(async function () {
            var noAnimation = _$(this).hasClass('no-animation');
            _$(this).removeClass('no-animation');
            _$('.action-span').removeClass('node-selected');
            _$(this).parents('.action-span').addClass('node-selected');
            _$(".new-node").remove();
            if (mode == "compact") {
                var parentList = _$(this).parents('ul');
                var selectedFolder_forCompact = []
                selectedFolderWithName = [];

                var batchId = _$(this).parents('.action-span').find('.custom-tree-name').data('batch-id')
                var id = _$(this).parents('.action-span').find('.custom-tree-name').data('id')
                var name = _$(this).parents('.action-span').find('.custom-tree-name').html();

                selectedFolderWithName.push({
                    batchId, id, name
                })
                selectedFolder_forCompact.push(id)


                for (let i = 0; i < parentList.length; i++) {
                    const aParent = parentList[i];
                    if (_$(aParent).hasClass('custom-tree-holder')) {
                        _$(aParent).prev().find('.action-span').addClass('node-selected');

                        var batchId = _$(aParent).prev().find('.custom-tree-name').data('batch-id')
                        var id = _$(aParent).prev().find('.custom-tree-name').data('id')
                        var name = _$(aParent).prev().find('.custom-tree-name').html();
                        selectedFolderWithName.push({
                            batchId, id, name
                        })
                        selectedFolder_forCompact.push(id)

                    } else {
                        _$(aParent).prev().addClass('node-selected')
                        var batchId = _$(aParent).prev().find('.custom-tree-name').data('batch-id')
                        var id = _$(aParent).prev().find('.custom-tree-name').data('id')
                        var name = _$(aParent).prev().find('.custom-tree-name').html();
                        selectedFolderWithName.push({
                            batchId, id, name
                        })
                        selectedFolder_forCompact.push(id)
                    }
                }

                selectedFolder_forCompact.reverse();
                selectedFolderWithName.reverse();
                // console.log(selectedFolder_forCompact);
                // console.log("selectedFolderWithName : ", selectedFolderWithName);
                if (!shouldNotNavigate)
                    navigate("/dashboard/studyMaterial/folder/" + selectedFolder_forCompact.join("-"))
            }
            if (_$(this).hasClass('is-root')) {
                if (_$(this).hasClass('isOpen') && !noAnimation) {
                    let children = _$(thisCmp).find(".custom-tree").find(".custom-tree-holder > li");
                    if (children.is(":visible")) {
                        children.hide('fast');
                    }
                    _$(this).removeClass('isOpen')
                } else {
                    let batchId = _$(this).data('batch-id');
                    loadChildForRoot(batchId, noAnimation);
                    _$(this).addClass('isOpen')
                }
            } else {
                let batchId = _$(this).data('batch-id');
                let parentId = _$(this).data('id');
                if (_$(this).hasClass('isOpen') && !noAnimation) {
                    let children = _$(thisCmp).find(".children-of-parent[data-id='" + parentId + "'] > li")
                    if (children.is(":visible")) {
                        children.hide('fast');
                    }
                    _$(this).removeClass('isOpen')
                } else {
                    loadChildForNode(batchId, parentId, noAnimation);
                    _$(this).addClass('isOpen');
                }
            }
        })
    }

    let renderFirstLevelNodes = async function (nodeArr, noAnimation, batchId) {
        if (nodeArr.length > 0) {
            let firstLevelHtml = '';
            for (let i = 0; i < nodeArr.length; i++) {
                const aFolder = nodeArr[i];
                firstLevelHtml +=
                    `<li class="li-nodes" data-id="${aFolder._id}" style="display:none"> 
                        <span class="action-span" data-batch-id="${aFolder.batchId}" data-id="${aFolder._id}">
                            <div class="custom-control custom-checkbox tree-cb">
                                <input type="checkbox" class="custom-control-input" id="cblabel_${cmpRandomId + aFolder._id}">
                                <label class="custom-control-label" for="cblabel_${cmpRandomId + aFolder._id}"></label>
                            </div>
                            <i data-feather="folder"></i>
                            <span 
                                data-batch-id="${aFolder.batchId}" 
                                data-id="${aFolder._id}" 
                                class="custom-tree-name" >${aFolder.name}</span>
                            
                            
                            ${viewOnlyMode ? "" : `<div style="display: inherit;">
                                <a href="" class="dropdown-link" data-toggle="dropdown"><i
                                    data-feather="more-horizontal"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <button 
                                        class="dropdown-item add-folder" 
                                        data-batch-id="${aFolder.batchId}" 
                                        data-id="${aFolder._id}">
                                            <i data-feather="plus-square"></i>Create New
                                    </button>
                                    <button 
                                        class="dropdown-item edit-folder" 
                                        data-batch-id="${aFolder.batchId}" 
                                        data-id="${aFolder._id}" >
                                        <i data-feather="edit"></i>Rename
                                    </button>
                                    <button 
                                        class="dropdown-item delete-folder" 
                                        data-batch-id="${aFolder.batchId}" 
                                        data-id="${aFolder._id}" >
                                        <i data-feather="trash"></i>Delete
                                    </button>
                                </div>
                            </div>`}

                            <div class='node-loader mg-l-10'>

                            </div>
                        </span>
                        <ul class="children-of-parent" data-id="${aFolder._id}"></ul>
                        
                    </li>`
            }
            _$(thisCmp).find(".custom-tree").find(".custom-tree-holder").html(firstLevelHtml)
        } else {

            if (!viewOnlyMode)
                _$(thisCmp).find(".custom-tree").find(".custom-tree-holder").html(`<li class="li-nodes new-node"> 
                        <span class="action-span" data-batch-id="${batchId}" data-id="${batchId}">
                            <i data-feather="plus" style="        margin-top: 3px;
                                                            width: 14px !important;
                                                            height: 14px;"></i>
                            <span 
                                data-batch-id="${batchId}" 
                                data-id="${batchId}" 
                                class=" add-folder is-root" >Click to create</span>
                        </span>
                    </li>`)
        }
        feather.replace();

        let children = _$(thisCmp).find(".custom-tree").find(".custom-tree-holder > li")
        if (!noAnimation) {
            children.show('fast');
        } else {
            children.show();
        }

    }


    let loadChildForRoot = async function (batchId, noAnimation) {
        _$(thisCmp).find(".root .node-loader").html('<div class="spinner-border spinner-border-sm " role="status"></div>')
        let batchObj = await api_getFirstLevelForBatch({
            batchId
        })
        // _$(".root node-loader").html('');

        let linkStr = "";
        if (batchObj.data.isRootBatch && batchObj.data.syncedWith && batchObj.data.syncedWith.length > 0) {
            linkStr = '(' + batchObj.data.syncedWith.length + ' links)';
        }


        _$(thisCmp).find(".custom-tree").find(".root span").html(`
            <i data-feather="book"></i>
            <span data-batch-id="${batchId}" data-id="${batchId}" class="custom-tree-name is-root tx-medium" >${batchObj.data.name} ${linkStr}</span>
                                ${viewOnlyMode ? "" : `<div style="display: inherit;">
                                <a href="" class="dropdown-link" data-toggle="dropdown"><i
                                    data-feather="more-horizontal"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <button
                                        class="dropdown-item add-folder is-root"
                                        data-batch-id="${batchId}" 
                                        data-id="${batchId}">
                                            <i data-feather="plus-square"></i>Create New Subject
                                    </button>
                                  
                                </div>
                            </div>`}
            <div class='node-loader mg-l-10'></div>
        `);

        //   <button 
        //      class="dropdown-item edit-folder" 
        //      data-batch-id="${batchId}" 
        //      data-id="${batchId}" >
        //      <i data-feather="edit"></i>Rename
        //  </button>
        //  <button 
        //      class="dropdown-item delete-folder" 
        //      data-batch-id="${batchId}" 
        //      data-id="${batchId}" >
        //      <i data-feather="trash"></i>Delete
        //  </button>
        feather.replace();
        renderFirstLevelNodes(batchObj.data.rootLevel, noAnimation, batchId);
        bindAddNewNodeBtn();
        bindNameClick();
        bindCheckboxClick();
        bindRightClick();
    }

    let bindRightClick = function () {
        _$(thisCmp).find(".action-span").unbind("contextmenu").contextmenu(function (event) {

            if (viewOnlyMode) return;

            let batchId = _$(this).data('batch-id');
            let parentId = _$(this).data('id');
            _$(".action-span").css({ outline: 0, "background-color": "white" })

            if (!batchId || !parentId) { return; }

            var isRoot = _$(this).hasClass('is-root')

            event.preventDefault();
            _$(this).css({ outline: "1px dotted #ddd", "background-color": "#eef0f7" })

            let htmlStr = `<div class="cm-actions" data-context-cmp-id="${cmpRandomId}">
                                <button
                                    class="dropdown-item add-folder ${isRoot ? "is-root" : ""}"
                                    data-batch-id="${batchId}" 
                                    data-id="${parentId}">
                                        <i data-feather="plus-square"></i>${isRoot ? "Create New Subject" : "Create New"}
                                </button>
                                <button 
                                    class="dropdown-item edit-folder">
                                    <i data-feather="edit"></i>Rename
                                </button>
                                <button 
                                    class="dropdown-item delete-folder" >
                                    <i data-feather="trash"></i>Delete
                                </button>
                            </div>`;
            _$(".custom-menu").html(htmlStr);
            bindAddNewNodeBtn();
            // _$(".custom-menu .dropdown-item").unbind('click').click(function () {
            //     _$(".custom-menu").hide(100);
            // });

            feather.replace();
            _$(".custom-menu").finish().toggle(100).css({
                top: event.pageY + "px",
                left: event.pageX + "px"
            });
        });
    }

    let loadChildForNode = async function (batchId, parentId, noAnimation) {
        _$(thisCmp).find("li[data-id=" + parentId + "]>.action-span .node-loader").html('<div class="spinner-border spinner-border-sm " role="status"></div>')
        let childrenList = await api_getChildren({
            batchId, parentId
        })



        if (childrenList.length > 0) {
            let childrenHtml = '';
            for (let i = 0; i < childrenList.length; i++) {
                const aFolder = childrenList[i];
                childrenHtml +=
                    `<li class="li-nodes" data-id="${aFolder._id}"> 
                        <span class="action-span" data-batch-id="${aFolder.batchId}" data-id="${aFolder._id}">
                            <div class="custom-control custom-checkbox tree-cb">
                                <input type="checkbox" class="custom-control-input" id="cblabel_${cmpRandomId + aFolder._id}">
                                <label class="custom-control-label" for="cblabel_${cmpRandomId + aFolder._id}"></label>
                            </div>
                            <i data-feather="folder"></i>
                            <span 
                                data-batch-id="${aFolder.batchId}" 
                                data-id="${aFolder._id}" 
                                class="custom-tree-name" >${aFolder.name}</span>

                                ${viewOnlyMode ? "" : `<div style="display: inherit;">
                                <a href="" class="dropdown-link" data-toggle="dropdown"><i
                                    data-feather="more-horizontal"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <button
                                        class="dropdown-item add-folder"
                                        data-batch-id="${aFolder.batchId}" 
                                        data-id="${aFolder._id}">
                                            <i data-feather="plus-square"></i>Create New
                                    </button>
                                    <button 
                                        class="dropdown-item edit-folder" 
                                        data-batch-id="${aFolder.batchId}" 
                                        data-id="${aFolder._id}" >
                                        <i data-feather="edit"></i>Rename
                                    </button>
                                    <button 
                                        class="dropdown-item delete-folder" 
                                        data-batch-id="${aFolder.batchId}" 
                                        data-id="${aFolder._id}" >
                                        <i data-feather="trash"></i>Delete
                                    </button>
                                </div>
                            </div>`}
                            
                            <div class='node-loader mg-l-10'>
                            </div>
                        </span>
                        <ul class="children-of-parent" data-id="${aFolder._id}"></ul>
                    </li>`
            }
            _$(thisCmp).find(".children-of-parent[data-id='" + parentId + "']").html(childrenHtml)
        } else {

            if (!viewOnlyMode)
                _$(thisCmp).find(".children-of-parent[data-id='" + parentId + "']").html(`<li class="li-nodes new-node"> 
                        <span class="action-span" data-batch-id="${batchId}" data-id="${parentId}">
                            <i data-feather="plus" style="    margin-top: 3px;
                                                            width: 14px !important;
                                                            height: 14px;"></i>
                            <span 
                                data-batch-id="${batchId}" 
                                data-id="${parentId}" 
                                class=" add-folder " >Click to create</span>
                        </span>
                    </li>`)
        }
        feather.replace();



        let children = _$(thisCmp).find(".children-of-parent[data-id='" + parentId + "'] > li")
        if (!noAnimation) {
            children.show('fast');
        } else {
            children.show();
        }

        _$(thisCmp).find("li[data-id=" + parentId + "]>.action-span .node-loader").html('');
        bindAddNewNodeBtn();
        bindNameClick();
        bindCheckboxClick();
        bindRightClick();
    }

    afterUpdate(() => {
        if (lastBatchId != _batchId) {
            lastBatchId = _batchId;
            console.log("reloading on batch param change")
            loadChildForRoot(_batchId);
        }
    });


    onMount(async () => {
        loadChildForRoot(_batchId);
        _$(thisCmp).find('.custom-tree li:has(ul)').addClass('parent_li').find(' > span').attr('title', 'Collapse this branch');
        _$(thisCmp).find('.custom-tree li.parent_li > span').on('click', function (e) {
            if (e.target.attributes.length == 3) return;
            let children = _$(this).parent('li.parent_li').find(' > ul > li');
            if (children.is(":visible")) {
                children.hide('fast');
                _$(this).attr('title', 'Expand this branch').find(' > i').addClass('fa-plus-square').removeClass('fa-minus-square');
            } else {
                children.show('fast');
                _$(this).attr('title', 'Collapse this branch').find(' > i').addClass('fa-minus-square').removeClass('fa-plus-square');
            }
            e.stopPropagation();
        });

    })
</script>


<div class="folder-exp" bind:this={thisCmp} class:compact={(mode=='compact' )}>
    <div class="custom-tree ">
        <div class="root">
            <span class="action-span is-root" data-batch-id="{_batchId}" data-id="{_batchId}"></span>
        </div>
        <ul class="custom-tree-holder">
        </ul>
    </div>
</div>
