<script>
  import { onMount, tick } from "svelte";
  import { link } from "svelte-routing";
  import { activeMenu } from "../stores";
  import api_handleApiCalls from "../utils/apiCallHandler.js";

  import MODULES from "../utils/modules.js"
  import { API_URL } from "../../config.js"


  let studentsCount = 0;
  let batchesCount = 0;
  let enquiriesCount = 0;
  let coursesCount = 0;
  let studyMaterial = 0;
  let questionsCount = 0;
  let isLoading = false;
  let isRazorPay = false;

  let students = [];
  let latestEnquiries = [];
  let latestFeedback = [];
  let latestJoinRequest = [];
  let LiveLectures = [];
  let LatestTests = [];
  let TodaysHolidays = {};
  let StudyReports = [];
  let TodaysFollowups = [];
  let FileUploads = [];
  let holidays = [];

  async function api_getAllCounts() {
    var response = await api_handleApiCalls("home.counts", {});
    console.log("api_getAllCounts-", response);

    if (response.status) {
      let counts = response.data;
      studentsCount = counts.student;
      batchesCount = counts.batches;
      enquiriesCount = counts.enquiries;
      coursesCount = counts.courses;
      studyMaterial = counts.studyMaterial;
      questionsCount = counts.questions;
      await tick();
    } else {
      studentsCount = 0;
      batchesCount = 0;
      enquiriesCount = 0;
      coursesCount = 0;
      studyMaterial = 0;
      await tick();
    }
  }


  async function api_getIntegrationSettings() {

    let result = await api_handleApiCalls("settings.fetchIntegrationSettings", {});
    // console.log("fetchIntegrationSettings>>>>>>>", result);
    if (result.status) {
      // console.log("integration data>>>>>", JSON.stringify(result.data.RAZOR_PAY, null, 4))
      isRazorPay = false
    } else {
      isRazorPay = true
    }
  }

  function messagesBoxClose() {
    _$("#msgBox").hide()
  }


  async function api_getNewlyAddedStudents() {
    var response = await api_handleApiCalls("dashboard.newlyAddedStudents", {});
    if (response.data) return response.data;
    return [];
  }

  async function api_getNewlyAddedEnquiries() {
    var response = await api_handleApiCalls("dashboard.latestEnquiry", {});
    if (response.data) return response.data;
    return [];
  }
  async function api_getNewlyAddedLatestFeedback() {
    var response = await api_handleApiCalls("dashboard.latestFeedback", {});
    feather.replace();
    if (response.data) return response.data;
    return [];
  }


  async function api_getJoinRequests() {
    var response = await api_handleApiCalls("dashboard.joinRequest", {});
    feather.replace();
    if (response.data) return response.data;
    return [];
  }

  async function api_getLiveLeactures() {
    var response = await api_handleApiCalls("dashboard.liveLeactures", {});
    feather.replace();
    if (response.data) return response.data;
    return [];
  }

  async function api_getLatestTests() {
    var response = await api_handleApiCalls("dashboard.latestTests", {});
    feather.replace();
    if (response.data) return response.data;
    return [];
  }
  async function api_getTodaysHolidays() {
    var response = await api_handleApiCalls("dashboard.todaysHolidaysList", {});
    feather.replace();
    if (response.data) {
      // holidays = [response.data];
      return response.data;
    } else {
      return [];
    }
  }

  async function api_getLatestStudyReport() {
    var response = await api_handleApiCalls("dashboard.latestStudyReport", {});
    feather.replace();
    if (response.data) return response.data;
    return [];
  }

  async function api_getTodaysFollowups() {
    var response = await api_handleApiCalls("enquiries.listTodaysFollowups", { start: 0, length: 5 });
    feather.replace();
    if (response.data) return response.data;
    return [];
  }

  async function api_getLatestFileUploads() {
    var response = await api_handleApiCalls("dashboard.latestFileUploads", {});
    await tick();
    feather.replace();
    if (response.data) return response.data;
    return [];
  }
  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var dateString = [day, month, year].join("-");
    return dateString;
  }


  onMount(async () => {
    feather.replace();
    activeMenu.set("home");
    api_getAllCounts();

    api_getIntegrationSettings();

    students = await api_getNewlyAddedStudents();
    latestEnquiries = await api_getNewlyAddedEnquiries();
    latestFeedback = await api_getNewlyAddedLatestFeedback();
    latestJoinRequest = await api_getJoinRequests();
    LiveLectures = await api_getLiveLeactures();
    LatestTests = await api_getLatestTests();
    TodaysHolidays = await api_getTodaysHolidays();
    StudyReports = await api_getLatestStudyReport();
    TodaysFollowups = await api_getTodaysFollowups();
    TodaysFollowups = TodaysFollowups.data;
    FileUploads = await api_getLatestFileUploads();
  });


</script>

<div class="content-body fadeIn">
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <!-- <li class="breadcrumb-item"><a href="/">Dashboard</a></li> -->
          <li class="breadcrumb-item active" aria-current="page">
            Dashboard
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Welcome to Dashboard</h4>
    </div>
    <div class="d-none d-md-block">
      <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i
          data-feather="mail"
          class="wd-10 mg-r-5" />
        Email</button>
      <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><i
          data-feather="printer"
          class="wd-10 mg-r-5" />
        Print</button> -->
      <!-- <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
          data-feather="file"
          class="wd-10 mg-r-5" />
        Generate Report</button> -->
    </div>
  </div>
  <hr class="full-width">

  {#if isRazorPay}
  <div class="messageBox pd-x-15 pd-y-20 mg-b-20 pd-t-30 card" id="msgBox">
    <div class="row">
      <div class="col-sm-11">
        <p><a href="/dashboard/settings/integration" use:link>Click here to Setup your Razor Pay Details.</a></p>
      </div>
      <!-- <div class="col-sm-1">
        <a href="/dashboard/settings/integration" use:link>Redirect </a>
      </div> -->
      <div class="col-sm-1">
        <a href="" role="button" class="close" on:click={messagesBoxClose} aria-label="Close" use:link>
          <span aria-hidden="true">&times;</span>
        </a>
      </div>
    </div>
  </div>
  {/if}

  <div class="row row-xs">
    <div class="col-sm-6 col-md">
      <div class="card card-body" style="width: 100%;">
        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
          Students
        </h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{studentsCount}</h3>
          <p class="tx-color-03 mg-b-0" />
        </div>
        <div class="chart-three">
          <img src="/images/dummychart.png" style="    width: 100%;
    margin-top: 10px;
    margin-bottom: -10px;" alt="">
        </div>
        <!-- chart-three -->
      </div>
    </div>
    <!-- col -->

    <div class="col-sm-6 col-md  mg-t-10 mg-sm-t-0">
      <div class="card card-body" style="width: 100%;">
        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
          Enquiries
        </h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
            {enquiriesCount}
          </h3>
          <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
        </div>
        <div class="chart-three">
          <img src="/images/dummychart.png" style="    width: 100%;
              margin-top: 10px;
              margin-bottom: -10px;" alt="">
        </div>
        <!-- chart-three -->
      </div>
    </div>
    <!-- col -->
    <div class="col-sm-6 col-md">
      <div class="card card-body" style="width: 100%;">
        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
          Questions
        </h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
            {questionsCount}
          </h3>
          <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
        </div>
        <div class="chart-three">
          <img src="/images/dummychart.png" style="    width: 100%;
              margin-top: 10px;
              margin-bottom: -10px;" alt="">
        </div>
        <!-- chart-three -->
      </div>
    </div>
    <!-- col -->
    <div class="col-sm-6 col-md mg-t-10 mg-lg-t-0">
      <div class="card card-body" style="width: 100%;">
        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
          Batches
        </h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{batchesCount}</h3>
          <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
        </div>
        <div class="chart-three">
          <img src="/images/dummychart.png" style="    width: 100%;
              margin-top: 10px;
              margin-bottom: -10px;" alt="">
        </div>
        <!-- chart-three -->
      </div>
    </div>
    <!-- col -->
    <!-- <div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
      <div class="card card-body">
        <h6
          class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
          Courses
        </h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{coursesCount}</h3>
          <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
        </div>
        <div class="chart-three">
          <img src="/images/dummychart.png" style="    width: 100%;
              margin-top: 10px;
              margin-bottom: -10px;" alt="">
        </div>
        <!-- chart-three -->
    <!-- </div>
    </div> -->
    <!-- col -->
    <div class="col-sm-6 col-md mg-t-10 mg-lg-t-0">
      <div class="card card-body" style="width: 100%;">
        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
          Study Materials
        </h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{studyMaterial}</h3>
          <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
        </div>
        <div class="chart-three">
          <img src="/images/dummychart.png" style="    width: 100%;
              margin-top: 10px;
              margin-bottom: -10px;" alt="">
        </div>
        <!-- chart-three -->
      </div>
    </div>
    <!-- col -->
  </div>
  <!-- row -->

  <div class="pd-x-0 pd-lg-x-10 pd-xl-x-0 row">

    {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD_VIEW_LATEST_ENQ)}
    <div class="col-md-6 col-xl-4 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">Latest Enquiries</h6>
          <div class="d-flex tx-18">
            <a use:link href="/dashboard/enquiries" class="link-03 lh-0 tx-13"><i data-feather="list"
                class="wd-15 mg-r-5 pd-b-5"></i>View all</a>
            <!-- <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a> -->
          </div>
        </div>
        <ul class="list-group list-group-flush tx-13">
          {#if !latestEnquiries.length}
          <li class="list-group-item d-flex pd-sm-x-20 justify-content-center">
            <p class="tx-medium mg-b-0 tx-color-03">No latest enquiries available!</p>
          </li>
          {:else}
          {#each latestEnquiries as Enquiries}
          <li class="list-group-item d-flex pd-sm-x-20">
            <div
              class="wd-35 ht-35 bd bd-2 bd-primary tx-primary tx-capitalize rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex capitalize">
              {(Enquiries.name).charAt(0)}
            </div>
            <a use:link href="/dashboard/enquiries/info/{Enquiries._id}">
              <div class="pd-sm-l-10">
                <p class="tx-medium mg-b-0 tx-color-02 tx-capitalize"
                  style="width: 150px; overflow: hidden; height: 20px;">
                  {Enquiries.name}</p>
                <small class="tx-12  mg-b-0 tx-color-02"><i data-feather="phone"
                    class="wd-10 mg-r-5"></i>{Enquiries.mobileNo}</small><br>
                <small class="tx-12 tx-color-03 mg-b-0">Date : {convertDateObjToStr(Enquiries.createdOn)}</small>
              </div>
            </a>
            <div class="mg-l-auto text-right pd-sm-l-10">
              {#if Enquiries.status}
              <small class="tx-12 badge badge-light mg-b-0 ">{Enquiries.status}</small>
              {:else}
              <small class="tx-12 mg-b-0 ">-</small>
              {/if}
              <br>
              <small class="tx-12 tx-color-03 mg-b-1">Assigned Counselor : {Enquiries.assignedCounselor ?
                Enquiries.assignedCounselor :"-"}</small>
              <br>
              <small class="tx-12 tx-color-03 mg-b-0">Class : {Enquiries.className ? Enquiries.className :"-"}</small>


            </div>
          </li>
          {/each}
          {/if}
        </ul>
        <div class="card-footer text-center tx-13">
          <a use:link href="/dashboard/enquiries" class="link-03">View All Enquiries <i
              class="icon ion-md-arrow-down mg-l-5"></i></a>
        </div>
        <!--card-footer -->
      </div><!-- card -->
    </div>
    {/if}


    {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD_VIEW_LATEST_STUDENTS)}
    <div class="col-md-6 col-xl-4 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">New Students</h6>
          <div class="d-flex align-items-center tx-18">
            <a use:link href="/dashboard/students" class="link-03 lh-0 tx-13"><i data-feather="list"
                class="wd-15 mg-r-5 pd-b-5"></i>View all</a>
            <!-- <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a> -->
          </div>
        </div>
        <ul class="list-group list-group-flush tx-13">
          {#if !students.length}
          <li class="list-group-item d-flex pd-sm-x-20 justify-content-center">
            <p class="tx-medium mg-b-0 tx-color-03">No latest students available!</p>
          </li>
          {:else}
          {#each students as student}
          <li class="list-group-item d-flex pd-sm-x-20">
            <div class="avatar"><img src={student.profilePic ? student.profilePic : '/images/man.png' }></div>
            <!--<span class="avatar-initial rounded-circle bg-gray-600">s</span> -->
            <a use:link href="/dashboard/student/view/{student._id}">
              <div class="pd-l-10">
                <p class="tx-medium mg-b-0 tx-color-02 tx-capitalize"
                  style="width: 150px; overflow: hidden; height: 20px;">{student.name}</p>
                <small class="tx-12  mg-b-0 tx-color-02"><i data-feather="phone" class="wd-10 mg-r-5"></i>
                  {student.mobileNo}</small><br>
                <small class="tx-12 tx-color-03 mg-b-0">Roll No. {student.enrollmentNo? student.enrollmentNo:
                  "-"}</small>
              </div>
            </a>
            <div class="mg-l-auto d-flex align-self-center">
              <nav class="nav nav-icon-only">
                <a use:link href="/dashboard/student/view/{student._id}" class="nav-link d-none d-sm-block"><i
                    data-feather="eye"></i></a>
                <a href="" class="nav-link d-sm-none"><i data-feather="more-vertical"></i></a>
              </nav>
            </div>
          </li>
          {/each}
          {/if}
        </ul>
        <div class="card-footer text-center tx-13">
          <a use:link href="/dashboard/students" class="link-03">View More Students <i
              class="icon ion-md-arrow-down mg-l-5"></i></a>
        </div><!-- card-footer -->
      </div><!-- card -->
    </div>
    {/if}

    {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD_VIEW_LATEST_FEEDBACKS)}
    <div class="col-md-6 col-xl-4 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">Latest Feedbacks</h6>
          <div class="d-flex tx-18">
            <a use:link href="/dashboard/feedback" class="link-03 lh-0 tx-13"><i data-feather="list"
                class="wd-15 mg-r-5 pd-b-5"></i>View all</a>
            <!-- <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a> -->
          </div>
        </div>
        <ul class="list-group list-group-flush tx-13">
          {#if !latestFeedback.length}
          <li class="list-group-item d-flex pd-sm-x-20 justify-content-center">
            <p class="tx-medium mg-b-0 tx-color-03">No latest feedback available!</p>
          </li>
          {:else}
          {#each latestFeedback as feedback}
          <li class="list-group-item d-flex pd-sm-x-20">
            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600">
                {(feedback.type).charAt(0)}</span></div>
            <!-- <div class="wd-35 ht-35 bd bd-2 bd-primary tx-primary rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex capitalize">
              {(feedback.type).charAt(0)}
            </div> -->
            <a use:link href="/dashboard/feedback/{feedback._id}">
              <div class="pd-sm-l-10">
                <p class="tx-medium mg-b-0 tx-color-02" style="height: 20px; overflow: hidden; width: 150px;">{@html
                  feedback.query}</p>
                <small class="tx-12 tx-color-02 mg-b-0 tx-capitalize" style="width: 150px; overflow: hidden;">By User:
                  {feedback.studentName}</small><br>
                <small class="tx-12 tx-color-02 mg-b-0">Status : {feedback.status}</small><br>
                <small class="tx-12 tx-color-03 mg-b-0">Date : {convertDateObjToStr(feedback.createdOn)}</small>
              </div>
            </a>
            <div class="mg-l-auto text-right">
              {#if feedback.type}
              <small class="tx-12 badge badge-light mg-b-0 ">{feedback.type}</small>
              {:else}
              <small class="tx-12 mg-b-0 ">-</small>
              {/if}
            </div>
          </li>
          {/each}
          {/if}
        </ul>
        <div class="card-footer text-center tx-13">
          <a use:link href="/dashboard/feedback" class="link-03">View All Feedbacks <i
              class="icon ion-md-arrow-down mg-l-5"></i></a>
        </div><!--card-footer -->
      </div><!-- card -->
    </div>
    {/if}

    {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD_VIEW_LATEST_BATCHJOININGREQ)}
    <div class="col-md-6 col-xl-4 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">New Joining Requests</h6>
          <div class="d-flex align-items-center tx-18">
            <a use:link href="/dashboard/batchJoinRequest" class="link-03 lh-0 tx-13"><i data-feather="list"
                class="wd-15 mg-r-5 pd-b-5"></i>View all</a>
            <!-- <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a> -->
          </div>
        </div>
        <ul class="list-group list-group-flush tx-13">
          {#if !latestJoinRequest.length}
          <li class="list-group-item d-flex pd-sm-x-20 justify-content-center">
            <p class="tx-medium mg-b-0 tx-color-03">No latest joining requests available!</p>
          </li>
          {:else}
          {#each latestJoinRequest as request}
          <li class="list-group-item d-flex pd-sm-x-20">
            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600">B</span></div>
            <!--<span class="avatar-initial rounded-circle bg-gray-600">s</span> -->
            <a use:link href="/dashboard/students/info/{request.studentId}/batch-info/assignNew/{request.batchId}">
              <div class="pd-l-10">
                <p class="tx-medium mg-b-0 tx-color-02 tx-capitalize"
                  style="width: 150px; overflow: hidden; height: 20px;">{request.batchname}</p>
                <small class="tx-12 tx-color-02 mg-b-0 tx-capitalize" style="width: 150px; overflow: hidden;">By Student
                  : {request.studentName}</small><br>
                <small class="tx-12 tx-color-03 mg-b-0">Status : {request.status}</small>
              </div>
            </a>
            <div class="mg-l-auto d-flex align-self-center">
              <nav class="nav nav-icon-only">
                <!-- dashboard/students/info/{request.studentId}/batch-info/assignNew/{request.batchId} -->
                <a use:link href="/dashboard/students/info/{request.studentId}/batch-info/assignNew/{request.batchId}"
                  class="nav-link d-none d-sm-block"><i data-feather="eye"></i></a>
                <a href="" class="nav-link d-sm-none"><i data-feather="more-vertical"></i></a>
              </nav>
            </div>
          </li>
          {/each}
          {/if}
        </ul>
        <div class="card-footer text-center tx-13">
          <a use:link href="/dashboard/batchJoinRequest" class="link-03">View More Joing Requests <i
              class="icon ion-md-arrow-down mg-l-5"></i></a>
        </div><!-- card-footer -->
      </div><!-- card -->
    </div>
    {/if}

    {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD_VIEW_LATEST_LIVELEC)}
    <div class="col-md-6 col-xl-4 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">New Live Lectures</h6>
          <div class="d-flex align-items-center tx-18">
            <a use:link href="/dashboard/studyMaterial/liveLecture/" class="link-03 lh-0 tx-13"><i data-feather="list"
                class="wd-15 mg-r-5 pd-b-5"></i>View all</a>
            <!-- <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a> -->
          </div>
        </div>
        <ul class="list-group list-group-flush tx-13">
          {#if !LiveLectures.length}
          <li class="list-group-item d-flex pd-sm-x-20 justify-content-center">
            <p class="tx-medium mg-b-0 tx-color-03">No live lectures available!</p>
          </li>
          {:else}
          {#each LiveLectures as lecture}
          <li class="list-group-item d-flex pd-sm-x-20">
            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600">L</span></div>
            <!--<span class="avatar-initial rounded-circle bg-gray-600">s</span> -->
            <a use:link href="/dashboard/studyMaterial/liveLecture/edit/{lecture._id}">
              <div class="pd-l-10">
                <p class="tx-medium mg-b-0 tx-color-02 tx-capitalize"
                  style="width: 150px; overflow: hidden; height: 20px;">{lecture.name}</p>
                <small class="tx-12 tx-color-03 mg-b-0"><i data-feather="tv" class="wd-10 mg-r-5"></i>
                  {lecture.lecture_type}</small><br>
                <small class="tx-12 tx-color-03 mg-b-0">Date : {convertDateObjToStr(lecture.createdOn)}</small>
              </div>
            </a>
            <div class="mg-l-auto d-flex align-self-center">
              <nav class="nav nav-icon-only">
                <a use:link href="/dashboard/studyMaterial/liveLecture/edit/{lecture._id}"
                  class="nav-link d-none d-sm-block"><i data-feather="eye"></i></a>
                <a href="" class="nav-link d-sm-none"><i data-feather="more-vertical"></i></a>
              </nav>
            </div>
          </li>
          {/each}
          {/if}
        </ul>
        <div class="card-footer text-center tx-13">
          <a use:link href="/dashboard/studyMaterial/liveLecture/" class="link-03">View More Lectures <i
              class="icon ion-md-arrow-down mg-l-5"></i></a>
        </div><!-- card-footer -->
      </div><!-- card -->
    </div>
    {/if}

    {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD_VIEW_LATEST_TEST)}
    <div class="col-md-6 col-xl-4 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">New Tests</h6>
          <div class="d-flex align-items-center tx-18">
            <a use:link href="/dashboard/tests" class="link-03 lh-0 tx-13"><i data-feather="list"
                class="wd-15 mg-r-5 pd-b-5"></i>View all</a>
            <!-- <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a> -->
          </div>
        </div>
        <ul class="list-group list-group-flush tx-13">
          {#if !LatestTests.length}
          <li class="list-group-item d-flex pd-sm-x-20 justify-content-center">
            <p class="tx-medium mg-b-0 tx-color-03">No latest tests available!</p>
          </li>
          {:else}
          {#each LatestTests as test}
          <li class="list-group-item d-flex pd-sm-x-20">
            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600">T</span></div>
            <!--<span class="avatar-initial rounded-circle bg-gray-600">s</span> -->
            <a use:link href="/dashboard/tests/view/{test._id}">
              <div class="pd-l-10">
                <p class="tx-medium mg-b-0 tx-color-02 tx-capitalize"
                  style="width: 150px; overflow: hidden; height: 20px;">{test.name}</p>
                <small class="tx-12 tx-color-03 mg-b-0">Level : {test.difficulty ? test.difficulty : "-"}</small><br>
                <small class="tx-12 tx-color-03 mg-b-0">Start Date: {convertDateObjToStr(test.startDate)}</small><br>
                <small class="tx-12 tx-color-03 mg-b-0">End Date: {convertDateObjToStr(test.endDate)}</small>
              </div>
            </a>
            <div class="mg-l-auto d-flex align-self-center">
              <nav class="nav nav-icon-only">
                <a use:link href="/dashboard/tests/view/{test._id}" class="nav-link d-none d-sm-block"><i
                    data-feather="eye"></i></a>
                <a href="" class="nav-link d-sm-none"><i data-feather="more-vertical"></i></a>
              </nav>
            </div>
          </li>
          {/each}
          {/if}
        </ul>
        <div class="card-footer text-center tx-13">
          <a use:link href="/dashboard/tests" class="link-03">View More Tests <i
              class="icon ion-md-arrow-down mg-l-5"></i></a>
        </div><!-- card-footer -->
      </div><!-- card -->
    </div>
    {/if}

    {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD_VIEW_LATEST_HOLIDAY)}
    <div class="col-md-6 col-xl-4 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">Latest Holidays</h6>
          <div class="d-flex align-items-center tx-18">
            <a use:link href="/dashboard/holidays" class="link-03 lh-0 tx-13"><i data-feather="list"
                class="wd-15 mg-r-5 pd-b-5"></i>View all</a>
          </div>
        </div>
        <ul class="list-group list-group-flush tx-13">

          {#if (TodaysHolidays.length > 0)}
          <!-- <li class="list-group-item d-flex pd-sm-x-20 justify-content-center">
            <p class="tx-medium mg-b-0 tx-color-03">No latest holidays available!</p>
          </li> -->

          {#each TodaysHolidays as holiday}
          <li class="list-group-item d-flex pd-sm-x-20">
            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600">T</span></div>
            <a use:link href="/dashboard/holidays/view/{holiday._id}">
              <div class="pd-l-10">
                <p class="tx-medium mg-b-0 tx-color-02 tx-capitalize"
                  style="width: 150px; overflow: hidden; height: 20px;">{holiday.name}</p>
                <small class="tx-12 tx-color-03 mg-b-0">Date: {convertDateObjToStr(holiday.date)}</small><br>
              </div>
            </a>
            <div class="mg-l-auto d-flex align-self-center">
              <nav class="nav nav-icon-only">
                <a use:link href="/dashboard/holidays/view/{holiday._id}" class="nav-link d-none d-sm-block"><i
                    data-feather="eye"></i></a>
                <a href="" class="nav-link d-sm-none"><i data-feather="more-vertical"></i></a>
              </nav>
            </div>
          </li>
          {/each}
          {/if}
        </ul>
        <div class="card-footer text-center tx-13">
          <a use:link href="/dashboard/holidays" class="link-03">View More Holidays <i
              class="icon ion-md-arrow-down mg-l-5"></i></a>
        </div>
      </div>
    </div>
    {/if}

    {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD_VIEW_LATEST_STUDYREPORT)}
    <div class="col-md-6 col-xl-4 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">New Study Report</h6>
          <div class="d-flex align-items-center tx-18">
            <a use:link href="/dashboard/reports/study" class="link-03 lh-0 tx-13"><i data-feather="list"
                class="wd-15 mg-r-5 pd-b-5"></i>View all</a>
            <!-- <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a> -->
          </div>
        </div>
        <ul class="list-group list-group-flush tx-13">
          {#if !StudyReports.length}
          <li class="list-group-item d-flex pd-sm-x-20 justify-content-center">
            <p class="tx-medium mg-b-0 tx-color-03">No latest study reports available!</p>
          </li>
          {:else}
          {#each StudyReports as report}
          <li class="list-group-item d-flex pd-sm-x-20">
            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600">S</span></div>
            <!--<span class="avatar-initial rounded-circle bg-gray-600">s</span> -->
            <a use:link href="/dashboard/reports/study/{report._id}">
              <div class="pd-l-10">
                <p class="tx-medium mg-b-0" style="width: 150px; overflow: hidden; height: 20px;">
                  {(report.folders).map((item) => item.name).join(" > ")}</p>
                <small class="tx-12 tx-color-02 mg-b-0 tx-capitalize" style="width: 150px; overflow: hidden;">By Student
                  : {report.studentName}</small><br>
                <small class="tx-12 tx-color-03 mg-b-0">Date : {convertDateObjToStr(report.createdOn)}</small>
              </div>
            </a>
            <div class="mg-l-auto d-flex align-self-center">
              <nav class="nav nav-icon-only">
                <a use:link href="/dashboard/reports/study/{report._id}" class="nav-link d-none d-sm-block"><i
                    data-feather="eye"></i></a>
                <!-- <a href="" class="nav-link d-none d-sm-block"><i data-feather="user"></i></a>
                  <a href="" class="nav-link d-sm-none"><i data-feather="more-vertical"></i></a> -->
              </nav>
            </div>
          </li>
          {/each}
          {/if}
        </ul>
        <div class="card-footer text-center tx-13">
          <a use:link href="/dashboard/reports/study" class="link-03">View More Study Reports <i
              class="icon ion-md-arrow-down mg-l-5"></i></a>
        </div><!-- card-footer -->
      </div><!-- card -->
    </div>
    {/if}
    {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD_VIEW_LATEST_FOLLOWUP)}
    <div class="col-md-6 col-xl-4 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">Todays Follow Ups</h6>
          <div class="d-flex align-items-center tx-18">
            <a use:link href="/dashboard/enquiries" class="link-03 lh-0 tx-13"><i data-feather="list"
                class="wd-15 mg-r-5 pd-b-5"></i>View all</a>
            <!-- <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a> -->
          </div>
        </div>
        <ul class="list-group list-group-flush tx-13">
          {#if !TodaysFollowups.length}
          <li class="list-group-item d-flex pd-sm-x-20 justify-content-center">
            <p class="tx-medium mg-b-0 tx-color-03">No today's follow ups available!</p>
          </li>
          {:else}
          {#each TodaysFollowups as followUps}
          <li class="list-group-item d-flex pd-sm-x-20">
            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600">S</span></div>
            <!--<span class="avatar-initial rounded-circle bg-gray-600">s</span> -->
            <a use:link href="/dashboard/enquiries">
              <div class="pd-l-10">
                <p class="tx-medium mg-b-0 tx-color-02 tx-capitalize"
                  style="width: 150px; overflow: hidden; height: 20px;">{(followUps.user.name)}</p>
                <small class="tx-12 tx-color-02 mg-b-0">Contact No. : {followUps.user.mobileNo}</small><br>
                <small class="tx-12 tx-color-03 mg-b-0">Next Follow Up :
                  {convertDateObjToStr(followUps.nextFollowupDate)}</small><br>
                <small class="tx-12 tx-color-03 mg-b-0">Date : {convertDateObjToStr(followUps.createdOn)}</small>
              </div>
            </a>
            <div class="mg-l-auto d-flex align-self-center">
              <nav class="nav nav-icon-only">
                <a use:link href="/dashboard/enquiries" class="nav-link d-none d-sm-block"><i
                    data-feather="eye"></i></a>
                <!-- <a href="" class="nav-link d-none d-sm-block"><i data-feather="slash"></i></a>
                  <a href="" class="nav-link d-none d-sm-block"><i data-feather="user"></i></a>
                  <a href="" class="nav-link d-sm-none"><i data-feather="more-vertical"></i></a> -->
              </nav>
            </div>
          </li>
          {/each}
          {/if}
        </ul>
        <div class="card-footer text-center tx-13">
          <a use:link href="/dashboard/enquiries" class="link-03">View More Follow Ups<i
              class="icon ion-md-arrow-down mg-l-5"></i></a>
        </div><!-- card-footer -->
      </div><!-- card -->
    </div>
    {/if}

    {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD_VIEW_LATEST_FILEUPLOADS)}
    <div class="col-md-6 col-xl-4 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">Latest File Uploads</h6>
          <div class="d-flex align-items-center tx-18">
            <a use:link href="/dashboard/studyMaterial" class="link-03 lh-0 tx-13"><i data-feather="list"
                class="wd-15 mg-r-5 pd-b-5"></i>View all</a>
            <!-- <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a> -->
          </div>
        </div>
        <ul class="list-group list-group-flush tx-13">
          {#if !FileUploads.length}
          <li class="list-group-item d-flex pd-sm-x-20 justify-content-center">
            <p class="tx-medium mg-b-0 tx-color-03">No latest file uploads available!</p>
          </li>
          {:else}
          {#each FileUploads as file}
          {#if file.type == 'liveLecture'}
          {:else}
          <li class="list-group-item d-flex pd-sm-x-20">
            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600">F</span></div>
            <!--<span class="avatar-initial rounded-circle bg-gray-600">s</span> -->
            <a use:link href="/dashboard/studyMaterial/{file.type}/edit/{file._id}">
              <div class="pd-l-10">
                <p class="tx-medium mg-b-0 tx-color-02 tx-capitalize"
                  style="width: 150px; overflow: hidden; height: 20px;">{file.name}</p>
                <small class="tx-12 tx-color-03 mg-b-0">Type : {file.type}</small><br>
              </div>
            </a>
            <div class="mg-l-auto d-flex align-self-center">
              <nav class="nav nav-icon-only">

                <a use:link href="/dashboard/studyMaterial/{file.type}/edit/{file._id}"
                  class="nav-link d-none d-sm-block"><i data-feather="eye"></i></a>
                <!-- <a href="" class="nav-link  d-sm-block"><i data-feather="slash"></i></a>
                <a href="" class="nav-link  d-sm-block"><i data-feather="user"></i></a>
                <a href="" class="nav-link d-sm-none"><i data-feather="more-vertical"></i></a> -->
              </nav>
            </div>
          </li>
          {/if}
          {/each}
          {/if}
        </ul>
        <div class="card-footer text-center tx-13">
          <a use:link href="/dashboard/studyMaterial" class="link-03">View More Files <i
              class="icon ion-md-arrow-down mg-l-5"></i></a>
        </div><!-- card-footer -->
      </div><!-- card -->
    </div>
    {/if}
  </div><!-- row -->
</div>
