<script>

  import {
    Router,
    links,
    Link,
    Route,
    navigate
  } from "svelte-routing";

  import {
    createEventDispatcher,
    onMount, beforeUpdate,
    tick,
    afterUpdate
  } from 'svelte';
  import { userData } from "../stores";
  import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
  import ButtonLoader from "../utils/button_loader.svelte"
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import StudentComments from "../comments/index.svelte";
  const dispatch = createEventDispatcher();
  let isLoading = false;
  let emptyState = false
  let remark;
  let errorObj = {}
  export let studentId
  let _id;
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  }

  async function addRemark(_id) {
    isLoading = true

    let action = 'attendance.saveAttendance';
    var response = await api_handleApiCalls(action, data);
    return response.data;

  }

  function handleClick() {
    if (remark === "") {
      pError("Error", "No Remark Added")
      return;
    }
    isLoading = true
    pSuccess(null, "Remark Added Successfully !")
    remark = ""
    isLoading = false
  }

  const unsubscribe = userData.subscribe(value => {
    _id = value._id;
  });

  onMount(async () => {
    feather.replace();
    dispatch('setActiveTab', {
      tabName: 'conversation'
    });
    unsubscribe();
  });
</script>

<div class="row row-sm">
  <div class="col-md-12 col-sm-12">
    <div class="row">
      <div class="col-sm-12 ht-250">
        <div class="pd-t-20 pd-r-10 ht-100p">
          <!-- comments here -->
          <StudentComments id={studentId} isExport={true}/>
        </div>
      </div>
    </div>
  </div>

</div>
