<script>
  import { onMount } from "svelte";
  import { link, links, navigate } from "svelte-routing";
  import { activeMenu } from "../../stores";
  import Empty from "../empty/empty.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte"
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  let elem_datatable;
  let isEmpty = true;
  let isSearch = false;

  let filterModalThis;
let filterElemBatch;
let filterByPrefix;
let filterElemUser;
let filterStartDate;
let isLoading = false;
	

function hideFilterModel(params) {
    _$("#filter-modal").modal("hide");
     assignmentObj.topic = "";
   }
  function showFilterModel(params) {
    _$("#filter-modal").modal("show");
  }


let showHideFilterModal = ()=>{
   
    _$('#filter-modal').modal('show');
  }
  
  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [day, month, year].join("-");
    return dateString;
  }
  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.studentName = order;
    } else if (sortOrder.column === 3) {
      sort.createdOn = order;
    } else if (sortOrder.column === 4) {
      sort.severity = order;
    } else if (sortOrder.column === 5) {
      sort.type = order;
    } else if (sortOrder.column === 6) {
      sort.status = order;
    } else {
      sort.feedbackId = order;
    }
    return sort;
  }
  function initDatatable() {
    _$(elem_datatable).DataTable({
      // dom: 'flr<"toolbar">tip',
      serverSide: true,
      responsive: false,
      language: {
        processing: "",
        searchPlaceholder: "Student ID | Name",
        sSearch: "",
        lengthMenu: "_MENU_ Feedback/page",
      },
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data));
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname));
      },
      ajax: async function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        delete data.order;
        isSearch = Boolean(data.search.value);
        console.info(data);
        // fetch call
        let response = await api_handleApiCalls("feedback.fetchFeedbackList", data);
        console.info({ response });
        if (response.status) {
          var paginationData = response.data;
          var result = {
            recordsTotal: Number(paginationData.recordsTotal),
            recordsFiltered: Number(paginationData.recordsFiltered),
            data: paginationData.data,
          };
          isEmpty = !Boolean(result.data.length);
          callback(result);
        } else {
          console.error("something went wrong");
          var result = {
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          };
          isEmpty = true;
          callback(result);
        }
      },
      columns: [
        { data: "feedbackId" },
        { data: "studentName",
        render: (data,type,full) => {
          let image;
          // if image found display profilePic avatar
          image = `<img src="${
          full.profilePic ? full.profilePic : "/images/man.png"
          }" class="avatar mg-r-15" alt="${data[0].toUpperCase()}">`;     
            return `
            <div class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 btn-view-display" feedback-id="${full._id}">
              ${image}
              <div class="wd-80p bg-transparent">
                <h6 class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent tx-capitalize">${
                  data || "-" 
                } <span class = "  tx-11 text-capitalize"> (${full.role || "-"})  </span></h6> 
                <span class="d-block tx-11 text-muted bg-transparent">
                    <i data-feather="phone"></i> 
                    ${full.mobileNo || "-"}
                </span>
               
              </div>
            </div>`;
          },
       },
        {
          data: "query",
          orderable: false,
          render: (data,type,full) => {
             let date = convertDateObjToStr(full.createdOn) ;
            return `
            <div style="width:300px; max-height: 45px !important; overflow: hidden; text-overflow: ellipsis;  cursor: pointer;" class = "btn-view tx-capitalize" data-id="${full._id}">
              ${data}
            </div>

           <span class="d-block tx-12 text-muted bg-transparent">
                   Added on : ${date}
            </span>
          
            <span class="d-block tx-12 text-muted bg-transparent ">
                   Type : <span class = "text-uppercase"> ${full.type} <span>
            </span>
          `;
          },
        },
        // { data: "createdOn", render: (data) => convertDateObjToStr(data) },
        {
          data: "severity",
          render: (data) => {
            switch (data) {
              case 1:
                return `<div style="text-align: center;"><div class = "badge badge-danger">Very High</div></div>`;
              case 2:
                return `<div style="text-align: center;"><div class = "badge badge-warning">High</div></div>`;
              case 3:
                return `<div style="text-align: center;"><div class = "badge badge-primary">Medium</div></div>`;
              case 4:
                return `<div style="text-align: center;"><div class = "badge badge-secondary">Low</div></div>`;
              case 5:
                return `<div style="text-align: center;"><div class = "badge badge-light">Very Low</div></div>`;
              default:
                return "--";
            }
          },
        },
        // {
        //   data: "type",
        //   render: (data) =>
        //     `<div style="text-transform:capitalize">${data}</div>`,
        // },
        {
          data: "status",
          render: (data) =>
            `<div style="text-transform:capitalize; text-align: center; ">${data}</div>`,
        },
        {
          data: "_id",
          orderable: false,
          render: (data, a, b, c) => {
            // console.table({ data, a, b, c });
            return `
                  <div style="text-align: center;">
                    <a href="javascript:void(0)" data-id="${data}" class='btn-view mg-r-5' data-toggle="tooltip" data-placement="bottom" title="View"><i data-feather="eye" ></i> </a>
                    <!--a href="javascript:void(0)" class='btn-archive mg-r-5' data-id='${data}' data-toggle="tooltip" data-placement="bottom" title="Delete"><i data-feather="trash" ></i> </a-->
                  </div>
                    `;
          },
        },
      ],
      columnDefs: [
        { targets: 0, width: "5%" },
        { targets: 1, width: "18%" },
        { targets: 2, width: "15%" },
        { targets: 3, width: "10%" },
        { targets: 4, width: "10%" },
        { targets: 5, width: "5%" },
        // { targets: 6, width: "12%" },
        // { targets: 7, width: "12%" },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        _$(".btn-view").on("click", function (event) {
          let id = _$(this).data("id");
          // _$(this).tooltip("dispose");
          let route = `/dashboard/feedback/${id}`;
          navigate(route);
        });

             _$(".btn-view-display").on("click", function(event) {
             
             let id = _$(this).attr("feedback-id");
            
             let route = `/dashboard/feedback/${id}`;
             navigate(route);
             })   



        
        // _$(".btn-archive").on("click", async function (event) {
        //   let id = _$(this).data("id");
        //   // _$(this).tooltip("dispose");
        //   pConfirm(
        //     "Delete user?",
        //     "Are you sure you want to delete this user?",
        //     async function () {
        //       console.info("Deleting");
        //       //   delete api_handleApiCalls
        //       let result = await api_delete({ _id: Id });
        //       if (result.status) {
        //         // reload table
        //         pSuccess("", result.data);
        //         reload();
        //       } else {
        //         pError("", result.errMsg);
        //       }
        //     }
        //   );
        // });
           

        _$("#table tbody").on("click", "tr td", function (e) {
          // responsive expand button clicked
          feather.replace({ width: "1em", height: "1em" });
          _$(".btn-view").text("View");
          _$(".btn-archive").text("Archive");
        });
        // _$('[data-toggle="tooltip"]').tooltip();
      },
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }
  onMount(function () {
    feather.replace();
    activeMenu.set("feedback");
    initDatatable();
  });
</script>

<style>
</style>

<div class="content-body animated scale">
  <!-- breadcrumbs -->
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Feedback</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Feedback</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search all the feedbacks.</p>
    </div>
    <div class="d-block">
      
      <button class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase" on:click={()=>{showHideFilterModal(true)}}> <i data-feather="filter" class="mg-r-5"></i> Filters</button>
      
  </div>
    
  </div>
  <!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
aria-labelledby="exampleModalLabel6" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
            <a role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </a>
            <div class="media align-items-center">
                <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter"
                        class="wd-60 ht-60"></i></span>
                <div class="media-body mg-sm-l-20">
                    <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                    <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
                </div>
            </div><!-- media -->
        </div><!-- modal-header -->

        <hr class="full-width">
        <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
          <div class="form-group">
            <label class="d-block">Severity:</label>
            <input type="text" class="form-control follow-up-date" placeholder="Severity"/>
        </div>
        <div class="form-group">
          <label class="d-block">Status:</label>
          <input type="text" class="form-control follow-up-date" placeholder="Status"/>
        </div>
        <div class="form-group">
          <label class="d-block">Student name:</label>
          <input type="text" class="form-control follow-up-date" placeholder="Student name"/>
        </div>
        <!-- <div class="form-group">
          <label class="d-block">Type:</label>
          <input type="text" class="form-control follow-up-date" placeholder="Type"/>
      </div> -->
          <div class="form-group">
                <label class="d-block">Added on Date:</label>
                <input type="date" onkeydown="return false"  bind:value={filterStartDate} class="form-control follow-up-date" placeholder="Added on Date"/>
            </div>

            <div class="d-md-flex justify-content-end mg-t-25">
                <button disabled={isLoading} 
                    class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal">CLEAR
                    <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                <button disabled={isLoading} 
                    class="btn btn-sm pd-x-15 btn-primary mg-l-5" data-dismiss="modal">
                    <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                    <i data-feather="filter" class="wd-10 mg-l-5"></i>
                </button>
            </div>
        </div>

    </div>
</div>
</div>
<!-- Modal -->
  <hr class="full-width">
  <div data-label="Example" class="df-example demo-table">
    <table bind:this={elem_datatable} class="table wd-99p">
      <thead>
        <tr>
          <th style="text-align: center;">#</th>
          <th>User Name</th>
          <th>Query</th>
          <!-- <th>Added On</th> -->
          <th style="text-align: center;">Severity</th>
          <!-- <th>Type</th> -->
          <th style="text-align: center;">Status</th>
          <th style="text-align: center;">Actions</th>
        </tr>
      </thead>
      <tbody />
    </table>
    {#if isEmpty}
      {#if isSearch}
        <Empty mode="search" />
      {:else}
        <Empty mode="empty" />
      {/if}
    {/if}
  </div>
</div>




