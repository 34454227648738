<script>
  import { links, link, navigate } from "svelte-routing";
  import { onMount, tick, afterUpdate } from "svelte";
  import { activeMenu } from "../stores";
  import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
  import ButtonLoader from "../utils/button_loader.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import MODULES from "../utils/modules.js"
  import { API_URL } from "../../config.js"
  let isLoadingList = true;
  let isLoading = false;
  let addNewClassModal;
  const classObj = { name: "" };
  let errorObj = {};
  let displayText = "Class List";
  let classesList = { data: [] };
  let search = { value: "" };
  let sessionDataTable;
  let elem_sessionDataTable
  const pageSize = 100;

  async function api_delete(data) {

    let action = "doubtSession.deleteSession";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_list(data) {

    let action = "doubtSession.list";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_getbyid(data) {

    let action = "instituteClass.fetchInstituteClass";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_save(data) {

    let action = "instituteClass.saveInstituteClass";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function showModal() {

    navigate("/dashboard/doubtSession/new");
    //   _$(addNewClassModal).modal("show");
    //   _$(".first-input").focus();
    //   clearFields();
    //   await tick();
  }

  async function hideModal() {
    _$(addNewClassModal).modal("hide");
    clearFields();
  }

  function validate(name) {
    //   clear previous validations before validating anew
    clearValidations();

    if (!name) {
      errorObj.name = "Class name can not be empty";
      return false
    }

    var regexpStartingWithAlphabet = new RegExp(`^[A-Za-z0-9]`);

    name = name.trim();

    if (!name.match(regexpStartingWithAlphabet)) {

      errorObj.name = "Name must begin with A-Z or a-z characters";
    } else if (name.length < 3) {

      errorObj.name = "Name should be minimum 3 characters long";
    }

    if (Object.values(errorObj).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async function submitForm(event) {
    event.preventDefault();

    if (isLoading) return

    const isValid = validate(classObj.name);

    if (!isValid) return


    let data = { name: classObj.name }
    if (classObj._id) data._id = classObj._id;

    isLoading = true;
    let result = await api_save(data);
    isLoading = false;
    await tick();

    if (result.status) {
      pSuccess(null, result.data);
      hideModal();
      reload();
    } else {
      pError("Error!", result.errMsg)
    }



  }

  async function clearFields() {
    // form fields
    if (classObj._id) delete classObj._id;
    classObj.name = "";
    await clearValidations();
  }

  async function clearValidations() {
    errorObj = {};
    await tick();
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    // if (sortOrder.column === 1) {
    //   sort.name = order;
    // } else if (sortOrder.column === 2) {
    //   sort.createdOn = order;
    // } else {
    //   sort.seqId = order;
    // }
    sort.seqId = order;
    return sort;
  }

  async function fetchCourseList() {
    let data = {
      start: 0,
      length: pageSize,
      search,
      sort: parseSorts({ 0: "dsc" }),
    };
    console.info(data);
    // fetch call
    isLoadingList = true;
    await tick();
    let response = await api_list(data);
    console.info({ response });
    if (response.status) {
      // var paginationData = response.data;
      classesList = response.data
    } else {
      console.error("something went wrong");
      classesList = {
        recordsTotal: 0,
        recordsFiltered: 0,
        data: [],
      };
    }
    isLoadingList = false;
    await tick();
  }

  async function editSession(_id) {
    navigate(`/dashboard/doubtSession/new/${_id}`)
  }

  function deleteSession(courseId) {
    pConfirm(
      "Delete Session?",
      "Are you sure you want to delete this doubt session?",
      async function () {
        //   delete api
        let result = await api_delete({ _id: courseId });
        if (result.status) {
          pSuccess(null, result.data);
          await fetchCourseList();
        } else if (result.errMsg) {
          pError(null, result.errMsg);
        } else if (result.error) {
          pError(null, result.error);
        } else {
          pError(null, "Something went wrong");
        }
      }
    );
  }

  async function reload() {
    //   await fetchCourseList();
    await bindDatatable();
    feather.replace({ width: "1em", height: "1em" });
  }

  function bindDatatable() {
    sessionDataTable = _$(elem_sessionDataTable).DataTable({

      responsive: false,
      language: {
        processing: "",
        searchPlaceholder: 'Session topic | venue ',
        sSearch: '',
        lengthMenu: '_MENU_ Sessions/page',
      },
      ajax: function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        // var filterObj = { ...getFilterData() }
        // filterObj.start = data.start
        // filterObj.length = data.length
        // filterObj.search = data.search.value
        // filterObj.draw = data.draw
        // filterObj.sort = data.sort
        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: 'doubtSession.list',
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: 10000,
              search: data.search,
              draw: data.draw,
              sort: data.sort,
            },

          },
          success: function (resData) {
            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.recordsTotal),
                recordsFiltered: Number(paginationData.recordsFiltered),
                data: paginationData.data
              }
              callback(result);
            }
            else {
              console.log("Something went wrong");
            }
          }
        })
      },
      columns: [
        {
          data: 'seqId', defaultContent: "-",
          render: function (data, type, row, meta) {
            return `<div class="d-flex justify-content-center" style = "text-align:center">${data}</div>`;
          }
        },
        {
          data: 'topic', render: function (data, type, row, meta) {

            return `<div class="d-flex justify-content-center">${data}</div>`;
          }
        },
        {
          data: 'venue', render: function (data, type, row, meta) {

            return `<div class="d-flex justify-content-center" style = "text-align:center">${data}</div>`;

          }
        },
        // { data: 'code' },
        {
          data: 'sessionDate',
          defaultContent: "-",
          render: function (data, type, row, meta) {
            return `<div class="d-flex justify-content-center">${convertDateObjToStr(data)}</div>`;
          }
        },
        {
          data: 'sessionAttendies',
          defaultContent: "-",
          render: function (data, type, row, meta) {
            return `<div class="d-flex justify-content-center">${data && Array.isArray(data) ? data.length : 0}</div>`;
          }
        },
        {
          data: 'yourPresence',
          defaultContent: "-",
          render: function (data, type, row, meta) {
            return `<div class="d-flex justify-content-center">${data ? "Present" : "Absent"}</div>`;
          }
        },
        {
          data: 'startTime', render: function (data, type, row, meta) {
            if (!data) {
              return `<div class="d-flex justify-content-center">-</div>`
            }
            let d = new Date(data)
            let date = moment(d).format("LT");//LT
            return `<div class="d-flex justify-content-center">${date}</div>`

          }
        },
        {
          data: 'endTime', render: function (data, type, row, meta) {

            if (!data) {
              return `<div class="d-flex justify-content-center">-</div>`
            }
            let d = new Date(data)
            let date = moment(d).format("LT");//LT
            return `<div class="d-flex justify-content-center">${date}</div>`

          }
        },
        {
          data: '_id', render: function (data, type, row, meta) {
            var view = "-"
            var archive = ""
            if (MODULES.hasAccess(MODULES.EDIT_DOUBT_SESSION) && !(MODULES.hasAccess(MODULES.DELETE_DOUBT_SESSION))) {
              view = `<a href="#" class="rename btn-edit-session mg-r-5" session-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i data-feather="edit" class="wd-15 mg-r-5"></i></a>`
            } else if (MODULES.hasAccess(MODULES.DELETE_DOUBT_SESSION) && !(MODULES.hasAccess(MODULES.EDIT_DOUBT_SESSION))) {
              archive = ` <a href="#" class="delete btn-delete-session mg-r-5" session-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete"><i data-feather="trash" class="wd-15 mg-r-5"></i></a>`
            } else if (!(MODULES.hasAccess(MODULES.DELETE_DOUBT_SESSION)) && !(MODULES.hasAccess(MODULES.EDIT_DOUBT_SESSION))) {
              view = "-"
              archive = ""
            } else {
              view = `<a href="#" class="rename btn-edit-session mg-r-5" session-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i data-feather="edit" class="wd-15 mg-r-5"></i></a>`
              archive = `<a href="#" class="delete btn-delete-session mg-r-5" session-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete"><i data-feather="trash" class="wd-15 mg-r-5"></i></a>`
            }
            return `
                        <div class="d-md-flex flex-row justify-content-center">
                            ${view}
                            ${archive}
                        </div>
                    `;
          }
        },
      ],
      "drawCallback": function () {
        feather.replace()
        _$('.btn-edit-session').unbind('click').click(function (e) {
          e.preventDefault();
          var sessionId = _$(this).attr("session-id");
          navigate(`/dashboard/doubtSession/new/${sessionId}`)

        })
        _$('.btn-delete-session').unbind('click').click(function (e) {
          e.preventDefault();
          var sessionId = _$(this).attr("session-id");
          const notice = PNotify.notice({
            title: "Delete session",
            text: "Are you sure you want to delete this session ?",
            hide: false,
            closer: false,
            sticker: false,
            modules: new Map([
              ...PNotify.defaultModules,
              [PNotifyConfirm, { confirm: true }],
            ]),
          })


          notice.on("pnotify:confirm", async () => {
            try {
              var deleteSessionRes = await api_delete({ _id: sessionId });
              if (deleteSessionRes.status) {
                PNotify.success({
                  title: 'Success',
                  text: "Session Deleted Successfully",
                  delay: 3000
                })
              } else {
                PNotify.error({
                  title: 'Error',
                  text: deleteSessionRes.errMsg,
                  delay: 3000
                })
              }

            } catch (error) {
              console.error(error);
            }
            sessionDataTable.ajax.reload();

          })

        })

        // bind actions 
      },
      "processing": true,
      "serverSide": true,
    });
    _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
  }


  function init() {
    activeMenu.set("doubtSession");
    console.log("adsfdsfdsfadfdssfdfsdsddsdfs")
    reload();
  }

  onMount(init);

  afterUpdate(function () {
    feather.replace({ width: "1em", height: "1em" });
  });

  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var dateString = [day, month, year].join("-");
    return dateString;
  }


</script>

<style>
  /* .long-name-wrap {
      word-break: break-all;
      overflow-wrap: break-word;
      min-widht: 90%
      max-widht: 90%
    } */

  .classes-cards-wrap {
    flex-wrap: wrap;
  }

  .no-select {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
  }
</style>


<div class="content-body fadeIn">
  <!-- <Link style="display: none;" to="about">About</Link> -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Doubt Sessions </li>

        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Doubt Sessions</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search doubt sessions or add a doubt sessions.
      </p>
    </div>
    <div class="d-block">
      {#if MODULES.hasAccess(MODULES.ADD_DOUBT_SESSION)}
      <button class="btn pd-x-15 pd-y-8 btn-primary btn-uppercase mg-l-5 btn-sm" on:click={showModal}><i
          data-feather="plus" class="wd-10 mg-r-5" />
        New Doubt Session
      </button>
      {/if}
    </div>
  </div>

  <hr class="full-width">
  <div>
    <table bind:this={elem_sessionDataTable} class="table">

      <thead>
        <tr>
          <th style="width: 5%;">Sr. No.</th>
          <th style="width: 5%; text-align: center;">Topic</th>
          <th style="width: 5%; text-align: center;">Venue</th>
          <th style="width: 7%; text-align: center;">Date</th>
          <th style="width: 10%; text-align: center;">Attendant count</th>
          <th style="width: 5%; text-align: center;">Presence</th>
          <th style="width: 6%; text-align: center;">Start Time</th>
          <th style="width: 6%; text-align: center;">End Time</th>
          <th style="text-align: center; width: 5%;">Actions</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
</div>
