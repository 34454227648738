<script>
  import { links, navigate } from "svelte-routing";
  import { afterUpdate, onMount } from "svelte";
  import AddNewTaskModal from "../add/index.svelte";
  import { pSuccess, pError, pConfirm } from "../../utils/notify.svelte";
  import { userData } from "../../stores";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import MODULES from "../../utils/modules.js"
  import { API_URL } from "../../../config.js"
  let dataTableInstance;
  let roleIsOrgAdmin;
  let elem_tasksDataTable;
  let showAddNewTaskModal = false;
  const isArchived = true;

  let filter = { assignedToMe: true, reportingToMe: false };
  let assingIdArr, reportToIdArr, priorityToIdArr, StatusToIdArr;
  let selectizeAssintoInstance, selectizeReporttoInstance, selectizeStatusInstance, selectizePriorityInstance;
  let filterModalThis;
  let filterElemBatch;
  let filterByPrefix;
  let filterElemUser, filterElemReportTo, filterCreatedOn, filterDueDate, filterPriority, filterStatus;
  let isLoading = false;


  function hideFilterModel(params) {
    _$("#filter-modal").modal("hide");
    assignmentObj.topic = "";
  }
  function showFilterModel(params) {
    _$("#filter-modal").modal("show");
  }


  let showHideFilterModal = () => {
    _$('#filter-modal').modal('show');
  }
  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [year, month, day].join("-");
    return dateString;
  }
  function clearFilter() {
    filterCreatedOn = null;
    selectizeAssintoInstance[0].selectize.clear()
    selectizeReporttoInstance[0].selectize.clear()
    filterDueDate = null;
    if (dataTableInstance) { dataTableInstance.draw() }
  }

  function openAddTask() {
    showAddNewTaskModal = true;
  }

  async function api_ReportToList() {
    var resData = await api_handleApiCalls('systemUser.fetchSystemUsersToAssign', {})
    if (resData.status) {

      return resData.data.data
    } else {
      return []
    }
  }

  async function api_AssingToList() {
    var resData = await api_handleApiCalls('systemUser.fetchUserReportsToUser', {})
    if (resData.status) {

      return resData.data.data
    } else {
      return []
    }
  }

  async function api_StatusList() {
    var resData = await api_handleApiCalls('taskManager.status', {})
    if (resData.status) {

      return resData.data.data
    } else {
      return []
    }
  }
  async function api_unarchiveTaskById(taskId) {
    let result = await api_handleApiCalls("taskManager.unarchieveTaskById", { _id: taskId })
    if (result.status) {
      pSuccess('', result.data)
      _$(elem_tasksDataTable).DataTable().ajax.reload(null, false);
    } else {
      pError('', result.errMsg || result.error || 'Something went wrong')
    }
  }
  async function api_priorityList() {
    var resData = await api_handleApiCalls('taskManager.updatePriority', {})
    if (resData.status) {

      return resData.data.data
    } else {
      return []
    }
  }

  async function bindSelect2() {


    selectizeAssintoInstance = _$(filterElemUser).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "Assigned To",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: assingIdArr,
    });

    selectizeReporttoInstance = _$(filterElemReportTo).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "Report To",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: reportToIdArr,
    });



  }

  function newTaskDone(event) {
    let newTaskId = event.detail.newTaskId;
    let modalClosed = event.detail.modalClosed;
    if (newTaskId) {
      showAddNewTaskModal = false;
      navigate("/dashboard/tasks/edit/" + newTaskId);
    } else if (modalClosed) {
      showAddNewTaskModal = false;
    } else {
      _$(elem_tasksDataTable).DataTable().ajax.reload(null, false);
    }
  }


  function applyFilter() {
    if (dataTableInstance) { dataTableInstance.draw() }
  }
  function getFilterData() {
    let assingIdArr = _$(selectizeAssintoInstance).val()
    let reportToIdArr = _$(selectizeReporttoInstance).val()
    return {
      assingIdArr, reportToIdArr, filterCreatedOn, filterDueDate
    }


  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.summary = order;
    } else if (sortOrder.column === 2) {
      sort.assignedTo = order;
    } else if (sortOrder.column === 2) {
      sort.reportTo = order;
    } else if (sortOrder.column === 3) {
      sort.status = order;
    } else if (sortOrder.column === 4) {
      sort.priority = order;
    } else if (sortOrder.column === 5) {
      sort.createdOn = order;
    } else if (sortOrder.column === 6) {
      sort.createdOn = order;
    } else {
      sort.taskId = order;
    }
    return sort;
  }

  function initDatatable() {
    dataTableInstance = _$(elem_tasksDataTable).DataTable({
      responsive: false,
      language: {
        processing: "<small class='tx-13 pd-5 mg-5'>... loading tasks</small>",
        searchPlaceholder: "Summary",
        sSearch: "",
        lengthMenu: "_MENU_ Tasks/page",
      },
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data));
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname));
      },
      ajax: function (data, callback, settings) {

        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: 'taskManager.list',
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: data.length,
              search: data.search.value,
              isArchived: isArchived
            }
          },
          success: function (resData) {
            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data
              }
              if (!resData.data.length) {
                emptyState = true
              }
              callback(result);
            }
            else {
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },
      columns: [
        {
          data: "taskId",
          defaultData: "-",
        },
        {
          data: "summary",
          defaultData: "-",
          render: function (data, type, full) {
            let createdOn = full.createdOn ? convertDateObjToStr(full.createdOn).split("-").reverse().join("-") : "-";
            let updatedOn = full.updatedOn ? convertDateObjToStr(full.updatedOn).split("-").reverse().join("-") : "-";
            return `
            <div title="${data}"
                style="width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; cursor:pointer;" class="btn-edit-task" task-id="${full._id}">
                ${data}
              </div>
           
            <span class="d-block tx-12 text-muted bg-transparent btn-edit-task" style="cursor:pointer;" task-id="${full._id}">
                   Created on : ${createdOn}
            </span>
  
            <span class="d-block tx-12 text-muted bg-transparent btn-edit-task" style="cursor:pointer;" task-id="${full._id}">
                   Updated on : ${updatedOn}
            </span>
            `;
          }
        },
        {
          data: "assignedTo",
          defaultData: "-",
          render: (data) => data ? `<div class="text-capitalize d-flex justify-content-center">${data.name}</div>` : '-',
          // render: (data, mode, row, settings) => {
          //   if (!(row.assignedTo && row.assignedTo._id)) {
          //     return `-`;
          //   }
          //   let image = "";
          //   // if image found display profilePic avatar
          //   image = `<img src="${
          //     data.profilePic ? data.profilePic : "/images/man.png"
          //   }" class="avatar mg-r-15" alt="${data.name}">`;
          //   return `
          //   <div class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0" >
          //     ${image}
          //     <div class="wd-80p bg-transparent">
          //       <h6 class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent">${data.name}</h6>
          //       <span class="d-block tx-11 text-muted bg-transparent">
          //         <!-- a href="tel:+91${data.mobileNo}" -->
          //           <i data-feather="phone"></i>
          //           ${data.mobileNo}
          //         <!--/a -->
          //       </span>
          //     </div>
          //   </div>`;
          // },
        },
        {
          data: "reportTo",
          defaultData: "-",
          render: (data) => data ? `<div class="text-capitalize d-flex justify-content-center">${data.name}</div>` : '-',
          // render: (data, mode, row, settings) => {
          //   if (!(row.reportTo && row.reportTo._id)) {
          //     return `-`;
          //   }
          //   let image = "";
          //   // if image found display profilePic avatar
          //   image = `<img src="${
          //     data.profilePic ? data.profilePic : "/images/man.png"
          //   }" class="avatar mg-r-15" alt="${data.name}">`;
          //   return `
          //   <div class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0" >
          //     ${image}
          //     <div class="wd-80p bg-transparent">
          //       <h6 class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent">${data.name}</h6>
          //       <span class="d-block tx-11 text-muted bg-transparent">
          //         <!-- a href="tel:+91${data.mobileNo}" -->
          //           <i data-feather="phone"></i>
          //           ${data.mobileNo}
          //         <!--/a -->
          //       </span>
          //     </div>
          //   </div>`;
          // },
        },
        {
          data: "status",
          render: function (data, type, row, meta) {
            switch (data) {
              case 0:
                return `<div class="d-flex justify-content-center"><div class="badge badge-primary">Open</div></div>`;
              case 1:
                return `<div class="d-flex justify-content-center"><div class="badge badge-info">Assigned</div></div>`;
              case 2:
                return `<div class="d-flex justify-content-center"><div class="badge badge-success">In Progress</div></div>`;
              case 3:
                return `<div class="d-flex justify-content-center"><div class="badge badge-warning">In Review</div></div>`;
              case 4:
                return `<div class="d-flex justify-content-center"><div class="badge badge-danger">Re-assigned</div></div>`;
              case 5:
                return `<div class="d-flex justify-content-center"><div class="badge badge-secondary">Completed</div></div>`;
                s;
              default:
                return `<div class="d-flex justify-content-center">-</div>`;
            }
          },
        },
        {
          data: "priority",
          render: function (data, type, row, meta) {
            switch (data) {
              case 1:
                return `<div class="d-flex justify-content-center"><div class="badge bd bd-danger tx-danger">Very High</div></div>`;
              case 2:
                return `<div class="d-flex justify-content-center"><div class="badge bd bd-warning tx-warning">High</div></div>`;
              case 3:
                return `<div class="d-flex justify-content-center"><div class="badge bd bd-success tx-success">Medium</div></div>`;
              case 4:
                return `<div class="d-flex justify-content-center"><div class="badge bd bd-info tx-info">Low</div></div>`;
              case 5:
                return `<div class="d-flex justify-content-center"><div class="badge bd">Very Low</div></div>`;
              default:
                return `<div class="d-flex justify-content-center">-</div>`;
            }
          },
        },
        // {//refer
        //   data: "createdOn",
        //   render: (data) =>
        //     data
        //       ? convertDateObjToStr(data).split("-").reverse().join("-")
        //       : "-",
        //   defaultData: "-",
        // },
        // {//refer
        //   data: "updatedOn",
        //   render: (data) =>
        //     data
        //       ? convertDateObjToStr(data).split("-").reverse().join("-")
        //       : "-",
        //   defaultData: "-",
        // },
        {
          data: "_id",
          orderable: false,
          render: function (data, type, row, meta) {
            var unarchive = ""
            unarchive = ` <a href="#" data-title="unarchive" class="unarchive btn-unarchive-task mg-r-5" task-id="${data}" data-toggle="tooltip" data-placement="top" title="Unarchive"><i data-feather="repeat" class="wd-20 mg-r-5"></i></a>`


            return `
              <div class="d-md-flex flex-row justify-content-center">
                 ${unarchive}
              </div>
          `;
          },
        },
      ],
      drawCallback: function () {
        feather.replace({ widht: "1em", height: "1em" });
        // _$(".btn-edit-task")
        //   .unbind("click")
        //   .click(function (e) {
        //     e.preventDefault();
        //     var taskId = _$(this).attr("task-id");
        //     navigate("/dashboard/tasks/edit/" + taskId);
        //   });
        _$(".btn-unarchive-task")
          .unbind("click")
          .click(function (e) {
            e.preventDefault();
            var taskId = _$(this).attr("task-id");
            api_unarchiveTaskById(taskId)

          })

        // bind actions
      },
      processing: true,
      serverSide: true,
    });

    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }

  let toggleTab = function (tabName) {
    if (tabName == 'assigned-to-me') filter = { assignedToMe: true, reportingToMe: false };
    else if (tabName == 'reporting-to-me') filter = { assignedToMe: false, reportingToMe: true };
    else if (tabName == 'all' && roleIsOrgAdmin) filter = { all: true };
    // if(roleIsOrgAdmin && tabName != 'all') filter.all = false
  }

  afterUpdate(function () {
  })

  const unsubscribe = userData.subscribe(value => {
    roleIsOrgAdmin = value.role == "orgAdmin"
  });
  onMount(async () => {
    assingIdArr = await api_ReportToList()
    reportToIdArr = await api_ReportToList()
    // priorityToIdArr = await api_priorityList()
    // StatusToIdArr = await api_StatusList()
    unsubscribe()
    toggleTab('assigned-to-me')
    bindSelect2();
    initDatatable();
    feather.replace();

  });


  let changeTaskListMode = () => {

    navigate(("/dashboard/tasks/archived"))
    // redrawEnquiryTable()
  }
</script>

<div class="content-body fadeIn">
  <!-- <Link style="display: none;" to="about">About</Link> -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item"><a href="/dashboard/tasks">Tasks</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Tasks</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Manage task and track work of staff.</p>
    </div>

  </div>
  <hr class="full-width">
  <div>
    <!-- tabs -->
    <ul class="nav nav-line mg-b-15" id="myTab" role="tablist">
      {#if roleIsOrgAdmin}
      <li class="nav-item">
        <a class="nav-link active" id="assigned-to-me-tab" data-toggle="tab" href="javascript:void(0)" role="tab"
          aria-controls="assigned-to-me" aria-selected="false" on:click={()=> {
          toggleTab('assigned-to-me')
          _$(elem_tasksDataTable).DataTable().ajax.reload(null, false);
          }}>Assigned to me</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="reporting-to-me-tab" data-toggle="tab" href="javascript:void(0)" role="tab"
          aria-controls="reporting-to-me" aria-selected="false" on:click={()=> {
          toggleTab('reporting-to-me')
          _$(elem_tasksDataTable).DataTable().ajax.reload(null, false);
          }}>Reporting to me</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="all-tasks-tab" data-toggle="tab" href="javascript:void(0)" role="tab"
          aria-controls="all-tasks" aria-selected="true" on:click={()=> {
          toggleTab('all')
          _$(elem_tasksDataTable).DataTable().ajax.reload(null, false);
          }}>All Tasks</a>
      </li>
      {:else}
      <li class="nav-item">
        <a class="nav-link active" id="assigned-to-me-tab" data-toggle="tab" href="javascript:void(0)" role="tab"
          aria-controls="assigned-to-me" aria-selected="false" on:click={()=> {
          toggleTab('assigned-to-me')
          _$(elem_tasksDataTable).DataTable().ajax.reload(null, false);
          }}>Assigned To Me</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="reporting-to-me-tab" data-toggle="tab" href="javascript:void(0)" role="tab"
          aria-controls="reporting-to-me" aria-selected="false" on:click={()=> {
          toggleTab('reporting-to-me')
          _$(elem_tasksDataTable).DataTable().ajax.reload(null, false);
          }}>Reporting To Me</a>
      </li>
      {/if}
    </ul>
    <table bind:this={elem_tasksDataTable} class="table table-sm">
      <thead>
        <tr>
          <th style="text-align: center; width: 5%;">#</th>
          <th style="width: 30%;">Summary</th>
          <th style="text-align: center; width: 15%;">Assigned to</th>
          <th style="text-align: center; width: 15%;">Report to</th>
          <th style="text-align: center; width: 10%;">Status</th>
          <th style="text-align: center; width: 10%;">Priority</th>
          <!-- <th class="wd-8p text-capitalize">created on</th>
            <th class="wd-8p text-capitalize">updated on</th> -->
          <th style="text-align: center; width: 8%;">Actions</th>
        </tr>
      </thead>
      <tbody />
    </table>
    <!-- <div class="tab-content bd bd-gray-300 bd-t-0 pd-20" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        </div>
      </div> -->
  </div>
</div>

{#if showAddNewTaskModal}
<AddNewTaskModal on:close={newTaskDone} />
{/if}

<!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->

      <hr class="full-width">
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
        <div class="form-group">
          <label class="d-block">Task Status:</label>
          <select disabled={isLoading} bind:this={filterStatus} class="form-control select2" multiple="multiple">
            <option> Task Status</option>
          </select>
        </div>
        <div class="form-group">
          <label class="d-block">Priority:</label>
          <select disabled={isLoading} bind:this={filterPriority} class="form-control select2" multiple="multiple">
            <option>Priority</option>
          </select>
        </div>
        <div class="form-group">
          <label class="d-block">Created On:</label>
          <input type="date" onkeydown="return false" bind:value={filterCreatedOn} class="form-control follow-up-date"
            placeholder="Created On" />
        </div>
        <div class="form-group">
          <label class="d-block">Due Date:</label>
          <input type="date" onkeydown="return false" bind:value={filterDueDate} class="form-control follow-up-date"
            placeholder="Due Date" />
        </div>
        <div class="form-group">
          <label class="d-block">Report To:</label>
          <select disabled={isLoading} bind:this={filterElemReportTo} class="form-control select2" multiple="multiple">
            <option> Report To</option>
          </select>
        </div>

        <div class="form-group">
          <label class="d-block">Assigned To:</label>
          <select disabled={isLoading} bind:this={filterElemUser} class="form-control select2" multiple="multiple">
            <option> Assigned To</option>
          </select>
        </div>

        <div class="d-md-flex justify-content-end mg-t-25">
          <button disabled={isLoading} on:click={clearFilter} class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5"
            data-dismiss="modal">CLEAR
            <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button disabled={isLoading} on:click={applyFilter} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
            data-dismiss="modal">
            <ButtonLoader isLoading={isLoading} btnText="APPLY" />
            <i data-feather="filter" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Modal -->
