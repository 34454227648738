<script>
    import { Router, Link, links, Route, navigate } from "svelte-routing";
    import { onMount, tick, beforeUpdate, afterUpdate, onDestroy } from "svelte";
    import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
    import {userData} from "../stores";
    import ProfileSideBar from "./profileSideBar.svelte"
    import Details from "./details.svelte"
    import Batches from "./batches/index.svelte"
    import Fees from "./fee/index.svelte"
    import TestResults from "../tests/myExamReports.svelte"
    import Performance from "./performance.svelte"
    import Notification from "./notification.svelte"
    // import Chat from "./chat.svelte"
    import Settings from "./settings.svelte";
    import AddEditParent from "./parents/AddEditParent.svelte";
    import Attendance from "./attendance.svelte";
    import Assignment from "./assignment.svelte";
    import Conversation from "./conversation.svelte"
    import StudyMonitoringChart from "./monitoringChart/index.svelte"
    import StudentFollowUp from "./studentsFollowUp.svelte"
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import MODULES from "../utils/modules.js" 
    import Forbidden from "../forbidden.svelte"
    import AddEditPoints from "./points/index.svelte";
    import {hasOptedForPoints, STUDY_MONITORING_ACCESS} from '../../orgAccessControl'
    export let studentId;
    let activeTab;
    let breadcrumbStudName = '-'
    let inactive = false
    let admissionCancel = false
    let roleIsTelecaller;
    let channelsList = []
    let role = "faculty"

    let localUserData = {
    fullName: "...",
    role: "...",
    avatar: "/images/man.png",
    access: {
      home: false,
      students: false,
      settings: false,
    },
  };
  
    function setActiveTab(event) {
        activeTab = event.detail.tabName;
    }

    async function api_getChannelListByUserId(id) {
        var resData = await api_handleApiCalls('students.getChannelListByUserId', { userId: id })
        if (resData.status) {
            channelsList = resData.data || []
        } else {
            pError("Error", resData.errMsg)
        }
    }
    async function api_getStudentNameById(id) {
        var resData = await api_handleApiCalls('students.getStudentNameById', { _id: id })
        if (resData.status) {
            return resData.data.name
        } else {

            pError("Error", resData.errMsg)
            return '-'
        }
    }

    async function api_getStudentInactiveStatusById(id) {
        var resData = await api_handleApiCalls('students.getStudentInactiveStatus', { _id: id })


        if (resData.status) {
            resData = resData.data
            return resData
        } else {

            pError("Error", resData.errMsg)
            return '-'
        }
    }
    async function setInactiveStatus() {
        let _status = await api_getStudentInactiveStatusById(studentId)
        inactive = _status.inactive
        admissionCancel = _status.admissionCancel
        await tick()
        feather.replace({ width: "1em", height: "1em" });
    }
    async function api_makeInactive(id, inactive) {
        var resData = await api_handleApiCalls('students.makeInactive', { _id: id, inactive: inactive })
        if (resData.status) {
            return resData.data
        } else {

            pError("Error", resData.errMsg)
            return '-'
        }
    }

    // let oldStudentId; 

    // beforeUpdate(() => {
    //     if(oldStudentId && oldStudentId != studentId){
    //         console.log("beforeUpdate : ", oldStudentId, studentId)
    //         setBreadcrumbStudName()
    //     }
    //     oldStudentId = studentId;
    // });


    async function setBreadcrumbStudName() {
        breadcrumbStudName = await api_getStudentNameById(studentId)
        feather.replace();
    }
    let btnActiveInactiveClickHandler = async () => {
        let res = await api_makeInactive(studentId, inactive)
        if (res) {
            pSuccess("Success!", res)
            setInactiveStatus()
        }
    }

    function to(path) {
        navigate("/dashboard/student/view/" + studentId + "/" + path)
    }

    function handleParentModal(e) {
        e.preventDefault();
        _$(addParent).modal('show');
    }

    let isPoints = false
    const unsubscribe = userData.subscribe(value => {
        localUserData = value;
        roleIsTelecaller = value.role == "telecaller"
        isPoints = hasOptedForPoints(value)
    });

    onMount(async () => {
        unsubscribe();
        feather.replace({ width: "1em", height: "1em" });
        setInactiveStatus()
        setBreadcrumbStudName()
        api_getChannelListByUserId(studentId)
    })

</script>
<style>
    .inactivate:hover {
        background-color: #dc3545;
        border-color: #dc3545;
    }

    .activate:hover {
        background-color: #0d9448;
        border-color: #0c8842;
    }
</style>

<div class="content-body">

    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a use:links href="/dashboard/">Dashboard</a></li>
                    <li class="breadcrumb-item"><a use:links href="/dashboard/students">Student</a></li>
                    <li class="breadcrumb-item" aria-current="page"> {breadcrumbStudName} </li>
                    <li class="breadcrumb-item active" aria-current="page">Information</li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1 tx-uppercase">
                {breadcrumbStudName?breadcrumbStudName:'-'}

            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">Student Summary</p>
        </div>
        <!-- {#if !roleIsTelecaller}
        <div class="d-none d-md-block">
            {#if inactive}
            <button on:click={btnActiveInactiveClickHandler}
                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 activate">
                <i data-feather="eye" class="wd-10 mg-r-5"></i>
                Activate For All
            </button>

            {:else}
            <button on:click={btnActiveInactiveClickHandler}
                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 inactivate">
                <i data-feather="eye-off" class="wd-10 mg-r-5"></i>
                Inactivate From All
            </button>
            {/if}
        </div>
        {/if} -->
    </div>

    <hr class="full-width">
    <div class="pd-x-0 tx-13">
        <div class="media d-block d-lg-flex">
            <ProfileSideBar inactive={inactive} admissionCancel={admissionCancel} studentId={studentId} />
            <div class="media-body mg-t-40 mg-lg-t-0 pd-lg-x-10">
                <ul class="nav nav-line " id="myTab" role="tablist" style="margin-bottom: 20px;">
                    <li class="nav-item" on:click={()=> to('')} >
                        <a class="nav-link" class:active={(activeTab=='Details' )} data-tab-name="Details"
                            data-toggle="tab" href="#">Details</a>
                    </li>
                    {#if MODULES.hasAccess(MODULES.ASSIGN_BATCH) && MODULES.hasAccess(MODULES.UPDATE_ASSIGNED_BATCH) }
                    <li class="nav-item" on:click={()=> to('batches')}>
                        <a class="nav-link" class:active={(activeTab=='Batches' )} data-tab-name="Batches"
                            data-toggle="tab" href="#">Batches</a>
                    </li>
                    {/if}
                    <li class="nav-item" on:click={()=> to('fees')}>
                        <a class="nav-link" class:active={(activeTab=='Fees' )} data-tab-name="Fees" data-toggle="tab"
                            href="#">Fees</a>
                    </li>
                    <li class="nav-item" on:click={()=> to('attendance')}>
                        <a class="nav-link" class:active={(activeTab=='Attendance' )} data-tab-name="Attendance"
                            data-toggle="tab" href="#">Attendance</a>
                    </li>
                    <li class="nav-item" on:click={()=> to('assignment')}>
                        <a class="nav-link" class:active={(activeTab=='Assignment' )} data-tab-name="Assignment"
                            data-toggle="tab" href="#">Assignment</a>
                    </li>
                    <li class="nav-item" on:click={()=> to('testResults')}>
                        <a class="nav-link" class:active={(activeTab=='TestResults' )} data-tab-name="TestResults"
                            data-toggle="tab" href="#">Test Results</a>
                    </li>
                    {#if role === "faculty" || role === "orgAdmin" || role === "admin" }
                    <li class="nav-item" on:click={()=> to('conversation')}>
                        <a class="nav-link" class:active={(activeTab=='Conversation' )} data-tab-name="Conversation"
                            data-toggle="tab" href="#">Conversation</a>
                    </li>
                    {/if}

                    {#if localUserData && STUDY_MONITORING_ACCESS.indexOf(localUserData.orgCode.toString()) != -1}
                        <li class="nav-item" on:click={()=> to('studyMonitoringChart')}>
                            <a class="nav-link" class:active={(activeTab=='studyMonitoringChart' )} data-tab-name="studyMonitoringChart"
                                data-toggle="tab" href="#">Monitoring Chart</a>
                        </li>
                    {/if}
                    <!-- <li class="nav-item" on:click={()=> to('studentfollowups')}>
                        <a class="nav-link" class:active={(activeTab=='StudentFollowUp' )} data-tab-name="Performance"
                            data-toggle="tab" href="#">Follow Ups</a>
                    </li> -->
                    <!-- <li class="nav-item" on:click={()=> to('notification')}>
                        <a class="nav-link" class:active={(activeTab=='Notification' )} data-tab-name="Notification"
                            data-toggle="tab" href="#">Notification</a>
                    </li>
                    <li class="nav-item" on:click={()=> to('chat')}>
                        <a class="nav-link" class:active={(activeTab=='Chat' )} data-tab-name="Chat" data-toggle="tab"
                            href="#">Chat</a>
                    </li>
                    <li class="nav-item" on:click={()=> to('settings')}>
                        <a class="nav-link" class:active={(activeTab=='Account Settings' )}
                            data-tab-name="Account Settings" data-toggle="tab" href="#">Account Settings</a>
                    </li> -->
                </ul>
                <Router>
                    <Route path="testResults">
                        {#if MODULES.hasAccess(MODULES.VIEW_STUDENT_DETAILS)}
                        <TestResults on:setActiveTab={setActiveTab} mode="cmp" userId={studentId}></TestResults>
                        {:else}
                        <Forbidden />
                        {/if}
                    </Route>
                    <Route path="fees">
                        {#if MODULES.hasAccess(MODULES.VIEW_STUDENT_DETAILS)}
                        <Fees on:setActiveTab={setActiveTab} studentId={studentId}></Fees>
                        {:else}
                        <Forbidden />
                        {/if}
                    </Route>
                    <Route path="assignment">
                        {#if MODULES.hasAccess(MODULES.VIEW_STUDENT_DETAILS)}
                        <Assignment on:setActiveTab={setActiveTab} studentId={studentId}></Assignment>
                        {:else}
                        <Forbidden />
                        {/if}
                    </Route>
                    <Route path="attendance">
                        {#if MODULES.hasAccess(MODULES.VIEW_STUDENT_DETAILS)}
                        <Attendance on:setActiveTab={setActiveTab} studentId={studentId}></Attendance>
                        {:else}
                        <Forbidden />
                        {/if}
                    </Route>
                    <Route path="notification">
                        <Notification on:setActiveTab={setActiveTab} studentId={studentId}></Notification>
                    </Route>
                    <Route path="chat">
                        <Chat on:setActiveTab={setActiveTab} studentId={studentId}></Chat>
                    </Route>
                    <Route path="settings">
                        <Settings on:setActiveTab={setActiveTab} studentId={studentId}></Settings>
                    </Route>
                    <!-- <Route path="performance">
                        <Performance on:setActiveTab={setActiveTab} studentId={studentId}></Performance>
                    </Route> -->
                    <Route path="details">
                        {#if MODULES.hasAccess(MODULES.VIEW_STUDENT_DETAILS)}
                        <Details on:setActiveTab={setActiveTab} studentId={studentId}></Details>
                        {:else}
                        <Forbidden />
                        {/if}
                    </Route>
                    <Route path="batches">
                        {#if MODULES.hasAccess(MODULES.VIEW_STUDENT_DETAILS)}
                        <Batches on:setActiveTab={setActiveTab} studentId={studentId}></Batches>
                        {:else}
                        <Forbidden />
                        {/if}
                    </Route>
                    <Route path="conversation">
                        <Conversation on:setActiveTab={setActiveTab} studentId={studentId}></Conversation>
                    </Route>
                    <Route path="studyMonitoringChart/*" let:params>
                        <StudyMonitoringChart on:setActiveTab={setActiveTab} studentId={studentId}></StudyMonitoringChart>
                    </Route>
                    <Route path="studentfollowups">
                        <StudentFollowUp on:setActiveTab={setActiveTab} studentId={studentId}></StudentFollowUp>
                    </Route>
                    <Route path="">
                        {#if MODULES.hasAccess(MODULES.VIEW_STUDENT_DETAILS)}
                        <Details on:setActiveTab={setActiveTab} studentId={studentId}></Details>
                        {:else}
                        <Forbidden />
                        {/if}
                    </Route>

                </Router>
            </div><!-- media-body -->
            <div class="profile-sidebar profile-sidebar-two mg-t-40 mg-lg-t-0 pd-lg-l-15">
                <div class="row">
                    <div class="col-sm-6 col-md-5 col-lg">
                        {#if MODULES.hasAccess(MODULES.UPDATE_STUDENT)}
                        <AddEditParent studentId={studentId} />
                        {/if}
                    </div><!-- col -->
                </div>
                <div class="row">
                    {#if MODULES.hasAccess(MODULES.UPDATE_STUDENT)}
                    <div class="col-sm-6 col-md-5 col-lg mg-t-40 order-sm-1">
                        <div class="d-flex align-items-center justify-content-between mg-b-15">
                            <h6 class="tx-13 tx-uppercase tx-semibold mg-b-0">Groups</h6>
                        </div>
                        <div class="flex-fill pd-y-20 pd-r-20">
                            <ul id="chatDirectMsg" class="chat-msg-list">
                                {#each channelsList as __groups}
                                <li class="media groups">
                                    <div class="avatar">
                                        <span class="avatar-initial bg-dark rounded-circle">g</span>
                                    </div>
                                    <div class="media-body mg-l-10">
                                        <h6 class="mg-b-0">{__groups.name}</h6>
                                        <!-- <small class="d-block tx-color-04">5 mins ago</small> -->
                                    </div>
                                </li>
                                {:else}
                                <div></div>
                                {/each}
                            </ul>
                        </div>
                        <!-- <div class="flex-fill pd-y-20 pd-r-20">
                            <div id="chatDirectMsg" class="chat-msg-list">
                                {#if channelsList.length}
                                    {#each channelsList as __channel}
                                        {#if __channel && __channel.batchId}
                                        <a use:links href="/dashboard/chat/batch/{__channel.batchId}" data-channelid="{__channel.batchId}"  class="media channels selected{__channel.batchId} ">
                                            <div class="avatar avatar-online" data-channelid="{__channel.batchId}"><span data-channelid="{__channel.batchId}" class="avatar-initial bg-dark rounded-circle">c</span></div>
                                            <div class="media-body mg-l-10" data-channelid="{__channel.batchId}" >
                                            <h6 class="mg-b-0" data-channelid="{__channel.batchId}" >{__channel.batchName}</h6>
                                            <small class="d-block tx-color-04" data-channelid="{__channel.batchId}" >5 mins ago</small>
                                            </div>
                                        </a>
                                        {:else}
                                        <a use:links href="/dashboard/chat/channel/{__channel.id}" data-channelid="{__channel.id}"  class="media batches selected{__channel.id} ">
                                            <div class="avatar avatar-online" data-channelid="{__channel.id}"><span data-channelid="{__channel.id}" class="avatar-initial bg-dark rounded-circle">b</span></div>
                                            <div class="media-body mg-l-10" data-channelid="{__channel.id}" >
                                            <h6 class="mg-b-0" data-channelid="{__channel.id}" >{__channel.name}</h6>
                                            <small class="d-block tx-color-04" data-channelid="{__channel.id}" >5 mins ago</small>
                                            </div>
                                        </a>
                                        {/if}
                                    {:else}
                                    <div></div>
                                    {/each}
                                {/if}
                            </div>
                        </div> -->
                        <!-- <h6>You have 18 mutual connection</h6>
                        <p class="tx-color-03 mg-b-0">You and Fen both know Archie Cantones, Socrates Itumay, and 17
                            others</p> -->
                    </div><!-- col -->
                    {/if}
                </div>
                {#if isPoints}
                    <div class="row">
                        <div class="col-sm-6 col-md-5 col-lg">
                            {#if MODULES.hasAccess(MODULES.UPDATE_STUDENT)}
                            <AddEditPoints studentId={studentId} />
                            {/if}
                        </div><!-- col -->
                    </div>
                {/if}
                <div class="row">
                    <div style="display: none;" class="col-sm-6 col-md-5 col-lg mg-t-40 mg-sm-t-0 mg-lg-t-40">
                        <div class="d-flex align-items-center justify-content-between mg-b-20">
                            <h6 class="tx-13 tx-spacing-1 tx-uppercase tx-semibold mg-b-0">People Also Viewed</h6>
                        </div>
                        <ul class="list-unstyled media-list mg-b-15">
                            <li class="media align-items-center">
                                <a href="">
                                    <div class="avatar avatar-online"><img src="https://via.placeholder.com/350"
                                            class="rounded-circle" alt=""></div>
                                </a>
                                <div class="media-body pd-l-15">
                                    <p class="tx-medium mg-b-2"><a href="" class="link-01">Roy Recamadas</a></p>
                                    <span class="tx-12 tx-color-03">UI/UX Designer &amp; Developer</span>
                                </div>
                            </li>
                            <li class="media align-items-center">
                                <a href="">
                                    <div class="avatar avatar-offline"><img src="https://via.placeholder.com/600"
                                            class="rounded-circle" alt=""></div>
                                </a>
                                <div class="media-body pd-l-15">
                                    <p class="tx-medium mg-b-2"><a href="" class="link-01">Raymart Serencio</a></p>
                                    <span class="tx-12 tx-color-03">Full-Stack Developer</span>
                                </div>
                            </li>
                            <li class="media align-items-center">
                                <a href="">
                                    <div class="avatar avatar-offline"><span
                                            class="avatar-initial rounded-circle bg-teal">R</span></div>
                                </a>
                                <div class="media-body pd-l-15">
                                    <p class="tx-medium mg-b-2"><a href="" class="link-01">Rolando Paloso Jr</a></p>
                                    <span class="tx-12 tx-color-03">Licensed Architect</span>
                                </div>
                            </li>
                            <li class="media align-items-center">
                                <a href="">
                                    <div class="avatar avatar-online"><span
                                            class="avatar-initial rounded-circle bg-indigo">R</span></div>
                                </a>
                                <div class="media-body pd-l-15">
                                    <p class="tx-medium mg-b-2"><a href="" class="link-01">Robert Restificar</a></p>
                                    <span class="tx-12 tx-color-03">Business Analyst</span>
                                </div>
                            </li>
                            <li class="media align-items-center">
                                <a href="">
                                    <div class="avatar avatar-online"><span
                                            class="avatar-initial rounded-circle bg-gray-600">A</span></div>
                                </a>
                                <div class="media-body pd-l-15">
                                    <p class="tx-medium mg-b-2"><a href="" class="link-01">Archie Cantones</a></p>
                                    <span class="tx-12 tx-color-03">Senior Software Architect</span>
                                </div>
                            </li>
                        </ul>
                    </div><!-- col -->
                    <div style="display: none;" class="col-sm-6 col-md-5 col-lg mg-t-40">
                        <div class="d-flex align-items-center justify-content-between mg-b-20">
                            <h6 class="tx-13 tx-uppercase tx-semibold mg-b-0">People You May Know</h6>
                        </div>
                        <ul class="list-unstyled media-list mg-b-15">
                            <li class="media align-items-center">
                                <a href="">
                                    <div class="avatar"><img src="https://via.placeholder.com/500"
                                            class="rounded-circle" alt=""></div>
                                </a>
                                <div class="media-body pd-l-15">
                                    <p class="tx-medium mg-b-2"><a href="" class="link-01">Allan Ray Palban</a></p>
                                    <span class="tx-12 tx-color-03">Senior Business Analyst</span>
                                </div>
                            </li>
                            <li class="media align-items-center">
                                <a href="">
                                    <div class="avatar"><img src="https://via.placeholder.com/500"
                                            class="rounded-circle" alt=""></div>
                                </a>
                                <div class="media-body pd-l-15">
                                    <p class="tx-medium mg-b-2"><a href="" class="link-01">Rhea Castanares</a></p>
                                    <span class="tx-12 tx-color-03">Product Designer</span>
                                </div>
                            </li>
                            <li class="media align-items-center">
                                <a href="">
                                    <div class="avatar"><img src="https://via.placeholder.com/500"
                                            class="rounded-circle" alt=""></div>
                                </a>
                                <div class="media-body pd-l-15">
                                    <p class="tx-medium mg-b-2"><a href="" class="link-01">Philip Cesar Galban</a></p>
                                    <span class="tx-12 tx-color-03">Executive Assistant</span>
                                </div>
                            </li>
                            <li class="media align-items-center">
                                <a href="">
                                    <div class="avatar"><img src="https://via.placeholder.com/500"
                                            class="rounded-circle" alt=""></div>
                                </a>
                                <div class="media-body pd-l-15">
                                    <p class="tx-medium mg-b-2"><a href="" class="link-01">Randy Macapala</a></p>
                                    <span class="tx-12 tx-color-03">Business Entrepreneur</span>
                                </div>
                            </li>
                            <li class="media align-items-center">
                                <a href="">
                                    <div class="avatar"><img src="https://via.placeholder.com/500"
                                            class="rounded-circle" alt=""></div>
                                </a>
                                <div class="media-body pd-l-15">
                                    <p class="tx-medium mg-b-2"><a href="" class="link-01">Abigail Johnson</a></p>
                                    <span class="d-block tx-12 tx-color-03">System Administrator</span>
                                </div>
                            </li>
                        </ul>
                    </div><!-- col -->
                    <div style="display: none;" class="col-sm-6 col-md-5 col-lg mg-t-40">
                        <div class="d-flex align-items-center justify-content-between mg-b-15">
                            <h6 class="tx-13 tx-uppercase tx-semibold mg-b-0">Photos</h6>
                            <a href="" class="link-03">Add Photo</a>
                        </div>

                        <div class="row row-xxs">
                            <div class="col-4">
                                <a href="" class="d-block ht-60"><img src="https://via.placeholder.com/640x426"
                                        class="img-fit-cover" alt=""></a>
                            </div><!-- col -->
                            <div class="col-4">
                                <a href="" class="d-block ht-60"><img src="https://via.placeholder.com/500x281"
                                        class="img-fit-cover" alt=""></a>
                            </div><!-- col -->
                            <div class="col-4">
                                <a href="" class="d-block ht-60"><img src="https://via.placeholder.com/500x281"
                                        class="img-fit-cover" alt=""></a>
                            </div><!-- col -->
                            <div class="col-4 mg-t-2">
                                <a href="" class="d-block ht-60"><img src="https://via.placeholder.com/500x281"
                                        class="img-fit-cover" alt=""></a>
                            </div><!-- col -->
                            <div class="col-4 mg-t-2">
                                <a href="" class="d-block ht-60"><img src="https://via.placeholder.com/350"
                                        class="img-fit-cover" alt=""></a>
                            </div><!-- col -->
                            <div class="col-4 mg-t-2">
                                <a href="" class="d-block ht-60"><img src="https://via.placeholder.com/600"
                                        class="img-fit-cover" alt=""></a>
                            </div><!-- col -->
                            <div class="col-4 mg-t-2">
                                <a href="" class="d-block ht-60"><img src="https://via.placeholder.com/500"
                                        class="img-fit-cover" alt=""></a>
                            </div><!-- col -->
                            <div class="col-4 mg-t-2">
                                <a href="" class="d-block ht-60"><img src="https://via.placeholder.com/500"
                                        class="img-fit-cover" alt=""></a>
                            </div><!-- col -->
                            <div class="col-4 mg-t-2">
                                <a href="" class="d-block ht-60"><img src="https://via.placeholder.com/500"
                                        class="img-fit-cover" alt=""></a>
                            </div><!-- col -->
                        </div><!-- row -->
                    </div><!-- col -->
                </div><!-- row -->
            </div><!-- profile-sidebar -->
        </div><!-- media -->
    </div><!-- container -->
</div><!-- content-body -->

<!-- <div class="modal fade" id="addParent" tabindex="-1" role="dialog" aria-labelledby="addParentLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content tx-14">
        <div class="modal-header">
          <h6 class="modal-title" id="addParentLabel">Modal Title</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="mg-b-0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary tx-13">Save changes</button>
        </div>
      </div>
    </div> 
  </div> -->
