<script>
    import { Router, Link, Route } from "svelte-routing";
    import BatchTiming from "./batchTiming.svelte";
</script>


<Router>

    <Route path="/:batch_id" let:params>
        <BatchTiming batch_id="{params.batch_id}"></BatchTiming>
    </Route>
    <Route path="">
        <BatchTiming></BatchTiming>
    </Route>
</Router>







