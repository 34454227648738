<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import ButtonLoader from "../../utils/button_loader.svelte"
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import { API_URL } from "../../../config.js"


    const isArchived = true;
    let demoDataTable;
    let isLoading = false;
    let studentDataTable;
    let emptyState;
    let deleteEnquiryObj = {};
    let modalArchiveEnquiry;
    let archiveEnquiryId
    let modalArchiveEnquiryTitle
    let modalArchiveEnquirySubTitle
    let modalArchiveEnquiryProceed
    let modalArchiveEnquiryBtnText = ""
    let modalArchiveEnquiryIcon = "archive";
    deleteEnquiryObj = {
        _id: null,
        name: '',
        mobileNo: ''
    }

    function redrawEnquiryTable() {
        // getCounts()
        if (studentDataTable) { studentDataTable.draw() }

    }

    async function deleteStudent(id) {
        var response = await api_handleApiCalls('students.delete', { _id: id })

        if (response.status) {
            if (studentDataTable) { studentDataTable.draw() }
            pSuccess(null, response.data)
        } else {
            pError("Error", response.errMsg)
        }
    }

    async function proccedToRestoreEnquiry() {
        if (deleteEnquiryObj._id) {

            let response = await api_handleApiCalls('enquiries.restore', { _id: deleteEnquiryObj._id })
            if (response.status) {
                showDeleteEnquiryModal(false)
                redrawEnquiryTable()
                pSuccess('Success!', response.data)
            } else {
                showDeleteEnquiryModal(false)
                redrawEnquiryTable()
                pError('Error!', response.errMsg)
            }

        } else {
            showDeleteEnquiryModal(false)
        }
    }
    async function proccedToDeleteEnquiry() {
        if (deleteEnquiryObj._id) {

            let response = await api_handleApiCalls('enquiries.delete', { _id: deleteEnquiryObj._id })
            if (response.status) {
                showDeleteEnquiryModal(false)
                redrawEnquiryTable()
                pSuccess('Success!', response.data)
            } else {
                showDeleteEnquiryModal(false)
                redrawEnquiryTable()
                pError('Error!', response.errMsg)
            }

        } else {
            showDeleteEnquiryModal(false)
        }
    }

    async function getEnquiryNameId(id) {
        var resData = await api_handleApiCalls('enquiries.getEnquiryNameById', { _id: id })
        if (resData.status) {

            return resData.data

        } else {
            pError("Error!", resData.errMsg)
            return null
        }

    }
    var handleDeleteBtnClick = async (id) => {
        deleteEnquiryObj = {}
        let aEnquiry = await getEnquiryNameId(id)

        if (aEnquiry) {
            if (id === aEnquiry._id) {

                deleteEnquiryObj = aEnquiry
                archiveEnquiryId = aEnquiry._id
                modalArchiveEnquiryBtnText = "Delete"
                modalArchiveEnquiryIcon = "trash"
                modalArchiveEnquiryTitle = "Delete enquiry"
                modalArchiveEnquirySubTitle = "Are you sure you want to delete this enquiry?"
                modalArchiveEnquiryProceed = proccedToDeleteEnquiry
                showDeleteEnquiryModal(true)
                // feather.replace()
            }

        }
        await tick()
    }

    async function showDeleteEnquiryModal(action) {
        action ? _$(modalArchiveEnquiry).modal('show') : _$(modalArchiveEnquiry).modal('hide');
    }

    function getFilteredOrder(data) {
        var sort
        try {

            if (Boolean(data)) {
                let index = data.order[0].column
                let dir = data.order[0].dir

                sort = {
                    name: data.columns[index].data,
                    dir: dir
                }
            }

        } catch (error) {

        }
        return sort
    }


    var handleRestoreBtnClick = async (id) => {
        deleteEnquiryObj = {}
        let aEnquiry = await getEnquiryNameId(id)

        if (aEnquiry) {
            if (id === aEnquiry._id) {

                deleteEnquiryObj = aEnquiry
                archiveEnquiryId = aEnquiry._id
                modalArchiveEnquiryBtnText = "Restore"
                modalArchiveEnquiryIcon = "upload"
                modalArchiveEnquiryTitle = "Restore enquiry"
                modalArchiveEnquirySubTitle = "Are you sure you want to restore this enquiry?"
                modalArchiveEnquiryProceed = proccedToRestoreEnquiry
                showDeleteEnquiryModal(true)
                // feather.replace()
            }
        }
        await tick()
    }

    function bindDatatable() {
        studentDataTable = _$(demoDataTable).DataTable({
            stateSave: true,
            "stateSaveCallback": function (settings, data) {
                localStorage.setItem(window.location.pathname, JSON.stringify(data))
            },
            "stateLoadCallback": function (settings) {
                return JSON.parse(localStorage.getItem(window.location.pathname))
            },

            "searching": false,
            "bLengthChange": false,
            responsive: true,
            "order": [[3, "desc"]],
            language: {
                processing: "",
                lengthMenu: '_MENU_ enquiries/page',
            },
            ajax: function (data, callback, settings) {
                // var filterObj = { ...getFilterData() }
                // filterObj.start = data.start
                // filterObj.length = data.length
                // filterObj.search = data.search.value
                // filterObj.draw = data.draw
                // filterObj.sort = getFilteredOrder(data)

                _$.ajax({
                    url: API_URL,
                    method: 'post',
                    data: {
                        action: 'students.getarchivedlist',
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                            sort: getFilteredOrder(data),
                            isArchived: isArchived
                        }
                    },
                    success: function (resData) {
                        var result = []
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(paginationData.filteredCount),
                                data: paginationData.data
                            }
                            if (!resData.data.length) {
                                emptyState = true
                            }
                            callback(result);
                        }
                        else {
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        emptyState = true
                    }
                })
            },
            columns: [
                {
                    data: 'name',
                    defaultContent: '<div class="text-center">-</div>',
                    render: (data, type, full) => {
                        let image;
                        // if image found display profilePic avatar
                        image = `<img src="${full.profilePic ? full.profilePic : "/images/man.png"
                            }" class="avatar mg-r-15" alt="${data[0].toUpperCase()}">`;
                        return `
                            <div style = "cursor:pointer" class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 btn-edit-enquiry" enquiry-id="${full._id}">
                                 ${image}
                                 <div class="wd-80p bg-transparent">
                            <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 tx-capitalize bg-transparent btn-edit-enquiry" enquiry-id="${full._id}">${data || "-"
                            }</h6>
                        <span class="d-block tx-11 text-muted bg-transparent">
                        <i data-feather="phone"></i> 
                        ${full.mobileNo || "-"}
                       </span>
                       
                        </div>
                      </div>`;

                    }
                },
                // { data: 'mobileNo', defaultContent : '<div class="text-center">-</div>' },
                {
                    data: 'Reason',
                    render: function (data, type, row) {

                        let statusElem


                        statusElem = `<span>${row.admissionCancelReason}</span>`





                        return statusElem
                    }
                },
                // { 
                //     data: 'createdOn', 
                //     render: function (data, a, b, c) {
                //         var _dateStr = '<div class="text-center">-</div>'
                //         data = new Date(data)
                //             if(data && data.toString().toLowerCase() != 'invalid date') {

                //                 _dateStr = `
                //                     <span> ${data.toLocaleDateString('en-GB')}, ${data.toLocaleTimeString()} </span>
                //                 `
                //             }

                //         return _dateStr

                //     } 
                // },

                {
                    data: 'createdByUserName',
                    defaultContent: '<div style="text-align:center;" class="text-center">-</div>'
                },
                {
                    data: '_id', render: function (data, a, b, c) {
                        return `
                    <div class="d-md-flex flex-row justify-content-center">
                        <a href="#" class="delete mg-r-5 btn-edit-student" student-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit student"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>
                        <a href="#" class="delete mg-r-5 btn-remove-student" student-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete student"><i class="wd-20 mg-r-5" data-feather="trash"></i></a>
                    </div>
                    `;
                    }
                },

            ],
            columnDefs: [
                { orderable: false, targets: [1, -1] }
            ],
            "drawCallback": function () {
                feather.replace({ width: "1em", height: "1em" })
                _$('.btn-edit-student').unbind('click').click(function (e) {
                    e.preventDefault();

                    var studentId = _$(this).attr("student-id");
                    navigate(("/dashboard/student/view/" + studentId))

                })
                _$('.btn-remove-student').unbind('click').click(function (e) {

                    e.preventDefault();
                    var studentId = _$(this).attr("student-id");

                    pConfirm("Delete Student", "Are you sure you want to delete student",
                        function () {
                            deleteStudent(studentId)
                        })
                })

            },
            "processing": true,
            "serverSide": true,
        });


    }

    onMount(async () => {
        feather.replace();
        await bindDatatable();
    })
</script>
<div class="content-body fadeIn">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>

                    <li class="breadcrumb-item active" aria-current="page">Archived List</li>

                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">Archived Students</h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Cancel students</p>
        </div>
        <hr class="full-width">
    </div>
    <div class="col-sm-12">
        <div class="mg-b-25 mg-t-15">
            <div class="df-example demo-table">
                <table bind:this={demoDataTable} class="table mg-b-0" style="width: 100%;">
                    <thead>
                        <tr>
                            <th class="">Student Name</th>
                            <!-- <th class="">Contact Number</th> -->
                            <th class="" style=" width: 25%;">Reason</th>
                            <th class="">Created by</th>
                            <th class="" style="width: 10%;">Actions </th>
                        </tr>
                    </thead>
                    <tbody></tbody>
                </table>
                {#if emptyState }
                <div class="empty-state-img">
                    <img src="/images/empty.svg" style="width: 25%;" alt="empty" />
                    <p class="tx-bold"></p>
                </div>
                {/if}
            </div>
        </div>
    </div>
</div>


<div class="modal effect-scale" bind:this={modalArchiveEnquiry} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather={modalArchiveEnquiryIcon}
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">{modalArchiveEnquiryTitle}</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">{modalArchiveEnquirySubTitle}</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0 ">
                <!-- <h4 class="tx-16 tx-bold mg-b-30">Are you sure you want to Archive this Enquiry?</h4>
          <a href="" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a> -->
                <hr class="full-width">
                <div>

                    <div class="bd">
                        <ul class="list-unstyled media-list tx-12 tx-sm-13 mg-b-0">
                            <li class="media bg-ui-01 ">
                                <div class="avatar"><span
                                        class="avatar-initial rounded-circle bg-gray-600">{deleteEnquiryObj.name ?
                                        deleteEnquiryObj.name.charAt(0) : '-'}</span></div>
                                <div class="media-body mg-l-15">
                                    <h6 class="tx-13 mg-b-2">{deleteEnquiryObj.name ? deleteEnquiryObj.name : ''}</h6>
                                    <span class="d-block tx-color-03"><i class="mg-r-5" data-feather="phone-call"></i>
                                        {deleteEnquiryObj.mobileNo ? deleteEnquiryObj.mobileNo : ''}</span>
                                </div>
                            </li>
                        </ul>
                    </div>


                    <div class="d-flex justify-content-end tx-12 mg-t-30">
                        <button type="button" class="btn  pd-x-15 btn-secondary tx-12" data-dismiss="modal">CANCEL
                            <i data-feather="x" class="wd-10 mg-l-5"></i>
                        </button>
                        <button type="button" disabled={isLoading} on:click={modalArchiveEnquiryProceed}
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                            <ButtonLoader isLoading={isLoading} btnText={modalArchiveEnquiryBtnText} />
                            <i data-feather={modalArchiveEnquiryIcon} class="wd-10 mg-l-5"></i>
                        </button>
                    </div>

                </div>
            </div><!-- modal-body -->
        </div><!-- modal-content -->
    </div><!-- modal-dialog -->
</div><!-- modal -->
