// Utility function to convert class name to constant key
export function getClassKey(className) {
  const classMapping = {
    "Class 3": "CLASS_3",
    "Class 4": "CLASS_4",
    "Class 5": "CLASS_5",
    "Class 6": "CLASS_6",
    "Class 7": "CLASS_7",
    "Class 8": "CLASS_8",
    "Class 9": "CLASS_9",
    "Class 10": "CLASS_10",
  };
  return classMapping[className] || className;
}
