<script>
  import { Router, Link, links, Route } from "svelte-routing";
  import { onMount } from "svelte";
  import { activeMenu } from "../stores";

  // import SPTcmp from "./component.svelte";
  import STPModal from "./modal.svelte";

  let showModal = false;

  onMount(async function () {
    console.log("opening test instructions text editor");
    activeMenu.set("announcementtemplate");
    showModal = true;
  });

  let onCloseModal = function (event) {
    showModal = !showModal;
    console.log("sptObject : ", event.detail.sptObject);
    _$(".modal-backdrop").remove();
  };
</script>

<div
  class="content-body fadeIn"
  style="overflow: hidden; display: flex; flex-direction: column; padding-bottom: 0;">
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-15 mg-lg-b-15">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/tests/instructions" use:links>Announcement Template</a>
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1"><small>Announcement Template</small></h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
        announcement template text editor. add, update, delete teplate
      </p>
    </div>
    <div class="d-block">
      <div class="d-sm-flex">
        <button
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          on:click={() => {
            showModal = true;
          }}>
          <i data-feather="file" class="wd-10 mg-r-5" />
          Manage Template
        </button>
      </div>
    </div>
  </div>
  <div
    class="contact-wrapper contact-wrapper-two"
    style="margin-left: -25px;margin-right: -25px;border-top: 1px solid #e5e8ee; flex: 1; overflow: hidden;">
    <!-- <SPTcmp/> -->
  </div>
</div>

{#if showModal}
<STPModal on:close={onCloseModal} allowSelection={false} />
{/if}
