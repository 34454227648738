<script>
  import { links, link } from "svelte-routing";
  import { onMount, tick, afterUpdate } from "svelte";
  import { activeMenu } from "../../stores";
  import EmptyHoliday from "../empty/emptyHoliday.svelte";
  import { userData } from "../../stores";
  import EmptyBatch from "../empty/emptyBatch.svelte";
  import { pConfirm, pSuccess, pError, pAlert } from "../../utils/notify.svelte";
  import YearPicker from "../yearPicker.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js" 
  let showEarlierThisYear = false;
  let roleIsOrgAdmin;
  let accessModuleEdit
  let elem_holidayPickerFromDate, elem_holidayPickerToDate;
  let isLoadingHolidays = true,
    isLoadingBatches = true,
    isSaving = false,
    isDeleting = false;
  let scroll1, scroll2;
  let modalTitle;
  let firstInputField;
  let displayText = "Holiday List";
  let holidayList = { data: [] };
  let search = { value: "" };
  let year = new Date().getFullYear();
  let displayBatchList = [];
  let holidayObj = {
    name: "",
    fromDate: new Date(),
    toDate: new Date(),
    batchList: [],
  };
  let isSortByName
  let errorObj = {};
  let elem_modal;
  const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  let minDate, maxDate;
  let today = new Date();
  let checked=false;
  function setMinDate() {
    let day = new Date();
    if (year != day.getFullYear()) {
      day.setFullYear(year);
      day.setMonth(0);
      day.setDate(1);
      day.setHours(0);
      day.setMinutes(0);
      day.setSeconds(0);
    }
    minDate = day;
  }
  function setMaxDate() {
    let day = new Date();
    day.setFullYear(year);
    day.setMonth(11);
    day.setDate(31);
    day.setHours(23);
    day.setMinutes(59);
    day.setSeconds(59);
    maxDate = day;
  }

  async function api_delete(data) {

    let action = "holidays.removeHoliday";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_list(data) {

    let action = "holidays.fetchHolidayList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_getbyid(data) {

    let action = "holidays.fetchHoliday";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_fetchBatchList(data) {

    let action = "holidays.fetchBatchList";
    var response = await api_handleApiCalls(action, {isSortByName:true});
    return response;

  }

  async function api_saveHoliday(data) {

    let action = "holidays.saveHoliday";
    var response = await api_handleApiCalls(action,data);
    return response;

  }

  async function batchSelectionChanged(selectedId) {
    let tempBatchList = holidayObj.batchList;
    
    if (tempBatchList) {
      let index = tempBatchList.findIndex((item) => item == selectedId);
      if (index != -1) {
        tempBatchList.splice(index, 1);
      } else {
        tempBatchList.push(selectedId);
      }
    } else {
      tempBatchList = [selectedId];
    }
    _$('.select-all-batches').prop("checked", false);
    
    holidayObj = { ...holidayObj, batchList: [...tempBatchList] };
    await tick();
  }
  async function allBatchSelected(event) {
    checked = !checked;
    let tempBatchList = [];
    if (checked) {
      displayBatchList.forEach(batch => {
        tempBatchList.push(batch._id);
      })
    }
    holidayObj = { ...holidayObj, batchList: [...tempBatchList] };
    await tick();
  }
  async function addUpdateHoliday() {
    if (isSaving) return;
    isSaving = true;
    await tick();
    let isValid = validate();
    console.log({ ...holidayObj, isValid });
    if (holidayObj._id == "") delete holidayObj._id;
    if (isValid) {
      let result = await api_saveHoliday(holidayObj);
      if (result.status) {
        closeModal();
        await tick();
        pSuccess("", result.data);
        clearFields();
        reload();
      } else {
        pError("", result.errMsg);
      }
    }
    isSaving = false;
    await tick();
  }

  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [year, month, day].join("-");
    return dateString;
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {
      date: 1,
    };
    return sort;
  }

  async function fetchHolidayList(offset = 0, event) {
    let data = {
      search,
      sort: parseSorts({ 0: "dsc" }),
      year: year ? year : new Date().getFullYear(),
    };
    console.info(data);
    // fetch call
    isLoadingHolidays = true;
    await tick();
    let response = await api_list(data);
    console.info({ response });
    if (response.status) {
      holidayList = response.data.data;
      for (let i = 0; i < holidayList.length; i++) {
        holidayList[i].batchName = [];
        holidayList[i].batchList.map((item) => {
          if (displayBatchList.length) {
            let batchName = displayBatchList.find((item2) => item2._id == item);
            if (batchName) {
              holidayList[i].batchName.push(batchName.name);
            }
          }
        })

      }
      console.log(holidayList);
    } else {
      console.error("something went wrong");
      length = 0;
      holidayList = [];
    }
    isLoadingHolidays = false;
    feather.replace({ width: "1em", height: "1em" });
    await tick();
  }

  async function deleteHoliday(holidayId) {
    if(!(MODULES.hasAccess(MODULES.DELETE_HOLIDAYS))){
      pError("Access Denied", "This user profile does not have permission to delete holiday!!")
      return
    }
    isDeleting = true;
    await tick();
    pConfirm(
      "Delete Holiday?",
      "This will also delete associated data",
      async function () {
        console.info("Deleting");
        //   delete api
        let result = await api_delete({ _id: holidayId });
        if (result.status) {
          pSuccess(null, result.data);
          await fetchHolidayList();
          closeModal();
          clearFields();
        } else if (result.errMsg) {
          pError(null, result.errMsg);
        } else if (result.error) {
          pError(null, result.error);
        } else {
          pError(null, "Something went wrong");
        }
        isDeleting = false;
        await tick();
      },
      async function () {
        isDeleting = false;
        await tick();
      }
    );
  }

  async function loadBatchList() {
    isLoadingBatches = true;
    await tick();
    var batchRes = await api_fetchBatchList();
    if (batchRes.status) {
      displayBatchList = batchRes.data;
      console.log({ displayBatchList });
      if (displayBatchList.length == 0) {
        // Rare case
        pAlert("Note", "Please create batch to access this feature.");
      }
    } else {
      pError("", batchRes.errMsg);
    }
    isLoadingBatches = false;
    await tick();
  }

  async function reload() {
    await loadBatchList();
    await fetchHolidayList();
    feather.replace({ width: "1em", height: "1em" });
    await tick();
    // try {
    //   if (scroll1) scroll1.update();
    //   scroll1 = new PerfectScrollbar(".scroll1", {
    //     suppressScrollX: true,
    //   });
    // } catch (error) {
    //   console.error(error);
    // }
  }

  function yearChanged(selectedYear) {
    year = selectedYear;
    setMinDate();
    setMaxDate();
    reload();
  }

  function getMonthName(date) {
    let month = new Date(date).getMonth();
    switch (month) {
      case 0:
        return "JAN";
      case 1:
        return "FEB";
      case 2:
        return "MAR";
      case 3:
        return "APR";
      case 4:
        return "MAY";
      case 5:
        return "JUN";
      case 6:
        return "JUL";
      case 7:
        return "AUG";
      case 8:
        return "SEP";
      case 9:
        return "OCT";
      case 10:
        return "NOV";
      case 11:
        return "DEC";
      default:
        "";
    }
  }

  function getDayOfWeek(date) {
    let dayOfWeek = new Date(date).getDay();
    switch (dayOfWeek) {
      case 0:
        return "SUNDAY";
      case 1:
        return "MONDAY";
      case 2:
        return "TUESDAY";
      case 3:
        return "WEDNESDAY";
      case 4:
        return "THURSDAY";
      case 5:
        return "FRIDAY";
      case 6:
        return "SATURDAY";
    }
  }

  function bindModalAnimations() {
    // new holiday modal
    _$(elem_modal).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
      // _$(elem_holidayPicker).datepicker({
      //   minDate,
      //   maxDate,
      //   onSelect: function (text, instance){
      //     console.log(text, instance);
      //   }
      // });
      // console.debug(elem_holidayPicker);
    });

    _$(elem_modal).on("hidden.bs.modal", function (e) {
      _$(this).removeClass("effect-scale");
      isLoadingBatches = false;
      isSaving = false;
      isDeleting = false;
    });
  }

  function init() {
    console.log("mounting holiday list");
    activeMenu.set("holidays");
    unsubscribe();
    setMinDate();
    setMaxDate();
    bindModalAnimations();
    reload();
  }

  function clearFields() {
    // form fields
    holidayObj._id = "";
    holidayObj.name = "";
    holidayObj.fromDate = convertDateObjToStr(minDate);
    holidayObj.toDate = convertDateObjToStr(minDate);
    holidayObj.batchList = [];
    clearValidations();
  }

  async function clearValidations() {
    delete errorObj.name;
    delete errorObj.date;
    delete errorObj.batchList;
    await tick();
  }

  function validate() {
    //   clear previous validations before validating anew
    clearValidations();

    var regexpStartingWithAlphabet = new RegExp(`^[A-Za-z]`);
    holidayObj.name = holidayObj.name.trim();
    if (!Boolean(holidayObj.name)) {
      errorObj.name = "Please enter the holiday name";
    } else if (!holidayObj.name.match(regexpStartingWithAlphabet)) {
      errorObj.name = "Holiday name must start with alphabet";
    }

    // date
    if (!Boolean(holidayObj.fromDate)) {
      errorObj.fromDate = "Please select a date";
    } else if (new Date(holidayObj.fromDate) == "Invalid Date") {
      errorObj.fromDate = "Please select a valid date";
    }
    if (!Boolean(holidayObj.toDate)) {
      errorObj.toDate = "Please select a date";
    } else if (new Date(holidayObj.toDate) == "Invalid Date") {
      errorObj.toDate = "Please select a valid date";
    }

    // mobilenumber
    if (!Boolean(holidayObj.batchList) || !holidayObj.batchList.length) {
      errorObj.batchList = "Please select some batch";
    }

    if (Object.values(errorObj).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async function closeModal() {
    _$(elem_modal).modal("hide");
    _$(this).removeClass(function (index, className) {
      return (className.match(/(^|\s)effect-\S+/g) || []).join(" ");
    });
  }
  async function openModal(holidayId) {
    if (holidayId) {
      let holiday = await api_getbyid({ holidayId });
      if (holiday.status) {
        holiday = holiday.data;
        if (typeof holiday.date == "object" || holiday.date.length > 10) {
          holiday.fromDate = convertDateObjToStr(holiday.date);
          holiday.toDate = convertDateObjToStr(holiday.date);
          delete holiday.date;
        }
        holidayObj = holiday;
        console.debug(holidayObj, displayBatchList);
        modalTitle = "Change holiday details";
      } else if (holiday.error) {
        clearFields();
        pError("", holiday.error);
      } else if (holiday.errMsg) {
        clearFields();
        pError("", holiday.errMsg);
      } else {
        clearFields();
        pError("", "something went wrong");
      }
    } else {
      clearFields();
      modalTitle = "Holiday";
    }
    _$(elem_modal).modal("show");
    _$(firstInputField).focus();
    if (scroll2) scroll2.update();
    else scroll2 = new PerfectScrollbar(".scroll2", { suppressScrollX: true });
    await tick();
  }

  const unsubscribe = userData.subscribe(value => {
    roleIsOrgAdmin = value.role == "student"
  });
  onMount(init);
  afterUpdate(function () {
    feather.replace({ width: "1em", height: "1em" });
  });
</script>

<div class="content-body animated scale">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {displayText}
          </li>
        </ol>
      </nav>
      <div class="mg-b-0 tx-spacing--1 wd-100p">
        <div class="d-inline-block">
          <div class="form-group">
            <h4 class="mg-b-0 tx-spacing--1">Holidays</h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
              Search holiday details.
            </p>
            <YearPicker {yearChanged} {year} />
          </div>
        </div>
      </div>
    </div>

    <div class="d-inline-block">
      <div class="d-inline-block">
        <form class="search-form" on:submit|preventDefault={async (event)=>{
          await fetchHolidayList(0, event)} 
        }
          >
          <input type="search" class="form-control" placeholder="Holiday name " bind:value={search.value} 
          on:input={async (event)=> {await fetchHolidayList(0, event)}}
          />
          <button class="btn" type="submit"><i data-feather="search" /></button>
        </form>
      </div>
      {#if !roleIsOrgAdmin}
      <div class="d-inline-block">
        {#if MODULES.hasAccess(MODULES.ADD_HOLIDAYS)}
        <button class="btn pd-x-15 btn-primary btn-uppercase mg-l-5 btn-sm" on:click={()=> {
          let day = new Date();
          if (year != day.getFullYear()) {
          day.setYear(year);
          day.setMonth(0);
          day.setDate(1);
          }
          accessModuleEdit = false
          openModal();
          }}
          ><i data-feather="plus" class="wd-10 mg-r-5" />
          Add new Holiday
        </button>
        {/if}
      </div>
      {/if}
    </div>
  </div>
  <!-- breadcrumbs -->
  {#if year == new Date().getFullYear()}
  <!-- todays holidays start -->
  <hr class="full-width" />
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30 mg-t-15">
    <div>
      <h4 class="mg-b-0 tx-spacing--1">Today's Holidays</h4>
    </div>
  </div>
  <!-- <div class="scroll1 pos-relative ht-250"> -->
  <div>
    {#if isLoadingHolidays}
    <div class="mg-20 pd-10">
      <div class="placeholder-paragraph">
        <div class="line" />
      </div>
    </div>
    {:else if holidayList && holidayList && holidayList.length}
    {#if holidayList.find((holiday) => {
    let holiDate = new Date(holiday.date);
    return holiDate.getMonth() == today.getMonth() && holiDate.getDate() == today.getDate();
    })}
    <div class="calendar-content-body">
      <div class="fc-view fc-listWeek-view fc-list-view fc-widget-content" >
        <div class="fc-scroller">
          <!-- <div
              class="fc-scroller"
              style="overflow: hidden auto; height: 485px;"> -->
          <table class="fc-list-table">
            <tbody>
              {#each holidayList as holiday (holiday._id)}
              {#if new Date(holiday.date).getMonth() == new Date().getMonth() && new Date(holiday.date).getDate() == new
              Date().getDate()}
              <tr class="fc-list-heading" data-date={convertDateObjToStr(holiday.date)}>
                <td class="fc-widget-header" colspan="3">
                  <a class="fc-list-heading-main">{getMonthName(holiday.date)}<span>{new
                      Date(holiday.date).getDate()}</span></a>
                </td>
              </tr>
              <tr class="fc-list-item" style="border-left-color: rgb(1, 104, 250);">
                <td class="fc-list-item-time fc-widget-content d-flex justify-content-between mg-r-0 pd-r-0"
                  style="color: rgb(1, 104, 250); border-color: rgb(1, 104, 250);">
                  <div class="col-sm-4 col-md-3 col-lg-2">
                    {getDayOfWeek(holiday.date)}
                  </div>
                  <span class="col-sm-8 col-md-9 col-lg-10 pd-0 mg-0">
                    {#if MODULES.hasAccess(MODULES.DELETE_HOLIDAYS)}
                    <a href="javascript:void(0)" on:click|stopPropagation={()=>
                      deleteHoliday(holiday._id)}
                      class="btn-holiday btn-delete pd-0 mg-0 mg-r-5"
                      >
                      <i data-feather="trash" class="wd-15 ht-15" />
                    </a>
                    {/if}
                    {#if MODULES.hasAccess(MODULES.EDIT_HOLIDAYS)}
                    <a href="javascript:void(0)" on:click|stopPropagation={()=>{
                      accessModuleEdit = true
                      openModal(holiday._id)}
                      }
                      class="btn-holiday btn-edit pd-0 mg-0 mg-r-5"
                      >
                      <i data-feather="edit" class="wd-15 ht-15" />
                    </a>
                    {/if}
                  </span>
                </td>
                <td class="fc-list-item-marker fc-widget-content">
                  <span class="fc-event-dot" style="background-color:#d9e8ff" />
                </td>
                <td class="fc-list-item-title fc-widget-content" style="border-color: rgb(1, 104, 250);">
                  <a href="javascript:void(0)">{holiday.name}</a>
                  <span class="fc-desc">
                    {#each holiday.batchName as batchName}
                    <span class="badge badge-info mg-r-5">
                      {@html batchName}
                    </span>
                    {/each}
                  </span>
                </td>
              </tr>
              {/if}
              {/each}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    {:else}
    <EmptyHoliday mode={search.value ? "search" : "today" } {year} />
    {/if}
    {:else}
    <EmptyHoliday mode={search.value ? "search" : "today" } {year} />
    {/if}
  </div>
  <!-- todays holidays end -->
  {/if}
  <!-- upcoming holidays start -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30 mg-t-15">
    <div>
      <h4 class="mg-b-0 tx-spacing--1">
        {#if year == new Date().getFullYear()}
        Upcoming Holidays
        {:else}Holidays of {year}{/if}
      </h4>
    </div>
  </div>
  <!-- <div class="scroll1 pos-relative ht-250"> -->
  <div>
    {#if isLoadingHolidays}
    <div class="mg-20 pd-10">
      <div class="placeholder-paragraph">
        <div class="line" />
      </div>
    </div>
    {:else if holidayList && holidayList && holidayList.length}
    {#if holidayList.find((holiday) => {
    let holiDate1 = new Date(holiday.date);
    return holiDate1.getFullYear() != today.getFullYear() || holiDate1.getMonth() > today.getMonth() ||
    (holiDate1.getMonth() == today.getMonth() && holiDate1.getDate() > today.getDate());
    })}
    <div class="calendar-content-body">
      <div class="fc-view fc-listWeek-view fc-list-view fc-widget-content" >
        <div class="fc-scroller">
          <!-- <div
              class="fc-scroller"
              style="overflow: hidden auto; height: 485px;"> -->
          <table class="fc-list-table">
            <tbody>
              {#each holidayList as holiday (holiday._id)}
              {#if new Date(holiday.date).getFullYear() != today.getFullYear() || new Date(holiday.date).getMonth() >
              today.getMonth() || (new Date(holiday.date).getMonth() == today.getMonth() && new
              Date(holiday.date).getDate() > today.getDate())}
              <tr class="fc-list-heading" data-date={convertDateObjToStr(holiday.date)}>
                <td class="fc-widget-header" colspan="3">
                  <a class="fc-list-heading-main">{getMonthName(holiday.date)}<span>{new
                      Date(holiday.date).getDate()}</span></a>
                </td>
              </tr>
              <tr class="fc-list-item" style="border-left-color: rgb(1, 104, 250);">
                <td class="fc-list-item-time fc-widget-content d-flex"
                  style="color: rgb(1, 104, 250); border-color: rgb(1, 104, 250);">
                  <div class="col-sm-4 col-md-3 col-lg-2">
                    {getDayOfWeek(holiday.date)}
                  </div>
                  <span class="col-sm-8 col-md-9 col-lg-10 pd-0 mg-0">
                    {#if MODULES.hasAccess(MODULES.DELETE_HOLIDAYS)}
                    <a href="javascript:void(0)" on:click|stopPropagation={()=>
                      deleteHoliday(holiday._id)}
                      class="btn-holiday btn-delete pd-0 mg-0 mg-r-5"
                      >
                      <i data-feather="trash" class="wd-15 ht-15" />
                    </a>
                    {/if}
                    {#if MODULES.hasAccess(MODULES.EDIT_HOLIDAYS)}
                    <a href="javascript:void(0)" on:click|stopPropagation={()=>{
                      accessModuleEdit = true
                      openModal(holiday._id)}
                    }
                      class="btn-holiday btn-edit pd-0 mg-0 mg-r-5"
                      >
                      <i data-feather="edit" class="wd-15 ht-15" />
                    </a>
                    {/if}
                  </span>
                </td>
                <td class="fc-list-item-marker fc-widget-content">
                  <span class="fc-event-dot" style="background-color:#d9e8ff" />
                </td>
                <td class="fc-list-item-title fc-widget-content" style="border-color: rgb(1, 104, 250);">
                  <a href="javascript:void(0)">{holiday.name}</a>
                  <span class="fc-desc">
                    {#each holiday.batchName as batchName}
                    <span class="badge badge-info mg-r-5">
                      {@html batchName}
                    </span>
                    {/each}
                  </span>
                </td>
              </tr>
              {/if}
              {/each}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    {:else}
    <EmptyHoliday mode={search.value ? "search" : "upcoming" } {year} />
    {/if}
    {:else}
    <EmptyHoliday mode={search.value ? "search" : "upcoming" } {year} />
    {/if}
  </div>
  <!-- upcoming holidays end -->
  <!-- expired holidays start -->
  {#if year == new Date().getFullYear()}
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30 mg-t-15">
    <div>
      <h4 class="mg-b-0 tx-spacing--1">Expired Holidays</h4>
    </div>
  </div>
  <!-- <div class="scroll1 pos-relative ht-250"> -->
  <div>
    {#if isLoadingHolidays}
    <div class="mg-20 pd-10">
      <div class="placeholder-paragraph">
        <div class="line" />
      </div>
    </div>
    {:else if holidayList && holidayList && holidayList.length}
    {#if holidayList.find((holiday) => {
    let holiDate3 = new Date(holiday.date);
    return holiDate3.getMonth() < today.getMonth() || (holiDate3.getMonth()==today.getMonth() && holiDate3.getDate() <
      today.getDate()); })} <div class="calendar-content-body">
      <div class="fc-view fc-listWeek-view fc-list-view fc-widget-content" >
        <div class="fc-scroller">
          <!-- <div
              class="fc-scroller"
              style="overflow: hidden auto; height: 485px;"> -->
          <table class="fc-list-table">
            <tbody>
              {#each holidayList as holiday (holiday._id)}
              {#if new Date(holiday.date).getMonth() < new Date().getMonth() || (new Date(holiday.date).getMonth()==new
                Date().getMonth() && new Date(holiday.date).getDate() < new Date().getDate())} <tr
                class="fc-list-heading" data-date={convertDateObjToStr(holiday.date)}>
                <td class="fc-widget-header" colspan="3">
                  <a class="fc-list-heading-main">{getMonthName(holiday.date)}<span>{new
                      Date(holiday.date).getDate()}</span></a>
                </td>
                </tr>
                <tr class="fc-list-item" style="border-left-color: rgb(1, 104, 250);">
                  <td class="fc-list-item-time fc-widget-content d-flex"
                    style="color: rgb(1, 104, 250); border-color: rgb(1, 104, 250);">
                    <div class="col-sm-4 col-md-3 col-lg-2">
                      {getDayOfWeek(holiday.date)}
                    </div>
                    <span class="col-sm-8 col-md-9 col-lg-10 pd-0 mg-0">
                      {#if MODULES.hasAccess(MODULES.DELETE_HOLIDAYS)}
                      <a href="javascript:void(0)" on:click|stopPropagation={()=>
                        deleteHoliday(holiday._id)}
                        class="btn-holiday btn-delete pd-0 mg-0 mg-r-5"
                        >
                        <i data-feather="trash" class="wd-15 ht-15" />
                      </a>
                      {/if}
                      {#if MODULES.hasAccess(MODULES.VIEW_HOLIDAY_DETAILS)}
                      <a href="javascript:void(0)" on:click|stopPropagation={()=>{
                        accessModuleEdit = true
                        openModal(holiday._id)}
                        }
                        class="btn-holiday btn-edit pd-0 mg-0 mg-r-5"
                        >
                        <i data-feather="edit" class="wd-15 ht-15" />
                      </a>
                      {/if}
                    </span>
                  </td>
                  <td class="fc-list-item-marker fc-widget-content">
                    <span class="fc-event-dot" style="background-color:#d9e8ff" />
                  </td>
                  <td class="fc-list-item-title fc-widget-content" style="border-color: rgb(1, 104, 250);">
                    <a href="javascript:void(0)">{holiday.name}</a>
                    <span class="fc-desc">
                      {#each holiday.batchName as batchName}
                      <span class="badge badge-info mg-r-5">
                        {@html batchName}
                      </span>
                      {/each}
                    </span>
                  </td>
                </tr>
                {/if}
                {/each}
            </tbody>
          </table>
        </div>
      </div>
  </div>
  {:else}
  <EmptyHoliday mode={search.value ? "search" : "expired" } {year} />
  {/if}
  {:else}
  <EmptyHoliday mode={search.value ? "search" : "expired" } {year} />
  {/if}
</div>
{/if}
<!-- expired holidays end -->
</div>
<div class="modal" bind:this={elem_modal} tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="umbrella" class="wd-60 ht-60" /></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">{modalTitle}</h4>
            <p class="tx-13 tx-color-03 mg-b-0">
              Provide info about your holiday.
            </p>
          </div>
        </div>
        <!-- media -->
      </div>
      <!-- modal-header -->
      <!-- <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <span class="tx-color-04"><i data-feather="umbrella" class="wd-40 ht-40 stroke-wd-3 mg-b-20" /></span>
        <h4 class="modal-title mg-l-15" id="exampleModalLabel6">{modalTitle}</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <hr class="full-width" />
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
        <div class="row">
          <div class="form-group col-sm-6 col-md-6 col-lg-6">
            <label class="d-inline-block mg-r-10" for="holiday-name">Name
              <span class="required-field">*</span></label>
            <!-- disabled={ new Date(holidayObj.date).valueOf() < new Date().valueOf()} -->
            <input bind:this={firstInputField} type="text" id="holiday-name" on:keyup={(e)=> e.key === "Enter" &&
            addUpdateHoliday()}
            class="form-control tx-12 d-inline-block"
            placeholder="Enter holiday name"
            bind:value={holidayObj.name}
            />
            {#if errorObj.name}
            <div class="parsley-errors-list">{errorObj.name}</div>
            {/if}
          </div>
          <div class="form-group col-sm-6 col-md-3 col-lg-3">
            <label class="d-inline-block" for="holiday-date">From Date
              <span class="required-field">*</span></label>
            <input bind:this={elem_holidayPickerFromDate} type="date" id="holiday-date"
              min={convertDateObjToStr(minDate)} max={convertDateObjToStr(maxDate)}
              class="form-control tx-12 d-inline-block modal-datepicker" onkeydown="return false" on:change={(event)=> {
            holidayObj.toDate = event.target.value;
            }}
            placeholder="Select date"
            bind:value={holidayObj.fromDate}
            />
            {#if errorObj.fromDate}
            <div class="parsley-errors-list">{errorObj.fromDate}</div>
            {/if}
          </div>
          <div class="form-group col-sm-6 col-md-3 col-lg-3">
            <label class="d-inline-block" for="holiday-date">To Date
              <span class="required-field">*</span></label>
            <input bind:this={elem_holidayPickerToDate} type="date" onkeydown="return false" id="holiday-date" min={holidayObj.fromDate ?
              convertDateObjToStr(new Date(holidayObj.fromDate)) : convertDateObjToStr(minDate)}
              max={convertDateObjToStr(maxDate)} class="form-control tx-12 d-inline-block modal-datepicker"
              placeholder="Select date" bind:value={holidayObj.toDate} />
            {#if errorObj.toDate}
            <div class="parsley-errors-list">{errorObj.toDate}</div>
            {/if}
          </div>
        </div>
        
        <label class="mg-b-10 mg-r-5" for="select-batches">
          <input type="checkbox" name="select-batches" class="select-all-batches"checked={checked} on:click={(e) => allBatchSelected(e)}>
          Select All Batches
          <span class="required-field">*</span></label>
          {#if errorObj.batchList}
          <div class="parsley-errors-list">{errorObj.batchList}</div>
          {/if}
        <div class="ht-350 pos-relative scroll2 scrollbar-lg">
          {#if isLoadingBatches}
          <div class="row row-xs mg-b-20">
            <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
              <div class="placeholder-media">
                <div class="line" />
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
              <div class="placeholder-media">
                <div class="line" />
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
              <div class="placeholder-media">
                <div class="line" />
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
              <div class="placeholder-media">
                <div class="line" />
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
              <div class="placeholder-media">
                <div class="line" />
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
              <div class="placeholder-media">
                <div class="line" />
              </div>
            </div>
          </div>
          {:else if displayBatchList && displayBatchList.length}
          <div class="row row-xs mg-b-10 wd-100p" class:parsley-error={errorObj.batchList}>
            {#each displayBatchList as aBatch (aBatch._id)}
            <div class="col-sm-6 col-lg-4 col-xl-4 mg-b-10">
              <div class="card rounded mg-5 {holidayObj &&
                    holidayObj.batchList &&
                    holidayObj.batchList.includes(aBatch._id)
                      ? 'bd bd-2 bd-primary'
                      : ''}  ht-100 " on:click={(e)=> batchSelectionChanged(aBatch._id)}
                >
                <div class="card-body ">
                  <h6 class="card-title pd-b-0 mg-b-5" for="aBatch" data-toggle="tooltip" data-placement="bottom" title={aBatch.name}
                    style="text-transform: capitalize; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {aBatch.name}
                  </h6>
                  <small class="card-text text-muted pd-t-0 mg-t-5" for="aBatch" style="text-transform: capitalize">-
                    {aBatch.createdByUser}</small>
                </div>
              </div>
            </div>
            {:else}
            <EmptyBatch />
            {/each}
          </div>
          {/if}
         
        </div>
      </div>
      <div class="modal-footer pd-l-25 pd-r-25 pd-b-25">
        <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">
          CLOSE
          <i data-feather="x" class="wd-10 mg-l-5" />
        </button>
      
        {#if MODULES.hasAccess(MODULES.ADD_HOLIDAYS) || (MODULES.hasAccess(MODULES.EDIT_HOLIDAYS) && modalTitle == "Change holiday details")}
        <button disabled={isSaving || isDeleting} type="button" on:click={addUpdateHoliday}
          class="btn btn-primary tx-12">
          <ButtonLoader isLoading={isSaving} btnText="SAVE" />
          <i data-feather="save" class="wd-10 mg-l-5" />
        </button>
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .btn-holiday {
    opacity: 0;
    cursor: pointer;
    float: right;
  }

  tr:hover .btn-holiday {
    opacity: 1;
  }

  /* .long-name-wrap {
    word-break: break-all;
    overflow-wrap: break-word;
    min-width: 90%;
    max-width: 90%;
  } */

  .calendar-content-body {
    height: auto;
  }

  .holiday-cards-wrap {
    flex-wrap: wrap;
  }

  .card * {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
  }

  .card .hidden-dropdown {
    display: none;
  }

  .card:hover .hidden-dropdown,
  .card:focus .hidden-dropdown {
    display: inherit;
  }

  .fc-agenda-view .fc-day-header.fc-today>a {
    color: #0168fa;
  }

  .fc-listMonth-view,
  .fc-listWeek-view {
    border-width: 0;
  }

  .fc-listMonth-view .fc-scroller,
  .fc-listWeek-view .fc-scroller {
    padding: 20px 20px 20px 0;
  }

  .fc-listMonth-view .fc-list-table,
  .fc-listWeek-view .fc-list-table {
    border-collapse: separate;
    display: block;
  }

  .fc-listMonth-view .fc-list-table>tbody,
  .fc-listWeek-view .fc-list-table>tbody {
    display: flex;
    flex-wrap: wrap;
  }

  .fc-listMonth-view .fc-list-heading,
  .fc-listWeek-view .fc-list-heading {
    flex: 0 0 20%;
    max-width: 20%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 576px) {

    .fc-listMonth-view .fc-list-heading,
    .fc-listWeek-view .fc-list-heading {
      flex: 0 0 15%;
      max-width: 15%;
    }
  }

  @media (min-width: 768px) {

    .fc-listMonth-view .fc-list-heading,
    .fc-listWeek-view .fc-list-heading {
      flex: 0 0 12%;
      max-width: 12%;
    }
  }

  @media (min-width: 992px) {

    .fc-listMonth-view .fc-list-heading,
    .fc-listWeek-view .fc-list-heading {
      flex: 0 0 10%;
      max-width: 10%;
    }
  }

  @media (min-width: 1200px) {

    .fc-listMonth-view .fc-list-heading,
    .fc-listWeek-view .fc-list-heading {
      flex: 0 0 8%;
      max-width: 8%;
    }
  }

  .fc-listMonth-view .fc-list-heading:first-child,
  .fc-listWeek-view .fc-list-heading:first-child {
    margin-top: 0;
  }

  .fc-listMonth-view .fc-list-heading:first-child+.fc-list-item,
  .fc-listWeek-view .fc-list-heading:first-child+.fc-list-item {
    margin-top: 0;
  }

  .fc-listMonth-view .fc-list-heading td,
  .fc-listWeek-view .fc-list-heading td {
    background-color: transparent;
    border-width: 0;
    padding: 0;
  }

  .fc-listMonth-view .fc-list-heading-main,
  .fc-listWeek-view .fc-list-heading-main {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    color: #8392a5;
    font-weight: 500;
    padding: 5px;
    text-align: center;
  }

  .fc-listMonth-view .fc-list-heading-main span:last-child,
  .fc-listWeek-view .fc-list-heading-main span:last-child {
    display: block;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -1.5px;
    color: #001737;
    line-height: 1;
  }

  @media (min-width: 576px) {

    .fc-listMonth-view .fc-list-heading-main span:last-child,
    .fc-listWeek-view .fc-list-heading-main span:last-child {
      font-size: 32px;
    }
  }

  @media (min-width: 1200px) {

    .fc-listMonth-view .fc-list-heading-main span:last-child,
    .fc-listWeek-view .fc-list-heading-main span:last-child {
      font-size: 36px;
    }
  }

  .fc-listMonth-view .fc-list-heading-main.now,
  .fc-listWeek-view .fc-list-heading-main.now {
    color: #0168fa;
  }

  .fc-listMonth-view .fc-list-heading-main.now span:last-child,
  .fc-listWeek-view .fc-list-heading-main.now span:last-child {
    color: #0168fa;
  }

  .fc-listMonth-view .fc-list-item,
  .fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(80% - 5px);
    max-width: calc(80% - 5px);
    display: flex;
    flex-direction: column;
    border-left: 4px solid transparent;
    background-color: #fff;
    margin-top: 15px;
  }

  @media (min-width: 576px) {

    .fc-listMonth-view .fc-list-item,
    .fc-listWeek-view .fc-list-item {
      flex: 0 0 calc(85% - 5px);
      max-width: calc(85% - 5px);
    }
  }

  @media (min-width: 768px) {

    .fc-listMonth-view .fc-list-item,
    .fc-listWeek-view .fc-list-item {
      flex: 0 0 calc(88% - 5px);
      max-width: calc(88% - 5px);
    }
  }

  @media (min-width: 992px) {

    .fc-listMonth-view .fc-list-item,
    .fc-listWeek-view .fc-list-item {
      flex: 0 0 calc(90% - 5px);
      max-width: calc(90% - 5px);
    }
  }

  @media (min-width: 1200px) {

    .fc-listMonth-view .fc-list-item,
    .fc-listWeek-view .fc-list-item {
      flex: 0 0 calc(92% - 5px);
      max-width: calc(92% - 5px);
    }
  }

  /* .fc-listMonth-view .fc-list-item:hover,
  .fc-listMonth-view .fc-list-item:focus,
  .fc-listWeek-view .fc-list-item:hover,
  .fc-listWeek-view .fc-list-item:focus {
    
  } */
  .fc-listMonth-view .fc-list-item:hover td,
  .fc-listMonth-view .fc-list-item:focus td,
  .fc-listWeek-view .fc-list-item:hover td,
  .fc-listWeek-view .fc-list-item:focus td {
    background-color: transparent;
  }

  .fc-listMonth-view .fc-list-item>td,
  .fc-listWeek-view .fc-list-item>td {
    border-width: 0;
  }

  .fc-listMonth-view .fc-list-item>td.fc-list-item-time,
  .fc-listWeek-view .fc-list-item>td.fc-list-item-time {
    border-top: 1px solid #e5e9f2;
    border-right: 1px solid #e5e9f2;
    box-sizing: border-box;
  }

  .fc-listMonth-view .fc-list-item>td.fc-list-item-title,
  .fc-listWeek-view .fc-list-item>td.fc-list-item-title {
    border-bottom: 1px solid #e5e9f2;
    border-right: 1px solid #e5e9f2;
    box-sizing: border-box;
  }

  .fc-listMonth-view .fc-list-item+.fc-list-item,
  .fc-listWeek-view .fc-list-item+.fc-list-item {
    margin-left: 20%;
  }

  @media (min-width: 576px) {

    .fc-listMonth-view .fc-list-item+.fc-list-item,
    .fc-listWeek-view .fc-list-item+.fc-list-item {
      margin-left: 15%;
    }
  }

  @media (min-width: 768px) {

    .fc-listMonth-view .fc-list-item+.fc-list-item,
    .fc-listWeek-view .fc-list-item+.fc-list-item {
      margin-left: 12%;
    }
  }

  @media (min-width: 992px) {

    .fc-listMonth-view .fc-list-item+.fc-list-item,
    .fc-listWeek-view .fc-list-item+.fc-list-item {
      margin-left: 10%;
    }
  }

  @media (min-width: 1200px) {

    .fc-listMonth-view .fc-list-item+.fc-list-item,
    .fc-listWeek-view .fc-list-item+.fc-list-item {
      margin-left: 8%;
    }
  }

  .fc-listMonth-view .fc-list-item-marker,
  .fc-listWeek-view .fc-list-item-marker {
    display: none;
  }

  .fc-listMonth-view .fc-list-item-time,
  .fc-listWeek-view .fc-list-item-time {
    padding: 12px 15px 2px;
    width: 100%;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto,
      sans-serif;
    letter-spacing: 0.5px;
  }

  .fc-listMonth-view .fc-list-item-title,
  .fc-listWeek-view .fc-list-item-title {
    padding: 0 15px 12px;
  }

  .fc-listMonth-view .fc-list-item-title a,
  .fc-listWeek-view .fc-list-item-title a {
    display: block;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 2px;
    color: #001737;
  }

  @media (min-width: 576px) {

    .fc-listMonth-view .fc-list-item-title a,
    .fc-listWeek-view .fc-list-item-title a {
      font-size: 18px;
      cursor: default;
    }
  }

  .fc-listMonth-view .fc-list-item-title .fc-desc,
  .fc-listWeek-view .fc-list-item-title .fc-desc {
    font-size: 12px;
    line-height: 1.375;
    display: block;
    color: #8392a5;
  }

  @media (min-width: 992px) {

    .fc-listMonth-view .fc-list-item-title .fc-desc,
    .fc-listWeek-view .fc-list-item-title .fc-desc {
      font-size: 13px;
    }
  }
</style>
<!-- {@html holiday &&
  holiday.batchList &&
  holiday.batchList
    .map(
      (item) =>
        `<span class="badge badge-info mg-r-5">${
          displayBatchList &&
          displayBatchList.length
            ? displayBatchList.find(
                (item2) => item2._id == item
              ).name
            : item
        }</span>`
    )
    .join("")} -->
