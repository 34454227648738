<script>
  import { onMount } from "svelte";
  import { link, links, navigate } from "svelte-routing";
  import { activeMenu } from "../../stores";
  import Empty from "../empty/empty.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js"  
  let elem_datatable;
  let isEmpty = true;
  let isSearch = false;

  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [year, month, day].join("-");
    return dateString;
  }
  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.severity = order;
    } else if (sortOrder.column === 2) {
      sort.type = order;
    } else if (sortOrder.column === 4) {
      sort.createdOn = order;
    } else if (sortOrder.column === 5) {
      sort.status = order;
    } else {
      sort.feedbackId = order;
    }
    return sort;
  }
  function initDatatable() {
    _$(elem_datatable).DataTable({
      // dom: 'flr<"toolbar">tip',
      serverSide: true,
      responsive: false,
      language: {
        processing: "",
        searchPlaceholder: "Feedback ID",
        sSearch: "",
        lengthMenu: "_MENU_ items/page",
      },
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data));
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname));
      },
      ajax: async function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        delete data.order;
        isSearch = Boolean(data.search.value);
        console.info(data);
        // fetch call
        let response = await api_handleApiCalls("feedback.fetchStudentFeedbackList", data);
        console.info({ response });
        if (response.status) {
          var paginationData = response.data;
          var result = {
            recordsTotal: Number(paginationData.recordsTotal),
            recordsFiltered: Number(paginationData.recordsFiltered),
            data: paginationData.data,
          };
          isEmpty = !Boolean(result.data.length);
          callback(result);
        } else {
          console.error("something went wrong");
          var result = {
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          };
          isEmpty = true;
          callback(result);
        }
      },
      columns: [
        { data: "feedbackId" },
        {
          data: "query",
          orderable: false,
          render: (data) => {
            return `
            <div style="width:400px; max-height: 400px !important; overflow:auto;">
              ${data}
            </div>
          `;
          },
        },
        {
          data: "severity",
          render: (data) => {
            switch (data) {
              case 1:
                return "Very High";
              case 2:
                return "High";
              case 3:
                return "Medium";
              case 4:
                return "Low";
              case 5:
                return "Very Low";
              default:
                return "--";
            }
          },
        },
        {
          data: "type",
          render: (data) =>
            `<div style="text-transform:capitalize">${data}</div>`,
        },
        { data: "createdOn", render: (data) => convertDateObjToStr(data) },
        {
          data: "status",
          render: (data) =>
            `<div style="text-transform:capitalize">${data}</div>`,
        },
        {
          data: "_id",
          orderable: false,
          render: (data, a, b, c) => {
            // console.table({ data, a, b, c });
            var edit = ""
            if(MODULES.hasAccess(MODULES.EDIT_FEEDBACK)){
              edit = `<a href="javascript:void(0)" data-id="${data}" class='btn-view mg-r-5' data-toggle="tooltip" data-placement="bottom" title="Edit"><i data-feather="eye" ></i> </a>`
            }
            return `
                  <span>
                    ${edit? edit : "-"}
                    <!--a href="javascript:void(0)" class='btn-archive mg-r-5' data-id='${data}' data-toggle="tooltip" data-placement="bottom" title="Delete"><i data-feather="trash" ></i> </a-->
                  </span>
                    `;
          },
        },
      ],
      columnDefs: [
        { targets: 0, width: "12%" },
        { targets: 1 },
        { targets: 2, width: "12%" },
        { targets: 3, width: "12%" },
        { targets: 4, width: "12%" },
        { targets: 5, width: "12%" },
        { targets: 6, width: "12%" },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        _$(".btn-view").on("click", function (event) {
          let id = _$(this).data("id");
          // _$(this).tooltip("dispose");
          let route = `/dashboard/studentFeedback/${id}`;
          navigate(route);
        });
        // _$(".btn-archive").on("click", async function (event) {
        //   let id = _$(this).data("id");
        //   // _$(this).tooltip("dispose");
        //   pConfirm(
        //     "Delete user?",
        //     "Are you sure you want to delete this user?",
        //     async function () {
        //       console.info("Deleting");
        //       //   delete api_handleApiCalls
        //       let result = await api_delete({ _id: Id });
        //       if (result.status) {
        //         // reload table
        //         pSuccess("", result.data);
        //         reload();
        //       } else {
        //         pError("", result.errMsg);
        //       }
        //     }
        //   );
        // });
        _$("#table tbody").on("click", "tr td", function (e) {
          // responsive expand button clicked
          feather.replace({ width: "1em", height: "1em" });
          _$(".btn-view").text("View");
          _$(".btn-archive").text("Archive");
        });
        // _$('[data-toggle="tooltip"]').tooltip();
      },
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }
  onMount(function () {
    feather.replace();
    activeMenu.set("studentFeedback");
    initDatatable();
  });
</script>

<style>
</style>

<div class="content-body animated scale">
  <!-- breadcrumbs -->
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Feedback</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Feedback</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search or add new feedback </p>
    </div>
    <div class="d-inline-block">
      {#if MODULES.hasAccess(MODULES.ADD_FEEDBACK)}
      <a
        href="/dashboard/studentFeedback/new"
        class="btn pd-x-15 btn-primary btn-uppercase mg-l-5 btn-sm"
        use:link><i data-feather="plus" class="wd-10 mg-r-5" />
        Add new Feedback
      </a>
      {/if}
    </div>
  </div>
  <hr class="full-width">
  <div data-label="Example" class="df-example demo-table">
    <table bind:this={elem_datatable} class="table">
      <thead>
        <tr>
          <th>Feedback ID</th>
          <th>Query</th>
          <th>Severity</th>
          <th>Type</th>
          <th>Added On</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody />
    </table>
    {#if isEmpty}
      {#if isSearch}
        <Empty />
      {:else}
        <Empty />
      {/if}
    {/if}
  </div>
</div>
