<script>
	// NEED to implement this
	//https://levelup.gitconnected.com/code-splitting-in-svelte-js-routify-7de5fe919f61
	import { Router, Link, Route } from "svelte-routing";
	import { fade, fly } from "svelte/transition";
	import Dashboard from "./dashboard/dashboard.svelte";
	import Login from "./login/login.svelte";
	import InactiveStudent from "./login/inactiveStudent.svelte";
	import CancelAdmisson from "./login/canceladmission.svelte";
	import Restricted from "./login/restricted.svelte";
	import Home from "./home/home.svelte";
	import Signup from "./signup/signup.svelte";
	import TestPagePrint from "../frontend/printPdf/TestPagePrint.svelte";
	import { onMount } from "svelte";
	import jQuery from "jquery";

	let elapsed = 0;
	let duration = 100;
	let isLoading = true;
	let percentage = 0;
	let style = "width:0;";

	onMount(async () => {
		var jsTotalSize = 0;
		var cssTotalSize = 0;
		var jsLoaded = 0;
		var cssLoaded = 0;

		var isJsLoaded = false;
		var isCssLoaded = false;
		var updateProgress = function () {
			percentage =
				((jsLoaded + cssLoaded) / (jsTotalSize + cssTotalSize)) * 100;
			style = "width:" + percentage + "%;";
		};
		var checkBothLoaded = function () {
			if (isJsLoaded && isCssLoaded) {
				setTimeout(function () {
					isLoading = false;
				}, 100);
			}
		};
		var xhr = new XMLHttpRequest();
		xhr.open("get", "/lib/concat.min.js", true);
		xhr.onprogress = (e) => {
			jsTotalSize ? null : (jsTotalSize = e.total);
			jsLoaded = e.loaded;
			updateProgress();
		};
		xhr.onreadystatechange = function () {
			if (xhr.readyState == 4) {
				if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
					var script = document.createElement("script");
					script.type = "text/javascript";
					script.text = xhr.responseText;
					document.body.appendChild(script);
					window._$ = window.jQuery;
					PNotify.defaults.mode = "light";
					PNotify.defaults.styling = "material";
					PNotify.defaults.icons = "material";
					isJsLoaded = true;
					checkBothLoaded();
				}
			}
		};
		xhr.send(null);
		if (typeof isDevMode == "undefined") {
			var xhr2 = new XMLHttpRequest();
			xhr2.open("get", "/lib/concat.min.css", true);
			xhr2.onprogress = (e) => {
				cssTotalSize ? null : (cssTotalSize = e.total);
				cssLoaded = e.loaded;
				updateProgress();
			};
			xhr2.onreadystatechange = function () {
				if (xhr2.readyState == 4) {
					if ((xhr2.status >= 200 && xhr2.status < 300) || xhr2.status == 304) {
						var css = document.createElement("style");
						css.type = "text/css";
						css.innerHTML = xhr2.responseText;
						document.body.appendChild(css);
						isCssLoaded = true;
						checkBothLoaded();
					}
				}
			};
			xhr2.send(null);
		} else {
			isCssLoaded = true;
		}
	});
</script>

{#if isLoading}
<div class="loading-page">
	<div class="counter">
		<div class="boxLoading"></div>
		<h1>{percentage.toFixed(1)}%</h1>
		<div class="l_bar" {style}></div>
		<p>loading</p>
	</div>
</div>
{:else}
<Router>
	<div class="fullPage">
		<Route path="/" component="{Home}" />
		<Route path="/login/*" component="{Login}" />
		<Route path="/signup/*" component="{Signup}" />
		<Route path="/inactive/*" component="{InactiveStudent}" />
		<Route path="/canceladmission/*" component="{CancelAdmisson}" />
		<Route path="/restricted/*" component="{Restricted}" />
		<Route path="/dashboard/*" component="{Dashboard}" />

		<Route path="/test/:testId/print" let:params>
			<TestPagePrint {params} />
		</Route>
	</div>
</Router>

{/if}
