<script>
  import { onDestroy, onMount, tick } from "svelte";
  import { API_URL } from "../../../config.js"

  // your script goes here
  let previousTestsList = [],
    filter = {};
  let testNameSearch = ""
  export let testId;
  export let selectedQuestions = [];
  export let onModalSelectQuestions;
  let filterQuestionType,
    filterQuestionLevel,
    demoDataTable;
  let selectedIdArr = [],
    tempDisplayList = [],
    qList = [];
  let selectedTestId, selectedTestName;

  let api = async function (action, data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var payload = JSON.stringify({
      action: action,
      accessToken: localStorage.getItem("accessToken"),
      data: data,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };
    try {
      var resObj = await fetch(`${API_URL}`, requestOptions);
      var response = await resObj.json();
      return response;
    } catch (error) {
      console.error("error", error);
      return { error: error };
    }
  };

  let navItemClicked = function (previousTestId, title) {
    selectedTestName = title;
    _$(".prev-test-nav-header .nav-link").removeClass("active");
    _$(
      ".prev-test-nav-header .nav-link[data-prev-test-title='" + title + "']"
    ).addClass("active");
    _$(".a-section").hide();
    _$(".a-section[data-prev-test-title='" + title + "']").show();

    selectedTestId = previousTestId;
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    _$(demoDataTable).DataTable().ajax.reload(null, false);
  };

  let listPreviousTests = async function (query) {
    let result = await api("test.listPreviousTests", {
      excludeTestId: testId,
      search: query,
    });
    if (result.status) {
      // test
      previousTestsList = result.data.data;
    } else {
      previousTestsList = [];
      console.error(result.errMsg || result.error || "something went wrong");
    }
  };

  let onCBChange = function (ele, aQuestion) {
    let obj = {}

    if (aQuestion.qType == 'numerical') {
      obj = {
        ele: ele, q_id: aQuestion._id,
        questionText: aQuestion.data.question.English,
        answer: aQuestion.data.answer,
        additionalInfo: aQuestion.additionalInfo,
        qId: aQuestion.qId
      }
    }
    else {
      obj = {
        ele: ele, q_id: aQuestion._id,
        questionText: aQuestion.data.question.English,
        answer: aQuestion.data.answer,
        qOptions: aQuestion.data.option_a ? {
          option_a: aQuestion.data.option_a.English,
          option_b: aQuestion.data.option_b.English,
          option_c: aQuestion.data.option_c.English,
          option_d: aQuestion.data.option_d.English
        }

          : {},
        additionalInfo: aQuestion.additionalInfo,
        qId: aQuestion.qId
      }
      if (aQuestion.data.option_e) obj.qOptions['option_e'] = aQuestion.data.option_e.English
      if (aQuestion.data.option_f) obj.qOptions['option_f'] = aQuestion.data.option_f.English
      if (aQuestion.data.option_g) obj.qOptions['option_g'] = aQuestion.data.option_g.English
      if (aQuestion.data.option_h) obj.qOptions['option_h'] = aQuestion.data.option_h.English

    }
    if (ele.target.checked) {
      selectedIdArr.push({ ...obj });
      tempDisplayList.push(obj.q_id);
    } else {
      selectedIdArr = selectedIdArr.filter((e) => e.q_id !== obj.q_id);
      tempDisplayList = tempDisplayList.filter((e) => e !== obj.q_id);
    }

    selectedIdArr = selectedIdArr;
    tempDisplayList = tempDisplayList;

  };

  function bindDatatable() {
    _$(demoDataTable).DataTable({
      responsive: true,
      dom: "ip",
      language: {
        processing: "",
        searchPlaceholder: "Search # ID",
        sSearch: "",
        lengthMenu: "_MENU_ Questions/page",
      },
      ajax: function (data, callback, settings) {
        if (!selectedTestId) {
          qList = []
          tempDisplayList = []
          selectedIdArr = []
          return callback({ data: [], recordsTotal: 0, recordsFiltered: 0 });
        }
        let excludeQuestionsIds = [...tempDisplayList, ...selectedQuestions]
        _$.ajax({
          url: API_URL,
          method: "post",
          data: {
            action: "test.getPreviousTestQuestions",
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: data.length,
              search: data.search.value,
              filter,
              previousTestId: selectedTestId,
              excludeQuestionsIds
            },
          },
          success: async function (resData) {
            var result = {};
            var questions = [];

            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.questions,
              };

              qList = [];
              await tick();
              qList = paginationData.questions;
              qList.map((question) => {
                if (selectedQuestions.includes(question._id)) {
                  if (
                    !(tempDisplayList && tempDisplayList.length) ||
                    !tempDisplayList.includes(question._id)
                  ) {
                    let obj = {
                      q_id: question._id,
                      questionText: question.data.question.English,
                      answer: question.data.answer,
                      qOptions: question.data.option_a ? {
                        option_a: question.data.option_a.English,
                        option_b: question.data.option_b.English,
                        option_c: question.data.option_c.English,
                        option_d: question.data.option_d.English
                      }

                        : {},
                      additionalInfo: question.additionalInfo,
                      qId: question.qId
                    }
                    if (question.data.option_e) obj.qOptions['option_e'] = question.data.option_e.English
                    if (question.data.option_f) obj.qOptions['option_f'] = question.data.option_f.English
                    if (question.data.option_g) obj.qOptions['option_g'] = question.data.option_g.English
                    if (question.data.option_h) obj.qOptions['option_h'] = question.data.option_h.English
                    selectedIdArr = [
                      ...selectedIdArr,
                      obj


                    ];
                    tempDisplayList = [...tempDisplayList, question._id];
                  }
                }
              });

              await tick();
              MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
              callback(result);
            } else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            // emptyState = true
          },
        });
      },
      columns: [
        {
          data: "qId",
          render: function (data, type, full) {
            return "";
          },
        },
      ],
      drawCallback: function () {
        feather.replace();
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        // _$('.btn-edit-question').unbind('click').click(function (e) {
        //     e.preventDefault();
        //     var questionId = _$(this).attr("question-id");
        //     navigate(("/dashboard/questions/view/" + questionId))
        //     return false;
        // })
      },
      processing: true,
      serverSide: true,
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }

  let onChooseSelectedPreviousTest = function () {
    // closing = true
    onModalSelectQuestions(selectedIdArr);
  };

  // onDestroy(function(){
  //   if(!closing) onModalSelectQuestions(selectedIdArr, closing);
  // })
  // let closing

  var scroll1
  onMount(function () {
    // closing = false;
    scroll1 = new PerfectScrollbar('.previous-test-list', {
      suppressScrollX: true
    });
    // console.log("previous test mounted");

    bindDatatable();
    listPreviousTests();
    var tags = _$(".chips-tag").selectize({
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create" style="padding-left:15px"><i class="icon ion-md-search" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Search by tag : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
      create: function (input) {
        return { value: input, text: input };
      },
      onChange: function (value) {
        filter.tags = value;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        _$(demoDataTable).DataTable().ajax.reload(null, false);
      },
    });

    // _$('.slectizedd').selectize({ maxItems: 1 });
    _$(filterQuestionType).selectize({
      maxItems: 1,
      onChange: function (value) {
        filter.qType = value;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        _$(demoDataTable).DataTable().ajax.reload(null, false);
      },
    });
    _$(filterQuestionLevel).selectize({
      maxItems: 1,
      onChange: function (value) {
        filter.level = value == "all" ? "" : value;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        _$(demoDataTable).DataTable().ajax.reload(null, false);
      },
    });
  });
</script>

<style>
  .empty-state-img {
    text-align: center;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
</style>

<div data-label="Example" class="df-example demo-table" style="height: 100%;">
  <div class="row" style="height: 100%;">
    <div class="col-sm-2 previous-test-list" style="height: 100%; ">
      <!-- <div style="flex: 1; height: 100%; flex-direction: column " class="">
        <div data-label="Example" class="df-example "> -->
      {#if previousTestsList && previousTestsList.length}

      <div class="col-sm fixed mg-b-10">
        <div class="input-group">
          <!-- <div class="input-group-prepend">
                <span class="input-group-text">#</span>
              </div> -->
          <input type="text" class="form-control" id="testName" placeholder="Search" value={testNameSearch }
            on:input={(event)=>{listPreviousTests(event.target.value)}}>
        </div>
      </div>
      <ul class="nav nav-line flex-column prev-test-nav-header" id="tests-tabs" role="tablist">
        {#each previousTestsList as aTest}
        <li class="nav-item" on:click={()=> navItemClicked(aTest._id, aTest.name)}>
          <a class="nav-link" href="#" data-prev-test-title={aTest.name}>
            {aTest.name}
          </a>
        </li>
        {/each}
      </ul>
      {:else}
      <div class="empty-state-img">
        <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
        <p class="tx-bold">No Test found</p>
      </div>
      {/if}
      <!-- </div>
      </div> -->
    </div>
    <div class="col-sm-10" style="height: 100%; display: flex; flex-direction: column; overflow: hidden;">
      <div>
        <div class="row row-xs">
          <div style="margin-bottom: 10px;">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                <li class="breadcrumb-item">
                  previous test
                </li>
                {#if selectedTestName}
                <li class="breadcrumb-item active" aria-current="page">
                  {selectedTestName}
                </li>
                {/if}
              </ol>
            </nav>
          </div>
        </div>
        <div class="row row-xs">
          <div class="col-sm">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">#</span>
              </div>
              <input type="number" class="form-control" id="qId" placeholder="QuestionId" value={filter.qId || '' }
                on:input={(event)=> {
              filter.qId = parseInt(event.target.value) || '';
              MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
              _$(demoDataTable).DataTable().ajax.reload(null, false);
              }} />
            </div>
          </div>
          <div class="col-sm">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i data-feather="tag" style="width: 15px;height: 17px;" />
                </span>
              </div>
              <input class="info form-control chips-tag has-prepend-icon" type="text" data-role="tagsinput"
                name="chips-tag" placeholder="Search tags..." />
            </div>
          </div>
          <div class="col-sm">
            <select id="qType" name="qTYpe" type="text" class="slectizedd info form-control"
              bind:this={filterQuestionType}>
              <option value="">Select Type</option>
              <option value="all">All Types</option>
              <option value="multipleChoice">Multiple Choice</option>
              <option value="multipleResponse">Multiple Response</option>
              <option value="singleDigit">Integer Type</option>
              <option value="decimalType">Decimal Type</option>
              <option value="trueFalse">True False</option>
              <option value="fillInTheBlanks">Fill in the Blanks</option>
            </select>
          </div>
          <div class="col-sm">
            <select id="qType" name="qTYpe" type="text" class="slectizedd info form-control"
              bind:this={filterQuestionLevel}>
              <option value="">Select Level</option>
              <option value="all">All Levels</option>
              <option value="easy">Easy</option>
              <option value="mid">Medium</option>
              <option value="hard">Hard</option>
            </select>
          </div>
          <div class="col-sm">
            <div class="form-row no-gutters">
              <div class="form-group col-sm">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">+</span>
                  </div>
                  <input type="number" class="form-control pd-0 pd-l-5" id="pmarksEle" placeholder="+ve"
                    value={filter.pmarks || '' } on:input={(event)=> {
                  filter.pmarks = event.target.value;
                  MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                  _$(demoDataTable).DataTable().ajax.reload(null, false);
                  }} />
                </div>
              </div>
              <div class="form-group col-sm">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">-</span>
                  </div>
                  <input type="number" class="form-control" id="nmarksEle" placeholder="-ve" value={filter.nmarks || ''
                    } on:input={(event)=> {
                  filter.nmarks = event.target.value;
                  MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                  _$(demoDataTable).DataTable().ajax.reload(null, false);
                  }} />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm">
            <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25" style="width: 100%;" on:click={()=> {
              filter = {};
              selectedTestId = null
              selectedTestName = null
              _$('.chips-tag')[0].selectize.setValue('');
              _$(filterQuestionLevel)[0].selectize.setValue('');
              _$(filterQuestionType)[0].selectize.setValue('');
              MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
              _$(demoDataTable).DataTable().ajax.reload(null, false);
              }}><i data-feather="x-circle" class="wd-10 mg-r-5" />
              Clear Filter
            </button>
          </div>
        </div>
      </div>
      <div class="q-list-sec" style="flex: 1;overflow: auto;">
        <input type="checkbox" class="custom-control-input" name="selected_q" bind:group={selectedQuestions} value="0"
          hidden />
        {#each qList as aQuestion}
        <div class="question-display"
          style="border-radius: 4px; border : 1px solid #ddd;    display: flex;align-items: center; margin-bottom: 15px;">
          <div class="col-sm-2 col-md-1">
            <div class="custom-control custom-checkbox">
              {#if tempDisplayList.indexOf(aQuestion._id) > -1}
              <input type="checkbox" class="custom-control-input" id="sq_{aQuestion._id}" name="selected_q"
                on:change={(ele)=> onCBChange(ele, aQuestion)}
              checked
              value={aQuestion._id} />
              <label class="custom-control-label" for="sq_{aQuestion._id}"> {aQuestion.qId ||
                aQuestion.qId_synced}</label>
              {:else}
              <input type="checkbox" class="custom-control-input" id="sq_{aQuestion._id}" name="selected_q"
                on:change={(ele)=> onCBChange(ele, aQuestion)}
              value={aQuestion._id} />
              <label class="custom-control-label" for="sq_{aQuestion._id}"> {aQuestion.qId || aQuestion.qId_synced}
              </label>
              {/if}
            </div>
          </div>
          <div style="padding : 15px; padding-left: 0; ">
            {@html aQuestion.data && aQuestion.data.question && aQuestion.data.question.English ?
            aQuestion.data.question.English : '-'}
          </div>
        </div>
        {:else}
        <div class="empty-state-img">
          <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
          <p class="tx-bold">
            No questions found OR All questions in selected test are already
            added
          </p>
        </div>
        {/each}
      </div>
      <div>
        <table bind:this={demoDataTable} class="table" style="display: none;">
          <thead>
            <tr>
              <th class="wd-20p">ID</th>
            </tr>
          </thead>
          <tbody />
        </table>
      </div>
      <div style="padding-top: 15px;">
        <button on:click={onChooseSelectedPreviousTest} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25"
          style="width: 100%;" disabled={selectedIdArr.length < 1}>
          <i data-feather="pocket" class="wd-10 mg-r-5" />
          {#if selectedIdArr.length}
          Choose
          {selectedIdArr.length}
          New Questions From Selected Test
          {:else}
          Choose Selected Question
          {/if}
        </button>
      </div>
    </div>
  </div>
</div>
