<script>
  import { setContext, onMount, tick } from "svelte";
  import ButtonLoader from "../utils/button_loader.svelte";
  import moment from "moment";
  import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
  import { pAlert } from "../utils/notify.svelte";
  import Empty from "./empty.svelte";
  import ExcelJS from 'exceljs'
  import { API_URL } from "../../config.js"

  export let id;
  export let isExport = false
  let comments = { data: [], totalCount: 0 };
  let search = { value: "" };
  let length = 0;
  const pageSize = 10;
  let groupedComments = [];
  let elem_comment_editor, elem_uploadFile, scroll;
  let isSending, isLoading, isLoadingComments;
  let dateFrom, dateTo
  let exportLoading = false
  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    let dayOfWeek = date.getDay();
    switch (dayOfWeek) {
      case 0:
        dayOfWeek = "SUNDAY";
        break;
      case 1:
        dayOfWeek = "MONDAY";
        break;
      case 2:
        dayOfWeek = "TUESDAY";
        break;
      case 3:
        dayOfWeek = "WEDNESDAY";
        break;
      case 4:
        dayOfWeek = "THURSDAY";
        break;
      case 5:
        dayOfWeek = "FRIDAY";
        break;
      case 6:
        dayOfWeek = "SATURDAY";
        break;
    }
    var dateString = dayOfWeek + ", " + [year, month, day].join("-");
    return dateString;
  }
  async function api(action, data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var payload = JSON.stringify({
      accessToken: localStorage.getItem("accessToken"),
      action,
      data,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };
    try {
      var resObj = await fetch(API_URL, requestOptions);
      var response = await resObj.json();
      return response;
    } catch (error) {
      console.error("error", error);
      return { error: error };
    }
  }
  async function fetchComments(keepScrollPosition = false) {
    if (isLoadingComments) return;
    isLoadingComments = true;
    await tick();
    if (!keepScrollPosition) {
      document
        .querySelector(".comment-scroll")
        .scrollTo(0, document.querySelector(".chat-group").scrollHeight);
      scroll.update();
    }
    await tick();
    // console.table({ id, isLoadingComments });
    let data = { txnId: id, start: 0, length, search };
    let result = await api("comments.fetchComments", data);
    if (result.status) {
      comments = result.data;
    } else if (result.errMsg) {
      pError("", result.errMsg);
    } else if (result.error) {
      pError("", result.error);
    } else {
      pError("", "Something went wrond");
    }
    isLoadingComments = false;
    await tick();
    if (!keepScrollPosition) {
      await tick();
      document
        .querySelector(".comment-scroll")
        .scrollTo(0, document.querySelector(".chat-group").scrollHeight);
      scroll.update();
    }
    await tick();
    feather.replace({ width: "1em", height: "1em" });
  }
  function loadMore() {
    length += pageSize;
    fetchComments(true);
  }
  async function makeComment() {
    if (isSending) return;
    let comment;
    // comment = CKEDITOR.instances['comment_editor'].getData()
    comment = elem_comment_editor;
    if (!comment) return;
    // console.table({ id, comment, isSending });
    isSending = true;
    await tick();
    document
      .querySelector(".comment-scroll")
      .scrollTo(0, document.querySelector(".chat-group").scrollHeight);
    scroll.update();
    await tick();
    let data = { txnId: id, comment, type: "text" };
    let result = await api("comments.saveComment", data);
    if (result.status) {
      elem_comment_editor = "";
    } else if (result.errMsg) {
      pError("", result.errMsg);
    } else if (result.error) {
      pError("", result.error);
    } else {
      pError("", "Something went wrong");
    }
    isSending = false;
    await tick();
    document
      .querySelector(".comment-scroll")
      .scrollTo(0, document.querySelector(".chat-group").scrollHeight);
    scroll.update();
    await tick();
    reload();
  }

  async function bindFileChange(e) {
    let file = e.target.files[0];
    // fieldName is the name of the input field
    // file is the actual file object to send
    const formData = new FormData();
    formData.append("comment", file, file.name);

    let originalFileName = file.name;
    let _fileName = file.name.split(".");

    var fileExtension = _fileName[_fileName.length - 1];
    var fileSize = file.size;
    //  var formData = new FormData();
    // formData.append('file', file, "newFileName"+_fileName);
    var res = await api("storage.getUrlForUpload", {
      fileId: id,
      name: originalFileName,
      type: file.type,
      uploadType: "COMMENT_ATTACHEMENT",
    });
    // console.log("res : ", res);
    if (res.status) {
      _$.ajax({
        xhr: function () {
          var xhr = new window.XMLHttpRequest();
          xhr.upload.addEventListener(
            "progress",
            function (e) {
              // var percentComplete = (evt.loaded / evt.total) * 100;
              // console.log("percentComplete : ", percentComplete);
            },
            false
          );
          return xhr;
        },
        url: res.data.url,
        type: "PUT",
        data: file,
        dataType: "text",
        cache: false,
        contentType: file.type,
        processData: false,
      })
        .done(async function (resss) {
          await api("comments.saveComment", {
            type: "file",
            txnId: id,
            attachment: {
              originalFileName: originalFileName,
              fileExtension: fileExtension,
              fileSize: fileSize,
              url: res.data.url,
            },
          });
          // console.log("asdfasd : ", resss);
          // console.info("YEAH", res.data.url.split("?")[0].substr(6));
          reload();
        })
        .fail(function (err) {
          console.error("damn...", err);
        });
    } else {
      // todo show erro toast
    }
    return {
      abort: () => {
        // This function is entered if the user has tapped the cancel button
        // request.abort();
        // Let FilePond know the request has been cancelled
        // abort();
        console.log("aborting...");
      },
    };
  }

  function openFileUploader() {
    _$(elem_uploadFile).click();
  }

  export function reload() {
    fetchComments();
  }
  onMount(async function () {
    // CKEDITOR.inline('comment_editor', {
    //   placeholder:"Type New Comment",
    //   readOnly: false,
    // })
    scroll = new PerfectScrollbar(".comment-scroll", {
      suppressScrollX: true,
    });
    length = pageSize;
    reload();
  });

  var btnExportConversation = async () => {
    if (exportLoading) return;
    exportLoading = true;
    if (!dateFrom) {
      pError("", "Please select Date from to export conversation data")
      return
    }
    else if (!dateTo) {
      pError("", "Please select Date To to export conversation data")
      return
    } else {
      let data = {
        txnId: id,
        dateFrom: new Date(dateFrom),
        dateTo: new Date(dateTo)
      };
      let commentsData = await api("comments.exportComments", data);
      // let commentsData = await api_exportCommentsData(data);
      let comments = commentsData.data
      // console.log("comments:::", comments)

      let allComments = comments.data;
      if (allComments) {
        generateWorkBook(allComments);
      }

    }

    exportLoading = false;
  };

  async function generateWorkBook(commentsDataList) {
    // console.log("commentsDataList:::", commentsDataList);

    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet("Conversation Data", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });

    let fileName = "Conversation_Data_Export_" + new Date().getTime() + "_.xlsx";

    let columnsArr = [
      { header: "Sr No.", key: "serialNo", width: 15 },
      { header: "User Name", key: "name", width: 15 },
      { header: "Mobile No", key: "mobileNo", width: 15 },
      { header: "Email", key: "email", width: 30 },
      { header: "Comment", key: "batch", width: 15 },

      { header: "Attachment", key: "subject", width: 50 },

      { header: "Created On Date", key: "topic", width: 15 },


      // { header: "Class", key: "class", width: 15 },
      // { header: "Assigned Batches", key: "batch", width: 15 },
      // { header: "Assigned Counselor", key: "counselor", width: 15 },
      // { header: "Enquiry Date", key: "attendanceDate", width: 20 },
      // { header: "Next Follow up Date", key: "followupDate", width: 15 },
      // { header: "Created By", key: "createdBy", width: 15 },
    ];

    worksheet.columns = columnsArr;

    for (let idx = 0; idx < commentsDataList.length; idx++) {
      let commentsDataArr = [];
      let commentsResult = commentsDataList[idx];

      let commentDate = moment(new Date(commentsResult.createdOn)).format(
        "DD/MM/YYYY"
      );



      commentsDataArr.push(idx + 1);
      commentsDataArr.push(commentsResult.userName ? commentsResult.userName : "");
      commentsDataArr.push(commentsResult.userMobileNo ? commentsResult.userMobileNo : "");
      commentsDataArr.push(commentsResult.userEmail ? commentsResult.userEmail : "");
      commentsDataArr.push(commentsResult.comment ? commentsResult.comment : "");
      commentsDataArr.push(commentsResult.type == 'file' && commentsResult.attachment && commentsResult.attachment.downloadUrl
        ? commentsResult.attachment.downloadUrl : "");

      commentsDataArr.push(commentDate ? commentDate : "");




      worksheet.addRow(commentsDataArr);
    }

    const buffer = await workbook.xlsx.writeBuffer();

    saveFile(
      new Blob([buffer], { type: "application/octet-stream" }),
      fileName
    );
    //   await workbook.xlsx.writeFile(fileName);
  }
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    // var view = new Uint8Array(buf);
    var view = s;
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const saveFile = async (blob, fileName) => {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };
  function uniqueId() {
    // always start with a letter (for DOM friendlyness)
    var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
    do {
      // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
      var ascicode = Math.floor(Math.random() * 42 + 48);
      if (ascicode < 58 || ascicode > 64) {
        // exclude all chars between : (58) and @ (64)
        idstr += String.fromCharCode(ascicode);
      }
    } while (idstr.length < 32);

    return idstr.toLowerCase();
  }
</script>

<style>
  .chat-wrapper {
    position: relative;
    background-color: transparent;
    top: unset;
    bottom: unset;
    right: unset;
    left: unset;
  }

  .chat-content {
    background-color: transparent;
    position: relative;
    top: unset;
    bottom: unset;
    right: unset;
    left: unset;
  }

  .chat-group {
    min-height: 80%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .chat-group .media+.media {
    margin-top: 20px;
  }

  .chat-group .media-body {
    margin-left: 20px;
  }

  .chat-group .media-body h6 {
    font-size: 14px;
    font-weight: 600;
  }

  .chat-group .media-body h6 small {
    color: #8392a5;
  }

  .chat-group .media-body p {
    margin-bottom: 5px;
  }

  .chat-group .media-body p:last-child {
    margin-bottom: 0;
  }

  .chat-content-header {
    position: relative;
    top: unset;
    bottom: unset;
    right: unset;
    left: unset;
    background-color: #fff;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px 0px 20px;
  }

  .chat-content-header nav {
    display: flex;
    align-items: center;
  }

  .chat-content-header nav a {
    color: #8392a5;
    padding: 2px;
    border-radius: 0.25rem;
  }

  .chat-content-header nav a:hover,
  .chat-content-header nav a:focus {
    color: #525f70;
  }

  @media (min-width: 576px) {
    .chat-content-header nav a {
      padding: 2px 5px;
    }
  }

  .chat-content-header nav a+a {
    margin-left: 2px;
  }

  .chat-content-header nav a.active {
    color: #1b2e4b;
  }

  .chat-content-header svg {
    width: 20px;
  }

  /* .search-form {
    
  } */
  .chat-content-header .search-form {
    height: 34px;
  }

  .chat-content-header .search-form .form-control {
    font-size: inherit;
    width: 150px;
    min-height: 0;
    height: auto;
    background-color: transparent;
  }

  .chat-content-header .search-form .btn {
    min-height: 0;
    padding: 0 10px;
  }

  .chat-group-divider {
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: #8392a5;
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto,
      sans-serif;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .chat-group-divider:first-child {
    margin-top: 0;
  }

  .chat-group-divider::before,
  .chat-group-divider::after {
    content: "";
    display: block;
    flex: 1;
    height: 1px;
    background-color: #e5e9f2;
  }

  .chat-group-divider::before {
    margin-right: 10px;
  }

  .chat-group-divider::after {
    margin-left: 10px;
  }

  .chat-content-footer {
    position: relative;
    left: unset;
    right: unset;
    top: unset;
    bottom: unset;
    background-color: #fff;
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    height: 60px;
    display: flex;
    justify-content: space-between;
  }

  .chat-content-footer .chat-plus {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8392a5;
    border-right: 1px solid rgba(72, 94, 144, 0.16);
  }

  .chat-content-footer .chat-plus:hover,
  .chat-content-footer .chat-plus:focus {
    color: #1b2e4b;
  }

  .chat-content-footer .form-control {
    font-size: inherit;
    border: 0;
    box-shadow: none !important;
    background-color: transparent;
  }

  .chat-content-footer nav {
    display: flex;
    align-items: center;
    border-left: 1px solid rgba(72, 94, 144, 0.16);
    padding: 10px 20px;
  }

  .chat-content-footer nav a {
    color: #8392a5;
    padding: 2px 5px;
    border-radius: 0.25rem;
  }

  .chat-content-footer nav a:hover,
  .chat-content-footer nav a:focus {
    color: #525f70;
  }

  .chat-content-footer nav a+a {
    margin-left: 2px;
  }

  .chat-content-footer nav a.active {
    color: #1b2e4b;
  }

  .chat-content-footer nav svg {
    width: 20px;
  }
</style>

<div class="wd-100p ht-100p chat-wrapper">
  <!-- chat-content-header -->
  <div class="chat-content-header">
    <h6 class="mg-b-0">Comments Section</h6>
    <div class="d-flex flex-row">



      <div class="d-flex flex-md-row flex-xs-col justify-content-between align-items-center">

        <nav class="mg-sm-l-10">
          <div class="search-form  mg-r-10 d-sm-flex">
            <input type="search" class="form-control wd-[110px]" placeholder="Search Comments" bind:value={search.value}
              on:input={fetchComments} />
            <button class="btn" type="button" on:click={fetchComments}><i data-feather="search" /></button>
          </div>
          {#if isExport == true}
          <div class="form-group mg-r-10">

            <label class="d-block ">Date from:</label>
            <input type="date" onkeydown="return false" bind:value={dateFrom} class="form-control follow-up-date "
              placeholder="Date from" />
          </div>

          <div class="form-group mg-r-10">
            <label class="d-block ">Date to:</label>
            <input type="date" onkeydown="return false" bind:value={dateTo} class="form-control follow-up-date "
              placeholder="Date to" />
          </div>
          <button class="btn btn-secondary mg-r-10" type="button" on:click={btnExportConversation}>Export</button>
          {/if}
          <a href="javascript:void(0)" data-toggle="tooltip" title="" on:click={fetchComments} data-placement="left"
            data-original-title="Refresh Comments"><i class="tx-primary" data-feather="refresh-ccw" /></a>
        </nav>
      </div>

    </div>
  </div>
  <div class="chat-content comment-scroll scroll-sm pos-relative ht-100p">
    <div class="chat-group">
      {#if isLoading}
      <div class="divider-text divider-loading">
        <ButtonLoader {isLoading} btnText="Loading " />
      </div>
      {:else if comments.totalCount > length}
      <button class="btn btn-light btn-xs wd-100p" on:click={loadMore}>
        Load More
      </button>
      {/if}
      {#each comments.data as comment (comment._id)}
      <div class="divider-text">{convertDateObjToStr(comment.createdOn)}</div>
      <div class="media">
        <div class="avatar avatar-sm">
          {#if comment.userProfilePic}
          <img class="rounded-circle" src={comment.userProfilePic} alt={comment.userName} />
          {:else}
          <span class="avatar-initial rounded-circle">{comment.userName[0]}</span>
          {/if}
        </div>
        <div class="media-body pd-5">
          <h6>
            {comment.userName}
            <small>{moment(new Date(comment.createdOn)).fromNow()}</small>
          </h6>
          {#if comment.type == 'text'}
          <p>{comment.comment}</p>
          {:else if comment.type == 'file'}
          {#if comment.attachment && 'jpg,jpeg,gif,bmp'.includes(comment.attachment.fileExtension)}
          <div class="wd-50p">
            <a class="wd-100p" href={comment.attachment.downloadUrl || '#' } target="blank">
              <img class="wd-100p" style="object-fit: cover;" src={comment.attachment.downloadUrl || '#' }
                title={comment.attachment.originalFileName || '' } alt={comment.attachment.originalFileName || '' } />
            </a>
          </div>
          {:else if comment.type == 'file' && comment.attachment}
          <div class="d-flex">
            <a href={comment.attachment.downloadUrl || '#' } target="blank">
              <p class="mg-r-10">
                {comment.attachment.originalFileName || ''}
                <i data-feather="external-link" />
              </p>
            </a>
          </div>
          {:else}
          <p class="mg-r-10 text-muted">missing attachment file</p>
          {/if}
          {:else}
          <!-- nothing -->
          {/if}
        </div>
        <!-- media-body -->
      </div>
      {:else}
      <Empty mode={search.value ? 'search' : 'empty' } />
      {/each}
      {#if isSending}
      <div class="divider-text divider-sending">
        <ButtonLoader isLoading={isSending} btnText="Sending " />
      </div>
      {:else if isLoadingComments}
      <div class="media mg-b-10">
        <div class="media-body pd-5">
          <div class="placeholder-media">
            <div class="line" />
          </div>
        </div>
        <!-- media-body -->
      </div>
      {/if}
    </div>
  </div>
  <!-- chat-content-body -->

  <div class="chat-content-footer">
    <a href="javascript:void(0)" data-toggle="tooltip" title="" class="chat-plus" on:click={openFileUploader}
      data-original-title="Add File"><i class="tx-primary" data-feather="file-plus" /></a>
    <input bind:this={elem_uploadFile} class="d-none" on:change={bindFileChange} type="file" id="file" name="comment" />

    <textarea bind:value={elem_comment_editor} style="resize:none" name="comment_editor" placeholder="type comment here"
      class="form-control d-block wd-0 bd-0" />
    <nav>
      <a href="javascript:void(0)" data-toggle="tooltip" title="" data-original-title="Send" on:click={makeComment}>
        <i class="tx-primary" data-feather="send" />
      </a>
      <!-- <a
        href=""
        data-toggle="tooltip"
        title=""
        data-original-title="Add Gift"><i data-feather="gift" /></a>
      <a
        href=""
        data-toggle="tooltip"
        title=""
        data-original-title="Add Smiley"><i data-feather="smile" /></a> -->
    </nav>
  </div>
  <!-- chat-content-footer -->
</div>
