<script>
  import { Router, Link, links, Route, navigate } from "svelte-routing";
  import { userData } from "../stores";
  import { onMount } from "svelte";
  import { activeMenu } from "../stores";
  import ButtonLoader from "../utils/button_loader.svelte";
  import { pSuccess, pError } from "../utils/notify.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import { API_URL } from "../../config.js"
  export let mode;
  export let testObj;
  export let updateParent;
  export let userId;
  let showModal = false;
  let reportedQuestionsDataTable;
  let reportedQuestionsTable;
  let allReportsListArr = [];
  let modalCmp;

  let btnDisable = false;
  let isLoading = false;

  let errorObj = {};

  let reevaluateMarks = () => {
    console.log("function ", reevaluateMarks.name);
  };
  let graceMarks = () => {
    console.log("function ", graceMarks.name);
  };

  async function api_giveGraceForThisQuestion(data) {
    let action = "test.giveGraceByQuestionId";
    var response = await api_handleApiCalls(action, data);
    return response.data;
  }

  function bindDatatable(testId) {
    reportedQuestionsDataTable = _$(reportedQuestionsTable).DataTable({
      responsive: true,
      language: {
        processing: "",
        searchPlaceholder: "Search question",
        sSearch: "",
        lengthMenu: "_MENU_ Report/page",
      },
      ajax: function (data, callback, settings) {
        _$.ajax({
          url: API_URL,
          method: "post",
          data: {
            action: "questionsReported.fetchReportedQuestionsByTestId",
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: data.length,
              search: data.search.value,
              testId: testId,
            },
          },
          success: function (resData) {
            var result = [];
            if (resData.status) {
              console.log("required data >>>>>>>>>>========>", resData.data);
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.recordsTotal),
                recordsFiltered: Number(paginationData.recordsFiltered),
                data: paginationData.data,
              };
              if (resData?.data?.data.length) {
                // emptyState = true
              }
              callback(result);
            } else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            // emptyState = true
          },
        });
      },
      columns: [
        {
          data: "seqId",
          render: function (data, type, full) {
            return data;
          },
        },
        {
          data: "aQuestion",
          render: function (data, type, full) {
            let question = data.data.question[data.languages[0].title];
            question = _$('<p>').html(question).find('img').css("width", "85px").end().html();
            return `<div style="max-width:600px; " class="btn-view-display" data-id='${full.report_id}'>
                 ${question}
              </div>`
          },
        },
        {
          data: "questionCount",
          render: function (data, type, full) {
            return `<div style="text-align: center;">
              ${data}
              </div>`;
          },
        },

        {
          data: "_id",
          render: function (data, type, full) {
            if (full.graced) {
              return `
              <div class="d-md-flex flex-row justify-content-center mg-2">
              <button class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-r-10 btn-reevaluate-question" question_id="${full.report_id}">Re-evaluate</button>
              <button class="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-r-10 btn-grace-question" question_id="${data}" disabled>Graced</button>
              </div>
              `;
            } else {
              return `
              <div class="d-md-flex flex-row justify-content-center mg-2">
              <button class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-r-10 btn-reevaluate-question" question_id="${full.report_id}">Re-evaluate</button>
              <button class="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-r-10 btn-grace-question" question_id="${data}">Grace Mark</button>
              </div>
              `;
            }
          },
        },
      ],
      drawCallback: function () {
        feather.replace();
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);

        _$(".btn-reevaluate-question")
          .unbind("click")
          .click(function (e) {

          });
        _$(".btn-grace-question")
          .unbind("click")
          .click(async function (e) {
            var reportedQuestionsId = _$(this).attr("question_id");
            var testId = testObj?._id
            var res = await api_giveGraceForThisQuestion({ testId: testId, questionId: reportedQuestionsId })
            if (res.status) {
              pSuccess("Success!!", "Successfully given grace marks.")
              redrawTestTable();
            } else {
              pError("Error!!", "Something went wrong.")
            }
          });
      },
      processing: true,
      serverSide: true,
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }

  function redrawTestTable() {
    if (reportedQuestionsDataTable) { reportedQuestionsDataTable.draw() }
  }


  onMount(async () => {
    testObj.menu = 'questionsReported';
    if (updateParent) updateParent()
    bindDatatable(testObj?._id);
    feather.replace();
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);

  });
</script>

{#if mode == "cmp"}
<div class="fadeIn" style="display: flex;flex-direction: column;">
  <div style="width: 1;border-bottom: 1px solid #e5e8ee; display: flex;">
    <div style="display: flex;align-items: center;justify-content: center;margin-left: 20px;" class="colored-svg">
      <i data-feather="clipboard" class="wd-40" />
    </div>
    <div class="media-body mg-l-10 pd-y-10">
      <h6 class="mg-b-2 tx-16">Reported Questions</h6>
      <span class="d-block tx-11 tx-color-03">Invalid questions, answers, etc issues raised by students during
        exam</span>
    </div>
    <div class="pd-y-10 d-none">
      <button class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-r-10" on:click={reevaluateMarks}>
        <!-- <i data-feather="chevron-left" class="wd-10 mg-r-5"></i> -->
        <span class="wd-10 mg-r-5" />Re-evaluate marks
      </button>
      <button class="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-r-10" on:click={graceMarks}>
        <!-- <i data-feather="chevron-right" class="wd-10 mg-l-5"></i> -->
        Grace Marks<span class="wd-10 mg-l-5" />
      </button>
    </div>
  </div>
  <div class="pd-20">
    <div data-label="Example" class="df-example ">
      <table bind:this={reportedQuestionsTable} class="table table-hover table-sm">
        <thead>
          <tr>
            <th style="width: 5%; text-align: center;">#</th>
            <th style="width: 300px;">Questions</th>
            <th style="width: 10%; text-align: center;">Count</th>
            <!-- <th>Report Type</th> -->
            <th style="width:20%; text-align: center;">Action</th>
          </tr>
        </thead>
        <tbody />
      </table>
    </div>
  </div>
</div>
{:else}{/if}
