<script>
    import { Router, Link, links, Route } from "svelte-routing";

    import { onMount, tick, createEventDispatcher } from "svelte";

    import FolderExplorer from "./questionBankExplorer.svelte";
    let modalCmp;
    let batchList = [];
    let elemBatchId;
    let saveBtnEle;
    let selectedFolder = [];
    let selectedBatch;

    const dispatch = createEventDispatcher();

    let onSelectedFolder = function(){
        dispatch('close', {
            selectedFolder: selectedFolder
        });
    }

    onMount(async () => {
        feather.replace(); 
        _$(modalCmp).modal('show');
        _$(modalCmp).on('hide.bs.modal', function (e) {
            dispatch('close', {
                selectedFolder: []
            });
        })
    })
</script>

<div class="modal effect-scale" bind:this={modalCmp} id="pdf-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header pd-b-0">
            <div class="media align-items-center pd-12">
                <span class="tx-color-04"><i data-feather="folder-plus" class="wd-60 ht-60" /></span>
                <div class="media-body mg-sm-l-20">
                    <h4 class="tx-18 tx-sm-20 mg-b-2">Select folder</h4>
                    <p class="tx-13 tx-color-03 mg-b-0">Select new folder</p>
                </div>
            </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div class="row row-sm pd-t-8">
                        <div class="col-sm-12">
                            <div class="form-group col-sm-12">
                                <label class="d-block">Select a location in Question Bank</label>
                                    <FolderExplorer 
                                        bind:selectedFolder={selectedFolder} 
                                        ></FolderExplorer>
                            </div>
                            <div class="form-group col-sm-12">
                                <label class="d-block">Selected Location : </label>
                                {#if selectedFolder.length > 0}
                                    <nav aria-label="breadcrumb " class="tree-bc">
                                        <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                                            {#each selectedFolder as item}
                                            <li class="breadcrumb-item active">{item.name}</li>
                                            {/each}
                                        </ol>
                                    </nav>
                                {/if}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pd-t-0">
                <div class="pd-12">
                    <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">
                        CLOSE <i data-feather="x" class="wd-10 mg-l-5"></i></button>
                    {#if selectedFolder.length == 0}
                    <button type="button" on:click={onSelectedFolder} class="btn btn-primary tx-13" bind:this={saveBtnEle}
                        disabled=false>
                        LINK HERE <i data-feather="link" class="wd-10 mg-l-5"></i></button>
                    {:else}
                    <button type="button" on:click={onSelectedFolder} class="btn btn-primary tx-13" bind:this={saveBtnEle} >
                        LINK HERE
                        <i data-feather="link" class="wd-10 mg-l-5"></i>
                        </button>
                    {/if}
                </div>
                
            </div>
        </div>
    </div>
</div>
