<script>
    import { Router, Link, Route } from "svelte-routing";
    import List from "./list/list.svelte";
</script>

<Router>
    <Route path="">
        <List/>
    </Route>
    <Route path="/:bradCrumbLink" let:params>
        <List bradCrumbLink={params.bradCrumbLink}/>
    </Route>
</Router>
