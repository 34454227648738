<script>
    import { Router, Link, links, Route } from "svelte-routing";

    import { onMount, tick, createEventDispatcher } from "svelte";

    import STPcmp from "./component.svelte";
    const dispatch = createEventDispatcher();

    export let allowSelection = true;
    let modalCmp;

    let sptObject;

    let onSelectedEssay = function () {
        dispatch('close', {
            sptObject: sptObject
        });
    }

    onMount(async () => {

        _$(modalCmp).modal('show');

        _$(modalCmp).on('hide.bs.modal', function (e) {
            dispatch('close', {
                sptObject: ""
            });
        })
    })
</script>





<div class="modal effect-scale" bind:this={modalCmp} id="pdf-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="    height: calc(100% - 100px);
    display: flex;
    overflow: auto;">
        <div class="modal-content tx-14" style="height: 100%;">

            <div class="modal-header pd-b-0" style=" flex-direction: column;">
                <div class="media align-items-center pd-12">
                <span class="tx-color-04 mg-r-8"><i data-feather="volume-2" class="wd-60 ht-60 stroke-wd-3 mg-b-0" /></span>
                <div class="media-body mg-sm-l-8">
                  <h4 class="modal-title" id="exampleModalLabel6">Select the Announcement Template</h4>
                <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
                    Please select one of the existing message template or create a new announcement message template and then select it
                </p>
                </div>
              </div>
                
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  style="position: absolute;
                   top: 18px;
                   right: 30px;">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>


            <div class="modal-body p-0" style="overflow: hidden;
    height: 100%;">
                <STPcmp bind:sptObject={sptObject} />
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Close <i data-feather="x" class="mg-l-5"></i></button>
                {#if allowSelection}
                    {#if !sptObject?.title}
                    <button type="button" on:click={onSelectedEssay} class="btn btn-primary tx-13"
                        disabled >Select Current Announcement Template</button>
                    {:else}
                    <button type="button" on:click={onSelectedEssay} class="btn btn-primary tx-13" >Select Current Announcement Template</button>
                    {/if}
                {/if}
            </div>
        </div>
    </div>
</div>
