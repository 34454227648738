import { API_URL } from "../config";

export default async function api_handleApiCalls(action, data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var payload = JSON.stringify({
    action: action,
    accessToken: localStorage.getItem("accessToken"),
    data: data,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: payload,
  };

  try {
    var resObj = await fetch(API_URL, requestOptions);
    var response = await resObj.json();
    return response;
  } catch (error) {
    console.error("error", error);
    return { errMsg: error.message };
  }
}
