import { writable } from 'svelte/store';

export const userData = writable(0);
export const activeMenu = writable(0);
export const fileExplorerFilter = writable(0);
export const _selectedQuestionsIds = writable(new Set());

export  const updateSelectedQuestion = (newData) => {
    _selectedQuestionsIds.set(newData)
}
