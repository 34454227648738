<script>
  import { Router, Link, links, Route, navigate } from "svelte-routing";

  import { onMount, tick } from "svelte";
  import { activeMenu } from "../stores";
  import ButtonLoader from "../utils/button_loader.svelte";
  import { pSuccess, pError, pConfirm } from "../utils/notify.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import MODULES from "../utils/modules.js";
  import { API_URL } from "../../config.js";

  let showModal = false;
  let testDataTable;
  let demoDataTable;
  let emptyState = false;
  let filterModalThis;
  let testName;
  let testType;
  let selectizeBatchInstance;
  let filterElemBatch;
  let batchList;
  let type,
    testState,
    assignedBatch,
    assignedGroup,
    pmarks,
    nmarks,
    fromDate,
    toDate;
  let todays_date;
  let minTomorrow;
  let isFromDateSelected = true;
  let modalCmp;

  //combine result declarations
  let testIdArr = [];
  let btnTestIdForResult = false;
  let exportsLoading = false;

  let btnDisable = false;
  let isLoading = false;
  let isLoadingTests = false;

  let errorObj = {};

  async function api_addTest(data) {
    let action = "test.add";
    var response = await api_handleApiCalls(action, data);
    return response;
  }
  $: fromDate, checkFromDateSelected(), setInitialDate();

  function checkFromDateSelected() {
    if (fromDate != null) isFromDateSelected = false;
    else isFromDateSelected = true;
  }
  async function api_generateResultByTestIds(data) {
    let action = "test.generateResultByTestIds";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getBatchesList() {
    var resData = await api_handleApiCalls("batch.getMyBatches", {});
    if (resData.status) {
      return resData.data;
    } else {
      return [];
    }
  }

  async function api_removeTest(testId) {
    var resData = await api_handleApiCalls("test.removeTest", { id: testId });
    return resData;
  }

  function bindSelect2s(batchList) {
    selectizeBatchInstance = _$(filterElemBatch).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      delimiter: ",",
      plugins: ["remove_button"],
      placeholder: "Select Batches",
      persist: false,
      options: batchList,
    });
  }

  let handleClickProceed = async function () {
    if (isLoading) return;
    btnDisable = true;
    isLoading = true;
    if (!testName || !testName.trim())
      errorObj.testName = "Test name is required field";
    // console.log(testName);
    if (!testType || !testType.trim())
      errorObj.testType = "Test type is required field";
    // console.log(testType);
    if (!(errorObj.testName || errorObj.testType)) {
      var insertedTest = await api_addTest({
        name: testName,
        type: testType,
      });
      // console.log("insertedTest : ", insertedTest);
      if (insertedTest.status) {
        pSuccess("", "Test created successfully");
        _$(".modal-backdrop").remove();
        navigate("/dashboard/tests/view/" + insertedTest.data.testId);
      } else {
        pError(
          "",
          insertedTest.errMsg || insertedTest.error || "Something went wrong",
        );
      }
    }
    btnDisable = false;
    isLoading = false;
  };

  function redrawTestTable() {
    if (testDataTable) {
      testDataTable.draw();
    }
  }

  function getFilterData() {
    let batchArr = _$(selectizeBatchInstance).val();
    let totalMaxMarks = pmarks;
    // if(fromDate) fromDate = new Date(fromDate)
    // if(toDate) toDate = new Date(toDate)

    return {
      type,
      testState,
      totalMaxMarks,
      batchArr,
      nmarks,
      fromDate,
      toDate,
    };
  }

  function showHideFilterModal(action) {
    action
      ? _$(filterModalThis).modal("show")
      : _$(filterModalThis).modal("hide");
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    /* if (sortOrder.column === 1) {
            sort.testId = order;
        } else */ if (sortOrder.column === 1) {
      sort.name = order;
    } else if (sortOrder.column === 2) {
      sort.isPracticeTest = order;
    } else if (sortOrder.column === 3) {
      sort.type = order;
    } else if (sortOrder.column === 4) {
      sort.publish = order;
    } else {
      sort.testId = order;
    }
    return sort;
  }

  let openAddTestModal = function () {
    btnDisable = false;
    isLoading = false;
    testName = undefined;
    testType = undefined;

    _$(modalCmp).modal("show");
  };

  const proceedRemoveTest = (testId) => {
    pConfirm(
      "Warning!",
      "Are you sure you want to delete this test, this action will delete test permanently",
      async function () {
        let resData = await api_removeTest(testId);
        if (resData.status) {
          pSuccess("Success", resData.data);
        } else {
          pError("Error", resData.errMsg);
        }
        redrawTestTable();
      },
    );
  };

  function bindDatatable() {
    testDataTable = _$(demoDataTable).DataTable({
      responsive: false,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data));
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname));
      },
      language: {
        processing: "",
        searchPlaceholder: "Test Name | Type",
        sSearch: "",
        lengthMenu: "_MENU_ Tests/page",
      },
      ajax: function (data, callback, settings) {
        isLoadingTests = true;
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        var filterObj = { ...getFilterData() };

        filterObj.start = data.start;
        filterObj.length = data.length;
        filterObj.search = data.search.value;
        filterObj.draw = data.draw;
        filterObj.sort = data.sort;

        // console.log("filtedata", filterObj)
        _$.ajax({
          url: API_URL,
          method: "post",
          data: {
            action: "test.list",
            accessToken: localStorage.getItem("accessToken"),
            data: filterObj,
          },
          success: function (resData) {
            isLoadingTests = false;
            var result = [];
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data,
              };
              if (!resData.data.data.length) {
                emptyState = true;
              } else {
                emptyState = false;
              }
              callback(result);

              // console.log("results", result)
            } else {
              console.log("Something went wrong");
              emptyState = true;
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            isLoadingTests = false;
            emptyState = true;
          },
        });
      },
      columns: [
        {
          data: "testId",
          render: function (data, type, full) {
            return `<div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input btn-combineresult-test status-change" id="${data}" test-id="${full._id}" ${testIdArr.find((e) => e == full._id) ? "checked" : ""}>
                                        <label class="custom-control-label" for="${data}">${data}</label>
                                    </div>`;
          },
        },
        {
          data: "name",
          render: function (data, type, full) {
            return `<div class="btn-edit-test" test-id="${full._id}"  style="max-height: 38px !important; overflow:hidden; cursor: pointer;">
                        ${data}
                        </div>`;
          },
        },
        {
          data: "testId",
          render: function (data, type, full) {
            // let qTypeFullName;
            // switch (full.qType) {
            //     case 'multipleResponse':
            //         qTypeFullName = "Multiple Response Type";
            //         break;
            //     case 'multipleChoice':
            //         qTypeFullName = "Multiple Choice Type";
            //         break;
            //     case 'singleDigit':
            //         qTypeFullName = "Single Digit Type";
            //         break;
            //     case 'decimalType':
            //         qTypeFullName = "Decimal Type";
            //         break;
            //     case 'fillInTheBlanks':
            //         qTypeFullName = "Fill in the Blanks";
            //         break;
            //     case 'trueFalse':
            //         qTypeFullName = "True Or False";
            //         break;
            //     default:
            //         qTypeFullName = "-"
            // }

            if (full.isPracticeTest) {
              return `<div class="d-flex justify-content-center text-capitalize">${full.isPracticeTest}</data>`;
            }
            return `<div class="d-flex justify-content-center text-capitalize">no</data>`;
          },
        },
        {
          data: "type",
          render: function (data, type, full) {
            // let qTypeFullName;
            // switch (full.qType) {
            //     case 'multipleResponse':
            //         qTypeFullName = "Multiple Response Type";
            //         break;
            //     case 'multipleChoice':
            //         qTypeFullName = "Multiple Choice Type";
            //         break;
            //     case 'singleDigit':
            //         qTypeFullName = "Single Digit Type";
            //         break;
            //     case 'decimalType':
            //         qTypeFullName = "Decimal Type";
            //         break;
            //     case 'fillInTheBlanks':
            //         qTypeFullName = "Fill in the Blanks";
            //         break;
            //     case 'trueFalse':
            //         qTypeFullName = "True Or False";
            //         break;
            //     default:
            //         qTypeFullName = "-"
            // }

            return `<div class="d-flex justify-content-center">${data}</data>`;
          },
        },
        {
          data: "publish",
          render: function (data, type, full) {
            if (data)
              return `<div class="d-flex justify-content-center tx-success">Published</data>`;
            else
              return `<div class="d-flex justify-content-center tx-pink">Unpublished</data>`;
          },
        },
        {
          data: "_id",
          render: function (data, a, b, c) {
            // TODO: add tooltip on top
            var edit = "";
            if (MODULES.hasAccess(MODULES.EDIT_TEST)) {
              edit += `<a href="#" class="rename mg-r-5 btn-edit-test" test-id="${data}" title="Edit">
                                <i class="wd-20 mg-r-5" data-feather="edit"></i>
                            </a>`;
            }
            if (MODULES.hasAccess(MODULES.DELETE_TEST)) {
              edit += `<a href="#" class="rename mg-r-5 btn-delete-test" data-id="${data}" title="Delete">
                                <i class="wd-20 mg-r-5" data-feather="trash"></i>
                            </a>`;
            }
            return `
                            <div class="d-md-flex flex-row justify-content-center">
                                ${edit}
                            </div>
                        `;
          },
        },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        _$(".btn-edit-test")
          .unbind("click")
          .click(function (e) {
            e.preventDefault();
            var testId = _$(this).attr("test-id");
            navigate("/dashboard/tests/view/" + testId);
            return false;
          });

        _$(".btn-delete-test")
          .unbind("click")
          .click(function (e) {
            let testId = _$(this).attr("data-id");
            proceedRemoveTest(testId);
          });

        _$(".btn-combineresult-test")
          .unbind("click")
          .click(function (e) {
            e.preventDefault();

            var testId = _$(this).attr("test-id");
            if (!e.target.checked) {
              let idIndx = testIdArr.findIndex((id) => id == testId);
              testIdArr.splice(idIndx, 1);
              testIdArr = testIdArr;
            } else {
              testIdArr.push(testId);
              testIdArr = testIdArr;
            }

            redrawTestTable();
            return false;
          });
      },
      processing: true,
      serverSide: true,
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }

  function clearFilter() {
    type = null;
    selectizeBatchInstance[0].selectize.clear();
    testState = null;
    assignedBatch = null;
    pmarks = null;
    nmarks = null;
    fromDate = null;
    toDate = null;
    redrawTestTable();
  }

  function applyFilter() {
    redrawTestTable();
  }

  async function handleGenerateResultClick(resId) {
    if (testIdArr.length) {
      exportsLoading = true;
      var res = await api_generateResultByTestIds({ testIdArr, resId });
      if (res.status) {
        pSuccess(
          "Success!!",
          "Successfully exported the combine result on organization mail Id",
        );
        exportsLoading = false;
        window.open(window.location.origin + res.data.url);
      } else {
        exportsLoading = false;
        pError("Error!!", "Something went wrong.");
      }
    } else {
      pError(
        "Error!!",
        "Please select the test for which result needs to generate.",
      );
    }
  }

  onMount(async () => {
    feather.replace();
    activeMenu.set("tests");
    bindDatatable();
    batchList = await api_getBatchesList();
    bindSelect2s(batchList);
    _$(modalCmp).on("hidden.bs.modal", function () {
      errorObj = {};
      testName = "";
      testType = "";
    });
  });
  async function setInitialDate() {
    let _tomorrows_date = new Date();
    _tomorrows_date.setDate(_tomorrows_date.getDate() + 1);

    todays_date = formatDate(new Date(fromDate).toString());
    minTomorrow = formatDate(_tomorrows_date.toString());
    // batchObj.startDate = formatDate(todays_date);

    // batchObj.startDate = convertDateStrToObj(batchObj.startDate);
    await tick();
  }
  function convertDateStrToObj(selectedDate) {
    selectedDate = selectedDate.split("-");
    var year = selectedDate[0];
    var month = selectedDate[1] - 1;
    var day = selectedDate[2];
    return new Date(year, month, day);
  }
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
</script>

<div class="content-body fadeIn">
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
  >
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Tests</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Tests</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
        Search or add new test.
      </p>
    </div>
    <div class="d-block">
      <div class="d-sm-flex">
        {#if testIdArr.length}
          <button
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
            on:click={() => {
              testIdArr = [];
              redrawTestTable();
            }}
          >
            <i data-feather="x" class="wd-10 mg-r-5"></i> clear ({testIdArr.length})
          </button>
        {/if}

        <div class="dropdown mg-l-5 float-right">
          <button
            disabled={exportsLoading}
            class="btn btn-sm pd-x-15 btn-uppercase btn-secondary mg-l-5 dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i data-feather="activity" class="wd-10 mg-r-5"></i> Generate Result
            For Selected Tests
            <ButtonLoader isLoading={exportsLoading} btnText="Proceed" />
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              class="dropdown-item"
              on:click={() => handleGenerateResultClick("T30")}
              href="#"
            >
              <i data-feather="file-text" class="wd-10 mg-r-5"></i>Top 30
            </a>
            <a
              class="dropdown-item"
              on:click={() => handleGenerateResultClick("T20")}
              href="#"
            >
              <i data-feather="file-text" class="wd-10 mg-r-5"></i>Top 20
            </a>
            <a
              class="dropdown-item"
              on:click={() => handleGenerateResultClick("all")}
              href="#"
            >
              <i data-feather="file-text" class="wd-10 mg-r-5"></i>All
            </a>
          </div>
        </div>
        <button
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          on:click={() => {
            showHideFilterModal(true);
          }}
        >
          <i data-feather="filter" class="wd-10 mg-r-5"></i> Filter
        </button>
        {#if MODULES.hasAccess(MODULES.ADD_TEST)}
          <button
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
            on:click={openAddTestModal}
          >
            <i data-feather="plus" class="wd-10 mg-r-5"></i> Add Test
          </button>
        {/if}
      </div>
    </div>
  </div>
  <hr class="full-width" />
  <!-- <hr class="mg-y-20"> -->
  <div data-label="Example" class="df-example demo-table">
    <table bind:this={demoDataTable} class="table" style="width:100%">
      <thead>
        <tr>
          <th style="text-align: center; width: 5%;">#</th>
          <th style="width: 20%;">Name</th>
          <th style="text-align: center; width: 10%;"
            >Practice Test / <br /> Apti Booster</th
          >
          <th style="text-align: center; width: 10%;">Type</th>
          <th style="text-align: center; width: 10%;">Publish</th>
          <th style="width: 10%; text-align: center;">Actions </th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    {#if emptyState}
      <div class="empty-state-img wd-200">
        <img src="/images/empty.svg" style="width: 200px" alt="empty" />
        <p class="tx-bold">No tests found</p>
      </div>
    {/if}
  </div>
</div>

<div
  class="modal effect-scale"
  bind:this={modalCmp}
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered wd-sm-650" role="document">
    <div class="modal-content">
      <div class="modal-body pd-5">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
          <a
            href=""
            role="button"
            class="close pos-absolute t-15 r-15 z-index-10"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="media align-items-center">
            <span class="tx-color-03 d-none d-sm-block"
              ><i data-feather="clipboard" class="wd-60 ht-60"></i></span
            >
            <div class="media-body mg-sm-l-20">
              <h4 class="tx-18 tx-sm-20 mg-b-2">Test</h4>
              <p class="tx-13 tx-color-03 mg-b-0">Create new test.</p>
            </div>
          </div>
          <!-- media -->
        </div>
        <!-- modal-header -->
        <hr class="full-width" />
        <div class="row no-gutters">
          <div class="col-12 bg-white rounded-right">
            <div
              class="ht-100p d-flex flex-column justify-content-center pd-l-20 pd-r-20 pd-b-20"
            >
              <div class="tx-14 tx-md-16 tx-color-02">
                <div class="form-group">
                  <label class="d-block"
                    >Name <span class="required-field">*</span></label
                  >
                  <input
                    type="text"
                    bind:value={testName}
                    class:parsley-error={errorObj.testName}
                    on:blur={() => {
                      delete errorObj.testName;
                      errorObj = errorObj;
                    }}
                    on:keyup={(event) =>
                      event.key == "Enter" && handleClickProceed()}
                    class="form-control"
                    placeholder="Enter test name..."
                  />
                  <div class="parsley-errors-list">
                    {#if errorObj.testName}
                      {errorObj.testName}
                    {/if}
                  </div>
                </div>
              </div>
              <div class="tx-14 tx-md-16 tx-color-02">
                <div class="form-group" style="    margin-bottom: 10px;">
                  <label class="d-block m-0"
                    >How would you like to select question set? <span
                      class="required-field">*</span
                    ></label
                  >
                </div>
              </div>

              <div class:mg-b-30={!errorObj.testType} style="display:flex;">
                <div
                  style="flex:1; border:1px solid #cccccc; border-radius: 4px;min-height: 80px; padding: 15px;"
                >
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio1"
                      value="fixed"
                      bind:group={testType}
                      name="customRadio"
                      on:change={() => {
                        delete errorObj.testType;
                        errorObj = errorObj;
                      }}
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="customRadio1">
                      Manual Question Selection
                      <p class="tx-12 tx-md-13 tx-color-03">
                        Question will be selected individually using either by
                        category or by question id.
                      </p>
                    </label>
                  </div>
                </div>
                <div style="width: 40px;"></div>
                <div
                  style="flex:1; border:1px solid #cccccc; border-radius: 4px;min-height: 80px; padding: 15px;"
                >
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio2"
                      value="automatic"
                      bind:group={testType}
                      name="customRadio"
                      on:change={() => {
                        delete errorObj.testType;
                        errorObj = errorObj;
                      }}
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="customRadio2">
                      Automatic Question Selection
                      <p class="tx-12 tx-md-13 tx-color-03">
                        System will Automatic select question form given
                        subject/topic or tags.
                      </p>
                    </label>
                  </div>
                </div>
              </div>
              <div
                class="parsley-errors-list"
                class:mg-b-30={errorObj.testType}
              >
                {#if errorObj.testType}
                  {errorObj.testType}
                {/if}
              </div>

              <button
                href=""
                disabled={btnDisable}
                on:click={handleClickProceed}
                class="btn btn-primary btn-block btn-uppercase"
              >
                <ButtonLoader {isLoading} btnText="Proceed" />
                <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
              </button>
            </div>
          </div>
          <!-- col -->
        </div>
        <!-- row -->
      </div>
      <!-- modal-body -->
    </div>
    <!-- modal-content -->
  </div>
  <!-- modal-dialog -->
</div>
<!-- modal -->

<!-- 
<div class="modal effect-scale" id="modal-new-test" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel6">New Test</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div class="row row-sm mg-b-20">
                        afasdfadf
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Close</button>
                <button type="button"  class="btn btn-primary tx-13" >Proceed</button>
            </div>
        </div>
    </div>
</div> -->

<!-- Filter Modal -->
<div
  class="modal effect-scale"
  bind:this={filterModalThis}
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel6"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a
          href=""
          role="button"
          class="close pos-absolute t-15 r-15 z-index-10"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"
            ><i data-feather="filter" class="wd-60 ht-60"></i></span
          >
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
          </div>
        </div>
        <!-- media -->
      </div>
      <!-- modal-header -->

      <hr class="full-width" />
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
        <div class="form-group">
          <label class="d-block flex-grow-1">Type:</label>
          <div class="d-md-flex">
            <div class="custom-control custom-radio mg-r-15">
              <input
                type="radio"
                id="fixed"
                bind:group={type}
                class="custom-control-input"
                value="fixed"
              />
              <label class="custom-control-label" for="fixed">Fixed</label>
            </div>

            <div class="custom-control custom-radio mg-r-15">
              <input
                type="radio"
                bind:group={type}
                id="automatic"
                class="custom-control-input"
                value="automatic"
              />
              <label class="custom-control-label" for="automatic"
                >Automatic</label
              >
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="d-block flex-grow-1">Published / Unpublished</label>
          <div class="d-md-flex">
            <div class="custom-control custom-radio mg-r-15">
              <input
                type="radio"
                id="publish"
                bind:group={testState}
                class="custom-control-input"
                value="published"
              />
              <label class="custom-control-label" for="publish">Published</label
              >
            </div>
            <div class="custom-control custom-radio mg-r-15">
              <input
                type="radio"
                bind:group={testState}
                id="unpublished"
                class="custom-control-input"
                value="unpublished"
              />
              <label class="custom-control-label" for="unpublished"
                >Unpublished</label
              >
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-6">
            <label class="d-block">Total Marks Of Test:</label>
            <input
              type="number"
              bind:value={pmarks}
              class="form-control follow-up-date"
              placeholder="Total Marks Of Test"
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-6">
            <label class="d-block">Test Started From:</label>
            <input
              type="date"
              onkeydown="return false"
              bind:value={fromDate}
              data-date-format="DD MM YYYY"
              class="form-control"
              placeholder="dd/mm/yyyy"
            />
          </div>
          <div class="col-sm-6">
            <label class="d-block">Test Started Till:</label>
            <input
              type="date"
              disabled={isFromDateSelected}
              onkeydown="return false"
              bind:value={toDate}
              data-date-format="DD MM YYYY"
              min={todays_date}
              class="form-control"
              placeholder="dd/mm/yyyy"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="d-block">Select Assigned Batches:</label>
          <select
            disabled={isLoading}
            bind:this={filterElemBatch}
            class="form-control select2"
            multiple="multiple"
          >
            <option>Select batches</option>
          </select>
        </div>

        <div class="d-md-flex justify-content-end mg-t-25">
          <button
            disabled={isLoading}
            on:click={clearFilter}
            class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5"
            data-dismiss="modal"
            >CLEAR
            <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button
            disabled={isLoading}
            on:click={applyFilter}
            class="btn btn-sm pd-x-15 btn-primary mg-l-5"
            data-dismiss="modal"
          >
            <ButtonLoader {isLoading} btnText="APPLY" />
            <i data-feather="filter" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
{#if isLoadingTests}
  <div class="loader">Loading tests...</div>
{/if}
