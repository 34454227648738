<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick, afterUpdate } from 'svelte';
    import { userData } from "../stores";
    import { activeMenu } from "../stores";
    import moment from 'moment'
    import ButtonLoader from "../utils/button_loader.svelte"
    import CountDownTimer from "../utils/countdownTimer.svelte"
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import MODULES from "../utils/modules.js" 
    import CheckLecture from  "./checklecture.svelte";
    let isLoading = false
    let roleIsOrgAdmin;
    var errorObj = {}
    let _id = '';
    let listAppSliders = [];
    let elem_uploadImageInput = "";
    let studentName = "";
    let studentEnrollmentNo = "";
    let studentId = "";
    let facultyName
    let urlImg = ""

    async function api_getLiveLecturesForStudents(){
        let today = new Date(new Date().setHours(0,0,0,0))
        let tomorrow = new Date(new Date().setHours(24,0,0,0))
tomorrow.setDate(tomorrow.getDate() + 1)
        var data = {
            start: today,
            end: tomorrow,
            type: "all",
            source: "web"
        }
        let action = "home.getDataForSchedulePage";
        var response = await api_handleApiCalls(action, data);
        return response;
    }

    afterUpdate(() => {
        feather.replace()
    })

    const unsubscribe = userData.subscribe(value => {
        roleIsOrgAdmin = value.role == "student"
        studentName = value.fullName;
        studentEnrollmentNo = value.enrollmentNo;
        studentId = value._id;

    });
    const init = async () => {
        activeMenu.set('liveLectureForStudent');
        unsubscribe();
        let res = await api_getLiveLecturesForStudents();
        if (res.status) {
            listAppSliders = res.data.studyMaterials;
            await tick();
        } else {
            console.log(res.errMsg);
        }
    }
    onMount(init)


    function youTubeGetID(url) {
        if (!url) return "dXo0LextZTU";
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    }

    function getId(url) {
        if (!url) {
            return
        }
        var thumbnail = youTubeGetID(url);
        var tbUrl = { uri: "https://img.youtube.com/vi/" + thumbnail + "/0.jpg" };
        return tbUrl.uri
    }

    function forZoom(meetingId, meetingPasscode) {

        return "#";
        // return "/zoom.svelte"



        // var meetingUrl = `https://us05web.zoom.us/wc/${meetingId}/start`//https://us05web.zoom.us/wc/85674847228/start
        // return meetingUrl
    }
    async function api_addZoomAttendance(data) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var payload = JSON.stringify({
            action: "zoomAttendance.addZoomAttendance",
            accessToken: localStorage.getItem("accessToken"),
            data: data,
        });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: payload,
        };
        
        try {
            var resObj = await fetch("/api/handleRequest", requestOptions);
            var response = await resObj.json();
            return response;
        } catch (error) {
            return { error: error };
        }
}    
    async function onZoomBtnClick(meetingId, meetingPasscode, lectureId) {
        var dataObj = {
            lectureId: lectureId,
            userId: studentId,
        }
        var res = await api_addZoomAttendance(dataObj)
        
        var meetingUrl = `https://us05web.zoom.us/wc/${meetingId}/start`;//https://us05web.zoom.us/wc/85674847228/start
        var meetingUrl2 = `https://us02web.zoom.us/j/${meetingId}?pwd=${meetingPasscode}`
        window.open(  meetingUrl2 );

        // var zoomConfig = 
        // {
        //     mn:meetingId, 
        //     pwd: meetingPasscode,
        //     name: studentName + (studentEnrollmentNo ? ("_" + studentEnrollmentNo) : ""),
        //     studentId: studentId,
        //     lectureId: lectureId

        // }

        // // localStorage.re
        // window.localStorage.zoomConfig = JSON.stringify(zoomConfig);

        // window.open(  '/zoom.html?' + new Date().getTime());

    }

    function checkYoutubeLectureStartTime(youtubeLink, startTime) {
        if(new Date(startTime) > new Date()){
            return
        }else{
            window.open(youtubeLink)
        }
    }
   
</script>

<style>
    .disable-link{
        pointer-events: none;
    }
    .offline-class{
        background-color:#A9A9A9;
        color:white
    }
</style>

<div class="content-body fadeIn">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Live Lectures</li>
                </ol>
            </nav>
            <h4 class="mg-b-0">Live Lectures</h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Online Lectures for students</p>
        </div>
    </div>
    <hr class="full-width">
    <div data-label="Example" class="df-example">
        <div class="card-columns">
            {#if listAppSliders && listAppSliders.length}
            {#each listAppSliders as aAppSlider}
            <div class="card">
                <div class={aAppSlider.type == "offline"?  "card-header offline-class" : "card-header"}>
                    {#if aAppSlider.platform == "youtube"}
                    <img src="{getId(aAppSlider.youtubeLink)}" class="card-img-top ht-300" alt="..." />
                    {:else if  aAppSlider.platform == "zoom"}
                    <img src={aAppSlider.zoomThumbnail ? aAppSlider.zoomThumbnail : "/assets/zoom.svg"} class="card-img-top ht-300" alt="https://www.businessnewsdaily.com/images/i/000/017/338/original/zoom.jpg" />
                    {:else}
                    <strong class="text-uppercase">{aAppSlider.type == "offline"? "Offline": ""}</strong>
                    {/if}
                </div>
                
                <div class="card-body">
                    <h5 class="card-title">{aAppSlider.title}</h5>
                    <p class="card-text">{aAppSlider.description}</p>
                    <p class="card-text">
                        Lecture Date/Time: {moment(new Date(aAppSlider.start)).format("DD/MM/YYYY, hh:mm A") }
                    </p>
                    <p>Faculty Name: {aAppSlider.facultyName} </p>
                    {#if aAppSlider.type == "offline"}
                  
                    <p>Venue: {aAppSlider.venue}</p>
                    {/if}
                 </div>
                <div class="card-footer tx-13">
                    <div class="d-flex flex-row">
                        <div class="flex-grow-2 row">
                            <div data-toggle="tooltip" data-placement="left" title="Join lecture" data-id="{aAppSlider._id}">
                                
                               {#if (new Date(aAppSlider.start) > new Date()) }

                                <CountDownTimer startFrom={ new Date(aAppSlider.start).getTime()} onTimeOut={()=>{init();}}>
                                    <button slot="container" let:time let:timeStr disabled class="btn btn-sm btn-secondary btn-uppercase mg-5">
                                        Starts in {timeStr}
                                        <br/>
                                    </button>
                                </CountDownTimer>
                                
                               {:else}
                               <CheckLecture onZoomBtnClick={onZoomBtnClick} checkYoutubeLectureStartTime={checkYoutubeLectureStartTime} aAppSlider={aAppSlider}  expiryDate={new Date(aAppSlider.end)} checkOnTimeOut={()=>{init();}} />
                                {/if}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/each}
            {/if}
        </div>
    </div>

    {#if !listAppSliders.length}
    <div class="d-flex align-items-center justify-content-center " style="flex-direction:column;">
        <div class="" style="width:250px">
            <img src="/images/empty.svg" class="img-fluid" alt="No Result" />
            <p class=" mg-b-0 tx-color-03" style="text-align:center;">No Lectures found.</p>
        </div>
    </div>
     {/if}
</div>     

