<script>
    import { createEventDispatcher, onMount, beforeUpdate } from 'svelte';

    const dispatch = createEventDispatcher();

    onMount(async () => {
        feather.replace();
        dispatch('setActiveTab', {
            tabName: 'Settings'
        });
    })
</script>

Settings here
