<script>
    import { Router, Link, link, links, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    export let selectedQuestionSequence;
    export let selectedQuestionData;
    export let breadCrumb =[]
    //  export let newQuestion = false;
    import FolderModal from "../../folderCmp/folderModal.svelte";
    import MultipleChoiceCmp from "../../questions/questionTypes/multipleChoice.svelte";
    import MultipleResponseCmp from "../../questions/questionTypes/multipleResponse.svelte";
    import NumericalCmp from "../../questions/questionTypes/numerical.svelte";
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import ButtonLoader from "../../utils/button_loader.svelte";
    import QuestionsReportedCmp from "../../questionsReportedCmp/index.svelte";
    import { createEventDispatcher } from 'svelte';
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    

    // import api_handleApiCalls from ".../utils/apiCallHandler.js";
    let elem_analyticalCategoryPicker;
    let tempIsPracticeQuestion = [];
    let isLoading = false;
    let selectedLang = "English"
    let linkArrFull = [];
    let questionData = {
        additionalInfo: {
            pmarks: 0,
            nmarks: 0,
            level: ''
        },
        data: {
            question: {},
            explanation: {},
            answer: "",
            attachEssay: {},
            isEssayAttached: [],
            optionCount: 4
        },
        isPracticeQuestion: false,
        sources: [],
        parentId: "61f13e8788f4023464f45209",
        sequence: "1",
        link: [],
        tags: [],
        languages: [{}],
        averageResponseTime: '',
        analyticalCategoryIds: [],

        qType: "",

    };
    const dispatch = createEventDispatcher();
    $: selectedQuestionData, questionData = selectedQuestionData
    onMount(async () => {

        questionData = selectedQuestionData

        if(questionData){
            _render.analyticalCategorySelections(questionData.analyticalCategoryIds)
            _render.generateTags(questionData.tags);
            _render.generateSources(questionData.sources);
            if (questionData.isPracticeQuestion) {
                tempIsPracticeQuestion = ["true"];
            } else {
                tempIsPracticeQuestion = [];
            }
        }
        else {
            _render.analyticalCategorySelections()
            _render.generateTags();
             _render.generateSources();
        }
    })


    let saveQuestion = async function (e) {
        let finalObj = questionData;
        finalObj.tags = _$('.chips-tag').val().split(',');
        finalObj.sources = _$('.chips-source').val().split(',')
        finalObj.isPracticeQuestion = (tempIsPracticeQuestion.length > 0 ? true : false),
            finalObj.link = linkArrFull;
        finalObj.parentId = questionData.parentId;

        let validatedFinalObj = validateQuestion(finalObj)
        if (isLoading) return;
        isLoading = true;
        await tick()

        if (validatedFinalObj) {
            selectedQuestionData = finalObj
            selectedQuestionSequence = finalObj.sequence
            pSuccess(null, "Question Updated Successfully")
            dispatch('update', 'selectedQuestionData', 'selectedQuestionSequence')
            // let res = await api_updateQuestion(finalObj);

            // if (res.status) {

            //     pSuccess("", "Question Updated successfully");

            // }



        }
        isLoading = false;


    }

    function validateQuestion(finalObj) {
        const {
            averageResponseTime,
            additionalInfo,
            data,
            qType,
            languages
        } = finalObj
        if (typeof additionalInfo != "object") { pError("!Validation error", "Positive marks cant be less than 1"); return null }
        if (!additionalInfo.pmarks || additionalInfo.pmarks < 1) { pError("!Validation error", "Positive marks cant be less than 1 or empty"); return null }
        if (additionalInfo.nmarks === null || additionalInfo.nmarks === undefined || additionalInfo.nmarks < 0) { pError("!Validation error", "Negative marks cant be negative number or empty"); return null }


        if (qType == 'multipleChoice') {
            let qOptionsArr = ['a', 'b', 'c', 'd', 'e', 'f', 'g']
            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
            for (let lngIdx = 0; lngIdx < languages.length; lngIdx++) {
                const aLang = languages[lngIdx].title;

                if (!data.question[aLang]) { pError("!Validation error", "In the " + aLang + " language question found empty, please fill out question data"); selectedLang = aLang; return null }

                for (let idx = 0; idx < data.optionCount; idx++) {
                    const qSeq = qOptionsArr[idx];
                    if (!data['option_' + qSeq] || !data['option_' + qSeq][aLang]) {
                        pError("!Validation error", "In the " + aLang + " language Option (" + qSeq.toUpperCase() + ") found empty, please fill out data or you can remove option");
                        selectedLang = aLang;
                        return
                    }
                }

            }



        }
        if (data && !data.answer) { pError("!Validation error", "Please select answer from options"); return null }
        if (averageResponseTime && (!averageResponseTime || averageResponseTime < 1)) { pError("!Validation error", "Average Response Time should be at least 1 second"); return null }

        return finalObj
    }

    var _render = {
        generateTags: function (perSelectedTags) {
            _core.getTagsData(function (res) {
                if (res.status) {
                    // _$('.chips-tag').attr('value',res.data.tags.join(','));
                    var tagsOptions = []
                    if(res.data && Array.isArray(res.data.tags)) {
                        res.data.tags.map((tag, i) => tagsOptions.push({ value: res.data.tags[i], text: res.data.tags[i] }))
                    }

                    if(Array.isArray(perSelectedTags)) {
                        perSelectedTags.map((tag) => tagsOptions.push({ value: tag, text: tag }))
                    }

                    var tags = _$('.chips-tag').selectize({
                        delimiter: ',',
                        plugins: ['remove_button'],
                        persist: false,
                        options: tagsOptions,
                        items: perSelectedTags || [],
                        render: {
                            option_create: function (data, escape) {
                                return '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New tag : </i><strong>' + escape(data.input) + '</strong></div>';
                            }
                        },
                        create: function (input) {
                            return { value: input.trim(), text: input }
                        },
                        onChange: function (value) {
                        }
                    });
                   

               
                }

            })
        },
        generateSources: function (perSelectedSources) {
            _core.getSourcesData(function (res) {
                if (res.status) {
                    // _$('.chips-tag').attr('value',res.data.tags.join(','));
                    var sourcesOptions = []
                    if(res.data && Array.isArray(res.data.sources)) {
                        res.data.sources.map((source,i) => sourcesOptions.push({ value: res.data.sources[i], text: res.data.sources[i] }))
                    }

                    if(Array.isArray(perSelectedSources)) {
                        perSelectedSources.map((source) => sourcesOptions.push({ value: source, text: source }))
                    }

                    var sources = _$('.chips-source').selectize({
                        delimiter: ',',
                        plugins: ['remove_button'],
                        persist: false,
                        options: sourcesOptions,
                        items: perSelectedSources || [],
                        render: {
                            option_create: function (data, escape) {
                                return '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New source : </i><strong>' + escape(data.input) + '</strong></div>';
                            }
                        },
                        create: function (input) {
                            return { value: input.trim(), text: input }
                        },
                        onChange: function (value) {
                        }
                    });
                   

               
                }

            })
        },
        analyticalCategorySelections: async function (selectedCategories = []) {


            let resData = await api_fetchAnalyticalCategoryList({
                search: { value: '' },
            });
            let allOptionsFirstTimeArr = resData.data.data

            _$(elem_analyticalCategoryPicker).selectize({
                maxItems: null,
                delimiter: ",",
                plugins: ["remove_button"],
                persist: false,
                create: false,
                valueField: "_id",
                labelField: "name",
                searchField: "name",
                options: allOptionsFirstTimeArr || [],
                items: selectedCategories,
                preload: true,
                render: {
                    option: function (item, escape) {
                        let selected = questionData.analyticalCategoryIds ? questionData.analyticalCategoryIds.includes(item._id) ? "selected" : "" : ""
                        //   if (item._id == "new") {
                        //     return `<div class="option text-capitalize" data-value="${item._id}"> <a href="javascript:void(0)" style="width: 100%;" class="btn btn-secondary">${item.name}</a> </div>`;
                        //   } else {
                        return `<div class="option  text-capitalize ${selected}" 
                        data-selectable="" data-value="${item._id}">${item.name}</div>`;
                        //   }
                    },
                },
                load: async function (query, callback) {
                    let analyticalCategoriesData = await api_fetchAnalyticalCategoryList({
                        search: { value: query },
                    });
                    if (analyticalCategoriesData.status) {
                        let selectizeData = analyticalCategoriesData.data.data
                        callback(selectizeData);
                    } else {
                        callback([]);
                    }
                },
                onChange: function (value) {
                    questionData.analyticalCategoryIds = value;
                },
            });
        }
    }
    async function api_fetchAnalyticalCategoryList(data) {

        let action = "analyticalCategory.fetchAnalyticalCategoryList";
        var response = await api_handleApiCalls(action, data);
        return response

    }

    var _core = {
       
        getTagsData: function (cb) {
            _$.post({
                url: API_URL,
                data: {
                    accessToken: localStorage.getItem("accessToken"),
                    action: 'questions.getAllTags'
                },
                success: function (resData) {
                    cb(resData);
                },
                error: function (err) {
                    //myToast('error', 'Get Question', 'Server Error');
                }
            });
        },
        getSourcesData: function (cb) {
            _$.post({
                url: API_URL,
                data: {
                    accessToken: localStorage.getItem("accessToken"),
                    action: 'questions.getAllSources'
                },
                success: function (resData) {
                    cb(resData);
                },
                error: function (err) {
                    //myToast('error', 'Get Question', 'Server Error');
                }
            });
        },

    }
    function onClickGoToDashboard(){
        pConfirm("Warning!", "Are you sure you want to go to dashboard, this action will erase the selected  questions permanently", async function () {
           navigate("/dashboard")
        })
    }
    function onClickGoToQuestionUpload(){
        dispatch('update')
    }
</script>
{#if questionData}
<div class="content-body fadeIn"
    style="    overflow: hidden; display: flex; flex-direction: column; padding-bottom: 0;">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-15 mg-lg-b-15">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a  on:click={onClickGoToDashboard} >Dashboard</a></li>
                    <li class="breadcrumb-item"><a  on:click={onClickGoToQuestionUpload} >Question Upload</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        { "#"+questionData.sequence}
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                Question {"#"+questionData.sequence}
            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">{questionData.qType}</p>
            <!-- <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                    <li class="breadcrumb-item"><a href="/dashboard/questions/questionBank/" use:link>Question
                            Upload</a></li>
                    {#each breadCrumb as item }
                    <li class="breadcrumb-item"><a href={'/dashboard/questions/questionBank/'+item._id}
                            use:link>{item.name}</a>
                    </li>
                    {/each}
                    <li class="breadcrumb-item active" aria-current="page">Data</li>
                </ol>
            </nav> -->
        </div>
        <div class="d-block">
            <div class="d-sm-flex">
                <!-- <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={onReportClick}>
                    <i data-feather="alert-circle" class="wd-10 mg-r-5"></i> Report
                </button> -->
                <!-- <QuestionsReportedCmp questionId={selectedQuestionSequence} btnName="Report" /> -->
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" disabled={isLoading}
                    on:click={saveQuestion}>
                    <i data-feather="file" class="wd-10 mg-r-5"></i>
                    <ButtonLoader isLoading={isLoading} btnText="Save" />
                </button>
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                    <i data-feather="eye" class="wd-10 mg-r-5"></i> Preview
                </button>
            </div>
        </div>
    </div>
    <div class="contact-wrapper contact-wrapper-two"
        style="margin-left: -25px;margin-right: -25px;border-top: 1px solid #e5e8ee; flex: 1; overflow: hidden;">
        <div class="row m-0" style="height:100%">
            <div class="col-md-4 p-0" style="border-right: 1px solid #e5e8ee;background: white; height: 100%;">
                <div class="pd-y-20 pd-x-10 contact-list " style="height: 100%;">

                    <div class="media" style="padding: 0px 20px;padding-top: 10px;">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="pmarksEle">Positive<span class="required-field">*</span></label>
                                <input type="number" min="{1}" bind:value={questionData.additionalInfo.pmarks}
                                    class="form-control" id="pmarksEle" placeholder="+ve Marks">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="nmarksEle">Negative</label>
                                <input type="number" min="{0}" bind:value={questionData.additionalInfo.nmarks}
                                    class="form-control" id="nmarksEle" placeholder="-ve Marks">
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 10px 20px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-4 m-0">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="q-level-easy" bind:group={questionData.additionalInfo.level}
                                        value="easy" name="q-level" class="custom-control-input">
                                    <label class="custom-control-label" for="q-level-easy">Easy</label>
                                </div>
                            </div>
                            <div class="form-group col-md-4 m-0">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="q-level-mid" bind:group={questionData.additionalInfo.level}
                                        value="mid" name="q-level" class="custom-control-input">
                                    <label class="custom-control-label" for="q-level-mid">Medium</label>
                                </div>
                            </div>
                            <div class="form-group col-md-4 m-0">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="q-level-hard" bind:group={questionData.additionalInfo.level}
                                        value="hard" name="q-level" class="custom-control-input">
                                    <label class="custom-control-label" for="q-level-hard">Hard</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 0px 20px;padding-top: 10px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-12">
                                <label for="inputAnalyticalCategory">Analytical Categories </label>
                                <select bind:this={elem_analyticalCategoryPicker} class="form-control"
                                    placeholder="Analytical category" />
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 0px 20px;padding-top: 10px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-12">
                                <label for="avgResponseCatg">Average response time (In seconds)</label>
                                <input type="number" min="{1}" bind:value={questionData.averageResponseTime}
                                    class="form-control" id="avgResponseCatg" placeholder="In seconds">
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 0px 20px;padding-top: 10px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-12">
                                <label for="inputEmail4">Tags</label>
                                <input class="info form-control chips-tag" type="text" data-role="tagsinput"
                                    name="chips-tag" placeholder="Add Tag for easy searching"
                                    >
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 0px 20px;padding-top: 10px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-12">
                                <label for="inputEmail4">Sources</label>
                                <input class="info form-control chips-source" type="text" data-role="sourceinput"
                                    name="chips-source" placeholder="Add Sources for easy searching"
                                    bind:value={questionData.sources}>
                            </div>
                        </div>
                    </div>
                    <div class="media" style="padding: 10px 20px;">
                        <div class="form-row" style="width: 100%;">
                            <div class="form-group col-md-12 m-0">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input"
                                        bind:group={tempIsPracticeQuestion} value=true id="customCheck1">
                                    <label class="custom-control-label" for="customCheck1">Include As Practice
                                        Question</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <label id="contactA" class="contact-list-divider">Assign to Folder(Link)</label> -->

                    <!-- <div class="card-header d-flex align-items-center justify-content-between">
                        <h6 class="mg-b-0">Linked To Study Material</h6>
                        <div class="d-flex align-items-center tx-18">
                            <button on:click="{() => showModal = !showModal}"
                                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                                <i data-feather="link" class="wd-10 mg-r-5"></i> New Link
                            </button>
                        </div>
                    </div>
                    <ul class="list-group list-group-flush tx-13">
                        {#each linkArr as item}
                        <li class="list-group-item d-flex pd-sm-x-20">
                            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600"><i
                                        data-feather="link" class="wd-10"></i></span></div>
                            <div class="pd-l-10">
                                <p class="tx-medium mg-b-0">{item.fullPath.join(" / ")}</p>
                                <small class="tx-12 tx-color-03 mg-b-0">{item.batchName}</small>
                            </div>
                            <div class="mg-l-auto d-flex align-self-center">
                                <nav class="nav nav-icon-only">
                                    <a href="#" class="nav-link d-sm-block">
                                        <i data-feather="trash-2" class="wd-10 mg-r-5"></i>
                                    </a>
                                </nav>
                            </div>
                        </li>
                        {/each}
                    </ul> -->
                </div>
            </div>
            <div class="col-md-8 p-0 " style="height: 100%;display: flex;flex-direction: column;">


                <div data-label="Example" class="df-example" style="background: white;">
                    <ul class="nav nav-line manage-language-nav-header" id="myTab5" role="tablist">
                        {#if questionData.languages}
                        {#each questionData.languages as item}
                        <li class="nav-item" style="margin-left: 25px;" on:click={()=> {
                            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                            selectedLang = item.title
                            }}>
                            <a class="nav-link" href="#" class:active={selectedLang==item.title}>{item.title}</a>
                        </li>
                        {/each}
                        {/if}
                        <!-- <li class="nav-item " style="margin-left: 25px;border-left: 1px solid #ddd;padding-left: 25px;"
                            on:click={()=>selectedLang = "manage-language"} >
                            <a class="nav-link" href="#" class:active={selectedLang=="manage-language" }>
                                <i data-feather="settings" style="height: 15px;"></i>
                                Manage Languages
                            </a>
                        </li> -->
                    </ul>
                </div>



                <div style="padding: 25px; overflow: auto;">



                    {#if questionData.languages}
                    {#each questionData.languages as aLanguages}
                    <div class="a-language sec-nav" class:showDiv={selectedLang==aLanguages.title}>
                        {#if questionData.qType == "multipleChoice"}
                        <MultipleChoiceCmp bind:questionData={questionData} langObj={aLanguages.title} />
                        {:else if questionData.qType == "multipleResponse"}
                        <MultipleResponseCmp bind:questionData={questionData} langObj={aLanguages.title} />
                        {:else if questionData.qType == "numerical"}
                        <NumericalCmp bind:questionData={questionData} langObj={aLanguages.title} />
                        {:else}
                        <p>Please implement this questionData.qType : {questionData.qType}</p>
                        {/if}
                    </div>
                    {/each}
                    {/if}


                    <!-- <div class="a-language" class:showDiv={selectedLang=="manage-language" }>
                        {#if questionData.languages}
                        {#if !questionData.languages[0].default}
                        <div style="margin-bottom:20px;">
                            <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase open-create-language-modal">
                                <i data-feather="plus" class="wd-10 mg-r-5"></i>
                                Add New Language
                            </button>
                        </div>
                        {#each questionData.languages as item}
                        <div data-label="Language {item.title}" class="fieldset-label sec-field-groups"
                            style="padding-top: 20px;margin-bottom:20px;">
                            <div class="form-group row">
                                <label for="sec-{item.title}-title" class="col-sm-2 col-form-label">Title</label>
                                <div class="col-sm-10">
                                    <input type="text" value="{item.title}" class="form-control"
                                        id="sec-{item.title}-title" placeholder="Language Title" on:change={(e)=>{
                                    item.title = e.target.value;
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/each} -->
                        <!-- {:else}
                                        <div
                                            style="border:1px solid #ddd; padding:60px 30px; display:flex;align-items:center;justify-content:center;flex-direction:column">
                                            <h4 id="language4" class="mg-b-10">Create your first Language</h4>
                                            <p class="mg-b-30 tx-center">Currently all the question falls under one default language.<br /> You can create
                                                multiple language to conduct test of different subject</p>
                                            <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase open-create-language-modal" on:click={openAddSecModal}><i data-feather="plus"
                                                    class="wd-10 mg-r-5" ></i>
                                                Add new Language
                                            </button>
                                        </div>
                                    {/if} -->
                        <!-- {/if}
                    </div> -->





                </div>
            </div>
        </div>
    </div>
</div>
{/if}
