// const MIN_TEXT_FIELD_LENGTH = 2
export const PaperMode = {
    "STUDENTS_COPY": "student-copy",
    "TEACHERS_COPY": "teachers-copy"
}
Object.freeze(PaperMode)

export const PaperLayout = {
    "SINGLE_COLUMN": "single-column",
    "DOUBLE_COLUMN": "double-column"
}
Object.freeze(PaperLayout)

export const PaperSet = {
    "A": "A",
    "B": "B",
    "C": "C",
    "D": "D",
}

export const OptionFormat = {
    "NUMBERS": "1234",
    "LETTERS": "ABCD",
}
Object.freeze(OptionFormat)

export function validateDetails(testObj) {
    /* details validation */
    console.log("/* details validation */")

    let errorObj = {
        allowSave: true,
        allowPublish: true,
        source: testObj.source,
        errors: {

        }
    }

    if (errorObj.source == "publish" && !(testObj.name && testObj.name.trim())) {
        errorObj.errors.name = "Test name is required field"
    } else if (testObj.name && testObj.name.trim()) {
        // name validation
        /* if(testObj.name.length < MIN_TEXT_FIELD_LENGTH){
            errorObj.name = "Test name should be at least "+ MIN_TEXT_FIELD_LENGTH+ "characters long"
        } else if(!/^[A-Za-z]/.test(testObj.name)) {
            errorObj.name = "Test name should start with alphabet"
        } */
    }
    if (testObj.displayName && testObj.displayName.trim()) {
        // displayName validation
        /* if(testObj.displayName.length < MIN_TEXT_FIELD_LENGTH){
            errorObj.displayName = "Test display name should be at least "+ MIN_TEXT_FIELD_LENGTH+ "characters long"
        } else if(!/^[A-Za-z]/.test(testObj.displayName)) {
            errorObj.displayName = "Test display name should start with alphabet"
        } */
    }
    /* if(testObj.description) {
        // description validation
    } */
    /* if(testObj.difficulty) {
        // difficulty validation
    } */
    if (!testObj.isDurationDividedAcrossSections) {
        // no duration distribution selected
        errorObj.errors.isDurationDividedAcrossSections = "Please specify duration distribution"
        // don't allow save
        errorObj.allowSave = false
    } else if (testObj.isDurationDividedAcrossSections == "no") {
        // duration distribution selected as overall (i.e., editable in details)
        if (errorObj.source == "publish" && !testObj.duration) {
            errorObj.errors.duration = "Duration should be positive integer"
        } else {
            // nothing here
        }
    }

    if (!testObj.examInstructions) {
        // exam instructions
        errorObj.errors.examInstructions = "Test instructions is required field"
        // don't allow save
        errorObj.allowSave = false;
    }

    // validation allows for publish
    errorObj.allowPublish = !Boolean(Object.values(errorObj.errors).length)

    return errorObj;
}
export function validateSettings(testObj) {
    /* settings validation */
    console.log("/* settings validation */")

    let errorObj = {
        allowSave: true,
        allowPublish: true,
        source: testObj.source,
        errors: {

        }
    }
    /* if(testObj.settings && !testObj.settings.length) {
        // settings validation
        errorObj.errors.settings = "Settings is required field"
    } */

    // validation allows for publish
    errorObj.allowPublish = !Boolean(errorObj.errors.settings)

    return errorObj;
}
export function validateQuestions(testObj) {
    /* questions validation */
    console.log("/* questions validation */")

    let errorObj = {
        allowSave: true,
        allowPublish: true,
        allowPrint: true,
        source: testObj.source,
        errors: {

        }
    }

    if ((errorObj.source == "publish" || errorObj.source == "print") && !(testObj.sections && testObj.sections.length)) {
        // sections not found
        errorObj.allowPrint = false
        errorObj.errors.questions = "There are no sections. Please add at least one section"
    }
    if ((testObj.sections && testObj.sections.length) && (testObj.qTextObj && Object.keys(testObj.qTextObj).length)) {
        errorObj.errors.sections = {

        }
        errorObj.errors.qLimit = {

        }
        errorObj.errors.manager = {

        }
        errorObj.errors.overrideMarks = {

        }
        // sections found
        testObj.sections && testObj.sections.forEach(section => {
            if (errorObj.source == "publish" && section.isLimitQuestions) {
                if (section.qLimit > section.questions.length) {
                    errorObj.errors.qLimit[section.title.replace(/ /g, '_') + '_limit'] = "Questions limit exceeds total questions added to this section"
                }
            }
            if (testObj.isDurationDividedAcrossSections == "yes") {
                if (errorObj.source == "publish" && !section.duration) {
                    errorObj.errors.manager[section.title.replace(/ /g, '_') + '_duration'] = "Duration should be positive integer"
                } else {
                    // nothing here
                }
            }
            if (errorObj.source == "publish" && section.isOverrideMarks) {
                if (!section.pmarks && isNaN(section.pmarks)) {
                    errorObj.errors.overrideMarks[section.title.replace(/ /g, '_') + "_pmark"] = "+ve override marks are required"
                } else if (section.pmarks === 0) {
                    errorObj.errors.overrideMarks[section.title.replace(/ /g, '_') + "_pmark"] = "+ve override marks must be greater than 0"
                }
            }
            if (!section.title) {
                errorObj.errors.manager[section.title.replace(/ /g, '_') + '_title'] = "Section title is required"
                // don't allow save
                errorObj.allowSave = false;
            }
            // section duration validation
            if (errorObj.source == "publish" && !section.questions.length && !section.isParentSection) {
                // sections found but question not found
                errorObj.errors.sections[section.title.replace(/ /g, '_')] = "Please add questions to section " + section.title
            }
        });
    } else {
        // sections found but question not found
        if (errorObj.source == "publish" || errorObj.source == "print") {
            errorObj.allowPrint = false
            errorObj.errors.questions = "Please add questions"
        }
    }

    // validation allows for publish
    errorObj.allowPublish = !Boolean(errorObj.errors.questions) && (Boolean(errorObj.errors.manager) && !Object.values(errorObj.errors.manager).length) &&
        (Boolean(errorObj.errors.qLimit) && !Object.values(errorObj.errors.qLimit).length) &&
        (Boolean(errorObj.errors.sections) && !Object.values(errorObj.errors.sections).length) &&
        (Boolean(errorObj.errors.overrideMarks) && !Object.values(errorObj.errors.overrideMarks).length)

    return errorObj;
}

export function validateAssigns(testObj) {
    /* assign validation */
    console.log("/* assign validation */")

    let errorObj = {
        allowSave: true,
        allowPublish: true,
        source: testObj.source,
        errors: {

        }
    }

    if (errorObj.source == "publish") {
        if (testObj.isPracticeTest == "yes") {
            if (!(testObj.linkArr && testObj.linkArr.length)) {
                errorObj.errors.linkArr = true
            } /* else if (!(testObj.practiceTestParentId && testObj.practiceTestParentId.length)) {
                errorObj.errors.linkArr = true
            } else if(testObj.linkArr.length != testObj.practiceTestParentId.length) {
                errorObj.errors.linkArr = true
            } */

            if(testObj.linkArr.length != testObj.practiceTestParentId.length) {
                errorObj.errors.linkArr = true
            }
            
        }
        if (testObj.isPracticeTest == "no") {
            if (!(testObj.batchArr && testObj.batchArr.length)) {
                errorObj.errors.batchArr = true
                // errorObj.errors.batchArr = "Please assing this test at least one batch"
            }
            if (!(testObj.groupsLinkArr && testObj.groupsLinkArr.length)) {
                // group link
                errorObj.errors.groupsLinkArr = true
                // errorObj.errors.groupsLinkArr = "Please assing this test at least one group"
            }
            if (!(testObj.studentsLinkArr && testObj.studentsLinkArr.length)) {
                // student link
                errorObj.errors.studentsLinkArr = true
                // errorObj.errors.studentsLinkArr = "Please assing this test at least one student"
            }
        }
        // validation allows for publish
        errorObj.allowPublish = !Boolean(errorObj.errors.linkArr) && !(Boolean(errorObj.errors.batchArr) && Boolean(errorObj.errors.groupsLinkArr) && Boolean(errorObj.errors.studentsLinkArr))

        if (errorObj.allowPublish) errorObj.errors = {}
    }


    return errorObj
}
export function validateNotifications(testObj) {
    /* notifications validation */
    console.log("/* notifications validation */")

    let errorObj = {
        allowSave: true,
        allowPublish: true,
        source: testObj.source,
        errors: {

        }
    }

    // publishAnnouncement.message

    // announcementBeforeExam
    // announcementBeforeExam.timeToNotify
    // announcementBeforeExam.message

    if (errorObj.source == "publish" && (testObj.notification_sms || testObj.notification_app)) {
        if (!testObj.publishAnnouncement.message || !testObj.publishAnnouncement.message.trim()) {
            errorObj.errors.publishAnnouncement_message = "message is required"
        }
        if (testObj.announce_before_exam) {
            if (!testObj.announcementBeforeExam.message || !testObj.announcementBeforeExam.message.trim()) {
                errorObj.errors.announcementBeforeExam_message = "message is required"
            }
            if (testObj.announcementBeforeExam.timeToNotify) {
                if (testObj.startDate) {
                    let date = new Date(testObj.startDate)
                    let hours = testObj.announcementBeforeExam.timeToNotify.substr(0, 2);
                    if (testObj.announcementBeforeExam.timeToNotify.substr(6, 2) == "PM") hours = (hours + 12) % 24;
                    let minutes = testObj.announcementBeforeExam.timeToNotify.substr(3, 2);
                    if (date.getHours() != 0 && date.getMinutes() != 0) {
                        let difference = date.getHours() - hours
                        if (difference < 0) {
                            errorObj.errors.announcementBeforeExam_time = 'please set announcement time before exam starts'
                        } else if (difference == 0) {
                            difference = date.getMinutes() - minutes
                            if (difference <= 0)
                                errorObj.errors.announcementBeforeExam_time = 'please set announcement time before exam starts'
                        }
                    }
                }
            } else {
                errorObj.errors.announcementBeforeExam_time = 'please set announcement time on exam start date'
            }
        }
    }

    // validation allows for publish
    errorObj.allowPublish = !Boolean(errorObj.errors.announcementBeforeExam_message) && !Boolean(errorObj.errors.announcementBeforeExam_message) && !Boolean(errorObj.errors.announcementBeforeExam_time)

    return errorObj
}
export function validatePublish(testObj) {
    /* publish validation */
    console.log("/* publish validation */")

    let errorObj = {
        allowSave: true,
        allowPublish: true,
        source: testObj.source,
        errors: {

        }
    }

    if (errorObj.source == "publish" && !testObj.startDate) {
        errorObj.errors.startDate = "Start date/time are required"
    } else if (testObj.startDate && new Date(testObj.startDate) == "Invalid Date") {
        errorObj.errors.startDate = "Invalid start date/time"
        errorObj.allowSave = false;
    }
    if (errorObj.source == "publish" && !testObj.endDate) {
        errorObj.errors.endDate = "End date/time are required"
    } else if (testObj.endDate && new Date(testObj.endDate) == "Invalid Date") {
        errorObj.errors.endDate = "Invalid end date/time"
        errorObj.allowSave = false;
    }
    if (!testObj.displayResultImmediately && !testObj.resultDate) {
        errorObj.errors.resultDate = "Result date/time are required"
        errorObj.allowSave = false;
    } else if (testObj.resultDate && new Date(testObj.resultDate) == "Invalid Date") {
        errorObj.errors.resultDate = "Invalid end date/time"
        errorObj.allowSave = false;
    }

    let startDate, endDate, resultDate, startTime, endTime, resultTime
    if (testObj.startDate) startDate = new Date(testObj.startDate);
    if (testObj.startTime) startTime = new Date(testObj.startTime);
    if (startDate && startTime) {
        startDate.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
    }

    if (testObj.endDate) endDate = new Date(testObj.endDate);
    if (testObj.endTime) endTime = new Date(testObj.endTime);
    if (endDate && endTime) {
        endDate.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);
    }

    if (testObj.resultDate) resultDate = new Date(testObj.resultDate);
    if (testObj.resultTime) resultTime = new Date(testObj.resultTime);
    if (resultDate && resultTime) {
        resultDate.setHours(resultTime.getHours(), resultTime.getMinutes(), 0, 0);
    }

    if (startDate && endDate) {
        let start = new Date(startDate)
        let end = new Date(endDate)

        if (start.valueOf() > end.valueOf()) {
            errorObj.errors.startDate = "Start date/time should not be after end date/time"
            errorObj.errors.endDate = "End date/time should not be before start date/time"
            errorObj.allowSave = false;
        }
    }

    if (endDate && resultDate) {
        let end = new Date(endDate)
        let result = new Date(resultDate)

        if (end.valueOf() > result.valueOf()) {
            errorObj.errors.endDate = "End date/time should not be after Result date/time"
            errorObj.errors.resultDate = "Result date/time should not be before end date/time"
            errorObj.allowSave = false;
        }
    }

    // validation allows for publish
    errorObj.allowPublish = !Boolean(errorObj.errors.startDate) && !Boolean(errorObj.errors.endDate) && !Boolean(errorObj.errors.resultDate)

    return errorObj
}

export function validateGeneratePDF(testObj) {

    let errorObj = {
        allowSave: true,
        allowPublish: true,
        allowPrint: true,
        source: testObj.source,
        errors: {

        }
    }

    if (errorObj.source == "print") {

        // if (!(testObj.sections && testObj.sections.length) && (testObj.qTextObj && Object.keys(testObj.qTextObj).length)) {
        //     errorObj.errors.questions = 'Please add questions'
        //     errorObj.allowSave = false
        // } else {
        let pdfObj = testObj.pdfObj;
        if (!pdfObj.class) {
            errorObj.allowPrint = false
            errorObj.errors.class = 'Please enter class'
        }
        if (!pdfObj.date) {
            errorObj.allowPrint = false
            errorObj.errors.date = 'Please enter date'
        } else if ((new Date(pdfObj.date)).getTime() == NaN) {
            errorObj.allowPrint = false
            errorObj.errors.date = 'Please enter valid date'
        } else if (!pdfObj.time) {
            errorObj.allowPrint = false
            errorObj.errors.date = 'Please enter time'
        } else if ((new Date(pdfObj.time)).getTime() == NaN) {
            errorObj.allowPrint = false
            errorObj.errors.date = 'Please enter valid time'
        }
        if (!pdfObj.marks) {
            errorObj.allowPrint = false
            errorObj.errors.marks = 'Please enter marks'
        } else if (pdfObj.marks <= 0) {
            errorObj.allowPrint = false
            errorObj.errors.marks = 'Marks should be positive non-zero integer'
        }
        if (!(pdfObj.duration && pdfObj.duration.length)) {
            errorObj.allowPrint = false
            errorObj.errors.duration = 'Please enter duration'
        }
        if (!Object.values(PaperMode).includes(pdfObj.paperMode)) {
            errorObj.allowPrint = false
            errorObj.errors.paperMode = 'Please select valid paper mode'
        }
        if (!Object.values(PaperLayout).includes(pdfObj.paperLayout)) {
            errorObj.allowPrint = false
            errorObj.errors.paperLayout = 'Please select valid paper layout'
        }
        // if (!Object.values(PaperSet).includes(pdfObj.paperSet)) {
        //     errorObj.allowPrint = false
        //     errorObj.errors.paperSet = 'Please select valid paper set'
        // }
        if (!Object.values(OptionFormat).includes(pdfObj.optionFormat)) {
            errorObj.allowPrint = false
            errorObj.errors.optionFormat = 'Please select valid option format'
        }
        if (!(pdfObj.watermark && pdfObj.watermark.length)) {
            errorObj.allowPrint = false
            errorObj.errors.watermark = 'Please enter watermark'
        }
        if (!(pdfObj.address && pdfObj.address.length)) {
            errorObj.allowPrint = false
            errorObj.errors.address = 'Please enter address'
        }
        // }
    }

    // errorObj.allowPublish = !Boolean(errorObj.errors.questions)

    return errorObj
}

export const checkForBrokenLink = (folder, practiceTestParentId=[]) => {
    return (folder && practiceTestParentId.indexOf(folder.id) != -1)
  }