<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../../stores";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js";
  import { API_URL, BACKEND_URL } from "../../../config.js";
  let elem_submissionTimePicker;
  let elem_publishTimePicker;

  let scheduleTime;
  let isLoading = false;
  export let _id;
  let studentData;
  let minDate = moment(new Date()).format("YYYY-MM-DD");
  let assignmentObj = {
    topic: "",
    submissionDate: String(minDate),
    submissionTime: moment(new Date()).format("hh:mm"),
    publishTime: "",
    publishDate: String(moment(new Date()).format("YYYY-MM-DD")),
    notes: "",
    notifyStudent: false,
    notifyAssignmentSubmission: false,
    assignedToStudents: [],
    assignedToBatches: [],
  };

  var _fileObj;
  let studentDatatable;
  let studentList = [];
  var ampmList = [
    { id: "AM", text: "AM" },
    { id: "PM", text: "PM" },
  ];
  var submissionTime = {
    HH: new Date().getHours().toString(),
    MM: new Date().getMinutes().toString(),
    AMPM: String(moment(new Date()).format("AM")),
  };
  var publishTime = {
    HH: String(moment(new Date()).format("hh")),
    MM: String(moment(new Date()).format("mm")),
    AMPM: String(moment(new Date()).format("A")),
  };

  let errorObj = {};
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  };
  let eleAssignedToBatch;
  let eleAssignedToStudent;
  let assignedToList = [];
  let isSortByName;
  let fileObj;
  let inactiveStudents;
  let elem_uploadAttachmentInput;
  var hourList = [
    { id: 1, text: "01" },
    { id: 2, text: "02" },
    { id: 3, text: "03" },
    { id: 4, text: "04" },
    { id: 5, text: "05" },
    { id: 6, text: "06" },
    { id: 7, text: "07" },
    { id: 8, text: "08" },
    { id: 9, text: "09" },
    { id: 10, text: "10" },
    { id: 11, text: "11" },
    { id: 12, text: "12" },
  ];

  var minList = [
    { id: 0, text: "00" },
    { id: 15, text: "15" },
    { id: 30, text: "30" },
    { id: 45, text: "45" },
  ];

  function convertToDate(classTime) {
    var hours = Number(classTime.HH);
    var minutes = Number(classTime.MM);
    var AMPM = classTime.AMPM;
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  }

  function formatTime(dateObj) {
    var date = new Date(dateObj);

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "am" : "pm";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  function generateDuration(submissionTime) {
    if (
      submissionTime.hasOwnProperty("HH") &&
      submissionTime.hasOwnProperty("MM") &&
      submissionTime.hasOwnProperty("AMPM")
    ) {
      var submissionT = convertToDate(submissionTime);
      assignmentObj.submissionTime = submissionT;
    } else {
      assignmentObj.submissionTime = "";
    }
  }
  function generatePublishDuration(publishTime) {
    if (
      publishTime.hasOwnProperty("HH") &&
      publishTime.hasOwnProperty("MM") &&
      publishTime.hasOwnProperty("AMPM")
    ) {
      var publishT = convertToDate(publishTime);
      assignmentObj.publishTime = publishT;
    } else {
      assignmentObj.publishTime = "";
    }
  }
  async function api_getListOfFiles(data) {
    let action = "storage.getUrlForUpload";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_updateFileName(data) {
    let action = "storage.updateFileName";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getUrlForUpload(data) {
    try {
      const response = await fetch(
        `${BACKEND_URL}/fileupload-api/get-presigned-url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              contentType: "ASSIGNMENTS",
              fileName: data.name,
              fileExtension: data.name.split(".").pop(),
            },
            accessToken: localStorage.getItem("accessToken"),
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      // Extract both URLs from the response
      return {
        status: true,
        data: {
          url: result.presignedUrl,
          downloadUrl: result.downloadUrl || result.fileUrl || result.url, // Try different possible field names
          fileId: result.fileId,
        },
      };
    } catch (error) {
      console.error("Error getting presigned URL:", error);
      return {
        status: false,
        errMsg: "Failed to get upload URL",
      };
    }
  }

  async function api_updateAttachmentFileUrl(data) {
    let action = "assignment.updateAttachmentFileUrl";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_RemoveAttachmentFiles(data) {
    let action = "assignment.removeAttachmentFile";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  let filepondOptions = {
    allowDownloadByUrl: true,
    acceptedFileTypes: ["image/*", "application/*", "text/*"],
    allowRevert: true,
    onremovefile: function (err, file) {
      PNotify.notice({
        title: "Delete Attachment",
        text: "Do you want to remove the file from attachments?",
        icon: "fas fa-question-circle",
        hide: false,
        closer: true,
        sticker: false,
        destroy: true,
        modules: new Map([
          ...PNotify.defaultModules,
          [
            PNotifyConfirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Yes",
                  primary: true,
                  click: async (notice) => {
                    notice.close();
                    var obj = {
                      originalFileName: file.filename,
                      fileExtension: file.fileExtension,
                      assignmentId: _id,
                    };
                    var res = await api_RemoveAttachmentFiles(obj);

                    if (res.status) {
                      pSuccess(
                        "Successful!!",
                        "Successfully removed an attachment.",
                      );
                    } else {
                      pError("Error!!", "Something went wrong.");
                    }
                  },
                },

                {
                  text: "No",
                  click: (notice) => {
                    notice.close();
                    if (_id && _fileObj.length) {
                      let files = [];
                      _fileObj.forEach((element) => {
                        files.push({
                          source: "12345",
                          options: {
                            type: "local",
                            file: {
                              name: element.originalFileName,
                              size: element.fileSize,
                              customUrl: element.downloadUrl,
                              type: "*",
                            },
                          },
                        });
                      });
                      _$(elem_uploadAttachmentInput).filepond("files", files);
                    }
                  },
                },
              ],
            },
          ],
        ]),
      });
    },
    server: {
      process: async (
        fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort,
        transfer,
        options,
      ) => {
        // fieldName is the name of the input field
        // file is the actual file object to send
        const originalFileName = file.name;

        if (containsSpecialChars(originalFileName)) {
          PNotify.error({
            title: "Error",
            text: "Please change the file name and upload again.",
            delay: 3000,
          });
          abort();
          return;
        }

        const fileExtension = file.name.split(".").pop();
        const fileSize = file.size;

        try {
          // Get presigned URL for upload
          const res = await api_getUrlForUpload({
            name: originalFileName,
            type: file.type,
          });

          if (res.status) {
            _$.ajax({
              xhr: function () {
                var xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener(
                  "progress",
                  function (e) {
                    progress(e.lengthComputable, e.loaded, e.total);
                  },
                  false,
                );
                return xhr;
              },
              url: res.data.url,
              type: "PUT",
              data: file,
              dataType: "text",
              cache: false,
              contentType: file.type,
              processData: false,
            })
              .done(async function (response) {
                // The upload URL often has a structure that can be transformed into the download URL
                const downloadUrl =
                  res.data.downloadUrl || res.data.url.split("?")[0]; // Remove query parameters

                // Update file information in backend with all possible URL parameters
                const updateResult = await api_updateAttachmentFileUrl({
                  fileId: res.data.fileId || _id,
                  originalFileName: originalFileName,
                  fileExtension: fileExtension,
                  fileSize: fileSize,
                  assignmentId: _id,
                  url: res.data.url, // Upload URL
                  downloadUrl: downloadUrl, // Download URL
                  fileUrl: downloadUrl, // Alternative name for the URL
                });

                if (!updateResult.status) {
                  console.error(
                    "File metadata update failed:",
                    updateResult.errMsg,
                  );
                  error(
                    updateResult.errMsg || "Failed to update file information",
                  );
                } else {
                  load("Done");
                }
              })
              .fail(function (err) {
                console.error("Upload failed:", err);
                error("Upload failed");
              });
          } else {
            PNotify.error({
              title: "Error",
              text: "Failed to get upload URL",
              delay: 3000,
            });
            abort();
          }
        } catch (err) {
          console.error("Error during upload process:", err);
          error("Upload process failed");
          abort();
        }

        return {
          abort: () => {
            abort();
          },
        };
      },
    },
  };

  function formatAMPM(dateObj) {
    let date = new Date(dateObj);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    // hourList.forEach(aHour => {
    //    if(aHour.id == hours){
    //     submissionTime.HH =  hours;
    //    }
    // });
    submissionTime.HH = hours;
    submissionTime.MM = minutes;
    submissionTime.AMPM = ampm;

    // var strTime = hours + ':' + minutes + ' ' + ampm;
    // return strTime;
  }

  function formatPublishTimeAMPM(dateObj) {
    let date = new Date(dateObj);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    // hourList.forEach(aHour => {
    //    if(aHour.id == hours){
    //     submissionTime.HH =  hours;
    //    }
    // });
    publishTime.HH = hours;
    publishTime.MM = minutes;
    publishTime.AMPM = ampm;

    // var strTime = hours + ':' + minutes + ' ' + ampm;
    // return strTime;
  }
  function initTimerDropdown() {
    var assignmentSubmitTime;
    if (
      submissionTime.hasOwnProperty("HH") &&
      submissionTime.hasOwnProperty("MM") &&
      submissionTime.hasOwnProperty("AMPM")
    ) {
      assignmentSubmitTime =
        submissionTime.HH + ":" + submissionTime.MM + submissionTime.AMPM;
    }

    if (elem_submissionTimePicker) {
      _$(elem_submissionTimePicker).timepicker("destroy");
    }

    _$(elem_submissionTimePicker).timepicker({
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      // template: "modal",
      defaultTime: assignmentSubmitTime ? assignmentSubmitTime : "-",
      change: async function (time) {
        if (time) {
          scheduleTime = new Date(time);
          submissionTime.HH = scheduleTime.getHours();
          submissionTime.MM = scheduleTime.getMinutes();
          submissionTime.AMPM = submissionTime.HH >= 12 ? "PM" : "AM";
          generateDuration(submissionTime);
        } else {
          scheduleTime = null;
          // scheduledOn.setHours(0, 0, 5, 0);
        }
        // clearError("scheduleTime");
        await tick();
      },
    });
  }
  function initPublishTimerDropdown() {
    var assignmentPublishTime;
    if (
      publishTime.hasOwnProperty("HH") &&
      publishTime.hasOwnProperty("MM") &&
      publishTime.hasOwnProperty("AMPM")
    ) {
      assignmentPublishTime =
        publishTime.HH + ":" + publishTime.MM + publishTime.AMPM;
    }

    if (elem_publishTimePicker) {
      _$(elem_publishTimePicker).timepicker("destroy");
    }

    _$(elem_publishTimePicker).timepicker({
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      // template: "modal",
      defaultTime: assignmentPublishTime ? assignmentPublishTime : "-",
      change: async function (time) {
        if (time) {
          scheduleTime = new Date(time);
          publishTime.HH = scheduleTime.getHours();
          publishTime.MM = scheduleTime.getMinutes();
          publishTime.AMPM = publishTime.HH >= 12 ? "PM" : "AM";
          generatePublishDuration(publishTime);
        } else {
          scheduleTime = null;
          // scheduledOn.setHours(0, 0, 5, 0);
        }
        // clearError("scheduleTime");
        await tick();
      },
    });
  }
  onMount(async () => {
    activeMenu.set("assignment");
    _$('[data-toggle="tooltip"]').tooltip();

    initTimerDropdown();

    initPublishTimerDropdown();

    getAssignment();
  });

  async function getAssignment() {
    _$.fn.filepond.setOptions({
      server: {
        url: "{{ url('upload')}}",
        process: {
          headers: {
            "X-CSRF-TOKEN": "csrf",
          },
        },
      },
    });
    _$.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
    _$.fn.filepond.registerPlugin(FilePondPluginGetFile);
    _$(elem_uploadAttachmentInput).filepond(filepondOptions);

    _$(elem_uploadAttachmentInput).filepond("allowMultiple", true);
    if (_id) {
      let result = await api_getAssignmentById();
      if (result.status) {
        _fileObj = result.data.attachments;
        assignmentObj = {
          topic: result.data.topic,
          submissionDate: result.data.submissionDate
            ? result.data.submissionDate
            : "",
          submissionTime: result.data.submissionTime
            ? result.data.submissionTime
            : "",
          publishDate: result.data.publishDate ? result.data.publishDate : "",
          publishTime: result.data.publishTime ? result.data.publishTime : "",
          notes: result.data.notes ? result.data.notes : "",
          // assignToType:result.data.assignToType?result.data.assignToType:"",
          notifyStudent: result.data.notifyStudent
            ? result.data.notifyStudent
            : false,
          notifyAssignmentSubmission: result.data.notifyAssignmentSubmission
            ? result.data.notifyAssignmentSubmission
            : false,
          assignedToStudents: result.data.assignedToStudents
            ? result.data.assignedToStudents
            : [],
          assignedToBatches: result.data.assignedToBatches
            ? result.data.assignedToBatches
            : [],
        };
        if (assignmentObj.submissionDate) {
          let submissionDate = new Date(assignmentObj.submissionDate);
          let month = "" + (submissionDate.getMonth() + 1);
          let day = "" + submissionDate.getDate();
          let year = submissionDate.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          assignmentObj.submissionDate = [year, month, day].join("-");

          // assignmentObj.submissionDate = moment(assignmentObj.submissionDate).format("YYYY-DD-MM");

          assignmentObj.submissionDate =
            assignmentObj.submissionDate === "Invalid date"
              ? null
              : assignmentObj.submissionDate;
        } else {
          var nowDate = new Date();
          assignmentObj.submissionDate = formatDate(nowDate);
        }

        if (assignmentObj.publishDate) {
          let publishDate = new Date(assignmentObj.publishDate);
          let month = "" + (publishDate.getMonth() + 1);
          let day = "" + publishDate.getDate();
          let year = publishDate.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          assignmentObj.publishDate = [year, month, day].join("-");

          // assignmentObj.publishDate = moment(assignmentObj.publishDate).format("YYYY-DD-MM");

          assignmentObj.publishDate =
            assignmentObj.publishDate === "Invalid date"
              ? null
              : assignmentObj.publishDate;
        } else {
          var nowDate = new Date();

          assignmentObj.publishDate = formatDate(nowDate);
        }

        await assignmentAssigned();
        // await api_CallsForStudentList()

        formatAMPM(assignmentObj.submissionTime);
        formatPublishTimeAMPM(assignmentObj.publishTime);

        initTimerDropdown();
        initPublishTimerDropdown();
      } else {
        PNotify.error({
          title: "Error",
          text: result.errMsg,
          delay: 3000,
        });
      }
    }
    if (_id && _fileObj.length) {
      let files = [];
      _fileObj.forEach((element) => {
        files.push({
          source: "12345",
          options: {
            type: "local",
            file: {
              name: element.originalFileName,
              size: element.fileSize,
              customUrl: element.downloadUrl,
              type: "*",
            },
          },
        });
      });
      _$(elem_uploadAttachmentInput).filepond("files", files);
    }

    _$(".may-pond .filepond--item-panel").css("background", "#369763");
    // _$(elem_uploadAttachmentInput).on('FilePond:addfile', function (e) {
    //     _$.fn.filepond.setOptions(server);
    // });
    _$(eleAssignedToBatch).selectize();
    initDatatable();
  }

  async function api_getAssignmentById() {
    let action = "assignment.getAssignmentById";
    var response = await api_handleApiCalls(action, { id: _id });
    return response;
  }

  async function api_addUpdateAssignment(data) {
    const action = "assignment.addUpdateAssignment";
    try {
      const response = await api_handleApiCalls(action, data);

      // Validate response structure
      if (response && typeof response === "object") {
        return response;
      } else {
        throw new Error("Invalid response from API");
      }
    } catch (error) {
      console.error("API call failed:", error);
      return {
        status: false,
        errMsg: "Failed to update assignment. Please try again.",
      };
    }
  }

  async function api_getBatchList() {
    let action = "batch.getMyBatches";
    var response = await api_handleApiCalls(action, {});
    return response;
  }

  async function get_studentList_with_IdAndName() {
    try {
      const response = await fetch(
        `${BACKEND_URL}/students-api/getStudentIdsAndNames`,
        {
          method: "POST",
          params: {
            isSortByName: true,
            inactiveStudents: true,
          },
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching student data:", error);
      return null;
    }
  }
  async function api_getlistAssignmentProgressById() {
    let action = "assignmentprogress.listAssignmentProgressByAssignmentId";
    var response = await api_handleApiCalls(action, { assignmentId: _id });
    return response;
  }
  function getDateWithTimeNextFollowup(date, time) {
    try {
      let thisDate = new Date(date);
      let thisTime = new Date(time);

      // if(thisTime.getHours() == 0) return null
      thisDate.setHours(thisTime.getHours(), thisTime.getMinutes(), 0, 0);

      return thisDate;
    } catch (error) {
      return null;
    }
  }
  let validateAssignment = () => {
    errorObj = {};
    if (
      !assignmentObj.topic ||
      !assignmentObj.submissionDate ||
      !assignmentObj.submissionTime ||
      !assignmentObj.notes
    ) {
      var newErrorObj = {};
      if (!assignmentObj.topic) {
        newErrorObj.topic = "Assignment topic is required field.";
      }
      if (!assignmentObj.submissionDate) {
        newErrorObj.submissionDate =
          "Assignment submission date is required field.";
      }
      if (!assignmentObj.submissionTime) {
        newErrorObj.submissionTime =
          "Assignment submission time is required field.";
      }

      if (!assignmentObj.notes) {
        newErrorObj.notes = "Assignment notes are mandatory";
      }

      errorObj = newErrorObj;
    }
    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;
  };

  let addUpdateAssignmentBtn = async () => {
    // return
    if (isLoading) return;
    isLoading = true;
    await tick();
    if (validateAssignment()) {
      let dateAndTime = getDateWithTimeNextFollowup(
        assignmentObj.submissionDate,
        assignmentObj.submissionTime,
      );
      if (!dateAndTime) {
        return pError(
          "Validation Error!",
          "Select valid submission date & time",
        );
      }
      let publishDateAndTime = getDateWithTimeNextFollowup(
        assignmentObj.publishDate,
        assignmentObj.publishTime,
      );
      if (!publishDateAndTime) {
        return pError(
          "Validation Error!",
          "Select valid submission date & time",
        );
      }
      assignmentObj.submissionDate = new Date(dateAndTime);
      assignmentObj.publishDate = new Date(publishDateAndTime);

      await tick();
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (new Date(assignmentObj.submissionDate) < today) {
        pError(null, "Submission Date should not be less than today's date");
        isLoading = false;
        return false;
      }
      assignmentObj.assignedToBatches = _$("select#assignBatch").val();
      assignmentObj.assignedToStudents = _$("select#assignStudent").val();

      if (assignmentObj.submissionDate < assignmentObj.publishDate) {
        pError(
          null,
          "Publish Date and Time should be less than Submission Date and Time",
        );
        isLoading = false;
        return false;
      }

      assignmentObj.id = _id;
      var assignmentRes = await api_addUpdateAssignment(assignmentObj);
      if (assignmentRes.status) {
        // create success
        PNotify.success({
          title: "Success",
          text: assignmentRes.data,
          delay: 3000,
        });
        window.history.back();
      } else {
        PNotify.error({
          title: "Error",
          text: assignmentRes.errMsg,
          delay: 3000,
        });
      }
      isLoading = false;
      return false;
    }
    isLoading = false;
  };

  function reload() {
    _$(studentDatatable).DataTable().ajax.reload();
  }
  let addUpdateAssignmentBtnAssign = async () => {
    // if (validateAssignment()) {
    assignmentObj.assignedToBatches = _$("select#assignBatch").val();
    assignmentObj.assignedToStudents = _$("select#assignStudent").val();

    assignmentObj.id = _id;
    var assignmentRes = await api_addUpdateAssignment(assignmentObj);
    if (assignmentRes.status) {
      //api_CallsForStudentList()
      reload();
      // create success
      PNotify.success({
        title: "Success",
        text: "Assigned Students Updated Successfully.",
        delay: 3000,
      });

      // window.history.back();
    } else {
      PNotify.error({
        title: "Error",
        text: assignmentRes.errMsg,
        delay: 3000,
      });
    }
    // }
  };

  let assignAssignmentBtn = async (studentId) => {
    let data = {
      studentId: studentId,
      assignmentId: _id,
    };
    var assignmentRes = await api_assignAssignment(data);
    if (assignmentRes.status) {
      // api_CallsForStudentList()
      reload();
      // create success
      PNotify.success({
        title: "Success",
        text: "Assigned Students Updated Successfully.",
        delay: 3000,
      });

      // window.history.back();
    } else {
      PNotify.error({
        title: "Error",
        text: assignmentRes.errMsg,
        delay: 3000,
      });
    }
  };

  async function api_assignAssignment(data) {
    let action = "assignment.assignAssignment";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getStudentListByBatchId(data) {
    let action = "students.getStudentListbyBatchIdsArr";
    var response = await api_handleApiCalls(action, {
      batchIdsArr: assignmentObj.assignedToBatches,
      length: data.length,
      start: data.start,
    });
    return response;
  }

  function loadBatchesOptions(data, preSelectId) {
    var oldSelected = [];

    if (_$("select#assignBatch")[0].selectize) {
      oldSelected =
        _$("select#assignBatch")[0].selectize.getValue() == ""
          ? []
          : _$("select#assignBatch")[0].selectize.getValue();
      _$("select#assignBatch")[0].selectize.destroy();
    }

    preSelectId = preSelectId ? preSelectId : [];
    var questionLang = _$(_$("#assignBatch")[0]).selectize({
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      plugins: ["remove_button"],
      maxItems: 100,
      options: data,
      create: false,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create"><i class="icon icon-plus" style="font-size:14px;"></i><i style="font-size:12px;">Add New Language with name : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
    });
    if (data[0]) {
      questionLang[0].selectize.setValue(data[0].value);
    }

    if (preSelectId) {
      if (Array.isArray(preSelectId)) {
        for (var i = 0; i < preSelectId.length; i++) {
          // preSelectId[i]
          oldSelected.push(preSelectId[i]);
        }
      } else {
        oldSelected.push(preSelectId);
      }
      _$("select#assignBatch")[0].selectize.setValue(oldSelected, false);
    }

    _$("select#assignBatch").trigger("change");

    questionLang[0].selectize.on("change", function () {
      // _bind.inactivateTabs();
      addUpdateAssignmentBtnAssign();
    });
  }
  function loadStudentsOptions(data, preSelectId) {
    var oldSelected = [];

    if (_$("select#assignStudent")[0].selectize) {
      oldSelected =
        _$("select#assignStudent")[0].selectize.getValue() == ""
          ? []
          : _$("select#assignStudent")[0].selectize.getValue();
      _$("select#assignStudent")[0].selectize.destroy();
    }

    preSelectId = preSelectId ? preSelectId : [];
    var questionLang = _$(_$("#assignStudent")[0]).selectize({
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      plugins: ["remove_button"],
      maxItems: 100,
      options: data, // Use the resStudentList data here
      create: false,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create"><i class="icon icon-plus" style="font-size:14px;"></i><i style="font-size:12px;">Add New Language with name : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
    });
    if (data[0]) {
      questionLang[0].selectize.setValue(data[0].value);
    }

    if (preSelectId) {
      if (Array.isArray(preSelectId)) {
        for (var i = 0; i < preSelectId.length; i++) {
          oldSelected.push(preSelectId[i]);
        }
      } else {
        oldSelected.push(preSelectId);
      }
      _$("select#assignStudent")[0].selectize.setValue(oldSelected, false);
    }

    _$("select#assignStudent").trigger("change");

    questionLang[0].selectize.on("change", function () {
      addUpdateAssignmentBtnAssign();
    });
  }

  let assignmentAssigned = async () => {
    let resBatchList = await api_getBatchList();
    if (resBatchList.status) {
      assignedToList = [];
      assignedToList = resBatchList.data;
      loadBatchesOptions(assignedToList, assignmentObj.assignedToBatches);
    } else {
      console.log("error ", resBatchList.errMsg);
    }
    let resStudentList = await get_studentList_with_IdAndName();

    let studentList = resStudentList;

    if (resStudentList) {
      assignedToList = [];
      assignedToList = resStudentList;
      loadStudentsOptions(assignedToList, assignmentObj.assignedToStudents);
    } else {
      console.log("error ", resStudentList.errMsg);
    }
  };
  async function initDatatable() {
    _$(studentDatatable).DataTable({
      serverSide: true,
      responsive: true,
      searching: false,
      language: {
        processing: "",
        // searchPlaceholder: "Name",
        // sSearch: "",
        lengthMenu: "_MENU_ items/page",
      },
      ajax: async function (data, callback, settings) {
        // data.sort = parseSorts(data.order ? data.order[0] : { 0: "asc" });
        // delete data.order;
        _$.ajax({
          url: `${API_URL}`,
          method: "post",
          data: {
            action: "assignment.assignedStudentsList",
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: data.length,
              search: data.search.value,
              sort: data.sort,
              batchIdsArr: assignmentObj.assignedToBatches
                ? assignmentObj.assignedToBatches
                : [],
              studentIdArr: assignmentObj.assignedToStudents
                ? assignmentObj.assignedToStudents
                : [],
            },
          },
          success: function (resData) {
            var result = [];
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data,
              };
              if (!resData.data.length) {
                emptyState = true;
              }
              callback(result);
            } else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true;
          },
        });
      },
      columns: [
        {
          data: "_id",
          render: (data, mode, row, settings) => {
            let image;
            if (row && row.profilePic) {
              // if image found display profilePic avatar
              image = `<img src="${
                row.profilePic
              }" class="avatar mg-r-15" alt="${row.name[0].toUpperCase()}">`;
            } else {
              // if image not found display initials avatar
              image = `<div class="avatar mg-r-15"><span class="avatar-initial rounded-circle">${row.name
                .split(" ")
                .map((str) => str[0] || "")
                .join("")}</span></div>`;
            }
            if (row.isAssProgress) {
              return `
            <div class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0" >
              ${image}
              <div class="wd-80p bg-transparent">
                <h6 class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent">${row.name}</h6>
                <span class="d-block tx-11 text-muted bg-transparent">
                  <a href="tel:+91${row.mobileNo}">
                    <i data-feather="phone"></i> 
                    ${row.mobileNo}
                  </a>
                </span>
              </div>
            </div>`;
            } else {
              return `
            <div class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0" >
              ${image}
              <div class="wd-80p bg-transparent">
                <h6 class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent">${row.name}</h6>
                <span class="d-block tx-11 text-muted bg-transparent">
                  <a href="tel:+91${row.mobileNo}">
                    <i data-feather="phone"></i> 
                    ${row.mobileNo}
                  </a>
                </span>
              </div>
            
            </div>`;
            }
          },
          // plus button functionality to be disccuss
          //         <a href="" data-studentId=${row._id} class="btn btn-assign-student  btn-white rounded-circle btn-icon mg-l-15"
          //   data-toggle="tooltip" data-placement="bottom" title="Assign Assignment">
          //   <i data-feather="plus" data-studentId=${row._id}></i></a>
        },
      ],
      autoWidth: true,
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        // to be discuss

        // _$('.btn-assign-student').unbind('click').click(function (e) {
        //     e.preventDefault();
        //     var studentId = e.target.dataset.studentid;
        //     pConfirm("Assign Assignment", "Are you sure you want to assign assignment?",
        //         function () { assignAssignmentBtn(studentId) }
        //     )

        // })
      },
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }

  let onChangeNotify = (e) => {
    if (e.target.dataset.label == "notifyStudent") {
      let val = !assignmentObj.notifyStudent;
      assignmentObj.notifyStudent = val;
    } else {
      assignmentObj.notifyAssignmentSubmission =
        !assignmentObj.notifyAssignmentSubmission;
    }
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/;
    return specialChars.test(str);
  }

  // Let's make a raw API call to see what parameters are expected
  fetch(`${API_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "assignment.listActions", // If this exists, it might help us see available actions
      accessToken: localStorage.getItem("accessToken"),
    }),
  })
    .then((response) => response.json())
    .then((data) => console.log("Available actions:", data))
    .catch((error) => console.error("Error:", error));
</script>

<div class="content-body fadeIn">
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
  >
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/assignment">Assignment</a>
          </li>
          <li class="breadcrumb-item">
            <a href="/dashboard/assignment">Update</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {assignmentObj.topic}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        {assignmentObj.topic}
      </h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
        Update Assignment Details
      </p>
    </div>
    <div class="d-block">
      {#if MODULES.hasAccess(MODULES.EDIT_ASSIGNMENT)}
        <button
          on:click={addUpdateAssignmentBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          disabled={isLoading}
        >
          <ButtonLoader {isLoading} btnText="SAVE" />
          <i data-feather="save" class="wd-10 mg-l-5"></i>
        </button>
      {/if}
    </div>
  </div>
  <hr class="full-width" />
  <!-- <hr class="mg-y-20"> -->
  <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div class="row">
      <div class="col">
        <fieldset class="form-fieldset">
          <legend>Assignment Details</legend>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="d-block"
                >Topic<span class="required-field">*</span></label
              >
              <input
                bind:value={assignmentObj.topic}
                type="text"
                on:blur={() => clearError("topic")}
                class="form-control"
                class:parsley-error={errorObj.topic}
                placeholder="Enter your topic"
              />
              {#if errorObj.topic}
                <div class="parsley-errors-list">{errorObj.topic}</div>
              {/if}
            </div>

            <div class="form-group col-md-12">
              <label class="d-block"
                >Notes<span class="required-field">*</span></label
              >
              <textarea
                bind:value={assignmentObj.notes}
                type="text"
                on:blur={() => clearError("notes")}
                class="form-control"
                class:parsley-error={errorObj.notes}
                placeholder="Enter your notes"
              />
              {#if errorObj.notes}
                <div class="parsley-errors-list">{errorObj.notes}</div>
              {/if}
            </div>

            <div class="form-group hasDatepicker col-md-6">
              <label class="d-block"
                >Select Submission Date<span class="required-field">*</span
                ></label
              >
              <input
                min={minDate}
                bind:value={assignmentObj.submissionDate}
                type="date"
                onkeydown="return false"
                on:blur={() => clearError("submissionDate")}
                class="form-control"
                class:parsley-error={errorObj.submissionDate}
                placeholder="Enter your submissionDate"
              />
              {#if errorObj.submissionDate}
                <div class="parsley-errors-list">{errorObj.submissionDate}</div>
              {/if}
            </div>
            <div class="form-group col-md-6">
              <label class="d-block pd-l-20"
                >Select Submission Time<span class="required-field">*</span
                ></label
              >
              <div class="row">
                <div class="col-md-4 pd-l-20 wd-200">
                  <input
                    bind:this={elem_submissionTimePicker}
                    type="text"
                    class="form-control batch-time first-input wd-200"
                    placeholder="Enter time"
                    onkeydown="return false"
                  />
                </div>
              </div>
              {#if errorObj.submissionTime}
                <div class="parsley-errors-list">{errorObj.submissionTime}</div>
                <!-- content here -->
              {/if}
            </div>

            <div class="form-group hasDatepicker col-md-6">
              <label class="d-block"
                >Select publish Date<span class="required-field">*</span></label
              >
              <input
                min={minDate}
                bind:value={assignmentObj.publishDate}
                type="date"
                onkeydown="return false"
                on:blur={() => clearError("publishDate")}
                class="form-control"
                class:parsley-error={errorObj.publishDate}
                placeholder="Enter your publishDate"
              />
              {#if errorObj.publishDate}
                <div class="parsley-errors-list">{errorObj.publishDate}</div>
              {/if}
            </div>
            <div class="form-group col-md-6">
              <label class="d-block pd-l-20"
                >Select publish Time<span class="required-field">*</span></label
              >
              <div class="row">
                <div class="col-md-4 pd-l-20 wd-200">
                  <input
                    bind:this={elem_publishTimePicker}
                    type="text"
                    class="form-control batch-time first-input wd-200"
                    placeholder="Enter time"
                    onkeydown="return false"
                  />
                </div>
              </div>
              {#if errorObj.publishTime}
                <div class="parsley-errors-list">{errorObj.publishTime}</div>
                <!-- content here -->
              {/if}
            </div>
            <!-- </div>
                    <div class="form-row"> -->
            <!-- <div class="form-group col-md-12">
                            <label class="d-block">Notes</label>
                            <textarea bind:value={assignmentObj.notes} type="text" on:blur={()=>clearError("notes")} class="form-control" class:parsley-error={errorObj.notes} placeholder="Enter your notes"/>
                            {#if errorObj.notes}
                                <div class="parsley-errors-list">{errorObj.notes}</div>
                            {/if}
                        </div> -->
          </div>
          <div class="form-row">
            <!-- <div class="form-group  col-md-6">
                        <div class="custom-control custom-checkbox">
                            <input bind:checked={assignmentObj.notifyStudent} data-label="notifyStudent"   type="checkbox" class="custom-control-input" id="notifyStudent">
                            <label class="custom-control-label" for="notifyStudent">Notify Students via sms</label>
                          </div>
                    </div> -->
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="custom-control custom-checkbox">
                <input
                  bind:checked={assignmentObj.notifyAssignmentSubmission}
                  data-label="notifyAssignmentSubmission"
                  type="checkbox"
                  class="custom-control-input"
                  id="notifyAssignmentSubmission"
                />
                <label
                  class="custom-control-label"
                  for="notifyAssignmentSubmission"
                  >Inform when assignment not submitted</label
                >
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset class="form-fieldset">
          <legend>Add Attachment</legend>
          <div class="form-row">
            <div class="form-group col-md-12">
              <input
                type="file"
                bind:this={elem_uploadAttachmentInput}
                class="my-pond"
                data-instant-upload="false"
                data-allow-image-preview="false"
                name="filepond"
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col">
        <fieldset class="form-fieldset">
          <legend>Assign Assignment</legend>
          <div class="form-row">
            <div
              class="form-group col-md-12"
              id="selectBatch"
              on:click={() => clearError("assignedToBatches")}
              class:parsley-errors-select-custom={errorObj.assignedToBatches}
            >
              <label class="d-block">Select Batches</label>
              <!-- <select bind:this={eleAssignedToBatch} multiple class="custom-select"  id="selectBatch" >
                    </select> -->
              <select
                name="assignBatch"
                id="assignBatch"
                type="text"
                class="info form-control"
              >
                <option value="">Please Select Batch</option>
              </select>
              {#if errorObj.assignedToBatches}
                <div class="parsley-errors-list">
                  {errorObj.assignedToBatches}
                </div>
                <!-- content here -->
              {/if}
            </div>
            <div
              class="form-group col-md-12"
              id="selectStudent"
              on:click={() => clearError("assignedToStudents")}
              class:parsley-errors-select-custom={errorObj.assignedToStudents}
            >
              <label class="d-block">Select Students</label>

              <select
                name="assignStudent"
                id="assignStudent"
                class="form-control"
              >
                <option value="">Please Select Student</option>
                {#each assignedToList as student}
                  <option value={student._id}>{student.name}</option>
                {/each}
              </select>
              {#if errorObj.assignedToStudents}
                <div class="parsley-errors-list">
                  {errorObj.assignedToStudents}
                </div>
              {/if}
            </div>
          </div>
          <hr />
          <div data-label="System Users">
            <table bind:this={studentDatatable} class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Assigned Student List</th>
                  <!-- <th scope="col">Email</th>
                          <th scope="col">Actions</th> -->
                  <!-- <th scope="col">Added On</th> -->
                  <!-- <th scope="col">Mobile Number</th> -->
                  <!--<th scope="col">Role <br />(Access Profile)</th>-->
                </tr>
              </thead>
            </table>
          </div>
          <!-- <div class="bd">
                            <div class="d-flex ht-45 pd-x-15 align-items-center bd-b">
                              <input type="search" class="form-control bd-0 pd-0 shadow-none-f" placeholder="Search for users">
                              <a href="" role="button" class="link-03"><i data-feather="search" class="wd-20 ht-20 stroke-wd-2-5"></i></a>
                            </div>
              
                            <ul class="list-unstyled media-list tx-12 tx-sm-13 mg-b-0">
                                {#if studentList.length}
                                {#each studentList as aStudent}
                              <li class="media bg-ui-01 pd-y-10 pd-x-15">
                                <div class="avatar"><img src="../assets/img/img13.jpg" class="rounded-circle" alt=""></div>
                                <div class="media-body mg-l-15">
                                  <h6 class="tx-13 mg-b-2">{aStudent.name}</h6>
                                  <span class="d-block tx-color-03">{aStudent.mobileNo}</span>
                                </div>
                                <a href="" class="btn btn-white rounded-circle btn-icon mg-l-15"><i data-feather="plus"></i></a>
                              </li>
                              {/each}
                              {:else}
                              {/if}
                            </ul>
                          </div> -->
        </fieldset>
        <!-- </div> -->
      </div>
    </div>
  </div>
  <div class="d-sm-flex align-items-center justify-content-end">
    <div class="d-block">
      {#if MODULES.hasAccess(MODULES.EDIT_ASSIGNMENT)}
        <button
          on:click={addUpdateAssignmentBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          disabled={isLoading}
        >
          <ButtonLoader {isLoading} btnText="SAVE" />
          <i data-feather="save" class="wd-10 mg-l-5"></i>
        </button>
      {/if}
    </div>
  </div>
</div>
