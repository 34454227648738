<script>
  import { onMount, afterUpdate } from "svelte";
  import { activeMenu } from "../../stores";
  import constants from "../../utils/constants";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import { navigate } from "svelte-routing";
  import { tick } from "svelte";

  export let params = {};
  let { boardId, className } = params || { boardId: "", className: "CLASS_7" };
  let boardName =
    boardId === "cbse" ? constants.CBSE : constants.MAHARASHTRA_BOARD;

  let subjects = [];
  let loading = true;
  let showAddModal = false;
  let editingSubject = null;
  let newSubject = {
    name: "",
    standard: className,
    board: boardId ? boardId.toUpperCase() : "",
    backgroundColor: "#ffffff", // Default white
    imageUrl: "",
    textColor: "#000000", // Default black
    leftGradientColor: "#ffffff", // Default white
    rightGradientColor: "#ffffff", // Default white
  };

  let showDropdown = {};
  let activeDropdown = null;
  let currentPage = 1; // Track the current page
  let imagePreview = ""; // Add this for image preview

  // Add variables for file upload
  let selectedFile = null;
  let uploadLoading = false;
  let fileInputElement;

  onMount(async () => {
    activeMenu.set("aptiBooster");
    await loadSubjects();
    feather.replace();
  });

  afterUpdate(() => {
    feather.replace();
  });

  // Mapping function to convert class name to constant key
  function getClassKey(className) {
    const classMapping = {
      "Class 3": "CLASS_3",
      "Class 4": "CLASS_4",
      "Class 5": "CLASS_5",
      "Class 6": "CLASS_6",
      "Class 7": "CLASS_7",
      "Class 8": "CLASS_8",
      "Class 9": "CLASS_9",
      "Class 10": "CLASS_10",
    };
    return classMapping[className] || className;
  }

  async function loadSubjects() {
    try {
      const classKey = getClassKey(className);
      loading = true;
      const params = new URLSearchParams({
        standard: classKey,
        board: boardId.toUpperCase(),
        limit: 20,
        page: currentPage, // Use currentPage for pagination
      });

      const response = await fetch(
        `${constants.BACKEND_URL}/apti-booster/admin/subject/?${params}`,
        {
          method: "GET",
          headers: {
            // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          },
          cache: "no-cache",
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch subjects: ${response.status}`);
      }

      const data = await response.json();
      subjects = (data.data || []).sort((a, b) => a.name.localeCompare(b.name)); // Sort subjects by name
      await tick();
      feather.replace();
    } catch (error) {
      console.error("Error loading subjects:", error);
      pError(`Failed to load subjects: ${error.message}`);
    } finally {
      loading = false;
    }
  }

  function nextPage() {
    // Check if there are enough subjects for the next page
    if (subjects.length === 20) {
      // Assuming 10 is the limit per page
      currentPage += 1; // Increment the page number
      loadSubjects(); // Reload subjects for the new page
    }
  }

  function previousPage() {
    if (currentPage > 1) {
      currentPage -= 1; // Decrement the page number
      loadSubjects(); // Reload subjects for the new page
    }
  }

  // Function to handle file selection
  function handleFileSelect(event) {
    const file = event.target.files[0];
    if (file) {
      selectedFile = file;
      // Create a preview of the selected image
      const reader = new FileReader();
      reader.onload = (e) => {
        imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  // Function to get presigned URL and upload file
  async function uploadFileWithPresignedUrl(file) {
    if (!file) return null;

    try {
      uploadLoading = true;

      // 1. Get presigned URL from backend
      const response = await fetch(
        `${constants.BACKEND_URL}/fileupload-api/get-presigned-url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          },
          body: JSON.stringify({
            data: {
              contentType: "SUBJECT",
              fileName: file.name,
              fileExtension: file.name.split(".").pop(),
            },
            accessToken: localStorage.getItem("accessToken"),
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to get presigned URL: ${response.status}`);
      }

      const res = await response.json();

      if (!res.presignedUrl) {
        throw new Error("Failed to get presigned URL");
      }

      // 2. Convert file to Blob
      const fileBlob = new Blob([file], { type: file.type });

      // 3. Upload to S3
      const uploadResponse = await fetch(res.presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: fileBlob,
        mode: "cors",
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed: ${uploadResponse.status}`);
      }

      // 4. Return the object URL
      return res.objectUrl;
    } catch (error) {
      console.error("Error uploading file:", error);
      pError(`Failed to upload file: ${error.message}`);
      return null;
    } finally {
      uploadLoading = false;
    }
  }

  async function handleSubmit() {
    try {
      if (!newSubject.name.trim()) {
        pError("Subject name is required");
        return;
      }

      // Upload the image if a file is selected
      if (selectedFile) {
        const objectUrl = await uploadFileWithPresignedUrl(selectedFile);
        if (objectUrl) {
          newSubject.imageUrl = objectUrl;
        } else {
          pError("Failed to upload image. Please try again.");
          return;
        }
      } else if (!editingSubject) {
        // If creating a new subject and no file is selected
        pError("Please select an image file");
        return;
      }

      // Use the mapping function to get the correct class key
      const classKey = getClassKey(newSubject.standard);

      // Prepare payload with correct property names
      const payload = {
        ...(editingSubject ? { subjectId: editingSubject._id } : {}),
        name: newSubject.name,
        standard: classKey, // Use the mapped class key
        board: newSubject.board,
        backgroundColor: newSubject.backgroundColor,
        imageUrl: newSubject.imageUrl,
        textColor: newSubject.textColor,
        leftGradientColor: newSubject.leftGradientColor,
        rightGradientColor: newSubject.rightGradientColor,
      };

      const url = `${constants.BACKEND_URL}/apti-booster/admin/subject/${editingSubject ? "update" : "create"}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to ${editingSubject ? "update" : "create"} subject`,
        );
      }

      await loadSubjects();
      pSuccess(`Subject ${editingSubject ? "updated" : "added"} successfully`);
      closeModal();
    } catch (error) {
      console.error("Error saving subject:", error);
      pError(`Failed to ${editingSubject ? "update" : "create"} subject`);
    }
  }

  async function deleteSubject(subject) {
    const isConfirmed = confirm(
      "Are you sure you want to delete this subject?",
    ); // Use the built-in confirm dialog

    if (isConfirmed) {
      try {
        // Construct the payload using the subject object
        const payload = {
          subjectId: subject._id, // Use the subject's ID
          name: subject.name, // Use the subject's name
          standard: subject.standard || className, // Use the subject's standard or fallback to className
          board: subject.board || boardId.toUpperCase(), // Use the subject's board or fallback to boardId
        };

        const response = await fetch(
          `${constants.BACKEND_URL}/apti-booster/admin/subject/delete/${subject._id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify(payload), // Send the payload in the request body
          },
        );

        if (!response.ok) {
          const errorText = await response.text(); // Get the error message from the response
          console.error("Failed to delete subject:", errorText); // Log the error message
          throw new Error("Failed to delete subject");
        }

        await loadSubjects();
        pSuccess("Subject deleted successfully");
      } catch (error) {
        console.error("Error deleting subject:", error);
        pError("Failed to delete subject");
      }
    } else {
      console.log("Delete action was cancelled."); // Log if the action was cancelled
    }
  }

  function openAddModal() {
    editingSubject = null;
    newSubject = {
      name: "",
      standard: className,
      board: boardId ? boardId.toUpperCase() : "",
      backgroundColor: "#ffffff", // Default white
      imageUrl: "",
      textColor: "#000000", // Default black
      leftGradientColor: "#ffffff", // Default white
      rightGradientColor: "#ffffff", // Default white
    };
    imagePreview = ""; // Reset image preview
    selectedFile = null; // Reset selected file
    showAddModal = true;
  }

  function openEditModal(subject) {
    editingSubject = subject;

    // Handle various possible property names for gradient colors
    const leftGradient =
      subject.leftGradientColor || subject.gradientColors?.left || "#ffffff";
    const rightGradient =
      subject.rightGradientColor || subject.gradientColors?.right || "#ffffff";

    newSubject = {
      name: subject.name,
      standard: className,
      board: boardId ? boardId.toUpperCase() : "",
      backgroundColor: subject.backgroundColor || "#ffffff",
      imageUrl: subject.imageUrl || "",
      textColor: subject.textColor || "#000000",
      leftGradientColor: leftGradient,
      rightGradientColor: rightGradient,
    };
    imagePreview = subject.imageUrl || ""; // Set image preview
    selectedFile = null; // Reset selected file
    showAddModal = true;
  }

  function closeModal() {
    showAddModal = false;
    editingSubject = null;
    newSubject = {
      name: "",
      standard: className,
      board: boardId ? boardId.toUpperCase() : "",
      backgroundColor: "#ffffff", // Default white
      imageUrl: "",
      textColor: "#000000", // Default black
      leftGradientColor: "#ffffff", // Default white
      rightGradientColor: "#ffffff", // Default white
    };
    imagePreview = ""; // Reset image preview
    selectedFile = null; // Reset selected file
  }

  function toggleDropdown(subjectId) {
    activeDropdown = activeDropdown === subjectId ? null : subjectId;
    setTimeout(() => feather.replace(), 0);
  }

  function handleClickOutside() {
    activeDropdown = null;
  }

  function handleSubjectClick(subject) {
    navigate(
      `/dashboard/aptiBooster/${boardId}/${className}/subjects/${subject._id}/chapters`,
    );
  }

  // Helper function to get the background style with fallbacks for different property structures
  function getSubjectBackgroundStyle(subject) {
    // Check for gradient colors (handling both property structures)
    const leftGradient =
      subject.leftGradientColor || subject.gradientColors?.left;
    const rightGradient =
      subject.rightGradientColor || subject.gradientColors?.right;

    if (leftGradient && rightGradient) {
      return `background: linear-gradient(to right, ${leftGradient}, ${rightGradient});`;
    } else {
      return `background-color: ${subject.backgroundColor || "#ffffff"};`;
    }
  }
</script>

<svelte:window on:click={handleClickOutside} />

<div class="content-body fadeIn">
  <div class="container px-3 h-100">
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item">
              <a href="/dashboard/aptiBooster">Apti Booster</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Subjects</li>
          </ol>
        </nav>
        <h4 class="mg-b-0 tx-spacing--1">Subjects - {boardName}</h4>
      </div>
      <div class="d-inline-block">
        <button
          class="btn pd-x-15 pd-y-8 btn-primary btn-uppercase mg-l-5 btn-sm"
          on:click={openAddModal}
        >
          <i data-feather="plus" class="wd-10 mg-r-5" /> Add Subject
        </button>
      </div>
    </div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div class="row row-xs card-row">
      {#each subjects as subject}
        <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
          <div class="card card-folder">
            <div
              class="subject-image-container"
              on:click={() => handleSubjectClick(subject)}
            >
              {#if subject.imageUrl}
                <img
                  src={subject.imageUrl}
                  alt={subject.name}
                  class="subject-image"
                />
              {:else}
                <div
                  class="subject-color-background"
                  style={getSubjectBackgroundStyle(subject)}
                ></div>
              {/if}

              <div class="dropdown-wrapper" on:click|stopPropagation>
                <button
                  class="btn btn-icon"
                  on:click|stopPropagation={() => toggleDropdown(subject._id)}
                >
                  <i data-feather="more-vertical"></i>
                </button>
                {#if activeDropdown === subject._id}
                  <div class="dropdown-menu show">
                    <!-- svelte-ignore a11y-invalid-attribute -->
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item"
                      on:click={() => openEditModal(subject)}
                    >
                      <i data-feather="edit-2"></i>
                      <span>Edit</span>
                    </a>
                    <!-- svelte-ignore a11y-invalid-attribute -->
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item text-danger"
                      on:click={() => deleteSubject(subject)}
                    >
                      <i data-feather="trash-2"></i>
                      <span>Delete</span>
                    </a>
                  </div>
                {/if}
              </div>
            </div>
            <div
              class="card-body d-flex align-items-center"
              on:click={() => handleSubjectClick(subject)}
            >
              <div class="subject-info">
                <h6
                  class="subject-title"
                  style={subject.textColor
                    ? `color: ${subject.textColor};`
                    : ""}
                >
                  {subject.name}
                </h6>
              </div>
            </div>
          </div>
        </div>
      {/each}
    </div>

    <div class="pagination">
      <button
        class="btn btn-secondary"
        on:click={previousPage}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span>Page {currentPage}</span>
      <button class="btn btn-secondary" on:click={nextPage}> Next </button>
    </div>
  </div>
</div>

<!-- Add/Edit Modal -->
{#if showAddModal}
  <div class="modal effect-scale show" style="display: block;" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{editingSubject ? "Edit" : "Add"} Subject</h5>
          <button type="button" class="close" on:click={closeModal}>×</button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Subject Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter subject name"
              bind:value={newSubject.name}
            />
          </div>
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label style="width: 120px; margin-bottom: 0;">Background</label>
              <input
                type="color"
                class="form-control"
                style="width: 60px; height: 38px; padding: 5px;"
                bind:value={newSubject.backgroundColor}
              />
              <input
                type="text"
                class="form-control ml-2"
                placeholder="Color code (e.g., #ff0000)"
                bind:value={newSubject.backgroundColor}
              />
            </div>
            <small class="form-text text-muted"
              >Pick a color or enter a color code</small
            >
          </div>
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label style="width: 120px; margin-bottom: 0;">Text Color</label>
              <input
                type="color"
                class="form-control"
                style="width: 60px; height: 38px; padding: 5px;"
                bind:value={newSubject.textColor}
              />
              <input
                type="text"
                class="form-control ml-2"
                placeholder="Color code (e.g., #000000)"
                bind:value={newSubject.textColor}
              />
            </div>
          </div>

          <div class="form-group">
            <label>Gradient Colors</label>
            <div class="d-flex align-items-center mb-2">
              <label style="width: 120px; margin-bottom: 0;">Left</label>
              <input
                type="color"
                class="form-control"
                style="width: 60px; height: 38px; padding: 5px;"
                bind:value={newSubject.leftGradientColor}
              />
              <input
                type="text"
                class="form-control ml-2"
                placeholder="Left gradient color"
                bind:value={newSubject.leftGradientColor}
              />
            </div>
            <div class="d-flex align-items-center">
              <label style="width: 120px; margin-bottom: 0;">Right</label>
              <input
                type="color"
                class="form-control"
                style="width: 60px; height: 38px; padding: 5px;"
                bind:value={newSubject.rightGradientColor}
              />
              <input
                type="text"
                class="form-control ml-2"
                placeholder="Right gradient color"
                bind:value={newSubject.rightGradientColor}
              />
            </div>
          </div>
          <div class="form-group">
            <label>Upload Subject Image</label>
            <input
              type="file"
              class="form-control"
              accept="image/*"
              on:change={handleFileSelect}
              bind:this={fileInputElement}
            />
            <small class="form-text text-muted"
              >Select an image file from your computer</small
            >
          </div>
          <!-- {#if imagePreview}
            <div class="form-group">
              <label>Image Preview</label>
              <div class="image-preview">
                <img 
                  src={imagePreview} 
                  alt="Preview" 
                  style="max-width: 100%; max-height: 200px; object-fit: cover; border-radius: 4px;" 
                />
              </div>
            </div>
          {/if} -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" on:click={closeModal}
            >Close</button
          >
          <button
            type="button"
            class="btn btn-primary"
            on:click={handleSubmit}
            disabled={uploadLoading}
          >
            {#if uploadLoading}
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Uploading...</span>
            {:else}
              {editingSubject ? "Update" : "Save"}
            {/if}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
{/if}

<style>
  .card-folder {
    position: relative;
    border-radius: 4px;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: all 0.2s ease;
    cursor: pointer;
    border: 1px solid #e5e9f2;
    overflow: hidden;
  }

  .card-folder:hover {
    border-color: #0168fa;
    box-shadow: 0 2px 6px rgba(1, 104, 250, 0.15);
  }

  .card-body {
    padding: 15px;
    position: relative;
  }

  .folder-icon {
    width: 24px;
    height: 24px;
    color: #0168fa !important;
    margin-right: 10px;
    stroke-width: 2px;
    z-index: 2;
    visibility: visible !important;
  }

  .subject-title {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1b2e4b;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .subject-info {
    flex: 1;
    padding-right: 10px;
  }

  .subject-image-container {
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: relative;
  }

  .subject-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  .subject-color-background {
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease;
  }

  .card-folder:hover .subject-image {
    transform: scale(1.05);
  }

  .card-folder:hover .subject-color-background {
    opacity: 0.8;
  }

  .btn-icon {
    padding: 5px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #7987a1 !important;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  }

  .btn-icon:hover {
    color: #1b2e4b !important;
  }

  .dropdown-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    min-width: 120px;
    max-width: calc(100vw - 20px);
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    z-index: 20;
    margin-top: 5px;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    color: #1b2e4b;
    text-decoration: none;
    font-size: 0.875rem;
  }

  .dropdown-item i {
    margin-right: 8px;
  }

  .dropdown-item:hover {
    background: #f5f5f5;
  }

  .text-danger {
    color: #dc3545;
  }

  .text-danger:hover {
    background: #fff5f5;
  }

  .card-row {
    margin-bottom: 20px;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }

  .pagination button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }

  .pagination span {
    margin: 0 10px;
  }
</style>
