<script>
  import { Router, Link, Route } from "svelte-routing";
  import { onMount } from "svelte";
  import List from "./list/list.svelte";
  import View from "./view/view.svelte";
  // import Comments from "../comments/index.svelte";
  import MODULES from "../utils/modules.js"
  import Forbidden from "../forbidden.svelte";

  function init() {
    console.log("redirecting ");
  }
  onMount(init);
</script>

<Router>
  <Route path=":id" let:params>
    {#if params.id == 'new'}
      <View id={null} />
    {:else}

      {#if MODULES.hasAccess(MODULES.VIEW_STUDY_REPORT)}
        <View id={params.id} />
      {:else}
        <Forbidden/>
      {/if}

      
    {/if}
  </Route>
  <Route path="">
    <List />
  </Route>
</Router>
<!-- 
<div
  class="pd-t-20 pd-r-10 contact-list "
  style="height: calc(100% - 122px);">
  <Comments id={1} />
</div> -->
