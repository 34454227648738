<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick, afterUpdate } from 'svelte';
  import { activeMenu } from "../../../stores";
  import ButtonLoader from "../../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../../utils/apiCallHandler.js";

  let isLoading = false;
  let pdfList = { data: [] };
  let search = { value: "" };
  const pageSize = 1000;
  let length = pageSize;
  function editBtnHander(id) {
    navigate(`/dashboard/studyMaterial/pdf/edit/${id}`)
  }
  var errorObj = {

  }

  var modelTitle = "Add new PDF"

  let validatePdf = () => {
    errorObj = {};
    if (!pdfObj.name) {
      var newErrorObj = {};
      if (!pdfObj.name) {
        newErrorObj.name = "PDF Name is required Field.";
      }
      errorObj = newErrorObj;

    }
    if (Object.keys(errorObj).length > 0) {
      isLoading = false;
      return false;
    }
    return true;


  }

  async function api_addPdf(data) {

    let action ="studyMaterial.addUpdatePdf";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    // if (sortOrder.column === 1) {
    //   sort.name = order;
    // } else if (sortOrder.column === 2) {
    //   sort.createdOn = order;
    // } else {
    //   sort.seqId = order;
    // }
    sort.seqId = order;
    return sort;
  }

  async function fetchPdfList(offset = 0, event) {
    let data = {
      start: 0,
      length: length + offset >= pageSize ? length + offset : pageSize,
      search,
      sort: parseSorts({ 0: "dsc" }),
    };
    console.info(data);
    // fetch call
    let response = await api_listPdfs(data);
    console.info({ response });
    if (response.status) {
      var paginationData = response.data;
      length = paginationData.data.length;
      pdfList = {
        filtered_count: Number(paginationData.filtered_count),
        total_count: Number(paginationData.total_count),
        data: paginationData.data,
      };
    } else {
      console.error("something went wrong");
      length = 0;
      pdfList = {
        filtered_count: 0,
        total_count: 0,
        data: [],
      };
    }
    await tick();
  }

  async function api_listPdfs(data) {

    let action ="studyMaterial.listPdfs";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function bindDelete(recordedLectureId) {
    let isDelete = confirm(
      "Delete Pdf ? This will also delete associated data"
    );
    console.info("Deleting", { isDelete });
    if (isDelete) {
      //   delete api
      let result = await api_delete({ _id: recordedLectureId });
      if (result.status) {
        // reload table
        // OR
        await fetchPdfList();


      } else if (result.errMsg) {
        updateErrors({ msg: result.errMsg });
      } else if (result.error) {
        updateErrors({ msg: result.error });
      } else {
        updateErrors({ msg: "Something went wrong" });
      }
    }
  }

  async function api_delete(data) {

    let action ="studyMaterial.deletePdf";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  function hideModel(params) {
    _$('#pdf-add-modal').modal("hide")

  }
  let addNewPdf = async () => {
    if (isLoading) return;
    isLoading = true;
    if (validatePdf()) {

      var pdfRes = await api_addPdf(pdfObj);
      if (pdfRes.status) {
        // create success
        updateSuccess({ title: "Success", msg: pdfRes.data[0] });
        // setTimeout(() => {
        setTimeout(() => {
          navigate(`/dashboard/studyMaterial/pdf/edit/${pdfRes.data[1]}`)
        }, 1000);
        hideModel();

        // navigate(`/dashboard/studyMaterial/pdf/edit/${pdfRes.data[1]}`)

        // }, 1000);
      } else {
        // Failed to create 
        updateErrors({ title: "Error", msg: pdfObj.errMsg })
        isLoading = false;
        return false;
      }

    }
    isLoading = false;
  }


  var pdfObj = {
    name: "",
  }
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  }
  function updateSuccess(success) {
    PNotify.success({
      title: success.title ? success.title : "Success",
      text: success.msg,
      delay: 3000
    })
  }

  function updateErrors(error) {
    PNotify.error({
      title: error.title ? error.title : "Error",
      text: error.msg,
      delay: 3000
    })
  }

  onMount(async () => {
    initDashboard();

    // initFileManager();            
    activeMenu.set('studyMaterial')
    await fetchPdfList();


  });
  afterUpdate(function () {
    feather.replace({ width: "1em", height: "1em" });
    _$('[data-toggle="tooltip"]').tooltip();
  });

</script>


<div class="pd-20 pd-lg-25 pd-xl-30">


  <label
    class="d-flex justify-content-between align-items-center tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15">PDF
    Files
    <a href="#pdf-add-modal" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" data-toggle="modal">
      <i data-feather="plus" class="wd-10 mg-r-5"></i>Add PDF
    </a>
  </label>
  <div class="row row-xs">

    {#if pdfList && pdfList.data && pdfList.data.length > 0}
    {#each pdfList.data as aPdf,pdfIndex (aPdf._id)}
    <div class:mg-sm-t-0={(pdfIndex % 5==2)} class:mg-md-t-0={(pdfIndex % 5==3)} class:mg-xl-t-0={(pdfIndex % 5==4)}
      class:mg-t-10={(pdfIndex % 5==2 || pdfIndex % 5==3 || pdfIndex % 5==4)} class="col-6 col-sm-4 col-md-3 col-xl">
      <div class="card card-file">
        <div class="dropdown-file">
          <a href="" class="dropdown-link" data-toggle="dropdown"><i data-feather="more-vertical"></i></a>
          <div class="dropdown-menu dropdown-menu-right" use:links>
            <a href="/dashboard/studyMaterial/pdf/edit/{aPdf._id}" data-toggle="modal" class="dropdown-item details"><i
                data-feather="info"></i>View Details</a>
            <a href="/dashboard/studyMaterial/pdf/edit/{aPdf._id}" class="dropdown-item important"><i
                data-feather="star"></i>Mark as
              Important</a>
            <a href="/dashboard/studyMaterial/pdf/edit/{aPdf._id}" data-toggle="modal" class="dropdown-item share"><i
                data-feather="share"></i>Share</a>
            <a href="/dashboard/studyMaterial/pdf/edit/{aPdf._id}" class="dropdown-item download"><i
                data-feather="download"></i>Download</a>
            <a href="/dashboard/studyMaterial/pdf/edit/{aPdf._id}" data-toggle="modal" class="dropdown-item copy"><i
                data-feather="copy"></i>Copy to</a>
            <a href="/dashboard/studyMaterial/pdf/edit/{aPdf._id}" data-toggle="modal" class="dropdown-item move"><i
                data-feather="folder"></i>Move to</a>
            <a href="/dashboard/studyMaterial/pdf/edit/{aPdf._id}" class="dropdown-item rename"><i
                data-feather="edit"></i>Rename</a>
            <a on:click={()=> bindDelete(aPdf._id)} class="dropdown-item delete"><i data-feather="trash"></i>Delete</a>
          </div>
        </div><!-- dropdown -->
        <div class="card-file-thumb tx-danger">
          <i class="far fa-file-pdf"></i>
        </div>
        <div class="card-body">
          <h6><a href="/dashboard/studyMaterial/pdf/edit/{aPdf._id}" use:links class="link-02">{aPdf.name}</a></h6>
          <p>PDF File</p>
          <span>{(aPdf.size ?? "")}</span>
        </div>
      </div>
    </div><!-- col -->

    {#if (pdfIndex >= 4 && pdfIndex % 5 == 4)}
    <div class="d-none d-xl-block wd-100p ht-10"></div>
    {/if}
    {/each}

    {#if (pdfList.data.length % 5) == 1}
    <div class="col-6 col-sm-4 col-md-3 col-xl mg-t-10"></div>
    <div class="col-6 col-sm-4 col-md-3 col-xl mg-t-10 mg-sm-t-0"></div>
    <div class="col-6 col-sm-4 col-md-3 col-xl mg-t-10 mg-md-t-0"></div>
    <div class="col-6 col-sm-4 col-md-3 col-xl mg-t-10  mg-xl-t-0"></div>

    {/if}
    {#if (pdfList.data.length % 5) == 2}
    <div class="col-6 col-sm-4 col-md-3 col-xl mg-t-10 mg-sm-t-0"></div>
    <div class="col-6 col-sm-4 col-md-3 col-xl mg-t-10 mg-md-t-0"></div>
    <div class="col-6 col-sm-4 col-md-3 col-xl mg-t-10  mg-xl-t-0"></div>
    {/if}
    {#if (pdfList.data.length % 5) == 3}
    <div class="col-6 col-sm-4 col-md-3 col-xl mg-t-10 mg-md-t-0"></div>
    <div class="col-6 col-sm-4 col-md-3 col-xl mg-t-10  mg-xl-t-0"></div>

    {/if}
    {#if (pdfList.data.length % 5) == 4}
    <div class="col-6 col-sm-4 col-md-3 col-xl mg-t-10  mg-xl-t-0"></div>
    {/if}

    {/if}


  </div><!-- row -->
</div>


<div class="content-body fadeIn" style="display: none;">
  <!-- <div class="content bd-b"> -->
  <!-- <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0"> -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item"><a href="/dashboard/studyMaterial">Study Material</a></li>
          <li class="breadcrumb-item active" aria-current="page">PDF</li>
        </ol>
      </nav>
      <h4 class="mg-b-0">Pdf List</h4>
    </div>
    <a href="#pdf-add-modal" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" data-toggle="modal">
      Add PDF
    </a>

    <!-- <div class="search-form mg-t-20 mg-sm-t-0">
              <input type="search" class="form-control" placeholder="Search people">
              <button class="btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
            </div> -->
  </div>
  <!-- </div>
    </div> -->



  <div>

    {#if pdfList && pdfList.data && pdfList.data.length}
    {#each pdfList.data as aPdf (aPdf._id)}
    <div class="card mg-10">
      <div class="card-body d-flex justify-content-left">
        <i class="d-flex justify-content-left far fa-file-pdf fa-2x tx-danger"></i>
        <div class="col" on:click={()=> editBtnHander(aPdf._id)}>
          {aPdf.name}
        </div>
        <div class="col-sm-1 col-md-1 col-lg-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="pointer"> <i data-feather="more-vertical" /></span>
          <nav class="dropdown-menu" use:links>
            <a href="/dashboard/studyMaterial/pdf/edit/{aPdf._id}" class="dropdown-item"><i data-feather="edit-2" />
              {@html '&nbsp;'}Edit</a>
            <button class="dropdown-item" on:click={()=> bindDelete(aPdf._id)}
              data-id={aPdf._id}><i data-feather="trash" />
              {@html '&nbsp;'}Remove
            </button>
          </nav>
        </div>
      </div>
    </div>

    {:else}
    <span>No PDF's Found. Please Add a pdf</span>
    {/each}
    {/if}
  </div>
  <p>
    Showing {length}/ {pdfList.filtered_count} from total {pdfList.total_count}
    pdfs {#if pdfList.filtered_count > length}
    <button class="btn btn-secondary d-inline-block mg-10" title="load more" on:click={async ()=> {
      console.log(`loading ${pageSize} more`);
      await fetchPdfList(pageSize);
      }}>
      Load more <i data-feather="more-horizontal" />
    </button>
    {/if}
  </p>

</div>


<div class="modal effect-scale" id="pdf-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content ">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="file-plus" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">{modelTitle}</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Add new pdf.</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->
      <!-- <div class="modal-header">
          <span class="tx-color-04"><i data-feather="file-plus" class="wd-40 ht-40 stroke-wd-3 mg-b-20" /></span>
          <h4 class="modal-title mg-l-15" id="exampleModalLabel6">{modelTitle}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div> -->
      <hr class="full-width">
      <div class="modal-body  pd-l-25 pd-r-25 pd-b-25 pd-t-0 ">

        <div class="form-row">
          <div class="form-group col-md-12">
            <label class="d-block">Pdf Name
              <span class="required-field">*</span></label>
            <input bind:value={pdfObj.name} type="text" on:blur={()=>clearError("name")} class="form-control"
            class:parsley-error={errorObj.name} placeholder="Pdf Name"/>
            {#if errorObj.name}
            <div class="parsley-errors-list">{errorObj.name}</div>
            <!-- content here -->
            {/if}
          </div>



        </div>


      </div>
      <div class="modal-footer pd-l-25 pd-r-25 pd-b-25 pd-t-0">
        <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CLOSE
          <i data-feather="x" class="wd-10 mg-l-5"></i>
        </button>
        <button type="button" on:click={addNewPdf} class="btn btn-primary tx-12">
          <ButtonLoader isLoading={isLoading} btnText="SAVE" />
          <i data-feather="save" class="wd-10 mg-l-5"></i>
        </button>
      </div>
    </div>
  </div>
</div>
