<script>

let elem_croppieDialog;
let elem_cameraDialog;
let defaultProfilePic = '/images/man.png';
export let shape="circle"
export let profilePic = ''
export let outputType = "base64";
let elem_uploadFile,elem_croppie;
import { createEventDispatcher, onMount } from 'svelte';
let croppieInput = "";
let capturedCameraImageSrc = "/images/man.png";
let disableSaveCameraBtn = true;

const dispatch = createEventDispatcher();
    function isShapeValid() {
        return shape == 'circle' || shape == 'square'
    }
    onMount( () => {
        if(!isShapeValid()) shape = 'circle'
        _$(elem_croppieDialog).on("shown.bs.modal", function () {
            _$(elem_croppie).croppie({
                        viewport: {
                            width: 200,
                            height: 200,
                            mouseWheelZoom: true,
                            type: shape,
                        },
                        enableResize: (shape =='square')
                    });
            _$(elem_croppie).croppie("bind", { url: croppieInput });            
        })
        _$(elem_croppieDialog).on("hidden.bs.modal", function () {
            _$(elem_croppie).croppie("destroy");
            _$(elem_uploadFile).val("")
            
        })
        _$(elem_cameraDialog).on("shown.bs.modal", function () {
            disableSaveCameraBtn = true;
            capturedCameraImageSrc = "/images/man.png"
            Webcam.set({
                image_format: 'jpeg',
                jpeg_quality: 90,
                width:200,
                height:200,
                flip_horiz: true
            });
            Webcam.attach( '#camera-container');      
        })
        _$(elem_cameraDialog).on("hidden.bs.modal", function () {
            disableSaveCameraBtn = true;
            Webcam.reset();
            
        })
    })

    function take_snapshot() {
    Webcam.snap( function(data_uri) {
        capturedCameraImageSrc = data_uri;
        disableSaveCameraBtn = false;
    } );
}
    function onCamera(params) {

        navigator.permissions.query({name: 'camera'})
        .then((permissionObj) => {
            if (permissionObj.state == "denied") {
                PNotify.alert({
                    title: "Camera Permission Required",
                    text: "We require Camera Permission For Capturing photo. Please enable Camera access in your Browser Setting",
                    delay: 3000,
                });
                return;
            }
            _$(elem_cameraDialog).modal("show");

        })
        .catch((error) => {
            console.log('Got error :', error);
        })        

    }
    function onFile() {
        _$(elem_uploadFile).click();
        
    }
    function uploadCroppieBtn(e) {
        _$(elem_croppie)
            .croppie("result", {
                type: "canvas",
                size: "viewport",
                type: outputType,
                // type: 'blob'
            })
            .then(function (resp) {
                profilePic = resp;
                dispatch('updatePic', profilePic);
                _$(elem_croppieDialog).modal("hide");
                // _$(elem_croppie).croppie("destroy");
            })
            .catch(function (ex) {
                _$(elem_croppie).croppie("destroy");
            });
       
    }
    function b64toBlob(dataURI) {

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }    
    function saveCameraImage() {
        if (outputType == "blob") {
            profilePic = b64toBlob(capturedCameraImageSrc);
        } else {
            profilePic = capturedCameraImageSrc;
        }
        dispatch('updatePic', profilePic);
        _$(elem_cameraDialog).modal("hide");
    }

    function bindFileChange(params) {
            var fd = new FormData();

            var files = _$(elem_uploadFile)[0].files[0];
            //&& ((files.size * 1000) < 200000)
            // if (_$(elem_croppie).croppie) {
            //     _$(elem_croppie).croppie("destroy");
            // }
            if (files) {
                fd.append("file", files);

                const reader = new FileReader();
                reader.addEventListener("load", function (params) {
                    croppieInput = this.result;
                    _$(elem_croppieDialog).modal("show");
                });
                reader.readAsDataURL(files);
            } else {
                swal("Image Size Should be less 200kb");
            }
        
    }
</script>
<!-- 
<slot name="image" src={(profilePic ? profilePic : defaultProfilePic)}></slot>
<slot name="upload-btn" openCroppie={onFile} ></slot>
<slot name="camera-btn" openCamera={onCamera} ></slot> -->
<slot name="upload-container" src={(profilePic ? profilePic : defaultProfilePic)} openCroppie={onFile} openCamera={onCamera} ><!-- optional fallback --></slot>

<input bind:this={elem_uploadFile} class="d-none" on:change={bindFileChange} type="file" id="file" name="myFile" accept="image/*" data-max-size="2048"  />


<div class="modal fade" bind:this={elem_croppieDialog} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content tx-14">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLabel6">Crop Image</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="bind-croppie" bind:this={elem_croppie} style="height:400px">
        
                </div>
            </div>
            

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Close</button>
          <button type="button" on:click={uploadCroppieBtn} class="btn btn-primary tx-13">Crop and Save</button>
        </div>
      </div>
    </div>
  </div>

  
<div class="modal fade" bind:this={elem_cameraDialog} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content tx-14">
        <div class="modal-header">
          <h4 class="modal-title" id="camera-modal">Capture Image </h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="d-sm-flex justify-content-center " style="width: 100%;">
                    <div style="">
                        <div class="" style="width:200px;height:200px" id="camera-container" >
                        </div>
                    </div>                    
                    <div class="d-sm-flex align-items-center mg-20" id="" >
                        <button type="button" on:click={take_snapshot} class="btn btn-primary tx-13">Capture <i data-feather="arrow-right"></i></button>        
                    </div>
                    <div class="d-sm-flex justify-content-center " style="
                    justify-content: center;
                    align-items: center;">
                        <img class="rounded-circle" style="    width: 200px;" src={capturedCameraImageSrc}>
                    </div>
                </div>

                <div class="" id="camera-output" >
                </div>
            </div>
            

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Close</button>
          <button type="button" disabled={disableSaveCameraBtn} on:click={saveCameraImage} class="btn btn-primary tx-13">Save Image</button>
        </div>
      </div>
    </div>
  </div>
