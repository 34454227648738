<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../stores";
  import BatchSelectComponent from "../utils/batchSelectComponent.svelte";
  import { pError } from "../utils/notify.svelte";
  import ButtonLoader from "../utils/button_loader.svelte";
  import MODULES from "../utils/modules.js" 
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  const days = {
    SUN: 0,
    MON: 1,
    TUE: 2,
    WED: 3,
    THU: 4,
    FRI: 5,
    SAT: 6,
  };
  let isLoading = false;
  let elem_startTimePicker;
  let elem_endTimePicker
  let scheduleTime;
  var batchTimingsList = [];
  // let isEditMode = false
  let selectizeSubjectInstance, selectizeFacultyInstance
  var classes = {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  };
  const filter = {
      draw: 1,
      columns: [
        {
          data: "name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "email",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "_id",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      start: 0,
      length: 100,
      search: {
        value: "",
        regex: false,
      },
      sort: {
        userSeqId: 1,
      },
      accessProfileId: "",
    };
  var hourList = [
    { id: 1, text: "01" },
    { id: 2, text: "02" },
    { id: 3, text: "03" },
    { id: 4, text: "04" },
    { id: 5, text: "05" },
    { id: 6, text: "06" },
    { id: 7, text: "07" },
    { id: 8, text: "08" },
    { id: 9, text: "09" },
    { id: 10, text: "10" },
    { id: 11, text: "11" },
    { id: 12, text: "12" },
  ];

  var minList = [
    { id: 0, text: "00" },
    { id: 15, text: "15" },
    { id: 30, text: "30" },
    { id: 45, text: "45" },
  ];
  var ampmList = [
    { id: "AM", text: "AM" },
    { id: "PM", text: "PM" },
  ];
  var classStartTime = {
    HH: "",
    MM: "",
    AMPM: "",
  };
  var classEndTime = {
    HH: "",
    MM: "",
    AMPM: "",
  };

  var classDuration = "";
  function convertToDate(classTime) {
    var hours = Number(classTime.HH);
    var minutes = Number(classTime.MM);
    var AMPM = classTime.AMPM;
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  }

  function formatTime(dateObj) {
    var date = new Date(dateObj);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  function generateClassDuration() {
    if (
      classStartTime.HH != null &&
      classStartTime.AMPM != null &&
      classEndTime.HH != null &&
      classEndTime.AMPM != null
    ) {
      var startDate = convertToDate(classStartTime);
      var endDate = convertToDate(classEndTime);
      batchTimingObj.startTime = startDate;
      batchTimingObj.endTime = endDate;

      if (endDate < startDate) {
        endDate.setDate(endDate.getDate() + 1);
      }

      var diff = endDate - startDate;
      var msec = diff;
      var hh = Math.floor(msec / 1000 / 60 / 60);
      msec -= hh * 1000 * 60 * 60;
      var mm = Math.floor(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      var ss = Math.floor(msec / 1000);
      msec -= ss * 1000;

      classDuration = `${hh} Hours ${mm} mins`;
    } else {
      batchTimingObj.startTime = "";
      batchTimingObj.endTime = "";
      classDuration = ``;
    }
  }

  export let batch_id;

  var batchTimingObj = {
    _id: null,
    batch_id: batch_id,
    dayId: 0, // 0-6 = sun-sat
    startTime: "",
    endTime: "",
    facultyUserId: "",
  };
  var elemModuleSubj;
  var elemFaculty;
  var errorObj = {};
  var moduleSubjectList = [];
  var facultyList = [];
  var modelTitle = "Add new class";
  var elem_addBatchTimingModel;
  function showModel(params) {
    _$(elem_addBatchTimingModel).modal("show");
  }
  function hideModel(params) {
    _$(elem_addBatchTimingModel).modal("hide");
  }

  function addNewClass(dayId) {

    if(!(MODULES.hasAccess(MODULES.ADD_CLASS_TIMINGS))){
      pError("Access Denied", "This profile user does not have permission to schedule a class timing!!")
      return
    }

    _$(elem_startTimePicker).timepicker("setTime", "12:00 AM");
    _$(elem_endTimePicker).timepicker("setTime", "12:00 AM");
    // _$(elemModuleSubj).select2("");
    _$(elemModuleSubj)[0].selectize.clear();
    _$(elemFaculty)[0].selectize.clear();
    batchTimingObj.facultyUserId = "";
    batchTimingObj.moduleSubjects = [];
    batchTimingObj.dayId = dayId;
    batchTimingObj.topic = "";
    modelTitle = "Add new class";
    showModel();
  }

  async function editClassTiming(batchTimingId) {

    if(!(MODULES.hasAccess(MODULES.EDIT_CLASS_TIMINGS))){
      pError("Access Denied", "This profile user does not have permission to edit scheduled class!!")
      return
    }
    // isEditMode = true
    let classTimeObj = await api_getBatchTimingById({_id:batchTimingId})
    // console.log("classTIme obj:::",classTimeObj)
    
    batchTimingObj = classTimeObj.data;
    selectizeFacultyInstance.setValue(batchTimingObj.facultyUserId)
    selectizeSubjectInstance.setValue(batchTimingObj.moduleSubjects[0])
   let  startTime  = batchTimingObj.startTime ?moment(batchTimingObj.startTime ).format('LT'):"-";
   let  endTime  = batchTimingObj.endTime ?moment(batchTimingObj.endTime ).format('LT'):"-";
   _$(elem_startTimePicker).timepicker("setTime", startTime);
   _$(elem_endTimePicker).timepicker("setTime", endTime);


    // console.log("start time", startTime, endTime)
    modelTitle = "Edit class";
    showModel();
  }
  
  async function deleteClassTiming(batchTimingId) {

    if(!(MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS))){
      pError("Access Denied", "This profile user does not have permission to delete scheduled class!!")
      return
    }

    var batchTimingRes = await api_deleteClass({ _id: batchTimingId });
    if (batchTimingRes.status) {
      renderBatchList();
      hideModel();
      PNotify.success({
        title: "Success",
        text: batchTimingRes.data,
        delay: 3000,
      });
    } else {
      PNotify.error({
        title: "Error",
        text: batchTimingRes.errMsg,
        delay: 3000,
      });
    }
  }

  let validateClass = () => {
    errorObj = {};
    if (
      !batchTimingObj.dayId.toString() ||
      !batchTimingObj.startTime ||
      !batchTimingObj.endTime ||
      !batchTimingObj.startDate ||
      !batchTimingObj.moduleId ||
      !batchTimingObj.subjectId ||
      !batchTimingObj.facultyUserId ||
      !batchTimingObj.topic 
    ) {
      var newErrorObj = {};
      if (!batchTimingObj.startTime) {
        newErrorObj.startTime = "Class start time is required field.";
      }
      if (!batchTimingObj.endTime) {
        newErrorObj.endTime = "Class end time is required field.";
      }
    
      if (!batchTimingObj.topic ) {
        newErrorObj.topic = "Topic for the class is required field.";
      }
      if (!batchTimingObj.facultyUserId) {
        newErrorObj.facultyUserId = "Faculty user is required field.";
      }
      if (batchTimingObj.moduleSubjects.length == 0) {
        newErrorObj.subjectId = "Subject is required field.";
      }
      if (batchTimingObj.startTime && batchTimingObj.endTime) {
        if (
          batchTimingObj.startTime.getDate() != batchTimingObj.endTime.getDate()
        ) {
          newErrorObj.startTime = "Class start time cannot be greater than class end time.";
            
        }
        if (
          batchTimingObj.startTime.getHours() ==
          batchTimingObj.endTime.getHours() &&
          batchTimingObj.startTime.getMinutes() ==
          batchTimingObj.endTime.getMinutes()
        ) {
          newErrorObj.startTime =
            "Class start time and class end time cannot be the same.";
        }
      }

      batchTimingObj.startTime;
      errorObj = newErrorObj;
    }
    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;
  };

  async function addUpdateClass() {
    if (isLoading) return;
    isLoading = true;
    await tick();
    if (validateClass()) {
      var batchTimingRes = await api_addUpdateClass(batchTimingObj);
      if (batchTimingRes.status) {
        renderBatchList();
        hideModel();
        PNotify.success({
          title: "Success",
          text: batchTimingRes.data,
          delay: 3000,
        });
      } else {
        PNotify.error({
          title: "Error",
          text: batchTimingRes.errMsg,
          delay: 3000,
        });
      }
      isLoading = false;
      return false;
    }
    isLoading = false;
  }

  async function api_addUpdateClass(data) {

    let action = "batchtiming.addUpdateBatchTiming";
        var response = await api_handleApiCalls(action, data);
        return response;

  }
  async function api_deleteClass(data) {

      let action = "batchtiming.deleteBatchtimingById";
        var response = await api_handleApiCalls(action, data);
        return response;

  }
  
  async function api_getBatchTimingById(data) {

      let action = "batchtiming.getBatchTimingById";
      var response = await api_handleApiCalls(action, data);
      return response;

    }
  async function getSubjectForBatch(batchId) {
    let subjectArr = await api_handleApiCalls("folder.getSubjectsForBatch", {
      batchId: batchId,
    });
    moduleSubjectList = subjectArr.data;

    if (!Boolean(moduleSubjectList.length)) {
      errorObj.subjectId =
        "No subject found associated with this batch please assign some subject to it";
    } else {
      errorObj.subjectId = false;
    }
  }
  async function api_getModuleSub(data) {
    
    let action =  "categories.getModuleSubjectList";
        var response = await api_handleApiCalls(action, data);
        if (response.errMsg) {
            PNotify.error({
                title: 'Error',
                text: response.errMsg,
                delay: 3000
            })
        } else {
            PNotify.success({
                title: 'success',
                text: "successfully added an access profile",
                delay: 3000
            })
        }
        return response;

  }
  async function api_getFacultyList(data) {

    let action =  "systemUser.fetchSystemUserList";
        var response = await api_handleApiCalls(action, data);
        return response;

  }
  async function api_listBatchTimings(data) {

    let action =  "batchtiming.listBatchTimings";
        var response = await api_handleApiCalls(action, data);
        return response;

  }

  function bindAddModel() {
    _$(elem_addBatchTimingModel).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
      // bindSelect2();
      setTimeout(() => {
        _$(elem_addBatchTimingModel)
          .find(".select2-search__field")
          .css("width", "100%");
      }, 500);
    });

    // hide modal with effect
    _$(elem_addBatchTimingModel).on("hidden.bs.modal", function (e) {
      _$(this).removeClass("effect-scale");
    });
  }
  async function renderBatchList() {
    var batchTimingsRes = await api_listBatchTimings({ batch_id: batch_id });
    if (batchTimingsRes.status) {
      classes = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };
      batchTimingsList = batchTimingsRes.data.data;
      batchTimingsList = batchTimingsList.sort(function (batch1, batch2) {
        var d1 = new Date(batch1.startTime);
        var d2 = new Date(batch2.startTime);
        var date1 = new Date();
        var date2 = new Date();
        date1.setHours(d1.getHours());
        date1.setMinutes(d1.getMinutes());
        date2.setHours(d2.getHours());
        date2.setMinutes(d2.getMinutes());
        return date1 - date2;
      });
      batchTimingsList.forEach((aClass) => {
        switch (aClass.dayId) {
          case days.SUN:
            classes.sunday.push(aClass);
            break;
          case days.MON:
            classes.monday.push(aClass);
            break;
          case days.TUE:
            classes.tuesday.push(aClass);
            break;
          case days.WED:
            classes.wednesday.push(aClass);
            break;
          case days.THU:
            classes.thursday.push(aClass);
            break;
          case days.FRI:
            classes.friday.push(aClass);
            break;
          case days.SAT:
            classes.saturday.push(aClass);
            break;
          default:
            classes.monday.push();
            break;
        }
      });
      setTimeout(() => {
        feather.replace();
      }, 1000);
    }
  }
  const onKeyPress = e => {
    // this check is removed because class timing was not getting saved by Anurag
    // if (e.charCode === 13) 
    // if(isEditMode) 
    addUpdateClass();
  };
  

  function addLog(type, message) {
    // var $log = _$('<tr />');
    // $log.append(_$('<th />').text(type));
    // $log.append(_$('<td />').text(message ? JSON.stringify(message) : ''));
    // _$("#logs table").prepend($log);
  }
  function bindScheduler(params) {
    var isDraggable = true;
    var isResizable = true;
    var $sc = _$("#schedule").timeSchedule({
      startTime: "07:00", // schedule start time(HH:ii)
      endTime: "21:00", // schedule end time(HH:ii)
      widthTimeX: 5,
      widthTime: 60 * 15, // cell timestamp example 10 minutes
      timeLineY: 60, // height(px)
      verticalScrollbar: 20, // scrollbar (px)
      timeLineBorder: 2, // border(top and bottom)
      bundleMoveWidth: 6, // width to move all schedules to the right of the clicked time line cell
      draggable: isDraggable,
      resizable: isResizable,
      resizableLeft: true,
      rows: {
        "0": {
          title: "Title Area1",
          schedule: [
            {
              start: "09:00",
              end: "12:00",
              text: "Text Area",
              data: {},
            },
            {
              start: "11:00",
              end: "14:00",
              text: "Text Area",
              data: {},
            },
          ],
        },
        "1": {
          title: "Title Area2",
          schedule: [
            {
              start: "16:00",
              end: "17:00",
              text: "Text Area",
              data: {},
            },
          ],
        },
      },
      onChange: function (node, data) {
        addLog("onChange", data);
      },
      onInitRow: function (node, data) {
        addLog("onInitRow", data);
      },
      onClick: function (node, data) {
        addLog("onClick", data);
      },
      onAppendRow: function (node, data) {
        addLog("onAppendRow", data);
      },
      onAppendSchedule: function (node, data) {
        addLog("onAppendSchedule", data);
        if (data.data.class) {
          node.addClass(data.data.class);
        }
        if (data.data.image) {
          var $img = _$('<div class="photo"><img></div>');
          $img.find("img").attr("src", data.data.image);
          node.prepend($img);
          node.addClass("sc_bar_photo");
        }
      },
      onScheduleClick: function (node, time, timeline) {
        var start = time;
        var end = _$(this).timeSchedule(
          "formatTime",
          _$(this).timeSchedule("calcStringTime", time) + 3600
        );
        _$(this).timeSchedule("addSchedule", timeline, {
          start: start,
          end: end,
          text: "Insert Schedule",
          data: {
            class: "sc_bar_insert",
          },
        });
        addLog("onScheduleClick", time + " " + timeline);
      },
    });
    _$("#event_timelineData").on("click", function () {
      addLog("timelineData", $sc.timeSchedule("timelineData"));
    });
    _$("#event_scheduleData").on("click", function () {
      addLog("scheduleData", $sc.timeSchedule("scheduleData"));
    });
    _$("#event_resetData").on("click", function () {
      $sc.timeSchedule("resetData");
      addLog("resetData");
    });
    _$("#event_resetRowData").on("click", function () {
      $sc.timeSchedule("resetRowData");
      addLog("resetRowData");
    });
    _$("#event_setDraggable").on("click", function () {
      isDraggable = !isDraggable;
      $sc.timeSchedule("setDraggable", isDraggable);
      addLog("setDraggable", isDraggable ? "enable" : "disable");
    });
    _$("#event_setResizable").on("click", function () {
      isResizable = !isResizable;
      $sc.timeSchedule("setResizable", isResizable);
      addLog("setResizable", isResizable ? "enable" : "disable");
    });
    _$(".ajax-data").on("click", function () {
      // $.ajax({url: './data/'+_$(this).attr('data-target')})
      //     .done( (data) => {
      //         addLog('Ajax GetData', data);
      //         $sc.timeSchedule('setRows', data);
      //     });
    });
    _$("#clear-logs").on("click", function () {
      _$("#logs .table").empty();
    });
  }

  async function bindSelect2() {
    var facultyListRes = await api_getFacultyList(filter);
    let subjectArr = await api_handleApiCalls("folder.getSubjectsForBatch", {
      batchId: batch_id,
    });
    let subjectModuleInstance = _$(elemModuleSubj).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: subjectArr.data,
      onChange: function (value) {
        batchTimingObj.moduleSubjects = value;
      },
    });
    selectizeSubjectInstance = subjectModuleInstance[0].selectize

    
    let facultyModuleInstance = _$(elemFaculty).selectize({
      preload: true,
      create: false,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      delimiter: ",",
      //plugins: ["remove_button"],
      persist: false,
      options: facultyListRes.data.data,
      onChange: function (value) {
        batchTimingObj.facultyUserId = value;
      },
    });
    selectizeFacultyInstance = facultyModuleInstance[0].selectize

  }
  function handleBatchChange(event) {
    if (event.detail.batch_id) {
      navigate("/dashboard/classtiming/" + event.detail.batch_id);
    } else {
      navigate("/dashboard/classtiming");
    }
  }
  onMount(async () => {
    feather.replace({ width: "1em", height: "1em" });
    _$(elem_startTimePicker).timepicker("setTime", "12:00 AM");
    _$(elem_endTimePicker).timepicker("setTime", "12:00 AM");
    if (!batch_id) {
      return;
    }

    _$(elem_startTimePicker).timepicker({
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      // template: "modal",
      defaultTime: "12:00 AM",
      change: async function (time) {
        if (time) {
          scheduleTime = new Date(time);

          classStartTime.HH = scheduleTime.getHours();
          classStartTime.MM = scheduleTime.getMinutes();
          classStartTime.AMPM = classStartTime.HH >= 12 ? "PM" : "AM";

        } else {
          scheduleTime = null;
          // scheduledOn.setHours(0, 0, 5, 0);
        }
        // clearError("scheduleTime");
        generateClassDuration();

        await tick();
      },
    });
    _$(elem_endTimePicker).timepicker({
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      // template: "modal",
      defaultTime: "12:00 AM",
      change: async function (time) {
        if (time) {
          scheduleTime = new Date(time);
          classEndTime.HH = scheduleTime.getHours();
          classEndTime.MM = scheduleTime.getMinutes();
          classEndTime.AMPM = classEndTime.HH >= 12 ? "PM" : "AM";
        } else {
          scheduleTime = null;
        }
        generateClassDuration();
        await tick();
      },
    });



    // getSubjectForBatch(batch_id);
    renderBatchList();
    bindSelect2();
    activeMenu.set("batchtiming");
    
    bindAddModel();

    // setTimeout(() => {

    //   bindScheduler();
    // }, 1000);
    feather.replace();
  });
</script>

<style>
  /* ###### 4.6 Parsley ###### */
  input.parsley-error,
  input.parsley-error:focus,
  textarea.parsley-error,
  textarea.parsley-error:focus {
    box-shadow: none !important;
    border-color: #dc3545;
  }

  input.parsley-success,
  input.parsley-success:focus,
  textarea.parsley-success,
  textarea.parsley-success:focus {
    box-shadow: none !important;
    border-color: #10b759;
  }

  .parsley-checkbox.parsley-error,
  .parsley-checkbox.parsley-success {
    display: inline-block;
    padding: 10px;
    border-radius: 0.25rem;
  }

  .parsley-checkbox.parsley-error {
    border: 1px solid #dc3545;
  }

  .parsley-checkbox.parsley-success {
    border: 1px solid #10b759;
  }

  .custom-select.parsley-error,
  .custom-select.parsley-success {
    box-shadow: none !important;
  }

  .custom-select.parsley-error {
    border-color: #dc3545;
  }

  .custom-select.parsley-success {
    border-color: #10b759;
  }

  .parsley-errors-list {
    color: #dc3545;
    list-style: none;
    font-size: 12px;
    padding: 0;
    margin-bottom: -12px;
  }

  .parsley-errors-list li {
    margin: 5px 0 0;
  }
</style>

<div class="content-body fadeIn">
  <!-- <Link style="display: none;" to="about">About</Link> -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Class Timing
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Class Timing</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Schedule suitable weekly class timing.</p>
    </div>

    <div class="d-flex">
      <BatchSelectComponent {batch_id} on:change={handleBatchChange} />
    </div>
  </div>
  <hr class="full-width">
  <div>
    <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="sunday-tab" data-toggle="tab" href="#sunday" role="tab" aria-controls="sunday"
          aria-selected="true">Sunday</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="monday-tab" data-toggle="tab" href="#monday" role="tab" aria-controls="monday"
          aria-selected="false">Monday</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="tuesday-tab" data-toggle="tab" href="#tuesday" role="tab" aria-controls="tuesday"
          aria-selected="false">Tuesday</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="wednesday-tab" data-toggle="tab" href="#wednesday" role="tab" aria-controls="wednesday"
          aria-selected="false">Wednesday</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="thursday-tab" data-toggle="tab" href="#thursday" role="tab" aria-controls="thursday"
          aria-selected="false">Thursday</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="friday-tab" data-toggle="tab" href="#friday" role="tab" aria-controls="friday"
          aria-selected="false">Friday</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="saturday-tab" data-toggle="tab" href="#saturday" role="tab" aria-controls="saturday"
          aria-selected="false">Saturday</a>
      </li>
    </ul>
    <div class="tab-content bd bd-gray-300 bd-t-0 pd-20" id="myTabContent">
      <div class="tab-pane fade show active" id="sunday" role="tabpanel" aria-labelledby="sunday-tab">
        <h4>Classes on Sunday</h4>
        <div id="schedule" class="row" />
        <div class="row">
          {#each classes.sunday as aBatchTiming}
            <div class="col-sm-3 mg-t-10">
              <div class="card ">
                <div class="card-header tx-semibold">
                  {aBatchTiming.moduleSubjects
                    .map(function (aModuleSubject) {
                      var aSubject = aBatchTiming.subjectList.find(function (
                        item
                      ) {
                        return item._id == aModuleSubject;
                      });
                      return aSubject.name;
                    })
                    .join(', ')}
                  <!-- <span style="float:right;" on:click={() => {editClassTiming(aBatchTiming)}} class="tx-color-04"><i data-feather="edit" class="wd-30 ht-30"></i></span> -->
                  {#if aBatchTiming.topic}
                  <br><span class="card-text">{aBatchTiming.topic ? aBatchTiming.topic: ""}</span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                  <span
                  style="float:right;"
                  on:click={() => {
                   editClassTiming(aBatchTiming._id);
                  }}
                  class="tx-color-04"><i
                    data-feather="edit"
                    class="wd-25 h-25" /></span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                 
                  
                  <span
                    style="float:right;"
                    on:click={() => {
                      deleteClassTiming(aBatchTiming._id);
                    }}
                    class="tx-color-04"><i
                      data-feather="trash"
                      class="wd-25 h-25" /></span>
                      {/if}
                </div>
                <div class="card-body">
                  <p class="card-text">
                    {formatTime(aBatchTiming.startTime)  || " - "}
                    to
                    {formatTime(aBatchTiming.endTime)  || " - "}
                  </p>
                  <p class="card-text">
                    By
                    {aBatchTiming.createdByDetails.name  || " - "}
                  </p>
                </div>
              </div>
            </div>
            <!-- col -->
          {/each}
          {#if MODULES.hasAccess(MODULES.ADD_CLASS_TIMINGS)}
          <div class="col-sm-3 mg-t-10">
            <div on:click={() => addNewClass(days.SUN)} class="card ">
              <div class="card-body">
                <div class="media">
                  <span class="tx-color-04"><i
                      data-feather="plus"
                      class="wd-30 ht-30" /></span>
                  <div class="media-body mg-l-30">
                    <h4 class="mg-b-10">Add Class</h4>
                  </div>
                </div>
                <!-- media -->
              </div>
            </div>
          </div>
          {/if}
          <!-- col -->
        </div>
      </div>
      <div
        class="tab-pane fade show"
        id="monday"
        role="tabpanel"
        aria-labelledby="monday-tab">
        <h4>Classes on Monday</h4>
        <div class="row">
          {#each classes.monday as aBatchTiming}
            <div class="col-sm-3 mg-t-10">
              <div class="card ">
                <div class="card-header tx-semibold">
                  {aBatchTiming.moduleSubjects
                    .map(function (aModuleSubject) {
                      var aSubject = aBatchTiming.subjectList.find(function (
                        item
                      ) {
                        return item._id == aModuleSubject;
                      });
                      return aSubject.name;
                    })
                    .join(', ')}
                  <!-- <span style="float:right;" on:click={() => {editClassTiming(aBatchTiming)}} class="tx-color-04"><i data-feather="edit" class="wd-30 ht-30"></i></span> -->
                  {#if aBatchTiming.topic}
                  <br><span class="card-text">{aBatchTiming.topic ? aBatchTiming.topic: ""}</span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                  <span
                  style="float:right;"
                  on:click={() => {
                   editClassTiming(aBatchTiming._id);
                  }}
                  class="tx-color-04"><i
                    data-feather="edit"
                    class="wd-25 h-25" /></span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                 
                  
                  <span
                    style="float:right;"
                    on:click={() => {
                      deleteClassTiming(aBatchTiming._id);
                    }}
                    class="tx-color-04"><i
                      data-feather="trash"
                      class="wd-25 h-25" /></span>
                      {/if}
                </div>
                <div class="card-body">
                  <p class="card-text">
                    {formatTime(aBatchTiming.startTime)  || " - "}
                    to
                    {formatTime(aBatchTiming.endTime)  || " - "}
                  </p>
                  <p class="card-text">
                    By
                    {aBatchTiming.createdByDetails.name  || " - "}
                  </p>
                </div>
              </div>
            </div>
            <!-- col -->
          {/each}
          {#if MODULES.hasAccess(MODULES.ADD_CLASS_TIMINGS)}
          <div class="col-sm-3 mg-t-10">
            <div on:click={() => addNewClass(days.MON)} class="card ">
              <div class="card-body">
                <div class="media">
                  <span class="tx-color-04"><i
                      data-feather="plus"
                      class="wd-30 ht-30" /></span>
                  <div class="media-body mg-l-30">
                    <h4 class="mg-b-10">Add Class</h4>
                  </div>
                </div>
                <!-- media -->
              </div>
            </div>
          </div>
          {/if}
          <!-- col -->
        </div>
      </div>
      <div
        class="tab-pane fade show"
        id="tuesday"
        role="tabpanel"
        aria-labelledby="tuesday-tab">
        <h4>Classes on Tuesday</h4>
        <div class="row">
          {#each classes.tuesday as aBatchTiming}
            <div class="col-sm-3 mg-t-10">
              <div class="card ">
                <div class="card-header tx-semibold">
                  {aBatchTiming.moduleSubjects
                    .map(function (aModuleSubject) {
                      var aSubject = aBatchTiming.subjectList.find(function (
                        item
                      ) {
                        return item._id == aModuleSubject;
                      });
                      return aSubject.name;
                    })
                    .join(', ')}
                  <!-- <span style="float:right;" on:click={() => {editClassTiming(aBatchTiming)}} class="tx-color-04"><i data-feather="edit" class="wd-30 ht-30"></i></span> -->
                  {#if aBatchTiming.topic}
                  <br><span class="card-text">{aBatchTiming.topic ? aBatchTiming.topic: ""}</span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                  <span
                  style="float:right;"
                  on:click={() => {
                   editClassTiming(aBatchTiming._id);
                  }}
                  class="tx-color-04"><i
                    data-feather="edit"
                    class="wd-25 h-25" /></span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                 
                  
                  <span
                    style="float:right;"
                    on:click={() => {
                      deleteClassTiming(aBatchTiming._id);
                    }}
                    class="tx-color-04"><i
                      data-feather="trash"
                      class="wd-25 h-25" /></span>
                      {/if}
                </div>
                <div class="card-body">
                  <p class="card-text">
                    {formatTime(aBatchTiming.startTime)  || " - "}
                    to
                    {formatTime(aBatchTiming.endTime)  || " - "}
                  </p>
                  <p class="card-text">
                    By
                    {aBatchTiming.createdByDetails.name  || " - "}
                  </p>
                </div>
              </div>
            </div>
            <!-- col -->
          {/each}
          {#if MODULES.hasAccess(MODULES.ADD_CLASS_TIMINGS)}
          <div class="col-sm-3 mg-t-10">
            <div on:click={() => addNewClass(days.TUE)} class="card ">
              <div class="card-body">
                <div class="media">
                  <span class="tx-color-04"><i
                      data-feather="plus"
                      class="wd-30 ht-30" /></span>
                  <div class="media-body mg-l-30">
                    <h4 class="mg-b-10">Add Class</h4>
                  </div>
                </div>
                <!-- media -->
              </div>
            </div>
          </div>
          {/if}
          <!-- col -->
        </div>
      </div>
      <div
        class="tab-pane fade show"
        id="wednesday"
        role="tabpanel"
        aria-labelledby="wednesday-tab">
        <h4>Classes on Wednesday</h4>
        <div class="row">
          {#each classes.wednesday as aBatchTiming}
            <div class="col-sm-3 mg-t-10">
              <div class="card ">
                <div class="card-header tx-semibold">
                  {aBatchTiming.moduleSubjects
                    .map(function (aModuleSubject) {
                      var aSubject = aBatchTiming.subjectList.find(function (
                        item
                      ) {
                        return item._id == aModuleSubject;
                      });
                      return aSubject.name;
                    })
                    .join(', ')}
                  <!-- <span style="float:right;" on:click={() => {editClassTiming(aBatchTiming)}} class="tx-color-04"><i data-feather="edit" class="wd-30 ht-30"></i></span> -->
                  {#if aBatchTiming.topic}
                  <br><span class="card-text">{aBatchTiming.topic ? aBatchTiming.topic: ""}</span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                  <span
                  style="float:right;"
                  on:click={() => {
                   editClassTiming(aBatchTiming._id);
                  }}
                  class="tx-color-04"><i
                    data-feather="edit"
                    class="wd-25 h-25" /></span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                 
                  
                  <span
                    style="float:right;"
                    on:click={() => {
                      deleteClassTiming(aBatchTiming._id);
                    }}
                    class="tx-color-04"><i
                      data-feather="trash"
                      class="wd-25 h-25" /></span>
                      {/if}
                </div>
                <div class="card-body">
                  <p class="card-text">
                    {formatTime(aBatchTiming.startTime)  || " - "}
                    to
                    {formatTime(aBatchTiming.endTime)  || " - "}
                  </p>
                  <p class="card-text">
                    By
                    {aBatchTiming.createdByDetails.name  || " - "}
                  </p>
                </div>
              </div>
            </div>
            <!-- col -->
          {/each}
          {#if MODULES.hasAccess(MODULES.ADD_CLASS_TIMINGS)}
          <div class="col-sm-3 mg-t-10">
            <div on:click={() => addNewClass(days.WED)} class="card ">
              <div class="card-body">
                <div class="media">
                  <span class="tx-color-04"><i
                      data-feather="plus"
                      class="wd-30 ht-30" /></span>
                  <div class="media-body mg-l-30">
                    <h4 class="mg-b-10">Add Class</h4>
                  </div>
                </div>
                <!-- media -->
              </div>
            </div>
          </div>
          {/if}
          <!-- col -->
        </div>
      </div>
      <div
        class="tab-pane fade show"
        id="thursday"
        role="tabpanel"
        aria-labelledby="thursday-tab">
        <h4>Classes on Thursday</h4>
        <div class="row">
          {#each classes.thursday as aBatchTiming}
            <div class="col-sm-3 mg-t-10">
              <div class="card ">
                <div class="card-header tx-semibold">
                  {aBatchTiming.moduleSubjects
                    .map(function (aModuleSubject) {
                      var aSubject = aBatchTiming.subjectList.find(function (
                        item
                      ) {
                        return item._id == aModuleSubject;
                      });
                      return aSubject.name;
                    })
                    .join(', ')}
                  <!-- <span style="float:right;" on:click={() => {editClassTiming(aBatchTiming)}} class="tx-color-04"><i data-feather="edit" class="wd-30 ht-30"></i></span> -->
                  {#if aBatchTiming.topic}
                  <br><span class="card-text">{aBatchTiming.topic ? aBatchTiming.topic: ""}</span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                  <span
                  style="float:right;"
                  on:click={() => {
                   editClassTiming(aBatchTiming._id);
                  }}
                  class="tx-color-04"><i
                    data-feather="edit"
                    class="wd-25 h-25" /></span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                 
                  
                  <span
                    style="float:right;"
                    on:click={() => {
                      deleteClassTiming(aBatchTiming._id);
                    }}
                    class="tx-color-04"><i
                      data-feather="trash"
                      class="wd-25 h-25" /></span>
                      {/if}
                </div>
                <div class="card-body">
                  <p class="card-text">
                    {formatTime(aBatchTiming.startTime)  || " - "}
                    to
                    {formatTime(aBatchTiming.endTime)  || " - "}
                  </p>
                  <p class="card-text">
                    By
                    {aBatchTiming.createdByDetails.name  || " - "}
                  </p>
                </div>
              </div>
            </div>
            <!-- col -->
          {/each}
          {#if MODULES.hasAccess(MODULES.ADD_CLASS_TIMINGS)}
          <div class="col-sm-3 mg-t-10">
            <div on:click={() => addNewClass(days.THU)} class="card ">
              <div class="card-body">
                <div class="media">
                  <span class="tx-color-04"><i
                      data-feather="plus"
                      class="wd-30 ht-30" /></span>
                  <div class="media-body mg-l-30">
                    <h4 class="mg-b-10">Add Class</h4>
                  </div>
                </div>
                <!-- media -->
              </div>
            </div>
          </div>
          {/if}
          <!-- col -->
        </div>
      </div>
      <div
        class="tab-pane fade show"
        id="friday"
        role="tabpanel"
        aria-labelledby="friday-tab">
        <h4>Classes on Friday</h4>
        <div class="row">
          {#each classes.friday as aBatchTiming}
            <div class="col-sm-3 mg-t-10">
              <div class="card ">
                <div class="card-header tx-semibold">
                  {aBatchTiming.moduleSubjects
                    .map(function (aModuleSubject) {
                      var aSubject = aBatchTiming.subjectList.find(function (
                        item
                      ) {
                        return item._id == aModuleSubject;
                      });
                      return aSubject.name;
                    })
                    .join(', ')}
                  <!-- <span style="float:right;" on:click={() => {editClassTiming(aBatchTiming)}} class="tx-color-04"><i data-feather="edit" class="wd-30 ht-30"></i></span> -->
                  {#if aBatchTiming.topic}
                  <br><span class="card-text">{aBatchTiming.topic ? aBatchTiming.topic: ""}</span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                  <span
                  style="float:right;"
                  on:click={() => {
                   editClassTiming(aBatchTiming._id);
                  }}
                  class="tx-color-04"><i
                    data-feather="edit"
                    class="wd-25 h-25" /></span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                 
                  
                  <span
                    style="float:right;"
                    on:click={() => {
                      deleteClassTiming(aBatchTiming._id);
                    }}
                    class="tx-color-04"><i
                      data-feather="trash"
                      class="wd-25 h-25" /></span>
                      {/if}
                </div>
                <div class="card-body">
                  <p class="card-text">
                    {formatTime(aBatchTiming.startTime)  || " - "}
                    to
                    {formatTime(aBatchTiming.endTime)  || " - "}
                  </p>
                  <p class="card-text">
                    By
                    {aBatchTiming.createdByDetails.name  || " - "}
                  </p>
                </div>
              </div>
            </div>
            <!-- col -->
          {/each}
          {#if MODULES.hasAccess(MODULES.ADD_CLASS_TIMINGS)}
          <div class="col-sm-3 mg-t-10">
            <div on:click={() => addNewClass(days.FRI)} class="card ">
              <div class="card-body">
                <div class="media">
                  <span class="tx-color-04"><i
                      data-feather="plus"
                      class="wd-30 ht-30" /></span>
                  <div class="media-body mg-l-30">
                    <h4 class="mg-b-10">Add Class</h4>
                  </div>
                </div>
                <!-- media -->
              </div>
            </div>
          </div>
          {/if}
          <!-- col -->
        </div>
      </div>
      <div
        class="tab-pane fade show"
        id="saturday"
        role="tabpanel"
        aria-labelledby="saturday-tab">
        <h4>Classes on Saturday</h4>
        <div class="row">
          {#each classes.saturday as aBatchTiming}
            <div class="col-sm-3 mg-t-10">
              <div class="card ">
                <div class="card-header tx-semibold">
                  {aBatchTiming.moduleSubjects
                    .map(function (aModuleSubject) {
                      var aSubject = aBatchTiming.subjectList.find(function (
                        item
                      ) {
                        return item._id == aModuleSubject;
                      });
                      return aSubject.name;
                    })
                    .join(', ')}
                  <!-- <span style="float:right;" on:click={() => {editClassTiming(aBatchTiming)}} class="tx-color-04"><i data-feather="edit" class="wd-30 ht-30"></i></span> -->
                  {#if aBatchTiming.topic}
                  <br><span class="card-text">{aBatchTiming.topic ? aBatchTiming.topic: ""}</span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                  <span
                  style="float:right;"
                  on:click={() => {
                   editClassTiming(aBatchTiming._id);
                  }}
                  class="tx-color-04"><i
                    data-feather="edit"
                    class="wd-25 h-25" /></span>
                  {/if}
                  {#if MODULES.hasAccess(MODULES.DELETE_CLASS_TIMINGS)}
                 
                  
                  <span
                    style="float:right;"
                    on:click={() => {
                      deleteClassTiming(aBatchTiming._id);
                    }}
                    class="tx-color-04"><i
                      data-feather="trash"
                      class="wd-25 h-25" /></span>
                      {/if}
                </div>
                <div class="card-body">
                  <p class="card-text">
                    {formatTime(aBatchTiming.startTime)  || " - "}
                    to
                    {formatTime(aBatchTiming.endTime)  || " - "}
                  </p>
                  <p class="card-text">
                    By
                    {aBatchTiming.createdByDetails.name || " - "}
                  </p>
                </div>
              </div>
            </div>
            <!-- col -->
          {/each}
          {#if MODULES.hasAccess(MODULES.ADD_CLASS_TIMINGS)}
          <div class="col-sm-3 mg-t-10">
            <div on:click={() => addNewClass(days.SAT)} class="card ">
              <div class="card-body">
                <div class="media">
                  <span class="tx-color-04"><i
                      data-feather="plus"
                      class="wd-30 ht-30" /></span>
                  <div class="media-body mg-l-30">
                    <h4 class="mg-b-10">Add Class</h4>
                  </div>
                </div>
                <!-- media -->
              </div>
            </div>
          </div>
          {/if}
          <!-- col -->
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  bind:this={elem_addBatchTimingModel}
  tabindex="-1"
  role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content tx-14">
      <!-- <div class="modal-header">
        <div class="media">
          <span class="tx-color-04"><i
            data-feather="clock"
            class="wd-60 ht-60 stroke-wd-3 mg-b-20" /></span>
          <div class="media-body">
            <h4 class="modal-title mg-l-15" id="exampleModalLabel6">
              {modelTitle}
            </h4>
            <p class="tx-13 tx-color-03 mg-b-0">Create new class</p>
          </div>
        </div>
        
        
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->

      <div class="modal-header pd-b-0 pd-r-25">
        <div class="media align-items-center pd-12">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="clock" class="wd-60 ht-60" /></span>
          <!-- <span class="tx-color-03 d-none d-sm-block"><i data-feather="grid" class="wd-60 ht-60"></i></span> -->
          <!-- <h3 class=" model-title pd-l-15 pd-t-2" id="exampleModalLabel6"></h3> -->
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">{modelTitle}</h4>
            <p class="tx-13 tx-color-03 mg-b-0">{modelTitle == "Edit class" ? "Update the Class" :"Create new class" }</p>
          </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span><br />
        </button>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="topic" class="d-block">Topic<span class="required-field"> *</span></label>
            <input id="topic" class="form-control" type="text" bind:value={batchTimingObj.topic}  on:keyup={(e)=> e.key === 'Enter' && onKeyPress()} placeholder="Class Topic" >
            {#if errorObj.topic}
              <div class="parsley-errors-list">{errorObj.topic}</div>
              <!-- content here -->
            {/if}
          </div>
          <div class="form-group col-md-6">
            <label class="d-block">Subjects<span class="required-field"> *</span></label>
            <select
              style="width:100%;"
              bind:this={elemModuleSubj}
              multiple><option value="" selected>Select Subject</option>
            </select>
            {#if errorObj.subjectId}
              <div class="parsley-errors-list">{errorObj.subjectId}</div>
              <!-- content here -->
            {/if}
          </div>
         
          <div class="form-group col-md-6">
            <label class="d-block">Class Start Time<span class="required-field"> *</span></label>
            <div class="row">
              <div class="col-md-4 wd-200">
                <input
                  bind:this={elem_startTimePicker} onkeydown="return false" on:keyup={e=> e.key === 'Enter' && onKeyPress()}
                  type="text"
                  class="form-control batch-time first-input wd-200"
                  placeholder="Enter time" />
              </div>
            </div>

            {#if errorObj.startTime}
              <div class="parsley-errors-list">{errorObj.startTime}</div>
              <!-- content here -->
            {/if}
          </div>
          <div class="form-group col-md-6">
            <label class="d-block">Class End Time<span class="required-field"> *</span></label>
            <div class="row">
              <div class="col-md-4 wd-200">
                <input
                bind:this={elem_endTimePicker} onkeydown="return false" on:keyup={e=> e.key === 'Enter' && onKeyPress()}
                type="text"
                class="form-control batch-time first-input wd-200"
                placeholder="Enter time" />
              </div>
            </div>
            {#if errorObj.endTime}
              <div class="parsley-errors-list">{errorObj.endTime}</div>
              <!-- content here -->
            {/if}
          </div>

          <div class="form-group col-md-6">
            <label class="d-block">Faculty <span class="required-field"> *</span></label>
            <select
              style="width:100%;"
              bind:this={elemFaculty}
              bind:value={batchTimingObj.facultyUserId}>
              <option value="" selected>Select Faculty</option>
            </select>
            {#if errorObj.facultyUserId}
              <div class="parsley-errors-list">{errorObj.facultyUserId}</div>
              <!-- content here -->
            {/if}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        {#if classDuration}<span>Class Duration : {classDuration}</span>{/if}
        <button
          type="button"
          class="btn btn-secondary tx-12"
          data-dismiss="modal">
          <i data-feather="x" class="wd-10 mg-r-5" />CLOSE</button>
        <button
          type="button"
          on:click={onKeyPress}
          class="btn btn-primary tx-13"
          disabled={isLoading}>
          <i data-feather="save" class="wd-10 mg-r-5" />
          <ButtonLoader {isLoading} btnText="Save" />
        </button>
      </div>
    </div>
  </div>
</div>
