<script>
    import { Router, links , Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import ButtonLoader from "../utils/button_loader.svelte"
    import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    
    export let lectureId

    let demoDataTable;
    let viewLectureAttendace;
    let emptyState = false;
    
    function redrawAttendanceTable() {
        if(viewLectureAttendace) { viewLectureAttendace.draw() } 
    }

    function getFilteredOrder(data){
        var sort 
        try {
            
            if(Boolean(data)){
            let index = data.order[0].column
            let dir = data.order[0].dir

            sort = {
                name: data.columns[index].data,
                dir: dir
            }
            }

        } catch (error) {
            
        }
        return sort
    }

    function bindDatatable (){
        viewLectureAttendace = _$(demoDataTable).DataTable({
            stateSave: true,
            "stateSaveCallback": function(settings,data) {
                localStorage.setItem(  window.location.pathname, JSON.stringify(data) )
            },
            "stateLoadCallback": function(settings) {
                return JSON.parse( localStorage.getItem(  window.location.pathname ) )
            },

            "searching": false,
            "bLengthChange": false,
            responsive:true,
            // "order": [[ 3, "desc" ]],
            language: {
                processing: "",
                lengthMenu: '_MENU_ attendance/page',
            },
            ajax: function (data, callback, settings) {
                
            _$.ajax({
                url: API_URL,
                method: 'post',
                data: {
                    action: 'zoomAttendance.listLectureAttendance',
                    accessToken: localStorage.getItem("accessToken"),
                    data: {
                        start: data.start,
                        length: data.length,
                        search: data.search.value,
                        sort: getFilteredOrder(data),
                        lectureId,
                    }
                },
                success: function (resData) {
                    var result = []
                    if (resData.status) {
                        var paginationData = resData.data;
                        var mappedData = paginationData.data.map(function(aUser) {

                            return {...aUser}

                        })
                        console.log("mappedData", mappedData);
                        result = {
                            recordsTotal: Number(paginationData.count),
                            recordsFiltered: Number(paginationData.count),
                            data: mappedData
                        }
                        if (!resData.data.length) {
                            emptyState = true
                        }
                        callback(result);
                    }
                    else {
                      emptyState = true
                  }
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) { 
                    emptyState = true
                }
            })
            },
            columns: [
            { 
                data: 'name',
                defaultContent : '<div class="text-center">-</div>',
                render: (data,type,full) => {
                     let image;
                     // if image found display profilePic avatar
                     image = `<img src="${
                     full.usersInfo[0]?.profilePic ? full.usersInfo[0]?.profilePic : "/images/man.png"
                        }" class="avatar mg-r-15" alt="${full.usersInfo[0]?.name[0].toUpperCase()}">`;     
                        return `
                            <div style = "cursor:pointer" class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 btn-edit-enquiry" enquiry-id="${full._id}">
                                 ${image}
                                 <div class="wd-80p bg-transparent">
                            <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 tx-capitalize bg-transparent btn-edit-enquiry" enquiry-id="${full._id}">${
                             full.usersInfo[0]?.name || "-"
                            }</h6>
                        <span class="d-block tx-11 text-muted bg-transparent">
                        <i data-feather="phone"></i> 
                        ${full.usersInfo[0]?.mobileNo || "-"}
                       </span>
                       
                        </div>
                      </div>`;

                    }
            },
            { 
                data: 'createdOn', 
                render: function (data, a, b, c) {
                    var _dateStr = '<div class="text-center">-</div>'
                    data = new Date(data)
                        if(data && data.toString().toLowerCase() != 'invalid date') {

                            _dateStr = `
                                <span> ${data.toLocaleDateString('en-GB')}, ${data.toLocaleTimeString()} </span>
                            `
                        }
                    
                    return _dateStr
                    
                } 
            },
            // { data: '_id' , render: function (data, a, b, c) {
            //         return `
            //         <div class="d-md-flex flex-row justify-content-center">
            //             <a href="#" class="delete mg-r-5 btn-delete-enquiry" enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete enquiry"><i class="wd-20 mg-r-5" data-feather="trash"></i></a>
            //             <a href="#" class="delete mg-r-5 btn-resotre-enquiry" enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Restore enquiry"><i class="wd-20 mg-r-5" data-feather="upload"></i></a>
            //         </div>
            //         `;
            // }},

            ],
            "drawCallback": function () {
                feather.replace({width: "1em", height: "1em"})
            _$('.btn-delete-enquiry').unbind('click').click(function (e) {
                e.preventDefault();
                var enquiryId = _$(this).attr("enquiry-id");
            })
            _$('.btn-resotre-enquiry').unbind('click').click(function (e) {
                e.preventDefault();
                var enquiryId = _$(this).attr("enquiry-id");
            })
            },
            "processing": true,
            "serverSide": true,                
            });


    }

    onMount(async ()=>{
        feather.replace();
        await bindDatatable();
    })
</script>
<div class="content-body fadeIn">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/schedule">Schedule</a></li>
                    <li class="breadcrumb-item active" aria-current="page">View Attendance</li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">Attendance of zoom lecture</h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">List of attendance who have attended the lecture.</p>
        </div>
        <hr class="full-width">
    </div>
    <div class="col-sm-12">
            <div class="mg-b-25 mg-t-15">
                <div class="df-example demo-table">
                    <table bind:this={demoDataTable} class="table mg-b-0" style="width: 100%;">
                        <thead>
                            <tr>
                                <th class="">Student Name</th>
                                <th class="">Student Joined On</th>
                                <!-- <th class="" style="text-align: center; width: 10%;">Actions </th> -->
                            </tr>
                        </thead>
                        <tbody></tbody>
                    </table>
                    <!-- {#if emptyState }
                        <div class="empty-state-img">
                            <img src="/images/empty.svg" style="width: 25%;" alt="empty" />
                            <p class="tx-bold"> No Student Attended yet </p>
                        </div>
                    {/if} -->
                </div>
              </div>
    </div>
</div>




