<script>

import {onMount} from 'svelte'


let logoURL = ""

    async function fetchOrgLogo() {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var payload = JSON.stringify({
        action: "settings.fetchOrgInfo",
        accessToken: localStorage.getItem("accessToken"),
        data: {},
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payload,
      };
      try {
        var resObj = await fetch(API_URL, requestOptions);
        var response = await resObj.json();
        var imageURL = "https:" + response.data.LOGO_URL
        console.log("url is ",imageURL)
        logoURL= imageURL;
      } catch (error) {
        console.error("error", error);
        return { error: error };
      }
    }

    onMount( async () => {
        fetchOrgLogo()
    })
   

</script>

<div class="d-block row invoice-box-footer bottom-footer">
  <h5> Print Preview</h5>
  
</div>

<div class="invoice-box">
    <table cellpadding="0" cellspacing="0">
        <tr class="top">
            <td colspan="2">
                <table>
                    <tr>
                        <td class="title">
                            <img src={logoURL} alt = "org log" style="width:40%; max-width:200px;">
                        </td>

                        <td>
                            Date: January 1, 2015<br>

                        </td>
                    </tr>
                </table>
            </td>
        </tr>

        <tr class="information">
            <td colspan="2">
                <table>
                    <tr>
                        <td>
                            Receipt ID : 123<br>
                            Batch Name : SBI BATCH<br>
                            Student ID : 35168484343843
                        </td>

                        <td>
                            Ketty Perry<br>
                            8989898989<br>
                            john@example.com
                        </td>
                    </tr>
                </table>
            </td>
        </tr>

        <tr class="heading">
            <td>
                Payment Method
            </td>

            <td>
                Status
            </td>
        </tr>

        <tr class="details">
            <td>
                Check
            </td>

            <td>
                PAID
            </td>
        </tr>

        <tr class="heading">
            <td>
                Installment Details </td>

            <td>
                &nbsp;&nbsp;
            </td>
        </tr>

        <tr class="item">
            <td>
                Website design
            </td>

            <td>
                &#8377;300.00
            </td>
        </tr>

        <tr class="item">
            <td>
                Hosting (3 months)
            </td>

            <td>
                &#8377;75.00
            </td>
        </tr>

        <tr class="item last">
            <td>
                Domain name (1 year)
            </td>

            <td>
                &#8377;10.00
            </td>
        </tr>

        <tr class="total">
            <td></td>

            <td>
                Total: &#8377;385.00
            </td>
        </tr>
    </table>
  
</div>

<div class="d-block row invoice-box-footer bottom-footer">
    <button x class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-5 offset-sm-5">
        <i data-feather="printer" class="wd-10 mg-r-5"></i> Print
    </button>
    <button class="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-r-5">
        <i data-feather="x" class="wd-10 mg-r-5"></i> Close
    </button>
  
</div>



<style>
    .bottom-footer{
        align-items: center;
        align-content: center;
    }
    .invoice-box {
        max-width: 800px;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        font-size: 16px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #555;
    }
    .invoice-box-footer {
        max-width: 100%;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        font-size: 16px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #555;
    }

    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
    }

    .invoice-box table td {
        padding: 5px;
        vertical-align: top;
    }

    .invoice-box table tr td:nth-child(2) {
        text-align: right;
    }

    .invoice-box table tr.top table td {
        padding-bottom: 20px;
    }

    .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
    }

    .invoice-box table tr.information table td {
        padding-bottom: 40px;
    }

    .invoice-box table tr.heading td {
        background: #eee;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
    }

    .invoice-box table tr.details td {
        padding-bottom: 20px;
    }

    .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
    }

    .invoice-box table tr.item.last td {
        border-bottom: none;
    }

    .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
        }

        .invoice-box table tr.information table td {
            width: 100%;
            display: block;
            text-align: center;
        }
    }

    /** RTL **/
    .rtl {
        direction: rtl;
        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    }

    .rtl table {
        text-align: right;
    }

    .rtl table tr td:nth-child(2) {
        text-align: left;
    }
</style>
