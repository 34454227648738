<script>
  import { links, link } from "svelte-routing";
  import { onMount, tick, afterUpdate } from "svelte";
  import { activeMenu } from "../../stores";
  import Empty from "../empty/empty.svelte";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js" 

  let isLoadingList = true;
  let isLoading = false;
  let addNewClassModal;
  const classObj = { name: "" };
  let errorObj = {};
  let displayText = "Class List";
  let classesList = { data: [] };
  let search = { value: "" };
  const pageSize = 100;

  async function api_delete(data) {

    let action = "instituteClass.removeInstituteClass";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_list(data) {

    let action = "instituteClass.fetchInstituteClassList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_getbyid(data) {

    let action = "instituteClass.fetchInstituteClass";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_save(data) {

    let action = "instituteClass.saveInstituteClass";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function showModal() {
    _$(addNewClassModal).modal("show");
    _$(".first-input").focus();
    clearFields();
    await tick();
  }

  async function hideModal() {
    _$(addNewClassModal).modal("hide");
    clearFields();
  }

  function validate(name) {
    //   clear previous validations before validating anew
    clearValidations();

    if(!name) {
      errorObj.name = "Class name can not be empty";
      return false
    }

    var regexpStartingWithAlphabet = new RegExp(`^[A-Za-z0-9]`);

    name = name.trim();

   if (!name.match(regexpStartingWithAlphabet)) {

      errorObj.name = "Name must begin with A-Z or a-z characters";
    } else if (name.length < 3) {

      errorObj.name = "Name should be minimum 3 characters long";
    } 

    if (Object.values(errorObj).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async function submitForm(event) {
    event.preventDefault();

    if (isLoading) return

    const isValid = validate(classObj.name);
    
    if(!isValid) return


      let data = { name: classObj.name }
      if (classObj._id) data._id = classObj._id;

      isLoading = true;
      let result = await api_save(data);
      isLoading = false;
      await tick();

      if (result.status) {
        pSuccess(null, result.data);
        hideModal();
        reload();
      } else {
        pError("Error!", result.errMsg)
      }
        
      
    
  }

  async function clearFields() {
    // form fields
    if (classObj._id) delete classObj._id;
    classObj.name = "";
    await clearValidations();
  }

  async function clearValidations() {
    errorObj = {};
    await tick();
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    // if (sortOrder.column === 1) {
    //   sort.name = order;
    // } else if (sortOrder.column === 2) {
    //   sort.createdOn = order;
    // } else {
    //   sort.seqId = order;
    // }
    sort.seqId = order;
    return sort;
  }

  async function fetchCourseList() {
    let data = {
      start: 0,
      length: pageSize,
      search,
      sort: parseSorts({ 0: "dsc" }),
    };
    console.info(data);
    // fetch call
    isLoadingList = true;
    await tick();
    let response = await api_list(data);
    console.info({ response });
    if (response.status) {
      var paginationData = response.data;
      classesList = {
        recordsTotal: Number(paginationData.recordsTotal),
        recordsFiltered: Number(paginationData.recordsFiltered),
        data: paginationData.data,
      };
    } else {
      console.error("something went wrong");
      classesList = {
        recordsTotal: 0,
        recordsFiltered: 0,
        data: [],
      };
    }
    isLoadingList = false;
    await tick();
  }

  async function editClass(_id) {
    console.log("edit class>>>")
    await showModal();
    let response = await api_getbyid({ _id });
    if (response.error) {
      pError(null, "Something went wrong");
    } else {
      classObj.name = response.data.name;
      classObj._id = response.data._id;
    }
  }

  function deleteCourse(courseId) {
    pConfirm(
      "Delete Class?",
      "Are you sure you want to delete this classes?",
      async function () {
        //   delete api
        let result = await api_delete({ _id: courseId });
        if (result.status) {
          pSuccess(null, result.data);
          await fetchCourseList();
        } else if (result.errMsg) {
          pError(null, result.errMsg);
        } else if (result.error) {
          pError(null, result.error);
        } else {
          pError(null, "Something went wrong");
        }
      }
    );
  }

  async function reload() {
    await fetchCourseList();
    feather.replace({ width: "1em", height: "1em" });
  }

  function bindModalAnimations() {
    // new classes modal
    _$(addNewClassModal).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
    });

    _$(addNewClassModal).on("hidden.bs.modal", function (e) {
      _$(this).removeClass("effect-scale");
    });
  }

  function init() {
    activeMenu.set("class");
    bindModalAnimations();
    reload();
  }

  onMount(init);

  afterUpdate(function () {
    feather.replace({ width: "1em", height: "1em" });
  });
</script>

<style>
  /* .long-name-wrap {
    word-break: break-all;
    overflow-wrap: break-word;
    min-widht: 90%
    max-widht: 90%
  } */

  .classes-cards-wrap {
    flex-wrap: wrap;
  }

  .no-select {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
  }
</style>

<div class="content-body fadeIn">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20
      mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {displayText}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">{displayText}</h4>
    </div>
    <div class="d-inline-block">
      <nav use:links class="d-inline-block">
        <div class="d-inline-block">
          <div class="search-form">
            <input type="search" class="form-control" placeholder="Class name" bind:value={search.value}
              on:input={async (event)=> await fetchCourseList()} />
            <button class="btn" on:click={async (event)=> await fetchCourseList()}><i data-feather="search" /></button>
          </div>
        </div>
        <div class="d-inline-block">
          {#if MODULES.hasAccess(MODULES.ADD_CLASSES)}
          <button class="btn pd-x-15 pd-y-8 btn-primary btn-uppercase mg-l-5 btn-sm" on:click={showModal}><i
              data-feather="plus" class="wd-10 mg-r-5" />
            Add new Class
          </button>
          {/if}
        </div>
      </nav>
    </div>
  </div>
  <div class="animated scale">
    {#if isLoadingList}
    <div class="row row-xs mg-b-20">
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
    </div>
    {:else if classesList && classesList.data && classesList.data.length}
    <div class="row row-xs mg-b-20">
      {#each classesList.data as classes (classes._id)}
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="media media-folder">
          <div class="avatar avatar-sm">
            <div class="avatar-initial rounded-circle bg-white bd bd-1 bd-primary tx-capitalize tx-primary">
              {classes.name[0]}
            </div>
          </div>
          <div class="media-body">
            <h6>
              <a href="javascript:void(0)" class="link-02 tx-capitalize">{classes.name}</a>
            </h6>
          </div>
          {#if MODULES.hasAccess(MODULES.EDIT_CLASSES) || MODULES.hasAccess(MODULES.DELETE_CLASSES)}
          <div class="dropdown-file">
            <a href="" class="dropdown-link" data-toggle="dropdown">
              <i data-feather="more-vertical" /></a>
            <nav class="dropdown-menu dropdown-menu-right" use:links>
              {#if MODULES.hasAccess(MODULES.EDIT_CLASSES)}
              <a href="javascript:void(0)" class="dropdown-item details" on:click={()=> editClass(classes._id)}><i
                  data-feather="edit" />Edit</a>
              {/if}
              {#if MODULES.hasAccess(MODULES.DELETE_CLASSES)}
              <a href="javascript:void(0)" on:click={()=> deleteCourse(classes._id)}
                class="dropdown-item details"><i data-feather="trash" />Delete</a>
              {/if}
            </nav>
          </div>
          {/if}
        </div>
      </div>
      {/each}
    </div>
    {:else}
    <Empty mode={search.value ? 'search' : 'empty' } />
    {/if}
  </div>
</div>

<!-- Modal -->
<div class="modal effect-scale" bind:this={addNewClassModal} id="modal-new-batch" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mx-wd-sm-650" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header pd-b-0">
        <div class="media align-items-center pd-12">
          <span class="tx-color-04"><i data-feather="package" class="wd-60 ht-60" /></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">
              {classObj._id ? 'Edit class details' : 'Classes'}
            </h4>
            <p class="tx-13 tx-color-03 mg-b-0">{classObj._id? 'Create new classes.': 'Update previous class name.' }</p>
          </div>

        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span><br />
        </button>
      </div>
      <div class="modal-body">
        <div class="row no-gutters pd-12">
          <div class="col-12 bg-white rounded-right">
            <div class=" tx-color-02">
              <div class="form-group">
                <label class="d-block">Name
                  <span class="required-field">*</span></label>
                <input bind:value={classObj.name} on:keyup={(e)=> e.key === 'Enter' && submitForm(e)}
                type="text"
                class="form-control first-input tx-capitalize"
                id="classes-name"
                class:parsley-error={errorObj.name}
                placeholder="Enter class name" />
                {#if errorObj.name}
                <div class="parsley-errors-list">{errorObj.name}</div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer pd-t-0">
        <div class="col-12 d-flex justify-content-end  pd-12">
          <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CANCEL <i data-feather="x"
              class="wd-10 mg-l-5"></i></button>
          <button type="button" disabled={isLoading} on:click={submitForm}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <ButtonLoader {isLoading} btnText="Proceed" />
            <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
