<script>
    import { createEventDispatcher, onMount, tick } from "svelte";
    import { links } from "svelte-routing";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import PrintReceiptCom from "../receipt/BatchwisePrintReceipt.svelte";
    import { userData } from "../../stores";
    import ButtonLoader from "../../utils/button_loader.svelte";
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import { API_URL } from "../../../config.js"

    const dispatch = createEventDispatcher();

    import BatchFee from "./batchFee.svelte";
    export let studentId;
    let togglePrintReceiptModal = false;

    let isLoading = false;
    let paymentCountsObj = {
        totalAgreed: 0,
        totalPaid: 0,
        balance: 0,
    };
    let batchwisePaymentCountsObj = {
        totalAgreed: 0,
        totalPaid: 0,
        balance: 0,
    };
    let paymentList;
    let selectedBatchIdArr = [];
    let selectAllChecked = false;
    let batchList = [];
    let paidBatchList = [];
    let convertToINR = function (input) {
        let amount = Number(input);
        return amount.toLocaleString("en-IN", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
        });
    };
    let localUserData = {
        fullName: "...",
        role: "...",
        avatar: "/images/man.png",
        access: {
            home: false,
            students: false,
            settings: false,
        },
    };
    let allPaidBatches = new Set();

    let totalAgreedFees = 0;
    let totalPaidFees = 0;
    let balanceAmount = 0;
    let balanceAmountWithInstalment = 0;
    const unsubscribe = userData.subscribe((value) => {
        localUserData = value;
        // roleIsTelecaller = value.role == 'telecaller';
    });

    let renderBatchList = async function () {
        let batchesListObj = await api_handleApiCalls(
            "students.getAssignedBatches",
            { _id: studentId }
        );
        batchList = batchesListObj.data;

        paidBatchList = batchList.filter((item) => item.batch.isFree != "yes");
        // console.log("paidBatchList:::",paidBatchList)

        if (batchList && batchList.length > 0) {
            renderTotalPaymentCounts({ studentId: studentId });
        }
    };

    let renderTotalPaymentCounts = async function (data) {
        let paymentRes = await api_handleApiCalls(
            "payment.getStudentPaymentCounts",
            data
        );
        // console.log(":paymentRes, ", paymentRes)
        if (paymentRes.status) {
            paymentCountsObj = paymentRes.data;
        }
    };

    onMount(async () => {
        feather.replace();
        dispatch("setActiveTab", {
            tabName: "Fees",
        });
        renderBatchList();
    });

    function refreshData() {
        // alert("refresj");
        renderBatchList();
    }

    function onCLickSelectAll(e) {
        e.stopPropagation();
        selectAllChecked = e.target.checked;
    }

    function onprintSelectedBatchReceipts(e) {
        selectedBatchIdArr = e.detail.selectedBatches;
    }

    async function onClickPrintReceipt() {
        // for(let i=0;i<selectedBatchIdArr.length;i++){
        //     let batchId = selectedBatchIdArr[i]

        //    let  paidBatch =  paidBatchList.find(item => {
        //         if(item.batchId == batchId) return item
        //     })
        //     totalAgreedFees += paidBatch.agreedFees
        //     // totalAgreedFees += paidBatch
        //     console.log("paidBatch",paidBatch)
        // }

        // console.log("totalAgreedFees::",totalAgreedFees)
        if (!selectedBatchIdArr.length >= 1) return pError("Error", "Select Batch first ")

        getStudentPaymentByBatchIds();
        let data = {
            studentId: studentId,
            batchIdArr: selectedBatchIdArr,
        };
        renderBatchwisePaymentCounts(data);
        await tick();
        togglePrintReceiptModal = true;
    }
    let renderBatchwisePaymentCounts = async function (data) {
        let paymentRes = await api_handleApiCalls(
            "payment.getPaymentCountByBatchId",
            data
        );
        // console.log(":paymentRes, ", paymentRes)
        if (paymentRes.status) {
            batchwisePaymentCountsObj = paymentRes.data;
        }
    };
    let getStudentPaymentByBatchIds = async function () {
        let paymentListData = await api_handleApiCalls(
            "payment.getStudentPaymentByBatchId",
            { studentId: studentId, batchIdArr: selectedBatchIdArr }
        );
        if (paymentListData.status) {
            paymentList = paymentListData.data
                .map(function (aPayment) {
                    return {
                        _id: aPayment._id,
                        studentId: aPayment.studentId,
                        batchId: aPayment.batchId,
                        paymentType: aPayment.paymentType,
                        batchName: aPayment.batchName,
                        installmentAmount: aPayment.installmentAmount,
                        paidAmount: aPayment.paidAmount,
                        receiptNo: aPayment.receiptNo,
                        paymentDate: aPayment.paymentDate,
                        freezePayment: aPayment.freezePayment,
                        freezePaymentDate: aPayment.freezePaymentDate,
                        chequeNo: aPayment.chequeNo,
                        bankName: aPayment.bankName,
                        ddNo: aPayment.ddNo,
                        refNo: aPayment.refNo,
                        upiNo: aPayment.upiNo,
                        cardNo: aPayment.cardNo,
                        receiptId: aPayment.receiptId || "",
                        branchId: aPayment.branchId || "",
                        prefix: aPayment.prefix || "",
                        remark: aPayment.remark || "",
                        sequence: aPayment.sequence || "",
                        isRefunded: aPayment.isRefunded || false,
                        className: aPayment.className
                    };
                })

                .sort(function (aPayment, anotherPayment) {
                    return (
                        new Date(
                            aPayment.freezePaymentDate
                                ? aPayment.paymentDate
                                : aPayment.paymentDate
                        ).getTime() -
                        new Date(
                            anotherPayment.freezePaymentDate
                                ? anotherPayment.paymentDate
                                : anotherPayment.paymentDate
                        ).getTime()
                    );
                });
            await tick();
            // feather.replace();
            // console.log("paymentList:::",paymentList)
        } else {
            console.error(paymentListData.errMsg);
        }
        // updateTotalPaymentList();
    };
    async function selectBatch(id, checked) {
        // console.log("id::", id, checked);

        if (checked) {
            allPaidBatches.add(id);
        } else {
            allPaidBatches.delete(id);
        }
        _$('#select_all_batches').prop("checked", false);

        allPaidBatches = allPaidBatches;
        // console.log("allPaidBatches:::", allPaidBatches);

        selectedBatchIdArr = Array.from(allPaidBatches);
        // console.log("selectedBatches",selectedBatches)
        //         dispatch('printSelectedBatchReceipts', {
        //             selectedBatches: selectedBatches
        //   })
        await tick();
    }
    $: selectAllChecked, checkSelectAllChecked(selectAllChecked);

    function checkSelectAllChecked(checked) {
        if (checked) selectAllUnselect(selectAllChecked, batchList);
        else selectAllUnselect(selectAllChecked, batchList);
    }
    const selectAllUnselect = async (checked, selectedArr) => {
        selectedArr = selectedArr.filter((item) => item.batch.isFree != "yes");
        // console.log("selectedArr::",selectedArr)
        if (checked) {
            selectedArr.forEach((obj) => allPaidBatches.add(obj.batch._id));
        } else {
            selectedArr.forEach((obj) => allPaidBatches.delete(obj.batch._id));
        }
        allPaidBatches = allPaidBatches;
        selectedBatchIdArr = Array.from(allPaidBatches);
        // console.log("selectedBatches",selectedBatches)
        // dispatch("printSelectedBatchReceipts", {
        //     selectedBatches: selectedBatches,
        // });
        // console.log("allPaidBatches::",allPaidBatches)
        await tick();
        // console.log("checked::",checked)
        makeCheckAll(checked);
    };
    const checkIfAllSelected = () => {
        let selectedArr = _$(".select_batch")
            .toArray()
            .filter((element) => {
                let elem_checkbox = _$(element);
                let batchId = elem_checkbox.attr("batch-id");
                return allPaidBatches.has(batchId);
            });

        if (selectedArr.length == batchList.length && batchList.length != 0) _$('#select_all_batches').prop("checked", true);

        else _$('#select_all_batches').prop("checked", false);
    };
    const makeCheckAll = (all) => {
        _$(".select_batch")
            .toArray()
            .map((element) => {
                let elem_checkbox = _$(element);
                let batchId = elem_checkbox.attr("batch-id");
                // console.log("batchId>>>>",batchId)
                if (all) elem_checkbox.prop("checked", true);
                else elem_checkbox.prop("checked", false);
            });
    };

    function closeReceiptModal() {
        (balanceAmount = 0), (totalAgreedFees = 0), (totalPaidFees = 0);
        togglePrintReceiptModal = false;
    }
</script>

{#if batchList && batchList.length}
<div class="d-flex justify-content-between mg-b-20">
    <div>
        <p class="tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5">
            Total Agreed Fees
        </p>
        <h3 class="tx-26 tx-normal tx-rubik tx-spacing--2 mg-b-5">
            {convertToINR(paymentCountsObj.totalAgreed)}
        </h3>
    </div>

    <div>
        <p class="tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5">
            Total Paid Fees
        </p>
        <h3 class="tx-26 tx-normal tx-rubik tx-spacing--2 mg-b-5">
            {convertToINR(paymentCountsObj.totalPaid)}
        </h3>
    </div>

    <div>
        <p class="tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5">
            Total Balance
        </p>
        <h3 class="tx-26 tx-normal tx-rubik tx-spacing--2 mg-b-5">
            {convertToINR(paymentCountsObj.balance)}
        </h3>
    </div>
</div>
{/if}
<div class="d-flex justify-content-between align-items-center">
    <div class="d-flex justify-content-center align-items-center ">
        <input type="checkbox" id="select_all_batches" name="selectAllBatch" on:change={(e)=> onCLickSelectAll(e)}
        />
        <label for="select_all_batches" class="mg-b-0 mg-l-8">Select All</label>
    </div>
    <div class="d-flex justify-content-right ">
        <button disabled={isLoading} class="btn btn-primary btn-sm pd-x-15 mg-b-10" on:click={onClickPrintReceipt}>
            <ButtonLoader {isLoading} btnText="Print All Receipt" />
        </button>
    </div>
</div>
{#each batchList as aBatch}
{#if aBatch.batch.isFree != "yes"}
<div class="d-flex align-items-center ">
    <input type="checkbox" id="select_batch" class="select_batch mg-r-1 mg-b-1" name="selectBatch"
        batch-id={aBatch.batch._id} on:change={(e)=> selectBatch(aBatch.batch._id, e.target.checked)}
    />
    <label for="select_batch" class="mg-b-1 mg-l-8 ">Select this batch</label>
</div>

{/if}
<BatchFee on:refreshData={refreshData} {aBatch} {studentId} {batchList} {allPaidBatches}
    on:printSelectedBatchReceipts={onprintSelectedBatchReceipts} {selectAllChecked} />
{:else}
<div class="d-flex align-items-center justify-content-center " style="flex-direction:column;">
    <div class="" style="width:200px">
        <img src="/images/empty.svg" class="img-fluid" alt="No Result" />
        <p class="tx-bold mg-b-0" style="text-align:center;">
            No Payments Available.
        </p>
    </div>
    <div class="" style="text-align:center;">
        Click <a href="/dashboard/students/info/{studentId}/batch-info" use:links class="">here</a> to assign a batch
        and enable payments
    </div>
</div>
{/each}

{#if togglePrintReceiptModal}
<PrintReceiptCom {studentId} {selectedBatchIdArr} totalAgreedFees={batchwisePaymentCountsObj.totalAgreed}
    totalPaid={batchwisePaymentCountsObj.totalPaid} balance={batchwisePaymentCountsObj.balance}
    onModalClose={closeReceiptModal} {localUserData} {paymentList} />
{/if}
