<script>
  import { onMount, tick } from "svelte";
  import { links } from "svelte-routing";
  import { activeMenu } from "../stores";
  import { userData } from "../stores";
  import ButtonLoader from "../utils/button_loader.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import MODULES from "../utils/modules.js"
  import { API_URL } from "../../config.js"
  export let from;
  // let elem_ReportedQuestionsCmp;
  let userIdArr;
  let action;
  let emptyState = false;
  let roleIsOrgAdmin;
  let elem_report_type, elem_report_to, elem_report_by, elem_created_by;
  let isLoading = false;
  let disabled;
  let demoDataTable;
  let testDataTable;
  let modelId = 0;
  let report = {
    reportType: "",
    report: "",
    date: new Date(),
    reportingTo: ""
  }
  let errorDetails = {};
  let addNewReportModal;
  let selectizeReportByInstance;
  let selectizeCreatedByInstance;
  let filterModalThis;
  let filterElemReportingTo;
  let filterElemCreatedBy;
  let filterByPrefix;
  let filterElemUser;
  let filterStartDate;
  let filterObj = {}


  function hideFilterModel(params) {
    _$("#filter-modal").modal("hide");
    assignmentObj.topic = "";
  }

  function showFilterModel(params) {
    _$("#filter-modal").modal("show");
  }


  let showHideFilterModal = () => {
    _$('#filter-modal').modal('show');
  }


  async function clearValidations() {
    errorDetails = {}
    await tick();
  }
  async function clearDateValidation() {
    delete errorDetails["date"];
    errorDetails = errorDetails;
    await tick();
  }

  async function clearReportValidation() {
    delete errorDetails["report"];
    errorDetails = errorDetails;
    await tick();
  }
  let toolbar = [
    [
      "NewPage",
      "Preview",
      "-",
      "Bold",
      "Italic",
      "Strike",
      "NumberedList",
      "BulletedList",
      "-",
      "Table",
      "HorizontalRule",
      "Styles",
      "Format",
      "Maximize",
      "Image"

    ]
  ]
  function validate(data) {
    clearValidations();
    if (data.date == null) {
      errorDetails.date = "Date should be selected";
    }
    // data.report = CKEDITOR.instances.report_editor.getData().trim();
    if (!Boolean(data.report)) {
      errorDetails.report = " Report Should not be empty";
    }
    if (Object.values(errorDetails).length > 0) {
      return false;
    } else {
      return true;
    }
  }
  let resetReportObject = function () {
    report = {
      reportingTo: "",
      reportType: "",
      report: "",
      date: null
    }
    CKEDITOR.instances.report_editor.setData(report.report);
    _$(elem_report_to)[0].selectize.setValue(report.reportingTo);
    _$(elem_report_type)[0].selectize.setValue(report.reportType);
  }

  let toggleTab = function (tabName) {
    if (tabName == 'my-reportings') {
      action = 'reports.reportsByMe';

    }
    else if (tabName == 'report-to-me') {
      action = 'reports.reportsForMe';

    }
    else if (tabName == 'all' && roleIsOrgAdmin)
      action = 'reports.fetchReports'

  }

  async function showModal() {
    modelId = 0;
    feather.replace();
    report = {
      report: "",
      date: null
    }
    _$(elem_report_to)[0].selectize.enable(report.reportingTo);
    _$(elem_report_type)[0].selectize.enable(report.reportType);
    CKEDITOR.instances.report_editor.setReadOnly(false);
    _$(addNewReportModal).modal("show");
    resetReportObject();
    await tick();
  }
  async function api_getReportById(_id) {

    let action = "reports.fetchSingleReport";
    var response = await api_handleApiCalls(action, { _id: _id });
    return response.data;

  }

  async function api_getReportForEdit(data) {
    let action = "reports.updateReport";
    var response = await api_handleApiCalls(action, data);
    if (response.errMsg) {
      PNotify.error({
        title: 'Error',
        text: response.errMsg,
        delay: 3000
      })
    } else {
      PNotify.success({
        title: 'success',
        text: "successfully updated a report",
        delay: 3000
      })
    }
    return response.data;
  }

  async function api_deleteReportByID(data) {
    let action = "reports.deleteReport";
    var response = await api_handleApiCalls(action, data);
    return response.data;
  }


  const unsubscribe = userData.subscribe(value => {
    roleIsOrgAdmin = value.role == "orgAdmin"
  });

  onMount(async () => {

    feather.replace();
    // console.log("mounting systemUser list");
    activeMenu.set("daily-reports");
    userIdArr = await api_ReportToList()
    unsubscribe();
    toggleTab('my-reportings')
    initDropdown();
    bindSelect2();
    filterDropDown();
    bindDatatable()
    CKEDITOR.replace("report_editor", {
      toolbar: toolbar,
      height: "150px"
    });
    bindModalAnimations();
    CKEDITOR.instances.report_editor.on('focus', function (event) {
      clearReportValidation();
    });
  })
  function bindModalAnimations() {
    // new report modal
    _$(addNewReportModal).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
    });

    _$(addNewReportModal).on("hidden.bs.modal", function (e) {
      _$(this).removeClass("effect-scale");
    });

  }
  let addNewReport = async function () {
    report = {
      reportingTo: "",
      reportType: "",
      report: "",
      date: null
    }
    _$(elem_report_to)[0].selectize.setValue("");
    _$(elem_report_type)[0].selectize.setValue("");
    _$(addNewReportModal).modal("show");

  }
  let onReportAdd = async function () {
    if (isLoading) return;
    isLoading = true;
    await tick()
    report.report = CKEDITOR.instances.report_editor.getData();
    let isValid = validate(report);
    if (!isValid) {
      isLoading = false;
      return false;
    }
    let res = await api_addReports(report)
    if (res.data?.insertedId) {
      selectedId = res.data.insertedId;
    }
    isLoading = false;
    testDataTable.ajax.reload();
    resetReportObject();
    _$(addNewReportModal).modal("hide");
  }
  async function api_addReports(data) {

    let action = "reports.addReport";
    var response = await api_handleApiCalls(action, data);
    if (response.errMsg) {
      PNotify.error({
        title: 'Error',
        text: response.errMsg,
        delay: 3000
      })
    } else {
      PNotify.success({
        title: 'success',
        text: "Daily report added successfully",
        delay: 3000
      })

    }
    return response;

  }
  let editPreviousReport = async function (report_id) {
    modelId = 2;
    var response = await api_getReportById(report_id);
    if (response && response.date) {
      report.date = convertDateObjToStr(response.date);
      report.report = response.report;
      report._id = response._id;
      report.reportingTo = response.reportingTo;
      report.reportType = response.reportType;
      _$(addNewReportModal).modal("show");
      _$(elem_report_to)[0].selectize.setValue(report.reportingTo);
      _$(elem_report_type)[0].selectize.setValue(report.reportType);
      CKEDITOR.instances.report_editor.setReadOnly(false);
      _$(elem_report_to)[0].selectize.enable(report.reportingTo);
      _$(elem_report_type)[0].selectize.enable(report.reportType);
      CKEDITOR.instances.report_editor.setData(report.report);

    }
    await tick();
    feather.replace();
  }
  let onReportEdit = async function () {
    if (isLoading) return;
    isLoading = true;
    await tick()
    report.report = CKEDITOR.instances.report_editor.getData();
    let isValid = await validate(report);
    if (!isValid) {
      isLoading = false;
      return false;
    }
    let res = await api_getReportForEdit(report);
    isLoading = false;
    testDataTable.ajax.reload();
    resetReportObject();
    _$(addNewReportModal).modal("hide");
  }

  let viewReport = async function (report_id) {
    var response = await api_getReportById(report_id);
    if (response && response.date) {
      report.date = convertDateObjToStr(response.date);
      report.report = response.report;
      report._id = response._id;
      report.reportingTo = response.reportingTo;
      report.reportType = response.reportType;
      _$(addNewReportModal).modal("show");
      modelId = 1;
      // console.log("console.log(modelId)===>", modelId);
      _$(elem_report_to)[0].selectize.setValue(report.reportingTo);
      _$(elem_report_type)[0].selectize.setValue(report.reportType);
      CKEDITOR.instances.report_editor.setData(report.report);
      CKEDITOR.instances.report_editor.setReadOnly(true);
      _$(elem_report_to)[0].selectize.disable(report.reportingTo);
      _$(elem_report_type)[0].selectize.disable(report.reportType);
    }
    await tick();
    feather.replace();
  }
  async function api_findReportToList(data) {

    let action = "systemUser.fetchSystemUserList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_ReportToList() {
    var resData = await api_handleApiCalls('systemUser.fetchSystemUserList', { noPagination: true })
    if (resData.status) {

      return resData.data.data
    } else {
      return []
    }
  }


  async function api_findReportTypeList(data) {

    let action = "reports.findReportType";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function bindSelect2() {


    selectizeReportByInstance = _$(filterElemReportingTo).selectize({
      preload: true,
      create: false,
      maxItems: 100,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "select staff",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: userIdArr,
    });

    selectizeCreatedByInstance = _$(filterElemCreatedBy).selectize({
      preload: true,
      create: false,
      maxItems: 100,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "select staff",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: userIdArr,
    });

  }


  function applyFilter() {
    testDataTable.ajax.reload();
  }

  function clearFilter() {

    selectizeReportByInstance[0].selectize.clear();
    selectizeCreatedByInstance[0].selectize.clear();
    filterStartDate = null
    testDataTable.ajax.reload();
  }

  async function filterDropDown() {

    var reportToList = await api_findReportToList({ start: 0, length: 100, sort: { name: 1 } });
    let reportToListArr = reportToList.data.data;

    _$(elem_report_by).selectize({
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "Reports assigned by",
      options: reportToListArr,
      create: false,
      items: [],
      onChange: function (value) {

      },
    });

    _$(elem_created_by).selectize({
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "Reports created by",
      options: reportToListArr,
      create: false,
      items: [],
      onChange: function (value) {

      },
    });

  }


  async function initDropdown() {
    // Put this code after you've included Selectize
    // but before any selectize fields are initialized
    var prevSetup = Selectize.prototype.setup;

    Selectize.prototype.setup = function () {
      prevSetup.call(this);

      // This property is set in native setup
      // Unless the source code changes, it should
      // work with any version
      // this.$control_input.prop("readonly", true);
    };
    var reportTypeList = await api_findReportTypeList({ start: 0, length: 100, sort: { reportType: 1 } });
    let reportTypeListArr = reportTypeList.data;
    // var reportTypeId = reportTypeList.data.reportType;
    _$(elem_report_type).selectize({
      maxItems: 1,
      valueField: "_id",
      labelField: "reportType",
      searchField: "reportType",
      options: reportTypeListArr,
      create: false,
      items: [],
      onChange: function (value) {
        // reportTypeId = value;
        report.reportType = value;
      },
    });
    var reportToList = await api_findReportToList({ start: 0, length: 100, sort: { name: 1 } });
    let reportToListArr = reportToList.data.data;
    // console.log('reportToListArr::::', reportToListArr);
    _$(elem_report_to).selectize({
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      options: reportToListArr,
      create: false,
      items: [],
      onChange: function (value) {
        report.reportingTo = value;
      },
    });

    Selectize.prototype.setup = prevSetup;
  }

  function getFilterData() {
    let date = filterStartDate;
    let createdByArr = _$(filterElemCreatedBy).val()
    let reportingToArr = _$(filterElemReportingTo).val()
    return {
      createdByArr,
      date,
      reportingToArr
    }
  }
  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 0) {
      sort.seqId = order;
    } else if (sortOrder.column === 1) {
      sort.reportType = order;
    } else if (sortOrder.column === 2) {
      sort.createdByUser = order;
    } else if (sortOrder.column === 3) {
      sort.reportingToName = order;
    } else if (sortOrder.column === 4) {
      sort.createdOn = order;
    } else {
      sort._id = order;
    }
    return sort;
  }
  function bindDatatable() {
    testDataTable = _$(demoDataTable).DataTable({
      stateSave: true,
      "stateSaveCallback": function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data))
      },
      "stateLoadCallback": function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname))
      },
      responsive: false,
      language: {
        processing: "",
        searchPlaceholder: 'Reporting Name',
        sSearch: '',
        lengthMenu: '_MENU_ Daily Staff Reporting/page',
      },

      ajax: function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "asc" });
        var filterObj = { ...getFilterData() }
        filterObj.start = data.start
        filterObj.length = data.length
        filterObj.search = data.search.value
        filterObj.draw = data.draw
        filterObj.sort = data.sort;

        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: action,
            accessToken: localStorage.getItem("accessToken"),
            data: filterObj
          },
          success: function (resData) {

            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              // console.log("paginationData",paginationData)
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data
              }
              if (!paginationData.data.length) {
                emptyState = true
              }
              else {
                emptyState = false
              }
              callback(result);
            }
            else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },
      columns: [
        {
          data: 'seqId', render: function (data, type, full) {
            return data;
          }
        },
        // {
        //   data: 'reportType', render: function (data, type, full) {
        //     return `
        //     <div style="width:auto;">
        //       ${data}
        //     </div>
        //   `;
        //   }
        // },
        {
          data: 'report', render: function (data, type, full) {


            return `
            <div class="btn-edit-test" report-id="${full._id}"  style="max-height: 38px !important; overflow:hidden;">
              ${data}...
            </div>
           
            <span class="d-block tx-12 text-muted bg-transparent">
                   Reporting Type : ${full.reportType}
            </span>
            
          `;
          }
        },
        {
          data: 'createdByUser', render: function (data, type, full) {
            return data;
          }
        },
        {
          data: 'reportingToName', render: function (data, type, full) {
            if (full.reportingToName) return data;
            else return "-"
          }
        },
        {
          data: 'date', render: function (data, type, full) {
            let date = convertDateObjToStr(data);
            date = date.split("-")
            date = date.reverse();
            date = date.join("-");
            return date;
          }
        },
        {
          data: '_id', render: function (data, type, full) {
            var edit = ""
            var del = ""
            if (MODULES.hasAccess(MODULES.EDIT_DAILY_STAFF_REPORTING) && !(MODULES.hasAccess(MODULES.DELETE_DAILY_STAFF_REPORTING))) {
              edit = `<a href="#" class="rename mg-r-5 btn-edit-test" report-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-15 mg-r-5" data-feather="edit"></i></a>`
            } else if (MODULES.hasAccess(MODULES.DELETE_DAILY_STAFF_REPORTING) && !(MODULES.hasAccess(MODULES.EDIT_DAILY_STAFF_REPORTING))) {
              del = ` <a href="#" class="rename mg-r-5 btn-delete-test" report-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete"><i class="wd-15 mg-r-5" data-feather="trash-2"></i></a>`
            } else if (!(MODULES.hasAccess(MODULES.DELETE_DAILY_STAFF_REPORTING)) && !(MODULES.hasAccess(MODULES.EDIT_DAILY_STAFF_REPORTING))) {
              edit = "-"
              del = ""
            } else {
              edit = `<a href="#" class="rename mg-r-5 btn-edit-test" report-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-15 mg-r-5" data-feather="edit"></i></a>`
              del = ` <a href="#" class="rename mg-r-5 btn-delete-test" report-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete"><i class="wd-15 mg-r-5" data-feather="trash-2"></i></a>`
            }
            return `
            <div class="d-md-flex flex-row justify-content-center">
              <a href="#" class="rename mg-r-5 btn-view-test" report-id="${data}" data-toggle="tooltip" data-placement="top" title="View"><i class="wd-15 mg-r-5" data-feather="eye"></i></a>
              ${edit}
              ${del}
            </div>
            `;

          }
        },

      ],
      "drawCallback": function () {
        feather.replace()
        _$('.btn-view-test').unbind('click').click(
          async function (e) {
            e.preventDefault();
            var reportId = _$(this).attr("report-id");
            await viewReport(reportId);
          })
        _$('.btn-edit-test').unbind('click').click(
          async function (e) {
            e.preventDefault();
            var reportId = _$(this).attr("report-id");
            await editPreviousReport(reportId);
          })
        _$('.btn-delete-test').unbind('click').click(function (e) {
          e.preventDefault();
          var reportId = _$(this).attr("report-id");
          const notice = PNotify.notice({
            title: "Delete Reporting",
            text: "Are you sure you want to delete this reporting ?",
            hide: false,
            closer: false,
            sticker: false,
            modules: new Map([
              ...PNotify.defaultModules,
              [PNotifyConfirm, { confirm: true }],
            ]),
          })


          notice.on("pnotify:confirm", async () => {
            try {
              var deleteReport = await api_deleteReportByID({ _id: reportId });
              if (deleteReport) {
                // console.log(deleteReport);
                PNotify.success({
                  title: 'Success',
                  text: "Reporting Deleted Successfully",
                  delay: 3000
                })
              } else {
                PNotify.error({
                  title: 'Error',
                  text: deleteReport.errMsg,
                  delay: 3000
                })
              }

            } catch (error) {
              console.error(error);
            }
            testDataTable.ajax.reload();
          })

        })
      },
      "processing": true,
      "serverSide": true,
    });
    _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });

  }
  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var dateString = [year, month, day].join("-");
    return dateString;
  }

</script>
<div class="content-body fadeIn">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20
        mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Daily Staff Reporting</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Daily Staff Reporting</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Add, review daily work of staff.</p>
    </div>
    <div class="d-block">
      <div class="d-sm-flex">
        {#if MODULES.hasAccess(MODULES.ADD_DAILY_STAFF_REPORTING)}
        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={showModal}>
          <i data-feather="plus" class="wd-10 mg-r-5"></i> Add Reportings
        </button>
        {/if}
        <button on:click={showHideFilterModal} class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"> <i
            data-feather="filter" class="mg-r-5"></i> Filters</button>

      </div>
    </div>
  </div>

  <hr class="full-width">
  <div data-label="Example" class="df-example ">
    <ul class="nav nav-line mg-b-15" id="myTab" role="tablist">
      {#if roleIsOrgAdmin}
      <li class="nav-item">
        <a class="nav-link active" id="my-reportings-tab" data-toggle="tab" href="javascript:void(0)" role="tab"
          aria-controls="my-reportings" aria-selected="false" on:click={()=> {
          toggleTab('my-reportings')
          testDataTable.ajax.reload();

          }}>My Reportings</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="in-review-tab" data-toggle="tab" href="javascript:void(0)" role="tab"
          aria-controls="in-review" aria-selected="false" on:click={()=> {
          toggleTab('report-to-me')
          testDataTable.ajax.reload();
          testDataTable.ajax.reload();

          }}>Reporting To Me</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="all-tasks-tab" data-toggle="tab" href="javascript:void(0)" role="tab"
          aria-controls="all-tasks" aria-selected="true" on:click={()=> {
          toggleTab('all')
          testDataTable.ajax.reload();


          }}>All</a>
      </li>
      {:else}
      <li class="nav-item">
        <a class="nav-link active" id="my-reportings-tab" data-toggle="tab" href="javascript:void(0)" role="tab"
          aria-controls="my-reportings" aria-selected="false" on:click={()=> {
          toggleTab('my-reportings')
          testDataTable.ajax.reload();


          }}>My Reportings</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="in-review-tab" data-toggle="tab" href="javascript:void(0)" role="tab"
          aria-controls="in-review" aria-selected="false" on:click={()=> {
          toggleTab('report-to-me')
          testDataTable.ajax.reload();

          }}>Reporting To Me</a>
      </li>
      {/if}
    </ul>
    <table bind:this={demoDataTable} class="table table-hover table-sm">
      <thead>
        <tr>
          <th class="wd-30" style="text-align: center;">#</th>
          <!-- <th>Reporting Type</th> -->
          <th class="wd-200">Reportings</th>
          <th class="wd-200">Created By</th>
          <th class="wd-200">Reporting To</th>
          <th class="wd-100">Date</th>
          <th class="wd-70" style="text-align: center;">Action</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    {#if emptyState }
    <div class="empty-state-img wd-200">
      <img src="/images/empty.svg" style="width: 200px" alt="empty" />
      <p class="tx-bold">No reports found </p>
    </div>
    {/if}
  </div>
</div>


<div class="modal effect-scale" bind:this={addNewReportModal} id="modal-new-batch" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered wd-sm-650" role="document">
    <div class="modal-content ">
      <div class="modal-body pd-0">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
          <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="media align-items-center">
            <span class="tx-color-03 d-none d-sm-block"><i data-feather="trello" class="wd-60 ht-60"></i></span>
            <div class="media-body mg-sm-l-20">
              {#if modelId==0}
              <h4 class=" tx-18 tx-sm-20 mg-b-2">
                Reporting
              </h4>
              <p class="tx-13 tx-color-03 mg-b-0">Add new reporting below</p>
              {:else if modelId==1}
              <h4 class=" tx-18 tx-sm-20 mg-b-2">
                View reporting
              </h4>
              <p class="tx-13 tx-color-03 mg-b-0">Full view of reporting below</p>
              {:else}
              <h4 class="tx-18 tx-sm-20 mg-b-2">
                Edit reporting
              </h4>
              <p class="tx-13 tx-color-03 mg-b-0">Edit the reporting below</p>
              {/if}

            </div>
          </div><!-- media -->
        </div><!-- modal-header -->
        <hr class="full-width">

        <!-- <div class="d-flex mg-">
          <span class="tx-color-04"><i data-feather="trello" class="wd-40 ht-40 stroke-wd-3 mg-b-20" /></span>
          {#if modelId==0}
           <h3 class=" mg-b-15 mg-md-b-20 mg-l-15">
            New reporting
           </h3>
          {:else if modelId==1}
           <h3 class=" mg-b-15 mg-md-b-20 mg-l-15">
            View reporting
           </h3>
           {:else}
           <h3 class=" mg-b-15 mg-md-b-20 mg-l-15">
            Edit reporting
           </h3>
          {/if}
          </div> -->
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>

        <div class="row no-gutters">
          <div class="col-12 bg-white rounded-right">
            <div class="ht-100p d-flex flex-column justify-content-center pd-l-25 pd-r-25 pd-b-25">

              <div class=" tx-color-02">
                <div class="form-group">
                  <label class="d-block">Date
                    <span class="required-field">*</span></label>
                  <input id="date" bind:value={report.date} on:keyup={(e)=> e.key === 'Enter' && onReportAdd(e)}
                  on:focus={clearDateValidation}
                  type="date"
                  onkeydown="return false"
                  class="form-control first-input"
                  max={convertDateObjToStr(new Date())}
                  class:parsley-error={errorDetails.date}
                  placeholder="Enter date" disabled={modelId==1}/>
                  {#if errorDetails.date}
                  <div class="parsley-errors-list">{errorDetails.date}</div>
                  {/if}
                  <label class="d-block mg-t-20">Reporting Type</label>
                  <select bind:this={elem_report_type} class="info slectizedd form-control" on:keyup={(e)=> e.key ===
                    'Enter' && onReportAdd(e)}
                    placeholder="Select Reporting type" disabled={modelId==1}/>
                    <label class="d-block mg-t-20">Reporting To</label>
                    <select bind:this={elem_report_to} class="info slectizedd form-control" on:keyup={(e)=> e.key ===
                      'Enter' && onReportAdd(e)}
                      placeholder="select Reporting to" disabled={modelId==1}/>

                      <div class="form-group align-items-center">
                        <label class="d-block mg-t-20 ">
                          Report Description
                          <span class="required-field">*</span>
                        </label>
                        <textarea rows={5} on:keyup={(e)=> e.key === 'Enter' && onReportAdd()}
                        on:input={(e) => {
                         answer = CKEDITOR.instances.report_editor.getData();
                       }}
                       name={'report_editor'}
                       class:parsley-error={errorDetails.report}
                       class="form-control rounded-5"
                       placeholder="Write your answer here"  />
                       {#if errorDetails.report}
                       <div class="parsley-errors-list">{errorDetails.report}</div>
                     {/if}  
                   </div>
                </div>
               
          </div>
              <div class=" d-flex justify-content-end">
                {#if modelId==0}
                <button
                type="button"
                class="btn btn-secondary tx-12"
                data-dismiss="modal">
                CANCEL
                <i data-feather="x" class="wd-10 mg-l-5"></i>
              </button>
               <button
                type="button"
               on:click={onReportAdd}
               disabled={isLoading}
                class="btn btn-sm pd-x-15 tx-12 btn-primary btn-uppercase mg-l-5">
                <ButtonLoader isLoading={isLoading} btnText="Save" />
                <i data-feather="save" class="wd-10 mg-l-5"></i>
               </button>
               {:else if modelId==1}
               <button
                type="button"
                class="btn btn-secondary tx-12"
                data-dismiss="modal">
                CLOSE
                <i data-feather="x" class="wd-10 mg-l-5"></i>
               </button>
               {:else}
               <button
               type="button"
               class="btn btn-secondary tx-12"
               data-dismiss="modal">
               CANCEL
               <i data-feather="x" class="wd-10 mg-l-5"></i>
               </button>
               <button
               type="button"
                on:click={onReportEdit}             
               class="btn btn-sm pd-x-15 tx-12 btn-primary btn-uppercase mg-l-5">
               <ButtonLoader isLoading={isLoading} btnText="Save"/>
               <i data-feather="save" class="wd-10 mg-l-5"></i>
               </button> 
               {/if}                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->


<!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
aria-labelledby="exampleModalLabel6" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
            <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </a>
            <div class="media align-items-center">
                <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter"
                        class="wd-60 ht-60"></i></span>
                <div class="media-body mg-sm-l-20">
                    <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                    <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
                </div>
            </div><!-- media -->
        </div><!-- modal-header -->

        <hr class="full-width">
        <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
          <div class="form-group">
            <label class="d-block">Created By:</label>
            <select disabled={isLoading} bind:this={filterElemCreatedBy} class="form-control select2" multiple = "multiple">
              <option>Created By</option>
            </select>
        </div>
        <div class="form-group">
          <label class="d-block">Reporting By:</label>
          <select disabled={isLoading} bind:this={filterElemReportingTo} class="form-control select2" multiple = "multiple">
            <option>Reporting By</option>
          </select>
        </div>
        
          <div class="form-group">
                <label class="d-block">Date:</label>
                <input type="date" onkeydown=" return false" bind:value={filterStartDate} class="form-control follow-up-date" placeholder="Student created on date"/>
            </div>
        
            <div class="d-md-flex justify-content-end mg-t-25">
                <button disabled={isLoading}
                    class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" on:click = {clearFilter} data-dismiss="modal">CLEAR
                    <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                <button disabled={isLoading} 
                    class="btn btn-sm pd-x-15 btn-primary mg-l-5" on:click={applyFilter} data-dismiss="modal">
                    <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                    <i data-feather="filter" class="wd-10 mg-l-5"></i>
                </button>
            </div>
        </div>

    </div>
</div>
</div>
<!-- Modal -->
