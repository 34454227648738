<script>
  import { onMount, tick } from "svelte";
  import { links } from "svelte-routing";
  import { activeMenu } from "../stores";
  import { userData } from "../stores";
  import ButtonLoader from "../utils/button_loader.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import { API_URL } from "../../config.js"

  export let from;

  let elem_report_type, elem_report_to;
  let emptyState = false;
  let isLoading = false;
  let disabled;
  let demoDataTable;
  let testDataTable;
  let modelId = 0;
  let report = {
    reportType: ""
  };
  let errorDetails = {};
  let addNewReportModal;
  async function clearValidations() {
    delete errorDetails["reportType"];
    errorDetails = errorDetails;
    await tick();
  }

  function validate(data) {
    clearValidations();
    if (data) {
      return true;
    } else {
      errorDetails.reportType = "Report type should not be empty";
    }
  }
  let resetReportObject = function () {
    report.reportType = "";
  }

  async function showModal() {
    modelId = 0;
    feather.replace();
    clearValidations();
    report.reportType = "";
    _$(addNewReportModal).modal("show");
    resetReportObject();
    await tick();
  }
  async function api_getReportById(_id) {

    let action = "questionsReported.getQuestionReportType";
    var response = await api_handleApiCalls(action, { _id: _id });
    return response.data;

  }

  async function api_getReportForEdit(data) {

    let action = "questionsReported.addQuestionReportType";
    var resObj = await api_handleApiCalls(action, data);

    if (resObj.errMsg) {
      PNotify.error({
        title: 'Error',
        text: resObj.errMsg,
        delay: 3000
      })
    } else {
      PNotify.success({
        title: 'success',
        text: "successfully updated a report",
        delay: 3000
      })

    }
    return resObj.data;
  }
  async function api_deleteReportByID(data) {

    let action = "questionsReported.deleteQuestionReportType";
    var response = await api_handleApiCalls(action, data);
    return response.data;

  }

  onMount(function () {
    feather.replace();
    console.log("mounting systemUser list");
    activeMenu.set("questionsreportedtype");
    bindDatatable()
    bindModalAnimations();
  })
  function bindModalAnimations() {
    // new report modal
    _$(addNewReportModal).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
    });

    _$(addNewReportModal).on("hidden.bs.modal", function (e) {
      _$(this).removeClass("effect-scale");
    });
  }
  let addNewReport = async function () {
    report.reportType = "";
    _$(addNewReportModal).modal("show");
  }
  let onReportAdd = async function () {
    feather.replace();
    if (isLoading) return;
    isLoading = true;
    await tick()
    console.log(report.reportType)
    let isValid = validate(report.reportType);
    if (!isValid) {
      isLoading = false;
      return false;
    }
    let res = await api_addReports(report.reportType)
    if (res.data?.insertedId) {
      selectedId = res.data.insertedId;
    }
    isLoading = false;
    testDataTable.ajax.reload();
    resetReportObject();
    _$(addNewReportModal).modal("hide");
  }
  async function api_addReports(data) {
    let response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({
        action: "questionsReported.addQuestionReportType",
        accessToken: localStorage.getItem("accessToken"),
        data: data,
      }),
    });
    let result = await response.json();
    console.log(result);
    if (result.errMsg) {
      PNotify.error({
        title: 'Error',
        text: result.errMsg,
        delay: 3000
      })
    } else {
      PNotify.success({
        title: 'success',
        text: "Report type added successfully",
        delay: 3000
      })

    }
    return result;
  }
  let editPreviousReport = async function (report_id) {
    modelId = 2;
    feather.replace();
    clearValidations();
    var response = await api_getReportById(report_id);
    if (response) {
      report = response
      _$(addNewReportModal).modal("show");
    }
    await tick();
    feather.replace();
  }

  let onReportEdit = async function () {
    feather.replace();
    if (isLoading) return;
    isLoading = true;
    await tick()
    let isValid = await validate(report.reportType);
    if (!isValid) {
      isLoading = false;
      return false;
    }
    let res = await api_getReportForEdit(report);
    isLoading = false;
    testDataTable.ajax.reload();
    resetReportObject();
    _$(addNewReportModal).modal("hide");
  }


  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.seqId = order;
    } else if (sortOrder.column === 2) {
      sort.reportType = order;
    } else {
      sort._id = order;
    }
    return sort;
  }

  function bindDatatable() {
    testDataTable = _$(demoDataTable).DataTable({
      stateSave: true,
      "stateSaveCallback": function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data))
      },
      "stateLoadCallback": function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname))
      },
      responsive: true,
      language: {
        processing: "",
        searchPlaceholder: 'Report type',
        sSearch: '',
        lengthMenu: '_MENU_ Reportings/page',
      },
      ajax: function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: 'questionsReported.fetchReportedQuestionsTypeList',
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: data.length,
              search: data.search.value,
              sort: data.sort
            }
          },
          success: function (resData) {

            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.recordsTotal),
                recordsFiltered: Number(paginationData.recordsFiltered),
                data: paginationData.data
              }
              if (!resData.data.data.length) {
                emptyState = true
              }
              callback(result);
            }
            else {
              console.log("Something went wrong");
              emptyState = true;
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },
      columns: [
        {
          data: 'seqId', render: function (data, type, full) {
            return data;


          }
        },
        {
          data: 'reportType', render: function (data, type, full) {

            return `
            <div class="btn-edit-test" report-id="${full._id}"  style="max-height: 38px !important; overflow:hidden; text-align:center;">
              ${data}
            </div>
           
          `;
          }
        },
        {
          data: '_id', render: function (data, type, full) {
            return `
            <div class="d-md-flex flex-row justify-content-center">
              <a href="#" class="rename mg-r-5 btn-edit-test" report-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>
              <a href="#" class="rename mg-r-5 btn-delete-test" report-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete"><i class="wd-20 mg-r-5" data-feather="trash-2"></i></a>
            </div>
            `;
          }
        },
      ],
      "drawCallback": function () {
        feather.replace()
        _$('.btn-edit-test').unbind('click').click(
          async function (e) {
            e.preventDefault();
            var reportId = _$(this).attr("report-id");
            await editPreviousReport(reportId);
          })
        _$('.btn-delete-test').unbind('click').click(function (e) {
          e.preventDefault();
          var reportId = _$(this).attr("report-id");
          const notice = PNotify.notice({
            title: "Delete Reporting",
            text: "Are you sure you want to delete this reporting ?",
            hide: false,
            closer: false,
            sticker: false,
            modules: new Map([
              ...PNotify.defaultModules,
              [PNotifyConfirm, { confirm: true }],
            ]),
          })

          notice.on("pnotify:confirm", async () => {
            try {
              var deleteReport = await api_deleteReportByID({ _id: reportId });
              if (deleteReport) {
                console.log(deleteReport);
                PNotify.success({
                  title: 'Success',
                  text: "Reporting Deleted Successfully",
                  delay: 3000
                })
              } else {
                PNotify.error({
                  title: 'Error',
                  text: deleteReport.errMsg,
                  delay: 3000
                })
              }

            } catch (error) {
              console.error(error);
            }
            testDataTable.ajax.reload();
          })

        })
      },
      "processing": true,
      "serverSide": true,
    });
    _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
  }

</script>
<div class="content-body fadeIn">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20
        mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Question Report Type</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Question Report Type</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search or add new question report type </p>
    </div>
    <div class="d-block">
      <div class="d-sm-flex">
        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={showModal}>
          <i data-feather="plus" class="wd-10 mg-r-5"></i> Add Question Report Type
        </button>
      </div>
    </div>
  </div>

  <hr class="full-width">
  <div data-label="Example" class="df-example ">
    <table bind:this={demoDataTable} class="table table-hover table-sm" style="width:100%">
      <thead>
        <tr>
          <th style="text-align: center; width: 10%;">#</th>
          <th style="text-align: center;">Reporting Type</th>
          <th style="text-align: center; width: 10%;">Action</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    {#if emptyState }
    <div class="empty-state-img wd-200">
      <img src="/images/empty.svg" style="width: 200px" alt="empty" />
      <p class="tx-bold">No reported questions found</p>
    </div>
    {/if}
  </div>
</div>


<div class="modal effect-scale" bind:this={addNewReportModal} id="modal-new-batch" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered wd-sm-650" role="document">
    <div class="modal-content ">
      <div class="modal-body pd-0">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
          <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="media align-items-center">
            <span class="tx-color-03 d-none d-sm-block"><i data-feather="trello" class="wd-60 ht-60"></i></span>
            <div class="media-body mg-sm-l-20">
              {#if modelId==0}
              <h4 class=" tx-18 tx-sm-20 mg-b-2">
                Add report type
              </h4>
              <p class="tx-13 tx-color-03 mg-b-0">Add the question report type.</p>
              {:else}
              <h4 class=" tx-18 tx-sm-20 mg-b-2">
                Edit report type
              </h4>
              <p class="tx-13 tx-color-03 mg-b-0">Edit the question report type.</p>
              {/if}
            </div>
          </div><!-- media -->
        </div><!-- modal-header -->
        <hr class="full-width">

        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>

        <div class="row no-gutters">
          <div class="col-12 bg-white rounded-right">
            <div class="ht-80p d-flex flex-column justify-content-center pd-l-25 pd-r-25 pd-b-25">

              <div class=" tx-color-02">
                <div class="form-group">
                  <label class="d-block mg-t-20">Reporting Type
                    <span class="required-field">*</span></label>
                  <input type="text" bind:value={report.reportType} on:keyup={(e)=> e.key === 'Enter' && onReportAdd()}
                  on:focus={clearValidations}
                  class:parsley-error={errorDetails.reportType}
                  class="form-control" placeholder="Enter report type">
                  {#if errorDetails.reportType}
                  <div class="parsley-errors-list">{errorDetails.reportType}</div>
                  {/if}
                </div>

              </div>

              <div class=" d-flex justify-content-end">
                {#if modelId==0}
                <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">
                  CANCEL <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                <button type="button" on:click={onReportAdd}
                  class="btn btn-sm pd-x-15 tx-12 btn-primary btn-uppercase mg-l-5">
                  <ButtonLoader isLoading={isLoading} btnText="ADD TYPE" />
                  <i data-feather="plus" class="wd-10 mg-l-5"></i>
                </button>
                {:else}
                <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">
                  CANCEL <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                <button type="button" on:click={onReportEdit}
                  class="btn btn-sm pd-x-15 tx-12 btn-primary btn-uppercase mg-l-5">
                  <ButtonLoader isLoading={isLoading} btnText="save" />
                  <i data-feather="save" class="wd-10 mg-l-5"></i>
                </button>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
