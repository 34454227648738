<script>
  import { onMount, createEventDispatcher, tick } from "svelte";
  import ButtonLoader from "./button_loader.svelte";
  const dispatch = createEventDispatcher();
  export let moduleName = "";
  export let isLoading = false;
  export let show = false;
  let elem_schedulerModal, elem_timePicker;
  let scheduledOn, scheduleDate, scheduleTime;
  let errorObj = {};
  let minTime = new Date();
  $: show && openScheduler();
  $: scheduledOn && updateMinTime();

  let updateMinTime = function () {
    minTime = new Date(scheduledOn);
    if (convertDateObjToDateStr(scheduledOn) != convertDateObjToDateStr(new Date())) {
      minTime.setHours(0, 0, 0, 0);
    }
  };
  function convertDateObjToDateStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [year, month, day].join("-");
    return dateString;
  }
  let clearError = function (fieldName) {
    if (errorObj[fieldName]) {
      delete errorObj[fieldName];
    }
    errorObj = errorObj;
  };
  let submitForm = function () {
    if(isLoading)return
    isLoading = true;
    scheduledOn = null;
    if (!scheduleDate) {
      isLoading = false;
      errorObj.scheduleDate = "Select Date";
    } else if (!scheduleTime) {
      let time = new Date();
      scheduledOn.setHours(time.getHours(), time.getMinutes(), 0, 0);
    } else if (scheduleDate && scheduleTime) {
      scheduledOn = new Date(scheduleDate);
      let time = new Date(scheduleTime);
      scheduledOn.setHours(time.getHours(), time.getMinutes(), 0, 0);
    }
    isLoading = false;
    _$(elem_schedulerModal).modal("hide");
  };
  export const openScheduler = async function () {
    // scheduleAnnouncement()
    _$(elem_schedulerModal).modal("show");
  };
  onMount(async function () {
    console.log("scheduler mounted");
    _$(elem_schedulerModal).on("hidden.bs.modal", function () {
      errorObj = {};
      scheduleDate = convertDateObjToDateStr(new Date());
      scheduleTime = new Date();
      show = false;
      _$(elem_timePicker).timepicker("setTime", "12:00 AM");
      dispatch("close", { schedule: { scheduledOn } });
      scheduledOn = null;
    });

    _$(elem_timePicker).timepicker({
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      template: "modal",
      minTime: new Date(),
      defaultTime: "12:00 AM",
      change: async function (time) {
        if (time) {
          scheduleTime = new Date(time);
          // scheduledOn.setHours(scheduleTime.getHours(), scheduleTime.getMinutes(), 5, 0);
        } else {
          scheduleTime = null;
          // scheduledOn.setHours(0, 0, 5, 0);
        }
        clearError("scheduleTime");
        await tick();
      },
    });

    scheduleDate = convertDateObjToDateStr(new Date());
    await tick();
    feather.replace();
  });
</script>

<!-- Modal -->
<div
  class="modal effect-scale"
  bind:this={elem_schedulerModal}
  id="modal-new-batch"
  tabindex="-1"
  role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered wd-sm-650" role="document">
    <div class="modal-content">
      <div class="modal-body pd-0">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
          <a
          href=""
          role="button"
          class="close pos-absolute t-15 r-15 z-index-10"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
          <div class="media align-items-center">
            <span class="tx-color-03 d-none d-sm-block"><i data-feather="clock" class="wd-60 ht-60"></i></span>
            <div class="media-body mg-sm-l-20">
            <h4 class=" mg-b-10 mg-md-b-2 ">Schedule {moduleName}</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Schedule your class </p>
            </div>
          </div><!-- media -->
        </div><!-- modal-header -->
        
        <hr class="full-width">
        <div class="row no-gutters">
          <div class="col-12 bg-white rounded-right">
            <div class="ht-100p d-flex flex-column justify-content-center pd-l-25 pd-r-25 pd-b-25">
              <div class="tx-color-02">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label class="d-block">Date
                        <span class="required-field">*</span></label>
                      <input
                        bind:value={scheduleDate}
                        min={convertDateObjToDateStr(new Date())}
                        onkeydown="return false"
                        on:keyup={(e) => e.key === 'Enter' && submitForm(e)}
                        on:blur={() => clearError('scheduleDate')}
                        type="date"
                        class="form-control first-input"
                        class:parsley-error={errorObj.scheduleDate}
                        placeholder="Enter date" />
                      {#if errorObj.scheduleDate}
                        <div class="parsley-errors-list">
                          {errorObj.scheduleDate}
                        </div>
                      {/if}
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label class="d-block">Time
                        <span class="required-field">*</span></label>
                      <input
                        bind:this={elem_timePicker}
                        onkeydown="return false"
                        on:keyup={(e) => e.key === 'Enter' && submitForm(e)}
                        type="text"
                        class="form-control first-input"
                        class:parsley-error={errorObj.scheduleTime}
                        placeholder="Enter time" />
                      {#if errorObj.scheduleTime}
                        <div class="parsley-errors-list">
                          {errorObj.scheduleTime}
                        </div>
                      {/if}
                    </div>
                  </div>
                </div>
              </div>
              <div class=" tx-color-02" />
              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-secondary tx-12"
                  data-dismiss="modal">CANCEL
                  <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  on:click={submitForm}
                  class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                  <ButtonLoader {isLoading} btnText="Proceed" />
                  <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
