<script>
    import { createEventDispatcher, onMount, afterUpdate, tick } from "svelte";
    import ButtonLoader from "../../utils/button_loader.svelte";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import MODULES from "../../utils/modules.js";
    import ReceiptCom from "../receipt/receipt.svelte";
    import PrintReceiptCom from "../receipt/printReceipt.svelte";

    const dispatch = createEventDispatcher();
    import { userData } from "../../stores";

    export let batchId;
    export let studentBatchObj;
    export let studentId;
    export let totalPaidAmount = 0;
    export let balanceAmount = 0;
    export let selectAllCbkChecked;
    export let batchList = [];
    export let allPaidBatches = new Set();
    let toggleModal = false;
    let togglePrintReceiptModal = false;

    let balanceAmountWithInstalment = 0;
    let selectizeInstance;
    let branchSelectize;
    let receiptselectizeInstance;
    let roleIsTelecaller;
    let batchObj = {};
    let modalEle;
    let modalPrintReceiptEle;
    let modalTitle = "New Payment";
    let showReceipt = false;
    let isLoading = false;
    let isSaveLoading = false;
    let _paidAmount = 0;
    let isDateChanged = false;
    let paymentId;
    let elemPaymentType;
    let elemReceiptType;
    let branchSelectizeInstance;
    let elembranchSelect;
    let branchListSelectArr;
    let paymentStatus = "DEFAULT";
    let paymentList = [];
    let receiptSeriesLists = [];
    let receiptSeriesListsOriginal = [];
    let receiptId = "";
    let paymentObj = {};
    let ifListNotPresent = "";

    let studentBatchInfo;
    let paymentDateStr = "";
    let errorObj = {};
    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    };
    let getPaymentType = function (value) {
        let paymentTypeOptions = {
            cash: "Cash",
            cheque: "Cheque",
            dd: "DD",
            onlinePay: "Online Payment",
            upi: "UPI",
            card: "Card Payment",
        };
        return paymentTypeOptions[value];
    };

    let getPaymentDesc = function (paymentObj) {
        let message = "Unpaid";
        if (paymentObj.isRefunded) {
            message = "Refund";
        } else {
            if (
                paymentObj.paidAmount &&
                paymentObj.paidAmount == paymentObj.installmentAmount
            ) {
                message = "Paid";
            }
            if (
                paymentObj.paidAmount &&
                paymentObj.paidAmount != paymentObj.installmentAmount
            ) {
                message = "Partial Paid";
            }
        }
        return message;
    };

    let localUserData = {
        fullName: "...",
        role: "...",
        avatar: "/images/man.png",
        access: {
            home: false,
            students: false,
            settings: false,
        },
    };

    const unsubscribe = userData.subscribe((value) => {
        localUserData = value;
        roleIsTelecaller = value.role == "telecaller";
    });

    function setInstallmentDateAsToday() {
        paymentDateStr = convertDateObjToStr(new Date());
        updatePaymentDate();
    }
    function updatePaymentDate() {
        paymentObj.paymentDate = convertDateStrToObj(paymentDateStr);

        let isTodayDate = new Date(new Date().setHours(0, 0, 0, 0));
        if (paymentObj.paymentType == "cheque") {
            //this is to select the future date
            populateBranchSelectize("", "", false);
            isDateChanged = false;
        } else if (paymentObj.paymentDate.getTime() == isTodayDate.getTime()) {
            populateBranchSelectize("", "", false);
            isDateChanged = true;
        }

        preProcessPaymentData(isDateChanged);
        updatePaidStatus();
    }
    function convertDateObjToStr(date) {
        var month = "" + (date.getMonth() + 1);
        var day = "" + date.getDate();
        var year = date.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        var dateString = [year, month, day].join("-");
        return dateString;
    }

    function convertDateStrToObj(selectedDate) {
        selectedDate = selectedDate.split("-");
        var year = selectedDate[0];
        var month = selectedDate[1] - 1;
        var day = selectedDate[2];
        return new Date(year, month, day);
    }
    let resetModalFields = function () {
        paymentObj = {
            studentId: studentId,
            batchId: batchId,
            paymentType: "cash",
            installmentAmount: balanceAmountWithInstalment,
            paidAmount: balanceAmountWithInstalment,
        };
        _paidAmount = paymentObj.paidAmount;
        paymentStatus = "DEFAULT";
        paymentDateStr = convertDateObjToStr(new Date());
        updatePaymentDate();

        paymentObj.freezePayment = false;
        _$(elemPaymentType)[0].selectize.setValue("cash");
        _$(elemPaymentType)[0].selectize.enable();
        if (
            branchSelectizeInstance.length &&
            branchSelectizeInstance.selectize
        ) {
            _$(elembranchSelect)[0].selectize.clear();
            _$(elembranchSelect)[0].selectize.enable();

            receiptSeriesLists = [];
        }
        _$(modalEle).modal("show");
        updatePaidStatus();
    };

    let validatePayment = () => {
        errorObj = {};
        let BANKNAMEREGEX = /^([a-z]+\s)*[a-zA-Z ]+$/;
        let CHEQUENOREGEX = /^[0-9]{6,6}$/;
        if (
            !paymentObj.installmentAmount ||
            !paymentObj.chequeNo ||
            !paymentObj.bankName ||
            !paymentObj.code ||
            !paymentObj.paymentDate ||
            !paymentObj.paymentType ||
            !paymentObj.paidAmount
        ) {
            var newErrorObj = {};
            if (!paymentObj.paymentType) {
                newErrorObj.paymentType = " Payment Type is required Field.";
            }
            if (!paymentObj.paymentDate) {
                newErrorObj.paymentDate = " Payment Date is required Field.";
            }

            if (!paymentObj.installmentAmount) {
                newErrorObj.installmentAmount =
                    " Installment Amount is required Field.";
            }

            if (
                paymentObj.paymentType == "cash" ||
                paymentObj.paymentType == "upi" ||
                paymentObj.paymentType == "card" ||
                paymentObj.paymentType == "onlinePay"
            ) {
                if (
                    paymentObj.paymentDate == new Date() ||
                    paymentObj.paymentDate < new Date()
                ) {
                    if (!paymentObj.paidAmount) {
                        newErrorObj.paidAmount = "  Amount is required Field.";
                    }
                    if (paymentObj.paidAmount && paymentObj.paidAmount < 0) {
                        newErrorObj.paidAmount =
                            "Amount cannot be less than  0";
                    }
                    if (
                        paymentObj.paidAmount &&
                        paymentObj.paidAmount > balanceAmount
                    ) {
                        newErrorObj.paidAmount =
                            "Amount cannot be greater than Balance Amount ( " +
                            balanceAmount +
                            " )";
                    }
                } else {
                    if (
                        paymentObj.installmentAmount &&
                        paymentObj.installmentAmount > balanceAmount
                    ) {
                        newErrorObj.installmentAmount =
                            " Installment Amount cannot be greater than Balance Amount ( " +
                            balanceAmount +
                            " )";
                    }

                    if (
                        paymentObj.installmentAmount &&
                        paymentObj.installmentAmount < 0
                    ) {
                        newErrorObj.installmentAmount =
                            " Installment Amount cannot be less than  0";
                    }
                    if (
                        paymentObj.installmentAmount &&
                        paymentObj.paidAmount &&
                        paymentObj.installmentAmount < paymentObj.paidAmount
                    ) {
                        newErrorObj.paidAmount =
                            "Cannot Pay amount more than Installment Amount ( " +
                            paymentObj.installmentAmount +
                            " )";
                    }
                }
            }
            // if(paymentObj.paymentType =="cash" || paymentObj.paymentType =="upi" || paymentObj.paymentType =="card" || paymentObj.paymentType =="onlinePay" )
            // {
            //     if(paymentObj.paymentDate == convertDateStrToObj(new Date()) || paymentObj.paymentDate < convertDateStrToObj(new Date()) ){
            //         if (!paymentObj.paidAmount ) {
            //         newErrorObj.paidAmount = "  Amount is required Field.";
            //      }
            //      if (paymentObj.paidAmount && paymentObj.paidAmount  < 0) {
            //         newErrorObj.paidAmount = "Amount cannot be less than  0";
            //      }
            //      if (paymentObj.paidAmount && paymentObj.paidAmount  > balanceAmount) {
            //         newErrorObj.paidAmount = "Amount cannot be greater than Balance Amount ( " + (balanceAmount) + " )";
            //      }
            //     }
            //    else{
            //         if (paymentObj.installmentAmount && paymentObj.installmentAmount > balanceAmount) {
            //             newErrorObj.installmentAmount = " Installment Amount cannot be greater than Balance Amount ( " + (balanceAmount) + " )";
            //         }

            //         if (paymentObj.installmentAmount && paymentObj.installmentAmount  < 0) {
            //             newErrorObj.installmentAmount = " Installment Amount cannot be less than  0";
            //         }
            //         if (paymentObj.installmentAmount && paymentObj.paidAmount && paymentObj.installmentAmount < paymentObj.paidAmount) {
            //             newErrorObj.paidAmount = "Cannot Pay amount more than Installment Amount ( " + (paymentObj.installmentAmount) + " )";
            //         }

            //    }
            // }

            // if(paymentObj.paymentType == "cash" ){
            //     if (paymentObj.paidAmount && paymentObj.paidAmount < 0) {
            //     newErrorObj.installmentAmount = " Installment Amount cannot be less than  0";
            // }
            // }
            if (paymentObj.paymentType == "cheque") {
                if (!paymentObj.chequeNo) {
                    newErrorObj.chequeNo = "Cheque Number is requred.";
                }
                if (!CHEQUENOREGEX.test(paymentObj.chequeNo)) {
                    newErrorObj.chequeNo =
                        "Please enter valid 6 digit cheque number.";
                }
                if (
                    !paymentObj.bankName ||
                    !BANKNAMEREGEX.test(paymentObj.bankName)
                ) {
                    newErrorObj.bankName = "Please enter valid bank name";
                }
                if (
                    paymentObj.installmentAmount &&
                    paymentObj.installmentAmount > balanceAmount
                ) {
                    newErrorObj.installmentAmount =
                        " Installment Amount cannot be greater than Balance Amount ( " +
                        balanceAmount +
                        " )";
                }

                if (
                    paymentObj.installmentAmount &&
                    paymentObj.installmentAmount < 0
                ) {
                    newErrorObj.installmentAmount =
                        " Installment Amount cannot be less than  0";
                }
                if (
                    paymentObj.installmentAmount &&
                    paymentObj.paidAmount &&
                    paymentObj.installmentAmount < paymentObj.paidAmount
                ) {
                    newErrorObj.paidAmount =
                        "Cannot Pay amount more than Installment Amount ( " +
                        paymentObj.installmentAmount +
                        " )";
                }
            }
            // if (paymentObj.paymentType == "dd") {

            //     if (!paymentObj.ddNo ) {

            //         newErrorObj.ddNo = "Please enter Demand Draft No";
            //     }
            //     if (!paymentObj.installmentAmount) {
            //         newErrorObj.installmentAmount = "Please Enter Demand Draft Amount";
            //     }
            //     if (paymentObj.installmentAmount && paymentObj.installmentAmount  <= 0) {
            //         newErrorObj.installmentAmount = " Installment Amount cannot be less than or equal to 0";
            //     }
            // }
            if (paymentObj.paidAmount && paymentObj.receiptNo == "") {
                newErrorObj.receiptNo = "Select branch to add receipt number";
            }
            if (paymentObj._id && !paymentObj.remark) {
                newErrorObj.remark = "Please add remarks for this transaction";
            }
            // if (paymentObj.paidAmount && !paymentObj.prefix) {
            //     newErrorObj.prefix = " Prefix is required Field.";
            // }
            // if (paymentObj.paidAmount && !paymentObj.sequence) {
            //     newErrorObj.sequence = " Sequence is required Field.";
            // }
            // if (paymentObj.paidAmount && paymentObj.sequence && !/^([1-9][0-9]*)$/.test(paymentObj.sequence)) {
            //     newErrorObj.sequence = " Enter valid Sequence ";
            // }
            errorObj = newErrorObj;
        }
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;
    };

    let preProcessPaymentData = function (isDateChanged) {
        if (paymentObj.paymentType == "cash") {
            if (paymentObj._id) {
            } else {
                if (
                    paymentObj.paymentDate < new Date() ||
                    paymentDateStr == convertDateObjToStr(new Date())
                ) {
                    if (isDateChanged) paymentObj.paidAmount = _paidAmount;

                    paymentObj.installmentAmount = paymentObj.paidAmount;
                } else {
                    paymentObj.paidAmount = 0;
                }
            }
        }
        if (paymentObj.paymentType == "cheque") {
            if (paymentObj._id) {
            } else {
                paymentObj.paidAmount = 0;
            }
        }
        if (paymentObj.paymentType == "dd") {
            if (paymentObj._id) {
            } else {
                paymentObj.paidAmount = 0;
            }
        }

        if (paymentObj.paymentType == "onlinePay") {
            if (paymentObj._id) {
            } else {
                if (
                    paymentObj.paymentDate < new Date() ||
                    paymentDateStr == convertDateObjToStr(new Date())
                ) {
                    if (isDateChanged) paymentObj.paidAmount = _paidAmount;
                    else paymentObj.installmentAmount = paymentObj.paidAmount;
                } else {
                    paymentObj.paidAmount = 0;
                }
            }
        }
        if (paymentObj.paymentType == "upi") {
            if (paymentObj._id) {
            } else {
                if (
                    paymentObj.paymentDate < new Date() ||
                    paymentDateStr == convertDateObjToStr(new Date())
                ) {
                    if (isDateChanged) paymentObj.paidAmount = _paidAmount;
                    else paymentObj.installmentAmount = paymentObj.paidAmount;
                } else {
                    paymentObj.paidAmount = 0;
                }
            }
        }
        if (paymentObj.paymentType == "card") {
            if (paymentObj._id) {
            } else {
                if (
                    paymentObj.paymentDate < new Date() ||
                    paymentDateStr == convertDateObjToStr(new Date())
                ) {
                    if (isDateChanged) paymentObj.paidAmount = _paidAmount;
                    else paymentObj.installmentAmount = paymentObj.paidAmount;
                } else {
                    paymentObj.paidAmount = 0;
                }
            }
        }
    };

    let saveAndAddAnotherPayment = async function () {
        paymentObj.paymentType = _$(elemPaymentType).val();
        var todaysDate = new Date();
        paymentObj.paymentDate = new Date(paymentObj.paymentDate);
        if (
            todaysDate.getDate() < paymentObj.paymentDate.getDate() ||
            todaysDate.getDate() > paymentObj.paymentDate.getDate()
        ) {
            // paymentObj.branchId = null
            // paymentObj.receiptId = null;
            //PDC LOGIC
            if (
                paymentObj.paymentType == "cheque" &&
                paymentStatus == "DEFAULT"
            ) {
                paymentObj.branchId =
                    branchSelectizeInstance && branchSelectizeInstance.length
                        ? branchSelectizeInstance[0].selectize.getValue()
                        : "";
                paymentObj.receiptId = receiptId;
            }
        } else {
            paymentObj.branchId =
                branchSelectizeInstance && branchSelectizeInstance.length
                    ? branchSelectizeInstance[0].selectize.getValue()
                    : "";
            paymentObj.receiptId = receiptId;
        }

        preProcessPaymentData();
        if (validatePayment()) {
            await tick();
            if (isSaveLoading) {
                return;
            }

            isSaveLoading = true;

            var paymentRes = await api_handleApiCalls(
                "payment.addUpdatePayment",
                paymentObj
            );
            isSaveLoading = false;
            if (paymentRes.status) {
                // create success
                PNotify.success({
                    title: "Success",
                    text: paymentRes.data,
                    delay: 3000,
                });
                paymentObj.bankName = "";
                paymentObj.receiptNo = "";
                paymentObj.chequeNo = "";
                (paymentObj.paidAmount = ""),
                    (paymentObj.installmentAmount = "");
                _$(elemPaymentType)[0].selectize.setValue("cheque");
                _$(elemPaymentType)[0].selectize.enable();
                _$(elembranchSelect)[0].selectize.clear();
                _$(elembranchSelect)[0].selectize.enable();
                _$(elemReceiptType)[0].selectize.clear();
                _$(elemReceiptType)[0].selectize.enable();
                receiptSeriesLists = [];
                paymentObj.prefix = "";
                paymentObj.sequence = "";

                refreshData();

                dispatch("refreshData");
            } else {
                PNotify.error({
                    title: "Error",
                    text: paymentRes.errMsg,
                    delay: 3000,
                });
            }
        }
    };

    let addUpdatePaymentBtn = async function () {
        paymentObj.paymentType = _$(elemPaymentType).val();
        var todaysDate = new Date();
        paymentObj.paymentDate = new Date(paymentObj.paymentDate);
        if (
            todaysDate.getDate() < paymentObj.paymentDate.getDate() ||
            todaysDate.getDate() > paymentObj.paymentDate.getDate()
        ) {
            // paymentObj.branchId = null
            // paymentObj.receiptId = null;
            //PDC LOGIC
            if (
                paymentObj.paymentType == "cheque" &&
                paymentStatus == "DEFAULT"
            ) {
                paymentObj.branchId =
                    branchSelectizeInstance && branchSelectizeInstance.length
                        ? branchSelectizeInstance[0].selectize.getValue()
                        : "";
                paymentObj.receiptId = receiptId;
            }
        } else {
            paymentObj.branchId =
                branchSelectizeInstance && branchSelectizeInstance.length
                    ? branchSelectizeInstance[0].selectize.getValue()
                    : "";
            paymentObj.receiptId = receiptId;
        }

        preProcessPaymentData();
        if (validatePayment()) {
            await tick();
            if (isLoading) {
                return;
            }

            isLoading = true;

            var paymentRes = await api_handleApiCalls(
                "payment.addUpdatePayment",
                paymentObj
            );
            if (paymentRes.status) {
                // create success
                PNotify.success({
                    title: "Success",
                    text: paymentRes.data,
                    delay: 3000,
                });
                _$(modalEle).modal("hide");
                refreshData();
                dispatch("refreshData");
            } else {
                PNotify.error({
                    title: "Error",
                    text: paymentRes.errMsg,
                    delay: 3000,
                });
                isLoading = false;
            }
        }
    };

    function openReceiptModal(payment_Id, studentId, batchId) {
        paymentId = payment_Id;
        toggleModal = true;
    }
    function closeModal() {
        toggleModal = false;
    }

    let validateRefundPayment = () => {
        errorObj = {};
        // -Math.abs(-45)
        var newErrorObj = {};

        if (!paymentObj.paidAmount) {
            newErrorObj.paidAmount = "Please Enter Amount To refund";
        }
        if (
            totalPaidAmount &&
            paymentObj.paidAmount &&
            totalPaidAmount < paymentObj.paidAmount
        ) {
            newErrorObj.paidAmount =
                "Cannot Refund amount more than Paid Amount ( " +
                totalPaidAmount +
                " )";
        }
        if (!paymentObj.paymentDate) {
            newErrorObj.paymentDate = " Payment Date is required Field.";
        }

        if (!paymentObj.paymentType) {
            newErrorObj.paymentType = " Payment Type is required Field.";
        }

        if (!paymentObj.remark) {
            newErrorObj.remark =
                "Please add remarks for this Refund transaction";
        }

        errorObj = newErrorObj;
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;
    };

    let addRefundPaymentBtn = async function () {
        paymentObj.paymentType = _$(elemPaymentType).val();

        if (validateRefundPayment()) {
            await tick();
            if (isLoading) {
                return;
            }
            isLoading = true;
            paymentObj.paidAmount = -Math.abs(paymentObj.paidAmount); //this to calculate accurate amount after of installments
            paymentObj.isRefunded = true;
            //  paymentObj.freezePayment = true;
            paymentObj.studentId = studentId;
            paymentObj.branchId = null;
            paymentObj.receiptId = null;

            var paymentRes = await api_handleApiCalls(
                "payment.refundPayment",
                paymentObj
            );
            if (paymentRes.status) {
                // create success
                PNotify.success({
                    title: "Success",
                    text: "Refunded",
                    delay: 2000,
                });
                _$(modalEle).modal("hide");
                refreshData();
                dispatch("refreshData");
            } else {
                PNotify.error({
                    title: "Error",
                    text: paymentRes.errMsg,
                    delay: 3000,
                });
                isLoading = false;
            }
        }
    };

    let deletePaymentById = function (paymentId, closeModal) {
        const notice = PNotify.notice({
            title: "Delete Installment",
            text: "Are you sure you want to delete this installment ?",
            hide: false,
            closer: false,
            sticker: false,
            modules: new Map([
                ...PNotify.defaultModules,
                [PNotifyConfirm, { confirm: true }],
            ]),
        });

        notice.on("pnotify:confirm", async () => {
            try {
                let deleteRes = await api_handleApiCalls(
                    "payment.deletePaymentById",
                    { _id: paymentId }
                );

                if (deleteRes.status) {
                    PNotify.success({
                        title: "Success",
                        text: deleteRes.data,
                        delay: 3000,
                    });
                    if (closeModal) {
                        _$(modalEle).modal("hide");
                    }
                } else {
                    PNotify.error({
                        title: "Error",
                        text: deleteRes.errMsg,
                        delay: 3000,
                    });
                }
                refreshData();
            } catch (error) {
                console.error(error);
            }
        });
    };

    let isPayingAmount = function (params) {
        return paymentObj.paidAmount && paymentObj.paidAmount != 0;
    };

    let bindSelectize = function (isPageRefresh) {
        ifListNotPresent = "";
        selectizeInstance = _$(elemPaymentType).selectize({
            onChange: function (value) {
                paymentObj.paymentType = value;
                setTimeout(() => {
                    if (isPageRefresh) {
                        isPageRefresh = false;
                        //Need to do :  bcoz on payment change we want to populate payment receipt
                        paymentObj.prefix = "";
                        paymentObj.sequence = "";
                        paymentObj.receiptNo = "";
                        populateBranchSelectize("", "", true);
                    } else {
                        paymentObj.prefix = "";
                        paymentObj.sequence = "";
                        paymentObj.receiptNo = "";
                        populateBranchSelectize("", "", false);
                        updatePaymentDate();
                    }
                    preProcessPaymentData();
                    updatePaidStatus(isDateChanged);
                }, 500);
            },
        });

        populateBranchSelectize("", "", false);
    };

    let bindEvents = function (params) { };
    // let printSelectedBatchReceipts = function (){

    // }
    let refreshData = function () {
        getStudentPaymentByBatchId();
        // window.location.reload();
    };

    let getStudentPaymentsByBatchId = async function () {
        let paymentListObj = await api_handleApiCalls(
            "students.getStudentPaymentsByBatchId",
            { _id: studentId }
        );
        paymentList = paymentListObj.data;
    };

    let getBatchById = async function () {
        let batcheObj = await api_handleApiCalls("batch.getBatchById", {
            _id: batchId,
        });
        if (batcheObj.status) {
            batchObj = batcheObj.data;
        } else {
            console.error(batcheObj.errMsg);
        }
    };

    let getStudentPaymentByBatchId = async function () {
        let paymentListData = await api_handleApiCalls(
            "payment.getStudentPaymentByBatchId",
            { studentId: studentId, batchId: batchId }
        );
        if (paymentListData.status) {
            paymentList = paymentListData.data
                .map(function (aPayment) {
                    return {
                        _id: aPayment._id,
                        studentId: aPayment.studentId,
                        batchId: aPayment.batchId,
                        paymentType: aPayment.paymentType,
                        installmentAmount: aPayment.installmentAmount,
                        paidAmount: aPayment.paidAmount,
                        receiptNo: aPayment.receiptNo,
                        paymentDate: aPayment.paymentDate,
                        freezePayment: aPayment.freezePayment,
                        freezePaymentDate: aPayment.freezePaymentDate,
                        chequeNo: aPayment.chequeNo,
                        bankName: aPayment.bankName,
                        ddNo: aPayment.ddNo,
                        refNo: aPayment.refNo,
                        upiNo: aPayment.upiNo,
                        cardNo: aPayment.cardNo,
                        receiptId: aPayment.receiptId || "",
                        branchId: aPayment.branchId || "",
                        prefix: aPayment.prefix || "",
                        remark: aPayment.remark || "",
                        sequence: aPayment.sequence || "",
                        isRefunded: aPayment.isRefunded || false,
                    };
                })
                .sort(function (aPayment, anotherPayment) {
                    return (
                        new Date(
                            aPayment.freezePaymentDate
                                ? aPayment.paymentDate
                                : aPayment.paymentDate
                        ).getTime() -
                        new Date(
                            anotherPayment.freezePaymentDate
                                ? anotherPayment.paymentDate
                                : anotherPayment.paymentDate
                        ).getTime()
                    );
                });

            // console.log("paymentList--",paymentList)
            await tick();
            feather.replace();
        } else {
            console.error(paymentListData.errMsg);
        }
        updateTotalPaymentList();
    };

    let updatePaidStatus = function (params) {
        if (isPayingAmount()) {
            paymentStatus = "FREEZE";
        } else {
            paymentStatus = "DEFAULT";
            //PDC LOGIC
            if (paymentObj.paymentType == "cheque" && paymentObj._id) {
                //add id logic
                receiptSeriesLists = [];
            }
        }
    };

    let updateTotalPaymentList = function (params) {
        let sum = 0;
        let installmentSum = 0;
        paymentList.forEach((aPayment) => {
            if (aPayment.freezePayment) {
                sum += aPayment.paidAmount ? aPayment.paidAmount : 0;
            } else {
                installmentSum += aPayment.installmentAmount
                    ? aPayment.installmentAmount
                    : 0;
            }
        });
        totalPaidAmount = sum;
        balanceAmount = studentBatchObj.agreedFees - totalPaidAmount;
        balanceAmountWithInstalment =
            studentBatchObj.agreedFees - (totalPaidAmount + installmentSum);
    };

    let addNewPayment = function () {
        modalTitle = "New Payment";
        resetModalFields();
        _$(modalEle).modal("show");
    };
    let addNewRefund = function () {
        modalTitle = "Refund Payment";
        resetModalFields();
        //  _$(elemPaymentType)[0].selectize.enableupdateOption('DD', {name: 'DD', disable: true});
        _$(modalEle).modal("show");
    };

    let convertToINR = function (input) {
        let amount = Number(input);
        return amount.toLocaleString("en-IN", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
        });
    };

    let viewPayment = function (viewPaymentObj, payNowFlag, titleModal) {
        modalTitle = titleModal;
        resetModalFields();

        paymentObj = JSON.parse(JSON.stringify(viewPaymentObj));
        if (!paymentObj.paidAmount) paymentObj.paidAmount = 0;

        paymentDateStr = convertDateObjToStr(new Date(paymentObj.paymentDate));
        updatePaymentDate();
        _$(elemPaymentType)[0].selectize.setValue(paymentObj.paymentType);
        // _$(elembranchSelect)[0].selectize.setValue(paymentObj.branchId);

        // paymentObj.branchId ? populateBranchSelectize(paymentObj.branchId,paymentObj.receiptId,true):""
        paymentObj.branchId
            ? populateBranchSelectize(
                paymentObj.branchId,
                paymentObj.receiptId,
                true
            )
            : populateBranchSelectize("", "", true);
        if (paymentObj.freezePayment) {
            _$(elemPaymentType)[0].selectize.disable();
            //  _$(elembranchSelect)[0].selectize.disable()
        }

        if (
            payNowFlag &&
            !paymentObj.freezePayment &&
            new Date(paymentObj.paymentDate).getTime() > new Date().getTime()
        ) {
            setInstallmentDateAsToday();
        }
        updatePaidStatus();
        _$(modalEle).modal("show");
    };

    let populateReceiptNumber = function () {
        if (paymentObj.prefix && paymentObj.sequence) {
            paymentObj.receiptNo = paymentObj.prefix + paymentObj.sequence;
        }
    };

    function onCloseReceiptModal() {
        showReceipt = false;
    }

    //   async function selectBatch(id, checked,) {
    //       console.log("id::",id, checked)
    //     if (checked) {
    //       allPaidBatches.add(id)
    //     } else {
    //       allPaidBatches.delete(id)
    //     }

    //     allPaidBatches = allPaidBatches
    //     console.log("allPaidBatches:::",allPaidBatches)

    //     let selectedBatches = Array.from(allPaidBatches)
    //         console.log("selectedBatches",selectedBatches)
    //         dispatch('printSelectedBatchReceipts', {
    //             selectedBatches: selectedBatches
    //   })
    //     await tick()

    //   }

    onMount(async () => {
        feather.replace();
        // console.log("selectAllCbkChecked",selectAllCbkChecked)
        // dispatch('printSelectedBatchReceipts')
        await getStudentPaymentByBatchId();
        bindSelectize(true);
        bindWheelDisable();
        _$(modalEle).on("show.bs.modal", function (e) {
            // change isloading here
            isLoading = false;
        });
        // checkIfAllSelected()
    });
    function bindWheelDisable() {
        document.addEventListener("wheel", function (event) {
            if (
                document.activeElement.type === "number" &&
                document.elementFromPoint(event.x, event.y) ==
                document.activeElement
            ) {
                document.activeElement.blur();
            }
        });
    }
    afterUpdate(() => {
        feather.replace();
    });

    let populateReceiptSelectize = async function (branchId, receiptID, isSet) {
        //get recept list
        var response = await api_handleApiCalls("receiptSeries.list", {
            start: 0,
            length: 100,
            search: "",
            branchId: branchId,
        });
        ifListNotPresent = "";

        if (response && response.data.data.length) {
            receiptSeriesLists = response.data.data;
            //PDC LOGIC
            if (
                paymentObj.paymentType == "cheque" &&
                (paymentStatus == "DEFAULT" || !paymentObj._id)
            ) {
                //add id logic
                receiptSeriesLists = receiptSeriesLists.filter(
                    (item) => item.isPdc == true
                );
            }
            if (
                paymentObj.paymentType == "cash" &&
                (paymentStatus == "DEFAULT" || !paymentObj._id)
            ) {
                receiptSeriesLists = receiptSeriesLists.filter(
                    (item) =>
                        (item.isOffline == true &&
                            item.isOnline == false &&
                            item.isPdc == false) ||
                        (item.isOffline == true &&
                            item.isOnline == true &&
                            item.isPdc == false)
                );
            }
            if (
                paymentObj.paymentType == "onlinePay" ||
                paymentObj.paymentType == "upi" ||
                (paymentObj.paymentType == "card" &&
                    (paymentStatus == "DEFAULT" || !paymentObj._id))
            ) {
                receiptSeriesLists = receiptSeriesLists.filter(
                    (item) =>
                        item.isOnline == true &&
                        item.isOffline == false &&
                        item.isPdc == false
                );
            }
            // else {
            //     receiptSeriesLists = receiptSeriesLists.filter(item => !item.isPdc || item.isPdc == false)
            // }

            if (receiptSeriesLists.length)
                receiptSeriesLists.map(
                    (item) => (item.label = `${item.prefix}${item.series}`)
                );
            if (receiptSeriesLists.length == 0)
                ifListNotPresent = "Please add receipt series first";
        } else {
            receiptSeriesLists = [];
            ifListNotPresent = "Please add receipt series first";
        }
        await tick();
        if (receiptselectizeInstance && receiptselectizeInstance.length)
            receiptselectizeInstance[0].selectize.destroy();
        receiptselectizeInstance = _$(elemReceiptType).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "label",
            searchField: ["prefix", "series"],
            delimiter: ",",
            persist: false,
            options: receiptSeriesLists,
            render: {
                option_create: function (data, escape) {
                    return (
                        '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
                        escape(data.input) +
                        "</strong></div>"
                    );
                },
            },
            onChange: function (receiptIdValue) {
                if (isSet) {
                } else {
                    var isFound = receiptSeriesLists.find(
                        (i) => i._id == receiptIdValue
                    );
                    if (isFound) {
                        paymentObj.prefix = isFound.prefix;
                        paymentObj.sequence = isFound.series;
                        paymentObj.receiptNo =
                            paymentObj.prefix + paymentObj.sequence;
                        receiptId = receiptIdValue;
                    }
                }
            },
        });
        if (receiptID && isSet) {
            paymentObj.receiptNo = paymentObj.prefix + paymentObj.sequence;
            receiptselectizeInstance[0].selectize.setValue(receiptID);
        }
    };

    let populateBranchSelectize = async function (branchId, receiptId, isSet) {
        ifListNotPresent = "";
        var response = await api_handleApiCalls(
            "instituteBranch.fetchInstituteBranchList",
            { start: 0, length: 100, sort: { name: 1 } }
        );
        if (response && response.data && response.data.data.length) {
            if (localUserData.role == "orgAdmin") {
                branchListSelectArr = [
                    { name: "All Reciepts", _id: "" },
                    ...response.data.data,
                ];
            } else {
                branchListSelectArr = [...response.data.data];
            }
        } else {
            branchListSelectArr.push({ name: "All", _id: "" });
        }
        await tick();
        // if(branchSelectizeInstance && branchSelectizeInstance.length) branchSelectizeInstance[0].selectize.destroy();
        branchSelectizeInstance = _$(elembranchSelect).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            persist: false,
            options: branchListSelectArr,
            render: {
                option_create: function (data, escape) {
                    return (
                        '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
                        escape(data.input) +
                        "</strong></div>"
                    );
                },
            },
            onChange: function (branchIdValue) {
                if (isSet) {
                } else {
                    if (
                        localUserData.role == "orgAdmin" &&
                        branchIdValue == ""
                    ) {
                        paymentObj.receiptNo = "";
                        populateReceiptSelectize(branchIdValue, "", false);
                    } else if (branchIdValue) {
                        paymentObj.receiptNo = "";
                        populateReceiptSelectize(branchIdValue, "", false);
                    }
                }
            },
        });

        if (branchId && isSet) {
            branchSelectizeInstance[0].selectize.setValue(branchId);
            if (modalTitle.toLowerCase() == "view payment")
                branchSelectizeInstance[0].selectize.disable();
            populateReceiptSelectize(branchId, receiptId, true);
        }
    };
    function restModal() {
        errorObj = {};
        paymentObj = {};
    }
    function showPrintReceiptModal(studentBatchObj) {
        studentBatchInfo = studentBatchObj;

        togglePrintReceiptModal = true;
    }
</script>

<div class="card" style="margin-bottom: 20px;">
    <!-- <p class="tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5">Current Balance</p>
    <h3 class="tx-26 tx-normal tx-rubik tx-spacing--2 mg-b-5">{convertToINR(balanceAmount) }</h3> -->
    <div class="card-header d-flex ">
        <h6 class="lh-5 mg-b-0">
            {studentBatchObj.batch.name} ({studentBatchObj.batch.code})
        </h6>
        <!-- <a href="" class="tx-11 link-03"><i data-feather="more-horizontal" class="wd-20 ht-20"></i></a> -->
    </div>
    <!-- card-header -->
    <div class="card-body pd-x-20 pd-b-10">
        <div class="d-flex mg-b-25">
            <p class="tx-12 tx-rubik mg-b-0">
                <span class="tx-medium tx-success mg-r-5">Batch Fee</span>
                {convertToINR(studentBatchObj.totalPayableAmount)}
            </p>
            <p class="tx-12 tx-rubik mg-b-0 mg-l-10">
                <span class="tx-medium tx-primary mg-r-5">Agreed Fee</span>
                {convertToINR(studentBatchObj.agreedFees)}
            </p>
        </div>
        <!-- <div class="d-flex mg-b-25">
            <button class="btn btn-sm btn-uppercase btn-white flex-fill tx-spacing-1">Send</button>
            <button class="btn btn-sm btn-uppercase btn-primary flex-fill tx-spacing-1 mg-l-10">Receive</button>
        
        
        </div> -->

        <div class="d-sm-flex  mg-b-10">
            <div class="media">
                <div
                    class="wd-40 wd-md-50 ht-40 ht-md-50 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                    <i data-feather="bar-chart-2" />
                </div>
                <div class="media-body">
                    <h6
                        class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">
                        Agreed Fees
                    </h6>
                    <h4 class="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0">
                        {convertToINR(studentBatchObj.agreedFees)}
                    </h4>
                </div>
            </div>
            <div class="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                <div
                    class="wd-40 wd-md-50 ht-40 ht-md-50 bg-pink tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-5">
                    <i data-feather="bar-chart-2" />
                </div>
                <div class="media-body">
                    <h6 class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                        Total Paid
                    </h6>
                    <h4 class="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0">
                        {convertToINR(totalPaidAmount)}
                    </h4>
                </div>
            </div>
            <div class="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                <div
                    class="wd-40 wd-md-50 ht-40 ht-md-50 bg-primary tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                    <i data-feather="bar-chart-2" />
                </div>
                <div class="media-body">
                    <h6 class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                        Balance Amount
                    </h6>
                    <h4 class="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0">
                        {convertToINR(balanceAmount)}
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-between mg-b-10">
            <p class="tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0">
                Payment History
            </p>
        </div>
        <div class="card mg-b-10">
            <div class="card-header d-sm-flex align-items-start justify-content-between">
                <h6 class="lh-5 mg-b-0">INSTALLMENTS</h6>
                <div class="d-flex mg-t-20 mg-sm-t-0">
                    {#if !roleIsTelecaller}
                    <nav class="nav nav-with-icon tx-13">
                        {#if studentBatchObj.batch.isFree == "yes"}
                        <!-- IF batch if demobatch hide new payment -->
                        <!-- content here -->
                        {:else}
                        <button type="button" disabled={!paymentList.length} class="btn btn-primary btn-btn-sm mg-r-5"
                            on:click={()=> {
                            showPrintReceiptModal(studentBatchObj);
                            }}>Print Receipt</button>
                        {#if MODULES.hasAccess(MODULES.REFUND_PAYMENT)}
                        <button on:click={addNewRefund} class="nav-link"
                            style="background: transparent;border: none;outline: none;"><i
                                data-feather="plus" />Refund</button>
                        {/if}
                        {#if MODULES.hasAccess(MODULES.ACCEPT_PAYMENT)}
                        <button on:click={addNewPayment} class="nav-link"
                            style="background: transparent;border: none;outline: none;"><i data-feather="plus" />Add
                            New</button>
                        {/if}
                        <!-- else content here -->
                        {/if}
                    </nav>
                    {/if}
                </div>

                <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase">
                    </i>New</button> -->
            </div>
            <!-- card-header -->
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                        <thead>
                            <tr class="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                <th style="max-width:20px;">#</th>
                                <th>Receipt</th>
                                <th class="">Description</th>
                                <th class="text-right">Defined</th>
                                <th class="text-right">Paid</th>
                                <th class="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {#each paymentList as aPayment, index}
                            <tr>
                                <td class="tx-color-03 " style="max-width:20px;">{index + 1}</td>
                                <td class="tx-color-03 ">{aPayment.receiptNo
                                    ? aPayment.receiptNo
                                    : "-"}</td>

                                <td class="tx-medium">{getPaymentDesc(aPayment)} (
                                    {getPaymentType(aPayment.paymentType)} )
                                    <br />
                                    {new Date(
                                    aPayment.freezePaymentDate
                                    ? aPayment.freezePaymentDate
                                    : aPayment.paymentDate
                                    ).toLocaleDateString()}
                                </td>
                                <td class="text-right">{convertToINR(
                                    aPayment.installmentAmount
                                    )}</td>
                                <td class="text-right ">{convertToINR(
                                    aPayment.paidAmount
                                    ? aPayment.paidAmount
                                    : 0
                                    )}
                                </td>
                                <td class="tx-normal ">
                                    <div class="d-md-flex flex-row justify-content-around">
                                        {#if aPayment.freezePayment}
                                        <button on:click={()=>
                                            viewPayment(
                                            aPayment,
                                            false,
                                            "View Payment"
                                            )}
                                            type="button"
                                            class="btn btn-xs btn-light "
                                            >View</button>
                                        <!-- <ReceiptCom paymentId={aPayment._id} studentId={studentId} batchId={batchId} /> -->
                                        {#if MODULES.hasAccess(MODULES.PRINT_RECEIPTS)}
                                        <button type="button" on:click={()=> {
                                            openReceiptModal(
                                            aPayment._id,
                                            studentId,
                                            batchId
                                            );
                                            }}
                                            class="btn btn-xs btn-light"
                                            ><i data-feather="printer">Receipt</i></button>
                                        {/if}
                                        <!-- openReceiptModal(aPayment._id, studentId, batchId) -->
                                        {:else}
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button on:click={()=>
                                                viewPayment(
                                                aPayment,
                                                true,
                                                "View Payment"
                                                )}
                                                type="button"
                                                class="btn btn-xs btn-primary"
                                                >Pay</button>
                                            {#if MODULES.hasAccess(MODULES.UPDATE_UNPAID_RECEIPTS) ||
                                            MODULES.hasAccess(MODULES.UPDATE_PAID_RECEIPTS)}
                                            <button style="padding: 0 6px;" on:click={()=>
                                                viewPayment(
                                                aPayment,
                                                false,
                                                "Edit Payment"
                                                )}
                                                type="button"
                                                class="btn
                                                btn-xs btn-light "
                                                ><i class="wd-20" data-feather="edit-2" /></button>
                                            {/if}
                                            {#if MODULES.hasAccess(MODULES.DELETE_PAID_RECEIPT)}
                                            <button style="padding: 0 6px;" on:click={()=>
                                                deletePaymentById(
                                                aPayment._id
                                                )}
                                                type="button"
                                                class="btn btn-xs
                                                btn-danger"
                                                ><i class="wd-20" data-feather="trash" /></button>
                                            {/if}
                                        </div>
                                        {/if}
                                    </div>
                                </td>
                            </tr>
                            {/each}
                            <tr style="border-top: 1px solid; border-color: rgba(72,94,144,0.16);">
                                <td class="tx-color-03 " />
                                <td class="tx-medium">Balance: </td>
                                <td class="tx-normal text-right">{convertToINR(balanceAmount)}</td>
                                <td class="text-right  ">Total Paid</td>
                                <td class="text-right  ">{convertToINR(totalPaidAmount)}</td>
                                <td class="tx-normal text-right" />
                                <!-- $28,670.90 <span
                                        class="mg-l-5 tx-10 tx-normal tx-success"><i
                                            class="icon ion-md-arrow-up"></i> 4.5%</span> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- table-responsive -->
            </div>
            <!-- card-body -->
        </div>
        <!-- card -->
    </div>
    <!-- card-body -->
</div>

<!-- Modal -->
<div class="modal fade" bind:this={modalEle} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="credit-card"
                            class="wd-60 ht-60" /></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">{modalTitle}</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">
                            {#if paymentObj.isRefunded}
                            <!-- content here -->
                            {:else if localUserData.role == "orgAdmin" && paymentObj.freezePayment}
                            Payment Details
                            {#if MODULES.hasAccess(MODULES.UPDATE_UNPAID_RECEIPTS) ||
                            MODULES.hasAccess(MODULES.UPDATE_PAID_RECEIPTS)}
                            <a on:click={()=> {
                                paymentObj.freezePayment = false;
                                modalTitle = "Edit Payment";
                                _$(
                                elemPaymentType
                                )[0].selectize.enable();
                                }}
                                style="cursor:pointer;"
                                class="btn-edit-batch"
                                ><i class="wd-20" data-feather="edit-2" />
                            </a>
                            {/if}
                            {#if MODULES.hasAccess(MODULES.DELETE_PAID_RECEIPT)}
                            <a on:click={()=>
                                deletePaymentById(
                                paymentObj._id,
                                true
                                )}
                                style="cursor:pointer;"
                                class="btn-edit-batch"
                                ><i class="wd-20" data-feather="trash" />
                            </a>
                            {/if}
                            {:else}
                            Enter your Payment details below.
                            {/if}
                        </p>
                    </div>
                </div>
                <!-- media -->
            </div>
            <!-- modal-header -->

            <div class="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                {#if paymentStatus != "FREEZE" && paymentObj._id && paymentObj.paymentDate > new Date()}
                <div class="form-group" style="display: flex;
            margin-top: -20px;
            margin-bottom: -18px;
            flex-direction: row-reverse;">
                    <!-- <div>
                    <p class="tx-13 tx-color-03 mg-b-0">Cannot Pay Future installments. Please change Payment date to <a href="#" on:click|preventDefault={setInstallmentDateAsToday}>Today</a></p>
                </div> -->
                    <button on:click={setInstallmentDateAsToday} type="button" class="btn btn-primary">
                        <ButtonLoader {isLoading} btnText="Click here to Pay now" />
                    </button>
                </div>
                {/if}
                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Payment Type <span
                                class="required-field">*</span></label>
                        <select disabled={paymentObj.freezePayment} bind:this={elemPaymentType} class="">
                            <option selected value="cash">Cash</option>
                            <option value="cheque">Cheque</option>
                            <!-- <option value="dd">DD</option> -->
                            <option value="onlinePay">Online Payment</option>
                            <option value="upi">UPI</option>
                            <option value="card">Card</option>
                        </select>
                        {#if errorObj.paymentType}
                        <div class="parsley-errors-list">
                            {errorObj.paymentType}
                        </div>
                        {/if}
                        {#if paymentObj.paymentType == "cheque"}
                        <div class="text-primary">
                            To Clear Cheque please Visit PDC section.
                        </div>
                        {/if}
                    </div>
                    {#if paymentObj.paymentType == "cash" || paymentObj.paymentType == "dd" || paymentObj.paymentType ==
                    "onlinePay" || paymentObj.paymentType == "upi" || paymentObj.paymentType == "card"}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Payment Date<span
                                class="required-field">*</span></label>
                        <input onkeydown="return false" disabled={paymentObj.freezePayment || !paymentObj.batchId ||
                            !paymentObj.studentId || modalTitle.toLowerCase()=="refund payment" }
                            on:change={updatePaymentDate} type="date" bind:value={paymentDateStr} on:blur={()=>
                        clearError("paymentDate")}
                        class="form-control"
                        class:parsley-error={errorObj.paymentDate}
                        placeholder="Select Date"
                        />
                        {#if errorObj.paymentDate}
                        <div class="parsley-errors-list">
                            {errorObj.paymentDate}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {#if paymentObj.paymentType == "cheque"}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Cheque Date<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment || !paymentObj.batchId || !paymentObj.studentId}
                            on:change={updatePaymentDate} type="date" bind:value={paymentDateStr} on:blur={()=>
                        clearError("paymentDate")}
                        class="form-control"
                        class:parsley-error={errorObj.paymentDate}
                        placeholder="Select Date"
                        />
                        {#if errorObj.paymentDate}
                        <div class="parsley-errors-list">
                            {errorObj.paymentDate}
                        </div>
                        {/if}
                    </div>
                    {/if}
                </div>
                {#if paymentObj.paymentType == "cash"}
                <div class="row">
                    {#if paymentObj.paymentDate > new Date()}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Installment
                            Amount<span class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.installmentAmount}
                            type="number" on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("installmentAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.installmentAmount}
                        placeholder="Enter
                        Installment
                        Amount"
                        />
                        {#if errorObj.installmentAmount}
                        <div class="parsley-errors-list">
                            {errorObj.installmentAmount}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {#if !(paymentObj.paymentDate > new Date())}
                    {#if paymentObj._id}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Installment
                            Amount<span class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.installmentAmount}
                            type="number" on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("installmentAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.installmentAmount}
                        placeholder="Enter
                        Installment
                        Amount"
                        />
                        {#if errorObj.installmentAmount}
                        <div class="parsley-errors-list">
                            {errorObj.installmentAmount}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Amount<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment || !paymentObj.batchId}
                            bind:value={paymentObj.paidAmount} type="number" on:keyup={()=> updatePaidStatus()}
                        on:blur={() => clearError("paidAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.paidAmount}
                        placeholder="Enter Amount"
                        />
                        {#if errorObj.paidAmount}
                        <div class="parsley-errors-list">
                            {errorObj.paidAmount}
                        </div>
                        {/if}
                    </div>
                    <div class="form-group col-md-6" class:d-none={modalTitle.toLowerCase()=="refund payment" }>
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Branch List<span
                                class="required-field">*</span></label>
                        <select id="elembranchSelect_aaa" disabled={paymentObj.freezePayment}
                            bind:this={elembranchSelect} class="info form-control" />
                        {#if errorObj.branchlistError}
                        <div class="parsley-errors-list">
                            {errorObj.branchlistError}
                        </div>
                        {/if}
                        <div class="parsley-errors-list">
                            {ifListNotPresent || ""}
                        </div>
                    </div>

                    {#if !(paymentObj.paymentDate > new Date())}
                    {#if receiptSeriesLists.length}
                    <!-- content here -->
                    <div class="form-group col-md-6" class:d-none={paymentObj.freezePayment ||
                        modalTitle.toLowerCase()=="refund payment" }>
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt Series<span
                                class="required-field">*</span></label>
                        <select disabled={paymentObj.freezePayment} bind:this={elemReceiptType}
                            class="info slectizedd form-control">
                            {#if errorObj.paymentType}
                            <div class="parsley-errors-list">
                                {errorObj.paymentType}
                            </div>
                            {/if}
                        </select>
                    </div>
                    {#if !paymentObj.freezePayment}
                    <!-- content here -->
                    <div class="form-group col-md-6">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Prefix<span
                                        class="required-field">*</span></label>
                                <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.prefix}
                                    type="text" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() =>
                                clearError(
                                "receiptNo"
                                )}
                                class="form-control"
                                class:parsley-error={errorObj.prefix}
                                placeholder="Enter Prefix"
                                />
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Series<span
                                        class="required-field">*</span></label>
                                <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.sequence}
                                    type="number" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() =>
                                clearError(
                                "sequence"
                                )}
                                class="form-control"
                                class:parsley-error={errorObj.receiptNo}
                                placeholder="Enter Sequence"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- {/if}
                {/if}
                {/if}
                {#if !(paymentObj.paymentDate > new Date())}
                {#if receiptSeriesLists.length}
                {#if !paymentObj.freezePayment} -->
                    <div class="form-group col-md-6" class:d-none={modalTitle.toLowerCase()=="refund payment" }>
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt No<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.receiptNo} type="text"
                            on:change={()=>
                        updatePaidStatus()}
                        on:blur={() =>
                        clearError("receiptNo")}
                        class="form-control"
                        class:parsley-error={errorObj.receiptNo}
                        placeholder="Enter Receipt No"
                        />
                        {#if errorObj.receiptNo}
                        <div class="parsley-errors-list">
                            {errorObj.receiptNo}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {/if}
                    {/if}
                    {/if}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Remarks
                            {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                            <span class="required-field">*</span>
                            {/if}
                        </label>
                        <input disabled={paymentObj.freezePayment || !paymentObj.batchId} bind:value={paymentObj.remark}
                            type="textarea" on:blur={()=> clearError("remark")}
                        class="form-control"
                        class:parsley-error={errorObj.remark}
                        placeholder="Add Remarks..."
                        />
                        {#if errorObj.remark}
                        <div class="parsley-errors-list">
                            {errorObj.remark}
                        </div>
                        {/if}
                    </div>
                </div>
                <div class="row" />
                <div class="row" />
                {/if}

                {#if paymentObj.paymentType == "cheque"}
                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Cheque No<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment} maxlength="6" bind:value={paymentObj.chequeNo}
                            type="text" on:change={()=> updatePaidStatus()}
                        on:blur={() => clearError("chequeNo")}
                        class="form-control"
                        class:parsley-error={errorObj.chequeNo}
                        placeholder="Enter Cheque No"
                        />
                        {#if errorObj.chequeNo}
                        <div class="parsley-errors-list">
                            {errorObj.chequeNo}
                        </div>
                        {/if}
                    </div>

                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Bank Name<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.bankName} type="text"
                            on:change={()=> updatePaidStatus()}
                        on:blur={() => clearError("bankName")}
                        class="form-control"
                        class:parsley-error={errorObj.bankName}
                        placeholder="Enter Bank Name"
                        />
                        {#if errorObj.bankName}
                        <div class="parsley-errors-list">
                            {errorObj.bankName}
                        </div>
                        {/if}
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Cheque Amount<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.installmentAmount}
                            type="number" on:change={()=> updatePaidStatus()}
                        on:blur={() => clearError("installmentAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.installmentAmount}
                        placeholder="Enter Cheque Amount"
                        />
                        {#if errorObj.installmentAmount}
                        <div class="parsley-errors-list">
                            {errorObj.installmentAmount}
                        </div>
                        {/if}
                    </div>
                    {#if paymentObj._id}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Cleared
                            Amount</label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.paidAmount} type="number"
                            on:keyup={()=> updatePaidStatus()}
                        on:blur={() => clearError("paidAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.paidAmount}
                        placeholder="Cleared Amount"
                        />
                        {#if errorObj.paidAmount}
                        <div class="parsley-errors-list">
                            {errorObj.paidAmount}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Branch List<span
                                class="required-field">*</span></label>
                        <select disabled={paymentObj.freezePayment} bind:this={elembranchSelect}
                            class="info slectizebranch form-control" />
                        {#if errorObj.branchlistError}
                        <div class="parsley-errors-list">
                            {errorObj.branchlistError}
                        </div>
                        {/if}
                        <div class="parsley-errors-list">
                            {ifListNotPresent || ""}
                        </div>
                    </div>
                </div>
                <div class="row">
                    {#if receiptSeriesLists.length}
                    <!-- content here -->
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt Series<span
                                class="required-field">*</span></label>
                        <select disabled={paymentObj.freezePayment} bind:this={elemReceiptType}
                            class="info slectizedd form-control">
                            {#if errorObj.paymentType}
                            <div class="parsley-errors-list">
                                {errorObj.paymentType}
                            </div>
                            {/if}
                        </select>
                    </div>
                    {#if !paymentObj.freezePayment}
                    <div class="form-group col-md-6">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Prefix</label>
                                <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.prefix}
                                    type="text" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() =>
                                clearError("prefix")}
                                class="form-control"
                                class:parsley-error={errorObj.prefix}
                                placeholder="Enter Prefix"
                                />
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Sequence</label>
                                <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.sequence}
                                    type="number" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() =>
                                clearError("sequence")}
                                class="form-control"
                                class:parsley-error={errorObj.receiptNo}
                                placeholder="Enter Sequence"
                                />
                            </div>
                        </div>
                    </div>
                    {/if}
                    {/if}
                    {#if receiptSeriesLists.length}
                    <!-- content here -->
                    {#if !paymentObj.freezePayment}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt No<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.receiptNo} type="text"
                            on:change={()=> updatePaidStatus()}
                        on:blur={() => clearError("receiptNo")}
                        class="form-control"
                        class:parsley-error={errorObj.receiptNo}
                        placeholder="Enter Receipt No"
                        />
                        {#if errorObj.receiptNo}
                        <div class="parsley-errors-list">
                            {errorObj.receiptNo}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {/if}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Remarks
                            {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                            <span class="required-field">*</span>
                            {/if}
                        </label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.remark} type="textarea"
                            on:blur={()=> clearError("remark")}
                        class="form-control"
                        class:parsley-error={errorObj.remark}
                        placeholder="Add Remarks..."
                        />
                        {#if errorObj.remark}
                        <div class="parsley-errors-list">
                            {errorObj.remark}
                        </div>
                        {/if}
                    </div>
                </div>

                <div class="row" />
                {/if}
                {#if paymentObj.paymentType == "dd"}
                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Demand Draft
                            Date<span class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment} on:change={updatePaymentDate} type="date"
                            bind:value={paymentDateStr} on:blur={()=> clearError("paymentDate")}
                        class="form-control"
                        class:parsley-error={errorObj.paymentDate}
                        placeholder="Select Date"
                        />
                        {#if errorObj.paymentDate}
                        <div class="parsley-errors-list">
                            {errorObj.paymentDate}
                        </div>
                        {/if}
                    </div>
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Demand Draft
                            No</label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.ddNo} type="text"
                            on:change={()=> updatePaidStatus()}
                        on:blur={() => clearError("ddNo")}
                        class="form-control"
                        class:parsley-error={errorObj.ddNo}
                        placeholder="Enter Demand Draft No"
                        />
                        {#if errorObj.ddNo}
                        <div class="parsley-errors-list">
                            {errorObj.ddNo}
                        </div>
                        {/if}
                    </div>
                </div>
                <div class="row">
                    {#if paymentObj.paymentDate > new Date()}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Demand Draft
                            Amount</label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.installmentAmount}
                            type="number" on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("installmentAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.installmentAmount}
                        placeholder="Enter Demand
                        Draft Amount"
                        />
                        {#if errorObj.installmentAmount}
                        <div class="parsley-errors-list">
                            {errorObj.installmentAmount}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {#if !(paymentObj.paymentDate > new Date())}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Demand Draft
                            Amount</label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.installmentAmount}
                            type="number" on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("installmentAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.installmentAmount}
                        placeholder="Enter Demand
                        Draft Amount"
                        />
                        {#if errorObj.installmentAmount}
                        <div class="parsley-errors-list">
                            {errorObj.installmentAmount}
                        </div>
                        {/if}
                    </div>
                    {#if paymentObj._id}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Cleared
                            Amount</label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.paidAmount} type="number"
                            on:keyup={()=> updatePaidStatus()}
                        on:blur={() => clearError("paidAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.paidAmount}
                        placeholder="Cleared Amount"
                        />
                        {#if errorObj.paidAmount}
                        <div class="parsley-errors-list">
                            {errorObj.paidAmount}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {/if}
                </div>
                <div class="row">
                    {#if !(paymentObj.paymentDate > new Date())}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Branch List<span
                                class="required-field">*</span></label>
                        <select disabled={paymentObj.freezePayment} bind:this={elembranchSelect}
                            class="elembranchSelect info slectizebranch form-control" />
                        {#if errorObj.branchlistError}
                        <div class="parsley-errors-list">
                            {errorObj.branchlistError}
                        </div>
                        {/if}
                        <div class="parsley-errors-list">
                            {ifListNotPresent || ""}
                        </div>
                    </div>

                    {#if receiptSeriesLists.length}
                    <!-- content here -->
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt Series (Next
                            Reciept Series For
                            Payment Only)<span class="required-field">*</span></label>
                        <select disabled={paymentObj.freezePayment} bind:this={elemReceiptType}
                            class="info slectizedd form-control">
                            {#if errorObj.paymentType}
                            <div class="parsley-errors-list">
                                {errorObj.paymentType}
                            </div>
                            {/if}
                        </select>
                    </div>
                    {/if}
                    {/if}
                </div>
                <div class="row">
                    {#if !paymentObj.freezePayment}
                    <!-- content here -->
                    <div class="form-group col-md-6">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Prefix</label>
                                <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.prefix}
                                    type="text" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() => clearError("prefix")}
                                class="form-control"
                                class:parsley-error={errorObj.prefix}
                                placeholder="Enter Prefix"
                                />
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt
                                    No</label>
                                <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.sequence}
                                    type="number" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() =>
                                clearError("sequence")}
                                class="form-control"
                                class:parsley-error={errorObj.receiptNo}
                                placeholder="Enter Sequence"
                                />
                            </div>
                        </div>

                        <!-- {#if errorObj.receiptNo}
                                                        <div class="parsley-errors-list">{errorObj.receiptNo}</div>
                                                    {/if} -->
                    </div>
                    {/if}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Remarks
                            {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                            <span class="required-field">*</span>
                            {/if}
                        </label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.remark} type="textarea"
                            on:blur={()=> clearError("remark")}
                        class="form-control"
                        class:parsley-error={errorObj.remark}
                        placeholder="Add Remarks..."
                        />
                        {#if errorObj.remark}
                        <div class="parsley-errors-list">
                            {errorObj.remark}
                        </div>
                        {/if}
                    </div>
                </div>
                {/if}
                {#if paymentObj.paymentType == "onlinePay"}
                <div class="row">
                    {#if paymentObj.paymentDate > new Date()}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Installment
                            Amount<span class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.installmentAmount}
                            type="number" on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("installmentAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.installmentAmount}
                        placeholder="Enter
                        Installment
                        Amount"
                        />
                        {#if errorObj.installmentAmount}
                        <div class="parsley-errors-list">
                            {errorObj.installmentAmount}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {#if !(paymentObj.paymentDate > new Date())}
                    {#if paymentObj._id}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Installment
                            Amount<span class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.installmentAmount}
                            type="number" on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("installmentAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.installmentAmount}
                        placeholder="Enter
                        Installment
                        Amount"
                        />
                        {#if errorObj.installmentAmount}
                        <div class="parsley-errors-list">
                            {errorObj.installmentAmount}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Amount<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment || !paymentObj.batchId}
                            bind:value={paymentObj.paidAmount} type="number" on:keyup={()=> updatePaidStatus()}
                        on:blur={() => clearError("paidAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.paidAmount}
                        placeholder="Enter Amount"
                        />
                        {#if errorObj.paidAmount}
                        <div class="parsley-errors-list">
                            {errorObj.paidAmount}
                        </div>
                        {/if}
                    </div>

                    <div class="form-group col-md-6" class:d-none={modalTitle.toLowerCase()=="refund payment" }>
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Branch List<span
                                class="required-field">*</span></label>
                        <select disabled={paymentObj.freezePayment} bind:this={elembranchSelect}
                            class="info slectizebranch form-control" />
                        {#if errorObj.branchlistError}
                        <div class="parsley-errors-list">
                            {errorObj.branchlistError}
                        </div>
                        {/if}
                        <div class="parsley-errors-list">
                            {ifListNotPresent || ""}
                        </div>
                    </div>

                    {#if receiptSeriesLists.length}
                    <!-- content here -->
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt Series (Next
                            Reciept Series For
                            Payment Only)<span class="required-field">*</span></label>
                        <select disabled={paymentObj.freezePayment} bind:this={elemReceiptType}
                            class="info slectizedd form-control">
                            {#if errorObj.paymentType}
                            <div class="parsley-errors-list">
                                {errorObj.paymentType}
                            </div>
                            {/if}
                        </select>
                    </div>
                    {#if !paymentObj.freezePayment}
                    <!-- content here -->
                    <div class="form-group col-md-6">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Prefix</label>
                                <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.prefix}
                                    type="text" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() =>
                                clearError("prefix")}
                                class="form-control"
                                class:parsley-error={errorObj.prefix}
                                placeholder="Enter Prefix"
                                />
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt No
                                </label>
                                <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.sequence}
                                    type="number" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() =>
                                clearError("sequence")}
                                class="form-control"
                                class:parsley-error={errorObj.receiptNo}
                                placeholder="Enter Sequence"
                                />
                            </div>
                        </div>
                    </div>
                    {/if}
                    {/if}
                    {#if receiptSeriesLists.length}
                    {#if !paymentObj.freezePayment}
                    <div class="form-group col-md-6" class:d-none={modalTitle.toLowerCase()=="refund payment" }>
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt No<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.receiptNo} type="text"
                            on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("receiptNo")}
                        class="form-control"
                        class:parsley-error={errorObj.receiptNo}
                        placeholder="Enter Receipt No"
                        />
                        {#if errorObj.receiptNo}
                        <div class="parsley-errors-list">
                            {errorObj.receiptNo}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {/if}
                    {/if}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Reference No</label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.refNo} type="text"
                            on:change={()=> updatePaidStatus()}
                        on:blur={() => clearError("refNo")}
                        class="form-control"
                        class:parsley-error={errorObj.refNo}
                        placeholder="Enter Reference No"
                        />
                        {#if errorObj.refNo}
                        <div class="parsley-errors-list">
                            {errorObj.refNo}
                        </div>
                        {/if}
                    </div>
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Remarks
                            {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                            <span class="required-field">*</span>
                            {/if}
                        </label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.remark} type="textarea"
                            on:blur={()=> clearError("remark")}
                        class="form-control"
                        class:parsley-error={errorObj.remark}
                        placeholder="Add Remarks..."
                        />
                        {#if errorObj.remark}
                        <div class="parsley-errors-list">
                            {errorObj.remark}
                        </div>
                        {/if}
                    </div>
                </div>
                <div class="row" />
                <div class="row" />
                {/if}
                {#if paymentObj.paymentType == "upi"}
                <div class="row">
                    {#if paymentObj.paymentDate > new Date()}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Installment
                            Amount<span class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment || modalTitle.toLowerCase()=="refund payment" }
                            bind:value={paymentObj.installmentAmount} type="number" on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("installmentAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.installmentAmount}
                        placeholder="Enter Installment Amount"
                        />
                        {#if errorObj.installmentAmount}
                        <div class="parsley-errors-list">
                            {errorObj.installmentAmount}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {#if !(paymentObj.paymentDate > new Date())}
                    {#if paymentObj._id}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Installment
                            Amount<span class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.installmentAmount}
                            type="number" on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("installmentAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.installmentAmount}
                        placeholder="Enter
                        Installment
                        Amount"
                        />
                        {#if errorObj.installmentAmount}
                        <div class="parsley-errors-list">
                            {errorObj.installmentAmount}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Amount<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment || !paymentObj.batchId}
                            bind:value={paymentObj.paidAmount} type="number" on:keyup={()=> updatePaidStatus()}
                        on:blur={() => clearError("paidAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.paidAmount}
                        placeholder="Enter Amount"
                        />
                        {#if errorObj.paidAmount}
                        <div class="parsley-errors-list">
                            {errorObj.paidAmount}
                        </div>
                        {/if}
                    </div>
                    <div class="form-group col-md-6" class:d-none={modalTitle.toLowerCase()=="refund payment" }>
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Branch List<span
                                class="required-field">*</span></label>
                        <select disabled={paymentObj.freezePayment} bind:this={elembranchSelect}
                            class="info slectizebranch form-control" />
                        {#if errorObj.branchlistError}
                        <div class="parsley-errors-list">
                            {errorObj.branchlistError}
                        </div>
                        {/if}
                        <div class="parsley-errors-list">
                            {ifListNotPresent || ""}
                        </div>
                    </div>
                    {/if}
                    {#if !(paymentObj.paymentDate > new Date())}
                    {#if receiptSeriesLists.length}
                    <!-- content here -->
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt Series (Next
                            Reciept Series For
                            Payment Only)<span class="required-field">*</span></label>
                        <select disabled={paymentObj.freezePayment} bind:this={elemReceiptType}
                            class="info slectizedd form-control">
                            {#if errorObj.paymentType}
                            <div class="parsley-errors-list">
                                {errorObj.paymentType}
                            </div>
                            {/if}
                        </select>
                    </div>
                    {#if !paymentObj.freezePayment}
                    <!-- content here -->
                    <div class="form-group col-md-6">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Prefix</label>
                                <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.prefix}
                                    type="text" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() =>
                                clearError("prefix")}
                                class="form-control"
                                class:parsley-error={errorObj.prefix}
                                placeholder="Enter Prefix"
                                />
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt
                                    No</label>
                                <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.sequence}
                                    type="number" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() =>
                                clearError("sequence")}
                                class="form-control"
                                class:parsley-error={errorObj.receiptNo}
                                placeholder="Enter Sequence"
                                />
                            </div>
                        </div>

                        <!-- {#if errorObj.receiptNo}
                        <div class="parsley-errors-list">{errorObj.receiptNo}</div>
                    {/if} -->
                    </div>
                    <!-- {/if}
{/if}
{/if}
{#if !(paymentObj.paymentDate > new Date())}
{#if receiptSeriesLists.length}
{#if !paymentObj.freezePayment} -->
                    <div class="form-group col-md-6" class:d-none={modalTitle.toLowerCase()=="refund payment" }>
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt No<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.receiptNo} type="text"
                            on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("receiptNo")}
                        class="form-control"
                        class:parsley-error={errorObj.receiptNo}
                        placeholder="Enter Receipt No"
                        />
                        {#if errorObj.receiptNo}
                        <div class="parsley-errors-list">
                            {errorObj.receiptNo}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {/if}
                    {/if}
                    <div class="form-group col-md-6" class:d-none={modalTitle.toLowerCase()=="refund payment" }>
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Trasaction
                            No</label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.upiNo} type="text"
                            on:change={()=> updatePaidStatus()}
                        on:blur={() => clearError("upiNo")}
                        class="form-control"
                        class:parsley-error={errorObj.upiNo}
                        placeholder="Enter Transaction No"
                        />
                        {#if errorObj.upiNo}
                        <div class="parsley-errors-list">
                            {errorObj.upiNo}
                        </div>
                        {/if}
                    </div>
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Remarks
                            {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                            <span class="required-field">*</span>
                            {/if}
                        </label>
                        <input disabled={paymentObj.freezePayment} bind:value={paymentObj.remark} type="textarea"
                            on:blur={()=> clearError("remark")}
                        class="form-control"
                        class:parsley-error={errorObj.remark}
                        placeholder="Add Remarks..."
                        />
                        {#if errorObj.remark}
                        <div class="parsley-errors-list">
                            {errorObj.remark}
                        </div>
                        {/if}
                    </div>
                </div>
                <div class="row" />
                <div class="row" />
                <!-- <div class="row">

</div> -->
                {/if}

                {#if paymentObj.paymentType == "card"}
                <div class="row">
                    {#if paymentObj.paymentDate > new Date()}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Installment
                            Amount<span class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment } bind:value={paymentObj.installmentAmount}
                            type="number" on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("installmentAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.installmentAmount}
                        placeholder="Enter
                        Installment
                        Amount"
                        />
                        {#if errorObj.installmentAmount}
                        <div class="parsley-errors-list">
                            {errorObj.installmentAmount}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {#if !(paymentObj.paymentDate > new Date())}
                    {#if paymentObj._id}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Installment
                            Amount<span class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment } bind:value={paymentObj.installmentAmount}
                            type="number" on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("installmentAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.installmentAmount}
                        placeholder="Enter
                        Installment
                        Amount"
                        />
                        {#if errorObj.installmentAmount}
                        <div class="parsley-errors-list">
                            {errorObj.installmentAmount}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Amount<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment || !paymentObj.batchId}
                            bind:value={paymentObj.paidAmount} type="number" on:keyup={()=> updatePaidStatus()}
                        on:blur={() => clearError("paidAmount")}
                        class="form-control"
                        class:parsley-error={errorObj.paidAmount}
                        placeholder="Enter Amount"
                        />
                        {#if errorObj.paidAmount}
                        <div class="parsley-errors-list">
                            {errorObj.paidAmount}
                        </div>
                        {/if}
                    </div>
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Branch List<span
                                class="required-field">*</span></label>
                        <select disabled={paymentObj.freezePayment } bind:this={elembranchSelect}
                            class="info slectizebranch form-control" />
                        {#if errorObj.branchlistError}
                        <div class="parsley-errors-list">
                            {errorObj.branchlistError}
                        </div>
                        {/if}
                        <div class="parsley-errors-list">
                            {ifListNotPresent || ""}
                        </div>
                    </div>
                    {/if}
                    {#if !(paymentObj.paymentDate > new Date())}
                    {#if receiptSeriesLists.length}
                    <!-- content here -->
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt Series (Next
                            Reciept Series For
                            Payment Only)</label>
                        <select disabled={paymentObj.freezePayment } bind:this={elemReceiptType}
                            class="info slectizedd form-control">
                            {#if errorObj.paymentType}
                            <div class="parsley-errors-list">
                                {errorObj.paymentType}
                            </div>
                            {/if}
                        </select>
                    </div>
                    {#if !paymentObj.freezePayment}
                    <div class="form-group col-md-6">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Prefix</label>
                                <input disabled={paymentObj.freezePayment || true } bind:value={paymentObj.prefix}
                                    type="text" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() =>
                                clearError("prefix")}
                                class="form-control"
                                class:parsley-error={errorObj.prefix}
                                placeholder="Enter Prefix"
                                />
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt
                                    No</label>
                                <input disabled={paymentObj.freezePayment || true } bind:value={paymentObj.sequence}
                                    type="number" on:keyup={()=>
                                populateReceiptNumber()}
                                on:blur={() =>
                                clearError("sequence")}
                                class="form-control"
                                class:parsley-error={errorObj.receiptNo}
                                placeholder="Enter Sequence"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6" class:d-none={modalTitle.toLowerCase()=="refund payment" }>
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt No<span
                                class="required-field">*</span></label>
                        <input disabled={paymentObj.freezePayment || true } bind:value={paymentObj.receiptNo}
                            type="text" on:change={()=> updatePaidStatus()}
                        on:blur={() =>
                        clearError("receiptNo")}
                        class="form-control"
                        class:parsley-error={errorObj.receiptNo}
                        placeholder="Enter Receipt No"
                        />
                        {#if errorObj.receiptNo}
                        <div class="parsley-errors-list">
                            {errorObj.receiptNo}
                        </div>
                        {/if}
                    </div>
                    {/if}
                    {/if}
                    {/if}
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Transaction
                            No</label>
                        <input disabled={paymentObj.freezePayment } bind:value={paymentObj.cardNo} type="text"
                            on:change={()=> updatePaidStatus()}
                        on:blur={() => clearError("cardNo")}
                        class="form-control"
                        class:parsley-error={errorObj.cardNo}
                        placeholder="Enter Transaction No"
                        />
                        {#if errorObj.cardNo}
                        <div class="parsley-errors-list">
                            {errorObj.cardNo}
                        </div>
                        {/if}
                    </div>
                    <div class="form-group col-md-6">
                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Remarks
                            {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                            <span class="required-field">*</span>
                            {/if}
                        </label>
                        <input disabled={paymentObj.freezePayment } bind:value={paymentObj.remark} type="textarea"
                            on:blur={()=> clearError("remark")}
                        class="form-control"
                        class:parsley-error={errorObj.remark}
                        placeholder="Add Remarks..."
                        />
                        {#if errorObj.remark}
                        <div class="parsley-errors-list">
                            {errorObj.remark}
                        </div>
                        {/if}
                    </div>
                </div>
                {/if}

                <!-- <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="form-row">
                    <div class="form-group col-md-6 m-1">
                        <div class="custom-control custom-radio">
                            <input type="radio" id="isOnline" value="online" on:click={()=>filterByReceiptType("online")} bind:group={paymentObj.receipt} name="receipt" class="custom-control-input">
                            <label class="custom-control-label" for="isOnline">Online Reciept</label>
                        </div>
                    </div>
                    <div class="form-group col-md-6 m-0">
                        <div class="custom-control custom-radio">
                            <input type="radio" id="isOffline" value="offline" on:click={()=>filterByReceiptType("offline")} bind:group={paymentObj.receipt} name="receipt" class="custom-control-input">
                            <label class="custom-control-label" for="isOffline">Offline Reciept</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>     -->
            </div>
            <!-- modal-body -->

            <div class="modal-footer pd-x-20 pd-y-15">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    on:click={restModal}>{paymentObj.freezePayment ? "CLOSE" : "CANCEL"}
                    <!-- <i data-feather="x" class="wd-10 mg-l-5"></i> -->
                </button>
                {#if modalTitle.toLowerCase() == "refund payment"}
                <!-- Refund Flow -->
                <button disabled={isLoading} on:click={addRefundPaymentBtn} type="button" class="btn btn-primary">
                    <ButtonLoader {isLoading} btnText="REFUND" />
                </button>
                {:else}
                <!-- Normal Flow -->
                {#if paymentObj.paymentType == "cheque"}
                <button disabled={isSaveLoading || paymentObj.freezePayment || isLoading}
                    on:click={saveAndAddAnotherPayment} type="button" class:d-none={paymentObj.freezePayment}
                    class="btn btn-primary">
                    <i class="far fa-credit-card" />

                    <ButtonLoader isLoading={isSaveLoading} btnText="SAVE AND ADD ANOTHER" />
                </button>
                {/if}
                <button disabled={isLoading || paymentObj.freezePayment || isSaveLoading} on:click={addUpdatePaymentBtn}
                    type="button" class:d-none={paymentObj.freezePayment} class="btn btn-primary">
                    {#if paymentStatus == "FREEZE"}
                    <i class="far fa-credit-card" />
                    <ButtonLoader {isLoading} btnText="CONFIRM PAYMENT" />
                    <!-- <i data-feather="thumbs-up" class="wd-10 mg-l-5"></i> -->
                    {:else}
                    <ButtonLoader {isLoading} btnText="SAVE INSTALLMENT" />
                    <!-- <i data-feather="arrow-right" class="wd-10 mg-l-5"></i> -->
                    {/if}
                </button>
                {/if}
            </div>
        </div>
        <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
</div>
<!-- modal -->
<!-- Modal -->
{#if toggleModal}
<ReceiptCom {paymentId} {studentId} {batchId} {balanceAmount} {totalPaidAmount} on:modalClose={()=> toggleModal = false}
    />
    {/if}

    {#if togglePrintReceiptModal}
    <PrintReceiptCom {paymentId} {studentId} {batchId} {paymentList} {balanceAmount} {localUserData} {totalPaidAmount}
        {studentBatchInfo} on:modalClose={()=> togglePrintReceiptModal = false} />
        {/if}
