<script>
    import { createEventDispatcher, onMount, afterUpdate, tick, beforeUpdate } from 'svelte';
    import ButtonLoader from "../../utils/button_loader.svelte"
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import print from "print-js";
    export let paymentId;
    export let studentId;
    export let batchId;
    export let totalPaidAmount;
    export let balanceAmount;
    export let showReceipt;
    export let onModalClose;
    let receiptModal;
    let StudentsData;
    let PaymentData;
    let BatchesData;
    let installmentAmount;
    let installmentDate;
    let name = '';
    let batchName = '';
    let mobileNo = '';
    let receiptNo = '';
    let paymentDate = '';
    let enrollmentNo = '';
    let paymentMode = '';
    let paidAmt = '';
    let payableAmt = '';
    let actualBatchFee = '';
    let InstallmentDetails;
    let bankList = []
    let bankName
    let bankBranchName
    let paymentType

    async function api_getPaymentById(paymentId) {

        let action = "payment.getPaymentById";
        var response = await api_handleApiCalls(action, { _id: paymentId });
        return response.data

    }

    async function api_getStudentById(studentId) {

        let action = "students.getStudentById";
        var response = await api_handleApiCalls(action, { _id: studentId });
        return response.data

    }

    async function api_getBatchById(batchId) {

        let action = "batch.getBatchById";
        var response = await api_handleApiCalls(action, { _id: batchId });
        return response.data

    }
    async function api_getOrganizationBanksList() {

        let result = await api_handleApiCalls("settings.fetchbank", {});
        if (result.status) {
            return result
        } else {
            return []
        }



    }

    // paymentDate: "2021-03-16T18:30:00.000Z"


    async function api_getNextInstallmentDataByBatchId(batchId, studentId) {

        let action = "payment.getNextInstallmentDataByBatchId";
        var response = await api_handleApiCalls(action, { batchId: batchId, studentId: studentId });
        return response.data;

    }

    let printReceipt = () => {
        _$('.page').css({ 'transform': 'scale(1)' });
        _$('#print-container').css({ 'padding-top': '0' });
        print({
            printable: 'print-container',
            type: 'html',
            header: false,
            scanStyles: false,
            scanStyles: false,
            style: `html, body {
                    padding : 0;
                    margin : 0;
                }
                 * {
                        box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                `,
            onPrintDialogClose: function () {
                _$('.page').css({ 'transform': 'scale(' + (window.innerHeight - 150) / (1122 / 2) + ')' });
                _$('#print-container').css({ 'padding-top': '15px' });
            }
        })
    }

    function convertDateObjToStr(date) {
        date = new Date(date);
        var month = "" + (date.getMonth() + 1);
        var day = "" + date.getDate();
        var year = date.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        var dateString = [day, month, year].join("-");
        return dateString;
    }

    let convertToINR = function (input) {
        let amount = Number(input);
        return amount.toLocaleString('en-IN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'INR'
        });
    }

    onMount(async () => {
        feather.replace();
        PaymentData = await api_getPaymentById(paymentId);
        StudentsData = await api_getStudentById(studentId);
        BatchesData = await api_getBatchById(batchId);
        InstallmentDetails = await api_getNextInstallmentDataByBatchId(batchId, studentId);
        name = StudentsData.name;
        installmentAmount = InstallmentDetails ? InstallmentDetails.installmentAmount ? convertToINR(InstallmentDetails.installmentAmount) : '-' : "-";
        installmentDate = InstallmentDetails ? InstallmentDetails.paymentDate ? convertDateObjToStr(InstallmentDetails.paymentDate) : '-' : "-";
        mobileNo = StudentsData.mobileNo;
        paidAmt = convertToINR(PaymentData.paidAmount);
        actualBatchFee = BatchesData.actualBatchFee;
        payableAmt = convertToINR(BatchesData.amount);
        enrollmentNo = StudentsData.enrollmentNo;
        paymentDate = convertDateObjToStr(PaymentData.paymentDate);
        receiptNo = PaymentData.receiptNo;
        paymentMode = PaymentData.paymentType;
        batchName = BatchesData.name;
        bankName = PaymentData.bankName
        paymentType = PaymentData.paymentType
        // console.log('PaymentData', PaymentData);
        // console.log('StudentsData', StudentsData);
        // console.log('BatchesData', BatchesData);
        await tick();

        let allbankdetails = await api_getOrganizationBanksList()

        allbankdetails = allbankdetails.data[0].bankdetail;

        bankList = allbankdetails.map(function (item) {

            return {
                ifsc: item.ifsc,
                name: item.bankName,
                branch: item.branchName
            }
        })

        if (PaymentData.chequeDepositedBranch) bankBranchName = PaymentData.chequeDepositedBranch
        else if (PaymentData.bankIFSCCode) bankBranchName = bankList.find(item => item.ifsc == PaymentData.bankIFSCCode).branch
        else bankBranchName = ""
        _$(receiptModal).modal("show");
    })
</script>

<!-- feather feather-printer -->


<div class="modal fade" bind:this={receiptModal} tabindex="-1" role="dialog" aria-labelledby="receipt-modal-label"
    style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header">
                <h6 class="modal-title" id="receipt-modal-label">Receipt</h6>
                <button type="button" class="close" on:click={onModalClose} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" style="display: flex;height: 100%;flex-direction: column;">
                <div id="print-container" style="    
                                    flex: 1;
                                    overflow: auto;
                                    display: flex;
                                    overflow-y: auto;
                                    overflow-x: hidden;
                                    justify-content: center;
                                    background:#ddd;
                                ">

                    <style>
                        * {
                            box-sizing: border-box;
                            -moz-box-sizing: border-box;
                        }

                        .print-receipt-tbl {
                            border-spacing: 0;
                            /* border-color: gray; */
                            /* border : 1px solid black; */
                        }

                        /* .table {
                            margin-top: 80px;
                            border: 1px solid black;
                        } */

                        .th,
                        .td {
                            border: 1px solid black;
                        }

                        .print-receipt-tbl td {
                            padding: 2px 5px;
                            /* border : 1px solid black; */
                        }

                        .page {
                            width: 21cm;
                            min-height: 148mm;
                            height: 148mm;
                            padding: 0;
                            margin: 0 auto;
                            border: 1px #D3D3D3 solid;
                            border-radius: 5px;
                            background: white;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                            background-size: contain;
                            background-repeat: no-repeat;
                            transform-origin: top center;
                            display: flex;
                            flex-direction: column;
                            overflow: hidden;
                        }

                        .p-body {
                            flex: 1;
                            display: flex;
                            background-repeat: no-repeat;
                            background-position: center;
                            width: 21cm;
                        }

                        /* .p-body-center {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                            } */

                        .p-body-divName {
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                        }

                        .p-body-divTable {
                            flex: 2;
                            display: flex;
                            flex-direction: row;
                        }

                        .p-header,
                        .p-footer {
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 21cm;
                        }

                        .p-body-left,
                        .p-body-right {
                            background-repeat: no-repeat;
                            background-size: contain;
                        }

                        @page {
                            size: A4;
                            margin: 0;
                        }

                        @media print {
                            .page {
                                margin: 0;
                                border-radius: initial;
                                width: initial;
                                min-height: initial;
                                page-break-after: always;
                            }
                        }
                    </style>

                    <div class="book">
                        <div class="page">
                            <div class="p-header">
                                <h4 style="text-align: center; margin-top: 30px;">
                                    Payment Receipt
                                </h4>
                            </div>
                            <!-- <div class="p-body"> -->


                            <!-- <div>
                            <div style="margin-left: 20px ;">
                                 <p>
                                    Name :
                                </p><br>
                                <p>
                                    Contact No.: 
                                </p>
                                
                            </div>
                           
                            <div class="p-body-divTable" style="text-align: center;">

                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Batches </th>
                                            <th>Amount (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                                <td>1</td>
                                                <td>Morning Batch</td>
                                                <td>12000/-</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr></tr>
                                        <tr>Total</tr>
                                        <tr></tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div> -->

                            <!-- <div class="p-body-left">

                        </div>
                        <div class="p-body-center">
                        </div>
                        <div class="p-body-right" style="justify-content: end;">
                            <div>
                                Date: 
                                Receipt No.: 
                            </div>
                        </div> -->
                            <!-- </div> -->
                            <!-- <div class="p-footer">

                    </div> -->
                            <div style="width: 100%; display: inline;">

                                <label style="padding-left: 20px;">Student's Name : {name}</label><label
                                    style="float: right; padding-right: 20px;">Date : {paymentDate}</label><br>
                                <label style="padding-left: 20px;">Mobile No. : {mobileNo}</label> <label
                                    style="float: right; padding-right: 20px;">Receipt No. : {receiptNo}</label><br>
                                <label style="padding-left: 20px;">Roll No. : {enrollmentNo}</label> <label
                                    style="float: right; padding-right: 20px;">Payment Mode : {paymentMode}</label>

                            </div>
                            {#if paymentType == "cheque"}
                            <div>
                                <label style="padding-left: 20px;">
                                    Bank: {bankName || "-"}{bankBranchName && ` (${bankBranchName})`}
                                </label>
                            </div>

                            {/if}
                            <table style="border: 1px solid black; border-collapse: collapse; margin: 20px;">
                                <thead>
                                    <tr>
                                        <th style="width: 5%; text-align: center; border: 1px solid black;">Sr. No.</th>
                                        <th style="text-align: center; border: 1px solid black;">Batches</th>
                                        <th style="width: 20%; text-align: center; border: 1px solid black;">Amount(Rs.)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border: 1px solid black; text-align: center">1</td>
                                        <td style="border: 1px solid black; padding-left:10px">{batchName}</td>
                                        <td style="border: 1px solid black; text-align: center;">{paidAmt}/-</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; text-align: center">2</td>
                                        <td style="border: 1px solid black;"></td>
                                        <td style="border: 1px solid black;"></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; text-align: center">3</td>
                                        <td style="border: 1px solid black;"></td>
                                        <td style="border: 1px solid black;"></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr style="padding-top: 10px; margin-top: 10px;">
                                        <td></td>
                                        <td style="text-align: end; margin-right: 10px; padding-right: 20px;">Total</td>
                                        <td style='text-align: center'>{paidAmt? paidAmt:"-"}/- </td>
                                    </tr>
                                </tfoot>
                            </table>
                            <div style="padding-left: 20px;">
                                <!-- <label>Batch Fees : {actualBatchFee? actualBatchFee : "-"}</label><br> -->
                                <label>Next Installment Fees : {installmentAmount}</label><br>
                                <label>Next Installment Date : {installmentDate}</label><br>
                                <label> Balance Fees : {convertToINR(balanceAmount)}</label>
                            </div>

                            <div style="text-align: end; padding-right: 20px;">

                                <div>
                                    -------------------------
                                </div>
                                <div>
                                    Student's Signature
                                </div>
                                <div style="padding-top:25px">
                                    -------------------------
                                </div>
                                <div>
                                    Faculty's Signature
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary tx-13" on:click={onModalClose}
                    data-dismiss="modal">Close</button>
                <button type="button" on:click={printReceipt} class="btn btn-primary tx-13">Print</button>
            </div>
        </div>
    </div>
</div>
