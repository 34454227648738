<script>
    import { Router, Link, links, Route } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import {pSuccess, pError, pConfirm} from "../utils/notify.svelte"
    import ButtonLoader from "../utils/button_loader.svelte";
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    let title = "";
    let search;
    export let selectedId = "";
    export let type
    export let sptObject = {}, errorObj = {};
    let inputFieldToFocus
    let sptList = [];
    let isLoading

    let mode = "create";

    let setSelected = async function (aSptItem) {
        selectedId = aSptItem._id;
        sptObject = aSptItem;
        title = aSptItem.title;
        mode = "edit";
        CKEDITOR.instances.essaySPT.setData(aSptItem.content);
        errorObj = {}
        await tick()
    }

    async function api_list(data) {

        let action = "testInstructions.editorDataList";
        var response = await api_handleApiCalls(action, data);
        return response;

    }
    async function api_addUpdate(data) {

        let action = "testInstructions.saveEditorData";
        var response = await api_handleApiCalls(action, data);
        return response;

    }
    async function api_delete(data) {

        let action = "testInstructions.removeEditorData";
        var response = await api_handleApiCalls(action, data);
        return response;

    }


    let onSave = async function () {
        if(isLoading) return;
        isLoading = true
        let content = CKEDITOR.instances.essaySPT.getData();
        if (!title.trim()) {
        	errorObj.title = "Title is required field";
        }
        if (!content.trim()) {
            errorObj.content = "Instructions is required field";
        }
        if (!(errorObj.title || errorObj.content)) {
            var data = {};
            if (selectedId) data._id = selectedId;
            data.title = title;
            data.content = content;
            data.type = type
            let res = await api_addUpdate(data)
            if (res.status) {
                pSuccess("", "Instructions saved successfully");
            } else {
                pError("", res.errMsg || res.error || "Something went wrong");
            }
            reloadList()    
            if(res.data?.insertedId){
                selectedId = res.data.insertedId;
                mode = "edit";
                
                sptObject = {
                    _id: selectedId,
                    title: title,
                    content: content
                };
                errorObj = {}
                await tick()
            }
            isLoading = false;
            return false;
        }
        isLoading = false;
    }

    let toolbar = [
        [
            "NewPage",
            "Preview",
            "-",
            "Bold",
            "Italic",
            "Strike",
            "NumberedList",
            "BulletedList",
            "-",
            "Table",
            "HorizontalRule",
            "Styles",
            "Format",
            "Maximize",
            "Image"
        ]
    ]

    let reloadList = async function () {
        let res = await api_list({search, type});
        console.log("res : ", res);
        sptList = res.data.result;
    }
    onMount(async function () {

        console.log(`----------------------------------------------- type (${type}) -----------------------------------------------------------`);

        reloadList()

        feather.replace();
        
         new PerfectScrollbar('.slist', {
            suppressScrollX: true
        });
         new PerfectScrollbar('.e-spt', {
            suppressScrollX: true
        });
        
        await tick();

        CKEDITOR.instances['essaySPT']?.destroy(false);

        CKEDITOR.replace('essaySPT', {
            placeholder: "Type test instructions here",
            toolbar: toolbar,
            height: 385,           
        });
        CKEDITOR.instances.essaySPT.on('blur', async function(event) {
            delete errorObj.content
            errorObj = errorObj
            await tick()
        })
        _$(inputFieldToFocus).focus()

    })

</script>


<div class="row no-gutters" style="height: 100%; ">
    <div class="col-sm-12 col-md-4"
        style=" border-right: 1px solid #ddd;display: flex;    flex-direction: column; background-color: #f8f9fc; 
    height: 100%;">
        <div class="spt-header">
            <i data-feather="search"></i>
            <div class="search-form">
                <input type="search" class="form-control" placeholder="Instruction" bind:value={search} on:input={reloadList}>
            </div><!-- search-form -->
            <div class="mg-10">
                <button style="background: transparent; border: none;outline: none !important;" on:click={()=>{
                    title = "";
                    selectedId = "";
                    CKEDITOR.instances.essaySPT.setData("");
                    mode = "create"
                    _$(inputFieldToFocus).focus()
                    }} class="nav-link"><i data-feather="plus" style="height: 15px;"></i> New
                </button>

            </div>
        </div>

        <div style="flex: 1;" class="slist">
            <ul class="list-unstyled media-list mg-b-0">

                {#each sptList as item}
                    <li class="media unread spt-list" class:active={selectedId == item._id} on:click={()=>setSelected(item)}>
                        <div class="media-body mg-l-15">
                            <h6 class="tx-13">{item.title}</h6>
                            <p class="tx-12 tx-color-03 mg-b-0">{@html item.content}</p>
                        </div><!-- media-body -->
                    </li>
                {/each}
            </ul>
        </div>

    </div>
    <div class="col-sm-12 col-md-8 e-spt" style="padding-top:56px;
    height: 100%;">
        <div class="conten-header" style="display: flex;justify-content: space-between;    z-index: 1; ">

            <input type="text" bind:this={inputFieldToFocus} class="form-control" bind:value={title} 
            placeholder={"Enter instruction title"}
            class:bd={errorObj.title}
            class:parsley-error={errorObj.title}
            on:blur={()=> {
                delete errorObj.title
                errorObj = errorObj
            }}>
            <button style="background: transparent; border: none;outline: none !important;" class="nav-link"
                on:click={onSave}
            ><i
                    data-feather="save" style="height: 15px;"></i><ButtonLoader isLoading={isLoading} btnText={(mode == "create" ? "Create"  : "Update")}/>
                    </button>
            <button style="background: transparent; border: none;outline: none !important;" class:nav-link={selectedId}
            disabled={!selectedId}
            on:click|stopPropagation={async () => {
                console.log('deleting', selectedId);
                if(!selectedId) return;
                pConfirm(
                  'Confirm delete?',
                  'Do you really want to delete these instructions?',
                  async () => {
                    let data = { _id: selectedId, type };
                    let result = await api_delete(data)
                    if (result.status) {
                        title = "";
                        selectedId = "";
                        CKEDITOR.instances.essaySPT.setData("");
                        mode = "create"
                        reloadList()
                        pSuccess("", result.data)
                    } else if (result.errMsg) {
                      pError('', result.errMsg);
                    } else {
                      pError('', 'something went wrong');
                    }
                  }
                );
              }}><i
                    data-feather="trash" style="height: 15px;"></i> Delete</button>
            <!-- {#if errorObj.title}
                <div class="parsley-errors-list mg-b-10">{errorObj.title}</div>
            {/if} -->
        </div>
        {#if errorObj.content}
        <div class="parsley-errors-list">{errorObj.content}</div>
        {/if}
        <div>
        <textarea class="form-control" name="essaySPT" rows="3" placeholder="Type test instructions here"></textarea>
        </div>
    </div>
</div>


<style>
    .spt-header {
        height: 55px;
        background-color: #fff;
        border-bottom: 1px solid rgba(72, 94, 144, 0.16);
        display: flex;
        align-items: center;
        padding: 0 20px;
    }

    .spt-header .search-form .form-control {
        border-width: 0;
        background-color: transparent;
        font-size: inherit;
        padding: 0;
        color: #1b2e4b;
    }

    .title{
        border:  none;
        outline: none;
        box-shadow: none;
    }
    .title:focus{
        box-shadow: none !important;
    }
    .title:hover{
        box-shadow: 0 0 0 0.2rem rgba(1,104,250,0.25) !important;
    }

    .spt-header .search-form {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .spt-list{
            cursor: pointer;    
            padding: 15px 20px;
            cursor: pointer;
            border-left: 4px solid #f8f9fc;
            z-index: 1000;
            border-bottom: 1px solid #e5e9f2;
    }

    .spt-list:hover{
        border-left-color: #0153c7;
    }

    .spt-list.active{
        border-left-color: #0168fa;
        background-color: #ecf4ff;
    }


    .conten-header{
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 55px;
        border-bottom: 1px solid rgba(72,94,144,0.16);
        display: flex;
        align-items: center;
        padding: 0 20px;
    }

</style>
