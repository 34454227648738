<script>
    import { createEventDispatcher, onMount, beforeUpdate } from 'svelte';
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import List from '../../studyMonitoring/list/list.svelte'
    const dispatch = createEventDispatcher();

    export let studentId;

    onMount(async () => {
        feather.replace();
        dispatch('studyMonitoringChart', {
            tabName: 'Monitoring Chart'
        });
    })

</script>
<!-- <BatchList isComponent={true} _id={studentId}/> -->

<Router>
    <Route path="">
        <List studentId={studentId} viewMode={true}/>
    </Route>
    <Route path="/:bradCrumbLink" let:params>
        <List studentId={studentId} viewMode={true} bradCrumbLink={params.bradCrumbLink}/>
    </Route>
</Router>

<!-- <StudyMonitoring /> -->
