<script>
    import { Router, links, link, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import { activeMenu } from "../stores";
    import ViewCounts from "./counts-card.svelte";
    import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
    import StudentCard from "./studentCard.svelte";
    import DatatablesCMP from "./datatables.svelte";
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import ButtonLoader from "./../utils/button_loader.svelte";
    import MODULES from "../utils/modules.js";
    import { userData } from ".././stores";
    import ExcelJS from 'exceljs'
    let debounce = (f, w) => {
        let t;
        return function fn(...args) {
            const later = () => {
                t = null;
                f(...args);
            };
            clearTimeout(t);
            t = setTimeout(later, w);
        };
    };
    let totalDue = 0;
    let totalPaid = 0;
    let totalUpcoming = 0;
    let totalCheck = 0;
    let totalOnline = 0;
    let totalCash = 0;
    // let totalLY = 0
    let roleIsTelecaller;
    let isSaveLoading = false;
    let modalEle;
    let paymentDateStr = "";
    let elemReceiptType;
    let pdcUpcomingTable;
    let pdcTodayTable;
    let dueTable;
    let upcomingTable;
    let modalTitle = "New Payment";
    let paymentObj = {
        studentId: "",
    };
    let addpaymentStatus = "DEFAULT";
    let paymentStatus;
    let receiptSeriesLists = [];
    let studentId = "";
    let batchId = "";
    let balanceAmountWithInstalment = 0;
    let _paidAmount = 0;
    let ifListNotPresent = "";
    let receiptselectizeInstance;
    let instanceExportStatus;
    let instanceExportStudents;
    let instanceExportBatches;
    let exportLoading = false;
    let elmSelectExportStatus;
    let elmSelectExportStudents;
    let elmSelectExportBatches;
    let elmDateExport;
    let receiptSeriesNumExport;
    let branchSelectizeInstance;
    let instanceFilterStatus;
    let instanceFilterStudents;
    let instanceFilterBatches;
    let elmSelectFilterStatus;
    let elmSelectFilterStudents;
    let elmSelectFilterBatches;
    let elmDateFilter;
    let receiptSeriesNumFilter;
    let filterBy = "today"; // all, totalDue totalPaid, totalUpcoming
    let elemBtnFilterBy;
    let studentSearch;
    let selectStudentList = [];
    let receiptId = "";
    let totalPaidAmount = 0;
    let balanceAmount = 0;
    let elemTableTransactions;
    let modalSelectStudent;
    let instanceTableTransactions;
    let branchListSelectArr = [];
    let errorObj = {};
    let isLoading = false;
    let emptyState = false;
    let isDateChanged = false;
    let batchesList;
    let filterModalThis;
    let filterElemBatch;
    let filterByPrefix;
    let filterElemUser;
    let filterStartDate;
    let filterByPaymentType;
    let elemPaymentType;
    let selectizeInstance;
    let selectizeBatchInstance;
    let elemSelectPaymentType;
    let studentSelectizeInstance;
    let batchSelectizeInstance;
    let elemBranchListSelect;
    let elemStudentSelect;
    let elemBatchSelect;
    let paymentList = [];
    let batchesList1 = [];
    let batchList = [];
    let studentBatchObj = {};
    let isStudentListLoading = false;

    let localUserData = {
        fullName: "...",
        role: "...",
        avatar: "/images/man.png",
        access: {
            home: false,
            students: false,
            settings: false,
        },
    };
    let ifBatchListNotPresent = "";

    const unsubscribe = userData.subscribe((value) => {
        localUserData = value;
        roleIsTelecaller = value.role == "telecaller";
    });
    function hideFilterModel(params) {
        _$("#filter-modal").modal("hide");
        assignmentObj.topic = "";
    }
    function showFilterModel(params) {
        _$("#filter-modal").modal("show");
    }
    let showHideFilterModal = () => {
        _$("#filter-modal").modal("show");
    };

    //   async function api_getBatchesList() {
    //         var resData = await api_handleApiCalls('batch.getMyBatches', {})
    //         if (resData.status) {
    //             return resData.data
    //         } else {
    //             return []
    //         }
    //     }

    $: paymentObj.batchId,
        paymentObj.studentId,
        enableDisabledropdowns(paymentObj.batchId, paymentObj.studentId);
    const enableDisabledropdowns = (batchId, studentId) => {
        if (!batchId || !studentId) {
            if (payTypeSelectizeInstance) payTypeSelectizeInstance.disable();
            if (branchSelectizeInstance) branchSelectizeInstance.disable();
        } else {
            if (payTypeSelectizeInstance) payTypeSelectizeInstance.enable();
            if (branchSelectizeInstance) branchSelectizeInstance.enable();
        }
    };

    let getStudentPaymentByBatchId = async function () {
        let paymentListData = await api_handleApiCalls(
            "payment.getStudentPaymentByBatchId",
            { studentId: paymentObj.studentId, batchId: paymentObj.batchId }
        );
        if (paymentListData.status) {
            paymentList = paymentListData.data
                .map(function (aPayment) {
                    return {
                        _id: aPayment._id,
                        studentId: aPayment.studentId,
                        batchId: aPayment.batchId,
                        paymentType: aPayment.paymentType,
                        installmentAmount: aPayment.installmentAmount,
                        paidAmount: aPayment.paidAmount,
                        receiptNo: aPayment.receiptNo,
                        paymentDate: aPayment.paymentDate,
                        freezePayment: aPayment.freezePayment,
                        freezePaymentDate: aPayment.freezePaymentDate,
                        chequeNo: aPayment.chequeNo,
                        bankName: aPayment.bankName,
                        ddNo: aPayment.ddNo,
                        refNo: aPayment.refNo,
                        upiNo: aPayment.upiNo,
                        cardNo: aPayment.cardNo,
                        receiptId: aPayment.receiptId || "",
                        branchId: aPayment.branchId || "",
                        prefix: aPayment.prefix || "",
                        remark: aPayment.remark || "",
                        sequence: aPayment.sequence || "",
                        isRefunded: aPayment.isRefunded || false,
                    };
                })
                .sort(function (aPayment, anotherPayment) {
                    return (
                        new Date(
                            aPayment.freezePaymentDate
                                ? aPayment.paymentDate
                                : aPayment.paymentDate
                        ).getTime() -
                        new Date(
                            anotherPayment.freezePaymentDate
                                ? anotherPayment.paymentDate
                                : anotherPayment.paymentDate
                        ).getTime()
                    );
                });

            await tick();
            feather.replace();
            updateTotalPaymentList();
        } else {
            console.error(paymentListData.errMsg);
        }
    };
    let updateTotalPaymentList = async function (params) {
        let sum = 0;
        let installmentSum = 0;
        paymentList.forEach((aPayment) => {
            if (aPayment.freezePayment) {
                sum += aPayment.paidAmount ? aPayment.paidAmount : 0;
            } else {
                installmentSum += aPayment.installmentAmount
                    ? aPayment.installmentAmount
                    : 0;
            }
        });
        totalPaidAmount = sum;
        balanceAmount = studentBatchObj.agreedFees - totalPaidAmount;
        balanceAmountWithInstalment =
            studentBatchObj.agreedFees - (totalPaidAmount + installmentSum);

        paymentObj.paidAmount = balanceAmountWithInstalment;
        _paidAmount = paymentObj.paidAmount;

        paymentObj.installmentAmount = balanceAmountWithInstalment;

        await tick();
        updatePaidStatus();
    };
    let payTypeSelectizeInstance;
    let bindSelectize = function (isPageRefresh) {
        ifListNotPresent = "";
        if (payTypeSelectizeInstance) payTypeSelectizeInstance.destroy();
        payTypeSelectizeInstance = _$(elemSelectPaymentType).selectize({
            onChange: function (value) {
                paymentObj.paymentType = value;
                setTimeout(() => {
                    if (isPageRefresh) {
                        isPageRefresh = false;
                        //Need to do :  bcoz on payment change we want to populate payment receipt
                        paymentObj.prefix = "";
                        paymentObj.sequence = "";
                        paymentObj.receiptNo = "";
                        populateBranchSelectize("", "", true);
                    } else {
                        paymentObj.prefix = "";
                        paymentObj.sequence = "";
                        paymentObj.receiptNo = "";
                        populateBranchSelectize("", "", false);
                        updatePaymentDate();
                    }
                    // preProcessPaymentData();
                    updatePaidStatus(isDateChanged);
                }, 500);
            },
        });
        payTypeSelectizeInstance = payTypeSelectizeInstance[0].selectize;
        populateBranchSelectize("", "", false);
    };

    async function api_getStudentList() {
        let action = "students.list";
        var response = await api_handleApiCalls(action, {
            isSortByName: true,
            inactiveStudents: true,
        });
        return response;
    }
    async function api_getMyBatchesList(studentId) {}
    async function bindStudentSelect() {
        let studentListArr = [];
        isStudentListLoading = true;
        var response = await api_getStudentList();

        isStudentListLoading = false;

        studentListArr = response.data.data;

        await tick();
        studentSelectizeInstance = _$(elemStudentSelect).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            persist: false,
            options: studentListArr,
            onChange: async function (value) {
                paymentObj.studentId = value;
                paymentObj.batchId = "";

                bindbatchSelect(value);
            },
        });
    }
    async function bindbatchSelect(studentId) {
        batchesList1 = [];
        let batchListArr = [];
        let action = "students.getAssignedBatches";
        var response = await api_handleApiCalls(action, { _id: studentId });

        batchList = response.data;
        ifBatchListNotPresent = "";
        // if (batchList && batchList.length) {
        //     batchListArr = batchList.map(elem => elem.batch)
        // }
        batchList = batchList.filter((elem) => elem.batch.isFree != "yes");

        if (batchList && batchList.length) {
            batchesList1 = batchList.map((item) => item.batch);
        }
        if (!batchesList1.length) {
            if (batchSelectizeInstance) batchSelectizeInstance.disable();
            ifBatchListNotPresent = "No batch is assigned to this student";
        } else {
            if (batchSelectizeInstance) batchSelectizeInstance.enable();
        }
        await tick();
        if (batchSelectizeInstance) batchSelectizeInstance.destroy();
        batchSelectizeInstance = _$(elemBatchSelect).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            persist: false,
            options: batchesList1,
            onChange: function (value) {
                paymentObj.batchId = value;
                studentBatchObj = batchList.find(
                    (item) => item.batch._id == value
                );
                getStudentPaymentByBatchId();
            },
        });
        batchSelectizeInstance = batchSelectizeInstance[0].selectize;
    }
    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    };
    let isPayingAmount = function (params) {
        return paymentObj.paidAmount && paymentObj.paidAmount != 0;
    };

    let updatePaidStatus = function (params) {
        if (isPayingAmount()) {
            addpaymentStatus = "FREEZE";
        } else {
            addpaymentStatus = "DEFAULT";
            //PDC LOGIC
            if (paymentObj.paymentType == "cheque" && paymentObj._id) {
                //add id logic
                receiptSeriesLists = [];
            }
        }
    };
    let addUpdatePaymentBtn = async function () {
        // getStudentPaymentByBatchId()
        paymentObj.paymentType = _$(elemSelectPaymentType).val();
        var todaysDate = new Date();
        paymentObj.paymentDate = new Date(paymentObj.paymentDate);
        if (
            todaysDate.getDate() < paymentObj.paymentDate.getDate() ||
            todaysDate.getDate() > paymentObj.paymentDate.getDate()
        ) {
            // paymentObj.branchId = null
            // paymentObj.receiptId = null;
            //PDC LOGIC
            if (
                paymentObj.paymentType == "cheque" &&
                addpaymentStatus == "DEFAULT"
            ) {
                paymentObj.branchId = branchSelectizeInstance
                    ? branchSelectizeInstance.getValue()
                    : "";
                paymentObj.receiptId = receiptId;
            }
        } else {
            paymentObj.branchId = branchSelectizeInstance
                ? branchSelectizeInstance.getValue()
                : "";
            paymentObj.receiptId = receiptId;
        }
        preProcessPaymentData();
        if (validatePayment()) {
            await tick();
            if (isLoading) {
                return;
            }

            isLoading = true;

            var paymentRes = await api_handleApiCalls(
                "payment.addUpdatePayment",
                paymentObj
            );
            if (paymentRes.status) {
                // create success
                PNotify.success({
                    title: "Success",
                    text: paymentRes.data,
                    delay: 3000,
                });
                _$(modalEle).modal("hide");
                // refreshData();
                clearModalFields();
                // dispatch('refreshData');
            } else {
                PNotify.error({
                    title: "Error",
                    text: paymentRes.errMsg,
                    delay: 3000,
                });
                isLoading = false;
            }
        }
    };
    function restModal() {
        errorObj = {};
        paymentObj = {};
    }
    let preProcessPaymentData = function (isDateChanged) {
        if (paymentObj.paymentType == "cash") {
            if (paymentObj._id) {
            } else {
                if (
                    paymentObj.paymentDate < new Date() ||
                    paymentDateStr == convertDateObjToStr(new Date())
                ) {
                    if (isDateChanged) {
                        paymentObj.paidAmount = _paidAmount;
                        
                    }

                    paymentObj.installmentAmount = paymentObj.paidAmount;
                  
                } else {

                    paymentObj.paidAmount = 0;
                }
            }
        }
        if (paymentObj.paymentType == "cheque") {
            if (paymentObj._id) {
            } else {
                // if ((paymentObj.paymentDate < new Date()) || (paymentDateStr == convertDateObjToStr(new Date()))) {
                //     if (isDateChanged) paymentObj.paidAmount = _paidAmount

                //     paymentObj.installmentAmount = paymentObj.paidAmount;
                // } else {
                //     paymentObj.paidAmount = 0;
                // }
                // paymentObj.paidAmount = _paidAmount
                // paymentObj.installmentAmount =  paymentObj.paidAmount
                paymentObj.paidAmount = 0;
            }
        }
        if (paymentObj.paymentType == "dd") {
            if (paymentObj._id) {
            } else {
                paymentObj.paidAmount = 0;
            }
        }

        if (paymentObj.paymentType == "onlinePay") {
            if (paymentObj._id) {
            } else {
                if (
                    paymentObj.paymentDate < new Date() ||
                    paymentDateStr == convertDateObjToStr(new Date())
                ) {
                    if (isDateChanged) paymentObj.paidAmount = _paidAmount;
                    else paymentObj.installmentAmount = paymentObj.paidAmount;
                } else {
                    paymentObj.paidAmount = 0;
                }
            }
        }
        if (paymentObj.paymentType == "upi") {
            if (paymentObj._id) {
            } else {
                if (
                    paymentObj.paymentDate < new Date() ||
                    paymentDateStr == convertDateObjToStr(new Date())
                ) {
                    if (isDateChanged) paymentObj.paidAmount = _paidAmount;
                    else paymentObj.installmentAmount = paymentObj.paidAmount;
                } else {
                    paymentObj.paidAmount = 0;
                }
            }
        }
        if (paymentObj.paymentType == "card") {
            if (paymentObj._id) {
            } else {
                if (
                    paymentObj.paymentDate < new Date() ||
                    paymentDateStr == convertDateObjToStr(new Date())
                ) {
                    if (isDateChanged) paymentObj.paidAmount = _paidAmount;
                    else paymentObj.installmentAmount = paymentObj.paidAmount;
                } else {
                    paymentObj.paidAmount = 0;
                }
            }
        }
    };
    let saveAndAddAnotherPayment = async function () {
        paymentObj.paymentType = _$(elemSelectPaymentType).val();

        var todaysDate = new Date();
        paymentObj.paymentDate = new Date(paymentObj.paymentDate);
        if (
            todaysDate.getDate() < paymentObj.paymentDate.getDate() ||
            todaysDate.getDate() > paymentObj.paymentDate.getDate()
        ) {
            // paymentObj.branchId = null
            // paymentObj.receiptId = null;
            //PDC LOGIC
            if (
                paymentObj.paymentType == "cheque" &&
                addpaymentStatus == "DEFAULT"
            ) {
                paymentObj.branchId = branchSelectizeInstance
                    ? branchSelectizeInstance.getValue()
                    : "";
                paymentObj.receiptId = receiptId;
            }
        } else {
            paymentObj.branchId = branchSelectizeInstance
                ? branchSelectizeInstance.getValue()
                : "";
            paymentObj.receiptId = receiptId;
        }

        preProcessPaymentData();
        if (validatePayment()) {
            await tick();
            if (isSaveLoading) {
                return;
            }

            isSaveLoading = true;

            var paymentRes = await api_handleApiCalls(
                "payment.addUpdatePayment",
                paymentObj
            );
            isSaveLoading = false;
            if (paymentRes.status) {
                // create success
                PNotify.success({
                    title: "Success",
                    text: paymentRes.data,
                    delay: 3000,
                });
                // paymentObj.bankName = ""
                // paymentObj.receiptNo = ""
                // paymentObj.chequeNo = ""
                (paymentObj.paidAmount = 0),
                    //     paymentObj.installmentAmount = ""
                    // _$(elemSelectPaymentType)[0].selectize.setValue("cheque");
                    // _$(elemSelectPaymentType)[0].selectize.enable();
                    // if (studentSelectizeInstance.length && studentSelectizeInstance.selectize) {
                    //     _$(elemStudentSelect)[0].selectize.clear();
                    //     _$(elemStudentSelect)[0].selectize.enable();

                    // }

                    clearModalFields();
                // restModal()
            } else {
                PNotify.error({
                    title: "Error",
                    text: paymentRes.errMsg,
                    delay: 3000,
                });
            }
        }
    };
    function convertDateObjToStr(date) {
        var month = "" + (date.getMonth() + 1);
        var day = "" + date.getDate();
        var year = date.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        var dateString = [year, month, day].join("-");
        return dateString;
    }

    function convertDateStrToObj(selectedDate) {
        selectedDate = selectedDate.split("-");
        var year = selectedDate[0];
        var month = selectedDate[1] - 1;
        var day = selectedDate[2];
        return new Date(year, month, day);
    }
    function updatePaymentDate() {
        paymentObj.paymentDate = convertDateStrToObj(paymentDateStr);

        let isTodayDate = new Date(new Date().setHours(0, 0, 0, 0));
        if (paymentObj.paymentType == "cheque") {
            //this is to select the future date
            populateBranchSelectize("", "", false);
            isDateChanged = false;
        } else if (paymentObj.paymentDate.getTime() == isTodayDate.getTime()) {
            populateBranchSelectize("", "", false);
            isDateChanged = true;
        }

        preProcessPaymentData(isDateChanged);
        updatePaidStatus();
    }

    let populateBranchSelectize = async function (branchId, receiptId, isSet) {
        ifListNotPresent = "";

        branchListSelectArr = [];
        var response = await api_handleApiCalls(
            "instituteBranch.fetchInstituteBranchList",
            { start: 0, length: 100, sort: { name: 1 } }
        );
        if (response && response.data && response.data.data.length) {
            if (localUserData.role == "orgAdmin") {
                branchListSelectArr = [
                    { name: "All Reciepts", _id: "" },
                    ...response.data.data,
                ];
            } else {
                branchListSelectArr = [...response.data.data];
            }
        } else {
            branchListSelectArr.push({ name: "All", _id: "" });
        }
        branchListSelectArr = branchListSelectArr;
        await tick();

        if (branchSelectizeInstance) branchSelectizeInstance.destroy();
        branchSelectizeInstance = _$(elemBranchListSelect).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            persist: false,
            options: branchListSelectArr,
            render: {
                option_create: function (data, escape) {
                    return (
                        '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
                        escape(data.input) +
                        "</strong></div>"
                    );
                },
            },
            onChange: function (branchIdValue) {
                if (isSet) {
                } else {
                    if (
                        localUserData.role == "orgAdmin" &&
                        branchIdValue == ""
                    ) {
                        paymentObj.receiptNo = "";
                        populateReceiptSelectize(branchIdValue, "", false);
                    } else if (branchIdValue) {
                        paymentObj.receiptNo = "";
                        populateReceiptSelectize(branchIdValue, "", false);
                    }
                }
            },
        });

        branchSelectizeInstance =
            branchSelectizeInstance &&
            branchSelectizeInstance[0] &&
            branchSelectizeInstance[0].selectize
                ? branchSelectizeInstance[0].selectize
                : null;

        if (branchId && isSet) {
            branchSelectizeInstance.setValue(branchId);
            if (modalTitle.toLowerCase() == "view payment")
                branchSelectizeInstance.disable();
            populateReceiptSelectize(branchId, receiptId, true);
        }
    };

    let populateReceiptSelectize = async function (branchId, receiptID, isSet) {
        //get recept list
        var response = await api_handleApiCalls("receiptSeries.list", {
            start: 0,
            length: 100,
            search: "",
            branchId: branchId,
        });
        ifListNotPresent = "";

        if (response && response.data.data.length) {
            receiptSeriesLists = response.data.data;
            //PDC LOGIC
            if (
                paymentObj.paymentType == "cheque" &&
                (addpaymentStatus == "DEFAULT" || !paymentObj._id)
            ) {
                //add id logic
                receiptSeriesLists = receiptSeriesLists.filter(
                    (item) => item.isPdc == true
                );
            }
            if (
                paymentObj.paymentType == "cash" &&
                (addpaymentStatus == "DEFAULT" || !paymentObj._id)
            ) {
                receiptSeriesLists = receiptSeriesLists.filter(
                    (item) =>
                        (item.isOffline == true &&
                            item.isOnline == false &&
                            item.isPdc == false) ||
                        (item.isOffline == true &&
                            item.isOnline == true &&
                            item.isPdc == false)
                );
            }
            if (
                paymentObj.paymentType == "onlinePay" ||
                paymentObj.paymentType == "upi" ||
                (paymentObj.paymentType == "card" &&
                    (addpaymentStatus == "DEFAULT" || !paymentObj._id))
            ) {
                receiptSeriesLists = receiptSeriesLists.filter(
                    (item) =>
                        item.isOnline == true &&
                        item.isOffline == false &&
                        item.isPdc == false
                );
            }
            // else {
            //     receiptSeriesLists = receiptSeriesLists.filter(item => !item.isPdc || item.isPdc == false)
            // }

            if (receiptSeriesLists.length)
                receiptSeriesLists.map(
                    (item) => (item.label = `${item.prefix}${item.series}`)
                );
            if (receiptSeriesLists.length == 0)
                ifListNotPresent = "Please add receipt series first";
        } else {
            receiptSeriesLists = [];
            ifListNotPresent = "Please add receipt series first";
        }
        await tick();
        if (receiptselectizeInstance && receiptselectizeInstance.length)
            receiptselectizeInstance[0].selectize.destroy();
        receiptselectizeInstance = _$(elemReceiptType).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "label",
            searchField: ["prefix", "series"],
            delimiter: ",",
            persist: false,
            options: receiptSeriesLists,
            render: {
                option_create: function (data, escape) {
                    return (
                        '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
                        escape(data.input) +
                        "</strong></div>"
                    );
                },
            },
            onChange: function (receiptIdValue) {
                if (isSet) {
                } else {
                    var isFound = receiptSeriesLists.find(
                        (i) => i._id == receiptIdValue
                    );
                    if (isFound) {
                        paymentObj.prefix = isFound.prefix;
                        paymentObj.sequence = isFound.series;
                        paymentObj.receiptNo =
                            paymentObj.prefix + paymentObj.sequence;
                        receiptId = receiptIdValue;
                    }
                }
            },
        });
        if (receiptID && isSet) {
            paymentObj.receiptNo = paymentObj.prefix + paymentObj.sequence;
            receiptselectizeInstance[0].selectize.setValue(receiptID);
        }
    };
    // $:paymentObj.studentId, paymentObj.batchId ,  getStudentPaymentByBatchId()

    const isValidDate = function (date) {
        return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
    };
    let validatePayment = () => {
        errorObj = {};
        let BANKNAMEREGEX = /^([a-z]+\s)*[a-zA-Z ]+$/;
        let CHEQUENOREGEX = /^[0-9]{6,6}$/;
        // if(!isValidDate(paymentObj.paymentDate)) newErrorObj.paymentDate = 'Select Valid Date.'
        if (
            !paymentObj.installmentAmount ||
            !paymentObj.chequeNo ||
            !paymentObj.bankName ||
            !paymentObj.code ||
            !paymentObj.paymentDate ||
            !paymentObj.paymentType ||
            !paymentObj.paidAmount ||
            !paymentObj.studentId ||
            !paymentObj.batchId
        ) {
            var newErrorObj = {};
            if (!paymentObj.studentId) {
                newErrorObj.studentId = " Student is required Field.";
            }
            if (!paymentObj.batchId) {
                newErrorObj.batchId = " Batch is required Field.";
            }
            if (!paymentObj.paymentDate) {
                newErrorObj.paymentDate = " Payment Date is required Field.";
            }

            if (!paymentObj.installmentAmount) {
                newErrorObj.installmentAmount =
                    " Installment Amount is required Field.";
            }

            if (
                paymentObj.paymentType == "cash" ||
                paymentObj.paymentType == "upi" ||
                paymentObj.paymentType == "card" ||
                paymentObj.paymentType == "onlinePay"
            ) {
                if (
                    paymentObj.paymentDate == new Date() ||
                    paymentObj.paymentDate < new Date()
                ) {
                    if (!paymentObj.paidAmount) {
                        newErrorObj.paidAmount = "  Amount is required Field.";
                    }
                    if (paymentObj.paidAmount && paymentObj.paidAmount < 0) {
                        newErrorObj.paidAmount =
                            "Amount cannot be less than  0";
                    }
                    if (
                        paymentObj.paidAmount &&
                        paymentObj.paidAmount > balanceAmount
                    ) {
                        newErrorObj.paidAmount =
                            "Amount cannot be greater than Balance Amount ( " +
                            balanceAmount +
                            " )";
                    }
                } else {
                    if (
                        paymentObj.installmentAmount &&
                        paymentObj.installmentAmount > balanceAmount
                    ) {
                        newErrorObj.installmentAmount =
                            " Installment Amount cannot be greater than Balance Amount ( " +
                            balanceAmount +
                            " )";
                    }

                    if (
                        paymentObj.installmentAmount &&
                        paymentObj.installmentAmount < 0
                    ) {
                        newErrorObj.installmentAmount =
                            " Installment Amount cannot be less than  0";
                    }
                    if (
                        paymentObj.installmentAmount &&
                        paymentObj.paidAmount &&
                        paymentObj.installmentAmount < paymentObj.paidAmount
                    ) {
                        newErrorObj.paidAmount =
                            "Cannot Pay amount more than Installment Amount ( " +
                            paymentObj.installmentAmount +
                            " )";
                    }
                }
            }
            // if(paymentObj.paymentType =="cash" || paymentObj.paymentType =="upi" || paymentObj.paymentType =="card" || paymentObj.paymentType =="onlinePay" )
            // {
            //     if(paymentObj.paymentDate == convertDateStrToObj(new Date()) || paymentObj.paymentDate < convertDateStrToObj(new Date()) ){
            //         if (!paymentObj.paidAmount ) {
            //         newErrorObj.paidAmount = "  Amount is required Field.";
            //      }
            //      if (paymentObj.paidAmount && paymentObj.paidAmount  < 0) {
            //         newErrorObj.paidAmount = "Amount cannot be less than  0";
            //      }
            //      if (paymentObj.paidAmount && paymentObj.paidAmount  > balanceAmount) {
            //         newErrorObj.paidAmount = "Amount cannot be greater than Balance Amount ( " + (balanceAmount) + " )";
            //      }
            //     }
            //    else{
            //         if (paymentObj.installmentAmount && paymentObj.installmentAmount > balanceAmount) {
            //             newErrorObj.installmentAmount = " Installment Amount cannot be greater than Balance Amount ( " + (balanceAmount) + " )";
            //         }

            //         if (paymentObj.installmentAmount && paymentObj.installmentAmount  < 0) {
            //             newErrorObj.installmentAmount = " Installment Amount cannot be less than  0";
            //         }
            //         if (paymentObj.installmentAmount && paymentObj.paidAmount && paymentObj.installmentAmount < paymentObj.paidAmount) {
            //             newErrorObj.paidAmount = "Cannot Pay amount more than Installment Amount ( " + (paymentObj.installmentAmount) + " )";
            //         }

            //    }
            // }

            // if(paymentObj.paymentType == "cash" ){
            //     if (paymentObj.paidAmount && paymentObj.paidAmount < 0) {
            //     newErrorObj.installmentAmount = " Installment Amount cannot be less than  0";
            // }
            // }
            if (paymentObj.paymentType == "cheque") {
                if (!paymentObj.chequeNo) {
                    newErrorObj.chequeNo = "Cheque Number is requred.";
                }
                if (!CHEQUENOREGEX.test(paymentObj.chequeNo)) {
                    newErrorObj.chequeNo =
                        "Please enter valid 6 digit cheque number.";
                }
                if (
                    !paymentObj.bankName ||
                    !BANKNAMEREGEX.test(paymentObj.bankName)
                ) {
                    newErrorObj.bankName = "Please enter valid bank name";
                }
                if (
                    paymentObj.installmentAmount &&
                    paymentObj.installmentAmount > balanceAmount
                ) {
                    newErrorObj.installmentAmount =
                        " Installment Amount cannot be greater than Balance Amount ( " +
                        balanceAmount +
                        " )";
                }

                if (
                    paymentObj.installmentAmount &&
                    paymentObj.installmentAmount < 0
                ) {
                    newErrorObj.installmentAmount =
                        " Installment Amount cannot be less than  0";
                }
                if (
                    paymentObj.installmentAmount &&
                    paymentObj.paidAmount &&
                    paymentObj.installmentAmount < paymentObj.paidAmount
                ) {
                    newErrorObj.paidAmount =
                        "Cannot Pay amount more than Installment Amount ( " +
                        paymentObj.installmentAmount +
                        " )";
                }
            }
            // if (paymentObj.paymentType == "dd") {

            //     if (!paymentObj.ddNo ) {

            //         newErrorObj.ddNo = "Please enter Demand Draft No";
            //     }
            //     if (!paymentObj.installmentAmount) {
            //         newErrorObj.installmentAmount = "Please Enter Demand Draft Amount";
            //     }
            //     if (paymentObj.installmentAmount && paymentObj.installmentAmount  <= 0) {
            //         newErrorObj.installmentAmount = " Installment Amount cannot be less than or equal to 0";
            //     }
            // }
            if (paymentObj.paidAmount && paymentObj.receiptNo == "") {
                newErrorObj.receiptNo = "Select branch to add receipt number";
            }
            if (paymentObj._id && !paymentObj.remark) {
                newErrorObj.remark = "Please add remarks for this transaction";
            }
            // if (paymentObj.paidAmount && !paymentObj.prefix) {
            //     newErrorObj.prefix = " Prefix is required Field.";
            // }
            // if (paymentObj.paidAmount && !paymentObj.sequence) {
            //     newErrorObj.sequence = " Sequence is required Field.";
            // }
            // if (paymentObj.paidAmount && paymentObj.sequence && !/^([1-9][0-9]*)$/.test(paymentObj.sequence)) {
            //     newErrorObj.sequence = " Enter valid Sequence ";
            // }
            errorObj = newErrorObj;
        }
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;
    };
    let resetModalFields = function () {
        paymentObj = {
            studentId: "",
            batchId: "",
            paymentType: "cash",
            installmentAmount: balanceAmountWithInstalment,
            paidAmount: balanceAmountWithInstalment,
        };
        _paidAmount = paymentObj.paidAmount;
        addpaymentStatus = "DEFAULT";
        paymentDateStr = convertDateObjToStr(new Date());
        updatePaymentDate();

        paymentObj.freezePayment = false;
        _$(elemSelectPaymentType)[0].selectize.setValue("cash");
        _$(elemSelectPaymentType)[0].selectize.enable();
        if (branchSelectizeInstance) {
            _$(elemBranchListSelect)[0].selectize.clear();
            _$(elemBranchListSelect)[0].selectize.enable();
            // _$(elemStudentSelect)[0].selectize.clear();
            // _$(elemBatchSelect)[0].selectize.clear();

            // _$(elemBatchSelect)[0].selectize.setValue("");
            receiptSeriesLists = [];
        }
        _$(modalEle).modal("show");
        updatePaidStatus();
    };
    let bindFilterSelectize = function () {
        selectizeInstance = _$(elemPaymentType).selectize({
            onChange: function (value) {
                filterByPaymentType = value;
            },
        });

        let paymentStatus = [
            { name: "Paid", _id: "paid" },
            { name: "Due", _id: "due" },
            { name: "Upcoming", _id: "upcoming" },
        ];

        instanceFilterStatus = _$(elmSelectFilterStatus).selectize({
            preload: true,
            create: false,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "Select payment status",
            delimiter: ",",
            persist: false,
            options: paymentStatus,
        });

        instanceFilterStudents = _$(elmSelectFilterStudents).selectize({
            plugins: ["remove_button"],
            maxItems: 100,
            options: [],
            create: false,
            preload: false,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "Type student name or mobile number",

            load: async function (query, callback) {
                let result = await api_getStudentsList(query, 0, 10);
                if (!result) result = [];
                callback(result);
            },
        });

        instanceFilterBatches = _$(elmSelectFilterBatches).selectize({
            plugins: ["remove_button"],
            maxItems: 100,
            options: [],
            create: false,
            preload: false,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "Type batch name or code",
            onChange: function (values) {},
            load: async function (query, callback) {
                let result = await api_getBatchesList(query, 0, 10);
                if (!result) result = [];
                callback(result);
            },
        });
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    let drawCallbackDatatable = () => {
        feather.replace({ width: "1em", height: "1em" });
        _$("table.dataTable tbody td.sorting_1")
            .css({ "background-color": "#fff" })
            .addClass("list-item");
        _$(".sorting_asc").css("background-color", "#fff");
    };

    function bindTransactionsDatatable() {
        instanceTableTransactions = _$(elemTableTransactions).DataTable({
            searching: false,
            bLengthChange: false,
            bSort: false,
            bProcessing: false,
            responsive: true,

            language: {
                processing: "",
                emptyTable: "No data found",
            },
            ajax: function (data, callback, settings) {
                _$.ajax({
                    url: API_URL,
                    method: "post",
                    data: {
                        action: "payment.getPaymentTransactions",
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                        },
                    },
                    success: function (resData) {
                        var result = [];
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(
                                    paginationData.filteredCount
                                ),
                                data: paginationData.data,
                            };
                            callback(result);
                        } else {
                            emptyState = true;
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        emptyState = true;
                    },
                });
            },
            columnDefs: [
                {
                    targets: [0],
                    render: function (data, type, row) {
                        var userIcon = "";
                        if (row.student && row.student.profilePic) {
                            userIcon = `<img src="${row.student.profilePic}" class="avatar-initial rounded-circle bg-primary tx-uppercase"/>`;
                        } else {
                            userIcon = `<span class="avatar-initial rounded-circle bg-primary tx-uppercase op-6">${convertToINR(
                                row.installmentAmount
                            )}}</span>`;
                        }

                        return `
                        <div class="d-flex flex-column" style="width: 100%">
                            <div class="d-flex justify-content-between mg-t-15">
                                <div class="avatar avatar-sm">
                                    ${userIcon}
                                </div>
                                <div class="media-body mg-l-10 justify-content-between">
                                    <h6 class="tx-13 mg-b-3">${convertToINR(
                                        row.installmentAmount
                                    )}</h6>
                                    <span class="tx-12"><i class="mg-r-5" data-feather="phone"></i>Money Received via ${
                                        row.paymentType
                                    }</span>
                                </div><!-- media-body -->
                                <a href="#"><i class="mg-r-5" data-feather="send"></i>Send notification </a>
                            </div>
                            <div class="media">
                                <div class="media-body media mg-b-10 d-block mg-t-10">
                                    <p class="tx-medium mg-b-0">Received ${convertToINR(
                                        row.installmentAmount
                                    )}</p>
                                    <p class="mg-b-0 tx-11 tx-color-02 tx-uppercase tx-medium">${new Date(
                                        row.paymentDate
                                    ).toLocaleDateString()}</p>
                                </div>
            
                                <div class="text-right">
                                    <p class="tx-normal tx-rubik mg-b-0">${convertToINR(
                                        row.installmentAmount
                                    )}</p>
                                    <p class="mg-b-0 tx-12 tx-rubik tx-color-02">
                                        ${row.paymentType}
                                    </p>
                            </div>
                            </div><!-- media -->
                        
                        </div>                      
                        `;
                    },
                },
            ],
            drawCallback: drawCallbackDatatable,
            processing: true,
            serverSide: true,
        });

        _$(".dataTables_length select").select2({
            minimumResultsForSearch: Infinity,
        });
    }
    let convertToINR = function (input) {
        let amount = Number(input);
        if (Number.isNaN(amount)) {
            amount = 0;
        }
        return amount.toLocaleString("en-IN", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
        });
    };
    var returnedFunction = debounce(async function () {
        let listData = await api_getStudentsList(studentSearch, 0, 10);
        selectStudentList = listData;
    }, 250);

    let keyup = function () {
        returnedFunction();
    };
    let toggleStudentSelectModal = (show) =>
        show
            ? _$(modalSelectStudent).modal("show")
            : _$(modalSelectStudent).modal("hide");
    let filterClickHandler = (selected) => {
        filterBy = selected;
        paymentCounts(filterBy);
    };

    const goToFees = (studentId) => {
        let link = "/dashboard/student/view/" + studentId + "/fees";
        _$(modalSelectStudent).modal("hide");
        navigate(link);
    };

    let modalExport;
    const show_hide_export_modal = (show = true) => {
        show ? _$(modalExport).modal("show") : _$(modalExport).modal("hide");
    };
    let refreshData = function () {
        getStudentPaymentByBatchId();
        // window.location.reload();
    };
    const keyShortcutInit = () => {
        window.addEventListener("keydown", function (e) {
            if (e.altKey == true && e.keyCode == 78) {
                modalTitle = "New Payment";
                resetModalFields();
                bindStudentSelect();
                // bindbatchSelect()
                _$(modalEle).modal("show");
            }
        });
    };
    const getStudentsList = async () => {
        selectStudentList = await api_getStudentsList("", 0, 10);
    };
    onMount(async () => {
        activeMenu.set("payment");
        keyShortcutInit();
        bindSelectize(true);

        _$(modalEle).on("show.bs.modal", function (e) {
            // change isloading here
            isLoading = false;
        });
        paymentCounts(filterBy);
        bindTransactionsDatatable();
        bindExportSelectize();
        getStudentsList();
        bindFilterSelectize();
        await tick();
        clearModalFields();

        feather.replace();
    });
    function clearModalFields() {
        paymentObj = {};
        errorObj = {};
        // _paidAmount = paymentObj.paidAmount
        addpaymentStatus = "DEFAULT";
        paymentDateStr = convertDateObjToStr(new Date());
        updatePaymentDate();
        paymentObj.paidAmount = "";
        paymentObj.installmentAmount = "";

        balanceAmountWithInstalment = "";
        paymentObj.freezePayment = false;
        _$(elemSelectPaymentType)[0].selectize.setValue("cash");
        _$(elemSelectPaymentType)[0].selectize.enable();
        if (
            studentSelectizeInstance &&
            studentSelectizeInstance.length &&
            studentSelectizeInstance.selectize
        ) {
            _$(elemStudentSelect)[0].selectize.clear();
            _$(elemStudentSelect)[0].selectize.enable();
        }
        if (
            batchSelectizeInstance &&
            batchSelectizeInstance.length &&
            batchSelectizeInstance.selectize
        ) {
            _$(elemBatchSelect)[0].selectize.clear();
            _$(elemBatchSelect)[0].selectize.enable();
        }
        ifBatchListNotPresent = "";
        if (branchSelectizeInstance) {
            branchSelectizeInstance.clear();
            branchSelectizeInstance.enable();

            receiptSeriesLists = [];
        }
        // _$(modalEle).modal('show')
        updatePaidStatus();
    }
    async function api_getStudentsList(name, start, length) {
        let resData = await api_handleApiCalls("students.list", {
            start: start,
            length: length,
            search: name,
        });

        if (resData.status) {
            return resData.data.data;
        } else {
            return [];
        }
    }

    async function api_getBatchesList(name, start, length) {
        let resData = await api_handleApiCalls("batch.listBatches", {
            start: start,
            length: length,
            search: name,
        });

        if (resData.status) {
            return resData.data.data;
        } else {
            return [];
        }
    }

    async function api_getPaymentCounts(data) {
        let resData = await api_handleApiCalls(
            "payment.getPaymentCounts",
            data
        );

        if (resData.status) {
            return resData.data;
        } else {
            return null;
        }
    }

    async function paymentCounts(filterBy) {
        let counts = await api_getPaymentCounts({ filterBy });
        totalDue = counts.totalDue;
        totalPaid = counts.totalPaid;
        totalUpcoming = counts.totalUpcoming;
        totalCheck = counts.totalCheque;
        totalOnline = counts.totalOnline;
        totalCash = counts.totalCash;
    }

    async function bindExportSelectize() {
        let paymentStatus = [
            { name: "Paid", _id: "paid" },
            { name: "Due", _id: "due" },
            { name: "Upcoming", _id: "upcoming" },
        ];

        instanceExportStatus = _$(elmSelectExportStatus).selectize({
            preload: true,
            create: false,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "Select payment status",
            delimiter: ",",
            persist: false,
            options: paymentStatus,
        });

        instanceExportStudents = _$(elmSelectExportStudents).selectize({
            plugins: ["remove_button"],
            maxItems: 100,
            options: [],
            create: false,
            preload: false,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "Type student name or mobile number",

            load: async function (query, callback) {
                let result = await api_getStudentsList(query, 0, 10);
                if (!result) result = [];
                callback(result);
            },
        });

        instanceExportBatches = _$(elmSelectExportBatches).selectize({
            plugins: ["remove_button"],
            maxItems: 100,
            options: [],
            create: false,
            preload: false,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "Type batch name or code",
            onChange: function (values) {},
            load: async function (query, callback) {
                let result = await api_getBatchesList(query, 0, 10);
                if (!result) result = [];
                callback(result);
            },
        });
    }

    const clearExportFilter = function () {
        _$(instanceExportStatus)[0].selectize.clear();
        _$(instanceExportStudents)[0].selectize.clear();
        _$(instanceExportBatches)[0].selectize.clear();
        receiptSeriesNumExport = "";
    };
    const startExportBtnClick = async () => {
        exportLoading = true;

        let paymentStatus = _$(instanceExportStatus).val();
        let students = _$(instanceExportStudents).val();
        let batches = _$(instanceExportBatches).val();

        let exportFilter = {};

        if (Boolean(receiptSeriesNumExport))
            exportFilter.receiptNo = receiptSeriesNumExport;
        if (paymentStatus && paymentStatus.length)
            exportFilter.paymentStatus = paymentStatus;
        if (Array.isArray(students) && students.length)
            exportFilter.students = students;
        if (Array.isArray(batches) && batches.length)
            exportFilter.batches = batches;

        exportLoading = true;
        let resExport = await api_handleApiCalls(
            "payment.export",
            exportFilter
        );
        exportLoading = false;

        if (resExport.status) {
            clearExportFilter();
            show_hide_export_modal(false);
            if (resExport.data) {
                let reportsArr = resExport.data.reportsArr
                if (reportsArr ) {
                    generateWorkBook(reportsArr);
                }
            }
        } else {
            pError("Error!", resExport.errMsg);
        }
    };

    async function generateWorkBook(paymentsList) {
        const workbook = new ExcelJS.Workbook();
        const paymentWorkSheet = workbook.addWorksheet("Payments Data", {
            pageSetup: { paperSize: 9, orientation: "landscape" },
        });
       
        let fileName =  "Export_Payments_Data_" + new Date().getTime() + '.xlsx';

        let columnsArr = [
            { header: "Sr no", key: "serialNo", width: 20 },
            { header: "Name", key: "name", width: 15 },
            { header: "Mobile Number", key: "mobileNo", width: 20 },
            { header: "Batch Name", key: "batchname", width: 20 },
            { header: "Receipt No", key: "receiptNo", width: 20 },
            { header: "Payment Type", key: "paymenttype", width: 20 },
            { header: "Installment Amount", key: "installmentAmount", width: 20 },
            { header: "Payment Date", key: "paymentDate", width: 20 },
            { header: "Paid On", key: "paidOn", width: 20 },
            { header: "Paid Amount", key: "paidAmount", width: 20 },
            { header: "Remark", key: "remark", width: 20 },
        ];

        paymentWorkSheet.columns = columnsArr;

        for (let idx = 0; idx < paymentsList.length; idx++) {
            let paymentDataArr = [];
            let paymentDataResult = paymentsList[idx];
            let paymentDate = moment(new Date(paymentDataResult.paymentDate)).format('DD/MM/YYYY');

            paymentDataArr.push(paymentDataResult.srNo ? paymentDataResult.srNo : "");
            paymentDataArr.push( paymentDataResult.studentName ? paymentDataResult.studentName : "");
            paymentDataArr.push(paymentDataResult.mobileNo ? paymentDataResult.mobileNo :"");
            paymentDataArr.push(paymentDataResult.batchName ? paymentDataResult.batchName :"");
            paymentDataArr.push(paymentDataResult.receiptNo ? paymentDataResult.receiptNo :"");
            paymentDataArr.push(paymentDataResult.paymentType ? paymentDataResult.paymentType :"");
            paymentDataArr.push(paymentDataResult.installmentAmount ? paymentDataResult.installmentAmount :"");
            paymentDataArr.push(paymentDate ? paymentDate :"");
            paymentDataArr.push(paymentDataResult.paidOn ? paymentDataResult.paidOn :"");
            paymentDataArr.push(paymentDataResult.paidAmount ? paymentDataResult.paidAmount :"");
            paymentDataArr.push(paymentDataResult.remark ? paymentDataResult.remark :"");
            paymentWorkSheet.addRow(paymentDataArr);
          }

        const buffer = await workbook.xlsx.writeBuffer();

        saveFile(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName
        );
        //   await workbook.xlsx.writeFile(fileName);
    }
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        // var view = new Uint8Array(buf);
        var view = s;
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    const saveFile = async (blob, fileName) => {
        const a = document.createElement("a");
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.addEventListener("click", (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };
    function uniqueId() {
        // always start with a letter (for DOM friendlyness)
        var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
        do {
            // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
            var ascicode = Math.floor(Math.random() * 42 + 48);
            if (ascicode < 58 || ascicode > 64) {
                // exclude all chars between : (58) and @ (64)
                idstr += String.fromCharCode(ascicode);
            }
        } while (idstr.length < 32);

        return idstr.toLowerCase();
    }

</script>

<div class="content-body fadeIn">
    <div
        class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
        <div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Payment
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">Payments</h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
                Search the payment details or make a new payment.
            </p>
        </div>
        <hr class="full-width" />
        <div class="d-block">
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i data-feather="mail"
                    class="wd-10 mg-r-5"></i> Email</button> -->
            <div class="d-sm-flex">
                <div class="btn-group mg-r-5">
                    {#if MODULES.hasAccess(MODULES.SHOW_STAT_PAYMENT)}
                        <button
                            class="btn btn-light btn-sm btn-uppercase bg-white dropdown-toggle"
                            bind:this={elemBtnFilterBy}
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <!--{filterBy}-->{filterBy == "T"
                                ? "today"
                                : filterBy == "Y"
                                ? "Yesterday"
                                : filterBy == "LD7"
                                ? "Last 7 Days"
                                : filterBy == "LD30"
                                ? "Last 30 Days"
                                : filterBy == "TM"
                                ? "This Month"
                                : filterBy == "LM"
                                ? "Last Month"
                                : "Last Year"}
                        </button>
                        <ul class="dropdown-menu filterByDropdown">
                            <!-- <li class="dropdown-item {filterBy == 'all' ? 'active' : ''}" on:click="{()=> filterClickHandler('all')}" href="#">All</li>
                        <li class="dropdown-item {filterBy == 'due' ? 'active' : ''}" on:click="{()=> filterClickHandler('due')}" href="#">Total due</li>
                        <li class="dropdown-item {filterBy == 'paid' ? 'active' : ''}" on:click="{()=> filterClickHandler('paid')}" href="#">Total paid</li>
                        <li class="dropdown-item {filterBy == 'upcoming' ? 'active' : ''}" on:click="{()=> filterClickHandler('upcoming')}" href="#">Total upcoming</li> -->
                            <li
                                class="dropdown-item {filterBy == 'Today'
                                    ? 'active'
                                    : ''}"
                                filter="Today"
                                on:click={() => filterClickHandler("T")}
                                href="#"
                            >
                                Today
                            </li>
                            <li
                                class="dropdown-item {filterBy == 'Yesterday'
                                    ? 'active'
                                    : ''}"
                                filter="Yesterday"
                                on:click={() => filterClickHandler("Y")}
                                href="#"
                            >
                                Yesterday
                            </li>
                            <li
                                class="dropdown-item {filterBy ==
                                'Last seven Days'
                                    ? 'active'
                                    : ''}"
                                filter="Last seven Days"
                                on:click={() => filterClickHandler("LD7")}
                                href="#"
                            >
                                Last 7 Days
                            </li>
                            <li
                                class="dropdown-item {filterBy == 'Last 30 Days'
                                    ? 'active'
                                    : ''}"
                                filter="Last 30 Days"
                                on:click={() => filterClickHandler("LD30")}
                                href="#"
                            >
                                Last 30 Days
                            </li>
                            <li
                                class="dropdown-item {filterBy == 'This Month'
                                    ? 'active'
                                    : ''}"
                                filter="This Month"
                                on:click={() => filterClickHandler("TM")}
                                href="#"
                            >
                                This Month
                            </li>
                            <li
                                class="dropdown-item {filterBy == 'Last Month'
                                    ? 'active'
                                    : ''}"
                                filter="Last Month"
                                on:click={() => filterClickHandler("LM")}
                                href="#"
                            >
                                Last Month
                            </li>
                            <li
                                class="dropdown-item {filterBy == 'Last Year'
                                    ? 'active'
                                    : ''}"
                                filter="Last Year"
                                on:click={() => filterClickHandler("LY")}
                                href="#"
                            >
                                Last Year
                            </li>
                        </ul>
                    {/if}
                </div>
                {#if MODULES.hasAccess(MODULES.EXPORT_PAYMENT)}
                    <button
                        disabled={exportLoading}
                        on:click={() => {
                            show_hide_export_modal(true);
                        }}
                        class="btn btn-secondary btn-sm mg-r-5"
                    >
                        <i data-feather="zap" class="wd-10 mg-r-5" />
                        <ButtonLoader
                            isLoading={exportLoading}
                            btnText={exportLoading
                                ? "Exporting..."
                                : "Export Payments"}
                        />
                    </button>
                {/if}

                {#if MODULES.hasAccess(MODULES.MANAGE_RECEIPT_SERIES) || MODULES.hasAccess(MODULES.VIEW_RECEIPT_SERIES_PAYMENT)}
                    <a
                        use:link
                        href="/dashboard/receiptSeries"
                        class="btn btn-success btn-sm mg-r-5"
                        ><i
                            data-feather="file-text"
                            class="wd-10 mg-r-5"
                        />Receipt Series</a
                    >
                {/if}

                {#if MODULES.hasAccess(MODULES.ACCEPT_PAYMENT)}
                    <a
                        href="#modal-new-enquiry"
                        on:click={() => toggleStudentSelectModal(true)}
                        data-toggle="modal"
                        class="btn btn-sm pd-x-15 btn-primary"
                        data-animation="effect-scale"
                    >
                        <i data-feather="plus" class="wd-10 mg-r-5" /> New Payment
                    </a>
                {/if}
                <button
                    on:click={showHideFilterModal}
                    class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"
                >
                    <i data-feather="filter" class="mg-r-5" /> Filters</button
                >
            </div>
        </div>
    </div>

    <!-- <hr class="mg-y-20"> -->
    <hr class="full-width" />
    <div class="row row-xs">
        <div class="col-sm-12 col-md-12 col-lg-12">
            {#if MODULES.hasAccess(MODULES.SHOW_STAT_PAYMENT)}
                <div class="d-md-flex flex-row mg-b-15 justify-content-around">
                    <div
                        class="mg-sm-t-8 mg-lg-r-8 wd-md-200"
                        style="margin-right: 3px;width: 100%;"
                    >
                        <ViewCounts
                            time={filterBy == "T"
                                ? "today"
                                : filterBy == "Y"
                                ? "Yesterday"
                                : filterBy == "LD7"
                                ? "Last 7 Days"
                                : filterBy == "LD30"
                                ? "Last 30 Days"
                                : filterBy == "TM"
                                ? "This Month"
                                : filterBy == "LM"
                                ? "Last Month"
                                : "Last Year"}
                            title="Due Payments"
                            count={totalDue}
                        />
                    </div>

                    <div
                        class="mg-sm-t-8 mg-lg-r-8 wd-md-200"
                        style="margin-right: 3px;width: 100%;"
                    >
                        <ViewCounts
                            time={filterBy == "T"
                                ? "today"
                                : filterBy == "Y"
                                ? "Yesterday"
                                : filterBy == "LD7"
                                ? "Last 7 Days"
                                : filterBy == "LD30"
                                ? "Last 30 Days"
                                : filterBy == "TM"
                                ? "This Month"
                                : filterBy == "LM"
                                ? "Last Month"
                                : "Last Year"}
                            title="Upcoming Payments"
                            count={totalUpcoming}
                        />
                    </div>

                    <div
                        class="mg-sm-t-8 mg-lg-r-8 wd-md-200"
                        style="margin-right: 3px;width: 100%;"
                    >
                        <ViewCounts
                            time={filterBy == "T"
                                ? "today"
                                : filterBy == "Y"
                                ? "Yesterday"
                                : filterBy == "LD7"
                                ? "Last 7 Days"
                                : filterBy == "LD30"
                                ? "Last 30 Days"
                                : filterBy == "TM"
                                ? "This Month"
                                : filterBy == "LM"
                                ? "Last Month"
                                : "Last Year"}
                            title="Cash Payment"
                            count={totalCash}
                        />
                    </div>

                    <div
                        class="mg-sm-t-8 mg-lg-r-8 wd-md-200"
                        style="margin-right: 3px;width: 100%;"
                    >
                        <ViewCounts
                            time={filterBy == "T"
                                ? "today"
                                : filterBy == "Y"
                                ? "Yesterday"
                                : filterBy == "LD7"
                                ? "Last 7 Days"
                                : filterBy == "LD30"
                                ? "Last 30 Days"
                                : filterBy == "TM"
                                ? "This Month"
                                : filterBy == "LM"
                                ? "Last Month"
                                : "Last Year"}
                            title="Cheque Payment"
                            count={totalCheck}
                        />
                    </div>

                    <div
                        class="mg-sm-t-8 mg-lg-r-8 wd-md-200"
                        style="margin-right: 3px;width: 100%;"
                    >
                        <ViewCounts
                            time={filterBy == "T"
                                ? "today"
                                : filterBy == "Y"
                                ? "Yesterday"
                                : filterBy == "LD7"
                                ? "Last 7 Days"
                                : filterBy == "LD30"
                                ? "Last 30 Days"
                                : filterBy == "TM"
                                ? "This Month"
                                : filterBy == "LM"
                                ? "Last Month"
                                : "Last Year"}
                            title="Online Payment"
                            count={totalOnline}
                        />
                    </div>

                    <div
                        class="mg-sm-t-8 mg-lg-r-8 wd-md-200"
                        style="margin-right: 3px;width: 100%;"
                    >
                        <ViewCounts
                            time={filterBy == "T"
                                ? "today"
                                : filterBy == "Y"
                                ? "Yesterday"
                                : filterBy == "LD7"
                                ? "Last 7 Days"
                                : filterBy == "LD30"
                                ? "Last 30 Days"
                                : filterBy == "TM"
                                ? "This Month"
                                : filterBy == "LM"
                                ? "Last Month"
                                : "Last Year"}
                            title="Paid Payment"
                            count={totalPaid}
                        />
                    </div>
                </div>
            {/if}
        </div>
    </div>
    <div class="row">
        <DatatablesCMP />

        <!-- <div class="col-sm-4 col-xl-4">
            <div class="mg-b-25">
                <div class="df-example demo-table" style="width: 100%;">
                    
                    <table style="width: 100%;" bind:this={elemTableTransactions} class="table table-striped mg-b-0 d-none">
                        <thead>
                            <tr><th class="">Transactions</th></tr>
                        </thead>

                        <tbody class="list-group-flush tx-13"></tbody>
                        
                    </table>
                    
                </div>
            </div>
        </div> -->
    </div>
</div>

<!-- Modal -->
<div
    class="modal effect-scale"
    bind:this={modalSelectStudent}
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel6"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a
                    href=""
                    role="button"
                    class="close pos-absolute t-15 r-15 z-index-10"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"
                        ><i
                            data-feather="help-circle"
                            class="wd-60 ht-60"
                        /></span
                    >
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">New Payment</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">
                            Select student to add new payment
                        </p>
                    </div>
                </div>
                <!-- media -->
            </div>
            <!-- modal-header -->
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <!-- <a href="" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </a>
            <div class="d-flex">
              <span class="tx-color-04"><i data-feather="help-circle"
                class="wd-40 ht-40 stroke-wd-3 mg-b-20"></i></span>
              <h3 class=" mg-b-15 mg-md-b-20 mg-l-15">Create new enquiry</h3>
            </div> -->
                <hr class="full-width" />
                <div class="input-group mg-b-10">
                    <input
                        type="text"
                        class="form-control"
                        on:keyup={keyup}
                        bind:value={studentSearch}
                        placeholder="Students's name"
                        aria-label="Students's name"
                        aria-describedby="basic-addon2"
                    />
                    <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"
                            ><i
                                class="mg-r-5"
                                data-feather="search"
                            />Search</span
                        >
                    </div>
                </div>

                <div class="card ht-400" style="overflow-x: auto;">
                    <ul class="list-group list-group-flush tx-13">
                        {#each selectStudentList as item}
                            <li
                                class="list-group-item d-flex pd-x-20"
                                on:click={() => {
                                    goToFees(item._id);
                                }}
                                style="cursor:
                            pointer"
                            >
                                <StudentCard {item} />
                            </li>
                        {:else}
                            <div class="pd-10 mg-t-15 align-self-center">
                                Student not found
                            </div>
                        {/each}
                    </ul>
                </div>

                <div class="mg-t-30">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                        style="width: 100%;">Close</button
                    >
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

<!-- Modal -->
<div
    class="modal effect-scale"
    bind:this={modalExport}
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel6"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a
                    href=""
                    role="button"
                    class="close pos-absolute t-15 r-15 z-index-10"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"
                        ><i data-feather="zap" class="wd-60 ht-60" /></span
                    >
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Export Payments</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">
                            You can use the following filters to export payments
                        </p>
                    </div>
                </div>
                <!-- media -->
            </div>
            <!-- modal-header -->
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <hr class="full-width" />

                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group mg-b-15">
                            <label for="exportStatus">Payment Status</label>
                            <select
                                id="exportStatus"
                                bind:this={elmSelectExportStatus}
                                on:keyup={(e) =>
                                    e.key === "Enter" && startExportBtnClick()}
                            />
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group mg-b-15">
                            <label for="exportSeries">Receipt Series</label>
                            <input
                                type="text"
                                bind:value={receiptSeriesNumExport}
                                class="form-control"
                                on:keyup={(e) =>
                                    e.key === "Enter" && startExportBtnClick()}
                                placeholder="Enter receipt
                            series number"
                            />
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mg-b-15">
                            <label for="exportStatus"
                                >Payments of Students</label
                            >
                            <select
                                bind:this={elmSelectExportStudents}
                                on:keyup={(e) =>
                                    e.key === "Enter" && startExportBtnClick()}
                            />
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mg-b-15">
                            <label for="exportStatus">Payments of Batches</label
                            >
                            <select
                                bind:this={elmSelectExportBatches}
                                on:keyup={(e) =>
                                    e.key === "Enter" && startExportBtnClick()}
                            />
                        </div>
                    </div>
                </div>

                <div class="mg-t-30  d-flex justify-content-end">
                    <!-- <button type="button" on:click="{clearExportFilter}" class="btn btn-sm btn-secondary mg-r-5">Clear filter</button> -->
                    <button
                        type="button"
                        on:click={clearExportFilter}
                        class="btn btn-sm btn-secondary mg-r-5"
                        data-dismiss="modal"
                    >
                        CANCEL
                        <i data-feather="x" class="wd-10 mg-l-5" />
                    </button>
                    <button
                        type="button"
                        disabled={exportLoading}
                        on:click={startExportBtnClick}
                        class="btn btn-sm btn-primary"
                    >
                        <ButtonLoader
                            isLoading={exportLoading}
                            btnText={exportLoading
                                ? "Expoting..."
                                : "Start Export"}
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

<!-- Filter Modal -->
<div
    class="modal effect-scale"
    id="filter-modal"
    bind:this={filterModalThis}
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel6"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a
                    href=""
                    role="button"
                    class="close pos-absolute t-15 r-15 z-index-10"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block">
                        <i data-feather="filter" class="wd-60 ht-60" /></span
                    >
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">
                            Apply filter for sorting
                        </p>
                    </div>
                </div>
                <!-- media -->
            </div>
            <!-- modal-header -->
            <hr class="full-width" />
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <div class="form-group mg-b-15">
                    <label for="exportStatus">Payments of Batches</label>
                    <select bind:this={elmSelectFilterBatches} />
                </div>
                <div class="form-group">
                    <label for="exportStatus">Payments of Students</label>
                    <select bind:this={elmSelectFilterStudents} />
                </div>
                <div class="form-group">
                    <label class="d-block">Payment Date:</label>
                    <input
                        type="date"
                        onkeydown="return false"
                        bind:value={filterStartDate}
                        class="form-control follow-up-date"
                        placeholder="Payment Date"
                    />
                </div>
                <div class="form-group">
                    <label class="d-block">Payment Type:</label>
                    <select
                        bind:this={elemPaymentType}
                        class="form-control select2"
                    >
                        <option selected value="cash">Cash</option>
                        <option value="cheque">Cheque</option>
                        <option value="dd">DD</option>
                        <option value="onlinePay">Online Payment</option>
                        <option value="upi">UPI</option>
                        <option value="card">Card</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="d-block">Payment amount:</label>
                    <input
                        type="text"
                        class="form-control follow-up-date"
                        placeholder="Payment amount"
                    />
                </div>

                <div class="form-group">
                    <label class="d-block">Receipt series:</label>
                    <input
                        type="text"
                        class="form-control follow-up-date"
                        placeholder="Receipt series"
                    />
                </div>
                <div class="form-group">
                    <label class="d-block">All upcoming installment:</label>
                    <input
                        type="text"
                        class="form-control follow-up-date"
                        placeholder="All upcoming installment"
                    />
                </div>
                <div class="form-group">
                    <label class="d-block">Due payment:</label>
                    <input
                        type="text"
                        class="form-control follow-up-date"
                        placeholder="Due payment"
                    />
                </div>
                <div class="form-group">
                    <label class="d-block">Overall payment:</label>
                    <input
                        type="text"
                        class="form-control follow-up-date"
                        placeholder="Overall payment"
                    />
                </div>

                <div class="d-md-flex justify-content-end mg-t-25">
                    <button
                        disabled={isLoading}
                        class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5"
                        data-dismiss="modal"
                        >CLEAR
                        <i data-feather="x" class="wd-10 mg-l-5" />
                    </button>
                    <button
                        disabled={isLoading}
                        class="btn btn-sm pd-x-15 btn-primary mg-l-5"
                        data-dismiss="modal"
                    >
                        <ButtonLoader {isLoading} btnText="APPLY" />
                        <i data-feather="filter" class="wd-10 mg-l-5" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

<div
    class="modal fade"
    bind:this={modalEle}
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered   modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a
                    href="#"
                    role="button"
                    class="close pos-absolute t-15 r-15"
                    data-dismiss="modal"
                    aria-label="Close"
                    on:click={clearModalFields}
                >
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"
                        ><i
                            data-feather="credit-card"
                            class="wd-60 ht-60"
                        /></span
                    >
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">{modalTitle}</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">
                            {#if paymentObj.isRefunded}
                                <!-- content here -->
                            {:else if localUserData.role == "orgAdmin" && paymentObj.freezePayment}
                                Payment Details
                                {#if MODULES.hasAccess(MODULES.UPDATE_UNPAID_RECEIPTS) || MODULES.hasAccess(MODULES.UPDATE_PAID_RECEIPTS)}
                                    <a
                                        on:click={() => {
                                            paymentObj.freezePayment = false;
                                            modalTitle = "Edit Payment";
                                            _$(
                                                elemSelectPaymentType
                                            )[0].selectize.enable();
                                        }}
                                        style="cursor:pointer;"
                                        class="btn-edit-batch"
                                        ><i
                                            class="wd-20"
                                            data-feather="edit-2"
                                        />
                                    </a>
                                {/if}
                                {#if MODULES.hasAccess(MODULES.DELETE_PAID_RECEIPT)}
                                    <a
                                        on:click={() =>
                                            deletePaymentById(
                                                paymentObj._id,
                                                true
                                            )}
                                        style="cursor:pointer;"
                                        class="btn-edit-batch"
                                        ><i
                                            class="wd-20"
                                            data-feather="trash"
                                        />
                                    </a>
                                {/if}
                            {:else}
                                Enter your Payment details below.
                            {/if}
                        </p>
                    </div>
                </div>
                <!-- media -->
            </div>
            <!-- modal-header -->

            <div class="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                {#if paymentStatus != "FREEZE" && paymentObj._id && paymentObj.paymentDate > new Date()}
                    <div
                        class="form-group"
                        style="display: flex;
            margin-top: -20px;
            margin-bottom: -18px;
            flex-direction: row-reverse;"
                    >
                        <!-- <div>
                    <p class="tx-13 tx-color-03 mg-b-0">Cannot Pay Future installments. Please change Payment date to <a href="#" on:click|preventDefault={setInstallmentDateAsToday}>Today</a></p>
                </div> -->
                        <button
                            on:click={setInstallmentDateAsToday}
                            type="button"
                            class="btn btn-primary"
                        >
                            <ButtonLoader
                                {isLoading}
                                btnText="Click here to Pay now"
                            />
                        </button>
                    </div>
                {/if}
                {#if (paymentObj.studentId && paymentObj.batchId)}
                <div class='row'>
                    <div class='col-md-12'>
                        <div class="d-flex  mg-b-10">
                            <div class="media">
                                
                                <div class="media-body">
                                    <h6
                                        class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03  tx-nowrap mg-b-5 mg-md-b-8">
                                        Agreed Fees : <span class="tx-18 " style="color:black">{convertToINR(studentBatchObj.agreedFees)}</span></h6>
                                    <!-- <h4 class="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0">
                                         </h4> -->
                                </div>
                            </div>
                            <div class="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                               
                                <div class="media-body">
                                    <h6 class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03  mg-b-5 mg-md-b-8">
                                        Total Paid : <span class="tx-18 " style="color:black"> {convertToINR(totalPaidAmount)}</span></h6>
                                    <!-- <h4 class="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0">
                                        </h4> -->
                                </div>
                            </div>
                            <div class="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                               
                                <div class="media-body">
                                    <h6 class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03  mg-b-5 mg-md-b-8">
                                        Balance Amount : <span class="tx-18 " style="color:black">{convertToINR(balanceAmount)}</span></h6>
                                    <!-- <h4 class="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0">
                                        </h4> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/if}
                <div class="row">
                    <div
                        class="form-group col-md-6"
                        class:d-none={modalTitle.toLowerCase() ==
                            "refund payment"}
                    >
                        <label
                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                            >Student List
                            <span class="required-field">*</span>
                        </label>
                        <select
                            disabled={paymentObj.freezePayment ||
                                isStudentListLoading}
                            bind:this={elemStudentSelect}
                            class="info slectizebranch form-control"
                        />
                        {#if errorObj.studentId}
                            <div class="parsley-errors-list">
                                {errorObj.studentId}
                            </div>
                        {/if}
                    </div>

                    <div
                        class="form-group col-md-6"
                        class:d-none={modalTitle.toLowerCase() ==
                            "refund payment"}
                    >
                        <label
                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                            >Batch
                            <span class="required-field">*</span>
                        </label>
                        <select
                            disabled={paymentObj.freezePayment ||
                                !paymentObj.studentId}
                            bind:this={elemBatchSelect}
                            class="info slectizebranch form-control"
                        />
                        {#if errorObj.batchId}
                            <div class="parsley-errors-list">
                                {errorObj.batchId}
                            </div>
                        {:else if ifBatchListNotPresent != ""}
                            <div class="parsley-errors-list">
                                {ifBatchListNotPresent}
                            </div>
                        {/if}
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label
                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                            >Payment Type <span class="required-field">*</span
                            ></label
                        >
                        <select
                            disabled={paymentObj.freezePayment}
                            bind:this={elemSelectPaymentType}
                            class=""
                        >
                            <option selected value="cash">Cash</option>
                            <option value="cheque">Cheque</option>
                            <!-- <option value="dd">DD</option> -->
                            <option value="onlinePay">Online Payment</option>
                            <option value="upi">UPI</option>
                            <option value="card">Card</option>
                        </select>
                        {#if errorObj.paymentType}
                            <div class="parsley-errors-list">
                                {errorObj.paymentType}
                            </div>
                        {/if}
                        {#if paymentObj.paymentType == "cheque"}
                            <div class="text-primary">
                                To Clear Cheque please Visit PDC section.
                            </div>
                        {/if}
                    </div>
                    {#if paymentObj.paymentType == "cash" || paymentObj.paymentType == "dd" || paymentObj.paymentType == "onlinePay" || paymentObj.paymentType == "upi" || paymentObj.paymentType == "card"}
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Payment Date<span class="required-field"
                                    >*</span
                                ></label
                            >
                            <input
                                onkeydown="return false"
                                disabled={paymentObj.freezePayment ||
                                    !paymentObj.batchId ||
                                    !paymentObj.studentId ||
                                    modalTitle.toLowerCase() ==
                                        "refund payment"}
                                on:change={updatePaymentDate}
                                type="date"
                                bind:value={paymentDateStr}
                                on:blur={() => clearError("paymentDate")}
                                class="form-control"
                                class:parsley-error={errorObj.paymentDate}
                                placeholder="Select Date"
                            />
                            {#if errorObj.paymentDate}
                                <div class="parsley-errors-list">
                                    {errorObj.paymentDate}
                                </div>
                            {/if}
                        </div>
                    {/if}
                    {#if paymentObj.paymentType == "cheque"}
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Cheque Date<span class="required-field">*</span
                                ></label
                            >
                            <input
                                disabled={paymentObj.freezePayment ||
                                    !paymentObj.batchId ||
                                    !paymentObj.studentId}
                                on:change={updatePaymentDate}
                                type="date"
                                bind:value={paymentDateStr}
                                on:blur={() => clearError("paymentDate")}
                                class="form-control"
                                class:parsley-error={errorObj.paymentDate}
                                placeholder="Select Date"
                            />
                            {#if errorObj.paymentDate}
                                <div class="parsley-errors-list">
                                    {errorObj.paymentDate}
                                </div>
                            {/if}
                        </div>
                    {/if}
                </div>
                {#if paymentObj.paymentType == "cash"}
                    <div class="row">
                        {#if paymentObj.paymentDate > new Date()}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Installment Amount<span
                                        class="required-field">*</span
                                    ></label
                                >
                                <input
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:value={paymentObj.installmentAmount}
                                    type="number"
                                    on:change={() => updatePaidStatus()}
                                    on:blur={() =>
                                        clearError("installmentAmount")}
                                    class="form-control"
                                    class:parsley-error={errorObj.installmentAmount}
                                    placeholder="Enter
                        Installment
                        Amount"
                                />
                                {#if errorObj.installmentAmount}
                                    <div class="parsley-errors-list">
                                        {errorObj.installmentAmount}
                                    </div>
                                {/if}
                            </div>
                        {/if}
                        {#if !(paymentObj.paymentDate > new Date())}
                            {#if paymentObj._id}
                                <div class="form-group col-md-6">
                                    <label
                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                        >Installment Amount<span
                                            class="required-field">*</span
                                        ></label
                                    >
                                    <input
                                        disabled={paymentObj.freezePayment ||
                                            batchesList1.length == 0}
                                        bind:value={paymentObj.installmentAmount}
                                        type="number"
                                        on:change={() => updatePaidStatus()}
                                        on:blur={() =>
                                            clearError("installmentAmount")}
                                        class="form-control"
                                        class:parsley-error={errorObj.installmentAmount}
                                        placeholder="Enter
                        Installment
                        Amount"
                                    />
                                    {#if errorObj.installmentAmount}
                                        <div class="parsley-errors-list">
                                            {errorObj.installmentAmount}
                                        </div>
                                    {/if}
                                </div>
                            {/if}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Amount<span class="required-field">*</span
                                    ></label
                                >
                                <input
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0 ||
                                        !paymentObj.batchId}
                                    bind:value={paymentObj.paidAmount}
                                    type="number"
                                    on:keyup={() => updatePaidStatus()}
                                    on:blur={() => clearError("paidAmount")}
                                    class="form-control"
                                    class:parsley-error={errorObj.paidAmount}
                                    placeholder="Enter Amount"
                                />
                                {#if errorObj.paidAmount}
                                    <div class="parsley-errors-list">
                                        {errorObj.paidAmount}
                                    </div>
                                {/if}
                            </div>
                            <div
                                class="form-group col-md-6"
                                class:d-none={modalTitle.toLowerCase() ==
                                    "refund payment"}
                            >
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Branch List<span class="required-field"
                                        >*</span
                                    ></label
                                >
                                <select
                                    id="elemBranchListSelect_aaa"
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:this={elemBranchListSelect}
                                    class="info form-control"
                                />
                                {#if errorObj.branchlistError}
                                    <div class="parsley-errors-list">
                                        {errorObj.branchlistError}
                                    </div>
                                {/if}
                                <div class="parsley-errors-list">
                                    {ifListNotPresent || ""}
                                </div>
                            </div>

                            {#if !(paymentObj.paymentDate > new Date())}
                                {#if receiptSeriesLists.length}
                                    <!-- content here -->
                                    <div
                                        class="form-group col-md-6"
                                        class:d-none={paymentObj.freezePayment ||
                                            modalTitle.toLowerCase() ==
                                                "refund payment"}
                                    >
                                        <label
                                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                            >Receipt Series<span
                                                class="required-field">*</span
                                            ></label
                                        >
                                        <select
                                            disabled={paymentObj.freezePayment ||
                                                batchesList1.length == 0}
                                            bind:this={elemReceiptType}
                                            class="info slectizedd form-control"
                                        >
                                            {#if errorObj.paymentType}
                                                <div
                                                    class="parsley-errors-list"
                                                >
                                                    {errorObj.paymentType}
                                                </div>
                                            {/if}
                                        </select>
                                    </div>
                                    {#if !paymentObj.freezePayment}
                                        <!-- content here -->
                                        <div class="form-group col-md-6">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6">
                                                    <label
                                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                                        >Prefix<span
                                                            class="required-field"
                                                            >*</span
                                                        ></label
                                                    >
                                                    <input
                                                        disabled={paymentObj.freezePayment ||
                                                            true ||
                                                            batchesList1.length ==
                                                                0}
                                                        bind:value={paymentObj.prefix}
                                                        type="text"
                                                        on:keyup={() =>
                                                            populateReceiptNumber()}
                                                        on:blur={() =>
                                                            clearError(
                                                                "receiptNo"
                                                            )}
                                                        class="form-control"
                                                        class:parsley-error={errorObj.prefix}
                                                        placeholder="Enter Prefix"
                                                    />
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <label
                                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                                        >Series<span
                                                            class="required-field"
                                                            >*</span
                                                        ></label
                                                    >
                                                    <input
                                                        disabled={paymentObj.freezePayment ||
                                                            true}
                                                        bind:value={paymentObj.sequence}
                                                        type="number"
                                                        on:keyup={() =>
                                                            populateReceiptNumber()}
                                                        on:blur={() =>
                                                            clearError(
                                                                "sequence"
                                                            )}
                                                        class="form-control"
                                                        class:parsley-error={errorObj.receiptNo}
                                                        placeholder="Enter Sequence"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <!-- {/if}
                    {/if}
                    {/if}
                    {#if !(paymentObj.paymentDate > new Date())}
                    {#if receiptSeriesLists.length}
                    {#if !paymentObj.freezePayment} -->
                                        <div
                                            class="form-group col-md-6"
                                            class:d-none={modalTitle.toLowerCase() ==
                                                "refund payment"}
                                        >
                                            <label
                                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                                >Receipt No<span
                                                    class="required-field"
                                                    >*</span
                                                ></label
                                            >
                                            <input
                                                disabled={paymentObj.freezePayment ||
                                                    true ||
                                                    batchesList1.length == 0}
                                                bind:value={paymentObj.receiptNo}
                                                type="text"
                                                on:change={() =>
                                                    updatePaidStatus()}
                                                on:blur={() =>
                                                    clearError("receiptNo")}
                                                class="form-control"
                                                class:parsley-error={errorObj.receiptNo}
                                                placeholder="Enter Receipt No"
                                            />
                                            {#if errorObj.receiptNo}
                                                <div
                                                    class="parsley-errors-list"
                                                >
                                                    {errorObj.receiptNo}
                                                </div>
                                            {/if}
                                        </div>
                                    {/if}
                                {/if}
                            {/if}
                        {/if}
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Remarks
                                {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                                    <span class="required-field">*</span>
                                {/if}
                            </label>
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0 ||
                                    !paymentObj.batchId}
                                bind:value={paymentObj.remark}
                                type="textarea"
                                on:blur={() => clearError("remark")}
                                class="form-control"
                                class:parsley-error={errorObj.remark}
                                placeholder="Add Remarks..."
                            />
                            {#if errorObj.remark}
                                <div class="parsley-errors-list">
                                    {errorObj.remark}
                                </div>
                            {/if}
                        </div>
                    </div>
                    <div class="row" />
                    <div class="row" />
                {/if}

                {#if paymentObj.paymentType == "cheque"}
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Cheque No<span class="required-field">*</span
                                ></label
                            >
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                maxlength="6"
                                bind:value={paymentObj.chequeNo}
                                type="text"
                                on:change={() => updatePaidStatus()}
                                on:blur={() => clearError("chequeNo")}
                                class="form-control"
                                class:parsley-error={errorObj.chequeNo}
                                placeholder="Enter Cheque No"
                            />
                            {#if errorObj.chequeNo}
                                <div class="parsley-errors-list">
                                    {errorObj.chequeNo}
                                </div>
                            {/if}
                        </div>

                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Bank Name<span class="required-field">*</span
                                ></label
                            >
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:value={paymentObj.bankName}
                                type="text"
                                on:change={() => updatePaidStatus()}
                                on:blur={() => clearError("bankName")}
                                class="form-control"
                                class:parsley-error={errorObj.bankName}
                                placeholder="Enter Bank Name"
                            />
                            {#if errorObj.bankName}
                                <div class="parsley-errors-list">
                                    {errorObj.bankName}
                                </div>
                            {/if}
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Cheque Amount<span class="required-field"
                                    >*</span
                                ></label
                            >
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:value={paymentObj.installmentAmount}
                                type="number"
                                on:change={() => updatePaidStatus()}
                                on:blur={() => clearError("installmentAmount")}
                                class="form-control"
                                class:parsley-error={errorObj.installmentAmount}
                                placeholder="Enter Cheque Amount"
                            />
                            {#if errorObj.installmentAmount}
                                <div class="parsley-errors-list">
                                    {errorObj.installmentAmount}
                                </div>
                            {/if}
                        </div>
                        {#if paymentObj._id}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Cleared Amount</label
                                >
                                <input
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:value={paymentObj.paidAmount}
                                    type="number"
                                    on:keyup={() => updatePaidStatus()}
                                    on:blur={() => clearError("paidAmount")}
                                    class="form-control"
                                    class:parsley-error={errorObj.paidAmount}
                                    placeholder="Cleared Amount"
                                />
                                {#if errorObj.paidAmount}
                                    <div class="parsley-errors-list">
                                        {errorObj.paidAmount}
                                    </div>
                                {/if}
                            </div>
                        {/if}
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Branch List<span class="required-field">*</span
                                ></label
                            >
                            <select
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:this={elemBranchListSelect}
                                class="info slectizebranch form-control"
                            />
                            {#if errorObj.branchlistError}
                                <div class="parsley-errors-list">
                                    {errorObj.branchlistError}
                                </div>
                            {/if}
                            <div class="parsley-errors-list">
                                {ifListNotPresent || ""}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        {#if receiptSeriesLists.length}
                            <!-- content here -->
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Receipt Series<span class="required-field"
                                        >*</span
                                    ></label
                                >
                                <select
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:this={elemReceiptType}
                                    class="info slectizedd form-control"
                                >
                                    {#if errorObj.paymentType}
                                        <div class="parsley-errors-list">
                                            {errorObj.paymentType}
                                        </div>
                                    {/if}
                                </select>
                            </div>
                            {#if !paymentObj.freezePayment}
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-6">
                                            <label
                                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                                >Prefix</label
                                            >
                                            <input
                                                disabled={paymentObj.freezePayment ||
                                                    true ||
                                                    batchesList1.length == 0}
                                                bind:value={paymentObj.prefix}
                                                type="text"
                                                on:keyup={() =>
                                                    populateReceiptNumber()}
                                                on:blur={() =>
                                                    clearError("prefix")}
                                                class="form-control"
                                                class:parsley-error={errorObj.prefix}
                                                placeholder="Enter Prefix"
                                            />
                                        </div>
                                        <div class="col-md-6 col-sm-6">
                                            <label
                                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                                >Sequence</label
                                            >
                                            <input
                                                disabled={paymentObj.freezePayment ||
                                                    true ||
                                                    batchesList1.length == 0}
                                                bind:value={paymentObj.sequence}
                                                type="number"
                                                on:keyup={() =>
                                                    populateReceiptNumber()}
                                                on:blur={() =>
                                                    clearError("sequence")}
                                                class="form-control"
                                                class:parsley-error={errorObj.receiptNo}
                                                placeholder="Enter Sequence"
                                            />
                                        </div>
                                    </div>
                                </div>
                            {/if}
                        {/if}
                        {#if receiptSeriesLists.length}
                            <!-- content here -->
                            {#if !paymentObj.freezePayment}
                                <div class="form-group col-md-6">
                                    <label
                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                        >Receipt No<span class="required-field"
                                            >*</span
                                        ></label
                                    >
                                    <input
                                        disabled={paymentObj.freezePayment ||
                                            true ||
                                            batchesList1.length == 0}
                                        bind:value={paymentObj.receiptNo}
                                        type="text"
                                        on:change={() => updatePaidStatus()}
                                        on:blur={() => clearError("receiptNo")}
                                        class="form-control"
                                        class:parsley-error={errorObj.receiptNo}
                                        placeholder="Enter Receipt No"
                                    />
                                    {#if errorObj.receiptNo}
                                        <div class="parsley-errors-list">
                                            {errorObj.receiptNo}
                                        </div>
                                    {/if}
                                </div>
                            {/if}
                        {/if}
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Remarks
                                {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                                    <span class="required-field">*</span>
                                {/if}
                            </label>
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:value={paymentObj.remark}
                                type="textarea"
                                on:blur={() => clearError("remark")}
                                class="form-control"
                                class:parsley-error={errorObj.remark}
                                placeholder="Add Remarks..."
                            />
                            {#if errorObj.remark}
                                <div class="parsley-errors-list">
                                    {errorObj.remark}
                                </div>
                            {/if}
                        </div>
                    </div>

                    <div class="row" />
                {/if}

                {#if paymentObj.paymentType == "dd"}
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Demand Draft Date<span class="required-field"
                                    >*</span
                                ></label
                            >
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                on:change={updatePaymentDate}
                                type="date"
                                bind:value={paymentDateStr}
                                on:blur={() => clearError("paymentDate")}
                                class="form-control"
                                class:parsley-error={errorObj.paymentDate}
                                placeholder="Select Date"
                            />
                            {#if errorObj.paymentDate}
                                <div class="parsley-errors-list">
                                    {errorObj.paymentDate}
                                </div>
                            {/if}
                        </div>
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Demand Draft No</label
                            >
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:value={paymentObj.ddNo}
                                type="text"
                                on:change={() => updatePaidStatus()}
                                on:blur={() => clearError("ddNo")}
                                class="form-control"
                                class:parsley-error={errorObj.ddNo}
                                placeholder="Enter Demand Draft No"
                            />
                            {#if errorObj.ddNo}
                                <div class="parsley-errors-list">
                                    {errorObj.ddNo}
                                </div>
                            {/if}
                        </div>
                    </div>
                    <div class="row">
                        {#if paymentObj.paymentDate > new Date()}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Demand Draft Amount</label
                                >
                                <input
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:value={paymentObj.installmentAmount}
                                    type="number"
                                    on:change={() => updatePaidStatus()}
                                    on:blur={() =>
                                        clearError("installmentAmount")}
                                    class="form-control"
                                    class:parsley-error={errorObj.installmentAmount}
                                    placeholder="Enter Demand
                        Draft Amount"
                                />
                                {#if errorObj.installmentAmount}
                                    <div class="parsley-errors-list">
                                        {errorObj.installmentAmount}
                                    </div>
                                {/if}
                            </div>
                        {/if}
                        {#if !(paymentObj.paymentDate > new Date())}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Demand Draft Amount</label
                                >
                                <input
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:value={paymentObj.installmentAmount}
                                    type="number"
                                    on:change={() => updatePaidStatus()}
                                    on:blur={() =>
                                        clearError("installmentAmount")}
                                    class="form-control"
                                    class:parsley-error={errorObj.installmentAmount}
                                    placeholder="Enter Demand
                        Draft Amount"
                                />
                                {#if errorObj.installmentAmount}
                                    <div class="parsley-errors-list">
                                        {errorObj.installmentAmount}
                                    </div>
                                {/if}
                            </div>
                            {#if paymentObj._id}
                                <div class="form-group col-md-6">
                                    <label
                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                        >Cleared Amount</label
                                    >
                                    <input
                                        disabled={paymentObj.freezePayment ||
                                            batchesList1.length == 0}
                                        bind:value={paymentObj.paidAmount}
                                        type="number"
                                        on:keyup={() => updatePaidStatus()}
                                        on:blur={() => clearError("paidAmount")}
                                        class="form-control"
                                        class:parsley-error={errorObj.paidAmount}
                                        placeholder="Cleared Amount"
                                    />
                                    {#if errorObj.paidAmount}
                                        <div class="parsley-errors-list">
                                            {errorObj.paidAmount}
                                        </div>
                                    {/if}
                                </div>
                            {/if}
                        {/if}
                    </div>
                    <div class="row">
                        {#if !(paymentObj.paymentDate > new Date())}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Branch List<span class="required-field"
                                        >*</span
                                    ></label
                                >
                                <select
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:this={elemBranchListSelect}
                                    class="elemBranchListSelect info slectizebranch form-control"
                                />
                                {#if errorObj.branchlistError}
                                    <div class="parsley-errors-list">
                                        {errorObj.branchlistError}
                                    </div>
                                {/if}
                                <div class="parsley-errors-list">
                                    {ifListNotPresent || ""}
                                </div>
                            </div>

                            {#if receiptSeriesLists.length}
                                <!-- content here -->
                                <div class="form-group col-md-6">
                                    <label
                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                        >Receipt Series (Next Reciept Series For
                                        Payment Only)<span
                                            class="required-field">*</span
                                        ></label
                                    >
                                    <select
                                        disabled={paymentObj.freezePayment ||
                                            batchesList1.length == 0}
                                        bind:this={elemReceiptType}
                                        class="info slectizedd form-control"
                                    >
                                        {#if errorObj.paymentType}
                                            <div class="parsley-errors-list">
                                                {errorObj.paymentType}
                                            </div>
                                        {/if}
                                    </select>
                                </div>
                            {/if}
                        {/if}
                    </div>
                    <div class="row">
                        {#if !paymentObj.freezePayment}
                            <!-- content here -->
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6">
                                        <label
                                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                            >Prefix</label
                                        >
                                        <input
                                            disabled={paymentObj.freezePayment ||
                                                true ||
                                                batchesList1.length == 0}
                                            bind:value={paymentObj.prefix}
                                            type="text"
                                            on:keyup={() =>
                                                populateReceiptNumber()}
                                            on:blur={() => clearError("prefix")}
                                            class="form-control"
                                            class:parsley-error={errorObj.prefix}
                                            placeholder="Enter Prefix"
                                        />
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <label
                                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                            >Receipt No</label
                                        >
                                        <input
                                            disabled={paymentObj.freezePayment ||
                                                true ||
                                                batchesList1.length == 0}
                                            bind:value={paymentObj.sequence}
                                            type="number"
                                            on:keyup={() =>
                                                populateReceiptNumber()}
                                            on:blur={() =>
                                                clearError("sequence")}
                                            class="form-control"
                                            class:parsley-error={errorObj.receiptNo}
                                            placeholder="Enter Sequence"
                                        />
                                    </div>
                                </div>

                                <!-- {#if errorObj.receiptNo}
                                                                    <div class="parsley-errors-list">{errorObj.receiptNo}</div>
                                                                {/if} -->
                            </div>
                        {/if}
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Remarks
                                {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                                    <span class="required-field">*</span>
                                {/if}
                            </label>
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:value={paymentObj.remark}
                                type="textarea"
                                on:blur={() => clearError("remark")}
                                class="form-control"
                                class:parsley-error={errorObj.remark}
                                placeholder="Add Remarks..."
                            />
                            {#if errorObj.remark}
                                <div class="parsley-errors-list">
                                    {errorObj.remark}
                                </div>
                            {/if}
                        </div>
                    </div>
                {/if}
                {#if paymentObj.paymentType == "onlinePay"}
                    <div class="row">
                        {#if paymentObj.paymentDate > new Date()}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Installment Amount<span
                                        class="required-field">*</span
                                    ></label
                                >
                                <input
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:value={paymentObj.installmentAmount}
                                    type="number"
                                    on:change={() => updatePaidStatus()}
                                    on:blur={() =>
                                        clearError("installmentAmount")}
                                    class="form-control"
                                    class:parsley-error={errorObj.installmentAmount}
                                    placeholder="Enter
                        Installment
                        Amount"
                                />
                                {#if errorObj.installmentAmount}
                                    <div class="parsley-errors-list">
                                        {errorObj.installmentAmount}
                                    </div>
                                {/if}
                            </div>
                        {/if}
                        {#if !(paymentObj.paymentDate > new Date())}
                            {#if paymentObj._id}
                                <div class="form-group col-md-6">
                                    <label
                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                        >Installment Amount<span
                                            class="required-field">*</span
                                        ></label
                                    >
                                    <input
                                        disabled={paymentObj.freezePayment ||
                                            batchesList1.length == 0}
                                        bind:value={paymentObj.installmentAmount}
                                        type="number"
                                        on:change={() => updatePaidStatus()}
                                        on:blur={() =>
                                            clearError("installmentAmount")}
                                        class="form-control"
                                        class:parsley-error={errorObj.installmentAmount}
                                        placeholder="Enter
                        Installment
                        Amount"
                                    />
                                    {#if errorObj.installmentAmount}
                                        <div class="parsley-errors-list">
                                            {errorObj.installmentAmount}
                                        </div>
                                    {/if}
                                </div>
                            {/if}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Amount<span class="required-field">*</span
                                    ></label
                                >
                                <input
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0 ||
                                        !paymentObj.batchId}
                                    bind:value={paymentObj.paidAmount}
                                    type="number"
                                    on:keyup={() => updatePaidStatus()}
                                    on:blur={() => clearError("paidAmount")}
                                    class="form-control"
                                    class:parsley-error={errorObj.paidAmount}
                                    placeholder="Enter Amount"
                                />
                                {#if errorObj.paidAmount}
                                    <div class="parsley-errors-list">
                                        {errorObj.paidAmount}
                                    </div>
                                {/if}
                            </div>

                            <div
                                class="form-group col-md-6"
                                class:d-none={modalTitle.toLowerCase() ==
                                    "refund payment"}
                            >
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Branch List<span class="required-field"
                                        >*</span
                                    ></label
                                >
                                <select
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:this={elemBranchListSelect}
                                    class="info slectizebranch form-control"
                                />
                                {#if errorObj.branchlistError}
                                    <div class="parsley-errors-list">
                                        {errorObj.branchlistError}
                                    </div>
                                {/if}
                                <div class="parsley-errors-list">
                                    {ifListNotPresent || ""}
                                </div>
                            </div>

                            {#if receiptSeriesLists.length}
                                <!-- content here -->
                                <div class="form-group col-md-6">
                                    <label
                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                        >Receipt Series (Next Reciept Series For
                                        Payment Only)<span
                                            class="required-field">*</span
                                        ></label
                                    >
                                    <select
                                        disabled={paymentObj.freezePayment ||
                                            batchesList1.length == 0}
                                        bind:this={elemReceiptType}
                                        class="info slectizedd form-control"
                                    >
                                        {#if errorObj.paymentType}
                                            <div class="parsley-errors-list">
                                                {errorObj.paymentType}
                                            </div>
                                        {/if}
                                    </select>
                                </div>
                                {#if !paymentObj.freezePayment}
                                    <!-- content here -->
                                    <div class="form-group col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <label
                                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                                    >Prefix</label
                                                >
                                                <input
                                                    disabled={paymentObj.freezePayment ||
                                                        true ||
                                                        batchesList1.length ==
                                                            0}
                                                    bind:value={paymentObj.prefix}
                                                    type="text"
                                                    on:keyup={() =>
                                                        populateReceiptNumber()}
                                                    on:blur={() =>
                                                        clearError("prefix")}
                                                    class="form-control"
                                                    class:parsley-error={errorObj.prefix}
                                                    placeholder="Enter Prefix"
                                                />
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <label
                                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                                    >Receipt No
                                                </label>
                                                <input
                                                    disabled={paymentObj.freezePayment ||
                                                        true ||
                                                        batchesList1.length ==
                                                            0}
                                                    bind:value={paymentObj.sequence}
                                                    type="number"
                                                    on:keyup={() =>
                                                        populateReceiptNumber()}
                                                    on:blur={() =>
                                                        clearError("sequence")}
                                                    class="form-control"
                                                    class:parsley-error={errorObj.receiptNo}
                                                    placeholder="Enter Sequence"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                {/if}
                            {/if}
                            {#if receiptSeriesLists.length}
                                {#if !paymentObj.freezePayment}
                                    <div
                                        class="form-group col-md-6"
                                        class:d-none={modalTitle.toLowerCase() ==
                                            "refund payment"}
                                    >
                                        <label
                                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                            >Receipt No<span
                                                class="required-field">*</span
                                            ></label
                                        >
                                        <input
                                            disabled={paymentObj.freezePayment ||
                                                true ||
                                                batchesList1.length == 0}
                                            bind:value={paymentObj.receiptNo}
                                            type="text"
                                            on:change={() => updatePaidStatus()}
                                            on:blur={() =>
                                                clearError("receiptNo")}
                                            class="form-control"
                                            class:parsley-error={errorObj.receiptNo}
                                            placeholder="Enter Receipt No"
                                        />
                                        {#if errorObj.receiptNo}
                                            <div class="parsley-errors-list">
                                                {errorObj.receiptNo}
                                            </div>
                                        {/if}
                                    </div>
                                {/if}
                            {/if}
                        {/if}
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Reference No</label
                            >
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:value={paymentObj.refNo}
                                type="text"
                                on:change={() => updatePaidStatus()}
                                on:blur={() => clearError("refNo")}
                                class="form-control"
                                class:parsley-error={errorObj.refNo}
                                placeholder="Enter Reference No"
                            />
                            {#if errorObj.refNo}
                                <div class="parsley-errors-list">
                                    {errorObj.refNo}
                                </div>
                            {/if}
                        </div>
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Remarks
                                {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                                    <span class="required-field">*</span>
                                {/if}
                            </label>
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:value={paymentObj.remark}
                                type="textarea"
                                on:blur={() => clearError("remark")}
                                class="form-control"
                                class:parsley-error={errorObj.remark}
                                placeholder="Add Remarks..."
                            />
                            {#if errorObj.remark}
                                <div class="parsley-errors-list">
                                    {errorObj.remark}
                                </div>
                            {/if}
                        </div>
                    </div>
                    <div class="row" />
                    <div class="row" />
                {/if}

                {#if paymentObj.paymentType == "upi"}
                    <div class="row">
                        {#if paymentObj.paymentDate > new Date()}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Installment Amount<span
                                        class="required-field">*</span
                                    ></label
                                >
                                <input
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0 ||
                                        modalTitle.toLowerCase() ==
                                            "refund payment"}
                                    bind:value={paymentObj.installmentAmount}
                                    type="number"
                                    on:change={() => updatePaidStatus()}
                                    on:blur={() =>
                                        clearError("installmentAmount")}
                                    class="form-control"
                                    class:parsley-error={errorObj.installmentAmount}
                                    placeholder="Enter Installment Amount"
                                />
                                {#if errorObj.installmentAmount}
                                    <div class="parsley-errors-list">
                                        {errorObj.installmentAmount}
                                    </div>
                                {/if}
                            </div>
                        {/if}
                        {#if !(paymentObj.paymentDate > new Date())}
                            {#if paymentObj._id}
                                <div class="form-group col-md-6">
                                    <label
                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                        >Installment Amount<span
                                            class="required-field">*</span
                                        ></label
                                    >
                                    <input
                                        disabled={paymentObj.freezePayment ||
                                            batchesList1.length == 0}
                                        bind:value={paymentObj.installmentAmount}
                                        type="number"
                                        on:change={() => updatePaidStatus()}
                                        on:blur={() =>
                                            clearError("installmentAmount")}
                                        class="form-control"
                                        class:parsley-error={errorObj.installmentAmount}
                                        placeholder="Enter
                        Installment
                        Amount"
                                    />
                                    {#if errorObj.installmentAmount}
                                        <div class="parsley-errors-list">
                                            {errorObj.installmentAmount}
                                        </div>
                                    {/if}
                                </div>
                            {/if}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Amount<span class="required-field">*</span
                                    ></label
                                >
                                <input
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0 ||
                                        !paymentObj.batchId}
                                    bind:value={paymentObj.paidAmount}
                                    type="number"
                                    on:keyup={() => updatePaidStatus()}
                                    on:blur={() => clearError("paidAmount")}
                                    class="form-control"
                                    class:parsley-error={errorObj.paidAmount}
                                    placeholder="Enter Amount"
                                />
                                {#if errorObj.paidAmount}
                                    <div class="parsley-errors-list">
                                        {errorObj.paidAmount}
                                    </div>
                                {/if}
                            </div>
                            <div
                                class="form-group col-md-6"
                                class:d-none={modalTitle.toLowerCase() ==
                                    "refund payment"}
                            >
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Branch List<span class="required-field"
                                        >*</span
                                    ></label
                                >
                                <select
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:this={elemBranchListSelect}
                                    class="info slectizebranch form-control"
                                />
                                {#if errorObj.branchlistError}
                                    <div class="parsley-errors-list">
                                        {errorObj.branchlistError}
                                    </div>
                                {/if}
                                <div class="parsley-errors-list">
                                    {ifListNotPresent || ""}
                                </div>
                            </div>
                        {/if}
                        {#if !(paymentObj.paymentDate > new Date())}
                            {#if receiptSeriesLists.length}
                                <!-- content here -->
                                <div class="form-group col-md-6">
                                    <label
                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                        >Receipt Series (Next Reciept Series For
                                        Payment Only)<span
                                            class="required-field">*</span
                                        ></label
                                    >
                                    <select
                                        disabled={paymentObj.freezePayment ||
                                            batchesList1.length == 0}
                                        bind:this={elemReceiptType}
                                        class="info slectizedd form-control"
                                    >
                                        {#if errorObj.paymentType}
                                            <div class="parsley-errors-list">
                                                {errorObj.paymentType}
                                            </div>
                                        {/if}
                                    </select>
                                </div>
                                {#if !paymentObj.freezePayment}
                                    <!-- content here -->
                                    <div class="form-group col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <label
                                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                                    >Prefix</label
                                                >
                                                <input
                                                    disabled={paymentObj.freezePayment ||
                                                        true ||
                                                        batchesList1.length ==
                                                            0}
                                                    bind:value={paymentObj.prefix}
                                                    type="text"
                                                    on:keyup={() =>
                                                        populateReceiptNumber()}
                                                    on:blur={() =>
                                                        clearError("prefix")}
                                                    class="form-control"
                                                    class:parsley-error={errorObj.prefix}
                                                    placeholder="Enter Prefix"
                                                />
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <label
                                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                                    >Receipt No</label
                                                >
                                                <input
                                                    disabled={paymentObj.freezePayment ||
                                                        true ||
                                                        batchesList1.length ==
                                                            0}
                                                    bind:value={paymentObj.sequence}
                                                    type="number"
                                                    on:keyup={() =>
                                                        populateReceiptNumber()}
                                                    on:blur={() =>
                                                        clearError("sequence")}
                                                    class="form-control"
                                                    class:parsley-error={errorObj.receiptNo}
                                                    placeholder="Enter Sequence"
                                                />
                                            </div>
                                        </div>

                                        <!-- {#if errorObj.receiptNo}
                                            <div class="parsley-errors-list">{errorObj.receiptNo}</div>
                                        {/if} -->
                                    </div>
                                    <!-- {/if}
                    {/if}
                    {/if}
                    {#if !(paymentObj.paymentDate > new Date())}
                    {#if receiptSeriesLists.length}
                    {#if !paymentObj.freezePayment} -->
                                    <div
                                        class="form-group col-md-6"
                                        class:d-none={modalTitle.toLowerCase() ==
                                            "refund payment"}
                                    >
                                        <label
                                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                            >Receipt No<span
                                                class="required-field">*</span
                                            ></label
                                        >
                                        <input
                                            disabled={paymentObj.freezePayment ||
                                                true ||
                                                batchesList1.length == 0}
                                            bind:value={paymentObj.receiptNo}
                                            type="text"
                                            on:change={() => updatePaidStatus()}
                                            on:blur={() =>
                                                clearError("receiptNo")}
                                            class="form-control"
                                            class:parsley-error={errorObj.receiptNo}
                                            placeholder="Enter Receipt No"
                                        />
                                        {#if errorObj.receiptNo}
                                            <div class="parsley-errors-list">
                                                {errorObj.receiptNo}
                                            </div>
                                        {/if}
                                    </div>
                                {/if}
                            {/if}
                        {/if}
                        <div
                            class="form-group col-md-6"
                            class:d-none={modalTitle.toLowerCase() ==
                                "refund payment"}
                        >
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Trasaction No</label
                            >
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:value={paymentObj.upiNo}
                                type="text"
                                on:change={() => updatePaidStatus()}
                                on:blur={() => clearError("upiNo")}
                                class="form-control"
                                class:parsley-error={errorObj.upiNo}
                                placeholder="Enter Transaction No"
                            />
                            {#if errorObj.upiNo}
                                <div class="parsley-errors-list">
                                    {errorObj.upiNo}
                                </div>
                            {/if}
                        </div>
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Remarks
                                {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                                    <span class="required-field">*</span>
                                {/if}
                            </label>
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:value={paymentObj.remark}
                                type="textarea"
                                on:blur={() => clearError("remark")}
                                class="form-control"
                                class:parsley-error={errorObj.remark}
                                placeholder="Add Remarks..."
                            />
                            {#if errorObj.remark}
                                <div class="parsley-errors-list">
                                    {errorObj.remark}
                                </div>
                            {/if}
                        </div>
                    </div>
                    <div class="row" />
                    <div class="row" />
                    <!-- <div class="row">
            
        </div> -->
                {/if}

                {#if paymentObj.paymentType == "card"}
                    <div class="row">
                        {#if paymentObj.paymentDate > new Date()}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Installment Amount<span
                                        class="required-field">*</span
                                    ></label
                                >
                                <input
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:value={paymentObj.installmentAmount}
                                    type="number"
                                    on:change={() => updatePaidStatus()}
                                    on:blur={() =>
                                        clearError("installmentAmount")}
                                    class="form-control"
                                    class:parsley-error={errorObj.installmentAmount}
                                    placeholder="Enter
                        Installment
                        Amount"
                                />
                                {#if errorObj.installmentAmount}
                                    <div class="parsley-errors-list">
                                        {errorObj.installmentAmount}
                                    </div>
                                {/if}
                            </div>
                        {/if}
                        {#if !(paymentObj.paymentDate > new Date())}
                            {#if paymentObj._id}
                                <div class="form-group col-md-6">
                                    <label
                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                        >Installment Amount<span
                                            class="required-field">*</span
                                        ></label
                                    >
                                    <input
                                        disabled={paymentObj.freezePayment ||
                                            batchesList1.length == 0}
                                        bind:value={paymentObj.installmentAmount}
                                        type="number"
                                        on:change={() => updatePaidStatus()}
                                        on:blur={() =>
                                            clearError("installmentAmount")}
                                        class="form-control"
                                        class:parsley-error={errorObj.installmentAmount}
                                        placeholder="Enter
                            Installment
                            Amount"
                                    />
                                    {#if errorObj.installmentAmount}
                                        <div class="parsley-errors-list">
                                            {errorObj.installmentAmount}
                                        </div>
                                    {/if}
                                </div>
                            {/if}
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Amount<span class="required-field">*</span
                                    ></label
                                >
                                <input
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0 ||
                                        !paymentObj.batchId}
                                    bind:value={paymentObj.paidAmount}
                                    type="number"
                                    on:keyup={() => updatePaidStatus()}
                                    on:blur={() => clearError("paidAmount")}
                                    class="form-control"
                                    class:parsley-error={errorObj.paidAmount}
                                    placeholder="Enter Amount"
                                />
                                {#if errorObj.paidAmount}
                                    <div class="parsley-errors-list">
                                        {errorObj.paidAmount}
                                    </div>
                                {/if}
                            </div>
                            <div class="form-group col-md-6">
                                <label
                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                    >Branch List<span class="required-field"
                                        >*</span
                                    ></label
                                >
                                <select
                                    disabled={paymentObj.freezePayment ||
                                        batchesList1.length == 0}
                                    bind:this={elemBranchListSelect}
                                    class="info slectizebranch form-control"
                                />
                                {#if errorObj.branchlistError}
                                    <div class="parsley-errors-list">
                                        {errorObj.branchlistError}
                                    </div>
                                {/if}
                                <div class="parsley-errors-list">
                                    {ifListNotPresent || ""}
                                </div>
                            </div>
                        {/if}
                        {#if !(paymentObj.paymentDate > new Date())}
                            {#if receiptSeriesLists.length}
                                <!-- content here -->
                                <div class="form-group col-md-6">
                                    <label
                                        class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                        >Receipt Series (Next Reciept Series For
                                        Payment Only)</label
                                    >
                                    <select
                                        disabled={paymentObj.freezePayment ||
                                            batchesList1.length == 0}
                                        bind:this={elemReceiptType}
                                        class="info slectizedd form-control"
                                    >
                                        {#if errorObj.paymentType}
                                            <div class="parsley-errors-list">
                                                {errorObj.paymentType}
                                            </div>
                                        {/if}
                                    </select>
                                </div>
                                {#if !paymentObj.freezePayment}
                                    <div class="form-group col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <label
                                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                                    >Prefix</label
                                                >
                                                <input
                                                    disabled={paymentObj.freezePayment ||
                                                        true ||
                                                        batchesList1.length ==
                                                            0}
                                                    bind:value={paymentObj.prefix}
                                                    type="text"
                                                    on:keyup={() =>
                                                        populateReceiptNumber()}
                                                    on:blur={() =>
                                                        clearError("prefix")}
                                                    class="form-control"
                                                    class:parsley-error={errorObj.prefix}
                                                    placeholder="Enter Prefix"
                                                />
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <label
                                                    class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                                    >Receipt No</label
                                                >
                                                <input
                                                    disabled={paymentObj.freezePayment ||
                                                        true ||
                                                        batchesList1.length ==
                                                            0}
                                                    bind:value={paymentObj.sequence}
                                                    type="number"
                                                    on:keyup={() =>
                                                        populateReceiptNumber()}
                                                    on:blur={() =>
                                                        clearError("sequence")}
                                                    class="form-control"
                                                    class:parsley-error={errorObj.receiptNo}
                                                    placeholder="Enter Sequence"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="form-group col-md-6"
                                        class:d-none={modalTitle.toLowerCase() ==
                                            "refund payment"}
                                    >
                                        <label
                                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                            >Receipt No<span
                                                class="required-field">*</span
                                            ></label
                                        >
                                        <input
                                            disabled={paymentObj.freezePayment ||
                                                true ||
                                                batchesList1.length == 0}
                                            bind:value={paymentObj.receiptNo}
                                            type="text"
                                            on:change={() => updatePaidStatus()}
                                            on:blur={() =>
                                                clearError("receiptNo")}
                                            class="form-control"
                                            class:parsley-error={errorObj.receiptNo}
                                            placeholder="Enter Receipt No"
                                        />
                                        {#if errorObj.receiptNo}
                                            <div class="parsley-errors-list">
                                                {errorObj.receiptNo}
                                            </div>
                                        {/if}
                                    </div>
                                {/if}
                            {/if}
                        {/if}
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Transaction No</label
                            >
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:value={paymentObj.cardNo}
                                type="text"
                                on:change={() => updatePaidStatus()}
                                on:blur={() => clearError("cardNo")}
                                class="form-control"
                                class:parsley-error={errorObj.cardNo}
                                placeholder="Enter Transaction No"
                            />
                            {#if errorObj.cardNo}
                                <div class="parsley-errors-list">
                                    {errorObj.cardNo}
                                </div>
                            {/if}
                        </div>
                        <div class="form-group col-md-6">
                            <label
                                class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03"
                                >Remarks
                                {#if paymentObj._id || modalTitle.toLowerCase() == "refund payment"}
                                    <span class="required-field">*</span>
                                {/if}
                            </label>
                            <input
                                disabled={paymentObj.freezePayment ||
                                    batchesList1.length == 0}
                                bind:value={paymentObj.remark}
                                type="textarea"
                                on:blur={() => clearError("remark")}
                                class="form-control"
                                class:parsley-error={errorObj.remark}
                                placeholder="Add Remarks..."
                            />
                            {#if errorObj.remark}
                                <div class="parsley-errors-list">
                                    {errorObj.remark}
                                </div>
                            {/if}
                        </div>
                    </div>
                {/if}
            </div>
            <!-- modal-body -->

            <div class="modal-footer pd-x-20 pd-y-15">
                <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    on:click={clearModalFields}
                    >{paymentObj.freezePayment ? "CLOSE" : "CANCEL"}
                    <!-- <i data-feather="x" class="wd-10 mg-l-5"></i> -->
                </button>
                {#if modalTitle.toLowerCase() == "refund payment"}
                    <!-- Refund Flow -->
                    <button
                        disabled={isLoading}
                        on:click={addRefundPaymentBtn}
                        type="button"
                        class="btn btn-primary"
                    >
                        <ButtonLoader {isLoading} btnText="REFUND" />
                    </button>
                {:else}
                    <!-- Normal Flow -->
                    <button
                        disabled={isSaveLoading ||
                            paymentObj.freezePayment ||
                            isLoading}
                        on:click={saveAndAddAnotherPayment}
                        type="button"
                        class:d-none={paymentObj.freezePayment}
                        class="btn btn-primary"
                    >
                        <i class="far fa-credit-card" />

                        <ButtonLoader
                            isLoading={isSaveLoading}
                            btnText="SAVE AND ADD ANOTHER"
                        />
                    </button>
                    <button
                        disabled={isLoading ||
                            paymentObj.freezePayment ||
                            isSaveLoading}
                        on:click={addUpdatePaymentBtn}
                        type="button"
                        class:d-none={paymentObj.freezePayment}
                        class="btn btn-primary"
                    >
                        {#if addpaymentStatus == "FREEZE"}
                            <i class="far fa-credit-card" />
                            <ButtonLoader
                                {isLoading}
                                btnText="CONFIRM PAYMENT"
                            />
                            <!-- <i data-feather="thumbs-up" class="wd-10 mg-l-5"></i> -->
                        {:else}
                            <ButtonLoader
                                {isLoading}
                                btnText="SAVE INSTALLMENT"
                            />
                            <!-- <i data-feather="arrow-right" class="wd-10 mg-l-5"></i> -->
                        {/if}
                    </button>
                {/if}
            </div>
        </div>
        <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
</div>
<!-- modal -->

<style>
    .counts-card {
        width: "16.666666%";
    }

    .filterByDropdown li:hover {
        cursor: pointer;
    }
</style>
