<script>
  import {
    Router,
    links,
    Link,
    Route,
    navigate
  } from "svelte-routing";
  import {
    onMount,
    tick,
    afterUpdate
  } from 'svelte';
  import {
    activeMenu
  } from "../../stores";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import ExcelJS from 'exceljs'
  import { API_URL, BACKEND_URL } from "../../../config.js"
  export let assignmentId
  let submissionDate;
  let demoDataTable;
  let studentDataTable;
  let emptyState = false
  let assignmentObj = {
    topic: "",
  }
  let search = {
    value: ""
  };
  let assignmentList = []
  let errorObj = {}
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  }
  let exportLoading = false
  let status;
  let userIdArr
  let batchArr
  let filterModalThis;
  let filterElemBatch;
  let filterElemBatch1;
  let filterByPrefix;
  let filterElemUser;
  let filtertopic;
  let filterstatus;
  let filtertime;
  let filterSDate;
  let selectizeBatchInstance1;
  let isLoading = false;
  let selectizeBatchInstance
  let assignmentData
  let assignmentProgressData = []
  async function bindSelect2() {


    selectizeBatchInstance1 = _$(filterElemBatch1).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "select student",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: userIdArr,
    });

    selectizeBatchInstance = _$(filterElemBatch).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "select batch",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: batchArr,
    });

  }


  function hideFilterModel(params) {
    _$("#filter-modal").modal("hide");
    assignmentObj.topic = "";
  }
  function showFilterModel(params) {
    _$("#filter-modal").modal("show");
  }


  let showHideFilterModal = () => {
    _$('#filter-modal').modal('show');
  }

  function getFormatedDate(date) {
    if (!date && date == '') {
      return '-'
    }
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');

  }


  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.studentName = order;
    } else if (sortOrder.column === 2) {
      sort.assignmentTopic = order;
    } else if (sortOrder.column === 3) {
      sort.assignmentCreatedOn = order;
    } else if (sortOrder.column === 4) {
      sort.submissionDate = order;
    } else if (sortOrder.column === 4) {
      sort.submissionTime = order;
    } else if (sortOrder.column === 4) {
      sort.assignmentStatus = order;
    } else {
      sort._id = order;
    }
    return sort;
  }


  const pageSize = 10;
  let length = pageSize;

  let validateAssignment = () => {
    errorObj = {};
    if (!assignmentObj.topic) {
      var newErrorObj = {};
      if (!assignmentObj.topic) {
        newErrorObj.topic = "Assignment topic is required Field.";
      }
      errorObj = newErrorObj;

    }
    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;


  }


  let addUpdateAssignmentBtn = async () => {
    if (validateAssignment()) {
      var assignmentRes = await api_addUpdateAssignment(assignmentObj);
      if (assignmentRes.status) {
        // create success

        PNotify.success({
          title: 'Success',
          text: assignmentRes.data[0],
          delay: 3000
        })
        navigate(("assignmentprogress/edit/" + assignmentRes.data[1]))

      } else {
        PNotify.error({
          title: 'Error',
          text: assignmentRes.errMsg,
          delay: 3000
        })
      }
    }
  }

  async function api_addUpdateAssignment(data) {

    let action = "assignment.addUpdateAssignment";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_getBatchesList() {
    var resData = await api_handleApiCalls('batch.getMyBatches', { isSortByName: true })
    if (resData.status) {

      return resData.data
    } else {
      return []
    }
  }


  async function get_studentList_with_IdAndName() {
    try {
      const response = await fetch(`${BACKEND_URL}/students-api/getStudentIdsAndNames`, {
        method: 'POST',
        params: {
          isSortByName: true,
          inactiveStudents: true
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching student data:', error);
      return null;
    }
  }

  async function api_getAssignmentDetails() {
    var resData = await api_handleApiCalls('assignment.getAssignmentById', { id: assignmentId })
    if (resData.status) {

      return resData.data
    } else {
      return []
    }
  }
  function hideModel(params) {
    _$("#assignment-add-modal").modal("hide");
    assignmentObj.topic = "";
  }
  function showModel(params) {
    _$("#assignment-add-modal").modal("show");
  }
  onMount(async () => {
    activeMenu.set('assignment')
    assignmentData = await api_getAssignmentDetails()
    userIdArr = await get_studentList_with_IdAndName()
    batchArr = await api_getBatchesList()

    bindSelect2();

    bindDatatable();
    // _$(demoDataTable).DataTable( {
    //   initComplete: function () {
    //         this.api().columns().every( function () {
    //             var column = this;
    //             var select = _$('<select><option value=""></option></select>')
    //                 .appendTo( _$(column.footer()).empty() )
    //                 .on( 'change', function () {
    //                     var val = _$.fn.dataTable.util.escapeRegex(
    //                         _$(this).val()
    //                     );

    //                     column
    //                         .search( val ? '^'+val+'$' : '', true, false )
    //                         .draw();
    //                 } );

    //             column.data().unique().sort().each( function ( d, j ) {
    //                 select.append( '<option value="'+d+'">'+d+'</option>' )
    //             } );
    //         } );
    //     },
    // } );
    feather.replace();
  });


  function getFilterData() {

    let userIdArr = _$(selectizeBatchInstance1).val()
    let batchesList = _$(selectizeBatchInstance).val()
    let submissionTime = filtertime;
    let assignmentStatus = status;
    let topic = filtertopic;

    return {

      userIdArr,
      batchesList,
      topic,
      submissionDate,
      submissionTime,
      assignmentStatus


    }
  }


  function clearFilter() {
    selectizeBatchInstance1[0].selectize.clear();
    selectizeBatchInstance[0].selectize.clear();
    filtertopic = null;
    submissionDate = null;
    filtertime = null;
    status = null;
    redrawAssignmentTable();

  }
  function applyFilter() {

    redrawAssignmentTable();
  }

  function redrawAssignmentTable() {
    if (studentDataTable) { studentDataTable.draw() }
  }

  function bindDatatable() {
    studentDataTable = _$(demoDataTable).DataTable({

      responsive: true,
      language: {
        processing: "",
        searchPlaceholder: 'Topic | Status',
        sSearch: '',
        lengthMenu: '_MENU_ Assignment progress/page',
      },
      ajax: function (data, callback, settings) {

        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        var filterObj = { ...getFilterData() }
        filterObj.start = data.start
        filterObj.length = data.length
        filterObj.search = data.search.value
        filterObj.draw = data.draw
        filterObj.sort = data.sort;
        filterObj.assignmentId = assignmentId

        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: 'assignment.assignmentProgressListByAssignmentId',
            accessToken: localStorage.getItem("accessToken"),
            data: filterObj
          },
          success: function (resData) {
            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              assignmentProgressData = paginationData.data
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data
              }
              if (!resData.data.length) {
                emptyState = true
              }
              callback(result);
            }
            else {
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },
      columns: [
        {
          data: "studentName",
          render: (data, mode, row, settings) => {
            let image;
            if (row.profilePic) {
              // if image found display profilePic avatar
              image = `<img src="${row.profilePic
                }" class="avatar mg-r-15" alt="${row.studentName[0].toUpperCase()}">`;
            } else {
              // if image not found display initials avatar
              image = `<div class="avatar mg-r-15"><span class="avatar-initial rounded-circle">${row.studentDetails.name
                .split(" ")
                .map((str) => str[0] || "")
                .join("")}</span></div>`;
            }
            return `
            <div class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0" >
              ${image}
              <div class="wd-80p bg-transparent">
                <h6 class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent tx-capitalize btn-view-display" question-id="${row._id}">${row.studentDetails.name}</h6>
                <span class="d-block tx-11 text-muted bg-transparent">
                  <a href="tel:+91${row.mobileNo}">
                    <i data-feather="phone"></i> 
                    ${row.mobileNo}
                  </a>
                </span>
              </div>
            </div>`;
          },
        },

        { data: 'assignmentTopic', defaultContent: '<i>No data found<i>' },
        // { data: 'email', defaultContent : '<i>No data found<i>' },
        // { data: 'mobileNo', defaultContent : '<i>No data found<i>' },
        //  { data: 'assignedBatches', defaultContent : '<i>No data found<i>' },

        {
          data: 'batchArr',
          defaultContent: '-',
          render: (data, type, full) => {
            var htmlstr = '';
            if (!full.batchArr.length) {
              return "-";
            }
            for (let j = 0; j < full.batchArr.length; j++) {
              let assignedBatch = full.batchArr[j]
              let batch = data.filter(item => item.name == assignedBatch.name)

              if (batch) {
                htmlstr += `
                      <div class="badge badge-secondary tx-capitalize" style="font-size:12px;margin-right:4px;margin-bottom:4px; text-align: center;">${batch[0].name}</div>
                      `;
              }

            }

            return htmlstr

          }
        },
        {
          data: 'createdOn',
          render: function (data, a, b, c) {
            let d = new Date(data)
            return moment(d).format("DD-MM-YYYY")

          }
        },
        {
          data: 'submissionDate',
          render: function (data, a, b, c) {
            if (!data) {
              return '-'
            }
            let d = new Date(data)
            return moment(d).format("DD-MM-YYYY")

          }
        },
        {
          data: 'submissionTime',
          render: function (data, a, b, c) {
            if (!data) {
              return '-'
            }
            let d = new Date(data)
            return moment(d).format('LT')

          }
        },
        {
          data: '_id', render: function (data, type, full) {
            let show;
            if (full.assignmentStatus == "pending") {
              show = `<span class="badge badge-secondary">${full.assignmentStatus}</span>`
            } else if (full.assignmentStatus == "submitted") {
              show = `<span class="badge badge-primary">${full.assignmentStatus}</span>`
            } else if (full.assignmentStatus == "approved") {
              show = `<span class="badge badge-success">${full.assignmentStatus}</span>`
            } else if (full.assignmentStatus == "rejected") {
              show = `<span class="badge badge-danger">${full.assignmentStatus}</span>`
            } else if (full.assignmentStatus == "resubmit") {
              show = `<span class="badge badge-warning">${full.assignmentStatus}</span>`
            }
            else {
              show = `<span > - </span>`
            }
            return show;

          }
        },
        // { data: ,                  },
        {
          data: '_id', render: function (data, a, b, c) {
            return `
                <div class="d-md-flex flex-row">
                    <a href="#" class="rename btn-edit-assignment mg-r-5" data-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i data-feather="edit" class="wd-20 mg-r-5"></i></a>
                </div>
                `;

          }
        },

      ],
      "drawCallback": function () {
        feather.replace({ width: "1em", height: "1em" })
        _$('.btn-edit-assignment').unbind('click').click(function (e) {
          e.preventDefault();
          var assignmentId = _$(this).attr("data-id");

          navigate(("/dashboard/assignmentprogress/edit/" + assignmentId))

        })
        _$('.btn-view-display').click(function () {

          var assignmentId = _$(this).attr("question-id");

          navigate(("/dashboard/assignmentprogress/edit/" + assignmentId))

        })

      },
      "processing": true,
      "serverSide": true,

    });
    _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });

  }

  var btnExportAssignmentProgress = async () => {
    if (exportLoading) return;
    exportLoading = true;
    let data = {
      assignmentId: assignmentId,
      noPagination: true
    }
    let response = await api_handleApiCalls("assignment.assignmentProgressListByAssignmentId", data)
    let allAssignmentProgress
    if (response.status) {
      allAssignmentProgress = response.data.data;
    }

    if (allAssignmentProgress) {
      generateWorkBook(allAssignmentProgress);
    }

    exportLoading = false;
  };

  async function generateWorkBook(progressDataList) {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet("Assignment Progress Data", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });

    let fileName = "Assignment_Progress_Data_Export_" + new Date().getTime() + "_.xlsx";

    let columnsArr = [
      { header: "Sr No.", key: "serialNo", width: 15 },
      { header: "Student Name", key: "name", width: 15 },
      { header: "Mobile No", key: "mobileNo", width: 15 },
      { header: "Email", key: "email", width: 15 },
      { header: "Assignment Topic", key: "subject", width: 15 },
      { header: "Attachment", key: "attachment", width: 50 },

      { header: "Batch", key: "batch", width: 15 },

      { header: "Submission Date", key: "date", width: 15 },

      { header: "Submission Time", key: "endTime", width: 15 },

      { header: "Assignment Status", key: "status", width: 20 },
      { header: "Submitted Attachment", key: "Sattachment", width: 50 },

      { header: "Remark", key: "remark", width: 15 },
      { header: "Remark Attachment", key: "Rattachment", width: 50 },




      // { header: "Assigned Batches", key: "batch", width: 15 },
      // { header: "Assigned Counselor", key: "counselor", width: 15 },
      // { header: "Enquiry Date", key: "attendanceDate", width: 20 },
      // { header: "Next Follow up Date", key: "followupDate", width: 15 },
      // { header: "Created By", key: "createdBy", width: 15 },
    ];

    worksheet.columns = columnsArr;

    for (let idx = 0; idx < progressDataList.length; idx++) {
      let progressDataArr = [];
      let progressResult = progressDataList[idx];

      let submissionDate = moment(new Date(progressResult.submissionDate)).format(
        "DD/MM/YYYY"
      );



      progressDataArr.push(idx + 1);
      progressDataArr.push(progressResult.studentDetails.name ? progressResult.studentDetails.name : "");
      progressDataArr.push(progressResult.studentDetails.mobileNo ? progressResult.studentDetails.mobileNo : "");
      progressDataArr.push(progressResult.studentDetails.email ? progressResult.studentDetails.email : "");
      progressDataArr.push(progressResult.assignmentTopic ? progressResult.assignmentTopic : "");
      progressDataArr.push(progressResult.assignmentDetails &&
        (progressResult.assignmentDetails.attachments) && progressResult.assignmentDetails.attachments.length
        ?
        progressResult.assignmentDetails.attachments.map((element) => element.downloadUrl).join(",") : "");

      progressDataArr.push(progressResult.batchName ? progressResult.batchName.join(',') : "");

      progressDataArr.push(submissionDate ? submissionDate : "")

      progressDataArr.push(progressResult.submissionTime ? moment(progressResult.submissionTime).format('LT') : "");
      progressDataArr.push(progressResult.assignmentStatus ? progressResult.assignmentStatus : "");
      progressDataArr.push(progressResult.submittedFile && progressResult.submittedFile.length
        ?
        progressResult.submittedFile.map((element) => element.downloadUrl).join(",") : "");

      progressDataArr.push(progressResult.assignmentRemark ? progressResult.assignmentRemark : "");
      //   progressDataArr.push(progressResult.notes? progressResult.notes: "");
      progressDataArr.push(progressResult.remarkFile && progressResult.remarkFile.length
        ?
        progressResult.remarkFile.map((element) => element.downloadUrl).join(",") : "");

      worksheet.addRow(progressDataArr);
    }

    const buffer = await workbook.xlsx.writeBuffer();

    saveFile(
      new Blob([buffer], { type: "application/octet-stream" }),
      fileName
    );
    //   await workbook.xlsx.writeFile(fileName);
  }
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    // var view = new Uint8Array(buf);
    var view = s;
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const saveFile = async (blob, fileName) => {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };
  function uniqueId() {
    // always start with a letter (for DOM friendlyness)
    var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
    do {
      // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
      var ascicode = Math.floor(Math.random() * 42 + 48);
      if (ascicode < 58 || ascicode > 64) {
        // exclude all chars between : (58) and @ (64)
        idstr += String.fromCharCode(ascicode);
      }
    } while (idstr.length < 32);

    return idstr.toLowerCase();
  }
</script>

<div class="content-body fadeIn">
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item " aria-current="page">
            <a href="/dashboard/assignment"> Assignment </a>
          </li>
          <li class="breadcrumb-item " aria-current="page">
            <a href="/dashboard/assignment"> Assignment Progress </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{assignmentData && assignmentData.topic
            ?assignmentData.topic :""}
            <!-- {"Assignment Progress of" + assignmentData && assignmentData.topic ? assignmentData.topic :""} -->
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        {assignmentData && assignmentData.topic ?assignmentData.topic :""}
      </h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Review the assignments progress.</p>
    </div>
    <div class="d-block">
      <button disabled={exportLoading} class="btn btn-sm pd-x-15 btn-uppercase btn-secondary mg-l-5  mg-r-5 "
        type="button" on:click={btnExportAssignmentProgress}>
        <ButtonLoader isLoading={exportLoading} btnText={exportLoading ? "Processing..." : "Export" } />
      </button>
    </div>
  </div>
  <hr class="full-width">
  <div data-label="Example" class="df-example demo-table">
    <table bind:this={demoDataTable} class="table" style="width:100%">
      <thead>
        <tr>
          <th class="wd-20p">Student</th>
          <th class="wd-20p">Topic</th>
          <th class="wd-20p">Batch</th>
          <!-- <th class="wd-25p">Email</th>
                <th class="wd-25p">Contact Number</th>-->
          <th class="wd-15p">Added On</th>
          <th class="wd-15p">Submission Date</th>
          <th class="wd-15p">Submission Time</th>
          <th class="wd-10p">Status</th>
          <th class="wd-25p">Actions</th>
        </tr>
      </thead>
      <tbody></tbody>
      <!-- <tfoot>
          <tr>
            <th class="wd-20p">Student</th>
            <th class="wd-20p">Topic</th>
            <th class="wd-15p">Added On</th> 
            <th class="wd-15p">Submission Date</th> 
            <th class="wd-15p">Submission Time</th> 
            <th class="wd-25p">Actions </th>
          </tr>
      </tfoot> -->
    </table>
    <!-- {#if emptyState && paginationData.data.length < 0}
    <div class="empty-state-img">
      <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
      <p class="tx-bold">No Assignments found</p>
    </div>
    {/if} -->
  </div>

</div>



<!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->

      <hr class="full-width">
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
        <div class="form-group">
          <label class="d-block">Assignment Status:</label>
          <div class="d-md-flex">
            <div class="custom-control custom-radio mg-r-15">
              <input type="radio" id="submitted" bind:group={status} class="custom-control-input" value='submitted'>
              <label class="custom-control-label" for="submitted">Submitted</label>
            </div>
            <div class="custom-control custom-radio mg-r-15">
              <input type="radio" bind:group={status} id="pending" class="custom-control-input" value='pending'>
              <label class="custom-control-label" for="pending">Pending</label>
            </div>
            <div class="custom-control custom-radio mg-r-15">
              <input type="radio" bind:group={status} id="rejected" class="custom-control-input" value='rejected'>
              <label class="custom-control-label" for="rejected">Rejected</label>
            </div>
            <div class="custom-control custom-radio mg-r-15">
              <input type="radio" bind:group={status} id="approved" class="custom-control-input" value='approved'>
              <label class="custom-control-label" for="approved">Approved</label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="d-block">Select student:</label>
          <select disabled={isLoading} bind:this={filterElemBatch1} class="form-control select2" multiple="multiple">
            <option>Select student</option>
          </select>
        </div>
        <div class="form-group">
          <label class="d-block">Assignment Topic:</label>
          <input type="text" bind:value={filtertopic} class="form-control" placeholder="Enter Assignment Topic" />
        </div>

        <div class="form-group">
          <label class="d-block">Submission Date:</label>
          <input type="date" onkeydown="return false" bind:value={submissionDate} class="form-control follow-up-date"
            placeholder="Enter Submission Date" />
        </div>
        <div class="form-group">
          <label class="d-block">Submission Time:</label>
          <input type="time" onkeydown="return false" bind:value={filtertime} class="form-control follow-up-date"
            placeholder="Enter Submission Time" />
        </div>

        <div class="form-group">
          <label class="d-block">Batch</label>
          <select disabled={isLoading} bind:this={filterElemBatch} class="">
            <option>Select batches</option>
          </select>
        </div>

        <div class="d-md-flex justify-content-end mg-t-25">
          <button disabled={isLoading} class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" on:click={clearFilter}
            data-dismiss="modal">CLEAR
            <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button disabled={isLoading} class="btn btn-sm pd-x-15 btn-primary mg-l-5" on:click={applyFilter}
            data-dismiss="modal">
            <ButtonLoader isLoading={isLoading} btnText="APPLY" />
            <i data-feather="filter" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Modal -->
