<script>
  import { setContext, onMount, tick } from "svelte";
  import { links, link, navigate } from "svelte-routing";
  import { activeMenu } from "../../stores";
  import StudyReportComments from "../../comments/index.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import CatSelect from "../../questions/categorySelect.svelte";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import EmptyCategory from "../empty/empty.svelte";
  export let id;
  let isLoading, showCatSelect;
  let noOfQuestionsSolved,
  notes,
  batchId,
  folders = [],
  displayFolderCaegories = "",
  batchList = [],
  elem_selectBatch,
  selectedFolderWithName = [],
  studentName;
  let errorObj = {};
 
  function selectBatchFunction() {}
  async function fetchBatchList() {
    let batchRes = await api_handleApiCalls("batch.listBatches", { start: "0" , noPagination:true});
    if (batchRes.status) {
      batchList = batchRes.data.data;
      batchList = batchRes.data.data.map((item) => {
        return { _id: item._id, name: item.name };
      });

      _$(elem_selectBatch).selectize({
        maxItems: 1,
        valueField: "_id",
        labelField: "name",
        searchField: "name",
        options: batchList,
        create: false,
        items: [batchId],
        onChange: selectBatchFunction,
        sortField: "text",
      });
    }
  }

  function updateDisplayCategories() {
    displayFolderCaegories = folders.map((item) => item.name).join(" > ");
  }
  $: folders && updateDisplayCategories();
  async function fetchStudentStudyReport() {
    let data = { id };
    let result = await api_handleApiCalls("studyReport.fetchStudentStudyReport", data);
    if (result.status) {
      setValues(result.data);
    } else if (result.errMsg) {
      pError("", result.errMsg);
    } else if (result.error) {
      pError("", result.error);
    } else {
      pError("", "Something went wrong");
    }
  }
  async function setValues(values) {
    studentName = values.studentName;
    batchId = values.batchId;
    selectedFolderWithName = values.folders;
    noOfQuestionsSolved = values.noOfQuestionsSolved;
    notes = values.notes;
    folders = values.folders;
    feather.replace({width: "1em", height: "1em"});
    await tick();
  }
  // function clearFields() {
  //   setValues({
  //     severity: 2,
  //     type: "general",
  //     status: "new",
  //     query: "",
  //   });
  // }
  async function reload() {
    if (id && !isLoading) {
      isLoading = true;
      await tick();
      await fetchStudentStudyReport();
      fetchBatchList()
      // fetchComments()
      isLoading = false;
      await tick();
    }
  }

  onMount(async function () {
    activeMenu.set("studyreport");
    reload();
  });
</script>

<style>
  .tx-capitalize {
    text-transform: capitalize;
  }
  .contact-list:hover nav {
    display: block;
  }
</style>

<div class="content-body animated scale">
  <!-- breadcrumbs -->
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20
      mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active">
            <a href="/dashboard/reports/study">Reports</a>
          </li>
          <li class="breadcrumb-item">View</li>
          <li class="breadcrumb-item active" aria-current="page">{studentName || ""}</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">View Study Report</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
        View Study Report, and make/see comments on it
      </p>
    </div>
    <!-- <div class="d-inline-block">
      <div class="d-inline-block">
        <button
          on:click={reload}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          use:link>
          {#if isLoading}
            <ButtonLoader {isLoading} btnText="" />
            Refresh
          {:else}<i class="icon ion-md-refresh mg-l-5" /> Refresh{/if}
        </button>
      </div>
    </div> -->
  </div>
  <div
    class="contact-wrapper contact-wrapper-two"
    style="    margin-left: -25px;
		margin-right: -25px;
		border-top: 1px solid #e5e8ee;">
    <div class="row m-0" style="height:100%">
      <div
        class="col-md-4 p-0"
        style="border-right: 1px solid #e5e8ee;background: white; height: 100%;">
        <div
          class="pd-y-20 pd-x-10 contact-list "
          style="height: calc(100% - 122px);">
          <label id="contactA" class="contact-list-divider">Category</label>
          <div class="media">
            <div class="media-body mg-l-10">
              <div class="form-group wd-100p">
                <label class="d-block wd-100p">Select Batch
                  <div class="d-inline required-field">*</div></label>
                <select
                  bind:this={elem_selectBatch}
                  disabled={Boolean(id)}
                  class="form-control"
                  placeholder="Please select batch" />
              </div>
            </div>
          </div>
          {#if batchId}
            <div class="media">
              <div class="media-body mg-l-10">
                <div class="form-group wd-100p">
                  <label class="d-block wd-100p">Category
                    <div class="d-inline required-field">*</div></label>
                  <span class="tx-capitalize">{displayFolderCaegories}</span>
                </div>
              </div>
            </div>
          {/if}
          <label id="contactA" class="contact-list-divider">Report</label>
          <div class="media">
            <div class="media-body mg-l-10">
              <div class="form-row" style="width: 100%;">
                <div class="form-group wd-100p">
                  <label class="d-block wd-100p">No. of Questions Solved
                    <div class="d-inline required-field">*</div></label>
                  <input
                    disabled={Boolean(id)}
                    type="number"
                    class="form-control"
                    minlength="0"
                    min="0"
                    bind:value={noOfQuestionsSolved}
                    oninput="validity.valid||(value='');" />
                </div>
                <div class="form-group wd-100p">
                  <label class="d-block wd-100p">Notes</label>
                  <textarea class="form-control wd-100p" bind:value={notes} disabled={Boolean(id)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 p-0"
        style="border-right: 1px solid #e5e8ee;background: white; height: 100%;">
        <div
          class="pd-t-20 pd-r-10 contact-list "
          style="height: calc(100% - 122px);">
          <!-- comments here -->
          <StudyReportComments {id} />
        </div>
      </div>
    </div>
  </div>
</div>
