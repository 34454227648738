<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { afterUpdate, onMount, tick } from "svelte";
    import { activeMenu } from "../../../stores";
    import FolderModal from "../../../folderCmp/folderModal.svelte";
    import ButtonLoader from "../../../utils/button_loader.svelte";
    import api_handleApiCalls from "../../../utils/apiCallHandler.js";
    export let _id;
    let isLoading = false;
    let moduleSubjectList = [];
    let facultyList = [];
    let linkArr = [];
    let showModal = false;
    let youtubeLectureObj = {
      _id: _id,
      name: "",
      desc: "",
      facultyId: "",
      moduleId: "",
      subjectId: "",
      tags: "",
      meetingLink: "",
      to: "",
      from: "",
      batchIds: [],
    };
  
    let displayText = "Add Youtube Lecture";
  
    let elemModuleSubj;
    let elemFaculty;
    if (_id) {
      displayText = "Update Youtube Lecture";
    }
  
  
    let errorObj = {};
    let clearError = (key) => {
      delete errorObj[key];
      errorObj = { ...errorObj }; // reset state
    };
  
    function updateSuccess(success) {
      PNotify.success({
        title: success.title ? success.title : "Success",
        text: success.msg,
        delay: 3000,
      });
    }
    async function api_removeFileLinkToFolder(data) {
  
      let action = "files.removeFileLinkToFolder";
      var response = await api_handleApiCalls(action, data);
      return response;
  
    }
    async function api_loadLinksForFile(data) {
  
      let action = "files.loadLinksForFile";
      var response = await api_handleApiCalls(action, data);
      return response;
  
    }
  
    async function api_addFileLinkToFolder(data) {
  
      let action =  "files.addFileLinkToFolder";
      var response = await api_handleApiCalls(action, data);
      return response;
  
    }
    function updateErrors(error) {
      PNotify.error({
        title: error.title ? error.title : "Error",
        text: error.msg,
        delay: 3000,
      });
    }
  
    let deleteLink = async (linkId) => {
      var linkRes = await api_removeFileLinkToFolder({ _id: linkId });
      if (linkRes.status) {
        updateSuccess({ title: "Success", msg: linkRes.data });
        renderLinks();
      } else {
        // Failed to create
        updateErrors({
          title: "Error",
          msg: linkRes?.errMsg ?? "Something went wrong",
        });
      }
    };
    let onCloseModal = async function (event) {
      showModal = !showModal;
      console.log("selectedFolder : ", event.detail.selectedFolder);
  
      // if (event?.detail?.selectedFolder?.length > 0) {
      if (
        event &&
        event.detail &&
        event.detail.selectedFolder &&
        event.detail.selectedFolder.length > 0
      ) {
        var selectedFolder = event.detail.selectedFolder;
  
        var linkObj = {
          fileId: _id,
          folderId: selectedFolder[selectedFolder.length - 1].id,
          batchId: selectedFolder[selectedFolder.length - 1].batchId,
          selectedFolder: selectedFolder,
        };
        var resp = await api_addFileLinkToFolder(linkObj);
        renderLinks();
      }
      _$(".modal-backdrop").remove();
    };
  
    let renderLinks = async function () {
      var templinkArr = await api_loadLinksForFile({
        fileId: _id,
      });
      linkArr = templinkArr.data.fileFolderLinkArr;
      tick();
      setTimeout(function () {
        feather.replace();
      }, 10);
    };
  
    let validateYoutubeLectureObj = () => {
      errorObj = {};
      var newErrorObj = {};
        if (!youtubeLectureObj.name) {
          newErrorObj.name = "Youtube Lecture Name is required Field.";
        }
        if (!youtubeLectureObj.youtubeLink) {
          newErrorObj.youtubeLink = "Youtube Lecture link is required Field.";
        }

        errorObj = newErrorObj;
      if (Object.keys(errorObj).length > 0) {
        isLoading = false
        return false;
      }
      return true;
    };
  
    let addUpdateImageBtn = async () => {
      if (isLoading) return;
      isLoading = true;
      if (validateYoutubeLectureObj()) {
        var imageRes = await api_addUpdateImage(youtubeLectureObj);
        if (imageRes.status) {
          // create success
          updateSuccess({ title: "Success", msg: imageRes.data });
          setTimeout(() => {
            window.history.back();
          }, 1000);
        } else {
          // Failed to create
          updateErrors({ title: "Error", msg: youtubeLectureObj.errMsg });
          isLoading = false;
          return false;
        }
      }
      isLoading = false;
    };
  
    async function api_getFaculty(data) {
  
      let action =  "studyMaterial.getFacultyList";
      var response = await api_handleApiCalls(action, data);
      return response;
  
    }
  
    async function api_getModuleSub(data) {
  
      let action =   "categories.getModuleSubjectList";
      var response = await api_handleApiCalls(action, {});
      return response;
  
    }
    async function api_addUpdateImage(data) {
  
      let action =   "studyMaterial.addUpdateYoutubeLecture";
      var response = await api_handleApiCalls(action, data);
      return response;
  
    }
  
    async function api_getImageById(data) {
  
      let action =   "studyMaterial.getYoutubeLectureById";
      var response = await api_handleApiCalls(action, data);
      return response;
  
    }
  
    function bindEvents() {
      _$(elemModuleSubj).select2({
        minimumResultsForSearch: Infinity,
        data: moduleSubjectList.map(function (aModule) {
          return {
            id: aModule.moduleId + "_" + aModule.subjectId,
            text: aModule.moduleSubjStr,
            selected: aModule.subjectId == youtubeLectureObj.subjectId,
          };
        }),
      });
  
      _$(elemFaculty).select2({
        minimumResultsForSearch: Infinity,
        data: facultyList.map(function (aFaculty) {
          return {
            id: aFaculty._id,
            text: aFaculty.name,
            selected: aFaculty._id == youtubeLectureObj.facultyId,
          };
        }),
      });
  
      loadBatchList();
    }
  
    
    async function api_getBatchList() {
  
      let action = "batch.getMyBatches";
      var response = await api_handleApiCalls(action, {});
      return response;
  
    }
  
    let loadBatchList = async function () {
      let resBatchList = await api_getBatchList();
      if (resBatchList.status) {
        loadBatchesOptions(resBatchList.data, youtubeLectureObj.batchIds);
      } else {
        console.log("error ", res.errMsg);
      }
    };
  
    function loadBatchesOptions(data, preSelectId) {
      var oldSelected = [];
  
      if (_$("select#assignBatch")[0].selectize) {
        oldSelected =
          _$("select#assignBatch")[0].selectize.getValue() == ""
            ? []
            : _$("select#assignBatch")[0].selectize.getValue();
        _$("select#assignBatch")[0].selectize.destroy();
      }
  
      preSelectId = preSelectId ? preSelectId : [];
      var batchSelectizeEle = _$(_$("#assignBatch")[0]).selectize({
        valueField: "_id",
        labelField: "name",
        searchField: "name",
        plugins: ["remove_button"],
        maxItems: 100,
        options: data,
        create: false,
        onChange: function (values) {
          console.log("selected batch : ", values);
          youtubeLectureObj.batchIds = values;
        },
        render: {
          option_create: function (data, escape) {
            return (
              '<div class="create"><i class="icon icon-plus" style="font-size:14px;"></i><i style="font-size:12px;">Add New Language with name : </i><strong>' +
              escape(data.input) +
              "</strong></div>"
            );
          },
        },
      });
      if (data[0]) {
        batchSelectizeEle[0].selectize.setValue(data[0].value);
      }
  
      if (preSelectId) {
        if (Array.isArray(preSelectId)) {
          for (var i = 0; i < preSelectId.length; i++) {
            oldSelected.push(preSelectId[i]);
          }
        } else {
          oldSelected.push(preSelectId);
        }
        _$("select#assignBatch")[0].selectize.setValue(oldSelected, false);
      }
      _$("select#assignBatch").trigger("change");
    }
  
    onMount(async () => {
      activeMenu.set("studyMaterial");
  
      var moduleSubjectRes = await api_getModuleSub();
      if (moduleSubjectRes.status) {
        moduleSubjectList = moduleSubjectRes.data;
      }
      var facultyRes = await api_getFaculty();
      if (facultyRes.status) {
        facultyList = facultyRes.data;
      }
      renderLinks();
      if (_id) {
        var imageDetailsRes = await api_getImageById({ _id: _id });
  
        if (imageDetailsRes.status) {
          console.log("imageDetailsRes ", imageDetailsRes);
          var imageData = imageDetailsRes.data;
            _$(".youtube").removeClass("d-none");
            _$("div.moduleSub select").val("youtube");
            youtubeLectureObj = {
              _id: imageData._id,
              name: imageData.name,
              lecture_type: imageData.lecture_type,
              youtubeLink: imageData.youtubeLink,
            };
        }
      } 
      bindEvents();
    });
  </script>
  
  <div class="content-body fadeIn">
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div>
        <nav use:links aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/dashboard/studyMaterial">Study Material</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {displayText}
            </li>
          </ol>
        </nav>
        <h4 class="mg-b-0 tx-spacing--1">{displayText}</h4>
      </div>
    </div>
    <!-- <hr class="mg-y-20"> -->
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div class="row row-sm mg-b-10">
        <div class="col-sm-12 col-md-6">
          <fieldset class="form-fieldset">
            <legend>Basic</legend>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block"> Name
                    <span class="required-field">*</span>
                </label>
                <input
                  bind:value={youtubeLectureObj.name}
                  type="text"
                  on:blur={() => clearError("name")}
                  class="form-control"
                  class:parsley-error={errorObj.name}
                  placeholder="Enter your name"
                />
                {#if errorObj.name}
                  <div class="parsley-errors-list">
                    {errorObj.name}
                  </div>
                  <!-- content here -->
                {/if}
              </div>
  
              <div
                class="form-group col-md-12 moduleSub"
                on:click={() => clearError("type")}
                class:parsley-errors-select-custom={errorObj.type}
              >
                <label class="d-block">Type</label>
                <select disabled style="width: 100%;" class="custom-select ">
                  <option value="">Select Type</option>
                  <option value="zoom">Zoom</option>
                  <option value="youtube">YouTube</option>
                  <option value="bluebird">Blue Bird</option>
                </select>
                {#if errorObj.subjectId}
                  <div class="parsley-errors-list">
                    {errorObj.subjectId}
                  </div>
                  <!-- content here -->
                {/if}
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="card d-none" style="    margin-top: 9px;">
            <div style="margin: 20px;" class="">
              <!-- <h6 class=" tx-bold">Publish Live Session</h6> -->
              <div class="form-row">
                <div
                  class="form-group col-md-12"
                  id="selectBatch"
                  on:click={() => clearError("assignedToBatches")}
                  class:parsley-errors-select-custom={errorObj.batchIds}
                >
                  <label class="d-block">Link To Batches</label>
                  <select
                    name="assignBatch"
                    id="assignBatch"
                    type="text"
                    class="info form-control">
                    <option value="">Please Select Batch</option>
                  </select>
                  {#if errorObj.batchIds}
                    <div class="parsley-errors-list">{errorObj.batchIds}</div>
                    <!-- content here -->
                  {/if}
                </div>
              </div>
            </div>
          </div>
  
          <div class="card" style="margin-top: 9px;">
            <div
              class="card-header d-flex align-items-center justify-content-between"
            >
              <h6 class="mg-b-0">Linked To Study Material</h6>
              <div class="d-flex align-items-center tx-18">
                <button
                  on:click={() => (showModal = !showModal)}
                  class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                  <i data-feather="link" class="wd-10 mg-r-5" />
                  New Link
                </button>
              </div>
            </div>
            <ul class="list-group list-group-flush tx-13">
              {#each linkArr as item}
                <li class="list-group-item d-flex pd-sm-x-20">
                  <div class="avatar">
                    <span class="avatar-initial rounded-circle bg-gray-600"
                      ><i data-feather="link" class="wd-10" /></span
                    >
                  </div>
                  <div class="pd-l-10">
                    <p class="tx-medium mg-b-0">
                      {item.fullPath.join(" / ")}
                    </p>
                    <small class="tx-12 tx-color-03 mg-b-0"
                      >{item.batchName}</small
                    >
                  </div>
                  <div class="mg-l-auto d-flex align-self-center">
                    <nav class="nav nav-icon-only">
                      <a
                        on:click|preventDefault={() => deleteLink(item._id)}
                        href="#"
                        class="nav-link d-none d-sm-block">
                        <i data-feather="trash-2" class="wd-10 mg-r-5" />
                      </a>
                    </nav>
                  </div>
                </li>
              {/each}
            </ul>
            <!-- <div class="card-footer text-center tx-13">
              <button
                on:click={() => {
                  return;
                }}
                class="btn"
                >Refresh
                <i class="icon ion-md-refresh mg-l-5" /></button
              >
            </div> -->
  
            <div style="margin: 20px;" class="youtube d-none">
              <h6 class=" tx-bold">YouTube Lecture</h6>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label class="d-block">YouTube Link <span class="required-field">*</span></label>
                  <input
                    type="text"
                    bind:value={youtubeLectureObj.youtubeLink}
                    on:blur={() => clearError("youtubeLink")}
                    class="form-control"
                    class:parsley-error={errorObj.youtubeLink}
                    placeholder="Enter your YouTube Link"
                  />
                  {#if errorObj.youtubeLink}
                    <div class="parsley-errors-list">
                      {errorObj.youtubeLink}
                    </div>
                    <!-- content here -->
                  {/if}
                </div>
              </div>
            </div>
            <!-- card-footer -->
          </div>
          <!-- card -->
        </div>
      </div>
    </div>
  
    <div class="d-sm-flex align-items-center justify-content-end ">
      <div class="d-block">
        <button
          on:click={addUpdateImageBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" disabled={isLoading}>
          <ButtonLoader {isLoading} btnText={displayText} />
          <i data-feather="file" class="wd-10 mg-r-5" />
        </button>
      </div>
    </div>
  </div>
  {#if showModal}
    <FolderModal on:close={onCloseModal} />
  {/if}
  
  <style>
    /* ###### 4.6 Parsley ###### */
    input.parsley-error,
    input.parsley-error:focus,
    textarea.parsley-error,
    textarea.parsley-error:focus {
      box-shadow: none !important;
      border-color: #dc3545;
    }
  
    input.parsley-success,
    input.parsley-success:focus,
    textarea.parsley-success,
    textarea.parsley-success:focus {
      box-shadow: none !important;
      border-color: #10b759;
    }
  
    .parsley-checkbox.parsley-error,
    .parsley-checkbox.parsley-success {
      display: inline-block;
      padding: 10px;
      border-radius: 0.25rem;
    }
  
    .parsley-checkbox.parsley-error {
      border: 1px solid #dc3545;
    }
  
    .parsley-checkbox.parsley-success {
      border: 1px solid #10b759;
    }
  
    .custom-select.parsley-error,
    .custom-select.parsley-success {
      box-shadow: none !important;
    }
  
    .custom-select.parsley-error {
      border-color: #dc3545;
    }
  
    .custom-select.parsley-success {
      border-color: #10b759;
    }
  
    .parsley-errors-select-custom .select2-selection {
      border-color: #dc3545 !important;
    }
  
    .parsley-errors-list {
      color: #dc3545;
      list-style: none;
      font-size: 12px;
      padding: 0;
      margin-bottom: -12px;
    }
    .parsley-errors-list li {
      margin: 5px 0 0;
    }
  </style>
  
