<script>
  import { onMount, tick } from "svelte";
  let yearSelect;

  export let year = new Date().getFullYear();
  export let yearChanged;
  let scroll;
  let years = [
    { id: 2024, text: 2024 },
    { id: 2025, text: 2025 },
    { id: 2026, text: 2026 },
    { id: 2027, text: 2027 },
    { id: 2028, text: 2028 },
    { id: 2029, text: 2029 },
    { id: 2030, text: 2030 },
  ];

  async function initSelect() {
    if (_$(yearSelect)[0].selectize) {
      _$(yearSelect)[0].selectize.destroy();
    }
    _$(yearSelect).selectize({
      // placeholder: { id: "", text: "Filter by Year" },
      maxItems: 1,
      valueField: "id",
      labelField: "text",
      searchField: "text",
      create: false,
      items: [new Date().getFullYear()],
      options: years,
      minimumResultsForSearch: -1,
      onChange: function (value) {
        year = value;
        if (yearChanged) yearChanged(year);
      },
    });
  }

  onMount(async function () {
    console.log("years.", years.length);
    years = years.map((item) =>
      item.id == year ? { ...item, selected: true } : item,
    );
    // if (scroll) scroll.destroy();
    // scroll = new PerfectScrollbar(".scroll4", {
    //   suppressScrollX: true,
    // });
    initSelect();
  });
</script>

<span> <select bind:this={yearSelect} class="wd-100" /> </span>

<!-- <div
  class="col-sm-1 col-md-1 col-lg-1 hidden-dropdown more-btn"
  data-toggle="dropdown"
  aria-haspopup="true"
  aria-expanded="false">
  <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {years.find(item=> item.id == year).text ||  "Select Year"}
  </button>
  <div class="dropdown-menu ht-200 scroll4 pos-relative">
    {#each years as year (year.id)}
    <div
      class="dropdown-item"
      on:click={() => yearChanged(year.id)}>
      {year.text}</div>
    {/each}
  </div>
</div> -->
