<script>
  import { onMount, afterUpdate, tick } from "svelte";
  import { Router, Link, Route, links, link, navigate } from "svelte-routing";
  import moment from "moment";
  import { activeMenu } from "../stores/index.js";
  import ButtonLoader from "../utils/button_loader.svelte";
  import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import UserMultiSelectCmp from "../utils/userMultiSelectCmp.svelte";
  import STPModal from "../AnnouncementsTextEditor/modal.svelte";
  import Scheduler from "../utils/scheduler.svelte";

  export let _id;
   let isLoading = false
   let todaysDate = new Date();
   let showModal = false;
   let emptyState = false;
   let dateString
   let startTimeStr
   let endTimeStr
   let role = {roles :"student"}
  let sessionObj = {
    batchId: [],
    groupId: [],
    facultyId: [],
    studentId:[],
    venue:"",
    startTime: "",
    endTime:"",
  };
  let elem_sessionAttendanceDataTable, sessionAttendanceDataTable;

  let sessionAttendanceObj = {
    studentId: [],
    _id: _id ? _id: '',
    markAttendance: true,
  }
  let channelsList = [];
  let selectedStudentsList = [];
  let selectedBatchesList = [];
  let selectedSysGroupsList = [];
  let sessionDate;
  let errorObj = {};
  let displayText = "Create"

  if(_id) displayText = "Update"

  // elements
  let elem_batchSelection,
    elem_studentSelection,
    elem_channelSelection,
    elem_parentSelection,
    elem_sysUserSelection;
 
  let elem_startTimePicker, elem_endTimePicker;
  let elem_scrollStudentsList, elem_scrollBatchesList, elem_scrollGroupsList;

  async function clearError(fieldName) {
    if (errorObj[fieldName]) {
      delete errorObj[fieldName];
    }
    errorObj = errorObj;
    // await tick()
  }

  async function api_getStudentsByIds(data) {

    let action = "students.getStudentsByIds";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_getParentsByIds(data) {

    let action = "parents.getParentsByIds";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_getSystemUserByIds(data) {

    let action = "systemUser.getSystemUserByIds";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_listStudents(data) {

    let action = "students.findStudentsExcludingIds";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_listParents(data) {

    let action = "parents.getParentsExcludingIds";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_listSysUsers(data) {

    let action = "systemUser.fetchSystemUsersToAssign";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_listBatches(data) {

    let action = "batch.listBatches";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_listChannels(data ) {

    let action = "chat.getChannelList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_addUpdateSession(data) {

    let action = "doubtSession.addUpdate";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  let onCloseModal = async function (event) {

    showModal = !showModal;
    sessionAttendanceObj.studentId = event.detail.selectedUsersIds
    var res = await api_addUpdateSession (sessionAttendanceObj);
    if (res.status) {
        pSuccess("", res.data);
        sessionAttendanceDataTable.ajax.reload();
    } else {
        pError("", res.errMsg || res.error || "something went wrong");
    }
  };

  let initStudentDropdown = async function () {
    let data = {
          start: 0,
          length: 10,
          excludeIds: sessionObj.studentId || [],
          noPagination:true,
          isSortByName:true
    };
    var studentsList
    let studentResult = await api_listStudents(data);
    if (studentResult.status) {
      console.log(studentResult.data.data);
      studentsList = studentResult.data.data;
    } else {
      studentsList = [];
      console.error(studentResult);
    }
    _$(elem_studentSelection).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: studentsList,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
      onBlur: function () {
        clearError("participants");
      },
      onChange: async function (values) {
        if (!values) return;
        sessionObj.studentId.push(values);
        await tick();
      },
    });
    if(_id && sessionObj.studentId && (sessionObj.studentId).length){
        _$(elem_studentSelection)[0].selectize.setValue(sessionObj.studentId, false)
    }

  };

  
  let initSysUserDropdown = async function () {

 let data = {
          start: 0,
          length: 10,
          excludeIds: sessionObj.facultyId || [],
          isSortByName:true
    };
    var systemUserList
    let sysUserResult = await api_listSysUsers(data);
    if (sysUserResult.status) {
      systemUserList = sysUserResult.data.data;
    } else {
      systemUserList = [];
      console.error(sysUserResult);
    }
    _$(elem_sysUserSelection).selectize({
      create: false,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      options: systemUserList,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
      onBlur: function () {
        clearError("participants");
      },
      onChange: async function (values) {
        if (!values) return;
        sessionObj.facultyId[0] = values;
        await tick();
      },
    });
    if(sessionObj.facultyId && (sessionObj.facultyId).length ){
        _$(elem_sysUserSelection)[0].selectize.setValue(sessionObj.facultyId[0], false)
    }

  };
  let initBatchDropdown = async function () {
    let data = {
          start: 0,
          length: 10,
          getModuleSubjects: false,
          sort: {
            field: "_id",
            dir: -1,
          },
          noPagination:true,
          isSortByName:true
    };
    var batchList
    let batchResult = await api_listBatches(data);
    if (batchResult.status) {
      console.log(batchResult.data.data);
      batchList = batchResult.data.data;
    } else {
      batchList = [];
      console.error(batchResult);
    }
    _$(elem_batchSelection).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: batchList,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
      onBlur: function () {
        clearError("participants");
      },
      onChange: async function (values) {
        if (!values) return;
        sessionObj.batchId = values;
        await tick();
      },
    });
    if(_id && sessionObj.batchId && (sessionObj.batchId).length){
        _$(elem_batchSelection)[0].selectize.setValue(sessionObj.batchId, false)
    }
  };

  async function initTimers(){

    _$(elem_startTimePicker).timepicker({
            timeFormat: "h:mm p",
            interval: 15,
            dynamic: false,
            dropdown: true,
            scrollbar: true,
            // template: "modal",
            defaultTime: startTimeStr? startTimeStr : "Select start time",
            // placeholder: "Select start time",
            change: async function (time) {
                if (time) {
                   sessionObj.startTime = time
                }
                await tick();
            },
        });

        _$(elem_endTimePicker).timepicker({
            timeFormat: "h:mm p",
            interval: 15,
            dynamic: false,
            dropdown: true,
            scrollbar: true,
            defaultTime: endTimeStr? endTimeStr : "Select end time",
            // placeholder: "Select end time",
            // template: "modal",
            change: async function (time) {
                if (time) {
                    sessionObj.endTime = time
                }
                await tick();
            },
        });

        if(startTimeStr || endTimeStr){



        }
  }


  let initChannelsDropdown = async function () {
    let channelsResult = await api_listChannels({ isSortByName:true});
    if (channelsResult.status) {
      console.log(channelsResult.data);
      channelsList = channelsResult.data.data;
    } else {
      channelsList = [];
      console.error(channelsResult);
    }
    _$(elem_channelSelection).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: channelsList,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
      onBlur: function () {
        clearError("participants");
      },
      onChange: async function (values) {
        sessionObj.groupId = values;
      },
    });
    if(_id && sessionObj.groupId && (sessionObj.groupId).length){
        _$(elem_channelSelection)[0].selectize.setValue(sessionObj.groupId, false)
    }
  };

  async function api_markStudentAbscent(studentId) {

    let action = "doubtSession.markStudentAbscent";
    var response = await api_handleApiCalls(action, {studentId, _id});
    console.log("response::::::", response)
    return response;

  }



   function bindDatatable (_id){
    sessionAttendanceDataTable = _$(elem_sessionAttendanceDataTable).DataTable({
      stateSave: true,
      "stateSaveCallback": function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data))
      },
      "stateLoadCallback": function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname))
      },
      responsive: false,
      language: {
        processing: "",
        searchPlaceholder: 'Search',
        sSearch: '',
        lengthMenu: '_MENU_ Session attendance/page',
      },

      ajax: function (data, callback, settings) {

        // data.sort = parseSorts(data.order ? data.order[0] : { 0: "asc" });
        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: "doubtSession.listAttendance",
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: data.length,
              search: data.search.value,
              sort: data.sort,
              _id: _id
            }
          },
          success: function (resData) {
            
            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.recordsTotal),
                recordsFiltered: Number(paginationData.recordsFiltered),
                data: paginationData.data
              }
              if (!resData.data.data.length) {
                emptyState = true
              }
              callback(result);
            }
            else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },
      columns: [
              {
                  data: 'name',
                  defaultContent: "-",
                   render: (data, type, full) => {
                        let image;
                        // if image found display profilePic avatar
                        image = `<img src="${full.profilePic ? full.profilePic : "/images/man.png"
                            }" class="avatar mg-r-15" alt="${data[0].toUpperCase()}">`;
                        return `
            <div style = "cursor:pointer" class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 btn-edit-student" student-id="${full._id}">
              ${image}
              <div class="wd-80p bg-transparent">
                <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent btn-edit-student tx-capitalize" student-id="${full._id}">${data || "-"
                            }</h6>
                <span class="d-block tx-11 text-muted bg-transparent">
                    <i style="width: 15px; height: 15px;" data-feather="phone"></i> 
                    ${full.mobileNo || "-"}
                </span>
              </div>
            </div>`;
                    },
              },
              {
                    data: 'batchDetails',
                    defaultContent: '-',
                    render: (data, type, full) => {
                        var htmlstr = '';
                        if (!full.studentBatchDetails.length) {
                            return "-";
                        }
                        for (let j = 0; j < full.studentBatchDetails.length; j++) {
                            let assignedBatch = full.studentBatchDetails[j]

                            let batch = data.filter(item => item._id == assignedBatch.batchId)

                            if (batch && assignedBatch.active && assignedBatch.access) {
                                htmlstr += `
                    <div class="badge badge-secondary tx-capitalize" style="font-size:12px;margin-right:4px;margin-bottom:4px; text-align: center;">${batch[0].name}</div>
                    `;
                            }

                        }

                        return htmlstr

                    }
                },
              {
                  data: '_id', render: function (data, type, row, meta) {
                      return `
                      <div class="d-md-flex flex-row justify-content-center">
                          <a href="#" class="rename btn-edit-attendance mg-r-5" student-id="${data}" data-toggle="tooltip" data-placement="top" title="Mark abscent"><i data-feather="edit" class="wd-15 mg-r-5"></i></a>
                      </div>
                  `;


                  }
              },

          ],

          "drawCallback": function () {
              feather.replace()
              _$('.btn-edit-attendance').unbind('click').click(function (e) {
                  e.preventDefault();
                  var studentId = _$(this).attr("student-id");
                  pConfirm("Mark Absent ", "Are you sure you want to mark Absent",
                        async function () {
                            var res = await api_markStudentAbscent(studentId)
                            if(res.status){
                              pSuccess("Absent Mark", "This student is removed from present list")
                              sessionAttendanceDataTable.ajax.reload();
                            }else{
                              pError("Error", "Something went wrong");
                            }
                        })

              })
              // bind actions 
          },
          "processing": true,
          "serverSide": true,
          });

          _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
  
  }


  let validateDoubtSession = () => {
        errorObj = {};
       //console.log("session Obj",sessionObj)

            var newErrorObj = {};
            if (!sessionObj.topic) {
                newErrorObj.topic = "Session topic is required field.";
            }

            if (!sessionObj.venue) {
                newErrorObj.venue = "Session venue is required field.";
            }
            if(!sessionObj.facultyId) {
             
            newErrorObj.facultyId = "Address By is required field";
          }
            if (!sessionObj.sessionDate) {
                newErrorObj.sessionDate = "Session date is required field.";
            }

            if (!sessionObj.startTime) {
                newErrorObj.startTime = "Start time is required field.";
            }

            if (!sessionObj.endTime) {
                newErrorObj.endTime = "End time is required field.";
            }
          
           

            if (!sessionObj.batchId || !sessionObj.groupId || !sessionObj.studentId) {
                newErrorObj.participants = "Participents are required. Select atleast one participant.";
            }

            errorObj = newErrorObj;

        
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;


    }


  let createSession = async function () {
    if (isLoading) return;
    isLoading = true;
    await tick();

    if(_id) sessionObj._id = _id
    sessionObj.sessionDate = dateString
    

    let startDate, startTime, endDate, endTime;

    if (sessionObj.sessionDate) startDate = new Date(sessionObj.sessionDate);
    if (sessionObj.startTime) startTime = new Date(sessionObj.startTime);
    if (startDate && startTime) {
      startDate.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
      sessionObj.startTime = new Date(startDate);
    }
    // else if(startDate && !startTime){
    //   startDate.setHours(0,0,0,0)
    //   sessionObj.startTime = startDate;
    // }
    


    if (sessionObj.sessionDate) endDate = new Date(sessionObj.sessionDate);
    if (sessionObj.endTime) endTime = new Date(sessionObj.endTime);
    if (endDate && endTime) {
      endDate.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);
      sessionObj.endTime = new Date(endDate);
    }
    // else if(endDate && !endTime){
    //   endDate.setHours(0,0,0,0)
    //   sessionObj.endTime = endDate;
    // }
    sessionObj.facultyId = _$(elem_sysUserSelection).val();
  
    if(validateDoubtSession()){
          let result = await api_addUpdateSession(sessionObj);
          console.log("createSession", result);
          if (result.status) {
            pSuccess("", result.data);
            // get insertedId here
            navigate("/dashboard/doubtSession/");
          } else {
            pError("", result.errMsg || result.error || "something went wrong");
          }
        
    }
    isLoading = false;
    await tick();
  };
  let scheduleChanged = async function (event) {
    console.log(" scheduleChanged : ", event.detail.schedule);
    sessionObj.schedule = event.detail.schedule;
    sessionObj.scheduled = true;
    await tick();
  };
  let clearSchedule = async function () {
    sessionObj.schedule = {};
    sessionObj.scheduled = false;
    await tick();
  };

  async function api_getDoubtSessionById(data){
    let action = "doubtSession.getDoubtSessionById";
    var response = await api_handleApiCalls(action, data);
    return response;
  }
  

  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var dateString = [year, month, day].join("-");
    return dateString;
  }

  function convertDateObjToTimeStr(date) {
        date = new Date(date);
       
        var minutes = "" + date.getMinutes();
        var am_pm = date.getHours() >= 12 ? 'PM' : 'AM'
        var hours = date.getHours() % 12;
        hours = hours ? hours : 12;

        if (hours.length < 2) hours = "0" + hours;
        if (minutes.length < 2) minutes = "0" + minutes;
        
        var timeString = [hours, minutes].join(":") + " " + am_pm;
        
       return timeString;
  }
  console.log(convertDateObjToTimeStr(new Date))
  


  onMount(async function () {
    activeMenu.set("doubtSession");
    feather.replace();
    if (_id) {
      await bindDatatable( _id)
      var res = await api_getDoubtSessionById(_id);
      sessionObj = {...(res.data)}
      dateString = convertDateObjToStr(sessionObj.sessionDate)
      console.log("newdatae" ,dateString);
      startTimeStr = convertDateObjToTimeStr(sessionObj.startTime)
      endTimeStr = convertDateObjToTimeStr(sessionObj.endTime)
      console.log("date>>", endTimeStr);

    }
    await tick();
    initBatchDropdown();
    initStudentDropdown();
    initSysUserDropdown();
    initChannelsDropdown();
    initTimers();

  });
</script>

<style>
  .empty-state-img {
    text-align: center;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
</style>

<div class="content-body fadeIn">
  <div class="d-sm-flex align-itemd-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/doubtSession">Doubt Session</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {displayText} Session
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">{displayText} Doubt Session</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
        Create or update a doubt session
      </p>
    </div>
    <div class="d-block">
      <button disable={isLoading} on:click={createSession}
        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
        <i data-feather="send" class="wd-10 mg-r-5" />
        <ButtonLoader {isLoading} btnText={_id ? 'Update Doubt Session' : 'Create Doubt Session' } />
      </button>
    </div>
  </div>
  <hr class="full-width" />
  <div class="mg-b-20 ">
    <div class="row row-sm">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Doubt Session Details
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="formGroupExampleInput" class="d-flex" style="align-items: baseline">
                  <div>
                    Session Topic
                    <span class="required-field">*</span>
                  </div>
                </label>
                <input type="text" class="form-control" bind:value={sessionObj.topic} class:parsley-error={errorObj.topic}
                on:keyup={(e)=> e.key === 'Enter' && createSession()} placeholder="Session topic" on:blur={()=> clearError('topic') }>
                {#if errorObj.topic}
                  <div class="parsley-errors-list">{errorObj.topic}</div>
                {/if}
              </div>
            </div>
              <div class="form-group">
                <label for="formGroupExampleInput" class="d-flex" style="align-items: baseline">
                  <div>
                    Session Venue
                    <span class="required-field">*</span>
                  </div>
                </label>
                <input type="text" class="form-control" bind:value={sessionObj.venue} on:keyup={(e)=> e.key === 'Enter' && createSession()} class:parsley-error={errorObj.venue}
                 placeholder="Session venue" on:blur={()=> clearError('venue') }>
                {#if errorObj.venue}
                  <div class="parsley-errors-list">{errorObj.venue}</div>
                {/if}
              </div>

              <div class="form-group">
                <label for="formGroupExampleInput" class="d-flex" style="align-items: baseline">
                  <div>
                    Adderssed By Faculty
                    <span class="required-field">*</span>
                  </div>
                </label>
                 <select bind:this={elem_sysUserSelection} id="faculty" required name="faculty_dropdown"
                   class="info slectizedd form-control" class:parsley-error={errorObj.facultyId}
                   placeholder="Select Faculty" on:blur={()=> clearError('facultyId')} >
                   <option>Select faculty</option>
                  </select>
                   {#if errorObj.facultyId}
                 <div class="parsley-errors-list">{errorObj.facultyId}</div>
               {/if}
             </div>
             <div class="form-row">

              <div class="form-group col-md-12">
                  <label for="formGroupExampleInput" class="d-flex" style="align-items: baseline">
                    <div>
                      Session date
                      <span class="required-field">*</span>
                    </div>
                  </label>
                  <input type="date" class="form-control" bind:value={dateString} class:parsley-error={errorObj.sessionDate} on:keyup={(e)=> e.key === 'Enter' && createSession()}
                  placeholder="Session date" min={convertDateObjToStr(todaysDate)} on:blur={()=> clearError('sessionDate') }>
                  {#if errorObj.sessionDate}
                    <div class="parsley-errors-list">{errorObj.sessionDate}</div>
                    {/if}
                  </div>
                  
                  <div class="row">
                      <div class="form-group col-sm-6">
                        <label for="formGroupExampleInput" class="d-flex" style="align-items: baseline">
                          <div>
                            Session Start Time
                            <span class="required-field">*</span>
                          </div>
                        </label>
                        <input type="text" class="form-control" bind:this={elem_startTimePicker} class:parsley-error={errorObj.startTime}
                        placeholder="Session startTime" on:blur={()=> clearError('startTime') }>
                        {#if errorObj.startTime}
                          <div class="parsley-errors-list">{errorObj.startTime}</div>
                        {/if}
                      </div>
                      <div class="form-group col-sm-6">
                        <label for="formGroupExampleInput" class="d-flex" style="align-items: baseline">
                          <div>
                            Session End Time
                            <span class="required-field">*</span>
                          </div>
                        </label>
                        <input type="text" class="form-control" bind:this={elem_endTimePicker} class:parsley-error={errorObj.endTime}
                        placeholder="Session endTime" on:blur={()=> clearError('endTime') }>
                        {#if errorObj.endTime}
                          <div class="parsley-errors-list">{errorObj.endTime}</div>
                        {/if}
                      </div>
                  </div>
  
          </div>

          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <!-- div class="column"> -->
        <div class="col-sm-12 row">
          <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
            Session Recipients
          </h6>
          <p
            class="parsley-errors-list"
            class:text-muted={!errorObj.participants}>
            {#if errorObj.participants}
              {errorObj.participants}
            {:else}
              Select at least one Batch/ Channel/ Students
            {/if}
          </p>
        </div>
        <hr style="margin-top: 5px;" />
        <div class="col-sm-4 d-none" />

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label class="d-block">Batches</label>
            <select
              bind:this={elem_batchSelection}
              class="info slectizedd form-control"
              placeholder="Select Batches">
              <option value="">Select Batch</option>
            </select>
          </div>
        </div>
        
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label class="d-block">Groups</label>
            <select
              bind:this={elem_channelSelection}
              class="info slectizedd form-control"
              placeholder="Select Groups">
              <option value="">Select Group</option>
            </select>
          </div>
        </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label class="d-block">Student</label>
              <select
                bind:this={elem_studentSelection}
                class="info slectizedd form-control"
                placeholder="Select Student">
                <option value="">Select Student</option>
              </select>
            </div>
          </div>
          
      </div>
      </div>
    </div>

    {#if _id}
    <div class="mg-b-20 ">
      <div class="row row-sm">
        <div class="col-sm-12">
          <div class="column">
            <div class="col-sm-06">
              <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
                Mark Attendance
              </h6>
              <p class="tx-color-03">
                Mark attendance for the session
              </p>
              <hr  />
            </div>
            <button style="float: right;"  on:click={()=> showModal = !showModal}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <i data-feather="send" class="wd-10 mg-r-5" />
            Mark Attendance
          </button>
            
          <div class="pd-t-50">
            <table bind:this={elem_sessionAttendanceDataTable} class="table">
                <thead>
                    <tr>
                        <th style="width: 20%;">Name</th>
                        <th style="text-align: center; width: 15%;">Batches</th>
                        <th style="text-align: center; width: 10%;">Actions</th>
                    </tr>
                </thead>
                <tbody></tbody>
    
            </table>
    
        </div>




          </div>
      </div>
      <!-- {JSON.stringify(sessionObj, null, 2)} -->
    </div>
  </div>
  {/if}



  </div>
{#if showModal}
  <UserMultiSelectCmp filters = {role} on:done = {onCloseModal} ></UserMultiSelectCmp>
{/if}

  

