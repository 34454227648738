<script>
  // your script goes here
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../../stores";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import { pSuccess, pError, pConfirm } from "../../utils/notify.svelte";

  export let reload;
  const integration = {};
  const originalObject = {};
  let isLoading= false;
  let errorObj = {}
  let disableStatus = false
  
  async function fetchIntegrationSetting() {
    if (isLoading) return;
    isLoading = true;
    await tick();
    let result = await api_handleApiCalls("settings.fetchIntegrationSettings", {});
    console.log("fetchIntegrationSettings", result);
    if (result.status) {
      console.log("integration data", JSON.stringify(result.data.RAZOR_PAY, null, 4))
      integration.razorPayPrivateKey = result.data.RAZOR_PAY.razorPayPrivateKey || "";
      integration.razorPayAPIKey = result.data.RAZOR_PAY.razorPayAPIKey || "";
      //store for original comparision
      originalObject.razorPayPrivateKey = result.data.RAZOR_PAY.razorPayPrivateKey || "";
      originalObject.razorPayAPIKey = result.data.RAZOR_PAY.razorPayAPIKey || "";
    } else {
      pError("", result.errMsg);
    }
    isLoading = false;
    await tick();
    feather.replace({ width: "1em", height: "1em" });
  }

  async function setRazorPayIntegration(value) {
    
    let validationResult = validateRazorPay(value)
    if (!validationResult)
    {
      return false;
    }
    let result = await api_handleApiCalls("settings.setRazorPayCredentials", { value });
      if (result.status) {
      pSuccess("", result.data);
      fetchIntegrationSetting();
      if (reload) reload();
    } else {
      pError("", result.errMsg);
    }
  }
  
  onMount(function () {
      fetchIntegrationSetting();
  });


function clearValidation () {
  errorObj = {}
}

function validateRazorPay (data) {
  if (checkRazorPayAPIKey(data) && checkRazorPayPrivatkey(data) && checkForUnchangedData(data) && checkForStars(data))
  {   
    return true;
  }
  return false;
}

function checkRazorPayAPIKey(data) {
  if(data.razorPayAPIKey===""){
    errorObj["api_key"] = "Please enter API key"
    return false;
  }
  return true;
}

function checkRazorPayPrivatkey(data) {
  if(data.razorPayPrivateKey===""){
    errorObj["private_key"] = "Please enter secret key"
    return false;
  }
  return true;
}

function checkForUnchangedData (data) {
  if(data.razorPayPrivateKey===originalObject.razorPayPrivateKey && data.razorPayAPIKey===originalObject.razorPayAPIKey){
    errorObj["data_unchanged"] = "Credentials are unchaged"
    return false;
  }
  return true;
}

function checkForStars (data) {
  if(data.razorPayPrivateKey.includes("*") ||  data.razorPayAPIKey.includes("*")){
    errorObj["star_present"] = "Credentials Cannot Contains Asterisk "
    return false;
  }
  return true;
}
</script>

<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xs-12">
    <ul class="list-group">
      {#if isLoading}
      <li class="list-group-item list-group-item-action">
        <div class="mg-20 pd-10">
          <div class="placeholder-paragraph">
            <div class="line mg-b-10" />
            <div class="line mg-b-10" />
          </div>
        </div>
      </li>  
      {:else}
        <li class="list-group-item list-group-item-action">
          <div class="d-flex justify-content-between">
            <div>Razor Pay</div>
            <nav>
              <a
                data-toggle="collapse"
                href="#facebook"
                role="button"
                aria-expanded="false"
                aria-controls="facebook"
                class="d-inline mg-l-5 nav-link">
                <i data-feather="edit" /></a>
            </nav>
          </div>
          <div class="collapse mg-t-10" id="facebook">
            <div class="form-group">
              <label for="formGroupExampleInput" class="d-block">API Key </label>
              <input
                type="text"
                bind:value={integration.razorPayAPIKey}
                on:focus={clearValidation}
                class="form-control"
                placeholder="Razor Pay API Key"
                aria-label="Facebook Page"
                class:parsley-error={errorObj.api_key || errorObj.data_unchanged} 
                aria-describedby="button-addon2" />
                {#if errorObj.api_key} 
                <div class="parsley-errors-list">*{errorObj.api_key}</div>
              {/if}
                <br>

              <label for="formGroupExampleInput" class="d-block">Secret Key</label>
              <input
                type="text"
                bind:value={integration.razorPayPrivateKey}
                class="form-control"
                on:focus={clearValidation}
                placeholder="Razor Pay Secret Key"
                aria-label="Facebook Page"
                class:parsley-error={errorObj.private_key || errorObj.data_unchanged || errorObj.star_present} 
                aria-describedby="button-addon2" />
                {#if errorObj.private_key} 
              <div class="parsley-errors-list">*{errorObj.private_key}</div>
            {/if}

            {#if errorObj.data_unchanged} 
            <div class="parsley-errors-list">*{errorObj.data_unchanged}</div>
          {/if}

            {#if errorObj.star_present} 
            <div class="parsley-errors-list">*{errorObj.star_present}</div>
          {/if}
              <div class="input-group-append mg-t-10 d-flex flex-row-reverse">
                <button
                  on:click={(event) => setRazorPayIntegration(integration)}
                  class="btn btn-outline-light"
                  disabled={disableStatus}
                  type="button"
                  id="button-addon2">Save</button>
              </div>
            </div>
          </div>
        </li>
      {/if}
    </ul>
  </div>
</div>
