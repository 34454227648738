<script>
  // import { link } from "svelte-routing";
  // _$(function () {
  //   "use script";

  //   window.darkMode = function () {
  //     _$(".btn-white").addClass("btn-dark").removeClass("btn-white");
  //   };

  //   window.lightMode = function () {
  //     _$(".btn-dark").addClass("btn-white").removeClass("btn-dark");
  //   };

  //   var hasMode = Cookies.get("df-mode");
  //   if (hasMode === "dark") {
  //     darkMode();
  //   } else {
  //     lightMode();
  //   }
  // });
  export let mode = "empty";
</script>

<div class="wd-200p tx-center align-items-center justify-content-center bd">
  {#if mode == 'search'}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">Branch not found.</p>
  {:else}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">
      Welcome! Add your first branch.
    </p>
  {/if}
  <img src="/images/empty.svg" style="width: 200px;" class="img-fluid" alt="No Result" />
</div>

<!-- <div class="content content-fixed content-auth-alt bd bg-gray-100">
  <div class="container ht-100p tx-center">
    <div
      class="ht-100p d-flex flex-column align-items-center
        justify-content-center">
      <h1 class="tx-gray-500 tx-15 tx-sm-18 tx-lg-21 mg-xl-b-5">
        {#if mode == 'search'}Branch Not Found{:else}Add your First Branch{/if}
      </h1>
      <br />
      <p class="tx-14 tx-sm-15 tx-lg-16 tx-gray-400 mg-b-20">
        {#if mode == 'search'}
          Try other keywords
        {:else}
          <a
            href="branch/add"
            use:link
            class="btn btn-white btn-sm pd-x-15 btn-uppercase mg-l-5 tx-gray-400
              bd-gray-400"><i data-feather="plus" class="wd-10 mg-r-5" /> Add new
            Branch</a>
        {/if}
      </p>
    </div>
  </div>
</div> -->
