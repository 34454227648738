<script>
  import {
    Router,
    Link,
    links,
    Route,
    navigate
  } from "svelte-routing";
  import {
    onMount
  } from "svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import { userData } from "../../stores";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  export let studentId;
  let parentsArr = [];
  let roleIsTelecaller;
  let addParentModal;
  let elem_relation_type;

  async function api_addUpdateParent(data) {
    let action = "parents.getById";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  console.log("studentID", studentId);
  if (!studentId) window.history.back();
  export let _id;
  let relationType;
  let isLoading;
  let parentObj = {
    _id: _id,
    name: "",
    occupation: "",
    relation: null,
    mobileNo: "",
    altMobileNo: "",
    studentId
  }

  let displayText = "Add Parent";


  let errorObj = {};
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = {
      ...errorObj
    }; // reset state
  }
  let validateParent = () => {
    const PHONE = RegExp(/^[7-9][0-9]{9}$/);
    const NAME = RegExp(/^[a-zA-Z ]+$/);
    errorObj = {};
    if (parentObj) {
      var newErrorObj = {};
      if (!parentObj.name || !parentObj.relation) {
        if (!parentObj.name) {
          newErrorObj.name = "Name is required Field.";
        }
        // if (!parentObj.mobileNo) {
        //   newErrorObj.mobileNo = "Mobile Number is required Field.";
        // }
        if (!parentObj.relation) {
          newErrorObj.relation = "Relation is required Field.";
        }
      }

      if (!NAME.test(parentObj.name)) {
        newErrorObj.name = "Enter Valid Name";
      }

      if (!PHONE.test(parentObj.mobileNo)) {
        newErrorObj.mobileNo = "Enter Valid Mobile Number";
      }

      if (parentObj.altMobileNo && !PHONE.test(parentObj.altMobileNo)) {
        newErrorObj.altMobileNo = "Enter Valid Mobile Number";
      }
      errorObj = newErrorObj;

    }
    if (Object.keys(errorObj).length > 0) {
      isLoading = false;
      return false;
    }
    return true;
  }

  function initSelectize() {
    _$(elem_relation_type).selectize({
      maxItems: 1,
      valueField: "value",
      labelField: "relation",
      searchField: "relation",
      placeholder: 'Select relation',
      options: [
        { value: "mother", relation: "Mother" },
        { value: "father", relation: "Father" },
        { value: "guardian", relation: "Guardian" },
      ],
      create: false,
      items: [],
      onChange: function (value) {
        parentObj.relation = value;
      },
    });
  }

  function resetParent() {
    parentObj = {
      _id: "",
      name: "",
      occupation: "",
      relation: null,
      mobileNo: "",
      altMobileNo: "",
      studentId
    }
    _$(relationType).val('');
    displayText = "Add Parent";
  }
  let selectChange = () => {
    parentObj.relation = _$(relationType).val();
  }
  async function addUpdateParent() {
    if (isLoading) return;
    isLoading = true;
    if (validateParent()) {
      parentObj.name = (parentObj.name).replace(/\s+/g, ' ').trim();
      let action = "parents.add";
      if (parentObj._id) action = "parents.edit";
      let resData = await api_handleApiCalls(action, parentObj);
      if (resData.status) {
        isLoading = false;
        _$(addParentModal).modal('hide');
        PNotify.success({
          title: 'Success',
          text: resData.msg,
          delay: 3000
        })
        loadParents();
      } else {
        isLoading = false
        PNotify.error({
          title: 'Error',
          text: resData.errMsg,
          delay: 3000
        })
      }
    } else {
      isLoading = false;
    }
  }

  async function editParent(parentId) {
    console.log("parentId", parentId);
    if (parentId) {
      let resData = await api_addUpdateParent({
        _id: parentId
      });
      if (resData.status) {
        let patObj = resData.data[0];
        displayText = "Update Parent"
        parentObj = {
          _id: patObj._id,
          name: patObj.name,
          occupation: patObj.occupation,
          relation: patObj.relation,
          mobileNo: patObj.mobileNo,
          altMobileNo: patObj.altMobileNo,
          studentId
        }
        _$(relationType).val(patObj.relation);
        _$(addParentModal).modal('show');
      }
       else {
        PNotify.error({
          title: 'Error',
          text: resData.errMsg,
          delay: 3000
        })
      }
    }
  }

  async function deleteParent(parentId) {
    const notice = PNotify.notice({
      title: "Delete Parent",
      text: "Are you sure you want to delete this Parent ?",
      hide: false,
      closer: false,
      sticker: false,
      modules: new Map([
        ...PNotify.defaultModules,
        [PNotifyConfirm, { confirm: true }],
      ]),
    })


    notice.on("pnotify:confirm", async () => {
      try {
        var deleteBatchRes = await api_handleApiCalls('parents.delete', { _id: parentId });
        if (deleteBatchRes.status) {
          loadParents();
          PNotify.success({
            title: 'Success',
            text: "Parent Deleted Successfully",
            delay: 3000
          })
        } else {
          PNotify.error({
            title: 'Error',
            text: deleteBatchRes.errMsg,
            delay: 3000
          })
        }

      } catch (error) {
        console.error(error);
      }


    })

  }
  let loadParents = async () => {
    var resData = await api_handleApiCalls("parents.getById", {
      studentId
    })
    if (resData.status) {
      parentsArr = resData.data;
    } else {
      PNotify.error({
        title: 'Error',
        text: resData.errMsg,
        delay: 2000
      })
    }
  }
  const unsubscribe = userData.subscribe(value => {
    roleIsTelecaller = value.role == "telecaller"
  });

  onMount(async () => {
    unsubscribe();
    initSelectize();
    loadParents();
    _$(addParentModal).on('hidden.bs.modal', function (e) {
      errorObj = {};
      resetParent();
      _$(this).removeClass(function (index, className) {
        return (className.match(/(^|\s)effect-\S+/g) || []).join(' ');
      });
    });
  })
</script>


<div class="d-flex align-items-center justify-content-between mg-b-20">
  <h6 class="tx-13 tx-spacng-1 tx-uppercase tx-semibold mg-b-0">Parents</h6>
  {#if !roleIsTelecaller}
  <a href="#addParent" data-toggle="modal" class="link-03 tx-bold" style="color: #0168fa;">
    <i data-feather="plus" class="wd-10 mg-r-5"></i>Add Parent</a>
  {/if}
</div>
<ul class="list-unstyled media-list mg-b-15">

  {#each parentsArr as parent (parent._id)}
  <li class="media align-items-center" parent-id={parent._id}>
    <div class="d-flex" on:click={editParent(parent._id)}>
      <a href="javascript:void(0);">
        <div class="avatar"><span class="avatar-initial rounded-circle bg-dark">{#if parent.relation =="father"}
            F
            {:else if parent.relation=="mother"}
            M
            {:else}
            G
            {/if}</span></div>
      </a>
      <div class="media-body pd-l-15">
        <p class="tx-medium mg-b-0"><a href="javascript:void(0);" class="link-01">{parent.name}</a></p>
        <span class="tx-12 tx-color-03">{parent.mobileNo}
          {#if parent.altMobileNo}
          <!-- content here -->
          | {parent.altMobileNo}
          {/if}
        </span>
      </div>

    </div>
    <div class="mg-l-auto d-flex align-self-start">
      <span class="nav nav-icon-only">
        <a href="javascript:void(0);" on:click={deleteParent(parent._id)} class="nav-link d-none d-sm-block"
          style="color:  #0168fa;" data-toggle="tooltip" data-placement="bottom" title="Delete"><i data-feather="trash"
            class="wd-20 mg-r-5"></i></a>
      </span>
    </div>
  </li>
  {/each}
</ul>

<div class="modal fade" id="addParent" bind:this={addParentModal} tabindex="-1" role="dialog"
  aria-labelledby="addParentLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="users" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">{displayText}</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Add details below.</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->
      <hr class="full-width">
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0 ">
        <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div class="row row-sm mg-b-10">
            <div class="col-sm-12">

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="d-block">Name<span class="tx-danger">*</span></label>
                  <input type="text" bind:value={parentObj.name} on:keyup={(e) => e.key === 'Enter' && addUpdateParent()} on:blur={()=>clearError("name")} class="form-control"
                  placeholder="Enter your full name">
                  {#if errorObj.name}
                  <div class="parsley-errors-list">{errorObj.name}</div>
                  {/if}
                </div>
                <div class="form-group col-md-3">
                  <label class="d-block">Mobile Number<span class="tx-danger">*</span></label>
                  <input type="number" bind:value={parentObj.mobileNo} on:keyup={(e) => e.key === 'Enter' && addUpdateParent()} on:blur="{()=>clearError('mobileNo')}"
                    on:keypress="{e => (e.key =='e'||e.key =='+') ? e.preventDefault():''}"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    class="form-control" placeholder="Enter your Mobile Number" maxlength="10">
                  {#if errorObj.mobileNo}
                  <div class="parsley-errors-list">{errorObj.mobileNo}</div>
                  {/if}
                </div>
                <div class="form-group col-md-3">
                  <label class="d-block">Alternate Mobile Number</label>
                  <input type="number" bind:value={parentObj.altMobileNo} on:keyup={(e) => e.key === 'Enter' && addUpdateParent()}  on:blur="{()=>clearError('altMobileNo')}"
                    on:keypress="{e => (e.key =='e'||e.key =='+') ?e.preventDefault():''}" class="form-control"
                    placeholder="Enter your Alternate Mobile Number"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="10">
                  {#if errorObj.altMobileNo}
                  <div class="parsley-errors-list">{errorObj.altMobileNo}</div>
                  {/if}
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="d-block">Relation<span class="tx-danger">*</span></label>
                  <!-- on:change={selectChange} -->
                  <select bind:this={elem_relation_type} on:click={()=>clearError("relation")}
                    >
                    <!-- <option value="father">Father</option>
                    <option value="mother">Mother</option>
                    <option value="guardian">Guardian</option> -->
                  </select>
                  {#if errorObj.relation}
                  <div class="parsley-errors-list">{errorObj.relation}</div>
                  {/if}
                </div>
                <div class="form-group col-md-6">
                  <label class="d-block">Occupation</label>
                  <input type="text" on:keyup={(e) => e.key === 'Enter' && addUpdateParent()} bind:value={parentObj.occupation}  on:blur={()=>clearError("occupation") }
                  class="form-control" placeholder="Enter your occupation">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CLOSE
          <i data-feather="x" class="wd-10 mg-l-5"></i>
        </button>
        <button type="button" disabled={isLoading} on:click={addUpdateParent} class="btn btn-primary tx-12">
          <ButtonLoader isLoading={isLoading} btnText="SAVE" />
          <i data-feather="save" class="wd-10 mg-l-5"></i>
        </button>
      </div>
    </div>
  </div>
</div>
