<script>
  import { links, link } from "svelte-routing";
  import { onMount, tick, afterUpdate } from "svelte";
  import { activeMenu } from "../../stores";
  import Empty from "../empty/empty.svelte";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  let isLoading = false;
  let isLoadingList = false;
  let addNewAnalyticalCategoryModal;
  let displayText = "Analytical Categories List";
  const analyticalCategoryObj = { name: "", color: "#ff0000" };
  let errorObj = {};
  let analyticalCategoriesList = { data: [] };
  let search = { value: "" };
  const pageSize = 100;
  let elem_picker;
  const dummyData = [
    { _id: 0, name: "name", createdOn: new Date() },
    { _id: 1, name: "name1", createdOn: new Date() },
  ];

  async function api_delete(data) {

    let action = "analyticalCategory.removeAnalyticalCategory";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_list(data) {

    let action = "analyticalCategory.fetchAnalyticalCategoryList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_getbyid(data) {

    let action = "analyticalCategory.fetchAnalyticalCategory";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_save(data) {

    let action = "analyticalCategory.saveAnalyticalCategory";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  function validate() {
    //   clear previous validations before validating anew
    clearValidations();
    var regexpStartingWithAlphabet = new RegExp(`^[A-Za-z]`);
    var regexpColorHexString = new RegExp(`#[A-Za-z0-9]{6}$`);
    // var regexpEnglish = new RegExp(`^[A-Za-z][\\w\\s]*$`);

    analyticalCategoryObj.name = analyticalCategoryObj.name.trim();
    if (!Boolean(analyticalCategoryObj.name)) {
      errorObj.name = "Name can not be empty";
    } else if (!analyticalCategoryObj.name.match(regexpStartingWithAlphabet)) {
      errorObj.name = "Name must begin with A-Z or a-z characters";
    } else if (analyticalCategoryObj.name.length < 3) {
      errorObj.name = "Name should be minimum 3 characters long";
    } else if (analyticalCategoryObj.name.length > 80) {
      errorObj.name = "Name should not exceed 80 characters long";
    }

    analyticalCategoryObj.color = analyticalCategoryObj.color.trim();
    if (Boolean(analyticalCategoryObj.color)) {
      if (!analyticalCategoryObj.color.match(regexpColorHexString)) {
        errorObj.color = "Please enter valid color hex string";
      }
    }

    if (Object.values(errorObj).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async function submitForm(event) {
    event.preventDefault();
    if (!isLoading) {
      isLoading = true;
      await tick();
      const isValid = validate();
      console.log({ isValid, errorObj });
      if (isValid) {
        console.log("adding", { analyticalCategoryObj, errorObj });
        // save analytical category api call
        let data = {
          name: analyticalCategoryObj.name,
          color: analyticalCategoryObj.color,
        };
        if (analyticalCategoryObj._id) data._id = analyticalCategoryObj._id;
        let result = await api_save(data);
        console.log(result);
        if (result.status) {
          pSuccess(null, result.data);
          reload();
          hideModal();
        } else if (result.errMsg) {
          pError(null, result.errMsg);
        } else if (result.error) {
          pError(null, result.error);
        } else {
          pError(null, "Something went wrong");
        }
      }
      isLoading = false;
      await tick();
    }
  }

  function clearFields() {
    // form fields
    if (analyticalCategoryObj._id) delete analyticalCategoryObj._id;
    analyticalCategoryObj.name = "";
    analyticalCategoryObj.color = "";
    clearValidations();
  }

  async function showModal() {
    _$(".first-input").focus();
    clearFields();
    await tick();
    _$(addNewAnalyticalCategoryModal).modal("show");
  }

  async function hideModal() {
    _$(addNewAnalyticalCategoryModal).modal("hide");
    clearFields();
  }

  async function clearValidations() {
    errorObj = {};
    await tick();
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    // if (sortOrder.column === 1) {
    //   sort.name = order;
    // } else if (sortOrder.column === 2) {
    //   sort.createdOn = order;
    // } else {
    //   sort.seqId = order;
    // }
    sort.seqId = order;
    return sort;
  }

  async function fetchAnalyticalCategoriesList() {
    let data = {
      start: 0,
      length: pageSize,
      search,
      sort: parseSorts({ 0: "dsc" }),
    };
    console.info(data);
    // fetch call
    isLoadingList = true;
    await tick();
    let response = await api_list(data);
    console.info({ response });
    if (response.status) {
      var paginationData = response.data;
      analyticalCategoriesList = {
        recordsTotal: Number(paginationData.recordsTotal),
        recordsFiltered: Number(paginationData.recordsFiltered),
        data: paginationData.data,
      };
    } else {
      console.error("something went wrong");
      analyticalCategoriesList = {
        recordsTotal: 0,
        recordsFiltered: 0,
        data: [],
      };
    }
    isLoadingList = false;
    await tick();
  }

  async function editAnalyticalCategory(_id) {
    await showModal();
    let response = await api_getbyid({ _id });
    analyticalCategoryObj.name = response.data.name;
    analyticalCategoryObj.color = response.data.color;
    analyticalCategoryObj._id = response.data._id;
    await tick();
    _$(elem_picker).spectrum("set", analyticalCategoryObj.color);
  }

  async function deleteAnalyticalCategory(analyticalCategoryId) {
    pConfirm(
      "Delete analytical category?",
      "If this analytical category is assigned to any question, it will not be deleted.",
      async function () {
        console.info("Deleting");
        //   delete api
        let result = await api_delete({ _id: analyticalCategoryId });
        if (result.status) {
          pSuccess(null, result.data);
          await fetchAnalyticalCategoriesList();
        } else if (result.errMsg) {
          pError("", result.errMsg);
        } else {
          pError(null, "Something went wrong");
        }
      }
    );
  }

  function colorPicked(value) {
    analyticalCategoryObj.color = value;
  }

  async function reload() {
    await fetchAnalyticalCategoriesList();
    feather.replace({ width: "1em", height: "1em" });
  }

  function bindModalAnimations() {
    _$(this).addClass("effect-scale");
    // new analytical category modal
    _$(addNewAnalyticalCategoryModal).on("show.bs.modal", function (event) {
      _$(elem_picker).spectrum({
        preferredFormat: "hex",
        showInput: true,
        allowEmpty: false,
        change: function (color) {
          console.log(color.toHexString()); // #ff0000
          if (color != analyticalCategoryObj.color)
            colorPicked(color.toHexString());
        },
      });
    });

    _$(addNewAnalyticalCategoryModal).on("hidden.bs.modal", function (e) {
      _$(elem_picker).spectrum("destroy");
      _$(this).removeClass("effect-scale");
    });
  }

  async function init() {
    console.log("mounting analytical category list");
    activeMenu.set("analytical-category");
    bindModalAnimations();
    await reload();
  }

  onMount(init);
  afterUpdate(function () {
    feather.replace({ width: "1em", height: "1em" });
  });
</script>

<style>
  .long-name-wrap {
    word-break: keep-all;
    overflow-wrap: break-word;
    min-width: 90%;
    max-width: 90%;
  }

  .analytical-category-cards-wrap {
    flex-wrap: wrap;
  }

  .card * {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
  }

  .card .hidden-dropdown {
    display: none;
  }

  .card:hover .hidden-dropdown,
  .card:focus .hidden-dropdown {
    display: inherit;
  }

  .more-btn {
    float: right;
    clear: both;
    width: 1em;
    height: 1em;
  }
</style>

<div class="content-body fadeIn">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20
      mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {displayText}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">{displayText}</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search or add the {displayText}. </p>
    </div>
    <div class="d-inline-block">
      <nav use:links class="d-inline-block">
        <div class="d-inline-block">
          <div class="search-form">
            <input type="search" class="form-control" placeholder="Category" bind:value={search.value}
              on:input={reload} />
            <button class="btn" type="button" on:click={reload}><i data-feather="search" /></button>
          </div>
        </div>
        <div class="d-inline-block">
          <button on:click={showModal} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
              data-feather="plus" class="wd-10 mg-r-5" />
            Add new analytical category
          </button>
        </div>
      </nav>
    </div>
  </div>
  <hr class="full-width">
  <div class="animated scale">
    {#if isLoadingList}
    <div class="row row-xs mg-b-20">
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
    </div>
    {:else if analyticalCategoriesList && analyticalCategoriesList.data && analyticalCategoriesList.data.length}
    <!-- <div class="d-sm-flex analytical-category-cards-wrap mg-b-10">
        {#each analyticalCategoriesList.data as analyticalCategory (analyticalCategory._id)}
          <div class="card wd-sm-50p wd-md-40p wd-lg-30p mg-10">
            <div class="card-body row">
              <h6 class="long-name-wrap col-sm-10 col-md-10 col-lg-10">
                {analyticalCategory.name}
              </h6>
              <div
                class="col-sm-1 col-md-1 col-lg-1 hidden-dropdown more-btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <span class="pointer"> <i data-feather="more-vertical" /></span>
                <nav class="dropdown-menu tx-13" use:links>
                  <a href="analytical-category/{analyticalCategory._id}" class="dropdown-item"><i
                      data-feather="edit-2" />
                    Edit</a>
                  <button
                    class="dropdown-item"
                    on:click={() => deleteAnalyticalCategory(analyticalCategory._id)}
                    data-id={analyticalCategory._id}><i data-feather="trash" />
                    Remove
                  </button>
                </nav>
              </div>
              {#if analyticalCategory.color}
                <small
                  class="card-text long-name-wrap col-sm-10 col-md-10
                     col-lg-10"><i
                    <div class="wd-12 ht-12 rounded-circle bd bd-3 bd-primary"></div>
                  {analyticalCategory.color}</small>
              {/if}
            </div>
          </div>
        {/each}
      </div> -->
    <div class="row row-xs mg-b-20">
      {#each analyticalCategoriesList.data as analyticalCategory (analyticalCategory._id)}
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="media media-folder">
          <div class="avatar avatar-sm mg-r-10">
            <div class="avatar rounded bd bd-1" class:bg-white={!analyticalCategory.color}
              class:tx-primary={!analyticalCategory.color} class:bd-primary={!analyticalCategory.color}
              style="background-color: {analyticalCategory.color}; color: {analyticalCategory.color}">
              {analyticalCategory.name[0]}
            </div>
          </div>
          <div class="media-body">
            <h6>
              <a href="javascript:void(0)" class="link-02 text-capitalize">{analyticalCategory.name}</a>
            </h6>
            <!-- <span>
              {analyticalCategory.color ? analyticalCategory.color : '-NA-'}</span> -->
          </div>
          <div class="dropdown-file">
            <a href="" class="dropdown-link" data-toggle="dropdown">
              <i data-feather="more-vertical" /></a>
            <nav class="dropdown-menu dropdown-menu-right" use:links>
              <a href="javascript:void(0)" class="dropdown-item details" on:click={()=>
                editAnalyticalCategory(analyticalCategory._id)}><i data-feather="edit" />Edit</a>
              <a href="javascript:void(0)" on:click={()=> deleteAnalyticalCategory(analyticalCategory._id)}
                class="dropdown-item details"><i data-feather="trash" />Delete</a>
            </nav>
          </div>
        </div>
      </div>
      {/each}
    </div>
    {:else}
    <Empty mode={search.value ? 'search' : 'empty' } />
    {/if}
  </div>
</div>

<!-- Modal -->
<div class="modal effect-scale" bind:this={addNewAnalyticalCategoryModal} id="modal-new-batch" tabindex="-1"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mx-wd-sm-650" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header pd-b-0 pd-r-25">
        <div class="media align-items-center pd-12">
          <span class="tx-color-04"><i data-feather="server" class="wd-60 ht-60" /></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">
              {analyticalCategoryObj._id ? 'Edit Analytical Category Details' : 'Analytical category'}
            </h4>
            <p class="tx-13 tx-color-03 mg-b-0">Create new analytical category</p>
          </div>

        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span><br />
        </button>
      </div>
      <div class="modal-body">
        <div class="row no-gutters pd-t-8 pd-12">
          <div class="col-12 bg-white rounded-right">

            <div class=" tx-color-02">
              <div class="form-group">
                <label class="d-block">Name
                  <span class="required-field">*</span></label>
                <input bind:value={analyticalCategoryObj.name} on:keyup={(e)=> e.key === 'Enter' && submitForm(e)}
                type="text"
                class="form-control first-input"
                id="course-name"
                class:parsley-error={errorObj.name}
                placeholder="Enter name" />
                {#if errorObj.name}
                <div class="parsley-errors-list">{errorObj.name}</div>
                {/if}
              </div>
              <div class="form-group">
                <label class="d-block" for="analytical-category-color">Color<span
                    class="required-field">*</span></label>
                <div class="color-picker-container">
                  <input type="color" bind:this={elem_picker} bind:value={analyticalCategoryObj.color}
                    on:change={function (event) { console.log(event.target.value); // #ff0000 //
                    themeChanged(event.target.value); }} />
                </div>
                {#if errorObj.color}
                <div class="parsley-errors-list">{errorObj.color}</div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer pd-t-0">
        <div class="col-12 d-flex justify-content-end pd-12">
          <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CANCEL
            <i data-feather="x" class="wd-10 mg-l-5" />
          </button>
          <button type="button" disabled={isLoading} on:click={submitForm}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <ButtonLoader {isLoading} btnText="Proceed" />
            <i data-feather="arrow-right" class="wd-10 mg-l-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
