<script>
  import {
    Router,
    links,
    Link,
    Route,
    navigate
  } from "svelte-routing";
  import {
    onMount,
    tick,
    afterUpdate
  } from 'svelte';
  import {
    activeMenu
  } from "../../stores";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js"
  import { API_URL } from "../../../config.js"


  let isLoading = false;
  let demoDataTable;
  let studentDataTable;
  let emptyState = false;
  let filterModalThis;
  let filterElemBatch
  let filterElemBatch1
  let submissionTimeFrom
  let submissionTimeTo
  let timeFrom
  let timeTo
  let filterByPrefix
  let filterStudentName
  let submissionTime
  let submissionDateFrom
  let submissionDateTo
  let selectizeBatchInstance;
  // let selectizeBatchInstance1;
  let batchesList
  // let userIdArr
  let assignmentObj = {
    topic: "",
  }
  let search = {
    value: ""
  };
  let assignmentList = []
  let errorObj = {}
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  }
  const pageSize = 10;
  let length = pageSize;

  async function bindSelect2() {

    selectizeBatchInstance = _$(filterElemBatch).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "select batches",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: batchesList,
    });

    // selectizeBatchInstance1 = _$(filterElemBatch1).selectize({
    //   preload: true,
    //   create: false,
    //   maxItems: null,
    //   valueField: "_id",
    //   labelField: "name",
    //   searchField: "name",
    //   placeholder: "select student",
    //   delimiter: ",",
    //   plugins: ["remove_button"],
    //   persist: false,
    //   // options: userIdArr,
    // });


    _$(submissionTimeFrom).timepicker({
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      // template: "modal",
      startTime: "12:00 AM",
      change: async function (time) {
        if (time) {
          timeFrom = new Date(time);
        } else {
          timeFrom = null;
        }
        await tick();
      },
    });
    _$(submissionTimeTo).timepicker({
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      // template: "modal",
      startTime: "12:00 AM",
      change: async function (time) {
        if (time) {
          timeTo = new Date(time);
        } else {
          timeTo = null;
        }
        await tick();
      },
    });
  }

  function clearFilter() {
    selectizeBatchInstance[0].selectize.clear();
    // selectizeBatchInstance1[0].selectize.clear();

    _$(submissionTimeFrom).timepicker("setTime", null);
    _$(submissionTimeTo).timepicker("setTime", null);
    submissionTime = null;
    submissionDateFrom = null;
    submissionDateTo = null;
    filterStudentName = null;

    redrawAssignmentTable();
  }

  function applyFilter() {
    redrawAssignmentTable();
  }

  function redrawAssignmentTable() {
    if (studentDataTable) { studentDataTable.draw() }
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.topic = order;
    } else if (sortOrder.column === 2) {
      sort.createdOn = order;
    } else if (sortOrder.column === 3) {
      sort.submissionDate = order;
    } else if (sortOrder.column === 4) {
      sort.submissionTime = order;
    } else {
      sort._id = order;
    }
    return sort;
  }

  let validateAssignment = () => {
    errorObj = {};
    if (!assignmentObj.topic) {
      var newErrorObj = {};
      if (!assignmentObj.topic) {
        newErrorObj.topic = "Assignment topic is required Field.";
      }
      errorObj = newErrorObj;

    }
    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;


  }

  function clearFields() {
    assignmentObj = {
      topic: "",
    }
  }

  let addUpdateAssignmentBtn = async () => {
    if (isLoading) return;
    isLoading = true;
    await tick();

    try {
      if (validateAssignment()) {
        var assignmentRes = await api_addUpdateAssignment(assignmentObj);
        if (assignmentRes.status) {
          // create success
          hideModel()
          PNotify.success({
            title: 'Success',
            text: assignmentRes.data ? assignmentRes.data[0] : "Assignment added successfully.",
            delay: 3000
          });
          navigate(("assignment/edit/" + assignmentRes.data[1]));
        } else {
          // Handle actual error cases
          PNotify.error({
            title: 'Error',
            text: assignmentRes.errMsg || 'An error occurred while processing your request.',
            delay: 3000
          });
        }
      }
    } catch (error) {
      PNotify.error({
        title: 'Unexpected Error',
        text: error.message || 'An error occurred while processing your request.',
        delay: 3000
      });
      console.error("Error while saving the assignment:", error);
    } finally {
      isLoading = false;
    }
    return false;
  };




  async function api_addUpdateAssignment(data) {
    const action = "assignment.addUpdateAssignment";
    try {
      const response = await api_handleApiCalls(action, data);

      // Validate response structure
      if (response && typeof response === 'object') {
        return response;
      } else {
        throw new Error('Invalid response from API');
      }
    } catch (error) {
      console.error('API call failed:', error);
      return { status: false, errMsg: 'Failed to update assignment. Please try again.' };
    }
  }






  function hideModel(params) {
    _$("#assignment-add-modal").modal("hide");
    assignmentObj.topic = "";
  }
  function showModel(params) {
    _$("#assignment-add-modal").modal("show");
  }

  function hideFilterModel(params) {
    _$("#filter-modal").modal("hide");
    assignmentObj.topic = "";
  }
  function showFilterModel(params) {
    _$("#filter-modal").modal("show");
  }

  async function api_getBatchesList() {
    var resData = await api_handleApiCalls('batch.getMyBatches', {})
    if (resData.status) {

      return resData.data
    } else {
      return []
    }
  }

  //   async function api_getStudentsList() {

  // // let action = 'user.getAllUsers';
  // // data =  {
  // //     start: 0,
  // //     length: 10,
  // //     search: data.search
  // //   };
  // var response = await api_handleApiCalls('user.getAllUsers', {})
  // return response

  // }

  // async function api_getStudentsList() {
  //   var resData = await api_handleApiCalls('students.list', {})
  //   if (resData.status) {

  //     return resData.data.data
  //   } else {
  //     return []
  //   }
  // }

  onMount(async () => {
    activeMenu.set('assignment')
    // _$('[data-toggle="tooltip"]').tooltip();
    feather.replace();
    batchesList = await api_getBatchesList()
    // userIdArr = await api_getStudentsList()
    bindSelect2();
    bindDatatable();
  });



  async function deleteBtn(id) {
    let response = await api_deleteAssigment(id);
    if (response.status) {
      if (studentDataTable) { studentDataTable.draw() }
      pSuccess(null, response.data)
    } else {
      pError("Error", response.errMsg)
    }
  }

  async function api_deleteAssigment(id) {

    let action = "assignment.delete";
    var response = await api_handleApiCalls(action, { _id: id });
    return response;

  }
  function getDateWithTimeNextFollowup(date, time) {
    try {

      let thisDate = new Date(date)
      let thisTime = new Date(time)

      // if(thisTime.getHours() == 0) return null
      thisDate.setHours(thisTime.getHours(), thisTime.getMinutes(), 0, 0)

      return thisDate
    } catch (error) {
      return null
    }
  }

  function getFilterData() {
    let batchesList = _$(selectizeBatchInstance).val()
    // let userIdArr = _$(selectizeBatchInstance1).val()

    let dateFrom
    let dateTo
    if (submissionDateFrom != null) {
      submissionDateFrom = new Date(submissionDateFrom)
      dateFrom = getDateWithTimeNextFollowup(submissionDateFrom, timeFrom)
      submissionDateFrom = dateFrom
    }

    if (submissionDateTo != null) {
      submissionDateTo = new Date(submissionDateTo)

      dateTo = getDateWithTimeNextFollowup(submissionDateTo, timeTo)
      submissionDateTo = dateTo
    }

    // submissionDateFrom = dateFrom
    // submissionDateTo  = dateTo
    return {
      batchesList,
      // userIdArr,
      submissionDateFrom,
      submissionDateTo,
      filterStudentName,
      submissionTime
    }
  }


  function bindDatatable() {
    studentDataTable = _$(demoDataTable).DataTable({
      responsive: true,
      language: {
        processing: "",
        searchPlaceholder: 'Topic',
        sSearch: '',
        lengthMenu: '_MENU_ Assignment/page',
      },
      ajax: function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        var filterObj = { ...getFilterData() }
        filterObj.start = data.start
        filterObj.length = data.length
        filterObj.search = data.search.value
        filterObj.draw = data.draw
        filterObj.sort = data.sort;

        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: 'assignment.list',
            accessToken: localStorage.getItem("accessToken"),
            data: filterObj,
          },
          success: function (resData) {
            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data
              }
              if (resData.data.data == 0) {
                emptyState = true
              }
              callback(result);
            }
            else {
              emptyState = true
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },
      columns: [
        {
          data: 'topic',
          defaultContent: '<i>No data found<i>',
          render: function (data, type, full) {
            return `<div class="btn-edit-assignment tx-capitalize" data-id="${full._id}"  style="max-height: 38px !important; overflow:hidden; cursor: pointer;">
               ${data}
              </div>`
          }

        },
        {
          data: 'batchArr',
          defaultContent: '-',
          render: (data, type, full) => {
            var htmlstr = '';
            if (!full.batchArr.length) {
              return "-";
            }
            for (let j = 0; j < full.batchArr.length; j++) {
              let assignedBatch = full.batchArr[j]

              let batch = data.filter(item => item.name == assignedBatch.name)
              if (batch) {
                htmlstr += `
                    <div class="badge badge-secondary tx-capitalize" style="font-size:12px;margin-right:4px;margin-bottom:4px; text-align: center;">${batch[0].name}</div>
                    `;
              }

            }

            return htmlstr

          }
        },
        // { data: 'mobileNo', defaultContent : '<i>No data found<i>' },
        {
          data: 'createdOn',
          render: function (data, a, b, c) {
            let d = new Date(data)
            let date = moment(d).format('DD/MM/YYYY');
            return `<div class="d-flex justify-content-center">${date}</div>`

          }
        },
        {
          data: "_id",
          render: function (data, type, full) {
            let d
            if (full.publishDate) {
              d = new Date(full.publishDate);
              return `<div class="d-flex justify-content-center">${moment(d).format("DD/MM/YYYY")}</div>`
            }
            else {
              return `<div class="d-flex justify-content-center">-</div>`
            }
          },
        },
        {
          data: 'submissionDate',

          render: function (data, a, b, c) {

            let date = moment(data).utcOffset("+05:30").format('DD/MM/YYYY')
            return `<div class="d-flex justify-content-center">${date}</div>`
          }
        },
        {
          data: 'submissionTime',
          render: function (data, a, b, c) {
            if (!data) {
              return `<div class="d-flex justify-content-center">-</div>`
            }
            let d = new Date(data)
            let date = moment(d).format("LT");//LT
            return `<div class="d-flex justify-content-center">${date}</div>`

          }
        },
        // { data: ,                  },
        {
          data: '_id', render: function (data, a, b, c) {

            var edit = `<a href="#" class="rename btn-edit-assignment mg-r-5" data-id="${data}" data-toggle="tooltip" data-placement="bottom" title="Edit"><i data-feather="edit" class="wd-20 mg-r-5"></i></a>`
            var del = `<a href="#" class="delete btn-remove-assignment mg-r-5" data-id="${data}" data-toggle="tooltip" data-placement="bottom" title="Delete"><i data-feather="trash" class="wd-20 mg-r-5"></i></a>`
            var view = `<a href="#" class="delete btn-view-progress mg-r-5" data-id="${data}" data-toggle="tooltip" data-placement="bottom" title="View assignment Progress of students"><i data-feather="arrow-up-right" class="wd-20 mg-r-5"></i></a>`
            if (MODULES.hasAccess(MODULES.VIEW_ASSIGNMENT) && !(MODULES.hasAccess(MODULES.DELETE_ASSIGNMENT))) {
              return `
              <div class="d-md-flex flex-row justify-content-center">
                  ${edit}
              </div>
              `;
            } else if (MODULES.hasAccess(MODULES.DELETE_ASSIGNMENT) && !(MODULES.hasAccess(MODULES.VIEW_ASSIGNMENT))) {
              return `
              <div class="d-md-flex flex-row justify-content-center">
                  ${del}
              </div>
              `;
            } else if (!(MODULES.hasAccess(MODULES.VIEW_ASSIGNMENT)) && !(MODULES.hasAccess(MODULES.DELETE_ASSIGNMENT))) {
              return `
              <div class="d-md-flex flex-row justify-content-center">
                -
                </div>
              `;
            } else {
              return `
              <div class="d-md-flex flex-row justify-content-center">
                  ${edit}
                  ${del}
                  ${view}
              </div>
              `;
            }


          }
        },

      ],
      "drawCallback": function () {
        feather.replace({ width: "1em", height: "1em" });

        _$('.btn-edit-assignment').unbind('click').click(function (e) {
          e.preventDefault();
          var assignmentId = _$(this).attr("data-id");

          navigate(("assignment/edit/" + assignmentId))

        })
        _$('.btn-remove-assignment').unbind('click').click(function (e) {
          e.preventDefault();
          var assignmentId = _$(this).attr("data-id");
          pConfirm("Delete Assignment", "Are you sure you want to delete assignment?",
            function () { deleteBtn(assignmentId) }
          )

        })
        _$('.btn-view-progress').unbind('click').click(function (e) {
          e.preventDefault();
          var assignmentId = _$(this).attr("data-id");
          navigate(("assignment/" + assignmentId + "/assignmentprogress"))


        })
      },
      "processing": true,
      "serverSide": true,
    });
    _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
  }

  let openAddAssignment = () => {
    clearFields();
    _$('#assignment-add-modal').modal('show');
  }

  let showHideFilterModal = () => {
    clearFields();
    _$('#filter-modal').modal('show');
  }

</script>

<div class="content-body fadeIn">
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Assignment
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        Assignment
      </h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search given assignments or add new assignment.
      </p>
    </div>
    <div class="d-block">
      {#if MODULES.hasAccess(MODULES.ADD_ASSIGNMENT)}
      <button on:click={openAddAssignment} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
        <i data-feather="plus" class="wd-10 mg-r-5"></i> Add new Assignment
      </button>
      {/if}
      <button on:click={showHideFilterModal} class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"> <i
          data-feather="filter" class="mg-r-5"></i> Filters</button>
    </div>
  </div>
  <hr class="full-width">
  <div data-label="Example" class="df-example demo-table">
    <table bind:this={demoDataTable} class="table" style="width:100%">
      <thead>
        <tr>
          <th style="width: 25%;">Topic</th>
          <th class="wd-25p">Batch</th>
          <!-- <th class="wd-25p">Contact Number</th> -->
          <th style="text-align: center; width: 15%;">Added On</th>
          <th style="text-align: center; width: 15%;">Publish On</th>

          <th style="text-align: center; width: 15%;">Submission Date</th>
          <th style="text-align: center; width: 15%;">Submission Time</th>
          <th style="text-align: center; width: 10%;">Actions</th>
        </tr>
      </thead>
      <tbody></tbody>

    </table>
    {#if emptyState}
    <div class="d-flex align-items-center justify-content-center " style="flex-direction:column;">
      <div class="" style="width:250px">
        <img src="/images/empty.svg" class="img-fluid" alt="No Result" />
        <p class=" mg-b-0 tx-color-03" style="text-align:center;">No Assignment found.</p>
      </div>
      <!-- <div class="" style="text-align:center;">Click <a href="/dashboard/students/info/{studentId}/batch-info" use:links class="">here</a> to assign a batch and enable payments</div> -->
    </div>
    {/if}
  </div>
</div>






<div class="modal fade" id="assignment-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="file-text" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Assignment</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Add new assignment</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->
      <!-- <hr class="full-width"> -->
      <div class="modal-body">
        <div class="form-row pd-12 pd-t-7 pd-b-7">
          <div class="form-group col-md-12">
            <label class="d-block">Topic<span class="required-field">*</span></label>
            <input bind:value={assignmentObj.topic} type="text" on:keyup={e=> e.key === 'Enter' &&
            addUpdateAssignmentBtn(e)}
            on:blur={()=>clearError("{assignmentObj.topic}")}
            class="form-control" class:parsley-error={errorObj.topic}
            placeholder="Enter topic name"/> {#if errorObj.topic}
            <div class="parsley-errors-list">{errorObj.topic}</div>
            <!-- content here -->
            {/if}
          </div>
        </div>
      </div>
      <div class="modal-footer pd-t-0">
        <div class="pd-12">
          <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal"> CLOSE
            <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button type="button" on:click={addUpdateAssignmentBtn}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" disable={isLoading}>
            <ButtonLoader isLoading={isLoading} btnText="SAVE" />
            <i data-feather="save" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->

      <hr class="full-width">
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
        <div class="form-group">
          <label class="d-block">Select Batch:</label>
          <select disabled={isLoading} bind:this={filterElemBatch} class="form-control select2" multiple="multiple">
            <option>Select batches</option>
          </select>
        </div>
        <div class="form-group">
          <label class="d-block">Select student:</label>
          <select disabled={isLoading} bind:this={filterElemBatch1} class="form-control select2" multiple="multiple">
            <option>Select student</option>
          </select>
        </div>
        <div class="form-row ">
          <div class="form-group col-md-6">
            <label class="d-block">Submission Date from:</label>
            <input type="date" onkeydown="return false" bind:value={submissionDateFrom}
              class="form-control follow-up-date" placeholder="Submission Date from" />
          </div>
          <div class="form-group col-md-6">
            <label class="d-block">Submission Time from:</label>
            <input type="text" onkeydown="return false" bind:this={submissionTimeFrom}
              class="form-control follow-up-date" placeholder="Submission Date from" />
          </div>

          <div class="form-group col-md-6">
            <label class="d-block">Submission Date to:</label>
            <input type="date" onkeydown="return false" bind:value={submissionDateTo}
              class="form-control follow-up-date" placeholder="Submission Date to" />
          </div>

          <div class="form-group col-md-6">
            <label class="d-block">Submission Time to:</label>
            <input type="text" onkeydown="return false" bind:this={submissionTimeTo} class="form-control follow-up-date"
              placeholder="Submission Date to" />
          </div>
        </div>






        <div class="d-md-flex justify-content-end mg-t-25">
          <button disabled={isLoading} class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" on:click={clearFilter}
            data-dismiss="modal">CLEAR
            <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button disabled={isLoading} class="btn btn-sm pd-x-15 btn-primary mg-l-5" on:click={applyFilter}
            data-dismiss="modal">
            <ButtonLoader isLoading={isLoading} btnText="APPLY" />
            <i data-feather="filter" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Modal -->
