<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, onDestroy, tick, afterUpdate } from "svelte";
  import { pSuccess, pError, pAlert } from "../../utils/notify.svelte";
  import ImageUploader from "../../utils/imageUploader.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js";
  import Cleave from "cleave.js";
  import "cleave.js/dist/addons/cleave-phone.in";
  onMount(function () {
    console.log("check check");
  });
  export let id;
  // let overrideAccessProfileModal;
  let elem_mobileNoInput, mobileNoCleave;
  let isLoadingSave;
  let displayTitle = "My Profile";
  let breadCrumbTitle;
  let roleIsStudent;
  let firstInputField;
  let mobileNoInput;
  let onKeyPress = (e) => {
    if (e.charCode === 13) submitForm();
  };
  let systemUserDetails = {
    // _id: "",
    name: "",
    profilePic: "",
    mobileNo: "",
    email: "",
  };
  var errorDetails = {};

  async function clearValidations() {
    var keys = Object.keys(errorDetails);
    keys.map((key) => delete errorDetails[key]);
    await tick();
  }

  async function handleUpdateProfilePic() {
    if (systemUserDetails.profilePic) {
      let result = await api_handleApiCalls("systemUser.setProfilePic", {
        userId: systemUserDetails._id,
        profilePic: systemUserDetails.profilePic,
      });
      console.log("setProfilePic", result);
      if (result.status) {
        pSuccess("", result.data);
      } else {
        pError("", result.errMsg);
      }
    } else {
      pError("Error!", "Failed to update the profile picture.");
    }
  }
  async function api_profileparam(systemUserDetails) {
    let action = "myProfile.myProfile";
    var response = await api_handleApiCalls(action, systemUserDetails);
    return response;
  }
  async function api_getProfileById(_id) {
    let action = "myProfile.getProfileById";
    var response = await api_handleApiCalls(action, { _id: _id });
    return response;
  }
  async function api_save(data) {
    let action = "myProfile.updateprofile";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function submitForm(event) {
    // event.preventDefault();
    if (!isLoadingSave) {
      isLoadingSave = true;
      await tick();
      const isValid = validate();
      console.log({ isValid, errorDetails });
      if (isValid) {
        console.log("updating", { systemUserDetails, errorDetails });
        let result = await api_save(systemUserDetails);
        if (result.status) {
          pSuccess("", "System user details updated successfully");
        } else {
          pError(result.errMsg);
        }
      }
      isLoadingSave = false;
      await tick();
    }

    function validate() {
      //   clear previous validations before validating anew
      clearValidations();

      var regexpAlphabetOnly = new RegExp(`^[A-Za-z]+$`);
      var regexpName = new RegExp(`^[A-Za-z\\s]+$`);
      var regexpMobileNumber = new RegExp(`^[6-9]{1,1}[0-9]{9,9}$`);
      var regexpEmail = new RegExp(`^[\\w-\\.]+@([\\w-]+\\.*)+\\.[\\w-]{2,4}$`);
      // name
      systemUserDetails.name = systemUserDetails.name.trim();
      if (!Boolean(systemUserDetails.name)) {
        errorDetails.name = "Full Name can not be empty";
      } else if (!systemUserDetails.name.match(regexpName)) {
        errorDetails.name =
          "Full Name must have only A-Z or a-z characters and space";
      } else if (systemUserDetails.name.length < 3) {
        errorDetails.name = "Full Name should be minimum 3 characters long";
      } else if (systemUserDetails.name.length > 80) {
        errorDetails.name = "Full Name should not exceed 80 characters long";
      }

      // mobilenumber
      systemUserDetails.mobileNo = systemUserDetails.mobileNo;
      if (!Boolean(systemUserDetails.mobileNo)) {
        errorDetails.mobileNo = "Mobile Number can not be empty";
      } else if (!`${systemUserDetails.mobileNo}`.match(regexpMobileNumber)) {
        errorDetails.mobileNo = "Invalid Mobile Number";
      } else if (`${systemUserDetails.mobileNo}`.length < 10) {
        errorDetails.mobileNo = "Mobile Number should be 10 digits long";
      } else if (`${systemUserDetails.mobileNo}`.length > 10) {
        errorDetails.mobileNo = "Mobile Number should be 10 digits long";
      }

      // email
      if (Boolean(systemUserDetails.email)) {
        systemUserDetails.email = systemUserDetails.email.trim();
        if (!systemUserDetails.email.match(regexpEmail)) {
          errorDetails.email = "Invalid Email Address";
        }
      }

      if (Object.values(errorDetails).length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }
  onMount(async function () {
    let response = await api_profileparam();
    if (response.status) {
      systemUserDetails = response.data;
      roleIsStudent = systemUserDetails.role == "student";
      console.log("systemUserDetails", systemUserDetails);
    }
    //  let getProfileDetails = await api_getProfileById(response.data._id);
    mobileNoCleave = new Cleave(elem_mobileNoInput, {
      phone: true,
      phoneRegionCode: "IN",
      onValueChanged: (e) => {
        systemUserDetails.mobileNo = e.target.rawValue;
        console.table({ mobileNo: e.target.rawValue });
      },
    });
    mobileNoCleave.setRawValue(systemUserDetails.mobileNo);

    await tick();
    feather.replace();
  });
</script>

<div class="content-body fadeIn">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/myprofile">My Profile</a>
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1 tx-capitalize">{displayTitle}</h4>
      <p class="tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
        User's profile information
      </p>
    </div>
  </div>
  <hr class="full-width" />
  <!-- form inputs -->
  <div class="mg-b-20 scroll0 pos-relative ht-100p">
    <div class="row row-sm mg-b-10">
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 col-xl-7 mg-b-10">
        <div class="form-row mg-b-10">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Personal Information
            </h6>
            <hr style="margin-top: 5px" />
          </div>
          <div class="col-md-12 form-group">
            <ImageUploader bind:profilePic="{systemUserDetails.profilePic}" on:updatePic="{handleUpdateProfilePic}">
              <div slot="upload-container" let:src let:openCroppie="{croppieOpen}" let:openCamera="{cameraOpen}">
                <div id="contactAvatar" class="pos-relative float-left">
                  <div class="avatar avatar-xl">
                    <img {src} alt="profile-pic" />
                  </div>
                  <a href="#" on:click|preventDefault class="contact-edit-photo" data-toggle="dropdown"
                    title="Upload Photo">
                    <i data-feather="edit-2" /></a>
                  <ul class="dropdown-menu filterByDropdown">
                    <li class="dropdown-item" on:click="{croppieOpen}">
                      <i data-feather="image" class="mg-r-5" />Uplaod Image
                    </li>
                    <li class="dropdown-item" on:click="{cameraOpen}">
                      <i data-feather="camera" class="mg-r-5" />Take Picture
                    </li>
                  </ul>
                </div>
              </div>
            </ImageUploader>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label class="d-block" for="name">Full Name <span class="required-field">*</span></label>
            <input type="text" class="form-control" id="name" aria-label="Full name" placeholder="Enter full name"
              on:keypress="{onKeyPress}" bind:this="{firstInputField}" bind:value="{systemUserDetails.name}"
              on:keypress="{onKeyPress}" class:parsley-error="{errorDetails.name}" />
            {#if errorDetails.name}
            <div class="parsley-errors-list">{errorDetails.name}</div>
            {/if}
          </div>

          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label class="d-block" for="mobile">Mobile Number <span class="required-field">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">IN (+91)</span>
              </div>
              <input type="text" class="form-control" on:keypress="{onKeyPress}" bind:this="{elem_mobileNoInput}"
                id="mobile" placeholder="Enter mobile number" class:parsley-error="{errorDetails.mobileNo}" />
            </div>
            {#if errorDetails.mobileNo}
            <div class="parsley-errors-list col-sm-6 col-md-6 col-lg-6">
              {errorDetails.mobileNo}
            </div>
            {/if}
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label class="d-block" for="email">Email</label>
            <input on:keypress="{onKeyPress}" bind:value="{systemUserDetails.email}" type="text" class="form-control"
              id="email" class:parsley-error="{errorDetails.email}" placeholder="Enter email" />
            {#if errorDetails.email}
            <div class="parsley-errors-list">{errorDetails.email}</div>
            {/if}
          </div>
        </div>
        {#if !roleIsStudent}
        <div class="form-group col-sm-12 col-md-12 col-lg-12 pd-l-0">
          {#if MODULES.hasAccess(MODULES.UPDATE_MY_PROFILE)}
          <button on:click="{submitForm}" disabled="{isLoadingSave}" class="btn btn-sm btn-primary btn-uppercase">
            <ButtonLoader isLoading="{isLoadingSave}" btnText="Update" />
            <i data-feather="arrow-right" class="wd-10" />
          </button>
          {/if}
        </div>
        {/if}
      </div>
    </div>
  </div>

  <!-- Modal New AccessProfile -->
</div>
