<script>
    import { Router, Link, links, Route } from "svelte-routing";

    import { onMount, tick } from "svelte";


    let title = "";

    export let selectedId = "";
    export let sptObject = {};
    let sptList = [];

    let mode = "create";

    let setSelected = function (aSptItem) {
        selectedId = aSptItem._id;
        sptObject = aSptItem;
        title = aSptItem.title;
        mode = "edit";
        CKEDITOR.instances.essaySPT.setData(aSptItem.content);
    }

    async function api_list(data) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var payload = JSON.stringify({
            action: "spt.list",
            accessToken: localStorage.getItem("accessToken"),
            data: data,
        });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: payload,
        };
        try {
            var resObj = await fetch(API_URL, requestOptions);
            var response = await resObj.json();
            return response;
        } catch (error) {
            console.error("error", error);
            return { error: error };
        }
    }
    async function api_addUpdate(data) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var payload = JSON.stringify({
            action: "spt.addUpdate",
            accessToken: localStorage.getItem("accessToken"),
            data: data,
        });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: payload,
        };
        try {
            var resObj = await fetch(API_URL, requestOptions);
            var response = await resObj.json();
            return response;
        } catch (error) {
            console.error("error", error);
            return { error: error };
        }
    }


    let onSave = async function () {
        let content = CKEDITOR.instances.essaySPT.getData();
        if (!title.trim()) return;
        if (!content.trim()) return;
        var data = {};
        if (selectedId) data._id = selectedId;
        data.title = title;
        data.content = content;
        let res = await api_addUpdate(data)
        let resList = await api_list();

        sptList = resList.data.result;

        if(res.data?.insertedId){
            selectedId = res.data.insertedId;
            mode = "edit";

            sptObject = {
                _id: selectedId,
                title: title,
                content: content
            };
        }
        

    }

    let toolbar = [
        [
            "NewPage",
            "Preview",
            "-",
            "Bold",
            "Italic",
            "Strike",
            "NumberedList",
            "BulletedList",
            "-",
            "Table",
            "HorizontalRule",
            "Styles",
            "Format",
            "Maximize",
            "Image"
        ]
    ]

    onMount(async function () {

        let res = await api_list();
        console.log("res : ", res);

        


        sptList = res.data.result;

        feather.replace();
        
         new PerfectScrollbar('.slist', {
            suppressScrollX: true
        });
         new PerfectScrollbar('.e-spt', {
            suppressScrollX: true
        });
        
        await tick();

        CKEDITOR.instances['essaySPT']?.destroy(false);

        CKEDITOR.replace('essaySPT', {
            toolbar: toolbar,
            height: 385
        });

    })

</script>

<div class="row no-gutters" style="height: 100%; ">
    <div class="col-sm-12 col-md-4"
        style=" border-right: 1px solid #ddd;display: flex;    flex-direction: column; background-color: #f8f9fc; 
    height: 100%;">
        <div class="spt-header">
            <i data-feather="search"></i>
            <div class="search-form">
                <input type="search" class="form-control" placeholder="Search Essay">
            </div><!-- search-form -->
            <div>
                <button style="background: transparent; border: none;outline: none !important;" on:click={()=>{
                    title = "";
                    selectedId = "";
                    CKEDITOR.instances.essaySPT.setData("");
                    mode = "create"}} class="nav-link"><i data-feather="plus" style="height: 15px;"></i> New
                </button>

            </div>
        </div>

        <div style="flex: 1;" class="slist">
            <ul class="list-unstyled media-list mg-b-0">

                {#each sptList as item}
                    <li class="media unread spt-list" class:active={selectedId == item._id} on:click={()=>setSelected(item)}>
                        <div class="media-body mg-l-15">
                            <h6 class="tx-13">{item.title}</h6>
                            <p class="tx-12 tx-color-03 mg-b-0">{item.content}</p>
                        </div><!-- media-body -->
                    </li>
                {/each}
            </ul>
        </div>

    </div>
    <div class="col-sm-12 col-md-8 e-spt" style="padding-top:56px;
    height: 100%;">
        <div class="conten-header" style="display: flex;justify-content: space-between;    z-index: 1; ">

            <input type="text" class="form-control title" bind:value={title} placeholder="Enter Essay Name">

            <button style="background: transparent; border: none;outline: none !important;" class="nav-link"
                on:click={onSave}
            ><i
                    data-feather="save" style="height: 15px;"></i> {(mode == "create" ? "Create"  : "Update")}</button>
            <button style="background: transparent; border: none;outline: none !important;" class="nav-link"><i
                    data-feather="trash" style="height: 15px;"></i> Delete</button>
        </div>
        <textarea class="form-control" name="essaySPT" rows="3" placeholder="Describe this test"></textarea>
    </div>
</div>


<style>
    .spt-header {
        height: 55px;
        background-color: #fff;
        border-bottom: 1px solid rgba(72, 94, 144, 0.16);
        display: flex;
        align-items: center;
        padding: 0 20px;
    }

    .spt-header .search-form .form-control {
        border-width: 0;
        background-color: transparent;
        font-size: inherit;
        padding: 0;
        color: #1b2e4b;
    }

    .title{
        border:  none;
        outline: none;
        box-shadow: none;
    }
    .title:focus{
        box-shadow: none !important;
    }
    .title:hover{
        box-shadow: 0 0 0 0.2rem rgba(1,104,250,0.25) !important;
    }

    .spt-header .search-form {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .spt-list{
            cursor: pointer;    
            padding: 15px 20px;
            cursor: pointer;
            border-left: 4px solid #f8f9fc;
            z-index: 1000;
            border-bottom: 1px solid #e5e9f2;
    }

    .spt-list:hover{
        border-left-color: #0153c7;
    }

    .spt-list.active{
        border-left-color: #0168fa;
        background-color: #ecf4ff;
    }


    .conten-header{
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 55px;
        border-bottom: 1px solid rgba(72,94,144,0.16);
        display: flex;
        align-items: center;
        padding: 0 20px;
    }

</style>
