<script>
    import { Router, Link, Route, navigate, links } from "svelte-routing";
    import { onMount, tick, afterUpdate, } from "svelte";
    import { userData, fileExplorerFilter } from "../../stores";
    import moment from "moment"
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import ButtonLoader from "../../utils/button_loader.svelte";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import SyncSubject from '../syncSubjectCmp.svelte'
    import MODULES from "../../utils/modules.js"
    import { onDestroy } from 'svelte';

    import { API_URL } from "../../../config.js"
    export let idList = [];

    $: idList, console.log("idList $::", idList)
    let isLoadingSaveAndProceed = false;
    let elem_uploadImageInput = "";
    let elem_addImageInput = ""
    let icon;
    let iconurl;
    let previewVideoSrc
    let folderObj = { name: "", icon: "" };
    let editfolderObj = { name: "", _id: null, icon: "" };
    let modalVideoPreview
    let vidPreview
    let linkArr = []
    var imgarrdata = [];
    let _fileId
    let _batchId
    let _parentId
    let fileFolderCount = [];
    let currentFileObj = {};
    var lastIdList = idList;
    let childrenList = [];
    let fileList = [];
    let name;
    let pdfName;
    let imageName;
    let lectureName;
    let isLoading;
    let errorObj = {};
    let elem_addNewLiveLectureModel;
    let liveLectureName;
    let lecture_type;
    let elemType;
    let _id;
    let elem_addNewyoutubeLectureModel;
    let youtubeLectureName;
    let externalLinkName;
    let urlToDrive;
    let refName;
    let refUrl;
    let selectedFolder = [];
    var parentId = idList[idList.length - 1];
    let loggedInUserId
    const subscribeToStore = userData.subscribe(value => {
        loggedInUserId = value._id
    });
    let isEdit = false

    let showModalVideoPreview = async (file) => {
        let res = await api_handleApiCalls("studyMaterial.getDownloadUrl", { _id: file._id })
        previewVideoSrc = res.data.downloadUrl
        await tick()
        _$(modalVideoPreview).modal('show')

    }

    async function api_getChildren(data) {

        let action = "folder.getChildren";
        var response = await api_handleApiCalls(action, data);
        return response.data;

    }

    async function api_createNode(data) {
        let action = "folder.createNode";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_deleteFolder(data) {

        let action = "folder.deleteFolder";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_editFolder(data) {
        let action = "folder.editFolder";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_addPdf(data) {

        let action = "studyMaterial.addUpdatePdf";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_addFileLinkToFolder(data) {

        let action = "files.addFileLinkToFolder";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_addImage(data) {

        let action = "studyMaterial.addUpdateImage";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_addUpdateRecordedLecture(data) {

        let action = "studyMaterial.addUpdateRecordedLecture";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_addUpdateLiveLecture(data) {

        let action = "studyMaterial.addUpdateLiveLecture";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_addUpdateYoutubeLecture(data) {

        let action = "studyMaterial.addUpdateYoutubeLecture";
        var response = await api_handleApiCalls(action, data);
        return response;

    }


    async function api_addExternalLink(data) {

        let action = "studyMaterial.addUpdateExternalLink";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_addUpdateRefLink(data) {

        let action = "studyMaterial.addUpdateRefLink";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    function getColorByFile(fileObj) {
        const typeRouting = {
            "pdf": "tx-danger",
            "image": "tx-indigo",
            "externalLink": "tx-gray-600",
            "refLink": "tx-gray-600",
            "liveLecture": "tx-primary",
            "recordedLecture": "tx-pink",
            "youtubeLecture": "tx-danger",
        };
        return typeRouting[fileObj.type];
    }

    function getTypeByFile(fileObj) {
        const typeRouting = {
            "pdf": "pdf",
            "image": "images",
            "externalLink": "externalLink",
            "refLink": "refLink",
            "liveLecture": "liveLecture",
            "recordedLecture": "recordedLecture",
            "youtubeLecture": "youtubeLecture",
        };
        return typeRouting[fileObj.type];
    }

    function getIconByFile(fileObj) {
        const typeRouting = {
            "pdf": "pdf",
            "image": "png",
            "externalLink": "ai",
            "refLink": "ai",
            "liveLecture": fileObj.lecture_type,
            "recordedLecture": "mp4",
            "youtubeLecture": "youtube"
        };
        // tx-indigo
        return typeRouting[fileObj.type];
    }

    let performDeleteOps = async function (folderId) {
        pConfirm(
            "Delete Folder?",
            "Please ensure you remove all items/folder inside this folder",
            async function () {
                let response = await api_deleteFolder({
                    folderId
                })
                if (response.status) {
                    pSuccess("", "Folder removed successfully");
                    renderFolderView(idList);
                } else {
                    pError("", response.errMsg);
                }
                await tick();
            },
            async function () {
                await tick();
            }
        );
    }
    var openNewFolderModal = function (data) {
        isEdit = false
        iconurl = ""
        removeFilepond(filepondelemInstance[0]);

        _$("#modal-new-folder").modal("show")

    }

    var openEditModal = function (data) {
        isEdit = true
        removeFilepond(filepondelemInstance[0]);

        _$("#modal-new-folder").modal("show")
        setDetails(data)

    }

    var setDetails = function (data) {
        editfolderObj._id = data._id
        editfolderObj.name = data.name
        editfolderObj.icon = data.icon


    }
    var updateDetails = async function (data) {
        let response = await api_editFolder({
            _id: editfolderObj._id,
            name: editfolderObj.name,
            icon: folderObj.icon
        })
        if (response.status) {
            isEdit = false
            pSuccess("", "Folder updated successfully");
            // removeFilepondedit(filepondelemInstance[0]);
            // removeFilepond(filepondelemInstance[0])
            clearFields();

            _$("#modal-new-folder").modal("hide")
            renderFolderView(idList);

        }


    }
    function clearFields() {
        folderObj = {
            name: "",
            icon: ""
        }
        removeFilepond(filepondelem[0]);
    }

    var handleClickProceed = async function api_createNewFolder(data, proceed, fileobj) {
        if (proceed) {
            isLoadingSaveAndProceed = true
        }
        var batchId = idList[0];
        var parentId = idList[idList.length - 1];
        let response = await api_createNode({
            parentId,
            batchId,
            name: folderObj.name,
            icon: folderObj.icon
        })
        if (response.status) {
            pSuccess("", "Folder Added successfully");
            clearFields();
            _$("#modal-new-folder").modal("hide")
        }
        else pError("Folder of same name already exists!", "");
        renderFolderView(idList);

    }



    async function api_getFolderNamesForIds(data) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let payload = JSON.stringify(
            {
                "action": "folder.getFolderNamesForIds",
                accessToken: localStorage.getItem("accessToken"),
                "data": data
            }
        );
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: payload,
        };
        try {
            let resObj = await fetch(API_URL, requestOptions);
            let response = await resObj.json();
            return response.data;

        } catch (error) {
            console.error('error', error)
            return { error: error };
        }
    }


    let filterObj
    let folderRedrawLock = false
    let renderFolderView = async function (idList) {
        // check if redraw is locked
        if (folderRedrawLock) return;
        // lock redraw function
        folderRedrawLock = true;

        // code that will execute regardless of filter -> start
        var batchId = idList[0];
        var parentId = idList[idList.length - 1];
        _batchId = batchId;
        _parentId = parentId;
        let folderNames = await api_getFolderNamesForIds(idList);
        // <i data-feather="archive"></i>  
        var breadcrumbItem = '';
        breadcrumbItem = `
        <li class="breadcrumb-item">
            <a class="bc-link" data-idlist="${folderNames.batchObj._id}" href="/dashboard/studyMaterial/folder/${folderNames.batchObj._id}"> 
                ${folderNames.batchObj.name.toUpperCase()}
            </a>
        </li>`;
        var aIdList = []
        aIdList.push(folderNames.batchObj._id);
        let tempSelectedFolder = [];
        tempSelectedFolder.push({
            batchId: folderNames.batchObj._id,
            id: folderNames.batchObj._id,
            name: folderNames.batchObj.name
        })
        folderNames.idListArr.reverse();
        folderNames.idListArr.forEach(aFolder => {
            aIdList.push(aFolder._id)
            tempSelectedFolder.push({
                batchId: folderNames.batchObj._id,
                id: aFolder._id,
                name: aFolder.name
            })
            breadcrumbItem += `
            <li class="breadcrumb-item">
                <a class="bc-link" data-idlist="${aIdList.join('-')}" href="/dashboard/studyMaterial/folder/${aIdList.join('-')}"> 
                    <i data-feather="folder"></i>  
                    ${aFolder.name}
                </a>
            </li>`;
        });
        selectedFolder = tempSelectedFolder;
        _$(".folder-header").html(`
            <nav aria-label="breadcrumb " class="folder-breadcrumb">
                <ol class="breadcrumb breadcrumb-style2 mg-b-0 b">
                    ${breadcrumbItem}
                </ol>
            </nav>
        `);
        _$(".bc-link").unbind().click(function (e) {
            let aIdAtt = _$(this).data('idlist');
            navigate(("/dashboard/studyMaterial/folder/" + aIdAtt));
            return false; // cancel the event
        })
        feather.replace();

        childrenList = await api_getChildren({
            batchId, parentId
        });
        tick();
        getCountsforFolders(childrenList);
        feather.replace();

        fileList = await api_getFilesInsideFolder({
            folderId: parentId,
            filterObj
        })
        if (!Array.isArray(fileList)) {
            fileList = []
        }
        tick();
        // code that will execute regardless of filter -> end
        // code that will execute for filter -> start
        // check filter is aplied or not
        if (filterObj != null) {
            if (!filterObj.batchIds) {
                let folderListArr = [...childrenList]
                // start loading
                isLoading = true
                function updateFilesList() {
                    // function to update list of files being rendered
                    let fileFolderListArrIndex = 0
                    let lockFilesListLoop = false;
                    let fileFolderInterval
                    function fileListEnded(i, length) {
                        // exit from loop
                        if (i == length - 1) {
                            // clear loop interval
                            if (fileFolderInterval) clearInterval(fileFolderInterval)
                            // allow execution of redraw function again
                            folderRedrawLock = false;
                            // end loading
                            isLoading = false
                            // return true when files list is ended
                            return true
                        }
                        // return false when files list is not ended
                        return false
                    }
                    fileFolderInterval = setInterval(() => {
                        // check if loop is locked
                        if (!lockFilesListLoop) {
                            if (fileFolderListArrIndex < folderListArr.length) {
                                // lock looping
                                lockFilesListLoop = true
                                // console.log("fileFolderListArrIndex => ", fileFolderListArrIndex)
                                // bring new files
                                let folderParentId = folderListArr[fileFolderListArrIndex]._id
                                api_getFilesInsideFolder({
                                    folderId: folderParentId,
                                    filterObj
                                }).then(resp => {
                                    // files found, push into array
                                    fileList.push(...resp)
                                    fileList = fileList
                                    // check if we should stop interval now
                                    if (!fileListEnded(fileFolderListArrIndex, folderListArr.length)) {
                                        // increment index for next file
                                        fileFolderListArrIndex++;
                                    }
                                    // unlock looping
                                    lockFilesListLoop = false;
                                }).catch(e => {
                                    // check if we should stop interval now
                                    if (!fileListEnded(fileFolderListArrIndex, folderListArr.length)) {
                                        // increment index for next file
                                        fileFolderListArrIndex++;
                                    }
                                    // unlock looping
                                    lockFilesListLoop = false;
                                })
                            } else {
                                // clear loop interval
                                if (fileFolderInterval) clearInterval(fileFolderInterval)
                                // unlock loop
                                lockFoldersListLoop = false
                            }
                        }
                    }, 500)
                }
                let lockFoldersListLoop = false
                if (folderListArr.length) {
                    let folderListArrIndex = 0
                    let folderInterval = setInterval(() => {
                        // check if loop is locked
                        if (!lockFoldersListLoop) {
                            // check if there are more folders in list
                            if (folderListArrIndex < folderListArr.length) {
                                // lock loop
                                lockFoldersListLoop = true
                                // console.log("folderListArrIndex => ", folderListArrIndex)
                                let folderParentId = folderListArr[folderListArrIndex]._id
                                let folderBatchId = folderListArr[folderListArrIndex].batchId
                                // bring folders from current folder
                                api_getChildren({
                                    batchId: folderBatchId,
                                    parentId: folderParentId
                                }).then(resp => {
                                    // new folders found, push into array
                                    folderListArr.push(...resp);
                                    // check if folders are ended from list
                                    if (folderListArrIndex == folderListArr.length - 1) {
                                        // now that all folders are found, search for files
                                        updateFilesList()
                                        // clear loop interval
                                        if (folderInterval) clearInterval(folderInterval)
                                    } else {
                                        // increment index for next file
                                        folderListArrIndex++;
                                    }
                                    // unlock folders loop
                                    lockFoldersListLoop = false
                                }).catch(e => {
                                    if (folderListArrIndex == folderListArr.length - 1) {
                                        // now that all folders are found, search for files
                                        updateFilesList()
                                        // clear loop interval
                                        if (folderInterval) clearInterval(folderInterval)
                                    } else {
                                        // increment index for next file
                                        folderListArrIndex++;
                                    }
                                    // unlock folders loop
                                    lockFoldersListLoop = false
                                })
                            } else {
                                updateFilesList()
                                // clear loop interval
                                if (folderInterval) clearInterval(folderInterval)
                                // unlock folders loop
                                lockFoldersListLoop = false
                            }
                        }
                    }, 500)
                } else {
                    // end loading
                    isLoading = false;
                    // allow execution of redraw function again
                    folderRedrawLock = false;
                }
            }

        } else {
            // end loading
            isLoading = false;
            // allow execution of redraw function again
            folderRedrawLock = false;
        }
        // code that will execute for filter -> end

        setTimeout(function () {
            feather.replace();
        }, 10)
        feather.replace();
    }
    fileExplorerFilter.subscribe(value => {
        filterObj = value
        renderFolderView(idList)
    })
    async function api_getFilesInsideFolder(data) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let payload = JSON.stringify(
            {
                "action": "folder.getFilesInsideFolder",
                accessToken: localStorage.getItem("accessToken"),
                "data": data
            }
        );
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: payload,
        };
        try {
            let resObj = await fetch(API_URL, requestOptions);
            let response = await resObj.json();
            return response.data;

        } catch (error) {
            console.error('error', error)
            return { error: error };
        }
    }


    async function getCountsforFolders(childrenList) {

        let childIdArr = []
        childIdArr = childrenList.map(item => {
            return { batchId: item.batchId, folderId: item._id }
        })

        fileFolderCount = await api_getfileFolderCount(childIdArr);
        mapCount(fileFolderCount);
    }

    async function api_getfileFolderCount(childIdArr) {

        let action = "studyMaterial.getFileFolderCountInsideBatch";
        var response = await api_handleApiCalls(action, childIdArr);
        return response.data;

    }


    function mapCount(fileFolderCount) {

        for (var i = 0; i < (fileFolderCount.folderCount).length; i++) {
            let index = childrenList.findIndex(item => item.batchId == fileFolderCount.folderCount[i]._id.batchId && item._id == fileFolderCount.folderCount[i]._id.folderId);
            childrenList[index].folderCount = fileFolderCount.folderCount[i].count
        }

        for (var i = 0; i < (fileFolderCount.fileCount).length; i++) {
            let index = childrenList.findIndex(item => item.batchId == fileFolderCount.fileCount[i]._id.batchId && item._id == fileFolderCount.fileCount[i]._id.folderId);
            childrenList[index].fileCount = fileFolderCount.fileCount[i].count
        }


    }

    afterUpdate(() => {
        if (lastIdList != idList) {
            lastIdList = idList;
            renderFolderView(idList);
        }
    });

    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    }
    function updateSuccess(success) {
        PNotify.success({
            title: success.title ? success.title : "Success",
            text: success.msg,
            delay: 3000
        })
    }

    function updateErrors(error) {
        PNotify.error({
            title: error.title ? error.title : "Error",
            text: error.msg,
            delay: 3000
        })
    }
    var addFilepondInstance;
    var filepondelem;
    var editFilepondInstance;
    var filepondelemInstance;
    onMount(async function () {
        renderFolderView(idList);
        _$("#batchSelectize").addClass('d-none')
        _$(modalVideoPreview).on('hidden.bs.modal', async function (e) {
            previewVideoSrc = ''
            vidPreview.pause();
            await tick()
        });

        _$.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
        _$.fn.filepond.registerPlugin(FilePondPluginGetFile);
        _$.fn.filepond.setOptions({

            server: {
                url: "{{ url('upload')}}",
                process: {
                    headers: {
                        'X-CSRF-TOKEN': "csrf"
                    },
                }
            }
        });
        // filepond for edit icon
        filepondelemInstance = _$(elem_uploadImageInput).filepond(filepondOptions);
        _$(".may-pond .filepond--item-panel").css('background', '#369763')
        // filepond for add icon
        filepondelem = _$(elem_addImageInput).filepond(filepondOptionsForAdd);
        clearFields();
        _$(".may-pond .filepond--item-panel").css('background', '#369763')
    })

    function removeFilepond(filepondelem) {
        addFilepondInstance = FilePond.find(filepondelem);
        if (addFilepondInstance) {
            addFilepondInstance.removeFiles();
        }

    }
    function removeFilepondedit(filepondelemInstance) {
        editFilepondInstance = FilePond.find(filepondelemInstance);
        if (editFilepondInstance) {
            editFilepondInstance.removeFiles();
        }

    }


    let validatePdf = () => {
        errorObj = {};
        if (!pdfName) {
            var newErrorObj = {};
            if (!pdfName) {
                newErrorObj.pdfName = "PDF Name is required Field.";
            }
            errorObj = newErrorObj;
        }
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;
    }

    let addNewPdf = async () => {
        if (isLoading) return;
        isLoading = true;
        if (validatePdf()) {
            var pdfRes = await api_addPdf({
                name: pdfName, idList
            });
            if (pdfRes.status) {
                var linkObj = {
                    fileId: pdfRes.data[1],
                    folderId: idList[idList.length - 1],
                    batchId: idList[0],
                    selectedFolder: selectedFolder
                }
                var resp = await api_addFileLinkToFolder(linkObj);
                updateSuccess({ title: "Success", msg: pdfRes.data[0] });
                navigate(`/dashboard/studyMaterial/pdf/edit/${pdfRes.data[1]}`)
                _$('#pdf-add-modal').modal("hide")
            } else {
                // Failed to create 
                updateErrors({ title: "Error", msg: pdfRes.errMsg })
                isLoading = false;
                return false;
            }
        }
        isLoading = false;
    }

    let validateImage = () => {
        errorObj = {};
        if (!imageName) {
            var newErrorObj = {};
            if (!imageName) {
                newErrorObj.imageName = "Image Name is required Field.";
            }
            errorObj = newErrorObj;
        }
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;
    }

    let addNewImage = async () => {
        if (isLoading) return;
        isLoading = true;
        await tick()
        if (validateImage()) {
            var imageRes = await api_addImage({
                name: imageName
            });
            if (imageRes.status) {
                updateSuccess({ title: "Success", msg: imageRes.data[0] });

                var linkObj = {
                    fileId: imageRes.data[1],
                    folderId: idList[idList.length - 1],
                    batchId: idList[0],
                    selectedFolder: selectedFolder
                }
                var resp = await api_addFileLinkToFolder(linkObj);
                _$('#image-add-modal').modal("hide")
                navigate(`/dashboard/studyMaterial/images/edit/${imageRes.data[1]}`)
            } else {
                updateErrors({ title: "Error", msg: imageRes.errMsg })
                isLoading = false;
                return false;
            }
        }
        isLoading = false;
    }

    let validateRVideos = () => {
        errorObj = {};
        if (!lectureName) {
            var newErrorObj = {};
            if (!lectureName) {
                newErrorObj.lectureName = "Recorded Lecture Name is required Field.";
            }
            errorObj = newErrorObj;
        }
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;
    }

    let addNewRecordedVideo = async () => {
        if (isLoading) return;
        isLoading = true;
        if (validateRVideos()) {
            var recodedLectureRes = await api_addUpdateRecordedLecture({
                name: lectureName
            });
            if (recodedLectureRes.status) {
                updateSuccess({ title: "Success", msg: recodedLectureRes.data[0] });
                var linkObj = {
                    fileId: recodedLectureRes.data[1],
                    folderId: idList[idList.length - 1],
                    batchId: idList[0],
                    selectedFolder: selectedFolder
                }

                var resp = await api_addFileLinkToFolder(linkObj);
                _$('#recorded-add-modal').modal("hide")
                navigate(`/dashboard/studyMaterial/recordedLecture/edit/${recodedLectureRes.data[1]}`)
            } else {
                // Failed to create 
                updateErrors({ title: "Error", msg: recodedLectureRes.errMsg })
                isLoading = false;
                return false;
            }
        }
        isLoading = false;
    }

    let validateLive = () => {
        errorObj = {};
        if (!liveLectureName || !lecture_type) {
            var newErrorObj = {};
            if (!liveLectureName) {
                newErrorObj.liveLectureName = "Live Lecture Name is required Field.";
            }
            if (!lecture_type) {
                newErrorObj.lecture_type = "Live Lecture Type is required Field.";
            }
            errorObj = newErrorObj;
        }
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;
    }

    let addNewLiveLecture = async () => {
        if (isLoading) return;
        isLoading = true;
        lecture_type = _$(elemType).val()
        if (validateLive()) {
            var liveRes = await api_addUpdateLiveLecture({
                name: liveLectureName,
                lecture_type
            });
            if (liveRes.status) {
                updateSuccess({ title: "Success", msg: liveRes.data[0] });
                var linkObj = {
                    fileId: liveRes.data[1],
                    folderId: idList[idList.length - 1],
                    batchId: idList[0],
                    selectedFolder: selectedFolder
                }

                var resp = await api_addFileLinkToFolder(linkObj);
                _$('#live-add-modal').modal("hide");
                navigate(`/dashboard/studyMaterial/liveLecture/edit/${liveRes.data[1]}`)
            } else {
                updateErrors({ title: "Error", msg: liveLectureObj.errMsg })
                isLoading = false;
                return false;
            }
        }
        isLoading = false;
    }


    let validateYoutube = () => {
        errorObj = {};
        if (!youtubeLectureName) {
            var newErrorObj = {};
            if (!youtubeLectureName) {
                newErrorObj.youtubeLectureName = "Youtube Lecture name is required Field.";
            }
            // if (!lecture_type) {
            //     newErrorObj.lecture_type = "Live Lecture Type is required Field.";
            // }
            errorObj = newErrorObj;
        }
        if (Object.keys(errorObj).length > 0) {
            isLoading = false;
            return false;
        }
        return true;
    }
    function handleError() {
        console.log('error'); // This never happen even when src is invalid

    }


    let addNewYoutubeLecture = async () => {
        if (isLoading) return;
        isLoading = true;
        if (validateYoutube()) {
            var youtubeRes = await api_addUpdateYoutubeLecture({
                name: youtubeLectureName,
            });
            if (youtubeRes.status) {
                updateSuccess({ title: "Success", msg: youtubeRes.data[0] });
                var linkObj = {
                    fileId: youtubeRes.data[1],
                    folderId: idList[idList.length - 1],
                    batchId: idList[0],
                    selectedFolder: selectedFolder
                }

                var resp = await api_addFileLinkToFolder(linkObj);
                _$('#youtubeLecture-add-modal').modal("hide");
                navigate(`/dashboard/studyMaterial/youtubeLecture/edit/${youtubeRes.data[1]}`)
            } else {
                updateErrors({ title: "Error", msg: youtubeLectureObj.errMsg })
                isLoading = false;
                return false;
            }
        }
        isLoading = false;
    }


    let validateExternalLink = () => {
        errorObj = {};
        if (!externalLinkName || !urlToDrive) {
            var newErrorObj = {};
            if (!externalLinkName) {
                newErrorObj.externalLinkName = "External Name is required Field.";
            }
            if (!urlToDrive) {
                newErrorObj.urlToDrive = "External url is required Field.";
            }
            errorObj = newErrorObj;

        }
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;


    }

    let addNewExternalLink = async () => {
        if (isLoading) return;
        isLoading = true;
        if (validateExternalLink()) {

            var externalLinkRes = await api_addExternalLink({
                name: externalLinkName,
                urlToDrive: urlToDrive
            });
            if (externalLinkRes.status) {
                updateSuccess({ title: "Success", msg: externalLinkRes.data[0] });
                var linkObj = {
                    fileId: externalLinkRes.data[1],
                    folderId: idList[idList.length - 1],
                    batchId: idList[0],
                    selectedFolder: selectedFolder
                }

                var resp = await api_addFileLinkToFolder(linkObj);
                _$('#externalLink-add-modal').modal("hide");
                navigate(`/dashboard/studyMaterial/externalLink/edit/${externalLinkRes.data[1]}`)
            } else {
                updateErrors({ title: "Error", msg: externalLinkRes.errMsg })
                isLoading = false;
                return false;
            }
        }
        isLoading = false;
    }

    let validateRefLink = () => {
        errorObj = {};
        if (!refName || !refUrl) {
            var newErrorObj = {};
            if (!refName) {
                newErrorObj.refName = "Ref Link Name is required Field.";
            }
            if (!refUrl) {
                newErrorObj.refUrl = "Ref Link url is required Field.";
            }
            errorObj = newErrorObj;
        }
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;
    }

    let addNewRef = async () => {
        if (isLoading) return;
        isLoading = true;
        if (validateRefLink()) {

            var refLinkRes = await api_addUpdateRefLink({
                name: refName,
                url: refUrl
            });
            if (refLinkRes.status) {
                updateSuccess({ title: "Success", msg: refLinkRes.data[0] });
                var linkObj = {
                    fileId: refLinkRes.data[1],
                    folderId: idList[idList.length - 1],
                    batchId: idList[0],
                    selectedFolder: selectedFolder
                }

                var resp = await api_addFileLinkToFolder(linkObj);
                _$('#ref-add-modal').modal("hide");
                navigate(`/dashboard/studyMaterial/refLink/edit/${refLinkRes.data[1]}`)
            } else {
                // Failed to create 
                updateErrors({ title: "Error", msg: refLinkRes.errMsg })
                isLoading = false;
                return false;
            }
        }
        isLoading = false;
    }

    let fileSizeView = (fileSize) => {

        if (fileSize > 1000000) {
            return (fileSize / 1e+6).toFixed(3) + ' MB'
        } else {
            return (fileSize / 1000).toFixed(3) + ' KB'
        }
    }

    async function api_removeFileLinkToFolder(data) {

        let action = "files.removeFileLinkToFolder";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_removeAllFileLinkToFolder(fileId) {

        let action = "files.removeAllFileLinkToFolder";
        var response = await api_handleApiCalls(action, { fileId: fileId });
        return response;

    }

    let deleteLink = async (linkId) => {
        var linkRes = await api_removeFileLinkToFolder({ _id: linkId });
        if (linkRes.status) {
            pSuccess({ title: "Success", msg: "Link has been removed successfully." });
            renderLinks();
        } else {
            pError({
                title: "Error",
                msg: linkRes?.errMsg ?? "Something went wrong",
            });
        }
    };

    async function api_loadLinksForFile(data) {

        let action = "files.loadLinksForFile";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    let renderLinks = async function (fileId) {
        var templinkArr = await api_loadLinksForFile({
            fileId: fileId,
        });
        console.log("---renderLinks ---", fileId, templinkArr);
        linkArr = templinkArr?.data?.fileFolderLinkArr || [];
        linkArr = linkArr
        await tick();
        setTimeout(function () {
            feather.replace();
        }, 10);
        return
    };

    function getCurrentLinkForFile() {
        var currentFileIndex = linkArr.findIndex(i => i.batchId == _batchId && i.fileId == _fileId && i.folderId == _parentId);
        currentFileObj = linkArr.splice(currentFileIndex, 1)[0]
        linkArr = linkArr;
        currentFileObj = currentFileObj;
    }

    async function handleDelete(fileId) {
        _fileId = fileId;
        await renderLinks(fileId);
        await getCurrentLinkForFile();
        _$("#delete-modal").modal("show");
        return
    }

    async function handleUnlinkAll() {
        let res = await api_removeAllFileLinkToFolder(_fileId);
        if (res.status) {
            fileList = fileList.filter(j => j.fileId != _fileId)
            PNotify.success({
                title: 'Success',
                text: "All links has been removed successfully.",
                delay: 3000
            })
        } else {
            PNotify.error({
                title: 'Error',
                text: res.errMsg,
                delay: 3000
            })
        }
        _$("#delete-modal").modal("hide");
    }

    async function handleUnlinkCurrent(linkId) {
        let res = await api_removeFileLinkToFolder({ _id: linkId });
        if (res.status) {
            fileList = fileList.filter(j => j.fileId != _fileId)
            PNotify.success({
                title: 'Success',
                text: "Current link has been removed successfully.",
                delay: 3000
            })
        } else {
            PNotify.error({
                title: 'Error',
                text: res.errMsg,
                delay: 3000
            })
        }
        _$("#delete-modal").modal("hide");
    }

    async function handleDeletePermanently(fileId) {
        let res = await api_deletePermanently(fileId);
        if (res.status) {
            fileList = fileList.filter(j => j.fileId != _fileId)
            PNotify.success({
                title: 'Success',
                text: "Current file has been deleted successfully.",
                delay: 3000
            })
        } else {
            PNotify.error({
                title: 'Error',
                text: res.errMsg,
                delay: 3000
            })
        }

        _$("#delete-modal").modal("hide");
    }

    async function api_deletePermanently(data) {

        let action = "studyMaterial.deleteFilePermanently";
        var response = await api_handleApiCalls(action, { fileId: data });
        return response;

    }

    function handleMoveToschedule() {
        navigate
    }


    async function api_updateImageFileUrl(data) {

        let action = "studyMaterial.updateImageFileUrl";
        var response = await api_handleApiCalls(action, data);
        return response;

    }
    async function api_getUrlforfile(data) {
        let action = "storage.getUrlforfile";
        var response = await api_handleApiCalls(action, data);
        return response;
    }

    async function api_getListOfFiles(data) {
        let action = "storage.getUrlForUpload";
        var response = await api_handleApiCalls(action, data);
        return response;
    }

    let filepondOptions = {
        allowDownloadByUrl: true,
        acceptedFileTypes: ["image/*"],

        server: {
            process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                // fieldName is the name of the input field
                // file is the actual file object to send
                const formData = new FormData();
                formData.append(fieldName, file, file.name);

                let originalFileName = file.name;
                let _fileName = file.name.split(".");
                var fileExtension = _fileName[_fileName.length - 1];
                var fileSize = file.size
                let fileObjForUpload = {
                    type: file.type,
                    name: originalFileName,
                    uploadType: 'CHAT_ATTACHEMENT',
                    fileId: loggedInUserId,
                };
                var resData = await api_handleApiCalls(
                    'storage.getUrlForUpload',
                    fileObjForUpload,
                );
                if (resData.status) {
                    _$.ajax({
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('x-amz-acl', 'public-read');
                            // xhr.setRequestHeader('Access-Control-Allow-Origin','*');
                        },
                        xhr: function () {
                            var xhr = new window.XMLHttpRequest();
                            xhr.upload.addEventListener("progress", function (e) {
                                // var percentComplete = (evt.loaded / evt.total) * 100;
                                progress(e.lengthComputable, e.loaded, e.total);
                                // console.log("percentComplete : ", percentComplete)
                            }, false);
                            return xhr;
                        },
                        url: resData.data.url,
                        type: "PUT",
                        data: file,
                        dataType: "text",
                        cache: false,
                        contentType: file.type,
                        processData: false
                    }).done(async function (resss) {
                        iconurl = (resData.data.url.split('?')[0]);
                        load("Done");
                    })
                        .fail(function (err) {
                            error('oh no');
                            console.error('damn...', err);
                        })
                }
                else {
                    // todo show erro toast
                }

                return {
                    abort: () => {
                        // This function is entered if the user has tapped the cancel button
                        // request.abort();

                        // Let FilePond know the request has been cancelled
                        abort();
                    }
                };
            }

        }


    }


    let filepondOptionsForAdd = {
        allowDownloadByUrl: true,
        acceptedFileTypes: ["image/*"],

        server: {
            process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                // fieldName is the name of the input field
                // file is the actual file object to send
                const formData = new FormData();
                formData.append(fieldName, file, file.name);

                let originalFileName = file.name;
                let _fileName = file.name.split(".");

                var fileExtension = _fileName[_fileName.length - 1];
                var fileSize = file.size
                let fileObjForUpload = {
                    type: file.type,
                    name: originalFileName,
                    uploadType: 'CHAT_ATTACHEMENT',
                    fileId: loggedInUserId,
                };

                var resData = await api_handleApiCalls(
                    'storage.getUrlForUpload',
                    fileObjForUpload,
                );
                if (resData.status) {
                    _$.ajax({
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('x-amz-acl', 'public-read');
                            // xhr.setRequestHeader('Access-Control-Allow-Origin','*');
                        },
                        xhr: function () {
                            var xhr = new window.XMLHttpRequest();
                            xhr.upload.addEventListener("progress", function (e) {
                                // var percentComplete = (evt.loaded / evt.total) * 100;
                                progress(e.lengthComputable, e.loaded, e.total);
                                // console.log("percentComplete : ", percentComplete)
                            }, false);
                            return xhr;
                        },
                        url: resData.data.url,
                        type: "PUT",
                        data: file,
                        dataType: "text",
                        cache: false,
                        contentType: file.type,
                        processData: false
                    }).done(async function (resss) {
                        folderObj.icon = (resData.data.url.split('?')[0]);
                        load("Done");
                    })
                        .fail(function (err) {
                            error('oh no');
                            console.error('damn...', err);
                        })
                }
                else {
                    // todo show erro toast
                }

                return {
                    abort: () => {
                        // This function is entered if the user has tapped the cancel button
                        // request.abort();

                        // Let FilePond know the request has been cancelled
                        abort();
                    }
                };
            }

        }


    }


</script>


<div class="filemgr-content-header" style="justify-content: space-between;">
    <h4 class="mg-b-0">All Files</h4>
    <div class="filemgr-sidebar-header" style="position: relative;border:none">
        {#if MODULES.hasAccess(MODULES.ADD_FOLDER_STUDY_MATERIAL)}
        {#if idList.length == 2}
        <SyncSubject on:refresh={()=>renderFolderView(idList)} syncInBatchId={idList[0]} syncInSubjectId={idList[1]}/>
            {/if}

            <a href="#" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" style="justify-content: space-between;font-weight: 500;
        font-size: 11px; padding: 6px 20px;" data-animation="effect-scale" on:click={openNewFolderModal}>
                <i data-feather="plus" class="wd-10 mg-r-5"></i> Add Folder
            </a>

            <!-- <button on:click={performAddOps} class="btn btn-sm pd-x-15 btn-white mg-r-10"><i data-feather="folder-plus"
                class="wd-10 mg-r-5" />
            New Folder</button> -->
            {/if}
            <div class="dropdown dropdown-icon flex-fill" style="justify-content: space-between;">
                {#if MODULES.hasAccess(MODULES.UPLOAD_STUDY_MATERIAL)}
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" style="    padding: 6px 20px;"
                    data-toggle="dropdown">Upload
                    <i data-feather="chevron-down"></i></button>
                {/if}
                <div class="dropdown-menu tx-13" use:links>
                    <!-- <a href="" class="dropdown-item"><i data-feather="folder"></i><span>Folder</span></a>
                            <a href="" class="dropdown-item"><i data-feather="file"></i><span>Notes</span></a> -->
                    <!-- <div class="dropdown-divider"></div> -->
                    <!-- <a href="#pdf-add-modal" class="dropdown-item" data-toggle="modal"><i
                        data-feather="file-text"></i><span>PDF</span></a>
                <a href="#image-add-modal" class="dropdown-item" data-toggle="modal"><i
                        data-feather="image"></i><span>Image</span></a>
                <a href="#recorded-add-modal" class="dropdown-item" data-toggle="modal"><i
                        data-feather="video"></i><span>Video Lecture</span></a>
                <a href="#live-add-modal" class="dropdown-item" data-toggle="modal"><i
                        data-feather="youtube"></i><span>Live Videos</span></a>
                <a href="#externalLink-add-modal" class="dropdown-item" data-toggle="modal"><i
                        data-feather="external-link"></i><span>External Link</span></a>
                <a href="#ref-add-modal" class="dropdown-item" data-toggle="modal"><i
                        data-feather="external-link"></i><span>Reference Link</span></a>
                <a href="#youtubeLecture-add-modal" class="dropdown-item" data-toggle="modal"><i
                            data-feather="youtube"></i><span>Youtube Lectures</span></a> -->


                    <a href="/dashboard/studyMaterial/addnewfile/doc/{idList.join('-')}" class="dropdown-item"
                        data-toggle="modal"><i data-feather="file-text"></i><span>Documents</span></a>
                    <a href="/dashboard/studyMaterial/addnewfile/image/{idList.join('-')}" class="dropdown-item"
                        data-toggle="modal"><i data-feather="image"></i><span>Image</span></a>
                    <a href="/dashboard/studyMaterial/addnewfile/uploadVideo/{idList.join('-')}" class="dropdown-item"
                        data-toggle="modal"><i data-feather="video"></i><span>Video Lecture</span></a>
                    <!-- <a href="/dashboard/studyMaterial/addnewfile/LiveLinkYoutube/{idList.join('-')}" class="dropdown-item" data-toggle="modal"><i
                  data-feather="youtube"></i><span>Youtube Live Videos</span></a> -->
                    <a href="#forwardToSchedule" class="dropdown-item" data-toggle="modal"><i
                            data-feather="youtube"></i><span>Youtube Live Videos</span></a>
                    <a href="#forwardToSchedule" class="dropdown-item" data-toggle="modal"><i
                            data-feather="youtube"></i><span>Zoom Live Videos</span></a>
                    <!-- <a href="/dashboard/studyMaterial/addnewfile/LiveLinkZoom/{idList.join('-')}" class="dropdown-item" data-toggle="modal"><i
                  data-feather="external-link"></i><span>Zoom Live Videos</span></a> -->
                    <a href="/dashboard/studyMaterial/addnewfile/referenceLink/{idList.join('-')}" class="dropdown-item"
                        data-toggle="modal"><i data-feather="external-link"></i><span>Reference Link</span></a>
                    <a href="/dashboard/studyMaterial/addnewfile/youtubeLink/{idList.join('-')}" class="dropdown-item"
                        data-toggle="modal"><i data-feather="youtube"></i><span>Youtube Lectures</span></a>
                    <a href="/dashboard/studyMaterial/addnewfile/multipleFiles/{idList.join('-')}" class="dropdown-item"
                        data-toggle="modal"><i data-feather="file-text"></i><span>Multiple File Upload</span></a>

                </div><!-- dropdown-menu -->
            </div><!-- dropdown -->
            <!-- <div class="dropdown dropdown-icon flex-fill mg-l-10">
            <button class="btn btn-xs btn-block btn-primary" data-toggle="dropdown">Upload <i
                    data-feather="chevron-down"></i></button>
            <div class="dropdown-menu tx-13">
                <a href="" class="dropdown-item"><i data-feather="file"></i><span>File</span></a>
                <a href="" class="dropdown-item"><i data-feather="folder"></i><span>Folder</span></a>
            </div>
        </div> -->
    </div><!-- filemgr-sidebar-header -->
</div>
<div class="filemgr-content-body">
    <div class=" pd-t-0 pd-20 pd-lg-25 pd-xl-30 " style="padding-top: 0 !important;">
        <hr class="mg-y-40 bd-0">
        <label class="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15">Folders</label>
        <div class="row row-xs">
            {#each childrenList as item}
            <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                <a href={"/dashboard/studyMaterial/folder/"+idList.join('-')+"-"+item._id} use:links class="link-02">
                    <div class="media media-folder">
                        <i data-feather="folder"></i>
                        <div class="media-body">
                            <h6>{item.name}</h6>
                            <span>Folders: {item.folderCount? item.folderCount : 0}</span>
                            <span>Files: {item.fileCount? item.fileCount : 0}</span>
                        </div><!-- media-body -->
                    </div>
                </a>
                <div>
                    {#if MODULES.hasAccess(MODULES.RENAME_STUDY_MATERIAL) &&
                    MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL) }
                    <div class="dropdown-file">
                        <a href="" class="dropdown-link" data-toggle="dropdown"><i data-feather="more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <!-- <a href="#modalViewDetails" data-toggle="modal" class="dropdown-item details"><i
                                    data-feather="info"></i>View Details</a>
                            <a href="" class="dropdown-item important"><i data-feather="star"></i>Mark as
                                Important</a>
                            <a href="#modalShare" data-toggle="modal" class="dropdown-item share"><i
                                    data-feather="share"></i>Share</a>
                            <a href="" class="dropdown-item download"><i data-feather="download"></i>Download</a>
                            <a href="#modalCopy" data-toggle="modal" class="dropdown-item copy"><i
                                    data-feather="copy"></i>Copy
                                to</a>
                            <a href="#modalMove" data-toggle="modal" class="dropdown-item move"><i
                                    data-feather="folder"></i>Move
                                to</a> -->
                            {#if MODULES.hasAccess(MODULES.RENAME_STUDY_MATERIAL)}
                            <a href="#" on:click={()=>{openEditModal(item)}}
                                class="dropdown-item rename"><i data-feather="edit"></i>Edit</a>
                            {/if}

                            {#if MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                            <a on:click={()=>{performDeleteOps(item._id)}} class="dropdown-item delete"><i
                                    data-feather="trash"></i>Delete</a>
                            {/if}
                        </div>
                    </div><!-- dropdown -->
                    {/if}
                </div>

            </div><!-- col -->
            {:else}
            <div class="d-flex justify-content-center" style="width: 100%;">
                <img style="max-width: 200px; width: 200px;" src="/assets/folder_empty.svg" alt="">

            </div>
            <p class="mg-t-10 tx-gray-500" style="text-align: center;width: 100%;">No folders found</p>
            {/each}

        </div><!-- row -->

        <hr class="mg-y-40 bd-0">
        <label class="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15">Files</label>
        <div class="row row-xs">

            {#each fileList as item}
            {#if item && item.filesArr && (item.filesArr.type == 'externalLink' || item.filesArr.type == 'refLink')}
            <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                <div class="card card-file">
                    {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL) &&
                    MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL) }
                    <div class="dropdown-file">
                        <a href="" class="dropdown-link" data-toggle="dropdown"><i data-feather="more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                            {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL)}
                            <a href={(`/dashboard/studyMaterial/${getTypeByFile(item.filesArr)}/edit/${item.filesArr._id}`)}
                                use:links class="dropdown-item details"><i data-feather="info"></i>View Details</a>
                            {/if}
                            <!-- <a href="" class="dropdown-item important"><i data-feather="star"></i>Mark as
                                        Important</a>
                                    <a href="#modalShare" data-toggle="modal" class="dropdown-item share"><i
                                            data-feather="share"></i>Share</a>
                                    <a href="" class="dropdown-item download"><i data-feather="download"></i>Download</a>
                                    <a href="#modalCopy" data-toggle="modal" class="dropdown-item copy"><i
                                            data-feather="copy"></i>Copy
                                        to</a>
                                    <a href="#modalMove" data-toggle="modal" class="dropdown-item move"><i
                                            data-feather="folder"></i>Move
                                        to</a> -->
                            <!-- <a href="#" class="dropdown-item rename" on:click={handleRename(item.fileId)}><i data-feather="edit"></i>Rename</a> -->
                            {#if MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                            <a href="#" class="dropdown-item delete" on:click={()=>{handleDelete(item.filesArr._id)}}><i
                                    data-feather="trash"></i>Delete</a>
                            {/if}
                        </div>
                    </div><!-- dropdown -->
                    {/if}
                    <div class="card-file-thumb {(getColorByFile(item.filesArr))}" style="cursor: pointer;"
                        on:click={()=>
                        {navigate(`/dashboard/studyMaterial/${getTypeByFile(item.filesArr)}/edit/${item.filesArr._id}`)}}>
                        <!-- <i class="far {(getIconByFile(item.filesArr))}"></i> -->
                        <img style="width: 80%; height: 80%; padding: 5px; cursor: pointer;" src={"/assets/" +
                            getIconByFile(item.filesArr) + ".svg" } alt="">
                    </div>
                    <div class="card-body" use:links>


                        <h6><a href={(`/dashboard/studyMaterial/${getTypeByFile(item.filesArr)}/edit/${item.filesArr._id}`)}
                                class="link-02">{item.filesArr.name}</a></h6>
                        <!-- <span>{fileSizeView(item.filesArr.fileSize)}</span> -->
                    </div>
                    <div class="card-footer"><span class="d-none d-sm-inline">Created On:
                        </span>{moment(item.filesArr.createdOn).format("DD-MMM-YYYY")}</div>
                    <div>
                        {#if Array.isArray(item.filesArr.tags)}
                        {#each item.filesArr.tags as tag}
                        <span class="badge badge-light mg-r-5" style="font-size: 10px; color: #636363;">{tag}</span>
                        {/each}
                        {/if}
                    </div>
                </div>
            </div><!-- col -->
            {/if}
            {#if item && item.filesArr && item.filesArr.originalFileName || item.filesArr.type == 'liveLecture'}
            <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                <div class="card card-file">
                    {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL) &&
                    MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL) }
                    <div class="dropdown-file">
                        <a href="" class="dropdown-link" data-toggle="dropdown"><i data-feather="more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                            {#if item.filesArr.type == "recordedLecture"}
                            {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL)}
                            <a href="#" on:click="{()=>{showModalVideoPreview(item.filesArr)}}" data-toggle="modal"
                                class="dropdown-item details">
                                <i data-feather="info"></i>View Details</a>
                            {/if}
                            {:else}
                            {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL)}
                            <a href={(`/dashboard/studyMaterial/${getTypeByFile(item.filesArr)}/edit/${item.filesArr._id}`)}
                                use:links class="dropdown-item details"><i data-feather="info"></i>View Details</a>
                            {/if}
                            {/if}

                            <!-- <a href="" class="dropdown-item important"><i data-feather="star"></i>Mark as
                                        Important</a>
                                    <a href="#modalShare" data-toggle="modal" class="dropdown-item share"><i
                                            data-feather="share"></i>Share</a>
                                    <a href="" class="dropdown-item download"><i data-feather="download"></i>Download</a>
                                    <a href="#modalCopy" data-toggle="modal" class="dropdown-item copy"><i
                                            data-feather="copy"></i>Copy
                                        to</a>
                                    <a href="#modalMove" data-toggle="modal" class="dropdown-item move"><i
                                            data-feather="folder"></i>Move
                                        to</a> -->
                            <!-- <a href="#" class="dropdown-item rename" on:click={handleRename(item.fileId)}><i data-feather="edit"></i>Rename</a> -->
                            {#if MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                            <a href="#" class="dropdown-item delete" on:click={()=>{handleDelete(item.filesArr._id)}}><i
                                    data-feather="trash"></i>Delete</a>
                            {/if}
                        </div>
                    </div><!-- dropdown -->
                    {/if}
                    <div class="card-file-thumb {(getColorByFile(item.filesArr))}" style="cursor: pointer;"
                        on:click={()=>
                        {navigate(`/dashboard/studyMaterial/${getTypeByFile(item.filesArr)}/edit/${item.filesArr._id}`)}}>
                        <!-- <i class="far {(getIconByFile(item.filesArr))}"></i> -->
                        <img style="width: 80%; height: 80%; padding: 5px; cursor: pointer;" src={"/assets/" +
                            getIconByFile(item.filesArr) + ".svg" } alt="">
                    </div>
                    <div class="card-body" use:links>

                        {#if item.filesArr.type == 'liveLecture'}
                        <h6><a href={(`/dashboard/studyMaterial/${getTypeByFile(item.filesArr)}/edit/${item.filesArr._id}`)}
                                class="link-02">{item.filesArr.name}</a></h6>
                        <p class="tx-12 mg-t-8" style="color: #777">Start:
                            {moment(item.filesArr.from).format("DD-MM-YYYY HH:mm:ss")} &nbsp; End:
                            {moment(item.filesArr.end).format("DD-MM-YYYY HH:mm:ss")}</p>
                        {:else}
                        <h6><a href={(`/dashboard/studyMaterial/${getTypeByFile(item.filesArr)}/edit/${item.filesArr._id}`)}
                                class="link-02">{item.filesArr.name}</a></h6>
                        <p class="tx-12 mg-t-8" style="color: #777">{item.filesArr.originalFileName}</p>

                        <span>{fileSizeView(item.filesArr.fileSize)}</span>
                        {/if}
                    </div>
                    <div class="card-footer"><span class="d-none d-sm-inline">Created On:
                        </span>{moment(item.filesArr.createdOn).format("DD-MMM-YYYY")}</div>
                    <div>
                        {#if Array.isArray(item.filesArr.tags)}
                        {#each item.filesArr.tags as tag}
                        <span class="badge badge-light mg-r-5" style="font-size: 10px; color: #636363;">{tag}</span>
                        {/each}
                        {/if}
                    </div>
                </div>
            </div><!-- col -->
            {/if}
            {#if item && item.filesArr && item.filesArr.type == 'youtubeLecture'}
            <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                <div class="card card-file">
                    {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL) &&
                    MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL) }
                    <div class="dropdown-file">
                        <a href="" class="dropdown-link" data-toggle="dropdown"><i data-feather="more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                            {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL)}
                            <a href={(`/dashboard/studyMaterial/${getTypeByFile(item.filesArr)}/edit/${item.filesArr._id}`)}
                                use:links class="dropdown-item details"><i data-feather="info"></i>View Details</a>
                            {/if}
                            <!-- <a href="" class="dropdown-item important"><i data-feather="star"></i>Mark as
                                        Important</a>
                                    <a href="#modalShare" data-toggle="modal" class="dropdown-item share"><i
                                            data-feather="share"></i>Share</a>
                                    <a href="" class="dropdown-item download"><i data-feather="download"></i>Download</a>
                                    <a href="#modalCopy" data-toggle="modal" class="dropdown-item copy"><i
                                            data-feather="copy"></i>Copy
                                        to</a>
                                    <a href="#modalMove" data-toggle="modal" class="dropdown-item move"><i
                                            data-feather="folder"></i>Move
                                        to</a> -->
                            <!-- <a href="#" class="dropdown-item rename" on:click={handleRename(item.fileId)}><i data-feather="edit"></i>Rename</a> -->
                            {#if MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                            <a href="#" class="dropdown-item delete" on:click={()=>{handleDelete(item.filesArr._id)}}><i
                                    data-feather="trash"></i>Delete</a>
                            {/if}
                        </div>
                    </div><!-- dropdown -->
                    {/if}
                    <div class="card-file-thumb {(getColorByFile(item.filesArr))}" style="cursor: pointer;"
                        on:click={()=>
                        {navigate(`/dashboard/studyMaterial/${getTypeByFile(item.filesArr)}/edit/${item.filesArr._id}`)}}>
                        <!-- <i class="far {(getIconByFile(item.filesArr))}"></i> -->
                        <img style="width: 80%; height: 80%; padding: 5px; cursor: pointer;" src={"/assets/" +
                            getIconByFile(item.filesArr) + ".svg" } alt="">
                    </div>
                    <div class="card-body" use:links>


                        <h6><a href={(`/dashboard/studyMaterial/${getTypeByFile(item.filesArr)}/edit/${item.filesArr._id}`)}
                                class="link-02">{item.filesArr.name}</a></h6>
                        <!-- <span>{fileSizeView(item.filesArr.fileSize)}</span> -->
                    </div>
                    <div class="card-footer"><span class="d-none d-sm-inline">Created On:
                        </span>{moment(item.filesArr.createdOn).format("DD-MMM-YYYY")}</div>
                    <div>
                        {#if Array.isArray(item.filesArr.tags)}
                        {#each item.filesArr.tags as tag}
                        <span class="badge badge-light mg-r-5" style="font-size: 10px; color: #636363;">{tag}</span>
                        {/each}
                        {/if}
                    </div>
                </div>
            </div><!-- col -->
            {/if}


            {/each}
            {#if isLoading}
            <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                <div class="card card-file ">
                    <!-- <li class="list-group-item list-group-item-action"> -->
                    <div class="placeholder-paragraph ">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                    <!-- </li> -->
                </div>
            </div>
            {:else if fileList.length == 0}
            <div class="d-flex justify-content-center" style="width: 100%;">
                <img style="max-width: 200px; width: 200px;" src="/assets/files_empty.svg" alt="">

            </div>
            <p class="mg-t-10 tx-gray-500" style="text-align: center;width: 100%;">No file in this folder</p>
            {/if}
        </div><!-- row -->

    </div>
</div>

<div class="modal effect-scale" id="pdf-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="file-plus"
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">PDF</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Add new pdf</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->
            <hr class="full-width">
            <div class="modal-body  pd-l-25 pd-r-25 pd-b-25 pd-t-0 ">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label class="d-block">File Name
                            <span class="required-field">*</span></label>
                        <input bind:value={pdfName} type="text" on:keyup={(e)=> e.key === 'Enter' && addNewPdf()}
                        on:blur={()=>clearError("pdfName")}
                        class="form-control" class:parsley-error={errorObj.pdfName} placeholder="Pdf Name"/>
                        {#if errorObj.pdfName}
                        <div class="parsley-errors-list">{errorObj.pdfName}</div>
                        {/if}
                    </div>
                </div>
            </div>
            <div class="modal-footer pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CLOSE
                    <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                <button type="button" disabled={isLoading} on:click={addNewPdf} class="btn btn-primary tx-12">
                    <ButtonLoader isLoading={isLoading} btnText="SAVE" />
                    <i data-feather="save" class="wd-10 mg-l-5"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal effect-scale" id="image-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="file-plus"
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Image</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Add images.</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label class="d-block">Image Name <span class="required-field">*</span></label>
                        <input bind:value={imageName} type="text" on:keyup={(e)=> e.key === 'Enter' && addNewImage()}
                        on:blur={()=>clearError("imageName")}
                        class="form-control" class:parsley-error={errorObj.imageName} placeholder="Image name"/>
                        {#if errorObj.imageName}
                        <div class="parsley-errors-list">{errorObj.imageName}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                </div>
            </div>
            <div class="modal-footer pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CLOSE
                    <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                <button type="button" disabled={isLoading} on:click={addNewImage} class="btn btn-primary ">
                    <ButtonLoader isLoading={isLoading} btnText="SAVE" />
                    <i data-feather="save" class="wd-10 mg-l-5"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal effect-scale" id="recorded-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="file-plus"
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Videos</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Add recorded lecture </p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->
            <hr class="full-width">
            <div class="modal-body  pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label class="d-block">Recorded Lecture Name <span class="required-field">*</span></label>
                        <input bind:value={lectureName} type="text" placeholder="Recorded Lecture name"
                            on:blur={()=>clearError("lectureName")} on:keyup={(e)=> e.key === 'Enter' &&
                        addNewRecordedVideo()}
                        class="form-control" class:parsley-error={errorObj.lectureName} />
                        {#if errorObj.lectureName}
                        <div class="parsley-errors-list">{errorObj.lectureName}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                </div>
            </div>
            <div class="modal-footer pd-t-0">
                <div class="pd-12">
                    <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CLOSE
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button type="button" disabled={isLoading} on:click={addNewRecordedVideo}
                        class="btn btn-primary tx-12">
                        <ButtonLoader isLoading={isLoading} btnText="SAVE" />
                        <i data-feather="save" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal effect-scale" id="live-add-modal" bind:this={elem_addNewLiveLectureModel} tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="youtube"
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Live</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Add live lectures.</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->
            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label class="d-block">Live Lecture Name<span class="required-field">*</span></label>
                        <input bind:value={liveLectureName} on:keyup={(e)=> e.key === 'Enter' && addNewLiveLecture()}
                        type="text" on:blur={()=>clearError("liveLectureName")}
                        class="form-control" class:parsley-error={errorObj.liveLectureName} placeholder="Enter Lecture
                        Name"/>
                        {#if errorObj.liveLectureName}
                        <div class="parsley-errors-list">{errorObj.liveLectureName}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                    <div class="form-group col-md-12" on:click={()=>clearError("lecture_type")}
                        class:parsley-errors-select-custom={errorObj.lecture_type}>
                        <label class="d-block">Type <span class="required-field">*</span></label>
                        <select bind:this={elemType} style="width: 100%;" class="custom-select moduleSub">
                            <option value="" selected>Select Type</option>
                            <option value="zoom">Zoom</option>
                            <option value="youtube">YouTube</option>
                            <!-- <option value="bluebird" disabled>Blue Bird</option> -->
                        </select>
                        {#if errorObj.lecture_type}
                        <div class="parsley-errors-list">{errorObj.lecture_type}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                </div>
            </div>
            <div class="modal-footer pd-t-0">
                <div class="pd-12">
                    <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CLOSE
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button type="button" disabled={isLoading} on:click={addNewLiveLecture}
                        class="btn btn-primary tx-12">
                        <ButtonLoader isLoading={isLoading} btnText="SAVE" />
                        <i data-feather="save" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal effect-scale" id="youtubeLecture-add-modal" bind:this={elem_addNewyoutubeLectureModel} tabindex="-1"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="youtube"
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Youtube Lectures</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Add youtube lectures.</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->
            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label class="d-block">Youtube Lecture Name<span class="required-field">*</span></label>
                        <input bind:value={youtubeLectureName} type="text" on:keyup={(e)=> e.key === 'Enter' &&
                        addNewYoutubeLecture()} on:blur={()=>clearError("youtubeLectureName")}
                        class="form-control" class:parsley-error={errorObj.youtubeLectureName} placeholder="Youtube
                        Lecture name"/>
                        {#if errorObj.youtubeLectureName}
                        <div class="parsley-errors-list">{errorObj.youtubeLectureName}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                    <!-- <div class="form-group col-md-12" on:click={()=>clearError("lecture_type")}
                        class:parsley-errors-select-custom={errorObj.lecture_type}>
                        <label class="d-block">Type <span class="required-field">*</span></label>
                        <select bind:this={elemType} style="width: 100%;" class="custom-select moduleSub">
                            <option value="" selected>Select Type</option>
                            <option value="zoom">Zoom</option>
                            <option value="youtube">YouTube</option>
                            <!- <option value="bluebird" disabled>Blue Bird</option> ->
                        </select>
                        {#if errorObj.lecture_type}
                        <div class="parsley-errors-list">{errorObj.lecture_type}</div>
                        <!- content here ->
                        {/if}
                    </div> -->
                </div>
            </div>
            <div class="modal-footer pd-t-0">
                <div class="pd-12">
                    <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CLOSE
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button type="button" disabled={isLoading} on:click={addNewYoutubeLecture}
                        class="btn btn-primary tx-12">
                        <ButtonLoader isLoading={isLoading} btnText="SAVE" />
                        <i data-feather="save" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal effect-scale" id="externalLink-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="external-link"
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Links</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Add external links.</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->
            <hr class="full-width">
            <div class="modal-body  pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label class="d-block">External Link Name<span class="required-field">*</span></label>
                        <input bind:value={externalLinkName} type="text" on:keyup={(e)=> e.key === 'Enter' &&
                        addNewExternalLink()} on:blur={()=>clearError("externalLinkName")}
                        class="form-control" class:parsley-error={errorObj.externalLinkName} placeholder="External link
                        name"/>
                        {#if errorObj.externalLinkName}
                        <div class="parsley-errors-list">{errorObj.externalLinkName}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                    <div class="form-group col-md-12">
                        <label class="d-block">URL To Drive<span class="required-field">*</span></label>
                        <input bind:value={urlToDrive} type="text" on:keyup={(e)=> e.key === 'Enter' &&
                        addNewExternalLink()} on:blur={()=>clearError("urlToDrive")}
                        class="form-control"
                        class:parsley-error={errorObj.urlToDrive} placeholder="URL To Drive"/>
                        {#if errorObj.urlToDrive}
                        <div class="parsley-errors-list">{errorObj.urlToDrive}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                </div>
            </div>
            <div class="modal-footer pd-t-0">
                <div class="pd-12">
                    <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CLOSE
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button type="button" disabled={isLoading} on:click={addNewExternalLink}
                        class="btn btn-primary tx-12">
                        <ButtonLoader isLoading={isLoading} btnText="SAVE" />
                        <i data-feather="save" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal effect-scale" id="ref-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="external-link"
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">References</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Add reference links.</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->
            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label class="d-block">Reference link name <span class="required-field">*</span></label>
                        <input bind:value={refName} type="text" on:keyup={(e)=> e.key === 'Enter' && addNewRef()}
                        on:blur={()=>clearError("refName")}
                        class="form-control" class:parsley-error={errorObj.refName} placeholder="Reference link name"/>
                        {#if errorObj.refName}
                        <div class="parsley-errors-list">{errorObj.refName}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                    <div class="form-group col-md-12">
                        <label class="d-block">Reference URL <span class="required-field">*</span></label>
                        <input bind:value={refUrl} type="text" on:keyup={(e)=> e.key === 'Enter' && addNewRef()}
                        on:blur={()=>clearError("refUrl")}
                        class="form-control" class:parsley-error={errorObj.refUrl} placeholder="Reference URL"/>
                        {#if errorObj.refUrl}
                        <div class="parsley-errors-list">{errorObj.refUrl}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                </div>
            </div>
            <div class="modal-footer pd-t-0">
                <div class="pd-12">
                    <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CLOSE
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button type="button" disabled={isLoading} on:click={addNewRef} class="btn btn-primary tx-12">
                        <ButtonLoader isLoading={isLoading} btnText="SAVE" />
                        <i data-feather="save" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal effect-scale" bind:this="{modalVideoPreview}" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div><!-- modal-header -->
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <hr class="full-width">

                <!-- svelte-ignore a11y-media-has-caption -->
                <video bind:this="{vidPreview}" src="{previewVideoSrc}" style="width: 100%; height: 100%;" controls>
                </video>


                <div class="d-md-flex justify-content-end tx-12 mg-t-30">
                    <button type="button" class="btn  btn-secondary tx-13 mg-md-r-40 "
                        data-dismiss="modal">Close</button>
                </div>

            </div>

        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal effect-scale" id="delete-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="trash-2"
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Delete</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Delete or unlink this study material</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->
            <hr class="full-width">
            <div class="modal-body  pd-l-25 pd-r-25 pd-b-25 pd-t-0 ">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <ul class="list-group list-group-flush tx-13">
                            <p class="tx-13 tx-color-03 pd-t-20 pd-b-10 mg-b-0">>> Current Link</p>
                            <li class="list-group-item d-flex bd-0 pd-sm-x-20">
                                <div class="avatar">
                                    <span class="avatar-initial rounded-circle bg-gray-600"><i data-feather="link"
                                            class="wd-10" /></span>
                                </div>
                                <div class="pd-l-10">
                                    {#if currentFileObj.fullPath}
                                    <p class="tx-medium mg-b-0">
                                        {currentFileObj.fullPath.length? currentFileObj.fullPath.join(" / "): "-"}
                                    </p>
                                    {/if}
                                    <small class="tx-12 tx-color-03 mg-b-0">{currentFileObj.batchName}</small>
                                </div>

                            </li>
                            {#if linkArr.length}
                            <p class="tx-13 tx-color-03 pd-t-20 pd-b-10 mg-b-0">>> Another Links</p>
                            {/if}
                            {#each linkArr as item}
                            <li class="list-group-item bd-0 d-flex pd-sm-x-20 bg-0">
                                <div class="avatar">
                                    <span class="avatar-initial rounded-circle bg-gray-600"><i data-feather="link"
                                            class="wd-10" /></span>
                                </div>
                                <div class="pd-l-10">
                                    <p class="tx-medium mg-b-0">
                                        {item.fullPath.join(" / ")}
                                    </p>
                                    <small class="tx-12 tx-color-03 mg-b-0">{item.batchName}</small>
                                </div>
                                <div class="mg-l-auto d-flex align-self-center">
                                    <nav class="nav nav-icon-only">
                                        <a on:click|preventDefault={()=> deleteLink(item._id)}
                                            href="#"
                                            class="nav-link d-none d-sm-block">
                                            <i data-feather="trash-2" class="wd-10 mg-r-5" />
                                        </a>
                                    </nav>
                                </div>
                            </li>
                            {/each}
                        </ul>
                        {#if !linkArr.length && !currentFileObj}
                        <div class="tx-danger">
                            No links found for this file, if you want to delete the file you can delete pramanently!!!
                        </div>
                        {/if}
                    </div>
                </div>
            </div>
            <div class="pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <button type="button" class="btn btn-danger tx-12 float-right"
                    on:click={handleDeletePermanently(currentFileObj.fileId)}>Permanent Delete
                    <i data-feather="delete" class="wd-10 mg-l-5"></i>
                </button>
                {#if linkArr.length || currentFileObj}
                <button type="button" class="btn btn-primary pd-r-5 mg-r-15 tx-12 float-right"
                    on:click={handleUnlinkAll}>
                    <!-- <ButtonLoader isLoading={isLoading} btnText="SAVE" />  -->
                    Unlink All
                    <i data-feather="delete" class="wd-10 mg-l-5"></i>
                </button>
                <button type="button" class="btn btn-primary pd-r-5 mg-r-15 tx-12 float-right"
                    on:click={handleUnlinkCurrent(currentFileObj._id)}>
                    <!-- <ButtonLoader isLoading={isLoading} btnText="SAVE" />  -->
                    Unlink Current
                    <i data-feather="delete" class="wd-10 mg-l-5"></i>
                </button>
                {/if}
            </div>
        </div>
    </div>
</div>


<!-- Forward Modal -->
<div class="modal effect-scale" id="forwardToSchedule" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel6"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="alert-circle"
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Functionality Moved</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Functionality has been moved to another screen</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <p class="tx-15 mg-l-40 pd-0"> Functionality of this screen has been moved to schedule!!!</p>

                <div class="d-md-flex justify-content-end mg-t-25">
                    <button class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal">Ok
                    </button>
                    <button on:click={()=>{navigate(`/dashboard/schedule`)}}
                        class="btn btn-sm pd-x-15 btn-primary mg-l-5" data-dismiss="modal">
                        Go there
                        <i data-feather="log-in" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Modal for add folder-->
<div class="modal effect-scale" id="modal-new-folder" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel6"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="help-circle"
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">{isEdit ? "Edit Folder" :"Add New Folder"}</h4>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <hr class="full-width">
                <div class=" tx-color-02">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label class="d-block">Folder Name <span class="required-field">*</span></label>
                            {#if isEdit }
                            <input bind:value={editfolderObj.name} type="text" class="form-control"
                                class:parsley-error={errorObj.name} placeholder="Enter folder name" />
                            {:else}
                            <input bind:value={folderObj.name} type="text" class="form-control"
                                class:parsley-error={errorObj.name} placeholder="Enter folder name" />
                            {/if}

                        </div>
                    </div>
                </div>
                <div>
                    <label class="d-block">Icon</label>
                    {#if isEdit && editfolderObj.icon }
                    <img src={editfolderObj.icon} alt="icon" height="100" width="100" class="mb-2" />
                    {/if}
                    <input type="file" bind:this={elem_addImageInput} class="my-pond" data-instant-upload="false"
                        data-allow-image-preview="true" name="filepond" />
                </div>


                <!-- <div class="form-group col-md-12 d-none">
                    <label class="d-block">Image</label>
                    <input type="file" bind:this={elem_uploadImageInput} class="my-pond" data-instant-upload="false"
                        data-allow-image-preview="false" name="filepond" />
                </div> -->
                <div class="d-md-flex justify-content-end tx-12 mg-t-30">
                    <button type="button" class="btn  pd-x-15 btn-secondary tx-12" data-dismiss="modal">CANCEL
                        <i data-feather="x" class="wd-10 mg-l-5 "></i>
                    </button>


                    {#if isEdit}
                    <button type="button" disabled={isLoadingSaveAndProceed} proceed="no" on:click={updateDetails}
                        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <ButtonLoader isLoading={isLoadingSaveAndProceed} btnText="Save & Proceed" />
                        <!-- <i data-feather="arrow-right" class="wd-10 mg-l-5"></i> -->
                    </button>
                    {:else}
                    <button type="button" disabled={isLoadingSaveAndProceed} proceed="no" on:click={handleClickProceed}
                        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <ButtonLoader isLoading={isLoadingSaveAndProceed} btnText="Save & Proceed" />
                        <!-- <i data-feather="arrow-right" class="wd-10 mg-l-5"></i> -->
                    </button>
                    {/if}

                </div>

            </div>

        </div>
    </div>
</div>
