<script context="module">
  import { API_URL } from "../../config.js"

  export async function api(action, data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var payload = JSON.stringify({
      accessToken: localStorage.getItem("accessToken"),
      action,
      data,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };

    try {
      var resObj = await fetch(API_URL, requestOptions);
      var response = await resObj.json();

      // Format the response to ensure consistent structure
      return {
        status: response.status || false,
        data: response.data || null,
        errMsg: response.errMsg || response.error || null
      };
    } catch (error) {
      console.error("API Error:", error);
      return {
        status: false,
        data: null,
        errMsg: error.message || "An unexpected error occurred"
      };
    }
  }
</script>
