<script>
  import { onMount } from "svelte";
  import { activeMenu } from "../../stores";
  import * as constants from "../../utils/constants";
  import { navigate } from "svelte-routing";

  export let params = {};

  function initBoard() {
    let { boardId } = params || { boardId: "" };
    if (!boardId) {
      navigate("/dashboard/aptiBooster");
      return;
    }
    return boardId;
  }

  let boardId = initBoard();

  let classes = [
    constants.CLASS_3,
    constants.CLASS_4,
    constants.CLASS_5,
    constants.CLASS_6,
    constants.CLASS_7,
    constants.CLASS_8,
    constants.CLASS_9,
    constants.CLASS_10,
  ];

  let boardName =
    boardId === "cbse" ? constants.CBSE : constants.MAHARASHTRA_BOARD;

  function handleClassClick(className) {
    const encodedClassName = encodeURIComponent(className);
    navigate(`/dashboard/aptiBooster/${boardId}/${encodedClassName}/subjects`);
  }

  onMount(() => {
    feather.replace();
    activeMenu.set("aptiBooster");
  });
</script>

<div class="content-body fadeIn">
  <div class="container px-3">
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item">
              <a href="/dashboard/aptiBooster">Apti Booster</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {boardName}
            </li>
          </ol>
        </nav>
        <h4 class="mg-b-0 tx-spacing--1">Classes - {boardName}</h4>
      </div>
    </div>

    <div class="row row-xs mg-b-20">
      {#each classes as className}
        <div
          class="col-sm-6 col-lg-4 col-xl-3 mg-b-20"
          on:click={() => handleClassClick(className)}
        >
          <div class="media media-folder class-item">
            <div class="avatar avatar-sm">
              <div
                class="avatar-initial rounded-circle bg-white bd bd-1 bd-primary tx-primary"
              >
                {className.split(" ")[1]}
              </div>
            </div>
            <div class="media-body">
              <h6>
                <span class="link-02">
                  {className}
                </span>
              </h6>
            </div>
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>

<style>
  .class-item {
    padding: 15px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
  }

  .class-item:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
</style>
