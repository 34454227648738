<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import ProgressBar from "./list/ProgressBar.svelte"

    export let batchId
    export let parentId
    export let totalTopicCount = 0
    export let tillFullPath = []
    export let chapterName = ''
    let emptyState = false;

    let isLoading = false
    let instanceStudentsListTable

    let studentsList = []
   

    const viewStudentClick = (id)   =>  {
        navigate(`/dashboard/student/view/${id}/studyMonitoringChart/${tillFullPath.join('-')}`)
    }
    function getFilterData() {
        
    return {
     batchId,
     parentId
    };
  }
  
    const initStudentsTable = () => {
      if(instanceStudentsListTable) instanceStudentsListTable.destroy()
           instanceStudentsListTable = _$('#studentsListTable').DataTable({
            stateSaveCallback: function (settings, data) {
                   localStorage.setItem(window.location.pathname, JSON.stringify(data));
               },
               stateLoadCallback: function (settings) {
                   return JSON.parse(localStorage.getItem(window.location.pathname));
               },   
            responsive: false,
               select: true,
            //    "columnDefs": [
            //        { "orderable": false, "targets": [0] },
            //    ],
            language: {
                   processing: "",
                   searchPlaceholder: "Student Name | Number",
                   sSearch: "",
                   lengthMenu: "_MENU_ Students/page",
               },
               ajax: function (data, callback, settings) {
                   var filterObj = {  ...getFilterData()};
                  
                   filterObj.start = data.start;
                   filterObj.length = data.length;
                   filterObj.search = data.search.value;
                   filterObj.draw = data.draw;
                   filterObj.sort = data.sort;

                   _$.ajax({
                       url: API_URL,
                       method: "post",
                       data: {
                           action: "studyMonitoring.getStudentsOfBatch",
                           accessToken: localStorage.getItem("accessToken"),
                           data: filterObj,
                       },
                       success: async function (resData) {
                           var result = [];
                           if (resData.status) {
                               var paginationData = resData.data;
                               result = {
                                   recordsTotal: Number(paginationData.totalCount),
                                   recordsFiltered: Number(paginationData.filteredCount),
                                   data: paginationData.data,
                               };
                               if (!resData.data.data.length) {
                                   emptyState = true;
                               }
                               studentsList = paginationData.data;
                               studentsList = studentsList
                               await tick();
                               callback(result);
                           } else {
                               console.log("Something went wrong");
                           }
                       },
                       error: function (XMLHttpRequest, textStatus, errorThrown) {
                           emptyState = true;
                       },
                   });
               },
               columns: [
                    {
                        data: '_id',
                        render: function(data, type, full, meta) {
                            return Number(meta.row) + 1
                        }
                    },
                    {
                        data: 'name',
                        render: function(data, type) {
                            return data
                        }
                    },
                    {
                        data: 'mobileNo',
                        render: function(data, type) {
                            return data;
                        }
                    },
                    {
                        data: 'markedCompleted',
                        render: function (data, type) {
                            let percentage = getPercentage(Object.keys(data).length, totalTopicCount)
                            let progressHtml =  `
                                <div class="media mt-2">
                                    <i data-feather="pie-chart" class="wd-20 ht-20"></i>
                                    <div class="media-body mg-l-10">
                                        <div class="tx-12 mg-b-4">${percentage}% Completed</div>`

                                if(percentage < 30){
                                    progressHtml += `
                                        <div class="progress ht-3 mg-b-10">  
                                            <div class="progress-bar bg-danger wd-${percentage}p" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    `
                                }
                                else if (percentage < 70)   {
                                    progressHtml += `
                                        <div class="progress ht-3 mg-b-10">  
                                            <div class="progress-bar bg-warning wd-${percentage}p" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    `

                                } else {
                                    progressHtml += `
                                        <div class="progress ht-3 mg-b-10">  
                                            <div class="progress-bar bg-success wd-${percentage}p" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    `
                                }
                                        

                            progressHtml +=  `</div>
                                </div>
                            `
                            return progressHtml
                            // <ProgressBar percentage={`${getPercentage(Object.keys(data).length, totalTopicCount)}`} />
                        } 
                    },
                    {
                        data: '_id',
                        render: function (data, type) {
                            return `<button class="btn btn-xs btn-primary btn-view-student" data-id="${data}">View</button>`
                        }
                    },
                ],
                "drawCallback": function(settings) {
                   _$('.btn-view-student').on('click', function (e) {
                       let id = _$(this).attr('data-id')
                       viewStudentClick(id)
                    })
                },
                processing: true,
                serverSide: true,
           })
    };
    const getPercentage = (count, total) => {
        if(total == 0) return 0
        return ((count / total) * 100).toFixed()
    }
    onMount(async () => {
           await tick()
            settingUpTable()
    })

    const settingUpTable = () => { 
        // let rows = ''
        // for (let idx = 0; idx < studentsList.length; idx++) {
        //     const element = studentsList[idx];
        //     rows += ` <tr>
        //         <td>${student.name}</td>
        //         <td>${student.mobileNo}</td>
        //         <td><ProgressBar percentage="${getPercentage(Object.keys(student.markedCompleted).length, totalTopicCount)}" /></td>
        //         <td><button class="btn btn-xs btn-primary" on:click={()=>{viewStudentClick(student._id)}}>View</button></td>
        //     </tr>`
        // }

        _$('#table-container').html(`
            <table id="studentsListTable" class="table studentsListTable bg-white animated fadeIn">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Student Name</th>
                        <th>Mobile Number</th>
                        <th>Progress</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    
                </tbody>
            </table>
        `)

        initStudentsTable()
     }

</script>
<div class="d-flex flex-row align-items-center">
    <h5 class="mt-3 ml-3 text-muted"><i data-feather="pie-chart" class="wd-20 ht-20"></i> Showing Students report for {chapterName}</h5>
    {#if isLoading}
        <div class="text-primary spinner-border spinner-border-sm ml-2 mt-2" role="status"></div>
    {/if}
</div>
<div class="mt-4 pd-5" style="padding: 1rem;">
    <div id="table-container">
        
    </div>
</div>
