<script>
  import { onMount } from "svelte";
  import { link, links, navigate } from "svelte-routing";
  import { activeMenu } from "../../stores";
  import Empty from "../empty/empty.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte"
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js"
  import { userData } from "../../stores";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import { API_URL } from "../../../config.js"


  let elem_datatable;
  let isEmpty = false;
  let isSearch = false;
  let filterModalThis;
  let status;
  let selectizeBatchInstance1
  let filterStudentName;
  let filterStartDate;
  let isLoading = false;
  let userIdArr;
  let dataTableInstance;
  let isAllDoubts = false;
  let systemUserData
  let batchList = []
  let batchIdArr = []
  var paginationData
  let doubtsBatchId
  function getFilterData() {
    let createdOn = filterStartDate
    let userIdArr = _$(selectizeBatchInstance1).val()


    return {
      createdOn,
      status,
      userIdArr
    }
  }
  let localUserData = {
    fullName: '...',
    role: "...",
    avatar: "/images/man.png",
    access: {
      home: false,
      students: false,
      settings: false
    }
  };

  const unsubscribe = userData.subscribe(value => {
    localUserData = value;
  });

  function redrawLeaveRequestTable() {
    if (dataTableInstance) { dataTableInstance.draw() }
  }

  function applyFilter() {
    redrawLeaveRequestTable();

  }

  function clearFilter() {
    status = null;
    filterStartDate = null;
    selectizeBatchInstance1[0].selectize.clear()
    redrawLeaveRequestTable();

  }

  function hideFilterModel(params) {
    _$("#filter-modal").modal("hide");
    assignmentObj.topic = "";
  }
  function showFilterModel(params) {
    _$("#filter-modal").modal("show");
  }

  let showHideFilterModal = () => {
    _$('#filter-modal').modal('show');
  }

  async function api_ReportToList() {
    var resData = await api_handleApiCalls('user.getAllUsers', {})
    if (resData.status) {

      return resData.data.data
    } else {
      return []
    }
  }

  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [day, month, year].join("-");
    return dateString;
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = -1;
    else order = 1;
    if (sortOrder.column === 1) {
      sort.name = order;
    }

    sort._id = order;
    return sort;
  }

  async function bindSelect2() {


    selectizeBatchInstance1 = _$(filterStudentName).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "select student",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: userIdArr,
    });
  }

  function initDatatable() {
    dataTableInstance = _$(elem_datatable).DataTable({
      serverSide: true,
      responsive: false,
      language: {
        processing: "",
        searchPlaceholder: "Student ID | Name",
        sSearch: "",
        lengthMenu: "_MENU_ Doubts/page",
      },
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data));
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname));
      },
      ajax: async function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        if (isAllDoubts == true) {
          var filterObj = { ...getFilterData() }
          filterObj.start = data.start
          filterObj.length = data.length
          filterObj.search = data.search.value
          filterObj.draw = data.draw
          filterObj.sort = data.sort;
          filterObj.isAllDoubts = isAllDoubts
        } else {

          var filterObj = { ...getFilterData() }
          filterObj.start = data.start
          filterObj.length = data.length
          filterObj.search = data.search.value
          filterObj.draw = data.draw
          filterObj.sort = data.sort;
        }

        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: 'discussionForum.list',
            accessToken: localStorage.getItem("accessToken"),
            data: filterObj
          },
          success: function (resData) {
            var result = []
            if (resData.status) {
              paginationData = resData.data;

              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data
              }
              if (!resData.data.length) {
                emptyState = true
              }
              callback(result);
            }
            else {
              isEmpty = true
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },

      columns: [
        {
          data: "studentName",
          render: (data, type, full) => {
            let image;
            // if image found display profilePic avatar
            image = `<img src="${full.profilePic ? full.profilePic : "/images/man.png"
              }" class="avatar mg-r-15" alt="${data.toUpperCase()}">`;
            return `
            <div class = "list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 btn-view tx-capitalize" data-id="${full._id}" feedback-id="${full._id}">
              ${image}
              <div class="wd-80p bg-transparent">
                <h6 class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent tx-capitalize" class = "btn-view tx-capitalize" data-id="${full._id}">${data || "-"
              }</h6>
                <span class="d-block tx-11 text-muted bg-transparent">
                    <i data-feather="phone"></i> 
                    ${full.mobileNumber || "-"}
                </span>
              </div>
            </div>`;;

          },
        },
        {
          data: "doubtText",
          render: (data, type, full) => {
            return `
                <div style=" overflow: hidden; width:200px; white-space: nowrap;
  text-overflow: ellipsis; ;text-transform: capitalize. text-align:center;" class = "btn-view tx-capitalize"  data-id="${full._id}"
  data-toggle="tooltip" title="${data}">
                   ${data}
              </div>
                `
          }
        },

        {
          data: "status",
          render: (data, type, full) => {
            return `
                <div style="max-height: 32px !important; overflow: hidden; ;text-transform: capitalize. text-align:center;" class = "btn-view tx-capitalize" data-id="${full._id}">
              ${data ? data : " - "}
            </div>`
          }
        },
        {
          data: "_id",
          render: (data, type, full) => {
            return `
                <div style="max-height: 32px !important;width:100px overflow: hidden; ;text-transform: capitalize. text-align:center;" class = "btn-view tx-capitalize" data-id="${full._id}">
              ${full.assignee ? full.assignee : " - "}
            </div>`
          }
        },
        {
          data: "folderPath",
          render: (data, type, full) => {
            if (!full.folderPath) {
              return `<div style="max-height: 32px !important; overflow: hidden; ;text-transform: capitalize. text-align:center;" class = "btn-view tx-capitalize" >
              -
            </div>`
            }
            return `
                <div style="max-height: 32px !important; overflow: hidden; width:120px; white-space: nowrap;
  text-overflow: ellipsis; ;text-transform: capitalize. text-align:center;" class = "btn-view tx-capitalize" data-id="${full._id}"  data-toggle="tooltip" title="${full.folderPath}">
              ${full.folderPath}
            </div>`
          }
        },
        {
          data: "createdOn",
          render: (data, type, full) => {
            let finalData = convertDateObjToStr(data)
            return `
                <div style=" max-height: 32px !important; overflow: hidden;text-align:center;" class = "btn-view tx-capitalize" data-id="${full._id}">
              ${finalData}
            </div>`
          }
        },
        {
          data: "attachments",
          render: (data, type, full) => {
            if (!full.attachement.url) {
              return `<div class="d-flex justify-content-center">-</div>`
            }
            if (full.attachement.downloadUrl) {
              let fileData
              fileData = `<a href="${full.attachement && full.attachement.downloadUrl ? full.attachement.downloadUrl : "-"}" data-id="${data}" target="_blank"><i data-feather="file"></i> </a>`

              return `
                <div style=" max-height: 32px !important; overflow: hidden;text-align:center;" class = "btn-view tx-capitalize" data-id="${full._id}">
                        ${fileData}
                      </div>`
            }
          },
        },
        {
          data: "_id",
          orderable: false,
          render: (data, type, full) => {


            var view = "-"
            if (MODULES.hasAccess(MODULES.VIEW_DISCUSSION_DETAILS)) {
              view = `<a href="javascript:void(0)" data-id="${data}" data-batch-id="${full.batchId}" data-status="${full.status}" data-assigned-to="${full.assignedTo}" class='btn-view mg-r-5' data-toggle="tooltip" data-placement="bottom" title="View"><i data-feather="edit" ></i> </a>`
            }
            return `
                      <div style="text-align: center;">
                        ${view}
                      </div>
                        `;
          },
        },
      ],
      columnDefs: [
        { targets: 0, width: "20%" },
        { targets: 1, width: "10%" },
        { targets: 2, width: "10%" },
        { targets: 3, width: "18%" },
        { targets: 4, width: "15%" },
        { targets: 4, width: "15%" }
      ],

      drawCallback: function () {
        if (MODULES.hasAccess(MODULES.VIEW_DISCUSSION_DETAILS)) {
          feather.replace({ width: "1em", height: "1em" });

          _$(".btn-view").on("click", function (event) {
            if (localUserData.role != "orgAdmin" && localUserData.role != "Admin" && isAllDoubts) {

              let batchId = _$(this).data("batch-id");
              let status = _$(this).data("status");
              let assignedTo = _$(this).data("assigned-to");

              // let condition1 =  status == "assigned" && assignedTo !== localUserData._id  && batchId == null
              let isNotAssignedToUserOrBatch = batchId == null && status == "unassigned"
              let isAssignedToUserButNotInBatch = batchId == null && status == "assigned" && assignedTo === localUserData._id
              let isInBatch = batchIdArr.find(x => x === batchId)
              let isNotAssignedButInBatch = status == "unassigned" && batchIdArr.find(x => x === batchId)
              let isAssignedToUser = status == "assigned" && assignedTo === localUserData._id

              if (isNotAssignedToUserOrBatch || isAssignedToUserButNotInBatch || isInBatch || isNotAssignedButInBatch || isAssignedToUser) {
                let id = _$(this).data("id");
                // _$(this).tooltip("dispose");
                let route = `/dashboard/discussionForum/${id}`;
                navigate(route);
              }
              else {
                pError(null, "User does not have access to these doubts");
                return false
              }

            }
            else {
              let id = _$(this).data("id");
              // _$(this).tooltip("dispose");
              let route = `/dashboard/discussionForum/${id}`;
              navigate(route);
            }



          });
        }
      },
    });

    //other than databale
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }
  async function api_fetchSystemUser(data) {

    let action = "systemUser.fetchSystemUser";
    var response = await api_handleApiCalls(action, data);
    return response.data;
  }


  onMount(async () => {
    feather.replace();
    activeMenu.set("discussionForum");
    userIdArr = await api_ReportToList()
    let _id = localUserData._id
    systemUserData = await api_fetchSystemUser({ _id })
    if (systemUserData.batchList) {
      batchList = systemUserData.batchList
      batchIdArr = []

      for (let index = 0; index < batchList.length; index++) {
        if (batchList[index].active) {
          let batchId = batchList[index].batchId;

          batchIdArr.push(batchId)
        }

      }
    }


    initDatatable();
    bindSelect2();
  });

  async function showAllDoubts() {

    isAllDoubts = true
    // redrawLeaveRequestTable()
    dataTableInstance.ajax.reload(null, false); // use
  }
  async function showUsersDoubts() {

    isAllDoubts = false
    // redrawLeaveRequestTable()
    dataTableInstance.ajax.reload(null, false); // use
  }
</script>

<style>
</style>

<div class="content-body animated scale">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Discussion Forum </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Discussion Forum</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search all the doubts.</p>
    </div>
    <div class="d-block">
      {#if localUserData.role != 'orgAdmin' && localUserData.role != 'Admin'}

      {#if isAllDoubts}
      <button class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase" on:click={()=>{showUsersDoubts()}}>Show Users
        Doubts</button>
      {:else}
      <button class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase" on:click={()=>{showAllDoubts()}}>Show all
        Doubts</button>
      {/if}
      {/if}
      <button class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase" on:click={()=>{showHideFilterModal(true)}}>
        <i data-feather="filter" class="mg-r-5"></i> Filters</button>

    </div>

  </div>
  <!-- Filter Modal -->
  <div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
          <a role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="media align-items-center">
            <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
            <div class="media-body mg-sm-l-20">
              <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
              <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
            </div>
          </div><!-- media -->
        </div><!-- modal-header -->

        <hr class="full-width">
        <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
          <div class="form-group">
            <label class="d-block">Status:</label>
            <div class="d-md-flex">
              <div class="custom-control custom-radio mg-r-15">
                <input type="radio" id="completed" bind:group={status} class="custom-control-input" value='completed'>
                <label class="custom-control-label" for="completed">Completed</label>
              </div>
              <div class="custom-control custom-radio mg-r-15">
                <input type="radio" bind:group={status} id="assigned" class="custom-control-input" value='assigned'>
                <label class="custom-control-label" for="assigned">Assigned</label>
              </div>
              <div class="custom-control custom-radio mg-r-15">
                <input type="radio" bind:group={status} id="unassigned" class="custom-control-input" value='unassigned'>
                <label class="custom-control-label" for="unassigned">Unassigned</label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="d-block">Student name:</label>
            <select disabled={isLoading} bind:this={filterStudentName} class="form-control select2" multiple="multiple">
              <option> Select Student name</option>
            </select>
          </div>
          <div class="form-group">
            <label class="d-block">Added on Date:</label>
            <input type="date" onkeydown="return false" bind:value={filterStartDate} class="form-control follow-up-date"
              placeholder="Added on Date" />
          </div>

          <div class="d-md-flex justify-content-end mg-t-25">
            <button disabled={isLoading} on:click={clearFilter} class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5"
              data-dismiss="modal">CLEAR
              <i data-feather="x" class="wd-10 mg-l-5"></i>
            </button>
            <button disabled={isLoading} on:click={applyFilter} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
              data-dismiss="modal">
              <ButtonLoader isLoading={isLoading} btnText="APPLY" />
              <i data-feather="filter" class="wd-10 mg-l-5"></i>
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Modal -->
  <hr class="full-width">
  <div data-label="Example" class="df-example demo-table">
    <table bind:this={elem_datatable} class="table" style="width:100%">
      <thead>
        <tr>
          <th style="text-align: left;" class="wd-20p">Student Name</th>
          <th class="wd-20p">Doubt Text</th>
          <th style="text-align: left;" class="wd-10p">Status</th>
          <th style="text-align: left;">Assigned To</th>

          <th style="text-align: left;" class="wd-20p">Category</th>
          <th style="text-align: left;" class="wd-10p">Created On</th>
          <th style="text-align: left;" class="wd-10p">Attachments</th>
          <th style="text-align: left;" class="wd-10p">Actions</th>
        </tr>
      </thead>
      <tbody />
    </table>

    {#if isEmpty}
    {#if isSearch}
    <Empty mode="search" />
    {:else}
    <Empty mode="empty" />
    {/if}
    {/if}
  </div>
</div>
