<script>
  import { Router, links, link, Link, Route, navigate } from "svelte-routing";
  import { afterUpdate, onMount, tick } from "svelte";
  import { pConfirm, pSuccess, pError, pAlert } from "../../utils/notify.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js"
  const rolesData = [
    { value: "", text: "All Roles" },
    { value: "orgAdmin", text: "Org Admin" },
    { value: "admin", text: "Admin" },
    { value: "batchManager", text: "Batch Manager" },
    { value: "faculty", text: "Faculty" },
    { value: "telecaller", text: "Tele Caller" },
    { value: "dtp", text: "DTP" },
  ];
  let displayText = "System User List";
  let accessProfileId = "",
    batchId = "",
    role = "";
  let systemUsersDatatable;
  let filterElemRole;
  let filterElemBatch;
  let filterModalThis;
  let search = { value: "" };
  let isLoading = false;
  let isSortByName
  let fromDatePicker,
    toDatePicker,
    elem_accessProfileFilter,
    elem_batchFilter,
    elem_roleFilter;
  let firstInputField;
  let addNewUserModal;
  let systemUserDetails = {
    name: "",
    mobileNo: "",
  };
  let errorObj = {
    name: "",
    mobileNo: "",
  };
  let batchList = [],
    accessProfilesList = [];
  const dummyData = [
    { _id: 0, name: "name", createdOn: new Date() },
    { _id: 1, name: "name1", createdOn: new Date() },
  ];

  let orgAccessProfiles = [];
  let elem_orgAccessProfiles;
  let treeJson;
  let selectedRole;

  async function api_delete(data) {

    let action = "systemUser.removeSystemUser";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_fetchBatchList(data) {

    let action = "systemUser.fetchBatchList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  function clearFilter() {
    _$(elem_roleFilter)[0].selectize.setValue("");
    _$(elem_batchFilter)[0].selectize.setValue("");
    reload();
  }

  function applyFilter() {
    reload();
  }

  async function api_list(data) {

    let action = "systemUser.fetchSystemUserList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  // async function api_fetchAccessProfileList(data) {

  //   let action = "systemUser.fetchAccessProfilesList";
  //   var response = await api_handleApiCalls(action, data);
  //   return response;

  // }

  function validate() {
    //   clear previous validations before validating anew
    clearValidations();

    var regexpName = new RegExp(`^[A-Za-z\\s]+$`);
    var regexpMobileNumber = new RegExp(`^[6-9]{1,1}[0-9]{9,9}$`);

    // name
    if (!Boolean(systemUserDetails.name)) {
      errorObj.name = "Full Name can not be empty";
    } else if (!systemUserDetails.name.match(regexpName)) {
      errorObj.name =
        "Full Name must have only A-Z or a-z characters and space";
    } else if (systemUserDetails.name.length < 3) {
      errorObj.name = "Full Name should be minimum 3 characters long";
    } else if (systemUserDetails.name.length > 80) {
      errorObj.name = "Full Name should not exceed 80 characters long";
    }

    // mobilenumber
    if (!Boolean(systemUserDetails.mobileNo)) {
      errorObj.mobileNo = "Mobile Number can not be empty";
    } else if (!`${systemUserDetails.mobileNo}`.match(regexpMobileNumber)) {
      errorObj.mobileNo = "Invalid Mobile Number";
    } else if (`${systemUserDetails.mobileNo}`.length < 10) {
      errorObj.mobileNo = "Mobile Number should be 10 digits long";
    } else if (`${systemUserDetails.mobileNo}`.length > 10) {
      errorObj.mobileNo = "Mobile Number should be 10 digits long";
    }

    if (!selectedRole) {
      errorObj.role = "Please select a role for the user";
    }

    if (Object.values(errorObj).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async function api_save(data) {

    let action = "systemUser.saveSystemUser";
    var response = await api_handleApiCalls(action, data);
    return response;

  }


  let getDisabledIds = function(selectedIds){
      let plainArr = getPlainArrFromTree(treeJson);
      let disabledModules = [];
      plainArr.forEach(aArr => {
        let found = false;
        selectedIds.forEach(aSelectedId => {
          if(aSelectedId == aArr.id){
            found = true;
          }
        });

        if(!found){
          disabledModules.push(aArr.nodeId);
        }
      });

      return disabledModules

  }
  async function submitForm(event) {
    await tick()
    var newErrorObj = {}
        if (!systemUserDetails.name && !Boolean(systemUserDetails.name)) {
            newErrorObj.name = "Name is required.";
        }
        if (!systemUserDetails.mobileNo) {
            newErrorObj.mobileNo = "Mobile No is required";
        }
       let  selectedRole = _$(elem_orgAccessProfiles)[0].selectize.getValue();
            if (!selectedRole) {
                newErrorObj.role = "Please select a role ";
            } 

        
        errorObj = newErrorObj
        if (Object.keys(errorObj).length > 0) {
            isLoading = false;
            return null;
        }
    let selectedIds =  _$.jstree.reference('#jstree').get_selected();

    let disabledModules = getDisabledIds(selectedIds);
    // console.log("disabledModules : ", disabledModules);
    if (event) event.preventDefault();
    if (isLoading) return;
    isLoading = true;
    await tick();
    const isValid = validate();
    // console.log({ isValid, errorObj });
    if (isValid) {
      {
        // console.log("adding", { systemUserDetails, errorObj });
      }

      systemUserDetails.disabledModules = disabledModules;
      systemUserDetails.selectedRoleId = selectedRole;

      orgAccessProfiles.forEach(aAccessProfile => {
        if(aAccessProfile._id.toString() == selectedRole){
          systemUserDetails.role = aAccessProfile.name;
        }
      });



      // save systemuser api call
      let result = await api_save(systemUserDetails);
      if (result.status) {
        pSuccess("", "System user added successfully");
        _$(addNewUserModal).modal("hide");
        clearFields();
        navigate("systemuser/" + result.data);
      } else {
        pError("", result.errMsg);
      }
    }
    isLoading = false;
    await tick();
  }

  function clearFields() {
    // form fields
    systemUserDetails.name = "";
    systemUserDetails.mobileNo = "";
    clearValidations();
    _$(elem_orgAccessProfiles)[0].selectize.setValue("");
    _$('#jstree').addClass('d-none')
    // _$(elem_orgAccessProfiles).val("")
  }

  async function clearValidations() {
    errorObj = {};
    await tick();
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.name = order;
    } else if (sortOrder.column === 2) {
      sort.role = order;
    } else if (sortOrder.column === 3) {
      sort.batchList = order;
    } else if (sortOrder.column === 4) {
      sort.batchList = order;
    } else {
      sort._id = order;
    }
    return sort;
  }

  async function initDropdown() {
    // Put this code after you've included Selectize
    // but before any selectize fields are initialized
    var prevSetup = Selectize.prototype.setup;

    Selectize.prototype.setup = function () {
      prevSetup.call(this);

      // This property is set in native setup
      // Unless the source code changes, it should
      // work with any version
      // this.$control_input.prop("readonly", true);
    };
    // data = data.map((item) => {item = {...item, name: item.name.toUpperCase()}; return item});

    // role filter selectize
    _$(elem_roleFilter).selectize({
      maxItems: 1,
      create: false,
      valueField: "name",
      labelField: "name",
      searchField: "name",
      options: accessProfilesList,
      items: [""],
      onChange: function (value) {
        role = value;
      },
      // load: async function (query) {
      //   console.log("load", query);

      // },
    });
    // access profile filter selectize
    _$(elem_accessProfileFilter).selectize({
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      options: [
        { _id: "", name: "All Access Profiles" },
        ...accessProfilesList,
      ],
      create: false,
      items: [""],
      onChange: function (value) {
        accessProfileId = value;
      },
      // load: async function (query) {
      //   console.log("load", query);

      // },
    });
    // batch filter selectize
    _$(elem_batchFilter).selectize({
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      options: [...batchList],
      plugins: ["remove_button"],
      placeholder: 'Select Batches',
 
      create: false,
      items: [""],
      onChange: function (value) {
        if(value[0] != "") {
          batchId = value;
        } else batchId = []
      },
      // load: async function (query) {
      //   console.log("load", query);
      //   let result = await initBatchList(query)
      //   callback(result)
      // },
    });
    Selectize.prototype.setup = prevSetup;
  }

  async function initDatatable() {
    _$(systemUsersDatatable).DataTable({
      // dom: 'flr<"toolbar">tip',
      serverSide: true,
      responsive: false,
      language: {
        processing: "",
        searchPlaceholder: "Name | Email | Mobile",
        sSearch: "",
        lengthMenu: "_MENU_ System User List/page",
      },
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data));
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname));
      },
      ajax: async function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "asc" });
        delete data.order;
        data.accessProfileId = accessProfileId;
        data.batchId = batchId;
        data.role = role;
        console.info(data);
        // fetch call
        let response = await api_list(data);
        console.info({ response });
        if (response.status) {
          var paginationData = response.data;
          var result = {
            recordsTotal: Number(paginationData.recordsTotal),
            recordsFiltered: Number(paginationData.recordsFiltered),
            data: paginationData.data,
          };
          callback(result);
        } else {
          console.error("something went wrong");
          var result = {
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          };
          callback(result);
        }
      },
      columns: [
        // {
        //   data: "userSeqId",
        // },
        {
          data: "name",
          render: (data, mode, row, settings) => {
            // console.log({ data, mode, row, settings });
            let image;

            // if image found display profilePic avatar
            image = `<img src="${row.profilePic ? row.profilePic : "/images/man.png"
              }" class="avatar mg-r-15" alt="${row.name[0].toUpperCase()}">`;

            return `
            <div class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0" >
              ${image}
              <div class="wd-80p bg-transparent btn-view-display" system-id='${row._id}'>
                <h6 class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent tx-capitalize">${row.name || "-"
              }</h6>
                <span class="d-block tx-11 text-muted bg-transparent">
                  <!-- a href="tel:+91${row.mobileNo}" -->
                    <i data-feather="phone"></i> 
                    ${row.mobileNo || "-"}
                  <!--/a -->
                </span>
                <span class="d-block tx-11 text-muted bg-transparent">
                  <!-- href="mailto:${row.email}" -->
                    <i data-feather="mail"></i> 
                    ${row.email || "-"}
                  <!--/a -->
                </span>
              </div>
            </div>`;
          },
        },
        {
          data: "role",
          orderable: false,
          render: (data, mode, row, settings) => {
            data = parseAccessProfileStrings(data);
            return data || "-";
          },
          defaultContent: "-",
        },
        {
          data: "batchList",
          orderable: false,
          render: (data, mode, row, settings) => {
            // console.log({ data, mode, row, settings });
            if (data && data.length) {
              let htmlStr = ``;
              data.map((item) => {
                let batch = batchList.find(
                  (batch) => batch._id == item.batchId && item.active
                );
                if (batch && batch.name && !htmlStr.includes(batch.name)) {
                  let newStr = ` <span class="badge badge-primary">${batch.name} </span> `;
                  htmlStr += newStr;
                }
              });
              return htmlStr;
            } else return "-";
          },
          defaultContent: "-",
        },
        {
          data: "_id",
          orderable: false,
          render: (data, a, b, c) => {
            // console.table({ data, a, b, c });
            var view = "-"
            var archive = ""
            if(MODULES.hasAccess(MODULES.VIEW_SYSTEM_USERS_DETAILS) && !(MODULES.hasAccess(MODULES.DELETE_SYSTEM_USERS))){
              view = `<a href="javascript:void(0)" data-id="${data}" class='btn-edit-systemuser mg-r-5' data-toggle="tooltip" data-placement="bottom" title="Edit"><i data-feather="edit" ></i> </a>`
            }else if(MODULES.hasAccess(MODULES.DELETE_SYSTEM_USERS) && !(MODULES.hasAccess(MODULES.VIEW_SYSTEM_USERS_DETAILS))){
              archive = `<a href="javascript:void(0)" class='btn-delete-systemuser mg-r-5' data-id='${data}' data-toggle="tooltip" data-placement="bottom" title="Delete"><i data-feather="trash" ></i> </a>`
            }else if(!(MODULES.hasAccess(MODULES.DELETE_SYSTEM_USERS)) && !(MODULES.hasAccess(MODULES.VIEW_SYSTEM_USERS_DETAILS))){
              view = "-"
              archive = ""
            }else{
              view = `<a href="javascript:void(0)" data-id="${data}" class='btn-edit-systemuser mg-r-5' data-toggle="tooltip" data-placement="bottom" title="Edit"><i data-feather="edit" ></i> </a>`
              archive = `<a href="javascript:void(0)" class='btn-delete-systemuser mg-r-5' data-id='${data}' data-toggle="tooltip" data-placement="bottom" title="Delete"><i data-feather="trash" ></i> </a>`
            }
            return `
                  <nav>
                    ${view}
                    ${archive}
                  </nav>
                    `;
          },
        },
      ],
      autoWidth: true,
      columnDefs: [
        { targets: 0 },
        { targets: 1, width: "20%" },
        { targets: 1, width: "20%" },
        { targets: 2 },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        _$(".btn-edit-systemuser").on("click", function (event) {
          let systemuserId = _$(this).data("id");
          // _$(this).tooltip("dispose");
          let route = `/dashboard/systemuser/${systemuserId}`;
          navigate(route);
        });

        _$(".btn-view-display").on("click", function (event) {
          let systemuserId = _$(this).attr("system-id");
          // _$(this).tooltip("dispose");
          let route = `/dashboard/systemuser/${systemuserId}`;
          navigate(route);
        });

        _$(".btn-delete-systemuser").on("click", async function (event) {
          let systemuserId = _$(this).data("id");
          
          // _$(this).tooltip("dispose");
          pConfirm(
            "Delete user?",
            "Are you sure you want to delete this user?",
            async function () {
              console.info("Deleting");
              //   delete api
              let result = await api_delete({ _id: systemuserId });
              if (result.status) {
                // reload table
                pSuccess("", result.data);
                reload();
              } else {
                pError("", result.errMsg);
              }
            }
          );
        });
        _$("#table tbody").on("click", "tr td", function (e) {
          // responsive expand button clicked
          feather.replace({ width: "1em", height: "1em" });
          _$(".btn-edit-systemuser").text("Edit");
          _$(".btn-delete-systemuser").text("Delete");
        });
        // _$('[data-toggle="tooltip"]').tooltip();
      },
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }

  // function initDatePicker() {
  //   // _$(".date-select").datepicker();
  //   _$(".date-select")
  //     .unbind()
  //     .on("select", function (dateText) {
  //       console.log(
  //         "Selected date: " +
  //           dateText +
  //           "; input's current value: " +
  //           this.value
  //       );
  //       let id = _$(this).attr("id");
  //       alert("changed " + id);
  //     });
  // }

  async function reload() {
    // accessProfilesList = await api_fetchAccessProfileList();
    // batchList = await initBatchList();
    _$(systemUsersDatatable).DataTable().ajax.reload(null, false);
  }

  function bindModalAnimations() {
    // new system user modal
    _$(addNewUserModal).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
    });

    _$(addNewUserModal).on("hidden.bs.modal", function (e) {
      _$(this).removeClass("effect-scale");
    });
  }


   let getPlainArrFromTree = function (aTreeNode) {
        let plainArr = [];
        let etrOverTree = function (_aTreeNode) {
            _aTreeNode.forEach(_aNode => {
                plainArr.push(_aNode);
                if (_aNode.children && _aNode.children.length > 0) {
                    etrOverTree(_aNode.children);
                }
            });
        }
        etrOverTree(aTreeNode)
        return plainArr;
    }


  let openAddUserModal = async () => {
    // clearFields();
    _$(elem_orgAccessProfiles).selectize({
      preload: true,
      create: false,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      load: async function (query, callback) {
        this.clearOptions();
        let data = {
          search: query,
        };
        orgAccessProfiles = await fetchAccessProfiles(data)
        callback(orgAccessProfiles)
      },
      onBlur: function () {

      },
      onChange: async function (value) {
        if (!value) {
          selectedRole = undefined;
          return;
        } else {
          selectedRole = value;
          let roleTerrResult = (await api_handleApiCalls('roles.getModuleForAccessProfile', {
            roleId: value
          }));
          // console.log("--------------------- role tree : ", roleTerrResult);
          treeJson = roleTerrResult.data;

          var to = false;
          _$('#jstree').removeClass('d-none')

          _$("#jstree").jstree('destroy');

          _$('#tree_search').keyup(function () {
            if (to) { clearTimeout(to); }
            to = setTimeout(function () {
              var v = _$('#tree_search').val();
              _$('#jstree').jstree(true).search(v);
            }, 250);
          });
          _$('#jstree').jstree({
            "plugins": ["wholerow", "checkbox", "search"],
            "checkbox": {
              cascade: 'down',
              "three_state": false,
            },
            "search": {
              "show_only_matches": true
            },
            'core': {
              'data': treeJson,
              "dblclick_toggle": false
            }
          }).on('changed.jstree', function (e, data) {
            var i, j, r = [];
            for (i = 0, j = data.selected.length; i < j; i++) {
              r.push(data.instance.get_node(data.selected[i]).text);
            }
            // console.log(" --------------------- data : ", data)

            let plainArr = getPlainArrFromTree(treeJson);
            // console.log("plainArr  : ", plainArr)
            if (data.action == 'ready') {
              plainArr.forEach(aTreeNode => {
                if (!aTreeNode.state.selected) {
                  _$.jstree.reference('#jstree').deselect_node(aTreeNode.id);
                }
              });
            }

            if (data.node && data.node.children_d && data.node.children_d.length > 0) {
              let nodeId = data.node.id;
              for (let c = 0; c < data.node.children_d.length; c++) {
                const aChildren = data.node.children_d[c];
                if (data.action == "deselect_node") {
                  _$.jstree.reference('#jstree').disable_node(aChildren);
                }
                if (data.action == "select_node") {
                  _$.jstree.reference('#jstree').enable_node(aChildren);
                }
              }
            }

          })


        }
      },
    });

    _$(addNewUserModal).modal('show');
    _$(firstInputField).focus();

  }

  function parseAccessProfileStrings(roleOrAccessProfile) {
    if (roleOrAccessProfile) {
      let defaultRole = rolesData.find(
        (role) => role.value == roleOrAccessProfile
      );
      if (defaultRole) return defaultRole.text;
    }
    return roleOrAccessProfile;
  }
  async function fetchAccessProfiles (data) {
    let result = (await api_handleApiCalls('roles.getOrgAccessProfiles', data));
    if (result.status) {
      return result.data;
    } else {
      return [];
    }
  }
  async function initAccessProfileList(query) {
    let result = await api_fetchAccessProfileList({
      search: { value: query },
      isSortByName:true
    });
    if (result.status) {
      result.data.data = result.data.data.map((item) => {
        // console.log(item.name);
        return { ...item, name: parseAccessProfileStrings(item.name) };
      });
      return result.data.data;
    } else {
      // console.log(result.errMsg);
      return [];
    }
  }
  async function initBatchList(query) {
    let batchListLengthOffset = 100;
    let batchListFilters = {
      start: 0,
      length: batchListLengthOffset,
      search: { value: query },
      sort: { seqId: 1 },
      isSortByName:true
    };
    batchList = await api_fetchBatchList(batchListFilters);
    if (batchList.status) {
      return batchList.data.data;
    } else {
      return [];
    }
  }

  async function init() {
    accessProfilesList = await fetchAccessProfiles({});
    batchList = await initBatchList();


    initDropdown();
    initDatatable();
    bindModalAnimations();
  }

  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [year, month, day].join("-");
    return dateString;
  }
  function showHideFilterModal(action) {
    action ? (_$(filterModalThis).modal('show')) : (_$(filterModalThis).modal('hide'))
  }

  onMount(init);
  afterUpdate(function () {
    feather.replace({ width: "1em", height: "1em" });
  });
</script>

<style>
  .table-cell-line-break {
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 40vw; */
  }

  .bg-transparent {
    background-color: transparent !important;
  }
</style>

<div class="content-body fadeIn">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20
      mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {displayText}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">{displayText}</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Add new system user, assign batches.</p>
    </div>
    <div class="d-inline-block">
      <!-- role filter start -->
      <!-- <div class="d-inline-block">
        <div class="form-group mg-r-10">
          <select bind:this={elem_roleFilter} class="form-control role-filter wd-150" placeholder="Roles">
            <option value="">ALL ROLES</option>
          </select>
        </div>
      </div> -->
      <!-- role filter end -->
      <!-- batch filter start -->
      <!-- <div class="d-inline-block">
        <div class="form-group mg-r-10">
          <select bind:this={elem_batchFilter} class="form-control wd-150" placeholder="Batch">
            <option value="">ALL BATCHES</option>
          </select>
        </div>
      </div> -->
      <!-- batch filter end -->
      <!-- access profile filter start -->
      <!-- <div class="d-inline-block">
        <div class="form-group mg-r-10">
          <select bind:this={elem_accessProfileFilter} class="form-control access-profile-filter wd-150"
            placeholder="Access Profile">
            <option value="">ALL ACCESS PROFILES</option>
          </select>
        </div>
      </div> -->
      <!-- access profile filter end -->
      <div class="d-inline-block">
        <div>
          {#if MODULES.hasAccess(MODULES. ADD_SYSTEM_USERS)}
          <button on:click={openAddUserModal} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
              data-feather="user-plus" class="wd-10 mg-r-5" />
            Add new System User</button>
            {/if}
        </div>
      </div>
      <button class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase" on:click={()=>{showHideFilterModal(true)}}>
        <i data-feather="filter" class="mg-r-5"></i> Filters</button>
    </div>
  </div>
  <!-- breadcrumbs end -->
  <hr class="full-width">
  <div data-label="System Users">
    <table bind:this={systemUsersDatatable} class="table table-sm" style="width:100%">
      <thead>
        <tr>
          <th scope="col"style="width: 20%;" >User</th>
          <th scope="col" style="width: 10%;" >Role</th>
          <th scope="col" style="width: 40%;">Batches</th>
          <!-- <th scope="col">Access Profiles</th> -->
          <th scope="col" style="width: 10%;">Actions</th>
          <!-- <th scope="col">Added On</th> -->
          <!-- <th scope="col">Mobile Number</th> -->
          <!--<th scope="col">Role <br />(Access Profile)</th>-->
        </tr>
      </thead>
    </table>
  </div>
</div>

<!-- Modal -->
<div bind:this={addNewUserModal} class="modal" id="modal-new-user" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="min-width: 1000px; ">
    <div class="modal-content tx-14" style="height: 100%;">
      <div class="modal-header pd-b-0 pd-r-25">
        <div class="media align-items-center pd-12">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="users" class="wd-60 ht-60" /></span>
          <!-- <span class="tx-color-03 d-none d-sm-block"><i data-feather="grid" class="wd-60 ht-60"></i></span> -->
          <!-- <h3 class=" model-title pd-l-15 pd-t-2" id="exampleModalLabel6"></h3> -->
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">System User</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Create new system user</p>
          </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span><br />
        </button>
      </div>

      <div class="modal-body">
        <div class="pd-12 d-flex justify-content-end">
          <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal" on:click={clearFields}>Cancel
            <i data-feather="x" class="wd-10 mg-l-5" />
          </button>
          <button type="button" disabled={isLoading} on:click={submitForm}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <ButtonLoader {isLoading} btnText="Proceed" />
            <i data-feather="arrow-right" class="wd-10 mg-l-5" />
          </button>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <!-- <div class="form-row pd-t-8 pd-12" autocomplete="off"> -->
              <div class="form-group col-sm-12 col-md-12 col-lg-12">
                <label class="d-block" for="name">Full Name<span class="required-field">*</span></label>
                <input type="text" autocomplete="off" class="form-control" id="name" on:keyup={(e)=> e.key === 'Enter' && submitForm()}
                aria-label="Full name"
                placeholder="Enter full name"
                bind:this={firstInputField}
                bind:value={systemUserDetails.name}
                class:parsley-error={errorObj.name} />
                {#if errorObj.name}
                <div class="parsley-errors-list">{errorObj.name}</div>
                {/if}
              </div>
              <div class="form-group col-sm-12 col-md-12 col-lg-12">
                <label class="d-block" for="mobilenumber">Mobile Number<span class="required-field">*</span></label>
                <input on:keyup={(e)=> {
                e.key === 'Enter' && submitForm();
                //systemUserDetails.mobileNo = systemUserDetails.mobileNo.replace(/\D/g, '');
                }}
                value={systemUserDetails.mobileNo}
                type="number"
                class="form-control"
                id="mobilenumber"
                maxlength="10"
                on:input={function (e) {
                this.value = this.value.slice(0, this.maxLength);
                systemUserDetails.mobileNo = this.value;
                }}
                class:parsley-error={errorObj.mobileNo}
                placeholder="Enter mobile number" />
                {#if errorObj.mobileNo}
                <div class="parsley-errors-list col-sm-6 col-md-6 col-lg-6">
                  {errorObj.mobileNo}
                </div>
                {/if}
              </div>
            
              <div class="form-group col-sm-12  col-md-12 col-lg-12">
                <label class="d-block">Roles<span class="required-field">*</span></label>
                <select bind:this={elem_orgAccessProfiles} class="info slectizedd form-control" placeholder="Select Role">
                  <option value="">Select Role</option>
                </select>
                {#if errorObj.role}
                <div class="parsley-errors-list">{errorObj.role}</div>
                {/if}
              </div>
            
              <!-- content here -->
              <div class="form-group col-sm-12 col-md-12 col-lg-12" style="display: none;">
                <label class="d-block" for="name">User Role<span class="required-field">*</span></label>
                <div class="d-md-flex flex-wrap">
                  <div class="custom-control custom-radio col-md-4">
                    <input type="radio" id="role-admin" bind:group={systemUserDetails.role} class="custom-control-input"
                      value="admin" />
                    <label class="custom-control-label" for="role-admin">Admin</label>
                  </div>
            
                  <div class="custom-control custom-radio col-md-4">
                    <input type="radio" id="role-batch-manager" bind:group={systemUserDetails.role} class="custom-control-input"
                      value="batchManager" />
                    <label class="custom-control-label" for="role-batch-manager">Batch Manager</label>
                  </div>
            
                  <div class="custom-control custom-radio col-md-4">
                    <input type="radio" id="role-faculty" bind:group={systemUserDetails.role} class="custom-control-input"
                      value="faculty" />
                    <label class="custom-control-label" for="role-faculty">Faculty</label>
                  </div>
            
                  <div class="custom-control custom-radio col-md-4">
                    <input type="radio" id="role-tele-caller" bind:group={systemUserDetails.role} class="custom-control-input"
                      value="telecaller" />
                    <label class="custom-control-label" for="role-tele-caller">Tele
                      Caller</label>
                  </div>
                </div>
                {#if errorObj.role}
                <div class="parsley-errors-list">{errorObj.role}</div>
                {/if}
              </div>
            <!-- </div> -->
          </div>
          <div class="col-sm-6" >
              <div class="form-group col-sm-12 col-md-12 col-lg-12" style="    margin-bottom: 10px;">
                <label class="d-block" for="name">Select Modules for this role</label>
                <input type="text" class="form-control" placeholder="Type to filter modules..." id="tree_search"/>
              </div>
            <div id="jstree" style="padding:0 15px;    overflow: auto;">
            </div>
          </div>
        </div>
       
        {#if errorObj.apiError}
        <div class="col-md-12">
          <div class="parsley-errors-list">{errorObj.apiError}</div>
        </div>
        {/if} 
        <div class="pd-12 d-flex justify-content-end">
          <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal" on:click={clearFields}>Cancel
            <i data-feather="x" class="wd-10 mg-l-5" />
          </button>
          <button type="button" disabled={isLoading} on:click={submitForm}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <ButtonLoader {isLoading} btnText="Proceed" />
            <i data-feather="arrow-right" class="wd-10 mg-l-5" />
          </button>
        </div>


      </div>
      <!-- <div class="modal-footer pd-t-0"> -->
       
      <!-- </div> -->
    </div>
  </div>
</div>
<!-- Modal -->


<!-- Filter Modal -->
<div class="modal effect-scale" bind:this={filterModalThis} tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->

      <hr class="full-width">
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

        <div class="form-group">
          <label class="d-block">Batches:</label>
          <select disabled={isLoading} bind:this={elem_batchFilter} class="form-control select2" multiple="multiple">
            <option>Select batches</option>
          </select>
        </div>
        <div class="form-group">
          <label class="d-block">Role:</label>
          <select disabled={isLoading} bind:this={elem_roleFilter} class="form-control select2">
            <option>Select role</option>
          </select>
        </div>




        <div class="d-md-flex justify-content-end mg-t-25">
          <button disabled={isLoading} on:click={clearFilter} class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5"
            data-dismiss="modal">CLEAR
            <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button disabled={isLoading} on:click={applyFilter} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
            data-dismiss="modal">
            <ButtonLoader isLoading={isLoading} btnText="APPLY" />
            <i data-feather="filter" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Modal -->
