<script>
  import { Router, Link, links, Route, navigate } from "svelte-routing";
  import { userData } from "../stores";
  import { onMount } from "svelte";
  import { activeMenu } from "../stores";
  import ButtonLoader from "../utils/button_loader.svelte";
  import { pSuccess, pError } from "../utils/notify.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import ExcelJS from 'exceljs'
  import { API_URL } from "../../config.js"

  export let mode;
  export let testObj;
  export let updateParent;
  export let userId;
  let showModal = false;
  let reportedQuestionsDataTable;
  let reportedQuestionsTable;
  let allReportsListArr = [];
  let modalCmp;
  let exportsLoading = false
  let btnDisable = false;
  let isLoading = false;
  let questionAnalyticsData = []
  let errorObj = {};

  let reevaluateMarks = () => {
    console.log("function ", reevaluateMarks.name);
  };
  let graceMarks = () => {
    console.log("function ", graceMarks.name);
  };


  async function api_resolveQuestionById(data) {
    let action = "questionsReported.resolveReportedQuestion";
    var response = await api_handleApiCalls(action, data);
    return response.data;
  }

  function bindDatatable(testId) {
    reportedQuestionsDataTable = _$(reportedQuestionsTable).DataTable({
      responsive: true,
      language: {
        processing: "",
        searchPlaceholder: "Search question",
        sSearch: "",
        lengthMenu: "_MENU_ Report/page",
      },
      ajax: function (data, callback, settings) {
        _$.ajax({
          url: API_URL,
          method: "post",
          data: {
            action: "test.getQuestionsAnalytics",
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: data.length,
              search: data.search.value,
              testId: testId,
            },
          },
          success: function (resData) {
            var result = [];
            if (resData.status) {
              // console.log("required data >>>>>>>>>>========>", resData.data);
              var paginationData = resData.data;
              questionAnalyticsData = paginationData.data
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data,
              };

              console.log("result data table::::::::", result);
              callback(result);
            } else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            // emptyState = true
          },
        });
      },
      columns: [
        {
          data: "data",
          render: function (data, type, full) {
            let qText = data.question.English
            qText = _$('<p>').html(qText).find('img').css("width", "85px").end().html();
            //  var qText = data.question.English
            var answer = data.answer
            if (data.option_a) {
              var option_a = data.option_a.English
              var option_b = data.option_b.English
              var option_c = data.option_c.English
              var option_d = data.option_d.English
            }
            let optionsHtml = ''
            if (data.option_a) {
              optionsHtml += `<div class="col-6 d-flex p-2">a)  ${" " + option_a}</div>`
            }
            if (data.option_b) {
              optionsHtml += `<div class="col-6 d-flex p-2">a)  ${" " + option_b}</div>`
            }
            if (data.option_c) {
              optionsHtml += `<div class="col-6 d-flex p-2">a)  ${" " + option_c}</div>`
            }
            if (data.option_d) {
              optionsHtml += `<div class="col-6 d-flex p-2">a)  ${" " + option_d}</div>`
            }
            if (data.option_e) {
              var option_e = data.option_e.English
              optionsHtml += `<div class="col-6 d-flex p-2">a)  ${" " + option_e}</div>`
            }
            if (data.option_f) {
              var option_f = data.option_f.English
              optionsHtml += `<div class="col-6 d-flex p-2">a)  ${" " + option_f}</div>`
            }
            if (data.option_g) {
              var option_g = data.option_g.English
              optionsHtml += `<div class="col-6 d-flex p-2">a)  ${" " + option_g}</div>`
            }
            if (data.option_h) {
              var option_h = data.option_h.English
              optionsHtml += `<div class="col-6 d-flex p-2">a)  ${" " + option_h}</div>`
            }


            if (data.option_a) {
              return `<div class="col-sm-10 pd-5">
                          ${qText}
                          <div class="d-flex flex-row">
                            <div class="column">
                              <div class="row mg-t-20 mg-b-20 mg-t-20 mg-r-20 mg-l-0 p-1" >
                                  ${optionsHtml}
                              </div>
                              <div class="mg-l-0 pd-l-0 mg-b-0 mg-t-3 col d-flex tx-bold">Ans:  ${answer}</div>
                            </div>
                          </div>
                      </div>`
            } else {
              return `<div class="col-sm-10 pd-5">
                          ${qText}
                          <div class="d-flex flex-row">
                            <div class="column">
                                  <div class="mg-l-0 pd-l-0 mg-b-0 mg-t-3 col d-flex tx-bold">Ans:  ${answer}</div>
                            </div>
                          </div>
                      </div>`
            }
          },
        },
        {
          data: "totalAttempt",
          render: function (data, type, full) {
            return `<div style="text-align: center">${data}</div>`;
          },
        },
        {
          data: "totalCorrect",
          render: function (data, type, full) {
            return `<div style="text-align: center">${data}</div>`;
          },
        },
        {
          data: "totalWrong",
          render: function (data, type, full) {
            return `<div style="text-align: center">${data}</div>`;
          },
        },
      ],
      drawCallback: function () {
        feather.replace();
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
      },
      processing: true,
      serverSide: true,
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }

  onMount(async () => {
    // console.log("testObj:::::::::::::::::", testObj);
    testObj.menu = 'questionanalytics';
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    if (updateParent) updateParent()
    bindDatatable(testObj?._id);
    feather.replace();
  });

  async function btnExportQuestionAnalytics() {
    exportsLoading = true
    generateWorkBook()
    exportsLoading = false
  }


  async function generateWorkBook() {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('Question Analytics Data', {
      pageSetup: { paperSize: 9, orientation: 'landscape' }
    });



    let fileName = 'Question_Analytics_export_' + new Date().getTime() + '_.xlsx'

    let columnsArr = [
      { header: 'Sr No.', key: 'serialNo', width: 15, },
      { header: 'Question', key: 'name', width: 100, },

      { header: 'Answer', key: 'mobileNo', width: 15 },
      { header: 'Option A', key: 'option_a', width: 15 },
      { header: 'Option B', key: 'option_b', width: 15 },
      { header: 'Option C', key: 'option_c', width: 15 },
      { header: 'Option D', key: 'option_d', width: 15 },
      { header: 'Option E', key: 'option_e', width: 15 },
      { header: 'Option F', key: 'option_f', width: 15 },
      { header: 'Option G', key: 'option_g', width: 15 },
      { header: 'Option H', key: 'option_h', width: 15 },

      { header: 'Total Attempt', key: 'testname', width: 15 },
      { header: 'Total Correct', key: 'testDate', width: 15 },
      { header: 'Total Wrong', key: 'startedAt', width: 15 }


    ]

    worksheet.columns = columnsArr

    for (let idx = 0; idx < questionAnalyticsData.length; idx++) {
      let questionDataArr = []
      let questionData = questionAnalyticsData[idx]
      let question = questionData.data.question.English

      let qtext = question.replace(/<\/?[^>]+(>|$)/g, "")
      if (questionData.data.option_a) {
        var options = {
          option_a: questionData.data.option_a.English.replace(/<\/?[^>]+(>|$)/g, ""),
          option_b: questionData.data.option_b.English.replace(/<\/?[^>]+(>|$)/g, ""),
          option_c: questionData.data.option_c.English.replace(/<\/?[^>]+(>|$)/g, ""),
          option_d: questionData.data.option_d.English.replace(/<\/?[^>]+(>|$)/g, "")
        }
        if (questionData.data.option_e && questionData.data.option_e.English) {
          options['option_e'] = questionData.data.option_e.English.replace(/<\/?[^>]+(>|$)/g, "")
        }

        if (questionData.data.option_f && questionData.data.option_f.English) {
          options['option_e'] = questionData.data.option_f.English.replace(/<\/?[^>]+(>|$)/g, "")
        }
        if (questionData.data.option_g && questionData.data.option_g.English) {
          options['option_e'] = questionData.data.option_g.English.replace(/<\/?[^>]+(>|$)/g, "")
        }
        if (questionData.data.option_h && questionData.data.option_h.English) {
          options['option_e'] = questionData.data.option_h.English.replace(/<\/?[^>]+(>|$)/g, "")
        }

      }


      let answer = questionData.data.answer
      let ans
      if (Array.isArray(answer) && answer.length) ans = answer.join();
      else ans = answer
      questionDataArr.push(idx + 1)
      questionDataArr.push(qtext ? qtext : "")

      questionDataArr.push(ans ? ans : "")


      questionDataArr.push(options && options.option_a ? options.option_a : "")
      questionDataArr.push(options && options.option_b ? options.option_b : "")
      questionDataArr.push(options && options.option_c ? options.option_c : "")
      questionDataArr.push(options && options.option_d ? options.option_d : "")
      questionDataArr.push(options && options.option_e ? options.option_e : "")
      questionDataArr.push(options && options.option_f ? options.option_f : "")
      questionDataArr.push(options && options.option_g ? options.option_g : "")
      questionDataArr.push(options && options.option_h ? options.option_h : "")

      questionDataArr.push(questionData.totalAttempt ? questionData.totalAttempt : 0)
      questionDataArr.push(questionData.totalCorrect ? questionData.totalCorrect : 0)
      questionDataArr.push(questionData.totalWrong ? questionData.totalWrong : 0)

      worksheet.addRow(questionDataArr)

    }

    const buffer = await workbook.xlsx.writeBuffer();

    saveFile(new Blob([buffer], { type: "application/octet-stream" }), fileName);
    //   await workbook.xlsx.writeFile(fileName);

  }
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    // var view = new Uint8Array(buf);
    var view = s
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  const saveFile = async (blob, fileName) => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };
</script>

<div class="fadeIn" style="display: flex;flex-direction: column;">
  <div style="width: 1;border-bottom: 1px solid #e5e8ee; " class="d-flex justify-content-between">
    <div style="display: flex;align-items: center;justify-content: center;margin-left: 20px;" class="colored-svg">
      <i data-feather="clipboard" class="wd-40" />
    </div>
    <div class="media-body mg-l-10 pd-y-10">
      <h6 class="mg-b-2 tx-16">Questions Analytics</h6>
      <span class="d-block tx-11 tx-color-03">Questions wise test analysis.</span>
    </div>
    <div class="d-block">

      <div class="pd-y-10 mg-r-25">

        <button disabled={exportsLoading} class="btn btn-sm pd-x-15 btn-uppercase btn-secondary mg-l-5 "
          on:click={btnExportQuestionAnalytics}>
          <ButtonLoader isLoading={exportsLoading} btnText={exportsLoading ? "Processing..." : "Export" } />
        </button>

      </div>
    </div>
  </div>
  <div class="pd-20">
    <div data-label="Example" class="df-example ">
      <div id="reportedQuestionsExportTable">
        <table bind:this={reportedQuestionsTable} class="table table-hover table-sm">
          <thead>
            <tr>
              <th style="width: 300px;">Questions</th>
              <th style="width: 10%; text-align: center;">Total Attempt</th>
              <th style="width: 10%; text-align: center;">Total Correct</th>
              <th style="width:20%; text-align: center;">Total Wrong</th>
            </tr>
          </thead>
          <tbody />
        </table>
      </div>

    </div>
  </div>
</div>
