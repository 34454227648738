<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../../stores";
  import { userData } from "../../stores";
  import FolderModal from "../../folderCmp/folderModal.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import { BACKEND_URL } from "../../../config.js"; // Import BACKEND_URL
  export let _type;
  export let idList = [];
  let _parentId = Array.isArray(idList) ? idList[idList.length - 1] : "";
  let _batchId = Array.isArray(idList) ? idList[0] : "";
  let showModal = false;
  let elem_uploadPdfInput;
  let elem_multipleUploadInput;
  const fileTypes = [
    { label: "Image", value: "imageType" },
    { label: "Document", value: "docType" },
  ];
  let oldSelectedValue;
  let multipleFilesArr = [];
  let selectedFileType = "imageType";
  let elem_uploadImageInput;
  let elem_uploadLectureInput;
  let elem_tags;
  let attachment = {};
  let isLoading = false;
  let fileEle;
  let files;
  let _fileName;
  let _id = false;
  let fileObj;
  let imageObj = {};
  let lectureObj = {};
  let referenceLinkObj = {};
  let youtubeLectureObj = {};
  let liveLectureObj = {};
  let dateFromTo = {};

  let elem_startTimePicker;
  let elem_endTimePicker;
  let minDate;
  let startPickerDisabled;
  let linkArr = [];
  let moduleSubjectList = [];
  let facultyList = [];
  let pdfObj = {
    // _id: _id,
    name: "",
    facultyId: "",
    tags: [],
  };
  let multipleFileObj = {
    // _id: _id,
    // name: "",

    facultyId: "",
    tags: [],
  };
  let loggedInUserId;
  let localUserData = {
    fullName: "...",
    role: "...",
    avatar: "/images/man.png",
    access: {
      home: false,
      students: false,
      settings: false,
    },
  };

  let displayText = "Add Pdf";
  let elemModuleSubj;
  let elemFaculty;
  if (_type == "doc") {
    displayText = "Add Document";
  } else if (_type == "image") {
    displayText = "Add Image";
  } else if (_type == "uploadVideo") {
    displayText = "Upload Video";
  } else if (_type == "referenceLink") {
    displayText = "Add Reference Link";
  } else if (_type == "youtubeLink") {
    displayText = "Add Youtube Video";
  } else if (_type == "LiveLinkZoom") {
    displayText = "Add Zoom Live Lecture ";
  } else if (_type == "LiveLinkYoutube") {
    displayText = "Add Youtube Live Lecture ";
  } else if (_type == "multipleFiles") {
    displayText = "Add Multiple Files ";
  }
  let errorObj = {};
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  };
  const unsubscribe = userData.subscribe((value) => {
    localUserData = value;
  });

  const subscribeToStore = userData.subscribe((value) => {
    loggedInUserId = value._id;
  });
  async function api_loadLinksForFile(data) {
    let action = "files.loadLinksForFile";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_addFileLinkToFolder(data) {
    let action = "files.addFileLinkToFolder";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_removeFileLinkToFolder(data) {
    let action = "files.removeFileLinkToFolder";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getUrlForUpload(data) {
    let action = "storage.getUrlForUpload";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_updatePdfFileUrl(data) {
    let action = "studyMaterial.updatePdfFileUrl";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getModuleSub(data) {
    let action = "categories.getModuleSubjectList";
    try {
      var response = await api_handleApiCalls(action, {});
      if (!response.ok) throw new Error("Network response was not ok");
      return response;
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  }

  async function api_addUpdatePdf(data) {
    let action = "studyMaterial.addUpdatePdf";
    var response = await api_handleApiCalls(action, data);
    return response;
  }
  async function api_addUpdateMultipleFiles(data) {
    let action = "studyMaterial.addUpdateMultipleFiles";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_addUpdateReferenceLink(data) {
    let action = "studyMaterial.addUpdateRefLink";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_addUpdateRecordedLecture(data) {
    let action = "studyMaterial.addUpdateRecordedLecture";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getPdfById(data) {
    let action = "studyMaterial.getPdfById";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_addUpdateImage(data) {
    let action = "studyMaterial.addUpdateImage";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_addUpdateYoutubeLecture(data) {
    let action = "studyMaterial.addUpdateYoutubeLecture";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getFaculty(data) {
    let action = "systemUser.fetchSystemUserList";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  function convertDateObjToDateStr(date) {
    if (!date) return null;
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [year, month, day].join("-");
    return dateString;
  }

  function randomString(length, chars) {
    var mask = "";
    if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
    if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    if (chars.indexOf("#") > -1) mask += "0123456789";
    if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
    var result = "";
    for (var i = length; i > 0; --i)
      result += mask[Math.floor(Math.random() * mask.length)];
    return result;
  }

  function updateSuccess(success) {
    PNotify.success({
      title: success.title ? success.title : "Success",
      text: success.msg,
      delay: 3000,
    });
  }

  function updateErrors(error) {
    PNotify.error({
      title: error.title ? error.title : "Error",
      text: error.msg,
      delay: 3000,
    });
  }

  let deleteLink = async (linkId) => {
    var linkRes = await api_removeFileLinkToFolder({ _id: linkId });
    if (linkRes.status) {
      updateSuccess({ title: "Success", msg: linkRes.data });
      // renderLinks();
    } else {
      // Failed to create
      updateErrors({
        title: "Error",
        msg: linkRes?.errMsg ?? "Something went wrong",
      });
    }
  };

  let validatePdf = () => {
    errorObj = {};
    var newErrorObj = {};

    if (!pdfObj.name) {
      newErrorObj.name = "Name is required Field.";
    }

    if (!pdfObj.facultyId) {
      newErrorObj.facultyId = "Faculty is required Field.";
    }

    // Check both pdfObj.attachment and global attachment object
    if (!pdfObj.attachment && !attachment) {
      newErrorObj.originalFileName = "PDF is required to upload.";
    }

    errorObj = newErrorObj;
    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;
  };

  let validateMultipleFiles = () => {
    errorObj = {};
    var newErrorObj = {};

    if (!selectedFileType) {
      newErrorObj.selectedFileType = "File Type is required Field.";
    }

    // Check if any files have been uploaded
    if (multipleFilesArr.length === 0) {
      newErrorObj.originalFileName = "File is required to upload.";
    }

    errorObj = newErrorObj;
    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;
  };

  let validateImage = () => {
    errorObj = {};
    var newErrorObj = {};

    if (!imageObj.name) {
      newErrorObj.name = "Name is required Field.";
    }

    if (!imageObj.facultyId) {
      newErrorObj.facultyId = "Faculty is required Field.";
    }

    // Check both attachment and global attachment object
    if (!imageObj.attachment && !attachment) {
      newErrorObj.originalFileName = "Image is required to upload.";
    }

    errorObj = newErrorObj;
    if (Object.keys(errorObj).length > 0) {
      isLoading = false;
      return false;
    }
    return true;
  };

  let validateLecture = () => {
    errorObj = {};
    var newErrorObj = {};

    if (!lectureObj.name) {
      newErrorObj.name = "Name is required Field.";
    }

    if (!lectureObj.facultyId) {
      newErrorObj.facultyId = "Faculty is required Field.";
    }

    // Only check for file requirement if no file has been uploaded successfully
    if (
      !lectureObj.attachment ||
      (!lectureObj.attachment.originalFileName && !lectureObj.file)
    ) {
      newErrorObj.originalFileName = "Video is required to upload.";
    }

    errorObj = newErrorObj;
    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;
  };

  let validateReferenceLink = () => {
    errorObj = {};
    var newErrorObj = {};

    if (!referenceLinkObj.name) {
      newErrorObj.name = "Reference Link name is required Field.";
    }

    if (!referenceLinkObj.refUrl) {
      newErrorObj.refUrl = "Reference Link url is required Field.";
    }

    if (!referenceLinkObj.tags) {
      newErrorObj.tags = "Reference Link tag is required Field.";
    }

    errorObj = newErrorObj;
    if (Object.keys(errorObj).length > 0) {
      isLoading = false;
      return false;
    }
    return true;
  };

  let validateYoutubeLectureObj = () => {
    errorObj = {};
    var newErrorObj = {};

    if (!youtubeLectureObj.name) {
      newErrorObj.name = "Youtube Lecture Name is required Field.";
    }

    if (!youtubeLectureObj.youtubeLink) {
      newErrorObj.youtubeLink = "Youtube Lecture link is required Field.";
    }

    errorObj = newErrorObj;
    if (Object.keys(errorObj).length > 0) {
      isLoading = false;
      return false;
    }
    return true;
  };

  let validateLiveLecture = () => {
    errorObj = {};
    var newErrorObj = {};

    if (!liveLectureObj.name) {
      newErrorObj.name = "Live Lecture Name is required Field.";
    }

    if (dateFromTo.date) {
      if (dateFromTo.from && dateFromTo.to) {
        let startTime = new Date(dateFromTo.from);
        let endTime = new Date(dateFromTo.to);

        if (startTime >= endTime) {
          newErrorObj.time = "Lecture end time cannot be before start time.";
        } else {
          let startDate = new Date(dateFromTo.date);
          let endDate = new Date(dateFromTo.date);
          startDate.setHours(
            startTime.getHours(),
            startTime.getMinutes(),
            0,
            0,
          );
          endDate.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

          liveLectureObj.from = startDate;
          liveLectureObj.end = endDate;
        }
      } else {
        newErrorObj.time = "Lecture start time and end time are required.";
      }
    } else {
      newErrorObj.date = "Lecture date is required.";
      newErrorObj.time = "Lecture start time and end time are required.";
    }

    if (liveLectureObj.published) {
      if (!liveLectureObj.zoomMeetingId) {
        newErrorObj.zoomMeetingId = "Cannot publish without Meeting ID";
      }
      if (!liveLectureObj.zoomPasscode) {
        newErrorObj.zoomPasscode = "Cannot publish without Zoom Passcode";
      }
    }

    errorObj = newErrorObj;
    if (Object.keys(errorObj).length > 0) {
      isLoading = false;
      return false;
    }
    return true;
  };

  let addUpdateImageBtn = async () => {
    if (isLoading) return;
    isLoading = true;

    try {
      let moduleSubIdList = [];
      imageObj.moduleSubjects = [];
      moduleSubIdList.forEach((moduleSubId) => {
        var moduleId = moduleSubId.split("_")[0];
        var subjectId = moduleSubId.split("_")[1];
        imageObj.moduleSubjects.push({
          moduleId: moduleId,
          subjectId: subjectId,
        });
      });

      let facultyIds = _$(elemFaculty).val();
      imageObj.facultyId = facultyIds;
      imageObj.tags = _$(elem_tags).val().split(",");
      imageObj.batchId = _batchId;
      imageObj.folderId = _parentId;

      // Ensure we're using the most up-to-date attachment object
      imageObj.attachment = attachment || imageObj.attachment;

      // Add idList if not present
      imageObj.idList = idList;

      console.log("Sending image object:", imageObj); // Debug log

      if (validateImage()) {
        var imgRes = await api_addUpdateImage(imageObj);
        if (imgRes.status) {
          updateSuccess({ title: "Success", msg: imgRes.data });
          setTimeout(() => {
            window.history.back();
          }, 1000);
        } else {
          updateErrors({
            title: "Error",
            msg: imgRes?.errMsg ?? "Something went wrong",
          });
          console.error("API Error:", imgRes); // Debug log
          isLoading = false;
          return false;
        }
      }
    } catch (error) {
      console.error("Error in addUpdateImageBtn:", error);
      updateErrors({
        title: "Error",
        msg: "An unexpected error occurred",
      });
      isLoading = false;
      return false;
    }

    isLoading = false;
  };

  let addUpdateUploadVideoBtn = async () => {
    if (isLoading) return;
    isLoading = true;
    let moduleSubIdList = [];
    // _$(elemModuleSubj).select2("data").map(function (aSelectedValue) {
    //     return aSelectedValue.id;
    // });
    lectureObj.moduleSubjects = [];
    moduleSubIdList.forEach((moduleSubId) => {
      var moduleId = moduleSubId.split("_")[0];
      var subjectId = moduleSubId.split("_")[1];
      lectureObj.moduleSubjects.push({
        moduleId: moduleId,
        subjectId: subjectId,
      });
    });
    let facultyIds = _$(elemFaculty).val();
    lectureObj.facultyId = facultyIds;
    lectureObj.tags = _$(elem_tags).val().split(",");
    lectureObj.batchId = _batchId;
    lectureObj.folderId = _parentId;
    lectureObj.attachment = attachment;
    lectureObj.idList = idList;
    if (validateLecture()) {
      var lectureRes = await api_addUpdateRecordedLecture(lectureObj);
      // fileObj = lectureRes;
      if (lectureRes.status) {
        // create success
        updateSuccess({ title: "Success", msg: lectureRes.data });
        setTimeout(() => {
          window.history.back();
        }, 1000);
      } else {
        // Failed to create
        updateErrors({
          title: "Error",
          msg: lectureRes?.errMsg ?? "Something went wrong",
        });
        isLoading = false;
        return false;
      }
    }
    isLoading = false;
  };

  let addUpdateReferenceLinkBtn = async () => {
    if (isLoading) return;
    isLoading = true;
    // let batchIds = _$(/elemBatchId).select2("data")[0].id;
    // referenceLinkObj.batchId = batchIds;
    referenceLinkObj.batchId = _batchId;
    referenceLinkObj.folderId = _parentId;
    referenceLinkObj.idList = idList;
    if (validateReferenceLink()) {
      var referenceLinkRes = await api_addUpdateReferenceLink(referenceLinkObj);
      if (referenceLinkRes.status) {
        // create success
        updateSuccess({ title: "Success", msg: referenceLinkRes.data });
        setTimeout(() => {
          window.history.back();
        }, 1000);
      } else {
        // Failed to create
        updateErrors({ title: "Error", msg: referenceLinkObj.errMsg });
        isLoading = false;
        return false;
      }
    }
    isLoading = false;
  };

  let addUpdateYoutubeLinkBtn = async () => {
    if (isLoading) return;
    isLoading = true;
    youtubeLectureObj.batchId = _batchId;
    youtubeLectureObj.folderId = _parentId;
    youtubeLectureObj.idList = idList;
    if (validateYoutubeLectureObj()) {
      var imageRes = await api_addUpdateYoutubeLecture(youtubeLectureObj);
      if (imageRes.status) {
        // create success
        updateSuccess({ title: "Success", msg: imageRes.data });
        setTimeout(() => {
          window.history.back();
        }, 1000);
      } else {
        // Failed to create
        updateErrors({ title: "Error", msg: youtubeLectureObj.errMsg });
        isLoading = false;
        return false;
      }
    }
    isLoading = false;
  };

  let addUpdatePdfBtn = async () => {
    if (isLoading) return;
    isLoading = true;
    let moduleSubIdList = [];

    pdfObj.moduleSubjects = [];
    moduleSubIdList.forEach((moduleSubId) => {
      var moduleId = moduleSubId.split("_")[0];
      var subjectId = moduleSubId.split("_")[1];
      pdfObj.moduleSubjects.push({ moduleId: moduleId, subjectId: subjectId });
    });
    let facultyIds = _$(elemFaculty).val();
    pdfObj.facultyId = facultyIds;
    pdfObj.tags = _$(elem_tags).val().split(",");
    pdfObj.batchId = _batchId;
    pdfObj.folderId = _parentId;
    pdfObj.attachment = attachment;
    pdfObj.idList = idList;
    if (validatePdf()) {
      var pdfRes = await api_addUpdatePdf(pdfObj);
      // fileObj = pdfRes;
      if (pdfRes.status) {
        // create success
        updateSuccess({ title: "Success", msg: pdfRes.data });
        setTimeout(() => {
          window.history.back();
        }, 1000);
      } else {
        // Failed to create
        updateErrors({
          title: "Error",
          msg: pdfRes?.errMsg ?? "Something went wrong",
        });
        isLoading = false;
        return false;
      }
    }
    isLoading = false;
  };
  let addUpdateMultipleFileBtn = async () => {
    if (isLoading) return;
    isLoading = true;
    let moduleSubIdList = [];

    multipleFileObj.moduleSubjects = [];
    moduleSubIdList.forEach((moduleSubId) => {
      var moduleId = moduleSubId.split("_")[0];
      var subjectId = moduleSubId.split("_")[1];
      multipleFileObj.moduleSubjects.push({
        moduleId: moduleId,
        subjectId: subjectId,
      });
    });
    let facultyIds = _$(elemFaculty).val();
    // multipleFileObj.facultyId = facultyIds;
    // multipleFileObj.tags = _$(elem_tags).val().split(",");
    // multipleFileObj.batchId = _batchId;
    // multipleFileObj.folderId = _parentId;
    // multipleFileObj.attachment = attachment;
    // multipleFileObj.idList = idList;

    multipleFilesArr.forEach(function (element) {
      (element.moduleSubjects = multipleFileObj.moduleSubjects),
        (element.facultyId = facultyIds),
        (element.tags = _$(elem_tags).val().split(",")),
        (element.batchId = _batchId);
      element.folderId = _parentId;
      // element.attachment = attachment;
      element.idList = idList;
    });

    if (validateMultipleFiles()) {
      var multipleFileRes = await api_addUpdateMultipleFiles(multipleFilesArr);
      // fileObj = pdfRes;
      if (multipleFileRes.status) {
        // create success
        updateSuccess({ title: "Success", msg: multipleFileRes.data });
        setTimeout(() => {
          window.history.back();
        }, 1000);
      } else {
        // Failed to create
        updateErrors({
          title: "Error",
          msg: multipleFileRes?.errMsg ?? "Something went wrong",
        });
        isLoading = false;
        return false;
      }
    }
    isLoading = false;
  };

  let backBtn = async () => {
    window.history.back();
  };
  let bindFacultyEvent = function (selectedId) {
    if (!selectedId) selectedId = loggedInUserId;

    var _selectizeFaculty = _$(elemFaculty).selectize({
      preload: true,
      maxItems: 1,
      placeholder: "Select Faculty",
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      items: [selectedId],
      options: facultyList,
    });
  };
  let bindEvents = function () {
    var selectedSubjId = {};
    if (!pdfObj.moduleSubjects) pdfObj.moduleSubjects = [];
    pdfObj.moduleSubjects.forEach((aModuleSubject) => {
      selectedSubjId[aModuleSubject.subjectId] = true;
    });
    _$(elemModuleSubj).select2({
      minimumResultsForSearch: Infinity,
      placeholder: "Select Module / Subject",
      data: moduleSubjectList.map(function (aModule) {
        var isSelected = false;
        return {
          id: aModule.moduleId + "_" + aModule.subjectId,
          text: aModule.moduleSubjStr,
          selected: selectedSubjId[aModule.subjectId] ? true : false,
        };
      }),
    });
    // if(!selectedId) selectedId = loggedInUserId
    // _$(elemFaculty).selectize({
    //     delimiter: ',',
    //     plugins: ['remove_button'],
    //     persist: false,
    //     options: facultyList.map(function (aFaculty) {
    //         return {
    //             id: (aFaculty._id),
    //             text: aFaculty.name,
    //             selected: (aFaculty._id == pdfObj.facultyId)
    //         }
    //     }),
    //     items: loggedInUserId,
    //     minimumResultsForSearch: Infinity,
    //     // data: facultyList.map(function (aFaculty) {
    //     //     return {
    //     //         id: (aFaculty._id),
    //     //         text: aFaculty.name,
    //     //         selected: (aFaculty._id == pdfObj.facultyId)
    //     //     }
    //     // })
    // });
    if (!pdfObj.tags) pdfObj.tags = [];
    var tags = _$(elem_tags).selectize({
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: pdfObj.tags.map(function (input) {
        return { value: input, text: input };
      }),
      items: pdfObj.tags,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New tag : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
      create: function (input) {
        return { value: input, text: input };
      },
    });

    // for (var i = 0; i < pdfObj.tags.length; i++) {
    //         tags[0].selectize.addOption({ selected: true, value: pdfObj.tags[i], text: pdfObj.tags[i] });
    //     }
  };

  function bindTimePickers() {
    _$(elem_startTimePicker).timepicker({
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      // template: "modal",
      defaultTime: "12:00 AM",
      change: async function (time) {
        if (time) {
          dateFromTo.from = new Date(time);
          // _$(elem_endTimePicker).timepicker(endPickerOptions(dateFromTo.to));
        } else {
          dateFromTo.from = null;
        }
        clearError("startTime");
        await tick();
      },
    });

    _$(elem_endTimePicker).timepicker({
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      // template: "modal",
      defaultTime: "12:00 AM",
      change: async function (time) {
        if (time) {
          dateFromTo.to = new Date(time);
        } else {
          dateFromTo.to = null;
        }
        clearError("endTime");
        await tick();
      },
    });

    // _$(elem_startTimePicker).timepicker(startPickerOptions);
    // _$(elem_endTimePicker).timepicker(endPickerOptions);
  }

  let addUpdateLiveLectureBtn = async () => {
    if (isLoading) return;
    isLoading = true;
    liveLectureObj.batchId = _batchId;
    liveLectureObj.folderId = _parentId;
    liveLectureObj.idList = idList;
    if (validateLiveLecture()) {
      var imageRes = await api_addUpdateLiveLecture(liveLectureObj);
      if (imageRes.status) {
        // create success
        updateSuccess({ title: "Success", msg: imageRes.data.msg });
        setTimeout(() => {
          window.history.back();
        }, 1000);
      } else {
        // Failed to create
        updateErrors({ title: "Error", msg: liveLectureObj.errMsg });
        isLoading = false;
        return false;
      }
    }
    isLoading = false;
  };

  let filePondInstance;

  function determineAcceptedFileTypes(_type, selectedFileType) {
    switch (_type) {
      case "doc":
        return [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];
      case "image":
        return ["image/jpeg", "image/png", "image/gif", "image/webp"];
      case "uploadVideo":
        return ["video/mp4", "video/webm", "video/ogg"];
      case "multipleFiles":
        return selectedFileType === "docType"
          ? ["application/pdf"]
          : ["image/jpeg", "image/png", "image/gif", "image/webp"];
      default:
        return [];
    }
  }

  let filepondOptions = {
    allowDownloadByUrl: true,
    acceptedFileTypes: determineAcceptedFileTypes(_type, selectedFileType),
    server: {
      process: async (
        fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort,
        transfer,
        options,
      ) => {
        // Log the file object to the console
        console.log("File object:", file);

        if (!loggedInUserId) {
          console.error("User ID is not available");
          return;
        }

        // Validate file type
        const acceptedTypes = determineAcceptedFileTypes(
          _type,
          selectedFileType,
        );
        if (!acceptedTypes.includes(file.type)) {
          error(
            `Invalid file type. Accepted types are: ${acceptedTypes.join(", ")}`,
          );
          return;
        }

        try {
          // Get presigned URL from backend
          const res = await fetch(
            `${BACKEND_URL}/fileupload-api/get-presigned-url`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                data: {
                  contentType: determineUploadType(_type, selectedFileType),
                  fileName: file.name,
                  fileExtension: file.name.split(".").pop(),
                },
                accessToken: localStorage.getItem("accessToken"),
              }),
            },
          ).then((r) => r.json());

          if (!res.presignedUrl) {
            throw new Error("Failed to get presigned URL");
          }

          // Convert file to Blob
          const fileBlob = new Blob([file], { type: file.type });

          // Upload to S3
          const uploadResponse = await fetch(res.presignedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: fileBlob,
            mode: "cors",
          });

          if (!uploadResponse.ok) {
            throw new Error(`Upload failed: ${uploadResponse.status}`);
          }

          // Create attachment object using S3 URL
          const s3Url = new URL(res.presignedUrl);
          const baseUrl = s3Url.origin + s3Url.pathname;

          const attachmentObj = {
            originalFileName: file.name,
            fileSize: file.size,
            fileExtension: file.name.split(".").pop(),
            downloadUrl: res.objectUrl,
            pathname: s3Url.pathname,
            origin: s3Url.origin,
            url: baseUrl,
          };

          // Update attachment based on type
          if (_type === "doc") {
            pdfObj.attachment = attachmentObj;
          } else if (_type === "image") {
            imageObj.attachment = attachmentObj;
          } else if (_type === "uploadVideo") {
            lectureObj.attachment = attachmentObj;
          } else if (_type === "multipleFiles") {
            // Create a new file object for multiple files array
            const newFile = {
              name: file.name,
              attachment: attachmentObj,
              facultyId: loggedInUserId,
              tags: [],
              moduleSubjects: [],
              batchId: _batchId,
              folderId: _parentId,
              fileType: selectedFileType === "docType" ? "pdf" : "image",
            };

            // Add the file to the multipleFilesArr
            multipleFilesArr.push(newFile);
            console.log("Added file to multipleFilesArr:", newFile);
            console.log("Current multipleFilesArr:", multipleFilesArr);
          }
          attachment = attachmentObj;

          load(baseUrl);
          await tick();
        } catch (err) {
          console.error("Upload Error:", err);
          error(err.message);
          abort();
        }

        return {
          abort: () => {
            abort();
          },
        };
      },
    },
    onaddfile: function (error, file) {
      if (error) {
        PNotify.error({
          title: "Error",
          text: error.message || "Invalid file type",
          delay: 3000,
        });
        return;
      }

      // Log the file object to verify its type
      console.log("File object added:", file.file);

      if (!file.file.customUrl) {
        filePondInstance.processFile().then((file) => {});
      }
    },
  };

  function determineUploadType(type, selectedFileType) {
    switch (type) {
      case "doc":
        return "STUDY_MATERIAL";
      case "image":
        return "STUDY_MATERIAL";
      case "uploadVideo":
        return "STUDY_MATERIAL";
      case "multipleFiles":
        return selectedFileType === "docType"
          ? "STUDY_MATERIAL"
          : "STUDY_MATERIAL";
      default:
        return "STUDY_MATERIAL";
    }
  }

  onMount(async () => {
    activeMenu.set("studyMaterial");
    feather.replace();
    if (
      _type != "referenceLink" ||
      _type != "youtubeLiveLink" ||
      _type != "youtubeLink"
    ) {
      _$.fn.filepond.setOptions({
        server: {
          url: "{{ url('upload')}}",
          process: {
            headers: {
              "X-CSRF-TOKEN": "csrf",
            },
          },
        },
      });
      _$.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
      _$.fn.filepond.registerPlugin(FilePondPluginGetFile);

      if (_type == "doc") {
        filePondInstance = FilePond.create(
          elem_uploadPdfInput,
          filepondOptions,
        );
      } else if (_type == "image") {
        filePondInstance = FilePond.create(
          elem_uploadImageInput,
          filepondOptions,
        );
      } else if (_type == "uploadVideo") {
        filePondInstance = FilePond.create(
          elem_uploadLectureInput,
          filepondOptions,
        );
      }
      if (_type == "multipleFiles") {
        bindMultipleUploadFilePond();
      }
      document.addEventListener("DOMContentLoaded", async function () {
        try {
          var moduleSubjectRes = await api_getModuleSub();
          if (moduleSubjectRes && moduleSubjectRes.status) {
            moduleSubjectList = moduleSubjectRes.data;
          }
        } catch (error) {
          console.error("Error fetching module subjects: ", error);
        }
      });
      const filter = {
        draw: 1,
        columns: [
          {
            data: "name",
            name: "",
            searchable: true,
            orderable: true,
            search: {
              value: "",
              regex: false,
            },
          },
          {
            data: "email",
            name: "",
            searchable: true,
            orderable: true,
            search: {
              value: "",
              regex: false,
            },
          },
          {
            data: "_id",
            name: "",
            searchable: true,
            orderable: false,
            search: {
              value: "",
              regex: false,
            },
          },
        ],
        start: 0,
        length: 100,
        search: {
          value: "",
          regex: false,
        },
        sort: {
          userSeqId: 1,
        },
        accessProfileId: "",
      };
      var facultyRes = await api_getFaculty(filter);
      if (facultyRes.status) {
        facultyList = facultyRes.data.data;
      }
    }

    // renderLinks();

    var _fileObj;

    if (_type == "doc") {
      _$(".may-pond .filepond--item-panel").css("background", "#369763");
      _$(elem_uploadPdfInput).filepond("allowMultiple", false);
    } else if (_type == "image") {
      _$(".may-pond .filepond--item-panel").css("background", "#369763");
      _$(elem_uploadImageInput).filepond("allowMultiple", false);
      // _$(elem_uploadImageInput).on('FilePond:addfile', function (e) {
      //     _$.fn.filepond.setOptions(server);
      // });
    } else if (_type == "uploadVideo") {
      _$(".may-pond .filepond--item-panel").css("background", "#369763");
      _$(elem_uploadLectureInput).filepond("allowMultiple", false);
    } else if (_type == "youtubeLink") {
    } else if (_type == "LiveLinkZoom" || _type == "LiveLinkYoutube") {
      liveLectureObj.lecture_type =
        _type == "LiveLinkZoom" ? "zoom" : "youtube";
      bindTimePickers();
    } else if (_type == "referenceLink") {
    }
    if (_type == "multipleFiles") {
      _$(".may-pond .filepond--item-panel").css("background", "#369763");
      _$(elem_multipleUploadInput).filepond("allowMultiple", true);
    }
    bindFacultyEvent();
    bindEvents();
  });
  // $:selectedFileType,checkFilesPresentForUpload()
  function checkFilesPresentForUpload(e) {
    //  e.target.checked = false
    if (multipleFilesArr.length) {
      updateErrors({
        title: "Error",
        msg: "Files present for upload, either save the files or remove the files",
      });

      selectedFileType = oldSelectedValue;
    } else {
      selectedFileType = e.target.value;
      bindMultipleUploadFilePond();
    }
  }
  function bindMultipleUploadFilePond() {
    let filepondOptionsForMultipleFiles = {
      ...filepondOptions,
      allowMultiple: true,
      maxFiles: 10,
      acceptedFileTypes: determineAcceptedFileTypes(
        "multipleFiles",
        selectedFileType,
      ),
      // ... rest of the multiple upload options
    };

    if (filePondInstance) FilePond.destroy(elem_multipleUploadInput);
    filePondInstance = FilePond.create(
      elem_multipleUploadInput,
      filepondOptionsForMultipleFiles,
    );
  }
</script>

<div class="content-body fadeIn">
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
  >
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/studyMaterial">Study Material</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {displayText}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        {displayText}
      </h4>
    </div>
  </div>
  <!-- <hr class="mg-y-20"> -->
  {#if _type == "doc"}
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div class="row row-sm mg-b-10">
        <div class="col-sm-12">
          <fieldset class="form-fieldset">
            <legend>Basic</legend>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="d-block">
                  Name<span class="required-field">*</span></label
                >
                <input
                  bind:value={pdfObj.name}
                  type="text"
                  on:blur={() => clearError("name")}
                  class="form-control"
                  class:parsley-error={errorObj.name}
                  placeholder="Enter file name"
                />
                {#if errorObj.name}
                  <div class="parsley-errors-list">{errorObj.name}</div>
                  <!-- content here -->
                {/if}
              </div>
              <div
                class="form-group col-md-6 d-none"
                on:click={() => clearError("subjectId")}
                class:parsley-errors-select-custom={errorObj.subjectId}
              >
                <label class="d-block">Module & Subject</label>
                <select
                  multiple
                  bind:this={elemModuleSubj}
                  class="custom-select moduleSub"
                />
                {#if errorObj.subjectId}
                  <div class="parsley-errors-list">{errorObj.subjectId}</div>
                  <!-- content here -->
                {/if}
              </div>
              <div
                class="form-group col-md-6"
                on:click={() => clearError("facultyId")}
                class:parsley-errors-select-custom={errorObj.facultyId}
              >
                <label class="d-block"
                  >Faculty <span class="required-field">*</span></label
                >
                <select bind:this={elemFaculty} disabled>
                  <option value="" selected>Select Faculty</option>
                </select>
                {#if errorObj.facultyId}
                  <div class="parsley-errors-list">{errorObj.facultyId}</div>
                  <!-- content here -->
                {/if}
              </div>
              <div class="form-group col-md-6">
                <label class="d-block">Tags</label>
                <input
                  bind:this={elem_tags}
                  bind:value={pdfObj.tags}
                  on:blur={() => clearError("tags")}
                  type="text"
                  class="form-control"
                  class:parsley-error={errorObj.tags}
                  placeholder="Enter tags"
                />
                {#if errorObj.tags}
                  <div class="parsley-errors-list">{errorObj.tags}</div>
                {/if}
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-12">
          <div class="card ht-100p" style="margin-top: 9px;">
            <div>
              <input
                type="file"
                bind:this={elem_uploadPdfInput}
                class="my-pond"
                data-instant-upload="false"
                data-allow-image-preview="false"
                name="filepond"
                class:parsley-error={errorObj.originalFileName}
              />
              {#if errorObj.originalFileName}
                <div class="parsley-errors-list">
                  {errorObj.originalFileName}
                </div>
              {/if}
            </div>
            <!-- card-footer -->
          </div>
          <!-- card -->
        </div>
      </div>
    </div>
    <div class="d-sm-flex align-items-center justify-content-end">
      <div class="d-block">
        <button
          on:click={addUpdatePdfBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          disabled={isLoading}
        >
          <ButtonLoader {isLoading} btnText="SAVE" />
          <i data-feather="save" class="wd-10 mg-l-5" />
        </button>
      </div>
      <div class="d-block">
        <button
          on:click={backBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        >
          Back
          <i data-feather="arrow-left" class="wd-10 mg-l-5" />
        </button>
      </div>
    </div>
  {:else if _type == "image"}
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div class="row row-sm mg-b-10">
        <div class="col-sm-12">
          <fieldset class="form-fieldset">
            <legend>Basic</legend>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="d-block"> Name</label>
                <input
                  bind:value={imageObj.name}
                  type="text"
                  on:blur={() => clearError("name")}
                  class="form-control"
                  class:parsley-error={errorObj.name}
                  placeholder="Enter your name"
                />
                {#if errorObj.name}
                  <div class="parsley-errors-list">{errorObj.name}</div>
                  <!-- content here -->
                {/if}
              </div>
              <div
                class="form-group col-md-6 d-none"
                on:click={() => clearError("subjectId")}
                class:parsley-errors-select-custom={errorObj.subjectId}
              >
                <label class="d-block">Module & Subject</label>
                <select
                  multiple
                  bind:this={elemModuleSubj}
                  class="custom-select moduleSub"
                />
                {#if errorObj.subjectId}
                  <div class="parsley-errors-list">{errorObj.subjectId}</div>
                  <!-- content here -->
                {/if}
              </div>
              <div
                class="form-group col-md-6"
                on:click={() => clearError("facultyId")}
                class:parsley-errors-select-custom={errorObj.facultyId}
              >
                <label class="d-block">Faculty</label>
                <select bind:this={elemFaculty} disabled>
                  <option value="" selected>Select Faculty</option>
                </select>
                {#if errorObj.facultyId}
                  <div class="parsley-errors-list">{errorObj.facultyId}</div>
                  <!-- content here -->
                {/if}
              </div>
              <div class="form-group col-md-6">
                <label class="d-block">Tags</label>
                <input
                  bind:this={elem_tags}
                  bind:value={imageObj.tags}
                  on:blur={() => clearError("tags")}
                  type="text"
                  class="form-control"
                  class:parsley-error={errorObj.tags}
                  placeholder="Enter
              tags"
                />
                {#if errorObj.tags}
                  <div class="parsley-errors-list">{errorObj.tags}</div>
                {/if}
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-12">
          <div class="card ht-100p" style="    margin-top: 9px;">
            <div>
              <input
                type="file"
                bind:this={elem_uploadImageInput}
                class="my-pond"
                data-instant-upload="false"
                data-allow-image-preview="false"
                name="filepond"
                class:parsley-error={errorObj.originalFileName}
              />
              {#if errorObj.originalFileName}
                <div class="parsley-errors-list">
                  {errorObj.originalFileName}
                </div>
              {/if}
            </div>
            <!-- card-footer -->
          </div>
          <!-- card -->
        </div>
      </div>
    </div>
    <div class="d-sm-flex align-items-center justify-content-end">
      <div class="d-block">
        <button
          on:click={addUpdateImageBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          disabled={isLoading}
        >
          <ButtonLoader {isLoading} btnText="SAVE" />
          <i data-feather="save" class="wd-10 mg-l-5" />
        </button>
      </div>
      <div class="d-block">
        <button
          on:click={backBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        >
          Back
          <i data-feather="arrow-left" class="wd-10 mg-l-5" />
        </button>
      </div>
    </div>
  {:else if _type == "uploadVideo"}
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div class="row row-sm mg-b-10">
        <div class="col-sm-12">
          <fieldset class="form-fieldset">
            <legend>Basic</legend>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="d-block"> Name</label>
                <input
                  bind:value={lectureObj.name}
                  type="text"
                  on:blur={() => clearError("name")}
                  class="form-control"
                  class:parsley-error={errorObj.name}
                  placeholder="Enter your name"
                />
                {#if errorObj.name}
                  <div class="parsley-errors-list">{errorObj.name}</div>
                  <!-- content here -->
                {/if}
              </div>
              <div
                class="form-group col-md-6 d-none"
                on:click={() => clearError("subjectId")}
                class:parsley-errors-select-custom={errorObj.subjectId}
              >
                <label class="d-block">Module & Subject</label>
                <select
                  multiple
                  bind:this={elemModuleSubj}
                  class="custom-select moduleSub"
                />
                {#if errorObj.subjectId}
                  <div class="parsley-errors-list">{errorObj.subjectId}</div>
                  <!-- content here -->
                {/if}
              </div>
              <div
                class="form-group col-md-6"
                on:click={() => clearError("facultyId")}
                class:parsley-errors-select-custom={errorObj.facultyId}
              >
                <label class="d-block">Faculty</label>
                <select bind:this={elemFaculty} disabled>
                  <option value="" selected>Select Faculty</option>
                </select>
                {#if errorObj.facultyId}
                  <div class="parsley-errors-list">{errorObj.facultyId}</div>
                  <!-- content here -->
                {/if}
              </div>
              <div class="form-group col-md-6">
                <label class="d-block">Tags</label>
                <input
                  bind:this={elem_tags}
                  bind:value={lectureObj.tags}
                  on:blur={() => clearError("tags")}
                  type="text"
                  class="form-control"
                  class:parsley-error={errorObj.tags}
                  placeholder="Enter
              tags"
                />
                {#if errorObj.tags}
                  <div class="parsley-errors-list">{errorObj.tags}</div>
                {/if}
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-12">
          <div class="card ht-100p" style="    margin-top: 9px;">
            <div>
              <input
                type="file"
                bind:this={elem_uploadLectureInput}
                class="my-pond"
                data-instant-upload="false"
                data-allow-image-preview="false"
                name="filepond"
                class:parsley-error={errorObj.originalFileName}
              />
              {#if errorObj.originalFileName}
                <div class="parsley-errors-list">
                  {errorObj.originalFileName}
                </div>
              {/if}
            </div>
            <!-- card-footer -->
          </div>
          <!-- card -->
        </div>
      </div>
    </div>
    <div class="d-sm-flex align-items-center justify-content-end">
      <div class="d-block">
        <button
          on:click={addUpdateUploadVideoBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          disabled={isLoading}
        >
          <ButtonLoader {isLoading} btnText="SAVE" />
          <i data-feather="save" class="wd-10 mg-l-5" />
        </button>
      </div>
      <div class="d-block">
        <button
          on:click={backBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        >
          Back
          <i data-feather="arrow-left" class="wd-10 mg-l-5" />
        </button>
      </div>
    </div>
  {:else if _type == "referenceLink"}
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div class="row row-sm mg-b-10">
        <div class="col-sm-12 col-md-6">
          <fieldset class="form-fieldset">
            <legend>Basic</legend>
            <div class="form-group">
              <label class="d-block"> Name</label>
              <input
                bind:value={referenceLinkObj.name}
                type="text"
                on:blur={() => clearError("name")}
                class="form-control"
                class:parsley-error={errorObj.name}
                placeholder="Enter your name"
              />
              {#if errorObj.name}
                <div class="parsley-errors-list">{errorObj.name}</div>
                <!-- content here -->
              {/if}
            </div>
            <div class="form-group hasDatepicker">
              <label class="d-block">Reference Link</label>
              <input
                bind:value={referenceLinkObj.refUrl}
                type="text"
                on:blur={() => clearError("refUrl")}
                class="form-control"
                class:parsley-error={errorObj.refUrl}
                placeholder="Enter Url"
              />
              {#if errorObj.refUrl}
                <div class="parsley-errors-list">{errorObj.refUrl}</div>
                <!-- content here -->
              {/if}
            </div>
            <div class="form-group hasDatepicker">
              <label class="d-block">Tags</label>
              <input
                bind:value={referenceLinkObj.tags}
                type="text"
                on:blur={() => clearError("tags")}
                class="form-control"
                class:parsley-error={errorObj.tags}
                placeholder="Enter Tags"
              />
              {#if errorObj.tags}
                <div class="parsley-errors-list">{errorObj.tags}</div>
                <!-- content here -->
              {/if}
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="d-sm-flex align-items-center justify-content-end">
      <div class="d-block">
        <button
          on:click={addUpdateReferenceLinkBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          disabled={isLoading}
        >
          <ButtonLoader {isLoading} btnText={displayText} />
          <i data-feather="file" class="wd-10 mg-r-5" />
        </button>
      </div>
      <div class="d-block">
        <button
          on:click={backBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        >
          Back
          <i data-feather="arrow-left" class="wd-10 mg-l-5" />
        </button>
      </div>
    </div>
  {:else if _type == "youtubeLink"}
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div class="row row-sm mg-b-10">
        <div class="col-sm-12 col-md-6">
          <fieldset class="form-fieldset">
            <legend>Basic</legend>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">
                  Name
                  <span class="required-field">*</span>
                </label>
                <input
                  bind:value={youtubeLectureObj.name}
                  type="text"
                  on:blur={() => clearError("name")}
                  class="form-control"
                  class:parsley-error={errorObj.name}
                  placeholder="Enter your name"
                />
                {#if errorObj.name}
                  <div class="parsley-errors-list">
                    {errorObj.name}
                  </div>
                  <!-- content here -->
                {/if}
              </div>

              <div
                class="form-group col-md-12 moduleSub"
                on:click={() => clearError("type")}
                class:parsley-errors-select-custom={errorObj.type}
              >
                <label class="d-block">Type</label>
                <select disabled style="width: 100%;" class="custom-select">
                  <option value="">Select Type</option>
                  <option value="zoom">Zoom</option>
                  <option value="youtube" selected>YouTube</option>
                  <option value="bluebird">Blue Bird</option>
                </select>
                {#if errorObj.subjectId}
                  <div class="parsley-errors-list">
                    {errorObj.subjectId}
                  </div>
                  <!-- content here -->
                {/if}
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="card d-none" style="    margin-top: 9px;">
            <div style="margin: 20px;" class="">
              <!-- <h6 class=" tx-bold">Publish Live Session</h6> -->
              <div class="form-row">
                <div
                  class="form-group col-md-12"
                  id="selectBatch"
                  on:click={() => clearError("assignedToBatches")}
                  class:parsley-errors-select-custom={errorObj.batchIds}
                >
                  <label class="d-block">Link To Batches</label>
                  <select
                    name="assignBatch"
                    id="assignBatch"
                    type="text"
                    class="info form-control"
                  >
                    <option value="">Please Select Batch</option>
                  </select>
                  {#if errorObj.batchIds}
                    <div class="parsley-errors-list">{errorObj.batchIds}</div>
                    <!-- content here -->
                  {/if}
                </div>
              </div>
            </div>
          </div>

          <div class="card" style="margin-top: 9px;">
            <ul class="list-group list-group-flush tx-13">
              {#each linkArr as item}
                <li class="list-group-item d-flex pd-sm-x-20">
                  <div class="avatar">
                    <span class="avatar-initial rounded-circle bg-gray-600"
                      ><i data-feather="link" class="wd-10" /></span
                    >
                  </div>
                  <div class="pd-l-10">
                    <p class="tx-medium mg-b-0">
                      {item.fullPath.join(" / ")}
                    </p>
                    <small class="tx-12 tx-color-03 mg-b-0"
                      >{item.batchName}</small
                    >
                  </div>
                  <div class="mg-l-auto d-flex align-self-center">
                    <nav class="nav nav-icon-only">
                      <a
                        on:click|preventDefault={() => deleteLink(item._id)}
                        href="#"
                        class="nav-link d-none d-sm-block"
                      >
                        <i data-feather="trash-2" class="wd-10 mg-r-5" />
                      </a>
                    </nav>
                  </div>
                </li>
              {/each}
            </ul>
            <div style="margin: 20px;" class="youtube">
              <h6 class=" tx-bold">YouTube Lecture</h6>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label class="d-block"
                    >YouTube Link <span class="required-field">*</span></label
                  >
                  <input
                    type="text"
                    bind:value={youtubeLectureObj.youtubeLink}
                    on:blur={() => clearError("youtubeLink")}
                    class="form-control"
                    class:parsley-error={errorObj.youtubeLink}
                    placeholder="Enter your YouTube Link"
                  />
                  {#if errorObj.youtubeLink}
                    <div class="parsley-errors-list">
                      {errorObj.youtubeLink}
                    </div>
                    <!-- content here -->
                  {/if}
                </div>
              </div>
            </div>
            <!-- card-footer -->
          </div>
          <!-- card -->
        </div>
      </div>
    </div>

    <div class="d-sm-flex align-items-center justify-content-end">
      <div class="d-block">
        <button
          on:click={addUpdateYoutubeLinkBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          disabled={isLoading}
        >
          <ButtonLoader {isLoading} btnText={displayText} />
          <i data-feather="file" class="wd-10 mg-r-5" />
        </button>
      </div>
      <div class="d-block">
        <button
          on:click={backBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        >
          Back
          <i data-feather="arrow-left" class="wd-10 mg-l-5" />
        </button>
      </div>
    </div>
  {:else if _type == "LiveLinkZoom"}
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div class="row row-sm mg-b-10">
        <div class="col-sm-12 col-md-6">
          <fieldset class="form-fieldset">
            <legend>Basic</legend>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block"> Name</label>
                <input
                  bind:value={liveLectureObj.name}
                  type="text"
                  on:blur={() => clearError("name")}
                  class="form-control"
                  class:parsley-error={errorObj.name}
                  placeholder="Enter your name"
                />
                {#if errorObj.name}
                  <div class="parsley-errors-list">
                    {errorObj.name}
                  </div>
                  <!-- content here -->
                {/if}
              </div>

              <div
                class="form-group col-md-12 moduleSub"
                on:click={() => clearError("type")}
                class:parsley-errors-select-custom={errorObj.type}
              >
                <label class="d-block">Type</label>
                <select disabled style="width: 100%;" class="custom-select">
                  <option value="">Select Type</option>
                  <option value="zoom" selected>Zoom</option>
                  <option value="youtube">YouTube</option>
                  <option value="bluebird">Blue Bird</option>
                </select>
                {#if errorObj.subjectId}
                  <div class="parsley-errors-list">
                    {errorObj.subjectId}
                  </div>
                  <!-- content here -->
                {/if}
              </div>
              <div class="form-group col-md-12">
                <label class="d-block"
                  >Date
                  <span class="required-field">*</span></label
                >
                <!-- disabled={dateFromTo.startPickerDisabled && dateFromTo.endPickerDisabled} -->
                <input
                  bind:value={dateFromTo.date}
                  on:blur={() => clearError("scheduleDate")}
                  type="date"
                  onkeydown="return false"
                  min={convertDateObjToDateStr(minDate)}
                  class="form-control first-input"
                  class:parsley-error={errorObj.date}
                  placeholder="Enter date"
                />
                {#if errorObj.date}
                  <div class="parsley-errors-list">
                    {errorObj.date}
                  </div>
                {/if}
              </div>
              <div class="row col-md-12">
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="d-block"
                      >Start from
                      <span class="required-field">*</span></label
                    >
                    <input
                      bind:this={elem_startTimePicker}
                      type="text"
                      onkeydown="return false"
                      class="form-control first-input"
                      class:parsley-error={errorObj.time}
                      placeholder="Enter start time"
                    />
                    <!--disabled={dateFromTo.startPickerDisabled}-->
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="d-block"
                      >Ends on
                      <span class="required-field">*</span></label
                    >
                    <input
                      bind:this={elem_endTimePicker}
                      type="text"
                      onkeydown="return false"
                      class="form-control first-input"
                      class:parsley-error={errorObj.time}
                      placeholder="Enter end time"
                    />
                    <!--disabled={dateFromTo.endPickerDisabled}-->
                  </div>
                </div>
                {#if errorObj.time}
                  <div class="parsley-errors-list">
                    {errorObj.time}
                  </div>
                {/if}
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="card d-none" style="    margin-top: 9px;">
            <div style="margin: 20px;" class="">
              <!-- <h6 class=" tx-bold">Publish Live Session</h6> -->
              <div class="form-row">
                <div
                  class="form-group col-md-12"
                  id="selectBatch"
                  on:click={() => clearError("assignedToBatches")}
                  class:parsley-errors-select-custom={errorObj.batchIds}
                >
                  <label class="d-block">Link To Batches</label>
                  <select
                    name="assignBatch"
                    id="assignBatch"
                    type="text"
                    class="info form-control"
                  >
                    <option value="">Please Select Batch</option>
                  </select>
                  {#if errorObj.batchIds}
                    <div class="parsley-errors-list">{errorObj.batchIds}</div>
                    <!-- content here -->
                  {/if}
                </div>
              </div>
            </div>
          </div>

          <div class="card" style="    margin-top: 9px;">
            <div style="margin: 20px;" class="zoom">
              <h6 class=" tx-bold">Publish Live Session</h6>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label class="d-block">Zoom Meeting ID</label>

                  <!-- on:input={function (e) {
                      liveLectureObj.zoomMeetingId = (liveLectureObj.zoomMeetingId).toString().slice(
                        0,
                        11
                      );
                    }} -->
                  <input
                    type="number"
                    bind:value={liveLectureObj.zoomMeetingId}
                    on:blur={() => clearError("zoomMeetingId")}
                    class="form-control"
                    class:parsley-error={errorObj.zoomMeetingId}
                    placeholder="Enter your Meeting ID"
                  />
                  {#if errorObj.zoomMeetingId}
                    <div class="parsley-errors-list">
                      {errorObj.zoomMeetingId}
                    </div>
                    <!-- content here -->
                  {/if}
                </div>
                <div class="form-group col-md-12">
                  <label class="d-block">Zoom Meeting Passcode</label>
                  <input
                    bind:value={liveLectureObj.zoomPasscode}
                    type="text"
                    on:blur={() => clearError("zoomPasscode")}
                    class="form-control"
                    class:parsley-error={errorObj.zoomPasscode}
                    placeholder="Enter Meeting Passcode"
                  />
                  {#if errorObj.zoomPasscode}
                    <div class="parsley-errors-list">
                      {errorObj.zoomPasscode}
                    </div>
                    <!-- content here -->
                  {/if}
                </div>
                <div class="form-group col-md-12">
                  <div class="custom-control custom-switch">
                    <input
                      bind:checked={liveLectureObj.published}
                      type="checkbox"
                      style="cursor: pointer;"
                      class="custom-control-input"
                      id="tax-applicable"
                    />
                    <label class="custom-control-label" for="tax-applicable"
                      >Pubish / Unpublish</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- card -->
        </div>
      </div>
    </div>

    <div class="d-sm-flex align-items-center justify-content-end">
      <div class="d-block">
        <button
          on:click={addUpdateLiveLectureBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          disabled={isLoading}
        >
          <ButtonLoader {isLoading} btnText={displayText} />
          <i data-feather="file" class="wd-10 mg-r-5" />
        </button>
      </div>
    </div>
  {:else if _type == "multipleFiles"}
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div class="row row-sm mg-b-10">
        <div class="col-sm-12">
          <fieldset class="form-fieldset">
            <legend>Basic</legend>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="d-block"
                  >File Type<span class="required-field">*</span></label
                >
                <div class="d-flex mg-t-10">
                  <div class="custom-control custom-radio mg-r-10">
                    <input
                      type="radio"
                      id="imageType"
                      name="fileType"
                      class="custom-control-input"
                      bind:group={selectedFileType}
                      value="imageType"
                      on:input={(e) => {
                        oldSelectedValue = selectedFileType;
                      }}
                      on:change={(e) => checkFilesPresentForUpload(e)}
                    />
                    <label class="custom-control-label" for="imageType"
                      >Image</label
                    >
                  </div>

                  <div class="custom-control custom-radio mg-r-10">
                    <input
                      type="radio"
                      id="docType"
                      name="fileType"
                      class="custom-control-input"
                      bind:group={selectedFileType}
                      value="docType"
                      on:input={(e) => {
                        oldSelectedValue = selectedFileType;
                      }}
                      on:change={(e) => checkFilesPresentForUpload(e)}
                    />
                    <label class="custom-control-label" for="docType"
                      >Document</label
                    >
                  </div>
                </div>
                <!-- <input bind:value={pdfObj.name} type="text" on:blur={()=>clearError("name")}
                            class="form-control" class:parsley-error={errorObj.name} placeholder="Enter file name"/>
                            {#if errorObj.name}
                            <div class="parsley-errors-list">{errorObj.name}</div>
                            {/if} -->
              </div>
              <div
                class="form-group col-md-6 d-none"
                on:click={() => clearError("subjectId")}
                class:parsley-errors-select-custom={errorObj.subjectId}
              >
                <label class="d-block">Module & Subject</label>
                <select
                  multiple
                  bind:this={elemModuleSubj}
                  class="custom-select moduleSub"
                />
                {#if errorObj.subjectId}
                  <div class="parsley-errors-list">{errorObj.subjectId}</div>
                {/if}
              </div>
              <div
                class="form-group col-md-6"
                on:click={() => clearError("facultyId")}
                class:parsley-errors-select-custom={errorObj.facultyId}
              >
                <label class="d-block"
                  >Faculty <span class="required-field">*</span></label
                >
                <select bind:this={elemFaculty}>
                  <option value="" selected>Select Faculty</option>
                </select>
                {#if errorObj.facultyId}
                  <div class="parsley-errors-list">{errorObj.facultyId}</div>
                {/if}
              </div>
              <div class="form-group col-md-6">
                <label class="d-block">Tags</label>
                <input
                  bind:this={elem_tags}
                  bind:value={pdfObj.tags}
                  on:blur={() => clearError("tags")}
                  type="text"
                  class="form-control"
                  class:parsley-error={errorObj.tags}
                  placeholder="Enter tags"
                />
                {#if errorObj.tags}
                  <div class="parsley-errors-list">{errorObj.tags}</div>
                {/if}
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-12">
          <div class="card ht-100p" style="    margin-top: 9px;">
            <div>
              <input
                type="file"
                bind:this={elem_multipleUploadInput}
                class="my-pond"
                data-instant-upload="false"
                data-allow-image-preview="false"
                name="filepond"
                data-max-total-files="10"
                class:parsley-error={errorObj.originalFileName}
                multiple
              />
              {#if errorObj.originalFileName}
                <div class="parsley-errors-list">
                  {errorObj.originalFileName}
                </div>
              {/if}
            </div>
            <!-- card-footer -->
          </div>
          <!-- card -->
        </div>
      </div>
    </div>
    <div class="d-sm-flex align-items-center justify-content-end">
      <div class="d-block">
        <button
          on:click={addUpdateMultipleFileBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          disabled={isLoading}
        >
          <ButtonLoader {isLoading} btnText="SAVE" />
          <i data-feather="save" class="wd-10 mg-l-5" />
        </button>
      </div>
      <div class="d-block">
        <button
          on:click={backBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        >
          Back
          <i data-feather="arrow-left" class="wd-10 mg-l-5" />
        </button>
      </div>
    </div>
    <!--{:else if _type == 'LiveLinkYoutube'}-->
  {:else}
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div class="row row-sm mg-b-10">
        <div class="col-sm-12 col-md-6">
          <fieldset class="form-fieldset">
            <legend>Basic</legend>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block"> Name</label>
                <input
                  bind:value={liveLectureObj.name}
                  type="text"
                  on:blur={() => clearError("name")}
                  class="form-control"
                  class:parsley-error={errorObj.name}
                  placeholder="Enter your name"
                />
                {#if errorObj.name}
                  <div class="parsley-errors-list">
                    {errorObj.name}
                  </div>
                  <!-- content here -->
                {/if}
              </div>

              <div
                class="form-group col-md-12 moduleSub"
                on:click={() => clearError("type")}
                class:parsley-errors-select-custom={errorObj.type}
              >
                <label class="d-block">Type</label>
                <select disabled style="width: 100%;" class="custom-select">
                  <option value="">Select Type</option>
                  <option value="zoom">Zoom</option>
                  <option value="youtube" selected>YouTube</option>
                  <option value="bluebird">Blue Bird</option>
                </select>
                {#if errorObj.subjectId}
                  <div class="parsley-errors-list">
                    {errorObj.subjectId}
                  </div>
                  <!-- content here -->
                {/if}
              </div>
              <div class="form-group col-md-12">
                <label class="d-block"
                  >Date
                  <span class="required-field">*</span></label
                >
                <!-- disabled={dateFromTo.startPickerDisabled && dateFromTo.endPickerDisabled} -->
                <input
                  bind:value={dateFromTo.date}
                  on:blur={() => clearError("scheduleDate")}
                  type="date"
                  onkeydown="return false"
                  min={convertDateObjToDateStr(minDate)}
                  class="form-control first-input"
                  class:parsley-error={errorObj.date}
                  placeholder="Enter date"
                />
                {#if errorObj.date}
                  <div class="parsley-errors-list">
                    {errorObj.date}
                  </div>
                {/if}
              </div>
              <div class="row col-md-12">
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="d-block"
                      >Start from
                      <span class="required-field">*</span></label
                    >
                    <input
                      bind:this={elem_startTimePicker}
                      type="text"
                      onkeydown="return false"
                      class="form-control first-input"
                      class:parsley-error={errorObj.time}
                      placeholder="Enter start time"
                    /><!--disabled={dateFromTo.startPickerDisabled}-->
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="d-block"
                      >Ends on
                      <span class="required-field">*</span></label
                    >
                    <input
                      bind:this={elem_endTimePicker}
                      type="text"
                      onkeydown="return false"
                      class="form-control first-input"
                      class:parsley-error={errorObj.time}
                      placeholder="Enter end time"
                    />
                    <!--disabled={dateFromTo.endPickerDisabled}-->
                  </div>
                </div>
                {#if errorObj.time}
                  <div class="parsley-errors-list">
                    {errorObj.time}
                  </div>
                {/if}
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="card d-none" style="    margin-top: 9px;">
            <div style="margin: 20px;" class="">
              <!-- <h6 class=" tx-bold">Publish Live Session</h6> -->
              <div class="form-row">
                <div
                  class="form-group col-md-12"
                  id="selectBatch"
                  on:click={() => clearError("assignedToBatches")}
                  class:parsley-errors-select-custom={errorObj.batchIds}
                >
                  <label class="d-block">Link To Batches</label>
                  <select
                    name="assignBatch"
                    id="assignBatch"
                    type="text"
                    class="info form-control"
                  >
                    <option value="">Please Select Batch</option>
                  </select>
                  {#if errorObj.batchIds}
                    <div class="parsley-errors-list">{errorObj.batchIds}</div>
                    <!-- content here -->
                  {/if}
                </div>
              </div>
            </div>
          </div>

          <div class="card" style="    margin-top: 9px;">
            <div style="margin: 20px;" class="youtube">
              <h6 class=" tx-bold">YouTube Live Session</h6>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label class="d-block">YouTube Link</label>
                  <input
                    type="text"
                    bind:value={liveLectureObj.youtubeLink}
                    on:blur={() => clearError("youtubeLink")}
                    class="form-control"
                    class:parsley-error={errorObj.youtubeLink}
                    placeholder="Enter your YouTube Link"
                  />
                  {#if errorObj.youtubeLink}
                    <div class="parsley-errors-list">
                      {errorObj.youtubeLink}
                    </div>
                    <!--content here-->
                  {/if}
                </div>
              </div>
            </div>
            <!-- card-footer -->
          </div>
          <!-- card -->
        </div>
      </div>
    </div>

    <div class="d-sm-flex align-items-center justify-content-end">
      <div class="d-block">
        <button
          on:click={addUpdateLiveLectureBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          disabled={isLoading}
        >
          <ButtonLoader {isLoading} btnText={displayText} />
          <i data-feather="file" class="wd-10 mg-r-5" />
        </button>
      </div>
    </div>
  {/if}
</div>

<!-- {#if showModal}
<FolderModal on:close={onCloseModal} />
{/if} -->
<style>
  /* ###### 4.6 Parsley ###### */
  input.parsley-error,
  input.parsley-error:focus,
  textarea.parsley-error,
  textarea.parsley-error:focus {
    box-shadow: none !important;
    border-color: #dc3545;
  }

  input.parsley-success,
  input.parsley-success:focus,
  textarea.parsley-success,
  textarea.parsley-success:focus {
    box-shadow: none !important;
    border-color: #10b759;
  }

  .parsley-checkbox.parsley-error,
  .parsley-checkbox.parsley-success {
    display: inline-block;
    padding: 10px;
    border-radius: 0.25rem;
  }

  .parsley-checkbox.parsley-error {
    border: 1px solid #dc3545;
  }

  .parsley-checkbox.parsley-success {
    border: 1px solid #10b759;
  }

  .custom-select.parsley-error,
  .custom-select.parsley-success {
    box-shadow: none !important;
  }

  .custom-select.parsley-error {
    border-color: #dc3545;
  }

  .custom-select.parsley-success {
    border-color: #10b759;
  }

  .parsley-errors-select-custom .select2-selection {
    border-color: #dc3545 !important;
  }

  .parsley-errors-list {
    color: #dc3545;
    list-style: none;
    font-size: 12px;
    padding: 0;
    margin-bottom: -12px;
  }

  .parsley-errors-list li {
    margin: 5px 0 0;
  }
</style>
