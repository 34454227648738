<script>
    import { Router,links , Link, Route, navigate } from "svelte-routing";
    import { createEventDispatcher, onMount, tick } from 'svelte';
    import { activeMenu } from "../stores";
    import ToastContainer from "./toast.svelte";
    import api_handleApiCalls from "./apiCallHandler.js";
    const dispatch = createEventDispatcher();
    let batchList = [];
    let redirectUrl;
    export let batch_id;
    let selectedBatchObj;
    let elem_batchModal;
    let search = { value : "" }
    let showScreen = false;
    let showToast = (option) => {
        _$(".toast-header").html(option.title)
        _$(".toast-body").html(option.message)
        _$(".toast").toast("show");
    }
    function handleBatchChange() {
      
      navigate("/dashboard/batch/edit/"+batch_id);
        // _$(elem_batchModal).modal("show");  
    }
    function selectBatch(batchObj) {
      _$(elem_batchModal).modal("hide");
        
        dispatch('change', {batch_id: batchObj._id});
    }
    async function api_getMyBatches(data) {

      console.log("data>>>>>",data)

      let action = "batch.getMyBatches";
      var response = await api_handleApiCalls(action, data);
      console.log("response in the batchselectcompo>>>>>>", response);
      return response;

    }    

    async function loadData(){

      var data = {
                search: search.value,
            }
            console.log("data>>>>>",data);
            var batchRes = await api_getMyBatches(data);
            if (batchRes.status) {
                batchList = batchRes.data;
                console.log("batchList", batchList);
                if (batchList.length == 0) { // Rare case
                    showToast({title:"Note", message: "Please Create batch to access this feature."});
                    // window.history.back();
        
                } else if (batchList.length == 1) { 
                    var batchId = batchList[0]._id;
                    var url = redirectUrl.replace("BATCH_ID", batchId); 
                    navigate(url);
                } else {
                    showScreen = true;
        
                }

                _$(elem_batchModal).modal("show");
                await tick();
                feather.replace();
            } else {
                showToast({title:"Error", message: batchRes.errMsg});
            }
    }

    onMount(async () => {
        feather.replace();
        if (batch_id) {
          // fetch current batch only
          var batchRes = await api_getMyBatches();
          if (batchRes.status) {
                batchRes.data.forEach(aBatch => {
                  if (aBatch._id == batch_id) {
                    selectedBatchObj = aBatch; 
                  }
                });
                console.log("selectedBatchObj", selectedBatchObj, batch_id);
                return;
            } else {
                showToast({title:"Error", message: batchRes.errMsg});
            }

        } else {

            activeMenu.set('batchtiming')
            await loadData();
            
        }


    });

</script>
{#if selectedBatchObj}
<div >
    <div class="card card-body ht-lg-100">
      <div class="media">
        
           <div class="media-body mg-l-20">
             <h6 class="mg-b-10">{selectedBatchObj.name}</h6>
             <p class="tx-color-03 mg-b-0">Batch Start date: {new Date(selectedBatchObj.startDate).toLocaleDateString('en-GB')} </p>
           </div>
          <span on:click={handleBatchChange} class="tx-color-04"><i data-feather="edit" class="wd-20 ht-20 text-primary"></i></span>

           <div >
                <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i data-feather="mail"
        class="wd-10 mg-r-5"></i> Email</button>
<button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><i data-feather="printer"
        class="wd-10 mg-r-5"></i> Print</button> -->
           </div>
      </div><!-- media -->
    </div>                
</div>          
{/if}

<div class="modal fade" bind:this={elem_batchModal} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">

        <div class="modal-header pd-b-0 pd-r-25">
          <div class="media align-items-center pd-12">
            <span class="tx-color-03 d-none d-sm-block"><i data-feather="clock" class="wd-60 ht-60" /></span>
            <div class="media-body mg-sm-l-20">
              <h4 class="tx-18 tx-sm-20 mg-b-2">Batch List</h4>
              <p class="tx-13 tx-color-03 mg-b-0">Please select a batch</p>
            </div>
          </div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span><br />
          </button>
        </div>

        <div class="modal-body pd-20 pd-sm-30">
          <!-- <h4 class="tx-18 tx-sm-20 mg-b-3">Please Select a Batch</h4>
          <p class="mg-b-20 tx-color-03">Users you may add to your connections</p>
          <a href="" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a> -->

          <div class="bd">
            <div class="d-flex ht-45 pd-x-15 align-items-center bd-b">
              <input type="search" class="form-control bd-0 pd-0 shadow-none-f"
              placeholder="Batch Name" bind:value={search.value}
              on:input={async (event)=> {await loadData(0, event)}}>
              <a href="" role="button" class="link-03"><i data-feather="search" class="wd-20 ht-20 stroke-wd-2-5"></i></a>
            </div>

            <ul class="list-unstyled media-list tx-12 tx-sm-13 mg-b-0" style="height: 400px;overflow-y: scroll;">
                {#each batchList as aBatch}
                <li on:click={ (e) => selectBatch(aBatch) } class="media bg-ui-01 pd-y-10 pd-x-15 mg-t-1">
                    <!-- <div class="avatar"><img src="../assets/img/img14.jpg" class="rounded-circle" alt=""></div> -->
                    <div class="media-body mg-l-15">
                      <h6 class="tx-13 mg-b-2">{aBatch.name}</h6>
                      <span class="d-block tx-color-03">Batch Start date: {new Date(aBatch.startDate).toLocaleDateString()}</span>
                    </div><!-- media-body -->
                    <a href=""  on:click|preventDefault class="btn btn-white rounded-circle btn-icon mg-l-15"><i data-feather="plus"></i></a>
                </li><!-- media -->
                {/each}              
                    
            </ul>
          </div>

          <div class="d-none justify-content-end mg-t-30">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary mg-l-5">Save Changes</button>
          </div>
        </div><!-- modal-body -->
      </div><!-- modal-content -->
    </div><!-- modal-dialog -->
  </div><!-- modal -->


