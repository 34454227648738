<script>
    import { Router, Link, Route } from "svelte-routing";
    import ListRoute from "./list/index.svelte";
    import AddUpdate from "./addupdate/index.svelte";
    import ArchievedList from "./archievedList/index.svelte";
    import MODULES from "../utils/modules.js" 
    import Forbidden from "../forbidden.svelte"
</script>

<Router>

    
    <Route path="info/:id" let:params>
        {#if MODULES.hasAccess(MODULES.VIEW_ENQUIRY_DETAILS)}
        <AddUpdate _id="{params.id}"></AddUpdate>
        {:else}
        <Forbidden />
        {/if}
    </Route>
    <Route path="archived">
        <ArchievedList ></ArchievedList>
    </Route>
    <Route path="">
        <ListRoute></ListRoute>
    </Route>
</Router>



