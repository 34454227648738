<script>
  import { Router, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { STUDY_MONITORING_ACCESS } from "../orgAccessControl";
  import Navigation from "./layout/navigation.svelte";
  import Header from "./layout/header.svelte";

  import Home from "./home/home.svelte";
  import MyProfile from "./myProfile/index.svelte";
  import Reports from "./report/index.svelte";
  import Enquiry from "./enquiries/index.svelte";

  import AccessProfileFeatures from "./accessProfile/features.svelte";
  import Responses from "./response/index.svelte";
  import EnquirySource from "./enquirySource/index.svelte";
  import StudyMonitoring from "./studyMonitoring/index.svelte";
  import Student from "./students/index.svelte";
  import Attendance from "./attendance/index.svelte";
  import StudentAttendance from "./studentAttendance/index.svelte";
  import LiveLectureForStudents from "./liveLectureForStudent/index.svelte";
  import CustomFields from "./customFields/index.svelte";
  import StudyMaterial from "./studyMaterialNew/index.svelte";
  import Batch from "./batch/index.svelte";
  import BatchTiming from "./batch_timing/index.svelte";
  import Holiday from "./holiday/index.svelte";
  import Branch from "./branch/index.svelte";
  import Course from "./course/index.svelte";
  import Classes from "./class/index.svelte";
  // import ModuleSubject from "./categories/index.svelte";
  import SystemUser from "./systemUser/index.svelte";
  import Schedule from "./schedule/index.svelte";
  import TeleCallers from "./teleCallers/index.svelte";
  import TeleScript from "./TelecallerScriptTextEditor/container.svelte";
  import SmsTemplate from "./SmsTemplateTextEditor/container.svelte";
  import AnnouncementTemplate from "./AnnouncementsTextEditor/container.svelte";
  import TaskManagement from "./taskManagement/index.svelte";
  import Settings from "./settings/index.svelte";
  import GeneralSettings from "./settings/general/index.svelte";
  import SocialSettings from "./settings/social/index.svelte";
  // import PointsSettings from "./settings/points/index.svelte";
  import IntegrationSettings from "./settings/integration/index.svelte";
  import Feedback from "./feedbackAdmin/index.svelte";
  import StudentFeedback from "./feedbackStudent/index.svelte";
  import StudentStudyReport from "./studyReportStudent/index.svelte";
  import StudyReport from "./studyReportAdmin/index.svelte";
  import AccessProfile from "./accessProfile/index.svelte";
  import FAQ from "./faq/index.svelte";
  import FolderCmp from "./folderCmp/folderCmp.svelte";
  import Payment from "./payment/index.svelte";
  import PdcManagement from "./postDatedCheck/index.svelte";
  import ReceiptSeries from "./receiptSeries/index.svelte";
  import QuestionsReported from "./questionsReported/index.svelte";
  import QuestionsReportedType from "./questionsReportedType/index.svelte";
  import DiscussionForum from "./discussionForum/index.svelte";
  import LeaveRequest from "./leaveRequest/index.svelte";
  import OrgTree from "./orgTree/newOrgTree.svelte";
  import StudentView from "./studentView/index.svelte";
  import Questions from "./questions/index.svelte";
  import QuestionsUpload from "./questionUpload/index.svelte";
  import AnalyticalCategory from "./analyticalCategory/index.svelte";
  import Tests from "./tests/index.svelte";
  import MyTests from "./tests/myTests.svelte";
  import PracticeTest from "./tests/practiceTest.svelte";

  import ExamReports from "./tests/myExamReports.svelte";
  import TestInstructions from "./TestInstructionsTextEditor/container.svelte";
  import SPT from "./singleParamTextEditor/container.svelte";
  import StudyMonitoringSources from "./studyMonitoringSources/index.svelte";

  import { userData } from "./stores";

  import { _accessToken } from "../store.js";
  import { get } from "svelte/store";
  import AppSlider from "./appSlider/index.svelte";
  import Assignment from "./assignment/index.svelte";
  import AssignmentProgress from "./assignment_progress/index.svelte";
  import BatchJoinRequest from "./batchJoinRequest/index.svelte";
  import ViewAttendance from "./viewAttendance/index.svelte";
  import StudentAssignment from "./studentAssignment/index.svelte";
  import Chat from "./chat/index.svelte";
  import Announcements from "./announcements/index.svelte";
  import DoubtSession from "./doubtSession/index.svelte";

  import MODULES from "./utils/modules";
  import Forbidden from "./forbidden.svelte";

  import { API_URL } from "../config.js";

  import AptiBooster from "./aptiBooster/index.svelte";
  import Class from "./aptiBooster/class/Class.svelte";
  import Subjects from "./aptiBooster/subjects/Subjects.svelte";
  import Chapters from "./aptiBooster/chapters/Chapters.svelte";
  import AptiStudent from "./aptiStudent/AptiStudent.svelte";

  let isUserLoaded = false;
  let localUserData = {
    fullName: "...",
    role: "...",
    avatar: "/images/man.png",
    access: {
      home: false,
      students: false,
      settings: false,
    },
  };

  const unsubscribe = userData.subscribe((value) => {
    localUserData = value;
  });

  const keyShortcutInit = () => {
    window.addEventListener("keydown", function (e) {
      if (e.altKey == true && e.keyCode == 80) {
        if (MODULES.hasAccess(MODULES.MANAGE_PAYMENT)) {
          navigate("/dashboard/payment/");
        }
      }

      // else if(e.keyCode == 17 && e.keyCode == 18 && e.keyCode == 80)navigate('/dashboard/payment/')
    });
  };
  async function checkCurrentUser() {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/login");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var payload = JSON.stringify({
      action: "auth.getCurrentUser",
      accessToken: accessToken,
      data: {
        withAccessProfile: true,
        getFresh: true,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };

    try {
      var resObj = await fetch(API_URL, requestOptions);
      console.log("resObj", resObj);

      if (resObj.status === 498 || resObj.ok === false) {
        // Token expired or invalid
        window.localStorage.removeItem("accessToken");
        navigate("/login");
        return;
      }

      var response = await resObj.json();
      console.log("response", response);
      if (!response.status) {
        if (response.errMsg && response.errMsg.includes("TokenExpiredError")) {
          // Token expired
          window.localStorage.removeItem("accessToken"); // Remove the token
          navigate("/login");
        } else {
          // Any other error
          window.localStorage.removeItem("accessToken"); // Remove the token
          navigate("/login");
        }
      }

      var defaultAvatar = "/images/man.png";
      userData.set({
        _id: response.data.userId,
        fullName: response.data.name,
        role: response.data.role,
        orgName: response.data.orgName,
        mobileNo: response.data.mobileNo,
        avatar: response.data.profilePic
          ? response.data.profilePic
          : defaultAvatar,
        access: {},
        modules: response.data.modules,
        orgId: response.data.orgId,
        orgCode: response.data.orgCode,
      });

      setTimeout(() => {
        isUserLoaded = true;
        tick();
      }, 10);
    } catch (error) {
      window.localStorage.removeItem("accessToken");
      navigate("/login");
      console.log({ error: error });
    }
  }
  let init = () => {
    checkCurrentUser();
    keyShortcutInit();
    // called once for context menu
    _$(document).bind("mousedown", function (e) {
      if (!_$(e.target).parents(".custom-menu").length > 0) {
        _$(".custom-menu").hide(100);
        _$(".action-span").css({ outline: 0, "background-color": "white" });
      }
    });
  };

  onMount(async () => {
    init();
  });
</script>

{#if isUserLoaded}
  <Navigation></Navigation>
  <div class="content ht-100v pd-0 fadeIn">
    <Header></Header>

    <Router>
      {#if localUserData.role == "student"}
        <Route path="/">
          <MyTests />
        </Route>

        <Route path="/practiceTest">
          <PracticeTest />
        </Route>

        <Route path="/exam-reports">
          <ExamReports userId={localUserData._id} />
        </Route>

        <Route path="/studentAttendance/*">
          <StudentAttendance />
        </Route>

        <Route path="/lectures/*">
          <LiveLectureForStudents />
        </Route>

        <Route path="/studentAssignment/*">
          <StudentAssignment />
        </Route>

        <Route path="/reports/study/*">
          <StudentStudyReport />
        </Route>

        <Route path="/holidays/*">
          <Holiday />
        </Route>

        <Route path="/studentFeedback/*">
          <StudentFeedback />
        </Route>
      {/if}
      {#if localUserData.role != "student" || localUserData.role != "parent"}
        {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD)}
          <Route path="/">
            <Home />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.ADMIN_DASHBOARD)}
          <Route path="/home">
            <Home />
          </Route>
        {/if}

        <Route path="/batch/*">
          {#if MODULES.hasAccess(MODULES.MANAGE_BATCH)}
            <Batch />
          {:else}
            <Forbidden />
          {/if}
        </Route>

        {#if MODULES.hasAccess(MODULES.MANAGE_JOIN_REQUEST)}
          <Route path="/batchJoinRequest/*">
            <BatchJoinRequest />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_ATTENDANCE)}
          <Route path="/attendance/*">
            <Attendance />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_ATTENDANCE)}
          <Route path="/viewAttendance/*">
            <ViewAttendance />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_CLASS_TIMINGS)}
          <Route path="/classtiming/*">
            <BatchTiming />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_HOLIDAYS)}
          <Route path="/holidays/*">
            <Holiday />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_BRANCH)}
          <Route path="/branch/*">
            <Branch />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_COURSE)}
          <Route path="/course/*">
            <Course />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_CLASSES)}
          <Route path="/class/*">
            <Classes />
          </Route>
        {/if}
        <Route path="/aptiBooster">
          <AptiBooster />
        </Route>
        <Route path="/aptiStudent/student">
          <AptiStudent />
        </Route>
        <Route path="/aptiBooster/class/:boardId" let:params>
          <Class {params} />
        </Route>
        <Route path="/aptiBooster/:boardId/:className/subjects" let:params>
          <Subjects {params} />
        </Route>
        <Route
          path="/aptiBooster/:boardId/:className/subjects/:subjectId/chapters"
          let:params
        >
          <Chapters {params} />
        </Route>

        {#if MODULES.hasAccess(MODULES.MANAGE_ENQUIRY)}
          <Route path="/enquiries/*">
            <Enquiry />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_STUDENT)}
          <Route path="/students/*">
            <Student />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_STUDENT)}
          <Route path="student/view/:id/*" let:params>
            <StudentView studentId={params.id} />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_ASSIGNMENT)}
          <Route path="/assignment/*">
            <Assignment />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_ASSIGNMENT)}
          <Route path="/assignmentprogress/*">
            <AssignmentProgress />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_PAYMENT)}
          <Route path="payment/*">
            <Payment />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_PAYMENT)}
          <Route path="pdc/*">
            <PdcManagement />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_PAYMENT)}
          <Route path="receiptSeries/*">
            <ReceiptSeries />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_FEEDBACK)}
          <Route path="/feedback/*">
            <Feedback />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_DISCUSSION_FORUM)}
          <Route path="/discussionForum/*">
            <DiscussionForum />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_QUESTION_BANK)}
          <Route path="questions/*">
            <Questions />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_QUESTION_BANK)}
          <Route path="questions_upload/*">
            <QuestionsUpload />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_QUESTION_BANK)}
          <Route path="questionsreported/*">
            <QuestionsReported />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_QUESTION_BANK)}
          <Route path="questionsreportedtype/*">
            <QuestionsReportedType />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_TEST)}
          <Route path="tests/*">
            <Tests />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_TEST)}
          <Route path="myTests/*">
            <MyTests />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_TEST)}
          <Route path="/exam-reports">
            <ExamReports userId={localUserData._id} />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_STUDY_MATERIAL)}
          <Route path="/studyMaterial/*">
            <StudyMaterial />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_STUDY_REPORT)}
          <Route path="/reports/study/*">
            <StudyReport />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_SYSTEM_USERS)}
          <Route path="/systemuser/*">
            <SystemUser />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.SCHEDULE)}
          <Route path="/schedule/*">
            <Schedule />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_TASKS)}
          <Route path="/tasks/*" let:params>
            <TaskManagement />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_LEAVES)}
          <Route path="/leave-request">
            <LeaveRequest userRole={localUserData.role} />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_TELE_CALLER_LOGS)}
          <Route path="/telelogs/*">
            <TeleCallers />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_DAILY_STAFF_REPORTING)}
          <Route path="/reportings">
            <Reports />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_MY_PROFILE)}
          <Route path="/myprofile">
            <MyProfile />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_GENERAL_SETTINGS)}
          <Route path="/settings/:tab" let:params>
            <Settings tab={params.tab} />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_APP_SLIDER)}
          <Route path="/appslider">
            <AppSlider />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_ANNOUNCEMENTS)}
          <Route path="announcements/*">
            <Announcements />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_DOUBT_SESSION)}
          <Route path="doubtSession/*">
            <DoubtSession />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_FAQ)}
          <Route path="/faq">
            <FAQ />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_SMS_TEMPLATES)}
          <Route path="/smstemplate/*">
            <SmsTemplate />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_ANNOUNCEMENT_TEMPLATE)}
          <Route path="/announcementtemplate/*">
            <AnnouncementTemplate />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_TELE_CALLER_SCRIPTS)}
          <Route path="/telescript/*">
            <TeleScript />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_TEST_INST)}
          <Route path="tests/instructions/*">
            <TestInstructions />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_ANALYTICAL_CATEGORY)}
          <Route path="analyticalCategory/*">
            <AnalyticalCategory />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_ENQUIRY_RESPONSES)}
          <Route path="/enquiries/response">
            <Responses />
          </Route>
        {/if}
        {#if MODULES.hasAccess(MODULES.MANAGE_ENQUIRY_SOURCE)}
          <Route path="/enquiries/source/*">
            <EnquirySource />
          </Route>
        {/if}
      {/if}
      {#if localUserData && STUDY_MONITORING_ACCESS.indexOf(localUserData.orgCode.toString()) != -1}
        <!-- {#if localUserData &&
    (STUDY_MONITORING_ACCESS.indexOf(localUserData.orgCode.toString()) != -1 ||
    localUserData.orgCode === "1123")} -->
        <!-- {#if MODULES.hasAccess(MODULES.MANAGE_STUDY_MONITORING_SOURCE)} -->
        <Route path="/studymonitoringsources/*">
          <StudyMonitoringSources />
        </Route>
        <!-- {/if} -->
        <Route path="/studyMonitoring/*">
          <StudyMonitoring />
        </Route>
      {/if}

      <!-- <Route path="/chat/*">
            <Chat />
        </Route> -->

      <!-- <Route path="enquiries/reports">
            <Reports from="enquiries" />
        </Route> -->

      <!-- <Route path="/accessProfile/*">
            <AccessProfile />
        </Route> -->

      <!-- <Route path="/modulesubject/*">
            <ModuleSubject />
        </Route> -->

      <!-- <Route path="/customFields/*">
            <CustomFields />
        </Route> -->

      <!-- <Route path="/accessProfileFeatures">
            <AccessProfileFeatures />
        </Route> -->

      <!-- <Route path="folders/*">
            <FolderCmp />
        </Route> -->

      <!-- <Route path="spt/*">
            <SPT />
        </Route>

        <Route path="orgTree/*">
            <OrgTree />
        </Route> -->
    </Router>
    <!-- 
    <ul class='custom-menu'>
        <li data-action="first">First thing</li>
        <li data-action="second">Second thing</li>
        <li data-action="third">Third thing</li>
</ul> -->
  </div>
{:else}
  <div class="loading-page">
    <div class="counter">
      <div class="boxLoading"></div>
      <h1>100%</h1>
      <div class="l_bar" style="width: 100%"></div>
      <p>loading</p>
    </div>
  </div>
{/if}

<!-- 
<h1>Dashboard : </h1>
<nav>
    <Link to="dashboard/profile">Profile</Link>
    <Link to="dashboard/settings">Settings</Link>
</nav>
<Router>
    <button>
        Clicked Dashboard {count} {count === 1 ? 'time' : 'times'}
    </button>
    <Route path="profile" component="{Profile}" />
    <Route path="settings">
        <Settings />
    </Route>
</Router> -->
