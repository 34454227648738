<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick, afterUpdate } from 'svelte';
  import { activeMenu } from "../stores";
  import moment from 'moment';
  import Empty from "./empty/empty.svelte";
  import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
  import BatchSelectComponent from "../utils/batchSelectComponent.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import MODULES from "../utils/modules.js" 
  let date = "";
  let dateObj = date ? new Date(date).toISOString() : "";
  let errorObj = {};
  let batchList = [];
  export let batch_id;
  let batch_timing_id;
  let batchName;
  let startTime;
  let endTime;
  let subjectName;
  let subjectId;
  let batchStartDate;
  let holidayName;
  let isThisSubjectAssignedTemp;
  let studentList = {
    data: {
      data: []
    }
  };
  let msg = '';
  const pageSize = 10;
  // let length = pageSize;
  let search = {
    value: ""
  };
  let attendanceList = []
  let noteText = '';
  let tempStudentId = '';
  let aStudentAttendance;
  let studnetAttendanceList = []
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  }
  var rangeCalendar;

  let getBatchesFromBatchTiming = async () => {
    let result = await api_getBatchByDate();
    // console.log('result ', result);
    if (result.status) {
      batchList = result.data;
    } else {

    }
    msg = "No Class Timing is selected."
    // console.log('inn getBatchesFromBatchTiming ', new Date(date), result);
  }
  let getHolidaysByDateAndBatchId = async () => {
    let result = await api_getHolidayByBatchId();
    // console.log('api_getHolidayByBatchId ', result);
    if (result.status) {
      holidayName = result.data.name;
      _$('.holiday').removeClass('d-none');
      _$('.attendance').addClass('d-none');
      // batchList = result.data;
    } else {
      _$('.holiday').addClass('d-none');
      _$('.attendance').removeClass('d-none');
      getBatchesFromBatchTiming();

    }
  }

  async function api_getHolidayByBatchId() {

    let data = { date: new Date(date), batchId: batch_id };
    let action = 'holidays.getHolidayByBatchId';
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function selectBatch(e) {
    
    //clear select calss timing validation
    delete errorObj.batch_timing_id

    batch_timing_id = e.target.dataset.id;
    batchName = e.target.dataset.batchname;
    startTime = e.target.dataset.starttime;
    endTime = e.target.dataset.endtime;

    let subjectIdsArr = [];
    subjectIdsArr = e.target.dataset.subjectid.split(',');
    subjectId = subjectIdsArr;

    let subjectNameArr = e.target.innerHTML.split('<')
    subjectName = subjectNameArr[0];

    console.log('batchName ', e.target.innerHTML.split('<'));
    _$('.batchtimingbtn').removeClass('btn-dark').addClass('btn-outline-dark');
    _$(`#btn${batch_timing_id}`).addClass('btn-dark').removeClass('btn-outline-dark');
    // console.log('select batch id', batch_timing_id);
    msg = 'No student is avalaible in this class.'
    await fetchStudentList();
    // dashboard/batchtiming/5fa18eb113b82f119c69d1d1
    // navigate(`/dashboard/attendance/markAttendance/${batch_id}/${batch_timing_id}/${new Date(date).toISOString()}`);
  }
  async function api_getBatchByDate() {

    let data = { date: new Date(date).getDay(), batch_id: batch_id };
    let action = 'batchtiming.getBatchtimingByDate';
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  function rangeChanged(target, range) {
    batch_timing_id = null
    date = range.start;
    // console.log('range.start ', range.start);
    getHolidaysByDateAndBatchId();
    clearStudentTable();
    let temp = new Date().setHours(0, 0, 0, 0);
    let rangeDate = new Date(range.start).setHours(0, 0, 0, 0);
    // console.log(temp);
    if(rangeCalendar){
      if (new Date(rangeDate).getTime() > new Date(temp).getTime()) {
        rangeCalendar.setStartDate(new Date(temp));
      }
    }
    if (new Date(rangeDate).getTime() === new Date(temp).getTime()) {
      // console.log('temp',temp);
      _$('.selectTodaysDate').addClass('d-none');
    }
    else {

      // console.log('sdf', new Date(range.start).getTime(), new Date(temp).getTime());
      _$('.selectTodaysDate').removeClass('d-none');
      // _$('')
    }
  }
  function clearStudentTable() {
    studnetAttendanceList = [];
    studentList = [];
    attendanceList = [];
    _$('.batchtimingbtn').removeClass('btn-dark').addClass('btn-outline-dark');

  }
  onMount(async () => {
    activeMenu.set('attendance');

    if (!batch_id) {
      return;
    }

    _$('[data-toggle="tooltip"]').tooltip()
    let endDate;
    let noOfDays = "";
    let noOfMonths = ""
    let val = ""
    let res = await api_getBatchById();
    if (res.status) {
      // console.log('res batch>>>>>>>>>', res);
      endDate =  res.data.startDate? new Date(res.data.startDate):new Date(); //res.data.startDate? new Date(res.data.startDate):
      // endDate.setMonth(endDate.getMonth() + 1);
      batchName = res.data.name;
      batchStartDate = endDate;
      let a = moment()
      noOfDays = a.diff(endDate, 'days') + 1;
      noOfMonths = a.diff(endDate, 'months')+2;
      //  noOfDays = 7;
      let change = noOfDays * -1;
      val = change.toString();
      // console.log('difference>>>>>>>>', noOfDays.toString());

    }
    rangeCalendar = _$('#selectDate').rangeCalendar({
      locale: "en",
      theme: "default-theme",
      themeContext: this,
      startDate: new Date(),
      endDate: moment().add("days", noOfDays),
      endDate: moment().add("months", noOfMonths),
      start: val,
      startRangeWidth: 1,
      minRangeWidth: 1,
      maxRangeWidth: 1,
      weekends: true,
      autoHideMonths: false,
      visible: true,
      trigger: null,
      // change: rangeChanged,
      changeRangeCallback: rangeChanged,
    });

    // initRangeCalendar();

    date = new Date();
    rangeCalendar.setStartDate(date);

    getHolidaysByDateAndBatchId();


  });


  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    // if (sortOrder.column === 1) {
    //   sort.name = order;
    // } else if (sortOrder.column === 2) {
    //   sort.createdOn = order;
    // } else {n
    //   sort.seqId = order;
    // }
    sort.seqId = order;
    return sort;
  }

  async function fetchStudentList(offset = 0, event) {

    let data = {
      start: 0,
      // length: length + offset >= pageSize ? length + offset : pageSize,
      search,
      sort: parseSorts({
        0: "dsc"
      }),
      batch_id: batch_id,
      batch_timing_id: batch_timing_id,
      date: date
    };

    if(!batch_timing_id && search){
      pError("Error!", "First select the batch timing")
      return
    }

    // console.info("data>>>>>>>",data);
    // fetch call
    let response = await api_list(data);
    // console.info({
    //   response
    // });
    if (response.status) {
      // console.log('student list ', response);
      var paginationData = response.data;
      // console.log(paginationData);
      length = paginationData.data.length;
      studentList = {
        recordsTotal: Number(paginationData.totalCount),
        recordsFiltered: Number(paginationData.filteredCount),
        data: paginationData.data,
      };
      await fetchAttendanceList();

    } else {

      console.error("something went wrong");
      length = 0;
      studentList = {
        recordsTotal: 0,
        recordsFiltered: 0,
        data: { data: [] },
      };
    }
    await tick();
  }

  async function fetchAttendanceList() {
    let data = {
      batch_id: batch_id,
      batch_timing_id: batch_timing_id,
      date: date
    };
    // console.info(data);
    // fetch call
    let response = await api_listAttendance(data);
    // console.info({
    //   response
    // });
    if (response.status) {
      // console.log('attendanceList ', response);
      attendanceList = response.data;
      studnetAttendanceList = []
      await dataLooping()

      // dataLooping();

    } else {
      console.error("something went wrong");
    }
    await tick();
  }

  async function api_list(data) {

    let action = 'students.getStudentListByBatchId';
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_listAttendance(data) {

    let action = 'attendance.listAttendance';
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_getBatchById() {

    let data = { _id: batch_id };
    let action = 'batch.getBatchById';
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_saveAttendance(data) {

    let action = 'attendance.saveAttendance';
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_getStudentAttendance(data) {

    let action = 'attendance.getStudentAttendance';
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function dataLooping() {
    studentList.data.forEach(aStudent => {
      if (attendanceList.length) {
        attendanceList.forEach(aAttendance => {
          if (aStudent.studentId == aAttendance.studentId) {
            aStudent['attendanceDetails'] = aAttendance;
          }
        });
      }
    });
    if (subjectId) {
      studentList.data.forEach(aStudent => {
        subjectId.forEach(aSubject => {
          // console.log('aStudent.moduleSubjects ', aStudent.moduleSubjects, aSubject);
          let res = aStudent.moduleSubjects.includes(aSubject)
          // console.log('res ', res);
          if (!res) {
            aStudent['isThisSubjectAssigned'] = false;
            if (aStudent.subNotAssigned) {
              aStudent['subNotAssigned'].push(aSubject);
            } else {
              aStudent['subNotAssigned'] = [aSubject]
            }
          }
        });
      });
    }
    studnetAttendanceList = studentList.data
    // console.log('studntattendane listt', studnetAttendanceList);
  }

  let getStudentId = async (e) => {
    // console.log('e.target.dataset ', e.target.dataset);
    tempStudentId = e.target.dataset.studentid;
    isThisSubjectAssignedTemp = e.target.dataset.isthissubjectassigned
    let data = {
      batch_id: batch_id,
      batch_timing_id: batch_timing_id,
      date: date,
      studentId: tempStudentId
    };
    // console.info(data);
    // fetch call
    let response = await api_getStudentAttendance(data);
    // console.info({
    //   response
    // });
    if (response.status) {
      noteText = response.data.notes;
      // console.log('studentattendanceDetails ', response.data.notes);
      // aStudentAttendance = response.data;
    } else {
      noteText = '';
      console.error(response.errMsg);
    }
    await tick();
    // console.log('getStudentId ', e, e.target.dataset.studentid);
  }


  let validateNote = () => {
    errorObj = {};
    if (noteText == '') {
      var newErrorObj = {};
      newErrorObj.note = "Note is required Field.";
    }
    errorObj = newErrorObj? newErrorObj : errorObj;

    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;
  }

  let updateAttendanceNotes = async (e) => {
    // console.log('notestext ', noteText);
    let data = {
      batch_id: batch_id,
      batch_timing_id: batch_timing_id,
      studentId: tempStudentId,
      notes: noteText,
      date: date,
      batchName: batchName,
      startTime: startTime,
      endTime: endTime,
      subjectId: subjectId,
      subjectName: subjectName

    }
    // console.log('isThisSubjectAssignedTemp ', isThisSubjectAssignedTemp);
    if (isThisSubjectAssignedTemp != "") {
      data['isThisSubjectAssigned'] = isThisSubjectAssignedTemp
    }
    if (validateNote()) {
      let response = await api_saveAttendance(data);
      if (response.status) {
        await fetchAttendanceList();
        await fetchStudentList();
        hideModel();
        pSuccess(null, response.data)
      } else {
        pError("Error", response.errMsg)
      }
    }
  }
  let updateAttendanceCheck = async (e) => {
    if(!(MODULES.hasAccess(MODULES.MARK_ATTENDANCE))){
      pError("Access Denied", "This profile user does not have permission to mark attendance!!")
      return
    }

    if(!batch_timing_id){
      errorObj.batch_timing_id = "Select batch timing before marking attendance"
      return
    }else{
      delete errorObj.batch_timing_id
    }

    // console.log('e ', e);
    let data = {}
    if (e.target.dataset.isthissubjectassigned != "") {
      data = {
        batch_id: batch_id,
        batch_timing_id: batch_timing_id,
        studentId: e.target.dataset.studentid,
        attendance: e.target.dataset.label,
        date: date,
        batchName: batchName,
        startTime: startTime,
        endTime: endTime,
        subjectId: subjectId,
        subjectName: subjectName,
        isThisSubjectAssigned: e.target.dataset.isthissubjectassigned
      }
    } else {
      data = {
        batch_id: batch_id,
        batch_timing_id: batch_timing_id,
        studentId: e.target.dataset.studentid,
        attendance: e.target.dataset.label,
        date: date,
        batchName: batchName,
        startTime: startTime,
        endTime: endTime,
        subjectId: subjectId,
        subjectName: subjectName,
        isThisSubjectAssigned: e.target.dataset.isthissubjectassigned
      }
    }
    // console.log('isThisSubjectAssignedTemp ', isThisSubjectAssignedTemp);


    // console.log('data', data);
    let response = await api_saveAttendance(data);
    if (response.status) {
      await fetchStudentList();

      // console.log('response sae', response);
    } else {
      console.log('j');
    }
  }


  function hideModel(params) {
    noteText = '';
    tempStudentId = '';
    _$('#modal2').modal("hide")

  }
  async function api_saveAttendanceArr(data) {

    let action = 'attendance.saveAttendanceArr';
    var response = await api_handleApiCalls(action, data);
    return response;

  }


  let markAllAttendance = async (e) => {

   
    let data = {
      batch_id: batch_id,
      batch_timing_id: batch_timing_id,
      attendance: e.target.dataset.attendance,
      date: date
    }
    let saveData = [];
    studnetAttendanceList.forEach(aStudentAttendance => {
      let obj = {
        ...data,
        studentId: aStudentAttendance.studentDetails._id
      }
      saveData.push(obj);
    });
    // console.log('sdfds ', saveData);
    let response = await api_saveAttendanceArr(saveData);
    if (response.status) {
      await fetchAttendanceList();
      await fetchStudentList();
      pSuccess(null, response.data)
    } else {
      pError("Error", response.errMsg)
    }
    // console.log('e.target.dataset.attendance', e.target.dataset.attendance);
  }

  let selectTodaysDate = async (e) => {
    date = new Date();
    rangeCalendar.setStartDate(date);
    // _$('.selectTodaysDate').addClass('d-none');
  }
  let addClassTiming = async (e) => {
    navigate("/dashboard/classtiming/" + batch_id);
  }

  let addSubToStudent = async (e) => {
    let data = {
      subjectIds: e.target.dataset.subjectids.split(','),
      studentId: e.target.dataset.studentid,
    }

    pConfirm("Assign Class Timing Subject", "Are you sure you want to assign subject to student?",
      function () { assignSubBtn(data) }
    )
  }

  async function assignSubBtn(data) {
    // console.log('assign sub ', data);
    let result = await api_assignSubToStudent(data);
    // console.log('result ', result);
    if (result.status) {
      await fetchAttendanceList();
      await fetchStudentList();
      // batchList = result.data;
      pSuccess(null, result.data)
    } else {
      pError("Error", result.errMsg)
    }
  }

  async function api_assignSubToStudent(data) {
    let sendData = {
      ...data,
      batchId: batch_id
    }

    let action = 'students.assignSubToStudent';
    var response = await api_handleApiCalls(action, sendData);
    return response;

  }
  function handleBatchChange(event) {
    if (event.detail.batch_id) {
      navigate("/dashboard/attendance/" + event.detail.batch_id)
    } else {
      navigate("/dashboard/attendance");
    }
  }

</script>

<style>
  .removeBorder {
    border-right: hidden;
  }

  .batchtiming {
    overflow-y: scroll;
    overflow-x: hidden;
  }
</style>
<div class="content-body fadeIn">
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Attendance
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        Attendance
      </h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5 ">Mark attendance for the batch.</p>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5 tx-uppercase">{batchName? batchName:""}</p>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">Batch Start Date:
        {moment(batchStartDate).format("DD/MM/YYYY")}</p>
    </div>
    <div class="d-flex">
      <div class="d-block">
        <div class="btn-group flex-fill">
          <button class="btn btn-sm btn-dark pd-x-15 mg-l-5" disabled>{date ? moment(date).format('LL') :  moment(new Date()).format('LL')}</button>
          <!-- <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 selectTodaysDate"
            on:click={selectTodaysDate}>Today's Date</button> -->

        </div>
        <!-- <div class="card"><div class="card-header showTodaysDate"></div></div> -->
      </div>
      <BatchSelectComponent batch_id={batch_id} on:change={handleBatchChange}>
      </BatchSelectComponent>


    </div>
  </div>
  <hr class="full-width">
  <div id="selectDate"></div>
  <hr class="full-width">


  <div class="holiday d-none ">
    <div class="d-flex justify-content-center">
      <div class="col-md-6 col-lg-4">
        <div class="card card-body">
          <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Holiday</h6>
          <div class="d-flex d-lg-block d-xl-flex align-items-end">
            <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{holidayName}</h3>
            <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium tx-success"><i
                  data-feather="umbrella"></i></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row attendance ht-300">
    <div class="col-md-3 pd-0">
      <div class="card ht-300">
        <div class="card-header pd-10 d-md-flex align-items-center justify-content-between">
          <h6><i data-feather="clock"></i> Class Timings</h6>
          <button on:click={addClassTiming} class="btn btn-icon btn-primary btn-xs mg-l-15" data-toggle="tooltip"
            title="Add class timing">
            <span><i data-feather="plus"></i></span>
          </button>
        </div>
        <div class="ht-100p batchtiming">
          {#if errorObj.batch_timing_id}
          <div class="parsley-errors-list mg-l-10">{errorObj.batch_timing_id}</div>
          {/if}
          {#if batchList && batchList.length}
          {#each batchList as aBatchTiming}
          <button on:click={selectBatch} data-subjectList={aBatchTiming.subjectList}
            data-subjectId={aBatchTiming.moduleSubjects} data-id={aBatchTiming._id}
            data-startTime={aBatchTiming.startTime} data-endTime={aBatchTiming.endTime}
            data-batchName="{aBatchTiming.batchDetails[0].name}" id='btn{aBatchTiming._id}'
            class="mg-10 wd-90p btn batchtimingbtn btn-sm btn-outline-dark btn-uppercase">
            {aBatchTiming.moduleSubjects.map(function (aModuleSubject) {
            var aSubject = aBatchTiming.subjectList.find(function(item) { return item._id == aModuleSubject});
            return aSubject.name;
            }).join(", ")}<br />
            {#if aBatchTiming.topic}
            Class Topic : {aBatchTiming.topic}<br>
            {/if}
            {moment(aBatchTiming.startTime).format('LT')} - {moment(aBatchTiming.endTime).format('LT')}
          </button>

          {/each}
          {:else}
          <button class="mg-t-70 mg-10 wd-90p btn btn-sm btn-white btn-uppercase" disabled>Please add a class
            timing.</button>
          {/if}
        </div>
      </div>
    </div>
    <div class="col-md-9 pd-0 ht-100p">
     
      {#if studnetAttendanceList.length}
      <table class="table table-bordered ">
        <thead>
          <tr>
            <th scope="col" class="d-md-flex align-items-center justify-content-between bd-t-0-f removeBorder ">
              <h6>Student</h6>
              <input type="search" class="col-md-8 form-control" placeholder="Search" bind:value={search.value}
                on:input={async(event)=>
              await fetchStudentList(0, event)} />
              <button class="btn" type="submit"><i data-feather="search" /></button>
            </th>
            <th scope="col" class="tx-center">{moment(date).format('ll')}</th>
            <th scope="col" class="tx-center wd-10p">
              <div class="dropdown ">
                <a href="" class="dropdown-link" data-toggle="dropdown" data-display="static">
                  Present
                </a>
                <div class="dropdown-menu tx-13">
                  <span class="dropdown-item" data-attendance="present" on:click={markAllAttendance}>Mark All
                    Present</span>
                </div>
              </div>
            </th>
            <th scope="col" class="tx-center wd-10p">
              <div class="dropdown ">
                <a href="" class="dropdown-link" data-toggle="dropdown" data-display="static">
                  Late
                </a>
                <div class="dropdown-menu tx-13">
                  <span class="dropdown-item" data-attendance="late" on:click={markAllAttendance}>Mark All Late</span>
                </div>
              </div>
            </th>
            <th scope="col" class="tx-center wd-10p">
              <div class="dropdown ">
                <a href="" class="dropdown-link" data-toggle="dropdown" data-display="static">
                  Absent
                </a>
                <div class="dropdown-menu tx-13">
                  <span class="dropdown-item" data-attendance="absent" on:click={markAllAttendance}>Mark All
                    Absent</span>
                </div>
              </div>
            </th>
            <th scope="col" class="tx-center wd-10p">
              <div class="dropdown ">
                <a href="" class="dropdown-link" data-toggle="dropdown" data-display="static">
                  Excused
                </a>
                <div class="dropdown-menu tx-13">
                  <span class="dropdown-item" data-attendance="excused" on:click={markAllAttendance}>Mark All
                    Excused</span>
                </div>
              </div>
            </th>
            <th scope="col" class="tx-center">Notes</th>
          </tr>
        </thead>
        <tbody>
          {#each studnetAttendanceList as aStudent}
          <tr>
            <td class="removeBorder media bg-ui-01 pd-y-10 pd-x-15">
              <div class="avatar">
                {#if aStudent.studentDetails.profilePic}
                <img src="{aStudent.studentDetails.profilePic}" class="wd-30 rounded-circle mg-r-15"
                  alt="{aStudent.studentDetails.name.toUpperCase()}">
                {:else}
                <div class="wd-30 rounded-circle mg-r-15"><span
                    class="avatar-initial rounded-circle">{aStudent.studentDetails.name
                    .split(" ")
                    .map((str) => str[0] || "")
                    .join("")}</span>
                </div>
                {/if}
              </div>
              <div class="media-body d-flex justify-content-between mg-l-15">
                <div>
                  <h6 class="tx-13 mg-b-2"><a
                      href="/dashboard/student/view/{aStudent.studentDetails._id}/attendance">{aStudent.studentDetails.name}</a>
                  </h6>
                  <span class="d-block tx-color-03"><i
                      data-feather="phone"></i>{aStudent.studentDetails.mobileNo}</span>
                </div>
                {#if aStudent.isThisSubjectAssigned == false}
                <button data-toggle="tooltip" title="Assign class timing subject's to student"
                  on:click={addSubToStudent} data-studentId="{aStudent.studentDetails._id}"
                  data-subjectIds="{aStudent.subNotAssigned}"
                  class="btn btn-danger pd-6 mg-l-15 ht-30 wd-30 rounded-circle">
                  !
                </button>
                {/if}
              </div>
            </td>
            {#if aStudent.attendanceDetails}
            {#if aStudent.attendanceDetails.attendance == "present"}
            <td class='tx-center'><span class="badge badge-pill badge-success">100%</span></td>
            <td class="bg-success tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="present" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="present{aStudent.studentDetails._id}"><i class="fas fa-check col" data-label="present"
                data-studentId="{aStudent.studentDetails._id}"></i>Present</td>
            <td class='tx-center'
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="late" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="late{aStudent.studentDetails._id}"><i class="far fa-clock col" data-label="late"
                data-studentId="{aStudent.studentDetails._id}"></i> </td>
            <td class='tx-center'
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="absent" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="absent{aStudent.studentDetails._id}"><i class="fas fa-times col" data-label="absent"
                data-studentId="{aStudent.studentDetails._id}"></i> </td>
            <td class='tx-center'
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="excused" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="excused{aStudent.studentDetails._id}"><i class="fas fa-ban col" data-label="excused"
                data-studentId="{aStudent.studentDetails._id}"></i> </td>

            {:else if aStudent.attendanceDetails.attendance == "late"}
            <td class='tx-center'><span class="badge badge-pill badge-warning">50%</span></td>
            <td class="tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="present" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="present{aStudent.studentDetails._id}"><i class="fas fa-check col" data-label="present"
                data-studentId="{aStudent.studentDetails._id}"></i></td>
            <td class='bg-warning tx-center'
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="late" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="late{aStudent.studentDetails._id}"><i class="far fa-clock col" data-label="late"
                data-studentId="{aStudent.studentDetails._id}"></i>Late</td>
            <td class="tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="absent" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="absent{aStudent.studentDetails._id}"><i class="fas fa-times col" data-label="absent"
                data-studentId="{aStudent.studentDetails._id}"></i> </td>
            <td class="bg-light tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="excused" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="excused{aStudent.studentDetails._id}"><i class="fas fa-ban col" data-label="excused"
                data-studentId="{aStudent.studentDetails._id}"></i> </td>

            {:else if aStudent.attendanceDetails.attendance == "absent"}
            <td class='tx-center'><span class="badge badge-pill badge-danger">0%</span></td>
            <td class="tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="present" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="present{aStudent.studentDetails._id}"><i class="fas fa-check col" data-label="present"
                data-studentId="{aStudent.studentDetails._id}"></i></td>
            <td class="tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="late" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="late{aStudent.studentDetails._id}"><i class="far fa-clock col" data-label="late"
                data-studentId="{aStudent.studentDetails._id}"></i></td>
            <td class="bg-danger tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="absent" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="absent{aStudent.studentDetails._id}"><i class="fas fa-times col" data-label="absent"
                data-studentId="{aStudent.studentDetails._id}"></i>Absent </td>
            <td class="tx-center" data-label="excused"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="excused{aStudent.studentDetails._id}"><i class="fas fa-ban col" data-label="excused"
                data-studentId="{aStudent.studentDetails._id}"></i> </td>

            {:else}
            <td class='tx-center'><span class="badge badge-pill badge-warning"></span></td>
            <td class="tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="present" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="present{aStudent.studentDetails._id}"><i class="fas fa-check col" data-label="present"
                data-studentId="{aStudent.studentDetails._id}"></i></td>
            <td class="tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="late" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="late{aStudent.studentDetails._id}"><i class="far fa-clock col" data-label="late"
                data-studentId="{aStudent.studentDetails._id}"></i></td>
            <td class="tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="absent" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="absent{aStudent.studentDetails._id}"><i class="fas fa-times col" data-label="absent"
                data-studentId="{aStudent.studentDetails._id}"></i> </td>
            <td class='tx-center'
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="excused" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="excused{aStudent.studentDetails._id}"><i class="fas fa-ban col" data-label="excused"
                data-studentId="{aStudent.studentDetails._id}"></i>Excused </td>

            {/if}
            {:else}
            <td class='tx-center'><span class="badge badge-pill badge-light">--</span></td>
            <td class="tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="present" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="present{aStudent.studentDetails._id}"><i class="fas fa-check col" data-label="present"
                data-studentId="{aStudent.studentDetails._id}"></i></td>
            <td class="tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="late" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="late{aStudent.studentDetails._id}"><i class="far fa-clock col" data-label="late"
                data-studentId="{aStudent.studentDetails._id}"></i></td>
            <td class="tx-center"
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="absent" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="absent{aStudent.studentDetails._id}"><i class="fas fa-times col" data-label="absent"
                data-studentId="{aStudent.studentDetails._id}"></i> </td>
            <td class='tx-center'
              data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
              data-label="excused" data-studentId="{aStudent.studentDetails._id}" on:click={updateAttendanceCheck}
              id="excused{aStudent.studentDetails._id}"><i class="fas fa-ban col" data-label="excused"
                data-studentId="{aStudent.studentDetails._id}"></i> </td>

            <!-- <td class='tx-center'><i class="fas fa-check col"></i></td>
              <td class='tx-center'><i class="far fa-clock col"></td>
              <td class='tx-center'><i class="fas fa-times col"></td>
              <td class='tx-center'><i class="fas fa-ban col"></td> -->
            {/if}

            <td class='tx-center'><a href="#modal2" on:click={getStudentId}
                data-isThisSubjectAssigned="{aStudent.isThisSubjectAssigned?aStudent.isThisSubjectAssigned:''}"
                data-studentId="{aStudent.studentDetails._id}"
                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" data-toggle="modal">
                <i class="far fa-file-alt" data-studentId="{aStudent.studentDetails._id}"></i>
              </a></td>
          </tr>
          {/each}
        </tbody>
      </table>
      {:else}
      <table class="table table-bordered mg-b-0">
        <thead>
          <tr>
            <th scope="col" class="d-md-flex align-items-center justify-content-between bd-t-0-f removeBorder ">
              <h6>Student</h6>
              <input type="search" class="col-md-8 form-control" placeholder="Name" bind:value={search.value}
                on:input={async(event)=>
              await fetchStudentList(0, event)} />
              <button class="btn" type="submit"><i data-feather="search" /></button>
            </th>
            <th scope="col" class="tx-center">{moment(date).format('ll')}</th>
            <th scope="col" class="tx-center wd-10p">
              <div class="dropdown ">
                <a href="" class="dropdown-link" data-toggle="dropdown" data-display="static">
                  Present
                </a>
                <div class="dropdown-menu tx-13">
                  <span class="dropdown-item" data-attendance="present" on:click={markAllAttendance}>Mark All
                    Present</span>
                </div>
              </div>
            </th>
            <th scope="col" class="tx-center wd-10p">
              <div class="dropdown ">
                <a href="" class="dropdown-link" data-toggle="dropdown" data-display="static">
                  Late
                </a>
                <div class="dropdown-menu tx-13">
                  <span class="dropdown-item" data-attendance="late" on:click={markAllAttendance}>Mark All Late</span>
                </div>
              </div>
            </th>
            <th scope="col" class="tx-center wd-10p">
              <div class="dropdown ">
                <a href="" class="dropdown-link" data-toggle="dropdown" data-display="static">
                  Absent
                </a>
                <div class="dropdown-menu tx-13">
                  <span class="dropdown-item" data-attendance="absent" on:click={markAllAttendance}>Mark All
                    Absent</span>
                </div>
              </div>
            </th>
            <th scope="col" class="tx-center wd-10p">
              <div class="dropdown ">
                <a href="" class="dropdown-link" data-toggle="dropdown" data-display="static">
                  Excused
                </a>
                <div class="dropdown-menu tx-13">
                  <span class="dropdown-item" data-attendance="excused" on:click={markAllAttendance}>Mark All
                    Excused</span>
                </div>
              </div>
            </th>
            <th scope="col" class="tx-center">Notes</th>
          </tr>
        </thead>
      </table>
      <Empty mode={search.value ? 'search' : 'empty' } batchTiming={batch_timing_id=='' ?true:false}
        searchResult={search.value !='' ?true:false} />

      {/if}
    </div>
  </div>
</div>


<div class="modal fade" id="modal2" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel6">Add a note</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="form-group col-md-12">
            <label class="d-block">Note</label>
            <textarea bind:value={noteText} on:keyup={e=> e.key === 'Enter' && updateAttendanceNotes(e)} type="text" on:blur={()=>clearError("note")} class="form-control" class:parsley-error={errorObj.note} placeholder="Write a note"/>
                    {#if errorObj.note}
                        <div class="parsley-errors-list">{errorObj.note}</div>
                         <!-- content here -->
                    {/if}
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Close</button>
          <button type="button" on:click={updateAttendanceNotes} class="btn btn-primary tx-13">Add Note</button>
        </div>
      </div>
    </div>
  </div>
