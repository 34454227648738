<script>
    import { Router, link, links, Link, Route } from "svelte-routing";
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import { onMount, tick } from 'svelte';
    import { userData } from "../../stores";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import MODULES from "../../utils/modules.js"
    import Forbidden from "../../forbidden.svelte"
    import ButtonLoader from "../../utils/button_loader.svelte"
    import { API_URL } from "../../../config.js"


    export let _id
    export let isComponent
    let studentName
    let elemModalDelete
    let elemDeactivateBatchModal
    let batchIdForDelete = null
    let currentBatchName = null
    let isLoading = false
    let reason = ''
    let reasonError = null
    let batchesList = []
    let roleIsTelecaller;
    let inactiveStudent;
    const onKeyPress = e => {
        if (e.charCode === 13) inactivateStudentFromBatch(); removeAssignedBatch
    };
    const onKeyPress1 = e => {
        if (e.charCode === 13) removeAssignedBatch();
    };
    function goTo(to) {

    }

    async function setBreadcrumbStudName() {
        studentName = await api_getStudentNameById(_id)
        feather.replace();
    }

    async function api_getStudentNameById(id) {
        var resData = await api_handleApiCalls('students.getStudentNameById', { _id: id })
        if (resData.status) {
            inactiveStudent = resData.data.inactive == true
            return resData.data.name

        } else {

            pError("Error", resData.errMsg)
            return '-'
        }
    }

    async function api_getStudentsBatchesList(id) {
        let resData = await api_handleApiCalls('students.getAssignedBatches', { _id: id })
        if (resData.status) {

            batchesList = resData.data
            await tick()
            feather.replace({ width: "1em", height: "1em" })
        } else {
            batchesList = []
        }
    }
    async function api_updateAccessOfBatchById(data) {
        let resData = await api_handleApiCalls('students.updateBatchAccess', data)
        if (resData.status) {
            return resData
        } else {
            pError("Error!", resData.errMsg)
        }
    }
    function convertToINR(input) {
        let amount = Number(input);
        if (Number.isNaN(amount)) { amount = 0 }
        return amount.toLocaleString('en-IN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'INR'
        });
    }
    function formatDate(date) {
        var d = new Date(date)
        return d.toLocaleDateString()
    }
    async function api_removeAssignedBatch(data) {
        isLoading = true
        let resData = await api_handleApiCalls('students.removeBatch', data)
        isLoading = false
        if (resData.status) {
            pSuccess("Success!", resData.data)
            showHideDeleteModal(false)
            api_getStudentsBatchesList(_id);
        } else {
            pError("Error!", resData.errMsg)
        }
    }

    let removeAssignedBatch = async (_id) => {

        if (!reason) return reasonError = 'Please enter reason'

        api_removeAssignedBatch({ _id: batchIdForDelete, reason })
    }

    let handleClickAccess = async (id, access) => {

        let data = { access, _id: id }
        if (access == false) {
            if (!reason) return reasonError = 'Please enter reason'
            data.reason = reason
        }

        if (isLoading) return

        isLoading = true
        let res = await api_updateAccessOfBatchById(data)
        isLoading = false

        if (res) {
            showHideDeactivateModal(false)
            pSuccess("Success!", res.data)
            api_getStudentsBatchesList(_id)
        }
    }
    function updateFeather() {
        feather.replace({ width: "1em", height: "1em" })
    }

    const unsubscribe = userData.subscribe(value => {
        roleIsTelecaller = value.role == "telecaller"
    });

    const showHideDeleteModal = async (show, batchId, batchName) => {
        if (show) {
            batchIdForDelete = batchId
            currentBatchName = batchName
            _$(elemModalDelete).modal('show')
        } else {
            batchIdForDelete = null
            currentBatchName = null
            _$(elemModalDelete).modal('hide')
        }
    }

    const showHideDeactivateModal = async (show, batchId, batchName) => {
        if (show) {
            batchIdForDelete = batchId
            currentBatchName = batchName
            _$(elemDeactivateBatchModal).modal('show')
        } else {
            batchIdForDelete = null
            currentBatchName = null
            _$(elemDeactivateBatchModal).modal('hide')
        }
    }

    const inactivateStudentFromBatch = async () => {
        handleClickAccess(batchIdForDelete, false)
    }


    onMount(async () => {
        unsubscribe();
        updateFeather();
        setBreadcrumbStudName(_id)
        api_getStudentsBatchesList(_id);

        _$(elemModalDelete).on('hidden.bs.modal', function (e) {
            batchIdForDelete = null
            reasonError = null
            reason = null
            currentBatchName = null
        });
        _$(elemDeactivateBatchModal).on('hidden.bs.modal', function (e) {
            batchIdForDelete = null
            reasonError = null
            reason = null
            currentBatchName = null
        });
    });
</script>
<style>
    .mail-group-label {
        display: block;
        padding: 6px 8px;
        font-size: .875rem;
        font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
        font-weight: 500;
        letter-spacing: .5px;
        color: #222;
    }

    .media {
        margin-top: 5px;
        padding: 8px;
    }

    .avatar .avatar-initial {
        font-size: 0.7rem;
        text-transform: initial;
    }

    .title-legends {

        width: auto;
        background-color: inherit;
        padding: 0 10px;
        position: relative;
        margin-left: -10px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: inherit;
        color: #1c273c;
        letter-spacing: .5;
        margin-bottom: 0;
    }

    .empty-state-img {
        text-align: center;
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .deny:hover {
        background-color: #dc3545 !important;
        border-color: #dc3545;
        color: #fff;
    }

    .allow:hover {
        background-color: #0d9448 !important;
        border-color: #0c8842;
        color: #fff;
    }
</style>

<div class="modal effect-scale" bind:this={elemModalDelete} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ">

            <div class="modal-body pd-20 pd-sm-30">
                <h4 class="tx-16 tx-bold mg-b-3">Remove from {currentBatchName ? currentBatchName : 'assigned batch'}.
                </h4>
                <p class="mg-b-20 tx-13 tx-color-03">Are you sure you want remove {studentName?studentName: ''} from
                    {currentBatchName ? `"${currentBatchName}"` : ''} batch</p>
                <a href="" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>

                <div class="mg-t-15">
                    <div class="form-group">
                        <label for="">Reason <span class="required-field">*</span></label>
                        <input type="text" on:keypress={onKeyPress1} class="form-control" bind:value={reason}
                            placeholder="Reason" class:parsley-error={reasonError}>
                        {#if reasonError}
                        <div class="parsley-errors-list">{reasonError}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                </div>


                <div class="d-md-flex justify-content-end">

                    <button type="button" class="btn  pd-x-15 btn-secondary tx-12" data-dismiss="modal">CANCEL
                        <i data-feather="x" class="wd-10 mg-l-5 "></i>
                    </button>
                    <button type="button" disabled={isLoading} on:click={removeAssignedBatch}
                        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">

                        <i data-feather="arrow-right" class="wd-10 mg-l-5 "></i>
                        <ButtonLoader isLoading={isLoading} btnText="Yes"></ButtonLoader>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal effect-scale" bind:this={elemDeactivateBatchModal} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ">

            <div class="modal-body pd-20 pd-sm-30">
                <h4 class="tx-16 tx-bold mg-b-3">
                    Inactivate from {currentBatchName ? currentBatchName : 'assigned batch'}.
                </h4>
                <p class="mg-b-20 tx-13 tx-color-03">
                    Are you sure you want to inactivate {studentName ? studentName : 'student'} from {currentBatchName ?
                    currentBatchName : ''}
                </p>
                <a href="" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>


                <div class="mg-t-15">
                    <div class="form-group">
                        <label for="">Reason <span class="required-field">*</span></label>
                        <input type="text" on:keypress={onKeyPress} class="form-control" bind:value={reason}
                            placeholder="Reason" class:parsley-error={reasonError}>
                        {#if reasonError}
                        <div class="parsley-errors-list">{reasonError}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                </div>


                <div class="d-md-flex justify-content-end">

                    <button type="button" class="btn  pd-x-15 btn-secondary tx-12" data-dismiss="modal">CANCEL
                        <i data-feather="x" class="wd-10 mg-l-5 "></i>
                    </button>
                    <button type="button" disabled={isLoading} on:click={inactivateStudentFromBatch}
                        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">

                        <i data-feather="arrow-right" class="wd-10 mg-l-5 "></i>
                        <ButtonLoader isLoading={isLoading} btnText="Yes"></ButtonLoader>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

{#if MODULES.hasAccess(MODULES.ASSIGN_BATCH)}
<div class="d-sm-flex flex-row justify-content-between mail-group-label">
    <h4 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0"> Assigned Batches </h4>

    {#if !roleIsTelecaller}
    {#if !inactiveStudent}
    {#if MODULES.hasAccess(MODULES.UPDATE_STUDENT)}
    <a href="/dashboard/students/info/{_id}/batch-info" use:links
        class="btn btn-xs pd-x-15 btn-primary btn-uppercase"><i data-feather="plus" class="wd-10 mg-r-5"></i>
        Assign Batch
    </a>
    {/if}
    {/if}
    {/if}
</div>
<hr style="margin-top: 5px;">
<div class="pd-15">
    {#if batchesList.length == 0 }
    <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="" style="width:200px">
            <img src="/images/empty.svg" class="img-fluid" alt="No Result" />
            <p class="tx-bold mg-b-0" style="text-align:center;">No Batches Available.</p>
        </div>
        {#if !inactiveStudent}
        <div class="" style="text-align:center;">Click <a href="/dashboard/students/info/{_id}/batch-info" use:links
                class="">here</a> to assign a batch</div>
        {/if}
    </div>
    {/if}
    {#if !inactiveStudent}
    {#each batchesList as batch}
    <div class="card card-body shadow-none mg-b-15">
        <div class="marker marker-ribbon pos-absolute t-10 l-0">
            {#if batch.access}
            <span style="color: green">currently active</span>
            {:else}
            <span style="color: red">currently inactive</span>
            {/if}
        </div>
        <div class="d-flex flex-row justify-content-between">
            <h1></h1>
            <p class="tx-medium mg-b-2 tx-gray-700">Batch Code: {batch.batch.code}</p>
        </div>
        <div class="d-flex flex-row justify-content-between">
            <h6 class="mg-b-2 tx-semibold">{batch.batch.name}</h6>
        </div>
        <div class="d-flex justify-content-between mg-t-8">
            <span class="tx-color-02  mg-b-0">Student Joining Date: {formatDate(batch.joiningDate)}</span>
            <span class="tx-gray-700 mg-b-0">Batch Start Date: {formatDate(batch.startDate)}</span>
        </div>
        <p class="mg-b-15 tx-gray-700 mg-t-5">Counselor - <i>{batch.counselorUser ? (batch.counselorUser.name ?
                batch.counselorUser.name : 'not assigned yet') : 'not assigned yet'}</i></p>
        <div class="d-md-flex justify-content-end mg-t-15">

            {#if MODULES.hasAccess(MODULES.DEACTIVATE_ASSIGNED_BATCH)}
            {#if batch.access}
            <!-- <button type="button" class="btn btn-xs btn-secondary"><i class="mg-r-5" data-feather="unlock"></i> Activated </button> -->
            <button type="button" on:click="{() => showHideDeactivateModal(true, batch._id, batch.batch.name)}"
                class="btn btn-xs btn-secondary deny mg-b-8 mg-r-15"><i class="mg-r-5" data-feather="lock"></i>
                Inactivate </button>
            {:else}
            <button type="button" on:click="{() => handleClickAccess(batch._id, true)}"
                class="btn btn-xs btn-secondary allow mg-b-8 mg-r-15"><i class="mg-r-5" data-feather="unlock"></i>
                Activate </button>
            <!-- <button type="button" class="btn btn-xs btn-secondary"><i class="mg-r-5" data-feather="lock"></i> Inactivated </button> -->
            {/if}
            {/if}
            {#if MODULES.hasAccess(MODULES.REMOVE_ASSIGNED_BATCH)}
            <a href="#" on:click={()=>{showHideDeleteModal(true, batch._id, batch.batch.name)}} class="btn btn-xs
                btn-primary delete mg-b-8 mg-r-5"><i class="tx-16 mg-r-5 " data-feather="trash"></i> Remove from
                batch</a>
            {/if}
            {#if MODULES.hasAccess(MODULES.UPDATE_ASSIGNED_BATCH)}
            <a href={`/dashboard/students/info/${batch.studentId}/batch-info/edit/${batch._id}`} use:link
                class="btn btn-xs btn-primary mg-b-8"><i class="tx-16 mg-r-5" data-feather="edit"></i> Edit batch
                info</a>
            {/if}
        </div>
    </div>
    {/each}
    {/if}

</div>

{:else}
<Forbidden />
{/if}
{#if inactiveStudent}
<h2 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Inactivated Student</h2>
{/if}
