<script>
    import { Router, Link, Route } from "svelte-routing";
    import ListRoute from "./list/list.svelte";
    import AddUpdate from "./addupdate/addupdate.svelte";
</script>

<Router>

    <Route path="edit/:id" let:params>
        <AddUpdate _id="{params.id}"></AddUpdate>
    </Route>
    <Route path="">
        <ListRoute></ListRoute>
    </Route>
</Router>



