<script>
    import { Router, Link, link, navigate, Route } from "svelte-routing";
    import { userData } from "../stores";
    import MODULES from "../utils/modules.js"
    import { API_URL } from "../../config.js"

    let roleIsStudent = false;

    let localUserData = {
        fullName: "...",
        role: "...",
        avatar: "/images/man.png",
        access: {
            home: false,
            students: false,
            settings: false,
        },
    };

    const unsubscribe = userData.subscribe((value) => {
        localUserData = value;
        roleIsStudent = value.role == 'student';

        console.log("localUserData : ", localUserData)
    });


    let studentSearch = "";

    let showPanel = false;

    let studentList = [];

    async function api_getStudentsList(data) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var payload = JSON.stringify({
            action: 'students.list',
            accessToken: localStorage.getItem("accessToken"),
            data: {
                start: 0,
                length: 10,
                studentSearch: data
            }
        });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: payload,
        };
        try {
            var resObj = await fetch(API_URL, requestOptions);
            var response = await resObj.json();
            return response;
        } catch (error) {
            console.error("error", error);
            return { error: error };
        }
    }


    let debounce = (f, w) => { let t; return function fn(...args) { const later = () => { t = null; f(...args); }; clearTimeout(t); t = setTimeout(later, w); }; };


    var returnedFunction = debounce(async function () {
        let response = await api_getStudentsList(studentSearch);
        studentList = response.data.data;
        console.log("studentList : ", studentList);
    }, 250);

    let keyup = function () {
        returnedFunction();
        if (studentSearch.length > 0) {
            showPanel = true;
        } else {
            showPanel = false;
        }

    }
    let clearSearch = function () {
        studentSearch = "";
        showPanel = false;
        returnedFunction();
    }

    let onclickView = function (studentId) {
        showPanel = false;
        studentSearch = "";
        // navigate(("/dashboard/students"))
        window.location = "/dashboard/student/view/" + studentId;
    }
    let logout = function () {
        localStorage.clear();
        localUserData = null;
        navigate("/", { replace: true });
    }
</script>

<div class="content-header">
    <div class="content-search">
        {#if !roleIsStudent}


        <i data-feather="search"></i>
        <input type="text" bind:value={studentSearch} on:change={onchange} on:keyup={keyup} class="form-control"
            placeholder="Search student..." style="box-shadow: none !important;">

        {#if studentSearch.length > 0}
        <svg xmlns="http://www.w3.org/2000/svg" on:click={clearSearch} width="24" height="24" viewBox="0 0 24 24"
            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-x">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
        {/if}
        {/if}

    </div>
    <!-- 
        
        <a href="/" class="nav-link"><i data-feather="help-circle"></i></a>
        <a href="/" class="nav-link"><i data-feather="grid"></i></a>
        <a href="/" class="nav-link"><i data-feather="align-left"></i></a>-->
    <nav class="nav">
        <!-- <a href="/" use:link class="nav-link"> -->
        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={logout}><i
                data-feather="log-out" />
            Logout</button>
        <!-- </a> -->
    </nav>
</div>

{#if showPanel}
<div class="fadeIn" style="    position: absolute;
    background: #f8f9fc;
    /* box-shadow: rgb(0 0 0 / 50%) 0px 4px 16px 2px; */
    height: auto;
    min-height: 59px;
    z-index: 10000;
    width: 100%;
    border-bottom: 1px solid #e2e5ed;">
    {#each studentList as aStudent}
    <div style="cursor:pointer;margin: 10px;" on:click={()=>{onclickView(aStudent._id)}} class="list-group-item d-flex
        align-items-center bd-0 bg-transparent pd-0 btn-edit-student"
        student-id="{aStudent._id}">
        <img src="{aStudent.profilePic ? aStudent.profilePic : " /images/man.png" }" class="avatar mg-r-15"
            alt="{aStudent.name.toUpperCase()}">
        <div class="wd-80p bg-transparent">
            <h6 style="cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent btn-edit-student"
                student-id="{aStudent._id}">{
                aStudent.name || "-"
                }</h6>
            <span class="d-block tx-11 text-muted bg-transparent">
                <i data-feather="phone"></i>
                {aStudent.mobileNo || "-"}
            </span>
        </div>
    </div>
    {:else}
    <div style="height: 59px;display:flex;align-items: center;padding-left: 20px;">Nothing found</div>
    {/each}
</div>
{/if}
