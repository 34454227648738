<script>
    import { Router, Link, links, link, Route, navigate } from "svelte-routing";
    import { onMount, afterUpdate, tick } from 'svelte';
    import { pConfirm, pSuccess } from "../utils/notify.svelte"
    import QuestionList from "./questionList.svelte";
    import { activeMenu, _selectedQuestionsIds, updateSelectedQuestion } from "../stores";
    import ButtonLoader from "../utils/button_loader.svelte"
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import MODULES from "../utils/modules.js" 
    import FolderModal from "../folderCmp/questionBankModal.svelte";
import { pError } from "../utils/notify.svelte";
    export let parentId;
    let modalUpdateFolder
    let modalCmp;
    let elemModalMoveFolder
    let search = { value: "" };
    let newFolderName = "";
    let isLoadingBatches = true;
    let errorObj = {};
    let folderList = [];
    let oldParentId = "";
    let moveIsLoading = false
    let breadCrumb = [];
    let api_moveFlag = ''
    let showModal = false;
    let selectedFolderId
    let selectedLinkPath = []
    let filterQuestionType
    let modalCmpNQ;
    let selectedQuestionIdsArr =[]


    let qType;

    let btnDisable = false

    let isLoading = false
    let questionsBtnLoading = false

    let questionId
    let questionTags
    let questionType
    let questionCount = 0;

    let updateFolderObj = {
        _id: null,
        name: ''
    }
    let filter
    let isSearch= false
    let questionList=[]
    let filterModalThis;
    let filterElemBatch;
    let filterByPrefix;
    let filterElemUser;
    let filterStartDate;
    let questionTypesList
    let selectizeInstanceQuestionType
    const onKeyPress = e => {
    if (e.charCode === 13) createNewFolder();
  };

  const onPrintClick = () => {
        api_printSelectedQuestions(Array.from($_selectedQuestionsIds))
    }
  async function api_printSelectedQuestions(questionsIds=[]) {
        _$('#btn-print-selected-q').prop('disabled', true)
        questionsBtnLoading = true
        let response = await api_handleApiCalls('test.generatePDFByQuestionIds', questionsIds)
        questionsBtnLoading = false
        _$('#btn-print-selected-q').prop('disabled', false)

        if (response.status) {
            pSuccess("Success", response.data)
            updateSelectedQuestion(new Set())
            await tick()
            _$("#select_all_question").prop('checked', false)
            _$(".select-question").prop( "checked", false);
        } else {
            pSuccess("Error", response.errMsg)
        }
    }
    /* Question Selection START */
    const clearAllSelectedQuestions = async () => {
        updateSelectedQuestion(new Set())
        qSelectCheckboxClickHandler()
        checkIfAllSelected(0)
        await tick()
    }
    const qSelectCheckboxClickHandler = (all) => {
            _$(".select-question")
            .toArray()
            .map((element) => {
                let elem_checkbox = _$(element);
                let questionId = elem_checkbox.attr("qId");
                if (all) elem_checkbox.prop("checked", true);
                else elem_checkbox.prop("checked", false);

            });
        }
        const checkIfAllSelected = (dataLength) => {
            let selectedArr = _$(".select-question")
            .toArray()
            .filter((element) => {
                let elem_checkbox = _$(element);
                let qId = elem_checkbox.attr("qId");
                return $_selectedQuestionsIds.has(qId)
            });

            if (selectedArr.length == dataLength && dataLength != 0) _$('#select_all_question').prop("checked", true);
            else _$('#select_all_question').prop("checked", false);
        }
        const selectAllUnselect = async (checked, selectedArr) => {
            let _setTemp = $_selectedQuestionsIds
            if (checked) {
                selectedArr.forEach(obj => _setTemp.add(obj._id))
            } else {
                selectedArr.forEach(obj => _setTemp.delete(obj._id))
            }
            updateSelectedQuestion(_setTemp)
            await tick()
            qSelectCheckboxClickHandler(checked)
        }
    /* Question Selection END */

  const proccedMoveToFolder = async (folderId, selectedLinkPath) => {
    if(selectedLinkPath.length == 0) return 
    let parentId = selectedLinkPath[selectedLinkPath.length -1].id

    let response = await api_handleApiCalls('questions.moveFolder', {
        folderId,
        parentId,
        moveFlag: api_moveFlag
    })
    if(response.status) {
        pSuccess('Success!', `Folder moved to ${selectedLinkPath[selectedLinkPath.length -1].name}`)
        resetAfterMoveAction()
        navigate("/dashboard/questions/questionBank/"+parentId)
    } else {
        pError("Error!", response.errMsg)
    }
  }
  const proccedMoveSelectedQuestions = async (selectedQuestionIdsArr, selectedLinkPath)  =>  {
    if(selectedLinkPath.length == 0) return 
    let parentId = selectedLinkPath[selectedLinkPath.length -1].id

    let response = await api_handleApiCalls('questions.moveFolder', {
        selectedQuestionIdArr: selectedQuestionIdsArr,
        parentId,
        moveFlag: api_moveFlag
    })
    if(response.status) {
        pSuccess('Success!', `Questions moved to ${selectedLinkPath[selectedLinkPath.length -1].name}`)
        resetAfterMoveAction()
        updateSelectedQuestion(new Set())
        _$('.searchByTag_input').val('')
        isSearch = false
        navigate("/dashboard/questions/questionBank/"+parentId)
    } else {
        pError("Error!", response.errMsg)
    }
  }
  const proccedMoveTQuestions = async (oldParentId, selectedLinkPath)  =>  {
    if(selectedLinkPath.length == 0) return 
    let parentId = selectedLinkPath[selectedLinkPath.length -1].id

    let response = await api_handleApiCalls('questions.moveFolder', {
        folderId: oldParentId,
        parentId,
        moveFlag: api_moveFlag
    })
    if(response.status) {
        pSuccess('Success!', `Questions moved to ${selectedLinkPath[selectedLinkPath.length -1].name}`)
        resetAfterMoveAction()
        navigate("/dashboard/questions/questionBank/"+parentId)
    } else {
        pError("Error!", response.errMsg)
    }
  }

  const resetAfterMoveAction = () => {
    selectedLinkPath = []
        selectedFolderId = undefined
        api_moveFlag = ''
  }
  
  const moveProceedButtonClick = () => {
    if(api_moveFlag == 'folder')
        proccedMoveToFolder(selectedFolderId, selectedLinkPath)
    else  if(api_moveFlag == 'questionIdArr'){
        proccedMoveSelectedQuestions(selectedQuestionIdsArr, selectedLinkPath)
    }
    else 
        proccedMoveTQuestions(selectedFolderId, selectedLinkPath)

  }
  
  
  const moveFolderClickHandler = (folderId) => {
    selectedFolderId = folderId
    selectedQuestionIdsArr = []

    api_moveFlag = 'folder'
    showModalSelectFolder(true)
  }
  
  const moveQuestionsClickHandler = (oldParentId) => {
    selectedFolderId = oldParentId
    api_moveFlag = 'questions'
    showModalSelectFolder(true)
  }
  const moveSelectedQuestionsClickHandler = (selectedQuestionIdArr) => {
    selectedQuestionIdsArr = Array.from(selectedQuestionIdArr)

      api_moveFlag = 'questionIdArr'
      showModalSelectFolder(true)
  }
  
  const showModalMoveFolder = (show) => {
    show ? _$(elemModalMoveFolder).modal('show') : _$(elemModalMoveFolder).modal('hide')
  }
  
  const showModalSelectFolder = (show) => {
    if(show) {
        showModalMoveFolder(false)
        showModal = true
    } else {
        showModal = false
        _$('.modal-backdrop').remove()
        showModalMoveFolder(true)
    }
  }
  
function onCloseModal(event) {
    selectedLinkPath = []
    selectedLinkPath = event.detail.selectedFolder
    showModalSelectFolder(!showModal);
}
function hideFilterModel(params) {
    _$("#filter-modal").modal("hide");
     assignmentObj.topic = "";
   }
  function showFilterModel(params) {
    _$("#filter-modal").modal("show");
  }


let showHideFilterModal = ()=>{
    _$('#filter-modal').modal('show');
  }

    async function api_addQuestion(data) {

        let action = "questions.add";
        var response = await api_handleApiCalls(action,data);
        return response

    }

    function bindDatatable() {
        questionsDataTable = _$(demoDataTable).DataTable({
            responsive: true,
            language: {
                processing: "",
                searchPlaceholder: 'Search # ID',
                sSearch: '',
                lengthMenu: '_MENU_ Questions/page',
            },
            ajax: function (data, callback, settings) {
                _$.ajax({
                    url: API_URL,
                    method: 'post',
                    data: {
                        action: 'questions.list',
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value
                        }
                    },
                    success: function (resData) {
                        var result = []
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(paginationData.filteredCount),
                                data: paginationData.data
                            }
                            if (resData?.data?.data.length) {
                                // emptyState = true
                            }
                            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                            callback(result);
                        }
                        else {
                            console.log("Something went wrong");
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        // emptyState = true
                    }
                })
            },
            columns: [
                {
                    data: '_id', render: function (data, type, full) {
                        return "#" + full.qId;

                    }
                },
                {
                    data: '_id', render: function (data, type, full) {
                        //     var htmlStr = `<div class="media ">
                        //     <div class="media-body mg-l-10">
                        //         <h6 class="tx-13 mg-b-3">${full.categoryInfo.exam.name} >
                        //             ${full.categoryInfo.subject.name}</h6>
                        //         <span class="tx-12">${full.categoryInfo.topic.name} >
                        //             ${full.categoryInfo.subTopic.name}</span>
                        //     </div><!-- media-body -->
                        // </div>`
                        return "htmlStr";

                    }
                },
                {
                    data: '_id', render: function (data, type, full) {

                        let qTypeFullName;
                        switch (full.qType) {
                            case 'multipleResponse':
                                qTypeFullName = "Multiple Response Type";
                                break;
                            case 'multipleChoice':
                                qTypeFullName = "Multiple Choice Type";
                                break;
                            case 'singleDigit':
                                qTypeFullName = "Single Digit Type";
                                break;
                            case 'decimalType':
                                qTypeFullName = "Decimal Type";
                                break;
                            case 'fillInTheBlanks':
                                qTypeFullName = "Fill in the Blanks";
                                break;
                            case 'trueFalse':
                                qTypeFullName = "True Or False";
                                break;
                            default:
                                qTypeFullName = "-"
                        }

                        return qTypeFullName;

                    }
                },
                {
                    data: '_id', render: function (data, type, full) {
                        return full.pmarks ? full.pmarks : 0;

                    }
                },
                {
                    data: '_id', render: function (data, a, b, c) {
                        return `
                        <div class="d-md-flex flex-row">
                            <a href="#" class="rename mg-r-5 btn-edit-question" question-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>
                        </div>
                        `;
                    }
                },

            ],
            "drawCallback": function () {
                feather.replace()
                _$('.btn-edit-question').unbind('click').click(function (e) {
                    e.preventDefault();
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                    var questionId = _$(this).attr("question-id");
                    navigate(("/dashboard/questions/view/" + questionId))
                    return false;
                })
            },
            "processing": true,
            "serverSide": true,
        });
        _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
    }

    let openAddFolderModal = function () {
        newFolderName = "";
        _$(modalCmp).modal("show");
    };

    function randomId() {
        var digits = '0123456789';
        let rId = '';
        for (let i = 0; i < 10; i++) {
            rId += digits[Math.floor(Math.random() * 10)];
        }
        return rId;
    }
    var cmpRandomId = randomId();

    let noList = false;

    let clearError = function (fieldName, objName) {
        if (errorObj[objName] && errorObj[objName][fieldName]) {
            delete errorObj[objName][fieldName];
        } else if (errorObj[fieldName]) {
            delete errorObj[fieldName];
        }
        errorObj = errorObj;
    };

    $: parentIdChanged() && parentId;

    let parentIdChanged = function () {
        loadChild();
        checkForQuestions();
    }

    async function api_getChildren(data) {

        let action = "folder.getChildren";
        var response = await api_handleApiCalls(action,data);
        return response.data

    }

    async function api_renameFolder(data) {

        let action = "folder.rename";
        var response = await api_handleApiCalls(action,data);
        return response

    }

    async function api_deleteFolder(data) {

        let action = "folder.getChildren";
        var response = await api_handleApiCalls(action,data);
        return response

    }

    async function api_createNode(data) {

        let action = "folder.createNode";
        var response = await api_handleApiCalls(action,data);
        if (response.errMsg) {
          pError(null, response.errMsg);
        }
        return response

    }
    async function api_getPathTillRootByNodeId(data) {

        let action = "folder.getPathTillRootByNodeId";
        var response = await api_handleApiCalls(action,data);
        return response

    }


     async function api_getQuestionCountForParent(data) {

        let action = "folder.getQuestionCountForParent";
        var response = await api_handleApiCalls(action,data);
        return response

        }

    function showHideUpdateNameFolderModal (show = true) {
        show ? _$(modalUpdateFolder).modal("show") : _$(modalUpdateFolder).modal("hide")
    }

    let btnRenameFolder = async function (id) {

        let folderObj = folderList.find(item => item._id == id)
        
        if (folderObj && folderObj._id) {
            updateFolderObj = folderObj
            showHideUpdateNameFolderModal(true)
            await tick()
        }
    }

    let btnProcceedRenameFolder = async function () {
        if(updateFolderObj._id) {

            let resData = await api_renameFolder({_id: updateFolderObj._id, name: updateFolderObj.name})
            if(resData.status) {
                loadChild()
                showHideUpdateNameFolderModal(false)

                await tick()
            }


        }
    }

    let createNewFolder = async function () {
        await tick()
      

        if (parentId) {
            let createRes = await api_createNode({
                parentId: parentId,
                typeIsQuestionBank: true,
                name: newFolderName
            })

            parentId = createRes.data.parentId;
            _$(modalCmp).modal("hide");
            cmpRandomId = randomId();
        } else {
            let createRes = await api_createNode({
                root: true,
                typeIsQuestionBank: true,
                name: newFolderName,
                parentId:parentId
            })

            //   parentId = createRes.data.parentId;

            _$(modalCmp).modal("hide");
            cmpRandomId = randomId();
        }
        loadChild();
    }

    let checkForQuestions = async function(){
            let countRes = await api_getQuestionCountForParent({
                parentId: parentId,
            })
            if(countRes?.data)
            questionCount = countRes.data.questionCount;
            await tick()
    }

    let loadChild = async function (offset = 0, event) {
        if (parentId) {
            let childList = await api_getChildren({
                typeIsQuestionBank: true,
                parentId: parentId,
                search
            })
            folderList = childList;
            if (childList.length == 0) noList = true;
            else noList = false;


            let treeRes = await api_getPathTillRootByNodeId({
                nodeId: parentId
            });
            breadCrumb = treeRes.data.fullPath.reverse();
        } else {

            let isLoadingBatches = true;
            var data = {
                typeIsQuestionBank: true,
                root: true,
                search,
            }
            let childList = await api_getChildren({
                typeIsQuestionBank: true,
                root: true,
                search,
            })

            folderList = childList;
            if (childList.length == 0){
                isLoadingBatches = false;
                noList = true;
            } 
            else{
                noList = false;
                isLoadingBatches = false;
            } 

            let treeRes = await api_getPathTillRootByNodeId({
                nodeId: "parentId"
            });
            breadCrumb = treeRes.data.fullPath.reverse();
        }
        feather.replace();
    }

    if (parentId != oldParentId) {
        loadChild();
        checkForQuestions();
        oldParentId = parentId;

    }
   
    onMount(()=>{
       
        feather.replace();
        bindSelect()
        activeMenu.set('questionBank');
        const urlParams = new URLSearchParams(window.location.search);
                    let callback =  urlParams.get("callbackUrl")
                    let qIdFilter =  urlParams.get("qIdSearch")
                    
                    let qTagsFilter =  urlParams.get("qTagsSearch")
                    let qTypeFilter = urlParams.get("qTypeSearch")
                    if(qIdFilter && qTagsFilter && qTypeFilter) {
                        questionId  = qIdFilter
                        questionTags = qTagsFilter
                        questionType = qTypeFilter
                        bindSelect(questionType)
                        if(questionId && questionTags && questionType) {
                            applyFilter(questionType)
                            isSearch = true
                        }
                         else isSearch = false
                    }
                    if(qTypeFilter && qTagsFilter) {
                        questionTags = qTagsFilter
                        questionType = qTypeFilter
                        bindSelect(questionType)
                        if(questionType && questionTags) {
                            applyFilter(questionType)
                            isSearch = true
                        }
                         else isSearch = false
                    }
                    if(qTypeFilter && qIdFilter) {
                        questionId = qIdFilter
                        questionType = qTypeFilter
                        bindSelect(questionType)
                        if(questionId && questionType) {
                            applyFilter(questionType)
                            isSearch = true
                        }
                         else isSearch = false
                    }
                    if(qIdFilter && qTagsFilter) {
                        questionId  = qIdFilter
                        questionTags = qTagsFilter
                        if(questionId && questionTags) isSearch = true
                         else isSearch = false
                    }
                    if(qIdFilter) {
                         questionId  = qIdFilter
                         if(questionId) isSearch = true
                         else isSearch = false
                        }
                    if(qTagsFilter)  {
                        questionTags = qTagsFilter
                        if(questionTags) isSearch = true
                         else isSearch = false
                    }
                    if(qTypeFilter)  {
                        questionType = qTypeFilter
                        bindSelect(questionType)
                        if(questionType) {
                            applyFilter(questionType)
                            isSearch = true
                        }
                         else isSearch = false
                    }
                   
       
    })

     let handleClickProceed = async function () {
            if (!qType) return;
            _$(modalCmpNQ).modal('hide');
            navigate(("/dashboard/questions/create/" + qType +"/"+ parentId))
        }


let getQuestionListByQuestionId = async function ( qId, tag , type) {
    if(qId || tag || type) isSearch=true
    else{
       
        // navigate('/dashboard/questions/questionBank')
        if(!questionType){
            isSearch=false
            navigate('/dashboard/questions/questionBank')
        }
       
    }
}
async function bindSelect(selectedValue){
    questionTypesList =[
        {value:"",name:"Select Type"},
        {value:"all",name:"All Types"},
        {value:"multipleChoice",name:"Multiple Choice"},
        {value:"multipleResponse",name:"Multiple Response"},
        {value:"numerical",name:"Integer Type"},
        {value:"numberType",name:"Number Type"},
        {value:"decimalType",name:"Decimal Type"},
        {value:"singleDigit",name:"Single Digit"},

        {value:"trueFalse",name:"True False"},
        {value:"fillInTheBlanks",name:"Fill in the Blanks"},



    ]

   
    selectizeInstanceQuestionType = _$(filterQuestionType).selectize({
            preload: true,
            create: false,
            maxItems:1,
            valueField: "value",
            labelField: "name",
            searchField: "name",
            placeholder: 'Select Type',
            // plugins: ["remove_button"],
            options: questionTypesList,
            items : selectedValue ? [selectedValue] :[]

        });

        selectizeInstanceQuestionType[0].selectize.setValue(selectedValue)

    // _$(filterQuestionType).selectize({ 
    //         maxItems: 1 ,
    //         onChange: function (value){
              
    //         }
    //     });
}


const deleteFolderClickHandler = (id) => {
    
    pConfirm('Warning!', 'This action will delete the folder and all folders along with all questions inside it, which cannot be recover', function   (){
        proccedToDeleteFolder(id)
    })
}

const proccedToDeleteFolder =   async (id) => {
    let response = await api_handleApiCalls('questions.deleteFolderWithQuestions', {"folderId": id})
    if(response.status) {
        pSuccess('Success', response.data)
        onRefresh()
    } else {
        pError('Error', response.errMsg)
    }
}

function applyFilter(){
        // qTypeSearch = questionType
        questionType = questionType ? questionType : _$(filterQuestionType).val()

    if(questionType != "") getQuestionListByQuestionId(questionId, questionTags, questionType)
    
}

function clearFilter(){
    selectizeInstanceQuestionType[0].selectize.clear()
    questionType = ''
    if(questionId || questionTags) isSearch = true
    else {isSearch=false
        navigate('/dashboard/questions/questionBank')
    }
   
}
function onRefresh(){
    loadChild()
}


</script>



<div class="content-body" style="background-color: #f8f9fc;">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 " style="    margin: -25px -25px 0 -25px;
    padding: 25px;
    background-color: white;">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/questions/questionBank">Questions</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Question Bank
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                Question Bank
            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">Your personal question bank</p>
        </div>
        <div class="d-block">
            <div class="d-sm-flex justify-content-end mg-b-10">
                {#if $_selectedQuestionsIds.size > 0}
                    <a href="/dashboard/questions/printQuestions" use:link disabled={$_selectedQuestionsIds.size == 0} class="nav-link mg-l-5 text-primary animated fadeIn">
                        View All ({$_selectedQuestionsIds.size}) Selected Questions <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
                    </a>
                {/if}
                <button id="btn-print-selected-q" href="#" disabled={$_selectedQuestionsIds.size == 0} class="btn btn-sm pd-x-15 btn-secondary btn-uppercase mg-l-5 mg-r-5"
                    on:click={onPrintClick}>
                    <i data-feather="printer" class="wd-10 mg-r-5"></i> Print {$_selectedQuestionsIds.size} Questions
                </button>
                <a on:click="{() => { moveSelectedQuestionsClickHandler($_selectedQuestionsIds)} }" href="#" class="btn btn-sm pd-x-15  mg-r-5 btn-secondary btn-uppercase mg-l-5">
                    <i data-feather="move"  class="wd-10 mg-r-5"></i>Move {$_selectedQuestionsIds.size} Question{$_selectedQuestionsIds.size>1 ? "s" : ""}
                </a>
                <button disabled={$_selectedQuestionsIds.size == 0} class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-outline-light text-dark"
                    on:click={clearAllSelectedQuestions}>
                    Clear Selection
                </button>
            </div>
            {#if parentId}
            <div class="row mg-r-4 d-flex justify-content-end mg-b-10" >
                
                <div class="divider-text divider-vertical" style="background-color: #cfcfcf;"></div>
                {#if MODULES.hasAccess(MODULES.ADD_QUESTIONS_QUESTION_BANK)}
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click="{() => _$(modalCmpNQ).modal('show')}">
                    <i data-feather="plus" class="wd-10 mg-r-5"></i>
                    New Question
                </button>
                {/if}
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={openAddFolderModal}>
                    <i data-feather="plus" class="wd-10 mg-r-5"></i>
                    New Folder
                </button>
            </div>
            <div class="d-flex justify-content-end" >

                <div class="input-group mg-r-3 mg-l-4" style="width:30%" >
                    <div class="input-group-prepend ">
                        <span class="input-group-text">#</span>
                    </div>
                    <input type="number" class="form-control " id="qId" placeholder="QuestionId"  bind:value={questionId} on:input={(e)=>{getQuestionListByQuestionId(e.target.value,questionTags, filter)}}>
                </div>
                <div class="input-group mg-r-3" style="width:30%" >
                    
                    <div class="input-group-prepend mg-l-4">
                        <span class="input-group-text">
                            <i data-feather="tag" style="width: 15px;height: 17px;"></i>
                        </span>
                    </div>
                    <input class="info form-control chips-tag has-prepend-icon  searchByTag_input" type="text" data-role="tagsinput"
                        name="chips-tag" placeholder="Search tags..." bind:value={questionTags} on:input={(e)=>{getQuestionListByQuestionId(questionId,e.target.value,filter)}}>
                </div>
                
                <form class="search-form mg-r-5" on:submit|preventDefault={async (event)=>{
                    await loadChild(0, event)} 
                  }
                    >
                  

                    <input type="search" class="form-control  mg-l-4" placeholder="Folder name " bind:value={search.value} 
                    on:input={async (event)=> {await loadChild(0, event)}}
                    />
                    <button class="btn" type="submit"><i data-feather="search" /></button>
                </form>
               
               
            </div>
            <div class="d-flex justify-content-end" >
               
            </div>
            {/if}

            {#if !parentId}
            <div class="row mg-r-4 mg-b-6 d-flex justify-content-end" >
                  <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={openAddFolderModal}>
                    <i data-feather="plus" class="wd-10 mg-r-5"></i>
                    New Folder
                </button>
                <button on:click={showHideFilterModal} class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"> <i data-feather="filter" class="mg-r-5"></i> Filters</button>
            </div>
            <div class="d-flex justify-content-end" >
                <div class="input-group mg-r-3 mg-l-4" style="width:30%" >
                    <div class="input-group-prepend ">
                        <span class="input-group-text">#</span>
                    </div>
                    <input type="number" class="form-control " id="qId" placeholder="QuestionId"  bind:value={questionId} on:input={(e)=>{getQuestionListByQuestionId(e.target.value,questionTags)}}>
                </div>
                <div class="input-group mg-r-3" style="width:30%" >
                    
                    <div class="input-group-prepend mg-l-4">
                        <span class="input-group-text">
                            <i data-feather="tag" style="width: 15px;height: 17px;"></i>
                        </span>
                    </div>
                    <input class="info form-control chips-tag has-prepend-icon  searchByTag_input" type="text" data-role="tagsinput"
                        name="chips-tag" placeholder="Search tags..." bind:value={questionTags} on:input={(e)=>{getQuestionListByQuestionId(questionId,e.target.value)}}>
                </div>
                <form class="search-form mg-r-5" on:submit|preventDefault={async (event)=>{
                    await loadChild(0, event)} 
                  }
                    >
                  

                    <input type="search" class="form-control  mg-l-4" placeholder="Folder name " bind:value={search.value} 
                    on:input={async (event)=> {await loadChild(0, event)}}
                    />
                    <button class="btn" type="submit"><i data-feather="search" /></button>
                </form>
            </div>
              {/if}
        </div>
    </div>
    <hr class="full-width">
    {#if isSearch}
        <QuestionList questionId={questionId} on:refresh={onRefresh} questionTags={questionTags} questionType={questionType}/>
    {:else}
    <div class="mg-b-20 ">
        <div class="row">
            <div class="col">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                        <li class="breadcrumb-item"><a href="/dashboard/questions/questionBank/" use:link>Folders </a></li>
                        {#each breadCrumb as item (item._id)}
                        <li class="breadcrumb-item"><a href={'/dashboard/questions/questionBank/'+item._id}
                                use:link>{item.name}</a></li>
                        {/each}
                        <!-- <li class="breadcrumb-item active" aria-current="page">Data</li> -->
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row row-sm">
            <div class="col">
                {#if noList && questionCount == 0}
                <div
                    style="border:1px solid #ddd; padding:60px 30px; display:flex;align-items:center;justify-content:center;flex-direction:column;margin: 20px 0;">
                    <h4 id="section4" class="mg-b-10">Create your first Folder</h4>
                    <p class="mg-b-30 tx-center">
                        You can categories all you questions folder-wise<br />
                        Start creating your folder here
                    </p>
                    {#if MODULES.hasAccess(MODULES.ADD_FOLDER_QUESTION_BANK) || MODULES.hasAccess(MODULES.ADD_FOLDER_QUESTION_BANK)}
                    <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase open-create-section-modal"
                        on:click={openAddFolderModal}><i data-feather="plus" class="wd-10 mg-r-5" />
                        Add New folder
                    </button>
                    {/if}
                    {#if parentId}
                       
                        {#if MODULES.hasAccess(MODULES.ADD_QUESTIONS_QUESTION_BANK)}
                        <div class="divider-text">OR</div>
                        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase open-create-section-modal"
                            on:click="{() => _$(modalCmpNQ).modal('show')}"><i data-feather="plus" class="wd-10 mg-r-5" />
                            Add New Question
                        </button>
                        {/if}
                    {/if}
                </div>
                {/if}
                <div class="filemanager">
                    {#each folderList as item (item._id)}

                    <div class="qb-card" >
                        <div class="qb-list data animated" on:click={()=>
                            navigate("/dashboard/questions/questionBank/"+item._id)}>
                            <div class="qb-icon">
                                <span class="icon folder"></span>
                            </div>
                            <div class="qb-text">
                                {item.name}
                            </div>
                        </div>
                        {#if MODULES.hasAccess(MODULES.RENAME_QUESTION_BANK) || MODULES.hasAccess(MODULES.DELETE_QUESTION_BANK)}
                        <div class="dropdown-file">
                            <a href="#" class="dropdown-link" data-toggle="dropdown"><i data-feather="more-vertical"></i></a>
                            <div class="dropdown-menu dropdown-menu-right">
                                {#if MODULES.hasAccess(MODULES.RENAME_QUESTION_BANK)}
                                    <a on:click="{() => { btnRenameFolder(item._id)}}" href="#" class="dropdown-item rename"><i data-feather="edit"></i>Rename</a>
                                {/if}
                                {#if MODULES.hasAccess(MODULES.DELETE_QUESTION_BANK)}
                                    <a on:click="{() => { deleteFolderClickHandler(item._id)} }" href="#" class="dropdown-item delete"><i data-feather="trash"></i>Delete Folder</a>
                                {/if}
                                {#if MODULES.hasAccess(MODULES.DELETE_QUESTION_BANK)}
                                    <a on:click="{() => { moveFolderClickHandler(item._id)} }" href="#" class="dropdown-item move-folder">
                                        <i data-feather="move"></i>Move Folder
                                    </a>
                                {/if}
                            </div>
                          </div>
                          {/if}
                    </div>
                    <!-- <ul class="data animated">
                        <li class="folders">
                            <a  class="folders">
                                
                                <span class="name"></span>
                                <span class="details">Empty</span>
                            </a>
                        </li>
                    </ul> -->
                    {/each}
                </div>
                {#if parentId && questionCount > 0}
                    <hr class="full-width">

                    <div class="row">
                        <div class="col">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                                    <li class="breadcrumb-item"><a href={"/dashboard/questions/list/"+parentId} use:link>Question :  </a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div class="filemanager">
                        <div class="qb-list data animated" style="position: relative;" >
                            <div class="qb-icon" style="padding: 15px 10px;">
                                <img src="/assets/clipq.svg" style="height: 65px;" alt="">
                            </div>
                            <div class="d-flex">
                                <div class="qb-text"  on:click={()=>
                                    navigate("/dashboard/questions/list/"+parentId)}>
                                    {questionCount} Question{questionCount>1 ? "s" : ""} Found 

                                    <svg xmlns="http://www.w3.org/2000/svg"
                                    style="margin-left: 20px;"
                                    width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                                        <polyline points="9 18 15 12 9 6"></polyline>
                                    </svg>
                                </div>


                            </div>
                            {#if MODULES.hasAccess(MODULES.RENAME_QUESTION_BANK) || MODULES.hasAccess(MODULES.DELETE_QUESTION_BANK)}
                            <div class="dropdown-file">
                                <a href="#" class="dropdown-link" data-toggle="dropdown"><i data-feather="more-vertical"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    {#if MODULES.hasAccess(MODULES.DELETE_QUESTION_BANK)}
                                        <a on:click="{() => { moveQuestionsClickHandler(parentId)} }" href="#" class="dropdown-item move-folder">
                                            <i data-feather="move"></i>Move {questionCount} Question{questionCount>1 ? "s" : ""}
                                        </a>
                                    {/if}
                                </div>
                              </div>
                              {/if}
                        </div>
                    </div>
                {/if}
                
            </div>
        </div>
    </div>
    {/if}
</div>



<div class="modal effect-scale" bind:this={modalCmp} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header pd-b-0">
                <div class="media text-align-center pd-12">
                    <span class="tx-color-04"><i data-feather="layers" class="wd-50 ht-50" /></span>
                <div class="media-body mg-sm-l-20">
                    <h4 class="tx-18 tx-sm-20 mg-b-2">New folder </h4>
                    <p class="tx-13 tx-color-03 mg-b-0">Add new folder</p>
                </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span><br />
                  </button>
            </div>
            <div class="modal-body">
                
                <div class="row no-gutters pd-12">
                    <div class="col-12 bg-white rounded-right">
                            
                            <div class="tx-14 tx-md-16 tx-color-02">
                                <div class="form-group">
                                    <label class="d-block">Folder Name <span class="required-field">*</span></label>
                                    <input type="text" on:keypress={onKeyPress} bind:value={newFolderName} on:blur={()=>
                                    clearError('newFolderName')}
                                    class="form-control"
                                    class:parsley-error={errorObj.newFolderName}
                                    placeholder="Enter folder name." />
                                    {#if errorObj.newFolderName}
                                    <div class="parsley-errors-list">
                                        {errorObj.newFolderName}
                                    </div>
                                    {/if}
                                </div>
                            </div>
                            <button href="" on:click={createNewFolder} class="btn btn-primary btn-block btn-uppercase">
                                Add
                            </button>
                    </div>
                    <!-- col -->
                </div>
                <!-- row -->
            </div>
            <!-- modal-body -->
        </div>
        <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
</div>
<!-- modal -->

<div class="modal effect-scale" bind:this={modalUpdateFolder} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bd-0">
            <div class="modal-header pd-b-0">
                <div class="media text-align-center pd-12">
                    <span class="tx-color-04"><i data-feather="layers" class="wd-50 ht-50" /></span>
                <div class="media-body mg-sm-l-20">
                    <h4 class="tx-18 tx-sm-20 mg-b-2">Rename Folder</h4>
                    <p class="tx-13 tx-color-03 mg-b-0">Rename old folder</p>
                </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span><br />
                  </button>
            </div>

            <div class="modal-body pd-0">
               
                <div class="row no-gutters">
                    <div class="col-12 bg-white rounded-right">
                        <div class="ht-100p d-flex flex-column justify-content-center pd-20 pd-sm-30 pd-md-40">
                            
                            <div class="tx-14 tx-md-16 tx-color-02">
                                <div class="form-group">
                                    <label class="d-block">Folder Name</label>
                                    <input type="text" bind:value={updateFolderObj.name} on:blur={()=>
                                    clearError('updateFolderName')}
                                    class="form-control"
                                    class:parsley-error={errorObj.updateFolderName}
                                    placeholder="Enter Folder name..." />
                                    {#if errorObj.updateFolderName}
                                    <div class="parsley-errors-list">
                                        {errorObj.updateFolderName}
                                    </div>
                                    {/if}
                                </div>
                            </div>
                            <button href="" on:click={btnProcceedRenameFolder} class="btn btn-primary btn-block btn-uppercase">
                                Update
                            </button>
                        </div>
                    </div>
                    <!-- col -->
                </div>
                <!-- row -->
            </div>
            <!-- modal-body -->
        </div>
        <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
</div>

<div class="modal effect-scale" bind:this={modalCmpNQ} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mx-wd-sm-650" role="document">
        <div class="modal-content bd-0 bg-transparent">
            <div class="modal-body pd-0">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>

                <div class="row no-gutters">

                    <div class="col-12 bg-white rounded-right">
                        <div class="ht-100p d-flex flex-column justify-content-center pd-20 pd-sm-30 pd-md-40">
                            <div class="d-flex">
                                <span class="tx-color-04"><i data-feather="server"
                                        class="wd-40 ht-40 stroke-wd-3 mg-b-20"></i></span>
                                        <div class="media-body mg-sm-l-20">
                                            <h4 class="tx-18 tx-sm-20 mg-b-2">Create question</h4>
                                            <p class="tx-13 tx-color-03 mg-b-0">Create a new question of the type you want</p>
                                        </div>
                                <!-- <h3 class="tx-16 tx-sm-20 tx-md-24 mg-b-15 mg-md-b-20 mg-l-15">Create new question</h3> -->
                            </div>
                            <div class="tx-14 tx-md-16 tx-color-02">
                                <div class="form-group" style="    margin-bottom: 10px;">
                                    <label class="d-block m-0">What type of question would you like to create ?</label>
                                </div>
                            </div>

                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio1" value="multipleChoice" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio1">
                                        Multiple Choice
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio2" value="multipleResponse" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio2">
                                        Multiple Response
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio3" value="numerical" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio3">
                                        Number Type
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio4" value="trueFalse" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio4">
                                        True False
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio5" value="fillInTheBlanks" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio5">
                                        Fill in the Blanks
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio6" value="matchTheFollowing" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio6">
                                        Match the Following
                                    </label>
                                </div>
                            </div>
                            <div
                                style="flex:1; border:1px solid #cccccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio7" value="matchMatrix" bind:group={qType}
                                        name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio7">
                                        Match Matrix
                                    </label>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-secondary tx-12" data-dismiss="modal">
                                    <i data-feather="x" class="wd-10 mg-r-5"></i>
                                    CLOSE
                                </button>
                                <button href="" disabled={qType ? false : true} on:click={handleClickProceed}
                                    class="btn btn-primary btn-uppercase">
                                    <i data-feather="arrow-right" class="wd-10 mg-r-5"></i>
                                    <ButtonLoader isLoading={isLoading} btnText="Proceed" />
                                </button>
                            </div>
                        </div><!-- col -->
                    </div><!-- row -->
                </div><!-- modal-body -->
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div><!-- modal -->
</div>


<!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
aria-labelledby="exampleModalLabel6" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
            <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </a>
            <div class="media align-items-center">
                <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter"
                        class="wd-60 ht-60"></i></span>
                <div class="media-body mg-sm-l-20">
                    <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                    <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
                </div>
            </div><!-- media -->
        </div><!-- modal-header -->

        <hr class="full-width">
        <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
          <!-- <div class="form-group">
            <label class="d-block">Folder name:</label>
            <input type="text" class="form-control follow-up-date" placeholder="Enter folder name"/>
        </div> -->
        <div class="form-group">
          <label class="d-block">Question type:</label>
          <div class="col-sm">
            <select id="qType" name="qTYpe" type="text" class="slectizedd info form-control" bind:this={filterQuestionType}>
               
            </select>
        </div>
        </div>

            <div class="d-md-flex justify-content-end mg-t-25">
                <button disabled={isLoading} 
                    class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal" on:click="{clearFilter}">CLEAR
                    <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                <button disabled={isLoading} 
                    class="btn btn-sm pd-x-15 btn-primary mg-l-5" data-dismiss="modal" on:click={applyFilter}>
                    <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                    <i data-feather="filter" class="wd-10 mg-l-5"></i>
                </button>
            </div>
        </div>

    </div>
</div>
</div>
<!-- Modal -->


{#if showModal}
    <FolderModal on:close={onCloseModal} />
{/if}


<div class="modal effect-scale" bind:this={elemModalMoveFolder} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span><br />
                  </button>

                  <div class="pd-5">
                      <p>Moving to following location</p>
                      <div class="d-flex">
                          <!-- <button on:click={()=>{showModalSelectFolder(true)}} class="btn btn-xs btn-primary mr-2">Change location</button> -->
                          <button on:click={()=>{showModalSelectFolder(true)}} class="btn btn-xs btn-light">
                              {#if selectedLinkPath.length > 0}
                                  <nav aria-label="breadcrumb">
                                      <ol class="breadcrumb breadcrumb-style3 mg-b-0">
                                          {#each selectedLinkPath as item}
                                              <li class="breadcrumb-item active">{item.name}</li>
                                          {/each}
                                      </ol>
                                  </nav>
                                {:else}
                                Select Folder Location
                              {/if}
                          </button>
                      </div>
                  </div>


                <div class="d-flex justify-content-end mt-4">
                    <button disabled={moveIsLoading} 
                    class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal">CANCEL
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button on:click={moveProceedButtonClick} disabled={moveIsLoading} 
                        class="btn btn-sm pd-x-15 btn-primary mg-l-5" data-dismiss="modal">
                        <ButtonLoader isLoading={moveIsLoading} btnText="Proceed" />
                        <i data-feather="check" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>
            <!-- modal-body -->
        </div>
        <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
</div>
<!-- modal -->
