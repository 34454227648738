<script>
    import { createEventDispatcher, onMount, afterUpdate, tick, beforeUpdate } from 'svelte';
    import ButtonLoader from "../../utils/button_loader.svelte"
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import print from "print-js";
    export let paymentId;
    export let paymentList = []
    export let studentId;
    export let batchId;
    export let totalPaidAmount;
    export let balanceAmount;
    export let showReceipt;
    export let studentBatchInfo
    export let localUserData
    export let onModalClose;
    let receiptModal;

    let StudentsData;
    let PaymentData;
    let BatchesData;
    let installmentAmount;
    let installmentDate;
    let name = '';
    let batchName = '';
    let className = ''
    let mobileNo = '';
    let receiptNo = '';
    let paymentDate = '';
    let enrollmentNo = '';
    let paymentMode = '';
    let paidAmt = '';
    let payableAmt = '';
    let actualBatchFee = '';
    let InstallmentDetails;
    let Address = "";
    let todaysDate
    let currentYear
    let newYear
    let orgDetails = {
        name: " - ",
        mobileNo: " - ",
        logo: "",
        headOffice: " - ",
        address: "",
        website: " - ",
        GSTN: " - ",
        orgCode: " - "
    }
    let total = 0
    let printReceipt = () => {
        _$('.page').css({ 'transform': 'scale(1)' });
        _$('#print-container').css({ 'padding-top': '0' });
        print({
            printable: 'print-container',
            type: 'html',
            header: false,
            scanStyles: false,
            scanStyles: false,
            style: `html, body {
                    padding : 0;
                    margin : 0;
                }
                 * {
                        box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                `,
            onPrintDialogClose: function () {
                _$('.page').css({ 'transform': 'scale(' + (window.innerHeight - 150) / (1122 / 2) + ')' });
                _$('#print-container').css({ 'padding-top': '15px' });
            }
        })
    }
    afterUpdate(() => {
        // ...the DOM is now in sync with the data
        function calculateTotal() {

        }
    });
    function setOrganizationData() {
        orgDetails.name = localUserData.orgName
        orgDetails.mobileNo = localUserData.mobileNo
        orgDetails.orgCode = localUserData.orgCode
        if (orgDetails.orgCode == "DFCDFC") {
            orgDetails.headOffice = "Opp. Ludhiana Cycle, Roxy Corner, Paithan Gate Road , Aurangabad."
            orgDetails.website = "www.dfc.org.in"
            orgDetails.mobileNo = "9225318727"
            orgDetails.GSTN = "27ALEPK4847Q1ZE"
            orgDetails.logo = "https://d2korn9uxr5hfx.cloudfront.net/assets/DFC_logo.avif"

        }
        else if (orgDetails.orgCode == "pforg") {
            orgDetails.headOffice = "Opp. Ludhiana Cycle, Roxy Corner, Paithan Gate Road , Aurangabad."
            orgDetails.website = "www.dfc.org.in"
            orgDetails.logo = "https://d2korn9uxr5hfx.cloudfront.net/assets/DFC_logo.avif"

        }
        else {
            orgDetails.headOffice = "Opp. Ludhiana Cycle, Roxy Corner, Paithan Gate Road , Aurangabad."
            orgDetails.website = "www.dfc.org.in"
            orgDetails.logo = "https://d2korn9uxr5hfx.cloudfront.net/assets/DFC_logo.avif"
            orgDetails.GSTN = '28ALEPS4847Q2AE'
        }
    }
    onMount(async () => {
        feather.replace();
        if (paymentList.length) {
            for (let idx = 0; idx < paymentList.length; idx++) {
                total += parseInt(paymentList[idx].installmentAmount);

            }
        }
        setOrganizationData()
        StudentsData = await api_getStudentById(studentId);
        BatchesData = await api_getBatchById(batchId);
        InstallmentDetails = await api_getNextInstallmentDataByBatchId(batchId, studentId);
        Address = await api_fetchInstituteBranch(studentBatchInfo.batch.branch)

        name = StudentsData.name;
        installmentAmount = InstallmentDetails ? InstallmentDetails.installmentAmount ? convertToINR(InstallmentDetails.installmentAmount) : '-' : "-";
        mobileNo = StudentsData.mobileNo;
        actualBatchFee = BatchesData.actualBatchFee;
        payableAmt = convertToINR(BatchesData.amount);
        enrollmentNo = StudentsData.enrollmentNo;
        todaysDate = moment(new Date()).format("DD-MM-YYYY")
        currentYear = new Date().getFullYear()
        newYear = new Date().getFullYear() + 1

        receiptNo = studentBatchInfo.seqId;
        batchName = BatchesData.name;
        if (studentBatchInfo.className) className = studentBatchInfo.className
        else className = ''

        await tick();
        var scroll1
        _$(receiptModal).modal("show");
        _$(receiptModal).on('shown.bs.modal', function () {
            scroll1 = new PerfectScrollbar('#scroll1', {
                suppressScrollX: true
            });
        });
        _$(receiptModal).on('hidden.bs.modal', function () {
            onModalClose()
        });
    })


    let convertToINR = function (input) {
        let amount = Number(input);
        return amount.toLocaleString('en-IN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'INR'
        });
    }
    function convertDateObjToStr(date) {
        date = new Date(date);
        var month = "" + (date.getMonth() + 1);
        var day = "" + date.getDate();
        var year = date.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        var dateString = [day, month, year].join("-");
        return dateString;
    }
    async function api_getPaymentById(paymentId) {

        let action = "payment.getPaymentById";
        var response = await api_handleApiCalls(action, { _id: paymentId });
        return response.data

    }

    async function api_getStudentById(studentId) {

        let action = "students.getStudentById";
        var response = await api_handleApiCalls(action, { _id: studentId });
        return response.data

    }

    async function api_getBatchById(batchId) {

        let action = "batch.getBatchById";
        var response = await api_handleApiCalls(action, { _id: batchId });
        return response.data

    }


    // paymentDate: "2021-03-16T18:30:00.000Z"


    async function api_getNextInstallmentDataByBatchId(batchId, studentId) {

        let action = "payment.getNextInstallmentDataByBatchId";
        var response = await api_handleApiCalls(action, { batchId: batchId, studentId: studentId });
        return response.data;

    }
    async function api_fetchInstituteBranch(branchId) {
        try {
            let action = "instituteBranch.fetchInstituteBranch";
            let response = await api_handleApiCalls(action, { branchId: branchId });
            if (!response || !response.data || !response.data.address) {
                console.error("Failed to fetch institute branch details");
                return {}; // Return an empty object if data is not available
            }
            return response.data.address;
        } catch (error) {
            console.error("Error fetching institute branch details:", error);
            return {}; // Return an empty object if there's an error
        }
    }
    let getPaymentType = function (value) {
        let paymentTypeOptions = {
            cash: "Cash",
            cheque: "Cheque",
            dd: "DD",
            onlinePay: "Online Payment",
            upi: "UPI",
            card: "Card Payment",
        }
        return paymentTypeOptions[value];

    }
    let getPaymentDesc = function (paymentObj) {
        let message = "Unpaid";
        if (paymentObj.isRefunded) {
            message = "Refund";
        } else {
            if (paymentObj.paidAmount && paymentObj.paidAmount == paymentObj.installmentAmount) {
                message = "Paid";
            }
            if (paymentObj.paidAmount && paymentObj.paidAmount != paymentObj.installmentAmount) {
                message = "Partial Paid";
            }
        }
        return message;




    }

    function convertNumberToWords(amount) {
        var words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';

        amount = amount.toString();
        var atemp = amount.split(".");

        var number = atemp[0].split(",").join("");

        var n_length = number.length;

        var words_string = "";
        if (n_length <= 9) {
            var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);

            var received_n_array = new Array();

            for (var i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);


            }
            for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
                n_array[i] = received_n_array[j];

            }
            for (var i = 0, j = 1; i < 9; i++, j++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            let value = "";
            for (var i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Crores ";
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Lakhs ";
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    words_string += "Hundred and ";
                } else if (i == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
        }
        return words_string;
    }
</script>

<div class="modal fade" bind:this={receiptModal} tabindex="-1" role="dialog" aria-labelledby="receipt-modal-label"
    style="display: none ;height: 100%; overflow: auto;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header">
                <h6 class="modal-title" id="receipt-modal-label">Receipt</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" style="display: flex;height: 100%;flex-direction: column; overflow: auto;">
                <div id="print-container" style="    
                                flex: 1;
                                overflow: auto;
                                display: flex;
                                overflow-y: auto;
                                overflow-x: hidden;
                                justify-content: center;
                                background:#ddd;
                            ">

                    <style>
                        * {
                            box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            font-family: "Tahoma";
                        }

                        .print-receipt-tbl {
                            border-spacing: 0;
                            /* border-color: gray; */
                            /* border : 1px solid black; */
                        }

                        /* .table {
                        margin-top: 80px;
                        border: 1px solid black;
                    } */

                        .th,
                        .td {
                            border: 1px solid black;
                        }

                        .print-receipt-tbl td {
                            padding: 2px 5px;
                            /* border : 1px solid black; */
                        }

                        .page {
                            width: 22cm;
                            min-height: 200mm;
                            padding: 0;
                            margin: 0 10pt;
                            border: 1px #D3D3D3 solid;
                            border-radius: 5px;
                            background: white;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                            background-size: contain;
                            background-repeat: no-repeat;
                            transform-origin: top center;
                            display: flex;
                            flex-direction: column;
                            overflow: hidden;
                        }

                        .p-body {
                            flex: 1;
                            display: flex;
                            background-repeat: no-repeat;
                            background-position: center;
                            width: 20cm;
                        }

                        /* .p-body-center {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                        } */

                        .p-body-divName {
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                        }

                        .p-body-divTable {
                            flex: 2;
                            display: flex;
                            flex-direction: row;
                        }

                        .p-header,
                        .p-footer {
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 21cm;
                        }

                        .p-body-left,
                        .p-body-right {
                            background-repeat: no-repeat;
                            background-size: contain;
                        }

                        .vertical-text {
                            display: flex;
                            flex-direction: column;
                            padding: 0 10px;
                        }

                        .vertical-text ul {
                            margin: 0;
                            padding: 0 10px;
                            list-style-type: disc;
                        }

                        .vertical-text li {
                            margin: 0;
                            padding: 5px 0;
                        }


                        @page {
                            size: A4;
                            margin: 0;
                        }

                        @media print {
                            .page {
                                margin: 0;
                                border-radius: initial;
                                width: initial;
                                min-height: initial;
                                page-break-after: always;
                            }
                        }
                    </style>

                    <div class="book">
                        <div class="page">
                            <table style="border: 1px solid black; border-collapse: collapse; margin: 10px;"
                                class="h-10">
                                <tbody>
                                    <tr>
                                        <td rowspan="2" style="width:20%; text-align: center; border: 1px solid black;">
                                            {#if orgDetails.logo !=""}
                                            <img src={orgDetails.logo} style="height:100px; width:100px" />
                                            {/if}
                                        </td>

                                        <td style="border: 1px solid black;padding:10px">
                                            <label
                                                style="text-align: center;   font-size: 24px;  padding-bottom: -10px;">
                                                <b>{orgDetails.name.toUpperCase() }</b>
                                            </label>
                                            <br>

                                            <label>HeadOffice :{orgDetails.headOffice } </label>
                                            <br>
                                            <label>Website : {orgDetails.website } </label>
                                            <br>
                                            <label>Address : {#if Address && Address.area && Address.city &&
                                                Address.state && Address.pincode}
                                                {Address.area}, {Address.city}, {Address.state}, {Address.pincode}
                                                {:else}
                                                -
                                                {/if}</label>
                                        </td>


                                    </tr>
                                    <tr>
                                        <td style=" text-align: center; border: 1px solid black; padding:10px">
                                            <label style="float: left;">Helpline : {orgDetails.mobileNo }</label>

                                            <label style="float: right; ">GSTN :{orgDetails.GSTN } </label>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                            <div class="p-header">
                                <h4 style="text-align: center; margin-top: 10px;">
                                    Receipt
                                </h4>
                            </div>

                            <div style="width: 100%; display: inline;">


                                <label class="" style="float: left; margin-left: 10px;"><b>Receipt No.:</b>
                                    {receiptNo}</label>
                                <label style="float: right;margin-right: 10px; "><b>Date:</b> {todaysDate}</label>

                            </div>
                            <div class="p-body" style="margin:10px; padding-right: 10px ;width: 100%; ">
                                <p>Received with thanks from <u> {name} </u> Batch: <u> {batchName} </u> Class:
                                    <u>{className}</u> the Sum of total
                                    fees (in words) Rupees. <u> {totalPaidAmount ?
                                        convertNumberToWords(totalPaidAmount): " "} </u> only. <br>
                                    <!-- <b>Details of Cheque: </b> Bank:__________________ -->

                                </p>


                            </div>

                            <table style=" margin: 10px;">
                                <td style=" text-align: left"><b>Total Agreed Fees :</b>
                                    {convertToINR(studentBatchInfo.agreedFees) ?
                                    convertToINR(studentBatchInfo.agreedFees) : "-"}</td>
                                <td style=" text-align: center"><b>Total Paid Fees :</b> {convertToINR(totalPaidAmount)
                                    ?
                                    convertToINR(totalPaidAmount) :
                                    "-"}</td>
                                <td style=" text-align: right"><b>Total Balance Fees :</b> {convertToINR(balanceAmount)
                                    ?
                                    convertToINR(balanceAmount) :convertToINR(0)}
                                </td>
                            </table>

                            <table style="border: 1px solid black; border-collapse: collapse; margin: 10px;">
                                <thead>
                                    <tr>
                                        <th style="width: 5%; text-align: center; border: 1px solid black;">Sr. No.</th>
                                        <th style="text-align: center; border: 1px solid black;">Date</th>
                                        <th style="text-align: center; border: 1px solid black;">Type</th>

                                        <th style="text-align: center; border: 1px solid black;">Cheque No</th>
                                        <th style="text-align: center; border: 1px solid black;">Status</th>

                                        <th style="width: 20%; text-align: center; border: 1px solid black;">Amount(Rs.)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {#each paymentList as aPayment, index }
                                    <tr>
                                        <td style="border: 1px solid black; text-align: center">{index + 1}</td>
                                        <td style="border: 1px solid black; padding-left:10px; text-align: center">
                                            {aPayment.paymentDate
                                            ? moment(aPayment.paymentDate).format("DD-MM-YYYY")
                                            : "-"}
                                        </td>

                                        <td
                                            style="border: 1px solid black; padding-left:10px; width: 150px; overflow-wrap: anywhere; text-align: center">
                                            {aPayment.paymentType === "cheque"
                                            ? `${getPaymentType(aPayment.paymentType)} from ${aPayment.bankName} Bank`
                                            : getPaymentType(aPayment.paymentType)}
                                        </td>
                                        <td style="border: 1px solid black; padding-left:10px; text-align: center">
                                            {aPayment.paymentType === "cheque" ? aPayment.chequeNo : "-"}
                                        </td>
                                        <td style="border: 1px solid black; padding-left:10px; text-align: center">
                                            {getPaymentDesc(aPayment)}
                                        </td>

                                        <td style="border: 1px solid black; text-align: center;">
                                            {aPayment.isRefunded
                                            ? convertToINR(aPayment.paidAmount)
                                            : aPayment.installmentAmount
                                            ? convertToINR(aPayment.installmentAmount)
                                            : convertToINR(0)
                                            }/-
                                        </td>
                                    </tr>

                                    {/each}


                                </tbody>
                                <tfoot>
                                    <tr style="padding-top: 10px; margin-top: 10px;">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style="text-align: end; margin-right: 10px; padding-right: 20px;">Total</td>
                                        <td style='text-align: center'>{totalPaidAmount?
                                            convertToINR(totalPaidAmount):convertToINR(0)}/- </td>
                                    </tr>
                                </tfoot>
                            </table>
                            <div class="p-body" style="margin:10px">
                                <p>Towards the Part / Full / Advance Tution fees for the course to be conducted for the
                                    year {currentYear + " - " + newYear}</p>
                            </div>
                            <table style=" margin: 10px;">
                                <tr>
                                    <td style=" text-align: left">-------------------------</td>
                                    <td style=" text-align: right">-------------------------</td>
                                </tr>
                                <tr>
                                    <td style=" text-align: left"><b>Student's Signature</b></td>
                                    <td style=" text-align: right"><b>Faculty's Signature</b></td>
                                </tr>
                            </table>
                            <div class="vertical-text" style="margin:10px; padding-top:30px">
                                <ul>
                                    <li>फीस का चेक बँक मे जमा करने से पहले हम आपको रिमाईंडर कॉल नही करते, इसलिए आपके
                                        सहुलियत की हर महिने की कोई भी तारीख चेक पर
                                        डाल कर दे
                                    </li>
                                    <li>किसी भी कारणवश यदी चेक रिटर्न (बाऊँस ) होता है तो 200/- रुपये चेक रिटर्न चार्जेस
                                        जो बँक का जुर्माना है वो आपको देना
                                        पडेगा

                                    </li>
                                    <li>किसी भी कारणवश यदी चेक रुकाना हो तो उस चेक की तारीख से दो दिन पहले हेल्पलाईन
                                        नंबर 9225318727 पर केवल SMS कर दे
                                        (व्हाट्सअप ना करे) बिना आपके SMS के चेक रुक नही सकता
                                    </li>
                                    <li>रिटर्न (बाऊँस) चेक या रुकाया हुआ चेक आपको अधिकतम 8 दिनों के भितर क्लिअर करवाना
                                        होगा वरना आपके स्टुडंट का आयडी
                                        ब्लॉक हो सकता 
                                    </li>
                                </ul>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Close</button>
                <button type="button" on:click={printReceipt} class="btn btn-primary tx-13">Print</button>
            </div>
        </div>
    </div>
</div>
