<script>
    import { Router, Link, Route } from "svelte-routing";
    import ViewAttendance from "./viewAttendance.svelte";

</script>

<Router>
    <Route path="">
        <ViewAttendance />
    </Route>
    <Route path=":batch_id" let:params>
        <ViewAttendance batch_id="{params.batch_id}"></ViewAttendance>
    </Route>
</Router>
