<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../../stores";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import STPModal from "../../TelecallerScriptTextEditor/modal.svelte";
  import { pConfirm, pSuccess, pError, pAlert } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js"
  import { API_URL } from "../../../config.js"
  let errorObj = {};
  let emptyState = false;
  let elem_teleCallerLogTable, elem_mobileNoInput, elem_logTypeSelection;
  let teleCallerLogTable;
  let mobileNoCleave;
  let newLogEntryModal;
  let script = "";
  let teleCallerLog = {
    mobileNo: "",
    type: "",
    description: "",
  };

  let filterModalThis;
  let filterName;
  let filterStartDate;
  let isLoading = false;




  function hideFilterModel(params) {
    _$("#filter-modal").modal("hide");
    assignmentObj.topic = "";
  }
  function showFilterModel(params) {
    _$("#filter-modal").modal("show");
  }


  let showHideFilterModal = () => {
    _$('#filter-modal').modal('show');
  }



  let isSaving = false,
    isConverting = false,
    typesDoNotExist = false,
    typesNotFound = false,
    showModal = false;

  let onCloseScriptModal = async function (event) {
    showModal = !showModal;
    // console.log(event.detail, event.detail.sptObject, event.detail.sptObject.content)
    typesDoNotExist = false;
    typesNotFound = false;
    teleCallerLog.type = event.detail.sptObject._id;
    script = event.detail.sptObject.content;
    await tick();

    _$(elem_logTypeSelection)[0].selectize.clearOptions();
    _$(elem_logTypeSelection)[0].selectize.onSearchChange();
    _$(elem_logTypeSelection)[0].selectize.enable();
    _$(elem_logTypeSelection)[0].selectize.setValue(
      [teleCallerLog.type],
      false
    );
    // _$('.modal-backdrop').remove()
  };

  let clearErrors = function (fieldName) {
    delete errorObj[fieldName];
    errorObj = errorObj;
  };

  let openNewLog = function () {
    errorObj = {};
    let teleCallerLog = {
      mobileNo: "",
      type: "",
      description: "",
    };
    mobileNoCleave.setRawValue(teleCallerLog.mobileNo);
    _$(elem_logTypeSelection)[0].selectize.setValue([], false);
    _$(newLogEntryModal).modal("show");
  };


  async function api_fetchTeleCallerScript(data) {

    let action = "teleCallerScript.fetchTeleCallerScript";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_getById(data) {

    let action = "telecallerLogs.getById";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_addLog(data) {

    let action = "telecallerLogs.addLog";
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_archiveById(data) {

    let action = "telecallerLogs.archiveById";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function api_convertToEnquiry(data) {

    let action = "enquiries.create";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  async function convertToEnquiry(_id) {
    if (isSaving || isConverting) return;
    isConverting = true;
    await tick();
    var newErrorObj = {};
    var regexpMobileNumber = new RegExp(`^[6-9]{1,1}[0-9]{9,9}$`);
    if (!teleCallerLog.name && !Boolean(teleCallerLog.name)) {
      newErrorObj.name = "Please enter full name.";
    }
    if (
      !teleCallerLog.mobileNo &&
      !Boolean(teleCallerLog.mobileNo) &&
      !regexpMobileNumber.test(teleCallerLog.mobileNo)
    ) {
      newErrorObj.mobileNo = "Please enter valid mobile number.";
    }

    if (!teleCallerLog.type) {
      newErrorObj.type = "Type Required";
    }

    errorObj = newErrorObj;
    await tick();
    if (Object.keys(errorObj).length > 0) {
      isConverting = false;
      await tick();
      return null;
    }
    console.log("log entry : ", teleCallerLog);
    if (!_id) {
      var logEntryRes = await api_addLog(teleCallerLog);
      if (logEntryRes.status) {
        // create success
        console.log("log id : ", logEntryRes);
        _$(elem_teleCallerLogTable).DataTable().ajax.reload(null, false);
        pSuccess("", "Log added successfully");
      } else {
        pError(
          "",
          logEntryRes.errMsg || logEntryRes.error || "Something went wrong"
        );
      }
    }
    var logEntryRes = await api_convertToEnquiry({
      name: teleCallerLog.name,
      mobileNo: teleCallerLog.mobileNo,
    });
    if (logEntryRes.status) {
      // create success
      _$(newLogEntryModal).modal("hide");
      console.log("log id : ", logEntryRes.data.batchId);
      _$(elem_teleCallerLogTable).DataTable().ajax.reload(null, false);
      pSuccess("", "Enquiry saved successfully");
    } else {
      pError(
        "",
        logEntryRes.errMsg || logEntryRes.error || "Something went wrong"
      );
    }
    isConverting = false;
    await tick();
  }

  async function createLogEntry() {
    if (isSaving || isConverting) return;
    isSaving = true;
    await tick();
    var newErrorObj = {};
    var regexpMobileNumber = new RegExp(`^[6-9]{1,1}[0-9]{9,9}$`);
    if (!teleCallerLog.name && !Boolean(teleCallerLog.name)) {
      newErrorObj.name = "Please enter full name.";
    }
    if (
      !teleCallerLog.mobileNo &&
      !Boolean(teleCallerLog.mobileNo) &&
      !regexpMobileNumber.test(teleCallerLog.mobileNo)
    ) {
      newErrorObj.mobileNo = "Please enter valid mobile number.";
    }

    if (!teleCallerLog.type) {
      newErrorObj.type = "Type Required";
    }

    errorObj = newErrorObj;
    await tick();
    if (Object.keys(errorObj).length > 0) {
      isSaving = false;
      await tick();
      return null;
    }
    console.log("log entry : ", teleCallerLog);
    var logEntryRes = await api_addLog(teleCallerLog);
    if (logEntryRes.status) {
      // create success
      _$(newLogEntryModal).modal("hide");
      console.log("log id : ", logEntryRes);
      _$(elem_teleCallerLogTable).DataTable().ajax.reload(null, false);
      pSuccess("", "Log added successfully");
    } else {
      pError(
        "",
        logEntryRes.errMsg || logEntryRes.error || "Something went wrong"
      );
    }
    isSaving = false;
    await tick();
  }

  function getFormatedDate(date) {
    if (!date && date == "") {
      return "-";
    }
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }
  async function onNameChange() {
    var newErrorObj = {};
    var regexpMobileNumber = new RegExp(`^[6-9]{1,1}[0-9]{9,9}$`);
    if (!teleCallerLog.name && !Boolean(teleCallerLog.name)) {
      newErrorObj.name = "Please enter full name.";
    }
    if (
      !teleCallerLog.mobileNo &&
      !Boolean(teleCallerLog.mobileNo) &&
      !regexpMobileNumber.test(teleCallerLog.mobileNo)
    ) {
      newErrorObj.mobileNo = "Please enter mobile number.";
    }

    if (!teleCallerLog.type) {
      newErrorObj.type = "Type Required";
    }
    errorObj = newErrorObj;
  }

  async function api_listScripts(data) {

    let action = "teleCallerScript.fetchTeleCallerScriptsList";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  function redrawTestTable() {
    if (teleCallerLogTable) { teleCallerLogTable.draw() }
  }
  function clearFilter() {
    filterName = null;
    filterStartDate = null;
    redrawTestTable();
  }


  function applyFilter() {
    redrawTestTable();
  }

  function getFilterData() {
    let name = filterName;
    let createdOn = filterStartDate;

    return {
      name,
      createdOn
    }
  }


  function showHideFilterModals(action) {
    action ? (_$(filterModalThis).modal('show')) : (_$(filterModalThis).modal('hide'))
  }


  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.seqId = order;
    } else if (sortOrder.column === 2) {
      sort.name = order;
    } else if (sortOrder.column === 3) {
      sort.scriptTypeTitle = order;
    } else if (sortOrder.column === 4) {
      sort.createdOn = order;
    } else {
      sort._id = order;
    }
    return sort;
  }


  onMount(() => {
    initDashboard();

    activeMenu.set("telelogs");

    mobileNoCleave = new Cleave(elem_mobileNoInput, {
      phone: true,
      phoneRegionCode: "IN",
      // onBlur: ()=> clearErrors("mobileNo"),
      onValueChanged: (e) => {
        teleCallerLog.mobileNo = e.target.rawValue;
      },
    });
    var searchingTypes = false;
    _$(elem_logTypeSelection).selectize({
      plugins: ["remove_button"],
      preload: true,
      maxItems: 1,
      valueField: "_id",
      labelField: "title",
      searchField: "title",
      onChange: async function (value) {
        if (value) {
          teleCallerLog.type = value;
          let result = await api_fetchTeleCallerScript({ _id: value });
          if (result.status) {
            script = result.data.content;
          }
        } else {
          script = "";
          teleCallerLog.type = "";

        }
      },
      onBlur: () => clearErrors("type"),
      load: async function (query, callback) {
        _$(elem_logTypeSelection)[0].selectize.enable();
        if (query) searchingTypes = true;
        else searchingTypes = false;
        let data = await api_listScripts();
        console.log("selectize loaded", data);
        if (data.status) {
          typesDoNotExist = false;
          typesNotFound = false;
          if (!data.data.result.length) {
            if (!searchingTypes) {
              _$(elem_logTypeSelection)[0].selectize.disable();
              typesDoNotExist = true;
              typesNotFound = true;
            } else {
              typesDoNotExist = false;
              typesNotFound = true;
            }
          }
          callback(data.data.result);
        } else {
          _$(elem_logTypeSelection)[0].selectize.disable();
          if (!searchingTypes) {
            typesDoNotExist = true;
            typesNotFound = true;
          } else {
            typesDoNotExist = false;
            typesNotFound = true;
          }
          callback([]);
        }
      },
    });

    _$(newLogEntryModal).on("hidden.bs.modal", function (e) {
      teleCallerLog = {};
      mobileNoCleave.setRawValue("");
      _$(elem_logTypeSelection)[0].selectize.enable();
      _$(elem_logTypeSelection)[0].selectize.setValue([], false);
      _$(".modal-backdrop").remove();
    });
    // _$(elem_logTypeSelection)[0].setValue(teleCallerLog.type);

    teleCallerLogTable = _$(elem_teleCallerLogTable).DataTable({
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data));
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname));
      },
      responsive: false,
      language: {
        processing: "",
        searchPlaceholder: "Name | Mobile No.",
        sSearch: "",
        lengthMenu: "_MENU_ Tele Callers Logs/page",
      },
      ajax: function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        var filterObj = { ...getFilterData() }
        filterObj.start = data.start
        filterObj.length = data.length
        filterObj.search = data.search.value
        filterObj.draw = data.draw
        filterObj.sort = data.sort;
        _$.ajax({
          url: API_URL,
          method: "post",
          data: {
            action: "telecallerLogs.list",
            accessToken: localStorage.getItem("accessToken"),
            data: filterObj
          },
          success: function (resData) {
            var result = [];
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data,
              };
              if (!resData.data.data.length) {
                emptyState = true;
              } else {
                emptyState = false;
              }
              callback(result);
            } else {
              console.log("Something went wrong");
            }
          },
        });
      },
      columns: [
        {
          data: "seqId",
          defaultValue: "-",
          render: (data) =>
            `
          <div class="d-flex justify-content-center">
            ${data}
            </div>
          `

        },
        // { data: "mobileNo", defaultValue: "-" },
        {
          data: "name",
          defaultValue: "-",
          render: function (data, type, full) {
            return `<div title="${data}"
              style="width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; cursor:pointer; text-transform:capitalize;" class="btn-view-log" data-id="${full._id}">
              ${data}
            </div>
         
          <span class="d-block tx-12 text-muted bg-transparent btn-view-log" style="cursor:pointer;" data-id="${full._id}">
                 Mobile No. : ${full.mobileNo}
          </span>`

          },

        },
        {
          data: "scriptTypeTitle",
          defaultValue: "-",
          render: function (data, type, full) {
            return `<div style="text-align:center;">
              ${data}
            </div> `;

          }
        },
        {
          data: "createdOn",
          defaultValue: "-",
          render: function (data) {
            let date = getFormatedDate(data);
            return `<div class="d-flex justify-content-center">${date}</div>`;
          },
        },
        {
          data: "_id",
          orderable: false,
          render: (data, a, b, c) => {
            // console.table({ data, a, b, c });
            var view = "-"
            var archive = ""
            if (MODULES.hasAccess(MODULES.VIEW_TELE_CALLER_LOGS) && !(MODULES.hasAccess(MODULES.ARCHIVE_TELE_CALLER_LOGS))) {
              view = `<a href="javascript:void(0)" data-id="${data}" class='btn-view-log mg-r-5' data-toggle="tooltip" data-placement="bottom" title="View"><i data-feather="eye" ></i> </a>`
            } else if (MODULES.hasAccess(MODULES.ARCHIVE_TELE_CALLER_LOGS) && !(MODULES.hasAccess(MODULES.VIEW_TELE_CALLER_LOGS))) {
              archive = ` <a href="javascript:void(0)" disabled class='btn-archive-log mg-r-5' data-id='${data}' data-toggle="tooltip" data-placement="bottom" title="Archive"><i data-feather="archive" ></i> </a>`
            } else if (!(MODULES.hasAccess(MODULES.ARCHIVE_TELE_CALLER_LOGS)) && !(MODULES.hasAccess(MODULES.VIEW_TELE_CALLER_LOGS))) {
              view = "-"
              archive = ""
            } else {
              view = `<a href="javascript:void(0)" data-id="${data}" class='btn-view-log mg-r-5' data-toggle="tooltip" data-placement="bottom" title="View"><i data-feather="eye" ></i> </a>`
              archive = ` <a href="javascript:void(0)" disabled class='btn-archive-log mg-r-5' data-id='${data}' data-toggle="tooltip" data-placement="bottom" title="Archive"><i data-feather="archive" ></i> </a>`
            }
            return `
                    <div class="d-flex justify-content-center">
                       ${view}
                       ${archive} 
                   </div>
                        `;
          },
        },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        _$(".btn-archive-log").on("click", async function (event) {
          let id = _$(this).data("id");
          pConfirm("Are you sure?", "Archive this call log?", async () => {
            let result = await api_archiveById({ id });
            if (result.status) {
              pSuccess("", result.data);
              _$(elem_teleCallerLogTable).DataTable().ajax.reload(null, false);
              await tick();
            } else {
              pError(
                "",
                result.errmsg || result.error || "Something went wrong"
              );
            }
          });
        });
        _$(".btn-view-log").on("click", async function (event) {
          let id = _$(this).data("id");
          // _$(this).tooltip("dispose");
          let result = await api_getById({ id });
          console.log("api_getById", result);
          if (result.status) {
            _$(newLogEntryModal).modal("show");
            teleCallerLog = result.data;
            mobileNoCleave.setRawValue(teleCallerLog.mobileNo);
            _$(elem_logTypeSelection)[0].selectize.setValue(
              [teleCallerLog.type],
              false
            );
            _$(elem_logTypeSelection)[0].selectize.disable();
            await tick();
          } else {
            pError("", result.errmsg || result.error || "Something went wrong");
          }
        });
      },
      processing: true,
      serverSide: true,
    });

    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  });


  let onClickShowArchivedList = () => {

    navigate(("/dashboard/telelogs/archived"))
    // redrawEnquiryTable()
  }
</script>

<style>
  .empty-state-img {
    text-align: center;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
</style>

<!-- Modal -->
<div class="modal effect-scale" bind:this={newLogEntryModal} id="modal-new-batch" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header pd-b-0">
        <div class="media align-items-center pd-12">
          <span class="tx-color-04"><i data-feather="phone-call" class="wd-60 ht-60" /></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">
              {#if Boolean(teleCallerLog._id)}
              View Call Details
              {:else}Call{/if}
            </h4>
            <p class="tx-13 tx-color-03 mg-b-0">Add new call details</p>
          </div>

        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span><br />
        </button>

      </div>
      <div class="modal-body">
        <div class="row no-gutters pd-12">
          <div class="col-12 bg-white rounded-right">

            <div class="row">
              <div class="col-md-6">
                <div class=" tx-color-02">
                  <div class="form-group">
                    <label class="d-block">Name
                      <span class="required-field">*</span>
                      <span class="text-muted">(name of the person you are
                        calling)</span></label>
                    <input bind:value={teleCallerLog.name} type="text" disabled={Boolean(teleCallerLog._id)}
                      on:keyup={(e)=> {e.key === 'Enter' && createLogEntry();}}
                    on:blur={()=> clearErrors('name')}
                    class="form-control"
                    class:parsley-error={errorObj.name}
                    placeholder="Enter name" />
                    {#if errorObj.name}
                    <div class="parsley-errors-list">{errorObj.name}</div>
                    {/if}
                  </div>
                </div>
                <div class=" tx-color-02">
                  <div class="form-group">
                    <label class="d-block">Mobile No
                      <span class="required-field">*</span></label>
                    <input bind:this={elem_mobileNoInput} type="text" on:keyup={(e)=> {e.key === 'Enter' &&
                    createLogEntry();}}
                    on:blur={()=> clearErrors('mobileNo')}
                    disabled={Boolean(teleCallerLog._id)}
                    class="form-control"
                    class:parsley-error={errorObj.mobileNo}
                    placeholder="Enter mobile number" />
                    {#if errorObj.mobileNo}
                    <div class="parsley-errors-list">
                      {errorObj.mobileNo}
                    </div>
                    {/if}
                  </div>
                </div>
                <div class=" tx-color-02">
                  <div class="form-group">
                    <label class="d-block">Description </label>
                    <textarea class="form-control" rows="3" style="resize: none" on:keyup={(e)=> {e.key === 'Enter' && createLogEntry();}}
                    bind:value={teleCallerLog.description}
                      disabled={Boolean(teleCallerLog._id)} />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class=" tx-color-02">
                  <div class="form-group">
                    <label class="d-block">Script Type
                      <span class="required-field">*</span></label>
                    <select bind:this={elem_logTypeSelection} class="form-control wd-100p" style="min-width: 100%"
                      class:parsley-error={errorObj.type} on:keyup={(e)=> {e.key === 'Enter' && createLogEntry();}} placeholder="Select call script type" />
                    {#if errorObj.type}
                    <div class="parsley-errors-list">{errorObj.type}</div>
                    {/if}
                  </div>
                </div>
                <div class="bd rounded wd-100p ht-200 pd-10">
                  {#if typesDoNotExist}
                  <div class="empty-state-img">
                    <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
                    <p class="tx-bold">
                      Scripts do not exist. Please add New Scripts
                    </p>
                    <button class="btn btn-light btn-sm" on:click={()=> (showModal = true)}>
                      Add New Script
                    </button>
                  </div>
                  {:else if typesNotFound}
                  <div class="empty-state-img">
                    <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
                    <p class="tx-bold">Script not found</p>
                  </div>
                  {:else if script}
                  <!-- display script here -->
                  {@html script}
                  {:else}
                  <div class="empty-state-img">
                    <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
                    <p class="tx-bold">
                      Please select script type to read script
                    </p>
                  </div>
                  {/if}
                </div>
              </div>
              <div class="col-md-12">
                {#if errorObj.apiError}
                <div class="parsley-errors-list">{errorObj.apiError}</div>
                {/if}
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">
            {#if Boolean(teleCallerLog._id)}Close{:else}Cancel{/if}
            <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          {#if !teleCallerLog._id}
          <button type="button" disabled={isSaving || isConverting} on:click={createLogEntry}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <ButtonLoader isLoading={isSaving} btnText="Save" />
            <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
          </button>
          {/if}
          <button type="button" disabled={isSaving || isConverting} on:click={()=> convertToEnquiry(teleCallerLog._id)}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <ButtonLoader isLoading={isConverting} btnText="Convert To Enquiry" />
            <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->



<!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
aria-labelledby="exampleModalLabel6" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
            <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </a>
            <div class="media align-items-center">
                <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter"
                        class="wd-60 ht-60"></i></span>
                <div class="media-body mg-sm-l-20">
                    <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                    <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
                </div>
            </div><!-- media -->
        </div><!-- modal-header -->

        <hr class="full-wi'dth">
        <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
          <div class="form-group">
            <label class="d-block">Name:</label>
            <input type="text" class="form-control follow-up-date" bind:value={filterName}  placeholder="Name"/>
        </div>
      
          <div class="form-group">
                <label class="d-block">Call Date:</label>
                <input type="date" onkeydown="return false"  bind:value={filterStartDate} class="form-control follow-up-date" placeholder="Call Date"/>
            </div>

            <div class="d-md-flex justify-content-end mg-t-25">
                <button disabled={isLoading} on:click={clearFilter}
                    class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal">CLEAR
                    <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                <button disabled={isLoading} on:click={applyFilter}
                    class="btn btn-sm pd-x-15 btn-primary mg-l-5" data-dismiss="modal">
                    <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                    <i data-feather="filter" class="wd-10 mg-l-5"></i>
                </button>
            </div>
        </div>

    </div>
</div>
</div>
<!-- Modal -->

<div class="content-body fadeIn">
  <!-- <Link style="display: none;" to="about">About</Link> -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Tele Callers Logs
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Tele Callers Logs</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Maintain call logs.</p>
      
    </div>
    <div class="d-block">
      {#if MODULES.hasAccess(MODULES.ADD_NEW_CALL_TELE_CALLER_LOGS)}
      <button on:click={openNewLog} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
        <i data-feather="plus" class="wd-10 mg-r-5" />
        Make New Call
      </button>
      {/if}
      <a href="javascript:void(0)" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"  on:click ={onClickShowArchivedList}>
       Show Archived Logs
       </a>
      <button on:click={()=>{showHideFilterModals(true)}} class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"> <i data-feather="filter" class="mg-r-5"></i> Filters</button>
      <!-- <a href="/dashboard/batch/add" use:links  class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="file"
                    class="wd-10 mg-r-5"></i> Add new Batch</a> -->
    </div>
  </div>
  <hr class="full-width">
  <div>
    <table bind:this={elem_teleCallerLogTable} class="table">
      <thead>
        <tr>
          <th style="width: 5%; text-align: center;">#</th>
          <!-- <th>Mobile No</th> -->
          <th style="width:20%;">Name</th>
          <th style="width: 10%; text-align: center;">Type</th>
          <th style="width: 10%; text-align: center;">Call Date</th>
          <th style="width: 5%; text-align: center;">Actions</th>
        </tr>
      </thead>
      <tbody />
    </table>
  </div>
  <div class="d-flex justify-content-center">
    {#if emptyState }
    <div class="empty-state-img wd-200">
      <img src="/images/empty.svg" style="width: 200px" alt="empty" />
      <p class="tx-bold">No telecaller logs found</p>
    </div>
    {/if}
  </div>
</div>


{#if showModal}
<div class="mg-b-20">
  <STPModal on:close={onCloseScriptModal} />
</div>
{/if}
