<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();
  export let data = { agreedFees: 0 };
  export let isEdit = false;
  let checkBoxTaxApplicable = "yes";
  let errorObj = {};
  // $: console.log("current fees data", data);
  let convertToINR = function (input) {
    let amount = Number(input);
    return amount.toLocaleString("en-IN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: "currency",
      currency: "INR",
    });
  };
  let preventNonNumericalInput = (e) => {
    e.key == "e" || e.key == "+" || e.key == "-" ? e.preventDefault() : "";
  };
  let agreedFeesChanged = async (e) => {
    // console.log("agreedFeesChange", e.target.value);
    dispatch("agreedFeesChange", {
      agreedFees: e.target.value,
    });
  };
  let radioClickHandler = async (e) => {
    // console.log("checkBoxTaxApplicable", {
    //   checkBoxTaxApplicable: e.target.value,
    // });
    dispatch("checkBoxTaxApplicable", {
      checkBoxTaxApplicable: e.target.value,
    });
  };

  onMount(async () => {
  })
</script>

<div class="card" style="margin-bottom: 20px;">
  <div class="card-header d-flex justify-content-between">
    <h6 class="lh-5 mg-b-0 text-capitalize">
      current {#if !(data.batch && data.batch._id)}
        <span class="required-field">(Please Select Batch)</span>
      {/if}
    </h6>
    {#if data.batch && data.batch.taxApplicable}
      <p class="tx-13 tx-color-03">
        {data.batch && data.batch.taxPercent}% tax is applied on {convertToINR(
          data.batch && data.batch.amount
        )}.
        {#if data.batch && data.batch.feesIncludingTax}
          This fee includes the tax amount
        {/if}
      </p>
    {/if}
  </div>
  <div class="card-body pd-x-20 pd-b-10">
    <div class="d-flex justify-content-between" style="flex-direction: column">
      {#if data.batch && data.batch.taxApplicable}
        <div class="form-group row">
          <label class="col-form-label col-md-6"
            >Take Tax Amount From Student:
          </label>
          <div
            class="col-sm-6 d-sm-flex align-items-center justify-content-between "
          >
            <div class="d-sm-flex">
              <div class="custom-control custom-radio">
                <input
                  disabled={data.batch && data.batch.feesIncludingTax}
                  on:click={radioClickHandler}
                  bind:group={checkBoxTaxApplicable}
                  type="radio"
                  id="yesTaxRadio"
                  class="custom-control-input"
                  value="yes"
                />
                <label class="custom-control-label" for="yesTaxRadio">Yes</label
                >
              </div>

              <div class="custom-control custom-radio mg-sm-l-30">
                <input
                  disabled={data.batch && data.batch.feesIncludingTax}
                  type="radio"
                  on:click={radioClickHandler}
                  bind:group={checkBoxTaxApplicable}
                  id="noTaxRadio"
                  class="custom-control-input"
                  value="no"
                />
                <label class="custom-control-label" for="noTaxRadio">No</label>
              </div>
            </div>
          </div>
        </div>
      {/if}
    </div>
    <div class="table-responsive">
      <table class="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
        <thead>
          <tr class="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
            <th class="align-middle">Batch Name</th>
            <th class="align-middle">Actual Fees</th>
            <th class="align-middle">Agreed Fees</th>
            <th class="align-middle">Discount</th>
            <th class="align-middle">Total</th>
          </tr>
        </thead>
        <tbody>
          {#if Object.keys(data).length}
            <tr class="">
              <td class="tx-color-03 align-middle"
                >{#if data.batch && data.batch.name}{data.batch.name}{/if}</td
              >
              <td class="align-middle">{convertToINR(data.batchFees)}</td>
              <td class="align-middle">
                <input
                  min="0"
                  on:keypress={preventNonNumericalInput}
                  on:input={agreedFeesChanged}
                  value={data.agreedFees}
                  class:parsley-error={errorObj.agreedFees}
                  type="number"
                  class="form-control"
                  placeholder="Enter Final Fees"
                  disabled={
                    data.isFree == "yes"
                   }
                />
              </td>
              <td class="align-middle"
                >{convertToINR(data.batchFees - (data.agreedFees || 0))}</td
              >
              <td class="align-middle"
                >{convertToINR(data.totalPayableAmount)}</td
              >
            </tr>
          {:else}
            <tr>
              <td class="tx-color-03">-</td>
              <td class="">{convertToINR(0)}</td>
              <td class="">{convertToINR(0)}</td>
              <td class="">{convertToINR(0)}</td>
              <td class="">{convertToINR(0)}</td>
            </tr>
          {/if}
        </tbody>
      </table>
    </div>
  </div>
</div>
