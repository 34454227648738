<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { createEventDispatcher, onMount, tick } from 'svelte';
    import api_handleApiCalls from "./apiCallHandler.js";
    import { API_URL } from "../../config.js"

    const dispatch = createEventDispatcher();
    let totalStudentCount = 0
    let datatableCallbackList = []
    let firstRender = true
    export let filters
    export let userIdArr
    let thisFilters = {}
    let isLoading
    let filterRole
    let filterUserBy
    let filterBatchId
    let filterChannelId
    let accessProfilesList = []
    let elem_selectRole
    let elem_selectUserBy
    let elem_selectBatch
    let elem_selectChannel
    let showSelectizeElements = true

    let hideUi_elem = {
        selectRole: false,
        selectUserBy: true,
        selectBatch: true,
        selectChannel: true,
    }

    let selectizeChannelInstance
    let selectizeBatchInstance
    let selectizeUserByInstance
    let selectizeRoleInstance

    let elem_searchUser
    let elem_userModal
    let elem_usersTable
    let elem_selectedUsersTable
    let usersDatatableEmptyState = false
    let usersDatatableInstance
    let selectedUsersDatatableInstance




    function goTO(to) {
        if (to) navigate(to)
        return
    }

    let selectedUserIdsArr = []
    let unSelectedUsersArr = []
    let allUsersHashMapped = {}

    function afterDoneUserSelection() {
        _$(elem_userModal).modal("hide");
        dispatch('done', { selectedUsersIds: selectedUserIdsArr });
    }

    async function fetchAccessProfiles(data) {
        let result = (await api_handleApiCalls('roles.getOrgAccessProfiles', data));
        if (result.status) {
            return result.data;
        } else {
            return [];
        }
    }
    async function api_getUsersByFilters(data) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var payload = JSON.stringify(
            {
                action: "user.getUsersByFilter",
                accessToken: localStorage.getItem("accessToken"),
                data: data
            }
        );
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: payload,
        };
        try {
            var resObj = await fetch(API_URL, requestOptions);
            var response = await resObj.json();
            return response;

        } catch (error) {
            return { error: error };
        }
    }

    async function getChannelsById(idsArr) {

        var response = await api_handleApiCalls('user.getChannelsByIds', { channelIds: idsArr })

        if (response.status) {

            return response.data
        } else {
            return []
        }


    }
    async function getBatchesById(idsArr) {
        var response = await api_handleApiCalls('batch.getBatchesByIds', { batchIds: idsArr })

        if (response.status) {

            return response.data
        } else {
            return []
        }
    }

    async function selectUser(userId) {
        if (selectedUserIdsArr.indexOf(userId) == -1) {

            selectedUserIdsArr.push(userId)

            selectedUserIdsArr = selectedUserIdsArr

            if (selectedUsersDatatableInstance) {
                selectedUsersDatatableInstance.clear();
                selectedUsersDatatableInstance.rows.add(selectedUserIdsArr);
                selectedUsersDatatableInstance.draw();
            }
            if (usersDatatableInstance) usersDatatableInstance.draw();
        }


    }

    function hashmappedUsersArr(allUsersArr) {
        allUsersHashMapped = {}
        for (let userIndex = 0; userIndex < allUsersArr.length; userIndex++) {
            const element = allUsersArr[userIndex];
            allUsersHashMapped[element._id] = element
        }

    }

    function redrawTables() {
        if (usersDatatableInstance)
            usersDatatableInstance.draw()
        if (selectedUsersDatatableInstance)
            selectedUsersDatatableInstance.draw()
    }
    function reDrawSelectedUserTable(arrData = []) {
        if (selectedUsersDatatableInstance) {
            selectedUsersDatatableInstance.clear();
            selectedUsersDatatableInstance.rows.add(arrData);
            selectedUsersDatatableInstance.draw();
        }
    }
    function bindDatatable() {


        usersDatatableInstance = _$(elem_usersTable).DataTable({
            stateSave: true,
            "stateSaveCallback": function (settings, data) {
                localStorage.setItem(window.location.pathname, JSON.stringify(data))
            },
            "stateLoadCallback": function (settings) {
                return JSON.parse(localStorage.getItem(window.location.pathname))
            },

            "searching": false,
            "bLengthChange": false,
            responsive: true,
            pageLength: 7,
            language: {
                processing: "",

            },
            ajax: function (data, callback, settings) {
                var dData = {}
                dData.filters = { ...getFlitersData() }
                dData.start = data.start
                dData.length = data.length
                dData.search = _$(elem_searchUser).val()
                dData.draw = data.draw
                dData.selectedUsers = userIdArr;
                dData.firstRender = firstRender
                _$.ajax({
                    url: API_URL,
                    method: 'post',
                    data: {
                        action: 'user.getUsersByFilter',
                        accessToken: localStorage.getItem("accessToken"),
                        data: dData
                    },
                    success: function (resData) {
                        var result = []
                        if (resData.status) {
                            var paginationData = resData.data;
                            var allUsers = paginationData.allUsers
                            firstRender = false

                            if (allUsers) { hashmappedUsersArr(allUsers) }

                            totalStudentCount = paginationData.totalCount
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(paginationData.filteredCount),
                                data: paginationData.data
                            }

                            if (!resData.data.length) {
                                usersDatatableEmptyState = true
                            }

                            callback(result);
                        }
                        else {
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        usersDatatableEmptyState = true
                    }
                })
            },
            columns: [
                {
                    data: 'sequence',
                    render: function (data, type, row, meta) {
                        var statusElem
                        var userIcon
                        if (((meta.row % 2) == 0))
                            userIcon = `<span class="avatar-initial rounded-circle bg-white bd tx-dark">${row.name.charAt(0)}</span>`
                        else
                            userIcon = `<span class="avatar-initial rounded-circle bg-white bd tx-dark">${row.name.charAt(0)}</span>`

                        return `
                        <div class="media">
                            <div class="avatar avatar-sm">
                                ${userIcon}
                            </div>

                            <div class="media-body mg-l-10">
                                <h6 class="tx-13 mg-b-2">${row.name}</h6>
                                <span class="d-block tx-color-03">Mobile No: ${row.mobileNo}</span>
                            </div>
                            <button type="button" user-id="${row._id}" class="btn btn-xs btn-secondary btn-icon btn-select">
                                <i class="mg-r-3" data-feather="user-check"></i> Select
                            </button>
                            
                        </div>
                        `
                    },

                },
            ],
            "columnDefs": [],
            "drawCallback": function () {
                feather.replace({ width: "1em", height: "1em" });
                _$(".btn-select").unbind().click(function () {
                    let userId = _$(this).attr('user-id')
                    selectUser(userId)
                })
            },
            "ordering": false,
            "processing": true,
            "serverSide": true,
        });

        selectedUsersDatatableInstance = _$(elem_selectedUsersTable).DataTable({
            data: selectedUserIdsArr,
            "searching": false,
            "bLengthChange": false,
            bProcessing: false,
            responsive: true,
            pageLength: 7,
            columns: [
                {
                    data: '_id',
                    render: function (data, type, row, meta) {
                        var userIcon
                        row = allUsersHashMapped[row]

                        if (!row) {
                            row = {
                                name: '-',
                                mobileNo: '-',
                                _id: ''

                            }
                        }

                        if (((meta.row % 2) == 0))
                            userIcon = `<span class="avatar-initial rounded-circle bg-white bd tx-dark">${row.name.charAt(0)}</span>`
                        else
                            userIcon = `<span class="avatar-initial rounded-circle bg-white bd tx-dark">${row.name.charAt(0)}</span>`

                        return `
                        <div class="media">
                            <div class="avatar avatar-sm">
                                ${userIcon}
                            </div>

                            <div class="media-body mg-l-10">
                                <h6 class="tx-13 mg-b-2">${row.name}</h6>
                                <span class="d-block tx-color-03">Mobile No: ${row.mobileNo}</span>
                            </div>

                            <button type="button" user-id="${row._id}" class="btn btn-xs btn-secondary btn-icon remove-selected-user">
                                <i class="mg-r-3" data-feather="user-x"></i> Unselect
                            </button>

                        </div>
                        `
                    },

                },
            ],
            "columnDefs": [

            ],
            "language": {
                "loadingRecords": "Please wait - loading..."
            },
            "drawCallback": function () {
                feather.replace({ width: "1em", height: "1em" });
                _$(".remove-selected-user").click(function () {
                    let userId = _$(this).attr('user-id')
                    unselectUser(userId)
                })
            },
            "processing": false,
            "ordering": false,
            "oLanguage": {
                "sSearch": "",
                "sEmptyTable": "No users selected",
            },
            bAutoWidth: false
        });

        _$(elem_searchUser).keyup(function () {
            if (usersDatatableInstance)
                usersDatatableInstance.search(_$(this).val()).draw();
        })
    }

    function unselectUser(userId) {
        selectedUserIdsArr = selectedUserIdsArr.filter(_id => userId !== _id);

        if (selectedUsersDatatableInstance) {
            selectedUsersDatatableInstance.clear();
            selectedUsersDatatableInstance.rows.add(selectedUserIdsArr);
            selectedUsersDatatableInstance.draw();
        }

        if (usersDatatableInstance) usersDatatableInstance.draw();

    }

    function getFlitersData() {

        let filterData = {}

        if (filterRole && filterRole != '') filterData.role = filterRole;
        if (filterUserBy == 'channel') filterData.channelId = filterChannelId
        if (filterUserBy == 'batch') filterData.batchId = filterBatchId


        return filterData;
    }

    function clearSelectionChannel() { selectizeBatchInstance[0].selectize.clear(); filterChannelId = null }
    function clearSelectionBatch() { selectizeBatchInstance[0].selectize.clear(); filterBatchId = null }

    function clearChannelAndBatchSelection() {

        selectizeBatchInstance[0].selectize.clear()
        selectizeChannelInstance[0].selectize.clear()

        filterBatchId = null
        filterChannelId = null
    }

    var clearAllSelections = () => {
        clearChannelAndBatchSelection()

        selectizeUserByInstance[0].selectize.clear()
        selectizeRoleInstance[0].selectize.clear()

        filterRole = null
        filterUserBy = null
    }

    var btnUnSelectAll = async () => {
        selectedUserIdsArr = []
        selectedUserIdsArr = selectedUserIdsArr

        await tick()
        reDrawSelectedUserTable(selectedUserIdsArr)
        redrawTables()

    }

    var btnSelectAll = async () => {
        let userArr = Object.keys(allUsersHashMapped)
        let duplicateEntriesArr = selectedUserIdsArr
        duplicateEntriesArr.push(...userArr)
        selectedUserIdsArr = [...new Set(duplicateEntriesArr)]

        selectedUserIdsArr = selectedUserIdsArr
        await tick()
        reDrawSelectedUserTable(selectedUserIdsArr)
        redrawTables()

    }
    function bindRoleSelectize() {
        let userRolesArr = []
        // console.log("thisFilters.roles",thisFilters.roles);
        if (Array.isArray(thisFilters.roles)) {
            userRolesArr = thisFilters.roles.map((id) => { return { _id: id, name: id } })
        }
        // console.log("org roles",accessProfilesList);
        selectizeRoleInstance = _$(elem_selectRole).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "name",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            placeholder: "Select Role",
            persist: false,
            options: [{ _id: "", name: "student" },
            { _id: "", name: "parent" },
            ...accessProfilesList],
            onChange: async function (value) {
                // console.log("value:::",value);
                if (value && value != '') {
                    filterRole = value

                    if ((Array.isArray(thisFilters.channelIds) && thisFilters.channelIds.length != 0) || (Array.isArray(thisFilters.batchIds) && thisFilters.batchIds.length != 0)) {
                        hideUi_elem.selectUserBy = false
                    }

                }
                else
                    filterRole = null

                await tick()
                redrawTables()
            },
        });
    }
    async function bindSelectize() {



        selectizeUserByInstance = _$(elem_selectUserBy).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            placeholder: "Select Filter By",
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            persist: false,
            options: [{ _id: 'channel', name: 'Channel' }, { _id: 'batch', name: 'Batch' }],
            onChange: async function (value) {

                filterUserBy = value
                clearSelectionBatch()
                clearSelectionChannel()


                await tick()
            },
        });

        let batchSelectizeArr = []
        if (Array.isArray(thisFilters.batchIds)) {
            batchSelectizeArr = await getBatchesById(thisFilters.batchIds)

            // batchSelectizeArr = thisFilters.batchIds.map((id)=> { return {_id: id, name: id} })
        }

        selectizeBatchInstance = _$(elem_selectBatch).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            placeholder: "Select Batch",
            searchField: "name",
            delimiter: ",",
            persist: false,
            options: batchSelectizeArr,
            onChange: async function (value) {

                filterBatchId = value
                await tick()
                redrawTables()

            },
        });


        let channelSelectizeArr = []
        if (Array.isArray(thisFilters.channelIds)) {
            channelSelectizeArr = await getChannelsById(thisFilters.channelIds)
            // batchSelectizeArr = thisFilters.channelIds.map((id)=> { return {_id: id, name: id} })
        }

        selectizeChannelInstance = _$(elem_selectChannel).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "Select Channel",
            delimiter: ",",
            persist: false,
            options: channelSelectizeArr,
            onChange: async function (value) {
                filterChannelId = value
                await tick()
                redrawTables()
            },
        });

    }

    onMount(async () => {
        feather.replace({ width: "1em", height: "1em" })
        bindDatatable()
        _$(elem_userModal).modal("show");
        accessProfilesList = await fetchAccessProfiles({});

        if (filters) {

            if (filters.channelIds)
                thisFilters.channelIds = filters.channelIds

            if (filters.batchIds)
                thisFilters.batchIds = filters.batchIds

            if (filters.roles) {
                hideUi_elem.selectRole = false
                thisFilters.roles = filters.roles

                bindRoleSelectize()
            }

            bindSelectize()

        } else {

            showSelectizeElements = false
        }

    });

</script>

<style>
    .dNone {
        display: none !important;
    }
</style>


<div class="modal effect-scale" bind:this={elem_userModal} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-body pd-20 pd-sm-30">

                <div class="d-flex">
                    <h4 class="tx-18 tx-sm-20 mg-b-3">
                        <i class="mg-r-5" data-feather="users"></i> Select Users
                    </h4>

                    {#if showSelectizeElements}

                    <div class:dNone={hideUi_elem.selectRole} class="mg-l-25 wd-150 animated fadeIn">
                        <select bind:this={elem_selectRole} class="">
                        </select>
                    </div>

                    <div class:dNone={(!Boolean(filterRole))} class="mg-l-8 wd-150 animated fadeIn">
                        <select bind:this={elem_selectUserBy} class="">
                        </select>
                    </div>

                    <div class:dNone={(filterUserBy !='batch' )} class="mg-l-8 wd-150 animated fadeIn">
                        <select bind:this={elem_selectBatch} class="">
                        </select>
                    </div>

                    <div class:dNone={(filterUserBy !='channel' )} class="mg-l-8 wd-150 animated fadeIn">
                        <select bind:this={elem_selectChannel} class="">
                        </select>
                    </div>

                    <div class="mg-l-8 animated fadeIn">
                        <button style="height: 37px;" class="btn btn-sm pd-x-15 btn-secondary btn-uppercase"
                            on:click="{clearAllSelections}">
                            <i class="mg-r-5" data-feather="x"></i>
                            Clear filters
                        </button>
                    </div>
                    {/if}

                </div>

                <p class="mg-b-20 tx-color-03"></p>
                <a href="" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>

                <div class="d-flex" style="overflow-y: auto;">

                    <div id="scroll1" class="ht-500" style="width: 100%;">
                        <table bind:this={elem_usersTable} class="table tableWhite" style="width: 100% !important;">
                            <thead>
                                <tr>
                                    <th class="bg-white">
                                        <div class="d-flex justify-content-between">
                                            <div>Users List</div>
                                            <div class="d-md-flex">
                                                <input type="text" bind:this={elem_searchUser}
                                                    class="form-control wd-200" placeholder="Search Name | Mobile No"
                                                    style="height: 30px !important;">

                                                <button
                                                    class=" wd-150 mg-l-2 btn btn-xs pd-x-15 btn-primary btn-uppercase"
                                                    on:click="{btnSelectAll}">
                                                    <i class="mg-r-5" data-feather="check-circle"></i>
                                                    Select All ({ totalStudentCount ? totalStudentCount : '' })
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="tx-13 mg-l-15">

                            </tbody>
                            {#if usersDatatableEmptyState}
                            <div class="empty-state-img">
                                <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
                            </div>
                            {/if}
                        </table>

                    </div>

                    <div id="scroll2" class="ht-500 pd-l-20" style="width: 100%;">
                        <table bind:this={elem_selectedUsersTable} class="table tableWhite"
                            style="width: 100% !important;">
                            <thead>
                                <tr>
                                    <th class="bg-white">
                                        <div class="d-flex justify-content-between">
                                            <div>Selected Users List </div>
                                            {#if selectedUserIdsArr.length}
                                            <div>
                                                <a href="#" class="mg-l-2 btn btn-xs pd-x-15 btn-primary btn-uppercase"
                                                    on:click="{btnUnSelectAll}">
                                                    <i class="mg-r-5" data-feather="x"></i>
                                                    Unselect All ({ selectedUserIdsArr.length})
                                                </a>
                                            </div>
                                            {/if}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="tx-13 mg-l-15">

                            </tbody>
                        </table>

                    </div>

                </div>


                <div class="d-flex justify-content-end">
                    <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase" on:click="{afterDoneUserSelection}">
                        <i class="mg-r-5" data-feather="check-circle"></i>
                        Choose {selectedUserIdsArr.length} Selected Question
                    </button>
                </div>
            </div><!-- modal-body -->
        </div><!-- modal-content -->
    </div><!-- modal-dialog -->
</div><!-- modal -->
