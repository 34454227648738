<script>
  import { Router, Link, links, Route } from "svelte-routing";
  import ButtonLoader from "../utils/button_loader.svelte";
  import { onMount, tick, createEventDispatcher } from "svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  export let btnName;
  export let isLoading = false;
  export let show = false;
  export let questionId;
  export let testId;
  let questionsData = {};
  let errorDetails = {}
  let dataToBeSaved = {
    questionId: null,
    testId: null,
    issue: "",
    reportTypeId: null
  };
  function resetDataObject() {
    dataToBeSaved.issue = "";
    dataToBeSaved.reportTypeId = "";
  }
  async function clearValidations() {
    errorDetails = {}
    await tick();
  }

  async function clearReportTypeValidation() {
    delete errorDetails["reportType"];
    errorDetails = errorDetails;
    await tick();
  }

  async function clearIssueValidation() {
    delete errorDetails["Issue"];
    errorDetails = errorDetails;
    await tick();
  }

  function validate(data) {
    clearValidations();
    if (!data.reportTypeId) {
      errorDetails.reportType = "Report type should not be empty";
    }
    // data.report = CKEDITOR.instances.report_editor.getData().trim();
    if (!data.issue) {
      errorDetails.issue = "Specifying issue is mandatory";
    }
    if (Object.values(errorDetails).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  let reportType = "";
  let dataFromReport = {
    questionId: questionId,
  };

  let question;
  let data = [];
  let reportedTypeId;
  let modalCmp;

  async function api_getReportTypeList() {

    let action = "questionsReported.fetchReportedQuestionsTypeList";
    var response = await api_handleApiCalls(action, {});
    return response.data.data;

  }

  async function api_addReports(data) {
    let action = "questionsReported.saveReportedQuestion";
    var response = await api_handleApiCalls(action, data);
    if (response.errMsg) {
      PNotify.error({
        title: 'Error',
        text: response.errMsg,
        delay: 3000
      })
    } else {
      PNotify.success({
        title: 'success',
        text: "Report added successfully",
        delay: 3000
      })

    }
    return response;
  }

  let onReportAdd = async function () {
    feather.replace();
    if (isLoading) return;
    isLoading = true;
    await tick()
    let isValid = validate(dataToBeSaved);
    if (!isValid) {
      isLoading = false;
      return false;
    }
    dataToBeSaved.questionId = questionId;
    let res = await api_addReports(dataToBeSaved)
    if (res.data?.insertedId) {
      selectedId = res.data.insertedId;
    }
    isLoading = false;
    resetDataObject();
    _$(modalCmp).modal("hide");
  }

  let api_getQuestionById = async function (data) {

    let action = "questions.getQuestionById";
    var response = await api_handleApiCalls(action, data);
    return response.data;

  }

  onMount(async () => {
    feather.replace();
    data = await api_getReportTypeList();
    console.log("data result from reports+++++=======>>>>>>>>>>>>>", dataFromReport);
    console.log("questionId", questionId);
    CKEDITOR.replace("report_editor", {
      toolbarStartupExpanded: false,
      height: "150px",
    });

  })

  let openModal = async () => {
    questionsData = await api_getQuestionById(dataFromReport);
    question = questionsData.data.question[questionsData.languages[0].title];
    CKEDITOR.instances.report_editor.setData(question);
    CKEDITOR.instances.report_editor.setReadOnly(true);
    await tick();
    resetDataObject();
    _$(modalCmp).modal('show');
  }
</script>
<button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={openModal}>
  <i data-feather="alert-circle" class="wd-10 mg-r-5"></i> {btnName}
</button>
<div class="modal effect-scale" bind:this={modalCmp} id="modal-new-batch" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered wd-sm-650" role="document">
    <div class="modal-content ">
      <div class="modal-body pd-0">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
          <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="media align-items-center">
            <span class="tx-color-03 d-none d-sm-block"><i data-feather="help-circle" class="wd-60 ht-60"></i></span>
            <div class="media-body mg-sm-l-20">
              <h4 class="tx-18 tx-sm-20 mg-b-2">
                Report
              </h4>
              <p class="tx-13 tx-color-03 mg-b-0">Report this question.</p>
            </div>
          </div><!-- media -->
        </div><!-- modal-header -->
        <hr class="full-width">

        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="row no-gutters">
          <div class="col-12 bg-white rounded-right">
            <div class="ht-100p d-flex flex-column justify-content-center pd-l-25 pd-r-25 pd-b-25">
              <div class=" tx-color-02">
                <div class="form-group">
                  <div class="pd-0 d-flex flex-column">
                    <label class="d-block">Report Type
                      <span class="required-field">*</span></label>

                    {#if data.length == 0 }
                    <span class="parsley-errors-list">First add the report type that you want to use.</span>
                    {:else}
                    {#each data as data}
                    <span class="custom-control custom-radio"> <input class="mg-r-8" type="radio" id={data._id}
                        bind:group={dataToBeSaved.reportTypeId} on:focus={clearReportTypeValidation}
                        class:parsley-error={errorDetails.reportType} name="reportType" value={data._id}><label
                        for={data._id}>{data.reportType}</label></span>
                    {/each}
                    {/if}
                    {#if errorDetails.reportType}
                    <div class="parsley-errors-list">{errorDetails.reportType}</div>
                    {/if}
                  </div>

                  <label class="d-block mg-t-20">Question</label>
                  <textarea style="width: 500px; overflow: auto;" on:keyup={(e)=> e.key === 'Enter' && saveScript()}
                        on:input={(e) => {
                         question = CKEDITOR.instances.report_editor.getData();
                       }}
                       name = {'report_editor'}
                    />
                    <!-- {@html question} -->
                    <div class="form-group align-items-center">
                    <label class="d-block mg-t-20 " >
                      Issue
                      <span class="required-field">*</span>
                     </label>
                       <textarea rows={4} 
                       name="" bind:value={dataToBeSaved.issue}
                       on:focus={clearIssueValidation}
                       class:parsley-error={errorDetails.issue}  
                       class="form-control rounded-5"
                       placeholder="Write your issue here"/>
                       {#if errorDetails.issue} 
                    <div class="parsley-errors-list">{errorDetails.issue}</div>
                   {/if}
                   </div>
                </div>
               
          </div>
              <div class=" d-flex justify-content-end">
               <button
               type="button"
               class="btn btn-secondary tx-12"
               data-dismiss="modal">
               CANCEL
               <i data-feather="x" class="wd-10 mg-l-5"></i>
               </button>
               <button
               type="button" on:click={onReportAdd}
               class="btn btn-sm pd-x-15 tx-12 btn-primary btn-uppercase mg-l-5" disabled={isLoading}>
               <ButtonLoader isLoading={isLoading} btnText="Save"/>
               <i data-feather="save" class="wd-10 mg-l-5"></i>
               </button> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
