<script>
    import { Router, Link, Route, link, navigate } from "svelte-routing";
    import { onMount, tick, onDestroy } from 'svelte';
    import { _mobileNo, _signToken, _otp, _registerOtpToken, _accessToken } from "../store.js";
    import { API_URL } from "../config.js"

    let otp;
    let otpEle;
    let local_signToken;
    let local_otp;
    const errorDetails = {};

    _signToken.subscribe(value => {
        local_signToken = value;
    });

    _otp.subscribe(value => {
        local_otp = value;
    });


    function clearFields() {
        otp = "";
        clearValidations();
    }

    function clearValidations() {
        delete errorDetails.otp;
    }
    function validate() {
        clearValidations();
        tick();
        // var moNoReg = new RegExp("^[7-9][0-9]{9}$")
        // if (!moNoReg.test(otp) || otp.length != 10) {
        if (String(otp).length != 6) {
            errorDetails.otp = "Please enter valid OTP";
        }
        if (Object.values(errorDetails).length > 0) {
            return false;
        } else {
            return true;
        }
    }

    async function verifyOtp() {
        const isValid = validate();
        if (!isValid) return;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var payload = JSON.stringify({
            action: "auth.verifyOtpForLogin",
            data: {
                otpCode: otp,
                signToken: local_signToken
            },
        });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: payload,
        };
        try {
            var resObj = await fetch(API_URL, requestOptions);
            var response = await resObj.json();
            console.log(response);
            if (response.status) {
                var accessToken = "";

                if (response.data.accessToken && response.data.accessToken.length == 0) {
                    alert("Token not found. Failed to login, Please contact admin")
                }
                if (response.data.accessToken && response.data.accessToken.length == 1) {
                    accessToken = response.data.accessToken[0];
                    _accessToken.set(accessToken);
                    window.localStorage.setItem("accessToken", accessToken);


                    var browser = (function () {
                        var test = function (regexp) { return regexp.test(window.navigator.userAgent) }
                        switch (true) {
                            case test(/edg/i): return "Microsoft Edge";
                            case test(/trident/i): return "Microsoft Internet Explorer";
                            case test(/firefox|fxios/i): return "Mozilla Firefox";
                            case test(/opr\//i): return "Opera";
                            case test(/ucbrowser/i): return "UC Browser";
                            case test(/samsungbrowser/i): return "Samsung Browser";
                            case test(/chrome|chromium|crios/i): return "Google Chrome";
                            case test(/safari/i): return "Apple Safari";
                            default: return "Other";
                        }
                    })();

                    var myHeaders1 = new Headers();
                    myHeaders1.append("Content-Type", "application/json");
                    var payload1 = JSON.stringify({
                        action: "auth.updateUserAccessToken",
                        accessToken: accessToken,
                        data: {
                            accessToken: accessToken,
                            deviceName: 'Browser',
                            deviceModel: browser
                        }
                    });
                    var requestOptions1 = {
                        method: "POST",
                        headers: myHeaders1,
                        body: payload1,
                    };


                    await fetch(API_URL, requestOptions1);


                    navigate("/dashboard", { replace: true });
                } else {
                    window.localStorage.setItem("accessTokenList", response.data.accessToken)
                    navigate("/login/choose-user", { replace: true });


                }
            } else {
                errorDetails.otp = response.errMsg;
            }


        } catch (error) {
            console.error("error", error);
            console.log({ error: error });
        }
    }
    async function init() {
        clearFields();
        otpEle.focus();
        otp = local_otp;
        console.log(local_otp, local_signToken)
    }
    onMount(init);
    onDestroy(clearFields);


</script>

<div class="sign-wrapper mg-lg-l-50 mg-xl-l-60 fadeIn">
    <div class="wd-100p">
        <h3 class="tx-color-01 mg-b-5">Sign In</h3>
        <p class="tx-color-03 tx-16 mg-b-40">Welcome back! Please signin to continue.</p>

        <div class="form-group">
            <label>Enter OTP</label>
            <input bind:this={otpEle} value={otp} type="number" class:parsley-error={errorDetails.otp} on:keyup={(e)=>
            e.key === 'Enter' && verifyOtp()}
            on:input={function (e) {
            this.value = this.value.slice(0, 6);
            otp = this.value;
            }}
            class="form-control" placeholder="Enter your OTP">
            {#if errorDetails.otp}
            <div class="parsley-errors-list">{errorDetails.otp}</div>
            {/if}
        </div>
        <button on:click={verifyOtp} class="btn btn-brand-02 btn-block">Sign In</button>
        <!-- <div class="divider-text">or</div>
        <button class="btn btn-outline-facebook btn-block">Sign In With Facebook</button>
        <button class="btn btn-outline-twitter btn-block">Sign In With Twitter</button> -->
        <!-- <div class="tx-13 mg-t-20 tx-center">Don't have an account? <a href="/signup" use:link>Create an
                Account</a></div> -->
    </div>
</div><!-- sign-wrapper -->
