<script>
  import { onMount } from "svelte";
  import { link, links, navigate } from "svelte-routing";
  import { activeMenu } from "../../stores";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import MODULES from "../../utils/modules.js"
  import { API_URL } from "../../../config.js"
  let elem_datatable;
  let isEmpty;
  let dataTableInstance;
  let selectizeBatchInstance1;
  let selectizeBatchInstance;
  let filterModalThis;
  let filterElemBatch, filterElemUser, filterByNoOfQuestions, filterStartDate, filterParentVerified;
  let isLoading = false;
  let userIdArr;
  let batchesList;
  let emptyState = false;

  function hideFilterModel(params) {
    _$("#filter-modal").modal("hide");
    assignmentObj.topic = "";
  }
  function showFilterModel(params) {
    _$("#filter-modal").modal("show");
  }


  let showHideFilterModal = () => {
    _$('#filter-modal').modal('show');
  }

  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [day, month, year].join("-");
    return dateString;
  }

  function clearFilter() {
    filterParentVerified = null;
    selectizeBatchInstance1[0].selectize.clear()
    selectizeBatchInstance[0].selectize.clear()
    filterByNoOfQuestions = null;
    if (dataTableInstance) { dataTableInstance.draw() }
  }

  function redrawLeaveRequestTable() {
    if (dataTableInstance) { dataTableInstance.draw() }
  }
  function applyFilter() {
    if (dataTableInstance) { dataTableInstance.draw() }
  }


  function getFilterData() {
    let userIdArr = _$(filterElemUser).val()
    let batchId = _$(filterElemBatch).val()
    return {
      batchId,
      userIdArr,
      filterByNoOfQuestions,
      filterParentVerified
    }
  }

  async function api_ReportToList() {
    var resData = await api_handleApiCalls('user.getAllUsers', {})
    if (resData.status) {

      return resData.data.data
    } else {
      return []
    }
  }

  async function api_getBatchesList() {
    var resData = await api_handleApiCalls('batch.getMyBatches', {})
    if (resData.status) {

      return resData.data
    } else {
      return []
    }
  }
  async function bindSelect2() {


    selectizeBatchInstance1 = _$(filterElemUser).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "select student",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: userIdArr,
      render: {
        option: function (data, escape) {
          return (
            '<div style="padding-left:15px">' +
            escape(data.name) + "<span>" + " | " + escape(data.mobileNo) + "</span>" +
            "</div>"
          );
        },
        item: function (data, escape) {
          return (
            '<div>' +
            escape(data.name) + "<span>" + " | " + escape(data.mobileNo) + "</span>" +
            "</div>"
          );
        },
      }
    });

    selectizeBatchInstance = _$(filterElemBatch).selectize({
      preload: true,
      create: false,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "select batch",
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: batchesList,
    });
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 2) {
      sort.studentName = order;
    } else if (sortOrder.column === 4) {
      sort.noOfQuestionsSolved = order;
    } else if (sortOrder.column === 5) {
      sort.parentVerified = order;
    } else if (sortOrder.column === 6) {
      sort.createdOn = order;
    } else {
      sort.reportId = order;
    }
    return sort;
  }
  function initDatatable() {
    dataTableInstance = _$(elem_datatable).DataTable({
      // dom: 'flr<"toolbar">tip',
      serverSide: true,
      responsive: false,
      language: {
        processing: "Loading...",
        searchPlaceholder: "Student name",
        sSearch: "",
        lengthMenu: "_MENU_ Study Report/page",
      },
      ajax: function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        var filterObj = { ...getFilterData() }
        filterObj.start = data.start
        filterObj.length = data.length
        filterObj.search = data.search.value
        filterObj.draw = data.draw
        filterObj.sort = data.sort;
        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: 'studyReport.fetchStudyReportList',
            accessToken: localStorage.getItem("accessToken"),
            data: filterObj
          },
          success: function (resData) {
            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data
              }
              if (resData.data.data == 0) {
                emptyState = true
              }
              callback(result);
            }
            else {
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },
      columns: [
        { data: "reportId" },
        {
          data: "studentName",
          render: (data, type, full) => {
            let image;
            // if image found display profilePic avatar
            image = `<img src="${full.profilePic ? full.profilePic : "/images/man.png"
              }" class="avatar mg-r-15" alt="${data[0].toUpperCase()}">`;
            return `
            <div class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0" >
              ${image}
              <div class="wd-80p bg-transparent btn-view-display" data-id="${full._id}">
                <h6 class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent tx-capitalize">${data || "-"
              }</h6>
                <span class="d-block tx-11 text-muted bg-transparent">
                    <i data-feather="phone"></i> 
                    ${full.mobileNo || "-"}
                </span>
              </div>
            </div>`;
          },


        },
        {
          data: "createdOn", render: function (data) {

            let d = convertDateObjToStr(data)
            return `<div class="d-flex justify-content-center">${d}</div>`
          },
        },
        {
          data: "folders",
          orderable: false,
          render: function (data, type, full) {
            if (!data) return "-";
            var htmlStr = `<div class="media ">
                          <div class="media-body ">
                              <h6 class="tx-13 mg-b-3" style="text-transform: capitalize;">${data
                .map((item) => item.name)
                .join(" > ")}</span>
                          </div><!-- media-body -->
                      </div>`;
            return htmlStr;
          },
        },
        {
          data: "noOfQuestionsSolved",
          render: (data) =>
            `<div style="text-align : center">${data
            }</div>`,
          defaultContent: false,

        },
        {
          data: "parentVerified",
          render: (data) =>
            `<div style="text-transform:capitalize; text-align:center;">${data ? "Yes" : "No"
            }</div>`,
          defaultContent: false,
        },
        {
          data: "_id",
          orderable: false,
          render: (data, a, b, c) => {
            var edit = '-'
            if (MODULES.hasAccess(MODULES.VIEW_STUDY_REPORT)) {
              var edit = `<a href="javascript:void(0)" data-id="${data}" class='btn-view mg-r-5' data-toggle="tooltip" data-placement="bottom" title="Edit"><i data-feather="eye" ></i> </a>`
            }
            return `
                    <div class="d-flex justify-content-center">
                      ${edit}
                      <!--a  href="javascript:void(0)"  class='btn-archive mg-r-5' data-id='${data}' data-toggle="tooltip" data-placement="bottom" title="Delete"><i data-feather="trash" ></i> </a-->
                    </div>
                      `;
          },
        },
      ],
      columnDefs: [
        { targets: 0, width: "5%" },
        { targets: 1, width: "25%" },
        { targets: 2, width: "10%" },
        { targets: 3, width: "17%" },
        { targets: 4, width: "15%" },
        { targets: 5, width: "10%" },
        { targets: 6, width: "5%" },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        _$(".btn-view").on("click", function (event) {
          let id = _$(this).data("id");
          // _$(this).tooltip("dispose");
          let route = `/dashboard/reports/study/${id}`;
          navigate(route);
        });

        _$(".btn-view-display").on("click", function (event) {
          let id = _$(this).attr("data-id");
          // _$(this).tooltip("dispose");
          let route = `/dashboard/reports/study/${id}`;
          navigate(route);
        });
        // _$(".btn-archive").on("click", async function (event) {
        //   let id = _$(this).data("id");
        //   // _$(this).tooltip("dispose");
        //   pConfirm(
        //     "Delete user?",
        //     "Are you sure you want to delete this user?",
        //     async function () {
        //       //   delete api_handleApiCalls
        //       let result = await api_delete({ _id: Id });
        //       if (result.status) {
        //         // reload table
        //         pSuccess("", result.data);
        //         reload();
        //       } else {
        //         pError("", result.errMsg);
        //       }
        //     }
        //   );
        // });
        _$("#table tbody").on("click", "tr td", function (e) {
          // responsive expand button clicked
          feather.replace({ width: "1em", height: "1em" });
          _$(".btn-view").text("View");
          _$(".btn-archive").text("Archive");
        });
        // _$('[data-toggle="tooltip"]').tooltip();
      },
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }
  onMount(async () => {
    feather.replace();
    activeMenu.set("studyreport");
    userIdArr = await api_ReportToList()
    batchesList = await api_getBatchesList()
    initDatatable();
    bindSelect2();
  });
</script>

<div class="content-body animated scale">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Study Report
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Study Report</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">List of study report submitted by student</p>
    </div>
    <div class="d-block">

      <button on:click={showHideFilterModal} class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"> <i
          data-feather="filter" class="mg-r-5"></i> Filters</button>

    </div>

  </div>
  <hr class="full-width">
  <div data-label="Example" class="df-example demo-table">
    <table bind:this={elem_datatable} class="table" style="width:100%">
      <thead>
        <tr>
          <th style="text-align: center;">#</th>
          <th>Student </th>
          <th style="text-align: center;">Submitted On</th>
          <th>Category</th>
          <th style="text-align: center;">No. of Questions Solved</th>
          <th style="text-align: center;">Parents Verified</th>
          <th style="text-align: center;">Actions</th>
        </tr>
      </thead>
      <tbody />
    </table>
    {#if emptyState}
    <div class="d-flex align-items-center justify-content-center " style="flex-direction:column;">
      <div class="" style="width:250px">
        <img src="/images/empty.svg" class="img-fluid" alt="No Result" />
        <p class="tx-bold">
          No Study Report found.
        </p>
      </div>

    </div>
    {/if}
  </div>
</div>



<!-- Filter Modal -->
<div class="modal effect-scale" id="filter-modal" bind:this={filterModalThis} tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->

      <hr class="full-width">
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

        <div class="form-group">
          <label class="d-block">Student Name:</label>
          <select disabled={isLoading} bind:this={filterElemUser} class="form-control select2" multiple="multiple">
            <option> Select Student Name</option>
          </select>
        </div>
        <div class="form-group">
          <label class="d-block">Batch name:</label>
          <select disabled={isLoading} bind:this={filterElemBatch} class="form-control select2" multiple="multiple">
            <option>Batch name</option>
          </select>
        </div>
        <div class="form-group">
          <label class="d-block">Questions Solved:</label>
          <input type="text" class="form-control follow-up-date" bind:value={filterByNoOfQuestions}
            placeholder="Questions Solved" />
        </div>
        <div class="form-group">
          <label class="d-block">Parent Verified:</label>
          <div class="form-row">
            <div class="form-group col-md-2 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="sameSubjectYes" value="true" name="sameSubject"
                  bind:group={filterParentVerified} class="custom-control-input">
                <label class="custom-control-label" for="sameSubjectYes">Yes</label>
              </div>
            </div>
            <div class="form-group col-md-2 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="sameSubjectNo" value="false" name="sameSubject"
                  bind:group={filterParentVerified} class="custom-control-input">
                <label class="custom-control-label" for="sameSubjectNo">No</label>
              </div>
            </div>
          </div>
        </div>

        <div class="d-md-flex justify-content-end mg-t-25">
          <button disabled={isLoading} on:click={clearFilter} class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5"
            data-dismiss="modal">CLEAR
            <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button disabled={isLoading} on:click={applyFilter} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
            data-dismiss="modal">
            <ButtonLoader isLoading={isLoading} btnText="APPLY" />
            <i data-feather="filter" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Modal -->
