<script>
    import { Router, Link, links, Route, navigate } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import { activeMenu } from "../stores";
    import CountDownTimer from "../utils/countdownTimer.svelte"

    import { pSuccess, pError } from "../utils/notify.svelte";
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import ButtonLoader from "../utils/button_loader.svelte";
    import CheckTest from "./checkTest.svelte";
    import { testURL } from "../../config.js"

    let testList = []
    let batchIdArr = []
    const init = async () => {
        let obj = await api_getMyPracticeTests();

        testList = obj.data.testList;
        batchIdArr = obj.data.studentBatchIdArr
        activeMenu.set('practiceTest')
        if (Array.isArray(testList)) {
            for (let tIndx = 0; tIndx < testList.length; tIndx++) {
                var aTest = testList[tIndx];
                let tQCounts = 0

                if (Array.isArray(aTest.sections)) {

                    for (let sIdx = 0; sIdx < aTest.sections.length; sIdx++) {
                        const element = aTest.sections[sIdx];
                        tQCounts += element.questions.length
                    }

                }

                aTest.tQCounts = tQCounts
                testList[tIndx] = aTest

            }
        }

        await tick()
        feather.replace({ width: "1em", height: "1em" })

    }
    onMount(init)

    async function api_getMyPracticeTests() {

        let action = "test.getMyPracticeTest";
        var response = await api_handleApiCalls(action, null);
        return response;

    }
    async function api_generateStartToken(data) {

        let action = "test.generateStartToken";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    function testTimer(testStartTime) {

        var testStart = new Date(testStartTime)
        var todaysDate = new Date();

        // todaysDate = new Date(todaysDate.setHours(testStart.getHours(), testStart.getMinutes(), testStart.getSeconds(), 0))


        if (todaysDate >= testStart) {
            return true
        } else {
            return false
        }

        // return 
    }
    let getMomentString = (date, type) => {
        if (type == 'date')
            return moment(new Date(date)).format("DD-MMM-YYYY")
        else if (type == 'time')
            return moment(new Date(date)).format("hh:mm A")
        else return moment(new Date(date)).format("DD-MMM-YYYY, hh:mm A")
    }

    async function startTest(testId) {
        try {
            // Fetch the start token and other data
            let res = await api_generateStartToken({ testId });

            // Set dimensions for the popup window
            let xMax = window.outerWidth - 20;
            let yMax = window.outerHeight - 70;

            // Determine the URL and open the exam window
            let examWindow;
            if (res.data.doneTest) {
                let doneTestId = res.data.doneTest._id;
                let startToken = res.data.startToken || ""; // Use token only if it exists
                let testResultURL = `${testURL}/result/${doneTestId}${startToken ? `/${startToken}` : ""}`;

                examWindow = window.open(
                    testResultURL,
                    '_blank',
                    `scrollbars=yes,width=${xMax},height=${yMax},top=0,left=0,resizable=yes`
                );
            } else {
                let startToken = res.data.startToken || ""; // Handle cases where token might be missing
                let testStartURL = `${testURL}/start/${startToken}`;

                examWindow = window.open(
                    testStartURL,
                    '_blank',
                    `scrollbars=yes,width=${xMax},height=${yMax},top=0,left=0,resizable=yes`
                );
            }

            // Poll to check if the window is closed
            let timer = setInterval(function () {
                if (examWindow.closed) {
                    clearInterval(timer);
                    // Optionally reload the page or trigger an action
                    // window.location.reload();
                }
            }, 500);
        } catch (error) {
            console.error("Error starting test:", error);
            // Handle error (e.g., show a notification or error message)
        }
    }


</script>

<div class="content-body fadeIn" style="position: relative;    padding-bottom: 0;">
    <div class="d-sm-flex  justify-content-between mg-b-15 mg-lg-b-15 mg-xl-b-15">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        My Practice Test
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                My Practice Test
            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
                All practice tests
            </p>
        </div>
        <div class="d-block">
            <!-- <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25"
                on:click={onSaveClicked}
            ><i data-feather="save"
                    class="wd-10 mg-r-5"></i>
                Save
            </button>
            <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase "
            on:click={onPublishClicked}><i data-feather="anchor"
                    class="wd-10 mg-r-5"></i>
                Publish
            </button> -->
            <!-- <button on:click={showHideFilterModal} class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"> <i data-feather="filter" class="mg-r-5"></i> Filters</button> -->
        </div>
    </div>

    <hr class="full-width">

    <div class="card-columns">


        {#each testList as item}
        <div class="card ">


            <div class="card-body">
                <h5 class="card-title">{item.name || ''}</h5>
                <p class="card-text">{item.description || ""}</p>
                <p class="card-text">
                    Test Date/Time: {moment(new Date(item.startDate)).format("DD/MM/YYYY, hh:mm A") }
                </p>
                <!-- {#if item.linkArr.filter(item => item.batchId )}
                <p><span> <i data-feather="folder" class="mg-r-5"></i> </p> -->
                {#each item.linkArr as linkItem , i}
                {#if batchIdArr.indexOf(linkItem.batchId) != -1}
                <p> <span> <i data-feather="folder" class="mg-r-5"></i>{linkItem.batchName} </span> >
                    {#each linkItem.fullPath as childrenLink, i}
                    {#if linkItem.fullPath.length-1 == i }

                    <span class="mg-r-5"> <i data-feather="folder" class="mg-r-5"></i> {childrenLink }</span>

                    {:else}
                    <span class="mg-r-5"> <i data-feather="folder" class="mg-r-5"></i> {childrenLink + " > "}</span>

                    {/if}
                    {/each}
                </p>
                {/if}
                {/each}

            </div>
            <div class="card-footer tx-13">
                <div class="d-flex flex-row">
                    <div class="flex-grow-2 row">
                        <div data-toggle="tooltip" data-placement="left" title="Start Test" data-id="{item._id}">

                            {#if (new Date(item.startDate) > new Date()) }

                            <CountDownTimer startFrom={ new Date(item.startDate).getTime()} onTimeOut={()=>{init();}}>
                                <button slot="container" let:time let:timeStr disabled
                                    class="btn btn-sm btn-secondary btn-uppercase  mg-5">
                                    Starts in {timeStr}
                                    <br />
                                </button>
                            </CountDownTimer>

                            {:else if item.doneTestObj}
                            <button class="btn btn-sm btn-primary btn-uppercase  mg-5"
                                on:click={startTest(item._id)}>View Test Result
                            </button>
                            {:else}
                            <CheckTest onStartBtnClick={startTest} testObj={item} expiryDate={new Date(item.endDate)}
                                checkOnTimeOut={()=>{init();}} />

                                {/if}
                        </div>
                    </div>
                </div>

            </div>
        </div>

        {/each}

    </div>
    {#if !testList.length}
    <div class="wd-100p tx-center align-items-center justify-content-center ">
        <img src="/images/empty.svg" style="width:200px" class="img-fluid" alt="No Result" />
        <p class="tx-bold">No Practice test assigned.</p>

    </div>
    {/if}


</div>
