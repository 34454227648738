<script>
    export let btnText
    export let isLoading
</script>

    {#if isLoading}
        {btnText}
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {:else}
        {btnText}
    {/if}
