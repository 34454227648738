<script>
  import { onMount } from "svelte";
  import { activeMenu } from "../stores";
  import { BACKEND_URL } from "../../config"; // Adjust path if needed
  import ButtonLoader from "../utils/button_loader.svelte"; // Import the loader component

  let students = [];
  let loading = true;
  let error = null;
  let studentDataTable;
  let demoDataTable;
  let emptyState = false;

  // DataTable related variables
  let isLoadingStudents = false;

  // Stats related variables
  let stats = {};
  let isLoadingStats = false;

  onMount(() => {
    activeMenu.set("aptiStudent");
    bindDatatable();
    fetchStudentStats();
    feather.replace();
  });

  // Function to fetch student stats
  async function fetchStudentStats() {
    isLoadingStats = true;
    try {
      const response = await fetch(
        `${BACKEND_URL}/apti-booster/admin/student/stats`,
      );
      const data = await response.json();

      if (data.status) {
        stats = data.data;
      } else {
        console.error("Failed to fetch stats:", data.message);
      }
    } catch (err) {
      console.error("Error fetching student stats:", err);
    } finally {
      isLoadingStats = false;
    }
  }

  // Helper function to format stat keys for display
  function formatStatKey(key) {
    // Convert camelCase or snake_case to Title Case with spaces
    return key
      .replace(/([A-Z])/g, " $1") // Insert space before capital letters
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/^\w/, (c) => c.toUpperCase()) // Capitalize first letter
      .trim();
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;

    if (sortOrder.column === 0) {
      sort.name = order;
    } else if (sortOrder.column === 1) {
      sort.phoneNumber = order;
    } else if (sortOrder.column === 2) {
      sort.schoolBoard = order;
    } else if (sortOrder.column === 3) {
      sort.studentClass = order;
    } else if (sortOrder.column === 4) {
      sort.joiningDate = order;
    } else {
      sort.name = order;
    }
    return sort;
  }

  function bindDatatable() {
    studentDataTable = _$(demoDataTable).DataTable({
      responsive: false,
      stateSave: false, // Changed to false to avoid any state issues
      language: {
        processing: "",
        lengthMenu: "_MENU_ Students/page",
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        info: "Showing _START_ to _END_ of _TOTAL_ entries",
        infoEmpty: "Showing 0 to 0 of 0 entries",
      },
      paging: true,
      pageLength: 10,
      lengthMenu: [10, 25, 50, 100],
      pagingType: "simple_numbers", // Simplified pagination
      searching: false, // Hide the search input
      ajax: function (data, callback, settings) {
        isLoadingStudents = true;
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        var filterObj = {
          page: Math.floor(data.start / data.length) + 1,
          limit: data.length,
          search: data.search.value,
          draw: data.draw,
          sort: data.sort,
        };

        console.log("Requesting data with:", filterObj);

        _$.ajax({
          url: `${BACKEND_URL}/apti-booster/admin/student/`,
          method: "get",
          data: filterObj,
          success: function (resData) {
            isLoadingStudents = false;
            console.log("API Response:", resData);

            var result = [];
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: resData.totalPageCount * data.length, // Calculate total records
                recordsFiltered: resData.totalPageCount * data.length, // Calculate filtered records
                data: paginationData,
                draw: data.draw,
              };

              if (!resData.data.length) {
                emptyState = true;
              } else {
                emptyState = false;
              }
              callback(result);

              console.log("Sending to DataTables:", result);
            } else {
              console.log("Something went wrong");
              emptyState = true;
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
                draw: data.draw,
              });
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            console.error("API Error:", textStatus, errorThrown);
            isLoadingStudents = false;
            emptyState = true;
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
              draw: data.draw,
            });
          },
        });
      },
      columns: [
        {
          data: "name",
          render: function (data, type, full) {
            return `<div>${data || "N/A"}</div>`;
          },
        },
        {
          data: "phoneNumber",
          render: function (data, type, full) {
            return `<div>${data || "N/A"}</div>`;
          },
        },
        {
          data: "schoolBoard",
          render: function (data, type, full) {
            return `<div>${data || "N/A"}</div>`;
          },
        },
        {
          data: "studentClass",
          render: function (data, type, full) {
            return `<div>${data || "N/A"}</div>`;
          },
        },
        {
          data: "joiningDate",
          render: function (data, type, full) {
            return `<div>${new Date(data).toLocaleDateString() || "N/A"}</div>`;
          },
        },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
      },
      processing: true,
      serverSide: true,
    });

    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }
</script>

<div class="content-body fadeIn">
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
  >
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Apti Students
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Apti Student</h4>
      <!-- <p class="tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
        Search or manage student data.
      </p> -->
    </div>
    <!-- <div class="d-block">
      <div class="d-sm-flex">
        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
          <i data-feather="filter" class="wd-10 mg-r-5"></i> Filter
        </button>
        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
          <i data-feather="plus" class="wd-10 mg-r-5"></i> Add Student
        </button>
      </div>
    </div> -->
    <div class="d-block">
      <div class="d-sm-flex">
        {#if isLoadingStats}
          <div class="stats-loader">
            <i data-feather="loader" class="wd-20 mg-r-5 feather-spin"></i> Loading
            stats...
          </div>
        {:else}
          <div class="stats-container">
            {#each Object.entries(stats) as [key, value]}
              <div class="stat-card">
                <div class="stat-title">{formatStatKey(key)}</div>
                <div class="stat-value">{value}</div>
              </div>
            {/each}
          </div>
        {/if}
      </div>
    </div>
  </div>
  <hr class="full-width" />

  <div data-label="Example" class="df-example demo-table">
    <table bind:this={demoDataTable} class="table" style="width:100%">
      <thead>
        <tr>
          <th style="width: 25%;">Name</th>
          <th style="width: 25%;">Mobile Number</th>
          <th style="width: 20%;">School Board</th>
          <th style="width: 20%;">Class</th>
          <th style="width: 20%;">Join Date</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    {#if emptyState}
      <div class="empty-state-img wd-200">
        <img src="/images/empty.svg" style="width: 200px" alt="empty" />
        <p class="tx-bold">No students found</p>
      </div>
    {/if}
  </div>
</div>

{#if isLoadingStudents}
  <div class="loader">Loading students...</div>
{/if}

<style>
  .student-table-container {
    margin-top: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
  }

  .table th {
    background-color: #f8fafc;
    font-weight: 600;
  }

  .table tbody tr:hover {
    background-color: #f1f5f9;
  }

  .loading,
  .error,
  .empty-state {
    padding: 20px;
    text-align: center;
  }

  .error {
    color: #ef4444;
  }

  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 500;
  }

  /* Make sure pagination controls are visible */
  /* :global(.dataTables_paginate) {
    display: flex !important;
    justify-content: center !important;
    margin-top: 15px !important;
    width: 100% !important;
  }

  :global(.paginate_button) {
    padding: 5px 10px !important;
    margin: 0 2px !important;
    cursor: pointer !important;
    border: 1px solid #ddd !important;
    background-color: #f8f8f8 !important;
    border-radius: 3px !important;
    color: #333 !important;
  }

  :global(.paginate_button.current) {
    background-color: #4299e1 !important;
    color: white !important;
    border-color: #4299e1 !important;
  }

  :global(.paginate_button.disabled) {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }

  :global(.dataTables_info) {
    padding: 10px 0 !important;
    text-align: center !important;
  } */

  .stats-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  .stat-card {
    background-color: #0169fb;
    border-radius: 8px;
    padding: 12px 15px;
    min-width: 120px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
  }

  .stat-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  .stat-title {
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
  }

  .stat-value {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
  }

  .stats-loader {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #7987a1;
  }

  .feather-spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
