<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import { activeMenu } from "../../stores";
    import FollowupsList from './followupsList.svelte';
    import { userData } from "../../stores";
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import ButtonLoader from "../../utils/button_loader.svelte"
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import MODULES from "../../utils/modules.js"
    export let _id

    let localUserData = {
        fullName: "...",
        role: "...",
        avatar: "/images/man.png",
        access: {
            home: false,
            students: false,
            settings: false,

        },
    };

    let studentClassId

    const unsubscribe = userData.subscribe((value) => {
        localUserData = value;
    });

    let breadcrumbStudName
    let modalArchiveEnquiry
    let showHideUser = false
    let selectizeResponseInstance
    let stateList = [];
    let cityList = [];
    let batchesList = [];
    let sourcesList = [];
    let responseList = [];
    let counselorUserList = [];
    let elemSelectClass1
    let elemSelectBatchClass
    let elemSelectBatches
    let selectizeInstanceClass1
    let _errorObj = {}
    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    }
    let enquiryObj = {
        _id: _id,
        name: "",
        email: "",
        mobileNo: "",
        dob: "",
        gender: "",
        address: "",
        city: '',
        state: '',
        lastExamName: "",
        marksObtained: "",
        status: "",
        source: "",
        followUpDate: "",
        nextFollowupDate: '',
        batches: [],
        followUpHistory: [],
        counselor: '',
        referredBy: ''
    }

    let newFolloUp_date = ''
    let newFolloUp_response = ''
    let newFolloUp_remark = ''
    let newFolloUp_status = ''
    let nextFolloUpDate = ''
    let followup_nextFollowupTime
    let elem_nextFolloUpTimepicker
    let newFolloUp_action

    let selectedBatches = []
    let input_value_source_other
    let dpicker_followUpDate
    let elem_timePicker
    let nextFollowupTime
    let dpicker_dob
    let todaysDate = ''
    let followupsList = []

    let errorObj = {};
    let elemSelectState
    let elemSelectCity
    let elemResponseSelect
    let elemSelectSchoolCollege
    let elemSelectCounselor
    let isSortByName
    let isLoading = false
    let loadingNextFollowup = false
    let buttonLoading = false
    let loggedInUserId

    const subscribeToStore = userData.subscribe(value => {
        loggedInUserId = value._id
    });
    function api_getCityListByStateId(stateId) {

        switch (stateId) {

            case 'MH':
                return [
                    {
                        "name": "Akola",
                        "state_code": "MH"
                    },
                    {
                        "name": "Aurangabad",
                        "state_code": "MH"
                    },
                ]
                break;

            case 'MP':
                return [
                    {
                        "name": "Bhopal",
                        "state_code": "MH"
                    },
                    {
                        "name": "Indore",
                        "state_code": "MH"
                    },
                ]
                break

            default:
                return []
                break;
        }




    }
    function api_getStateList(stateId) {
        return [

            {
                "id": 4039,
                "name": "Madhya Pradesh",
                "state_code": "MP"
            },
            {
                "id": 4008,
                "name": "Maharashtra",
                "state_code": "MH"
            },
        ]
    }
    async function api_getEnquiryById(_id) {
        var resData = await api_handleApiCalls('enquiries.getEnquiryById', { _id: _id })
        if (resData.status) {

            return resData.data
        } else {

            pError("Error!", resData.errMsg)
            navigate("/dashboard/enquiries");
            return enquiryObj
        }
    }
    // async function api_getBatchesList() {
    //     var resData = await api_handleApiCalls('batch.getMyBatches', {})
    //     if (resData.status) {

    //         batchesList = resData.data
    //     } else {
    //         batchesList = []
    //     }
    // }

    async function api_getBatchesList(data) {
        var resData = await api_handleApiCalls('batch.getMyBatches', data)
        if (resData.status) {
            return resData.data
        } else {
            return []
        }
    }


    async function api_getEnquirySources() {
        var resData = await api_handleApiCalls('enquirySource.fetchAllEnquirySources', {})
        if (resData.status) {

            sourcesList = resData.data.result
        } else {
            sourcesList = []
        }
    }
    async function api_updateEnquiryData(data) {
        buttonLoading = true
        var resData = await api_handleApiCalls('enquiries.create', data)
        buttonLoading = false
        if (resData.status) {
            pSuccess("Success", resData.data);
            setTimeout(() => {
                window.history.back();
            }, 700);
        } else {
            pError("Error", resData.errMsg)
        }
    }
    // async function api_updateEnquiryStatusAndNextFollowUpDate(data, msg) {
    //     var resData = await api_handleApiCalls('enquiries.updateStatus', data)
    //     if (resData.status) {
    //         pSuccess("Success!", (msg + " Updated"))
    //     } else {
    //         pError("Error!", resData.errMsg)
    //     }
    // }

    async function api_getFollowups(_id) {

        let resData = await api_handleApiCalls('enquiries.listFollowups', { _id: _id })
        if (resData.status) {
            followupsList = resData.data ? resData.data : []
        } else {
            followupsList = []
        }
    }
    async function api_convertStudent(data) {
        var response = await api_handleApiCalls('students.create', data)

        if (response.status) {
            closeModalArchiveEnquiry()
            if (response.data.available) {
                pError('Error!', "Duplicate Enquiry found with same mobile number, can't convert!")
            } else {

                pSuccess('Success', 'Enquiry converted successfully!')
                navigate(("/dashboard/student/view/" + response.data._id));
            }
        } else {
            pError('Error', response.errMsg)
            _errorObj.apiError = response.errMsg
        }
    }

    async function api_getClassList(data) {
        var resData = await api_handleApiCalls('instituteClass.fetchInstituteClasses', data)
        if (resData.status) {
            return resData.data
        } else {
            return []
        }
    }



    async function getAllResponse(data) {
        var resData = await api_handleApiCalls('response.getResponse', data)
        if (resData.status) {
            return resData.data
        } else {
            return []
        }

    }
    function getEnquiryData(data) {
        errorObj = {};
        var newErrorObj = {}
        var EMAILREGEX = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        var MOBILENORGEX = RegExp(/^[6-9][0-9]{9}$/);
        let batchClass = _$(elemSelectBatchClass).val();
        let batches = _$(elemSelectBatches).val();


        if (!data.name || !Boolean(data.name)) {
            newErrorObj.name = "Please enter full name is required Field.";
        }


        if (!data.mobileNo || !MOBILENORGEX.test(data.mobileNo)) {
            newErrorObj.mobileNo = "Please enter 10 digit mobile number";
        }
        if (Boolean(data.email)) {
            data.email = data.email.trim().toLowerCase();
            if (!EMAILREGEX.test(data.email)) {
                newErrorObj.email = "You have entered an invalid email address!"
            }
        }
        if (Boolean(data.pincode) && (data.pincode.toString().length > 7)) {
            newErrorObj.pincode = "Please Enter 6 digit pincode"
        }

        errorObj = newErrorObj;


        if (Object.keys(errorObj).length > 0) {
            pError("Error", Object.values(errorObj)[0]);
            return null;
        }


        return {
            name: data.name ? data.name.trim() : data.name,
            email: data.email ? data.email.trim() : data.email,
            mobileNo: data.mobileNo,
            gender: data.gender,
            dob: dpicker_dob,
            referredBy: data.referredBy,
            schoolCollegeId: data.schoolCollegeId,
            lastExamName: data.lastExamName,
            marksObtained: data.marksObtained,

            address: data.address,
            city: data.city,
            state: data.state,
            pincode: data.pincode,

            source: data.source,
            counselor: data.counselor,
            batches: data.batches,
            batchClass: data.batchClass,
            status: data.status,
            nextFollowupDate: dpicker_followUpDate,

        }
    }
    function getValidateEnquiryData(data) {
        var newErrorObj = {}
        var MOBILENORGEX = RegExp(/^[6-9][0-9]{9}$/);
        let demoBatchId = addDemoBatch ? _$(elemDemoBatchesSelect).val() : null;
        let batchClass = _$(elemSelectClass1)[0].selectize.getValue();
        if (!data.name && !Boolean(data.name)) {
            newErrorObj.name = "Please enter full name.";
        }

        if (!data.mobileNo || !MOBILENORGEX.test(data.mobileNo)) {
            newErrorObj.mobileNo = "Please enter 10 digit mobileNo number";
        }

        if (!data._id || !Boolean(data._id)) {
            newErrorObj.apiError = "Enquiry id not found, please try again";
        }

        if (!Boolean(batchClass)) {
            newErrorObj.batchClass = "Please select the class";
        }

        if (addDemoBatch && !Boolean(demoBatchId)) {
            newErrorObj.demoBatchId = "Please select default demo batch";
        }
        _errorObj = newErrorObj
        if (Object.keys(_errorObj).length > 0) {
            return null;
        }
        return { name: data.name, mobileNo: data.mobileNo, enquiryId: data._id, demoBatchId, classId: studentClassId, }
    }
    function getValidatedFollowupData() {
        errorObj = {};
        var newErrorObj = {}

        if (!_id && (Boolean(_id))) {
            newErrorObj.fupServerErrMsg = "Enquiry id is required.";
        }

        if (!newFolloUp_date && (!Boolean(newFolloUp_date))) { newErrorObj.newFolloUp_date = "Please select date."; }
        if (!newFolloUp_response && (!Boolean(newFolloUp_response))) { newErrorObj.newFolloUp_response = "Please select response."; }

        var _nextFolloUpDate
        let dateAndTime = getDateWithTimeNextFollowup(nextFolloUpDate, followup_nextFollowupTime)

        _nextFolloUpDate = new Date(dateAndTime)
        if (!(newFolloUp_action == 'convert' || newFolloUp_action == 'archive')) {
            if (!newFolloUp_status && (!Boolean(newFolloUp_status))) { newErrorObj.newFolloUp_status = "Please select status."; }
            if (!newFolloUp_remark && (!Boolean(newFolloUp_remark))) { newErrorObj.newFolloUp_remark = "Please Enter remark."; }




            if (!dateAndTime) {
                errorObj.nextFolloUpDate = "Select valid next follow-up date & time"

            }




            let _todaysDate = new Date()

            if (!_nextFolloUpDate && (!Boolean(_nextFolloUpDate))) {
                newErrorObj.nextFolloUpDate = "Please select Next follow-up date.";
            }

            if (_nextFolloUpDate.toString().toLowerCase() == 'invalid date') {
                newErrorObj.nextFolloUpDate = "Enter valid next follow-up date & time.";
            }
            if (_todaysDate > _nextFolloUpDate) {
                newErrorObj.nextFolloUpDate = "Next follow-up date & time should not earlier than current date & time"
            }




        }

        errorObj = newErrorObj
        if (Object.keys(errorObj).length > 0) {

            pError("Validation Error!", Object.values(errorObj)[0])
            return null;
        }
        return {
            response: newFolloUp_response,
            status: newFolloUp_status,
            remark: newFolloUp_remark,
            date: newFolloUp_date,
            nextFollowupDate: _nextFolloUpDate,
            action: newFolloUp_action,
            _id: _id
        }

    }
    function openModalArchiveEnquiry() {
        _$(modalArchiveEnquiry).modal('show')
    }
    function closeModalArchiveEnquiry() {
        _$(modalArchiveEnquiry).modal('hide')
    }

    var isWaiting = false;
    function doafter3Sec(callback) {
        if (!isWaiting) {
            isWaiting = true;
            setTimeout(() => {
                isWaiting = false;
                callback()
            }, 1500);
        } else {
        }
    }
    var updateEnquiryNextFollowupDateBtn = async () => {

        doafter3Sec(function () {
            updateEnquiryNextFollowupDate()
        })


    }
    var resetEnquiryNextFollowupDateBtn = async () => {


        if (Boolean(enquiryObj.nextFollowupDate)) {
            let _holdDate = new Date(enquiryObj.nextFollowupDate)
            let timeToSetTimePicker = `${_holdDate.getHours()}:${_holdDate.getMinutes()}`
            _$(elem_timePicker).timepicker("setTime", timeToSetTimePicker);

            dpicker_followUpDate = (Boolean(enquiryObj.nextFollowupDate)) ? formatDate(enquiryObj.nextFollowupDate) : ''
        }

    }
    function getDateWithTimeNextFollowup(date, time) {
        try {

            let thisDate = new Date(date)
            let thisTime = new Date(time)

            // if(thisTime.getHours() == 0) return null
            thisDate.setHours(thisTime.getHours(), thisTime.getMinutes(), 0, 0)

            return thisDate
        } catch (error) {
            return null
        }
    }

    async function updateEnquiryNextFollowupDate() {
        if (loadingNextFollowup) return
        errorObj = {}
        // Left Here

        let dateAndTime = getDateWithTimeNextFollowup(dpicker_followUpDate, nextFollowupTime)

        if (!dateAndTime) {
            return pError("Validation Error!", "Select valid next follow-up date & time")
        }

        let _nextFolloUpDate = new Date(dateAndTime)


        let _todaysDate = new Date()

        if (!_nextFolloUpDate && (!Boolean(_nextFolloUpDate))) {
            newErrorObj.nextFollowupDate = "Please select Next follow-up date.";
            return
        }

        if (_nextFolloUpDate.toString().toLowerCase() == 'invalid date') return pError("Error!", "Enter valid next follow-up date & time.")
        if (_todaysDate > _nextFolloUpDate) return pError("Error!", "Next follow-up date & time should not earlier than current date & time")



        let defualtNextfolloupData = {
            response: "General",
            status: (Boolean(enquiryObj.status) ? enquiryObj.status : '-'),
            remark: "",
            date: new Date().toString(),
            nextFollowupDate: _nextFolloUpDate,
            _id: _id
        }

        loadingNextFollowup = true
        var res = await api_handleApiCalls('enquiries.createFollowup', defualtNextfolloupData)


        if (res.status) {
            pSuccess("Success!", "Next follow-up date updated")
            api_getFollowups(_id)
            var eData = await api_getEnquiryById(_id)
            setEnquiryData(eData)
        } else {
            pError("Error!", res.errMsg)
        }
        loadingNextFollowup = false
    }

    async function api_updateEnquiryStatusAndNextFollowUpDate(data, msg) {
        try {
            console.log("Data sent to API:", data);
            var resData = await api_handleApiCalls('enquiries.updateStatus', data);
            console.log("API response:", resData);

            if (resData.status) {
                pSuccess("Success!", `${msg} Updated`);
            } else {
                pError("Error!", resData.errMsg || "An unexpected error occurred");
            }
        } catch (error) {
            console.error("Caught Error in api_updateEnquiryStatusAndNextFollowUpDate:", error);
            pError("Error!", "Something went wrong. Please try again.");
        }
    }


    function addUpdateEnquiryBtn() {

        let enquiryData = enquiryObj
        enquiryData.state = _$(elemSelectState).val()
        enquiryData.counselor = _$(elemSelectCounselor).val()
        enquiryData.city = (enquiryData.state && enquiryData.state != '') ? _$(elemSelectCity).val() : ''
        enquiryData.schoolCollegeId = _$(elemSelectSchoolCollege).val()
        enquiryData.batchClass = _$(elemSelectBatchClass).val()
        enquiryData.batches = _$(elemSelectBatches).val()

        var enqData = getEnquiryData(enquiryData)
        enqData._id = _id
        if (enqData) {
            api_updateEnquiryData(enqData);
        }

    }

    async function handleClickNewFollowup() {
        if (isLoading) return
        var data = getValidatedFollowupData()
        isLoading = true
        if (data) {
            var res = await api_handleApiCalls('enquiries.createFollowup', data)
            if (res.status) {
                checkResponseActionForConvert(newFolloUp_action)
                clearFollowupData()
                api_getFollowups(_id)
                var eData = await api_getEnquiryById(_id)
                setEnquiryData(eData)
                _$('#modal-new-followup').modal('hide')
            } else {
                errorObj.fupServerErrMsg = res.errMsg
            }
        }
        isLoading = false


    }
    async function proccedConvertEnquiry() {
        // navigate(("students/info/archive-enquiry/" + enquiryId))
        enquiryObj._id = _id
        var enquiryData = getValidateEnquiryData(enquiryObj)

        if (enquiryData) {
            api_convertStudent(enquiryData)
        }
    }
    async function proccedArchiveEnquiry(id) {

        var response = await api_handleApiCalls('enquiries.archive', { _id: _id })
        if (response.status) {
            pSuccess('Success!', response.data)
            navigate("/dashboard/enquiries");
        } else {
            pError('Error!', response.errMsg)
        }
    }

    var handleClickDelete = () => {

        pConfirm("Archive Enquiry", "Do you want to archive this Enquiry", function () {

            proccedArchiveEnquiry(_id)
        })

    }
    async function stateOnchangeHandler(e) {
        let stateId = e.target.value

        cityList = await api_getCityListByStateId(stateId)
    }

    let elementBatchSelect, elementUserSelect
    function checkResponseActionForConvert(action) {
        if (action == 'convert') {
            openModalArchiveEnquiry()
        }
        if (action == 'archive') {
            handleClickDelete()
        }
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');

    }
    function getResponseAndItsActionById(id) {
        let data = responseList.filter(res => res._id === id)
        return data[0]
    }
    async function bindCounselor(selectedId) {
        if (!selectedId) selectedId = loggedInUserId

        let counselorUserList = []
        var resData = await api_handleApiCalls('systemUser.fetchSystemUserList', { noPagination: true, isSortByName: true })
        if (resData.status) counselorUserList = resData.data.data
        await tick()

        var _selectizeCounselor = _$(elemSelectCounselor).selectize({
            preload: true,
            maxItems: 1,
            placeholder: 'Select counselor',
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            items: [selectedId],
            options: counselorUserList,

        });
    }

    async function bindEvents() {

        feather.replace()
        bindResponseSelect2()
        //use select2 add apis

        let thisToday = new Date()
        var thisHours = thisToday.getHours().toString()

        _$(elem_timePicker).timepicker({
            timeFormat: "h:mm p",
            interval: 15,
            dynamic: false,
            dropdown: true,
            scrollbar: true,
            template: "modal",
            // minTime: thisHours,
            change: async function (time) {
                if (time) {
                    nextFollowupTime = new Date(time);
                    // scheduledOn.setHours(nextFollowupTime.getHours(), nextFollowupTime.getMinutes(), 5, 0);
                } else {
                    nextFollowupTime = null;
                    // scheduledOn.setHours(0, 0, 5, 0);
                }
                clearError("nextFollowupTime");
                await tick();

            },
        });

        _$(elem_nextFolloUpTimepicker).timepicker({
            timeFormat: "h:mm p",
            interval: 15,
            dynamic: false,
            dropdown: true,
            scrollbar: true,
            template: "modal",
            // minTime: thisHours,
            change: async function (time) {
                if (time) {
                    followup_nextFollowupTime = new Date(time);
                } else {
                    followup_nextFollowupTime = null;
                }
                clearError("nextFollowupTime");
                await tick();

            },
        });

        _$(elementBatchSelect).select2({ minimumResultsForSearch: Infinity });



        _$('#modal-new-followup').on('show.bs.modal', function (event) {
            var nowDate = new Date();
            newFolloUp_date = formatDate(nowDate)

            var animation = _$(event.relatedTarget).data('animation');
            _$(this).addClass(animation);
        })

        // hide modal with effect
        _$('#modal-new-followup').on('hidden.bs.modal', function (e) {
            errorObj = {}
            _errorObj = {}

            _$(this).removeClass(function (index, className) {
                return (className.match(/(^|\s)effect-\S+/g) || []).join(' ');
            });
        });

        _$(modalArchiveEnquiry).on('show.bs.modal', function (event) {
            var animation = _$(event.relatedTarget).data('animation');
            _$(this).addClass(animation);
        })

        // hide modal with effect
        _$(modalArchiveEnquiry).on('hidden.bs.modal', function (e) {
            _$(this).removeClass(function (index, className) {
                return (className.match(/(^|\s)effect-\S+/g) || []).join(' ');
            });
        });
    }
    async function bindResponseSelect2() {
        responseList = await getAllResponse()
        selectizeResponseInstance = _$(elemResponseSelect).selectize({
            onChange: async function (value, text) {
                let aRes = getResponseAndItsActionById(value)
                if (aRes) {

                    newFolloUp_response = aRes.response;

                    if ((aRes.action == 'convert')) {
                        newFolloUp_status = ''
                        clearError("nextFolloUpDate")
                    } else if ((aRes.action == 'archive')) {
                        newFolloUp_status = ''
                        clearError("nextFolloUpDate")
                    }
                    else {
                        newFolloUp_status = aRes.action
                    }

                    newFolloUp_action = aRes.action
                } else {
                    newFolloUp_response = null;
                    newFolloUp_action = null;
                    newFolloUp_status = ''
                }
                await tick()

            },
            options: responseList.map(function (aRes) {
                return {
                    text: aRes.response,
                    value: aRes._id
                }
            })
        });


    }
    async function clearFollowupData() {
        newFolloUp_date = ''
        newFolloUp_response = ''
        newFolloUp_remark = ''
        newFolloUp_status = ''
        nextFolloUpDate = ''
        newFolloUp_action = null
        _$(elemResponseSelect)[0].selectize.clear();

        await tick()
    }

    function setEnquiryData(data) {

        if (Boolean(data.nextFollowupDate)) {
            let _holdDate = new Date(data.nextFollowupDate)
            let timeToSetTimePicker = `${_holdDate.getHours()}:${_holdDate.getMinutes()}`
            _$(elem_timePicker).timepicker("setTime", timeToSetTimePicker);

        }
        breadcrumbStudName = data.name

        dpicker_followUpDate = (Boolean(data.nextFollowupDate)) ? formatDate(data.nextFollowupDate) : ''

        dpicker_dob = (Boolean(data.dob)) ? formatDate(data.dob) : ''
        selectedBatches = data.batches ? data.batches : []
        var _cityList = api_getCityListByStateId(data.state)
        cityList = _cityList ? _cityList : []
        enquiryObj = data
    }

    let selectizeInstanceCity
    let selectizeInstanceState
    const selectizeUtil = {
        bindState: async (selectedState) => {
            let stateList = []
            var response = await api_handleApiCalls('dropdown.stateList', {})
            if (response.status) stateList = response.data

            var _selectizeState = _$(elemSelectState).selectize({
                preload: true,
                maxItems: 1,
                valueField: "_id",
                labelField: "name",
                searchField: "name",
                placeholder: 'Select state',
                items: selectedState ? [selectedState] : [],
                options: stateList,
                create: true,
                sortField: 'name',
                create: async function (input) {
                    input = (typeof input == 'string') ? input.charAt(0).toUpperCase() + input.slice(1) : null

                    var response = await api_handleApiCalls('dropdown.stateCreate', {
                        name: input
                    })

                    if (response.status) {
                        response = response.data
                        selectizeInstanceState.addOption({ _id: response._id, name: response.name })
                        selectizeInstanceState.refreshOptions()
                        selectizeInstanceState.setValue(response._id)
                    }
                },
                onChange: function (value) {
                    if (!value) return;
                    selectizeUtil.bindCity(value, null)
                }

            });

            selectizeInstanceState = _selectizeState[0].selectize
        },
        bindCity: async (stateId, selectedCity) => {

            var cityListArr = []
            var response = await api_handleApiCalls('dropdown.cityList', { stateId: stateId })
            if (response.status) cityListArr = response.data

            if (selectizeInstanceCity) {
                selectizeInstanceCity.destroy()
            }


            var _selectizeCity = _$(elemSelectCity).selectize({
                preload: true,
                maxItems: 1,
                valueField: "_id",
                labelField: "name",
                placeholder: 'Select city',
                searchField: "name",
                items: selectedCity ? [selectedCity] : [],
                options: cityListArr,
                create: true,
                sortField: 'name',
                onChange: (value) => {
                    if (!value) return
                    enquiryObj.city = value
                },
                create: async function (input) {
                    input = (typeof input == 'string') ? input.charAt(0).toUpperCase() + input.slice(1) : null

                    var response = await api_handleApiCalls('dropdown.cityCreate', {
                        name: input,
                        stateId: stateId
                    })

                    if (response.status) {
                        response = response.data
                        selectizeInstanceCity.addOption({ _id: response._id, name: response.name })
                        selectizeInstanceCity.refreshOptions()
                        selectizeInstanceCity.setValue(response._id)
                    }
                },
            });

            selectizeInstanceCity = _selectizeCity[0].selectize
            if (stateId) selectizeInstanceCity.enable()
            else selectizeInstanceCity.disable()
        },

        bindStateCitySelectize: (state, city) => {
            selectizeUtil.bindCity(state, city)
            selectizeUtil.bindState(state)
        },
        bindSelectSchoolCollege: async (selected) => {
            var selectizeInsanceThis
            var schoolCollegesList = []
            var response = await api_handleApiCalls('dropdown.listSchoolColleges', {})
            if (response.status) schoolCollegesList = response.data

            var _selectizeCity = _$(elemSelectSchoolCollege).selectize({
                preload: true,
                maxItems: 1,
                valueField: "_id",
                labelField: "name",
                placeholder: 'School/College/University',
                searchField: "name",
                items: selected ? [selected] : [],
                options: schoolCollegesList,
                create: true,
                sortField: 'name',
                create: async function (input) {
                    input = (typeof input == 'string') ? input.charAt(0).toUpperCase() + input.slice(1) : null

                    var response = await api_handleApiCalls('dropdown.createSchoolCollege', {
                        name: input,
                    })

                    if (response.status) {
                        response = response.data
                        selectizeInsanceThis.addOption({ _id: response._id, name: response.name })
                        selectizeInsanceThis.refreshOptions()
                        selectizeInsanceThis.setValue(response._id)
                    }
                },
            });

            selectizeInsanceThis = _selectizeCity[0].selectize
        }
    }
    async function bindSelect2s() {

        let resClass1 = await api_getClassList({
            orgId: localUserData.orgId
        });



        selectizeInstanceClass1 = _$(elemSelectClass1).selectize({
            preload: true,
            create: false,
            maxItems: [1],
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: 'Select class',
            plugins: ["remove_button"],
            options: resClass1,
            onChange: async function (value) {
                studentClassId = value
                await initSelectize(value)
            }
        });
    }


    onMount(async () => {
        feather.replace({ width: "1em", height: "1em" });
        activeMenu.set('enquiries')
        if (!(MODULES.hasAccess(MODULES.UPDATE_ENQUIRY))) {
            showHideUser = true
        }
        todaysDate = formatDate(new Date().toString())
        bindEvents()
        initSelectize()
        bindSelect2s()
        unsubscribe()
        stateList = api_getStateList()
        api_getBatchesList()


        api_getEnquirySources()
        if (_id) {
            var eData = await api_getEnquiryById(_id)
            initClassSelectize(eData.batchClass)
            if (eData.batches) initBatchSelectize(eData.batches)
            else initBatchSelectize([])
            selectizeUtil.bindStateCitySelectize(eData.state, eData.city)
            selectizeUtil.bindSelectSchoolCollege(eData.schoolCollegeId)
            setEnquiryData(eData)
            bindCounselor(eData.counselor)
        } else {
            selectizeUtil.bindStateCitySelectize()
            selectizeUtil.bindSelectSchoolCollege()
            bindCounselor()
        }

        api_getFollowups(_id)

    });

    let getDemoBatches = async function (classId) {

        var response = await api_handleApiCalls('batch.getDemoBatches', { batchClass: classId })

        if (response.status) {
            return response.data
        } else {
            return []
        }
    }

    let addDemoBatch = true
    let elemDemoBatchesSelect
    let selectizeDemoBatches


    const initClassSelectize = async (selectedClass) => {
        let resClass = await api_getClassList({
            orgId: localUserData.orgId
        });
        _$(elemSelectBatchClass).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            items: [selectedClass],
            searchField: "name",
            persist: false,
            placeholder: 'Select class',
            options: resClass,
            onChange: () => {
            }
        });
    }

    const initBatchSelectize = async (selectedBatch) => {
        let resClass = await api_getBatchesList({
            orgId: localUserData.orgId
        });
        if (_$(elemSelectBatches).selectize()) _$(elemSelectBatches).selectize()[0].selectize.destroy();

        _$(elemSelectBatches).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            items: [...selectedBatch],
            searchField: "name",
            persist: false,
            placeholder: 'Select Batch',
            plugins: ["remove_button"],
            options: resClass,
        });
    }

    async function initSelectize(classId) {
        let demoBatches = await getDemoBatches(classId)

        if (_$(elemDemoBatchesSelect).selectize()) _$(elemDemoBatchesSelect).selectize()[0].selectize.destroy();

        selectizeDemoBatches = _$(elemDemoBatchesSelect).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            persist: false,
            items: demoBatches[0] ? [demoBatches[0]._id] : [],
            options: demoBatches,
        });

    }


</script>

<style>
    /* ###### 4.6 Parsley ###### */
    input.parsley-error,
    input.parsley-error:focus,
    textarea.parsley-error,
    textarea.parsley-error:focus {
        box-shadow: none !important;
        border-color: #dc3545;
    }

    input.parsley-success,
    input.parsley-success:focus,
    textarea.parsley-success,
    textarea.parsley-success:focus {
        box-shadow: none !important;
        border-color: #10b759;
    }

    .parsley-checkbox.parsley-error,
    .parsley-checkbox.parsley-success {
        display: inline-block;
        padding: 10px;
        border-radius: 0.25rem;
    }

    .parsley-checkbox.parsley-error {
        border: 1px solid #dc3545;
    }

    .parsley-checkbox.parsley-success {
        border: 1px solid #10b759;
    }

    .custom-select.parsley-error,
    .custom-select.parsley-success {
        box-shadow: none !important;
    }

    .custom-select.parsley-error {
        border-color: #dc3545;
    }

    .custom-select.parsley-success {
        border-color: #10b759;
    }

    .parsley-errors-list {
        color: #dc3545;
        list-style: none;
        font-size: 12px;
        padding: 0;
        margin-bottom: -12px;
    }

    .parsley-errors-list li {
        margin: 5px 0 0;
    }
</style>




<!-- Modal -->
<div class="modal fade" bind:this={modalArchiveEnquiry} tabindex="-1" role="dialog" aria-labelledby="convert-enquiry"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body pd-20 pd-sm-30">
                <h4 class="tx-16 tx-bold mg-b-3">Do you want to convert Enquiry to Student</h4>
                <p class="mg-b-20 tx-13 tx-color-03">This will archive current Enquiry</p>
                <a href="" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>

                <div class="bd">
                    <ul class="list-unstyled media-list tx-12 tx-sm-13 mg-b-0">
                        <li class="media bg-ui-01 pd-y-10 pd-x-15">
                            <div class="avatar"><span class="avatar-initial rounded-circle bg-gray-600">{enquiryObj.name
                                    ? enquiryObj.name.charAt(0) : 'A'}</span></div>
                            <div class="media-body mg-l-15">
                                <h6 class="tx-13 mg-b-2">{enquiryObj.name}</h6>
                                <span class="d-block tx-color-03"><i class="mg-r-5"
                                        data-feather="phone-call"></i>{enquiryObj.mobileNo}</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="animated bounce mg-t-15">
                    <label class="d-block">Class/Stream <span class="required-field">*</span></label>
                    <select bind:this={elemSelectClass1} class:parsley-error={errorObj.batchClass}>
                        <option>Select Class</option>
                    </select>
                    {#if _errorObj.batchClass}
                    <div class="parsley-errors-list">{_errorObj.batchClass}</div>
                    <!-- content here -->
                    {/if}
                </div>

                <div class="mg-t-15">
                    <div class="custom-control custom-checkbox ">
                        <input type="checkbox" bind:checked="{addDemoBatch}" on:click={()=> {
                        if(addDemoBatch) {_$(elemDemoBatchesSelect)[0].selectize.disable();}
                        else{_$(elemDemoBatchesSelect)[0].selectize.enable();}
                        }
                        }
                        class="custom-control-input" id="idAddDemoBatch">
                        <label class="custom-control-label" for="idAddDemoBatch">Add to demo batch</label>
                    </div>

                </div>
                <div class="animated bounce mg-t-15">

                    <label class="d-block">Select default demo batch to assign <span
                            class="required-field">{addDemoBatch? '*': ''}</span></label>
                    <select class:parsley-error={_errorObj.demoBatchId} bind:this={elemDemoBatchesSelect} class="">
                    </select>
                    {#if _errorObj.demoBatchId}
                    <div class="parsley-errors-list">{_errorObj.demoBatchId}</div>
                    <!-- content here -->
                    {/if}
                </div>

                <div class="d-flex justify-content-end tx-13 mg-t-30">
                    <button type="button" class="btn btn-white" data-dismiss="modal">Cancel</button>
                    <button type="button" disabled={isLoading} on:click={proccedConvertEnquiry}
                        class="btn btn-primary mg-l-5">
                        <ButtonLoader isLoading={isLoading} btnText="Proceed" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

<!-- Modal -->
<div class="modal fade" id="modal-new-followup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel6"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header">
                <span class="tx-color-03 d-none d-sm-block"><i data-feather="chevrons-up"
                        class="wd-60 ht-60"></i></span>
                <div class="d-flex flex-column mg-l-15">
                    <h4 class="mg-b-0 tx-spacing--1">Follow ups</h4>
                    <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Add new follow up schedule.</p>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="form-row">
                    <div class="form-group hasDatepicker col-md-6">
                        <label class="d-block">Date<span class="tx-danger">*</span>:</label>
                        <input disabled type="date" bind:value={newFolloUp_date} class="form-control"
                            class:parsley-error={errorObj.newFolloUp_date} />
                        {#if errorObj.newFolloUp_date}
                        <div class="parsley-errors-list">{errorObj.newFolloUp_date}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                    <div class="form-group col-md-6">
                        <label class="d-block">Response<span class="tx-danger">*</span>:</label>
                        <select bind:this={elemResponseSelect} class="">
                            <option>Select response</option>
                        </select>
                        {#if errorObj.newFolloUp_response}
                        <div class="parsley-errors-list">{errorObj.newFolloUp_response}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                    <div class="form-group hasDatepicker col-md-6">
                        <label class="d-block">Next Follow Up Date
                            {#if !(newFolloUp_action == 'convert' || newFolloUp_action == 'archive')}
                            <span class="tx-danger">*</span>
                            {/if}:
                        </label>

                        <div class="d-flex">
                            <div>
                                <input type="date" onkeydown="return false" min={newFolloUp_date}
                                    bind:value={nextFolloUpDate} class="form-control"
                                    class:parsley-error={errorObj.nextFolloUpDate} />

                            </div>
                            <div class="mg-l-8">
                                <input bind:this={elem_nextFolloUpTimepicker} on:keyup={(e)=> e.key === 'Enter' &&
                                submitForm(e)}
                                type="text"
                                onkeydown="return false"
                                class="form-control first-input"
                                class:parsley-error={errorObj.nextFolloUpDate}
                                placeholder="Select time" />
                            </div>
                        </div>
                        <div>
                            {#if errorObj.nextFolloUpDate}
                            <div class="parsley-errors-list">{errorObj.nextFolloUpDate}</div>
                            <!-- content here -->
                            {/if}
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="d-block">Status
                            {#if !(newFolloUp_action == 'convert' || newFolloUp_action == 'archive')}
                            <span class="tx-danger">*</span>
                            {/if}:
                        </label>
                        <div class="d-flex mg-t-20">
                            <div class="custom-control custom-radio mg-r-10">
                                <input type="radio" id="newFolloUpstatus-hot" bind:group={newFolloUp_status}
                                    class="custom-control-input" value='hot' disabled={showHideUser}>
                                <label class="custom-control-label" for="newFolloUpstatus-hot">Hot</label>
                            </div>

                            <div class="custom-control custom-radio mg-r-10">
                                <input type="radio" bind:group={newFolloUp_status} id="newFolloUpstatus-warm"
                                    class="custom-control-input" value='warm' disabled={showHideUser}>
                                <label class="custom-control-label" for="newFolloUpstatus-warm">Warm</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" bind:group={newFolloUp_status} id="newFolloUpstatus-cold"
                                    class="custom-control-input" value='cold' disabled={showHideUser}>
                                <label class="custom-control-label" for="newFolloUpstatus-cold">Cold</label>
                            </div>

                        </div>
                        {#if errorObj.newFolloUp_status}
                        <div class="parsley-errors-list">{errorObj.newFolloUp_status}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                    <div class="form-group col-md-12">
                        <label class="d-block">Remarks
                            {#if !(newFolloUp_action == 'convert' || newFolloUp_action == 'archive')}
                            <span class="tx-danger">*</span>
                            {/if}:
                        </label>
                        <textarea class="form-control"
                            on:blur={()=>clearError("newFolloUp_remark")} bind:value={newFolloUp_remark} rows="4" placeholder="Remarks" class:parsley-error={errorObj.newFolloUp_remark}></textarea>
                        {#if errorObj.newFolloUp_remark}
                        <div class="parsley-errors-list">{errorObj.newFolloUp_remark}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                    <div class="form-group col-md-12">
                        {#if errorObj.fupServerErrMsg}
                        <div class="parsley-errors-list">{errorObj.fupServerErrMsg}</div>
                        <!-- content here -->
                        {/if}
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Cancel</button>
                <button type="button" disabled={isLoading} on:click={handleClickNewFollowup}
                    class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                    <i data-feather="save"></i>
                    <ButtonLoader isLoading={isLoading} btnText="Save" />
                </button>
            </div>

        </div>
    </div>
</div>
<!-- Modal -->


<div class="content-body fadeIn">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/enquiries">Enquiries</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Update</li>
                    <li class="breadcrumb-item" aria-current="page">
                        {breadcrumbStudName?breadcrumbStudName:'-'}
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1 tx-uppercase">
                {breadcrumbStudName?breadcrumbStudName:'-'}

            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">Update Enquiry Details</p>
        </div>
        <div class="d-block">
            <div class="d-lg-flex">
                {#if MODULES.hasAccess(MODULES.CONVERT_ENQUIRY)}
                <button on:click={openModalArchiveEnquiry}
                    class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-t-5 mg-l-5" disabled={isLoading}>
                    Convert To Student <i data-feather="external-link" class="wd-10 mg-l-5"></i>
                </button>
                {/if}
                {#if MODULES.hasAccess(MODULES.ARCHIVE_ENQUIRY)}
                <button class="btn btn-sm mg-t-5 pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={handleClickDelete}
                    disabled={isLoading}>
                    Archive<i data-feather="archive" class="wd-10 mg-l-5 "></i>
                </button>
                {/if}
                {#if MODULES.hasAccess(MODULES.UPDATE_ENQUIRY)}
                <button on:click={addUpdateEnquiryBtn}
                    class="btn btn-sm mg-t-5 pd-x-15 btn-primary btn-uppercase mg-l-5" disabled={isLoading}>
                    <ButtonLoader isLoading={buttonLoading} btnText="Save" />
                    <i data-feather="save" class="wd-10 mg-l-5"></i>
                </button>
                {/if}
            </div>
        </div>

    </div>
    <hr class="full-width">
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div class="row row-sm mg-b-20">
            <div class="col-md-6">
                <div class="row row-sm mg-b-20">

                    <div class="col-sm-12">

                        <h4 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0"> Personal Details </h4>
                        <hr style="margin-top: 5px;">
                        <div class="form-row">

                            <div class="form-group col-md-12">
                                <label class="d-block">Full Name<span class="tx-danger">*</span></label>
                                <input bind:value={enquiryObj.name} type="text" maxlength="50" class="form-control"
                                    class:parsley-error={errorObj.name} placeholder="Enter full name" />
                                {#if errorObj.name}
                                <div class="parsley-errors-list">{errorObj.name}</div>
                                <!-- content here -->
                                {/if}
                            </div>

                            <div class="form-group col-md-6">
                                <label class="d-block">Email</label>
                                <input type="email" bind:value={enquiryObj.email} class="form-control"
                                    class:parsley-error={errorObj.email} placeholder="Enter email address" />
                                {#if errorObj.email}
                                <div class="parsley-errors-list">{errorObj.startDate}</div>
                                <!-- content here -->
                                {/if}
                            </div>

                            <div class="form-group col-md-6">
                                <label class="d-block">Mobile<span class="tx-danger">*</span></label>
                                <input type="tel" maxlength="10" bind:value={enquiryObj.mobileNo} class="form-control"
                                    class:parsley-error={errorObj.mobileNo} placeholder="Enter mobile number" />
                                {#if errorObj.mobileNo}
                                <div class="parsley-errors-list">{errorObj.mobileNo}</div>
                                <!-- content here -->
                                {/if}
                            </div>

                            <div class="form-group col-md-6">
                                <label class="d-block">Gender</label>
                                <div class="d-flex flex-row mg-t-20">
                                    <div class="custom-control custom-radio mg-r-5">
                                        <input type="radio" id="customRadio1" bind:group={enquiryObj.gender}
                                            class="custom-control-input" value='M'>
                                        <label class="custom-control-label" for="customRadio1">Male</label>
                                    </div>

                                    <div class="custom-control custom-radio mg-r-5">
                                        <input type="radio" bind:group={enquiryObj.gender} id="customRadio2"
                                            class="custom-control-input" value='F'>
                                        <label class="custom-control-label" for="customRadio2">Female</label>
                                    </div>

                                    <div class="custom-control custom-radio">
                                        <input type="radio" bind:group={enquiryObj.gender} id="customRadio3"
                                            class="custom-control-input" value='O'>
                                        <label class="custom-control-label" for="customRadio3">Other</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group hasDatepicker col-md-6">
                                <label class="d-block">Date of birth</label>
                                <input type="date" bind:value={dpicker_dob} class="form-control"
                                    class:parsley-error={errorObj.dob} onkeydown="return false" />
                                {#if errorObj.dob}
                                <div class="parsley-errors-list">{errorObj.dob}</div>
                                <!-- content here -->
                                {/if}
                            </div>

                        </div>

                    </div>

                </div>



                <div class="row row-sm mg-b-20">
                    <div class="col-sm-12">

                        <h4 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0"> Address Details </h4>
                        <hr style="margin-top: 5px;">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="d-block">Address</label>
                                <input bind:value={enquiryObj.address} type="text" class="form-control"
                                    class:parsley-error={errorObj.address} placeholder="Enter your Address" />
                                {#if errorObj.address}
                                <div class="parsley-errors-list">{errorObj.address}</div>
                                <!-- content here -->
                                {/if}
                            </div>

                            <div class="form-group col-md-6">
                                <label for="state" class="d-block">State</label>
                                <select bind:this={elemSelectState}>
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="city" class="d-block">City</label>
                                <select bind:this={elemSelectCity}>
                                </select>

                            </div>

                            <div class="form-group col-md-6">
                                <label class="d-block">Pincode</label>
                                <input type="number" bind:value={enquiryObj.pincode} class="form-control"
                                    class:parsley-error={errorObj.pincode} placeholder="Enter pincode number" />
                                {#if errorObj.pincode}
                                <div class="parsley-errors-list">{errorObj.pincode}</div>
                                <!-- content here -->
                                {/if}
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row row-sm mg-b-20">
                    <div class="col-sm-12">

                        <h4 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0"> Educational Details</h4>
                        <hr style="margin-top: 5px;">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="d-block">School/College/University</label>
                                <select bind:this="{elemSelectSchoolCollege}"></select>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="d-block">Last conducted exam</label>
                                <input type="email" bind:value={enquiryObj.lastExamName} class="form-control"
                                    class:parsley-error={errorObj.lastExamName}
                                    placeholder="Enter last conducted exam name" />
                                {#if errorObj.lastExamName}
                                <div class="parsley-errors-list">{errorObj.lastExamName}</div>
                                <!-- content here -->
                                {/if}
                            </div>
                            <div class="form-group col-md-6">
                                <label class="d-block">Marks</label>
                                <input type="text" bind:value={enquiryObj.marksObtained} class="form-control"
                                    placeholder="Obtained Marks" aria-label="Recipient's username"
                                    class:parsley-error={errorObj.marksObtained} aria-describedby="basic-addon2">
                                {#if errorObj.marksObtained}
                                <div class="parsley-errors-list">{errorObj.marksObtained}</div>
                                <!-- content here -->
                                {/if}
                            </div>
                        </div>


                    </div>
                </div>


                <div class="row row-sm mg-b-20">
                    <div class="col-sm-12">
                        <h4 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Enquiry Details</h4>
                        <hr style="margin-top: 5px;">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="d-block">Source:</label>
                                {#each sourcesList as source}
                                <div class="d-md-flex flex-wrap mg-t-20">
                                    <div class="custom-control custom-radio mg-r-5">
                                        <input type="radio" id={source._id} bind:group={enquiryObj.source}
                                            class="custom-control-input" value={source._id}>
                                        <label class="custom-control-label" for={source._id}>{source.name}</label>
                                    </div>
                                </div>
                                {:else}
                                <span class="tx-danger">(Please add new sources)</span>
                                {/each}
                            </div>

                            <!-- <div class="form-group col-md-12">
                                        <label class="d-block">Assign Counselor:</label>
                                        <select bind:value={enquiryObj.counselor} class="custom-select">
                                            <option selected>Select counselor</option>
                                            {#each counselorUserList as user}
                                            <option value="{user._id}">{user.name}</option>
                                            {/each}
                                        </select>
                                    </div> -->
                            <div class="col-md-12">
                                <label class="d-block">Class/Stream <span class="required-field">*</span></label>
                                <select bind:this={elemSelectBatchClass} class:parsley-error={errorObj.batchClass}>
                                    <option>Select Class</option>
                                </select>
                            </div>


                            <div class="form-group col-md-12">
                                <label class="d-block">Batch</label>
                                <select disabled={isLoading} bind:this={elemSelectBatches} class="">
                                    <option>Select batches</option>
                                </select>
                            </div>
                            <div class="form-group col-md-12">
                                <label class="d-block">Referred By</label>
                                <input bind:value={enquiryObj.referredBy} type="text" class="form-control"
                                    class:parsley-error={errorObj.referredBy} placeholder="Enter Name" />
                                {#if errorObj.referredBy}
                                <div class="parsley-errors-list">{errorObj.referredBy}</div>
                                <!-- content here -->
                                {/if}
                            </div>
                            <!-- <div class="col-md-12 mt-4">
                                        <label class="d-block">Enquiry for batches: </label>
                                        <div class="d-md-flex flex-wrap mg-t-5">
                                            {#each batchesList as batch}
                                                <div class="custom-control custom-checkbox mg-r-20">
                                                    <input type="checkbox" bind:group={selectedBatches} value={batch._id} class="custom-control-input" id={batch._id}>
                                                    <label class="custom-control-label" for={batch._id}>{batch.name}</label>
                                                </div>
                                            {/each}
                                        </div>
                                    </div> -->

                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-6">

                <div class="form-group row">
                    <div class="form-group col-md-6">
                        <label class="d-block mg-r-10">Status :</label>
                        <div class="d-flex flex-row mg-t-20">
                            <div class="custom-control custom-radio mg-r-10">
                                <input type="radio" id="status-hot" bind:group={enquiryObj.status}
                                    on:blur={()=>{updateEnquiryStatus(false)}} class="custom-control-input" value='hot'>
                                <label class="custom-control-label" for="status-hot">Hot</label>
                            </div>

                            <div class="custom-control custom-radio mg-r-10">
                                <input type="radio" bind:group={enquiryObj.status} id="status-warm"
                                    on:blur={()=>{updateEnquiryStatus(false)}} class="custom-control-input"
                                value='warm'>
                                <label class="custom-control-label" for="status-warm">Warm</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" bind:group={enquiryObj.status} id="status-cold"
                                    on:blur={()=>{updateEnquiryStatus(false)}} class="custom-control-input"
                                value='cold'>
                                <label class="custom-control-label" for="status-cold">Cold</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-md-6">
                        <label class="d-block">Assign Counselor<span class="required-field">*</span></label>
                        <select bind:this={elemSelectCounselor}>
                        </select>
                        {#if errorObj.counselor}
                        <div class="parsley-errors-list">{errorObj.counselor}</div>
                        <!-- content here -->
                        {/if}
                    </div>
                </div>

                <div class="form-group hasDatepicker">
                    <label class="d-block">Next follow up date</label>
                    <div class="d-flex">
                        <div>
                            <input type="date" min={todaysDate} bind:value={dpicker_followUpDate}
                                class="form-control follow-up-date" class:parsley-error={errorObj.followUpDate}
                                placeholder="Select follow up date" onkeydown="return false" />
                            {#if errorObj.nextFollowupDate}
                            <div class="parsley-errors-list">{errorObj.nextFollowupDate}</div>
                            <!-- content here -->
                            {/if}
                        </div>
                        <div class="mg-l-8">
                            <input bind:this={elem_timePicker} on:keyup={(e)=> e.key === 'Enter' && submitForm(e)}
                            type="text"
                            class="form-control first-input"
                            class:parsley-error={errorObj.nextFollowupTime}
                            placeholder="Enter time" onkeydown="return false" />

                            {#if errorObj.nextFollowupTime}
                            <div class="parsley-errors-list">{errorObj.nextFollowupTime}</div>
                            {/if}
                        </div>
                        <div class="d-flex mg-l-8">
                            <button disabled={loadingNextFollowup } class="btn btn-xs btn-primary"
                                on:click="{updateEnquiryNextFollowupDate}"> <i data-feather="check-circle"></i>

                            </button>
                            <button class="btn btn-xs btn-white bg-white mg-l-8"
                                on:click="{resetEnquiryNextFollowupDateBtn}"><i data-feather="x-circle"></i></button>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 pd-0">
                    <FollowupsList showHeader={true} arrList={followupsList} />
                </div>

            </div>
        </div>
    </div>



    <div class="d-sm-flex align-items-center justify-content-end ">
        <div class="d-block">
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i data-feather="mail"
                        class="wd-10 mg-r-5"></i> Email</button>
                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><i data-feather="printer"
                        class="wd-10 mg-r-5"></i> Print</button> -->
            {#if MODULES.hasAccess(MODULES.CONVERT_ENQUIRY) }
            <button on:click={openModalArchiveEnquiry}
                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-t-5 mg-l-5" disabled={isLoading}>
                Convert To Student <i data-feather="external-link" class="wd-10 mg-l-5"></i>
            </button>
            {/if}
            {#if MODULES.hasAccess(MODULES.ARCHIVE_ENQUIRY)}
            <button class="btn btn-sm mg-t-5 pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={handleClickDelete}
                disabled={isLoading}>
                Archive <i data-feather="archive" class="wd-10 mg-l-5 "></i>
            </button>
            {/if}
            {#if MODULES.hasAccess(MODULES.UPDATE_ENQUIRY)}
            <button on:click={addUpdateEnquiryBtn} class="btn btn-sm mg-t-5 pd-x-15 btn-primary btn-uppercase mg-l-5"
                disabled={isLoading}>
                <ButtonLoader isLoading={buttonLoading} btnText="Save" />
                <i data-feather="save" class="wd-10 mg-l-5"></i>
            </button>
            {/if}
        </div>
    </div>





</div>
