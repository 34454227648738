<script>
  import { Router, Link, links, link, Route } from "svelte-routing";
  import { onMount, afterUpdate, tick } from "svelte";
  import moment from "moment";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import GeneralSettings from "./general/index.svelte";
  import SocialSettings from "./social/index.svelte";
  import Points from "./points/index.svelte";
  import IntegrationSettings from "./integration/index.svelte";
  import BankSettings from "./bank/index.svelte";
  import { pSuccess, pError, pConfirm } from "../utils/notify.svelte";
  import ImageUploader from "../utils/imageUploader.svelte";
  import { hasOptedForPoints } from "../../orgAccessControl.js";
  import { userData } from "../stores/index.js";
  export let tab;
  let pageTitle, orgLogo, orgLogoRefreshTimeStamp, hasOrgLogo, isLoading;
  let ORG_CODE, ORG_NAME, UPDATED_ON, CREATED_ON;
  function tabSwitched() {
    switch (tab) {
      case "social":
        pageTitle = "Social Settings";
        break;
      case "points":
        if (isPoints) pageTitle = "Points Settings";
        break;
      case "integration":
        pageTitle = "Integration Settings";
        break;
      case "general":
        pageTitle = "General Settings";
        break;
      default:
        pageTitle = "General Settings";
        break;
    }
  }
  async function handleUpdateLogoURL() {
    const formData = new FormData();
    let originalFileName = file.name;
    let _fileName = file.name.split(".");
    var fileExtension = _fileName[_fileName.length - 1];
    var fileSize = file.size;
    var res = await api_handleApiCalls('storage.getUrlForUpload', {
      name: originalFileName,
      type: file.type,
      uploadType: "APP_LOGO",
    });
    if (res.status) {
      _$.ajax({
        beforeSend: function (xhr) {
          xhr.setRequestHeader("x-amz-acl", "public-read");
        },
        xhr: function () {
          var xhr = new window.XMLHttpRequest();
          xhr.upload.addEventListener(
            "progress",
            function (evt) {
              var percentComplete = (evt.loaded / evt.total) * 100;
            },
            false
          );
          return xhr;
        },
        url: res.data.url,
        type: "PUT",
        data: orgLogo,
        dataType: "text",
        cache: false,
        contentType: file.type,
        processData: false,
      })
        .done(async function (resss) {
          // await api_handleApiCalls('studyMaterial.updatePdfFileUrl', {
          //   originalFileName: originalFileName,
          //   fileExtension: fileExtension,
          //   fileSize: fileSize,
          //   url: res.data.url,
          // });
          // console.log("asdfasd : ", resss);
          // console.info("YEAH", res.data.url.split("?")[0].substr(6));
          let logoUrl = res.data.url.split("?")[0].substr(6);
          setLogo(logoUrl);
        })
        .fail(function (err) {
          console.error("damn...", err);
        });
    } else {
      // todo show erro toast
    }
    return {
      abort: () => {
        // This function is entered if the user has tapped the cancel button
        // request.abort();
        // Let FilePond know the request has been cancelled
        // abort();
        console.log("aborting...");
      },
    };
  }
  async function setLogo(value) {
    let result = await api_handleApiCalls("settings.setLogo", { value });
    if (result.status) {
      pSuccess("", result.data);
      fetchOrgInfo();
    } else {
      pError("", result.errMsg);
    }
  }
  async function fetchOrgInfo() {
    let result = await api_handleApiCalls("settings.fetchOrgInfo", {});
    if (result.status) {
      ORG_NAME = result.data.name;
      ORG_CODE = result.data.orgCode;
      hasOrgLogo = Boolean(result.data.LOGO_URL)
      orgLogo = hasOrgLogo ? "https:" + result.data.LOGO_URL : "/images/man.png";
      orgLogoRefreshTimeStamp = hasOrgLogo ? (new Date().getTime()).toString() : ''

      CREATED_ON = result.data.createdOn;
      UPDATED_ON = result.data.updatedOn;
      await tick()
    } else {
      orgLogo = "/images/man.png"
      orgLogoRefreshTimeStamp = ''
    }
  }
  let isPoints = false
  const unsubscribe = userData.subscribe(value => {
    isPoints = hasOptedForPoints(value)
  });
  onMount(function () {
    fetchOrgInfo()
    tabSwitched();
    unsubscribe();
  });
  afterUpdate(tabSwitched);
</script>

<div class="content-body animated scale">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item"><a href="./{tab || " general"}">Settings</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {pageTitle || ''}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Settings</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Manage organization general, social setting.
      </p>
    </div>
  </div>
  <hr class="full-width">
  <ul class="list-group">
    <li class="list-group-item">
      <!-- style="background-image: url('{orgLogo || ''}'); background-size: cover; background-repeat: no-repeat;" -->
      <div class="list-group-item" style="background: #ffffffcf">
        {#if isLoading}
        <div class="mg-20 pd-10">
          <div class="placeholder-paragraph">
            <div class="line" />
          </div>
        </div>
        {:else}
        <!-- Logo -->
        <div class="d-sm-flex wd-100p">
          <div class="d-block mg-r-10">
            <ImageUploader outputType="blob" shape='square' bind:profilePic={orgLogo}
              on:updatePic={handleUpdateLogoURL}>
              <div slot="upload-container" let:src let:openCroppie={croppieOpen} let:openCamera={cameraOpen}>
                <div id="contactAvatar" class="pos-relative float-left">
                  <div class="avatar avatar-xl"><img class="rounded"
                      src={`${orgLogo}?timestamp=${orgLogoRefreshTimeStamp}`} alt="org-logo" /></div>
                  <a href="#" on:click|preventDefault class="contact-edit-photo" data-toggle="dropdown"
                    title="Upload Photo"><i data-feather="edit-2" /></a>
                  <ul class="dropdown-menu filterByDropdown">
                    <li class="dropdown-item" on:click={croppieOpen}>
                      <i data-feather="image" class="mg-r-5" />Uplaod Image
                    </li>
                    <li class="dropdown-item" on:click={cameraOpen}>
                      <i data-feather="camera" class="mg-r-5" />Take Picture
                    </li>
                  </ul>
                </div>
              </div>
            </ImageUploader>
          </div>
          <div class="d-block">
            <h4 class="mg-b-0 tx-spacing--1">
              {ORG_NAME || ""}
              <span class="badge badge-light mg-b-0 tx-spacing--1 mg-x-5">{ORG_CODE || ""}</span>
            </h4>
            {#if Boolean(UPDATED_ON)}
            <span class="text-muted">last updated
              {moment(UPDATED_ON).fromNow()}</span>
            {:else if Boolean(CREATED_ON)}
            <span class="text-muted">created
              {moment(CREATED_ON).fromNow()}</span>
            {/if}
          </div>
        </div>
        <!-- <div class="d-sm-flex align-items-center" style="justify-content: space-between">
          <div class="d-block align-self-end" style="opacity: 0.6"><img alt="institute logo" src="{orgLogo}" style="height: 72px;"></div>
        </div> -->
        {/if}
      </div>
    </li>
  </ul>
  <!-- tabs start -->
  <ul class="nav nav-line" id="myTab" role="tablist">
    <li class="nav-item">
      <a class="nav-link" id="general-tab" class:active={tab=='general' } data-toggle="tab" href="general" use:link
        role="tab" aria-controls="general" aria-selected={tab=='general' }>General</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="social-tab" class:active={tab=='social' } data-toggle="tab" href="social" role="tab"
        use:link aria-controls="social" aria-selected={tab=='social' }>Social</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" class:active={tab=='integration' } id="integration-tab" data-toggle="tab" href="integration"
        use:link role="tab" aria-controls="integration" aria-selected={tab=='integration' }>Integration</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" class:active={tab=='Bank' } id="Bank-tab" data-toggle="tab" href="Bank" use:link role="tab"
        aria-controls="Bank" aria-selected={tab=='Bank' }>Bank</a>
    </li>
    {#if isPoints}
    <li class="nav-item">
      <a class="nav-link" class:active={tab=='points' } id="points-tab" data-toggle="tab" href="points" use:link
        role="tab" aria-controls="points" aria-selected={tab=='points' }>Points</a>
    </li>
    {/if}
  </ul>
  <!-- tabs end -->
  <!-- tab content start -->
  <div class="tab-content bd bd-gray-300 bd-t-0 pd-20" id="myTabContent">
    <div class="tab-pane fade show active" role="tabpanel" aria-labelledby={`${tab}-tab`}>
      {#if tab == 'general'}
      <GeneralSettings reload={fetchOrgInfo} />
      {:else if tab == 'social'}
      <SocialSettings reload={fetchOrgInfo} />
      {:else if tab == 'integration'}
      <IntegrationSettings reload={fetchOrgInfo} />
      {:else if tab == 'Bank'}
      <BankSettings reload={fetchOrgInfo} />
      {:else if isPoints && tab == 'points'}
      <Points reload={fetchOrgInfo} />
      {/if}
    </div>
  </div>
  <!-- tab content end -->
</div>
