<script>
  import { onMount, afterUpdate, onDestroy } from "svelte";
  import { activeMenu } from "../../stores";
  import constants from "../../utils/constants";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import { navigate } from "svelte-routing";
  import { tick } from "svelte";

  export let params = {};
  let { boardId, className, subjectId } = params || {
    boardId: "",
    className: "",
    subjectId: "",
  };
  let chapters = [];
  let loading = true;
  let showAddModal = false;
  let editingChapter = null;
  let newChapter = {
    name: "",
    imageUrl: "",
    chapterNumber: "",
    subjectId: subjectId,
    gradientLeftColorForLeftCircle: "#ffffff", // Default white
    gradientLeftColorForRightCircle: "#ffffff", // Default white
  };
  let activeDropdown = null;
  let fileInput;
  let imagePreview = "";
  let selectedFile = null;
  let uploadLoading = false;

  let currentPage = 1; // Track the current page
  let totalChapters = 0; // Total number of chapters for pagination

  onMount(async () => {
    activeMenu.set("aptiBooster");
    await loadChapters();
    feather.replace();
  });

  afterUpdate(() => {
    feather.replace();
  });

  async function loadChapters() {
    try {
      loading = true;
      const params = new URLSearchParams({
        subjectId: subjectId,
        limit: 20,
        page: currentPage, // Use currentPage for pagination
      });

      const response = await fetch(
        `${constants.BACKEND_URL}/apti-booster/admin/chapter/?${params}`,
        {
          method: "GET",
          headers: {
            // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          },
          cache: "no-cache",
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch chapters: ${response.status}`);
      }

      const data = await response.json();
      chapters = (data.data || [])
        .map((chapter) => ({
          ...chapter,
          gradientLeftColorForLeftCircle:
            chapter.designMetadata?.gradientLeftColorForLeftCircle || "#ffffff",
          gradientLeftColorForRightCircle:
            chapter.designMetadata?.gradientLeftColorForRightCircle ||
            "#ffffff",
        }))
        .sort((a, b) => a.chapterNumber - b.chapterNumber); // Sort chapters by number
      totalChapters = data.total; // Assuming the API returns total chapters count
      await tick();
    } catch (error) {
      console.error("Error loading chapters:", error);
      pError(`Failed to load chapters: ${error.message}`);
    } finally {
      loading = false;
    }
  }

  function saveChaptersToStorage() {
    localStorage.setItem(`chapters_${subjectId}`, JSON.stringify(chapters));
    chapters = chapters; // Trigger reactivity
  }

  // Function to get presigned URL and upload file
  async function uploadFileWithPresignedUrl(file) {
    if (!file) return null;

    try {
      uploadLoading = true;

      // 1. Get presigned URL from backend
      const response = await fetch(
        `${constants.BACKEND_URL}/fileupload-api/get-presigned-url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          },
          body: JSON.stringify({
            data: {
              contentType: "CHAPTER",
              fileName: file.name,
              fileExtension: file.name.split(".").pop(),
            },
            accessToken: localStorage.getItem("accessToken"),
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to get presigned URL: ${response.status}`);
      }

      const res = await response.json();

      if (!res.presignedUrl) {
        throw new Error("Failed to get presigned URL");
      }

      // 2. Convert file to Blob
      const fileBlob = new Blob([file], { type: file.type });

      // 3. Upload to S3
      const uploadResponse = await fetch(res.presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: fileBlob,
        mode: "cors",
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed: ${uploadResponse.status}`);
      }

      // 4. Return the object URL
      return res.objectUrl;
    } catch (error) {
      console.error("Error uploading file:", error);
      pError(`Failed to upload file: ${error.message}`);
      return null;
    } finally {
      uploadLoading = false;
    }
  }

  async function handleSubmit() {
    try {
      if (!newChapter.name.trim()) {
        pError("Chapter name is required");
        return;
      }

      if (!newChapter.chapterNumber) {
        pError("Chapter number is required");
        return;
      }

      // Upload the image if a file is selected
      if (selectedFile) {
        const objectUrl = await uploadFileWithPresignedUrl(selectedFile);
        if (objectUrl) {
          newChapter.imageUrl = objectUrl;
        } else {
          pError("Failed to upload image. Please try again.");
          return;
        }
      } else if (!editingChapter && !newChapter.imageUrl) {
        // If creating a new chapter and no file is selected
        pError("Please select an image file");
        return;
      }

      const url = `${constants.BACKEND_URL}/apti-booster/admin/chapter/${editingChapter ? "update" : "create"}`;
      const payload = editingChapter
        ? {
            chapterId: editingChapter._id,
            ...newChapter,
          }
        : {
            ...newChapter,
          };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to ${editingChapter ? "update" : "create"} chapter`,
        );
      }

      await loadChapters();
      pSuccess(`Chapter ${editingChapter ? "updated" : "added"} successfully`);
      closeModal();
    } catch (error) {
      console.error("Error saving chapter:", error);
      pError(`Failed to ${editingChapter ? "update" : "create"} chapter`);
    }
  }

  async function deleteChapter(chapter) {
    const isConfirmed = confirm(
      "Are you sure you want to delete this chapter?",
    );

    if (isConfirmed) {
      try {
        const response = await fetch(
          `${constants.BACKEND_URL}/apti-booster/admin/chapter/delete/${chapter._id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            },
          },
        );

        if (!response.ok) {
          throw new Error("Failed to delete chapter");
        }

        await loadChapters();
        pSuccess("Chapter deleted successfully");
      } catch (error) {
        console.error("Error deleting chapter:", error);
        pError("Failed to delete chapter");
      }
    } else {
      console.log("Delete action was cancelled.");
    }
  }

  function openAddModal() {
    editingChapter = null;
    newChapter = {
      name: "",
      imageUrl: "",
      chapterNumber: "",
      subjectId: subjectId,
      gradientLeftColorForLeftCircle: "#ffffff", // Default white
      gradientLeftColorForRightCircle: "#ffffff", // Default white
    };
    imagePreview = "";
    selectedFile = null;
    showAddModal = true;
  }

  function openEditModal(chapter) {
    editingChapter = chapter;
    newChapter = {
      name: chapter.name,
      imageUrl: chapter.imageUrl,
      chapterNumber: chapter.chapterNumber,
      subjectId: subjectId,
      gradientLeftColorForLeftCircle:
        chapter.designMetadata?.gradientLeftColorForLeftCircle || "#ffffff",
      gradientLeftColorForRightCircle:
        chapter.designMetadata?.gradientLeftColorForRightCircle || "#ffffff",
    };
    imagePreview = chapter.imageUrl || "";
    selectedFile = null;
    showAddModal = true;
  }

  function closeModal() {
    showAddModal = false;
    editingChapter = null;
    newChapter = {
      name: "",
      imageUrl: "",
      chapterNumber: "",
      subjectId: subjectId,
      gradientLeftColorForLeftCircle: "#ffffff", // Default white
      gradientLeftColorForRightCircle: "#ffffff", // Default white
    };
    imagePreview = "";
    selectedFile = null;
    if (fileInput) fileInput.value = "";
  }

  function toggleDropdown(chapterId) {
    activeDropdown = activeDropdown === chapterId ? null : chapterId;
    setTimeout(() => feather.replace(), 0);
  }

  function handleClickOutside() {
    activeDropdown = null;
  }

  async function handleImageSelect(event) {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        pError("Please select an image file");
        return;
      }

      selectedFile = file;

      // Create preview
      const reader = new FileReader();
      reader.onload = (e) => {
        imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);

      // Optional: Add size check
      if (file.size > 5 * 1024 * 1024) {
        // 5MB limit
        pError("Image size should be less than 5MB");
        return;
      }
    }
  }

  async function nextPage() {
    // Check if there are enough chapters for the next page
    if (chapters.length === 20) {
      // Assuming 10 is the limit per page
      currentPage += 1;
      await loadChapters();
    }
  }

  function previousPage() {
    if (currentPage > 1) {
      currentPage -= 1;
      loadChapters();
    }
  }

  // Add cleanup on component unmount
  onDestroy(() => {
    saveChaptersToStorage();
  });
</script>

<svelte:window on:click={handleClickOutside} />

<div class="content-body fadeIn">
  <div class="container px-3 h-100">
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item">
              <a href="/dashboard/aptiBooster">Apti Booster</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/dashboard/aptiBooster/{boardId}/{className}/subjects"
                >Subjects</a
              >
            </li>
            <li class="breadcrumb-item active" aria-current="page">Chapters</li>
          </ol>
        </nav>
        <h4 class="mg-b-0 tx-spacing--1">Chapters</h4>
      </div>
      <div class="d-inline-block">
        <button
          class="btn pd-x-15 pd-y-8 btn-primary btn-uppercase mg-l-5 btn-sm"
          on:click={openAddModal}
        >
          <i data-feather="plus" class="wd-10 mg-r-5" /> Add Chapter
        </button>
      </div>
    </div>

    <div class="row row-xs card-row">
      {#each chapters as chapter}
        <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
          <div class="card card-folder">
            <div class="card-body">
              <div class="chapter-image-container">
                <img
                  src={chapter.imageUrl || "default-chapter-image.jpg"}
                  alt={chapter.name}
                  class="chapter-image"
                />
              </div>
              <div class="chapter-info mt-2">
                <h6 class="chapter-title">{chapter.name}</h6>
                <div class="chapter-number">
                  {#if chapter.chapterNumber}Chapter {chapter.chapterNumber}{/if}
                </div>
              </div>
              <div class="dropdown-wrapper" on:click|stopPropagation>
                <button
                  class="btn btn-icon"
                  on:click|stopPropagation={() => toggleDropdown(chapter._id)}
                >
                  <i data-feather="more-vertical"></i>
                </button>
                {#if activeDropdown === chapter._id}
                  <div class="dropdown-menu show" style="left: -60px;">
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item"
                      on:click={() => openEditModal(chapter)}
                    >
                      <i data-feather="edit-2"></i>
                      <span>Edit</span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item text-danger"
                      on:click={() => deleteChapter(chapter)}
                    >
                      <i data-feather="trash-2"></i>
                      <span>Delete</span>
                    </a>
                  </div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      {/each}
    </div>

    <div class="pagination">
      <button
        class="btn btn-secondary"
        on:click={previousPage}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span>Page {currentPage}</span>
      <button
        class="btn btn-secondary"
        on:click={nextPage}
        disabled={chapters.length === 0}
      >
        Next
      </button>
    </div>
  </div>
</div>

<!-- Add/Edit Modal -->
{#if showAddModal}
  <div class="modal effect-scale show" style="display: block;" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{editingChapter ? "Edit" : "Add"} Chapter</h5>
          <button type="button" class="close" on:click={closeModal}>×</button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Chapter Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter chapter name"
              bind:value={newChapter.name}
            />
          </div>
          <div class="form-group">
            <label>Chapter Number</label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter chapter number"
              bind:value={newChapter.chapterNumber}
            />
          </div>
          <div class="form-group">
            <label>Upload Chapter Image</label>
            <input
              type="file"
              class="form-control"
              accept="image/*"
              on:change={handleImageSelect}
              bind:this={fileInput}
            />
            <small class="form-text text-muted"
              >Select an image file from your computer</small
            >
          </div>
          <div class="form-group">
            <label>Gradient Colors</label>
            <div class="d-flex align-items-center mb-2">
              <label style="width: 120px; margin-bottom: 0;">Left Circle</label>
              <input
                type="color"
                class="form-control"
                style="width: 60px; height: 38px; padding: 5px;"
                bind:value={newChapter.gradientLeftColorForLeftCircle}
              />
              <input
                type="text"
                class="form-control ml-2"
                placeholder="Left circle gradient color"
                bind:value={newChapter.gradientLeftColorForLeftCircle}
              />
            </div>
            <div class="d-flex align-items-center">
              <label style="width: 120px; margin-bottom: 0;">Right Circle</label
              >
              <input
                type="color"
                class="form-control"
                style="width: 60px; height: 38px; padding: 5px;"
                bind:value={newChapter.gradientLeftColorForRightCircle}
              />
              <input
                type="text"
                class="form-control ml-2"
                placeholder="Right circle gradient color"
                bind:value={newChapter.gradientLeftColorForRightCircle}
              />
            </div>
          </div>
          <!-- {#if imagePreview}
            <div class="form-group">
              <label>Image Preview</label>
              <div class="image-preview">
                <img 
                  src={imagePreview} 
                  alt="Preview" 
                  style="max-width: 100%; max-height: 200px; object-fit: cover; border-radius: 4px;" 
                />
              </div>
            </div>
          {/if} -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" on:click={closeModal}
            >Close</button
          >
          <button
            type="button"
            class="btn btn-primary"
            on:click={handleSubmit}
            disabled={uploadLoading}
          >
            {#if uploadLoading}
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Uploading...</span>
            {:else}
              {editingChapter ? "Update" : "Save"}
            {/if}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
{/if}

<style>
  .card-folder {
    position: relative;
    border-radius: 4px;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: all 0.2s ease;
    cursor: pointer;
    border: 1px solid #e5e9f2;
  }

  .card-folder:hover {
    border-color: #0168fa;
    box-shadow: 0 2px 6px rgba(1, 104, 250, 0.15);
  }

  .card-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
  }

  .folder-icon {
    width: 24px;
    height: 24px;
    color: #0168fa !important;
    margin-right: 10px;
    stroke-width: 2px;
    z-index: 1;
    visibility: visible !important;
  }

  .chapter-info {
    flex: 1;
    padding-right: 10px;
  }

  .chapter-title {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1b2e4b;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .chapter-number {
    font-size: 0.75rem;
    color: #7987a1;
    margin-top: 2px;
  }

  .dropdown-wrapper {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .btn-icon {
    padding: 0;
    background: none;
    border: none;
    color: #7987a1 !important;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-icon:hover {
    color: #1b2e4b !important;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: -60px;
    right: 0;
    min-width: 120px;
    max-width: calc(100vw - 20px);
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    z-index: 1000;
    margin-top: 5px;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    color: #1b2e4b;
    text-decoration: none;
    font-size: 0.875rem;
  }

  .dropdown-item i {
    margin-right: 8px;
  }

  .dropdown-item:hover {
    background: #f5f5f5;
  }

  .text-danger {
    color: #dc3545;
  }

  .text-danger:hover {
    background: #fff5f5;
  }

  .chapter-image-container {
    width: 100%;
    height: 150px;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .chapter-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  .card-folder:hover .chapter-image {
    transform: scale(1.05);
  }

  .content-body {
    min-height: calc(100vh - 60px);
    overflow-y: auto;
    padding-bottom: 20px;
  }

  .container {
    height: 100%;
    overflow: visible;
    padding-bottom: 40px;
  }

  .image-preview {
    max-width: 100%;
    max-height: 200px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #dee2e6;
  }

  .image-preview img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .custom-file-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the pagination */
    margin-top: 20px; /* Add space above the pagination */
    margin-bottom: 20px; /* Add space below the pagination */
  }

  .card-row {
    margin-bottom: 20px; /* Add space below the card row */
  }

  .pagination button {
    background-color: #007bff; /* Blue background */
    color: white; /* White text */
    border: none; /* Remove border */
    padding: 10px 15px; /* Padding for buttons */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
  }

  .pagination button:disabled {
    background-color: #6c757d; /* Gray background for disabled */
    cursor: not-allowed; /* Not allowed cursor */
  }

  .pagination span {
    margin: 0 10px; /* Space around the page number */
  }
</style>
