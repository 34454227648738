<script>
  import { onMount, setContext, tick } from "svelte";
  let elem_picker;
  export let themeChanged;
  export let theme
  async function initPalet() {
    _$(elem_picker).spectrum({
      hideAfterPaletteSelect:true,
      showPaletteOnly: true,
      showPalette: true,
      color: theme || "#DC3545",
      palette: [
        ["#1D2939", "#fff", "#0866C6", "#23BF08", "#F49917"],
        ["#DC3545", "#17A2B8", "#6610F2", "#fa1e81", "#72e7a6"],
      ],
      change: function (color) {
        console.log(color.toHexString()); // #ff0000
        themeChanged(color.toHexString())
      },
    });
  }
  onMount(async function () {
    console.log("Theme Picker loaded");
    await tick()
    initPalet();
    // if(theme) _$(elem_picker).spectrum().trigger('change');
  });
</script>

<input type="text" bind:this={elem_picker} />
