<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import TaskComments from "../../comments/index.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import { pSuccess, pError, pConfirm } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";

  export let _id;
  let firstLoad;
  let isLoading;
  let elem_selectAssignTo,
    elem_selectReportsTo,
    elem_selectTags,
    elem_selectPriority,
    elem_selectStatus,
    elem_uploadAttachment;
  let taskObj = {};
  let errorObj = {};
  let minDate /* , maxDate */;
  let today = new Date();

  let filepondOptions = {
    allowDownloadByUrl: true,
        allowRevert: true,
    allowDownloadByUrl: true,
    acceptedFileTypes:["application/pdf", "image/*", "application/*", "text/*"],
    onremovefile: function (err, file) {
            PNotify.notice({
                title: "Delete Attachment",
                text: "Do you want to remove the file from attachments?",
                icon: "fas fa-question-circle",
                hide: false,
                closer: true,
                sticker: false,
                destroy: true,
                modules: new Map([
                    ...PNotify.defaultModules,
                    [
                        PNotifyConfirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Yes",
                                    primary: true,
                                    click: async (notice) => {
                                        notice.close()
                                        var obj = {
                                          originalFileName: file.filename,
                                            fileExtension: file.fileExtension,
                                            taskId: _id
                                        }
                                        var res = await api_RemoveAttachmentFiles(obj);

                                        if (res.status) {
                                            pSuccess("Successful!!", "Successfully removed an attachment.")
                                        } else {
                                            pError("Error!!", "Something went wrong.")
                                        }
                                    }
                                },

                                {
                                    text: "No",
                                    click: (notice) => {
                                        notice.close()
                                        if (_id && _fileObj.length) {
                                            let files = []
                                            _fileObj.forEach(element => {
                                                files.push({
                                                    source: '12345',
                                                    options: {
                                                        type: 'local',
                                                        file: {
                                                            name: element.originalFileName,
                                                            size: element.fileSize,
                                                            customUrl: element.downloadUrl,
                                                            type: '*'
                                                        }
                                                    }
                                                })
                                            });
                                            _$(lem_uploadAttachment).filepond("files", files);
                                        }


                                    }
                                },

                            ],
                        },
                    ],
                ]),
            });
        },
    server: {
      process: async (
        fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort,
        transfer,
        options
      ) => {
        // fieldName is the name of the input field
        // file is the actual file object to send
        const formData = new FormData();
        formData.append(fieldName, file, file.name);

        let originalFileName = file.name;
        let _fileName = file.name.split(".");

        var fileExtension = _fileName[_fileName.length - 1];
        var fileSize = file.size;
        //  var formData = new FormData();
        // formData.append('file', file, "newFileName"+_fileName);

        var res = await api_handleApiCalls("storage.getUrlForUpload", {
          name: originalFileName,
          type: file.type,
          uploadType: "TASK_ATTACHMENTS",
          fileId: _id,
        });
        if (res.status) {
          _$.ajax({
            xhr: function () {
              var xhr = new window.XMLHttpRequest();
              xhr.upload.addEventListener(
                "progress",
                function (e) {
                  // var percentComplete = (evt.loaded / evt.total) * 100;
                  progress(e.lengthComputable, e.loaded, e.total);
                },
                false
              );
              return xhr;
            },
            url: res.data.url,
            type: "PUT",
            data: file,
            dataType: "text",
            cache: false,
            contentType: file.type,
            processData: false,
          })
            .done(async function (resss) {
              await api_handleApiCalls("taskManager.updateTaskAttachmentUrl", {
                fileId: _id,
                originalFileName: originalFileName,
                fileExtension: fileExtension,
                fileSize: fileSize,
                url: res.data.url,
              });
              load("Done");
            })
            .fail(function (err) {
              error("oh no");
            });
        } else {
          // todo show erro toast
        }
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            // request.abort();

            // Let FilePond know the request has been cancelled
            abort();
          },
        };
      },
    },
  };
  async function api_RemoveAttachmentFiles(data) {

let action = "taskManager.removeAttachmentFile";
var response = await api_handleApiCalls(action, data);
return response;

}

  function setMinDate() {
    let day = new Date();
    day.setFullYear(today.getFullYear());
    day.setMonth(0);
    day.setDate(1);
    day.setHours(0);
    day.setMinutes(0);
    day.setSeconds(0);
    minDate = day;
  }
  //   function setMaxDate() {
  //     let day = new Date();
  //     day.setFullYear(today.getFullYear()+4);
  //     day.setMonth(11);
  //     day.setDate(31);
  //     day.setHours(23);
  //     day.setMinutes(59);
  //     day.setSeconds(59);
  //     maxDate = day;
  //   }

  function parseStatus(value) {
    switch (value) {
      case 0:
        return `<div class="badge badge-primary">Open</div>`;
      case 1:
        return `<div class="badge badge-info">Assigned</div>`;
      case 2:
        return `<div class="badge badge-success">In Progress</div>`;
      case 3:
        return `<div class="badge badge-warning">In Review</div>`;
      case 4:
        return `<div class="badge badge-danger">Re-assigned</div>`;
      case 5:
        return `<div class="badge badge-secondary">Completed</div>`;
      default:
        return "-";
    }
  }

  function parsePriority(value) {
    switch (value) {
      case 1:
        return `<div class="badge bd bd-danger tx-danger">Very High</div>`;
      case 2:
        return `<div class="badge bd bd-warning tx-warning">High</div>`;
      case 3:
        return `<div class="badge bd bd-success tx-success">Medium</div>`;
      case 4:
        return `<div class="badge bd bd-info tx-info">Low</div>`;
      case 5:
        return `<div class="badge bd">Very Low</div>`;
      default:
        return "-";
    }
  }

  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [year, month, day].join("-");
    return dateString;
  }

  function initTags() {
    if (taskObj.tags && taskObj.tags.length) {
      let preSelectedTags = taskObj.tags.split(",");
      // _$('.chips-tag').attr('value',res.data.tags.join(','));
      var tags = _$(elem_selectTags).selectize({
        maxItems: null,
        delimiter: ",",
        plugins: ["remove_button"],
        persist: false,
        items: [...preSelectedTags],
        onChange: function (values) {
          values.map((item) => `${item.value || ""}`.trim());
          // taskObj.tags = values.join(",");
          taskObj.tags = values;
        },
        render: {
          option_create: function (data, escape) {
            return (
              '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New tag : </i><strong>' +
              escape(data.input) +
              "</strong></div>"
            );
          },
        },
        create: function (input) {
          return { value: input, text: input };
        },
      });
      let objTags = taskObj.tags.split(",");
      for (var i = 0; objTags && objTags.length && i < objTags.length; i++) {
        tags[0].selectize.addOption({
          value: objTags[i],
          text: objTags[i],
        });
      }

      if (preSelectedTags) {
        _$(elem_selectTags)[0].selectize.setValue(preSelectedTags, false);
      }
    } else {
      _$(elem_selectTags).selectize({
        maxItems: null,
        delimiter: ",",
        plugins: ["remove_button"],
        persist: false,
        onChange: function (values) {
          values.map((item) => `${item.value || ""}`.trim());
          // taskObj.tags = values.join(",");
          taskObj.tags = values;
        },
        render: {
          option_create: function (data, escape) {
            return (
              '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New tag : </i><strong>' +
              escape(data.input) +
              "</strong></div>"
            );
          },
        },
        create: function (input) {
          return { value: input, text: input };
        },
      });
    }
  }

  function clearError(key) {
    delete errorObj[key];
    errorObj = { ...errorObj };
  }

  function clearValidations() {
    let keys = Object.keys(errorObj);
    if (keys.length) {
      for (let index = 0; index < keys.length; index++) {
        delete errorObj[keys[index]];
      }
    }
    errorObj = { ...errorObj };
  }

  function clearFields() {
    taskObj._id = "";
    taskObj.summary = "";
    taskObj.assignedTo = "";
    taskObj.reportTo = "";
    taskObj.startDate = "";
    taskObj.dueDate = "";
    taskObj.description = "";
    taskObj.status = 0;
    taskObj.priority = 0;
    taskObj.attachments = [];
    clearValidations();
  }

  function validate() {
    // summary - required
    taskObj.summary = taskObj.summary.trim();
    if (!taskObj.summary) errorObj.summary = "Summary cannot be empty";

    // description - optional
    taskObj.description = taskObj.description.trim();

    // assignedTo - required
    if (!taskObj.assignedTo)
      errorObj.assignedTo = "Please select user to assign task to";
    // reportTo - required implied by assignedTo
    if (taskObj.assignedTo && !taskObj.reportTo)
      errorObj.reportTo = "Please select user to report task to";

    let startdate = new Date(taskObj.startDate);
    let duedate = new Date(taskObj.dueDate);

    // start date - optional
    if (taskObj.startDate) {
      if (startdate == "Invalid Date") {
        errorObj.startDate = "Invalide Start Date";
      }
    }

    // due date - optional
    if (taskObj.dueDate) {
      if (duedate == "Invalid Date") {
        errorObj.dueDate = "Invalid Due Date";
      } else if (
        new Date(startdate).getFullYear() >= new Date(duedate).getFullYear() &&
        new Date(startdate).getMonth() >= new Date(duedate).getMonth() &&
        new Date(startdate).getDate() >= new Date(duedate).getDate()
      ) {
        errorObj.dueDate = "Due Date should be after Start Date";
      }
    }

    // tags - optional
    if (taskObj.tags) {
      //
    }

    return !Object.values(errorObj).length;
  }
  async function updateTask() {
    if (isLoading) return;
    isLoading = true;
    await tick();
    clearValidations();
    let isValid = validate();
    if (isValid) {
      isLoading = true;
      let result = await api_handleApiCalls("taskManager.updateTaskDetails", taskObj);
      if (result.status) {
        pSuccess("", result.data);
      } else {
        pError("", result.errMsg);
      }
    } else {
      isLoading = false;
    }
    isLoading = false;
    await tick();
  }

  function initSelectize() {
    if (!taskObj.assignedTo && firstLoad) firstLoad = false;
    let assignedTo = taskObj.assignedTo ? [taskObj.assignedTo] : [];
     let reportsTo = [];
    let status =
      taskObj.status || taskObj.status === 0 ? [taskObj.status] : [2];
    let priority = taskObj.priority ? [taskObj.priority] : [3];
    // assign to
    _$(elem_selectAssignTo).selectize({
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      options: [],
      onChange: async function (value) {
        if (!value) {
          if (firstLoad) firstLoad = false;
          return false;
        }
        // getting report to value from user
        let result = await api_handleApiCalls("systemUser.fetchUserReportsToUser", {
          userId: value,
        });
        let userReportsTo = result.data && result.data.reportsTo;
        if (!firstLoad) {
          // set report to value on UI
          reportsTo = userReportsTo ? [userReportsTo] : [];
          _$(elem_selectReportsTo)[0].selectize.setValue(reportsTo);
        } else firstLoad = false;
        if (!result.status) {
          // error
        }
        // save assigned to value in var
        taskObj.assignedTo = value;
      },
      preload: true,
      create: false,
      items: assignedTo,
      load: async function (query, callback) {
        let result = await api_handleApiCalls("systemUser.fetchSystemUsersToAssign", {
          search: { value: query },
        });
        if (result.status) {
         
          if( result.data){
          result.data.data = result.data.data.map((item) =>
            item._id == taskObj.assignedTo ? { ...item, selected: true } : item
          );
          callback(result.data.data);
          }
        } else {
          callback([]);
        }
        let assignedTo = taskObj.assignedTo ? [taskObj.assignedTo] : [];
        _$(elem_selectAssignTo)[0].selectize.setValue(assignedTo);
      },
    });
    if (!taskObj.assignedTo) _$(elem_selectAssignTo)[0].selectize.setValue("");

    // report to
    _$(elem_selectReportsTo).selectize({
      maxItems: 1,
      create: false,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      options: [],
      onChange: function (value) {
        taskObj.reportTo = value;
      },
      preload: true,
      create: false,
      items: reportsTo,
      load: async function (query, callback) {
        let result = await api_handleApiCalls("systemUser.fetchSystemUsersToAssign", {
          search: { value: query },
          // excludeUserId: taskObj.assignedTo
        });
        if (result.status) {
          callback(result.data.data);
        } else {
          callback([]);
        }
        let reportsTo = taskObj.reportTo ? [taskObj.reportTo] : [];
        _$(elem_selectReportsTo)[0].selectize.setValue(reportsTo);
      },
    });
    if (!taskObj.reportTo) _$(elem_selectReportTo)[0].selectize.setValue("");
    // taskObj.assignedTo &&
    //     _$(elem_selectAssignTo).selectize.setValue(taskObj.assignedTo);
    //   taskObj.reportTo &&
    //     _$(elem_selectReportsTo).selectize.setValue(taskObj.reportTo);

    // priority
    _$(elem_selectPriority).selectize({
      maxItems: 1,
      create: false,
      items: priority,
      onChange: async function (value) {
        taskObj.priority = parseInt(value);
        let result = await api_handleApiCalls("taskManager.updatePriority", {
          _id,
          priority: value,
        });
        if (result.status) {
          pSuccess("", result.data);
        } else {
          pError("", result.errMsg);
        }
      },
      load: function () {
        let priority = taskObj.priority ? [taskObj.priority] : [2];
        _$(elem_selectPriority)[0].selectize.setValue(priority);
      },
    });

    // status
    _$(elem_selectStatus).selectize({
      maxItems: 1,
      create: false,
      items: status,
      onChange: async function (value) {
        async function update(_id, value) {
          taskObj.status = parseInt(value);
          let result = await api_handleApiCalls("taskManager.updateStatus", {
            _id,
            status: value,
          });
          if (result.status) {
            pSuccess("", result.data);
          } else {
            pError("", result.errMsg);
          }
        }
        /* function reset() {
          let status = taskObj.status ? [taskObj.status] : [3];
          _$(elem_selectStatus)[0].selectize.setValue(status);
        } */
        if (taskObj.status == value) {
          // do nothing
        } /* else if (value == 0) {
          pConfirm(
            "Are you sure?",
            "Changing status to open will re-assign this task to creater",
            () => update(_id, value),
            reset
          );
        }  */ else {
          update(_id, value);
        }
      },
      load: function () {
        let status =
          taskObj.status || taskObj.status === 0 ? [taskObj.status] : [3];
        _$(elem_selectStatus)[0].selectize.setValue(status);
      },
    });
    initTags();
    _$(elem_selectStatus).prop("disabled", taskObj.status == 5);
  }

  let renderLinks = async function () {
    // var templinkArr = await api_handleApiCalls("files.loadLinksForFile", {
    //   fileId: _id,
    // });
    // linkArr = templinkArr.data.fileFolderLinkArr;
    // tick();
    // setTimeout(function () {
    //   feather.replace();
    // }, 10);
  };

  let refreshLinkList = function () {
    renderLinks();
  };

  let deleteLink = async (linkId) => {
    /* var linkRes = await api_removeFileLinkToFolder({_id: linkId});
        if (linkRes.status) {
            updateSuccess({ title: "Success", msg: linkRes.data });
            renderLinks();
        } else {
            // Failed to create 
            updateErrors({ title: "Error", msg: (linkRes?.errMsg ?? "Something went wrong") })
        }         */
  };
  async function initFilepond() {
    _$.fn.filepond.setOptions({
      server: {
        url: "{{ url('upload')}}",
        process: {
          headers: {
            "X-CSRF-TOKEN": "csrf",
          },
        },
      },
    });
    _$.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
    _$.fn.filepond.registerPlugin(FilePondPluginGetFile);

    _$(elem_uploadAttachment).filepond(filepondOptions);
    var _fileObj;
    if (_id) {
      // var attachmentsRes = await api_handleApiCalls("taskManager.getAttachmentById", {
      //   _id: _id,
      // });
      // _fileObj = attachmentsRes;
      // if (attachmentsRes.status) {
      //   var pdfData = attachmentsRes.data;
      //   pdfObj = {
      //     // _id: pdfData._id, // ?
      //     name: pdfData.name,
      //     facultyId: pdfData.facultyId,
      //     moduleSubjects: pdfData.moduleSubjects,
      //     tags: pdfData.tags,
      //   };
      // }
    }
    _$(elem_uploadAttachment).filepond("allowMultiple", true);

    if (taskObj && taskObj?.attachments ) {
      let files = []
              taskObj.attachments.forEach(element => {
                files.push({
                        source: '12345',
                        options: {
                            type: 'local',
                            file: {
                                name: element.originalFileName,
                                size: element.fileSize,
                                customUrl: element.downloadUrl,
                                type: '*'
                            }
                        }
                    })
            });
         
        _$(elem_uploadAttachment).filepond("files",files);
      
    }
    renderLinks();
    _$(".may-pond .filepond--item-panel").css("background", "#369763");
    // _$(elem_uploadAttachment).on('FilePond:addfile', function (e) {
    //     _$.fn.filepond.setOptions(server);
    // });
  }

  async function reload() {
    let result = await api_handleApiCalls("taskManager.fetchTaskDetails", { _id });
    if (result.status) {
      taskObj = result.data;
      taskObj.startDate = taskObj.startDate
        ? convertDateObjToStr(taskObj.startDate)
        : "";
      taskObj.dueDate = taskObj.dueDate
        ? convertDateObjToStr(new Date(taskObj.dueDate))
        : "";
      try {
        taskObj.status = parseInt(taskObj.status);
        taskObj.priority = parseInt(taskObj.priority);
      } catch (error) {
        taskObj.status = 2;
        taskObj.priority = 3;
      }
      initSelectize();
      await tick();
    } else {
      pError("", result.errMsg);
    }
    initFilepond();
    if (taskObj.assignedTo && !taskObj.reportTo) {
      errorObj.reportsTo =
        "There is no reportee for user to whom this task is assigned to. Please assign a report to user or contact your administrator";
    }
  }

  onMount(function () {
    feather.replace()
    firstLoad = true;
    clearFields();
    setMinDate();
    // setMaxDate();
    reload();
    feather.replace({ width: "1em", height: "1em" });
  });
</script>

<style>
  .wrap-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<div class="content-body fadeIn">
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 ">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item"><a href="/dashboard/tasks">Tasks</a></li>
          <li class="breadcrumb-item">Update</li>
          <li class="breadcrumb-item active wrap-ellipsis" aria-current="page">
            Task #{taskObj.taskId}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1 text-capitalize">
        {taskObj.summary}
        #{taskObj.taskId}
      </h4>
      <p
        class="tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5 text-capitalize">
        Update task details, change priority, change status, upload attachments,
        comment on task
        {#if !isNaN(taskObj.priority) && parseInt(taskObj.priority) > 0}
          {@html parsePriority(taskObj.priority)}
        {/if}
        {#if !isNaN(taskObj.status) && parseInt(taskObj.status) >= 0}
          {@html parseStatus(taskObj.status)}
        {/if}
      </p>
    </div>
    <div class="col-sm-6">
      <div class="d-flex">
        <div class="col-sm-5">
          <select
            bind:this={elem_selectPriority}
            class="form-control"
            placeholder="Please select priority">
            <option value="1">Very High</option>
            <option value="2">High</option>
            <option value="3">Medium</option>
            <option value="4">Low</option>
            <option value="5">Very Low</option>
          </select>
        </div>
        <div class="col-sm-5">
          <select
            bind:this={elem_selectStatus}
            class="form-control"
            placeholder="Please select status">
            <option value="0">Open</option>
            <option value="1">Assign</option>
            <option value="2">In Progress</option>
            <option value="3">In Review</option>
            <option value="4">Re-assigned</option>
            <option value="5">Completed</option>
          </select>
        </div>
        <div class="col-sm-12">
          <button
            class="btn  tx-12 btn-primary btn-uppercase mg-l-5"
            on:click={updateTask}   
            disabled={isLoading}>
            <ButtonLoader {isLoading} btnText="Save" />  <i data-feather="save" class="wd-10 mg-l-5"></i>     
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- on:click={updateTask} -->
  <hr class="full-width" />
  <div class="mg-b-20 ">
    <div class="row row-sm">
      <div class="col-md-7 col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Task Details
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Summary
                  <span class="required-field">*</span></label>
                <input
                  type="text"
                  bind:value={taskObj.summary}
                  on:blur={() => clearError('summary')}
                  class="form-control"
                  class:parsley-error={errorObj.summary}
                  placeholder="Enter summary here" />
                {#if errorObj.summary}
                  <div class="parsley-errors-list">{errorObj.summary}</div>
                {/if}
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Description</label>
                <textarea
                  bind:value={taskObj.description}
                  class="form-control"
                  rows="3"
                  on:blur={() => clearError('description')}
                  style="resize: none"
                  class:parsley-error={errorObj.description}
                  placeholder="Enter description here" />
                {#if errorObj.description}
                  <div class="parsley-errors-list">{errorObj.description}</div>
                {/if}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Assigned To
                  <span class="required-field">*</span></label>
                <select
                  on:blur={() => clearError('assignedTo')}
                  bind:this={elem_selectAssignTo}
                  class="form-control"
                  class:parsley-error={errorObj.assignedTo}
                  placeholder="Please select assign to user" />
                {#if errorObj.assignedTo}
                  <div class="parsley-errors-list">{errorObj.assignedTo}</div>
                {/if}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Report To
                  <span class="required-field">*</span>
                </label>
                <select
                  on:blur={() => clearError('reportsTo')}
                  bind:this={elem_selectReportsTo}
                  class="form-control"
                  class:parsley-error={errorObj.reportsTo}
                  placeholder="Please select report to user" />
                {#if errorObj.reportsTo}
                  <div class="parsley-errors-list">{errorObj.reportsTo}</div>
                {/if}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Start Date
                  {taskObj.startDate ? `(${convertDateObjToStr(taskObj.startDate)})` : ''}</label>
                <input
                  type="date"
                  onkeydown="return false"
                  bind:value={taskObj.startDate}
                  on:blur={() => clearError('startDate')}
                  class="form-control"
                  min={convertDateObjToStr(minDate)}
                  class:parsley-error={errorObj.startDate}
                  placeholder="Please select start date" />
                {#if errorObj.startDate}
                  <div class="parsley-errors-list">{errorObj.startDate}</div>
                {/if}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Due Date
                  {taskObj.dueDate ? `(${convertDateObjToStr(taskObj.dueDate)})` : ''}</label>
                <input
                  type="date"
                  onkeydown="return false"
                  bind:value={taskObj.dueDate}
                  on:blur={() => clearError('dueDate')}
                  class="form-control"
                  min={taskObj.startDate}
                  class:parsley-error={errorObj.dueDate}
                  placeholder="Please select due date" />
                {#if errorObj.dueDate}
                  <div class="parsley-errors-list">{errorObj.dueDate}</div>
                {/if}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block">Tags</label>
                <select
                  on:blur={() => clearError('tags')}
                  bind:this={elem_selectTags}
                  class="form-control"
                  class:parsley-error={errorObj.tags}
                  placeholder="Select tags here" />
                {#if errorObj.tags}
                  <div class="parsley-errors-list">{errorObj.tags}</div>
                {/if}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Upload Attachments
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-sm-12">
            <div class="form-row ht-80">
              <div class="form-group col-md-12">
                <div>
                  <!-- filepond here -->
                  <input
                    type="file"
                    bind:this={elem_uploadAttachment}
                    class="my-pond"
                    data-instant-upload="false"
                    data-allow-image-preview="false"
                    name="filepond" />
                </div>
                {#if errorObj.attachments}
                  <div class="parsley-errors-list">{errorObj.attachments}</div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Task Comments
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-sm-12 ht-250">
            <div class="pd-t-20 pd-r-10 ht-100p">
              <!-- comments here -->
              <TaskComments id={_id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
