<script>
  import { Router, Link, links, Route, navigate } from "svelte-routing";
  import { afterUpdate, onMount, tick } from "svelte";
  import "timepicker";
  export let testObj;
  export let errorObj;
  export let updateParent;
  let dateObj = {
    startDate: null,
    endDate: null,
    startTIme: null,
    endTime: null,
    resultDate: null,
    resultTime: null,
  };

  let startTimepickerConfig, endTimepickerConfig, resultTimepickerConfig;
  let elem_testStartTime, elem_testEndTime, elem_testResultTime;
  let minEndDate, minResultDate;
  function convertDateStrToObj(selectedDate) {
    selectedDate = selectedDate.split("-");
    var year = selectedDate[0];
    var month = selectedDate[1] - 1;
    var day = selectedDate[2];
    return new Date(year, month, day);
  }

  let clearError = function (fieldName) {
    if (errorObj[fieldName]) {
      delete errorObj[fieldName];
    }
    errorObj = errorObj;
  };

  function convertDateObjToDateStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [year, month, day].join("-");
    return dateString;
  }

  function convertDateObjToTimeStr(date) {
    date = new Date(date);
    var minutes = "" + date.getMinutes();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    var hours = date.getHours() % 12;
    hours = hours ? hours : 12;

    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;

    var timeString = [hours, minutes].join(":") + " " + am_pm;
    return timeString;
  }

  let onDateChange = async function (type, event) {
    // final start date time
    // new Date(new Date(dateObj.startDate).toLocaleDateString() + " " + new Date(dateObj.startTime).toLocaleTimeString())

    // final end date time
    // new Date(new Date(dateObj.endDate).toLocaleDateString() + " " + new Date(dateObj.endTime).toLocaleTimeString())

    if (type == "startdate") {
      dateObj.startDate = event.target.value;
      // console.log(testObj.startDate);
      let minDate = new Date(dateObj.startDate);
      minDate.setDate(minDate.getDate());
      minEndDate = convertDateObjToDateStr(minDate);
      testObj.startDate = dateObj.startDate;
      await tick();
      // _$(elem_testStartTime).timepicker('setTime', '12:00 AM')
    }

    if (type == "starttime") {
      dateObj.startTime = event;
      // console.log(testObj.startTime);
      testObj.startTime = dateObj.startTime;
    }

    if (type == "enddate") {
      dateObj.endDate = event.target.value;
      // console.log(testObj.endDate);
      testObj.endDate = dateObj.endDate;
      await tick();
      // _$(elem_testEndTime).timepicker('setTime', '12:00 AM')
    }

    if (type == "endtime") {
      dateObj.endTime = event;
      // console.log(testObj.endTime);
      testObj.endTime = dateObj.endTime;
    }

    if (type == "resultTime") {
      dateObj.resultTime = event;
      // console.log(testObj.resultTime);
      testObj.resultTime = dateObj.resultTime;
    }

    if (type == "resultDate") {
      dateObj.resultDate = event.target.value;
      // console.log(testObj.resultDate);
      testObj.resultDate = dateObj.resultDate;
    }

    // console.log(testObj.startDate, testObj.endDate, testObj.resultDate);
  };

  // afterUpdate

  afterUpdate(function () {
    // if(!dateObj.startDate) {
    //     dateObj.startDate = testObj.startDate
    //     // console.log(testObj.startDate);
    //     minEndDate = convertDateObjToDateStr(new Date(dateObj.startDate))
    // } else {
    //     testObj.startDate = dateObj.startDate
    // }

    // if(!startTimepickerConfig && (dateObj.startTime || testObj.startTime)) {
    //     let time
    //     // console.log(dateObj.startTime, testObj.startTime, "time")
    //     if(dateObj.startTime) {
    //         time = new Date(dateObj.startTime)
    //     } else if(testObj.startTime) {
    //         dateObj.startTime = testObj.startTime
    //         time = new Date(testObj.startTime)
    //     }
    //     if(time) {
    //         // startTimepickerConfig = getTimepickerConfig(time, startTimeChanged)     //time? moment(new Date(time)).format("hh:mm A") : '0'
    //         // _$(elem_testStartTime).timepicker(startTimepickerConfig);
    //         _$(elem_testStartTime).timepicker('setTime', new Date(time))
    //     }
    // }

    // if(!dateObj.endDate) {
    //     dateObj.endDate = testObj.endDate
    //     // console.log(testObj.endDate);
    // } else {
    //     testObj.endDate = dateObj.endDate
    // }

    // if(!endTimepickerConfig && (dateObj.endTime || testObj.endTime)) {
    //     let time
    //     // console.log(dateObj.endTime, testObj.endTime, "time")
    //     if(dateObj.endTime) {
    //         time = new Date(dateObj.endTime)
    //     } else if(testObj.endTime) {
    //         dateObj.endTime = testObj.endTime
    //         time = new Date(testObj.endTime)
    //     }
    //     if(time) {
    //         // endTimepickerConfig = getTimepickerConfig(time, endTimeChanged)
    //         // _$(elem_testEndTime).timepicker(endTimepickerConfig);
    //         _$(elem_testEndTime).timepicker('setTime', new Date(time))
    //     }
    // }

    if (!testObj.displayResultImmediately) {
      minResultDate = new Date(dateObj.resultDate);
      minResultDate.setDate(minResultDate.getDate());

      if (!dateObj.resultDate) {
        dateObj.resultDate = testObj.resultDate;
        // console.log(testObj.resultDate);
      } else {
        testObj.resultDate = dateObj.resultDate;
      }

      if (testObj.resultTime) {
        resultTimepickerConfig = getTimepickerConfig(
          testObj.resultTime
            ? moment(new Date(testObj.resultTime)).format("hh:mm A")
            : "0",
          resultTimeChanged,
        );
        _$(elem_testResultTime).timepicker(resultTimepickerConfig);
      } else if (dateObj.endDate) {
        minResultDate = new Date(dateObj.endDate);
        minResultDate.setDate(minResultDate.getDate());
        dateObj.resultDate = new Date(dateObj.endDate);
        testObj.resultDate = new Date(dateObj.endDate);
        if (dateObj.endTime) {
          let minResultTime = new Date(dateObj.endTime);
          if (testObj.duration) {
            minResultTime.setMinutes(
              minResultTime.getMinutes() + testObj.duration,
            );
          }
          if (minResultTime.getMinutes() % 15) {
            // get ceiling of 15 minutes ran
            let minutes = minResultTime.getMinutes();
            let divisions = minutes / 15;
            if (divisions % 1) divisions = parseInt(divisions + 1);
            minutes = divisions * 15;
            minResultTime.setMinutes(minutes);
          }
          minResultTime =
            moment(new Date(minResultTime)).format("hh:mm A") || "0";
          resultTimepickerConfig = getTimepickerConfig(
            minResultTime,
            resultTimeChanged,
          );
          console.log("minResultTime", resultTimepickerConfig);
          _$(elem_testResultTime).timepicker(resultTimepickerConfig);
        }
      }

      if (!dateObj.resultTime) {
        dateObj.resultTime = testObj.resultTime;
        // console.log(testObj.resultTime);
        if (dateObj.resultTime) {
          // This works, but same for other fields does not work
          _$(elem_testResultTime).timepicker(
            "setTime",
            new Date(dateObj.resultTime),
          );
        } else if (dateObj.endTime) {
          if (
            new Date(dateObj.endDate).toDateString() ==
            new Date().toDateString()
          ) {
            dateObj.resultTime = new Date(dateObj.endTime);
            if (testObj.duration) {
              dateObj.resultTime.setHours(
                dateObj.resultTime.getHours() +
                  parseInt(parseInt(testObj.duration || 0) / 60), //  hours
                dateObj.resultTime.getMinutes() +
                  parseInt(parseInt(testObj.duration || 0) % 60), // minutes
              );
            }
          } else {
            dateObj.resultTime = new Date();
            dateObj.resultTime.setHours(0, 0, 0);
          }
          _$(elem_testResultTime).timepicker(
            "setTime",
            new Date(dateObj.resultTime),
          );
        }
      } else {
        // testObj.endTime = dateObj.endTime
        _$(elem_testResultTime).timepicker(
          "setTime",
          new Date(dateObj.resultTime),
        );
      }
    } else {
      dateObj.resultDate = null;
      dateObj.resultTime = null;
      testObj.resultDate = null;
    }
  });

  const getTimepickerConfig = (defaultTime, timeChanged) => {
    // if(defaultTime != '0') {
    //     let hours = parseInt(defaultTime.substr(0, 2));
    //     if (defaultTime.substr(6, 2) == "PM") hours = (hours + 12) % 24;
    //     let minutes = defaultTime.substr(3, 2);
    //     defaultTime = new Date();
    //     if (!isNaN(hours) && !isNaN(minutes)) {
    //         defaultTime.setHours(parseInt(hours), parseInt(minutes));
    //     } else defaultTime.setHours(0, 0, 1);
    // }
    return {
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      defaultTime: defaultTime ? defaultTime : "12:00 AM",
      startTime: "12:00 AM",
      change: timeChanged,
    };
  };

  async function startTimeChanged(time) {
    if (time != dateObj.startTime) {
      if (time) {
        dateObj.startTime = new Date(time);
      } else {
        dateObj.startTime = null;
      }
    }
    onDateChange("starttime", time);
    clearError("startDate");
    await tick();
  }

  async function endTimeChanged(time) {
    if (time) {
      dateObj.endTime = new Date(time);
    } else {
      dateObj.endTime = null;
    }
    onDateChange("endtime", time);
    // if(!testObj.displayResultImmediately) {
    //     let minResultTime = new Date(dateObj.endTime)
    //     if(minResultTime.valueOf() > new Date(dateObj.resultTime).valueOf()) {
    //         if(testObj.duration) {
    //             minResultTime.setMinutes(minResultTime.getMinutes() + testObj.duration)
    //         }
    //         if(minResultTime.getMinutes() % 15) {
    //             // get ceiling of 15 minutes ran
    //             let minutes = minResultTime.getMinutes()
    //             let divisions = minutes / 15
    //             if(divisions % 1) divisions = parseInt(divisions + 1)
    //             minutes = divisions * 15
    //             minResultTime.setMinutes(minutes)
    //         }
    //         minResultTime = moment(new Date(minResultTime)).format("hh:mm A") || '0'
    //         resultTimeChanged(minResultTime)
    //     }
    // }
    clearError("endTime");
    await tick();
  }

  async function resultTimeChanged(time) {
    if (time) {
      dateObj.resultTime = new Date(time);
    } else {
      dateObj.resultTime = null;
    }
    onDateChange("resultTime", time);
    clearError("resultTime");
    await tick();
  }

  onMount(function () {
    testObj.menu = "publish";
    console.log("Publish : ", testObj);
    testObj.isPublish = true;
    console.log("testObj.startTime>>>>>> : ", testObj.startTime);
    console.log("testObj.endTime>>>>>> : ", testObj.endTime);
    if (!dateObj.startDate) {
      dateObj.startDate = testObj.startDate;
      // console.log(testObj.startDate);
      let minDate = new Date(testObj.startDate);
      minDate.setDate(minDate.getDate());
      minEndDate = convertDateObjToDateStr(minDate);
    } else {
      testObj.startDate = dateObj.startDate;
    }
    if (!dateObj.endDate) {
      dateObj.endDate = testObj.endDate;
      // console.log(testObj.endDate);
    } else {
      testObj.endDate = dateObj.endDate;
    }
    startTimepickerConfig = getTimepickerConfig(
      testObj.startTime
        ? moment(new Date(testObj.startTime)).format("hh:mm A")
        : "12:00 AM",
      startTimeChanged,
    ); //time? moment(new Date(time)).format("hh:mm A") : '0'
    _$(elem_testStartTime).timepicker(startTimepickerConfig);

    endTimepickerConfig = getTimepickerConfig(
      testObj.endTime
        ? moment(new Date(testObj.endTime)).format("hh:mm A")
        : "12:00 AM",
      endTimeChanged,
    );
    _$(elem_testEndTime).timepicker(endTimepickerConfig);

    feather.replace();
    updateParent();
  });

  let onPublishClicked = async function () {
    if (publishButtonLoading) return;

    resetErrors();
    detailsErrors = validateDetails({ ...testObj, source: "publish" });
    settingsErrors = validateSettings({ ...testObj, source: "publish" });
    questionsErrors = validateQuestions({ ...testObj, source: "publish" });
    assignLinksErrors = validateAssigns({ ...testObj, source: "publish" });
    notificationErrors = validateNotifications({
      ...testObj,
      source: "publish",
    });
    publishErrors = validatePublish({ ...testObj, source: "publish" });

    if (
      detailsErrors.allowPublish &&
      settingsErrors.allowPublish &&
      questionsErrors.allowPublish &&
      assignLinksErrors.allowPublish &&
      notificationErrors.allowPublish &&
      publishErrors.allowPublish
    ) {
      const callPublishApi = async (notifyNow) => {
        publishButtonLoading = true;
        await tick();

        await onUpdateQuestion(true, true);
        console.log("publish success");

        // Integrate the logic from api_assignTestToAptiBooster
        const url = `${BACKEND_URL}/apti-booster/admin/test/assign-to-apti-booster/`;
        try {
          let response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({
              testId: testObj._id,
              boardIds: [selectedBoard],
              classIds: [getClassKey(selectedClass)],
              subjectIds: selectedSubjectIds,
              chapterIds: selectedChapterIds,
              publishStart:
                testObj.startDate && testObj.startTime
                  ? new Date(testObj.startDate + "T" + testObj.startTime)
                  : null,
              publishEnd:
                testObj.endDate && testObj.endTime
                  ? new Date(testObj.endDate + "T" + testObj.endTime)
                  : null,
              accessToken: localStorage.getItem("accessToken"),
            }),
          });

          const data = await response.json();
          console.log("API Response:", data);

          if (response.ok) {
            testObj.publish = true;
            testObj.isPublish = true;
            updateParent();
            pSuccess("Success", "Test published successfully");
          } else {
            pError(
              "Error",
              data.errMsg || data.message || "Failed to publish test",
            );
          }
        } catch (error) {
          console.error("API Error:", error);
          pError("Error", "Connection failed");
        } finally {
          publishButtonLoading = false;
          await tick();
        }
      };

      callPublishApi(false);
    }
  };
</script>

<div class="fadeIn" style="display: flex;flex-direction: column;">
  <div style="width: 1;border-bottom: 1px solid #e5e8ee; display: flex;">
    <div
      style="display: flex;align-items: center;justify-content: center;margin-left: 20px;"
      class="colored-svg"
    >
      <i data-feather="anchor" class="wd-40"></i>
    </div>
    <div class="media-body mg-l-10 pd-y-10">
      <h6 class="mg-b-2 tx-16">Publish</h6>
      <span class="d-block tx-11 tx-color-03">Start Date, End date</span>
    </div>
    <div class="pd-y-10">
      <button
        class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-r-10"
        on:click={() =>
          navigate("/dashboard/tests/view/" + testObj._id + "/notifications")}
      >
        <i data-feather="chevron-left" class="wd-10 mg-r-5"></i> Prev
      </button>
      <button
        class="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-r-10"
      >
        Next <i data-feather="chevron-right" class="wd-10 mg-l-5"></i>
      </button>
    </div>
  </div>
  <div style="flex: 1; padding: 25px;">
    <div class="form-group row">
      <label class="col-sm-3 col-form-label"
        >Start Date <span class="required-field">*</span></label
      >
      <div class="col-sm-5">
        <input
          disabled={testObj.publish}
          type="date"
          onkeydown="return false"
          value={convertDateObjToDateStr(dateObj.startDate)}
          on:change={(event) => onDateChange("startdate", event)}
          class="form-control"
          placeholder="Select Date"
          on:blur={async () => {
            clearError("startDate");
            await tick();
          }}
          class:parsley-error={errorObj.startDate && !dateObj.startDate}
        />
      </div>
      <div class="col-sm-4">
        <input
          disabled={testObj.publish}
          type="text"
          bind:this={elem_testStartTime}
          onkeydown="return false"
          class="timepicker form-control"
          class:parsley-error={errorObj.startDate && !dateObj.startTime}
        />
      </div>
      {#if errorObj.startDate}
        <div class="col-sm-3"></div>
        <div class="col-sm-9">
          <div class="parsley-errors-list">{errorObj.startDate}</div>
        </div>
      {/if}
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label"
        >End Date <span class="required-field">*</span></label
      >
      <div class="col-sm-5">
        <input
          disabled={testObj.publish}
          type="date"
          onkeydown="return false"
          value={dateObj.endDate
            ? convertDateObjToDateStr(dateObj.endDate)
            : ""}
          min={minEndDate}
          class="form-control"
          on:change={(event) => onDateChange("enddate", event)}
          on:blur={async () => {
            clearError("endDate");
            await tick();
          }}
          class:parsley-error={errorObj.endDate && !dateObj.endDate}
          placeholder="Select Date"
        />
      </div>
      <div class="col-sm-4">
        <input
          disabled={testObj.publish}
          type="text"
          bind:this={elem_testEndTime}
          onkeydown="return false"
          class="form-control"
          class:parsley-error={errorObj.endDate && !dateObj.endTime}
        />
      </div>
      {#if errorObj.endDate}
        <div class="col-sm-3"></div>
        <div class="col-sm-9">
          <div class="parsley-errors-list">{errorObj.endDate}</div>
        </div>
      {/if}
    </div>

    <div class="form-group row">
      <label class="col-sm-3 col-form-label"
        >Display Result <span class="required-field">*</span></label
      >
      <div
        class="form-group col-sm-5 m-0"
        style="display: flex;align-items: center;"
      >
        <div class="custom-control custom-radio">
          <input
            disabled={testObj.publish}
            type="radio"
            id="resultImmeYes"
            name="displayResult"
            class="custom-control-input"
            bind:group={testObj.displayResultImmediately}
            value={true}
          />
          <label class="custom-control-label" for="resultImmeYes"
            >Immediately</label
          >
        </div>
      </div>
      <div
        class="form-group col-sm-4 m-0"
        style="display: flex;align-items: center;"
      >
        <div class="custom-control custom-radio">
          <input
            disabled={testObj.publish}
            type="radio"
            id="resultImmeNo"
            name="displayResult"
            class="custom-control-input"
            bind:group={testObj.displayResultImmediately}
            value={false}
          />
          <label class="custom-control-label" for="resultImmeNo"
            >After Test</label
          >
        </div>
      </div>
      {#if errorObj.displayResultImmediately}
        <div class="col-sm-3"></div>
        <div class="col-sm-9">
          <div class="parsley-errors-list">
            {errorObj.displayResultImmediately}
          </div>
        </div>
      {/if}
    </div>
    {#if !testObj.displayResultImmediately}
      <div class="form-group row fadeIn">
        <label class="col-sm-3 col-form-label"
          >Result Time <span class="required-field">*</span></label
        >
        <div class="col-sm-5">
          <input
            disabled={testObj.publish}
            type="date"
            onkeydown="return false"
            value={dateObj.resultDate
              ? convertDateObjToDateStr(dateObj.resultDate)
              : ""}
            min={convertDateObjToDateStr(dateObj.endDate)}
            class="form-control"
            class:parsley-error={errorObj.resultDate && !dateObj.resultDate}
            placeholder="Select Date"
            on:change={(event) => onDateChange("resultDate", event)}
          />
        </div>
        <div class="col-sm-4">
          <input
            disabled={testObj.publish}
            type="text"
            bind:this={elem_testResultTime}
            class="form-control"
            class:parsley-error={errorObj.resultDate && !dateObj.resultTime}
            onkeydown="return false"
          />
        </div>
        {#if errorObj.resultDate}
          <div class="col-sm-3"></div>
          <div class="col-sm-9">
            <div class="parsley-errors-list">{errorObj.resultDate}</div>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</div>
