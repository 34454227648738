<script>
    import { Router, Link, links, Route, navigate } from "svelte-routing";
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import ButtonLoader from "../utils/button_loader.svelte";
    import { onMount } from "svelte";
    import { activeMenu } from "../stores";
    import MODULES from "../utils/modules.js"
    import { API_URL } from "../../config.js"


    let showModal = false;
    let questionsDataTable;
    let isLoading = false;
    let demoDataTable;
    let filterModalThis;
    let selectizeBatchInstance;
    let filterElemBatch;
    let selectizeBatchInstance1;
    let filterElemBatch1;

    let batchesList;
    let filterByStudentName;
    let batchName, status, requestedOn, requestedto;
    let emptyState = true;
    let userIdArr

    async function api_addQuestion(data) {
        let action = "questions.add";
        var response = await api_handleApiCalls(action, data);
        return response;
    }

    async function api_getBatchesList() {
        var resData = await api_handleApiCalls('batch.getMyBatches', {})
        if (resData.status) {
            return resData.data
        } else {
            return []
        }
    }

    async function api_deleteJoiningRequest(studentId, batchId) {
        var resData = await api_handleApiCalls('batch.deleteJoiningRequest', { studentId, batchId })
        if (resData.status) {
            return resData.data
        } else {
            return resData
        }
    }

    async function api_getStudentsList() {
        var resData = await api_handleApiCalls('students.list', {})
        if (resData.status) {

            return resData.data.data
        } else {
            return []
        }
    }

    async function getFilterDataList() {
        batchesList = await api_getBatchesList()
        userIdArr = await api_getStudentsList()
        bindSelect2s()
    }

    function bindSelect2s() {
        selectizeBatchInstance = _$(filterElemBatch).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "Select Batches",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: batchesList,
        });


        selectizeBatchInstance1 = _$(filterElemBatch1).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "select student",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: userIdArr,
        });
    }

    function parseSorts(sortOrder) {
        // parses datatable order to mongodb sort object
        let sort = {};
        // sorting orders
        let order;
        if (sortOrder.dir === "asc") order = 1;
        else order = -1;
        if (sortOrder.column === 1) {
            sort.requestId = order;
        } else if (sortOrder.column === 2) {
            sort._id = order;
        } else if (sortOrder.column === 3) {
            sort._id = order;
        } else if (sortOrder.column === 4) {
            sort._id = order;
        } else if (sortOrder.column === 5) {
            sort.status = order;
        } else {
            sort._id = order;
        }
        return sort;
    }

    function bindDatatable() {
        questionsDataTable = _$(demoDataTable).DataTable({
            responsive: true,
            language: {
                processing: "",
                searchPlaceholder: 'Student Name | Batch',
                sSearch: '',
                lengthMenu: '_MENU_ Requests/page',
            },
            ajax: function (data, callback, settings) {
                data.sort = parseSorts(data.order ? data.order[0] : { 0: "asc" });
                var filterObj = { ...getFilterData() }
                filterObj.start = data.start
                filterObj.length = data.length
                filterObj.search = data.search.value
                filterObj.draw = data.draw
                filterObj.sort = data.sort;
                _$.ajax({
                    url: API_URL,
                    method: 'post',
                    data: {
                        action: 'batch.getJoinRequest',
                        accessToken: localStorage.getItem("accessToken"),
                        data: filterObj
                    },
                    success: function (resData) {
                        var result = []
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.total_count),
                                recordsFiltered: Number(paginationData.filtered_count),
                                data: paginationData.data
                            }
                            if (resData?.data?.data.length) {

                                emptyState = false;
                            }
                            callback(result);
                        }
                        else {
                            console.log("Something went wrong");
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        // emptyState = true
                    }
                })
            },
            columns: [
                {
                    data: 'requestId', render: function (data, type, full) {
                        return data;
                    }
                },
                {
                    data: '_id', render: function (data, type, full) {
                        let image;
                        // if image found display profilePic avatar
                        image = `<img src="${full.studentDetails.profilePic ? full.studentDetails.profilePic : "/images/man.png"
                            }" class="avatar rounded-circle mg-r-15" alt="${data[0].toUpperCase()}">`;
                        return `
                     <div style = "cursor:pointer" class="list-group-item d-flex  align-items-center bd-0 bg-transparent pd-0 btn-edit-student" student-id="${full.studentId}" batch-id="${full.batchId}">
                     ${image}
                     <div class="wd-80p bg-transparent">
                     <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent btn-edit-student tx-capitalize" student-id="${full.studentId}" batch-id="${full.batchId}">${full.studentDetails.name || "-"
                            }</h6>
                     <span class="d-block tx-11 text-muted bg-transparent">
                     <i data-feather="phone" class="wd-10"></i> 
                     ${full.studentDetails.mobileNo || "-"}
                     </span>
                     </div>
                     </div>`;
                    }
                },
                {
                    data: '_id', render: function (data, type, full) {
                        let batchName = full.batchDetails.name;
                        return `<div class="d-flex justify-content-center">${batchName}<div>`
                    }
                },
                {
                    data: '_id', render: function (data, type, full) {
                        let d = new Date(full.createdOn).toLocaleString('en-GB')
                        return `<div class="d-flex justify-content-center">${d}<div>`
                    }
                },
                {
                    data: 'status', render: function (data, type, full) {
                        return `<div class="d-flex justify-content-center">${data}<div>`
                    }
                },
                {
                    data: '_id', render: function (data, a, full, c) {
                        var edit = "-"
                        var reject = ""
                        if (MODULES.hasAccess(MODULES.ACCEPT_REJECT_JOB_REQUEST)) {
                            edit = `<a href="#" class="rename mg-r-5 btn-edit-student" student-id="${full.studentDetails._id}" batch-id="${full.batchDetails._id}"  data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>`
                            reject = `<a href="#" class="rename mg-r-5 btn-reject-student" student-id="${full.studentDetails._id}" batch-id="${full.batchDetails._id}"  data-toggle="tooltip" data-placement="top" title="Reject request"><i class="wd-20 mg-r-5" data-feather="trash-2"></i></a>`
                        }
                        return `
                        <div class="d-md-flex flex-row justify-content-center">
                            ${edit}
                            ${reject}
                        </div>
                        `;
                    }
                },

            ],
            "drawCallback": function () {
                feather.replace()
                _$('.btn-edit-student').unbind('click').click(function (e) {
                    e.preventDefault();
                    var studentId = _$(this).attr("student-id");
                    var batchId = _$(this).attr("batch-id");
                    navigate(("/dashboard/students/info/" + studentId + "/batch-info/assignNew/" + batchId))
                    return false;
                })

                _$('.btn-reject-student').unbind('click').click(async function (e) {
                    e.preventDefault();
                    var studentId = _$(this).attr("student-id");
                    var batchId = _$(this).attr("batch-id");
                    const notice = PNotify.notice({
                        title: "Delete batch joining request",
                        text: "Are you sure you want to delete this joining request ?",
                        hide: false,
                        closer: false,
                        sticker: false,
                        modules: new Map([
                            ...PNotify.defaultModules,
                            [PNotifyConfirm, { confirm: true }],
                        ]),
                    })

                    notice.on("pnotify:confirm", async () => {
                        try {
                            var res = await api_deleteJoiningRequest(studentId, batchId);
                            if (res) {
                                console.log(res);
                                PNotify.success({
                                    title: 'Success',
                                    text: "Joining request deleted successfully",
                                    delay: 3000
                                })
                            } else {
                                PNotify.error({
                                    title: 'Error',
                                    text: res.errMsg,
                                    delay: 3000
                                })
                            }

                        } catch (error) {
                            console.error(error);
                        }
                        questionsDataTable.ajax.reload();
                    })
                    // var res = await api_deleteJoiningRequest(studentId, batchId);

                    return false;
                })
            },
            "processing": true,
            "serverSide": true,
        });
        _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
    }

    onMount(async () => {
        feather.replace();
        activeMenu.set('batchJoinRequest')
        bindDatatable()
        getFilterDataList();

    })

    let onCloseModal = async function (event) {
        showModal = !showModal;
        console.log("selectedCategory : ", event.detail.selectedCategory);
        var qId = await api_addQuestion(event.detail.selectedCategory);
        console.log('qId', qId.data.questionId);
        navigate(("/dashboard/questions/view/" + qId.data.questionId))

        _$('.modal-backdrop').remove()
    }

    function getFilterData() {
        var batchList = _$(selectizeBatchInstance).val()
        var userIdArr = _$(selectizeBatchInstance1).val()
        return {
            batchList,
            userIdArr,
            filterByStudentName,
            status,
            requestedOn,
            requestedto,
        }
    }

    function applyFilter() {
        redrawQuestionsTable();
    }
    function redrawQuestionsTable() {
        if (questionsDataTable) { questionsDataTable.draw() }
    }
    function clearFilter() {
        selectizeBatchInstance[0].selectize.clear()
        selectizeBatchInstance1[0].selectize.clear();
        status = null;
        requestedOn = null;
        requestedto = null;
        redrawQuestionsTable();
    }

    function showHideFilterModal(action) {
        action ? (_$(filterModalThis).modal('show')) : (_$(filterModalThis).modal('hide'))
    }
</script>

<div class="content-body fadeIn">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Batch Join Requests
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                Batch Join Requests
            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search the joining requests for the
                available batches.</p>

        </div>
        <div class="d-block">
            <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                on:click={()=>{showHideFilterModal(true)}}>
                <i data-feather="filter" class="wd-10 mg-r-5"></i> Filter
            </button>
        </div>

    </div>
    <!-- <hr class="mg-y-20"> -->
    <hr class="full-width">
    <div data-label="Example" class="df-example demo-table">
        <table bind:this={demoDataTable} class="table" style="width:100%">
            <thead>
                <tr>
                    <th style="width: 5%;">#</th>
                    <th style="width: 30%;">Student Name</th>
                    <th style="width: 20%; text-align: center;">Batch</th>
                    <th style="width: 15%; text-align: center;">Requested On</th>
                    <th style="width: 10%; text-align: center;">Status </th>
                    <th style="width: 10%; text-align: center;">Action </th>
                </tr>
            </thead>
            <tbody></tbody>

        </table>
    </div>

    {#if emptyState}
    <div class="d-flex align-items-center justify-content-center " style="flex-direction:column;">
        <div class="" style="width:250px">
            <img src="/images/empty.svg" class="img-fluid" alt="No Result" />
            <p class="tx-bold">
                No Joining requests available.
            </p>
        </div>
        <!-- <div class="" style="text-align:center;">Click <a href="/dashboard/students/info/{studentId}/batch-info" use:links class="">here</a> to assign a batch and enable payments</div> -->
    </div>
    {/if}
</div>

<!-- Filter Modal -->
<div class="modal effect-scale" bind:this={filterModalThis} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <!-- <div class="form-group">
                    <label class="d-block flex-grow-1">Status:</label>
                    <div class="d-md-flex">
                        <div class="custom-control custom-radio mg-r-15">
                            <input type="radio" id="pending" bind:group={status} class="custom-control-input" value='pending'>
                            <label class="custom-control-label" for="pending">Pending</label>
                        </div>
                    </div>
                </div> -->

                <div class="form-group">
                    <label class="d-block">Select Batches</label>
                    <select disabled={isLoading} bind:this={filterElemBatch} class="form-control select2"
                        multiple="multiple">
                        <option>Select batches</option>
                    </select>
                </div>


                <div class="form-row pd-12 pd-t-7 pd-b-7">

                    <div class="form-group col-md-6">
                        <label class="d-block">Requested From :</label>
                        <input type="date" onkeydown="return false" bind:value={requestedOn}
                            class="form-control follow-up-date" />
                    </div>
                    <div class="form-group col-md-6">
                        <label class="d-block">Requested To :</label>
                        <input type="date" onkeydown="return false" bind:value={requestedto}
                            class="form-control follow-up-date" placeholder="Student created on date" />
                    </div>

                </div>

                <div class="form-group">
                    <label class="d-block">Select student:</label>
                    <select disabled={isLoading} bind:this={filterElemBatch1} class="form-control select2"
                        multiple="multiple">
                        <option>Select student</option>
                    </select>
                </div>


                <div class="d-md-flex justify-content-end mg-t-25">
                    <button disabled={isLoading} on:click={clearFilter}
                        class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal">CLEAR
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button disabled={isLoading} on:click={applyFilter} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
                        data-dismiss="modal">
                        <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                        <i data-feather="filter" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Modal -->
