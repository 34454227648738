<script>
    import { navigate, Link, links } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import { activeMenu } from "../../stores";
    import moment from "moment";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import MODULES from "../../utils/modules.js";
    import { userData, fileExplorerFilter } from "../../stores";

    let batchList = [];
    let fileList = [];
    let previewVideoSrc;
    let linkArr = [];
    let folderCount = [];
    let _fileId;
    let search = { value: "" };
    let modalVideoPreview;
    let vidPreview;
    let filterObj ={
        clearFilter:true
    }
    let folderRedrawLock = false;
    let searchTimeout
    let isLoading = false;
    async function api_getBatchList(data) {
        let action = "batch.getMyBatches";
        var response = await api_handleApiCalls(action, data);
        return response.data;
    }

    async function api_getRecentFiles(data) {
        let action = "folder.getRecentFiles";
        var response = await api_handleApiCalls(action, null);
        return response.data;
    }

    function parseSorts(sortOrder) {
        // parses datatable order to mongodb sort object
        let sort = {};
        // sorting orders
        let order;
        if (sortOrder.dir === "asc") order = 1;
        else order = -1;
        // if (sortOrder.column === 1) {
        //   sort.name = order;
        // } else if (sortOrder.column === 2) {
        //   sort.createdOn = order;
        // } else {
        //   sort.seqId = order;
        // }
        sort.seqId = order;
        return sort;
    }

    async function api_getfileFolderCount(data) {
        let action = "studyMaterial.getFileFolderCount";
        var response = await api_handleApiCalls(action, data);
        return response.data;
    }

    let fileFolderCount = async function (batchList) {
        var batchIdArr = batchList.map((item) => {
            return item._id;
        });
        return await api_getfileFolderCount(batchIdArr);
    };

    function mapCount(folderCount) {
        for (var i = 0; i < folderCount.length; i++) {
            let index = batchList.findIndex(
                (item) => item._id == folderCount[i]._id
            );
            batchList[index].count = folderCount[i].count;
            // if(batchList[i]._id == folderCount[i]._id){
            //     batchList[i].count = folderCount[i].count;
            // }else{
            //     batchList[i].count = 0;
            // }
        }
    }

    let loadData = async function () {
        // check if redraw is locked
        if (folderRedrawLock) return;
        // lock redraw function
        folderRedrawLock = true;

        // code that will execute regardless of filter -> start
        let data = {
            start: 0,
            length: 10,
            search: search.value,
            sort: parseSorts({ 0: "dsc" }),
        };
        batchList = await api_getBatchList(data);
        tick();
        if (filterObj == null) fileList = await api_getRecentFiles();
        else fileList = [];
        tick();

        folderCount = await fileFolderCount(batchList);
        mapCount(folderCount);
        // code that will execute regardless of filter -> end
        // code that will execute for filter -> start
        // check filter is aplied or not
        if ( filterObj != null) {

            let childrenList = [];
            if (filterObj.batchIds) {
                    batchList = await api_getBatchList({
                        batchId: filterObj.batchIds,
                    });

                    childrenList = await api_getChildren({
                        batchId: filterObj.batchIds,
                        parentId: filterObj.batchIds,
                    });
                    filterFilesList(childrenList);
            } else {
                    batchList = await api_getBatchList({});
                    for (let i = 0; i < batchList.length; i++) {
                        let batchId = batchList[i]._id;
                        childrenList = await api_getChildren({
                            batchId: batchId,
                            parentId: batchId,
                        });
                        filterFilesList(childrenList);
                    }
                }
                folderCount = await fileFolderCount(batchList);
                mapCount(folderCount);
        } else {
            // end loading
            isLoading = false;
            // allow execution of redraw function again
            folderRedrawLock = false;
        }
        // code that will execute for filter -> end

        await tick();
        feather.replace();
    };
    fileExplorerFilter.subscribe((value) => {
        filterObj = value;

        loadData();
    });
    async function filterFilesList(childrenList) {
        let folderListArr = [...childrenList];
        // start loading
        isLoading = true;
        function updateFilesList() {
            // function to update list of files being rendered
            let fileFolderListArrIndex = 0;
            let lockFilesListLoop = false;
            let fileFolderInterval;
            function fileListEnded(i, length) {
                // exit from loop
                if (i == length - 1) {
                    // clear loop interval
                    if (fileFolderInterval) clearInterval(fileFolderInterval);
                    // allow execution of redraw function again
                    folderRedrawLock = false;
                    // end loading
                    isLoading = false;
                    // return true when files list is ended
                    return true;
                }
                // return false when files list is not ended
                return false;
            }
            fileFolderInterval = setInterval(() => {
                // check if loop is locked
                if (!lockFilesListLoop) {
                    if (fileFolderListArrIndex < folderListArr.length) {
                        // lock looping
                        lockFilesListLoop = true;
                        // console.log("fileFolderListArrIndex => ", fileFolderListArrIndex)
                        // bring new files
                        let folderParentId =
                            folderListArr[fileFolderListArrIndex]._id;
                        api_getFilesInsideFolder({
                            folderId: folderParentId,
                            filterObj,
                        })
                            .then((resp) => {
                                // files found, push into array
                                fileList.push(...resp);
                                fileList = fileList;
                                // check if we should stop interval now
                                if (
                                    !fileListEnded(
                                        fileFolderListArrIndex,
                                        folderListArr.length
                                    )
                                ) {
                                    // increment index for next file
                                    fileFolderListArrIndex++;
                                }
                                // unlock looping
                                lockFilesListLoop = false;
                            })
                            .catch((e) => {
                                // check if we should stop interval now
                                if (
                                    !fileListEnded(
                                        fileFolderListArrIndex,
                                        folderListArr.length
                                    )
                                ) {
                                    // increment index for next file
                                    fileFolderListArrIndex++;
                                }
                                // unlock looping
                                lockFilesListLoop = false;
                            });
                    } else {
                        // clear loop interval
                        if (fileFolderInterval)
                            clearInterval(fileFolderInterval);
                        // unlock loop
                        lockFoldersListLoop = false;
                    }
                }
            }, 500);
        }
        let lockFoldersListLoop = false;
        if (folderListArr.length) {
            let folderListArrIndex = 0;
            let folderInterval = setInterval(() => {
                // check if loop is locked
                if (!lockFoldersListLoop) {
                    // check if there are more folders in list
                    if (folderListArrIndex < folderListArr.length) {
                        // lock loop
                        lockFoldersListLoop = true;
                        // console.log("folderListArrIndex => ", folderListArrIndex)
                        let folderParentId =
                            folderListArr[folderListArrIndex]._id;
                        let folderBatchId =
                            folderListArr[folderListArrIndex].batchId;
                        // bring folders from current folder
                        api_getChildren({
                            batchId: folderBatchId,
                            parentId: folderParentId,
                        })
                            .then((resp) => {
                                // new folders found, push into array
                                folderListArr.push(...resp);
                                // check if folders are ended from list
                                if (
                                    folderListArrIndex ==
                                    folderListArr.length - 1
                                ) {
                                    // now that all folders are found, search for files
                                    updateFilesList();
                                    // clear loop interval
                                    if (folderInterval)
                                        clearInterval(folderInterval);
                                } else {
                                    // increment index for next file
                                    folderListArrIndex++;
                                }
                                // unlock folders loop
                                lockFoldersListLoop = false;
                            })
                            .catch((e) => {
                                if (
                                    folderListArrIndex ==
                                    folderListArr.length - 1
                                ) {
                                    // now that all folders are found, search for files
                                    updateFilesList();
                                    // clear loop interval
                                    if (folderInterval)
                                        clearInterval(folderInterval);
                                } else {
                                    // increment index for next file
                                    folderListArrIndex++;
                                }
                                // unlock folders loop
                                lockFoldersListLoop = false;
                            });
                    } else {
                        updateFilesList();
                        // clear loop interval
                        if (folderInterval) clearInterval(folderInterval);
                        // unlock folders loop
                        lockFoldersListLoop = false;
                    }
                }
            }, 500);
        } else {
            // end loading
            isLoading = false;
            // allow execution of redraw function again
            folderRedrawLock = false;
        }
    }
    async function api_getFilesInsideFolder(data) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let payload = JSON.stringify({
            action: "folder.getFilesInsideFolder",
            accessToken: localStorage.getItem("accessToken"),
            data: data,
        });
        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: payload,
        };
        try {
            let resObj = await fetch(API_URL, requestOptions);
            let response = await resObj.json();
            return response.data;
        } catch (error) {
            console.error("error", error);
            return { error: error };
        }
    }

    function getColorByFile(fileObj) {
        const typeRouting = {
            pdf: "tx-danger",
            image: "tx-indigo",
            externalLink: "tx-gray-600",
            refLink: "tx-gray-600",
            liveLecture: "tx-primary",
            recordedLecture: "tx-pink",
            youtubeLecture: "tx-danger",
        };
        return typeRouting[fileObj.type];
    }

    function getTypeByFile(fileObj) {
        const typeRouting = {
            pdf: "pdf",
            image: "images",
            externalLink: "externalLink",
            refLink: "refLink",
            liveLecture: "liveLecture",
            recordedLecture: "recordedLecture",
            youtubeLecture: "youtubeLecture",
        };
        return typeRouting[fileObj.type];
    }

    function getIconByFile(fileObj) {
        const typeRouting = {
            pdf: "pdf",
            image: "png",
            externalLink: "ai",
            refLink: "ai",
            liveLecture: fileObj.lecture_type,
            recordedLecture: "mp4",
            youtubeLecture: "youtube",
        };
        // tx-indigo
        return typeRouting[fileObj.type];
    }

    let showModalVideoPreview = async (file) => {
        let res = await api_handleApiCalls("studyMaterial.getDownloadUrl", {
            _id: file._id,
        });
        previewVideoSrc = res.data.downloadUrl;
        await tick();
        _$(modalVideoPreview).modal("show");
    };

    async function api_getChildren(data) {
        let action = "folder.getChildren";
        var response = await api_handleApiCalls(action, data);
        return response.data;
    }
    onMount(() => {
        loadData();
        _$("#batchSelectize").removeClass('d-none')

        feather.replace();

        _$(modalVideoPreview).on("hidden.bs.modal", async function (e) {
            previewVideoSrc = "";
            vidPreview.pause();
            await tick();
        });
    });

    let fileSizeView = (fileSize) => {
        if (fileSize > 1000000) {
            return (fileSize / 1e6).toFixed(3) + " MB";
        } else {
            return (fileSize / 1000).toFixed(3) + " KB";
        }
    };

    async function api_removeFileLinkToFolder(data) {
        let action = "files.removeFileLinkToFolder";
        var response = await api_handleApiCalls(action, data);
        return response;
    }

    async function api_removeAllFileLinkToFolder(fileId) {
        let action = "files.removeAllFileLinkToFolder";
        var response = await api_handleApiCalls(action, { fileId: fileId });
        return response;
    }

    let deleteLink = async (linkId) => {
        var linkRes = await api_removeFileLinkToFolder({ _id: linkId });
        if (linkRes.status) {
            pSuccess({ title: "Success", msg: linkRes.data });
            renderLinks();
        } else {
            // Failed to create
            pError({
                title: "Error",
                msg: linkRes?.errMsg ?? "Something went wrong",
            });
        }
    };

    async function api_loadLinksForFile(data) {
        let action = "files.loadLinksForFile";
        var response = await api_handleApiCalls(action, data);
        return response;
    }

    let renderLinks = async function (fileId) {
        var templinkArr = await api_loadLinksForFile({
            fileId: fileId,
        });
        linkArr = templinkArr.data.fileFolderLinkArr;
        tick();
        setTimeout(function () {
            feather.replace();
        }, 10);
    };

    async function api_deletePermanently(data) {
        let action = "studyMaterial.deleteFilePermanently";
        var response = await api_handleApiCalls(action, { fileId: data });
        return response;
    }

    async function handleDeletePermanently() {
        let res = await api_deletePermanently(_fileId);
        if (res.status) {
            fileList = fileList.filter((j) => j._id != _fileId);
            PNotify.success({
                title: "Success",
                text: "Current file has been deleted successfully.",
                delay: 3000,
            });
        } else {
            PNotify.error({
                title: "Error",
                text: res.errMsg,
                delay: 3000,
            });
        }

        _$("#delete-modal").modal("hide");
    }

    async function handleDelete(fileId) {
        _fileId = fileId;
        renderLinks(fileId);
        _$("#delete-modal").modal("show");
        return;
    }

    async function handleUnlinkAll() {
        let res = await api_removeAllFileLinkToFolder(_fileId);
        if (res.status) {
            fileList = fileList.filter((j) => j._id != _fileId);
            PNotify.success({
                title: "Success",
                text: "Current file has been unlinked successfully.",
                delay: 3000,
            });
        } else {
            PNotify.error({
                title: "Error",
                text: res.errMsg,
                delay: 3000,
            });
        }
        _$("#delete-modal").modal("hide");
    }
</script>

<div class="pd-20 pd-lg-25 pd-xl-30">
    <hr class="bd-0 btn mg-y-40" />
    <form
        class="search-form mg-r-5 float-right"
        on:submit|preventDefault={async (event) => {
            await loadData(0, event);
        }}
    >
        <input
            type="search"
            class="form-control wd-300 mg-y-40 "
            placeholder="Batch name "
            bind:value={search.value}
            on:input={(event) => {
                if(searchTimeout) clearTimeout(searchTimeout)
                searchTimeout = setTimeout(() => {
                    loadData(0, event);
                }, 2 * 1000);
            }}
        />
        <button class="btn mg-y-40" type="submit"
            ><i data-feather="search" /></button
        >
    </form>
    <label
        class="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15"
        >Batches</label
    >
    <div class="row row-xs">
        {#if MODULES.hasAccess(MODULES.VIEW_BATCH_STUDY_MATERIAL)}
            {#each batchList as item}
                {#if item.isRootBatch || item.isFree == "yes"}
                    <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                        <a
                            href={"/dashboard/studyMaterial/folder/" + item._id}
                            use:links
                            class="link-02"
                        >
                            <div class="media media-folder">
                                <i data-feather="folder" />
                                <div class="media-body">
                                    <h6>{item.name}</h6>
                                    <span>
                                        Total Folders : {item.count
                                            ? item.count
                                            : 0}
                                    </span>
                                </div>
                                <!-- media-body -->
                                <!-- <div class="dropdown-file">
                        <a href="" class="dropdown-link" data-toggle="dropdown"><i data-feather="more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="#modalViewDetails" data-toggle="modal" class="dropdown-item details"><i
                                    data-feather="info"></i>View Details</a>
                            <a href="" class="dropdown-item important"><i data-feather="star"></i>Mark as
                                Important</a>
                            <a href="#modalShare" data-toggle="modal" class="dropdown-item share"><i
                                    data-feather="share"></i>Share</a>
                            <a href="" class="dropdown-item download"><i data-feather="download"></i>Download</a>
                            <a href="#modalCopy" data-toggle="modal" class="dropdown-item copy"><i data-feather="copy"></i>Copy
                                to</a>
                            <a href="#modalMove" data-toggle="modal" class="dropdown-item move"><i data-feather="folder"></i>Move
                                to</a>
                            <a href="#" class="dropdown-item rename"><i data-feather="edit"></i>Rename</a>
                            <a href="#" class="dropdown-item delete"><i data-feather="trash"></i>Delete</a>
                        </div>
                    </div> -->
                            </div>
                            <!-- media -->
                        </a>
                    </div>
                    <!-- col -->
                {/if}
            {:else}
                <div class="d-flex justify-content-center" style="width: 100%;">
                    <img
                        style="max-width: 200px;
            width: 200px;"
                        src="/assets/folder_empty.svg"
                        alt=""
                    />
                </div>
                <p
                    class="mg-t-10 tx-gray-500"
                    style="text-align: center;width: 100%;"
                >
                    Folders not found
                </p>
            {/each}
        {/if}
    </div>
    <!-- row -->

    <hr class="mg-y-40 bd-0" />
    {#if filterObj != null}
        <label
            class="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15"
            >Files</label
        >
    {:else}
        <label
            class="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15"
            >Recently Added Files</label
        >
    {/if}
    <div class="row row-xs">
        {#if MODULES.hasAccess(MODULES.VIEW_BATCH_STUDY_MATERIAL)}
            {#if filterObj != null}
                {#each fileList as item}
                    {#if item && item.filesArr && (item.filesArr.type == "externalLink" || item.filesArr.type == "refLink")}
                        <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                            <div class="card card-file">
                                {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL) && MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                                    <div class="dropdown-file">
                                        <a
                                            href=""
                                            class="dropdown-link"
                                            data-toggle="dropdown"
                                            ><i
                                                data-feather="more-vertical"
                                            /></a
                                        >
                                        <div
                                            class="dropdown-menu dropdown-menu-right"
                                        >
                                            {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL)}
                                                <a
                                                    href={`/dashboard/studyMaterial/${getTypeByFile(
                                                        item.filesArr
                                                    )}/edit/${
                                                        item.filesArr._id
                                                    }`}
                                                    use:links
                                                    class="dropdown-item details"
                                                    ><i
                                                        data-feather="info"
                                                    />View Details</a
                                                >
                                            {/if}
                                            <!-- <a href="" class="dropdown-item important"><i data-feather="star"></i>Mark as
                                    Important</a>
                                <a href="#modalShare" data-toggle="modal" class="dropdown-item share"><i
                                        data-feather="share"></i>Share</a>
                                <a href="" class="dropdown-item download"><i data-feather="download"></i>Download</a>
                                <a href="#modalCopy" data-toggle="modal" class="dropdown-item copy"><i
                                        data-feather="copy"></i>Copy
                                    to</a>
                                <a href="#modalMove" data-toggle="modal" class="dropdown-item move"><i
                                        data-feather="folder"></i>Move
                                    to</a> -->
                                            <!-- <a href="#" class="dropdown-item rename" on:click={handleRename(item.fileId)}><i data-feather="edit"></i>Rename</a> -->
                                            {#if MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                                                <a
                                                    href="#"
                                                    class="dropdown-item delete"
                                                    on:click={() => {
                                                        handleDelete(
                                                            item.filesArr._id
                                                        );
                                                    }}
                                                    ><i
                                                        data-feather="trash"
                                                    />Delete</a
                                                >
                                            {/if}
                                        </div>
                                    </div>
                                    <!-- dropdown -->
                                {/if}
                                <div
                                    class="card-file-thumb {getColorByFile(
                                        item.filesArr
                                    )}"
                                    style="cursor: pointer;"
                                    on:click={() => {
                                        navigate(
                                            `/dashboard/studyMaterial/${getTypeByFile(
                                                item.filesArr
                                            )}/edit/${item.filesArr._id}`
                                        );
                                    }}
                                >
                                    <!-- <i class="far {(getIconByFile(item.filesArr))}"></i> -->
                                    <img
                                        style="width: 80%; height: 80%; padding: 5px; cursor: pointer;"
                                        src={"/assets/" +
                                            getIconByFile(item.filesArr) +
                                            ".svg"}
                                        alt=""
                                    />
                                </div>
                                <div class="card-body" use:links>
                                    <h6>
                                        <a
                                            href={`/dashboard/studyMaterial/${getTypeByFile(
                                                item.filesArr
                                            )}/edit/${item.filesArr._id}`}
                                            class="link-02"
                                            >{item.filesArr.name}</a
                                        >
                                    </h6>
                                    <!-- <span>{fileSizeView(item.filesArr.fileSize)}</span> -->
                                </div>
                                <div class="card-footer">
                                    <span class="d-none d-sm-inline"
                                        >Created On:
                                    </span>{moment(
                                        item.filesArr.createdOn
                                    ).format("DD-MMM-YYYY")}
                                </div>
                                <div>
                                    {#if Array.isArray(item.filesArr.tags)}
                                        {#each item.filesArr.tags as tag}
                                            <span
                                                class="badge badge-light mg-r-5"
                                                style="font-size: 10px; color: #636363;"
                                                >{tag}</span
                                            >
                                        {/each}
                                    {/if}
                                </div>
                            </div>
                        </div>
                        <!-- col -->
                    {/if}
                    {#if (item && item.filesArr && item.filesArr.originalFileName) || (item && item.filesArr && item.filesArr.type == "liveLecture")}
                        <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                            <div class="card card-file">
                                {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL) && MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                                    <div class="dropdown-file">
                                        <a
                                            href=""
                                            class="dropdown-link"
                                            data-toggle="dropdown"
                                            ><i
                                                data-feather="more-vertical"
                                            /></a
                                        >
                                        <div
                                            class="dropdown-menu dropdown-menu-right"
                                        >
                                            {#if item.filesArr.type == "recordedLecture"}
                                                {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL)}
                                                    <a
                                                        href="#"
                                                        on:click={() => {
                                                            showModalVideoPreview(
                                                                item.filesArr
                                                            );
                                                        }}
                                                        data-toggle="modal"
                                                        class="dropdown-item details"
                                                    >
                                                        <i
                                                            data-feather="info"
                                                        />View Details</a
                                                    >
                                                {/if}
                                            {:else if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL)}
                                                <a
                                                    href={`/dashboard/studyMaterial/${getTypeByFile(
                                                        item.filesArr
                                                    )}/edit/${
                                                        item.filesArr._id
                                                    }`}
                                                    use:links
                                                    class="dropdown-item details"
                                                    ><i
                                                        data-feather="info"
                                                    />View Details</a
                                                >
                                            {/if}

                                            <!-- <a href="" class="dropdown-item important"><i data-feather="star"></i>Mark as
                                    Important</a>
                                <a href="#modalShare" data-toggle="modal" class="dropdown-item share"><i
                                        data-feather="share"></i>Share</a>
                                <a href="" class="dropdown-item download"><i data-feather="download"></i>Download</a>
                                <a href="#modalCopy" data-toggle="modal" class="dropdown-item copy"><i
                                        data-feather="copy"></i>Copy
                                    to</a>
                                <a href="#modalMove" data-toggle="modal" class="dropdown-item move"><i
                                        data-feather="folder"></i>Move
                                    to</a> -->
                                            <!-- <a href="#" class="dropdown-item rename" on:click={handleRename(item.fileId)}><i data-feather="edit"></i>Rename</a> -->
                                            {#if MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                                                <a
                                                    href="#"
                                                    class="dropdown-item delete"
                                                    on:click={() => {
                                                        handleDelete(
                                                            item.filesArr._id
                                                        );
                                                    }}
                                                    ><i
                                                        data-feather="trash"
                                                    />Delete</a
                                                >
                                            {/if}
                                        </div>
                                    </div>
                                    <!-- dropdown -->
                                {/if}
                                <div
                                    class="card-file-thumb {getColorByFile(
                                        item.filesArr
                                    )}"
                                    style="cursor: pointer;"
                                    on:click={() => {
                                        navigate(
                                            `/dashboard/studyMaterial/${getTypeByFile(
                                                item.filesArr
                                            )}/edit/${item.filesArr._id}`
                                        );
                                    }}
                                >
                                    <!-- <i class="far {(getIconByFile(item.filesArr))}"></i> -->
                                    <img
                                        style="width: 80%; height: 80%; padding: 5px; cursor: pointer;"
                                        src={"/assets/" +
                                            getIconByFile(item.filesArr) +
                                            ".svg"}
                                        alt=""
                                    />
                                </div>
                                <div class="card-body" use:links>
                                    {#if item.filesArr.type == "liveLecture"}
                                        <h6>
                                            <a
                                                href={`/dashboard/studyMaterial/${getTypeByFile(
                                                    item.filesArr
                                                )}/edit/${item.filesArr._id}`}
                                                class="link-02"
                                                >{item.filesArr.name}</a
                                            >
                                        </h6>
                                        <p
                                            class="tx-12 mg-t-8"
                                            style="color: #777"
                                        >
                                            Start:
                                            {moment(item.filesArr.from).format(
                                                "DD-MM-YYYY HH:mm:ss"
                                            )} &nbsp; End:
                                            {moment(item.filesArr.end).format(
                                                "DD-MM-YYYY HH:mm:ss"
                                            )}
                                        </p>
                                    {:else}
                                        <h6>
                                            <a
                                                href={`/dashboard/studyMaterial/${getTypeByFile(
                                                    item.filesArr
                                                )}/edit/${item.filesArr._id}`}
                                                class="link-02"
                                                >{item.filesArr.name}</a
                                            >
                                        </h6>
                                        <p
                                            class="tx-12 mg-t-8"
                                            style="color: #777"
                                        >
                                            {item.filesArr.originalFileName}
                                        </p>

                                        <span
                                            >{fileSizeView(
                                                item.filesArr.fileSize
                                            )}</span
                                        >
                                    {/if}
                                </div>
                                <div class="card-footer">
                                    <span class="d-none d-sm-inline"
                                        >Created On:
                                    </span>{moment(
                                        item.filesArr.createdOn
                                    ).format("DD-MMM-YYYY")}
                                </div>
                                <div>
                                    {#if Array.isArray(item.filesArr.tags)}
                                        {#each item.filesArr.tags as tag}
                                            <span
                                                class="badge badge-light mg-r-5"
                                                style="font-size: 10px; color: #636363;"
                                                >{tag}</span
                                            >
                                        {/each}
                                    {/if}
                                </div>
                            </div>
                        </div>
                        <!-- col -->
                    {/if}
                    {#if item && item.filesArr && item.filesArr.type == "youtubeLecture"}
                        <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                            <div class="card card-file">
                                {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL) && MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                                    <div class="dropdown-file">
                                        <a
                                            href=""
                                            class="dropdown-link"
                                            data-toggle="dropdown"
                                            ><i
                                                data-feather="more-vertical"
                                            /></a
                                        >
                                        <div
                                            class="dropdown-menu dropdown-menu-right"
                                        >
                                            {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL)}
                                                <a
                                                    href={`/dashboard/studyMaterial/${getTypeByFile(
                                                        item.filesArr
                                                    )}/edit/${
                                                        item.filesArr._id
                                                    }`}
                                                    use:links
                                                    class="dropdown-item details"
                                                    ><i
                                                        data-feather="info"
                                                    />View Details</a
                                                >
                                            {/if}
                                            <!-- <a href="" class="dropdown-item important"><i data-feather="star"></i>Mark as
                                    Important</a>
                                <a href="#modalShare" data-toggle="modal" class="dropdown-item share"><i
                                        data-feather="share"></i>Share</a>
                                <a href="" class="dropdown-item download"><i data-feather="download"></i>Download</a>
                                <a href="#modalCopy" data-toggle="modal" class="dropdown-item copy"><i
                                        data-feather="copy"></i>Copy
                                    to</a>
                                <a href="#modalMove" data-toggle="modal" class="dropdown-item move"><i
                                        data-feather="folder"></i>Move
                                    to</a> -->
                                            <!-- <a href="#" class="dropdown-item rename" on:click={handleRename(item.fileId)}><i data-feather="edit"></i>Rename</a> -->
                                            {#if MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                                                <a
                                                    href="#"
                                                    class="dropdown-item delete"
                                                    on:click={() => {
                                                        handleDelete(
                                                            item.filesArr._id
                                                        );
                                                    }}
                                                    ><i
                                                        data-feather="trash"
                                                    />Delete</a
                                                >
                                            {/if}
                                        </div>
                                    </div>
                                    <!-- dropdown -->
                                {/if}
                                <div
                                    class="card-file-thumb {getColorByFile(
                                        item.filesArr
                                    )}"
                                    style="cursor: pointer;"
                                    on:click={() => {
                                        navigate(
                                            `/dashboard/studyMaterial/${getTypeByFile(
                                                item.filesArr
                                            )}/edit/${item.filesArr._id}`
                                        );
                                    }}
                                >
                                    <!-- <i class="far {(getIconByFile(item.filesArr))}"></i> -->
                                    <img
                                        style="width: 80%; height: 80%; padding: 5px; cursor: pointer;"
                                        src={"/assets/" +
                                            getIconByFile(item.filesArr) +
                                            ".svg"}
                                        alt=""
                                    />
                                </div>
                                <div class="card-body" use:links>
                                    <h6>
                                        <a
                                            href={`/dashboard/studyMaterial/${getTypeByFile(
                                                item.filesArr
                                            )}/edit/${item.filesArr._id}`}
                                            class="link-02"
                                            >{item.filesArr.name}</a
                                        >
                                    </h6>
                                    <!-- <span>{fileSizeView(item.filesArr.fileSize)}</span> -->
                                </div>
                                <div class="card-footer">
                                    <span class="d-none d-sm-inline"
                                        >Created On:
                                    </span>{moment(
                                        item.filesArr.createdOn
                                    ).format("DD-MMM-YYYY")}
                                </div>
                                <div>
                                    {#if Array.isArray(item.filesArr.tags)}
                                        {#each item.filesArr.tags as tag}
                                            <span
                                                class="badge badge-light mg-r-5"
                                                style="font-size: 10px; color: #636363;"
                                                >{tag}</span
                                            >
                                        {/each}
                                    {/if}
                                </div>
                            </div>
                        </div>
                        <!-- col -->
                    {/if}
                {/each}

                {#if isLoading}
                    <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                        <div class="card card-file ">
                            <div class="card-body" >

                            <!-- <li class="list-group-item list-group-item-action"> -->
                            <div class="placeholder-paragraph ">
                                <div class="line" />
                                <div class="line" />
                            </div>
                            <!-- </li> -->
                            </div>
                        </div>
                    </div>
                {:else if fileList.length == 0}
                    <div
                        class="d-flex justify-content-center"
                        style="width: 100%;"
                    >
                        <img
                            style="max-width: 200px; width: 200px;"
                            src="/assets/files_empty.svg"
                            alt=""
                        />
                    </div>
                    <p
                        class="mg-t-10 tx-gray-500"
                        style="text-align: center;width: 100%;"
                    >
                        No file in this folder
                    </p>
                {/if}
            {:else}
                {#each fileList as item}
                    {#if item && (item.type == "liveLecture" || item.type == "refLink" || item.type == "recordedLecture" || item.type == "youtubeLecture")}
                        <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                            <div class="card card-file">
                                <div class="dropdown-file">
                                    <a
                                        href=""
                                        class="dropdown-link"
                                        data-toggle="dropdown"
                                        ><i data-feather="more-vertical" /></a
                                    >
                                    <div
                                        class="dropdown-menu dropdown-menu-right"
                                    >
                                        {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL)}
                                            <a
                                                href={`/dashboard/studyMaterial/${getTypeByFile(
                                                    item
                                                )}/edit/${item._id}`}
                                                use:links
                                                class="dropdown-item details"
                                                ><i data-feather="info" />View
                                                Details</a
                                            >
                                        {/if}
                                        <!-- <a href="" class="dropdown-item important"><i data-feather="star"></i>Mark as
                                            Important</a>
                                        <a href="#modalShare" data-toggle="modal" class="dropdown-item share"><i
                                                data-feather="share"></i>Share</a>
                                        <a href="" class="dropdown-item download"><i data-feather="download"></i>Download</a>
                                        <a href="#modalCopy" data-toggle="modal" class="dropdown-item copy"><i
                                                data-feather="copy"></i>Copy
                                            to</a>
                                        <a href="#modalMove" data-toggle="modal" class="dropdown-item move"><i
                                                data-feather="folder"></i>Move
                                            to</a> -->
                                        <!-- <a href="#" class="dropdown-item rename" ><i data-feather="edit"></i>Rename</a> -->
                                        {#if MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                                            <a
                                                href="#"
                                                class="dropdown-item delete"
                                                on:click={() => {
                                                    handleDelete(item._id);
                                                }}
                                                ><i
                                                    data-feather="trash"
                                                />Delete</a
                                            >
                                        {/if}<!--on:click={handleDelete(item._id)}-->
                                    </div>
                                </div>
                                <!-- dropdown -->
                                <a
                                    use:links
                                    href={`/dashboard/studyMaterial/${getTypeByFile(
                                        item
                                    )}/edit/${item._id}`}
                                    class="link-02"
                                >
                                    <div
                                        class="card-file-thumb {getColorByFile(
                                            item
                                        )}"
                                        style="cursor: pointer;"
                                        on:click={() => {
                                            navigate(
                                                `/dashboard/studyMaterial/${getTypeByFile(
                                                    item
                                                )}/edit/${item._id}`
                                            );
                                        }}
                                    >
                                        <!-- <i class="far {(getIconByFile(item))}"></i> -->
                                        <img
                                            style="width: 80%;
                                        height: 80%;
                                        padding: 5px;"
                                            src={"/assets/" +
                                                getIconByFile(item) +
                                                ".svg"}
                                            alt=""
                                        />
                                    </div>
                                    <div class="card-body" use:links>
                                        <h6>{item.name}</h6>
                                        <!-- <span>{fileSizeView(item.fileSize)}</span> -->
                                        {#if item.type == "liveLecture"}
                                            <p
                                                class="tx-12 mg-t-8"
                                                style="color: #777"
                                            >
                                                Start: {moment(
                                                    item.from
                                                ).format("DD-MM-YYYY HH:mm:ss")}
                                                &nbsp; End: {moment(
                                                    item.end
                                                ).format("DD-MM-YYYY HH:mm:ss")}
                                            </p>
                                        {/if}
                                    </div>
                                    <div class="card-footer">
                                        <span class="d-none d-sm-inline"
                                            >Created On:
                                        </span>{moment(item.createdOn).format(
                                            "DD-MMM-YYYY"
                                        )}
                                    </div>
                                </a>
                                <div>
                                    {#if Array.isArray(item.tags)}
                                        {#each item.tags as tag}
                                            <span
                                                class="badge badge-light mg-r-5"
                                                style="font-size: 10px;
                                            color: #636363;">{tag}</span
                                            >
                                        {/each}
                                    {/if}
                                </div>
                            </div>
                        </div>
                        <!-- col -->
                    {/if}
                    {#if item && item.originalFileName}
                        <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10">
                            <div class="card card-file">
                                <div class="dropdown-file">
                                    <a
                                        href=""
                                        class="dropdown-link"
                                        data-toggle="dropdown"
                                        ><i data-feather="more-vertical" /></a
                                    >
                                    <div
                                        class="dropdown-menu dropdown-menu-right"
                                    >
                                        {#if item.type == "recordedLecture"}
                                            {#if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL)}
                                                <a
                                                    href="#"
                                                    on:click={() => {
                                                        showModalVideoPreview(
                                                            item
                                                        );
                                                    }}
                                                    data-toggle="modal"
                                                    class="dropdown-item details"
                                                >
                                                    <i
                                                        data-feather="info"
                                                    />View Details</a
                                                >
                                            {/if}
                                        {:else if MODULES.hasAccess(MODULES.VIEW_STUDY_MATERIAL)}
                                            <a
                                                href={`/dashboard/studyMaterial/${getTypeByFile(
                                                    item
                                                )}/edit/${item._id}`}
                                                use:links
                                                class="dropdown-item details"
                                            >
                                                <i data-feather="info" />View
                                                Details</a
                                            >
                                        {/if}
                                        <!-- <a href="" class="dropdown-item important"><i data-feather="star"></i>Mark as
                                            Important</a>
                                        <a href="#modalShare" data-toggle="modal" class="dropdown-item share"><i
                                                data-feather="share"></i>Share</a>
                                        <a href="" class="dropdown-item download"><i data-feather="download"></i>Download</a>
                                        <a href="#modalCopy" data-toggle="modal" class="dropdown-item copy"><i data-feather="copy"></i>Copy
                                            to</a>
                                        <a href="#modalMove" data-toggle="modal" class="dropdown-item move"><i data-feather="folder"></i>Move
                                            to</a>
                                        <a href="#" class="dropdown-item rename"><i data-feather="edit"></i>Rename</a> -->
                                        {#if MODULES.hasAccess(MODULES.DELETE_STUDY_MATERIAL)}
                                            <a
                                                href="#"
                                                class="dropdown-item delete"
                                                on:click={() => {
                                                    handleDelete(item._id);
                                                }}
                                                ><i
                                                    data-feather="trash"
                                                />Delete</a
                                            >
                                        {/if}
                                    </div>
                                </div>
                                <!-- dropdown -->
                                <a
                                    use:links
                                    href={`/dashboard/studyMaterial/${getTypeByFile(
                                        item
                                    )}/edit/${item._id}`}
                                    class="link-02"
                                >
                                    <div class="card-file-thumb tx-danger">
                                        <div
                                            class="card-file-thumb {getColorByFile(
                                                item
                                            )}"
                                            style="cursor: pointer;"
                                            on:click={() => {
                                                navigate(
                                                    `/dashboard/studyMaterial/${getTypeByFile(
                                                        item
                                                    )}/edit/${item._id}`
                                                );
                                            }}
                                        >
                                            <!-- <i class="far {(getIconByFile(item))}"></i> -->
                                            <img
                                                style="width: 80%;
                                            height: 80%;
                                            padding: 5px;"
                                                src={"/assets/" +
                                                    getIconByFile(item) +
                                                    ".svg"}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <h6>{item.name}</h6>
                                        <p
                                            class="tx-12 mg-t-8"
                                            style="color: #777"
                                        >
                                            {item.originalFileName}
                                        </p>
                                        <span
                                            >{fileSizeView(item.fileSize)}</span
                                        >
                                    </div>
                                    <div class="card-footer">
                                        <span class="d-none d-sm-inline"
                                            >Created on:
                                        </span>{moment(item.createdOn).format(
                                            "DD-MMM-YYYY"
                                        )}
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- col -->
                    {/if}
                {:else}
                    <div
                        class="d-flex justify-content-center"
                        style="width: 100%;"
                    >
                        <img
                            style="max-width: 200px;
                            width: 200px;"
                            src="/assets/files_empty.svg"
                            alt=""
                        />
                    </div>
                    <p
                        class="mg-t-10 tx-gray-500"
                        style="text-align: center;width: 100%;"
                    >
                        No files found in this folder
                    </p>
                {/each}
            {/if}
        {/if}
    </div>
    <!-- row -->
</div>

<!-- Modal -->
<div
    class="modal effect-scale"
    bind:this={modalVideoPreview}
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel6"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a
                    href=""
                    role="button"
                    class="close pos-absolute t-15 r-15 z-index-10"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <!-- modal-header -->
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <hr class="full-width" />

                <!-- svelte-ignore a11y-media-has-caption -->
                <video
                    bind:this={vidPreview}
                    src={previewVideoSrc}
                    style="width: 100%; height: 100%;"
                    controls
                />

                <div class="d-md-flex justify-content-end tx-12 mg-t-30">
                    <button
                        type="button"
                        class="btn  btn-secondary tx-13 mg-md-r-40 "
                        data-dismiss="modal">Close</button
                    >
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div
    class="modal effect-scale"
    id="delete-modal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a
                    href=""
                    role="button"
                    class="close pos-absolute t-15 r-15 z-index-10"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"
                        ><i data-feather="trash-2" class="wd-60 ht-60" /></span
                    >
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Delete</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">
                            Delete or unlink this study material
                        </p>
                    </div>
                </div>
                <!-- media -->
            </div>
            <!-- modal-header -->
            <hr class="full-width" />
            <div class="modal-body  pd-l-25 pd-r-25 pd-b-25 pd-t-0 ">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <ul class="list-group list-group-flush tx-13">
                            {#each linkArr as item}
                                <li class="list-group-item d-flex pd-sm-x-20">
                                    <div class="avatar">
                                        <span
                                            class="avatar-initial rounded-circle bg-gray-600"
                                            ><i
                                                data-feather="link"
                                                class="wd-10"
                                            /></span
                                        >
                                    </div>
                                    <div class="pd-l-10">
                                        <p class="tx-medium mg-b-0">
                                            {item.fullPath.join(" / ")}
                                        </p>
                                        <small class="tx-12 tx-color-03 mg-b-0"
                                            >{item.batchName}</small
                                        >
                                    </div>
                                    <div
                                        class="mg-l-auto d-flex align-self-center"
                                    >
                                        <nav class="nav nav-icon-only">
                                            <a
                                                on:click|preventDefault={() =>
                                                    deleteLink(item._id)}
                                                href="#"
                                                class="nav-link d-none d-sm-block"
                                            >
                                                <i
                                                    data-feather="trash-2"
                                                    class="wd-10 mg-r-5"
                                                />
                                            </a>
                                        </nav>
                                    </div>
                                </li>
                            {/each}
                        </ul>
                        {#if !linkArr.length}
                            <div class="tx-danger">
                                No links found for this file, if you want to
                                delete the file you can delete pramanently!!!
                            </div>
                        {/if}
                    </div>
                </div>
            </div>
            <div class="pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <button
                    type="button"
                    class="btn btn-danger tx-12 float-right"
                    on:click={handleDeletePermanently}
                    >Permanent Delete
                    <i data-feather="delete" class="wd-10 mg-l-5" />
                </button>
                {#if linkArr.length}
                    <button
                        type="button"
                        class="btn btn-primary tx-12 mg-r-10 float-right"
                        on:click={handleUnlinkAll}
                    >
                        <!-- <ButtonLoader isLoading={isLoading} btnText="SAVE" />  -->
                        Unlink All
                        <i data-feather="delete" class="wd-10 mg-l-5" />
                    </button>
                {/if}
            </div>
        </div>
    </div>
</div>
