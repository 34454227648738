<script>
  import { afterUpdate, onDestroy, onMount, tick } from "svelte";
  import api_handleApiCalls from "../utils/apiCallHandler";
  import STPModal from "../pdfExamTextEditor/modal.svelte";
  import 'timepicker';
  import {
    OptionFormat,
    PaperLayout,
    PaperMode,
    PaperSet,
  } from "./utils/utils";

  export let errorObj = {};
  export let testObj;
  export let updateParent;
  let firstLoad = true;
  let dateObj = {
    startDate: null,
    startTime: null,
  };
  let elem_testStartTime = false;
  let elem_scroll;
  let marksOnPdfEle;
  let fontSizePdfEle;
  let fontSize = 12;

  let syllabusInstructionModalToggle = false;
  let examInstructionModalToggle = false;

  const onCloseSyllabusInstructionModal = (event) => {
    syllabusInstructionModalToggle = !syllabusInstructionModalToggle;

    /* Set intruction here */
    let syllabusInstruction = event.detail.sptObject.content;
    CKEDITOR.instances.syllabus.setData(syllabusInstruction);

    _$(".modal-backdrop").remove();
  };
  const onCloseExamInstructionModal = (event) => {
    examInstructionModalToggle = !examInstructionModalToggle;

    let instructions = event.detail.sptObject.content;
    CKEDITOR.instances.instructions.setData(instructions);

    _$(".modal-backdrop").remove();
  };

  function clearError(fieldName) {
    if (errorObj[fieldName]) {
      delete errorObj[fieldName];
    }
    errorObj = errorObj;
  }
  function parseTestDurationToString(minutes) {
    if (minutes) {
      let hours = Number(minutes / 60).toFixed(1);
      if (hours > 1) {
        return `${hours}Hrs`;
      } else {
        return `${minutes}Mins`;
      }
    }
    return `0Hrs`;
  }
  function getTimepickerConfig(defaultTime, timeChanged) {
    return {
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      defaultTime: defaultTime ? defaultTime : "12:00 AM",
      startTime: "12:00 AM",
      change: timeChanged,
    };
  }

  function convertDateObjToDateStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var dateString = [year, month, day].join("-");
    return dateString;
  }
  async function onDateChange(type, event) {
    if (type == "startdate") {
      dateObj.startDate = event.target.value;
      // console.log(testObj.startDate);
      let minDate = new Date(dateObj.startDate);
      minDate.setDate(minDate.getDate());
      testObj.pdfObj.date = dateObj.startDate;
      await tick();
    }
    if (type == "starttime") {
      dateObj.startTime = event;
      // console.log(testObj.startTime);
      testObj.pdfObj.time = dateObj.startTime;
    }
  }
  function initTimePickers() {
    /* 
        date : "21st Jan 2020", //Date // perpopluate form publish > start date
        //perpopluate only if value is undefined
        time : "9 AM", //time // perpopluate form publish > start time
        //perpopluate only if value is undefined
    */
    async function testTimeChanged(time) {
      if (time != dateObj.startTime) {
        if (time) {
          dateObj.startTime = new Date(time);
        } else {
          dateObj.startTime = null;
        }
      }
      onDateChange("starttime", time);
      clearError("startDate");
      await tick();
    }
    let startTimepickerConfig = getTimepickerConfig(
      testObj.startTime
        ? moment(new Date(testObj.startTime)).format("hh:mm A")
        : "12:00 AM",
      testTimeChanged
    );
    _$(elem_testStartTime).timepicker(startTimepickerConfig);
  }
  function initCKEditors() {
    const toolbar = [
      [
        "NewPage",
        "Preview",
        "-",
        "Bold",
        "Italic",
        "Strike",
        "NumberedList",
        "BulletedList",
        "-",
        "Table",
        "HorizontalRule",
        "Styles",
        "Format",
        "Maximize",
        "Image",
      ],
    ];
    function initSyllabus() {
      if (CKEDITOR.instances.syllabus)
        CKEDITOR.instances.syllabus.destroy(false);

      CKEDITOR.replace("syllabus", {
        toolbar: toolbar,
        height: 150,
      });

      CKEDITOR.instances.syllabus.on("instanceReady", function (e) {
        // console.log('testObj.pdfObj.syllabus => ', JSON.stringify(testObj.pdfObj.syllabus, null, 4));
        if (testObj.pdfObj.syllabus) {
          CKEDITOR.instances.syllabus.setData(testObj.pdfObj.syllabus);
        } else CKEDITOR.instances.syllabus.setData("");
      })
      CKEDITOR.instances.syllabus.on("blur", function (e) {
        clearError("syllabus");
      });
      CKEDITOR.instances.syllabus.on("change", function (e) {
        testObj.pdfObj.syllabus = CKEDITOR.instances.syllabus.getData();
        // console.log(testObj.pdfObj.syllabus);
      });
    }
    function initPDFInstructions() {
      if (CKEDITOR.instances.instructions)
        CKEDITOR.instances.instructions.destroy(false);

      CKEDITOR.replace("instructions", {
        toolbar: toolbar,
        height: 150,
      });

      CKEDITOR.instances.instructions.on("instanceReady", function (e) {
        // console.log('testObj.pdfObj.instructions => ', JSON.stringify(testObj.pdfObj.instructions, null, 4));
        if (testObj.pdfObj.instructions) {
          CKEDITOR.instances.instructions.setData(testObj.pdfObj.instructions);
        } else CKEDITOR.instances.instructions.setData("");
      })
      CKEDITOR.instances.instructions.on("blur", function (e) {
        clearError("instructions");
      });
      CKEDITOR.instances.instructions.on("change", function (e) {
        testObj.pdfObj.instructions = CKEDITOR.instances.instructions.getData();
        // console.log(testObj.pdfObj.instructions);
      });
    }
    initSyllabus();
    initPDFInstructions();
  }
  function initCleaveInputs() {
    function initMarks() {
      // Number // perpopluate form details > marks
      //perpopluate only if value is undefined
      marksOnPdfEle = new Cleave(".test-marks-pdf", {
        numeral: true,
        numeralPositiveOnly: true,
        numeralDecimalScale: 0, // integer only
        // delimiter: '',
        onValueChanged: function (e) {
          if (e.target.rawValue == "") {
            e.target.rawValue = 0;
            if (marksOnPdfEle) marksOnPdfEle.setRawValue(0);
          }
          e.target.rawValue = Number(e.target.rawValue);
          testObj.pdfObj.marks = e.target.rawValue;
        },
      });
    }
    _$(".test-marks-pdf").val(testObj.pdfObj.marks.toString());

    function initFontSize() {
      // Number // perpopluate form details > marks
      //perpopluate only if value is undefined
      fontSizePdfEle = new Cleave(".test-font-size-pdf", {
        numeral: true,
        numeralPositiveOnly: true,
        numeralDecimalScale: 0, // integer only
        // delimiter: '',
        onValueChanged: function (e) {
          if (e.target.rawValue == "") {
            e.target.rawValue = 0;
            if (fontSizePdfEle) fontSizePdfEle.setRawValue(0);
          }
          e.target.rawValue = Number(e.target.rawValue);
          testObj.pdfObj.fontSize = e.target.rawValue;
        },
      });
    }
    _$(".test-font-size-pdf").val(testObj.pdfObj.fontSize.toString());

    initFontSize();
    initMarks();
  }
  function initScroll() {
    const scroll1 = new PerfectScrollbar(elem_scroll, {
      suppressScrollX: true,
    });
  }
  async function api_fetchWatermark() {
    let response = await api_handleApiCalls("settings.fetchWatermark", {});
    return response;
    // return { status: true, data: { WATERMARK: "IMS" } };
  }
  async function api_fetchAddress() {
    let response = await api_handleApiCalls("settings.fetchAddress", {});
    return response;
    // return { status: true, data: { ADDRESS: "Address" } };
  }
  onMount(function () {
    console.log(errorObj);
    testObj.menu = "generatePDF";
    console.log("Details : ", testObj);
    testObj.isGeneratePdf = true;
    feather.replace();
    if (testObj && !testObj.pdfObj)
      testObj.pdfObj = { class: "", duration: "" };
    updateParent();
  });
  afterUpdate(async function () {
    if (testObj && !testObj.pdfObj)
      testObj.pdfObj = { class: "", duration: "" };
    if (firstLoad && testObj && testObj.pdfObj) {
      firstLoad = false;
      if (!testObj.pdfObj.watermark) {
        let watermarkResult = await api_fetchWatermark();
        if (watermarkResult.status) {
          testObj.pdfObj.watermark = watermarkResult.data.WATERMARK;
        }
      }
      if (!testObj.pdfObj.address) {
        let addressResult = await api_fetchAddress();
        if (addressResult.status) {
          testObj.pdfObj.address = addressResult.data.ADDRESS;
        }
      }
      if (!testObj.pdfObj.marks) testObj.pdfObj.marks = testObj.totalMaxMarks;
      console.debug(
        "!testObj.pdfObj.marks",
        testObj.pdfObj.marks,
        testObj.totalMaxMarks
      );
      if (!testObj.pdfObj.duration)
        testObj.pdfObj.duration = parseTestDurationToString(testObj.duration);
      if (!testObj.pdfObj.date)
        onDateChange("startdate", { target: { value: testObj.startDate } });
      else
        onDateChange("startdate", { target: { value: testObj.pdfObj.date } });
      if (!testObj.pdfObj.time) onDateChange("starttime", testObj.startTime);
      else onDateChange("starttime", testObj.pdfObj.time);

      if (!testObj.pdfObj.paperMode)
        testObj.pdfObj.paperMode = PaperMode.STUDENTS_COPY;
      if (!testObj.pdfObj.paperLayout)
        testObj.pdfObj.paperLayout = PaperLayout.DOUBLE_COLUMN;
      // if(!testObj.pdfObj.paperSet) testObj.pdfObj.paperSet = PaperSet.A
      if (!testObj.pdfObj.optionFormat)
        testObj.pdfObj.optionFormat = OptionFormat.LETTERS;

      if (!testObj.pdfObj.fontSize)
        testObj.pdfObj.fontSize = fontSize;

      initCKEditors();
      initCleaveInputs();
      initTimePickers();
      initScroll();
      console.log("first load done");
    }
  });
  onDestroy(() => {
    if (CKEDITOR.instances.instructions && CKEDITOR.instances.instructions.loaded) {
      CKEDITOR.instances.instructions.destroy()
    }
    if (CKEDITOR.instances.syllabus && CKEDITOR.instances.syllabus.loaded) {
      CKEDITOR.instances.syllabus.destroy()
    }
  })
</script>

<div class="fadeIn" style="display: flex;flex-direction: column; max-height: 100%">
  {#if testObj.pdfObj}
  <div style="width: 1;border-bottom: 1px solid #e5e8ee; display: flex;">
    <div style="display: flex;align-items: center;justify-content: center;margin-left: 20px;" class="colored-svg">
      <i data-feather="external-link" class="wd-40" />
    </div>
    <div class="media-body mg-l-10 pd-y-10">
      <h6 class="mg-b-2 tx-16">Generate PDF</h6>
      <span class="d-block tx-11 tx-color-03">Settings for generating PDF of test</span>
    </div>
  </div>
  <div class="fadeIn d-block pos-relative scroll scroll-sm" style="max-height: 100% !important;"
    bind:this={elem_scroll}>
    <div style="flex: 1; display: flex;">
      <div style="flex:1; padding: 25px;" class="row">
        <div class="form-group col-md-12">
          <label for="classInput" class="d-block text-capitalize">class <span class="required-field">*</span></label>
          <input id="classInput" type="text" bind:value={testObj.pdfObj.class} class="form-control" on:blur={()=>
          clearError("class")}
          placeholder="Enter test class"
          class:parsley-error={errorObj.class}
          />
          {#if errorObj.class}
          <div class="parsley-errors-list">{errorObj.class}</div>
          {/if}
        </div>
        <div class="form-group col-md-12">
          <label class="d-block text-capitalize">Date Time <span class="required-field">*</span></label>
          <div class="form-row">
            <div class="form-group col-md-6 m-0">
              <input type="date" onkeydown="return false" value={dateObj.startDate ?
                convertDateObjToDateStr(dateObj.startDate) : "" } on:change={(event)=> onDateChange("startdate", event)}
              class="form-control"
              placeholder="Select Date"
              on:blur={async () => {
              clearError("startDate");
              await tick();
              }}
              class:parsley-error={errorObj.date && !dateObj.startDate}
              />
            </div>
            <div class="form-group col-md-6 m-0">
              <input type="text" bind:this={elem_testStartTime} onkeydown="return false" class="timepicker form-control"
                class:parsley-error={errorObj.date && !dateObj.startTime} />
            </div>
          </div>
          {#if errorObj.startDate}
          <div class="parsley-errors-list">{errorObj.startDate}</div>
          {/if}
        </div>
        <div class="form-group col-md-6">
          <label for="testMarks" class="d-block text-capitaize">Marks <span class="required-field">*</span></label>
          <div class="input-group mg-b-10">
            <input id="testMarks" type="text" value={testObj.pdfObj.marks} class="form-control test-marks-pdf"
              on:blur={()=> clearError("marks")}
            placeholder="Enter marks"
            class:parsley-error={errorObj.marks}
            />
          </div>
          {#if errorObj.marks}
          <div class="parsley-errors-list">{errorObj.marks}</div>
          {/if}
        </div>
        <div class="form-group col-md-6">
          <label for="testDuration" class="d-block text-capitalize">Duration <span
              class="required-field">*</span></label>
          <div class="input-group mg-b-10">
            <input id="testDuration" type="text" bind:value={testObj.pdfObj.duration}
              class="form-control test-duration-pdf" on:blur={()=> clearError("duration")}
            placeholder="Enter duration ( e.g, 2Hrs, 30Mins)"
            class:parsley-error={errorObj.duration}
            />
            <!-- <div class="input-group-append">
            <span class="input-group-text">Minutes</span>
          </div> -->
          </div>
          {#if errorObj.duration}
          <div class="parsley-errors-list">{errorObj.duration}</div>
          {/if}
        </div>

        <div class="form-group col-md-12">
          <label for="fontSize" class="d-block text-capitaize">Font Size <span class="required-field">*</span></label>
          <div class="input-group mg-b-10">
            <input id="fontSize" type="text" value={testObj.pdfObj.fontSize} class="form-control test-font-size-pdf"
              on:blur={()=>
            clearError("fontSize")}
            placeholder="Enter Font Size"
            class:parsley-error={errorObj.fontSize}
            />
          </div>
          {#if errorObj.fontSize}
          <div class="parsley-errors-list">{errorObj.fontSize}</div>
          {/if}
        </div>


        <div class="form-group col-md-12">
          <!-- paperMode : "student-copy", //"teachers-copy" //Radio -->
          <label for="formGroupExampleInput" class="d-block">Paper Mode <span class="required-field">*</span></label>
          <div class="form-row">
            <div class="form-group col-md-6 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="paperModeStudentCopy" name="customRadioPaperMode" class="custom-control-input"
                  on:blur={()=> clearError("paperMode")}
                bind:group={testObj.pdfObj.paperMode}
                value={PaperMode.STUDENTS_COPY}
                />
                <label class="custom-control-label" for="paperModeStudentCopy">Students' Copy</label>
              </div>
            </div>
            <div class="form-group col-md-6 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="paperModeTeachersCopy" name="customRadioPaperMode" class="custom-control-input"
                  on:blur={()=> clearError("paperMode")}
                bind:group={testObj.pdfObj.paperMode}
                value={PaperMode.TEACHERS_COPY}
                />
                <label class="custom-control-label" for="paperModeTeachersCopy">Teachers' Copy</label>
              </div>
            </div>
          </div>
          {#if errorObj.paperMode}
          <div class="parsley-errors-list">
            {errorObj.paperMode}
          </div>
          {/if}
        </div>
        <div class="form-group col-md-12">
          <!-- paperLayout : "single-column", //"double-column" //radio -->
          <label for="formGroupExampleInput" class="d-block text-capitalize">Paper Layout <span
              class="required-field">*</span></label>
          <div class="form-row">
            <div class="form-group col-md-6 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="paperLayoutSingleColumn" name="customRadioPaperLayout"
                  class="custom-control-input" on:blur={()=> clearError("paperLayout")}
                bind:group={testObj.pdfObj.paperLayout}
                value={PaperLayout.SINGLE_COLUMN}
                />
                <label class="custom-control-label" for="paperLayoutSingleColumn">Single Column</label>
              </div>
            </div>
            <div class="form-group col-md-6 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="paperLayoutDoubleColumn" name="customRadioPaperLayout"
                  class="custom-control-input" on:blur={()=> clearError("paperLayout")}
                bind:group={testObj.pdfObj.paperLayout}
                value={PaperLayout.DOUBLE_COLUMN}
                />
                <label class="custom-control-label" for="paperLayoutDoubleColumn">Double Column</label>
              </div>
            </div>
          </div>
          {#if errorObj.paperLayout}
          <div class="parsley-errors-list">
            {errorObj.paperLayout}
          </div>
          {/if}
        </div>

        <div class="form-group col-md-12">
          <!-- optionFormat : "1234", //"ABCD" //radio -->
          <label for="formGroupExampleInput" class="d-block text-capitalize">Option Format <span
              class="required-field">*</span></label>
          <div class="form-row">
            <div class="form-group col-md-6 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="optionFormatNumbers" name="customRadioOptionFormat" class="custom-control-input"
                  on:blur={()=> clearError("optionFormat")}
                bind:group={testObj.pdfObj.optionFormat}
                value={OptionFormat.NUMBERS}
                />
                <label class="custom-control-label" for="optionFormatNumbers">Numbers (1,2,3,4)
                </label>
              </div>
            </div>
            <div class="form-group col-md-6 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="optionFormatLetters" name="customRadio" class="custom-control-input"
                  on:blur={()=> clearError("optionFormat")}
                bind:group={testObj.pdfObj.optionFormat}
                value={OptionFormat.LETTERS}
                />
                <label class="custom-control-label" for="optionFormatLetters">Letters (A, B, C, D)</label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-12" style="display: none;">
          <!-- //paperSet : "A", // B. C, D, //radio ignore for now -->
          <label for="formGroupExampleInput" class="d-block">Paper Set <span class="required-field">*</span></label>
          <div class="form-row">
            <div class="form-group col-md-3 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="paperSetA" name="customRadioPaperSet" class="custom-control-input" on:blur={()=>
                clearError("paperSet")}
                bind:group={testObj.pdfObj.paperSet}
                value={PaperSet.A}
                />
                <label class="custom-control-label" for="paperSetA">A </label>
              </div>
            </div>
            <div class="form-group col-md-3 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="paperSetB" name="customRadioPaperSet" class="custom-control-input" on:blur={()=>
                clearError("paperSet")}
                bind:group={testObj.pdfObj.paperSet}
                value={PaperSet.B}
                />
                <label class="custom-control-label" for="paperSetB">B </label>
              </div>
            </div>
            <div class="form-group col-md-3 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="paperSetC" name="customRadioPaperSet" class="custom-control-input" on:blur={()=>
                clearError("paperSet")}
                bind:group={testObj.pdfObj.paperSet}
                value={PaperSet.C}
                />
                <label class="custom-control-label" for="paperSetC">C </label>
              </div>
            </div>
            <div class="form-group col-md-3 m-0">
              <div class="custom-control custom-radio">
                <input type="radio" id="paperSetD" name="customRadioPaperSet" class="custom-control-input" on:blur={()=>
                clearError("paperSet")}
                bind:group={testObj.pdfObj.paperSet}
                value={PaperSet.D}
                />
                <label class="custom-control-label" for="paperSetD">D </label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-12">
          <!-- watermark : "DFC", //Text // need to put in orgDeatils DASHBOARD >
        SETTINGS > GENERAL SETTINGS
     -->
          <label for="watermarkInput" class="d-block text-capitalize">watermark <span
              class="required-field">*</span></label>
          <input id="watermarkInput" type="text" bind:value={testObj.pdfObj.watermark} class="form-control"
            on:blur={()=> clearError("watermark")}
          placeholder="Enter watermark"
          class:parsley-error={errorObj.watermark}
          />
          {#if errorObj.watermark}
          <div class="parsley-errors-list">{errorObj.watermark}</div>
          {/if}
        </div>
        <div class="form-group col-md-12">
          <!-- address : "Opp. Ludhiana Cycle, Paithan Gate Road, Aurangabad-431001
        Cell 9225318727, (0240) 2350151.", //text // need to put in orgDeatils -->
          <label for="addressInput" class="d-block text-capitalize">address <span
              class="required-field">*</span></label>
          <textarea id="addressInput" rows="3" style="resize:none" name="pdfAddress" bind:value={testObj.pdfObj.address}
            class="form-control" on:blur={()=> clearError("address")}
              placeholder="Enter address"
              class:parsley-error={errorObj.address}
            />
            {#if errorObj.address}
              <div class="parsley-errors-list">{errorObj.address}</div>
            {/if}
          </div>
        </div>
        <div style="flex:1; padding: 25px;">
          <div class="form-group">
            <label
              for="formGroupExampleInput"
              class="d-flex"
              style="align-items: baseline"
            >
              <div class="d-block">
                Syllabus
              </div>
              <div class="d-block mg-l-auto">
                <div class="d-sm-flex">
                  <button
                    class="btn btn-xs pd-x-15 btn-primary btn-uppercase mg-l-5"
                    on:click={() => {
                      syllabusInstructionModalToggle = true;
                    }}
                  >
                    <i data-feather="copy" class="wd-10 mg-r-5" /> Select syllabus
                  </button>
                </div>
              </div>
            </label>
            <div>
              <textarea
                class="form-control"
                name="syllabus"
                rows="3"
                placeholder="Describe test syllabus"
              />
            </div>
            <!-- {#if errorObj.syllabus}
              <div class="parsley-errors-list">{errorObj.syllabus}</div>
            {/if} -->
          </div>
          <div class="form-group">
            <label
              for="formGroupExampleInput"
              class="d-flex"
              style="align-items: baseline"
            >
              <div class="d-block">
                Exam instructions 
              </div>
              <div class="d-block mg-l-auto">
                <div class="d-sm-flex">
                  <button
                    class="btn btn-xs pd-x-15 btn-primary btn-uppercase mg-l-5"
                    on:click={() => {
                      examInstructionModalToggle = true;
                    }}
                  >
                    <i data-feather="copy" class="wd-10 mg-r-5" /> Select Exam Instructions
                  </button>
                </div>
              </div>
            </label>
            <div>
              <textarea
                class="form-control"
                name="instructions"
                rows="3"
                placeholder="Describe pdf instructions"
              />
            </div>
            <!-- {#if errorObj.instructions}
              <div class="parsley-errors-list">{errorObj.instructions}</div>
            {/if} -->
          </div>
        </div>
      </div>
    </div>
  {/if}
</div>

{#if syllabusInstructionModalToggle}
  <STPModal type="syllabus" on:close={onCloseSyllabusInstructionModal} />
{/if}
{#if examInstructionModalToggle}
  <STPModal type="pdfExamInstruction" on:close={onCloseExamInstructionModal} />
{/if}
