<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../../../stores";
  import FolderModal from "../../../folderCmp/folderModal.svelte";
  import ButtonLoader from "../../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../../utils/apiCallHandler.js";
  import { BACKEND_URL } from "../../../../config.js"; // Import BACKEND_URL
  export let _id;
  let isLoading = false;
  let showModal = false;
  let imagesrc = true;
  let elem_uploadImageInput;
  let elem_tags;

  let fileEle;
  let files;
  let _fileName;
  let fileObj;
  let linkArr = [];
  let moduleSubjectList = [];
  let facultyList = [];
  let imageObj = {
    _id: _id,
    name: "",
    facultyId: "",
    tags: [],
  };
  let displayText = "Add Image";
  let elemModuleSubj;
  let elemFaculty;
  if (_id) {
    displayText = "Update Image";
  }
  let errorObj = {};
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  };

  async function api_loadLinksForFile(data) {
    let action = "files.loadLinksForFile";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_addFileLinkToFolder(data) {
    let action = "files.addFileLinkToFolder";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_removeFileLinkToFolder(data) {
    let action = "files.removeFileLinkToFolder";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getModuleSub(data) {
    let action = "categories.getModuleSubjectList";
    try {
      var response = await api_handleApiCalls(action, {});
      if (!response.ok) throw new Error("Network response was not ok");
      return response;
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  }

  async function api_addUpdateImage(data) {
    let action = "studyMaterial.addUpdateImage";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getImageById(data) {
    let action = "studyMaterial.getImageById";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getFaculty(data) {
    let action = "systemUser.fetchSystemUserList";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  function updateSuccess(success) {
    PNotify.success({
      title: success.title ? success.title : "Success",
      text: success.msg,
      delay: 3000,
    });
  }

  function updateErrors(error) {
    PNotify.error({
      title: error.title ? error.title : "Error",
      text: error.msg,
      delay: 3000,
    });
  }
  let deleteLink = async (linkId) => {
    var linkRes = await api_removeFileLinkToFolder({ _id: linkId });
    if (linkRes.status) {
      updateSuccess({ title: "Success", msg: linkRes.data });
      renderLinks();
    } else {
      // Failed to create
      updateErrors({
        title: "Error",
        msg: linkRes?.errMsg ?? "Something went wrong",
      });
    }
  };

  let validateImage = () => {
    errorObj = {};
    if (
      !imageObj.name /* || !imageObj.moduleId || !imageObj.subjectId  */ ||
      !imageObj.file
    ) {
      var newErrorObj = {};
      if (!imageObj.name) {
        newErrorObj.name = "Name is required Field.";
      }
      if (!imageObj.facultyId) {
        newErrorObj.facultyId = "Faculty is required Field.";
      }
      // if (imageObj.moduleSubjects.length == 0) {
      //     newErrorObj.subjectId = "Module/Subject is required Field.";
      // }
      errorObj = newErrorObj;
    }
    if (Object.keys(errorObj).length > 0) {
      isLoading = false;
      return false;
    }
    return true;
  };

  let addUpdateImageBtn = async () => {
    if (isLoading) return;
    isLoading = true;
    let moduleSubIdList = [];
    // _$(elemModuleSubj).select2("data").map(function (aSelectedValue) {
    //     return aSelectedValue.id;
    // });
    imageObj.moduleSubjects = [];
    moduleSubIdList.forEach((moduleSubId) => {
      var moduleId = moduleSubId.split("_")[0];
      var subjectId = moduleSubId.split("_")[1];
      imageObj.moduleSubjects.push({
        moduleId: moduleId,
        subjectId: subjectId,
      });
    });
    let facultyIds = _$(elemFaculty).select2("data")[0].id;
    imageObj.facultyId = facultyIds;
    imageObj.tags = _$(elem_tags).val().split(",");
    if (validateImage()) {
      var imgRes = await api_addUpdateImage(imageObj);
      // fileObj = imgRes;
      if (imgRes.status) {
        // create success
        updateSuccess({ title: "Success", msg: imgRes.data });
        setTimeout(() => {
          window.history.back();
        }, 1000);
      } else {
        // Failed to create
        updateErrors({
          title: "Error",
          msg: imgRes?.errMsg ?? "Something went wrong",
        });
        isLoading = false;
        return false;
      }
    }
    isLoading = false;
  };

  let renderLinks = async function () {
    var templinkArr = await api_loadLinksForFile({
      fileId: _id,
    });
    linkArr = templinkArr.data.fileFolderLinkArr;
    tick();
    setTimeout(function () {
      feather.replace();
    }, 10);
  };

  let bindEvents = function () {
    var selectedSubjId = {};
    if (!imageObj.moduleSubjects) imageObj.moduleSubjects = [];
    imageObj.moduleSubjects.forEach((aModuleSubject) => {
      selectedSubjId[aModuleSubject.subjectId] = true;
    });
    _$(elemModuleSubj).select2({
      minimumResultsForSearch: Infinity,
      placeholder: "Select Module / Subject",
      data: moduleSubjectList.map(function (aModule) {
        var isSelected = false;
        return {
          id: aModule.moduleId + "_" + aModule.subjectId,
          text: aModule.moduleSubjStr,
          selected: selectedSubjId[aModule.subjectId] ? true : false,
        };
      }),
    });

    _$(elemFaculty).select2({
      minimumResultsForSearch: Infinity,
      data: facultyList.map(function (aFaculty) {
        return {
          id: aFaculty._id,
          text: aFaculty.name,
          selected: aFaculty._id == imageObj.facultyId,
        };
      }),
    });
    if (!imageObj.tags) imageObj.tags = [];
    var tags = _$(elem_tags).selectize({
      delimiter: ",",
      plugins: ["remove_button"],
      persist: false,
      options: imageObj.tags.map(function (input) {
        return { value: input, text: input };
      }),
      items: imageObj.tags,
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New tag : </i><strong>' +
            escape(data.input) +
            "</strong></div>"
          );
        },
      },
      create: function (input) {
        return { value: input, text: input };
      },
    });

    // for (var i = 0; i < imageObj.tags.length; i++) {
    //         tags[0].selectize.addOption({ selected: true, value: imageObj.tags[i], text: imageObj.tags[i] });
    //     }
  };

  let onCloseModal = async function (event) {
    showModal = !showModal;
    console.log("selectedFolder : ", event.detail.selectedFolder);

    // if (event?.detail?.selectedFolder?.length > 0) {
    if (
      event &&
      event.detail &&
      event.detail.selectedFolder &&
      event.detail.selectedFolder.length > 0
    ) {
      var selectedFolder = event.detail.selectedFolder;

      var linkObj = {
        fileId: _id,
        folderId: selectedFolder[selectedFolder.length - 1].id,
        batchId: selectedFolder[selectedFolder.length - 1].batchId,
        selectedFolder: selectedFolder,
      };
      var resp = await api_addFileLinkToFolder(linkObj);
      renderLinks();
    }
    _$(".modal-backdrop").remove();
  };

  let refreshLinkList = function () {
    renderLinks();
  };

  let filePondInstance;
  let filepondOptions = {
    allowDownloadByUrl: true,
    acceptedFileTypes: ["image/*"],
    server: {
      process: async (
        fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort,
        transfer,
        options,
      ) => {
        // Log the file object to the console
        console.log("File object:", file);

        try {
          // Get presigned URL from backend
          const res = await fetch(
            `${BACKEND_URL}/fileupload-api/get-presigned-url`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                data: {
                  contentType: "STUDY_MATERIAL",
                  fileName: file.name,
                  fileExtension: file.name.split(".").pop(),
                  fileId: _id, // Include fileId when updating an existing file
                },
                accessToken: localStorage.getItem("accessToken"),
              }),
            },
          ).then((r) => r.json());

          if (!res.presignedUrl) {
            throw new Error("Failed to get presigned URL");
          }

          // Convert file to Blob
          const fileBlob = new Blob([file], { type: file.type });

          // Upload to S3
          const uploadResponse = await fetch(res.presignedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: fileBlob,
            mode: "cors",
          });

          if (!uploadResponse.ok) {
            throw new Error(`Upload failed: ${uploadResponse.status}`);
          }

          // Create attachment object using S3 URL
          const s3Url = new URL(res.presignedUrl);
          const baseUrl = s3Url.origin + s3Url.pathname;

          const attachmentObj = {
            originalFileName: file.name,
            fileSize: file.size,
            fileExtension: file.name.split(".").pop(),
            downloadUrl: res.objectUrl,
            pathname: s3Url.pathname,
            origin: s3Url.origin,
            url: baseUrl,
          };

          // Update image object with attachment and update all relevant properties
          imageObj.attachment = attachmentObj;
          fileObj = attachmentObj;

          // Also update the main object properties to match the new file
          imageObj.originalFileName = attachmentObj.originalFileName;
          imageObj.fileSize = attachmentObj.fileSize;
          imageObj.fileExtension = attachmentObj.fileExtension;
          imageObj.downloadUrl = attachmentObj.downloadUrl;
          imageObj.pathname = attachmentObj.pathname;
          imageObj.origin = attachmentObj.origin;
          imageObj.url = baseUrl;

          // Save the updated image information to the database
          if (_id) {
            // Use the existing API function to update the image metadata
            const updateResponse = await api_addUpdateImage({
              _id: _id,
              name: imageObj.name,
              facultyId: imageObj.facultyId,
              moduleSubjects: imageObj.moduleSubjects || [],
              tags: imageObj.tags || [],
              attachment: attachmentObj,
              originalFileName: attachmentObj.originalFileName,
              fileSize: attachmentObj.fileSize,
              fileExtension: attachmentObj.fileExtension,
              downloadUrl: attachmentObj.downloadUrl,
              pathname: attachmentObj.pathname,
              origin: attachmentObj.origin,
              url: baseUrl,
            });

            if (!updateResponse.status) {
              console.warn(
                "Failed to update image metadata in database",
                updateResponse,
              );
            } else {
              updateSuccess({ msg: "Image updated successfully!" });
            }
          }

          // Update the UI to reflect changes
          await tick();

          load(baseUrl);
        } catch (err) {
          console.error("Upload Error:", err);
          error(err.message);
          abort();
        }

        return {
          abort: () => {
            abort();
          },
        };
      },
    },
    onaddfile: function (error, file) {
      if (error) {
        PNotify.error({
          title: "Error",
          text: error.message || "Invalid file type",
          delay: 3000,
        });
        return;
      }

      // Log the file object to verify its type
      console.log("File object added:", file.file);

      if (!file.file.customUrl) {
        filePondInstance.processFile().then((file) => {});
      }
    },
  };

  onMount(async () => {
    activeMenu.set("studyMaterial");

    // Register FilePond plugins
    _$.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
    _$.fn.filepond.registerPlugin(FilePondPluginGetFile);

    filePondInstance = FilePond.create(elem_uploadImageInput, filepondOptions);

    document.addEventListener("DOMContentLoaded", async function () {
      try {
        var moduleSubjectRes = await api_getModuleSub();
        if (moduleSubjectRes && moduleSubjectRes.status) {
          moduleSubjectList = moduleSubjectRes.data;
        }
      } catch (error) {
        console.error("Error fetching module subjects: ", error);
      }
    });
    const filter = {
      draw: 1,
      columns: [
        {
          data: "name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "email",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "_id",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      start: 0,
      length: 100,
      search: {
        value: "",
        regex: false,
      },
      sort: {
        userSeqId: 1,
      },
      accessProfileId: "",
    };
    var facultyRes = await api_getFaculty(filter);
    if (facultyRes.status) {
      facultyList = facultyRes.data.data;
    }
    var _fileObj;
    if (_id) {
      var imageDetailsRes = await api_getImageById({ _id: _id });
      _fileObj = imageDetailsRes;
      if (imageDetailsRes.status) {
        var imageData = imageDetailsRes.data;
        console.log("imageData ", imageData.facultyId);
        imageObj = {
          _id: imageData._id,
          name: imageData.name,
          facultyId: imageData.facultyId,
          moduleSubjects: imageData.moduleSubjects,
          tags: imageData.tags,
        };
      }
    }

    renderLinks();

    if (_id && _fileObj?.data?.originalFileName) {
      imagesrc = true;
      _$(elem_uploadImageInput).filepond("files", [
        {
          source: "12345",
          options: {
            type: "local",
            file: {
              name: _fileObj.data.originalFileName,
              size: _fileObj.data.fileSize,
              customUrl: _fileObj.data.downloadUrl,
              type: "image/" + _fileObj.data.fileExtension,
            },
          },
        },
      ]);
    }

    _$(".may-pond .filepond--item-panel").css("background", "#369763");
    _$(elem_uploadImageInput).filepond("allowMultiple", false);
    // _$(elem_uploadImageInput).on('FilePond:addfile', function (e) {
    //     _$.fn.filepond.setOptions(server);
    // });
    bindEvents();
  });
</script>

<div class="content-body fadeIn">
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
  >
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/studyMaterial">Study Material</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {displayText}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        {displayText}
      </h4>
    </div>
  </div>
  <!-- <hr class="mg-y-20"> -->
  <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div class="row row-sm mg-b-10">
      <div class="col-sm-12">
        <fieldset class="form-fieldset">
          <legend>Basic</legend>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label class="d-block"> Name</label>
              <input
                bind:value={imageObj.name}
                type="text"
                on:blur={() => clearError("name")}
                class="form-control"
                class:parsley-error={errorObj.name}
                placeholder="Enter your name"
              />
              {#if errorObj.name}
                <div class="parsley-errors-list">{errorObj.name}</div>
                <!-- content here -->
              {/if}
            </div>
            <div
              class="form-group col-md-6 d-none"
              on:click={() => clearError("subjectId")}
              class:parsley-errors-select-custom={errorObj.subjectId}
            >
              <label class="d-block">Module & Subject</label>
              <select
                multiple
                bind:this={elemModuleSubj}
                class="custom-select moduleSub"
              >
              </select>
              {#if errorObj.subjectId}
                <div class="parsley-errors-list">{errorObj.subjectId}</div>
                <!-- content here -->
              {/if}
            </div>
            <div
              class="form-group col-md-6"
              on:click={() => clearError("facultyId")}
              class:parsley-errors-select-custom={errorObj.facultyId}
            >
              <label class="d-block">Faculty</label>
              <select bind:this={elemFaculty} class="custom-select faculty">
                <option value="" selected>Select Faculty</option>
              </select>
              {#if errorObj.facultyId}
                <div class="parsley-errors-list">{errorObj.facultyId}</div>
                <!-- content here -->
              {/if}
            </div>
            <div class="form-group col-md-6">
              <label class="d-block">Tags</label>
              <input
                bind:this={elem_tags}
                bind:value={imageObj.tags}
                on:blur={() => clearError("tags")}
                type="text"
                class="form-control"
                class:parsley-error={errorObj.tags}
                placeholder="Enter
                            tags"
              />
              {#if errorObj.tags}
                <div class="parsley-errors-list">{errorObj.tags}</div>
              {/if}
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-sm-12">
        <div class="card ht-100p" style="    margin-top: 9px;">
          <div>
            <input
              type="file"
              bind:this={elem_uploadImageInput}
              class="my-pond"
              data-instant-upload="false"
              data-allow-image-preview="false"
              name="filepond"
            />
          </div>
          <div
            class="card-header d-flex align-items-center justify-content-between"
          >
            <h6 class="mg-b-0">Linked To Study Material</h6>
            <div class="d-flex align-items-center tx-18">
              <button
                on:click={() => (showModal = !showModal)}
                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
              >
                <i data-feather="link" class="wd-10 mg-r-5"></i> New Link
              </button>
            </div>
          </div>
          <ul class="list-group list-group-flush tx-13">
            {#each linkArr as item}
              <li class="list-group-item d-flex pd-sm-x-20">
                <div class="avatar">
                  <span class="avatar-initial rounded-circle bg-gray-600"
                    ><i data-feather="link" class="wd-10"></i></span
                  >
                </div>
                <div class="pd-l-10">
                  <p class="tx-medium mg-b-0">{item.fullPath.join(" / ")}</p>
                  <small class="tx-12 tx-color-03 mg-b-0"
                    >{item.batchName}</small
                  >
                </div>
                <div class="mg-l-auto d-flex align-self-center">
                  <nav class="nav nav-icon-only">
                    <a
                      on:click={() => deleteLink(item._id)}
                      href="#"
                      class="nav-link d-none
                                        d-sm-block"
                    >
                      <i data-feather="trash-2" class="wd-10 mg-r-5"></i>
                    </a>
                  </nav>
                </div>
              </li>
            {/each}
          </ul>
          <div class="card-footer text-center tx-13">
            <button on:click={refreshLinkList} class="btn"
              >Refresh <i class="icon ion-md-refresh mg-l-5"></i></button
            >
          </div>
          <!-- card-footer -->
        </div>
        <!-- card -->
      </div>
    </div>
  </div>
  <div class="d-sm-flex align-items-center justify-content-end">
    <div class="d-block">
      <button
        on:click={addUpdateImageBtn}
        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        disabled={isLoading}
      >
        <ButtonLoader {isLoading} btnText="SAVE" />
        <i data-feather="save" class="wd-10 mg-l-5"></i>
      </button>
    </div>
  </div>
</div>
{#if showModal}
  <FolderModal on:close={onCloseModal} />
{/if}

<style>
  /* ###### 4.6 Parsley ###### */
  input.parsley-error,
  input.parsley-error:focus,
  textarea.parsley-error,
  textarea.parsley-error:focus {
    box-shadow: none !important;
    border-color: #dc3545;
  }

  input.parsley-success,
  input.parsley-success:focus,
  textarea.parsley-success,
  textarea.parsley-success:focus {
    box-shadow: none !important;
    border-color: #10b759;
  }

  .parsley-checkbox.parsley-error,
  .parsley-checkbox.parsley-success {
    display: inline-block;
    padding: 10px;
    border-radius: 0.25rem;
  }

  .parsley-checkbox.parsley-error {
    border: 1px solid #dc3545;
  }

  .parsley-checkbox.parsley-success {
    border: 1px solid #10b759;
  }

  .custom-select.parsley-error,
  .custom-select.parsley-success {
    box-shadow: none !important;
  }

  .custom-select.parsley-error {
    border-color: #dc3545;
  }

  .custom-select.parsley-success {
    border-color: #10b759;
  }

  .parsley-errors-select-custom .select2-selection {
    border-color: #dc3545 !important;
  }

  .parsley-errors-list {
    color: #dc3545;
    list-style: none;
    font-size: 12px;
    padding: 0;
    margin-bottom: -12px;
  }

  .parsley-errors-list li {
    margin: 5px 0 0;
  }
</style>
