<script>
    import { onMount, afterUpdate } from "svelte";
    import { activeMenu } from "../../stores/index.js";
    import { userData } from "../../stores";
    import { Router, Link, Route, links, navigate } from "svelte-routing";
    import { pSuccess, pError, pAlert, pConfirm } from "../../utils/notify.svelte";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import ButtonLoader from "../../utils/button_loader.svelte";
    import isJsonParsable from "../../utils/isJsonParsable.js";
    import parseNotificationMessage from "../../utils/parseNotificationMessage.js";
    import MODULES from "../../utils/modules.js" 
    let roleIsOrgAdmin;
    let announcementsList = [],
      elem_announcementsDataTable,
      scheduled = false;
    let studentsCount = 0,
      parentsCount = 0,
      systemUserCount = 0,
      batchesCount = 0,
      channelsCount = 0;
  
      let filterModalThis;
  let filterElemBatch;
  let filterByPrefix;
  let filterElemUser;
  let filterStartDate;
  let isLoading = false;
      
  
  function hideFilterModel(params) {
      _$("#filter-modal").modal("hide");
       assignmentObj.topic = "";
     }
    function showFilterModel(params) {
      _$("#filter-modal").modal("show");
    }
  
  
  let showHideFilterModal = ()=>{
      _$('#filter-modal').modal('show');
    }
  
    function convertDateObjToStr(date) {
      date = new Date(date);
      var month = "" + (date.getMonth() + 1);
      var day = "" + date.getDate();
      var year = date.getFullYear();
  
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
  
      var dateString = [year, month, day].join("-");
      return dateString;
    }
    function convertDateObjToTimeStr(date) {
      date = new Date(date);
      var minutes = "" + date.getMinutes();
      var am_pm = date.getHours() >= 12 ? "PM" : "AM";
      var hours = date.getHours() % 12;
      hours = hours ? hours : 12;
  
      if (hours.length < 2) hours = "0" + hours;
      if (minutes.length < 2) minutes = "0" + minutes;
  
      var timeString = [hours, minutes].join(":") + " " + am_pm;
      return timeString;
    }
    
    async function api_fetchAnnouncementsList(data) {
  
      let action = "announcements.getArchievedAnnouncements";
      var response = await api_handleApiCalls(action, data);
      return response;
  
    }
  
    async function api_archiveAnnouncementById(data) {
  
      let action =  "announcements.archiveAnnouncementById";
      var response = await api_handleApiCalls(action, data);
      return response;
  
    }
    let openCreateAnnouncement = function () {
      navigate("/dashboard/announcements/new");
    };
  
    function parseSorts(sortOrder) {
      // parses datatable order to mongodb sort object
      let sort = {};
      // sorting orders
      let order;
      if (sortOrder.dir === "asc") order = 1;
      else order = -1;
      if (sortOrder.column === 1) {
        sort.message = order;
      } else if (sortOrder.column === 4) {
        sort["mandatory"] = order;
      } else if (sortOrder.column === 5) {
        sort["counts.batchStudentCount"] = order;
      } else if (sortOrder.column === 6) {
        sort["counts.channelMembersCount"] = order;
      } else if (sortOrder.column === 7) {
        sort["counts.studentsCount"] = order;
      } else if (sortOrder.column === 8) {
        sort["counts.parentsCount"] = order;
      } else if (sortOrder.column === 9) {
        sort["counts.sysUsersCount"] = order;
      } else if (sortOrder.column === 10) {
        sort.updatedOn = order;
      } else if (sortOrder.column === 11) {
        sort["schedule.scheduledOn"] = order;
      } else {
        sort.seqId = order;
      }
      return sort;
    }
    let bindDatatable = function () {
      _$(elem_announcementsDataTable).DataTable({
        language: {
          processing: "",
          searchPlaceholder: "Message",
          sSearch: "",
          lengthMenu: "_MENU_ Announcements/page",
        },
        order: [[0, "desc"]],
        // stateSave: true,
        stateSaveCallback: function (settings, data) {
          // localStorage.setItem(window.location.pathname+':scheduled', scheduled);
          // localStorage.setItem(window.location.pathname, JSON.stringify(data));
        },
        stateLoadCallback: function (settings) {
          // scheduled = Boolean(localStorage.getItem(window.location.pathname+':scheduled'))
          // return JSON.parse(localStorage.getItem(window.location.pathname));
        },
        ajax: async function (data, callback, settings) {
          data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
          let list = await api_fetchAnnouncementsList({
            start: data.start,
            length: data.length,
            search: { value: data.search.value },
            sort: data.sort,
            scheduled,
          });
  
          if (list.status) {
            var paginationData = list.data;
            var result = {
              recordsTotal: Number(paginationData.totalCount),
              recordsFiltered: Number(paginationData.filteredCount),
              data: paginationData.data,
            };
            callback(result);
          } else {
            console.log("Something went wrong");
          }
        },
        columns: [
          { data: "seqId" },
          { data: "message",
          render: (data) => {
            // generated notification message
            if(isJsonParsable(data)) return parseNotificationMessage(data)
            // plain notification message
            return data
          }
         },
          {
            data: "mandatory",
            render: (data) =>
              data == 'yes'
                ? `<span class="tx-success" title="read"> <i data-feather="check"> </i> </span`
                : `<span class="tx-danger" title="unread"> <i data-feather="x"> </i> </span`,
          },
          {
            data: "notification_app",
            render: (data) =>
              data
                ? `<span class="tx-success" title="read"> <i data-feather="check"> </i> </span`
                : `<span class="tx-danger" title="unread"> <i data-feather="x"> </i> </span`,
          },
          {
            data: "notification_sms",
            render: (data) =>
              data
                ? `<span class="tx-success" title="read"> <i data-feather="check"> </i> </span`
                : `<span class="tx-danger" title="unread"> <i data-feather="x"> </i> </span`,
          },
          {
            data: "counts",
            render: (data) => ` 
              <span class="badge badge-light pd-x-5">${
                data.batchStudentCount ? data.batchStudentCount : "-"
              }</span>`,
          },
          {
            data: "counts",
            render: (data) =>
              `<span class="badge badge-light pd-x-5">${
                data.channelMembersCount ? data.channelMembersCount : "-"
              }</span>`,
          },
          {
            data: "counts",
            render: (data) =>
              `<span class="badge badge-light pd-x-5">${
                data.studentsCount ? data.studentsCount : "-"
              }</span>`,
          },
          {
            data: "counts",
            render: (data) => `
              <span class="badge badge-light pd-x-5">${
                data.parentsCount ? data.parentsCount : "-"
              }</span>`,
          },
          {
            data: "counts",
            render: (data) => `
              <span class="badge badge-light pd-x-5">${
                data.sysUsersCount ? data.sysUsersCount : "-"
              }</span>`,
          },
          {
            data: "createdOn",
            render: (data) =>
              data
                ? `<div>${convertDateObjToStr(data)
                    .split("-")
                    .reverse()
                    .join("-")}</div>
                <div> ${convertDateObjToTimeStr(data)}</div>`
                : "-",
          },
          {
            data: "schedule",
            render: (data) =>
              data && data.scheduledOn
                ? `<div>${convertDateObjToStr(data.scheduledOn)
                    .split("-")
                    .reverse()
                    .join("-")}</div>
                <div> ${convertDateObjToTimeStr(data.scheduledOn)}</div>`
                : "-",
          },
          {
            data: "_id",
            orderable: false,
            render: function (data, type, row, meta) {
              var unarchive = ""
              unarchive = ` <a href="#" class="unarchive btn-unarchive-announcement mg-r-5" title="unarchive" announcement-id="${data}"><i data-feather="repeat" class="wd-20 mg-r-5"></i></a>`

              
              return `
              <div class="d-md-flex flex-row justify-content-center">
                 ${unarchive}
              </div>
          `;
            },
          },
        //   {
        //   data: "_id",
        //   render: function (data, type, row, meta) {
        //     var edit = "-"
        //     var archive = ""
        //     if(MODULES.hasAccess(MODULES.VIEW_ANNOUNCEMENTS) && !(MODULES.hasAccess(MODULES.ARCHIVE_ANNOUNCEMENTS))){
        //       edit = `<a href="#" class="rename btn-edit-announcement mg-r-5" title="view status" announcement-id="${data}"><i data-feather="activity" class="wd-20 mg-r-5"></i></a>`
        //     }else if(MODULES.hasAccess(MODULES.ARCHIVE_ANNOUNCEMENTS) && !(MODULES.hasAccess(MODULES.VIEW_ANNOUNCEMENTS))){
        //       archive = ` <a href="#" class="delete btn-delete-announcement mg-r-5" title="archive" announcement-id="${data}"><i data-feather="archive" class="wd-20 mg-r-5"></i></a>`
        //     }else if(!(MODULES.hasAccess(MODULES.ARCHIVE_ANNOUNCEMENTS)) && !(MODULES.hasAccess(MODULES.VIEW_ANNOUNCEMENTS))){
        //       edit = "-"
        //       archive = ""
        //     }else{
        //       edit = `<a href="#" class="rename btn-edit-announcement mg-r-5" title="view status" announcement-id="${data}"><i data-feather="activity" class="wd-20 mg-r-5"></i></a>`
        //       archive = ` <a href="#" class="delete btn-delete-announcement mg-r-5" title="archive" announcement-id="${data}"><i data-feather="archive" class="wd-20 mg-r-5"></i></a>`
        //     }
        //     return `
        //     <div class="d-md-flex flex-row">
        //         ${edit}
        //         ${archive}
        //     </div>
        // `;
        //   },
        // },
        ],
        drawCallback: function () {
          feather.replace({ width: "1em", height: "1em" });
        
          _$(".btn-unarchive-announcement")
            .unbind("click")
            .click(function (e) {
              e.preventDefault();
              var announcementId = _$(this).attr("announcement-id");
              api_unarchiveAnnouncementById(announcementId)
              
              })
          // bind actions
        },
        processing: true,
        serverSide: true,
      });
  
      _$(".dataTables_length select").select2({
        minimumResultsForSearch: Infinity,
      });
    };
    async function api_unarchiveAnnouncementById(announcementId){
    let result = await api_handleApiCalls("announcements.unArchiveAnnouncement", {_id: announcementId}) 
                if(result.status ) {
                  pSuccess('', result.data)
                  _$(elem_announcementsDataTable).DataTable().ajax.reload(null, false);
                } else {
                  pError('', result.errMsg || result.error || 'Something went wrong')
                }
  }
    let reload = async function () {
      _$(elem_announcementsDataTable).DataTable().ajax.reload();
    };
  
    afterUpdate(function () {
      feather.replace();
    });
  
    const unsubscribe = userData.subscribe(value => {
      roleIsOrgAdmin = value.role == "student"
    });
  
    onMount(function () {
      activeMenu.set("announcements");
      unsubscribe();
      bindDatatable();
    });
    let onClickShowArchivedList = () => {
         
         navigate(("/dashboard/reportings/archived"))
        // redrawEnquiryTable()
    }
  </script>
  
  <div class="content-body fadeIn">
    <!-- <Link style="display: none;" to="about">About</Link> -->
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div>
        <nav use:links aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">
                Archived Announcements
            </li>
          </ol>
        </nav>
        <h4 class="mg-b-0 tx-spacing--1">Archived Announcements</h4>
        <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search annoucement.</p>
      </div>
     
    </div>
    <!-- charts -->
    <hr class="full-width">
    <div class="row row-xs">
      <!-- div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
        <div class="card card-body">
          <h6
            class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
            Batches
          </h6>
          <div class="d-flex d-lg-block d-xl-flex align-items-end">
            <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{batchesCount}</h3>
            <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
          </div>
          <div class="chart-three">
            <div id="flotChart5" class="flot-chart ht-30" />
          </div>
        </div>
      </div> -->
      <!-- chart-three -->
      <!-- col -->
      <!-- <div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
        <div class="card card-body">
          <h6
            class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
            Channels
          </h6>
          <div class="d-flex d-lg-block d-xl-flex align-items-end">
            <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{channelsCount}</h3>
            <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
          </div>
          <div class="chart-three">
            <div id="flotChart6" class="flot-chart ht-30" />
          </div>
        </div>
      </div> -->
      <!-- chart-three -->
      <!-- col -->
      <!-- <div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
        <div class="card card-body">
          <h6
            class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
            Students
          </h6>
          <div class="d-flex d-lg-block d-xl-flex align-items-end">
            <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{studentsCount}</h3>
            <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
          </div>
          <div class="chart-three">
            <div id="flotChart6" class="flot-chart ht-30" />
          </div>
        </div>
      </div> -->
      <!-- chart-three -->
      <!-- col -->
      <!-- <div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
        <div class="card card-body">
          <h6
            class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
            Parents
          </h6>
          <div class="d-flex d-lg-block d-xl-flex align-items-end">
            <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{parentsCount}</h3>
            <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
          </div>
          <div class="chart-three">
            <div id="flotChart6" class="flot-chart ht-30" />
          </div>
        </div>
      </div> -->
      <!-- chart-three -->
      <!-- col -->
      <!-- <div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
        <div class="card card-body">
          <h6
            class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
            System Users
          </h6>
          <div class="d-flex d-lg-block d-xl-flex align-items-end">
            <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
              {systemUserCount}
            </h3>
            <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
          </div>
          <div class="chart-three">
            <div id="flotChart6" class="flot-chart ht-30" />
          </div>
        </div>
      </div> -->
      <!-- chart-three -->
      <!-- col -->
    </div>
    <!-- logged in devices -->
    <!-- logged in students -->
    <!-- logged in system users -->
    <div class="">
      <!-- tabs -->
      <ul class="nav nav-line mg-b-15" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="sent-tab"
            data-toggle="tab"
            href="javascript:void(0)"
            role="tab"
            aria-controls="sent"
            aria-selected={!scheduled}
            on:click={() => {
              scheduled = false;
              _$(elem_announcementsDataTable)
                .DataTable()
                .ajax.reload(null, false);
            }}>Sent</a>
        </li>
        <!-- <li class="nav-item">
          <a
            class="nav-link"
            id="scheduled-tab"
            data-toggle="tab"
            href="javascript:void(0)"
            role="tab"
            aria-controls="scheduled"
            aria-selected={scheduled}
            on:click={() => {
              scheduled = true;
              _$(elem_announcementsDataTable)
                .DataTable()
                .ajax.reload(null, false);
            }}>Scheduled</a>
        </li> -->
      </ul>
      <table bind:this={elem_announcementsDataTable} class="table table-sm">
        <thead>
          <tr>
            <th class="wd-auto text-capitalize">#</th>
            <th class="wd-auto text-capitalize">message</th>
            <th class="wd-auto text-capitalize">mandatory</th>
            <th class="wd-auto text-capitalize">app</th>
            <th class="wd-auto text-capitalize">sms</th>
            <th class="wd-auto text-capitalize">batch</th>
            <th class="wd-auto text-capitalize">channel</th>
            <th class="wd-auto text-capitalize">student</th>
            <th class="wd-auto text-capitalize">parent</th>
            <th class="wd-auto text-capitalize">sys user</th>
            <th class="wd-auto text-capitalize">added on</th>
            <th class="wd-auto text-capitalize">scheduled on</th>
            <th class="wd-auto text-capitalize">actions</th>
          </tr>
        </thead>
        <tbody />
      </table>
    </div>
    <!-- {JSON.stringify(announcementsList, null, 2)} -->
  </div>
  
 
  
