<script>
    import {
        onMount,
        tick
    } from "svelte";
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import MODULES from "../utils/modules.js"
    import { API_URL } from "../../config.js"
    let dueTable;
    let upcomingTable;
    let pdcTodayTable;
    let pdcUpcomingTable;
    let sendNotifications;
    let notificationObj = {
        notification_app: true,
        notification_sms: false,
    }
    let errorObj = {}
    let modelId = 0;
    let isLoading = false;



    let convertToINR = function (input) {
        let amount = Number(input);
        if (Number.isNaN(amount)) {
            amount = 0
        }
        return amount.toLocaleString('en-IN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'INR'
        });
    }


    async function sendDuePaymentNotification(InstallmentID) {
        var response = await api_handleApiCalls("payment.getPaymentById", {
            _id: InstallmentID
        });
        if (response && response.data) {
            await tick();
            feather.replace();
            notificationObj.message = ""
            notificationObj.mode = "Due"
            notificationObj.studentId = response.data.studentId
            notificationObj.installmentAmount = response.data.installmentAmount
            _$(sendNotifications).modal('show');
        } else {
            PNotify.error({
                title: 'Error',
                text: "Something went wrong",
                delay: 3000
            })
        }
    }


    let renderTableRow = (row, table) => {

        var statusElem
        var userIcon

        if (row.student.profilePic) {
            userIcon =
                `<img src="${row.student.profilePic}" class="avatar-initial rounded-circle bg-primary tx-uppercase"/>`
        } else {
            userIcon =
                `<span class="avatar-initial rounded-circle bg-primary tx-uppercase op-6">${row.student.name.charAt(0)}</span>`
        }

        var notify = ""
        if (MODULES.hasAccess(MODULES.SEND_NOTIFICATIONS_PAYMENT)) {
            notify = ` <a href="#" class="sendNotification" id = "installmentLink" installment_id="${row._id}" ><i class="mg-r-5" data-feather="bell"></i>Send notification </a>`
        }


        if (table == 'due') {

            return `
                <div class="d-flex flex-column" style="width: 100%">
                    <div class="d-flex justify-content-between mg-t-15">
                        <div class="avatar avatar-sm">
                            ${userIcon}
                        </div>
                        <div class="media-body mg-l-10 justify-content-between">
                            <h6 class="tx-13 mg-b-3">${capitalizeFirstLetter(row.student.name)}</h6>
                            <span class="tx-12"><i class="mg-r-5" data-feather="phone"></i>${row.student.mobileNo}</span>
                        </div><!-- media-body -->
                        ${notify}
                    </div>
                    <div class="media">
                        <div class="media-body media mg-b-10 d-block mg-t-10">
                            <p class="tx-medium mg-b-0">${row.batch.name
                }</p>
                            <p class="mg-b-0 tx-11 tx-color-02 tx-uppercase tx-medium">${new Date(row.paymentDate).toLocaleDateString()}</p>
                        </div>
    
                        <div class="text-right">
                            <p class="tx-normal tx-rubik mg-b-0">${convertToINR(row.installmentAmount)}</p>
                            <p class="mg-b-0 tx-12 tx-rubik tx-color-02">
                                ${row.paymentType}
                            </p>
                    </div>
                    </div><!-- media -->
                
                </div>
            `
        }

        return `
                <div class="d-flex flex-column" style="width: 100%">
                    <div class="d-flex justify-content-between mg-t-15">
                        <div class="avatar avatar-sm">
                            ${userIcon}
                        </div>
                        <div class="media-body mg-l-10 justify-content-between">
                            <h6 class="tx-13 mg-b-3">${capitalizeFirstLetter(row.student.name)}</h6>
                            <span class="tx-12"><i class="mg-r-5" data-feather="phone"></i>${row.student.mobileNo}</span>
                        </div><!-- media-body -->
                         ${notify}
                    </div>
                    <div class="media">
                        <div class="media-body media mg-b-10 d-block mg-t-10">
                            <p class="tx-medium mg-b-0">${row.batch.name
            }</p>
                            <p class="mg-b-0 tx-11 tx-color-02 tx-uppercase tx-medium">${new Date(row.paymentDate).toLocaleDateString()}</p>
                        </div>
    
                        <div class="text-right">
                            <p class="tx-normal tx-rubik mg-b-0">${convertToINR(row.installmentAmount)}</p>
                            <p class="mg-b-0 tx-12 tx-rubik tx-color-02">
                                ${row.paymentType}
                            </p>
                    </div>
                    </div><!-- media -->
                    
                </div>
            `


        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
    let drawCallbackDatatable = () => {
        feather.replace({
            width: "1em",
            height: "1em"
        });
        _$("table.dataTable tbody td.sorting_1").css({
            "background-color": "#fff"
        }).addClass("list-item");


        _$('.sendNotification').unbind('click').click(function (e) {
            e.preventDefault();
            var installment_id = _$('#installmentLink').attr('installment_id');
            sendDuePaymentNotification(installment_id)
        })
    }


    let onSendNotification = async function () {
        if (isLoading) return;
        isLoading = true;
        await tick()
        let isValid = await validateInput(notificationObj);
        if (!isValid) {
            isLoading = false;
            return false;
        }
        var response = await api_handleApiCalls("payment.sendPaymentNotification", notificationObj)

        if (response && response.data) {
            await tick();
            feather.replace();
            PNotify.success({
                title: 'Successful',
                text: "Notification Sent Successfully ! ",
                delay: 3000
            })
        } else {
            PNotify.error({
                title: 'Error',
                text: "Something went wrong",
                delay: 3000
            })
        }
        isLoading = false;

        _$(sendNotifications).modal('hide');
    }

    function bindDatatable() {
        _$(dueTable).DataTable({
            searching: false,
            bLengthChange: false,
            bProcessing: false,
            responsive: true,
            "pageLength": 5,
            language: {
                processing: "",
                emptyTable: "No data found",
            },
            ajax: function (data, callback, settings) {
                _$.ajax({
                    url: API_URL,
                    method: "post",
                    data: {
                        action: "payment.getDuePayments",
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                        },
                    },
                    success: function (resData) {
                        var result = [];
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(
                                    paginationData.filteredCount
                                ),
                                data: paginationData.data,
                            };
                            console.log(
                                "bindDatatable :::::::::::::::::::::::::::::::",
                                paginationData.data
                            );
                            callback(result);
                        } else { }
                    },
                    error: function (
                        XMLHttpRequest,
                        textStatus,
                        errorThrown
                    ) { },
                });
            },
            columnDefs: [{
                targets: [0],
                render: function (data, type, row) {
                    return renderTableRow(row, 'due')
                },
            },],
            drawCallback: drawCallbackDatatable,
            processing: true,
            serverSide: true,
        });

        _$(upcomingTable).DataTable({
            searching: false,
            bLengthChange: false,
            bProcessing: false,
            responsive: true,
            "pageLength": 5,

            language: {
                processing: "",
                emptyTable: "No data found",
            },
            ajax: function (data, callback, settings) {
                _$.ajax({
                    url: API_URL,
                    method: "post",
                    data: {
                        action: "payment.getUpcomingPayments",
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                        },
                    },
                    success: function (resData) {
                        var result = [];
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(
                                    paginationData.filteredCount
                                ),
                                data: paginationData.data,
                            };
                            console.log(
                                "bindDatatable :::::::::::::::::::::::::::::::",
                                paginationData.data
                            );
                            callback(result);
                        } else { }
                    },
                    error: function (
                        XMLHttpRequest,
                        textStatus,
                        errorThrown
                    ) { },
                });
            },
            columnDefs: [{
                targets: [0],
                render: function (data, type, row) {
                    var statusElem;
                    var userIcon;
                    if (row.status == "hot") {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-800 tx-white d-flex align-items-center justify-content-center rounded">Hot</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-primary">${row.student.name.charAt(
                            0
                        )}</span>`;
                    } else if (row.status == "warm") {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-600 tx-white d-flex align-items-center justify-content-center rounded">Warm</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-indigo">${row.student.name.charAt(
                            0
                        )}</span>`;
                    } else if (row.status == "cold") {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-400 tx-white d-flex align-items-center justify-content-center rounded">Cold</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-indigo">${row.student.name.charAt(
                            0
                        )}</span>`;
                    } else {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-300 tx-white d-flex align-items-center justify-content-center rounded">-</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-gray-800">${row.student.name.charAt(
                            0
                        )}</span>`;
                    }

                    return renderTableRow(row, 'upcoming')
                },
            },],
            drawCallback: drawCallbackDatatable,
            processing: true,
            serverSide: true,
        });

        _$(pdcTodayTable).DataTable({
            searching: false,
            bLengthChange: false,
            bProcessing: false,
            responsive: true,
            "pageLength": 5,

            language: {
                processing: "",
                emptyTable: "No data found",
            },
            ajax: function (data, callback, settings) {
                _$.ajax({
                    url: API_URL,
                    method: "post",
                    data: {
                        action: "payment.getPDCTodayPayments",
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                        },
                    },
                    success: function (resData) {
                        var result = [];
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(
                                    paginationData.filteredCount
                                ),
                                data: paginationData.data,
                            };
                            console.log(
                                "bindDatatable :::::::::::::::::::::::::::::::",
                                paginationData.data
                            );
                            callback(result);
                        } else { }
                    },
                    error: function (
                        XMLHttpRequest,
                        textStatus,
                        errorThrown
                    ) { },
                });
            },
            columnDefs: [{
                targets: [0],
                render: function (data, type, row) {
                    var statusElem;
                    var userIcon;
                    if (row.status == "hot") {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-800 tx-white d-flex align-items-center justify-content-center rounded">Hot</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-primary">${row.student.name.charAt(
                            0
                        )}</span>`;
                    } else if (row.status == "warm") {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-600 tx-white d-flex align-items-center justify-content-center rounded">Warm</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-indigo">${row.student.name.charAt(
                            0
                        )}</span>`;
                    } else if (row.status == "cold") {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-400 tx-white d-flex align-items-center justify-content-center rounded">Cold</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-indigo">${row.student.name.charAt(
                            0
                        )}</span>`;
                    } else {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-300 tx-white d-flex align-items-center justify-content-center rounded">-</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-gray-800">${row.student.name.charAt(
                            0
                        )}</span>`;
                    }

                    return renderTableRow(row)
                },
            },],
            drawCallback: drawCallbackDatatable,
            processing: true,
            serverSide: true,
        });

        _$(pdcUpcomingTable).DataTable({
            searching: false,
            bLengthChange: false,
            bProcessing: false,
            responsive: true,
            "pageLength": 5,

            language: {
                processing: "",
                emptyTable: "No data found",
            },
            ajax: function (data, callback, settings) {
                _$.ajax({
                    url: API_URL,
                    method: "post",
                    data: {
                        action: "payment.getPDCUpcomingPayments",
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                        },
                    },
                    success: function (resData) {
                        var result = [];
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(
                                    paginationData.filteredCount
                                ),
                                data: paginationData.data,
                            };
                            console.log(
                                "bindDatatable :::::::::::::::::::::::::::::::",
                                paginationData.data
                            );
                            callback(result);
                        } else { }
                    },
                    error: function (
                        XMLHttpRequest,
                        textStatus,
                        errorThrown
                    ) { },
                });
            },
            columnDefs: [{
                targets: [0],
                render: function (data, type, row) {
                    var statusElem;
                    var userIcon;
                    if (row.status == "hot") {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-800 tx-white d-flex align-items-center justify-content-center rounded">Hot</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-primary">${row.student.name.charAt(
                            0
                        )}</span>`;
                    } else if (row.status == "warm") {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-600 tx-white d-flex align-items-center justify-content-center rounded">Warm</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-indigo">${row.student.name.charAt(
                            0
                        )}</span>`;
                    } else if (row.status == "cold") {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-400 tx-white d-flex align-items-center justify-content-center rounded">Cold</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-indigo">${row.student.name.charAt(
                            0
                        )}</span>`;
                    } else {
                        statusElem =
                            '<span class="wd-40 ht-40 bg-gray-300 tx-white d-flex align-items-center justify-content-center rounded">-</span>';
                        userIcon = `<span class="avatar-initial rounded-circle bg-gray-800">${row.student.name.charAt(
                            0
                        )}</span>`;
                    }
                    return renderTableRow(row)
                },
            },],
            drawCallback: drawCallbackDatatable,
            processing: true,
            serverSide: true,
        });



    }

    async function clearValidations() {
        errorObj = {}
        await tick();
    }

    function validateInput(data) {
        if (data.message == "") {
            errorObj.message = "Please Enter Message Text";
        }

        if (Object.values(errorObj).length > 0) {
            return false;
        } else {
            return true;
        }
    }

    onMount(async () => {
        await tick();
        feather.replace();
        bindDatatable()
        return;
        switch (tableKey) {
            case "due":
                datatableInstance = bindDatatable("payment.getDuePayments");
            case "upcoming":
                datatableInstance = bindDatatable(
                    "payment.getUpcomingPayments"
                );
            case "PDCToday":
                datatableInstance = bindDatatable(
                    "payment.getPDCTodayPayments"
                );
            case "PDCUpcoming":
                datatableInstance = bindDatatable(
                    "payment.getPDCUpcomingPayments"
                );
            default:
                datatableInstance = bindDatatable(
                    "payment.getPDCUpcomingPayments"
                );
                break;
        }
    });
</script>

<style>
    table.dataTable tbody td.sorting_1 {
        background-color: #fff !important;
    }
</style>

<div class="col-sm-4 col-xl-4">
    <div class="mg-b-25 ht-650">
        <div class="df-example demo-table" style="width: 100%;">
            <table style="width: 100%;" bind:this="{dueTable}" class="table table-striped mg-b-0">
                <thead>
                    <tr>
                        <th class="">Due Payments</th>
                    </tr>
                </thead>
                <tbody class="list-group-flush tx-13" />
            </table>
        </div>
    </div>
</div>

<div class="col-sm-4 col-xl-4">
    <div class="mg-b-25 ht-650">
        <div class="df-example demo-table" style="width: 100%;">
            <table style="width: 100%;" bind:this="{upcomingTable}" class="table table-striped mg-b-0">
                <thead>
                    <tr>
                        <th class="">Upcoming Payments</th>
                    </tr>
                </thead>
                <tbody class="list-group-flush tx-13" />
            </table>
        </div>
    </div>
</div>

<div class="col-sm-4 col-xl-4">
    <div class="mg-b-25 ht-650">

        <div class="df-example demo-table" style="width: 100%;">
            <table style="width: 100%;" bind:this="{pdcTodayTable}" class="table table-striped mg-b-0">
                <thead>
                    <tr>
                        <th class="">Post-dated cheque today</th>
                    </tr>
                </thead>
                <tbody class="list-group-flush tx-13" />
            </table>
        </div>
    </div>
</div>

<div class="col-sm-4 col-xl-4">
    <div class="mg-b-25 ht-650">
        <div class="df-example demo-table" style="width: 100%;">
            <table style="width: 100%;" bind:this="{pdcUpcomingTable}" class="table table-striped mg-b-0">
                <thead>
                    <tr>
                        <th class="">Post-dated cheque upcoming</th>
                    </tr>
                </thead>
                <tbody class="list-group-flush tx-13" />
            </table>
        </div>
    </div>
</div>


<div class="modal effect-scale" bind:this={sendNotifications} id="modal-new-batch" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered wd-sm-650" role="document">
        <div class="modal-content ">
            <div class="modal-body pd-0">
                <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                    <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="media align-items-center">
                        <span class="tx-color-03 d-none d-sm-block">
                            <i data-feather="trello" class="wd-60 ht-60"></i></span>
                        <div class="media-body mg-sm-l-20">
                            <h4 class=" tx-18 tx-sm-20 mg-b-2">
                                Payment Notification
                            </h4>
                            <p class="tx-13 tx-color-03 mg-b-0">Send Payment Notification</p>


                        </div>
                    </div><!-- media -->
                </div><!-- modal-header -->
                <hr class="full-width">


                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>

                <div class="row no-gutters">
                    <div class="col-12 bg-white rounded-right">
                        <div class="ht-100p d-flex flex-column justify-content-center pd-l-25 pd-r-25 pd-b-25">

                            <div class=" tx-color-02">
                                <div class="form-group">
                                    <label class="d-block pd-t-10 pd-b-0 mg-b-0">Notification Message
                                        <span class="required-field">*</span></label>
                                    <input bind:value={notificationObj.message} type="text"
                                        class="form-control first-input" class:parsley-error={errorObj.message}
                                        on:focus={clearValidations} placeholder="Enter Notification Message" />
                                    {#if errorObj.message}
                                    <div class="parsley-errors-list">{errorObj.message}</div>
                                    {/if}


                                </div>

                                <div class="col-sm-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-6 col-sm-12">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" disabled={true} id="notification-sms"
                                                    bind:checked={notificationObj.notification_sms}
                                                    name="notification-sms" class="custom-control-input" />
                                                <label class="custom-control-label" for="notification-sms">
                                                    Send SMS notifications
                                                    <span class="required-field">*</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6 col-sm-12">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" id="notification-app"
                                                    bind:checked={notificationObj.notification_app}
                                                    name="notification-app" class="custom-control-input" />
                                                <label class="custom-control-label" for="notification-app">
                                                    Send App notifications?
                                                    <span class="required-field">*</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <a href="#" on:click={onSendNotification} disabled={isLoading} data-toggle="modal"
                                    class="btn btn-sm pd-x-15 btn-primary mg-l-5" data-animation="effect-scale">
                                    <i data-feather="send" class="wd-10 mg-r-5"></i> Send
                                </a>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
