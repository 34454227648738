<script>
    import {
        Router,
        links,
        Link,
        Route,
        navigate
    } from "svelte-routing";
    import {
        createEventDispatcher,
        onMount, beforeUpdate,
        tick,
        afterUpdate
    } from 'svelte';
    import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
    import { userData } from "../stores";
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import MODULES from "../utils/modules.js"
    import { API_URL } from "../../config.js"
    const dispatch = createEventDispatcher();
    export let studentId;

    let demoDataTable;
    let roleIsTelecaller;
    let studentDataTable;
    let emptyState = false;
    let assignmentObj = {
        topic: "",
    }
    let search = {
        value: ""
    };
    let assignmentList = []
    let errorObj = {}
    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    }
    const pageSize = 10;
    let length = pageSize;

    let validateAssignment = () => {
        errorObj = {};
        if (!assignmentObj.topic) {
            var newErrorObj = {};
            if (!assignmentObj.topic) {
                newErrorObj.topic = "Assignment topic is required Field.";
            }
            errorObj = newErrorObj;

        }
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;


    }


    let addUpdateAssignmentBtn = async () => {
        if (validateAssignment()) {
            var assignmentRes = await api_addUpdateAssignment(assignmentObj);
            if (assignmentRes.status) {
                // create success

                PNotify.success({
                    title: 'Success',
                    text: assignmentRes.data[0],
                    delay: 3000
                })
                navigate(("assignmentprogress/edit/" + assignmentRes.data[1]))

            } else {
                PNotify.error({
                    title: 'Error',
                    text: assignmentRes.errMsg,
                    delay: 3000
                })
            }
        }
    }

    async function api_addUpdateAssignment(data) {

        let action = "assignment.addUpdateAssignment";
        var response = await api_handleApiCalls(action, data);
        return response;
    }


    function hideModel(params) {
        _$("#assignment-add-modal").modal("hide");
        assignmentObj.topic = "";
    }
    function showModel(params) {
        _$("#assignment-add-modal").modal("show");
    }

    const unsubscribe = userData.subscribe(value => {
        roleIsTelecaller = value.role == "telecaller"
    });

    onMount(async () => {
        unsubscribe();
        //   activeMenu.set('assignmentprogress')
        feather.replace();
        dispatch('setActiveTab', {
            tabName: 'Assignment'
        });
        bindDatatable();
    });



    async function deleteBtn(id) {
        let response = await api_deleteAssigment(id);
        if (response.status) {
            if (studentDataTable) { studentDataTable.draw() }
            pSuccess(null, response.data)
        } else {
            pError("Error", response.errMsg)
        }
    }

    async function api_deleteAssigment(id) {

        let action = "assignment.delete";
        var response = await api_handleApiCalls(action, data);
        return response;

    }


    function bindDatatable() {
        studentDataTable = _$(demoDataTable).DataTable({
            responsive: true,
            language: {
                processing: "",
                searchPlaceholder: 'Topic',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                infoFiltered: "",
            },
            ajax: function (data, callback, settings) {
                _$.ajax({
                    url: API_URL,
                    method: 'post',
                    data: {
                        action: 'assignmentprogress.listAssignmentProgressByStudentId',
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                            studentId: studentId
                        }
                    },
                    success: function (resData) {
                        var result = []
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(paginationData.filteredCount),
                                data: paginationData.data
                            }
                            if (!resData.data.data.length) {
                                emptyState = true
                            }
                            callback(result);
                        }
                        else {
                            console.log("Something went wrong");
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        emptyState = true
                    }
                })
            },
            columns: [
                { data: 'assignmentDetails.topic', defaultContent: '<i>No data found<i>' },
                // { data: 'email', defaultContent : '<i>No data found<i>' },
                // { data: 'mobileNo', defaultContent : '<i>No data found<i>' },
                {
                    data: 'assignmentDetails.createdOn',
                    render: function (data, a, b, c) {
                        let d = new Date(data)
                        return moment(d).format('ll')

                    }
                },
                {
                    data: 'assignmentDetails.submissionDate',
                    render: function (data, a, b, c) {
                        if (!data) {
                            return '-'
                        }
                        let d = new Date(data)
                        return moment(d).format('ll')

                    }
                },
                {
                    data: 'assignmentDetails.submissionTime',
                    render: function (data, a, b, c) {
                        if (!data) {
                            return '-'
                        }
                        let d = new Date(data)
                        return moment(d).format('LT')

                    }
                },
                // { data: ,                  },
                {
                    data: '_id', render: function (data, a, b, c) {
                        if (MODULES.hasAccess(MODULES.UPDATE_STUDENT)) {
                            return `
                                <div class="d-md-flex flex-row">
                                    <a href="#" class="rename btn-edit-assignment mg-r-5" data-id="${data}"><i data-feather="edit" class="wd-20 mg-r-5"></i></a>
                                </div>
                                `;
                        }
                        // <a href="#" class="delete btn-remove-assignment mg-r-5" data-id="${data}"><i data-feather="trash" class="wd-20 mg-r-5"></i></a>

                    }
                },

            ],
            "drawCallback": function () {
                feather.replace({ width: "1em", height: "1em" })
                _$('.btn-edit-assignment').unbind('click').click(function (e) {
                    if (roleIsTelecaller) {
                        return
                    }
                    e.preventDefault();
                    var assignmentId = _$(this).attr("data-id");

                    navigate(("/dashboard/assignmentprogress/edit/" + assignmentId))

                })
            },
            "processing": true,
            "serverSide": true,
        });
        _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
    }

</script>

<div class="content-body fadeIn">
    <div data-label="Example" class="df-example demo-table">
        <table bind:this={demoDataTable} class="table">
            <thead>
                <tr>
                    <th class="wd-20p">Topic</th>
                    <th class="wd-15p">Added On</th>
                    <th class="wd-15p">Submission Date</th>
                    <th class="wd-15p">Submission Time</th>
                    <th class="wd-25p">Actions </th>
                </tr>
            </thead>
            <tbody></tbody>

        </table>
        {#if emptyState }
        <div class="empty-state-img" style="text-align: center;">
            <img src="/images/empty.svg" style="width: 200px" alt="empty" />
            <p class="tx-bold">No assignments found</p>
        </div>
        {/if}
    </div>

</div>
