<script>
    import { Router, Link, Route } from "svelte-routing";
    import ListRoute from "./list/list.svelte";
    import AddUpdate from "./newAnnouncement/newAnnouncement.svelte";
    import Status from "./status/status.svelte";
    import MODULES from "../utils/modules.js"
    import Forbidden from "../forbidden.svelte" 
    import ArchievedList from "./archievedList/index.svelte";

    
</script>

<Router>

    <Route path="/new" >
        {#if MODULES.hasAccess(MODULES.ADD_ANNOUNCEMENTS)}
        <AddUpdate />
        {:else}
        <Forbidden />
        {/if}
    </Route>
    <Route path="/status/:id" let:params>
        {#if MODULES.hasAccess(MODULES.VIEW_ANNOUNCEMENTS)}
        <Status _id="{params.id}"></Status>
        {:else}
        <Forbidden />
        {/if}
    </Route>
    <Route path="">
        <ListRoute></ListRoute>
    </Route>
    <Route path="archived">
        <ArchievedList ></ArchievedList>
    </Route>
</Router>
