<script>
    import { onMount, tick } from "svelte";
    import { navigate } from 'svelte-routing';

    import api_handleApiCalls from "../utils/apiCallHandler";
    import moment from "moment";
    import {pError, pSuccess} from '../utils/notify.svelte'
        export let parentId
    let elemStudentsDoubtsTable
    let instanceSelectizeStudentTable
    export let tillFullPath = []
    
        
        onMount(async ()=>{
        //    console.log("parentId---",parentId)
           datatableInit()
        })
        function getFilterData() {
        
        return {
         
         parentId
        };
      }
         // studyMonitoring.getMarkedMonitoringData
    const datatableInit = () => {
        if(instanceSelectizeStudentTable) instanceSelectizeStudentTable.destroy()
        instanceSelectizeStudentTable   =   _$(elemStudentsDoubtsTable).DataTable({
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                localStorage.setItem(window.location.pathname, JSON.stringify(data));
            },
            stateLoadCallback: function (settings) {
                return JSON.parse(localStorage.getItem(window.location.pathname));
            },
            "processing": true,
            "serverSide": true,
            responsive: false,
            select: true,
            "columnDefs": [
                { "orderable": false, "targets": [0] },
            ],
            language: {
                processing: "",
                searchPlaceholder: "Student Name | Number",
                sSearch: "",
                lengthMenu: "_MENU_ Students/page",
            },
            ajax: function (data, callback, settings) {
                var filterObj = {  ...getFilterData()};
                  
                   filterObj.start = data.start;
                   filterObj.length = data.length;
                   filterObj.search = data.search.value;
                   filterObj.draw = data.draw;
                   filterObj.sort = data.sort;
                _$.ajax({
                    url: API_URL,
                    method: "post",
                    data: {
                        action: "studyMonitoring.fetchAllDoubts",
                        accessToken: localStorage.getItem("accessToken"),
                        data: filterObj,
                    },
                    success: async function (resData) {
                        var result = [];
                        if (resData.status) {
                        var paginationData = resData.data;
                        result = {
                            recordsTotal: Number(paginationData.totalCount),
                            recordsFiltered: Number(paginationData.filteredCount),
                            data: paginationData.data,
                        };
                        if (!resData.data.length) {

                        }
                            // await tick();
                            callback(result);
                        } else {
                            await tick();
                            callback({
                                recordsTotal: 0,
                                recordsFiltered: 0,
                                data: [],
                            });
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {

                    },
                });
            },
            columns: [
                    {
                        data: "student",
                        render: (data, type, full) => {
                            let image;
                            // if image found display profilePic avatar
                            image = `<img src="${data.profilePic ? data.profilePic : "/images/man.png"
                                }" class="avatar mg-r-15" alt="${data.name[0].toUpperCase()}">`;
                            return `
                          <div style = "cursor:pointer" class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 btn-edit-student" student-id="${full._id
                                }">
                            ${image}
                            <div class="wd-80p bg-transparent">
                              <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent btn-edit-student tx-capitalize" student-id="${full._id
                                }">${data.name || "-"} </h6>
                              <span class="d-block tx-11 text-muted bg-transparent">
                                  <i data-feather="phone"></i>
                                  ${data.mobileNo || "-"}
                              </span>
                             
                            </div>
                          </div>`;
                        },
                    },
                    
                    {
                        data: "type",
                        render: (data, type, full) => {
                            let doubtType =''
                            if(data == 'reading') doubtType = "Reading"
                            else doubtType = "Solving"
                            return doubtType  ? doubtType : " - "
                        },
                    },
                    {
                        data: "numberOfQuestions",
                        render: (data, type, full) => {
                            
                            return data  ? data : " - "
                        },
                    },
                    {
                        data: "createdOn",
                        render: (data, type, full) => {
                            return data ? moment(data).format('DD-MM-YYYY , hh:mm A') : " - "
                        },
                    },
                   
                    {
                        data: "solvedOn",
                        render: (data, type, full) => {
                            return data ? moment(data).format('DD-MM-YYYY , hh:mm A'):" - "
                        },
                    },
                    {
                        data: "solvedBy",
                        render: (data, type, full) => {
                            return data ? data :" - "
                        },
                    },
                    {
                        data: "solved",
                        render: (data, type, full) => {
                            let show;
                            if(data){
                                show =`<span class="badge badge-success">Solved</span>`
                            }else{
                                show =`<span class="badge badge-danger">Pending</span>`
                            }
                           
                            return show;
                        },
                    },
                    {
                        data: "student",
                        render: (data, type, full) => {
                            return `<a 
                                        href="#" 
                                        class="btn-view-student 
                                        mg-r-5" 
                                        data-id="${data._id}" 
                                        data-toggle="tooltip" 
                                        data-placement="top" 
                                        title="View Batch Students"> 
                                            View <i data-feather="arrow-up-right" class="wd-15 mg-r-5"></i> 
                                        </a>`
                        },
                    },
                   
            ],
            "drawCallback": function () { 
                feather.replace({ width: "1em", height: "1em" })
                _$('.btn-view-student').unbind('click').click(function (e) {
                    e.preventDefault();
                    var dataId = _$(this).attr("data-id");
                    viewStudentClick(dataId)
                })
            },
        })
    }
    
    const viewStudentClick = (id)   =>  {
        navigate(`/dashboard/student/view/${id}/studyMonitoringChart/${tillFullPath.join('-')}`)
    }
    
    </script>
    <div class="col-md-12 pd-5" style="margin-top: 1rem;">
        <div style="margin-bottom: 3rem;"><span class="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15">List of Doubts raised by students for this topic</span></div>
       
                <table  bind:this={elemStudentsDoubtsTable} class="table table-reposive mt-5">
                    <thead>
                        <tr>
                            <th>Student Name</th>
                            <th>Doubt Type</th>
                            <th>No. of Questions Solved</th>
                            <th>Doubt Raised On </th>
                            <th>Doubt Solved On</th>
                            <th>Solved By</th>
                            <th>Status</th>
                            <th>Action</th>

                        </tr>
                    </thead>
                </table>
            
          

    

</div>
