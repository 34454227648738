<script>
    import { links, navigate } from "svelte-routing";

    let toDashboard = async () => {
        navigate("/dashboard");
    }
</script>

<div class="sign-wrapper mg-lg-r-50 mg-xl-r-60 fadeIn" style="    flex: 1;">
    <div class="pd-t-20 wd-100p">
        <h4 class="tx-color-01 mg-b-5">Registration Successful</h4>
        <p class="tx-color-03 tx-16 mg-b-40">It's free to signup and only takes a minute.</p>

        <button on:click={toDashboard} class="btn btn-brand-02 btn-block">Access My Dashboard</button>
        <div class="divider-text">or</div>
        <!-- <button class="btn btn-outline-facebook btn-block">Sign Up With Facebook</button>
        <button class="btn btn-outline-twitter btn-block">Sign Up With Twitter</button> -->
        <div class="tx-13 mg-t-20 tx-center">Already have an account? <a use:links href="/login">Sign In</a>
        </div>
    </div>
</div><!-- sign-wrapper -->
