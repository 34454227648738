<script>
    import { Router, Link, Route } from "svelte-routing";
    import Attendance from "./attendance.svelte";

</script>

<Router>
    <Route path="">
        <Attendance></Attendance>
    </Route>
    <Route path=":batch_id" let:params>
        <Attendance batch_id="{params.batch_id}"></Attendance>
    </Route>
</Router>
