<script>
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { navigate } from "svelte-routing";
  import { pSuccess, pError, pAlert } from "../../utils/notify.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";

  let elm_modalCmp, elem_firstInput, elem_selectAssignTo;
  let taskObj = {};
  let errorObj = {};
  let isLoading = null;
  const dispatch = createEventDispatcher();
  let newTaskId = null;
 
  function clearError(key) {
    delete errorObj[key];
    errorObj = { ...errorObj };
  }
  function clearValidations(){
    for( err in errorObj){
      delete errorObj[err]
    }
    errorObj = { ...errorObj };
  }
  function clearFields() {
    taskObj.summary = "";
    taskObj.assignTo = "";
    var $select = _$(elem_selectAssignTo).selectize();
    var selectize = $select[0].selectize;
    selectize.setValue(taskObj.assignTo);
    clearValidations()
  }
  function validate() {
    if (!taskObj.summary || !`${taskObj.summary}`.trim()) {
      errorObj.summary = "summary is required";
    } else {
      taskObj.summary = taskObj.summary.trim()
      if(taskObj.summary.length< 3){
        errorObj.summary = "summary should be at least 3 characters";
      }
    }
    if (taskObj.assignTo) {
      if (!taskObj.assignTo.match(/^[0-9a-fA-F]{24}$/)) {
        errorObj.assignTo = "selected assignTo user is invalid";
      }
    }

    return !Object.values(errorObj).length;
  }
  async function saveTask(addAnotherTask = false) {
    console.log("saveTask");
    if (isLoading) return;
    isLoading = addAnotherTask ? "add-another" : "save";
    await tick();
    let isValid = validate();
    if (isValid) {
      console.log(taskObj);
      let data = {
        summary: taskObj.summary,
        assignedTo: taskObj.assignTo,
      };
      let result = await api_handleApiCalls("taskManager.addNewTask", data);
      isLoading = null;
      await tick();
      if (result.status) {
        pSuccess("", "new task added");
        if (addAnotherTask) {
          clearFields();
          dispatch("close", {});
        } else if (result.errMsg) {
          pError("", result.errMsg);
        } else {
          _$(".modal-backdrop").remove();
          dispatch("close", { newTaskId: result.data });
        }
      } else if (result.errMsg) {
        pError("", result.errMsg);
      }
    }
    isLoading = null;
    await tick();
  }
  function closeModal() {
    _$(".modal-backdrop").remove();
    dispatch("close", { newTaskId: null, modalClosed: true });
  }

  function initAssignToSelection() {
    _$(elem_selectAssignTo).selectize({
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      options: [],
      onChange: async function (value) {
        if (!value) return false;
        taskObj.assignTo = value;
        console.log(taskObj);
      },
      preload: true,
      create: false,
      items: [],
      load: async function (query, callback) {
        console.log("load", query);
        let result = await api_handleApiCalls("systemUser.fetchSystemUsersToAssign", {
          search: { value: query },
        });
        if (result.status) {
          callback(result.data.data);
        } else {
          console.log(result.errMsg);
          callback([]);
        }
      },
    });
  }
  onMount(function () {
    _$(elm_modalCmp).modal("show");
    initAssignToSelection();
    feather.replace({ widht: "1em", height: "1em" });
    _$(elm_modalCmp).on("hide.bs.modal", closeModal);
    _$(elm_modalCmp).on("show.bs.modal", clearFields);
  });
</script>

<div />

<!-- Modal -->
<div class="modal effect-scale" bind:this={elm_modalCmp} tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mx-wd-sm-650" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header pd-b-0">
        <div class="media align-items-center pd-12">
          <span class="tx-color-04"><i
            data-feather="layers"
            class="wd-60 ht-60" /></span>
            <div class="media-body mg-sm-l-20">
              <h4 class="tx-18 tx-sm-20 mg-b-2">Task</h4>
              <p class="tx-13 tx-color-03 mg-b-0">Add new task</p>

            </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span><br />
        </button>
      </div>
      <div class="modal-body">
        <div class="row no-gutters pd-12">
          <div class="col-12 bg-white rounded-right">
              <div class="tx-color-02">
                <div class="form-group">
                  <label class="d-block">Summary
                    <span class="required-field">*</span></label>
                  <input
                    bind:this={elem_firstInput}
                    bind:value={taskObj.summary}
                    on:blur={() => clearError('summary')}
                    on:keyup={(e) => e.key === 'Enter' && saveTask()}
                    type="text"
                    class="form-control"
                    id="course-name"
                    class:parsley-error={errorObj.summary}
                    placeholder="Enter name" />
                  {#if errorObj.summary}
                    <div class="parsley-errors-list">{errorObj.summary}</div>
                  {/if}
                </div>
                <div class="form-group">
                  <label class="d-block">Assign To</label>
                  <select
                    bind:this={elem_selectAssignTo}
                    on:blur={() => clearError('assignTo')}
                    class="form-control col-xs-12 col-sm-12
                    col-md-12 col-lg-12 col-xl-12"
                    placeholder="Please select user"
                    class:parsley-error={errorObj.assignTo}>
                    <option value="" />
                  </select>
                  {#if errorObj.assignTo}
                    <div class="parsley-errors-list">{errorObj.assignTo}</div>
                  {/if}
                </div>
              </div>
              
          </div>
        </div>
      </div>

      <div class="modal-footer pd-t-0">
        <div class="col-12 d-flex justify-content-end pd-12">
          <button
            type="button"
            class="btn btn-secondary tx-13 btn-sm pd-x-15 mg-r-8"
            data-dismiss="modal">Cancel <i data-feather="x" class="wd-10 mg-l-5"></i></button>
          <button
            type="button"
            class="btn btn-primary tx-13 btn-sm pd-x-15 mg-r-8"
            on:click={() => saveTask()}
            disabled={isLoading}>
            <ButtonLoader
              isLoading={isLoading == 'save'}
              btnText="Save" />
              <i data-feather="save" class="wd-10 mg-l-5"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary tx-13 btn-sm pd-x-15"
            on:click={() => saveTask(true)}
            disabled={isLoading}>
            <ButtonLoader
              isLoading={isLoading == 'add-another'}
              btnText="Save & Add New" />
              <i data-feather="save" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>



    </div>
  </div>
</div>
