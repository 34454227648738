<script>
  import { links, link } from "svelte-routing";
  import { onMount, tick, afterUpdate } from "svelte";
  import { activeMenu } from "../../stores";
  import Empty from "../empty/empty.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import MODULES from "../../utils/modules.js"
  let isLoading = false;
  let isLoadingList = false;
  let addNewBranchModal;
  let displayText = "Branch List";
  const branchObj = {
    name: "",
    location: "",
    area: "",
    city: "",
    state: "",
    pincode: ""
  };
  let errorObj = {
    name: "",
    location: "",
    address: {
      area: "",
      city: "",
      state: "",
      pincode: ""
    }
  };
  let branchList = { data: [] };
  let search = { value: "" };
  const pageSize = 100;
  let address = {
    area: '',
    city: '',
    state: '',
    pincode: ''
  };

  const dummyData = [
    { _id: 0, name: "name", createdOn: new Date() },
    { _id: 1, name: "name1", createdOn: new Date() },
  ];

  async function api_delete(data) {
    let action = "instituteBranch.removeInstituteBranch";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_list(data) {
    let action = "instituteBranch.fetchInstituteBranchList";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getbyid(data) {
    let action = "instituteBranch.fetchInstituteBranch";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_save(data) {

    let action = "instituteBranch.saveInstituteBranch";
    var response = await api_handleApiCalls(action, data);
    return response;
  }


  function validate() {
    clearValidations();


    var regexpStartingWithAlphabet = new RegExp(`^[A-Za-z]`);

    let hasErrors = false;

    // Name validation
    if (!Boolean(branchObj.name.trim())) {
      errorObj.name = "Name cannot be empty";
      hasErrors = true;
    } else if (!branchObj.name.match(regexpStartingWithAlphabet)) {
      errorObj.name = "Name must begin with A-Z or a-z characters";
      hasErrors = true;
    } else if (branchObj.name.length < 3 || branchObj.name.length > 80) {
      errorObj.name = "Name should be between 3 and 80 characters long";
      hasErrors = true;
    }

    // Location validation
    if (Boolean(branchObj.location.trim())) {
      if (!branchObj.location.match(regexpStartingWithAlphabet)) {
        errorObj.location = "Location must begin with A-Z or a-z characters";
        hasErrors = true;
      } else if (branchObj.location.length < 3 || branchObj.location.length > 80) {
        errorObj.location = "Location should be between 3 and 80 characters long";
        hasErrors = true;
      }
    }

    // Address validations
    if (address.area && address.area.trim().length === 0) {
      errorObj.address.area = "Area cannot be empty";
      hasErrors = true;
    } else if (address.area && !address.area.match(regexpStartingWithAlphabet)) {
      errorObj.address.area = "Area must begin with A-Z or a-z characters";
      hasErrors = true;
    } else if (address.area && (address.area.length < 3 || address.area.length > 100)) {
      errorObj.address.area = "Area should be between 3 and 100 characters long";
      hasErrors = true;
    }

    if (address.city && address.city.trim().length === 0) {
      errorObj.address.city = "City cannot be empty";
      hasErrors = true;
    } else if (address.city && !address.city.match(regexpStartingWithAlphabet)) {
      errorObj.address.city = "City must begin with A-Z or a-z characters";
      hasErrors = true;
    } else if (address.city && (address.city.length < 3 || address.city.length > 100)) {
      errorObj.address.city = "City should be between 3 and 100 characters long";
      hasErrors = true;
    }

    if (address.state && address.state.trim().length === 0) {
      errorObj.address.state = "State cannot be empty";
      hasErrors = true;
    } else if (address.state && !address.state.match(regexpStartingWithAlphabet)) {
      errorObj.address.state = "State must begin with A-Z or a-z characters";
      hasErrors = true;
    } else if (address.state && (address.state.length < 3 || address.state.length > 100)) {
      errorObj.address.state = "State should be between 3 and 100 characters long";
      hasErrors = true;
    }

    if (address.pincode && address.pincode.trim().length === 0) {
      errorObj.address.pincode = "Pincode cannot be empty";
    } else if (address.pincode && isNaN(address.pincode) || address.pincode.length !== 6) {
      errorObj.address.pincode = "Pincode must be a 6-digit number";
    }



    return !hasErrors;
  }

  async function submitForm(event) {
    event.preventDefault();
    if (!isLoading) {
      isLoading = true;
      await tick();
      const isValid = validate();

      if (isValid) {

        // save branch api call
        let data = {
          name: branchObj.name,
          location: branchObj.location,
          address: {
            area: branchObj.area,
            city: branchObj.city,
            state: branchObj.state,
            pincode: branchObj.pincode
          }
        };
        if (branchObj._id) data._id = branchObj._id;
        let result = await api_save(data);

        if (result.status) {
          pSuccess(null, result.data);
          reload();
          hideModal();
        } else if (result.errMsg) {
          pError(null, result.errMsg);
        } else if (result.error) {
          pError(null, result.error);
        } else {
          pError(null, "Something went wrong");
        }
      }
      isLoading = false;
      await tick();
    }
  }

  function clearFields() {
    // form fields
    if (branchObj._id) delete branchObj._id;
    branchObj.name = "";
    branchObj.location = "";
    clearValidations();
  }

  async function showModal() {
    _$(addNewBranchModal).modal("show");
    _$(".first-input").focus();
    clearFields();

    // Set the values of address fields if editing
    if (branchObj._id) {

      branchObj.area = branchObj.address?.area || '';
      branchObj.city = branchObj.address?.city || '';
      branchObj.state = branchObj.address?.state || '';
      branchObj.pincode = branchObj.address?.pincode || '';
    }

    await tick();
  }

  async function hideModal() {
    _$(addNewBranchModal).modal("hide");
    clearFields();
  }

  async function clearValidations() {
    errorObj = {
      name: "",
      location: "",
      address: {
        area: "",
        city: "",
        state: "",
        pincode: ""
      }
    };
    await tick();
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    // if (sortOrder.column === 1) {
    //   sort.name = order;
    // } else if (sortOrder.column === 2) {
    //   sort.createdOn = order;
    // } else {
    //   sort.seqId = order;
    // }
    sort.seqId = order;
    return sort;
  }

  async function fetchBranchList() {
    let data = {
      start: 0,
      length: pageSize,
      search,
      sort: parseSorts({ 0: "dsc" }),
    };

    // fetch call
    isLoadingList = true;
    await tick();
    let response = await api_list(data);

    if (response.status) {
      var paginationData = response.data;
      branchList = {
        recordsTotal: Number(paginationData.recordsTotal),
        recordsFiltered: Number(paginationData.recordsFiltered),
        data: paginationData.data,
      };
    } else {
      console.error("something went wrong");
      branchList = {
        recordsTotal: 0,
        recordsFiltered: 0,
        data: [],
      };
    }
    isLoadingList = false;
    await tick();
  }

  async function editBranch(_id) {
    await showModal();
    let response = await api_getbyid({ _id });
    branchObj.name = response.data.name;
    branchObj.location = response.data.location;
    branchObj._id = response.data._id;

    // Ensure address fields are set correctly
    if (response.data.address) {
      branchObj.area = response.data.address.area || '';
      branchObj.city = response.data.address.city || '';
      branchObj.state = response.data.address.state || '';
      branchObj.pincode = response.data.address.pincode || '';
    } else {
      branchObj.area = '';
      branchObj.city = '';
      branchObj.state = '';
      branchObj.pincode = '';
    }

  }

  async function deleteBranch(branchId) {
    pConfirm(
      "Delete Branch?",
      "If this branch is assigned to any batch, it will not be deleted.",
      async function () {
        console.info("Deleting");
        //   delete api
        let result = await api_delete({ _id: branchId });
        if (result.status) {
          pSuccess(null, result.data);
          await fetchBranchList();
        } else if (result.errMsg) {
          pError("", result.errMsg);
        } else {
          pError(null, "Something went wrong");
        }
      }
    );
  }

  async function reload() {
    await fetchBranchList();
    feather.replace({ width: "1em", height: "1em" });
  }

  function bindModalAnimations() {
    // new branch modal
    _$(addNewBranchModal).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
    });

    _$(addNewBranchModal).on("hidden.bs.modal", function (e) {
      _$(this).removeClass("effect-scale");
    });
  }

  async function init() {

    activeMenu.set("branch");
    bindModalAnimations();
    await reload();
  }

  onMount(init);
  afterUpdate(function () {
    feather.replace({ width: "1em", height: "1em" });
  });
</script>

<style>
  .long-name-wrap {
    word-break: keep-all;
    overflow-wrap: break-word;
    min-width: 90%;
    max-width: 90%;
  }

  .branch-cards-wrap {
    flex-wrap: wrap;
  }

  .card * {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
  }

  .card .hidden-dropdown {
    display: none;
  }

  .card:hover .hidden-dropdown,
  .card:focus .hidden-dropdown {
    display: inherit;
  }

  .more-btn {
    float: right;
    clear: both;
    width: 1em;
    height: 1em;
  }
</style>

<div class="content-body fadeIn">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20
      mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {displayText}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">{displayText}</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Add new branch.</p>
    </div>
    <div class="d-inline-block">
      <nav use:links class="d-inline-block">
        <div class="d-inline-block">
          <div class="search-form">
            <input type="search" class="form-control" placeholder="Search branch here" bind:value={search.value}
              on:input={reload} />
            <button class="btn" type="button" on:click={reload}><i data-feather="search" /></button>
          </div>
        </div>
        <div class="d-inline-block">
          {#if MODULES.hasAccess(MODULES.ADD_BRANCH)}
          <button on:click={showModal} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
              data-feather="plus" class="wd-10 mg-r-5" />
            Add new Branch
          </button>
          {/if}
        </div>
      </nav>
    </div>
  </div>
  <hr class="full-width">
  <div class="animated scale">
    {#if isLoadingList}
    <div class="row row-xs mg-b-20">
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="placeholder-media">
          <div class="line" />
        </div>
      </div>
    </div>
    {:else if branchList && branchList.data && branchList.data.length}
    <!-- <div class="d-sm-flex branch-cards-wrap mg-b-10">
        {#each branchList.data as branch (branch._id)}
          <div class="card wd-sm-50p wd-md-40p wd-lg-30p mg-10">
            <div class="card-body row">
              <h6 class="long-name-wrap col-sm-10 col-md-10 col-lg-10">
                {branch.name}
              </h6>
              <div
                class="col-sm-1 col-md-1 col-lg-1 hidden-dropdown more-btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <span class="pointer"> <i data-feather="more-vertical" /></span>
                <nav class="dropdown-menu tx-13" use:links>
                  <a href="branch/{branch._id}" class="dropdown-item"><i
                      data-feather="edit-2" />
                    Edit</a>
                  <button
                    class="dropdown-item"
                    on:click={() => deleteBranch(branch._id)}
                    data-id={branch._id}><i data-feather="trash" />
                    Remove
                  </button>
                </nav>
              </div>
              {#if branch.location}
                <small
                  class="card-text long-name-wrap col-sm-10 col-md-10
                     col-lg-10"><i
                    data-feather="map-pin" />
                  {branch.location}</small>
              {/if}
            </div>
          </div>
        {/each}
      </div> -->
    <div class="row row-xs mg-b-20">
      {#each branchList.data as branch (branch._id)}
      <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
        <div class="media media-folder">
          <div class="avatar avatar-sm">
            <div class="avatar-initial rounded-circle bg-white bd bd-1 bd-primary tx-primary tx-capitalize">
              {branch.name[0]}
            </div>
          </div>
          <div class="media-body">
            <h6>
              <a href="javascript:void(0)" class="link-02 tx-capitalize">{branch.name}</a>
            </h6>
            <span>
              <i data-feather="map-pin" />
              {branch.location ? branch.location : '-NA-'}</span>
          </div>
          {#if MODULES.hasAccess(MODULES.EDIT_BRANCH) || MODULES.hasAccess(MODULES.DELETE_BRANCH)}
          <div class="dropdown-file">
            <a href="" class="dropdown-link" data-toggle="dropdown">
              <i data-feather="more-vertical" /></a>
            <nav class="dropdown-menu dropdown-menu-right" use:links>
              {#if MODULES.hasAccess(MODULES.EDIT_BRANCH)}
              <a href="javascript:void(0)" class="dropdown-item details" on:click={()=> editBranch(branch._id)}><i
                  data-feather="edit" />Edit</a>
              {/if}
              {#if MODULES.hasAccess(MODULES.DELETE_BRANCH)}
              <a href="javascript:void(0)" on:click={()=> deleteBranch(branch._id)}
                class="dropdown-item details"><i data-feather="trash" />Delete</a>
              {/if}
            </nav>
          </div>
          {/if}
        </div>
      </div>
      {/each}
    </div>
    {:else}
    <Empty mode={search.value ? 'search' : 'empty' } />
    {/if}
  </div>
</div>

<!-- Modal -->
<div class="modal" id="modal-new-branch" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel6"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header">
        <h6 class="modal-title" id="exampleModalLabel6">Add New Branch</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label class="d-block" for="branch-name">Name
              <span class="required-field">*</span></label>
            <input bind:value={branchObj.name} on:keyup={(e)=> e.key === 'Enter' && submitForm(e)}
            type="text"
            class="form-control first-input"
            id="branch-name"
            class:parsley-error={errorObj.name}
            placeholder="Enter name" />
            {#if errorObj.name}
            <div class="parsley-errors-list">{errorObj.name}</div>
            {/if}
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label class="d-block" for="branch-location">Location</label>
            <input bind:value={branchObj.location} on:keyup={(e)=> e.key === 'Enter' && submitForm(e)}
            type="text"
            class="form-control"
            id="branch-location"
            class:parsley-error={errorObj.location}
            placeholder="Enter location" />
            {#if errorObj.location}
            <div class="parsley-errors-list">{errorObj.location}</div>
            {/if}
          </div>
        </div>
        {#if errorObj.apiError}
        <div class="col-md-12">
          <div class="parsley-errors-list">{errorObj.apiError}</div>
        </div>
        {/if}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Cancel</button>
        <button type="button" disabled={isLoading} on:click={submitForm}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
          <ButtonLoader {isLoading} btnText="Proceed" />
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->

<!-- Modal -->
<div class="modal effect-scale" bind:this={addNewBranchModal} id="modal-new-batch" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mx-wd-sm-650" role="document">
    <div class="modal-content">
      <div class="modal-header pd-b-0">
        <div class="media align-text-center pd-12">
          <span class="tx-color-04"><i data-feather="share-2" class="wd-60 ht-60" /></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">{branchObj._id ? 'Edit Branch Details' : 'Branch'}</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Create new branch</p>
          </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span><br />
        </button>
      </div>
      <div class="modal-body">

        <div class="row no-gutters pd-12">
          <div class="col-12 bg-white rounded-right">
            <div class=" tx-color-02">
              <div class="form-group">
                <label class="d-block">Name
                  <span class="required-field">*</span></label>
                <input bind:value={branchObj.name} on:keyup={(e)=> e.key === 'Enter' && submitForm(e)}
                type="text"
                class="form-control first-input"
                id="course-name"
                class:parsley-error={errorObj.name}
                placeholder="Enter name" />
                {#if errorObj.name}
                <div class="parsley-errors-list">{errorObj.name}</div>
                {/if}
              </div>
              <div class="form-group">
                <label class="d-block" for="branch-location">Location</label>
                <input bind:value={branchObj.location} on:keyup={(e)=> e.key === 'Enter' && submitForm(e)}
                type="text"
                class="form-control"
                id="course-name"
                class:parsley-error={errorObj.location}
                placeholder="Enter location" />
                {#if errorObj.location}
                <div class="parsley-errors-list">{errorObj.location}</div>
                {/if}
              </div>
              <div class="form-group">
                <label class="d-block" for="address-area">Area</label>
                <input bind:value={branchObj.area} on:keyup={(e)=> e.key === 'Enter' && submitForm(e)}
                type="text"
                class="form-control"
                id="address-area"
                placeholder="Enter area" />
                {#if errorObj.address && errorObj.address.area}
                <div class="parsley-errors-list">{errorObj.address.area}</div>
                {/if}
              </div>

              <div class="form-group">
                <label class="d-block" for="address-city">City</label>
                <input bind:value={branchObj.city} on:keyup={(e)=> e.key === 'Enter' && submitForm(e)}
                type="text"
                class="form-control"
                id="address-city"
                placeholder="Enter city" />
                {#if errorObj.address && errorObj.address.city}
                <div class="parsley-errors-list">{errorObj.address.city}</div>
                {/if}
              </div>
              <div class="form-group">
                <label class="d-block" for="address-state">State</label>
                <input bind:value={branchObj.state} on:keyup={(e)=> e.key === 'Enter' && submitForm(e)}
                type="text"
                class="form-control"
                id="address-state"
                placeholder="Enter state" />
                {#if errorObj.address && errorObj.address.state}
                <div class="parsley-errors-list">{errorObj.address.state}</div>
                {/if}
              </div>

              <div class="form-group">
                <label class="d-block" for="address-pincode">Pincode</label>
                <input bind:value={branchObj.pincode} on:keyup={(e)=> e.key === 'Enter' && submitForm(e)}
                type="text"
                class="form-control"
                id="address-pincode"
                placeholder="Enter pincode" />
                {#if errorObj.address && errorObj.address.pincode}
                <div class="parsley-errors-list">{errorObj.address.pincode}</div>
                {/if}
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="modal-footer pd-t-0">
        <div class="col-12 d-flex justify-content-end pd-12">
          <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">
            Cancel <i data-feather="x" class="wd-10 mg-l-5"></i></button>
          <button type="button" disabled={isLoading} on:click={submitForm}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <ButtonLoader {isLoading} btnText="Proceed" />
            <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>



    </div>
  </div>
</div>
<!-- Modal -->
