<script>
  export let mode = "empty";
</script>

<div class="wd-100p tx-center align-items-center justify-content-center bd">
  <img src="/images/empty.png" style="margin-left: 230%;" class="img-fluid" alt="No Result" />
  {#if mode == 'search'}
    <p class="tx-bold">Batch not found.</p>
  {:else}
    <p class="tx-bold">
      Please create batch to access this feature.
    </p>
  {/if}

</div>
