import { writable } from "svelte/store";

export const _mobileNo = writable(0);
export const _verifyOtpToken = writable(0);
export const _otp = writable(0);
export const _registerOtpToken = writable(0);
export const _accessToken = writable(0);
export const _signToken = writable(0);
export const _completeExamData = writable({});
export const _userObj = writable({});
