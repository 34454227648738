<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick, afterUpdate } from 'svelte';
    import { activeMenu } from "../../../stores";
    import ButtonLoader from "../../../utils/button_loader.svelte";
    import api_handleApiCalls from "../../../utils/apiCallHandler.js";
    let imageList = { data: [] };
    let search = { value: "" };
    let isLoading = false;
    let elemType;
    let elem_addNewYouTubeLecturesModel;
    const pageSize = 10;
    let length = pageSize;
    function editBtnHander(id) {
      navigate(`/dashboard/studyMaterial/YouTubeLectures/edit/${id}`)
    }
    var errorObj = {
  
    }
  
    var modelTitle = "Add new Live Lecture"
  
    let validateImage = () => {
      errorObj = {};
      if (!YouTubeLecturesObj.name || !YouTubeLecturesObj.lecture_type) {
        var newErrorObj = {};
        if (!YouTubeLecturesObj.name) {
          newErrorObj.name = "Live Lecture Name is required Field.";
        }
  
        if (!YouTubeLecturesObj.lecture_type) {
          newErrorObj.lecture_type = "Live Lecture Type is required Field.";
        }
        errorObj = newErrorObj;
  
      }
      if (Object.keys(errorObj).length > 0) {
        isLoading = false;
        return false;
      }
      return true;
    }
  
    async function api_addImage(data) {
  
      let action = "studyMaterial.addUpdateYouTubeLectures";
      var response = await api_handleApiCalls(action, data);
      return response;
  
    }
    async function api_listImage(data) {
  
      let action ="studyMaterial.listYouTubeLectures";
      var response = await api_handleApiCalls(action, data);
      return response;
  
    }
  
    function parseSorts(sortOrder) {
      // parses datatable order to mongodb sort object
      let sort = {};
      // sorting orders
      let order;
      if (sortOrder.dir === "asc") order = 1;
      else order = -1;
      // if (sortOrder.column === 1) {
      //   sort.name = order;
      // } else if (sortOrder.column === 2) {
      //   sort.createdOn = order;
      // } else {
      //   sort.seqId = order;
      // }
      sort.seqId = order;
      return sort;
    }
  
    async function fetchImageList(offset = 0, event) {
      let data = {
        start: 0,
        length: length + offset >= pageSize ? length + offset : pageSize,
        search,
        sort: parseSorts({ 0: "dsc" }),
      };
      console.info(data);
      // fetch call
      let response = await api_listImage(data);
      console.info({ response });
      if (response.status) {
        var paginationData = response.data;
        length = paginationData.data.length;
        imageList = {
          filtered_count: Number(paginationData.filtered_count),
          total_count: Number(paginationData.total_count),
          data: paginationData.data,
        };
      } else {
        console.error("something went wrong");
        length = 0;
        imageList = {
          filtered_count: 0,
          total_count: 0,
          data: [],
        };
      }
      await tick();
    }
  
    async function bindDelete(recordedLectureId) {
      let isDelete = confirm(
        "Delete Live Lecture ? This will also delete associated data"
      );
      console.info("Deleting", { isDelete });
      if (isDelete) {
        //   delete api
        let result = await api_delete({ _id: recordedLectureId });
        if (result.status) {
          // reload table
          // OR
          await fetchImageList();
  
        } else if (result.errMsg) {
          updateErrors({ msg: result.errMsg });
        } else if (result.error) {
          updateErrors({ msg: result.error });
        } else {
          updateErrors({ msg: "Something went wrong" });
        }
      }
    }
  
    async function api_delete(data) {
  
      let action ="studyMaterial.deleteYouTubeLectures";
      var response = await api_handleApiCalls(action, data);
      return response;
  
    }
  
    function hideModel(params) {
      _$('#image-add-modal').modal("hide")
  
    }
    let addNewImage = async () => {
      if (isLoading) return;
      isLoading = true;
      YouTubeLecturesObj.lecture_type = _$(elemType).val()
      if (validateImage()) {
  
        var imageRes = await api_addImage(YouTubeLecturesObj);
        if (imageRes.status) {
          // create success
          updateSuccess({ title: "Success", msg: imageRes.data[0] });
          // setTimeout(() => {
          hideModel();
          setTimeout(() => {
            navigate(`/dashboard/studyMaterial/YouTubeLectures/edit/${imageRes.data[1]}`)
          }, 1000);
          // }, 1000);
        } else {
          // Failed to create 
          updateErrors({ title: "Error", msg: YouTubeLecturesObj.errMsg })
          isLoading = false;
          return false;
        }
      }
      isLoading = false;
    }
  
  
    var YouTubeLecturesObj = {
      name: "",
    }
    let clearError = (key) => {
      delete errorObj[key];
      errorObj = { ...errorObj }; // reset state
    }
    function updateSuccess(success) {
      PNotify.success({
        title: success.title ? success.title : "Success",
        text: success.msg,
        delay: 3000
      })
    }
  
    function bindEvents() {
      _$(elemType).select2({
        minimumResultsForSearch: Infinity,
        dropdownParent: _$(elem_addNewYouTubeLecturesModel)
  
      });
  
    }
    function updateErrors(error) {
      PNotify.error({
        title: error.title ? error.title : "Error",
        text: error.msg,
        delay: 3000
      })
    }
  
    onMount(async () => {
      initDashboard();
      bindEvents();
      // initFileManager();            
      activeMenu.set('studyMaterial')
      await fetchImageList();
  
  
    });
    afterUpdate(function () {
      feather.replace({ width: "1em", height: "1em" });
      _$('[data-toggle="tooltip"]').tooltip();
    });
  
  </script>
  
  
  
  <div class="content-body fadeIn">
    <!-- <div class="content bd-b"> -->
    <!-- <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0"> -->
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item"><a href="/dashboard/studyMaterial">Study Material</a></li>
            <li class="breadcrumb-item active" aria-current="page">Live Lecture</li>
          </ol>
        </nav>
        <h4 class="mg-b-0">Live Lecture List</h4>
      </div>
      <a href="#image-add-modal" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" data-toggle="modal">
        <i data-feather="plus" class="wd-10 mg-r-5"></i> Add Live Lecture
      </a>
  
      <!-- <div class="search-form mg-t-20 mg-sm-t-0">
                <input type="search" class="form-control" placeholder="Search people">
                <button class="btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
              </div> -->
    </div>
    <!-- </div> -->
    <!-- </div> -->
  
  
  
    <div>
  
      {#if imageList && imageList.data && imageList.data.length}
      {#each imageList.data as aImage (aImage._id)}
      <div class="card mg-10">
        <div class="card-body d-flex justify-content-left">
          <i class="d-flex justify-content-left far fa-file-video fa-2x tx-warning"></i>
          <div class="col" on:click={()=> editBtnHander(aImage._id)}>
            {aImage.name}
          </div>
          <div class="col-sm-1 col-md-1 col-lg-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="pointer"> <i data-feather="more-vertical" /></span>
            <nav class="dropdown-menu" use:links>
              <a href="/dashboard/studyMaterial/YouTubeLectures/edit/{aImage._id}" class="dropdown-item"><i
                  data-feather="edit-2" />
                {@html '&nbsp;'}Edit</a>
              <button class="dropdown-item" on:click={()=> bindDelete(aImage._id)}
                data-id={aImage._id}><i data-feather="trash" />
                {@html '&nbsp;'}Remove
              </button>
            </nav>
          </div>
        </div>
      </div>
  
      {:else}
      <span>No Live Lecture's Found. Please Add a Live Lecture</span>
      {/each}
      {/if}
    </div>
    <p>
      Showing {length}/ {imageList.filtered_count} from total {imageList.total_count}
      live lectures {#if imageList.filtered_count > length}
      <button class="btn btn-secondary d-inline-block mg-10" title="load more" on:click={async ()=> {
        console.log(`loading ${pageSize} more`);
        await fetchImageList(pageSize);
        }}>
        Load more <i data-feather="more-horizontal" />
      </button>
      {/if}
    </p>
  </div>
  
  
  <div class="modal fade" id="image-add-modal" bind:this={elem_addNewYouTubeLecturesModel} tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
          <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="media align-items-center">
            <span class="tx-color-03 d-none d-sm-block"><i data-feather="youtube" class="wd-60 ht-60"></i></span>
            <div class="media-body mg-sm-l-20">
              <h4 class="tx-18 tx-sm-20 mg-b-2">{modelTitle}</h4>
              <p class="tx-13 tx-color-03 mg-b-0">Add live lectures.</p>
            </div>
          </div><!-- media -->
        </div><!-- modal-header -->
        <hr class="full-width">
        <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
  
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="d-block">Live Lecture Name<span class="required-field">*</span></label>
              <input bind:value={YouTubeLecturesObj.name} type="text" on:blur={()=>clearError("name")} class="form-control"
              class:parsley-error={errorObj.name} placeholder="Live Lecture Name"/>
              {#if errorObj.name}
              <div class="parsley-errors-list">{errorObj.name}</div>
              <!-- content here -->
              {/if}
            </div>
            <div class="form-group col-md-12" on:click={()=>clearError("lecture_type")}
              class:parsley-errors-select-custom={errorObj.lecture_type}>
              <label class="d-block">Type <span class="required-field">*</span></label>
              <select bind:this={elemType} style="width: 100%;" class="custom-select moduleSub">
                <option value="" selected>Select Type</option>
                <option value="zoom">Zoom</option>
                <option value="youtube">YouTube</option>
                <option value="bluebird" disabled>Blue Bird</option>
              </select>
              {#if errorObj.lecture_type}
              <div class="parsley-errors-list">{errorObj.lecture_type}</div>
              <!-- content here -->
              {/if}
            </div>
  
  
  
  
          </div>
  
  
        </div>
        <div class="modal-footer pd-l-25 pd-r-25 pd-b-25 pd-t-0">
          <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">CLOSE
            <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button type="button" on:click={addNewImage} class="btn btn-primary tx-12" disabled={isLoading}>
            <ButtonLoader isLoading={isLoading} btnText="SAVE" />
            <i data-feather="save" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
