<script>
    import { Router, Link, Route } from "svelte-routing";
    import ListRoute from "./list/index.svelte";
    import EditReport from "./resolveReportedQuestion/edit.svelte";
    import AddUpdate from "../questions/addUpdate.svelte";
</script>

<Router>
    <Route path="">
        <ListRoute></ListRoute>
    </Route>
   
    <Route path="edit/:id" let:params>
        <EditReport reportId="{params.id}"></EditReport>

    </Route>

    <Route path="view/:id" let:params>
        <AddUpdate questionId="{params.id}"></AddUpdate>
    </Route>
    
</Router>



