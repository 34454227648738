<script>
    import { onMount } from "svelte";
    import moment from 'moment'


    export let expiryDate = null;
    let isLiveLectureCom = true;
    export let scheduledZoomMeeting={}
    export let onZoomBtnClick;
    export let checkOnTimeOut = function () {
        console.log("No onTimeOut event handled");
    }

    let now = new Date().getTime();
    onMount(() => {
        // console.log("expiryDate:::",expiryDate)

        // console.log("scheduledZoomMeeting:::",scheduledZoomMeeting)
        let expiryTime = new Date(expiryDate).getTime()

        let durationT = moment.duration(expiryTime - now, 'milliseconds');
        if( now > expiryTime){
            isLiveLectureCom = false;
            return;
        }

        var timer = setInterval(()=> {
            now = new Date().getTime();
            let duration = moment.duration( expiryTime - now, 'milliseconds');
            if ( now > expiryTime ) {
                clearInterval(timer);
                isLiveLectureCom = false;
            }else{
                console.log("checking expiry date");
            }

        }, 1000)
    })



    function calculateDays({ countDownDate }) {
        var now = new Date().getTime();
        const countdown = countDownDate - now;
        let days = Math.floor(countdown / (1000 * 60 * 60 * 24));
        let hours = Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((countdown % (1000 * 60)) / 1000);
        return {
            DD: days > 0 ? days : 0,
            HH: hours > 0 ? hours : 0,
            MM: minutes > 0 ? minutes : 0,
            SS: seconds > 0 ? seconds : 0
        }
    }

</script>


    <!-- JOIN NOW -->
  
    <!-- BUTTOMS -->
    
    {#if isLiveLectureCom}
    <button on:click|preventDefault={()=> onZoomBtnClick(scheduledZoomMeeting.zoomMeetId, scheduledZoomMeeting.zoomMeetPasscode,
        )} class="btn btn-sm btn-primary btn-uppercase mg-5"
        target="_blank">
        Join Now
    </button>

    {:else}
    <!-- Expire -->
    <button disabled class="btn btn-sm btn-secondary btn-uppercase mg-5">
        Meeting Ended
        <br/>
    </button>
    {/if}
  
   

