<script>
    import { Router, Link, Route } from "svelte-routing";
    import AddUpdate from "./addUpdate.svelte";
    import List from "./list.svelte";
    import MODULES from "../utils/modules.js" 
    import Forbidden from "../forbidden.svelte" 
</script>

<Router>
    <Route path="/new/:id" let:params >
        {#if MODULES.hasAccess(MODULES.EDIT_DOUBT_SESSION)}
        <AddUpdate _id ={params.id}/>
        {:else}
        <Forbidden/>
        {/if}
    </Route>
    <Route path="/new/">
        {#if MODULES.hasAccess(MODULES.ADD_DOUBT_SESSION)}
        <AddUpdate />
        {:else}
        <Forbidden/>
        {/if}
    </Route>
    <Route path="/" >
        <List />
    </Route>
</Router>
