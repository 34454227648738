<script>
  import { setContext, onMount, tick } from "svelte";
  import { links, link, navigate } from "svelte-routing";
  import { activeMenu } from "../../stores";
  import FeedbackComments from "../../comments/index.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import MODULES from "../../utils/modules.js";
  import { userData } from "../../stores";
  import CheckZoomMeeting from "./CheckZoomMeeting.svelte";
  import CountDownTimer from "../../utils/countdownTimer.svelte";

  export let id;
  let isLoading;
  let errorObj = {};
  let studentName;
  let attachementData;
  let assignId;
  let addNewReportModal;
  let doubtAssignemnt = {
    userID: "",
    status: "assigned",
  };
  let modelId = 0;
  let doubt_assignemnet_selectize;
  let loading = false;
  let addNewScheduleModal;
  let meetingTitle = "";
  let selectedStart;
  let selectedEnd;
  let selectedStudent = [];
  let selectedFaculty;
  let zoomMeetId = "";
  let zoomMeetPasscode = "";
  let zoomThumbnail = "";
  let enableLiveChat = false;
  let elemStudentSelectize;
  let meetingDuration;
  let elem_startTimePicker;
  let elem_endTimePicker;
  let elem_sysUserSelection;
  let selectizeBatchInstance;
  let startTimeInstance;
  let endTimeInstance;
  let scheduledZoomMeeting;
  let selectedFacultyName = "";
  var classStartTime = {
    HH: "",
    MM: "",
    AMPM: "",
  };
  let assignee =""
  let studentId;
  var classEndTime = {
    HH: "",
    MM: "",
    AMPM: "",
  };
  let assignedTo;
  let isEdit = false;
  let meetingDescription = "";
  let editDoc_id;
  let localUserData = {
    fullName: "...",
    role: "...",
    avatar: "/images/man.png",
    access: {
      home: false,
      students: false,
      settings: false,
    },
  };
  const unsubscribe = userData.subscribe((value) => {
    localUserData = value;
  });
  function generateClassDuration() {
    if (
      classStartTime.HH != null &&
      classStartTime.AMPM != null &&
      classStartTime.date != null &&
      classEndTime.HH != null &&
      classEndTime.AMPM != null &&
      classEndTime.date != null
    ) {
      var startDate = convertToDate(classStartTime);
      var endDate = convertToDate(classEndTime);

      if (endDate < startDate) {
        endDate.setDate(endDate.getDate() + 1);
      }

      var diff = endDate - startDate;
      var msec = diff;
      var hh = Math.floor(msec / 1000 / 60 / 60);
      msec -= hh * 1000 * 60 * 60;
      var mm = Math.floor(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      var ss = Math.floor(msec / 1000);
      msec -= ss * 1000;

      meetingDuration = `${hh} Hours ${mm} mins`;
    } else {
      meetingDuration = ``;
    }
  }

  let initSysUserDropdown = async function (selectedId) {
    await tick();
    let data = {
      // start: 0,
      // length: 100,
      sort: { name: 1 },
      roles: ["orgAdmin", "admin", "faculty", "Batch Manager","Admin","Faculty"],
      noPagination: true,
    };
    let result = await api_assingToUserList(data);

    let facultylist;
    if (result.status) {
      facultylist = result.data.data;
    }

    _$(elem_sysUserSelection).selectize({
      preload: true,
      create: false,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      items: [selectedId],
      options: facultylist,
      onBlur: function () {
        clearError("selectedFaculty");
      },
      onChange: async function (value, a, b) {
        if (!value) {
          return;
        } else {
          selectedFaculty = value;
          assignedTo = value;
          selectedFacultyName = facultylist.find((item) => {
            if (item._id == value) return item.name;
          });
          selectedFacultyName = selectedFacultyName.name;
          // console.log("selectedFacultyName:::", selectedFacultyName);
        }
      },
    });

    if (selectedId) {
      _$(elem_sysUserSelection)[0].selectize.setValue(selectedId);
    }
  };
  let category =""
  let assignedStatus = ""
  function clearZoomFields() {
    meetingTitle = "";
    selectedStart = "";

    selectedEnd = "";
    zoomMeetId = "";
    zoomMeetPasscode = "";
    zoomThumbnail = "";
    meetingDescription = "";
    selectedFaculty = "";
    selectedStudent = [];
    // finalSelectedBatchIds = []
    _$(elem_sysUserSelection).val("");
    bindStudentSelect([]);
    _$("#eventStartDate").val("");
    _$("#eventEndDate").val("");

    enableLiveChat = false;
    _$(".addNewScheduleModal").modal("hide");
  }
  function clearError(key) {
    switch (key) {
      case "title":
        delete errorObj.title;
        break;
      case "selectedStart":
        delete errorObj.selectedStart;
        break;
      case "selectedEnd":
        delete errorObj.selectedEnd;
        break;
      case "selectedStudent":
        delete errorObj.selectedStudent;
        break;
      case "selectedFaculty":
        delete errorObj.selectedFaculty;
        break;

      case "zoomMeetPasscode":
        delete errorObj.zoomMeetPasscode;
        break;
      case "zoomMeetThumbnail":
        delete errorObj.zoomMeetThumbnail;
        break;
      case "zoomMeetId":
        delete errorObj.zoomMeetId;
        break;

      default:
        errorObj = {};
    }
  }
  async function fetchDoubtByID() {
    let data = { id };
    let result = await api_handleApiCalls(
      "discussionForum.getDoubtByDoubtId",
      data
    );
    if (result.status) {
      await tick();
      //setting status
      studentName = result.data.studentName;
      doubtAssignemnt.status = result.data.status;
      assignId = result.data.assignedTo;
      attachementData = result.data.attachments?.url[0]?.url;
      scheduledZoomMeeting = result.data.zoomMeetingEvent;
      // console.log("scheduledZoomMeeting:::", scheduledZoomMeeting);
      category = result.data.folderPath
      assignee = result.data.assignee
      assignedStatus = result.data.status
      studentId = result.data.studentId;
      selectedStudent = studentId
      bindStudentSelect(studentId);
      if (result.data.assignedTo !== "") {
        doubtAssignemnt.assingeeName = result.data.assingeeName;
        assignedTo = result.data.assignedTo;
      }
      //initialize ckeditorvalue
      setValues({
        doubtText: result.data.doubtText,
      });
    } else if (result.errMsg) {
      pError("", result.errMsg);
    } else if (result.error) {
      pError("", result.error);
    } else {
      pError("", "Something went wrong");
    }
  }

  //modal field for inserting new doubt
  async function initCkeditor(editorInstance) {
    try {
      CKEDITOR.replace(editorInstance, {
        readyOnly: true,
        placeholder: "Enter your Doubt",
        on: {
          loaded: reload,
        },
      });
      await tick();
    } catch (error) {
      console.error(error);
    }
  }

  async function setValues(values) {
    CKEDITOR.instances["doubt_editor"].setData(values.doubtText);
    await tick();
  }

  //reset field value
  function clearFields() {
    setValues({
      status: "Unassinged",
      doubtText: "",
    });
  }

  //reload page
  async function reload() {
    feather.replace({ width: "1em", height: "1em" });

    if (id && !isLoading) {
      isLoading = true;
      await tick();
      await fetchDoubtByID();
      // fetchComments()
      isLoading = false;
      await tick();
    }
  }
  async function showZoomScheduleModal() {
    feather.replace({ width: "1em", height: "1em" });

    initSysUserDropdown(assignedTo);
    bindStudentSelect(studentId);
    bindStartTimepicker();
    bindEndTimepicker();
    if (isEdit) {
      setZoomMeetingDetails();
    }

    _$(".addNewScheduleModal").modal("show");
  }
  async function showZoomScheduleEditModal() {
    feather.replace({ width: "1em", height: "1em" });

    initSysUserDropdown(assignedTo);
    bindStudentSelect(studentId);
    bindStartTimepicker();
    bindEndTimepicker();

    setZoomMeetingDetails();

    _$(".addNewScheduleModal").modal("show");
  }

  function setZoomMeetingDetails() {
    meetingTitle = scheduledZoomMeeting.title;
    selectedStart = scheduledZoomMeeting.start;

    selectedEnd = scheduledZoomMeeting.end;
    zoomMeetId = scheduledZoomMeeting.zoomMeetId;
    zoomMeetPasscode = scheduledZoomMeeting.zoomMeetPasscode;
    zoomThumbnail = scheduledZoomMeeting.zoomThumbnail;
    meetingDescription = scheduledZoomMeeting.meetingDescription;
    selectedFaculty = scheduledZoomMeeting.selectedFaculty;
    selectedStudent = scheduledZoomMeeting.selectedStudent;
    // finalSelectedBatchIds = []
    _$(elem_sysUserSelection).val(scheduledZoomMeeting.facultyId);
    bindStudentSelect(scheduledZoomMeeting.studentIds);
    let startDate = moment(scheduledZoomMeeting.start);
    let endDate = moment(scheduledZoomMeeting.end);

    var today = startDate.format("YYYY-MM-DD");
    // var now = new Date(startDate);
    // var day = ("0" + now.getDate()).slice(-2);
    // var month = ("0" + (now.getMonth() + 1)).slice(-2);
    // var today = now.getFullYear() + "-" + (month) + "-" + (day);
    _$("#eventStartDate").val(today);

    var today = endDate.format("YYYY-MM-DD");
    // var now = new Date(endDate);
    // var day = ("0" + now.getDate()).slice(-2);
    // var month = ("0" + (now.getMonth() + 1)).slice(-2);
    // var today = now.getFullYear() + "-" + (month) + "-" + (day);
    _$("#eventEndDate").val(today);

    _$(elem_startTimePicker).timepicker("setTime", startDate.format("LT"));
    _$(elem_endTimePicker).timepicker("setTime", endDate.format("LT"));

    selectedStart = startDate;
    selectedEnd = endDate;
    enableLiveChat = scheduledZoomMeeting.enableLiveChat;
  }
  //Modal code
  async function showModal() {
    modelId = 0;
    feather.replace({ width: "1em", height: "1em" });
    doubtAssignemnt = {
      userID: "",
      status: "assigned",
    };
    _$(doubt_assignemnet_selectize)[0].selectize.setValue(assignId);
    _$(addNewReportModal).modal("show");
    // resetAssignmentObject();
    await tick();
  }

  async function resetAssignmentObject() {
    doubtAssignemnt = {
      userID: "",
      status: "assigned",
    };
    _$(doubt_assignemnet_selectize)[0].selectize.setValue(assignId);
  }

  async function assignDoubtToSystemUser() {
    if (loading) return;
    loading = true;
    //change status
    let apiPayload = {
      id: id,
      userId: doubtAssignemnt.assignedTo,
      facultyName: selectedFacultyName,
    };

    let action = "discussionForum.assignDoubts";
    var response = await api_handleApiCalls(action, apiPayload);
    if (response.errMsg) {
      PNotify.error({
        title: "Error",
        text: response.errMsg,
        delay: 3000,
      });
    } else {
      PNotify.success({
        title: "success",
        text: "Doubt assigned successfully",
        delay: 3000,
      });

      reload();
    }
    loading = false;
    resetAssignmentObject();
    _$(addNewReportModal).modal("hide");
  }

  async function markAsCompleted() {
    if (loading) return;
    loading = true;
    //change status
    let apiData = { status: "completed", id };

    let response = await api_handleApiCalls(
      "discussionForum.changeStatusOfDoubt",
      apiData
    );

    if (response.errMsg) {
      PNotify.error({
        title: "Error",
        text: response.errMsg,
        delay: 3000,
      });
    } else {
      PNotify.success({
        title: "success",
        text: "Successfully marked as assigned !",
        delay: 3000,
      });
    }
    loading = false;
    reload();
  }

  //find system user memory
  async function api_assingToUserList(data) {
    let action = "systemUser.fetchSystemUserList";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function initDropdown() {
    // var prevSetup = Selectize.prototype.setup;
    // Selectize.prototype.setup = function () {
    //   prevSetup.call(this);
    //   this.$control_input.prop("readonly", true);
    // };
    let data = {
      
      sort: { name: 1 },
      roles: ["orgAdmin", "admin", "faculty", "Batch Manager","Admin","Faculty"],

      noPagination:true
    };
    var assignToAPIResp = await api_assingToUserList(data);
    let assignToList = assignToAPIResp.data.data;
    _$(doubt_assignemnet_selectize).selectize({
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      options: assignToList,
      create: false,
      items: [],
      onChange: function (value) {
        doubtAssignemnt.assignedTo = value;

        selectedFaculty = value;
        assignedTo = value;
        selectedFacultyName = assignToList.find((item) => {
          if (item._id == value) return item.name;
        });
        selectedFacultyName = selectedFacultyName.name;
        // console.log("selectedFacultyName:::", selectedFacultyName);
      },
    });

    // Selectize.prototype.setup = prevSetup;
  }

  //start here
  onMount(async function () {
    feather.replace({ width: "1em", height: "1em" });

    // console.log("id: ", { id });
    activeMenu.set("discussionForum");
    initDropdown();
    initCkeditor("doubt_editor");
    clearFields();
    clearZoomFields();
    initSysUserDropdown(assignedTo);
    bindStudentSelect(studentId);
    bindStartTimepicker();
    bindEndTimepicker();
  });

  async function api_listSysUsers(data) {
    let action = "systemUser.getSystemUsersExcludingIds";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  function handleClickEndDate() {
    clearError("selectedEnd");
    bindEndTimepicker();
    selectedEnd = _$("#eventEndDate").val();
  }

  function handleClickStartDate() {
    clearError("selectedStart");
    bindStartTimepicker();
    selectedStart = _$("#eventStartDate").val();
  }
  async function api_getStudentList() {
    let action = "students.list";
    var response = await api_handleApiCalls(action, {
      isSortByName: true,
      inactiveStudents: true,
    });
    return response;
  }

  async function bindStudentSelect(selectedId) {
    /* No need to select student for now */
    return 
    var oldSelected = [];
    clearError("selectedEnd");

    if (_$(elemStudentSelectize)[0].selectize) {
      oldSelected =
        _$(elemStudentSelectize)[0].selectize.getValue() == ""
          ? []
          : _$(elemStudentSelectize)[0].selectize.getValue();
      _$(elemStudentSelectize)[0].selectize.destroy();
    }

    selectedId = selectedId ? selectedId : [];
    await tick();
    let studentData = await api_getStudentList();
    let studentList = studentData.data.data;

    var studentSelectizeInstance = _$(elemStudentSelectize).selectize({
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      plugins: ["remove_button"],
      maxItems: 100,
      items: [...selectedId],
      options: studentList,
      create: false,
      onChange: function (value) {
        clearError("selectedStudent");
        // console.log("value::;", value);
        if (value) {
          selectedStudent = value;
          // console.log("selectedStudent::;", selectedStudent);
        } else selectedStudent = [];
      },
    });
    if (selectedId) {
      if (Array.isArray(selectedId)) {
        for (var i = 0; i < selectedId.length; i++) {
          // selectedId[i]
          oldSelected.push(selectedId[i]);
        }
      } else {
        oldSelected.push(selectedId);
      }
      _$(elemStudentSelectize)[0].selectize.setValue(oldSelected, false);
    }
  }

  async function bindStartTimepicker() {
    if (_$(elem_startTimePicker).timepicker())
      _$(elem_startTimePicker).timepicker().destroy();

    startTimeInstance = _$(elem_startTimePicker).timepicker({
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      // template: "modal",
      defaultTime: "12:00 AM",
      change: async function (time) {
        let scheduleTime;
        if (time) {
          scheduleTime = new Date(time);
          classStartTime.HH = scheduleTime.getHours();
          classStartTime.MM = scheduleTime.getMinutes();
          classStartTime.AMPM = classStartTime.HH >= 12 ? "PM" : "AM";
          classStartTime.date = _$("#eventStartDate").val();
          selectedStart = convertToDate(classStartTime);
        } else {
          scheduleTime = null;
        }
        generateClassDuration();

        await tick();
      },
    });
  }
  async function bindEndTimepicker() {
    if (_$(elem_endTimePicker).timepicker())
      _$(elem_endTimePicker).timepicker().destroy();

    endTimeInstance = _$(elem_endTimePicker).timepicker({
      timeFormat: "h:mm p",
      interval: 15,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      // template: "modal",
      defaultTime: "12:00 AM",
      change: async function (time) {
        let scheduleTime;
        if (time) {
          scheduleTime = new Date(time);
          classEndTime.HH = scheduleTime.getHours();
          classEndTime.MM = scheduleTime.getMinutes();
          classEndTime.AMPM = classEndTime.HH >= 12 ? "PM" : "AM";
          classEndTime.date = _$("#eventEndDate").val();
          selectedEnd = convertToDate(classEndTime);
        } else {
          scheduleTime = null;
        }
        generateClassDuration();
        await tick();
      },
    });
  }

  function convertToDate(classTime) {
    var hours = Number(classTime.HH);
    var minutes = Number(classTime.MM);
    var AMPM = classTime.AMPM;
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var date = new Date(classTime.date);
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  }

  function validateEvent() {
    if (!meetingTitle) errorObj.title = "Title is required field.";
    if (!selectedStart)
      errorObj.selectedStart = "Start date is required field.";
    if (!selectedEnd) errorObj.selectedEnd = "End date is required field.";
    if (selectedEnd && selectedStart) {
      var e = new Date(moment(selectedEnd).format()).getTime();
      var s = new Date(moment(selectedStart).format()).getTime();

      if (!(e > s))
        errorObj.selectedEnd = "End Date should be greater than start date.";
    }

    // if (selectedStudent.length == 0)
    //   errorObj.selectedStudent = "Atleast one student needed to be selected.";
    if (!selectedFaculty)
      errorObj.selectedFaculty = "Faculty should be selected.";

    if (!zoomMeetPasscode)
      errorObj.zoomMeetPasscode = "Zoom meeting passcode must be entered.";
    if (!zoomMeetId) errorObj.zoomMeetId = "Zoom meeting ID must be entered.";

    if (Object.values(errorObj).length > 0) {
      isLoading = false;
      return false;
    } else {
      return true;
    }
  }

  async function createZoomMeeting() {
    if (isLoading) return;
    await tick();

    if (validateEvent()) {
      var zoomMeetEvent = {
        _id: editDoc_id,
        title: meetingTitle,
        start: new Date(moment(selectedStart).format()),
        end: new Date(moment(selectedEnd).format()),
        description: meetingDescription,
        facultyName: selectedFacultyName,
        studentIds: selectedStudent,
        facultyId: selectedFaculty,

        zoomMeetPasscode: zoomMeetPasscode,
        zoomMeetId: zoomMeetId,
        zoomThumbnail: zoomThumbnail,
      };

      zoomMeetEvent.enableLiveChat = enableLiveChat;

      // console.log("zooom meet event", zoomMeetEvent);
      let data = {
        id: id,
        userId: assignedTo,
        zoomMeetingEvent: zoomMeetEvent,
      };
      isLoading = true;

      var response = await api_handleApiCalls(
        "discussionForum.addUpdateZoomMeeting",
        data
      );

      if (response.status) {
        isLoading = false;
        clearZoomFields();
        reload();
        pSuccess("Success!", response.data.msg);
        _$(".addNewScheduleModal").modal("hide");
      } else {
        isLoading = false;
        pError("Error!", response.errMsg);
      }
    }
  }

  let enableYLiveChat = async (isChecked) => {
    var linkRes = await api_handleApiCalls("schedule.updateChat", {
      _id: editDoc_id,
      enableChat: isChecked,
    });
    if (linkRes.status) {
      pSuccess("Successfull!", "Live chat enabled Successfully!");
    } else {
      pError("Error!", "Something went wrong!");
    }
  };

  async function onZoomBtnClick(meetingId, meetingPasscode) {
    var meetingUrl = `https://us05web.zoom.us/wc/${meetingId}/start`; //https://us05web.zoom.us/wc/85674847228/start
    var meetingUrl2 = `https://us02web.zoom.us/j/${meetingId}?pwd=${meetingPasscode}`;
    window.open(meetingUrl2);
  }
  async function onClickDeleteZoomMeeting() {
    pConfirm(
      "Delete Zoom Meeting",
      "Are you sure you want to delete Zoom Meeting?",
      async function () {
        var response = await api_handleApiCalls(
          "discussionForum.deleteZoomMeeting",
          { id: id, zoomMeetingEvent: null }
        );
        if (response.status) {
          // isLoading = false
          clearZoomFields();
          reload();
          pSuccess("Success!", response.data.msg);
          // _$(".addNewScheduleModal").modal("hide");
        } else {
          isLoading = false;
          pError("Error!", response.errMsg);
        }
      }
    );
  }
</script>

<div class="content-body animated scale">
  <!-- breadcrumbs -->
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20
        mg-lg-b-25 mg-xl-b-30"
  >
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active">
            <a href="/dashboard/discussionForum">Doubt</a>
          </li>
          <li class="breadcrumb-item">View</li>
          <li class="breadcrumb-item active" aria-current="page">
            {studentName + "'s Doubts " ?? "Student's" + "'s Doubts "}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1 text-capitalize">
        {studentName + "'s Doubts " ?? "Student's" + "'s Doubts "}
      </h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
        View Doubt, and make/see comments on it
      </p>
    </div>
    {#if doubtAssignemnt.status != "completed"}
      <div class="d-block">
        <div class="d-sm-flex">
          {#if MODULES.hasAccess(MODULES.EDIT_DISCUSSION)}
            {#if scheduledZoomMeeting == null}
              <button
                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                on:click={showZoomScheduleModal}
              >
                <i data-feather="plus" class="wd-10 mg-r-5" />Create a Zoom
                Schedule
              </button>
              <!-- {:else}
        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={showZoomScheduleEditModal}>
          <i data-feather="edit" class="wd-10 mg-r-5"></i>Edit a Zoom Schedule
        </button> -->
            {/if}
            <button
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
              on:click={showModal}
            >
              <i data-feather="user" class="wd-10 mg-r-5" /> Assign Doubt
            </button>
            <button
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
              on:click={markAsCompleted}
            >
              <i data-feather="check-square" class="wd-10 mg-r-5" /> Mark as completed
            </button>
          {/if}
        </div>
      </div>
    {/if}
  </div>
  <hr class="full-width" />
  <div class="mg-b-20 ">
    <div class="row row-sm">
      <div class="col-md-7 col-sm-5">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
             Category
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-md-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <span class="tx-capitalize tx-color-03">{category ? category :""}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Doubt Details
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-md-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <textarea
                  type="text"
                  disabled={id}
                  name="doubt_editor"
                  class="form-control feedback"
                  class:parsley-error={errorObj.query}
                />
              </div>
            </div>
          </div>
        </div>

        {#if scheduledZoomMeeting != null}
          {#if localUserData.role == "orgAdmin" || localUserData.role == "Admin" || localUserData.role == "admin" || localUserData._id == scheduledZoomMeeting.facultyId}
            <div class="row">
              <div class="col-sm-12">
                <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
                  Zoom Meeting
                </h6>
                <hr style="margin-top: 5px;" />
                <div class="col-md-12">
                  <div class="card-columns">
                    <div class="card " style="width:500px">
                      <div class="card-header">
                        <img
                          src={scheduledZoomMeeting.zoomThumbnail
                            ? scheduledZoomMeeting.zoomThumbnail
                            : "/assets/zoom.svg"}
                          class="card-img-top ht-150"
                          alt="https://www.businessnewsdaily.com/images/i/000/017/338/original/zoom.jpg"
                        />
                      </div>
                      <div class="card-body">
                        <h5 class="card-title">
                          {scheduledZoomMeeting.title
                            ? scheduledZoomMeeting.title
                            : ""}
                        </h5>
                        <p class="card-text">
                          {scheduledZoomMeeting.description
                            ? scheduledZoomMeeting.description
                            : ""}
                        </p>
                        <p class="card-text">
                          Lecture Date/Time: {scheduledZoomMeeting.start
                            ? moment(
                                new Date(scheduledZoomMeeting.start)
                              ).format("DD/MM/YYYY, hh:mm A")
                            : ""}
                        </p>
                        <p>
                          Faculty Name: {scheduledZoomMeeting.facultyName
                            ? scheduledZoomMeeting.facultyName
                            : " - "}
                        </p>
                      </div>
                      <div class="card-footer tx-13">
                        <div class="d-flex flex-row">
                          <div class="flex-grow-2 row">
                            <div
                              data-toggle="tooltip"
                              data-placement="left"
                              title="Join Meeting"
                            >
                              {#if new Date(scheduledZoomMeeting.start) > new Date()}
                                <CountDownTimer
                                  startFrom={new Date(
                                    scheduledZoomMeeting.start
                                  ).getTime()}
                                  onTimeOut={() => {
                                    reload();
                                  }}
                                >
                                  <button
                                    slot="container"
                                    let:time
                                    let:timeStr
                                    disabled
                                    class="btn btn-sm btn-secondary btn-uppercase mg-5"
                                  >
                                    Starts in {timeStr}
                                    <br />
                                  </button>
                                </CountDownTimer>
                              {:else}
                                <CheckZoomMeeting
                                  {onZoomBtnClick}
                                  {scheduledZoomMeeting}
                                  expiryDate={new Date(
                                    scheduledZoomMeeting.end
                                  )}
                                  checkOnTimeOut={() => {
                                    reload();
                                  }}
                                />
                              {/if}

                              <button
                                href="#"
                                class=" btn btn-primary  mg-5"
                                on:click={showZoomScheduleEditModal}
                                >Edit</button
                              >
                              <button
                                href="#"
                                class=" btn btn-secondary mg-5"
                                on:click={onClickDeleteZoomMeeting}
                                >Delete</button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/if}
        {/if}
      </div>
      <div class="col-sm-5 col-ms-12">
        <div class="col-sm-12">
          <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
            Doubt Assigned To
          </h6>
          <hr style="margin-top: 5px;" />
        </div>
        <div class="col-md-12">
          <div class="form-row">
            <div class="form-group col-md-12">
              <span class="tx-capitalize ">{assignee ?assignee :""}</span>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
            Doubt Comments
          </h6>
          <hr style="margin-top: 5px;" />
        </div>
        <div class="col-sm-12 ht-250">
          <div class="pd-r-10 ht-100p">
            <!-- comments here -->
            <FeedbackComments {id} />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal code -->

<div
  class="modal effect-scale"
  bind:this={addNewReportModal}
  id="modal-new-batch"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered wd-sm-650" role="document">
    <div class="modal-content ">
      <div class="modal-body pd-0">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
          <a
            href="#"
            role="button"
            class="close pos-absolute t-15 r-15"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="media align-items-center">
            <span class="tx-color-03 d-none d-sm-block"
              ><i data-feather="trello" class="wd-60 ht-60" /></span
            >
            <div class="media-body mg-sm-l-20">
              {#if modelId == 0}
                <h4 class=" tx-18 tx-sm-20 mg-b-2">Assign Doubt</h4>
              {/if}
            </div>
          </div>
          <!-- media -->
        </div>
        <!-- modal-header -->
        <hr class="full-width" />

        <a
          href="#"
          role="button"
          class="close pos-absolute t-15 r-15 z-index-10"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </a>

        <div class="row no-gutters">
          <div class="col-12 bg-white rounded-right">
            <div
              class="ht-100p d-flex flex-column justify-content-center pd-l-25 pd-r-25 pd-b-25"
            >
              <div class=" tx-color-02">
                <div class="form-group">
                  <label class="d-block mg-t-20">Assign To</label>
                  <select
                    bind:this={doubt_assignemnet_selectize}
                    class="info slectizedd form-control"
                    on:keyup={(e) => e.key === "Enter" && submitForm(e)}
                    placeholder="select assignee"
                    disabled={modelId == 1}
                  />
                </div>
                <div class=" d-flex justify-content-end">
                  {#if modelId == 0}
                    <button
                      type="button"
                      class="btn btn-secondary tx-12"
                      data-dismiss="modal"
                    >
                      CANCEL
                      <i data-feather="x" class="wd-10 mg-l-5" />
                    </button>
                    <button
                      type="button"
                      on:click={assignDoubtToSystemUser}
                      disabled={isLoading}
                      class="btn btn-sm pd-x-15 tx-12 btn-primary btn-uppercase mg-l-5"
                    >
                      <ButtonLoader {isLoading} btnText="Save" />
                      <i data-feather="save" class="wd-10 mg-l-5" />
                    </button>
                  {:else if modelId == 1}
                    <button
                      type="button"
                      class="btn btn-secondary tx-12"
                      data-dismiss="modal"
                    >
                      CLOSE
                      <i data-feather="x" class="wd-10 mg-l-5" />
                    </button>
                  {:else}
                    <button
                      type="button"
                      class="btn btn-secondary tx-12"
                      data-dismiss="modal"
                    >
                      CANCEL
                      <i data-feather="x" class="wd-10 mg-l-5" />
                    </button>
                    <button
                      type="button"
                      on:click={() => console.log("save")}
                      class="btn btn-sm pd-x-15 tx-12 btn-primary btn-uppercase mg-l-5"
                    >
                      <ButtonLoader {isLoading} btnText="Save" />
                      <i data-feather="save" class="wd-10 mg-l-5" />
                    </button>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->

<div
  class="modal calendar-modal-create fade effect-scale addNewScheduleModal"
  style="overflow: auto;"
  id="modalCreateEvent"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body pd-20 pd-sm-30">
        <button
          type="button"
          class="close pos-absolute t-20 r-20 close-create-class"
          data-dismiss="modal"
          aria-label="Close"
          on:click={clearZoomFields}
        >
          <span aria-hidden="true"><i data-feather="x" /></span>
        </button>
        {#if scheduledZoomMeeting == null}
          <h5 class="tx-18 tx-sm-20 mg-b-20 mg-sm-b-30">Create Zoom Meeting</h5>
        {:else}
          <h5 class="tx-18 tx-sm-20 mg-b-20 mg-sm-b-30">Edit Zoom Meeting</h5>
        {/if}

        <form id="formCalendar" method="post" action="app-calendar.svelte">
          <div class="form-group">
            <label for="title" class="d-block"
              >Title <span class="required-field">*</span></label
            >
            <input
              type="text"
              id="title"
              class="form-control"
              bind:value={meetingTitle}
              on:click={() => clearError("title")}
              placeholder="Add title"
            />
            {#if errorObj.title}
              <div class="parsley-errors-list">{errorObj.title}</div>
            {/if}
          </div>
          <!-- form-group -->
          <!-- <div class="form-group d-flex align-items-center">
                      <div class="custom-control custom-radio">
                          <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input"
                              checked>
                          <label class="custom-control-label" for="customRadio1">Event</label>
                      </div>
                      <div class="custom-control custom-radio mg-l-20">
                          <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input"
                              checked>
                          <label class="custom-control-label" for="customRadio2">Reminder</label>
                      </div>
                  </div> -->
          <div class="form-group">
            <label
              class="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03"
              >Start Date <span class="required-field">*</span></label
            >
            <div class="row row-xs">
              <div class="col-7">
                <input
                  id="eventStartDate"
                  type="date"
                  value=""
                  class="form-control"
                  on:change={handleClickStartDate}
                  placeholder="Select date"
                />
                {#if errorObj.selectedStart}
                  <div class="parsley-errors-list">
                    {errorObj.selectedStart}
                  </div>
                {/if}
              </div>
              <!-- col-7 -->
              <div class="col-5">
                <input
                  bind:this={elem_startTimePicker}
                  on:click={() => clearError("selectedStart")}
                  class:parsley-error={errorObj.selectedStart}
                  onkeydown="return false"
                  on:keyup={(e) => e.key === "Enter" && validateClass()}
                  type="text"
                  class="form-control batch-time first-input"
                  placeholder="Enter time"
                />

                <!-- <select class="custom-select">
                                  <option selected>Select Time</option>
                              </select> -->
              </div>
              <!-- col-5 -->
            </div>
            <!-- row -->
          </div>
          <!-- form-group -->
          <div class="form-group">
            <label
              class="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03"
              >End Date <span class="required-field">*</span></label
            >
            <div class="row row-xs">
              <div class="col-7">
                <input
                  id="eventEndDate"
                  type="date"
                  value=""
                  class="form-control"
                  on:change={handleClickEndDate}
                  placeholder="Select date"
                />
                {#if errorObj.selectedEnd}
                  <div class="parsley-errors-list">{errorObj.selectedEnd}</div>
                {/if}
              </div>
              <!-- col-7 -->
              <div class="col-5">
                <input
                  bind:this={elem_endTimePicker}
                  on:click={() => clearError("selectedEnd")}
                  class:parsley-error={errorObj.selectedStart}
                  onkeydown="return false"
                  on:keyup={(e) => e.key === "Enter" && validateClass()}
                  type="text"
                  class="form-control batch-time first-input"
                  placeholder="Enter time"
                />

                <!-- <select class="custom-select">
                                  <option selected>Select Time</option>
                              </select> -->
              </div>
              <!-- col-5 -->
            </div>
            <!-- row -->
          </div>
          <!-- form-group -->

          <div class="form-row">
            <!-- <div class="form-group col-sm-12">
              <label class="d-block"
                >Student <span class="required-field">*</span></label
              >
              <select
                name="assignStudent_final_selected"
                bind:this={elemStudentSelectize}
                type="text"
                class="info form-control"
              >
                <option value="">Select Students</option>
              </select>
              {#if errorObj.selectedStudent}
                <div class="parsley-errors-list">
                  {errorObj.selectedStudent}
                </div>
              {/if}
            </div> -->
            <div class="form-group col-sm-12">
              <label class="d-block"
                >Faculty <span class="required-field">*</span></label
              >
              <select
                class="info slectizedd form-control"
                bind:this={elem_sysUserSelection}
                placeholder="Select Faculty"
              >
                <option value="">Select Faculty</option>
              </select>
              {#if errorObj.selectedFaculty}
                <div class="parsley-errors-list">
                  {errorObj.selectedFaculty}
                </div>
              {/if}
            </div>
          </div>

          <div class="form-row">
            <!-- <div class="form-group mg-l-10">
                          <label class="d-block tx-sans flex-grow-1">Type <span class="required-field">*</span></label>
                          <div class="d-md-flex">
                              <div class="custom-control custom-radio mg-r-15">
                                  <input type="radio" id="type-online" bind:group={sessionType} on:click={()=>clearError('sessionType')} class="custom-control-input" value='online'>
                                  <label class="custom-control-label" for="type-online">Online</label>
                              </div>
                              
                              <div class="custom-control custom-radio mg-r-15">
                                  <input type="radio" bind:group={sessionType} id="type-offline" on:click={onClickOffline} class="custom-control-input" value='offline'>
                                  <label class="custom-control-label" for="type-offline">Offline</label>
                              </div>
                          </div>
                          {#if errorObj.sessionType}
                          <div class="parsley-errors-list">{errorObj.sessionType}</div>
                          {/if}
                      </div> -->
            <!-- {#if sessionType == "online"}
                      <div class="form-group mg-l-100">
                          <label class="d-block tx-sans flex-grow-1">Platform <span class="required-field">*</span></label>
                          <div class="d-md-flex">
                              <div class="custom-control custom-radio mg-r-15">
                                  <input type="radio" id="type-youtube"  on:click={()=>clearError('sessionPlatform')} bind:group={sessionPlatform} class="custom-control-input" value='youtube'>
                                  <label class="custom-control-label" for="type-youtube">Youtube</label>
                              </div>
                              
                              <div class="custom-control custom-radio mg-r-15">
                                  <input type="radio" bind:group={sessionPlatform}  on:click={()=>clearError('sessionPlatform')} id="type-zoom" class="custom-control-input" value='zoom'>
                                  <label class="custom-control-label" for="type-zoom">Zoom</label>
                              </div>
                          </div>
                          {#if errorObj.sessionPlatform}
                          <div class="parsley-errors-list">{errorObj.sessionPlatform}</div>
                          {/if}
                      </div>
                      {/if}
                      -->
          </div>

          <!-- {#if sessionPlatform == "youtube" && sessionType == "online"}
                  <div class="form-group">
                      <label for="youtubeLink" class="d-block tx-sans flex-grow-1">Youtube Link <span class="required-field">*</span></label>
                      <input type="url" id="youtubeLink" bind:value={youtubeLink} class="form-control"on:click={()=>clearError('youtubeLink')} placeholder="Enter Youtube Link">
                      {#if errorObj.youtubeLink}
                          <div class="parsley-errors-list">{errorObj.youtubeLink}</div>
                      {/if}
                  </div>
                  {/if} -->

          <!-- {#if sessionPlatform == "zoom" && sessionType == "online"} -->
          <div class="form-group">
            <label for="meeting-id" class="d-block tx-sans flex-grow-1"
              >Zoom Meeting ID <span class="required-field">*</span></label
            >
            <input
              type="number"
              id="meeting-id"
              bind:value={zoomMeetId}
              class="form-control"
              on:click={() => clearError("zoomMeetId")}
              placeholder="Enter Zoom Meeting ID"
            />
            {#if errorObj.zoomMeetId}
              <div class="parsley-errors-list">{errorObj.zoomMeetId}</div>
            {/if}

            <label
              for="meeting-passcode"
              class="d-block tx-sans flex-grow-1 pd-t-15"
              >Zoom Meeting Passcode <span class="required-field">*</span
              ></label
            >
            <input
              type="text"
              id="meeting-passcode"
              class="form-control"
              bind:value={zoomMeetPasscode}
              on:click={() => clearError("zoomMeetPasscode")}
              placeholder="Enter Zoom Meeting Passcode"
            />
            {#if errorObj.zoomMeetPasscode}
              <div class="parsley-errors-list">{errorObj.zoomMeetPasscode}</div>
            {/if}

            <label
              for="meeting-passcode"
              class="d-block tx-sans flex-grow-1 pd-t-15"
              >Zoom Meeting Thumbnail
            </label>
            <input
              type="text"
              id="meeting-passcode"
              class="form-control"
              bind:value={zoomThumbnail}
              on:click={() => clearError("zoomThumbnail")}
              placeholder="Enter Zoom Meeting Thumbnail URL"
            />
            {#if errorObj.zoomThumbnail}
              <div class="parsley-errors-list">{errorObj.zoomThumbnail}</div>
            {/if}
          </div>
          <!-- {/if} -->
          <fieldset class="form-fieldset mg-t-20 d-none">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label
                  for="meeting-passcode"
                  class="d-block tx-sans flex-grow-1 pd-t-15">Description</label>

                <input
                  type="file"
                  class="my-pond"
                  data-instant-upload="false"
                  data-allow-image-preview="false"
                  name="filepond"
                />
              </div>
            </div>
          </fieldset>

          <div class="form-group">
            <textarea
              class="form-control"
              rows="2"
              placeholder="Write some description (optional)"
              bind:value={meetingDescription}
            />
          </div>

          <div class="form-row pd-l-0">
            <!-- {#if sessionType == "online"} -->
            <div class="d-flex col">
              <label class="d-block">Enable Chat</label>
              <div class="ml-4">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="allow-multiple-device-login"
                    bind:checked={enableLiveChat}
                    on:change={(event) =>
                      editDoc_id ? enableYLiveChat(event.target.checked) : null}
                  />
                  <label
                    class="custom-control-label"
                    for="allow-multiple-device-login"
                  >
                    {@html "&nbsp"}
                  </label>
                </div>
              </div>
            </div>
            <!-- {/if} -->
            <div class="d-flex">
              <span class="justify-content-end"
                >Meeting Duration : {meetingDuration}</span
              >
            </div>
          </div>

          <!-- {#if sessionType == "online"} -->
          <div class="d-flex col pd-b-0 pd-l-0 pd-t-10" />
          <!-- {/if} -->
        </form>
      </div>
      <div class="modal-footer">
        <!-- {#if isEdit} -->
        <!-- <button type="submit" class="btn btn-primary mg-r-5" disabled={isLoading} >
                  <i data-feather="save" class="mg-r-10"></i>
                  <ButtonLoader isLoading={isLoading} btnText="Update Class"/>
              </button> -->
        <!-- {:else} -->
        {#if scheduledZoomMeeting == null}
          <button
            type="submit"
            class="btn btn-primary mg-r-5"
            disabled={isLoading}
            on:click={createZoomMeeting}
          >
            <i data-feather="plus" class="mg-r-10" />
            <ButtonLoader {isLoading} btnText="Save" />
          </button>
        {:else}
          <button
            type="submit"
            class="btn btn-primary mg-r-5"
            disabled={isLoading}
            on:click={createZoomMeeting}
          >
            <i data-feather="edit" class="mg-r-10" />
            <ButtonLoader {isLoading} btnText="Save" />
          </button>
        {/if}
        <!-- {/if} -->
        <button
          type="submit"
          class="btn btn-secondary mg-r-5"
          disabled={isLoading}
          on:click={clearZoomFields}
        >
          <i data-feather="x" class="mg-r-10" />Discard
        </button>
        <!-- <a href="" class="btn btn-secondary"  on:click="{clearZoomFields}"> <i data-feather="x" class="mg-r-10"></i> Discard</a> -->
      </div>
    </div>
  </div>
</div>
