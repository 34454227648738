<script>
  import { setContext, onMount, tick, afterUpdate } from "svelte";
  import { links, link, navigate } from "svelte-routing";
  import { activeMenu } from "../../stores";
  import StudyReportComments from "../../comments/index.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import EmptyCategry from "../empty/empty.svelte";
  import FolderExplorer from "../../folderCmp/folderExplorer.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";

  export let id;
  let reportId;
  let isLoadingSave,
    isLoading,
    hasAssignedBatches = false;
  let noOfQuestionsSolved,
    notes,
    batchId,
    folders = [],
    displayFolderCaegories = "",
    batchList = [],
    elem_selectBatch,
    selectedFolderWithName = [];
  let errorObj = {};

  $: selectedFolderWithName && onChangeSelectedFolder();
  let onChangeSelectedFolder = async function () {
    if (id) return;
    folders = selectedFolderWithName;
  };
  async function selectBatchFunction(value) {
    batchId = value;
    await tick();
    if (value == 0) {
      hasAssignedBatches = false;
      selectedFolderWithName = [];
    }
  }
  async function fetchBatchList() {
    let batchRes = await api_handleApiCalls("batch.getStudentBatchList", { start: "0",noPagination:true });
    if (batchRes.status) {
      batchList = batchRes.data.map((item) => {
        return { _id: item.batchDetails._id, name: item.batchDetails.name };
      });
      hasAssignedBatches = Boolean(batchList.length);
      batchList.push({ _id: 0, name: "None" });
      _$(elem_selectBatch).selectize({
        maxItems: 1,
        valueField: "_id",
        labelField: "name",
        searchField: "name",
        options: batchList,
        create: false,
        items: [batchId],
        onChange: selectBatchFunction,
        sortField: "text",
      });
    }
  }

  function updateDisplayCategories() {
    displayFolderCaegories = folders.map((item) => item.name).join(" > ");
  }
  $: folders && updateDisplayCategories();
  async function fetchStudentStudyReport() {
    let data = { id };
    let result = await api_handleApiCalls("studyReport.fetchStudentStudyReport", data);
    if (result.status) {
      reportId = result.data.reportId;
      setValues(result.data);
    } else if (result.errMsg) {
      pError("", result.errMsg);
    } else if (result.error) {
      pError("", result.error);
    } else {
      pError("", "Something went wrong");
    }
  }

  async function submitStudyReport() {
    if (isLoadingSave) return;
    isLoadingSave = true;
    await tick();
    // validate
    let errorObj = {};
    let values = {};
    values.batchId = batchId;
    values.folders = folders;
    values.notes = notes;
    values.noOfQuestionsSolved = noOfQuestionsSolved;
    let isValid = !errorObj.query;
    if (isValid) {
      // api_handleApiCalls call
      let result = await api_handleApiCalls("studyReport.saveStudentStudyReport", values);
      if (result.status) {
        pSuccess("", "Study report submitted successfully");
        navigate("./");
      } else if (result.errMsg) {
        pError("", result.errMsg);
      } else if (result.error) {
        pError("", result.error);
      } else {
        pError("", "Something went wrong");
      }
    }
    isLoadingSave = false;
    await tick();
  }

  async function setValues(values) {
    batchId = values.batchId;
    selectedFolderWithName = values.folders;
    noOfQuestionsSolved = values.noOfQuestionsSolved;
    notes = values.notes;
    folders = values.folders;
    feather.replace({ width: "1em", height: "1em" });
    await tick();
  }
  // function clearFields() {
  //   setValues({
  //     severity: 2,
  //     type: "general",
  //     status: "new",
  //     query: "",
  //   });
  // }
  async function reload() {
    if (id && !isLoading) {
      isLoading = true;
      await tick();
      await fetchStudentStudyReport();
      fetchBatchList();
      // fetchComments()
      isLoading = false;
      await tick();
    }
  }

  onMount(async function () {
    activeMenu.set("studentstudyreport");
    if (id) reload();
    else await fetchBatchList();
  });
</script>

<style>
  .empty-state-img {
    text-align: center;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .contact-list:hover nav {
    display: block;
  }
</style>

<div class="content-body fadeIn">
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 ">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/reports/study">Reports</a>
          </li>
          {#if id}
            <li class="breadcrumb-item">View</li>
            <li
              class="breadcrumb-item active wrap-ellipsis"
              aria-current="page">
              {'#' + reportId || ''}
            </li>
          {:else}
            <li class="breadcrumb-item active" aria-current="page">New</li>
          {/if}
        </ol>
      </nav>
      {#if !id}
        <h4 class="mg-b-0 tx-spacing--1 text-capitalize">Submit New Report</h4>
        <p class="tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
          Add New Study Report
        </p>
      {:else}
        <h4 class="mg-b-0 tx-spacing--1 text-capitalize">
          Study Report ({'#' + reportId || ''})
        </h4>
        <p class="tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
          View Feedback, and make/see comments on it
        </p>
      {/if}
    </div>
    {#if !id}
      <div class="d-inline-block">
        <button
          type="button"
          on:click={submitStudyReport}
          disabled={isLoadingSave}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
            data-feather="save"
            class="wd-10 mg-r-5" />
          <ButtonLoader isLoading={isLoadingSave} btnText="Submit Report" />
        </button>
      </div>
    {/if}
  </div>
  <hr class="full-width" />
  <div class="mg-b-20 ">
    <div class="row row-sm">
      <div class="col-sm-12 col-md-7">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Report Category (Batch and Topics)
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block wd-100p">Select Batch</label>
                <select
                  bind:this={elem_selectBatch}
                  disabled={Boolean(id)}
                  class="form-control"
                  placeholder="Please select batch" />
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="d-block wd-100p">Category
                  <div class="d-inline required-field">*</div></label>
                {#if batchId}
                  <span
                    class="text-capitalize mg-b-10">{displayFolderCaegories}</span>
                  {#if !id}
                    <FolderExplorer
                      mode="compact"
                      bind:selectedFolderWithName
                      shouldNotNavigate={true}
                      viewOnlyMode={true}
                      _batchId={batchId} />
                  {/if}
                {:else if hasAssignedBatches}
                  <div class="empty-state-img">
                    <img
                      src="/images/noresult.png"
                      style="width: 25%;"
                      alt="empty" />
                    <p class="tx-bold">
                      Please select batch to select category
                    </p>
                  </div>
                {:else}
                  <div class="empty-state-img">
                    <img
                      src="/images/noresult.png"
                      style="width: 25%;"
                      alt="empty" />
                    <p class="tx-bold">Please enroll in a batch</p>
                  </div>
                {/if}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Report
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-sm-12">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label class="d-block wd-100p">No. of Questions Solved
                  <div class="d-inline required-field">*</div></label>
                <input
                  disabled={Boolean(id)}
                  type="number"
                  class="form-control"
                  minlength="0"
                  min="0"
                  bind:value={noOfQuestionsSolved}
                  oninput="validity.valid||(value='');" />
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label class="d-block wd-100p">Notes</label>
                <textarea
                  class="form-control wd-100p"
                  style="resize: none"
                  bind:value={notes}
                  disabled={Boolean(id)} />
              </div>
            </div>
          </div>
        </div>
        <div class="mg-t-10 d-flex flex-row-reverse">
          {#if !id}
            <button
              type="button"
              on:click={submitStudyReport}
              disabled={isLoadingSave}
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i
                data-feather="save"
                class="wd-10 mg-r-5" />
              <ButtonLoader isLoading={isLoadingSave} btnText="Submit Report" />
            </button>
          {/if}
        </div>
      </div>
      {#if id}
        <div class="col-sm-5 col-ms-12">
          <div class="col-sm-12">
            <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
              Report Comments
            </h6>
            <hr style="margin-top: 5px;" />
          </div>
          <div class="col-sm-12 ht-250">
            <div class="pd-t-20 pd-r-10 ht-100p">
              <!-- comments here -->
              <StudyReportComments {id} />
            </div>
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>
