<script>
    import { Router, Link, Route } from "svelte-routing";
    import ListFolder from "./list.svelte";
    import newFile from "./newFile.svelte";
        export let idList = [];

</script>

<Router>
    <Route path="file/:id" let:params>
        <NewFile idList={idList} fileId="{params.id}"></NewFile>
    </Route>
    <Route path="">
        <ListFolder idList={idList}></ListFolder>
    </Route>
</Router>
