<script>
  import { Router, Link, links, Route, navigate } from "svelte-routing";
  import { userData } from "../stores";
  import { onMount, createEventDispatcher, tick } from "svelte";
  import { activeMenu } from "../stores";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import ButtonLoader from "../utils/button_loader.svelte";
  import { pSuccess, pError } from "../utils/notify.svelte";
  import ExcelJS from "exceljs";
  const dispatch = createEventDispatcher();
  import ResultImportedExcelData from "./utils/xlsx.utils";
  import { API_URL, testURL } from "../../config.js";
  export let mode;
  export let testObj;
  export let updateParent;
  export let userId;

  let showModal = false;
  let srNo = 0;
  let search = "";
  let abcentStudentArr = [];
  let resForTestResultScreen = [];
  let elemSelectFilterBatch;
  let exportsLoading = false;
  let elemSelectFilterClass;
  let elemSelectBranch;
  let reportDataTable;
  let exportLoading = false;
  let myTestResultData = [];
  let testSections = [];

  testSections = testObj ? (testObj.sections ? testObj.sections : []) : [];

  let reportsTable;
  let abcentStudentTable;
  let showAbcentStudentTable;
  let allReportsListArr = [];
  let modalCmp;
  let emptyState = false;
  let testSubmittedOn, score, rank;
  let filterModalThis;
  let btnDisable = false;
  let isLoading = false;
  let localUserData;

  let durationForAbcent = testObj
    ? testObj.endDate
      ? new Date(testObj.endDate)
      : null
    : null;
  let todaysDate = new Date();

  let errorObj = {};

  const unsubscribe = userData.subscribe((value) => {
    localUserData = value;
  });

  function showHideFilterModal(action) {
    action
      ? _$(filterModalThis).modal("show")
      : _$(filterModalThis).modal("hide");
  }

  function clearFilter() {
    testSubmittedOn = null;
    score = null;
    rank = null;
    _$(elemSelectFilterBatch)[0].selectize.clear();
    _$(elemSelectBranch)[0].selectize.clear();
    _$(elemSelectFilterClass)[0].selectize.clear();
    redrawResultTable();
  }

  function applyFilter() {
    redrawResultTable();
  }

  function getFilterData() {
    return {
      testSubmittedOn,
      score,
      rank,
      batches: _$(elemSelectFilterBatch).val(),
      branchId: _$(elemSelectBranch).val(),
      classId: _$(elemSelectFilterClass).val(),
    };
  }

  async function api_getAbcentStudentsList() {
    try {
      const data = { testId: testObj._id };
      const resData = await api_handleApiCalls(
        "test.getAbcentStudentsList",
        data,
      );
      if (resData.status) {
        return resData.data;
      } else {
        throw new Error("Failed to fetch the absent students list");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle the error appropriately in your application
      return null;
    }
  }

  async function api_generateStartToken(testId) {
    let action = "test.generateStartToken";
    let data = { testId: `${testId}` };
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function startTest(testId) {
    try {
      // Fetch the start token and other data
      let res = await api_generateStartToken({ testId });

      // Set dimensions for the popup window
      let xMax = window.outerWidth - 20;
      let yMax = window.outerHeight - 70;

      if (res.data.doneTest) {
        let doneTestId = res.data.doneTest._id;
        let startToken = res.data.startToken || "";

        return startToken;
      } else {
        let startToken = res.data.startToken || "";
      }
    } catch (error) {
      console.error("Error starting test:", error);
    }
  }

  async function api_getUserResult(filterData) {
    var data = { testId: testObj._id };
    var resData = await api_handleApiCalls("test.getUserResult", {
      ...data,
      ...filterData,
    });
    console.log("====================================");
    console.log("resData", resData);
    console.log("====================================");
    if (resData.status) return resData.data;
    return null;
  }

  function redrawResultTable() {
    if (reportDataTable) {
      reportDataTable.draw();
    }
  }

  async function api_getById(data) {
    setIsLoading(true);
    let resData = await api_handleApiCalls("test.getUserResult", {
      testId: route.params.testId,
      userId: route.params.userId,
    });
    setIsLoading(false);
    if (resData.status) {
      setResultObj(resData.data[0]);
    } else {
      navigation.goBack();
    }
    console.log(" setResultObj", setResultObj);
  }

  function bindAbcentStudentDatatable() {
    if (showAbcentStudentTable) showAbcentStudentTable.destroy();
    showAbcentStudentTable = _$(abcentStudentTable).DataTable({
      responsive: false,
      pagination: true,
      sort: false,
      language: {
        processing: "",
        searchPlaceholder: "Search Student",
        sSearch: "",
        lengthMenu: "_MENU_ Students/page",
        infoFiltered: "",
      },
      ajax: function (data, callback, settings) {
        _$.ajax({
          url: API_URL,
          method: "post",
          data: {
            action: "test.getAbcentStudentsList",
            accessToken: localStorage.getItem("accessToken"),
            data: {
              testId: testObj._id,
              start: data.start,
              length: data.length,
              search: data.search.value,
            },
          },
          success: function (resData) {
            var result = [];
            if (resData.status) {
              var paginationData = resData.data;
              console.log("paginationData", paginationData.data);
              result = {
                recordsTotal: Number(resData.data.recordsTotal),
                recordsFiltered: Number(resData.data.recordsFiltered),
                data: paginationData.data,
              };

              if (!resData.data.length) {
                emptyState = true;
              } else {
                emptyState = false;
              }
            } else {
              console.log("Something went wrong");
              result = {
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              };
            }
            callback(result);
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true;
          },
        });
      },
      columns: [
        {
          data: "name",
          defaultContent: "-",
          render: (data, type, full) => {
            let image;
            // if image found display profilePic avatar
            image = `<img src="${
              full.profilePic ? full.profilePic : "/images/man.png"
            }" class="avatar rounded-circle mg-r-15" alt="${data[0].toUpperCase()}">`;
            return `
                        <div style = "cursor:pointer" class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 btn-edit-student" student-id="${data._id}">
                        ${image}
                        <div class="bg-transparent" style="flex: 1">
                            <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent btn-edit-student tx-capitalize" student-id="${data._id}">${
                              data || "-"
                            }</h6>
                            <span class="d-block tx-11 text-muted bg-transparent">
                                <i data-feather="phone"></i> 
                                ${full.mobileNo || "-"}
                            </span>
                        </div>
                        </div>`;
          },
        },
        {
          data: "batchName",
          render: function (data, type, full) {
            var htmlstr = "";
            if (!full.batches.length) {
              return "-";
            }
            for (let j = 0; j < full.batches.length; j++) {
              let assignedBatch = full.batches[j];

              htmlstr += `
                                <div class="badge badge-secondary tx-capitalize" style="font-size:12px;margin-right:4px;margin-bottom:4px; text-align: center;">${assignedBatch}</div>
                                `;
            }

            return htmlstr;
          },
        },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
      },
      processing: true,
      serverSide: true,
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "desc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort._id = order;
    } else if (sortOrder.column === 2) {
      sort.rank = order;
    } else if (sortOrder.column === 3) {
      sort._id = order;
    } else if (sortOrder.column === 4) {
      sort.endedOn = order;
    } else if (sortOrder.column === 5) {
      sort._id = order;
    } else {
      sort._id = order;
    }
    return sort;
  }

  function bindDatatable(testId, userId) {
    let testApiData = {};
    if (testId) testApiData.testId = testId;
    if (userId) testApiData.userId = userId;

    let columnsConfig = [];

    if (userId) {
      columnsConfig = [
        {
          data: "_id",
          render: function (data, type, full) {
            let isPracticeTest = full.testData[0].isPracticeTest;
            isPracticeTest =
              isPracticeTest && isPracticeTest.toLowerCase() == "yes"
                ? '<div class="text-muted text-capitalize"> practice test</div>'
                : "";
            return `
                        
                        <div class="d-flex flex-column">
                            <div class="tx-14 tx-bold"> ${full.testData[0].name}</div>
                            <div class=""> ${full.testData[0].duration} Min</div>
                            ${isPracticeTest}
                        </div>
                        `;
          },
        },
        {
          data: "rank",
          render: function (data, type, full) {
            var resultDuration = new Date(full.testData[0].resultDate);

            if (resultDuration > new Date()) return "-";
            else return full.rank ? full.rank : "-";
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            var resultDuration = new Date(full.testData[0].resultDate);
            if (resultDuration > new Date()) return "-";
            else return full.total.totalMarks + "/" + full.total.maximumMarks;
          },
        },
        {
          data: "endedOn",
          render: function (data, type, full) {
            let redableDate = new Date(data);
            return redableDate.toLocaleDateString("en-GB");
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            var now = moment(full.expiresAt);
            var then = moment(full.startedAt);
            var duration = moment
              .utc(
                moment(now, "DD/MM/YYYY HH:mm:ss").diff(
                  moment(then, "DD/MM/YYYY HH:mm:ss"),
                ),
              )
              .format("HH:mm:ss");
            return duration;
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            var resultDuration = new Date(full.testData[0].resultDate);
            if (resultDuration > new Date()) {
              return "-";
            } else {
              return `
                                <div class="d-flex flex-column">
                                    <div class=""><i class="tx-success" data-feather="check-circle"></i> ${full.total.totalCorrectAns} Correctly Answered</div>
                                    <div class=""><i class="tx-danger" data-feather="x-circle"></i> ${full.total.totalWrongAns} Wrong Answered</div>
                                </div>
                            `;
            }
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            return `
                                <button on:click={startTest()} class="btn btn-xs btn-primary mg-r-5 btn-review-test" test-id="${data}"><i class="wd-20 mg-r-5" data-feather="file-text"></i>Review Test</button>
                        `;
          },
        },
        // <a href="#" class="rename mg-r-5 btn-review-test" test-id="${data}" title="Edit"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>
      ];
    } else {
      columnsConfig = [
        {
          data: "userData",
          defaultContent: "-",
          render: (data, type, full) => {
            let image;
            // if image found display profilePic avatar
            image = `<img src="${
              data.profilePic ? data.profilePic : "/images/man.png"
            }" class="avatar rounded-circle mg-r-15" alt="${data.name[0].toUpperCase()}">`;
            return `
                        <div style = "cursor:pointer" class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 btn-edit-student" student-id="${data._id}">
                        ${image}
                        <div class="bg-transparent" style="flex: 1">
                            <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent btn-edit-student tx-capitalize" student-id="${data._id}">${
                              data.name || "-"
                            }</h6>
                            <span class="d-block tx-11 text-muted bg-transparent">
                                <i data-feather="phone"></i> 
                                ${data.mobileNo || "-"}
                            </span>
                        </div>
                        </div>`;
          },
        },
        {
          data: "rank",
          render: function (data, type, full) {
            return data;
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            return full.total.totalMarks + "/" + full.total.maximumMarks;
          },
        },
        {
          data: "endedOn",
          render: function (data, type, full) {
            let redableDate = new Date(data);
            return redableDate.toLocaleDateString();
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            var now = moment(full.expiresAt);
            var then = moment(full.startedAt);
            var duration = moment
              .utc(
                moment(now, "DD/MM/YYYY HH:mm:ss").diff(
                  moment(then, "DD/MM/YYYY HH:mm:ss"),
                ),
              )
              .format("HH:mm:ss");
            return duration;
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            return `
                            <div class="d-flex flex-column">
                                <div class=""><i class="tx-success" data-feather="check-circle"></i> ${full.total.totalCorrectAns} Correctly Answered</div>
                                <div class=""><i class="tx-danger" data-feather="x-circle"></i> ${full.total.totalWrongAns} Wrong Answered</div>
                            </div>
                        `;
          },
        },
      ];
    }

    reportDataTable = _$(reportsTable).DataTable({
      responsive: false,
      sort: false,
      language: {
        processing: "",
        searchPlaceholder: "Search Test Name",
        sSearch: "",
        lengthMenu: "_MENU_ My reports/page",
        zeroRecords: "No given tests result found",
        // "info": "Showing page _PAGE_ of _PAGES_",
      },
      ajax: function (data, callback, settings) {
        // data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        var res = { ...getFilterData() };
        testApiData.testSubmittedOn = res.testSubmittedOn;
        testApiData.score = res.score;
        testApiData.rank = res.rank;
        testApiData.start = data.start;
        testApiData.length = data.length;
        testApiData.search = data.search.value;
        testApiData.draw = data.draw;
        // testApiData.sort = data.sort;
        testApiData.batches = res.batches;
        testApiData.classId = res.classId;
        testApiData.branchId = res.branchId;

        _$.ajax({
          url: API_URL,
          method: "post",
          data: {
            action: "test.getUserResult",
            accessToken: localStorage.getItem("accessToken"),
            data: testApiData,
          },
          success: function (resData) {
            var result = [];
            if (resData.status) {
              var paginationData = resData.data;
              myTestResultData = paginationData.data;
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data,
              };

              if (mode == "testResult") {
                for (let i = 0; i < result.data.length; i++) {
                  result.data[i].testObj = testObj;
                  result.data[i].testObj.sections[1] = obj;
                }
              }

              if (!resData.data.length) {
                emptyState = true;
              }
            } else {
              console.log("Something went wrong");
              result = {
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              };
            }
            callback(result);
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true;
          },
        });
      },
      columns: columnsConfig,
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        _$(".btn-edit-student")
          .unbind("click")
          .click(async function (e) {
            e.preventDefault();
            var studentId = _$(this).attr("student-id");
            navigate(
              "/dashboard/tests/view/studentdetailedreport" +
                "/" +
                studentId +
                "/" +
                testId,
            );
          });

        _$(".btn-review-test")
          .unbind("click")
          .click(async function (e) {
            e.preventDefault();

            const resultId = _$(this).attr("test-id");

            let data = await api_generateStartToken(resultId);
            // const testURL = `${window.location.protocol}//${window.TEST_SUBDOMAIN}.${window.location.host.replace(`${window.APP_SUBDOMAIN}.`, "")}`;
            let startToken = data.data.startToken;

            const xMax = window.outerWidth - 20;
            const yMax = window.outerHeight - 70;

            const url = `${testURL}/result/${resultId}/${startToken}`;

            const examWindow = window.open(
              url,
              "_blank",
              `scrollbars=yes,width=${xMax},height=${yMax},top=0,left=0,resizable=yes`,
            );
          });
      },
      processing: true,
      serverSide: true,
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }

  async function bindSelectBatchFilter() {
    let batchesList = [];
    var resData = await api_handleApiCalls("batch.getMyBatches", {});
    if (resData.status) batchesList = resData.data;
    await tick();

    _$(elemSelectFilterBatch).selectize({
      preload: true,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "Filter by batch",
      plugins: ["remove_button"],
      options: batchesList,
      onChange: function (value) {
        if (mode == "testResult") handleCallFromTest();
        else applyFilter();
      },
    });
  }

  async function bindSelectClassFilter() {
    let classList = [];
    var resData = await api_handleApiCalls(
      "instituteClass.fetchInstituteClasses",
      { orgId: localUserData.orgId },
    );
    if (resData.status) classList = resData.data;
    await tick();

    _$(elemSelectFilterClass).selectize({
      preload: true,
      maxItems: null,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "Filter by class",
      plugins: ["remove_button"],
      options: classList,
      onChange: function (value) {
        if (mode == "testResult") handleCallFromTest();
        else applyFilter();
      },
    });
  }

  async function bindSelectBranchFilter() {
    let branchList = [];
    var resData = await api_handleApiCalls("batch.getBranchNameList", {});
    if (resData.status) branchList = resData.data;
    await tick();

    _$(elemSelectBranch).selectize({
      preload: true,
      maxItems: 1,
      valueField: "_id",
      labelField: "name",
      searchField: "name",
      placeholder: "Filter by branch",
      options: branchList,
      onChange: function (value) {
        if (mode == "testResult") handleCallFromTest();
        else applyFilter();
      },
    });
  }

  async function handleCallFromTest() {
    var res = { ...getFilterData() };
    var data = {};
    data.batches = res.batches;
    data.classId = res.classId;
    data.branchId = res.branchId;
    data.search = search;

    var testIds = [];
    var res = await api_getUserResult(data);
    console.log("res----", res);
    if (!res) {
      console.error("No results found");
      return;
    }

    for (let i = 0; i < res.length; i++) {
      if (!(res[i].sectionalData && Object.keys(res[i].sectionalData))) {
        res[i].sectionalData = {};
      }
      testIds.push(res[i].testObj._id);
    }
    if (testObj.sections && testObj.sections.length) {
      for (let i = 0; i < testObj.sections.length; i++) {
        var sectionqIdArr = testObj.sections[i].questions;
        var sectionTitle = testObj.sections[i].title;
        for (let j = 0; j < res.length; j++) {
          var sectionalAttempt = 0;
          var sectionalCorrect = 0;
          var sectionalWrong = 0;
          var sectionalScore = 0;
          if (!(res[j].sectionalData && Object.keys(res[j].sectionalData)))
            res[j].sectionalData = {};

          var ex = res[j]?.testStats?.map((item) => {
            for (let k = 0; k < sectionqIdArr.length; k++) {
              if (item.questionId == sectionqIdArr[k]) {
                if (item.answer) sectionalAttempt = sectionalAttempt + 1;

                if (item.correct) sectionalCorrect = sectionalCorrect + 1;

                if (!item.correct && item.hasOwnProperty("correct"))
                  sectionalWrong = sectionalWrong + 1;

                sectionalScore =
                  (item.marks ? item.marks : Number(0)) + sectionalScore;
              }
            }
          });

          var xx = res[j].sections.map((item) => {
            if (item.title == sectionTitle) {
              res[j].sectionalData[sectionTitle] = {
                attempt: sectionalAttempt,
                correct: sectionalCorrect,
                wrong: sectionalWrong,
                score: sectionalScore,
              };
            }
          });
        }
      }
    }

    for (let i = 0; i < res.length; i++) {
      var now = new Date(res[i].startedAt);
      var then = new Date(res[i].endedOn);
      let testDuration;

      const diffTime = Math.abs(now - then);
      let timeTaken = diffTime / 1000;

      if (Math.floor(timeTaken / 3600) != 0) {
        testDuration =
          Math.floor(timeTaken / 3600) +
          "h : " +
          Math.floor((timeTaken % 3600) / 60) +
          "m : " +
          Math.floor((timeTaken % 3600) % 60) +
          "s";
      } else {
        testDuration =
          Math.floor((timeTaken % 3600) / 60) +
          "m : " +
          Math.floor((timeTaken % 3600) % 60) +
          "s";
      }

      res[i].timeTaken = testDuration;
    }
    function millisToMinutesAndSeconds(millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    }

    resForTestResultScreen = res;
    await tick();
    if (!res.length) emptyState = true;
    else emptyState = false;
  }

  async function updateStudentResults() {
    abcentStudentArr = await api_getAbcentStudentsList();
    handleCallFromTest();
    bindAbcentStudentDatatable();
  }
  onMount(async () => {
    activeMenu.set("examReport");

    if (userId) {
      bindDatatable(null, userId);
      dispatch("setActiveTab", {
        tabName: "TestResults",
      });
    } else if (mode == "testResult") {
      testObj.menu = "testResult";
      if (updateParent) updateParent();
      updateStudentResults();
    } else {
      testObj.menu = "testResult";
      if (updateParent) updateParent();
      bindDatatable(testObj?._id, null);
    }

    bindSelectBatchFilter();
    bindSelectBranchFilter();
    bindSelectClassFilter();
    feather.replace();
  });

  var btnExportMyTestResult = async () => {
    if (exportLoading) return;
    exportLoading = true;

    if (myTestResultData) {
      generateMyTestResultWorkBook(myTestResultData);
    }

    exportLoading = false;
  };

  async function generateMyTestResultWorkBook(myResultDataList) {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet("Test Result Data", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });

    let fileName = "Test_Result_Data_Export_" + new Date().getTime() + "_.xlsx";

    let columnsArr = [
      { header: "Sr No.", key: "serialNo", width: 15 },
      { header: "Test Name", key: "name", width: 15 },
      { header: "Test Duration", key: "testTime", width: 15 },
      { header: "Practice Test", key: "practiceTest", width: 15 },
      { header: "Rank", key: "rank", width: 15 },
      { header: "Score", key: "score", width: 15 },
      { header: "Submitted On", key: "submittedOn", width: 15 },
      { header: "Duration", key: "duration", width: 15 },
      { header: "Total Attempted", key: "total", width: 15 },
      { header: "Correctly Answered", key: "correctlyAnswered", width: 15 },
      { header: "Wrong Answered", key: "wrongAnswere", width: 15 },
    ];

    worksheet.columns = columnsArr;

    for (let idx = 0; idx < myResultDataList.length; idx++) {
      let testDataArr = [];
      let myTestResult = myResultDataList[idx];

      let submittedOn = moment(new Date(myTestResult.endedOn)).format(
        "DD/MM/YYYY",
      );
      var now = moment(myTestResult.expiresAt);
      var then = moment(myTestResult.startedAt);
      var duration = moment
        .utc(
          moment(now, "DD/MM/YYYY HH:mm:ss").diff(
            moment(then, "DD/MM/YYYY HH:mm:ss"),
          ),
        )
        .format("HH:mm:ss");
      testDataArr.push(idx + 1);
      testDataArr.push(
        myTestResult.testData[0] ? myTestResult.testData[0].name : "",
      );
      testDataArr.push(
        myTestResult.testData[0].duration
          ? myTestResult.testData[0].duration
          : "",
      );
      testDataArr.push(
        myTestResult.testData[0].isPracticeTest
          ? myTestResult.testData[0].isPracticeTest
          : "",
      );
      testDataArr.push(myTestResult.rank ? myTestResult.rank : "");
      testDataArr.push(
        myTestResult.total.maximumMarks
          ? myTestResult.total.totalMarks +
              "/" +
              myTestResult.total.maximumMarks
          : "",
      );
      testDataArr.push(submittedOn ? submittedOn : "");

      testDataArr.push(duration ? duration : "");
      testDataArr.push(
        myTestResult.total.totalAttempted
          ? myTestResult.total.totalAttempted
          : 0,
      );
      testDataArr.push(
        myTestResult.total.totalCorrectAns
          ? myTestResult.total.totalCorrectAns
          : 0,
      );
      testDataArr.push(
        myTestResult.total.totalWrongAns ? myTestResult.total.totalWrongAns : 0,
      );

      worksheet.addRow(testDataArr);
    }

    const buffer = await workbook.xlsx.writeBuffer();

    saveFile(
      new Blob([buffer], { type: "application/octet-stream" }),
      fileName,
    );
    //   await workbook.xlsx.writeFile(fileName);
  }

  const generateImportResultSheet = async () => {
    const workbook = new ExcelJS.Workbook();

    let fileName = "import_test_result" + new Date().getTime() + "_.xlsx";
    const worksheet = workbook.addWorksheet("Result Data", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });

    const columnsArr = [
      { header: "Roll Number", key: "RollNumber", width: 15 },
      { header: "Name", key: "name", width: 15 },
      { header: "Total Questions", key: "totalQuestion", width: 15 },
      { header: "Total Positive Marks", key: "TotalPositiveMarks", width: 15 },
      { header: "Total Negative Marks", key: "TotalNegativeMarks", width: 15 },
      { header: "Total Attempted", key: "TotalAttempted", width: 15 },
      {
        header: "Total Correct Answered",
        key: "TotalCorrectAnswered",
        width: 15,
      },
      { header: "Total Wrong Answered", key: "TotalWrongAnswered", width: 15 },
      { header: "Total Not Answered", key: "TotalNotAnswered", width: 15 },
      { header: "Total Not Visited", key: "TotalNotVisited", width: 15 },
      { header: "Total Marks", key: "TotalMarks", width: 15 },
      { header: "Maximum Marks", key: "MaximumMarks", width: 15 },
    ];
    worksheet.columns = columnsArr;

    const buffer = await workbook.xlsx.writeBuffer();

    saveFile(
      new Blob([buffer], { type: "application/octet-stream" }),
      fileName,
    );
  };
  async function generateWorkBook() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Test Result Data", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });

    // Check if resForTestResultScreen.data is not empty
    if (
      !resForTestResultScreen ||
      !resForTestResultScreen.data ||
      resForTestResultScreen.data.length === 0
    ) {
      console.error("No data available to generate workbook.");
      return; // Exit the function if there's no data
    }

    console.log("Data to be processed:", resForTestResultScreen.data);

    // Collect all unique sections from the data
    let allSections = new Set();
    resForTestResultScreen.data.forEach((result) => {
      if (result.sections) {
        result.sections.forEach((section) => {
          allSections.add(section.title);
        });
      }
    });

    let fileName = "Test_result_export_" + new Date().getTime() + "_.xlsx";

    let columnsArr = [
      { header: "Sr No.", key: "serialNo", width: 15 },
      { header: "Student Name", key: "name", width: 15 },
      { header: "Student Mobile No.", key: "mobileNo", width: 15 },
      { header: "Parent Contact Number", key: "parentContactNo", width: 15 },
      { header: "Test name", key: "testname", width: 15 },
      { header: "Test Date", key: "testDate", width: 15 },
      { header: "Started at", key: "startedAt", width: 15 },
      { header: "Ended at", key: "endedAt", width: 15 },
    ];

    let mergeCellsObj = {};

    // Adding Columns dynamically for each unique section
    allSections.forEach((title) => {
      let columnKey = uniqueId();
      columnsArr.push({
        header: title,
        key: columnKey,
        alignment: { horizontal: "center" },
      });
      let startIndex = columnsArr.length;
      columnsArr.push({ header: "Attempted", key: columnKey + "A" });
      columnsArr.push({ header: "Correct", key: columnKey + "C" });
      columnsArr.push({ header: "Wrong", key: columnKey + "W" });
      columnsArr.push({ header: "Total Score", key: columnKey + "Score" });

      // Merging sub column A, C, W, Score
      mergeCellsObj[columnKey] = {
        id: columnKey,
        title: title,
        mrgColumn: [startIndex, Number(startIndex) + 3],
      };
    });

    columnsArr.push({
      header: "Positive Marks",
      key: "positiveMarks",
      width: 15,
    });
    columnsArr.push({
      header: "Negative Marks",
      key: "negativeMarks",
      width: 15,
    });
    columnsArr.push({ header: "Total Marks", key: "totalMarks", width: 15 });

    worksheet.columns = columnsArr;

    // Merging Section columns
    for (const key in mergeCellsObj) {
      if (Object.hasOwnProperty.call(mergeCellsObj, key)) {
        const mergeArr = mergeCellsObj[key]["mrgColumn"];
        worksheet.mergeCells(1, mergeArr[0], 1, mergeArr[1]);
      }
    }

    // Adding second row for sub-columns
    let arr = ["", "", "", "", "", "", "", ""];
    allSections.forEach(() => {
      arr.push("Attempted");
      arr.push("Correct");
      arr.push("Wrong");
      arr.push("Total Score");
    });
    worksheet.addRow(arr);

    // Adding data rows
    for (let idx = 0; idx < resForTestResultScreen.data.length; idx++) {
      let testDataArr = [];
      let testresult = resForTestResultScreen.data[idx];
      let startDate = new Date(testresult.startedAt);

      testDataArr.push(idx + 1);
      testDataArr.push(testresult.userData ? testresult.userData.name : "");
      testDataArr.push(testresult.userData ? testresult.userData.mobileNo : "");
      testDataArr.push(
        testresult?.userData?.parentContactNo
          ? testresult?.userData?.parentContactNo
          : "",
      );

      testDataArr.push(
        testresult.testData[0] ? testresult.testData[0].name : "",
      );
      testDataArr.push(
        testresult ? moment(startDate).format("MM/DD/YYYY") : "",
      );
      testDataArr.push(
        testresult ? moment(startDate).format("hh:mm:ss a") : "",
      );
      testDataArr.push(
        testresult ? moment(testresult.expiresAt).format("hh:mm:ss a") : "",
      );

      allSections.forEach((title) => {
        let sectionData = testresult.sectionalData[title] || {};
        testDataArr.push(sectionData.attempt || 0);
        testDataArr.push(sectionData.correct || 0);
        testDataArr.push(sectionData.wrong || 0);
        testDataArr.push(sectionData.score || 0);
      });

      testDataArr.push(testresult.total.totalPMarks || 0);
      testDataArr.push(testresult.total.totalNMarks || 0);
      testDataArr.push(testresult.total.totalMarks || 0);

      // console.log("Adding row:", testDataArr);
      worksheet.addRow(testDataArr);
    }

    const buffer = await workbook.xlsx.writeBuffer();

    saveFile(
      new Blob([buffer], { type: "application/octet-stream" }),
      fileName,
    );
  }
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    // var view = new Uint8Array(buf);
    var view = s;
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const saveFile = async (blob, fileName) => {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };
  function uniqueId() {
    // always start with a letter (for DOM friendlyness)
    var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
    do {
      // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
      var ascicode = Math.floor(Math.random() * 42 + 48);
      if (ascicode < 58 || ascicode > 64) {
        // exclude all chars between : (58) and @ (64)
        idstr += String.fromCharCode(ascicode);
      }
    } while (idstr.length < 32);

    return idstr.toLowerCase();
  }

  var btnExportTestResult = async () => {
    exportsLoading = true;
    // let url = await api_exportTestResult({testId: testObj._id, testData:testObj});

    generateWorkBook();
    exportsLoading = false;

    // if (url) { window.open(window.location.origin + url) }
  };
  //send true or false the template for downloading it.
  async function api_exportTestResult(data) {
    try {
      const response = await api_handleApiCalls("test.testExport", data);
      return response;
    } catch (error) {
      console.error("Error in api_exportTestResult:", error);
      return {
        status: false,
        message: "An error occurred while exporting the test results",
      };
    }
  }

  var btnExportTestAbcent = async () => {
    exportsLoading = true;
    let response = await api_exportTestResult({
      testId: testObj._id,
      exportAbcent: true,
    });
    exportsLoading = false;
    if (
      response &&
      typeof response === "object" &&
      response.status === true &&
      response.data &&
      response.data.S3_url
    ) {
      const link = document.createElement("a");
      link.href = response.data.S3_url;
      link.download = "Absent_student_list_" + new Date().getTime() + ".xlsx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      let errorMessage = "Failed to generate export URL";
      if (response && response.errMsg) {
        errorMessage += ": " + response.errMsg;
      } else if (response && response.message) {
        errorMessage += ": " + response.message;
      }
      pError("Error", errorMessage);
    }
  };

  function handleNameClick(studentId) {
    var testId = testObj._id;
    navigate(
      "/dashboard/tests/view/studentdetailedreport" +
        "/" +
        studentId +
        "/" +
        testId,
    );
  }
  function clearAppliedFilter() {
    _$(elemSelectFilterBatch)[0].selectize.clear();
    _$(elemSelectBranch)[0].selectize.clear();
    _$(elemSelectFilterClass)[0].selectize.clear();
  }

  let elem_uploadFile;
  let importLoading = false;
  let resultsArrToUpload = [];

  const importResultStep1 = async (workbook) => {
    let sheetName = workbook.SheetNames[0];

    let xl_row_object = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[sheetName],
      { raw: false },
    );

    importLoading = true;
    await tick();

    const exportedSheetData = new ResultImportedExcelData(xl_row_object);
    const valResult = exportedSheetData.validateHeaders(
      workbook.Sheets[sheetName],
    );

    if (!exportedSheetData.hasData()) {
      importLoading = false;
      return pError("Error!", "No data to import");
    }

    if (!valResult.isValid) {
      importLoading = false;
      return pError("Results import Error!", valResult.errorMsg);
    }

    let missingValues = exportedSheetData.getMissingValues();
    if (missingValues && missingValues.length) {
      importLoading = false;
      return pError(
        "Import Result!",
        `Missing values \nMissing details: ${missingValues}`,
      );
    }
    let data = exportedSheetData.getFormattedData();

    let payload = {
      resultData: data,
      testId: testObj._id,
    };
    let response = await api_handleApiCalls("test.importResults", payload);
    if (response.status) {
      pSuccess("Success", response.data);
      updateStudentResults();
    } else pError("Error!", response.errMsg);

    importLoading = false;
  };

  const bindFileChange = (event) => {
    const files = event.target.files || [];

    if (!files.length) {
      pError("File Error!", "Please Select File");
      return;
    }

    const selectedFile = files[0];

    let fileExtension = selectedFile.name.split(".");
    fileExtension = fileExtension[fileExtension.length - 1];

    if (fileExtension != "xlsx") {
      pError("File Error!", "Select Only Excel File having extension .xlsx");
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      importLoading = false;
      const data = event.target.result;
      const workbook = XLSX.read(data, {
        type: "binary",
      });

      importResultStep1(workbook);
    };

    reader.onerror = function (event) {
      importLoading = false;
    };

    try {
      importLoading = true;
      reader.readAsBinaryString(selectedFile);
    } catch (error) {
      importLoading = false;
    }
  };

  const startImportResults = async () => {
    resultsArrToUpload = [];
    await tick();
    _$(elem_uploadFile).val("");
    _$(elem_uploadFile).click();
  };
</script>

{#if mode == "cmp" || mode == "testResult"}
  <div class="mg-b-0 d-flex flex-column mg-t-8">
    <div
      style="width: 1;border-bottom: 1px solid #e5e8ee; display: flex;"
      class="d-none"
    >
      <div
        style="display: flex;align-items: center;justify-content: center;margin-left: 20px;"
        class="colored-svg"
      >
        <i data-feather="file" class="wd-40"></i>
      </div>
      <div class="media-body mg-l-10 pd-y-10">
        <h6 class="mg-b-2 tx-16">Test Results</h6>
        <span class="d-block tx-11 tx-color-03"
          >Result of all candidates/students</span
        >
      </div>
    </div>
    {#if mode == "testResult"}
      <div class="pd-20 row" style="width: 1;">
        <div class="col-xs-3 col-sm-3 col-md-3">
          <div class="form-row">
            <div class="form-group col-md-12">
              <!-- <label class="d-block">Batches</label> -->
              <select
                bind:this={elemSelectFilterBatch}
                class="info slectizedd form-control"
                placeholder="Select Batches"
              >
                <option value="">Select Batches</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-xs-3 col-sm-3 col-md-3">
          <div class="form-row">
            <div class="form-group col-md-12">
              <!-- <label class="d-block">Branch</label> -->
              <select
                bind:this={elemSelectBranch}
                class="info slectizedd form-control"
                placeholder="Select Branch"
              >
                <option value="">Select Branches</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-xs-3 col-sm-3 col-md-3">
          <div class="form-row">
            <div class="form-group col-md-12">
              <!-- <label class="d-block">Class</label> -->
              <select
                bind:this={elemSelectFilterClass}
                class="info slectizedd form-control"
                placeholder="Select Class"
              >
                <option value="">Select Class</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-xs-3 col-sm-3 col-md-3">
          <div class="d-flex">
            <div class="form-row">
              <div class="form-group col-md-12">
                <!-- <label class="d-block">Class</label> -->
                <button
                  disabled={isLoading}
                  class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5 btn-uppercase"
                  data-dismiss="modal"
                  on:click={clearAppliedFilter}
                  >Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4 ml-4 d-flex flex-row">
        <input
          bind:this={elem_uploadFile}
          class="d-none"
          on:change={bindFileChange}
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <div class="dropdown d-flex flex-row">
          <button
            disabled={exportsLoading || importLoading}
            class="btn btn-sm pd-x-15 btn-uppercase btn-secondary mg-l-5 dropdown-toggle"
            type="button"
            id="import_dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <ButtonLoader
              isLoading={importLoading}
              btnText="Import Offline results"
            />
          </button>
          <div class="dropdown-menu" aria-labelledby="import_dropdown">
            <a
              class="dropdown-item"
              disabled={importLoading}
              on:click={startImportResults}
              href="#"
              ><i data-feather="upload" class="wd-10 mg-r-5"></i>Import Result
              (Select Excel File To Import)</a
            >
            <a
              class="dropdown-item"
              on:click={generateImportResultSheet}
              href="#"
              ><i data-feather="file-text" class="wd-10 mg-r-5"></i>Download
              Template</a
            >
          </div>
        </div>
        <div class="dropdown d-flex flex-row">
          <button
            disabled={exportsLoading}
            class="btn btn-sm pd-x-15 btn-uppercase btn-secondary mg-l-5 dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Export
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" on:click={btnExportTestResult} href="#"
              ><i data-feather="file-text" class="wd-10 mg-r-5"></i>Test Result
              Records</a
            >
            <a class="dropdown-item" on:click={btnExportTestAbcent} href="#"
              ><i data-feather="file-text" class="wd-10 mg-r-5"></i>Students
              Absent Records</a
            >
          </div>
        </div>
      </div>
    {/if}
  </div>
  {#if mode == "cmp"}
    <div class="d-sm-flex flex-row justify-content-between mail-group-label">
      <h4 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
        Test Results
      </h4>

      <button
        class="btn btn-xs pd-x-15 btn-secondary btn-uppercase mg-l-5"
        on:click={() => {
          showHideFilterModal(true);
        }}
      >
        <i data-feather="filter" class="wd-10 mg-r-5"></i> Filter
      </button>
    </div>
    <hr style="margin-top: 5px;" />
    <div class="pd-l-20 pd-r-20 pd-b-20">
      <div class="df-example demo-table" style="width: 100%;">
        <table
          bind:this={reportsTable}
          class="table table-striped mg-b-0"
          style="width: 100%"
        >
          <thead>
            <tr>
              <th>Student name</th>
              <th>Rank</th>
              <th>Score</th>
              <th>Submited on</th>
              <th>Duration</th>
              <th>Questions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody class="list-group-flush tx-13"></tbody>
        </table>
      </div>

      <!-- {#if emptyState &&  paginationData.data.length < 0 }
        <div class="empty-state-img" style="text-align: center;">
            <img src="/images/empty.svg" style="width: 200px" alt="empty" />
            <p class="tx-bold">No test results found.</p>
          </div>
        {/if} -->
    </div>
  {/if}

  {#if mode == "testResult"}
    <div class="pd-l-20 pd-r-20 pd-b-20">
      <div class="df-example demo-table" style="width: 100%;" id="dvData">
        <!-- bind:this={reportsTable}-->
        <div>
          <form
            class="search-form wd-400 pd-b-20"
            style="float: right;"
            on:submit={async (event) => {
              event.preventDefault();
              handleCallFromTest();
            }}
          >
            <input
              type="search"
              class="form-control"
              bind:value={search}
              on:input={async (event) => {
                handleCallFromTest();
              }}
              placeholder="search by name | mobile no.| email | enrollment no."
            />
            <button class="btn" type="submit"
              ><i data-feather="search" /></button
            >
          </form>
        </div>

        <table class="table table-bordered mg-b-0" style="width: 100%">
          <thead>
            <tr>
              <th rowspan="2"> Rank</th>
              <!-- <th rowspan="2">Roll No.</th> -->
              <th rowspan="2" style="width: 40%;">Student name</th>
              {#each testSections as section}
                <th colspan="4"
                  ><span style="text-align: center;">{section.title} </span>
                  <div>(Total Questios: {section.questions.length})</div>
                </th>
              {/each}
              <th rowspan="2"> Total Score</th>
              <th rowspan="2"> Percentile (%)</th>
              <th rowspan="2"> Percentage (%)</th>
              <th rowspan="2"> Acc. (%)</th>
              <th rowspan="2"> Time Taken</th>
              <!-- <th rowspan="2"> Mobile No.</th> -->
            </tr>
            <tr>
              {#each testSections as section}
                <th>A</th>
                <th>C</th>
                <th>W</th>
                <th>Score</th>
              {/each}
            </tr>
          </thead>
          <tbody class="list-group-flush tx-13">
            {#if resForTestResultScreen && resForTestResultScreen.data}
              {#each resForTestResultScreen.data as res}
                <tr>
                  <td>
                    {res.rank}
                    {#if res.type == "offline"}
                      <div><span class="badge badge-info">Offline</span></div>
                    {/if}
                  </td>
                  <!-- <td>{res.userData.enrollmentNo? res.userData.enrollmentNo : "-"}</td> -->
                  <td
                    style="cursor: pointer;"
                    class="row mg-0 pd-0"
                    on:click={() => {
                      handleNameClick(res.userData._id);
                    }}
                  >
                    <img
                      src={res.userData.profilePic
                        ? res.userData.profilePic
                        : "/images/man.png"}
                      class="avatar rounded-circle mg-r-15"
                      alt={res.userData.name[0].toUpperCase()}
                    />

                    <div class="bg-transparent" style="flex: 1">
                      <h6
                        style="cursor:pointer"
                        class="tx-13 tx-inverse tx-semibold mg-b-0 bg-transparent btn-edit-student tx-capitalize"
                      >
                        {res.userData.name || "-"}
                      </h6>
                      <span class="d-block tx-11 text-muted bg-transparent">
                        <i data-feather="phone" class="wd-15"></i>
                        {res.userData.mobileNo || "-"}
                      </span>
                    </div>
                  </td>
                  {#each res.sectionResults as section}
                    <td>{section.totalAttempted}</td>
                    <td>{section.totalCorrectAns}</td>
                    <td>{section.totalWrongAns}</td>
                    <td>{section.totalMarks}</td>
                  {/each}
                  <td>{res.total.totalMarks}</td>
                  <td>
                    {#if res.totalMarksForPercentile != 0}
                      {(
                        (res.total.totalMarks / res.totalMarksForPercentile) *
                        100
                      ).toFixed(2)} %
                    {:else if res.total.totalMarks == 0}
                      100 %
                    {:else}
                      0 %
                    {/if}
                  </td>
                  <td>
                    {#if res.total.maximumMarks != 0}
                      {(
                        (res.total.totalMarks / res.total.maximumMarks) *
                        100
                      ).toFixed(2)} %
                    {:else if res.total.totalMarks == 0}
                      0 %
                    {:else}
                      0 %
                    {/if}
                  </td>
                  <td>
                    {#if res.total.totalAttempted != 0}
                      {(
                        (res.total.totalCorrectAns / res.total.totalAttempted) *
                        100
                      ).toFixed(2)}%
                    {:else if res.total.totalCorrectAns == 0}
                      0 %
                    {:else}
                      0 %
                    {/if}
                  </td>
                  <td style="width:10%">
                    {(
                      Math.abs(
                        new Date(res.startedAt) - new Date(res.endedOn),
                      ) / 60000
                    ).toFixed(2)} min
                  </td>
                </tr>
              {/each}
            {/if}
          </tbody>
        </table>
      </div>

      {#if emptyState && resForTestResultScreen.length == 0}
        <div class="empty-state-img" style="text-align: center;">
          <img src="/images/empty.svg" style="width: 200px" alt="empty" />
          <p class="tx-bold">No test results found.</p>
        </div>
      {/if}

      {#if todaysDate > durationForAbcent}
        <div class="mg-t-35">
          <h4>Absent Students List</h4>
        </div>
        <div class="df-example demo-table mg-t-25" style="width: 100%;">
          <table
            bind:this={abcentStudentTable}
            class="table mg-b-0"
            style="width: 100%"
          >
            <thead>
              <tr>
                <!-- <th>Sr no.</th> -->
                <th>Student name</th>
                <th>Batch Name</th>
              </tr>
            </thead>
            <tbody class="list-group-flush tx-13"></tbody>
          </table>
        </div>
      {/if}
    </div>
  {/if}
{:else}
  <div class="content-body fadeIn">
    <div
      class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
    >
      <div>
        <nav use:links aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-style1 mg-b-10">
            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              My Reports
            </li>
          </ol>
        </nav>
        <h4 class="mg-b-0 tx-spacing--1">My Reports</h4>
        <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
          Your exams reports
        </p>
      </div>
      <div class="d-block">
        <button
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          on:click={() => {
            showHideFilterModal(true);
          }}
        >
          <i data-feather="filter" class="wd-10 mg-r-5"></i> Filter
        </button>
      </div>
    </div>
    <hr class="full-width" />

    <!-- <hr class="mg-y-20"> -->
    <div
      data-label="Example"
      class="df-example demo-table"
      style="width: 100%;"
    >
      <table bind:this={reportsTable} class="table mg-b-0" style="width: 100%">
        <thead>
          <tr>
            <th class="">Test name</th>
            <th>Rank</th>
            <th>Score</th>
            <th>Submited on</th>
            <th>Duration</th>
            <th>Questions</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!--         
        {#if emptyState}
        <div class="empty-state-img" style="text-align: center;">
            <img src="/images/empty.svg" style="width: 200px" alt="empty" />
            <p class="tx-bold">No test results found.</p>
          </div>
        {/if} -->
  </div>

  <!-- Filter Modal -->
  <div
    class="modal effect-scale"
    bind:this={filterModalThis}
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel6"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
          <a
            href=""
            role="button"
            class="close pos-absolute t-15 r-15 z-index-10"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="media align-items-center">
            <span class="tx-color-03 d-none d-sm-block"
              ><i data-feather="filter" class="wd-60 ht-60"></i></span
            >
            <div class="media-body mg-sm-l-20">
              <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
              <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
            </div>
          </div>
          <!-- media-->
        </div>
        <!-- modal-header-->

        <hr class="full-width" />
        <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
          <div class="form-group">
            <label for="">Select batch</label>
            <select bind:this={elemSelectFilterBatch}> </select>
          </div>
          <div class="form-group">
            <label for="">Select branch</label>
            <select bind:this={elemSelectBranch}> </select>
          </div>

          <div class="form-group">
            <label class="d-block">Test Submitted On:</label>
            <input
              type="date"
              onkeydown="return false"
              bind:value={testSubmittedOn}
              class="form-control follow-up-date"
            />
          </div>

          <div class="form-group">
            <label class="d-block">Ranks:</label>
            <input
              type="number"
              bind:value={rank}
              class="form-control follow-up-date"
              placeholder="Filter by rank"
            />
          </div>

          <div class="form-group">
            <label class="d-block">Score:</label>
            <input
              type="number"
              bind:value={score}
              class="form-control follow-up-date"
              placeholder="Filter by score"
            />
          </div>

          <div class="d-md-flex justify-content-end mg-t-25">
            <button
              disabled={isLoading}
              on:click={clearFilter}
              class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5"
              data-dismiss="modal"
              >CLEAR
              <i data-feather="x" class="wd-10 mg-l-5"></i>
            </button>
            <button
              disabled={isLoading}
              on:click={applyFilter}
              class="btn btn-sm pd-x-15 btn-primary mg-l-5"
              data-dismiss="modal"
            >
              <ButtonLoader {isLoading} btnText="APPLY" />
              <i data-feather="filter" class="wd-10 mg-l-5"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
{/if}
