<script>
    import { Router, Link, link, Route } from "svelte-routing";
    import { onMount, tick } from 'svelte';

    import GetMobileNumber from "./getMobileNumber.svelte";
    import VerifyOtp from "./oldVerifyOtp.svelte";
    import GetInstitudeInfo from "./getInstitudeInfo.svelte";
    import RegistrationSuccessful from "./registrationSuccessful.svelte";

    function login() {
        navigate("/dashboard");
    }

</script>

<link rel="stylesheet" href="/main/assets/css/dashforge.auth.css">
<header class="navbar navbar-header navbar-header-fixed">
    <a use:link href="/" id="mainMenuOpen" class="burger-menu"><i data-feather="menu"></i></a>
    <div class="navbar-brand">
        <a use:link href="/" class="df-logo">quick<span>class</span></a>
    </div><!-- navbar-brand -->
    <div id="navbarMenu" class="navbar-menu-wrapper">
        <div class="navbar-menu-header">
            <a use:link href="/" class="df-logo">quick<span>class</span></a>
            <a use:link id="mainMenuClose" href="/"><i data-feather="x"></i></a>
        </div><!-- navbar-menu-header -->
        <ul class="nav navbar-menu">
            <li class="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>
            <li class="nav-item">
                <a use:link href="/" class="nav-link"><i data-feather="pie-chart"></i> Home</a>
            </li>
            <li class="nav-item">
                <a use:link href="/" class="nav-link"><i data-feather="layers"></i> About Us</a>
            </li>
            <li class="nav-item">
                <a use:link href="/" class="nav-link"><i data-feather="package"></i> Features</a>
            </li>
            <li class="nav-item">
                <a use:link href="/" class="nav-link"><i data-feather="box"></i> Pricing</a>
            </li>
            <li class="nav-item">
                <a use:link href="/" class="nav-link"><i data-feather="archive"></i>
                    Contact Us</a>
            </li>
        </ul>
    </div><!-- navbar-menu-wrapper -->
    <div class="navbar-right">
        <a use:link href="/" class="btn btn-social"><i class="fab fa-facebook"></i></a>
        <a use:link href="/" class="btn btn-social"><i class="fab fa-instagram"></i></a>
        <a use:link href="/" class="btn btn-social"><i class="fab fa-twitter"></i></a>
        <a use:link href="/login" class="btn btn-buy"><i data-feather="shopping-bag"></i> <span>Login</span></a>
        <a use:link href="/signup" class="btn btn-buy"><i data-feather="shopping-bag"></i> <span>Register</span></a>
    </div><!-- navbar-right -->
</header><!-- navbar -->

<div class="content content-fixed content-auth fadeIn" style="flex: 1;">
    <div class="container">
        <div class="media align-items-stretch justify-content-center ht-100p">

            <Router>
                <Route path="/">
                    <GetMobileNumber />
                </Route>
                 <Route path="/verify-otp">
                    <VerifyOtp />
                </Route>
                <Route path="/institute-info">
                    <GetInstitudeInfo />
                </Route>
                <Route path="/registration-successful">
                    <RegistrationSuccessful />
                </Route>
            </Router>
          
            <div class="media-body pd-y-30 pd-lg-x-50 pd-xl-x-60 align-items-center d-none d-lg-flex pos-relative" style="flex: 1;">
                <div class="mx-lg-wd-500 mx-xl-wd-550">
                    <img src="/images/signup.png" class="img-fluid" alt="">
                </div>
            </div><!-- media-body -->
        </div><!-- media -->
    </div><!-- container -->
</div><!-- content -->

<footer class="footer">
    <div>
        <span>&copy; 2023. QuickClasses V2.3 </span>
		<span>By <a href="https://sdlccorp.com/">SDLC Corp.</a></span>
    </div>
    <div>
        <nav class="nav">
            <a use:link href="/" class="nav-link">Licenses</a>
            <a use:link href="/" class="nav-link">Change Log</a>
            <a use:link href="/" class="nav-link">Get Help</a>
        </nav>
    </div>
</footer>
