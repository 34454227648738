<script>
  // _$(function () {
  //   "use script";

  //   window.darkMode = function () {
  //     _$(".btn-white").addClass("btn-dark").removeClass("btn-white");
  //   };

  //   window.lightMode = function () {
  //     _$(".btn-dark").addClass("btn-white").removeClass("btn-dark");
  //   };

  //   var hasMode = Cookies.get("df-mode");
  //   if (hasMode === "dark") {
  //     darkMode();
  //   } else {
  //     lightMode();
  //   }
  // });
  export let mode = "empty";
</script>

<div class="wd-100p tx-center align-items-center justify-content-center bd">
  {#if mode == "not-selected"}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">
      Please select a copy template to access this feature.
    </p>
  {:else}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">
      Please create access profile to access this feature.
    </p>
  {/if}
  <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
</div>

<!-- <div
  class="content content-fixed content-auth-alt bd bd-gray bg-gray-100 ht-100p wd-100p">
  <div class="container ht-100p tx-center">
    <div
      class="ht-100p d-flex flex-column align-items-center
        justify-content-center">
      <h1 class="tx-gray-500 tx-15 tx-sm-18 tx-lg-21 mg-xl-b-5">
        Batch Not Found
      </h1>
      <img
        src="/images/noresult.png"
        class="wd-25p op-6 mg-b-10 mg-t-10"
        alt="empty" />
      <p class="tx-14 tx-sm-15 tx-lg-16 tx-gray-400 mg-b-20">
        Please create batch to access this feature.
      </p>
    </div>
  </div>
</div> -->
