<script>
  import { links, link } from "svelte-routing";
  import { onMount, tick, afterUpdate } from "svelte";
  import { activeMenu } from "../../stores";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import { API_URL } from "../../../config.js"
  let displayTitle
  let isLoading = false;
  let elemAddResponseModal;
  let datatableResponse;
  let elemResponseTable;
  let emptyState = false
  let _id
  let errorObj = {}
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  }

  let responseObj = {
    response: '',
    action: '',
  }

  async function api_handleApiCalls(action, data) {
    isLoading = true
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var payload = JSON.stringify({
      action: action,
      accessToken: localStorage.getItem("accessToken"),
      data: data,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: payload,
    };

    try {
      let resObj = await fetch(API_URL, requestOptions);
      let response = await resObj.json();
      console.log("response--", response);
      isLoading = false
      return response;

    } catch (error) {
      isLoading = false
      console.error('error', error)
      return { errMsg: error.message };

    }
  }
  async function api_createResponse(payload) {
    let resData = await api_handleApiCalls("response.create", payload)
    console.log("api_createResponse === ", resData);
    if (resData.status) {
      pSuccess("Success!", resData.data)
      hideModal()
      clearResponseData()
      redrawResponseTable()
    } else {
      pError("Error!", resData.errMsg)
    }
  }
  async function api_getResponseDataById(id) {
    let resData = await api_handleApiCalls("response.getById", { _id: id })
    console.log("api_getResponseDataById === ", resData);
    if (resData.status) {
      return resData.data
    } else {
      return null
      pError("Error!", resData.errMsg)
    }
  }
  async function api_deleteResponse(data) {
    let resData = await api_handleApiCalls("response.delete", data)
    redrawResponseTable()
    if (resData.status) {
      pSuccess("Success!", resData.data)
    } else {
      pError("Error!", resData.errMsg)
    }
  }
  function getResponseData(data) {
    errorObj = {};
    var newErrorObj = {}
    if (!data.response && !Boolean(data.response)) {
      newErrorObj.response = "Please enter response.";
    }
    data.response = data.response ? data.response.trim() : data.response

    if (!data.action) {
      newErrorObj.action = "Please select action";
    }

    errorObj = newErrorObj;

    if (Object.keys(errorObj).length > 0) {
      // pError("Error", Object.values(errorObj)[0]);
      return null;
    }

    return {
      response: data.response,
      action: data.action
    }
  }
  function clearResponseData() {
    _id = null
    responseObj.response = ''
    responseObj.action = ''
  }
  function redrawResponseTable() {
    if (datatableResponse) { datatableResponse.draw() }
  }
  async function editResponse(id) {

    let resObj = await api_getResponseDataById(id)

    console.log("resObj============================>", resObj);

    if (resObj && (id == resObj._id)) {
      _id = resObj._id
      responseObj.response = resObj.response
      responseObj.action = resObj.action
      await tick()
      showModal()
    }

  }
  function deleteResponse(_id) {
    pConfirm("Delete!", "Are you sure you want delete this response", function () {
      api_deleteResponse({ _id: _id })
    })
  }
  var addUpdateBtnHandler = () => {
    let resData = getResponseData(responseObj)
    if (resData) {
      console.log("response object =", resData);

      if (_id) {

        resData._id = _id

        console.log("response update =>", resData);
        api_createResponse(resData)

      } else {

        api_createResponse(resData)

      }
    }

  }
  function getFormatedDate(date) {
    if (!date && date == '') {
      return '-'
    }
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');

  }
  function capitalizeFirstLetter(string) {
    string = string ? string : ''
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function bindModalAnimations() {
    _$(elemAddResponseModal).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
    });

    _$(elemAddResponseModal).on("hidden.bs.modal", function (e) {
      clearResponseData()
      _$(this).removeClass("effect-scale");
    });
  }

  function bindDatatable() {
    datatableResponse = _$(elemResponseTable).DataTable({
      stateSave: true,
      "stateSaveCallback": function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data))
      },
      "stateLoadCallback": function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname))
      },
      "searching": false,
      "bLengthChange": true,
      responsive: true,
      "bSort": false,
      language: {
        processing: "",
      },
      ajax: function (data, callback, settings) {
        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: 'response.list',
            accessToken: localStorage.getItem("accessToken"),
            data: data
          },
          success: function (resData) {
            console.log("resData=======================================>", resData);
            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data
              }
              if (!paginationData.data.length) {
                emptyState = true
              } else {
                emptyState = false
              }
              callback(result);
            }
            else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true
          }
        })
      },
      columns: [
        {
          data: 'response',
          defaultContent: '<div class="">-</div>'
        },

        {
          data: 'action',
          render: function (data, a, b, c) {
            var str = '<div class="">-</div>'
            if (data) {
              str = capitalizeFirstLetter(data)
            }

            return str
          }
        },
        {
          data: 'username',
          render: function (data, a, b, c) {
            var str = '<div class="">-</div>'
            if (data) {
              str = capitalizeFirstLetter(data)
            }

            return str
          }
        },
        {
          data: 'createdOn',
          render: function (data, a, b, c) {
            var date = '<div class="">-</div>'
            if (data) {
              date = getFormatedDate(data)
            }

            return date

          }
        },
        {
          data: '_id', render: function (data, a, b, c) {
            return `
                <div class="d-md-flex flex-row">
                    <a href="#" class="rename mg-r-5 btn-edit-res" response-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>
                    <a href="#" class="delete mg-r-5 btn-remove-res" response-id="${data}" data-toggle="tooltip" data-placement="top" title="trash"><i class="wd-20 mg-r-5" data-feather="trash"></i></a>
                </div>
                `;


          }
        },

      ],
      columnDefs: [
        { orderable: false, targets: -1 }
      ],
      "drawCallback": function () {
        feather.replace({ width: "1em", height: "1em" })
        _$('.btn-edit-res').unbind('click').click(function (e) {
          displayTitle = "Edit response";
          e.preventDefault();
          var responseId = _$(this).attr("response-id");
          editResponse(responseId)
        })

        _$('.btn-remove-res').unbind('click').click(function (e) {
          e.preventDefault();
          var responseId = _$(this).attr("response-id");
          deleteResponse(responseId)
        })
      },
      "processing": true,
      "serverSide": true,
    });
    _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
  }
  async function showModal() {
    _$(elemAddResponseModal).modal("show");
  }

  async function hideModal() {
    _$(elemAddResponseModal).modal("hide");
  }

  onMount(async () => {
    feather.replace({ width: "1em", height: "1em" });
    activeMenu.set('response')
    bindDatatable()
    bindModalAnimations()
    if (_id) {

    }
  });

</script>

<style>
  .empty-state-img {
    text-align: center;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
</style>

<div class="modal fade" bind:this={elemAddResponseModal} tabindex="-1" role="dialog" aria-labelledby="response"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="media align-items-center">
          <span class="tx-color-03 d-none d-sm-block"><i data-feather="refresh-ccw" class="wd-60 ht-60"></i></span>
          <div class="media-body mg-sm-l-20">
            <h4 class="tx-18 tx-sm-20 mg-b-2">{displayTitle}</h4>
            <p class="tx-13 tx-color-03 mg-b-0">Write your response</p>
          </div>
        </div><!-- media -->
      </div><!-- modal-header -->
      <hr class="full-width">
      <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
        <div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="d-block">Response<span class="tx-danger">*</span>:</label>
              <input bind:value={responseObj.response} on:keyup={(e)=> e.key === 'Enter' && addUpdateBtnHandler()}
              type="text" class="form-control" on:blur={()=>clearError("response")}
              class:parsley-error={errorObj.response} placeholder="Enter Response"/>
              {#if errorObj.response}
              <div class="parsley-errors-list">{errorObj.response}</div>
              <!-- content here -->
              {/if}
            </div>
            <div class="col-md-12">
              <label class="d-block">Action<span class="tx-danger">*</span>: </label>
              <div class="d-md-flex flex-wrap mg-t-5">
                <div class="custom-control custom-radio mg-r-15">
                  <input type="radio" id="customRadio1" bind:group={responseObj.action} on:keyup={(e)=> e.key ===
                  'Enter' && addUpdateBtnHandler()} class="custom-control-input" value='hot'>
                  <label class="custom-control-label" for="customRadio1">Hot</label>
                </div>
                <div class="custom-control custom-radio mg-r-15">
                  <input type="radio" id="customRadio2" bind:group={responseObj.action} on:keyup={(e)=> e.key ===
                  'Enter' && addUpdateBtnHandler()} class="custom-control-input" value='warm'>
                  <label class="custom-control-label" for="customRadio2">Warm</label>
                </div>
                <div class="custom-control custom-radio mg-r-15">
                  <input type="radio" id="customRadio3" bind:group={responseObj.action} on:keyup={(e)=> e.key ===
                  'Enter' && addUpdateBtnHandler()} class="custom-control-input" value='cold'>
                  <label class="custom-control-label" for="customRadio3">Cold</label>
                </div>
                <div class="custom-control custom-radio mg-r-15">
                  <input type="radio" id="customRadio4" bind:group={responseObj.action} on:keyup={(e)=> e.key ===
                  'Enter' && addUpdateBtnHandler()} class="custom-control-input" value='convert'>
                  <label class="custom-control-label" for="customRadio4">Convert</label>
                </div>

                <div class="custom-control custom-radio mg-r-15">
                  <input type="radio" id="customRadio5" bind:group={responseObj.action} on:keyup={(e)=> e.key ===
                  'Enter' && addUpdateBtnHandler()} class="custom-control-input" value='archive'>
                  <label class="custom-control-label" for="customRadio5">Archive</label>
                </div>

              </div>
              {#if errorObj.action}
              <div class="parsley-errors-list">{errorObj.action}</div>
              {/if}
            </div>

          </div>
        </div>

        <div class="d-flex justify-content-end tx-12 mg-t-30">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            CANCEL
            <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button type="button" disabled={isLoading} on:click={addUpdateBtnHandler}
            class="btn btn-sm btn-primary btn-uppercase mg-l-5">
            <ButtonLoader isLoading={isLoading} btnText="SAVE" />
            <i data-feather="save" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content-body fadeIn">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/enquiries">enquiries</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            response
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Response</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search responses or add new response. </p>
    </div>
    <div class="d-inline-block">
      <button type="button" on:click={()=>{
        displayTitle="Response";
        showModal()
        }} disabled={isLoading} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
        <i data-feather="plus" class="mg-r-5"></i>
        Add Response
      </button>
    </div>
  </div>
  <hr class="full-width">
  <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div class="df-example demo-table">
      <table bind:this={elemResponseTable} class="table table-striped mg-b-0" style="width: 100%;">
        <thead>
          <tr>
            <th>Response</th>
            <th>Response Action</th>
            <th>Created By</th>
            <th>Created On</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
      {#if emptyState }
      <div class="empty-state-img">
        <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
      </div>
      {/if}
    </div>
  </div>
</div>
