<script>
  export let mode = "empty";
</script>

<div class="wd-100p tx-center align-items-center justify-content-center bd">
  {#if mode == 'search'}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">Search not found.</p>
  {:else}
    <p class="wd-100p bg-gray-100 pd-t-8 pd-b-8 tx-13">No Comments yet</p>
  {/if}
  <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
</div>
