<script>
  import { Router, Link, links, Route, navigate, link } from "svelte-routing";
  import { afterUpdate, onMount, tick } from "svelte";
  import moment from "moment";
  import STPModal from "../AnnouncementsTextEditor/modal.svelte";

  export let testObj = {};
  export let errorObj = {};
  export let updateParent;

  let showModal = false;
  let elem_notifyBeforeTime;
  let elem_scrollNotificationTypes, scrollNotificationTypes;

  $: (testObj.notification_sms || testObj.notification_app) &&
    setTestObjNotifications();
  $: !(testObj.notification_sms || testObj.notification_app) &&
    resetTestObjNotifications();

  let setTestObjNotifications = function () {
    resetTestObjNotifications();
    _$(elem_notifyBeforeTime).timepicker(getTimepickerConfig(testObj.announcementBeforeExam.timeToNotify || "00:00 AM"));
  };

  let resetTestObjNotifications = function () {
    let publishAnnouncementMessage =
      (testObj.publishAnnouncement && testObj.publishAnnouncement.message) ||
      `Test "[testname]" is assigned to you. It will start on [startdate] at [starttime].`;
    // publishAnnouncementMessage = replacePlaceholders(
    //   publishAnnouncementMessage,
    //   testObj.displayName || testObj.name,
    //   testObj.startDate
    // );

    let announcementBeforeExamMessage =
      (testObj.announcementBeforeExam &&
        testObj.announcementBeforeExam.message) ||
      `Your test "[testname]" is starting today at [starttime].`;
    // announcementBeforeExamMessage = replacePlaceholders(
    //   announcementBeforeExamMessage,
    //   testObj.displayName || testObj.name,
    //   testObj.startDate
    // );

    testObj.publishAnnouncement = {
      message: publishAnnouncementMessage,
      // message: `You have been assigend a test. [test-name] ([test-display-name])`
      announcementId:
        (testObj.publishAnnouncement &&
          testObj.publishAnnouncement.announcementId) ||
        null,
    };
    testObj.announcementBeforeExam = {
      message: announcementBeforeExamMessage,
      // message: `You have been assigend a test. [test-name] ([test-display-name])`
      timeToNotify:
        (testObj.announcementBeforeExam &&
          testObj.announcementBeforeExam.timeToNotify) ||
        null,
      announcementId:
        (testObj.announcementBeforeExam &&
          testObj.announcementBeforeExam.announcementId) ||
        null,
    };
  };

  $: errorObj && console.warn(errorObj);
  let clearError = function (fieldName) {
    if (errorObj[fieldName]) {
      delete errorObj[fieldName];
    }
    errorObj = errorObj;
  };

  /* let replacePlaceholders = function (message, testName, testStartDate) {
    if (message) {
      if (testName) {
        message = message.replace(/\[testname\]/g, testName);
      }
      if (testStartDate) {
        message = message.replace(
          /\[startdate\]/g,
          moment(new Date(testStartDate)).format("DD-MMM-YYYY")
        );
        message = message.replace(
          /\[starttime\]/g,
          moment(new Date(testStartDate)).format("hh:mm A")
        );
      }
      return message;
    }
  }; */

  let onCloseTemplateModal = function (event) {
    console.log("sptObject : ", event.detail.sptObject);
    if (showModal == "first") {
      testObj.publishAnnouncement.message = event.detail.sptObject.content
      //  replacePlaceholders(
      //   event.detail.sptObject.content,
      //   testObj.displayName || testObj.testName,
      //   testObj.startDate
      // );
      console.log(
        "announcementObj.publishAnnouncement.message ",
        testObj.publishAnnouncement
      );
    } else if (showModal == "second") {
      testObj.announcementBeforeExam.message = event.detail.sptObject.content,
      // replacePlaceholders(
      //   event.detail.sptObject.content,
      //   testObj.displayName || testObj.testName,
      //   testObj.startDate
      // );
      console.log(
        "announcementObj.announcementBeforeExam.message ",
        testObj.announcementBeforeExam
      );
    }
    showModal = false;
    _$(".modal-backdrop").remove();
  };

  /* function convertDateStrToObj(selectedDate) {
        selectedDate = selectedDate.split("-");
        var year = selectedDate[0];
        var month = selectedDate[1] - 1;
        var day = selectedDate[2];
        return new Date(year, month, day);
    }

    function convertDateObjToDateStr(date) {
        date = new Date(date);
        var month = "" + (date.getMonth() + 1);
        var day = "" + date.getDate();
        var year = date.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        var dateString = [year, month, day].join("-");
        return dateString;
    } */

  function convertDateObjToTimeStr(date) {
    date = new Date(date);
    var minutes = "" + date.getMinutes();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    var hours = date.getHours() % 12;
    hours = hours ? hours : 12;

    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;

    var timeString = [hours, minutes].join(":") + " " + am_pm;
    return timeString;
  }

  const getTimepickerConfig = (defaultTime) => {
    let hours = parseInt(defaultTime.substr(0, 2));
    if (defaultTime.substr(6, 2) == "PM") hours = (hours + 12) % 24;
    let minutes = defaultTime.substr(3, 2);
    defaultTime = new Date();
    if (hours && !isNaN(hours) && minutes && !isNaN(minutes)) {
      defaultTime.setHours(parseInt(hours), parseInt(minutes));
    } else defaultTime.setHours(0, 0, 1);
    return {
      timeFormat: "h:mm p",
      interval: 5,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      defaultTime,
      startTime: "12:00",
      change: async function (time) {
        
        testObj.announcementBeforeExam.timeToNotify = moment(
          new Date(time)
        ).format("hh:mm A");
        // clearError('startDate')
        await tick();
        
      },
    };
  };

  afterUpdate(function () {
    if(!testObj.loaded) {
      testObj.menu = "notifications";
      updateParent()
    } else if (
      testObj.loaded
    ) {
      if(testObj.announcementBeforeExam &&
      testObj.announcementBeforeExam.timeToNotify){
        _$(elem_notifyBeforeTime).timepicker(
          getTimepickerConfig(testObj.announcementBeforeExam.timeToNotify)
        );
      }
      if(testObj.notification_app || testObj.notification_sms) {
        if (scrollNotificationTypes) scrollNotificationTypes.update();
        else scrollNotificationTypes = new PerfectScrollbar(elem_scrollNotificationTypes, {
              suppressScrollX: true
        });
      }
    }
  });
  onMount(function () {
    testObj.menu = "notifications";
    console.log("Publish : ", testObj);
    testObj.isPublish = true;
    updateParent()
    feather.replace();
  });
</script>

<div class="fadeIn" style="display: flex;flex-direction: column;">
  <div style="width: 1;border-bottom: 1px solid #e5e8ee; display: flex;">
    <div
      style="display: flex;align-items: center;justify-content: center;margin-left: 20px;"
      class="colored-svg"
    >
      <i data-feather="volume-2" class="wd-40" />
    </div>
    <div class="media-body mg-l-10 pd-y-10">
      <h6 class="mg-b-2 tx-16">Notifications</h6>
      <span class="d-block tx-11 tx-color-03">Start Date, End date</span>
    </div>
    <div class="pd-y-10">
      <button
        class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-r-10"
        on:click={() => { updateParent(true); navigate("/dashboard/tests/view/" + testObj._id + "/assign")}}>
        <i data-feather="chevron-left" class="wd-10 mg-r-5" /> Prev
      </button>
      <button
        class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-r-10"
        on:click={() =>{  updateParent(true); navigate("/dashboard/tests/view/" + testObj._id + "/publish")}}>
        Next <i data-feather="chevron-right" class="wd-10 mg-l-5" />
      </button>
    </div>
  </div>
  <div
    style="width: 1;border-bottom: 1px solid #e5e8ee; display: flex; flex-direction: column"
  >
    <div class="d-block tx-11 tx-color-03 mg-l-10 pd-y-10">
      * In your custom message, you can either put test name**, start date, and
      start time directly or you can use placeholders "[testname]",
      "[startdate]", and "[starttime]", whcih will display them automatically in notifications
    </div>
    <div class="d-block tx-11 tx-color-03 mg-l-10 pd-y-10">
      ** testname will prioritize test display name over test name
    </div>
  </div>
  <div class="pos-relative ht-md-350 ht-sm-500" bind:this={elem_scrollNotificationTypes}>
    <div style="flex: 1; padding: 25px; ">
      <div class="col-sm-12">
        <div class="form-row">
          <div class="form-group col-md-4 col-sm-12">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                id="notification-sms"
                bind:checked={testObj.notification_sms}
                name="notification-sms"
                disabled
                class="custom-control-input"
              />
              <label class="custom-control-label" for="notification-sms">
                Send SMS notifications
                <span class="required-field">*</span>
              </label>
            </div>
          </div>
          <div class="form-group col-md-4 col-sm-12">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                id="notification-app"
                bind:checked={testObj.notification_app}
                name="notification-app"
                disabled={testObj.publish}
                class="custom-control-input"
              />
              <label class="custom-control-label" for="notification-app">
                Send App notifications?
                <span class="required-field">*</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12" class:d-none={!(testObj.notification_sms || testObj.notification_app)}>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label
                  for="formGroupExampleInput"
                  class="d-flex"
                  style="align-items: baseline">
                  <div>
                    Message to announce on publishing test 
                    <span class="required-field">*</span> 
                    <span class="d-block mg-l-10">
                      {#if testObj && testObj.publishAnnouncement && testObj.publishAnnouncement.announcementId}
                      <a
                        use:link
                        class="tx-11"
                        href={"/dashboard/announcements/status/" +
                          testObj.publishAnnouncement.announcementId}>check notifications status</a>
                      {/if}
                    </span>
                  </div>
                  <div class="d-block mg-l-auto">
                    <div class="d-sm-flex">
                      <button
                        disabled={testObj.publish}
                        class="btn btn-xs pd-x-15 btn-primary btn-uppercase mg-l-5"
                        on:click={() => {
                          showModal = "first";
                          tick();
                        }}>
                        <i data-feather="copy" class="wd-10 mg-r-5" />
                        Copy From Template
                      </button>
                    </div>
                  </div>
                </label>
                <textarea
                  type="text"
                  class="form-control"
                  rows="3"
                  style="resize: none"
                  disabled={testObj.publish}
                  maxlength="300"
                  bind:value={testObj.publishAnnouncement.message}
                  on:blur={() => clearError("message")}
                  class:bd={errorObj.publishAnnouncement_message}
                  class:parsley-error={errorObj.publishAnnouncement_message}
                  placeholder="Enter announcement message here"
                />
                {#if errorObj.publishAnnouncement_message}
                  <div class="parsley-errors-list">
                    {errorObj.publishAnnouncement_message}
                  </div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12" class:d-none={!(testObj.notification_sms || testObj.notification_app)}>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label
                  for="formGroupExampleInput"
                  class="d-flex"
                  style="align-items: baseline">
                  <div>
                    Message to announce as reminder on exam day 
                    <span class="required-field">*</span> 
                    <span class="d-block mg-l-10">
                      {#if testObj && testObj.announcementBeforeExam && testObj.announcementBeforeExam.announcementId}
                      <a
                        use:link
                        class="tx-11"
                        href={"/dashboard/announcements/status/" +
                        testObj.announcementBeforeExam.announcementId}>check notifications status</a>
                      {/if}
                    </span>
                  </div>
                  <div class="d-block mg-l-auto">
                    <div class="d-sm-flex">
                      <button
                      disabled={testObj.publish}
                        class="btn btn-xs pd-x-15 btn-primary btn-uppercase mg-l-5"
                        on:click={() => {
                          showModal = "second";
                          tick();
                        }}>
                        <i data-feather="copy" class="wd-10 mg-r-5" />
                        Copy From Template
                      </button>
                    </div>
                  </div>
                </label>
                <textarea
                  type="text"
                  class="form-control"
                  rows="3"
                  style="resize: none"
                  disabled={testObj.publish}
                  maxlength="300"
                  bind:value={testObj.announcementBeforeExam.message}
                  on:blur={() => clearError("message")}
                  class:parsley-error={errorObj.announcementBeforeExam_message}
                  placeholder="Enter announcement message here"
                />
                {#if errorObj.announcementBeforeExam_message}
                  <div class="parsley-errors-list">
                    {errorObj.announcementBeforeExam_message}
                  </div>
                {/if}
              </div>
            </div>
          </div>
          <div
            class:d-none={!(testObj.notification_sms || testObj.notification_app)}
            class="col-sm-12"
          >
            <div class="form-row">
              <!-- <div class="form-group col-md-4 col-sm-12">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="announce_before_exam"
                    disabled={testObj.publish}
                    bind:checked={testObj.announce_before_exam}
                    name="announce_before_exam"
                    class="custom-control-input"
                  />
                   <!-- <label class="custom-control-label" for="announce_before_exam">
                     Select duration to notify reminder before exam 
                     Notify reminder before exam on
                    <span class="required-field">*</span>
                  </label>   -->
                <!-- </div>
              </div> --> 
              <!-- <div class="form-group col-md-4 col-sm-12">
                <div class="">
                  <input
                    type="text"
                    disabled={testObj.publish || !testObj.announce_before_exam}
                    bind:this={elem_notifyBeforeTime}
                    class="timepicker form-control"
                  />
                  {#if errorObj.announcementBeforeExam_time}
                    <div class="parsley-errors-list">
                      {errorObj.announcementBeforeExam_time}
                    </div>
                  {/if}
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- some empty space to display dropdown properly -->
    <div class="col-sm-12 ht-100">
      <div class="row">
        <div class="col-sm-12" />
      </div>
    </div>
  </div>
</div>

{#if Boolean(showModal)}
  <div class="mg-b-20">
    <STPModal on:close={onCloseTemplateModal} allowSelection={true} />
  </div>
{/if}
