<script>
  import { onMount, tick } from "svelte";
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { activeMenu } from "../stores";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  let now = new Date(),
    month,
    day,
    year;
  var date = new Date();

  let holidayName;
  let dateString;
  let elemBatchId;
  let elemBatchTimingId;
  let batchList = [];
  let batchTimingList = [];
  let batch_id;
  let batch_timing_id;
  let noteText = '';
  let checkedVal = '';
  let displayMessage = "";
  let elem_batches
  let selectizeBatchInstance1
  function reset() {
    batchTimingList = [];
    batch_timing_id;
    noteText = '';
    checkedVal = '';
  }
  onMount(async () => {
    activeMenu.set("studentAttendance");
    _$('[data-toggle="tooltip"]').tooltip();

    (month = now.toLocaleString('default', { month: 'long' })),
      (day = "" + now.getDate()),
      (year = now.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    dateString = `${month} ${day},${year}`
    // [year, month, day].join("-");

    var batchRes = await api_getBatch({ start: '0' });
    if (batchRes.status) {
      batchList = batchRes.data.data;
      // console.log("batchList:::",batchList)
      if (batchList.length == 0) {
        _$('#selectBatch').addClass('d-none');
        _$('#selectTiming').addClass('d-none');
        _$('#markAttendance').addClass('d-none');
        //displayMessage = "You are not assigned to any batch."
      }
      if (batchList.length >= 1) {
        // _$(elemBatchId).val(batchList[0].batchDetails._id);
        // batch_id = batchList[0].batchDetails._id
        //console.log("batch_id",batch_id);
        _$(elemBatchId).attr('disabled', 'disabled');
        await getHolidaysByDateAndBatchId();
      }
      reset();
    }
    // let result = await api_getBatchByDate();
    // if(result.status){
    //     batchTimingList = result.data;
    // }

    _$(elemBatchId).select2({
      minimumResultsForSearch: Infinity,
      data: batchList.map(function (aBatchId) {

        return {
          id: aBatchId.batchDetails._id,
          text: aBatchId.batchDetails.name,
        }
      })
    });

    _$(elemBatchId).on('select2:select', async function (e) {
      // alert('sd')
      var data = e.params.data;
      // console.log(data);
      let batchId = data.id;
      batch_id = batchId;
      enableMarkAttendance()
      await getHolidaysByDateAndBatchId();
      // await getBatchesFromBatchTiming();
      tick();
      // loadChildForRoot(batchId);
    });

    _$(elemBatchTimingId).on('select2:select', async function (e) {
      var data = e.params.data;
      //console.log(data);
      let batcTiminghId = data.id;
      batch_timing_id = batcTiminghId;
      enableMarkAttendance();
      tick();
      // await getBatchesFromBatchTiming();
      // loadChildForRoot(batchId);
    });

    initSelectize()
  });

  var errorObj = {
  }

  let validateNote = () => {
    errorObj = {};
    if (noteText == '') {
      var newErrorObj = {};
      newErrorObj.note = "Note is required Field.";
      errorObj = newErrorObj;
    }
    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;
  }
  let enableMarkAttendance = async () => {
    let data = {
      batch_id: batch_id,
      batch_timing_id: batch_timing_id,
      date: date
    }
    _$('#late').removeAttr('disabled');
    _$('#excused').removeAttr('disabled');
    _$('#present').removeAttr('disabled');
    _$('#absent').removeAttr('disabled');
    _$('#notes').removeClass('disabled');

    let res = await api_getStudentAttendance(data);
    // console.log("attendance data:::::::", res);
    if (res.status) {
      noteText = res.data.notes;
      _$(elem_batches).val(res.data.batchId);
      _$(elemBatchTimingId).val(res.data.batch_timing_id);
      checkedVal = ''
      checkedVal = res.data.attendance;
      // _$(`#${res.data.attendance}`).attr('checked', 'checked');
      // console.log('checkedVal ', checkedVal);
      // aStudentAttendance = res.data;

      if (checkedVal == "present") {
        _$('#showMessage').removeClass('d-inline-block').removeClass('bg-danger').removeClass('bg-warning').removeClass('bg-light').removeClass('ht-40').removeClass('mg-t-50').removeClass('tx-black')
        _$('#markAttendance').addClass('d-none');
        _$('#showMessage').addClass('d-inline-block').addClass('bg-success').addClass('ht-40').addClass('mg-t-50').addClass('tx-white');
        _$('#message').addClass('d-none')
          displayMessage = "Present   100%"
          displayMessage = displayMessage
      }
      else if (checkedVal == "late") {
        _$('#showMessage').removeClass('d-inline-block').removeClass('bg-danger').removeClass('bg-light').removeClass('bg-success').removeClass('ht-40').removeClass('mg-t-50').removeClass('tx-black')
        _$('#markAttendance').addClass('d-none');
        _$('#showMessage').addClass('d-inline-block').addClass('bg-warning').addClass('ht-40').addClass('mg-t-50').addClass('tx-white');
        _$('#message').addClass('d-none')
          displayMessage = "Late!!  50%"
          displayMessage = displayMessage
      }
      else if (checkedVal == "absent") {
        _$('#showMessage').removeClass('d-inline-block').removeClass('bg-warning').removeClass('bg-success').removeClass('bg-light').removeClass('ht-40').removeClass('mg-t-50').removeClass('tx-black')
        _$('#markAttendance').addClass('d-none');
        _$('#showMessage').addClass('d-inline-block').addClass('bg-danger').addClass('ht-40').addClass('mg-t-50').addClass('tx-white');
        _$('#message').addClass('d-none')
          displayMessage = "Absent!!  0%"
          displayMessage = displayMessage
      }

    

      else if (checkedVal == "excused") {
        _$('#showMessage').removeClass('d-inline-block').removeClass('bg-danger').removeClass('bg-warning').removeClass('bg-success').removeClass('ht-40').removeClass('mg-t-50').removeClass('tx-white')
        _$('#markAttendance').addClass('d-none');
        _$('#showMessage').addClass('d-inline-block').addClass('bg-light').addClass('ht-40').addClass('mg-t-50').addClass('tx-black');
        _$('#message').addClass('d-none')
          displayMessage = "Excused"
          displayMessage = displayMessage
      }

    } else {
        _$('#showMessage').removeClass('bg-success').removeClass('ht-40').removeClass('mg-t-50').removeClass('tx-white').removeClass('tx-black').removeClass('bg-warning').removeClass('bg-danger').removeClass('bg-light')
        displayMessage = ""
        displayMessage = displayMessage
      noteText = '';
    }

  }
  let getBatchesFromBatchTiming = async () => {
    // console.log('herer');
    clearValues();
    _$('#late').attr('disabled', 'disabled');
    _$('#excused').attr('disabled', 'disabled');
    _$('#present').attr('disabled', 'disabled');
    _$('#absent').attr('disabled', 'disabled');
    _$('#notes').addClass('disabled');
    // if ( _$(elemBatchTimingId).data('select2')) {
    //         _$(elemBatchTimingId).select2("destroy");
    //         }
    let result = await api_getBatchByDate();
    if (result.status) {
      batchTimingList = []
      // console.log('batchTimingList ', batchTimingList);
      batchTimingList = result.data;
      // console.log('batchTimingList api', result.data);

      // console.log('batchTimingList ', batchTimingList);
      if (batchTimingList.length == 0) {
        _$('#markAttendance').addClass('d-none');
        _$('#selectTiming').addClass('d-none');
        _$('#showMessage').removeClass('bg-success').removeClass('ht-40').removeClass('mg-t-50').removeClass('tx-white').removeClass('tx-black').removeClass('bg-warning').removeClass('bg-danger').removeClass('bg-white')
        _$('#showMessage').addClass('d-inline-block').addClass('bg-gray-200')
        if (displayMessage == '') {
          displayMessage = "No class today."
          displayMessage = displayMessage
        }
      }
      if (batchTimingList.length == 1) {
        _$(elemBatchTimingId).val(batchTimingList[0]._id);
        batch_timing_id = batchTimingList[0]._id;
        // console.log('batch_timing_id ', batch_timing_id);
        _$(elemBatchTimingId).attr('disabled', 'disabled');
        // _$('#showMessage').addClass('d-none');
        // _$('#selectTiming').removeClass('d-none');
        // _$('#markAttendance').removeClass('d-none');
        enableMarkAttendance();
        tick()
      }

      if (batchTimingList.length > 1) {
        // console.log('batchTimingListin ', batchTimingList);
        _$(elemBatchTimingId).removeAttr('disabled');
      }

      if (_$(elemBatchTimingId).hasClass("select2-hidden-accessible")) {
        // Select2 has been initialized
        _$(elemBatchTimingId).select2('destroy');
      }
      _$(elemBatchTimingId).html("");

      await tick();
      // _$(elemBatchTimingId).val('');
      // batch_timing_id = "";
      _$(elemBatchTimingId).select2({
        minimumResultsForSearch: Infinity,
        placeholder: "Select Class Timing",
        data: batchTimingList.map(function (aBatchTiming) {
          // console.log("aBatchTiming", aBatchTiming);
          var subjectNames = aBatchTiming.moduleSubjects.map(function (aModuleSubject) {
            var aSubject = aBatchTiming.subjectList.find(function (item) { return item._id == aModuleSubject });
            return aSubject.name;
          }).join(", ")
          return {
            id: (aBatchTiming._id),
            text: `${subjectNames} ${getTime(aBatchTiming.startTime)} - ${getTime(aBatchTiming.endTime)}`,
          }
        }),

      });
      if (batchTimingList.length > 1) {
        _$(elemBatchTimingId).append('<option selected="selected" value="">Select Class Timing</option>');
      }
      // console.log('v_$(elemBatchTimingId)', _$(elemBatchTimingId).val());
    }
    // console.log('inn getBatchesFromBatchTiming ',new Date(date),result);
  }
  function getTime(date) {
    let time;
    let newDate = new Date(date);
    return time = newDate.toLocaleString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    })
  }
  async function api_getBatchByDate() {

    let action = 'batchtiming.getBatchtimingByDate';
    var response = await api_handleApiCalls(action, { date: new Date().getDay(), batch_id: batch_id });
    return response;

  }


  async function api_getBatch(data) {


    let action = "batch.getBatchListByStudentId";
    var response = await api_handleApiCalls(action, data);
    return response;

  }


  async function api_saveAttendance(data) {

    let action = 'attendance.saveAttendance';
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  async function api_getStudentAttendance(data) {

    let action = 'attendance.getStudentAttendance';
    var response = await api_handleApiCalls(action, data);
    return response;

  }
  function clearValues() {
    batchTimingList = [];
    _$(elemBatchTimingId).val('');
    batch_timing_id = '';
    displayMessage = '';
    _$('#selectTiming').removeClass('d-none');
    _$('#markAttendance').removeClass('d-none');
    _$('#showMessage').addClass('d-none').removeClass('bg-gray-200');
  }
  function hideModel(params) {
    noteText = '';
    _$('#modal2').modal("hide")
  }

  let updateAttendanceNotes = async (e) => {
    // console.log('notestext ', noteText);

    let data = {
      batch_id: batch_id,
      batch_timing_id: batch_timing_id,
      notes: noteText,
      date: date
    }
    if (validateNote()) {
      let response = await api_saveAttendance(data);
      if (response.status) {
        hideModel();

        let res = await api_getStudentAttendance(data);
        if (res.status) {
          noteText = res.data.notes;
          // console.log('studentattendanceDetails ', res.data.notes);
          // aStudentAttendance = res.data;

        } else {
          noteText = '';
          console.error(res.errMsg);
        }
        // console.log('response sae', response);
      } else {
        console.log(response.errMsg);
      }
    }
  }
  let updateAttendanceCheck = async (e) => {

    // console.log('e ', e);
    _$(this).removeClass("btn-outline-success").addClass("btn-success");
    let data = {
      batch_id: batch_id,
      batch_timing_id: batch_timing_id,
      attendance: e.target.dataset.label,
      date: date
    }
    let response = await api_saveAttendance(data);
    if (response.status) {
      let res = await api_getStudentAttendance(data);
      if (res.status) {
        // noteText = res.data.notes;
        // console.log('studentattendanceDetails ', res.data);
        // aStudentAttendance = res.data;te
          
        
      } else {
        // noteText = '';
        console.error(res.errMsg);
      }
      // console.log('response sae', response);
    } else {
      console.log('j');
    }
  }

  async function api_getHolidayByBatchId() {

    let action = 'holidays.getHolidayByBatchId';
    var response = await api_handleApiCalls(action, { date: new Date(date), batchId: batch_id });
    return response;

  }
  let getHolidaysByDateAndBatchId = async () => {
    // enableMarkAttendance()
    let result = await api_getHolidayByBatchId();
    // console.log('api_getHolidayByBatchId ', result);
    if (result.status) {
      holidayName = result.data.name;
      _$('.holiday').removeClass('d-none');
      _$('.attendance').addClass('d-none');
      // batchList = result.data;
    } else {
      _$('.holiday').addClass('d-none');
      _$('.attendance').removeClass('d-none');
      getBatchesFromBatchTiming();

    }
  }
  async function initSelectize() {
        
    let batchesList1 = batchList.map(function (aBatchId) {

      return {
        _id: aBatchId.batchDetails._id,
       name:aBatchId.batchDetails.name
      }
    })


        if(_$(elem_batches).selectize()) _$(elem_batches).selectize()[0].selectize.destroy();

        selectizeBatchInstance1 = _$(elem_batches).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder:"Select batch",
            // plugins: ["remove_button"],
            delimiter: ",",
            persist: false,
            options: batchesList1,
            onChange:async function (values) {
              let batchId = values;
              batch_id = batchId;
              enableMarkAttendance()
              await getHolidaysByDateAndBatchId();
              // await getBatchesFromBatchTiming();
              tick();
            }
      })

    }


</script>

<div class="content-body fadeIn">
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Student Attendance
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Student Attendance - {dateString}</h4>
    </div>
  </div>
  <!-- <div> -->
  <!-- <h4 class="mg-b-5">Invoice #DF032AZ00022</h4> -->
  <!-- <p class="mg-b-5 tx-color-03 tx-18">{dateString}</p>
  </div> -->

  {#if !batchList.length}
  <div class="wd-100p tx-center align-items-center justify-content-center ">
  <img src="/images/empty.svg" style="width:200px" class="img-fluid" alt="No Result" />
    <p class="tx-bold">You are not assigned to any batch</p>
   
</div>
{:else}
  <form>
    <!-- <div class="form-group hasDatepicker col-md-3">
      <label class="d-block">Date</label>
      <input type="date"   class="form-control"  bind:value="{dateString}" disabled/>
    </div> -->
    <div class="form-row">
      <div id="selectBatch" class="form-group col-sm-6">
        <fieldset class="form-fieldset">
          <legend>Select Batch</legend>
          <div class="form-group form-row">
            <div class="form-group col-md-6">
              <label class="d-block">Select a Batch</label>
              <select  bind:this={elem_batches} class="">
                <option>Select batches</option>
            </select>
              
            </div>
            <div class="form-group col-md-6" id="selectTiming">
              <label class="d-block">Select a Class Timing</label>
              <select bind:this={elemBatchTimingId} data-placeholder="Select Class Timing" data-allow-clear="true"
                disabled style="width: 100%;" on:change={enableMarkAttendance} class="custom-select">
                <option value="" disabled="true" selected>Select Class Timing</option>
              </select>
            </div>
          </div>
        </fieldset>
      </div>

      <!-- </div> -->
      <div class="holiday d-none wd-50p">
        <div class="d-flex justify-content-center">
          <div class="col-md-6">
            <div class="card card-body">
              <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Holiday</h6>
              <div class="d-flex d-lg-block d-xl-flex align-items-end">
                <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{holidayName}</h3>
                <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium tx-success"><i
                      data-feather="umbrella"></i></span></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="markAttendance" class="form-group attendance col-sm-5">
        <fieldset class="form-fieldset d-none">
          <legend>Mark Attendance</legend>
          <div class="form-group form-row">
            <div class="custom-control mg-10 custom-radio ">
              <input type="radio" checked={checkedVal=='present' } data-label="present" data-studentId=""
                on:change={updateAttendanceCheck} disabled id="present" name="present" class="custom-control-input">
              <label class="custom-control-label" for="present">Present</label>
            </div>
            <div class="custom-control mg-10 custom-radio ">
              <input type="radio" checked={checkedVal=='late' } data-label="late" data-studentId=""
                on:change={updateAttendanceCheck} disabled id="late" name="present" class="custom-control-input">
              <label class="custom-control-label" for="late">Late</label>
            </div>
            <div class="custom-control mg-10 custom-radio ">
              <input type="radio" checked={checkedVal=='absent' } data-label="absent" data-studentId=""
                on:change={updateAttendanceCheck} disabled id="absent" name="present" class="custom-control-input">
              <label class="custom-control-label" for="absent">Absent</label>
            </div>
            <div class="custom-control mg-10 custom-radio">
              <input type="radio" checked={checkedVal=='excused' } data-label="excused" data-studentId=""
                on:change={updateAttendanceCheck} disabled id="excused" name="present" class="custom-control-input">
              <label class="custom-control-label" for="excused">Excused</label>
            </div>
            <!-- <button type="button" on:click={updateAttendanceCheck} data-label="leave" class="btn btn-outline-warning mg-10" >Leave</button>
          <button type="button" on:click={updateAttendanceCheck} data-label="present" class="btn btn-outline-success mg-10">Present</button>
          <button type="button" on:click={updateAttendanceCheck} data-label="absent" class="btn btn-outline-danger mg-10">Absent</button>
          <div class="form-group col-md-12">
            <label class="d-block">Note</label>
            <textarea bind:value={noteText} type="text" on:blur={()=>clearError("note")} class="form-control" class:parsley-error={errorObj.note} placeholder="Write a note"/>
            {#if errorObj.note}
                <div class="parsley-errors-list">{errorObj.note}</div>
            {/if}
        </div>
        <button type="button" on:click={updateAttendanceNotes} class="btn btn-primary tx-13">Save</button> -->

            <div data-toggle="tooltip" data-placement="right" title="Add a note">
              <a href="#modal2" class="btn btn-sm btn-primary btn-uppercase mg-5 disabled" id="notes"
                data-toggle="modal">
                <i class="far fa-edit"></i>
              </a>
            </div>
          </div>
        </fieldset>
      </div>

      <div id="showMessage" class="d-flex align-items-end form-group justify-content-center col-sm-5 d-none">
        <p id="message" class:d-none={(displayMessage != "No class today.") } class="pd-b-50">{displayMessage}</p>
        <span class:d-none={(displayMessage === "No class today.") } class="pd-b-10" >{displayMessage}</span>
      </div>
    </div>
  </form>

{/if}
</div>


<div class="modal fade" id="modal2" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel6">Add a note</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="form-group col-md-12">
            <label class="d-block">Note</label>
            <textarea bind:value={noteText} type="text" on:blur={()=>clearError("note")} class="form-control" class:parsley-error={errorObj.note} placeholder="Write a note"/>
                  {#if errorObj.note}
                      <div class="parsley-errors-list">{errorObj.note}</div>
                       <!-- content here -->
                  {/if}
              </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary tx-13"  data-dismiss="modal">Close</button>
        <button type="button" on:click={updateAttendanceNotes} class="btn btn-primary tx-13">Save</button>
      </div>
    </div>
  </div>
</div>
