<script>
  import { onMount, afterUpdate } from "svelte";
  import { activeMenu } from "../../stores/index.js";
  import { Router, Link, Route, links, navigate } from "svelte-routing";
  import { pSuccess, pError, pAlert } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  let statusList = [],
    announcementDetails = { batchNames: [], channelNames: [], counts: {} },
    elem_statusDataTable;
  export let _id;

  async function api_fetchAnnouncementStatusById(data) {

    let action = "announcements.fetchAnnouncementStatusById";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.message = order;
    } else if (sortOrder.column === 4) {
      sort["counts.batchStudentCount"] = order;
    } else if (sortOrder.column === 5) {
      sort["counts.channelMembersCount"] = order;
    } else if (sortOrder.column === 6) {
      sort["counts.studentsCount"] = order;
    } else if (sortOrder.column === 7) {
      sort["counts.parentsCount"] = order;
    } else if (sortOrder.column === 8) {
      sort["counts.sysUsersCount"] = order;
    } else {
      sort.seqId = order;
    }
    return sort;
  }
  let bindDatatable = function () {
    _$(elem_statusDataTable).DataTable({
      language: {
        processing: "",
        searchPlaceholder: "search by user",
        sSearch: "",
        lengthMenu: "_MENU_ items/page",
      },
      "order": [[ 0, "desc" ]],
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data));
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname));
      },
      ajax: async function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
        let statusData = await api_fetchAnnouncementStatusById({
          start: data.start,
          length: data.length,
          search: { value: data.search.value },
          sort: data.sort,
          notificationId: _id,
        });

        if (statusData.status) {
          var paginationData = statusData.data;
          announcementDetails = statusData.data.announcementDetails;
          var result = {
            recordsTotal: Number(paginationData.totalCount),
            recordsFiltered: Number(paginationData.filteredCount),
            data: paginationData.statusArr,
          };
          callback(result);
        } else {
          console.log("Something went wrong");
        }
      },
      columns: [
        { data: "seqId" },
        {
          data: "userName",
          // render: data=> data? `<span class="badge badge-success"> </span`:
          //   `<span class="badge badge-danger"> </span`
        },
        {
          data: "read",
          render: data=> data? `<span class="tx-success" title="read"> <i data-feather="check"> </i> </span`:
              `<span class="tx-danger" title="unread"> <i data-feather="x"> </i> </span`
        },
        // {
        //   data: "_id",
        //   render: function (data, type, row, meta) {
        //       console.log(data, type, row, meta)
        //     return `
        //       <div class="d-md-flex flex-row">
        //           <a href="#" class="rename btn-edit-announcement mg-r-5" title="view status" announcement-id="${data}"><i data-feather="activity" class="wd-20 mg-r-5"></i></a>
        //           <a href="#" class="delete btn-delete-announcement mg-r-5" title="archive" announcement-id="${data}"><i data-feather="archive" class="wd-20 mg-r-5"></i></a>
        //       </div>
        //   `;
        //   },
        // },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        /* _$(".btn-edit-announcement")
            .unbind("click")
            .click(function (e) {
              e.preventDefault();
              var announcementId = _$(this).attr("announcement-id");
  
              navigate("announcements/status/" + announcementId);
            }); */
        /* _$(".btn-delete-announcement")
            .unbind("click")
            .click(function (e) {
              e.preventDefault();
              var announcementId = _$(this).attr("announcement-id");
              const notice = PNotify.notice({
                title: "Archive Announcement?",
                text:
                  "Are you sure you want to archive this announcement's details?",
                hide: false,
                closer: false,
                sticker: false,
                modules: new Map([
                  ...PNotify.defaultModules,
                  [PNotifyConfirm, { confirm: true }],
                ]),
              });
  
              notice.on("pnotify:confirm", async () => {
                pAlert("", "Not yet implemented");
                // try {
                //   var deleteBatchRes = await api_deleteBatchById({
                //     _id: announcementId,
                //   });
                //   if (deleteBatchRes.status) {
                //     PNotify.success({
                //       title: "Success",
                //       text: "Batch Deleted Successfully",
                //       delay: 3000,
                //     });
                //   } else {
                //     PNotify.error({
                //       title: "Error",
                //       text: deleteBatchRes.errMsg,
                //       delay: 3000,
                //     });
                //   }
                // } catch (error) {
                //   console.error(error);
                // }
                // batchDataTable.ajax.reload();
              });
            }); */

        // bind actions
      },
      processing: true,
      serverSide: true,
    });

    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  };

  let reload = async function () {
    _$(elem_statusDataTable).DataTable.ajax.reload();
    console.log("reloaded ", result);
  };

  afterUpdate(function () {
    feather.replace();
  });
  onMount(function () {
    console.log("mounted announcements list");
    activeMenu.set("announcements");

    bindDatatable();
  });
</script>

<div class="content-body fadeIn">
  <!-- <Link style="display: none;" to="about">About</Link> -->
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/announcements">Announcements</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Status</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1 d-flex" style="align-items: baseline" >
          Announcements Status
          <span class="badge badge-success mg-l-10 tx-uppercase">Read {announcementDetails.counts.statusRead || 0}</span>
          <span class="badge badge-warning mg-l-10 tx-uppercase">Unread {announcementDetails.counts.statusUnread || 0}</span>
          {#if announcementDetails.mandatory}
          <span class="badge badge-danger mg-l-10 tx-uppercase">Mandatory</span>
          {/if}
          {#if announcementDetails.notification_app}
          <span class="badge badge-primary mg-l-10 tx-uppercase">App</span>
          {/if}
          {#if announcementDetails.notification_sms}
          <span class="badge badge-info mg-l-10 tx-uppercase">SMS</span>
          {/if}
        </h4>
    </div>
    <div class="d-block">
      <!-- <button
        on:click={() => {}}
        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
        <i data-feather="plus" class="wd-10 mg-r-5" />
        Make new Announcement
      </button> -->
    </div>
  </div>
  <!-- charts -->

  <div class="row row-xs mg-b-10">
    <div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
        <div class="card card-body">
          <h6
            class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
            Total Count
          </h6>
          <div class="d-flex d-lg-block d-xl-flex align-items-end">
            <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
              {announcementDetails.counts.totalUsersCount || 0}
            </h3>
            <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
          </div>
          <div class="chart-three">
            <div id="flotChart6" class="flot-chart ht-30" />
          </div>
        </div>
      </div>
      <!-- chart-three -->
      <!-- col -->
    <div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
      <div class="card card-body">
        <h6
          class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
          Batch Count
        </h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
            {announcementDetails.batchNames.length || 0}
          </h3>
          <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
        </div>
        <div class="chart-three">
          <div id="flotChart5" class="flot-chart ht-30" />
        </div>
      </div>
    </div>
    <!-- chart-three -->
    <!-- col -->
    <div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
      <div class="card card-body">
        <h6
          class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
          Channels Count
        </h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
            {announcementDetails.channelNames.length || 0}
          </h3>
          <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
        </div>
        <div class="chart-three">
          <div id="flotChart6" class="flot-chart ht-30" />
        </div>
      </div>
    </div>
    <!-- chart-three -->
    <!-- col -->
    <div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
      <div class="card card-body">
        <h6
          class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
          Students Count
        </h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
            {announcementDetails.counts.studentsCount || 0}
          </h3>
          <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
        </div>
        <div class="chart-three">
          <div id="flotChart6" class="flot-chart ht-30" />
        </div>
      </div>
    </div>
    <!-- chart-three -->
    <!-- col -->
    <div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
      <div class="card card-body">
        <h6
          class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
          Parents Count
        </h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
            {announcementDetails.counts.parentsCount || 0}
          </h3>
          <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
        </div>
        <div class="chart-three">
          <div id="flotChart6" class="flot-chart ht-30" />
        </div>
      </div>
    </div>
    <!-- chart-three -->
    <!-- col -->
    <div class="col-sm-6 col-md-2 mg-t-10 mg-lg-t-0">
      <div class="card card-body">
        <h6
          class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
          Sys Users Count
        </h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
            {announcementDetails.counts.sysUsersCount || 0}
          </h3>
          <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium" /></p>
        </div>
        <div class="chart-three">
          <div id="flotChart6" class="flot-chart ht-30" />
        </div>
      </div>
    </div>
    <!-- chart-three -->
    <!-- col -->
  </div>
  <!-- logged in devices -->
  <!-- logged in students -->
  <!-- logged in system users -->
  <div class="">
    <table bind:this={elem_statusDataTable} class="table table-sm">
      <thead>
        <tr>
          <th class="wd-auto text-capitalize">#</th>
          <th class="wd-auto text-capitalize">user</th>
          <th class="wd-auto text-capitalize">read</th>
          <!-- <th class="wd-auto text-capitalize">actions</th> -->
        </tr>
      </thead>
      <tbody />
    </table>
  </div>
  <!-- {JSON.stringify(statusList, null, 2)} -->
</div>
