import App from './App.svelte';

// window.debounce = (f, w) => {let t;return function fn(...args) {const later = () => {t = null;f(...args);};clearTimeout(t);t = setTimeout(later, w);};};



const app = new App({
	target: document.body
});

export default app;