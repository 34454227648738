<script>
  import { links, navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import ButtonLoader from "../../utils/button_loader.svelte"
  import { pSuccess, pError } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  export let reportId;

  let testDataTable;
  let demoDataTable;
  let requiredData;
  let questionDetails;
  let studentsDetails;
  let question;
  let questionId;
  let data = [];
  let level;
  let pMark;
  let nMark;
  let testName;
  let testType;

  //    let modalCmp;

  let btnDisable = false;
  let isLoading = false;

  let errorObj = {}


  async function api_getReportedIssueById(_id) {

     let action = "questionsReported.fetchCurrentReportedQuestion";
      var response = await api_handleApiCalls(action,{ _id: _id });
      return response.data;

    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // var payload = JSON.stringify({
    //   action: "questionsReported.fetchCurrentReportedQuestion",
    //   accessToken: localStorage.getItem("accessToken"),
    //   data: { _id: _id },
    // });
    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: payload,
    // };
    // try {
    //   var resObj = await fetch(API_URL, requestOptions);
    //   var response = await resObj.json();
    //   return response.data;
    // } catch (error) {
    //   console.error("error", error);
    //   return { error: error };
    // }
  }
  async function api_resolveQuestionById(data) {

    let action = "questionsReported.resolveReportedQuestion";
    var response = await api_handleApiCalls(action, data);
    return response.data;

  }

  let api_getQuestionById = async function (data) {

    let action = "questions.getQuestionById";
    var response = await api_handleApiCalls(action,{ questionId: data });
    return response.data;

  }
  let onQuestionEdit = function () {
    navigate(("/dashboard/questions/view/" + requiredData.questionId))
  }
  let onQuestionResolve = async function () {
    const notice = PNotify.notice({
      title: "Resolve question",
      text: "Are you sure you want to resolve this question ?",
      hide: false,
      closer: false,
      sticker: false,
      modules: new Map([
        ...PNotify.defaultModules,
        [PNotifyConfirm, { confirm: true }],
      ]),
    })

    notice.on("pnotify:confirm", async () => {
      try {
        var resolvequestion = await api_resolveQuestionById({ _id: questionId});
        if (resolvequestion) {
          console.log(resolvequestion);
          PNotify.success({
            title: 'Success',
            text: "Question resolved successfully",
            delay: 3000
          })
        } else {
          PNotify.error({
            title: 'Error',
            text: resolvequestion.errMsg,
            delay: 3000
          })
        }
        navigate(("/dashboard/questionsreported"))
      } catch (error) {
        console.error(error);
      }
    })

  }

  let api_getStudentsById = async function (data) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let payload = JSON.stringify(
      {
        "action": "questionsReported.reportedStudentsList",
        accessToken: localStorage.getItem("accessToken"),
        "data": {
          questionId: data,
          start: data.start,
          length: data.length,
          search: data.search.value
        }
      }
    );
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: payload,
    };
    try {
      let resObj = await fetch(API_URL, requestOptions);
      let response = await resObj.json();
      return response.data;

    } catch (error) {
      console.error('error', error)
      return { error: error };
    }
  }


  onMount(async () => {
    feather.replace();
    requiredData = await api_getReportedIssueById(reportId);
    questionDetails = await api_getQuestionById(requiredData.questionId);
    questionId = requiredData.questionId;
    question = questionDetails.data.question[questionDetails.languages[0].title];
    level = questionDetails.additionalInfo.level;
    pMark = questionDetails.additionalInfo.pmarks;
    nMark = questionDetails.additionalInfo.nmarks;
    studentsDetails = await api_getStudentsById(requiredData.questionId);
    data = studentsDetails.data
    name = studentsDetails.data[0].userData.name;
  })
  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var dateString = [day, month, year].join("-");
    return dateString;
  }
</script>

<style>
  .my-class > img {
    border: 1px red,
  }
</style>

<div class="content-body fadeIn" style="position: relative;    padding-bottom: 0;">
  <div class="d-sm-flex align-items-center justify-content-between mg-b-15 mg-lg-b-15 mg-xl-b-15">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item"><a href="/dashboard/questionsreported">Questions Reported</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Edit reported questions
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        Reported question
      </h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
        Show or edit reported question
      </p>
    </div>
    <div class="d-block">
      <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase" on:click={onQuestionEdit}><i data-feather="edit"
          class="wd-10 mg-r-5"></i>
        Edit question
      </button>
      <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25" on:click={onQuestionResolve}><i data-feather="arrow-right"
          class="wd-10 mg-r-5"></i>
        Resolve
      </button>

    </div>
  </div>

  <hr>
  <div class="reportedQuestionsEdit">
    <div class="row">
      <div class="col-sm-6">
        <h6 class="tx-bold">Question
          Level :<span class="badge badge-info mg-l-5"> {level? level: "Medium"}</span></h6>
        <div style="width:500px; overflow-wrap: break-word; height: fit-content;" class='my-class'>
          Q. {@html question}
        </div>
        <p class="bg-light pd-5"><span class=" tx-bold">Mark:-</span><span class="mg-l-25">Positive: {pMark? pMark:"0"}</span>
          <span class="mg-l-25">Negative: {nMark? nMark:"0"}</span></p>
      </div>
      <div class="col-sm-6">
        <div class="ScrollStyle scrollbar-sm pos-relative pd-12">

          {#each data as data}
                  <div class="media align-items-center mg-b-20">
                      <div class="avatar avatar-online"><img src="/images/man.png" class="rounded-circle" alt=""></div>
                      <div class="media-body pd-l-15">
                        <h6 class="mg-b-3" style="text-transform: capitalize;" >{data.userData.name}</h6>
                        <span class="d-block tx-13 tx-color-03">Mobile No. :{data.userData.mobileNo}</span>
                        <span class="d-block tx-13 tx-color-03">Report Type :{data.reportType.reportType}</span>
                      </div>
                      <span class="d-none d-sm-block tx-12 tx-color-03 align-self-start">Date :{convertDateObjToStr(data.createdOn)}</span>
                    </div><!-- media -->
                    <lable  class="tx-bold">Issue:-</lable>
                    <p class="mg-b-20" style="width: 500px; overflow-wrap: break-word; height: fit-content;" >{data.issue}</p>
                   {/each}
                
            <div class="d-block float-right pd-t-12">
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase" on:click={onQuestionEdit}><i data-feather="edit" class="wd-10 mg-r-5"></i>
                    Edit question
                </button>
                <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-25" on:click={onQuestionResolve}><i data-feather="arrow-right" class="wd-10 mg-r-5"></i>
                    Resolve
                </button>
                
            </div>
        </div>
    </div>
</div>

</div>


</div>
