<script>
    import { Router, Link, link, Route } from "svelte-routing";
    import { onMount } from "svelte";
</script>

<div class="content content-fixed content-auth-alt">
    <div class="container ht-100p tx-center">
        <div class="ht-100p d-flex flex-column align-items-center justify-content-center">
            <div class="wd-70p wd-sm-250 wd-lg-300 mg-b-15"><img src="../../public/assets/img/img22.png" class="img-fluid"
                    alt=""></div>
            <h1 class="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">Forbidden</h1>
            <h4 class="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">Oopps. Account Deactivated</h4>
            <p class="tx-color-03 mg-b-30">Check with admin if you have rights to visit this account
            </p>
            
            

        </div>
    </div><!-- container -->
</div>
