<script>
    import { Router, links, Link, Route } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import { activeMenu } from "../../stores";
    import { userData } from "../../stores";
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import BatchesListView from "./batchList.svelte"
    import ImageUploader from "../../utils/imageUploader.svelte";
    import ButtonLoader from "../../utils/button_loader.svelte"
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import MODULES from "../../utils/modules.js"
    import { API_URL } from "../../../config.js"

    export let _id
    export let isComponent
    let breadcrumbStudName
    let selectizeInstanceCity
    let elemSelectSchoolCollege
    let selectizeBoardInstance
    let elemSelectBoard
    let selectizeInstance
    let selectizeInstance2
    let selectCity
    let roleIsTelecaller
    let selectState
    let elem_city_list;
    let selectizeCityInstance;
    let elem_state_list;
    let elemSelectCity
    let isLoading = false
    let buttonLoading = false
    let showWebCam = true
    let cities = ''
    let stateList = [];
    let cityList = [];
    let counselorUserList = [];
    let studentObj = {
        name: "",
        email: "",
        mobileNo: "",
        Alternatemobileno: "",
        dob: "",
        gender: "",

        address: "",
        city: '',
        state: '',
        pincode: '',

        enrollmentNo: '',
        instituteName: "",
        lastExamName: "",
        marksObtained: "",
    }


    let dpicker_dob

    let errorObj = {};
    let elemStartDate, elemModuleSubj;


    function api_getCityListByStateId(stateId) {

        switch (stateId) {

            case 'MH':
                return [
                    {
                        "name": "Akola",
                        "state_code": "MH"
                    },
                    {
                        "name": "Aurangabad",
                        "state_code": "MH"
                    },
                ]
                break;

            case 'MP':
                return [
                    {
                        "name": "Bhopal",
                        "state_code": "MH"
                    },
                    {
                        "name": "Indore",
                        "state_code": "MH"
                    },
                ]
                break

            default:
                return []
                break;
        }




    }
    function api_getStateList(stateId) {
        return [

            {
                "id": 4039,
                "name": "Madhya Pradesh",
                "state_code": "MP"
            },
            {
                "id": 4008,
                "name": "Maharashtra",
                "state_code": "MH"
            },
        ]
    }

    async function api_getStudentById(id) {
        var resData = await api_handleApiCalls('students.getStudentById', { _id: id })
        if (resData.status) {

            return resData.data
        } else {

            pError("Error", resData.errMsg)
            return studentObj
        }
    }
    async function api_getStudentNameById(id) {
        var resData = await api_handleApiCalls('students.getStudentNameById', { _id: id })
        if (resData.status) {
            breadcrumbStudName = resData.data.name
            await tick()
        } else {

            pError("Error", resData.errMsg)
            breadcrumbStudName = '-'
            await tick()
        }
    }
    async function api_createNewStudent(data) {

        buttonLoading = true
        var resData = await api_handleApiCalls('students.create', data)
        buttonLoading = false

        if (resData.status) {
            pSuccess("Success", resData.data);
            return resData.data.data
        } else {
            pError("Error", resData.errMsg)
        }

    }

    function getStudentData(data) {
        errorObj = {};
        var newErrorObj = {}
        var NAMEREGEX = RegExp(/^[a-zA-Z\s][a-zA-Z\s]+$/)
        var EMAILREGEX = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        var MOBILENORGEX = RegExp(/^[6-9][0-9]{9}$/);

        if (!data.name && !Boolean(data.name)) {
            newErrorObj.name = "Please fill the Full Name";
        }
        if (!NAMEREGEX.test(data.name)) newErrorObj.name = "Plaese enter valid name";
        data.name = data.name ? data.name.trim() : data.name

        if (!data.mobileNo || !MOBILENORGEX.test(data.mobileNo)) {
            newErrorObj.mobileNo = "Plaese enter 10 digit mobile number";
        }

        if (data.Alternatemobileno) {
            if (!MOBILENORGEX.test(data.Alternatemobileno)) {
                newErrorObj.Alternatemobileno = "Please Enter 10 Digit Valid Mobile Number";
            }
        }

        if (Boolean(data.email)) {
            data.email = data.email.trim().toLowerCase();
            if (!EMAILREGEX.test(data.email)) {
                newErrorObj.email = "You have entered an invalid email address!"
            }
        }

        if (Boolean(data.pincode) && (data.pincode.toString().length > 7)) {
            newErrorObj.pincode = "Please Enter 6 digit pincode"
        }

        errorObj = newErrorObj;


        if (Object.keys(errorObj).length > 0) {
            pError("Error", Object.values(errorObj)[0])
            return null;
        }

        return {
            name: data.name,
            email: data.email,
            mobileNo: data.mobileNo,
            Alternatemobileno: data.Alternatemobileno,
            gender: data.gender,
            dob: dpicker_dob,

            enrollmentNo: data.enrollmentNo,
            boardId: data.boardId,
            schoolCollegeId: data.schoolCollegeId,
            lastExamName: data.lastExamName,
            marksObtained: data.marksObtained,

            address: data.address,
            city: data.city,
            state: data.state,
            pincode: data.pincode,
        }


    }

    let addUpdateStudentBtn = async () => {

        // validate before procced
        let studentData = studentObj
        studentData.dob = dpicker_dob.value

        studentData.state = _$(elem_state_list).val()
        studentData.city = (studentData.state && studentData.state != '') ? _$(elem_city_list).val() : ''
        studentData.schoolCollegeId = _$(elemSelectSchoolCollege).val()
        studentData.boardId = _$(elemSelectBoard).val()

        var studData = getStudentData(studentData)
        if (studData) {
            studData._id = _id
            api_createNewStudent(studData);
        }
    }



    let elementBatchSelect, elementUserSelect

    function bindEvents() {

        //use select2 add apis
        _$(elementBatchSelect).select2({ minimumResultsForSearch: Infinity });

    }
    async function stateOnchangeHandler(e) {
        let stateId = e.target.value

        cityList = api_getCityListByStateId(stateId)
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');

    }

    function setStudentData(data) {
        dpicker_dob = (Boolean(data.dob)) ? formatDate(data.dob) : ''
        var _cityList = api_getCityListByStateId(data.state)
        cityList = _cityList ? _cityList : []

        studentObj = {
            name: data.name,
            email: data.email,
            mobileNo: data.mobileNo,
            Alternatemobileno: data.Alternatemobileno,

            dob: dpicker_dob,
            gender: data.gender,
            boardId: data.boardId,
            address: data.address,
            city: data.city,
            state: data.state,
            pincode: data.pincode,

            enrollmentNo: data.enrollmentNo,
            schoolCollegeId: data.schoolCollegeId,
            lastExamName: data.lastExamName,
            marksObtained: data.marksObtained,
        }

    }

    const unsubscribe = userData.subscribe(value => {
        roleIsTelecaller = value.role == "telecaller"
    });

    onMount(async () => {
        bindEvents()
        unsubscribe();
        activeMenu.set('students')
        if (_id) {
            api_getStudentNameById(_id)

            var stdData = await api_getStudentById(_id)
            setStudentData(stdData)
        }
        stateList = api_getStateList()
        selectizeUtil.bindStateCitySelectize(studentObj.state, studentObj.city)
        selectizeUtil.bindSelectSchoolCollege(studentObj.schoolCollegeId)
        selectizeUtil.bindSelectBoard(studentObj.boardId)

        await tick()
        feather.replace({ width: "1em", height: "1em" })
    });


    const selectizeUtil = {
        bindState: async (selectedState) => {
            var selectizeInstanceState
            let stateList = []
            var response = await api_handleApiCalls('dropdown.stateList', {})
            if (response.status) stateList = response.data

            var _selectizeState = _$(elem_state_list).selectize({
                preload: true,
                maxItems: 1,
                valueField: "_id",
                labelField: "name",
                searchField: "name",
                placeholder: 'Select state',
                items: selectedState ? [selectedState] : [],
                options: stateList,
                create: true,
                sortField: 'name',
                create: async function (input) {
                    input = (typeof input == 'string') ? input.charAt(0).toUpperCase() + input.slice(1) : null

                    var response = await api_handleApiCalls('dropdown.stateCreate', {
                        name: input
                    })

                    if (response.status) {
                        response = response.data
                        selectizeInstanceState.addOption({ _id: response._id, name: response.name })
                        selectizeInstanceState.refreshOptions()
                        selectizeInstanceState.setValue(response._id)
                    }
                },
                onChange: function (value) {
                    if (!value) return;
                    selectizeUtil.bindCity(value, null)
                }

            });

            selectizeInstanceState = _selectizeState[0].selectize
        },
        bindCity: async (stateId, selectedCity) => {
            var cityListArr = []
            var response = await api_handleApiCalls('dropdown.cityList', { stateId: stateId })
            if (response.status) cityListArr = response.data

            if (selectizeInstanceCity) {
                selectizeInstanceCity.destroy()
            }


            var _selectizeCity = _$(elem_city_list).selectize({
                preload: true,
                maxItems: 1,
                valueField: "_id",
                labelField: "name",
                placeholder: 'Select city',
                searchField: "name",
                items: selectedCity ? [selectedCity] : [],
                options: cityListArr,
                create: true,
                sortField: 'name',
                create: async function (input) {
                    input = (typeof input == 'string') ? input.charAt(0).toUpperCase() + input.slice(1) : null

                    var response = await api_handleApiCalls('dropdown.cityCreate', {
                        name: input,
                        stateId: stateId
                    })

                    if (response.status) {
                        response = response.data
                        selectizeInstanceCity.addOption({ _id: response._id, name: response.name })
                        selectizeInstanceCity.refreshOptions()
                        selectizeInstanceCity.setValue(response._id)
                    }
                },
            });

            selectizeInstanceCity = _selectizeCity[0].selectize
            if (stateId) selectizeInstanceCity.enable()
            else selectizeInstanceCity.disable()
        },

        bindStateCitySelectize: (state, city) => {
            selectizeUtil.bindCity(state, city)
            selectizeUtil.bindState(state)
        },
        bindSelectSchoolCollege: async (selected) => {
            var selectizeInsanceThis
            var schoolCollegesList = []
            var response = await api_handleApiCalls('dropdown.listSchoolColleges', {})
            if (response.status) schoolCollegesList = response.data

            var _selectizeCity = _$(elemSelectSchoolCollege).selectize({
                preload: true,
                maxItems: 1,
                valueField: "_id",
                labelField: "name",
                placeholder: 'School/College/University',
                searchField: "name",
                items: selected ? [selected] : [],
                options: schoolCollegesList,
                create: true,
                sortField: 'name',
                create: async function (input) {
                    input = (typeof input == 'string') ? input.charAt(0).toUpperCase() + input.slice(1) : null

                    var response = await api_handleApiCalls('dropdown.createSchoolCollege', {
                        name: input,
                    })

                    if (response.status) {
                        response = response.data
                        selectizeInsanceThis.addOption({ _id: response._id, name: response.name })
                        selectizeInsanceThis.refreshOptions()
                        selectizeInsanceThis.setValue(response._id)
                    }
                },
            });

            selectizeInsanceThis = _selectizeCity[0].selectize
        },

        bindSelectBoard: async (selected) => {
            var selectizeInsanceThis
            var boardList = []
            var response = await api_handleApiCalls("dropdown.getBoardList", {});

            if (response.status) boardList = response.data
            var _selectBoard = _$(elemSelectBoard).selectize({
                preload: true,
                maxItems: 1,
                valueField: "_id",
                labelField: "name",
                placeholder: 'Enter board name of last conducted exam',
                searchField: "name",
                items: selected ? [selected] : [],
                options: boardList,
                create: true,
                sortField: 'name',
                create: async function (input) {
                    input = (typeof input == 'string') ? input.charAt(0).toUpperCase() + input.slice(1) : null
                    var response = await api_handleApiCalls('dropdown.createBoard', {
                        name: input,
                    })

                    if (response.status) {
                        response = response.data
                        selectizeBoardInstance.addOption({ _id: response._id, name: response.name })
                        selectizeBoardInstance.refreshOptions()
                        selectizeBoardInstance.setValue(response._id)
                    }
                },
            });

            selectizeBoardInstance = _selectBoard[0].selectize
        }

    }


</script>

<style>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    /* ###### 4.6 Parsley ###### */
    input.parsley-error,
    input.parsley-error:focus,
    textarea.parsley-error,
    textarea.parsley-error:focus {
        box-shadow: none !important;
        border-color: #dc3545;
    }

    input.parsley-success,
    input.parsley-success:focus,
    textarea.parsley-success,
    textarea.parsley-success:focus {
        box-shadow: none !important;
        border-color: #10b759;
    }

    .parsley-checkbox.parsley-error,
    .parsley-checkbox.parsley-success {
        display: inline-block;
        padding: 10px;
        border-radius: 0.25rem;
    }

    .parsley-checkbox.parsley-error {
        border: 1px solid #dc3545;
    }

    .parsley-checkbox.parsley-success {
        border: 1px solid #10b759;
    }

    .custom-select.parsley-error,
    .custom-select.parsley-success {
        box-shadow: none !important;
    }

    .custom-select.parsley-error {
        border-color: #dc3545;
    }

    .custom-select.parsley-success {
        border-color: #10b759;
    }

    .parsley-errors-list {
        color: #dc3545;
        list-style: none;
        font-size: 12px;
        padding: 0;
        margin-bottom: -12px;
    }

    .parsley-errors-list li {
        margin: 5px 0 0;
    }

    #my_result img {
        background: black;
    }
</style>

<div class={ isComponent ? "content-body fadeIn pd-0" :"content-body fadeIn"} style="overflow: hidden;">
    {#if !isComponent}

    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/students">Students</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {breadcrumbStudName? breadcrumbStudName : '-'}
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                Student Information
            </h4>
        </div>
        {#if !roleIsTelecaller}
        <div class="d-block">
            <div class="d-sm-flex">
                {#if MODULES.hasAccess(MODULES.UPDATE_STUDENT)}
                <button on:click={addUpdateStudentBtn} disabled={isLoading}
                    class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="save"
                        class="wd-10 mg-r-5"></i>
                    <ButtonLoader isLoading={buttonLoading} btnText="Save Changes" />
                </button>
                {/if}
            </div>
        </div>
        {/if}
    </div>

    {/if}
    <!-- <hr class="mg-y-20"> -->
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div class="row mg-b-20">
            <div class="col-md-12 col-lg-12 mg-b-20">
                <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0"> Personal Details </h6>
                <hr>
                <div class="row row-sm">
                    <div class={ isComponent ? "col-lg-12 col-md-12" :"col-lg-6 col-md-12"}>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="d-block">Full Name <span class="required-field">*</span></label>
                                <input bind:value={studentObj.name} type="text" class="form-control"
                                    class:parsley-error={errorObj.name} placeholder="Enter full name" maxlength="50" />
                                {#if errorObj.name}
                                <div class="parsley-errors-list">{errorObj.name}</div>
                                <!-- content here -->
                                {/if}
                            </div>
                            <div class="form-group col-md-6">
                                <label class="d-block">Roll number: </label>
                                <input type="tel" bind:value={studentObj.enrollmentNo} class="form-control"
                                    class:parsley-error={errorObj.enrollmentNo} placeholder="Roll no" disabled />
                                {#if errorObj.enrollmentNo}
                                <div class="parsley-errors-list">{errorObj.enrollmentNo}</div>
                                <!-- content here -->
                                {/if}
                            </div>
                            <div class="form-group col-md-6">
                                <label class="d-block">Email</label>
                                <input type="email" bind:value={studentObj.email} class="form-control"
                                    class:parsley-error={errorObj.email} placeholder="Enter email address" />
                                {#if errorObj.email}
                                <div class="parsley-errors-list">{errorObj.startDate}</div>
                                <!-- content here -->
                                {/if}
                            </div>
                            <div class="form-group col-md-6">
                                <label class="d-block">Mobile <span class="required-field">*</span></label>
                                <input type="tel" bind:value={studentObj.mobileNo} maxlength="10" class="form-control"
                                    class:parsley-error={errorObj.mobileNo} placeholder="Enter mobile number" />
                                {#if errorObj.mobileNo}
                                <div class="parsley-errors-list">{errorObj.mobileNo}</div>
                                <!-- content here -->
                                {/if}
                            </div>

                            <div class="form-group col-md-6">
                                <label class="d-block">Alternate Mobile</label>
                                <input type="tel" bind:value={studentObj.Alternatemobileno} maxlength="10"
                                    class="form-control" class:parsley-error={errorObj.Alternatemobileno}
                                    placeholder="Enter mobile number" />
                                {#if errorObj.Alternatemobileno}
                                <div class="parsley-errors-list">{errorObj.Alternatemobileno}</div>
                                <!-- content here -->
                                {/if}
                            </div>

                            <div class="form-group col-md-6">
                                <label class="d-block">Gender</label>
                                <div class="d-flex flex-row mg-t-20">
                                    <div class="custom-control custom-radio mg-r-5">
                                        <input type="radio" id="maleRadio" bind:group={studentObj.gender}
                                            class="custom-control-input" value='Male'>
                                        <label class="custom-control-label" for="maleRadio">Male</label>
                                    </div>

                                    <div class="custom-control custom-radio mg-r-5">
                                        <input type="radio" bind:group={studentObj.gender} id="femaleRadio"
                                            class="custom-control-input" value='Female'>
                                        <label class="custom-control-label" for="femaleRadio">Female</label>
                                    </div>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" bind:group={studentObj.gender} id="otherRadio"
                                            class="custom-control-input" value='Other'>
                                        <label class="custom-control-label" for="otherRadio">Other</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group hasDatepicker col-md-6">
                                <label class="d-block">Date of birth</label>
                                <input type="date" bind:value={dpicker_dob} class="form-control"
                                    class:parsley-error={errorObj.dob} onkeydown="return false" />
                                {#if errorObj.dob}
                                <div class="parsley-errors-list">{errorObj.dob}</div>
                                <!-- content here -->
                                {/if}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class={ isComponent ? "col-md-12 col-lg-12 mg-b-20" : "col-md-12 col-lg-6 mg-b-20" }>

                <div class="row row-sm mg-b-20">
                    <div class="col-md-12 col-md-6">
                        <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0"> Address Details </h6>
                        <hr>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="d-block">Address</label>
                                <input bind:value={studentObj.address} type="text" class="form-control"
                                    class:parsley-error={errorObj.address} placeholder="Enter Your Address" />
                                {#if errorObj.address}
                                <div class="parsley-errors-list">{errorObj.address}</div>
                                <!-- content here -->
                                {/if}
                            </div>

                            <div class="form-group col-md-6">
                                <label class="d-block">State</label>
                                <select bind:this={elem_state_list}>
                                    <!-- <option selected>Select State</option> on:change={stateOnchangeHandler}
                                    {#each stateList as state}
                                    <option value="{state.state_code}">{state.name}</option>
                                    {/each} -->
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="d-block">City</label>
                                <select bind:this={elem_city_list}>
                                    <!-- <option selected>Select City</option>
                                    {#each cityList as city}
                                    <option value="{city.name}">{city.name}</option>
                                    {/each} -->
                                </select>
                            </div>

                            <div class="form-group col-md-6">
                                <label class="d-block">Pincode</label>
                                <input type="number" bind:value={studentObj.pincode} class="form-control"
                                    class:parsley-error={errorObj.pincode} placeholder="Enter pincode number" />
                                {#if errorObj.pincode}
                                <div class="parsley-errors-list">{errorObj.pincode}</div>
                                <!-- content here -->
                                {/if}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row row-sm mg-b-20">
                    <div class="col-md-12 col-md-6">
                        <h6 class="tx-13 tx-spacing-1 tx-color-02 tx-semibold mg-b-0"> Education Details </h6>
                        <hr>
                        <div class="form-row">



                            <div class="form-group col-md-12">
                                <label class="d-block">Board</label>
                                <select bind:this="{elemSelectBoard}"></select>
                                {#if errorObj.board}
                                <div class="parsley-errors-list">{errorObj.board}</div>
                                <!-- content here -->
                                {/if}
                            </div>
                            <div class="form-group col-md-12">
                                <label class="d-block">School/College/University</label>
                                <select bind:this="{elemSelectSchoolCollege}"></select>
                            </div>

                            <div class="form-group col-md-6">
                                <label class="d-block">Last conducted exam:</label>
                                <input type="text" bind:value={studentObj.lastExamName} class="form-control"
                                    class:parsley-error={errorObj.lastExamName}
                                    placeholder="Enter last conducted exam name" />
                                {#if errorObj.lastExamName}
                                <div class="parsley-errors-list">{errorObj.lastExamName}</div>
                                <!-- content here -->
                                {/if}
                            </div>
                            <div class="form-group col-md-6">
                                <label class="d-block">Marks:</label>
                                <input type="text" bind:value={studentObj.marksObtained} class="form-control"
                                    placeholder="Obtained Marks" aria-label="Recipient's username"
                                    class:parsley-error={errorObj.marksObtained} aria-describedby="basic-addon2">
                                {#if errorObj.marksObtained}
                                <div class="parsley-errors-list">{errorObj.marksObtained}</div>
                                <!-- content here -->
                                {/if}
                            </div>
                        </div>

                    </div>
                </div>

                <div class="mg-b-25">
                    <div class="d-sm-flex align-items-center justify-content-end ">
                        {#if !roleIsTelecaller}
                        <div class="d-block">
                            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i data-feather="mail"
                                        class="wd-10 mg-r-5"></i> Email</button>
                                <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><i data-feather="printer"
                                        class="wd-10 mg-r-5"></i> Print</button> -->
                            {#if MODULES.hasAccess(MODULES.UPDATE_STUDENT)}
                            <button on:click={addUpdateStudentBtn} disabled={isLoading}
                                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="save"
                                    class="wd-10 mg-r-5"></i>
                                <ButtonLoader isLoading={buttonLoading} btnText="Save Changes" />
                            </button>
                            {/if}
                        </div>
                        {/if}
                    </div>
                </div>
            </div>
            {#if !isComponent}
            <div class="col-md-6">
                <div class="row row-sm mg-b-20">
                    <div class="col-sm-12">
                        <BatchesListView _id={_id} />
                    </div>
                </div>
            </div>
            {/if}
        </div>
    </div>
</div>
