<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import { activeMenu } from "../../stores";
    import FolderExplorer from "../../folderCmp/folderExplorer.svelte";
    import MonitoringZone from "../monitoringZone.svelte";
    import StudentDataView from "../studentData.svelte"
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import ProgressBar from "./ProgressBar.svelte"
    import StudentsListTable from "../studentsList.svelte"
    import AllDoubtsList from "../allDoubtsList.svelte"
    import { pSuccess, pError } from '../../utils/notify.svelte'
    import ButtonLoader from "../../utils/button_loader.svelte";

    export let bradCrumbLink
    
    /* viewMode=true using for show student monitoring data */
    export let viewMode
    export let studentId
    var renderCount = 0

    let isLoading = false
    let batchList = [];
    let bradCrumbArr   =   []
    let bradCrumbArrWithName   =   []
    let bradCrumbData   =   []
    let folderList  =   []
    let aIdList = []
    let chapterLevel = false
    let showStudents = false
    let search = { value: "" };
    let parentId
    let batchId
    let isAllStudentMode = true
    let allChapterTaught = false
    let   markLoading = false
    let noTopicPresent= false
    async function api_getBatch(data) {
        let action = "batch.listBatches";
        var response = await api_handleApiCalls(action, data);
        return response;
    }
    async function api_getStudentsBatches(data) {
        let action = "batch.getStudentBatchList";
        var response = await api_handleApiCalls(action, data);
        return response;
    }
    const getStudentsWhoMarkedCompleted = async (batchId, parentId) => {
      let response  =   await api_handleApiCalls('studyMonitoring.getStudentsOfBatch', {batchId, parentId})
      if(response.status) return response.data
      else return []
    };
    
    onMount(async () => {
        if(!viewMode) activeMenu.set('studyMonitoring')
        feather.replace();
        init()

    });
    let loadData = async function () {
        let data = {
            start: 0,
            length: 10,
            search: search.value,
           
        };
        var batchRes = await api_getBatch({search:data.search , noPagination:true});
            isLoading = false
            if (batchRes.status) {
                batchList = batchRes.data.data;
                await tick()
                feather.replace();
            }
        await tick();
        feather.replace()
    }
    
    $: bradCrumbLink, init()            

    const init =    async () => {
        renderCount += 1
        if(bradCrumbLink) chapterLevel = bradCrumbLink.split('-').length == 3
        else chapterLevel = false
        await tick()
        if(bradCrumbLink) startShowingFolders(chapterLevel)
        else startShowingBatches()

        // batchId=bradCrumbArr[0]
        // parentId= bradCrumbArr[bradCrumbArr.length-1]


    }
    
    
    
    const showHideStudentListHandler = async () => {
        showStudents = !showStudents
        await tick()
            feather.replace();
    };
    

    const startShowingFolders = async (chapterLevel) => {
        bradCrumbArr = bradCrumbLink.split('-')
        getFolderNamesForIds(bradCrumbArr)
        await tick()
        /* isChapterLevel = all chapters list */
        let isChapterLevel = bradCrumbArr.length == 2
        let isForStudents = viewMode 
        getFolderForFolder(bradCrumbArr[0], bradCrumbArr[bradCrumbArr.length-1], isChapterLevel, isForStudents)
        
        /* chapterLevel = inside chapter */
        // if(chapterLevel){
        //    studentsList = await getStudentsWhoMarkedCompleted(bradCrumbArr[0], bradCrumbArr[bradCrumbArr.length-1])
        //    console.log("studentsList---", JSON.stringify(studentsList, null, 4));
        //    await tick()
        //    initStudentsTable()
           
        // }
    }

    const startShowingBatches = async () => {
        
        if(viewMode) {
            isLoading = true
            var batchRes = await api_getStudentsBatches({ start: 0, length: 10000, studentId: studentId });
            isLoading = false
            if (batchRes.status) {
                batchList = batchRes.data.map((obj)=>({name:obj.batchDetails.name ,_id:obj.batchDetails._id, folderCount:obj.subjectList.length}));
                await tick()
                feather.replace();
            }
        } else {
            isLoading = true
            var batchRes = await api_getBatch({ start: 0, length: 10000 });
            isLoading = false
            if (batchRes.status) {
                batchList = batchRes.data.data;
                await tick()
                feather.replace();
            }
        }

        // _$(".folder-header").html(`
        //         <nav aria-label="breadcrumb " class="folder-breadcrumb">
        //             <ol class="breadcrumb breadcrumb-style2 mg-b-0 b">
        //                 <li class="breadcrumb-item">
        //                     <a class="bc-link" href=${viewMode ? `/dashboard/student/view/${studentId}/studyMonitoringChart` : "/dashboard/studyMonitoring"}> 
        //                         <i data-feather="folder"></i>  
        //                         All Batches
        //                     </a>
        //                 </li>
        //             </ol>
        //         </nav>
        //     `);
    }

    let getFolderNamesForIds = async (bradCrumbArr) => {
        let response = await api_handleApiCalls("folder.getFolderNamesForIds", bradCrumbArr)
        if(response.status){
            let batchName = response.data.batchObj.name;
            let batchId = response.data.batchObj._id;
            aIdList = [] 
            bradCrumbData = []
            let breadcrumbItem = ''
            aIdList.push(response.data.batchObj._id);

            // bradCrumbData data for breadcrumb rendering
    
            bradCrumbData.push({
                _id: batchId,
                name: batchName
            })
            let idListArr = response.data.idListArr.reverse();
            bradCrumbData.push(...idListArr)
            bradCrumbData = bradCrumbData
            breadcrumbItem = `
                <li class="breadcrumb-item">
                    <a class="bc-link" data-idlist="${aIdList[0]}" href="/dashboard/studyMonitoring/${aIdList[0]}"> 
                        ${batchName.toUpperCase()}
                    </a>
                </li>`;

            var bcList = [batchName];
            for (let r = 0; r < idListArr.length; r++) {
                const aListArr = idListArr[r];
                aIdList.push(aListArr._id)
                bcList.push(aListArr.name)
                breadcrumbItem += `
                <li class="breadcrumb-item">
                    <a class="bc-link" data-idlist="${aIdList.join('-')}" href="/dashboard/studyMonitoring/${aIdList.join('-')}"> 
                        <i data-feather="folder"></i>  
                        ${aListArr.name}
                    </a>
                </li>`;
            }
            bradCrumbArrWithName = bcList
            aIdList = aIdList

            await tick()
            feather.replace();
        } else {
            pError('Error', response.data)
            navigateTo('')
        }
    }

    let getFolderForFolder = async (batchId, parentId, isChapterLevel=false, isForStudents=false) => {
        isLoading = true
        let response = await api_handleApiCalls("folder.getChildren", {
            batchId,
            parentId,
            isChapterLevel,
            isForStudents,
            studentId
        })
        isLoading = false
        folderList = response.data;
        if(folderList.length == 0){ 
            noTopicPresent= true
        }
        else {
            isAllStudentMode = true
           let taughtChapterArr = folderList.filter(item => item.taught == true)
           if(taughtChapterArr.length) allChapterTaught = true
           else allChapterTaught = false
            noTopicPresent = false
        }
        await tick()
        feather.replace();
    }
    
    const onClickFolder = async (folderId) => {
        isAllStudentMode = true
        folderList = []
        bradCrumbArr.push(folderId)
        bradCrumbArr = bradCrumbArr
        await tick()
        let path = bradCrumbArr.join('-')
        navigateTo(path)
    }
    
    const navigateTo = (to) => {
        if(viewMode){
            navigate(`/dashboard/student/view/${studentId}/studyMonitoringChart/`+to)
        } else {
            navigate('/dashboard/studyMonitoring/'+to)
        }
    }
    
    const getProgressCount = (folderArr=[]) => {
        let total = folderArr.length
        let taughtArr = folderArr.filter(obj=>obj.taught)
        if(total == 0) return 0
        return ((taughtArr.length / total) * 100).toFixed()

    };

    const getStudentProgressCount = (chapter) => {
        if(chapter.totalTopicCount == 0) return 0
        return ((chapter.totalMarkCompletedCount / chapter.totalTopicCount) * 100).toFixed()
    };
    
    const getPercentage = (count, total) => {
        if(total == 0) return 0
        return ((count / total) * 100).toFixed()
    }

    const getLinkForBreadcrumbAddress = (bradCrumbData, idx) => { 
        let link = []
        for (let index = 0; index <= idx; index++) {
            const element = bradCrumbData[index];
            link.push(element._id)
        }

        return link.join('-')
     }

     const onClickMarkAsTaught =    async (id) => {
        if(noTopicPresent) return pError("Error", "Please add at least one topic")

         if(!id) return 
         markLoading= true
        let response = await api_handleApiCalls("studyMonitoring.markChapterTaught", { parentId:id})
        markLoading= false
        await tick()
        if(response.status) {
            pSuccess("Success!", response.data) 
             init()
        }
        else pError("Error!", response.errMsg)   
    }
</script>
<style>
    .fileManager-content {
        padding: 2rem;
        min-height: 100vh;
    }
    .fileManagerItem {
        background-color: #ffff;
        transition: background-color 0.3s all
    }
    .fileManagerItem:hover {
        background-color: #ecf3ff;
    }
    .custome-overlay    {
        width: 100%;
        height: 100%;
        position: absolute;
        text-align: center;
        transition: all 0.3s ease;
    }
    .center-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }
    .content-body {
        background-color: #f8f9fc;
    }
</style>
<div class="content-body pd-0">
    
    
    <div class="filemgr-container" style="position: relative;">

        <!-- <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div class="d-sm-flex align-items-center justify-content-between">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                            <li class="breadcrumb-item"><a href="/dashboard/studyMonitoring">Study Monitoring</a></li>
                            {#each bradCrumbArrWithName as folderName}
                                <li class="breadcrumb-item active" aria-current="page">{folderName}</li>
                            {:else}
                                <li class="breadcrumb-item active" aria-current="page">Batches</li>
                            {/each}
                        </ol>
                    </nav>
                    <h4 class="mg-b-0 tx-spacing--1">Study Monitoring</h4>
                </div>
    
            </div>
        </div> -->
        
        {#if isLoading}
            <div class="custome-overlay bg-white-9">
                <span>Looking for folders....</span>
            </div>
        {/if}
        <div class="filemanager-content">
            <div class="filemgr-content-header d-flex justify-content-between" style="position: relative; background-color: #ffff;">
                <!-- <h4 class="mg-b-0" style="width: 85%;">Batches</h4> -->
                <div class="folder-header">
                    <nav aria-label="breadcrumb " class="folder-breadcrumb">
                        <ol class="breadcrumb breadcrumb-style2 mg-b-0 b">
                            <li class="breadcrumb-item">
                                <a class="bc-link" use:links href={viewMode?`/dashboard/student/view/${studentId}/studyMonitoringChart`:'/dashboard/studyMonitoring'}> 
                                    <i data-feather="folder"></i>  
                                    All Batches
                                </a>
                            </li>
                            {#if aIdList.length}
                                {#each bradCrumbData as item, idx}
                                    <li class="breadcrumb-item">
                                        <a class="bc-link" use:links href={viewMode?`/dashboard/student/view/${studentId}/studyMonitoringChart/${getLinkForBreadcrumbAddress(bradCrumbData, idx)}`:`/dashboard/studyMonitoring/${getLinkForBreadcrumbAddress(bradCrumbData, idx)}`}> 
                                            <i data-feather="folder"></i>  
                                            {item.name}
                                        </a>
                                    </li>
                                {/each}
                            {/if}
                            {#if isLoading}
                                <li class="breadcrumb-item">
                                    <div class="spinner-border spinner-primary spinner-border-sm" role="status"></div>
                                </li>
                            {/if}
                        </ol>
                    </nav>
                </div>
                <div >
                    {#if !viewMode && chapterLevel}
                        <button on:click={showHideStudentListHandler} class="ml-5 btn btn-sm btn-primary"> {showStudents ? 'View All Topic Show' : 'All Students Progress'}</button>
                        <button disabled={markLoading } on:click={()=>{onClickMarkAsTaught(bradCrumbArr[bradCrumbArr.length-1])}} class="ml-2 btn btn-sm btn-primary">
                            <ButtonLoader {markLoading} btnText="Mark As Taught" />
                            
                          </button>
                    {:else if !viewMode && bradCrumbArr.length == 4 }    
                          {#if  isAllStudentMode}
                                <button   class="ml-5 btn btn-sm btn-primary" on:click={()=>isAllStudentMode = false}>See All Doubts</button>
                          {:else}

                          <button  class="ml-5 btn btn-sm btn-primary" on:click={()=>isAllStudentMode = true}>See All Students</button>
                            
                          {/if}
                    {/if}
                </div>
            </div>
            
            <div class="filemgr-content-body pd-5" style={isLoading?"position: relative; top: 0; opacity: 0.8":"position: relative; top: 0;"}>
                <div class="col-12 d-flex justify-content-end">
                    {#if !bradCrumbLink && !viewMode}
                    <form class="search-form mg-r-5 float-right"  on:submit|preventDefault={async (event)=>{
                        await loadData(0, event)} 
                      }
                        >
                        <input type="search" class="form-control wd-300 mg-y-40 " placeholder="Batch name " bind:value={search.value} 
                        on:input={async (event)=> {await loadData(0, event)}} 
                        />
                        <button class="btn mg-y-40" type="submit"><i data-feather="search" /></button>
                      </form>
                    {/if}
                </div>
                
               
                <!-- <div class="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15 mg-l-2">
                    
                        <div class="d-flex align-items-center">
                            <h5 class="tx-spacing--1">{bradCrumbArrWithName.length ? bradCrumbArrWithName[bradCrumbArrWithName.length-1]:'Batches' }</h5> 
                            {#if bradCrumbLink}
                                <div class="d-flex flex-row align-items-center ml-2" style="flex: 1;">
                                    <span>(25% completed)</span>
                                    <div class="progress ml-1 ht-5" style="flex: 1;">
                                        <div class="progress-bar wd-25p" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            {/if}
                        </div>
                </div> -->
                {#if !viewMode && chapterLevel && showStudents}
                    <StudentsListTable 
                        chapterName={bradCrumbArrWithName[bradCrumbArrWithName.length-1]}
                        tillFullPath={bradCrumbArr}
                        totalTopicCount={folderList.length}
                        batchId={bradCrumbArr[0]} 
                        parentId={bradCrumbArr[bradCrumbArr.length-1]} />
                {:else}
                
                    <div class="row row-xs pd-5 animated fadeIn">
                       
                        {#each batchList as item}
                        <div class="col-sm-6 col-lg-4 col-xl-3  mg-t-10 animated fadeIn">
                            <a href={viewMode?`/dashboard/student/view/${studentId}/studyMonitoringChart/${item._id}`:`/dashboard/studyMonitoring/${item._id}`} use:links class="media media-folder fileManagerItem">
                                <i data-feather="folder"></i>
                                <div class="media-body">
                                    <h6>{item.name}</h6>
                                    {#if !viewMode}
                                    <span>Folders: {item.subjectList && item.subjectList.length  ? item.subjectList.length : 0}</span>
                                    {:else}
                                    <span>Folders: {item.folderCount   ? item.folderCount : 0}</span>
                                    {/if}
                                    <!-- <span>Files: {item.fileCount? item.fileCount : 0}</span> -->
                                </div><!-- media-body -->
                            </a>
                        </div>
                        {:else }
                            {#if batchList.length == 0 && !bradCrumbLink  }
                                <div class="col-md-12">
                                    <div class="center-container">
                                        <p>No batch Available </p>
                                    </div>
                                </div>
                            {:else}
                            {#each folderList as item}
                                <div class={viewMode ? "col-sm-12 col-lg-6 col-xl-6  mg-t-10 animated fadeIn":"col-sm-6 col-lg-4 col-xl-3 animated fadeIn mg-t-10"}>
                                    <div class="qb-card" style="position: relative;">
                                        <div style="position: relative;" class="qb-list data animated" on:click={()=>onClickFolder(item._id)}>
                                            <div class="small" style="position: absolute;
                                                right: 8px;
                                                top: 8px;">
                                                    {#if item.taught && chapterLevel}
                                                        <div class="mt-1"><span><i data-feather="check-circle" class="text-success wd-15 ht-15"></i>  Taught </span></div>
                                                    {/if}
                                                </div>
                                            <div class="qb-icon">
                                                {#if item.icon}
                                                    <img src={item.icon} style="width: 100px; height: 100px; object-fit: contain;" alt="icon" />
                                                {:else}
                                                    <span class="icon folder"></span>
                                                {/if}
                                            </div>
                                            <div class="qb-text">
                                                <div>
                                                    <div>{item.name}</div>
                                                    {#if bradCrumbArr.length == 2}
                                                        {#if viewMode}
                                                            <ProgressBar percentage={getStudentProgressCount(item)} />
                                                        {:else}
                                                            <ProgressBar percentage={getProgressCount(item.topicFolders)} />
                                                        {/if}
                                                    {/if}
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <!-- {#if MODULES.hasAccess(MODULES.RENAME_QUESTION_BANK) || MODULES.hasAccess(MODULES.DELETE_QUESTION_BANK)}
                                        <div class="dropdown-file">
                                            <a href="#" class="dropdown-link" data-toggle="dropdown"><i data-feather="more-vertical"></i></a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                {#if MODULES.hasAccess(MODULES.RENAME_QUESTION_BANK)}
                                                    <a on:click="{() => { btnRenameFolder(item._id)}}" href="#" class="dropdown-item rename"><i data-feather="edit"></i>Rename</a>
                                                {/if}
                                                {#if MODULES.hasAccess(MODULES.DELETE_QUESTION_BANK)}
                                                    <a on:click="{() => { deleteFolderClickHandler(item._id)} }" href="#" class="dropdown-item delete"><i data-feather="trash"></i>Delete Folder</a>
                                                {/if}
                                                {#if MODULES.hasAccess(MODULES.DELETE_QUESTION_BANK)}
                                                    <a on:click="{() => { moveFolderClickHandler(item._id)} }" href="#" class="dropdown-item move-folder">
                                                        <i data-feather="move"></i>Move Folder
                                                    </a>
                                                {/if}
                                            </div>
                                        </div>
                                        {/if} -->
                                    </div>

                                    <!-- <div on:click={()=>onClickFolder(item._id)} class="media media-folder fileManagerItem pointer">
                                        {#if item.icon}
                                            <img src={item.icon} width="50" height="50" alt="icon" />
                                        {:else}
                                            <i data-feather="folder"></i>
                                        {/if}
                                        
                                        <div class="media-body">
                                            <h6>{item.name}</h6>
                                            {#if item.taught}
                                                <div class="mt-1"><span><i data-feather="check-circle" class="text-success wd-15 ht-15"></i>  Taught </span></div>
                                            {/if}
                                        </div>
                                    </div> -->
                                </div>
                                {:else}
                                {#if !isLoading}
                                    
                                    {#if bradCrumbArr.length < 4}
                                        <div class="col-md-12">
                                            <div class="center-container">
                                                <p>You can see student monitoring data only at topic level </p>
                                            </div>
                                        </div>
                                        {:else}
                                        {#if viewMode }
                                                <StudentDataView
                                                    batchId={bradCrumbArr[0]}
                                                    parentId={bradCrumbArr[bradCrumbArr.length-1]} 
                                                    studentId={studentId}
                                                />
                                            {:else if isAllStudentMode}
                                                <MonitoringZone 
                                                    tillFullPath={bradCrumbArr}
                                                    batchId={bradCrumbArr[0]}
                                                    parentId={bradCrumbArr[bradCrumbArr.length-1]} 
                                                />

                                            {:else if !isAllStudentMode}
                                                <AllDoubtsList  parentId={bradCrumbArr[bradCrumbArr.length-1]} tillFullPath={bradCrumbArr} />
                                            
                                        {/if}
                                        
                                    {/if}
                                {/if}
                            {/each}
                            {/if}
                        {/each}
                        
                        
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>
