<script>
    import { Router, links, Link, Route } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import { activeMenu, userData } from "../../stores";
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import ButtonLoader from "../../utils/button_loader.svelte"
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import MODULES from "../../utils/modules.js"
    import PreviousFeesReview from "./previousFeesReview.svelte";
    import CurrentFeesReview from "./currentFeesReview.svelte";
    import FinalFeesReview from "./finalFeesReview.svelte";
    export let _id
    export let isEdit
    export let studentId
    export let selectedBatchInfoId
    export let newBatchid;
    export let newAssign;
    let assignedBatchDetails = {}
    let breadcrumbStudName
    let backUrl = studentId ? ('/dashboard/student/view/' + studentId) : '/dashboard/students'
    let displayText
    displayText = selectedBatchInfoId ? 'Update Batch' : 'Assign Batch'
    let checkBoxTaxApplicable = "yes"
    let isLoading = false
    let discount = 0;
    let batchJoiningDateStr = "";
    let batchStartDateStr = "";
    let batchesList = [];
    let moduleSubjectList = [];
    let assignedBatchesList = [];
    let overRideRollNoChecked = false
    let studentAgreedFees = 0;
    let isRollNumberOveride = "no";
    let studentBatchObj = {
        joiningDate: "",
        startDate: "",
        batchFees: "",
        agreedFees: "",
        batchId: "",
        taxApplicable: "",
        feesIncludingTax: true,
        taxPercent: "",
        remarks: "",
        sequence: 0,
        prefix: "",
        moduleSubjects: [],
        totalPayableAmount: 0,
        counselor: ''
    }
    let isSortByName
    let selectedBatchInfo = {
        "amount": 0,
        "startDate": "",
        "taxPercent": 0,
        totalPayableAmount: 0
    }
    let errorObj = {};
    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    }
    let elemBatch
    let elemModuleSubj
    let elemSubjectsSelect
    let elemSelectCounselor
    let instanceSubjectsSelectize
    let instanceBatchSelectize
    let loggedInUserId

    const subscribeToStore = userData.subscribe(value => {
        loggedInUserId = value._id
    });

    function validationForPrefixSequence() {

        clearError("prefix");
        clearError("sequence")
        errorObj = {};
        if (!studentBatchObj.prefix) {
            errorObj.prefix = "Please enter batch prefix.";
        }
        if (!studentBatchObj.sequence) {
            errorObj.sequence = "Please enter batch sequence.";
        }
        if (studentBatchObj.sequence < 0 || errorObj.sequence < 1) {
            clearError("sequence");
            errorObj.sequence = "Please enter a valid sequence, it should not be a fractional number nor less than zero";
        }
        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;
    }

    function clearPrefixValidation() {
        clearError("prefix");
    }

    function clearSequenceValidation() {
        clearError("sequence");
    }


    async function getBatches(id) {
        let resData = await api_handleApiCalls('batch.getBatchesForStudent', { studentId: id })
        if (resData.status) {
            batchesList = resData.data
        } else {
            batchesList = []
        }
    }
    async function api_getStudentNameById(id) {
        var resData = await api_handleApiCalls('students.getStudentNameById', { _id: id })
        if (resData.status) {
            return resData.data.name
        } else {

            pError("Error", resData.errMsg)
            return '-'
        }
    }
    async function getStudentsBatchesList(id) {
        let resData = await api_handleApiCalls('students.getAssignedBatches', { _id: id })
        if (resData.status) {
            // console.log("THISS IS STUDENT BATCH LIST");
            assignedBatchesList = resData.data

            await tick()
        } else {
            // console.log("THISS IS STUDENT BATCH LIST");
            assignedBatchesList = []
            // overRideRollNoChecked = true
        }
    }


    async function getModuleSubjects(subjectIds) {
        try {
            console.log('Calling API with subjectIds:', subjectIds);
            const controller = new AbortController();
            const timeout = setTimeout(() => controller.abort(), 5000); // 5 seconds timeout

            let resData = await api_handleApiCalls(
                'categories.getModuleSubjectListBySubjectIds',
                { subjectIds: subjectIds },
                { signal: controller.signal, headers: { Authorization: `Bearer ${accessToken}` } }
            );

            clearTimeout(timeout);
            console.log('API Response:', resData);

            if (resData.status) {
                console.log('Data received:', resData.data);
                return resData.data;
            } else {
                console.warn('API call was unsuccessful. Status:', resData.status);
                return [];
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                console.error('Request timed out');
            } else {
                console.error('Error in getModuleSubjects:', error);
            }
            return [];
        }
    }




    async function saveAssignBatch(data) {
        isLoading = true;
        let resData = await api_handleApiCalls('students.assignBatch', data);
        isLoading = false;

        if (resData.status) {
            pSuccess("Success", resData.data);

            setTimeout(() => {
                window.history.back();
            }, 1000);

        } else {
            pError("Error", resData.errMsg)
        }
    }

    async function initSubjectsSelectize(batchId, selectedSubjectsArr = []) {

        let subjectsList = []

        if (batchId) {

            let fetchSubjectFrmBatch = batchId;

            for (let o = 0; o < batchesList.length; o++) {
                const aBatchList = batchesList[o];
                if (aBatchList._id == batchId && aBatchList.syncFoldersWith) {
                    fetchSubjectFrmBatch = aBatchList.syncFoldersWith;
                }
            }

            let subjectArrRes = await api_handleApiCalls('folder.getSubjectsForBatch', { batchId: fetchSubjectFrmBatch });
            if (subjectArrRes.status) {

                subjectsList = subjectArrRes.data;
            } else {
                subjectsList = []
            }
        }
        const allSelectedSubjectsArr = subjectsList.map(subject => {
            return subject._id
        })

        if (instanceSubjectsSelectize && instanceSubjectsSelectize[0].selectize) {
            instanceSubjectsSelectize[0].selectize.destroy();
        }

        instanceSubjectsSelectize = _$(elemSubjectsSelect).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: subjectsList,
        });

        if (selectedSubjectsArr.length) {

            instanceSubjectsSelectize[0].selectize.setValue(selectedSubjectsArr);

        } else {

            instanceSubjectsSelectize[0].selectize.setValue(allSelectedSubjectsArr);

        }

    }
    function initSelectizeSubjectsInstance(subjectsList = []) {
        instanceSubjectsSelectize = _$(elemSubjectsSelect).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: subjectsList,
        });
    }


    function vaildateBatchData(data = {}) {
        errorObj = {};
        var newErrorObj = {}

        if (!data.batchId || !Boolean(data.batchId)) {
            newErrorObj.batchId = "Please Select Batch";
        }

        if (data.isFree == 'no') {
            if (Boolean(data.agreedFees) || data.agreedFees == 0) {
                if (data.agreedFees < 0) {
                    newErrorObj.agreedFees = "Final fee amount cannot be negative"
                }
            } else {
                newErrorObj.agreedFees = "Please enter final fees amount";
            }
        }

        if (overRideRollNoChecked && assignedBatchesList.length == 0) {

            if (!studentBatchObj.prefix) newErrorObj.prefix = "Prefix is the mandatory fields"

            if (!studentBatchObj.sequence) newErrorObj.sequence = "Sequence is the mandatory fields"

        }

        if (data.moduleSubjects.length === 0) {

            newErrorObj.subjectId = "No subject found associated with this batch please assign some subject to it"

        } else {
            if (Array.isArray(data.moduleSubjects) && data.moduleSubjects.length === 0) {
                newErrorObj.moduleSubjects = "Please select at least one subject";
            }
        }

        // if()


        if (data.joiningDate < data.startDate) {
            newErrorObj.joiningDate = "Joining date should not be earlier than starting date";
        }
        if (!data.counselor) {
            newErrorObj.counselor = "Please select counselor"
        }
        if (discount || _id) {
            if (!data.remarks && !Boolean(data.remarks)) {
                newErrorObj.remarks = "Please fill out the remark";
            }
        }

        errorObj = newErrorObj;


        if (Object.keys(errorObj).length > 0) {
            pError("Error", Object.values(errorObj)[0])
            isLoading = false;
            return null;
        }

        return {
            joiningDate: data.joiningDate,
            startDate: data.startDate,
            batchFees: data.batchFees,
            agreedFees: data.agreedFees,
            batchId: data.batchId,
            taxApplicable: data.taxApplicable,
            feesIncludingTax: data.feesIncludingTax,
            taxPercent: data.taxPercent,
            remarks: data.remarks,
            moduleSubjects: data.moduleSubjects,
            totalPayableAmount: data.totalPayableAmount,
            syncFoldersWith: selectedBatchInfo.syncFoldersWith
        }
    }
    let addUpdateBatchBtn = () => {
        // let moduleSubIdList = _$(elemModuleSubj).select2("data").map(function (aSelectedValue) {
        //     return aSelectedValue.id;
        // });


        studentBatchObj.moduleSubjects = _$(instanceSubjectsSelectize).val();
        studentBatchObj.counselor = _$(elemSelectCounselor).val()
        studentBatchObj.joiningDate = convertDateStrToObj(batchJoiningDateStr);
        studentBatchObj.startDate = convertDateStrToObj(batchStartDateStr);

        studentBatchObj.studentId = studentId
        studentBatchObj.selectedBatchInfoId = selectedBatchInfoId
        var bData = vaildateBatchData(studentBatchObj);

        if (isRollNumberOveride == "no" || isRollNumberOveride.length === 0 && assignedBatchesList.length) {
            delete studentBatchObj.prefix;
            delete studentBatchObj.sequence;
        }

        if (newAssign) studentBatchObj.newAssign = newAssign;

        if (bData) {

            studentBatchObj.syncFoldersWith = bData.syncFoldersWith;

            if (_id) studentBatchObj._id = _id
            saveAssignBatch(studentBatchObj);
        }

    }

    let agreedFeesChanged = async (event) => {
        studentBatchObj.agreedFees = Number(event.detail.agreedFees || 0)
        // console.log("new agreed fees", studentBatchObj.agreedFees)
        new_updateNetTotalAmount()
    }
    let radioClickHandler = async (e) => {
        checkBoxTaxApplicable = e.detail.checkBoxTaxApplicable
        // console.log("new check box tax applicatble", checkBoxTaxApplicable)
        studentBatchObj.feesIncludingTax = (!checkBoxTaxApplicable == 'yes')

        await tick()
        if (checkBoxTaxApplicable == "yes") {
            studentBatchObj.agreedFees = selectedBatchInfo.totalPayableAmount;

        } else {
            studentBatchObj.agreedFees = selectedBatchInfo.amount;
        }

        new_updateNetTotalAmount()
    }

    let convertToINR = function (input) {
        let amount = Number(input);
        if (Number.isNaN(amount)) { amount = 0 }
        return amount.toLocaleString('en-IN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'INR'
        });
    }
    let updateNetTotalAmount = async () => {
        clearError("agreedFees");
        let totalAmount = 0;
        if (studentBatchObj.agreedFees) {
            totalAmount += Number(studentBatchObj.agreedFees);
        }
        if (selectedBatchInfo.taxApplicable) {

            if (!studentBatchObj.feesIncludingTax) {
                var taxPercent = (selectedBatchInfo.taxPercent ? selectedBatchInfo.taxPercent : 0);
                totalAmount += Number(((studentBatchObj.agreedFees * taxPercent) / 100));
            }

        }


        discount = (Number(selectedBatchInfo.totalPayableAmount) - totalAmount)
        studentBatchObj.totalPayableAmount = totalAmount

        await tick()

    }

    let new_updateNetTotalAmount = async (isAggreedFees) => {
        let myDiscount = 0;

        if (checkBoxTaxApplicable == "yes") {
            myDiscount = Number(selectedBatchInfo.totalPayableAmount) - Number(studentBatchObj.agreedFees);
        } else {
            myDiscount = selectedBatchInfo.amount - studentBatchObj.agreedFees;

        }

        discount = myDiscount;
        discount = discount;
        studentBatchObj.totalPayableAmount = studentBatchObj.agreedFees
    }

    let update

    async function getSubjectForBatch(batchId) {

        let fetchSubjectFrmBatch = batchId;

        for (let o = 0; o < batchesList.length; o++) {
            const aBatchList = batchesList[o];
            if (aBatchList._id == batchId && aBatchList.syncFoldersWith) {
                fetchSubjectFrmBatch = aBatchList.syncFoldersWith;
            }
        }


        let subjectArr = await api_handleApiCalls('folder.getSubjectsForBatch', { batchId: fetchSubjectFrmBatch });
        moduleSubjectList = subjectArr.data;

        if (!Boolean(moduleSubjectList.length)) {
            errorObj.subjectId = "No subject found associated with this batch please assign some subject to it"
        } else {
            errorObj.subjectId = false
        }
        if (elemModuleSubj) { _$(elemModuleSubj).select2("destroy"); }
        _$(elemModuleSubj).html("");
        await tick();
        _$(elemModuleSubj).select2({
            minimumResultsForSearch: Infinity, placeholder: "Select Module / Subject",
            disable: false,
            data: moduleSubjectList.map(function (aSubject) {
                return {
                    id: aSubject._id,
                    text: aSubject.name
                }
            })
        });


    }


    async function excludeModuleIdsAndGetModuleSubjectList(modulesSubjects) {
        let _subjectIds = modulesSubjects ? modulesSubjects.map(element => element.subjectId) : [];
        let moduleSubjectList = await getModuleSubjects(_subjectIds);

        if (!moduleSubjectList.length) {
            errorObj.subjectId = "No subject found associated with this batch, please assign some subjects to it";
        } else {
            errorObj.subjectId = false;
        }

        if (elemModuleSubj) {
            _$(elemModuleSubj).select2("destroy");
        }

        _$(elemModuleSubj).html("");
        await tick();

        _$(elemModuleSubj).select2({
            minimumResultsForSearch: Infinity,
            placeholder: "Select Module / Subject",
            data: moduleSubjectList.map(aModule => ({
                id: `${aModule.moduleId}_${aModule.subjectId}`,
                text: aModule.moduleSubjStr,
                selected: (aModule.subjectId == studentBatchObj.subjectId)
            }))
        });
    }

    // Example usage
    // const moduleSubjectList = await getModuleSubjects([]);
    console.log('Module subjects retrieved:', moduleSubjectList);

    function convertDateObjToStr(date) {

        var month = '' + (date.getMonth() + 1);
        var day = '' + date.getDate();
        var year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var dateString = [year, month, day].join('-');
        return dateString;
    }
    function convertDateStrToObj(selectedDate) {
        selectedDate = selectedDate.split("-");
        var year = selectedDate[0];
        var month = selectedDate[1] - 1;
        var day = selectedDate[2];
        return new Date(year, month, day);
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');

    }
    async function getAssignedBatchById(data = { isSortByName: true }) {

        var resData = await api_handleApiCalls('students.getAssignedBatchById', data)
        if (resData.status) {
            return resData.data
        } else {

            pError("Error", resData.errMsg)
            return '-'
        }
    }
    let batchSelectOnChange = async (selectedBatchId) => {

        if (assignedBatchesList.length == 0 && selectedBatchId && !selectedBatchInfoId) {

            isRollNumberOveride = 'yes'
            overRideRollNoChecked = true
        } else {

            isRollNumberOveride = 'no'
            overRideRollNoChecked = false
        }

        let batchId = selectedBatchId
        if (assignedBatchDetails && assignedBatchDetails.batchId == batchId) {
            setAssignedBatchData(assignedBatchDetails)

            return
        }


        let filteredArr = batchesList.filter(x => x._id == batchId);
        let batch = filteredArr[0]


        selectedBatchInfo = {}
        discount = 0

        if (batch) {
            studentBatchObj.batchId = batchId
            studentBatchObj.agreedFees = batch.totalPayableAmount
            studentBatchObj.batchFees = batch.totalPayableAmount
            studentBatchObj.feesIncludingTax = batch.feesIncludingTax
            checkBoxTaxApplicable = batch.feesIncludingTax ? 'no' : 'yes'
            studentBatchObj.taxApplicable = batch.taxApplicable
            studentBatchObj.taxPercent = batch.taxPercent
            studentBatchObj.isFree = batch.isFree;
            studentBatchObj.prefix = batch.prefix ? batch.prefix : assignedBatchDetails.batch ? assignedBatchDetails.batch.prefix : "";
            studentBatchObj.sequence = batch.sequence ? batch.sequence : assignedBatchDetails.batch ? assignedBatchDetails.batch.sequence : "";
            batchStartDateStr = convertDateObjToStr(new Date(batch.startDate))
            batchJoiningDateStr = batchStartDateStr
            selectedBatchInfo = batch
            new_updateNetTotalAmount();

            initSubjectsSelectize(batchId);
            // excludeModuleIdsAndGetModuleSubjectList(batch.moduleSubjects)
            await tick()
        } else {
            selectedBatchInfo = {
                "amount": 0,
                "startDate": "",
                "taxPercent": 0,
                totalPayableAmount: 0
            }
            studentBatchObj = {
                joiningDate: "",
                startDate: "",
                batchFees: "",
                agreedFees: "",
                batchId: "",
                taxApplicable: "",
                feesIncludingTax: true,
                taxPercent: 0,
                totalPayableAmount: 0,
                moduleSubjects: [],
            }
            moduleSubjectList = []
            updateNetTotalAmount()
            await tick()
        }


    }

    async function bindCounselor(selectedId) {
        if (!selectedId) selectedId = loggedInUserId

        let counselorUserList = []
        var resData = await api_handleApiCalls('systemUser.fetchSystemUserList', { noPagination: true })
        if (resData.status) counselorUserList = resData.data.data
        await tick()
        _$(elemSelectCounselor).selectize({
            preload: true,
            maxItems: 1,
            placeholder: 'Select counselor',
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            items: [selectedId],
            options: counselorUserList,
        });
    }
    function bindEvents() {



        instanceBatchSelectize = _$(elemBatch).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            persist: false,
            options: batchesList,
            render: {
                option_create: function (data, escape) {
                    return (
                        '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
                        escape(data.input) +
                        "</strong></div>"
                    );
                },
            },
            onChange: batchSelectOnChange
        });





        if (newAssign) {

            setTimeout(function () {
                instanceBatchSelectize[0].selectize.setValue(newBatchid);
            }, 500);

        }
    }

    async function setAssignedBatchData(data) {

        batchStartDateStr = convertDateObjToStr(new Date(data.startDate))
        batchJoiningDateStr = convertDateObjToStr(new Date(data.joiningDate))

        studentBatchObj.remarks = data.remarks
        studentBatchObj.batchId = data.batchId
        studentBatchObj.agreedFees = data.agreedFees
        studentBatchObj.batchFees = data.batchFees
        studentBatchObj.totalPayableAmount = data.agreedFees
        studentBatchObj.feesIncludingTax = data.feesIncludingTax
        studentBatchObj.taxApplicable = data.taxApplicable
        studentBatchObj.taxPercent = data.taxPercent
        studentBatchObj.isFree = data.batch.isFree
        selectedBatchInfo = data
        selectedBatchInfo.startDate = data.startDate
        selectedBatchInfo.totalPayableAmount = data.batchFees
        selectedBatchInfo.amount = data.batchFees
        selectedBatchInfo.taxPercent = data.taxPercent

        checkBoxTaxApplicable = data.feesIncludingTax ? 'no' : 'yes'
        new_updateNetTotalAmount();
        // console.log("studentBatchObj:::",studentBatchObj)
        initSubjectsSelectize(data.batchId, data.moduleSubjects)
        await tick()
    }



    onMount(async () => {
        try {
            console.log('Setting active menu to students');
            activeMenu.set('students');
            subscribeToStore();

            console.log('Getting breadcrumb student name');
            breadcrumbStudName = await api_getStudentNameById(studentId);
            console.log('Breadcrumb student name:', breadcrumbStudName);

            await tick();
            console.log('Tick completed');

            console.log('Getting batches');
            await getBatches(studentId);
            console.log('Batches retrieved');

            console.log('Getting module subjects');
            const moduleSubjectList = await getModuleSubjects([]);
            console.log('Module subjects retrieved:', moduleSubjectList);

            console.log('Getting student batches list');
            await getStudentsBatchesList(studentId);
            console.log('Student batches list retrieved');

            console.log('Initializing selectize subjects instance');
            initSelectizeSubjectsInstance();
            feather.replace({ width: "1em", height: "1em" });

            if (_id) {
                console.log('Fetching assigned batch details');
                displayText = 'Update assign batch details';
                assignedBatchDetails = await getAssignedBatchById({ _id });

                console.log('Assigned batch details:', assignedBatchDetails);
                batchesList.push({
                    _id: assignedBatchDetails.batch._id,
                    name: assignedBatchDetails.batch.name,
                    syncFoldersWith: assignedBatchDetails.syncFoldersWith
                });
                batchesList = batchesList;
                studentBatchObj.prefix = assignedBatchDetails.batch.prefix;
                studentBatchObj.sequence = assignedBatchDetails.batch.sequence;

                bindEvents();
                setAssignedBatchData(assignedBatchDetails);

                if (instanceBatchSelectize) {
                    instanceBatchSelectize[0].selectize.setValue(assignedBatchDetails.batchId);
                }

                bindCounselor(assignedBatchDetails.counselor);
            } else {
                bindEvents();
                bindCounselor();
            }

            console.log('onMount completed successfully');
        } catch (error) {
            console.error('Error during onMount:', error);
        }
    });




    // function handleDateKeydown(){
    //     return false;
    // }
</script>

<style>

</style>

<div class="content-body fadeIn">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/students">Students</a></li>
                    <li class="breadcrumb-item"><a href={backUrl}>{breadcrumbStudName ? breadcrumbStudName: '-'}</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {displayText}
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                Assign Batch Details
            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
                {_id ? 'Edit' : ''} assign batch to {breadcrumbStudName ? breadcrumbStudName: '-'}
            </p>
        </div>
        <div class="d-block">
            {#if MODULES.hasAccess(MODULES.EDIT_BATCH)}
            <button on:click={addUpdateBatchBtn} disabled={isLoading}
                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="save"
                    class="wd-10 mg-r-5"></i>
                <ButtonLoader isLoading={isLoading} btnText={displayText} />

            </button>
            {/if}
        </div>

    </div>
    <hr class="full-width" />
    <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div class="row row-sm mg-b-10">
            <div class="col-sm-12">
                <div class="form-row">
                    <div class="col-md-6">
                        <div class="form-group col-md-12" on:click={()=>clearError("batchId")}
                            class:parsley-errors-select-custom={errorObj.batchId}>
                            <label class="d-block">Batch<span class="required-field">*</span></label>
                            <select bind:this={elemBatch} class="info selectized form-control"
                                disabled={Boolean(newAssign)} placeholder="Select Batch">
                            </select>
                            {#if errorObj.batchId}
                            <div class="parsley-errors-list">{errorObj.batchId}</div>
                            <!-- content here -->
                            {/if}
                        </div>
                        <div class="form-group col-sm-12">
                            <label class="d-block">Subject</label>
                            <select bind:this={elemSubjectsSelect} class="info slectizedd form-control"
                                placeholder="Select Subject">
                                <option value="">Select Subject</option>
                            </select>
                            {#if errorObj.subjectId}
                            <div class="parsley-errors-list">{errorObj.subjectId}</div>
                            <!-- content here -->
                            {/if}
                        </div>

                        <!-- <div class="form-group col-md-12" on:click={()=>clearError("subjectId")} class:parsley-errors-select-custom={errorObj.subjectId}>
                                <label class="d-block">Subject<span class="required-field">*</span></label>
                                    <select bind:this={elemModuleSubj} disabled={(!Boolean(moduleSubjectList.length))} multiple class="custom-select">
                                    </select>
                                    
                            </div> -->
                        <div class="form-group hasDatepicker col-md-12">
                            {#if studentBatchObj.batchId != ''}
                            <label class="d-block">Batch Start Date</label>
                            <input type="date" disabled value={batchStartDateStr} class="form-control"
                                placeholder="Batch Start Date" />
                            {/if}
                        </div>
                        <div class="form-group hasDatepicker col-md-12">
                            <label class="d-block">Student Joining Date<span class="required-field">*</span></label>
                            <input type="date" onkeydown="return false" min={batchStartDateStr}
                                bind:value={batchJoiningDateStr} class="form-control"
                                placeholder="Student Joining Date" />
                            {#if errorObj.joiningDate}
                            <div class="parsley-errors-list">{errorObj.joiningDate}</div>
                            <!-- content here -->
                            {/if}

                        </div>

                        <div class="form-group col-md-12">
                            <label class="d-block">Assign Counselor<span class="required-field">*</span></label>
                            <select bind:this={elemSelectCounselor}>
                            </select>
                            {#if errorObj.counselor}
                            <div class="parsley-errors-list">{errorObj.counselor}</div>
                            <!-- content here -->
                            {/if}
                        </div>

                        <!-- {#if assignedBatchesList.length} -->
                        <!-- content here -->
                        <div class="form-group row col-md-12">
                            <label class="col-form-label col-md-6">Assign roll number: </label>
                            <div class="col-sm-6 d-sm-flex align-items-center justify-content-between ">
                                <div class="d-sm-flex">
                                    <div class="custom-control custom-checkbox">
                                        <input disabled={(studentBatchObj.batchId=="" || (isRollNumberOveride=='yes' &&
                                            assignedBatchesList.length==0) )} bind:group={isRollNumberOveride}
                                            bind:checked={overRideRollNoChecked} type="checkbox" id="oRadio"
                                            class="custom-control-input" value="yes">
                                        <label class="custom-control-label" for="oRadio">Yes</label>
                                    </div>
                                </div>
                                <!-- <label class="col-form-label col-md-6">(CAUTION : this Override the current roll number)</label> -->
                            </div>
                        </div>
                        <!-- {#if (isRollNumberOveride == "yes")} -->
                        <!-- content here -->
                        <div class:d-none={(isRollNumberOveride==="no" ) } class="form-group col-md-12">
                            <div class="form-row">
                                <div id="rollPrefix" class="form-group col-md-6">
                                    <label class="d-block">Roll Number Prefix<span
                                            class="required-field">*</span></label>
                                    <input type="text" bind:value={studentBatchObj.prefix} class="form-control "
                                        on:focus={clearPrefixValidation} class:parsley-error={errorObj.prefix}
                                        placeholder="Please Enter Prefix" disabled={studentBatchObj.prefix} />
                                    {#if errorObj.prefix}
                                    <div class="parsley-errors-list">{errorObj.prefix}</div>
                                    {/if}
                                </div>
                                <div id="rollSeq" class="form-group col-md-6">
                                    <label class="d-block">Roll Number Sequence<span
                                            class="required-field">*</span></label>
                                    <input type="number" bind:value={studentBatchObj.sequence} class="form-control "
                                        on:focus={clearSequenceValidation} class:parsley-error={errorObj.sequence}
                                        placeholder="Please Enter Sequence" />
                                    {#if errorObj.sequence}
                                    <div class="parsley-errors-list">{errorObj.sequence}</div>
                                    {/if}
                                </div>
                            </div>
                        </div>
                        <!-- {/if}
                        {/if} -->



                        <div class="form-group col-md-12">
                            <label class="d-block">Remarks
                                {#if (discount || _id)}
                                <span class="required-field">*</span>
                                {/if}
                                :</label>
                            <textarea class="form-control" bind:value={studentBatchObj.remarks} rows="6"
                                placeholder="Remarks"></textarea>
                            {#if errorObj.remarks}
                            <div class="parsley-errors-list">{errorObj.remarks}</div>
                            <!-- content here -->
                            {/if}
                        </div>





                    </div>
                    <div class="col-md-6 pd-l-15 pd-r-15">
                        <PreviousFeesReview data={assignedBatchesList} />
                        <CurrentFeesReview isEdit={isEdit} data={{...studentBatchObj, batch: selectedBatchInfo}}
                            on:checkBoxTaxApplicable={radioClickHandler} on:agreedFeesChange={agreedFeesChanged} />
                        <FinalFeesReview previousData={[...assignedBatchesList]} currentData={{...studentBatchObj,
                            batch: selectedBatchInfo}} />
                    </div>



                </div>
            </div>
        </div>

    </div>
    <div class="d-sm-flex align-items-center justify-content-end ">
        <div class="d-block">
            <button on:click={addUpdateBatchBtn} disabled={isLoading}
                class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="save"
                    class="wd-10 mg-r-5"></i>
                <ButtonLoader isLoading={isLoading} btnText={displayText} />

            </button>
        </div>
    </div>

</div>
