<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import { activeMenu } from "../../stores";
    import ViewCounts from "./counts-card.svelte";
    import ButtonLoader from "../../utils/button_loader.svelte";
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import { userData } from "../../stores";
    import MODULES from "../../utils/modules.js";
    import Forbidden from "../../forbidden.svelte";
    import ExcelJS from 'exceljs'

    import { API_URL } from "../../../config.js"

    let localUserData = {
        fullName: "...",
        role: "...",
        avatar: "/images/man.png",
        access: {
            home: false,
            students: false,
            settings: false,
        },
    };

    const unsubscribe = userData.subscribe((value) => {
        localUserData = value;
    });

    let elemSelectClass;
    let elemSelectClass1;

    let classId;
    let selectizeBatchInstanceForAdd;
    let filterElemBatcforAdd;
    let selectizeInstanceClass;
    let selectizeInstanceClass1;
    let demoDataTable;
    let enquiryDatatable;
    let elemOverdueFollowupTable;
    let overdueFolloupsDatatable;
    let overdueDatatableEmptyState;
    let modalArchiveEnquiry,
        modalArchiveEnquiryTitle,
        modalArchiveEnquirySubTitle,
        modalArchiveEnquiryProceed = () => { },
        modalArchiveEnquiryBtnText = "",
        modalArchiveEnquiryIcon = "archive";
    let elemTodaysFollowupTable;
    let elemTodaysDatatable;
    let todaysDatatableEmptyState;
    let showEnquiryDeleteModal = false;
    let isLoadingSaveAndAdd = false;
    let isLoadingSaveAndProceed = false;
    let filterModalThis;
    let selectizeDemoBatches;
    let archiveEnquiryId;
    let addDemoBatch = true;
    let elemDemoBatchesSelect;
    let name, mobileno, batch, classes, referredBy;
    let errorObj = {};
    let convertEnquiryId;
    let isLoading = false;
    let emptyState = false;
    let enquiryDataList = []
    let TOTAL_ENQUIRIES = 0;
    let TOTAL_FOLLOW_UPS = 0;
    let FOLLOW_UPS_OVERDUE = 0;
    let HOT_AND_PENDING = 0;
    let CONVERTED = 0;

    let elemBtnFilterBy;
    let filterElemBatch;
    let filterElemBatch1;
    let filterElemCounselor;
    let filterElemUser;
    let filterBy = "Today";
    let filterFlag = "T";
    let filterFollowUpDate = "";
    let filterFollowUpDate1 = "";
    let filterFollowUpTime;
    let elem_nextFolloUpTimepicker;
    let isSortByName;
    let selectizeCounselorInstance;
    let selectizeBatchInstance;
    let selectizeBatchInstance1;
    let selectizeUserlorInstance;
    let noPagination;

    let todaysDate = formatDate(new Date());
    let batchesList = [];
    let userList = [];
    let counselorUserList = [];
    let filterStatus = "";
    let selectedBatches = [];
    let selectedClasses = [];
    let _errorObj = {};
    let exportLoading = false;
    let allSelectedEnquiries = new Set()
    let deleteEnquiryObj = {
        _id: null,
        name: "",
        mobileNo: "",
        referredBy: "",
    };
    let enquiryObj = {
        _id: null,
        name: "",
        mobileNo: "",
        batch: "",
        classes: "",
        referredBy: "",
    };
    const _enquiryMode = () => {
        const DEFAULT_MODE = "DEFAULT";
        const ARCHIVED_MODE = "ARCHIVED";
        let _mode = DEFAULT_MODE;
        const toggleMode = () => {
            if (_mode == DEFAULT_MODE) _mode = ARCHIVED_MODE;
            else _mode = DEFAULT_MODE;
        };
        const isDefaultMode = () => _mode == DEFAULT_MODE;
        const isArchivedMode = () => _mode == ARCHIVED_MODE;
        return {
            toggleMode,
            isDefaultMode,
            isArchivedMode,
        };
    };
    let { toggleMode, isDefaultMode, isArchivedMode } = _enquiryMode();
    let isEnquiryModeDefault = true;
    let changeEnquiryListMode = () => {
        toggleMode();
        isEnquiryModeDefault = Boolean(isDefaultMode());
        if (!isEnquiryModeDefault) navigate("/dashboard/enquiries/archived");
        // redrawEnquiryTable()
    };

    let showToast = (option) => {
        _$(".toast-header").html(option.title);
        _$(".toast-body").html(option.message);
        _$(".toast").toast("show");
    };

    async function showDeleteEnquiryModal(action) {
        action
            ? _$(modalArchiveEnquiry).modal("show")
            : _$(modalArchiveEnquiry).modal("hide");
    }

    var handleArchiveBtnClick = async (id) => {
        deleteEnquiryObj = {};
        let aEnquiry = await getEnquiryNameId(id);

        if (aEnquiry) {
            if (id === aEnquiry._id) {
                deleteEnquiryObj = aEnquiry;
                archiveEnquiryId = aEnquiry._id;
                modalArchiveEnquiryBtnText = "Archive";
                modalArchiveEnquiryIcon = "archive";
                modalArchiveEnquiryTitle = "Archive enquiry";
                modalArchiveEnquirySubTitle =
                    "Are you sure you want to archive this enquiry?";
                modalArchiveEnquiryProceed = proccedToArchiveEnquiry;
                showDeleteEnquiryModal(true);
                // feather.replace()
            }
        }

        await tick();
    };

    var handleDeleteBtnClick = async (id) => {
        deleteEnquiryObj = {};
        let aEnquiry = await getEnquiryNameId(id);

        if (aEnquiry) {
            if (id === aEnquiry._id) {
                deleteEnquiryObj = aEnquiry;
                archiveEnquiryId = aEnquiry._id;
                modalArchiveEnquiryBtnText = "Delete";
                modalArchiveEnquiryIcon = "trash";
                modalArchiveEnquiryTitle = "Delete enquiry";
                modalArchiveEnquirySubTitle =
                    "Are you sure you want to delete this enquiry?";
                modalArchiveEnquiryProceed = proccedToDeleteEnquiry;
                showDeleteEnquiryModal(true);
                // feather.replace()
            }
        }

        await tick();
    };

    var handleRestoreBtnClick = async (id) => {
        deleteEnquiryObj = {};
        let aEnquiry = await getEnquiryNameId(id);

        if (aEnquiry) {
            if (id === aEnquiry._id) {
                deleteEnquiryObj = aEnquiry;
                archiveEnquiryId = aEnquiry._id;
                modalArchiveEnquiryBtnText = "Restore";
                modalArchiveEnquiryIcon = "upload";
                modalArchiveEnquiryTitle = "Restore enquiry";
                modalArchiveEnquirySubTitle =
                    "Are you sure you want to restore this enquiry?";
                modalArchiveEnquiryProceed = proccedToRestoreEnquiry;
                showDeleteEnquiryModal(true);
                // feather.replace()
            }
        }

        await tick();
    };

    async function api_createNewEnquiry(data, proceed) {
        if (proceed) {
            isLoadingSaveAndProceed = true;
        } else {
            isLoadingSaveAndAdd = true;
        }

        var response = await api_handleApiCalls("enquiries.create", data);

        if (response.status) {
            if (proceed) {
                _$("#modal-new-enquiry").modal("hide");
                navigate("enquiries/" + response.data._id);
                isLoadingSaveAndProceed = false;
                clearFormData();
            }
            pSuccess("Success!", "Enquiry created Successfully");
            isLoadingSaveAndAdd = false;
            clearFormData();
            redrawEnquiryTable();
        } else {
            errorObj.apiError = response.errMsg;
            isLoadingSaveAndAdd = false;
            isLoadingSaveAndProceed = false;
        }
    }
    async function api_convertStudent(data) {
        var response = await api_handleApiCalls("students.create", data);

        if (response.status) {
            if (response.data.available) {
                pError("Error!", "Duplicate Enquiry Entry Found");
            } else {
                _$("#modal-convert-enquiry").modal("hide");
                pSuccess("Success!", "Enquiry Converted Successfully");
                navigate("/dashboard/student/view/" + response.data._id);
            }
        } else {
            _errorObj.apiError = response.errMsg;
        }
    }
    async function api_deleteEnquiry(data) {
        var response = await api_handleApiCalls("enquiries.delete", data);

        if (response.status) {
            showToast({ title: "Success", message: resData.data });
            redrawEnquiryTable();
            redrawFollowupsTable();
        } else {
            showToast({ title: "Error", message: resData.errMsg });
        }
    }
    async function api_getCounts(data) {
        var response = await api_handleApiCalls("enquiries.counts", data);

        if (response.status) {
            var count = response.data;
            TOTAL_ENQUIRIES = count.totalEnquiries;
            TOTAL_FOLLOW_UPS = count.totalFollowups;
            FOLLOW_UPS_OVERDUE = count.totalOverdueFollowups;
            HOT_AND_PENDING = count.totalHotPending;
            CONVERTED = count.totalConverted;
        } else {
            TOTAL_ENQUIRIES = 0;
            TOTAL_FOLLOW_UPS = 0;
            FOLLOW_UPS_OVERDUE = 0;
            HOT_AND_PENDING = 0;
            CONVERTED = 0;
        }
    }
    async function api_getBatchesList() {
        var resData = await api_handleApiCalls("batch.getMyBatches", {
            isSortByName: true,
        });
        if (resData.status) {
            return resData.data;
        } else {
            return [];
        }
    }

    async function api_getClassList(data) {
        var resData = await api_handleApiCalls(
            "instituteClass.fetchInstituteClasses",
            data
        );
        if (resData.status) {
            return resData.data;
        } else {
            return [];
        }
    }

    async function api_getCounselorUsers() {
        var resData = await api_handleApiCalls(
            "systemUser.fetchSystemUserList",
            { noPagination: true }
        );
        if (resData.status) {
            return resData.data.data;
        } else {
            return [];
        }
    }
    async function getEnquiryValues() {
        var newErrorObj = {};

        const MOBILENORGEX = RegExp(/^[6-9][0-9]{9}$/);
        const NAMEREGEX = RegExp(/^[a-zA-Z ]+$/);
        let batchesData = _$(selectizeBatchInstanceForAdd).val();
        let batchClass = _$(elemSelectClass).val();
        if (!Boolean(name)) {
            newErrorObj.name = "Full name is required.";
        } else {
            if (!NAMEREGEX.test(name)) {
                newErrorObj.name = "Enter Valid Full Name";
            }
        }

        if (!Boolean(mobileno)) {
            newErrorObj.mobileno = "Mobile number is required.";
        } else {
            if (!MOBILENORGEX.test(mobileno)) {
                newErrorObj.mobileno =
                    "Please Enter 10 Digit Valid Mobile Number";
            }
        }
        if (!Boolean(batchClass)) {
            newErrorObj.batchClass = "Please select the class";
        }
        if (Boolean(referredBy)) {
            if (!NAMEREGEX.test(referredBy)) {
                newErrorObj.referredBy = "Enter Valid Name";
            }
        }
        errorObj = newErrorObj;
        if (Object.keys(errorObj).length > 0) {
            return null;
        }
        return {
            name: name,
            mobileNo: mobileno,
            batches: batchesData,
            batchClass: batchClass,
            referredBy: referredBy,
        };
    }
    async function getValidateEnquiryData(data) {
        var newErrorObj = {};
        var MOBILENORGEX = /^\d{10}$/;
        if (!data.name && !Boolean(data.name)) {
            newErrorObj.name = "Please enter full name.";
        }

        if (!data.mobileNo || !MOBILENORGEX.test(data.mobileNo)) {
            newErrorObj.mobileNo = "Please enter 10 digit mobile number";
        }

        if (!data._id || !Boolean(data._id)) {
            newErrorObj.apiError = "Enquiry id not found, please try again";
        }

        let demoBatchId = addDemoBatch
            ? _$(selectizeBatchInstance1).val()
            : null;

        if (addDemoBatch && !demoBatchId) {
            newErrorObj.demoBatchId = "Select default demo batch";
        }

        _errorObj = newErrorObj;
        if (Object.keys(errorObj).length > 0) {
            return null;
        }
        return {
            name: data.name,
            mobileNo: data.mobileNo,
            enquiryId: data._id,
            demoBatchId,
            classId: classId,
            referredBy: referredBy,
        };
    }
    async function getEnquiryById(id) {
        var resData = await api_handleApiCalls("enquiries.getEnquiryById", {
            _id: id,
        });

        if (resData.status) {
            return resData.data;
        } else {
            return {
                id: null,
                name: "",
                mobileNo: "",
                referredBy: "",
            };
            _errorObj.apiError = resData.errMsg;
        }
    }

    let getDemoBatches = async function (classId) {
        var response = await api_handleApiCalls("batch.getDemoBatches", {
            batchClass: classId,
        });

        if (response.status) {
            return response.data;
        } else {
            return [];
        }
    };

    async function getEnquiryNameId(id) {
        var resData = await api_handleApiCalls("enquiries.getEnquiryNameById", {
            _id: id,
        });
        if (resData.status) {
            return resData.data;
        } else {
            pError("Error!", resData.errMsg);
            return null;
        }
    }

    function concatDateAndTime(date, time) {
        try {
            let thisDate = new Date(date);
            let thisTime = new Date(time);

            thisDate.setHours(thisTime.getHours(), thisTime.getMinutes(), 0, 0);

            return thisDate;
        } catch (error) {
            return null;
        }
    }
    function getFilterData() {
        let counselor = _$(selectizeCounselorInstance).val();
        let createdBy = _$(selectizeUserlorInstance).val();
        let batches = _$(selectizeBatchInstance).val();
        let batchClass = _$(selectizeInstanceClass).val();
        let status = filterStatus;
        // let followupFilterDateAndTime = concatDateAndTime(filterFollowUpDate, filterFollowUpTime)
        // console.error("followupFilterDateAndTime >>>>>>>>>>>>>>>>> ", followupFilterDateAndTime);

        // if(Boolean(followupFilterDateAndTime) && followupFilterDateAndTime.toString().toLowerCase() == 'invalid date')
        //     followupFilterDateAndTime = ''
        let followUpDate = filterFollowUpDate;
        let nextFollowupDate = filterFollowUpDate1;
        // if(isArchivedMode()) var isArchived = true
        return {
            counselor,
            createdBy,
            batches,
            batchClass,
            status,
            followUpDate,
            nextFollowupDate,
            // isArchived
        };
    }
    function redrawEnquiryTable() {
        getCounts();
        if (enquiryDatatable) {
            enquiryDatatable.draw();
        }
    }
    function redrawFollowupsTable() {
        if (overdueFolloupsDatatable) {
            overdueFolloupsDatatable.draw();
        }
        if (elemTodaysDatatable) {
            elemTodaysDatatable.draw();
        }
    }
    var handleClickProceed = async (e) => {
        var enquiryData = await getEnquiryValues();
        if (enquiryData) {
            api_createNewEnquiry(enquiryData, true);
        }
    };
    var handleSaveAndAdd = async (e) => {
        var enquiryData = await getEnquiryValues();
        if (enquiryData) {
            api_createNewEnquiry(enquiryData, false);
        }
    };
    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }
    async function archiveEnquiry(id) {
        id = archiveEnquiryId;
        var response = await api_handleApiCalls("enquiries.delete", {
            _id: id,
        });
        _$(modalArchiveEnquiry).modal("hide");
        archiveEnquiryId = null;
        if (response.status) {
            pSuccess("Success!", response.data);
            redrawEnquiryTable();
            redrawFollowupsTable();
        } else {
            pError("Error!", response.errMsg);
        }
    }
    async function proccedToArchiveEnquiry() {
        if (deleteEnquiryObj._id) {
            let response = await api_handleApiCalls("enquiries.archive", {
                _id: deleteEnquiryObj._id,
            });
            if (response.status) {
                showDeleteEnquiryModal(false);
                redrawEnquiryTable();
                redrawFollowupsTable();
                pSuccess("Success!", response.data);
            } else {
                showDeleteEnquiryModal(false);
                redrawEnquiryTable();
                pError("Error!", response.errMsg);
            }
        } else {
            showDeleteEnquiryModal(false);
        }
    }
    async function proccedToRestoreEnquiry() {
        if (deleteEnquiryObj._id) {
            let response = await api_handleApiCalls("enquiries.restore", {
                _id: deleteEnquiryObj._id,
            });
            if (response.status) {
                showDeleteEnquiryModal(false);
                redrawEnquiryTable();
                redrawFollowupsTable();
                pSuccess("Success!", response.data);
            } else {
                showDeleteEnquiryModal(false);
                redrawEnquiryTable();
                pError("Error!", response.errMsg);
            }
        } else {
            showDeleteEnquiryModal(false);
        }
    }
    async function proccedToDeleteEnquiry() {
        if (deleteEnquiryObj._id) {
            let response = await api_handleApiCalls("enquiries.delete", {
                _id: deleteEnquiryObj._id,
            });
            if (response.status) {
                showDeleteEnquiryModal(false);
                redrawEnquiryTable();
                redrawFollowupsTable();
                pSuccess("Success!", response.data);
            } else {
                showDeleteEnquiryModal(false);
                redrawEnquiryTable();
                pError("Error!", response.errMsg);
            }
        } else {
            showDeleteEnquiryModal(false);
        }
    }

    async function proccedConvertEnquiry() {
        // navigate(("students/info/archive-enquiry/" + enquiryId))

        var enquiryData = await getValidateEnquiryData(enquiryObj);

        if (enquiryData) {
            api_convertStudent(enquiryData);
        }
    }
    async function convertEnquiryToStudent(id) {
        enquiryObj = await getEnquiryById(id);

        _errorObj = {};
        await tick();

        _$("#modal-convert-enquiry").modal("show");
    }

    function showHideFilterModal(action) {
        allSelectedEnquiries = new Set()
        action
            ? _$(filterModalThis).modal("show")
            : _$(filterModalThis).modal("hide");
    }
    function getFilteredOrder(data) {
        var sort;
        try {
            if (Boolean(data)) {
                let index = data.order[0].column;
                let dir = data.order[0].dir;

                sort = {
                    name: data.columns[index].data,
                    dir: dir,
                };
            }
        } catch (error) { }
        return sort;
    }
    function applyFilter() {

        redrawEnquiryTable();

    }

    function clearFilter() {
        filterStatus = "";

        selectizeCounselorInstance[0].selectize.clear();
        selectizeBatchInstance[0].selectize.clear();
        selectizeUserlorInstance[0].selectize.clear();
        selectizeInstanceClass[0].selectize.clear();

        filterFollowUpDate = "";
        filterFollowUpDate1 = "";
        redrawEnquiryTable();
        allSelectedEnquiries = new Set()
    }
    function clearFormData() {
        enquiryObj = {
            _id: null,
            name: "",
            mobileNo: "",
        };
        selectizeInstanceClass[0].selectize.clear();
        selectizeBatchInstanceForAdd[0].selectize.clear();
        mobileno = "";
        name = "";
        referredBy = "";
        selectedBatches = [];
    }
    async function clearEnquiryFormData() {
        name = "";
        mobileno = "";
        await tick();
    }
    async function getFilterDataList() {
        batchesList = await api_getBatchesList();
        userList = await api_getCounselorUsers();
        counselorUserList = await api_getCounselorUsers();
        bindSelect2s();
    }
    function getCounts() {
        let apiData = {
            filterBy: filterFlag,
        };
        if (isArchivedMode()) {
            apiData.isArchived = true;
        }
        api_getCounts(apiData);
    }
    function capitalizeFirstLetter(string) {
        string = string ? string : "A";
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    function getFormatedDate(date) {
        if (!date && date == "") {
            return "-";
        }
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [day, month, year].join("-");
    }
    const makeCheckAll = (all) => {
        _$(".select-enquiry")
            .toArray()
            .map((element) => {
                let elem_checkbox = _$(element);
                let enquiryId = elem_checkbox.attr("enquiry-id");
                if (all) elem_checkbox.prop("checked", true);
                else elem_checkbox.prop("checked", false);

            });
    }

    const checkIfAllSelected = () => {

        let selectedArr = _$(".select-enquiry")
            .toArray()
            .filter((element) => {
                let elem_checkbox = _$(element);
                let enquiryId = elem_checkbox.attr("enquiry-id");
                return allSelectedEnquiries.has(enquiryId)
            });

        if (selectedArr.length == enquiryDataList.length && enquiryDataList.length != 0) _$('#select_all_enquiries').prop("checked", true);

        else _$('#select_all_enquiries').prop("checked", false);

    }
    const selectAllUnselect = async (checked, selectedArr) => {
        if (checked) {
            selectedArr.forEach(obj => allSelectedEnquiries.add(obj._id))
        } else {
            selectedArr.forEach(obj => allSelectedEnquiries.delete(obj._id))
        }
        allSelectedEnquiries = allSelectedEnquiries
        await tick()
        makeCheckAll(checked)
    }
    async function selectenquiry(id, checked) {
        if (checked) {
            allSelectedEnquiries.add(id)
        } else {
            allSelectedEnquiries.delete(id)
        }
        _$('#select_all_enquiries').prop("checked", false);

        allSelectedEnquiries = allSelectedEnquiries
        await tick()


    }
    function bindDatatable() {
        enquiryDatatable = _$(demoDataTable).DataTable({
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                localStorage.setItem(
                    window.location.pathname,
                    JSON.stringify(data)
                );
            },
            stateLoadCallback: function (settings) {
                return JSON.parse(
                    localStorage.getItem(window.location.pathname)
                );
            },

            searching: true,
            bLengthChange: false,
            responsive: true,
            order: [[3, "desc"]],
            language: {
                processing: "",
                lengthMenu: "_MENU_ enquiries/page",
                searchPlaceholder: 'Student Name | Number ',
                sSearch: '',

            },
            ajax: function (data, callback, settings) {
                var filterObj = { ...getFilterData() };
                filterObj.start = data.start;
                filterObj.length = data.length;
                filterObj.search = data.search.value;
                filterObj.draw = data.draw;
                filterObj.sort = getFilteredOrder(data);

                _$.ajax({
                    url: API_URL,
                    method: "post",
                    data: {
                        action: "enquiries.list",
                        accessToken: localStorage.getItem("accessToken"),
                        data: filterObj,
                    },
                    success: function (resData) {
                        var result = [];
                        if (resData.status) {
                            var paginationData = resData.data;
                            enquiryDataList = paginationData.data
                            // console.log("::::::enquiryDataList",enquiryDataList)
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(
                                    paginationData.filteredCount
                                ),
                                data: paginationData.data,
                            };
                            if (!resData.data.length) {
                                emptyState = true;
                            }
                            callback(result);
                        } else {
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        emptyState = true;
                    },
                });
            },
            columns: [
                {
                    data: "_id",

                    render: (data, type, full) => {
                        if (allSelectedEnquiries && allSelectedEnquiries.has(data)) {
                            return `
                        <input type="checkbox" checked class="select-enquiry itsSelected" enquiry-id="${data}">
                `;
                        } else {
                            return `
                        <input type="checkbox" class="select-enquiry" enquiry-id="${data}">
                `;
                        }

                    },
                },

                {
                    data: "name",
                    defaultContent: '<div class="text-center">-</div>',
                    render: (data, type, full) => {
                        let image;
                        // if image found display profilePic avatar
                        image = `<img src="${full.profilePic
                            ? full.profilePic
                            : "/images/man.png"
                            }" class="avatar mg-r-15" alt="${data[0].toUpperCase()}">`;
                        return `
                            <div style = "cursor:pointer" class="list-group-item d-flex align-items-center bd-0 bg-transparent pd-0 btn-edit-enquiry" enquiry-id="${full._id
                            }">
                                 ${image}
                                 <div class="wd-80p bg-transparent">
                            <h6 style = "cursor:pointer" class="tx-13 tx-inverse tx-semibold mg-b-0 tx-capitalize bg-transparent btn-edit-enquiry" enquiry-id="${full._id
                            }">${data || "-"}</h6>
                        <span class="d-block tx-11 text-muted bg-transparent">
                        <i data-feather="phone"></i> 
                        ${full.mobileNo || "-"}
                       </span>
                       
                        </div>
                      </div>`;
                    },
                },
                {
                    data: "status",
                    render: function (data, type, row) {
                        let statusElem;
                        if (row.status == "hot") {
                            statusElem =
                                '<div style="text-align: center;"><span class="tx-12 badge badge-pill badge-dark">Hot</span></div>';
                        } else if (row.status == "warm") {
                            statusElem =
                                '<div style="text-align: center;"><span class="tx-12 badge badge-pill badge-secondary">Warm</span></div>';
                        } else if (row.status == "cold") {
                            statusElem =
                                '<div style="text-align: center;"><span class="tx-12 badge badge-pill bg-gray-400">Cold</span></div>';
                        } else {
                            statusElem = '<div class="text-center" style="text-align: center;">-</div>';
                        }

                        return statusElem;
                    },
                },
                {
                    data: "_id",
                    // defaultContent : '<div class="text-center">-</div>',

                    render: (data, type, full) => {
                        let className;
                        if (full.className)
                            className = `<div class="text-center">${full.className}</div>`;
                        else className = `<div class="text-center"> - </div>`;

                        return className;
                    },
                },
                {
                    data: "assignedCounselorName",


                    render: (data, type, full) => {
                        // if(data == "") return `<div class="text-center"  style="text-align: center;"> - </div>`
                        return `<div class="text-center"  style="text-align: center;">${data ? data : ' - '}</div>`
                    }
                },

                {
                    data: "createdOn",
                    render: function (data, a, b, c) {
                        var _dateStr = '<div class="text-center"  style="text-align: center;">-</div>';
                        data = new Date(data);
                        if (
                            data &&
                            data.toString().toLowerCase() != "invalid date"
                        ) {
                            _dateStr = `
                            <div style="text-align:center" style="text-align:center">  <span > ${data.toLocaleDateString(
                                "en-GB"
                            )}, ${data.toLocaleTimeString()} </span> </div>
                            `;
                        }

                        return _dateStr;
                    },
                },
                {
                    data: "nextFollowupDate",
                    render: function (data, a, b, c) {
                        var _dateStr = '<div class=""  style="text-align: center;">-</div>';
                        data = new Date(data);
                        if (
                            data &&
                            data.toString().toLowerCase() != "invalid date"
                        ) {
                            _dateStr = `
                            <div style="text-align:center"> <span> ${data.toLocaleDateString("en-GB")} </span></div>
                        `;
                        }

                        return _dateStr;
                    },
                },
                {
                    data: "createdByUserName",
                    // defaultContent:
                    //     '<div style="text-align:center;" class="text-center">-</div>',
                    render: (data, type, full) => {
                        // if(data == "") return `<div class="text-center"  style="text-align: center;"> - </div>`
                        return `<div class="text-center"  style="text-align: center;">${data ? data : ' - '}</div>`
                    }
                },
                {
                    data: "_id",
                    render: function (data, a, b, c) {
                        if (isArchivedMode()) {
                            return `
                    <div class="d-md-flex flex-row justify-content-center">
                        <a href="#" class="delete mg-r-5 btn-delete-enquiry" enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete enquiry"><i class="wd-20 mg-r-5" data-feather="trash"></i></a>
                        <a href="#" class="delete mg-r-5 btn-resotre-enquiry" enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Restore enquiry"><i class="wd-20 mg-r-5" data-feather="upload"></i></a>
                    </div>
                    `;
                        } else {
                            var view = "";
                            var archive = "";
                            var convert = "";
                            if (
                                MODULES.hasAccess(
                                    MODULES.VIEW_ENQUIRY_DETAILS
                                ) &&
                                !MODULES.hasAccess(MODULES.ARCHIVE_ENQUIRY) &&
                                !MODULES.hasAccess(MODULES.CONVERT_ENQUIRY)
                            ) {
                                view = `<a href="#" class="rename mg-r-5 btn-edit-enquiry" createdBy-id="${b.createdBy}"  enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>`;
                            } else if (
                                MODULES.hasAccess(MODULES.ARCHIVE_ENQUIRY) &&
                                !MODULES.hasAccess(
                                    MODULES.VIEW_ENQUIRY_DETAILS
                                ) &&
                                !MODULES.hasAccess(MODULES.CONVERT_ENQUIRY)
                            ) {
                                archive = `<a href="#" class="delete mg-r-5 btn-remove-enquiry" enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Archive"><i class="wd-20 mg-r-5" data-feather="archive"></i></a>`;
                            } else if (
                                MODULES.hasAccess(MODULES.CONVERT_ENQUIRY) &&
                                !MODULES.hasAccess(
                                    MODULES.VIEW_ENQUIRY_DETAILS
                                ) &&
                                !MODULES.hasAccess(MODULES.ARCHIVE_ENQUIRY)
                            ) {
                                convert = `<a href="#" class="delete mg-r-5 btn-convert-enquiry" enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Convert to student"><i class="wd-20 mg-r-5" data-feather="external-link"></i></a>`;
                            } else if (
                                !MODULES.hasAccess(MODULES.ARCHIVE_ENQUIRY) &&
                                !MODULES.hasAccess(
                                    MODULES.VIEW_ENQUIRY_DETAILS
                                ) &&
                                !MODULES.hasAccess(MODULES.UPDATE_ENQUIRY)
                            ) {
                                view = "";
                                archive = "";
                                convert = "";
                            } else if (
                                !MODULES.hasAccess(MODULES.VIEW_ENQUIRY_DETAILS)
                            ) {
                                archive = ` <a href="#" class="delete mg-r-5 btn-remove-enquiry" enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Archive"><i class="wd-20 mg-r-5" data-feather="archive"></i></a>`;
                                convert = `<a href="#" class="delete mg-r-5 btn-convert-enquiry" enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Convert to student"><i class="wd-20 mg-r-5" data-feather="external-link"></i></a>`;
                            } else {
                                view = `<a href="#" class="rename mg-r-5 btn-edit-enquiry" createdBy-id="${b.createdBy}"  enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>`;
                                archive = ` <a href="#" class="delete mg-r-5 btn-remove-enquiry" enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Archive"><i class="wd-20 mg-r-5" data-feather="archive"></i></a>`;
                                convert = `<a href="#" class="delete mg-r-5 btn-convert-enquiry" enquiry-id="${data}" data-toggle="tooltip" data-placement="top" title="Convert to student"><i class="wd-20 mg-r-5" data-feather="external-link"></i></a>`;
                            }
                            return `
                    <div class="d-md-flex flex-row justify-content-center" style="text-align:center">
                        ${view}
                        ${archive}
                        ${convert}
                    </div>
                    `;
                        }
                    },
                },
            ],
            columnDefs: [{ orderable: false, targets: [1, -1] }],
            drawCallback: function () {
                feather.replace({ width: "1em", height: "1em" });
                checkIfAllSelected()

                _$(".btn-edit-enquiry")
                    .unbind("click")
                    .click(function (e) {
                        e.preventDefault();

                        var enquiryId = _$(this).attr("enquiry-id");
                        var createdBy = _$(this).attr("createdBy-id");

                        if (
                            localUserData.role == "telecaller" &&
                            !(localUserData._id == createdBy)
                        )
                            return;

                        navigate("/dashboard/enquiries/info/" + enquiryId);
                    });

                _$(".btn-convert-enquiry")
                    .unbind("click")
                    .click(function (e) {
                        e.preventDefault();
                        if (!MODULES.hasAccess(MODULES.CONVERT_ENQUIRY)) {
                            pError(
                                "Access Denied!",
                                "The User does not have a permission to convert enquiry to student"
                            );
                            return;
                        }
                        var enquiryId = _$(this).attr("enquiry-id");
                        convertEnquiryToStudent(enquiryId);
                    });

                _$(".btn-remove-enquiry")
                    .unbind("click")
                    .click(function (e) {
                        e.preventDefault();
                        if (!MODULES.hasAccess(MODULES.ARCHIVE_ENQUIRY)) {
                            pError(
                                "Access Denied!",
                                "The User does not have a permission to archive enquiry"
                            );
                            return;
                        }
                        if (localUserData.role == "telecaller") return;
                        var enquiryId = _$(this).attr("enquiry-id");
                        archiveEnquiryId = enquiryId;
                        handleArchiveBtnClick(enquiryId);
                    });

                _$(".btn-delete-enquiry")
                    .unbind("click")
                    .click(function (e) {
                        e.preventDefault();
                        if (!MODULES.hasAccess(MODULES.DELETE_ENQUIRY)) {
                            pError(
                                "Access Denied!",
                                "The User does not have a permission to delete enquiry"
                            );
                            return;
                        }
                        var enquiryId = _$(this).attr("enquiry-id");
                        handleDeleteBtnClick(enquiryId);
                    });
                _$(".btn-resotre-enquiry")
                    .unbind("click")
                    .click(function (e) {
                        e.preventDefault();

                        var enquiryId = _$(this).attr("enquiry-id");
                        handleRestoreBtnClick(enquiryId);
                    });

                _$(".select-enquiry")
                    .unbind("click")
                    .click(function (e) {
                        var enquiryId = _$(this).attr("enquiry-id");
                        var checked = e.target.checked
                        selectenquiry(enquiryId, checked);
                    });


                _$('#select_all_enquiries').unbind("click").click(function (e) {
                    e.stopPropagation();
                    selectAllUnselect(e.target.checked, enquiryDataList)
                });
            },
            processing: true,
            serverSide: true,
        });
        _$(".dataTables_length select").select2({
            minimumResultsForSearch: Infinity,
        });
        overdueFolloupsDatatable = _$(elemOverdueFollowupTable).DataTable({
            searching: false,
            bLengthChange: false,
            bProcessing: false,
            responsive: true,

            language: {
                processing: "",
                emptyTable: "No data found",
            },
            ajax: function (data, callback, settings) {
                _$.ajax({
                    url: API_URL,
                    method: "post",
                    data: {
                        action: "enquiries.listOverdueFollowups",
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                        },
                    },
                    success: function (resData) {
                        var result = [];
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(
                                    paginationData.filteredCount
                                ),
                                data: paginationData.data,
                            };
                            if (!result.data.length) {
                                overdueDatatableEmptyState = true;
                            }
                            callback(result);
                        } else {
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        overdueDatatableEmptyState = true;
                    },
                });
            },
            columnDefs: [
                {
                    targets: [0],
                    render: function (data, type, row) {
                        var statusElem;
                        var userIcon;
                        let followUpDate = row.followUpDate ? getFormatedDate(row.followUpDate) : ' - '
                        if (row.status == "hot") {
                            statusElem =
                                '<span class="wd-40 ht-40 bg-gray-800 tx-white d-flex align-items-center justify-content-center rounded">Hot</span>';
                            userIcon = `<span class="avatar-initial rounded-circle bg-primary">${row.user.name.charAt(
                                0
                            )}</span>`;
                        } else if (row.status == "warm") {
                            statusElem =
                                '<span class="wd-40 ht-40 bg-gray-600 tx-white d-flex align-items-center justify-content-center rounded">Warm</span>';
                            userIcon = `<span class="avatar-initial rounded-circle bg-indigo">${row.user.name.charAt(
                                0
                            )}</span>`;
                        } else if (row.status == "cold") {
                            statusElem =
                                '<span class="wd-40 ht-40 bg-gray-400 tx-white d-flex align-items-center justify-content-center rounded">Cold</span>';
                            userIcon = `<span class="avatar-initial rounded-circle bg-indigo">${row.user.name.charAt(
                                0
                            )}</span>`;
                        } else {
                            statusElem =
                                '<span class="wd-40 ht-40 bg-gray-300 tx-white d-flex align-items-center justify-content-center rounded">-</span>';
                            userIcon = `<span class="avatar-initial rounded-circle bg-gray-800">${row.user.name.charAt(
                                0
                            )}</span>`;
                        }

                        return `
                            <tr class="list-group-item pd-sm-x-20">
                                <div class="media">
                                    <div class="avatar d-sm-block">
                                        ${statusElem}
                                    </div>
                                    <div class="media-body mg-l-15">
                                        <div class="tx-color-02 d-flex align-items-center justify-content-between mg-b-2">
                                            <span class="tx-12">Response: ${row.response
                            }</span>
                                            <span class="tx-12">Follow-up Date: ${followUpDate}</span>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between mg-b-2">
                                            <h6 class="tx-13 tx-bold">Next Follow Up Date: ${getFormatedDate(
                                row.nextFollowupDate
                            )}</h6>
                                            <p class="tx-12 tx-color-02 mg-b-5 mg-t-5 d-flex align-items-center"><i class="mg-r-5" data-feather="user"></i>Created By: ${row.createdByUserName
                            }</p>
                                        </div>    
                                            <p class="tx-12 tx-color-02 mg-b-0">Remark: ${row.remark
                            }</p>
                                            
                                            <a href="/dashboard/enquiries/info/${row.user._id
                            }" >
                                                <div  class="d-flex justify-content-between mg-t-15">
                                                    <div class="avatar avatar-sm">
                                                        ${userIcon}
                                                    </div>
                                                    <div class="media-body mg-l-10 justify-content-between">
                                                        <h6 class="tx-13 mg-b-3 tx-capitalize">${row.user.name
                            }</h6>
                                                        <span class="tx-12"><i class="mg-r-5" data-feather="phone"></i>${row.user.mobileNo
                            }</span>
                                                    </div><!-- media-body -->
                                                </div> 
                                            </a>
                                    </div><!-- media-body -->
                                    
                                <div>
                            </tr>
                        
                        `;
                    },
                },
            ],
            drawCallback: function () {
                feather.replace({ width: "1em", height: "1em" });
                _$(".btn-class-tr")
                    .unbind("click")
                    .click(function (e) {
                        e.preventDefault();
                    });
            },
            processing: true,
            serverSide: true,
        });

        elemTodaysDatatable = _$(elemTodaysFollowupTable).DataTable({
            searching: false,
            bLengthChange: false,
            bProcessing: false,
            responsive: true,

            language: {
                processing: "",
                emptyTable: "No data found",
            },
            ajax: function (data, callback, settings) {
                _$.ajax({
                    url: API_URL,
                    method: "post",
                    data: {
                        action: "enquiries.listTodaysFollowups",
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                        },
                    },
                    success: function (resData) {
                        var result = [];
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.totalCount),
                                recordsFiltered: Number(
                                    paginationData.filteredCount
                                ),
                                data: paginationData.data,
                            };
                            if (!result.data.length) {
                                todaysDatatableEmptyState = true;
                            }
                            callback(result);
                        } else {
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        todaysDatatableEmptyState = true;
                    },
                });
            },
            columns: [{ data: "_id" }],
            columnDefs: [
                {
                    targets: 0,
                    data: null,
                    render: function (data, type, row) {
                        var statusElem;
                        var userIcon;
                        if (row.status == "hot") {
                            statusElem =
                                '<span class="wd-40 ht-40 bg-gray-800 tx-white d-flex align-items-center justify-content-center rounded">Hot</span>';
                            userIcon = `<span class="avatar-initial rounded-circle bg-primary">${row.user.name.charAt(
                                0
                            )}</span>`;
                        } else if (row.status == "warm") {
                            statusElem =
                                '<span class="wd-40 ht-40 bg-gray-600 tx-white d-flex align-items-center justify-content-center rounded">Warm</span>';
                            userIcon = `<span class="avatar-initial rounded-circle bg-indigo">${row.user.name.charAt(
                                0
                            )}</span>`;
                        } else if (row.status == "cold") {
                            statusElem =
                                '<span class="wd-40 ht-40 bg-gray-400 tx-white d-flex align-items-center justify-content-center rounded">Cold</span>';
                            userIcon = `<span class="avatar-initial rounded-circle bg-indigo">${row.user.name.charAt(
                                0
                            )}</span>`;
                        } else {
                            statusElem =
                                '<span class="wd-40 ht-40 bg-gray-300 tx-white d-flex align-items-center justify-content-center rounded">-</span>';
                            userIcon = `<span class="avatar-initial rounded-circle bg-gray-800">${row.user.name.charAt(
                                0
                            )}</span>`;
                        }

                        return `
                                <tr class="list-group-item pd-sm-x-20">
                                    <div class="media">
                                        <div class="avatar d-sm-block ">
                                            ${statusElem}
                                        </div>
                                        <div class="media-body mg-l-15">
                                        <div class="tx-color-02 d-flex align-items-center justify-content-between mg-b-2">
                                            <span class="tx-12">Response: ${row.response
                            }</span>
                                            <span class="tx-12">Follow Up Date: ${getFormatedDate(
                                row.followUpDate
                            )}</span>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between mg-b-2">
                                            <h6 class="tx-13 tx-bold">Next Follow Up Date: ${getFormatedDate(
                                row.nextFollowupDate
                            )}</h6>
                                            <p class="tx-12 tx-color-02 mg-b-5 mg-t-5 d-flex align-items-center"><i class="mg-r-5" data-feather="user"></i>Created By: ${row.createdByUserName
                            }</p>
                                        </div>
                                            
                                            <p class="tx-12 tx-color-02 mg-b-0">Remark: ${row.remark
                            }</p>
                                             <a href="/dashboard/enquiries/info/${row.user._id
                            }">
                                                <div class="d-flex justify-content-between mg-t-15" >
                                                    <div class="avatar avatar-sm">
                                                        ${userIcon}
                                                    </div>
                                                    <div class="media-body mg-l-10">
                                                        <h6 class="tx-13 mg-b-3 tx-capitalize">${row.user.name
                            }</h6>
                                                        <span class="tx-12"><i class="mg-r-5" data-feather="phone"></i>${row.user.mobileNo
                            }</span>
                                                    </div><!-- media-body -->
                                                </div> 
                                            </a>
                                        </div><!-- media-body -->
                                        
                                    <div>
                                </tr>
                            
                            `;
                    },
                    targets: 0,
                },
            ],
            drawCallback: function () {
                feather.replace({ width: "1em", height: "1em" });
                _$(".btn-class-tr")
                    .unbind("click")
                    .click(function (e) {
                        e.preventDefault();
                        var enquiryId = _$(this).attr("enquiry-id");
                        navigate("enquiries/info/" + enquiryId);
                    });
            },
            processing: true,
            serverSide: true,
        });
    }

    async function bindSelect2s() {
        selectizeCounselorInstance = _$(filterElemCounselor).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: counselorUserList,
        });

        selectizeUserlorInstance = _$(filterElemUser).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: userList,
        });

        let resClass = await api_getClassList({
            orgId: localUserData.orgId,
        });

        selectizeInstanceClass = _$(elemSelectClass).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            persist: false,
            placeholder: "Select class",

            options: resClass,
        });

        let resClass1 = await api_getClassList({
            orgId: localUserData.orgId,
        });

        selectizeInstanceClass1 = _$(elemSelectClass1).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            persist: false,
            placeholder: "Select class",
            // plugins: ["remove_button"],
            options: resClass1,
            onChange: async function (value) {
                classId = value;
                await initSelectize(value);
            },
        });

        selectizeBatchInstance = _$(filterElemBatch).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: batchesList,
        });

        selectizeBatchInstanceForAdd = _$(filterElemBatcforAdd).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            placeholder: "Select Batch",

            options: batchesList,
        });
    }

    function bindUiEvents() {
        let thisToday = new Date();
        var thisHours = thisToday.getHours().toString();

        _$(elem_nextFolloUpTimepicker).timepicker({
            timeFormat: "h:mm p",
            interval: 15,
            dynamic: false,
            dropdown: true,
            scrollbar: true,
            template: "modal",
            maxTime: thisHours,
            change: async function (time) {
                if (time) {
                    filterFollowUpTime = new Date(time);
                } else {
                    filterFollowUpTime = null;
                }
                clearError("filterFollowUpTime");
                await tick();
            },
        });

        _$(".filterByDropdown li").on("click", function (e) {
            filterBy = _$(this).text();
            filterFlag = _$(this).attr("filter");
            if (!_$(this).hasClass("active")) {
                _$(".filterByDropdown li").removeClass("active");
                _$(this).addClass("active");
            }

            api_getCounts({ filterBy: filterFlag });
        });

        _$("#modal-new-enquiry").on("show.bs.modal", function (event) {
            var animation = _$(event.relatedTarget).data("animation");
            _$(this).addClass(animation);
        });

        // hide modal with effect
        _$("#modal-new-enquiry").on("hidden.bs.modal", function (e) {
            errorObj = {};
            clearEnquiryFormData();
            _$(this).removeClass(function (index, className) {
                return (className.match(/(^|\s)effect-\S+/g) || []).join(" ");
            });
        });

        _$(modalArchiveEnquiry).on("show.bs.modal", function (event) {
            var animation = _$(event.relatedTarget).data("animation");
            _$(this).addClass(animation);
        });

        // hide modal with effect
        _$(modalArchiveEnquiry).on("hidden.bs.modal", function (e) {
            errorObj = {};

            _$(this).removeClass(function (index, className) {
                return (className.match(/(^|\s)effect-\S+/g) || []).join(" ");
            });
        });
    }

    function clearDeleteEnquiryObj() {
        deleteEnquiryObj = {
            _id: null,
            name: "",
            mobileNo: "",
        };
    }

    async function initSelectize(classId) {
        let batchesList1 = await getDemoBatches(classId);

        if (_$(filterElemBatch1).selectize())
            _$(filterElemBatch1).selectize()[0].selectize.destroy();

        selectizeBatchInstance1 = _$(filterElemBatch1).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "Select demo batch",
            // plugins: ["remove_button"],
            delimiter: ",",
            persist: false,
            options: batchesList1,
        });
    }

    onMount(() => {
        feather.replace({ width: "1em", height: "1em" });
        initDashboard();
        bindUiEvents();
        initSelectize();
        api_getBatchesList();
        api_getBatchesList();
        unsubscribe();
        // initFileManager();
        activeMenu.set("enquiries");
        getCounts();
        bindDatatable();
        getFilterDataList();
    });

    async function generateWorkBook(enquiryDataList) {
        const workbook = new ExcelJS.Workbook();

        const worksheet = workbook.addWorksheet("Enquiry Data", {
            pageSetup: { paperSize: 9, orientation: "landscape" },
        });




        let fileName = "Enquiry_Data_Export_" + new Date().getTime() + "_.xlsx";

        let columnsArr = [
            { header: "Sr No.", key: "serialNo", width: 15 },
            { header: "Student Name", key: "name", width: 15 },
            { header: "Mobile No", key: "mobileNo", width: 15 },
            { header: "Email", key: "email", width: 15 },
            { header: "Gender", key: "gender", width: 15 },

            { header: "Address", key: "address", width: 15 },

            { header: "City", key: "city", width: 15 },
            { header: "State", key: "state", width: 15 },
            { header: "School/College", key: "school", width: 15 },
            { header: "Last Conducted Exam", key: "exam", width: 15 },
            { header: "Marks Obtained", key: "marks", width: 15 },

            { header: "Status", key: "status", width: 15 },
            { header: "Class", key: "class", width: 15 },
            { header: "Assigned Batches", key: "batch", width: 15 },
            { header: "Assigned Counselor", key: "counselor", width: 15 },
            { header: "Enquiry Date", key: "enquiryDate", width: 20 },
            { header: "Next Follow up Date", key: "followupDate", width: 15 },
            { header: "Created By", key: "createdBy", width: 15 },
        ];


        worksheet.columns = columnsArr;

        for (let idx = 0; idx < enquiryDataList.length; idx++) {
            let enquiryDataArr = [];
            let enquiryResult = enquiryDataList[idx];

            let enquiryDate = moment(new Date(enquiryResult.createdOn)).format('DD/MM/YYYY');

            let nextFollowupdate = new Date(enquiryResult.nextFollowupDate)
            let followUpDate
            if (nextFollowupdate && nextFollowupdate.toString().toLowerCase() != "invalid date") {
                followUpDate = moment(nextFollowupdate).format('DD/MM/YYYY');
            }



            enquiryDataArr.push(idx + 1);
            enquiryDataArr.push(
                enquiryResult.name ? enquiryResult.name : ""
            );
            enquiryDataArr.push(enquiryResult.mobileNo ? enquiryResult.mobileNo : "");
            enquiryDataArr.push(enquiryResult.email ? enquiryResult.email : "");
            enquiryDataArr.push(enquiryResult.gender ? enquiryResult.gender : "");

            enquiryDataArr.push(enquiryResult.address ? enquiryResult.address : "");
            enquiryDataArr.push(enquiryResult.stateName ? enquiryResult.cityName ? enquiryResult.cityName : "" : '');
            enquiryDataArr.push(enquiryResult.stateName ? enquiryResult.stateName : "");

            enquiryDataArr.push(enquiryResult.schoolName ? enquiryResult.schoolName : "");
            enquiryDataArr.push(enquiryResult.lastExamName ? enquiryResult.lastExamName : "");
            enquiryDataArr.push(enquiryResult.marksObtained ? enquiryResult.marksObtained : "");
            enquiryDataArr.push(
                enquiryResult.status ? enquiryResult.status : ""
            );
            enquiryDataArr.push(
                enquiryResult.className ? enquiryResult.className : ""
            );
            enquiryDataArr.push(
                enquiryResult.batchNameArr ? enquiryResult.batchNameArr.join(',') : ""
            );
            enquiryDataArr.push(
                enquiryResult.assignedCounselorName ? enquiryResult.assignedCounselorName : ""
            );

            enquiryDataArr.push(enquiryDate ? enquiryDate : "");
            enquiryDataArr.push(followUpDate ? followUpDate : "");
            enquiryDataArr.push(
                enquiryResult.createdByUserName ? enquiryResult.createdByUserName : ''
            );



            worksheet.addRow(enquiryDataArr);
        }

        const buffer = await workbook.xlsx.writeBuffer();

        saveFile(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName
        );
        //   await workbook.xlsx.writeFile(fileName);
    }
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        // var view = new Uint8Array(buf);
        var view = s;
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    const saveFile = async (blob, fileName) => {
        const a = document.createElement("a");
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.addEventListener("click", (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };
    function uniqueId() {
        // always start with a letter (for DOM friendlyness)
        var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
        do {
            // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
            var ascicode = Math.floor(Math.random() * 42 + 48);
            if (ascicode < 58 || ascicode > 64) {
                // exclude all chars between : (58) and @ (64)
                idstr += String.fromCharCode(ascicode);
            }
        } while (idstr.length < 32);

        return idstr.toLowerCase();
    }
    // const exportEnquiryList = () => {
    //     if (exportLoading) return;
    //     exportLoading = true;
    //     generateWorkBook();
    //     exportLoading = false;
    // };
    async function api_exportEnquiries(filterObj, selectedEnquiries) {
        exportLoading = true;
        var response = await api_handleApiCalls("enquiries.enquiryExport", {
            filter: filterObj,
            selected: selectedEnquiries
        });
        exportLoading = false;

        if (response.status) {
            return response.data;
        } else {
            pError("Error!", response.errMsg);
            return null;
        }
    }
    var btnExportEnquiries = async () => {
        if (exportLoading) return;
        exportLoading = true;
        let enquirydata = await api_exportEnquiries();
        // console.log("enquirydata:::",enquirydata)

        let allEnquiries = enquirydata.data
        // console.log("allEnquiries:::",allEnquiries)
        if (allEnquiries) {
            generateWorkBook(allEnquiries);
        }

        exportLoading = false;


    };
    var btnExportFilteredEnquiries = async () => {
        exportLoading = true;
        var filterObj = { ...getFilterData() };
        let enquirydata = await api_exportEnquiries(filterObj);
        // console.log("enquirydata:::", enquirydata)

        let allEnquiries = enquirydata.data
        // console.log("allEnquiries:::", allEnquiries)
        if (allEnquiries) {
            generateWorkBook(allEnquiries);
        }

        exportLoading = false;

        // if (url) {
        //   window.open(window.location.origin + url);
        // }
    };
    function clearSelected() {
        allSelectedEnquiries = new Set()
        makeCheckAll()
        checkIfAllSelected(0)
    }
    var btnExportSelectedEnquiries = async () => {
        if (allSelectedEnquiries.size == 0) {
            return pAlert("Warning", "Select Enquiries First")

        }
        else {
            exportLoading = true;

            let selectedEnquiries = Array.from(allSelectedEnquiries)
            let enquirydata = await api_exportEnquiries({}, selectedEnquiries);
            let allEnquiries = enquirydata.data
            // console.log("allEnquiries:::", allEnquiries)
            if (allEnquiries) {
                generateWorkBook(allEnquiries);
            }


            exportLoading = false;

            //   if (url) {
            //     window.open(window.location.origin + url);
            //   }
        }
    };
</script>

<div class="modal effect-scale" bind:this={modalArchiveEnquiry} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather={modalArchiveEnquiryIcon}
                            class="wd-60 ht-60" /></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">
                            {modalArchiveEnquiryTitle}
                        </h4>
                        <p class="tx-13 tx-color-03 mg-b-0">
                            {modalArchiveEnquirySubTitle}
                        </p>
                    </div>
                </div>
                <!-- media -->
            </div>
            <!-- modal-header -->
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0 ">
                <!-- <h4 class="tx-16 tx-bold mg-b-30">Are you sure you want to Archive this Enquiry?</h4>
          <a href="" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a> -->
                <hr class="full-width" />
                <div>
                    <div class="bd">
                        <ul class="list-unstyled media-list tx-12 tx-sm-13 mg-b-0">
                            <li class="media bg-ui-01 ">
                                <div class="avatar">
                                    <span class="avatar-initial rounded-circle bg-gray-600">{deleteEnquiryObj.name
                                        ? deleteEnquiryObj.name.charAt(0)
                                        : "-"}</span>
                                </div>
                                <div class="media-body mg-l-15">
                                    <h6 class="tx-13 mg-b-2">
                                        {deleteEnquiryObj.name
                                        ? deleteEnquiryObj.name
                                        : ""}
                                    </h6>
                                    <span class="d-block tx-color-03"><i class="mg-r-5" data-feather="phone-call" />
                                        {deleteEnquiryObj.mobileNo
                                        ? deleteEnquiryObj.mobileNo
                                        : ""}</span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="d-flex justify-content-end tx-12 mg-t-30">
                        <button type="button" class="btn  pd-x-15 btn-secondary tx-12" data-dismiss="modal">CANCEL
                            <i data-feather="x" class="wd-10 mg-l-5" />
                        </button>
                        <button type="button" disabled={isLoading} on:click={modalArchiveEnquiryProceed}
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                            <ButtonLoader {isLoading} btnText={modalArchiveEnquiryBtnText} />
                            <i data-feather={modalArchiveEnquiryIcon} class="wd-10 mg-l-5" />
                        </button>
                    </div>
                </div>
            </div>
            <!-- modal-body -->
        </div>
        <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
</div>
<!-- modal -->

<!-- Modal -->
<div class="modal effect-scale" id="modal-convert-enquiry" tabindex="-1" role="dialog" aria-labelledby="convert-enquiry"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="user-plus"
                            class="wd-60 ht-60" /></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">
                            Convert to student
                        </h4>
                        <p class="tx-13 tx-color-03 mg-b-0">
                            Do you want to convert Enquiry to Student?
                        </p>
                        <p class="tx-13 tx-color-03 mg-b-0">
                            (This will archive current enquiry.)
                        </p>
                    </div>
                </div>
                <!-- media -->
            </div>
            <!-- modal-header -->
            <hr class="full-width" />
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <div class="bd">
                    <ul class="list-unstyled media-list tx-12 tx-sm-13 mg-b-0">
                        <li class="media bg-ui-01 ">
                            <div class="avatar">
                                <span class="avatar-initial rounded-circle bg-gray-600">{enquiryObj.name
                                    ? enquiryObj.name.charAt(0)
                                    : "A"}</span>
                            </div>
                            <div class="media-body mg-l-15">
                                <h6 class="tx-13 mg-b-2">{enquiryObj.name}</h6>
                                <span class="d-block tx-color-03"><i class="mg-r-5"
                                        data-feather="phone-call" />{enquiryObj.mobileNo}</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="animated bounce mg-t-15">
                    <label class="d-block">Class/Stream <span class="required-field">*</span></label>
                    <select bind:this={elemSelectClass1} class:parsley-error={errorObj.batchClass}>
                        <option>Select Class</option>
                    </select>
                </div>

                <div class="mg-t-15">
                    <div class="custom-control custom-checkbox ">
                        <input type="checkbox" bind:checked={addDemoBatch} on:click={()=> {
                        if (addDemoBatch) {
                        _$(
                        elemDemoBatchesSelect
                        )[0].selectize.disable();
                        } else {
                        _$(
                        elemDemoBatchesSelect
                        )[0].selectize.enable();
                        }
                        }}
                        class="custom-control-input"
                        id="idAddDemoBatch"
                        />
                        <label class="custom-control-label" for="idAddDemoBatch">Add to demo batch</label>
                    </div>
                </div>
                <div class="animated bounce mg-t-15">
                    <label class="d-block">Select default demo batch to assign <span
                            class="required-field">{addDemoBatch ? "*" : ""}</span></label>
                    <!-- <select class:parsley-error={_errorObj.demoBatchId} bind:this={elemDemoBatchesSelect} class="">
                    <option>select batch</option>
                </select> -->
                    <select disabled={isLoading} bind:this={filterElemBatch1} class="" />
                    {#if _errorObj.demoBatchId}
                    <div class="parsley-errors-list">
                        {_errorObj.demoBatchId}
                    </div>
                    <!-- content here -->
                    {/if}
                </div>

                <div class="d-flex justify-content-end tx-12 mg-t-35">
                    <button type="button" class="btn  pd-x-15 btn-secondary" data-dismiss="modal">CANCEL
                        <i data-feather="x" class="wd-10 mg-l-5" />
                    </button>

                    <button type="button" disabled={isLoading} on:click={proccedConvertEnquiry}
                        class="btn btn-sm btn-primary pd-x-15 btn-uppercase mg-l-5">
                        <ButtonLoader {isLoading} btnText="CONVERT" />
                        <i class="wd-20 mg-r-5" data-feather="external-link" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

<!-- Modal -->
<div class="modal effect-scale" bind:this={filterModalThis} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>

                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60" /></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">
                            Apply filter for sorting
                        </p>
                    </div>
                </div>
                <!-- media -->
            </div>
            <!-- modal-header -->

            <hr class="full-width" />
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <h4 class="tx-16 tx-bold mg-b-30">
                <i data-feather="filter" class="mg-r-5"></i> Filters
            </h4> -->

                <div class="form-group">
                    <label class="d-block flex-grow-1">Status:</label>
                    <div class="d-md-flex">
                        <div class="custom-control custom-radio mg-r-15">
                            <input type="radio" id="status-hot" bind:group={filterStatus} class="custom-control-input"
                                value="hot" />
                            <label class="custom-control-label" for="status-hot">Hot</label>
                        </div>

                        <div class="custom-control custom-radio mg-r-15">
                            <input type="radio" bind:group={filterStatus} id="status-warm" class="custom-control-input"
                                value="warm" />
                            <label class="custom-control-label" for="status-warm">Warm</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input type="radio" bind:group={filterStatus} id="status-cold" class="custom-control-input"
                                value="cold" />
                            <label class="custom-control-label" for="status-cold">Cold</label>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="d-block">Enquiry date from:</label>
                    <input type="date" max={todaysDate} bind:value={filterFollowUpDate}
                        class="form-control follow-up-date wd-300" class:parsley-error={errorObj.followUpDate}
                        placeholder="Select follow up date" style="width: 100%" onkeydown="return false" />
                    <!-- <div class="d-flex">
                    <div>
                        <input type="date" max={todaysDate} bind:value={filterFollowUpDate} class="form-control follow-up-date" placeholder="Select follow up date"/>
                    </div>
                    <div class="mg-l-8">
                        <input
                            bind:this={elem_nextFolloUpTimepicker}
                            on:keyup={(e) => e.key === 'Enter' && submitForm(e)}
                            type="text"
                            class="form-control first-input"
                            class:parsley-error={errorObj.nextFolloUpTimepicker}
                            placeholder="Select time" />
                    </div>
                </div> -->
                </div>
                <div class="form-group">
                    <label class="d-block">Enquiry date to:</label>
                    <input type="date" max={todaysDate} bind:value={filterFollowUpDate1}
                        class="form-control follow-up-date wd-300" class:parsley-error={errorObj.followUpDate}
                        placeholder="Select follow up date" style="width: 100%" onkeydown="return false" />
                </div>
                <div class="form-group">
                    <label class="d-block">Created By:</label>
                    <select bind:this={filterElemUser} class="">
                        <option>Select created by user</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="d-block">Counselor:</label>
                    <select bind:this={filterElemCounselor} class="">
                        <option>Select Counselor</option>
                    </select>
                </div>
                <!--   -->
                <div class="form-group">
                    <label class="d-block">Batch</label>
                    <select disabled={isLoading} bind:this={filterElemBatch} class="">
                        <option>Select batches</option>
                    </select>
                </div>

                <div class="d-md-flex justify-content-end mg-t-25">
                    <button disabled={isLoading} on:click={clearFilter}
                        class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal">CLEAR
                        <i data-feather="x" class="wd-10 mg-l-5" />
                    </button>
                    <button disabled={isLoading} on:click={applyFilter} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
                        data-dismiss="modal">
                        <ButtonLoader {isLoading} btnText="APPLY" />
                        <i data-feather="filter" class="wd-10 mg-l-5" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

<!-- Modal -->
<div class="modal effect-scale" id="modal-new-enquiry" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel6"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="help-circle"
                            class="wd-60 ht-60" /></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Enquiry</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">
                            Create new enquiry
                        </p>
                    </div>
                </div>
                <!-- media -->
            </div>
            <!-- modal-header -->
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <!-- <a href="" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </a>
            <div class="d-flex">
              <span class="tx-color-04"><i data-feather="help-circle"
                class="wd-40 ht-40 stroke-wd-3 mg-b-20"></i></span>
              <h3 class=" mg-b-15 mg-md-b-20 mg-l-15">Create new enquiry</h3>
            </div> -->
                <hr class="full-width" />
                <div class=" tx-color-02">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label class="d-block">Full Name <span class="required-field">*</span></label>
                            <input bind:value={name} type="text" maxlength="50" class="form-control"
                                class:parsley-error={errorObj.name} placeholder="Enter full name" on:keyup={(e)=>
                            e.key === "Enter" && handleClickProceed()}
                            />
                            {#if errorObj.name}
                            <div class="parsley-errors-list">
                                {errorObj.name}
                            </div>
                            <!-- content here -->
                            {/if}
                        </div>
                        <div class="form-group col-md-12">
                            <label class="d-block">Mobile <span class="required-field">*</span></label>
                            <input type="tel" maxlength="10" bind:value={mobileno} class="form-control"
                                class:parsley-error={errorObj.mobileno} placeholder="Enter mobile number"
                                on:keyup={(e)=>
                            e.key === "Enter" && handleClickProceed()}
                            />
                            {#if errorObj.mobileno}
                            <div class="parsley-errors-list">
                                {errorObj.mobileno}
                            </div>
                            <!-- content here -->
                            {/if}
                        </div>

                        <div class="form-group col-md-12">
                            <label class="d-block">Class/Stream <span class="required-field">*</span></label>
                            <select bind:this={elemSelectClass} class:parsley-error={errorObj.batchClass}>
                                <option>Select Class</option>
                            </select>
                            {#if errorObj.batchClass}
                            <div class="parsley-errors-list">
                                {errorObj.batchClass}
                            </div>
                            <!-- content here -->
                            {/if}
                        </div>

                        <div class="form-group col-md-12">
                            <label class="d-block">Batch</label>
                            <select disabled={isLoading} bind:this={filterElemBatcforAdd} class="">
                                <option>Select batches</option>
                            </select>
                        </div>
                        <div class="form-group col-md-12">
                            <label class="d-block">Referred By </label>
                            <input bind:value={referredBy} type="text" class="form-control"
                                class:parsley-error={errorObj.referredBy} placeholder="Enter Name" on:keyup={(e)=>
                            e.key === "Enter" && handleClickProceed()}
                            />
                            {#if errorObj.referredBy}
                            <div class="parsley-errors-list">
                                {errorObj.referredBy}
                            </div>
                            <!-- content here -->
                            {/if}
                        </div>

                        <!-- <div class="col-md-12">
                        {#if batchesList.length != 0} 
                            <label class="d-block">Enquiry for batches: </label>
                            <div class="d-md-flex flex-wrap mg-t-10 mg-b-15">
                                {#each batchesList as batch}
                                    <div class="custom-control custom-checkbox mg-r-20">
                                        <input type="checkbox" bind:group={selectedBatches} value={batch._id} class="custom-control-input" id={batch._id}>
                                        <label class="custom-control-label" for={batch._id}>{batch.name}</label>
                                    </div>
                                {/each}     
                            </div>
                        {/if}
                    </div> -->
                        <div class="col-md-12">
                            {#if errorObj.apiError}
                            <div class="parsley-errors-list">
                                {errorObj.apiError}
                            </div>
                            {/if}
                        </div>
                    </div>
                </div>

                <div class="d-md-flex justify-content-end tx-12 mg-t-30">
                    <button type="button" class="btn  btn-secondary tx-13 mg-md-r-40 " data-dismiss="modal"
                        on:click={clearFormData}>CANCEL</button>

                    <button type="button" disabled={isLoadingSaveAndAdd} proceed="yes" on:click={handleSaveAndAdd}
                        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <ButtonLoader isLoading={isLoadingSaveAndAdd} btnText="Save & Add Another" />
                        <!-- <i data-feather="arrow-right" class="wd-10 mg-l-5"></i> -->
                    </button>

                    <button type="button" disabled={isLoadingSaveAndProceed} proceed="no" on:click={handleClickProceed}
                        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <ButtonLoader isLoading={isLoadingSaveAndProceed} btnText="Save" />
                        <!-- <i data-feather="arrow-right" class="wd-10 mg-l-5"></i> -->
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

<div class="content-body fadeIn">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item">
                        <a href="/dashboard">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">
                        enquiries
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        List
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">Enquiry Management</h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">
                Manage enquiries or add new enquiry.
            </p>
        </div>
        <hr class="full-width" />
        <div class="d-block">
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i data-feather="mail"
                    class="wd-10 mg-r-5"></i> Email</button> -->
            <div class="d-sm-flex">
                <div class="btn-group">
                    <button class="btn btn-light btn-sm bg-white dropdown-toggle" bind:this={elemBtnFilterBy}
                        type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {filterBy}
                    </button>
                    <ul class="dropdown-menu filterByDropdown">
                        <li class="dropdown-item active" filter="T" href="#">
                            Today
                        </li>
                        <li class="dropdown-item" filter="Y" href="#">
                            Yesterday
                        </li>
                        <li class="dropdown-item" filter="LD7">Last 7 Days</li>
                        <li class="dropdown-item" filter="LD30">
                            Last 30 Days
                        </li>
                        <li class="dropdown-item" filter="TM">This Month</li>
                        <li class="dropdown-item" filter="LM">Last Month</li>
                        <li class="dropdown-item" filter="LY">Last Year</li>
                    </ul>
                </div>
                {#if MODULES.hasAccess(MODULES.SHOW_ARCHIEVED_ENQUIRY) && isEnquiryModeDefault}
                <a href="javascript:void(0)" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                    on:click={changeEnquiryListMode}>
                    {isEnquiryModeDefault
                    ? "Show Archived Enquiries"
                    : "Show Enquiries"}
                </a>
                {/if}
                {#if MODULES.hasAccess(MODULES.ADD_ENQUIRY)}
                <a href="#modal-new-enquiry" data-toggle="modal"
                    class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" data-animation="effect-scale">
                    <i data-feather="plus" class="wd-10 mg-r-5" /> Add enquiry
                </a>
                {/if}
            </div>
        </div>
    </div>
    <hr class="full-width" />
    <div class="row row-xs">
        <div class="col-sm-12 col-lg-12">
            <div class="d-md-flex flex-row mg-b-20">
                <div class="mg-sm-t-8 mg-lg-r-8" style="width: 20%;">
                    <ViewCounts time={filterBy} title="Total Enquiries" count={TOTAL_ENQUIRIES} />
                </div>

                <div class="mg-sm-t-8 mg-lg-r-8" style="width: 20%;">
                    <ViewCounts time={filterBy} title="Total Follow Ups" count={TOTAL_FOLLOW_UPS} />
                </div>

                <div class="mg-sm-t-8 mg-lg-r-8" style="width: 20%;">
                    <ViewCounts time={filterBy} title="Follow Ups Overdue" count={FOLLOW_UPS_OVERDUE} />
                </div>

                <div class="mg-sm-t-8 mg-lg-r-8" style="width: 20%;">
                    <ViewCounts time={filterBy} title="Hot & Pending" count={HOT_AND_PENDING} />
                </div>

                <div class="mg-sm-t-8" style="width: 20%;">
                    <ViewCounts time={filterBy} title="Converted" count={CONVERTED} />
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="d-flex">
                <div class="title-legends flex-grow-1">
                    {isEnquiryModeDefault ? "Enquiries" : "Archived Enquiries"}
                </div>
                <!-- <button
                    class="btn btn-sm pd-x-15 mg-r-5 btn-primary btn-uppercase"
                    disabled={exportLoading}
                    on:click={exportEnquiryList}
                >
                    <ButtonLoader isLoading={exportLoading} btnText="Export" />
                </button> -->
                <div class="d-flex justify-content-between align-items-center">
                    {#if allSelectedEnquiries.size > 0}
                    <div class="d-flex justify-content-between align-items-center">
                        <a disabled={allSelectedEnquiries.size==0}
                            class=" mg-l-5   mg-r-5 text-primary animated fadeIn align-items-center justify-content-center">
                            Selected {allSelectedEnquiries.size} Enquiries
                        </a>
                        <button class="btn btn-sm pd-x-15 btn-outline-light  btn-capitalize" on:click={clearSelected}>
                            Clear Selected</button>
                    </div>
                    <div class="divider-text divider-vertical" style="background-color: #cfcfcf;"></div>
                    {/if}

                    <div class="dropdown mg-l-5">

                        <button disabled={exportLoading}
                            class="btn btn-sm pd-x-15 btn-uppercase btn-secondary mg-l-5  mg-r-5 dropdown-toggle"
                            type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <ButtonLoader isLoading={exportLoading} btnText={exportLoading ? "Processing..." : "Export"
                                } />
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" on:click={btnExportEnquiries} href="#"><i data-feather="file-text"
                                    class="wd-10 mg-r-5" />Export All
                                Enquiries</a>
                            <a class="dropdown-item" on:click={btnExportFilteredEnquiries} href="#"><i
                                    data-feather="file-text" class="wd-10 mg-r-5" />Export Filtered
                                Enquiries</a>
                            <a class="dropdown-item" on:click={btnExportSelectedEnquiries} href="#"><i
                                    data-feather="file-text" class="wd-10 mg-r-5" />Export Selected
                                Enquiries</a>

                        </div>
                    </div>
                    <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase" on:click={()=> {
                        showHideFilterModal(true);
                        }}
                        >
                        <i data-feather="filter" class="mg-r-5" /> Filters</button>
                </div>
            </div>

            <div class="mg-b-25 mg-t-15">
                <div class="df-example demo-table">
                    <table bind:this={demoDataTable} class="table mg-b-0" style="width: 100%;">
                        <thead>
                            <tr>
                                <th style="width: 10%;">
                                    <input type="checkbox" id="select_all_enquiries" name="selectAll" /> Select All
                                </th>
                                <th class="" style=" width: 15%;">Student Name</th>
                                <th class="" style="text-align: center; width: 10%;">Status</th>
                                <th class="" style="text-align: center; width: 10%;">Class</th>

                                <th class="" style="text-align: center; width: 15%;">Assigned Counselor</th>
                                <th class="" style="text-align: center; width: 10%;">Enquiry date</th>
                                <th class="" style="text-align: center; width: 15%;">Next Follow up date</th>
                                <th class="" style="text-align: center; width: 15%;">Created by</th>
                                <th class="" style="text-align: center; width: 10%;">Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody />
                    </table>
                    {#if emptyState}
                    <div class="empty-state-img">
                        <img src="/images/empty.svg" style="width: 25%;" alt="empty" />
                        <p class="tx-bold" />
                    </div>
                    {/if}
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xl-6">
            <div class="mg-b-25">
                <div class="df-example demo-table" style="width: 100%;">
                    <table bind:this={elemTodaysFollowupTable} class="table table-striped mg-b-0">
                        <thead>
                            <tr>
                                <th class="">Todays Follow Ups</th>
                            </tr>
                        </thead>

                        <tbody class="list-group-flush tx-13" />

                        {#if todaysDatatableEmptyState}
                        <div class="empty-state-img">
                            <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
                        </div>
                        {/if}
                    </table>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xl-6">
            <div class="mg-b-25">
                <div class="df-example demo-table" style="width: 100%;">
                    <table bind:this={elemOverdueFollowupTable} class="table table-striped mg-b-0">
                        <thead>
                            <tr>
                                <th class="">Overdue Follow Ups</th>
                            </tr>
                        </thead>
                        <tbody class="list-group-flush tx-13" />
                        {#if overdueDatatableEmptyState}
                        <div class="empty-state-img">
                            <img src="/images/noresult.png" style="width: 25%;" alt="empty" />
                        </div>
                        {/if}
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .empty-state-img {
        text-align: center !important;
        width: 100%;
        left: 50%;
    }

    .filterByDropdown li:hover {
        cursor: pointer;
    }

    .title-legends {
        width: auto;
        background-color: inherit;
        padding: 0 10px;
        position: relative;
        margin-left: -10px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: inherit;
        color: #1c273c;
        letter-spacing: 0.5;
        margin-bottom: 0;
    }
</style>
