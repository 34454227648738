<script>
  import { flip } from "svelte/animate";
  import { Router, Link, links, Route, navigate } from "svelte-routing";
  import { onMount, afterUpdate, tick } from "svelte";

  import QuestionBank from "./selectQuestions/questionBank.svelte";
  import PreviousTests from "./selectQuestions/previousTests.svelte";
  import QuestionsImport from "./selectQuestions/importQuestions.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import { pConfirm, pSuccess, pError ,pAlert } from "../utils/notify.svelte"

  export let testObj;
  export let updateParent;
  export let errorObj = {};

  let totalDuration;
  let elemDurationFields = [],
    elemPmarkFields = [],
    elemNmarkFields = [];
  let modalCmp;
  let sectionName;
  let parentSection
  let loadedOnce = false;

  //put total marks in the testObj if missing

  if(!testObj.hasOwnProperty('totalMaxMarks')){
    testObj.totalMaxMarks = Number(0);
  }
  
  //subsections declaration
  let makeSub_Section;
  let elemParentSections
  let selectizeParentSection

  let elemDeleteSectionModal,
    moveQuestionsFromDeletedSection = "no",
    sectionToMoveQuestionsTo;
  let addQuestionModal;
  let showQselectionTab = "";

  let selectedSecName = "";

  let deleteSection = async function () {
  
    const tempTestObj = testObj;
    if(tempTestObj.publish) {
      pAlert('Alert', 'Cannot remove section from published test'); 
      return;
    }
    if (tempTestObj.sections.length == 1) {
      // if only one, reset to All Questions
      tempTestObj.sections[0] = {
        title: "All Questions",
        default: true,
        questions: [],
      };
      tempTestObj.qTextObj = {};
    recalculate()

      await tick();
    } else {
      // if more than one, use splice

      // index to splice from
      let deleteFrom = tempTestObj.sections.findIndex(
        (sectionItem) => sectionItem.title == sectionName
      );
      if (moveQuestionsFromDeletedSection == "yes") {
        //   move Questions to other section
        let questions = tempTestObj.sections[deleteFrom].questions;
        let moveTo = tempTestObj.sections.findIndex(
          (sectionItem) => sectionItem.title == sectionToMoveQuestionsTo
        );
        // add duration
        tempTestObj.sections[moveTo].duration +=
          tempTestObj.sections[deleteFrom].duration;
        // move questions
        tempTestObj.sections[moveTo].questions = tempTestObj.sections[moveTo]
          .questions
          ? [...tempTestObj.sections[moveTo].questions, ...questions]
          : questions;

        // update in UI
        
        await tick();
      } else {
        //   delete questions from sections
        tempTestObj.sections[deleteFrom].questions.map((question) => {
          delete tempTestObj.qTextObj[question];
        });
        tempTestObj.qTextObj = { ...tempTestObj.qTextObj };
      }
      //   splice section off
      tempTestObj.sections.splice(deleteFrom, 1);
    recalculate()

      await tick();
    }
    // reset sectionToMoveQuestionsTo
    sectionToMoveQuestionsTo = "";
    // reset moveQuestionsFromDeletedSection
    moveQuestionsFromDeletedSection = "no";
    // update testObj
    testObj = { ...tempTestObj };
    _$(elemDeleteSectionModal).modal("hide");
    navItemClicked("manage-section");
  };
  let deleteQuestion = function (index, sectionTitle, qPmarks,obj) {
    
    if(testObj.publish) {
      pAlert('Alert', 'Cannot remove question from published test'); 
      return;
    }
    const list = testObj.sections.find(
      (section) => section.title == sectionTitle
    ).questions;
    let questionIdToDelete = list[index];
    list.splice(index, 1);
    testObj.sections.find(
      (section) => section.title == sectionTitle
    ).questions = list;

    const list2 = testObj.qTextObj;
    delete list2[questionIdToDelete];
    testObj.qTextObj = list2;

    // testObj.totalMaxMarks = obj.isOverrideMarks ? testObj.totalMaxMarks - obj.pmarks :  testObj.totalMaxMarks - qPmarks
    
    
    testObj = testObj;
    recalculate()
  };

  // list sequence changing
  let hoveringQuestion = false, hoveringSection = false;
  const dropQuestion = (event, target, sectionTitle) => {
    if(testObj.publish) return
    event.dataTransfer.dropEffect = "move";
    const start = parseInt(event.dataTransfer.getData("text/plain"));
    const newTracklist = testObj.sections.find(
      (section) => section.title == sectionTitle
    ).questions;

    if (start < target) {
      newTracklist.splice(target + 1, 0, newTracklist[start]);
      newTracklist.splice(start, 1);
    } else {
      newTracklist.splice(target, 0, newTracklist[start]);
      newTracklist.splice(start + 1, 1);
    }
    testObj.sections.find(
      (section) => section.title == sectionTitle
    ).questions = newTracklist;
    testObj = testObj;
    hoveringQuestion = null;
  };
  const dropSection = (event, target, sectionTitle) => {
    if(testObj.publish) return
    event.dataTransfer.dropEffect = "move";
    const start = parseInt(event.dataTransfer.getData("text/plain"));
    const newTracklist = testObj.sections
    if (start < target) {
      newTracklist.splice(target + 1, 0, newTracklist[start]);
      newTracklist.splice(start, 1);
    } else {
      newTracklist.splice(target, 0, newTracklist[start]);
      newTracklist.splice(start + 1, 1);
    }
    testObj.sections = newTracklist;
    testObj = testObj;
    hoveringSection = null;
  };

  const dragstartQuestion = (event, i) => {
    if(testObj.publish) return
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.dropEffect = "move";
    const start = i;
    event.dataTransfer.setData("text/plain", start);
  };
  const dragstartSection = (event, i) => {
    if(testObj.publish) return
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.dropEffect = "move";
    const start = i;
    event.dataTransfer.setData("text/plain", start);
  };
  // list sequence changing

  async function api_addQuestionsToTest(data) {

    let action = "test.addQuestions";
    var response = await api_handleApiCalls(action, data);
    return response;

  }

  let clearError = function (fieldName, objName) {
    if(errorObj[objName] && errorObj[objName][fieldName]) {
      delete errorObj[objName][fieldName];
    } else if (errorObj[fieldName]) {
      delete errorObj[fieldName];
    }
    errorObj = errorObj;
  };

  function recalculate() {
    testObj.totalMaxMarks = 0
    // outer for -> testObj.sections.lenght
    for (let i = 0; i < testObj.sections.length; i++) {
      const aSec = testObj.sections[i];
      if(aSec.isOverrideMarks){
        // if override marks, simple multiplication + totalMaxmARKS
        if(aSec.isLimitQuestions) {
          testObj.totalMaxMarks = aSec.pmarks*aSec.qLimit + testObj.totalMaxMarks
        } else 
          testObj.totalMaxMarks = aSec.pmarks*aSec.questions.length + testObj.totalMaxMarks
          
      } else {
        // if not override marks
            // inner for -> testObj.sections[i].questions.length
                // totalMaxMarks += testObj.qTextObj[testObj.sections[i].questions[j]].additionalInfo.pmarks 
          if(aSec.isLimitQuestions) {
            // if there is limimt to questions, then calculate total marks considering questions having most positive marks first 
            var questionsInSectionsDscOrderedByMarks = aSec.questions.sort((a, b) => {
              // sort questions by pmarks in descending order
              return testObj.qTextObj[b].additionalInfo.pmarks - testObj.qTextObj[a].additionalInfo.pmarks
            })
            for (let j = 0; j < aSec.qLimit; j++) {
              // calculate total using pmarks in descending order, if there are 5 questions with pmarks 1
              // and 2 questions with pmarks 2
              // and qLimit is 5, then max marks are (2questions*2pmarks) + (3questions*1pmakrs) = 7maxmarks
              if(testObj.qTextObj[questionsInSectionsDscOrderedByMarks[j]] && testObj.qTextObj[questionsInSectionsDscOrderedByMarks[j]].additionalInfo) {
                // this check is for skipping the question that is being removed, when removing a question 
                testObj.totalMaxMarks = testObj.qTextObj[questionsInSectionsDscOrderedByMarks[j]].additionalInfo.pmarks + testObj.totalMaxMarks
              }
            }
          } else {
            for (let j = 0; j < aSec.questions.length; j++) {
              testObj.totalMaxMarks = testObj.qTextObj[aSec.questions[j]].additionalInfo.pmarks + testObj.totalMaxMarks
            }
          }
        }   
    }
    updateParent()

  }
  let onModalSelectQuestions = async function (modalSelectedQuestions/* , isClose=true */) {
    _$(addQuestionModal).modal("hide");
    let selectedSec = _$(".manage-section-nav-header .nav-link.active").attr(
      "data-section-title"
    );

    for (let i = 0; i < testObj.sections.length; i++) {
      const aSec = testObj.sections[i];
      let counter = 0
      if (aSec.title == selectedSec) {
        for (let j = 0; j < modalSelectedQuestions.length; j++) {
          const aSelectedQuestion = modalSelectedQuestions[j].q_id;
          if (aSec.questions.indexOf(aSelectedQuestion) === -1) {
            aSec.questions.push(aSelectedQuestion);
            counter++
            testObj.qTextObj[aSelectedQuestion] = {
              qText : modalSelectedQuestions[j].questionText,
              additionalInfo: modalSelectedQuestions[j].additionalInfo,
              answer : modalSelectedQuestions[j].answer  ,
              qId:   modalSelectedQuestions[j].qId
            }
            // console.log("modalSelectedQuestions[j].qOptions::::",modalSelectedQuestions[j].qOptions)

            if(modalSelectedQuestions[j].qOptions){
              testObj.qTextObj[aSelectedQuestion].qOptions ={
                option_a: modalSelectedQuestions[j].qOptions.option_a,
                option_b: modalSelectedQuestions[j].qOptions.option_b,
                option_c: modalSelectedQuestions[j].qOptions.option_c,
                option_d: modalSelectedQuestions[j].qOptions.option_d,
               
              }

              if(  modalSelectedQuestions[j].qOptions &&  modalSelectedQuestions[j].qOptions.option_e  ) {
                testObj.qTextObj[aSelectedQuestion].qOptions['option_e'] = modalSelectedQuestions[j].qOptions.option_e
                    }
                    if(  modalSelectedQuestions[j].qOptions &&  modalSelectedQuestions[j].qOptions.option_f ) {
                      testObj.qTextObj[aSelectedQuestion].qOptions['option_f'] = modalSelectedQuestions[j].qOptions.option_f
                    }
                    if( modalSelectedQuestions[j].qOptions &&  modalSelectedQuestions[j].qOptions.option_g ) {
                      testObj.qTextObj[aSelectedQuestion].qOptions['option_g'] = modalSelectedQuestions[j].qOptions.option_g
                    }
                    if( modalSelectedQuestions[j].qOptions &&  modalSelectedQuestions[j].qOptions.option_h ) {
                      testObj.qTextObj[aSelectedQuestion].qOptions['option_h'] = modalSelectedQuestions[j].qOptions.option_h
                    }
            }

            // if(!aSec.isOverrideMarks){
            //   testObj.totalMaxMarks = testObj.totalMaxMarks + modalSelectedQuestions[j].additionalInfo.pmarks 
            // }
          }
        }
       
        // if(aSec.isOverrideMarks){
        //   testObj.totalMaxMarks = aSec.pmarks*counter + testObj.totalMaxMarks
        //   updateParent()
        // }        
      }
      
    }

    recalculate()

    // await api_addQuestionsToTest({questionList : modalSelectedQuestions, selectedSec : selectedSec, _id : testObj._id})
    testObj = { ...testObj };
    await tick();
    navItemClicked(selectedSec);
    feather.replace();

    /* if(isClose) {
      _$(addQuestionModal).modal("hide");
      navItemClicked(selectedSec);
      feather.replace();
    } */
  };

  let createNewSection = function () {

    delete errorObj.sectionName;
    delete errorObj.parentSection;

    if (!sectionName || sectionName.trim().length == 0) {
      errorObj.sectionName = "Section name cannot blank.";
    }
    if (sectionName == "All Questions") {
      errorObj.sectionName = "Section name cannot be 'All Questions'.";
    }

    if (!(testObj.sections.length == 1 && testObj.sections[0].title == 'All Questions') && !parentSection && makeSub_Section) {
      errorObj.parentSection = "Parent section is mandatory for sub-section";
      return
    }

    for (let i = 0; i < testObj.sections.length; i++) {
      const aSection = testObj.sections[i];
      if (aSection.title.toLowerCase() == sectionName.toLowerCase()) {
        errorObj.sectionName =
          "Section with name '" + sectionName + "' already exist.";
      }
    }

    if (!errorObj.sectionName) {

      
      if (makeSub_Section) {
        var indx = testObj.sections.findIndex(aSection => aSection.title == parentSection)

        testObj.sections[indx] = {
          title : parentSection,
          isParentSection : true,
          questions:[]
        }

        testObj.sections.push({
          title: sectionName,
          parentSection: parentSection,
          questions: [],
          isChildSection: true,
          isOverrideMarks: false,
          pmarks: 0,
          nmarks: 0,
        });
      }else{
        if (testObj.sections.length == 1 && testObj.sections[0].default) {
          delete testObj.sections[0].default;
          testObj.sections[0].title = sectionName;
        } else {
          testObj.sections.push({
            title: sectionName,
            questions: [],
            isOverrideMarks: false,
            pmarks: 0,
            nmarks: 0,
          });
        }
      }

      _$(modalCmp).modal("hide");
      sectionName = "";
    }
    testObj = { ...testObj };
    errorObj = { ...errorObj };
    updateParent()
  };

  let openAddSecModal = function () {
    if(testObj.publish) return;
    delete errorObj.parentSection
    makeSub_Section = null
    parentSection = null
    _$(modalCmp).modal("show");
  };
  let openAddQuestionModal = async function () {
    if(testObj.publish) return;
    _$(addQuestionModal).modal("show");
    await tick()
    showQselectionTab = "questionBank";
  };
  let openDeleteSectionModal = function (sectionTitle) {
    if(testObj.publish) {
      pAlert('Alert', 'Cannot remove section from published test'); 
      return;
    }
    sectionName = sectionTitle;
    _$(elemDeleteSectionModal).modal("show");
  };

  let navItemClicked = function (title) {
    selectedSecName = title;
    _$(".manage-section-nav-header .nav-link").removeClass("active");
    _$(
      ".manage-section-nav-header .nav-link[data-section-title='" + title + "']"
    ).addClass("active");
    _$(".a-section").hide();
    _$(".a-section[data-section-title='" + title + "']").show();
  };

  let createSections = function () {
    if (testObj.sections && testObj.sections.length > 0) {
    } else {
      testObj.sections = [
        {
          title: "All Questions",
          qLimit: 0,

          default: true,
          questions: [],
        },
      ];
    }
    tick();
    setTimeout(function () {
      _$(".manage-section-nav-header .nav-item:first-child").click();
      feather.replace();
    }, 100);
  };

  afterUpdate(function () {
    feather.replace();
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    if (testObj.loaded && !loadedOnce) {
      MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
      loadedOnce = true;
      testObj.menu = "questionList";
      testObj.isQuestionList = true;
      createSections();
      updateParent();

      _$(".override-marks-positive")
        .toArray()
        .forEach(function (field) {

          let id = _$(field).attr("id");
          let title = id.split("-")[1];
          title = title.replace(/_/g, " ");
          let sectionIndex = testObj.sections.findIndex(
            (item) => item.title == title
          );
          elemPmarkFields[sectionIndex] = new Cleave(`#${id}`, {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0, // integer only
            // delimiter: '',
            onValueChanged: function (e) {
              if (e.target.rawValue == "") {
                e.target.rawValue = 0;
                // if (testTimeEle) testTimeEle.setRawValue(0);
              }
              e.target.rawValue = Number(e.target.rawValue);
              // save state
              if (sectionIndex > -1) {
                // durations[sectionIndex] = e.target.rawValue;
                testObj.sections[sectionIndex].pmarks = e.target.rawValue;
                updateParent()
              }
            },
          });
          elemPmarkFields[sectionIndex].setRawValue(
            testObj.sections[sectionIndex].pmarks || 0
          );
        });
      _$(".override-marks-negative")
        .toArray()
        .forEach(function (field) {
          let id = _$(field).attr("id");
          let title = id.split("-")[1];
          title = title.replace(/_/g, " ");
          let sectionIndex = testObj.sections.findIndex(
            (item) => item.title == title
          );
          elemNmarkFields[sectionIndex] = new Cleave(`#${id}`, {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0, // integer only
            // delimiter: '',
            onValueChanged: function (e) {
              if (e.target.rawValue == "") {
                e.target.rawValue = 0;
                // if (testTimeEle) testTimeEle.setRawValue(0);
              }
              e.target.rawValue = Number(e.target.rawValue);
              // save state
              if (sectionIndex > -1) {
                // durations[sectionIndex] = e.target.rawValue;
                testObj.sections[sectionIndex].nmarks = e.target.rawValue;
                updateParent()
              }
            },
          });
          elemNmarkFields[sectionIndex].setRawValue(
            testObj.sections[sectionIndex].nmarks || 0
          );
        });
      _$(".test-duration")
        .toArray()
        .forEach(function (field) {
          let id = _$(field).attr("id");
          let title = id.split("-")[1];
          title = title.replace(/_/g, " ");
          let sectionIndex = testObj.sections.findIndex(
            (item) => item.title == title
          );
          elemDurationFields[sectionIndex] = new Cleave(`#${id}`, {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0, // integer only
            // delimiter: '',
            onValueChanged: function (e) {
              if (e.target.rawValue == "") {
                e.target.rawValue = 0;
                // if (testTimeEle) testTimeEle.setRawValue(0);
              }
              e.target.rawValue = Number(e.target.rawValue);
              // save state
              if (sectionIndex > -1) {
                // durations[sectionIndex] = e.target.rawValue;
                if (!testObj.sections[sectionIndex].duration)
                  testObj.sections[sectionIndex].duration = 0;

                if (totalDuration > 0)
                  totalDuration -= testObj.sections[sectionIndex].duration;
                else totalDuration = 0;

                if (
                  testObj.sections[sectionIndex].duration != e.target.rawValue
                )
                  testObj.sections[sectionIndex].duration = e.target.rawValue;
                totalDuration += testObj.sections[sectionIndex].duration;
              }
            },
          });
          elemDurationFields[sectionIndex].setRawValue(
            testObj.sections[sectionIndex].duration
          );
        });

        _$(".limit-questions")
        .toArray()
        .forEach(function (field) {
          let id = _$(field).attr("id");
          let title = id.split("-")[1];
          title = title.replace(/_/g, " ");
          let sectionIndex = testObj.sections.findIndex(
            (item) => item.title == title
          );
          elemPmarkFields[sectionIndex] = new Cleave(`#${id}`, {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0, // integer only
            // delimiter: '',
            onValueChanged: function (e) {
              if (e.target.rawValue == "") {
                e.target.rawValue = 0;
                // if (testTimeEle) testTimeEle.setRawValue(0);
              }
              e.target.rawValue = Number(e.target.rawValue);
              // save state
              if (sectionIndex > -1) {
                // durations[sectionIndex] = e.target.rawValue;
                if(testObj.sections[sectionIndex].isLimitQuestions) {
                  testObj.sections[sectionIndex].qLimit = e.target.rawValue;
                }
              }
              recalculate()
            },
          });
          // elemPmarkFields[sectionIndex].setRawValue(
          //   testObj.sections[sectionIndex].pmarks || 0
          // );
        });
    }
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  });

  let allowHideQuestionSelectModal = false;
  onMount(function () {
    feather.replace();
    if (!(testObj && testObj.sections && testObj.sections.length))
      sectionName = "All Questions";
    else sectionName = testObj.sections ? testObj.sections[0].title : "All Questions";

    if((testObj && testObj.sections && testObj.sections.length))  testObj.sections[0].qLimit =testObj.sections ? testObj.sections[0].qLimit : 0
    
    _$(modalCmp).on("show.bs.modal", function (e) {
      sectionName = "";
    });
    _$(elemDeleteSectionModal).on("hide.bs.modal", function (e) {
      sectionName = "";
    });
    _$(addQuestionModal).on("hide.bs.modal", function (e) {
      if(showQselectionTab == 'import' && !allowHideQuestionSelectModal){
       e.preventDefault();
       e.stopImmediatePropagation();

       pConfirm("Are you sure?", "List of imported questions will not be available after you close this form.", ()=> {allowHideQuestionSelectModal = true; _$(addQuestionModal).modal('hide')})
      } else {
       if(allowHideQuestionSelectModal) allowHideQuestionSelectModal = false;
        let selectedSec = _$(".manage-section-nav-header .nav-link.active").attr(
          "data-section-title"
        );
        clearError(sectionName.replace(/ /g, '_') + "_limit", 'qLimit')
        
        showQselectionTab = "";
        navItemClicked(selectedSec);
      }
    });

    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  });

  async function initParentSectionDropDown() {
        let optionArr = []

        for (let index = 0; index < testObj.sections.length; index++) {
          const aSection = testObj.sections[index];
          if(aSection.isParentSection || aSection.questions.length || !aSection.isChildSection){
            optionArr.push({title: aSection.title})
          }
        }
        
        if(makeSub_Section) {
          _$(elemParentSections)[0].selectize.clear();
        }

        if(selectizeParentSection){
          selectizeParentSection[0].selectize.destroy()
        }

        selectizeParentSection = _$(elemParentSections).selectize({
            preload: true,
            maxItems: 1,
            placeholder: 'Select Parent Section',
            valueField: "title",
            labelField: "title",
            searchField: "title",
            options: optionArr,
            onChange: function(value){
              parentSection = value;
            }
        });
    }

    function onPositiveMarksOverride(sectionId, pMarks) {

     var index =  testObj.sections.findIndex(item => item.title == sectionId)
     testObj.sections[index].pmarks = parseInt(pMarks)
     testObj.sections[index].nmarks = testObj.sections[index].nmarks || 0
     recalculate()
      
    }

    function onNegativeMarksOverride(sectionId, nMarks) {

      var index =  testObj.sections.findIndex(item => item.title == sectionId)
      testObj.sections[index].pmarks = testObj.sections[index].pmarks || 0
      testObj.sections[index].nmarks = parseInt(nMarks)
      recalculate()
    
    }

    function isOverrideMarksChanged(sectionId, isOverrideMarks) {
      let index = testObj.sections.findIndex((section) => section.title == sectionId);
      if (index > -1) {
        testObj.sections[index].isOverrideMarks = isOverrideMarks;
        testObj.sections[index].pmarks =  testObj.sections[index].pmarks ?  testObj.sections[index].pmarks : 0;
        testObj.sections[index].nmarks =  testObj.sections[index].nmarks  ?  testObj.sections[index].nmarks  : 0;
        recalculate()
      }
    }
    let editQuestion = function (index, id) {
      if(testObj.publish) {
        pAlert('Alert', 'Cannot edit question from published test'); 
        return;
      }
      let testId = testObj._id
      updateParent(true);
      let callbackUrl = "/dashboard/tests/view/"+testId+"/questionList"
      navigate(`/dashboard/questions/view/${id}?callbackUrl=${callbackUrl}`)

    }
</script>

<style>
  .empty-state-img {
    text-align: center;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
</style>

<div class="fadeIn" style="display: flex;flex-direction: column;">
  <div style="width: 1;border-bottom: 1px solid #e5e8ee; display: flex;">
    <div
      style="display: flex;align-items: center;justify-content: center;margin-left: 20px;"
      class="colored-svg">
      <i data-feather="list" class="wd-40" />
    </div>
    <div class="media-body mg-l-10 pd-y-10">
      <h6 class="mg-b-2 tx-16">Question Select</h6>
      {#if errorObj.questions && errorObj.questions.length > 0}
        <span class="d-block parsley-errors-list">{errorObj.questions}</span>
      {:else}
        <span class="d-block tx-11 tx-color-03">Set question for this test</span>
      {/if}
    </div>
    <div class="pd-y-15 mg-r-30">
      Total Marks Of Test :  {testObj.totalMaxMarks ? testObj.totalMaxMarks : 0 }
    </div> 
    <div class="pd-y-10">
      <button
        class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-r-10"
        on:click={() => {	updateParent(true);  navigate('/dashboard/tests/view/' + testObj._id )}}>
        <i data-feather="chevron-left" class="wd-10 mg-r-5" />
        Prev
      </button>
      <button
        class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-r-10"
        on:click={() => {	updateParent(true);  navigate('/dashboard/tests/view/' + testObj._id + '/assign')}}>
        Next
        <i data-feather="chevron-right" class="wd-10 mg-l-5" />
      </button>
    </div>
  </div>

  <div style="flex: 1;">
    <div data-label="Example" class="df-example">
      <ul
        class="nav nav-line manage-section-nav-header"
        id="myTab5"
        role="tablist">
        {#if testObj.sections}
          {#each testObj.sections as item}
            {#if !item.isParentSection}
            <li
              class="nav-item"
              style="margin-left: 25px;"
              on:click={() => navItemClicked(item.title)}>
              <a class="nav-link" href="#" data-section-title={item.title}>
                {item.parentSection?item.parentSection+" - ": ""}
                {item.title}
                <span
                  class="required-field"
                  class:d-none={!(errorObj.sections && errorObj.sections && errorObj.sections[item.title.replace(/ /g, '_')]) && !(errorObj.overrideMarks && errorObj.overrideMarks && errorObj.overrideMarks[item.title.replace(/_/g, '_') + "_pmark"])}>!</span>
                ({item.questions.length})
              </a>
            </li>
            {/if}
          {/each}
        {/if}
        <li
          class="nav-item "
          style="margin-left: 25px;border-left: 1px solid #ddd;padding-left: 25px;"
          on:click={() => navItemClicked('manage-section')}>
          <a class="nav-link" href="#" data-section-title="manage-section"><i
              data-feather="settings"
              style="height: 15px;" />
            Manage Sections
            {#if errorObj.manager && Object.values(errorObj.manager).length}
              <span class="required-field">!</span>
            {/if}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div style="padding: 20px; padding-top: 0;" class="test-sections">
    {#if testObj.sections}
      {#each testObj.sections as aSections}
      {#if !aSections.isParentSection}
        <div class="a-section sec-nav" data-section-title={aSections.title}>
          <div
            class="card-header d-sm-flex align-items-start justify-content-between"
            style="margin-bottom: 15px;">
            <h6 class="lh-5 mg-b-0" />
            <div class="row mg-t-20 mg-sm-t-0" style="align-items: baseline">
              <!-- marks override here -->
              <div class="form-group col-sm-2 mb-0 mg-r-0">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="section_{aSections.title.replace(/ /g, '_')}_limlit_questions"
                    name="customRadio"
                    class="custom-control-input"
                    disabled={testObj.publish}
                    checked={aSections.isLimitQuestions}
                    on:change={(event) => {
                      
                      let sectionIndex = testObj.sections.findIndex((section) => section.title == aSections.title);
                      if (sectionIndex > -1) {
                        testObj.sections[sectionIndex].isLimitQuestions = event.target.checked;
                        if (!event.target.checked) {
                          testObj.sections[sectionIndex].qLimit = 0;
                        } else if(!testObj.sections[sectionIndex].qLimit) {
                          if(testObj.sections[sectionIndex]?.questions.length) {
                            testObj.sections[sectionIndex].qLimit = testObj.sections[sectionIndex]?.questions.length
                          } else {
                            testObj.sections[sectionIndex].qLimit = 0
                          }
                        }
                        recalculate()
                      } 
                    }} />
                  <label
                    for="section_{aSections.title.replace(/ /g, '_')}_limlit_questions"
                    class="custom-control-label">Limit Questions
                  </label>
                </div>
              </div>
              <div class="form-group col-sm-2 mb-0">
                <div class="input-group mb-0">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Limit</span>
                  </div>
                  <input
                    type="number"
                    class="form-control limit-questions text-right"
                    value={aSections.qLimit}
                    title="No. of questions to limit"
                    id="sec-{aSections.title.replace(/ /g, '_')}-qLimit"
                    disabled={testObj.publish || testObj.sections && testObj.sections.length && !testObj.sections.find((section) => section.title == aSections.title).isLimitQuestions} 
                    on:blur={() => clearError(aSections.title.replace(/ /g, '_') + "_limit", 'qLimit')}
                    />
                </div>
                {#if errorObj.qLimit && errorObj.qLimit[aSections.title.replace(/ /g, '_') + '_limit']}
                  <div class="parsley-errors-list">
                    {errorObj.qLimit[aSections.title.replace(/ /g, '_') + '_limit']}
                  </div>
                {/if}
              </div>
              <div class="form-group col-sm-2 mb-0 mg-l-10">
                <div class="custom-control custom-checkbox wd-200">
                  <input
                    type="checkbox"
                    id="section_{aSections.title.replace(/ /g, '_')}_override_marks"
                    name="customRadio"
                    class="custom-control-input"
                    disabled={testObj.publish}
                    checked={testObj.sections && testObj.sections.length && testObj.sections.find((section) => section.title == aSections.title).isOverrideMarks}
                    on:change={(event) => {
                      isOverrideMarksChanged(aSections.title, event.target.checked) 
                    }} />
                  <label
                    for="section_{aSections.title.replace(/ /g, '_')}_override_marks"
                    class="custom-control-label">Override Marks
                  </label>
                </div>
              </div>
              <div class="form-group col-sm-2 mb-0">
                <div class="input-group mb-0">
                  <div class="input-group-prepend">
                    <span class="input-group-text">+ve</span>
                  </div>
                  <input
                    type="text"
                    class="form-control override-marks-positive text-right"
                    on:blur={() => clearError(aSections.title.replace(/ /g, '_') + "_pmark", 'overrideMarks')}
                    on:change={(event)=>{
                      onPositiveMarksOverride(aSections.title, event.target.value)
                    }}
                    title="Enter positive marks"
                    id="sec-{aSections.title.replace(/ /g, '_')}-pmark"
                    class:parsley-error={errorObj.overrideMarks && errorObj.overrideMarks[aSections.title.replace(/ /g, '_') + '_pmark']}
                    disabled={testObj.publish || testObj.sections && testObj.sections.length && !testObj.sections.find((section) => section.title == aSections.title).isOverrideMarks} />
                </div>
                {#if errorObj.overrideMarks && errorObj.overrideMarks[aSections.title.replace(/ /g, '_') + '_pmark']}
                  <div class="parsley-errors-list">
                    {errorObj.overrideMarks[aSections.title.replace(/ /g, '_') + '_pmark']}
                  </div>
                {/if}
              </div>
              <div class="form-group col-sm-2 mb-0">
                <div class="input-group mb-0">
                  <div class="input-group-prepend">
                    <span class="input-group-text">-ve</span>
                  </div>
                  <input
                    type="text"
                    class="form-control override-marks-negative text-right"
                    title="Enter negative marks"
                    id="sec-{aSections.title.replace(/ /g, '_')}-nmark"
                    class:parsley-error={false}
                    disabled={testObj.publish || testObj.sections && testObj.sections.length && !testObj.sections.find((section) => section.title == aSections.title).isOverrideMarks}
                    on:change={(event)=>{
                      onNegativeMarksOverride(aSections.title, event.target.value)
                    }} />
                </div>
                <!-- {#if errorObj.duration}
                                    <div class="parsley-errors-list">{errorObj.duration}</div>
                                {/if} -->
              </div>
              <div class="d-block mg-l-auto">
                <div class="d-sm-flex">
                  <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                    on:click={openAddQuestionModal}
                    disabled={testObj.publish}>
                    <i data-feather="plus" class="wd-10 mg-r-5" />
                    Add New Question
                  </button>
                </div>
              </div>
            </div>
          </div>

          {#each testObj.sections as aSec}
            {#if aSec.title == selectedSecName}
              {#if aSec.questions && aSec.questions.length}
                {#each aSec.questions as aQuestion, index (aQuestion.substr(15, 18))}
                  <div
                    class="question-display"
                    style="border-radius: 4px; border : 1px solid #ddd;    display: flex;align-items: center; margin-bottom: 15px;"
                    animate:flip
                    draggable={!testObj.publish}
                    on:dragstart={(event) => dragstartQuestion(event, index)}
                    on:drop|preventDefault={(event) => dropQuestion(event, index, aSec.title)}
                    ondragover="return false"
                    on:dragenter={() => (hoveringQuestion = index)}>
                    <!-- using svg instead of feather icon because, multiple copies of feathericon are populated as we drag and drop -->
                     <div class="col-sm-10 pd-15">
                        <strong>Q.{index + 1},  ID:{ testObj.qTextObj[aQuestion].qId}</strong>  - 
                        {@html testObj.qTextObj[aQuestion].qText}
                        {#if  testObj.qTextObj[aQuestion] && testObj.qTextObj[aQuestion].qOptions}
                        <div class="d-flex flex-row">
                          <div class="column">
                            <div class="row mg-t-20 mg-b-20 mg-t-20 mg-r-20 mg-l-0" >
                                <div class="col-6 d-flex p-2">a)  &nbsp; {@html testObj.qTextObj[aQuestion].qOptions.option_a ? testObj.qTextObj[aQuestion].qOptions.option_a  : "-"}</div>
                                <div class="col-6 d-flex p-2">b)  &nbsp; {@html testObj.qTextObj[aQuestion].qOptions.option_b ? testObj.qTextObj[aQuestion].qOptions.option_b  : "-"}</div>
                                <div class="col-6 d-flex p-2">c)  &nbsp; {@html testObj.qTextObj[aQuestion].qOptions.option_c ? testObj.qTextObj[aQuestion].qOptions.option_c  : "-"}</div>
                                <div class="col-6 d-flex p-2">d)  &nbsp; {@html testObj.qTextObj[aQuestion].qOptions.option_d ? testObj.qTextObj[aQuestion].qOptions.option_d  : "-"}</div> 
                                {#if testObj.qTextObj[aQuestion].qOptions.option_e}     
                                <div class="col-6 d-flex p-2">e)  &nbsp; {@html testObj.qTextObj[aQuestion].qOptions.option_e ? testObj.qTextObj[aQuestion].qOptions.option_e  : "-"}</div>
                                {/if}  
                                {#if testObj.qTextObj[aQuestion].qOptions.option_f}     
                                <div class="col-6 d-flex p-2">f)  &nbsp; {@html testObj.qTextObj[aQuestion].qOptions.option_f ? testObj.qTextObj[aQuestion].qOptions.option_f  : "-"}</div>
                                {/if} 
                                {#if testObj.qTextObj[aQuestion].qOptions.option_g}     
                                <div class="col-6 d-flex p-2">g)  &nbsp; {@html testObj.qTextObj[aQuestion].qOptions.option_g ? testObj.qTextObj[aQuestion].qOptions.option_g  : "-"}</div>
                                {/if} 
                                {#if testObj.qTextObj[aQuestion].qOptions.option_h}     
                                <div class="col-6 d-flex p-2">h)  &nbsp; {@html testObj.qTextObj[aQuestion].qOptions.option_h ? testObj.qTextObj[aQuestion].qOptions.option_h  : "-"}</div>
                                {/if}                                
                            </div>
                            {#if  testObj.qTextObj[aQuestion].answer}
                            <div class="mg-l-0 pd-l-0 mg-b-0  mg-t-10 col d-flex tx-bold">Ans:  &nbsp; {@html testObj.qTextObj[aQuestion].answer ? testObj.qTextObj[aQuestion].answer : '-'}</div>
                            {/if}
                          </div>
                        </div>
                        {:else}

                        <div class="d-flex flex-row">
                          <div class="column">
                            <div class="mg-l-0 pd-l-0 mg-b-0  mg-t-10 col d-flex tx-bold">Ans:  &nbsp; {@html testObj.qTextObj[aQuestion].answer ? testObj.qTextObj[aQuestion].answer : '-'}</div>
                          </div>
                        </div>

                        {/if}
                    </div>

                    <div class="col-sm-2 d-flex align-self-center justify-content-end">
                      <nav class="nav nav-icon-only">
                        <!-- disabled={testObj.publish} -->
                        <a
                        href="javascript:void(0)"
                        title="Delete"
                        class="nav-link d-sm-block"
                        on:click={(event) => editQuestion(index, aQuestion)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit wd-10 mg-r-5">
                          <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                          <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                        </svg>
                      </a>
                      <span class="pd-x-5">|</span>
                      <!-- disabled={testObj.publish} -->
                        <a
                          href="javascript:void(0)"
                          title="Delete"
                          class="nav-link d-sm-block"
                          on:click={(event) => deleteQuestion(index, aSec.title, testObj.qTextObj[aQuestion].additionalInfo.pmarks, aSec)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-trash-2 wd-10 mg-r-5"><polyline
                              points="3 6 5 6 21 6" />
                            <path
                              d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                            <line x1="10" y1="11" x2="10" y2="17" />
                            <line x1="14" y1="11" x2="14" y2="17" /></svg>
                        </a>
                        <span class="pd-x-5">|</span>
                        <a
                          href="javascript:void(0)"
                          title="drag and drop to change sequence"
                          class="nav-link d-sm-block"
                          style:cursor={testObj.publish ? 'default' : 'grab'}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                        </a>
                      </nav>
                    </div>
                  </div>
                {/each}
              {:else}
                <div class="empty-state-img">
                  <img
                    src="/images/noresult.png"
                    style="width: 25%;"
                    alt="empty" />
                  <p class="tx-bold">
                    Please select some questions(s) for this section.
                  </p>
                </div>
              {/if}
            {/if}
          {/each}
        </div>
        {/if}
      {/each}
    {/if}

    <div class="a-section" data-section-title="manage-section">
      {#if testObj.sections}
        {#if !testObj.sections[0].default}
          <div style="margin-bottom:20px;margin-top: 20px;">
            <button
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase open-create-section-modal"
              on:click={() => openAddSecModal()}
              disabled={testObj.publish}
              >
              <i data-feather="plus" class="wd-10 mg-r-5" />
              Add New Section
            </button>
            <div class="d-inline-block mg-l-10">
              {#if testObj.isDurationDividedAcrossSections == "yes"}
              Total Duration {testObj.duration || 0} Minutes
              {/if}
            </div>
          </div>
          {#each testObj.sections as item,indexSection ("manage_"+item.title.replace(/ /g, "_"))}
            <div
              animate:flip
              draggable={!testObj.publish}
              on:dragstart={(event) => dragstartSection(event, indexSection)}
              on:drop|preventDefault={(event) => dropSection(event, indexSection, item.title)}
              ondragover="return false"
              on:dragenter={() => (hoveringSection = indexSection)}
              data-label="{item.parentSection? "Parent  " + item.parentSection  + "   Sub-": ""}Section {item.title}"
              class="fieldset-label sec-field-groups"
              style="margin-bottom:20px;">
              <div class="d-flex align-self-center justify-content-end pd-b-10">
                <nav class="nav nav-icon-only">
                  <a
                    href="javascript:void(0)"
                    title="delete section"
                    class="nav-link d-sm-block"
                    on:click|stopPropagation={() => openDeleteSectionModal(item.title)} disabled={testObj.publish}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mg-r-5"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                  </a>
                  <span class="pd-x-5">|</span>
                  <a
                    href="javascript:void(0)"
                    class="nav-link d-sm-block"
                    title="drag and drop to change sequence"
                    style:cursor={testObj.publish ? 'default' : 'grab'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                  </a>
                </nav>
              </div>
              <div class="form-group row">
                <label
                  for="sec-{item.title.replace(/ /g, '_')}-title"
                  class="col-sm-2 col-form-label">Title
                  <span class="required-field">*</span></label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    value={item.title}
                    disabled={testObj.publish}
                    class="form-control"
                    id="sec-{item.title.replace(/ /g, '_')}-title"
                    placeholder="Section Title"
                    class:parsley-error={errorObj.manager && errorObj.manager[item.title.replace(/ /g, '_') + '_title']}
                    on:change={(e) => {
                      if (testObj.sections.find((sectionItem) => sectionItem.title == e.target.value)) {
                        errorObj.sectionName = "Section with name '" + sectionName + "' already exist.";
                        errorObj.manager[item.title.replace(/ /g, '_') + '_title'] = errorObj.sectionName;
                      }
                      item.title = e.target.value;
                    }} />
                </div>
                <div class="col-sm-2" />
                <div class="col-sm-10">
                  {#if errorObj.manager && errorObj.manager[item.title.replace(/ /g, '_') + '_title']}
                    <div class="parsley-errors-list col-sm-12">
                      {errorObj.manager[item.title.replace(/ /g, '_') + '_title']}
                    </div>
                  {/if}
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="sec-{item.title.replace(/ /g, '_')}-duration"
                  class="col-sm-2 col-form-label">Duration
                  {#if testObj.isDurationDividedAcrossSections == "yes"}
                    <span class="required-field">*</span>
                  {/if}
                </label>
                <div class="col-sm-10">
                  <div class="input-group">
                    <input
                      type="text"
                      id="sec-{item.title.replace(/ /g, '_')}-duration"
                      class="form-control test-duration text-right"
                      placeholder="Enter duration"
                      on:blur={() => clearError(item.title.replace(/ /g, '_') + '_duration', 'manager')}
                      class:parsley-error={errorObj.manager && errorObj.manager[item.title.replace(/ /g, '_') + '_duration']}
                      disabled={!testObj.isDurationDividedAcrossSections || testObj.isDurationDividedAcrossSections == 'no'} />
                    <div class="input-group-append">
                      <span class="input-group-text">Minutes</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-2" />
                <div class="col-sm-10">
                  {#if errorObj.manager && errorObj.manager[item.title.replace(/ /g, '_') + '_duration']}
                    <div class="parsley-errors-list col-sm-12">
                      {errorObj.manager[item.title.replace(/ /g, '_') + '_duration']}
                    </div>
                  {:else if testObj.isDurationDividedAcrossSections != "yes"}
                  <small class="text-muted">
                    To set duration for each sections, change setting "Is duration divided across sections?" to "Yes"
                  </small>
                  {/if}
                </div>
              </div>
            </div>
          {/each}
        {:else}
          <div
            style="border:1px solid #ddd; padding:60px 30px; display:flex;align-items:center;justify-content:center;flex-direction:column;margin-top: 20px;">
            <h4 id="section4" class="mg-b-10">Create your first Section</h4>
            <p class="mg-b-30 tx-center">
              Currently all the question falls under one default section.<br />
              You can create multiple section to conduct test of different
              subject
            </p>
            <button
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase open-create-section-modal"
              on:click={openAddSecModal}
              disabled={testObj.publish}><i
                data-feather="plus"
                class="wd-10 mg-r-5" />
              Add new Section
            </button>
          </div>
        {/if}
      {/if}
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal effect-scale"
  bind:this={modalCmp}
  id="modal-new-section" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered wd-sm-650" role="document">
        <div class="modal-content ">
            <div class="modal-body pd-0 ">
                <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                    <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="media align-items-center">
                        <span class="tx-color-03 d-none d-sm-block"><i data-feather="grid"
                                class="wd-60 ht-60"></i></span>
                        <div class="media-body mg-sm-l-20">
                          <h4 class="tx-18 tx-sm-20 mg-b-2">New Section</h4>
                          <p class="tx-13 tx-color-03 mg-b-0">
                            <!-- {#if testObj.sections && testObj.sections.length == 1 && testObj.sections[0].title == "All Questions"}
                            Rename "All Questions" section
                            {:else}
                            Create new section
                            {/if} -->
                            Create new section
                          </p>
                        </div>
                    </div><!-- media -->
                </div><!-- modal-header -->

                <hr class="full-width">
                <div class="row no-gutters">
                    <div class="col-12 bg-white rounded-right">
                        <div class="ht-100p d-flex flex-column justify-content-center pd-l-25 pd-r-25 pd-b-25">
                            <div class=" tx-color-02">
                                <div class="form-group">
                                    <label class="d-block">Name<span class="required-field">*</span></label>
                                    <input
                                      type="text"
                                      bind:value={sectionName}
                                      on:blur={()=> clearError('sectionName')}
                                      class="form-control"
                                      class:parsley-error={errorObj.sectionName}
                                      placeholder="Enter section name..." 
                                      on:keyup={(e)=> e.key === 'Enter' && createNewSection()}/>
                                    {#if errorObj.sectionName}
                                      <div class="parsley-errors-list">
                                        {errorObj.sectionName}
                                      </div>
                                    {/if}
                                </div>
                                <!-- {#if !(testObj.sections.length == 1 && testObj.sections[0].title == 'All Questions')} -->
                                <!--<div class="custom-control custom-checkbox ">  on:click={initParentSectionDropDown}
                                  <input type="checkbox" id="oRadio" bind:checked={makeSub_Section} on:click={initParentSectionDropDown} class:d-none={testObj.sections.length == 1 && testObj.sections[0].title == 'All Questions'} class="custom-control-input" value="subsection">
                                  <label class="custom-control-label" for="oRadio">Make it a sub-section</label>
                                </div>-->
                                <!-- {/if} -->

                              <div class:d-none={(!makeSub_Section) } class="form-group mg-t-10">
                                <label class="d-block">Select Parent Section<span class="required-field">*</span></label>
                                <select  bind:this={elemParentSections}>
                                </select>
                                {#if errorObj.parentSection}
                                <div class="parsley-errors-list">{errorObj.parentSection}</div>
                                {/if}
                              </div>
                            </div>
                            <!-- <div class="col-md-12">
                                {#if errorObj.apiError}
                                <div class="parsley-errors-list">{errorObj.apiError}</div>
                                {/if}
                            </div> -->
                            <div class=" d-flex justify-content-end ">
                                <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">
                                    CANCEL
                                    <i data-feather="x" class="wd-10 mg-l-5"></i>
                                </button>
                                <button type="button" on:click={createNewSection}
                                    class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                                    Proceed 
                                    <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

<div
  class="modal effect-scale"
  bind:this={addQuestionModal}
  id="modalTabbed"
  tabindex="-1"
  role="dialog"
  aria-hidden="true">
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="width: calc(100% - 45px);min-width: calc(100% - 45px);height: calc(100% - 60px);">
    <div
      class="modal-content"
      style="height: 100%;min-height: 100%;overflow: hidden;">
      <div
        class="modal-body pd-x-25 pd-sm-x-30 pd-t-40 pd-sm-t-20 pd-b-15 pd-sm-b-20"
        style="height: 100%;">
        <a
          href=""
          role="button"
          class="close pos-absolute t-15 r-15"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div class="nav-wrapper mg-b-20 tx-13">
          <div>
            <nav class="nav nav-line tx-medium">
              <a
                href="#questionBank"
                class="nav-link"
                class:active={showQselectionTab == 'questionBank'}
                on:click={() => {
                  if(showQselectionTab == 'import') {
                   pConfirm("Are you sure?", "List of imported questions will not be available after you close this form.", ()=> showQselectionTab = 'questionBank')
                  } else showQselectionTab = 'questionBank'
                }}
                data-toggle="tab">Question Bank</a>
              <a
                href="#previousTest"
                class="nav-link"
                class:active={showQselectionTab == 'previousTest'}
                on:click={() => {
                  if(showQselectionTab == 'import') {
                   pConfirm("Are you sure?", "List of imported questions will not be available after you close this form.", ()=> showQselectionTab = 'previousTest')
                  } else showQselectionTab = 'previousTest'
                }}
                data-toggle="tab">From Previous Test</a>
              <a
                href="#activities"
                class="nav-link"
                class:active={showQselectionTab == 'import'}
                on:click={() => {
                  showQselectionTab = 'import'
                  // pConfirm("Are you sure?", "List of imported questions will not be available after you close this form.", ()=> showQselectionTab = 'import', ()=> showQselectionTab = 'import' )
                }}
                data-toggle="tab">Import from File</a>
            </nav>
          </div>
        </div>
        <!-- nav-wrapper -->
        <div
          style="overflow: auto;height: calc(100% - 70px);    padding: 0 15px;">
          {#if showQselectionTab == 'questionBank'}
            <QuestionBank
              bind:onModalSelectQuestions
              selectedQuestions={Object.keys(testObj.qTextObj) || []}
              testId={testObj._id} />
          {/if}
          {#if showQselectionTab == 'previousTest'}
            <PreviousTests
              bind:onModalSelectQuestions
              selectedQuestions={Object.keys(testObj.qTextObj) || []}
              testId={testObj._id} />
          {/if}
          {#if showQselectionTab == 'import'}
            <QuestionsImport 
            bind:onModalSelectQuestions
            testId={testObj._id}/>
          {/if}
        </div>
      </div>
      <!-- modal-body -->
    </div>
    <!-- modal-content -->
  </div>
  <!-- modal-dialog -->
</div>
<!-- modal -->

<!-- Modal -->
<div class="modal effect-scale"
  bind:this={elemDeleteSectionModal}
  id="modal-remove-section" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered wd-sm-650" role="document">
        <div class="modal-content ">
            <div class="modal-body pd-0 ">
                <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                    <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="media align-items-center">
                        <span class="tx-color-03 d-none d-sm-block"><i data-feather="grid"
                                class="wd-60 ht-60"></i></span>
                        <div class="media-body mg-sm-l-20">
                          <h4 class="tx-18 tx-sm-20 mg-b-2"> Delete Section:
                            {sectionName}
                            ?</h4>
                          <p class="tx-13 tx-color-03 mg-b-0">
                            Delete section and move corresponding questions
                          </p>
                        </div>
                    </div><!-- media -->
                </div><!-- modal-header -->

                <hr class="full-width">
                <div class="row no-gutters">
                    <div class="col-12 bg-white rounded-right">
                        <div class="ht-100p d-flex flex-column justify-content-center pd-l-25 pd-r-25 pd-b-25">
                            <div class=" tx-color-02">
                              {#if testObj.sections && testObj.sections.length > 1}
                                <div class="form-group">
                                    <label class="d-block">Move
                                      questions to different section?<span class="required-field">*</span></label>
                                    <div class="form-row">
                                      <div class="form-group col-md-4 m-0">
                                        <div class="custom-control custom-radio">
                                          <input
                                            type="radio"
                                            id="moveQuestionsYes"
                                            name="moveQuestions"
                                            class="custom-control-input"
                                            bind:group={moveQuestionsFromDeletedSection}
                                            value="yes"
                                            disabled={!testObj.sections || testObj.sections.length < 1} />
                                          <label
                                            class="custom-control-label"
                                            for="moveQuestionsYes">Yes</label>
                                        </div>
                                      </div>
                                      <div class="form-group col-md-4 m-0">
                                        <div class="custom-control custom-radio">
                                          <input
                                            type="radio"
                                            id="moveQuestionsNo"
                                            name="moveQuestions"
                                            class="custom-control-input"
                                            bind:group={moveQuestionsFromDeletedSection}
                                            value="no"
                                            disabled={!testObj.sections || testObj.sections.length < 1} />
                                          <label
                                            class="custom-control-label"
                                            for="moveQuestionsNo">No</label>
                                        </div>
                                      </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                  <label class="d-block">Select Section</label>
                                  <div class="form-row">
                                    {#each testObj.sections.filter((item) => item.title != sectionName) as moveToSection}
                                      <div class="form-group col-md-4 m-0">
                                        <div class="custom-control custom-radio">
                                          <input
                                            type="radio"
                                            id="del-{moveToSection.title.replace(/ /g, '_')}"
                                            name="selectSectionToMoveTo"
                                            class="custom-control-input"
                                            bind:group={sectionToMoveQuestionsTo}
                                            value={moveToSection.title}
                                            disabled={testObj.sections.length < 1 || moveQuestionsFromDeletedSection == 'no'} />
                                          <label
                                            class="custom-control-label"
                                            for="del-{moveToSection.title.replace(/ /g, '_')}">{moveToSection.title}</label>
                                        </div>
                                      </div>
                                    {/each}
                                    {#if errorObj.moveToSection}
                                      <div class="parsley-errors-list">
                                        {errorObj.moveToSection}
                                      </div>
                                    {/if}
                                  </div>
                                </div>
                                {/if}
                            </div>
                            <!-- <div class="col-md-12">
                                {#if errorObj.apiError}
                                <div class="parsley-errors-list">{errorObj.apiError}</div>
                                {/if}
                            </div> -->
                            <div class=" d-flex justify-content-end ">
                                <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">
                                    CANCEL
                                    <i data-feather="x" class="wd-10 mg-l-5"></i>
                                </button>
                                <button type="button" on:click={deleteSection}
                                    class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                                    Proceed 
                                    <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
