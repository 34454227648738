<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { activeMenu } from "../../stores";
  import ButtonLoader from "../../utils/button_loader.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import { BACKEND_URL } from "../../../config.js";

  export let _id;
  let isLoading = false;
  let assignmentObj = {
    topic: "",
    submissionDate: "",
    submissionTime: "",
    notes: "",
    attachments: [],
  };

  let tempStatus;
  let submitAssignmentObj = {
    notes: "",
    submittedFile: [],
  };
  let remarkAssignmentObj = {
    remarkFile: [],
  };

  let assignmentStatus;
  let assignmentRemark;
  let elem_uploadAttachmentInput;
  let filepondOptions = {
    allowDownloadByUrl: true,
    acceptedFileTypes: ["image/*", "application/*", "text/*"],
    allowRevert: true,
    onremovefile: function (err, file) {
      PNotify.notice({
        title: "Delete Attachment",
        text: "Do you want to remove the file from attachments?",
        icon: "fas fa-question-circle",
        hide: false,
        closer: true,
        sticker: false,
        destroy: true,
        modules: new Map([
          ...PNotify.defaultModules,
          [
            PNotifyConfirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Yes",
                  primary: true,
                  click: async (notice) => {
                    notice.close();
                    var obj = {
                      originalFileName: file.filename,
                      fileExtension: file.fileExtension,
                      assignmentId: _id,
                    };
                    var res = await api_RemoveAttachmentFiles(obj);

                    if (res.status) {
                      pSuccess(
                        "Successful!!",
                        "Successfully removed an attachment.",
                      );
                    } else {
                      pError("Error!!", "Something went wrong.");
                    }
                  },
                },

                {
                  text: "No",
                  click: (notice) => {
                    notice.close();
                  },
                },
              ],
            },
          ],
        ]),
      });
    },
    server: {
      process: async (
        fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort,
        transfer,
        options,
      ) => {
        const originalFileName = file.name;

        if (containsSpecialChars(originalFileName)) {
          PNotify.error({
            title: "Error",
            text: "Please change the file name and upload again.",
            delay: 3000,
          });
          abort();
          return;
        }

        const fileExtension = file.name.split(".").pop();
        const fileSize = file.size;

        try {
          // Get presigned URL for upload
          const res = await api_getUrlForUpload({
            name: originalFileName,
            type: file.type,
          });

          if (res.status) {
            _$.ajax({
              xhr: function () {
                var xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener(
                  "progress",
                  function (e) {
                    progress(e.lengthComputable, e.loaded, e.total);
                  },
                  false,
                );
                return xhr;
              },
              url: res.data.url,
              type: "PUT",
              data: file,
              dataType: "text",
              cache: false,
              contentType: file.type,
              processData: false,
            })
              .done(async function (response) {
                const downloadUrl =
                  res.data.downloadUrl || res.data.url.split("?")[0];

                const updateResult = await api_updateAttachmentFileUrl({
                  fileId: res.data.fileId || _id,
                  originalFileName: originalFileName,
                  fileExtension: fileExtension,
                  fileSize: fileSize,
                  assignmentId: _id,
                  url: res.data.url,
                  downloadUrl: downloadUrl,
                });

                if (!updateResult.status) {
                  console.error(
                    "File metadata update failed:",
                    updateResult.errMsg,
                  );
                  error(
                    updateResult.errMsg || "Failed to update file information",
                  );
                } else {
                  load("Done");
                }
              })
              .fail(function (err) {
                console.error("Upload failed:", err);
                error("Upload failed");
              });
          } else {
            PNotify.error({
              title: "Error",
              text: "Failed to get upload URL",
              delay: 3000,
            });
            abort();
          }
        } catch (err) {
          console.error("Error during upload process:", err);
          error("Upload process failed");
          abort();
        }

        return {
          abort: () => {
            abort();
          },
        };
      },
    },
  };

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/;
    return specialChars.test(str);
  }

  onMount(async () => {
    activeMenu.set("assignmentprogress");
    _$.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
    _$.fn.filepond.registerPlugin(FilePondPluginGetFile);
    _$(elem_uploadAttachmentInput).filepond(filepondOptions);

    // _$(elem_uploadAttachmentInput).filepond('allowMultiple', true);
    let attachmentsdata = elem_uploadAttachmentInput;

    if (_id) {
      let result = await api_getAssignmentProgressById();

      if (result.status) {
        assignmentObj = {
          topic: result.data[0].assignmentDetails.topic,
          submissionDate: result.data[0].assignmentDetails.submissionDate
            ? moment(result.data[0].assignmentDetails.submissionDate).format(
                "ll",
              )
            : "-",
          submissionTime: result.data[0].assignmentDetails.submissionTime
            ? moment(result.data[0].assignmentDetails.submissionTime).format(
                "LT",
              )
            : "-",
          notes: result.data[0].assignmentDetails.notes
            ? result.data[0].assignmentDetails.notes
            : "-",
          attachments: result.data[0].assignmentDetails.attachments,
        };
        submitAssignmentObj = {
          notes: result.data[0].notes ? result.data[0].notes : "-",
          submittedFile: result.data[0].submittedFile,
        };
        remarkAssignmentObj = {
          assignmentStatus: result.data[0].assignmentStatus,
          assignmentRemark: result.data[0].assignmentRemark
            ? result.data[0].assignmentRemark
            : "No remark",
          remarkFile: result.data[0].remarkFile,
        };

        if (remarkAssignmentObj.assignmentStatus) {
          _$(`.${assignmentStatus}`).attr("disabled", "disabled");
          if (remarkAssignmentObj.assignmentStatus == "pending") {
            _$(".updateStatusBtn").attr("disabled", "disabled");
          }
          if (
            remarkAssignmentObj.assignmentStatus == "pending" ||
            remarkAssignmentObj.assignmentStatus == "submitted"
          ) {
            _$(".assignmentRemark").addClass("d-none");
          }
        }
      } else {
        PNotify.error({
          title: "Error",
          text: result.errMsg,
          delay: 3000,
        });
      }
    }
    feather.replace();
  });

  async function api_getAssignmentProgressById() {
    let action = "assignment.getAssignmentProgressById";
    var response = await api_handleApiCalls(action, { id: _id });
    return response;
  }
  async function api_updateFileName(data) {
    let action = "storage.updateFileName";
    var response = await api_handleApiCalls(action, data);
    return response;
  }
  async function api_RemoveAttachmentFiles(data) {
    let action = "assignment.removeAttachmentFile";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function api_getUrlForUpload(data) {
    try {
      const response = await fetch(
        `${BACKEND_URL}/fileupload-api/get-presigned-url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              contentType: "ASSIGNMENTS",
              fileName: data.name,
              fileExtension: data.name.split(".").pop(),
            },
            accessToken: localStorage.getItem("accessToken"),
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      // Extract both URLs from the response
      return {
        status: true,
        data: {
          url: result.presignedUrl,
          downloadUrl: result.downloadUrl || result.fileUrl || result.url,
          fileId: result.fileId,
        },
      };
    } catch (error) {
      console.error("Error getting presigned URL:", error);
      return {
        status: false,
        errMsg: "Failed to get upload URL",
      };
    }
  }

  async function api_updateAttachmentFileUrl(data) {
    let action = "assignmentprogress.updateRemarkFileUrl";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  let backBtn = async () => {
    window.history.back();
  };

  async function api_updateAssignmentStatus(data) {
    let action = "assignmentprogress.updateAssignmentStatus";
    var response = await api_handleApiCalls(action, {
      status: data.assignmentStatus,
      _id: _id,
      remark: data.assignmentRemark,
    });
    return response;
  }

  let updateAssStatusModal = async (e) => {
    tempStatus = e.target.dataset.status;
    _$("#modal-add-remark").modal("show");
  };

  // if (_id) {
  //     let files = []s

  //         files.push({
  //             source: '12345',
  //             options: {
  //                 type: 'local',
  //                 file: {
  //                     name: element.originalFileName,
  //                     size: element.fileSize,
  //                     customUrl: element.downloadUrl,
  //                     type: '*'
  //                 }
  //             }
  //         })

  //     console.log('files ', files);
  //     _$(elem_uploadAttachmentInput).filepond("files", files);
  // }

  _$(".may-pond .filepond--item-panel").css("background", "#369763");

  let updateAssStatusBtn = async (e) => {
    isLoading = true;
    assignmentStatus = tempStatus;
    _$("#modal-add-remark").modal("hide");
    var assignmentRes = await api_updateAssignmentStatus({
      assignmentStatus: assignmentStatus,
      assignmentRemark: assignmentRemark,
    });
    if (assignmentRes.status) {
      isLoading = false;
      if (assignmentStatus == "pending" || assignmentStatus == "submitted") {
        _$(".assignmentRemark").addClass("d-none");
      }
      PNotify.success({
        title: "Success",
        text: assignmentRes.data,
        delay: 3000,
      });
      window.history.back();
    } else {
      isLoading = false;
      PNotify.error({
        title: "Error",
        text: assignmentRes.errMsg,
        delay: 3000,
      });
    }
  };
</script>

<div class="content-body fadeIn">
  <div
    class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"
  >
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item">
            <a href="/dashboard/assignmentprogress">Assignment progress</a>
          </li>
          <li class="breadcrumb-item">
            <a href="/dashboard/assignmentprogress">View Assignment progress</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {assignmentObj.topic}
          </li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">
        {assignmentObj.topic}
      </h4>

      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">
        {#if assignmentStatus == "pending"}
          <span class="badge badge-secondary">{assignmentStatus}</span>
        {:else if assignmentStatus == "submitted"}
          <span class="badge badge-primary">{assignmentStatus}</span>
        {:else if assignmentStatus == "approved"}
          <span class="badge badge-success">{assignmentStatus}</span>
        {:else if assignmentStatus == "rejected"}
          <span class="badge badge-danger">{assignmentStatus}</span>
        {:else if assignmentStatus == "resubmit"}
          <span class="badge badge-warning">{assignmentStatus}</span>
        {/if}
      </p>
    </div>
    <div class="d-block">
      <button
        on:click={backBtn}
        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
      >
        Back <i data-feather="arrow-left" class="wd-10 mg-l-5"></i>
      </button>
    </div>
  </div>
  <hr class="full-width" />
  <!-- <hr class="mg-y-20"> -->
  <div class="mg-b-20 mg-lg-b-25 mg-xl-b-30">
    <div class="row row-sm mg-b-10">
      <div class="col-sm-6">
        <div class="card">
          <!-- <img src="..." class="card-img-top" alt="..."> -->
          <div class="card-header">
            <h5>Assignment</h5>
          </div>
          <div class="card-body">
            <h6 class="card-title">
              <span class="tx-bold">Topic:</span>
              {assignmentObj.topic}
            </h6>
            <p class="card-text">
              <span class="tx-bold">Submission Date:</span
              >{assignmentObj.submissionDate}
            </p>
            <p class="card-text">
              <span class="tx-bold">Submission Time:</span
              >{assignmentObj.submissionTime}
            </p>
            <p class="card-text">
              <span class="tx-bold">Note:</span>{assignmentObj.notes}
            </p>

            <div class="form-group row col-md-12">
              <label class="tx-bold">Attachments :</label>
              {#if assignmentObj.attachments}
                <ul>
                  {#each assignmentObj.attachments as file}
                    <li>
                      <a href={file.downloadUrl} target="_blank"
                        >{file.originalFileName}</a
                      >
                    </li>
                  {/each}
                </ul>
              {:else}
                -
              {/if}
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">
            <h5>Submitted Assignment</h5>
          </div>
          <div class="card-body">
            <p class="card-text">
              <span class="tx-bold">Note: </span>{submitAssignmentObj.notes}
            </p>
            <div class="form-group row col-md-12">
              <label class="tx-bold">Submitted File: </label>
              {#if submitAssignmentObj.submittedFile}
                <ul>
                  {#each submitAssignmentObj.submittedFile as file}
                    <li>
                      <a href={file.downloadUrl} target="_blank"
                        >{file.originalFileName}</a
                      >
                    </li>
                  {/each}
                </ul>
              {:else}
                -
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="d-sm-flex align-items-center justify-content-start assignmentRemark">
        <h6 class="tx-15 mg-b-10 tx-bold">Remark</h6>
        <p class="mg-b-9">{remarkAssignmentObj.assignmentRemark}</p>
        <div class="form-group row col-md-12">
            <label class="tx-bold">Submitted File: </label>
            {#if remarkAssignmentObj.remarkFile}
            <ul>
            {#each remarkAssignmentObj.remarkFile as file}
              <li>  <a href="{file.downloadUrl}">{file.originalFileName}</a></li>
            {/each}
        </ul>
            {:else}
                -
            {/if}
        </div>
    </div> -->
  <div class="row row-sm mg-b-10">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header">
          <h5>Remark:</h5>
        </div>
        <div class="card-body">
          <p class="card-text">
            <span class="tx-bold"
              >Remark:
            </span>{remarkAssignmentObj.assignmentRemark}
          </p>
          <div class="form-group row col-md-12">
            <label class="tx-bold">Remark File: </label>
            {#if remarkAssignmentObj.remarkFile}
              <ul>
                {#each remarkAssignmentObj.remarkFile as file}
                  <li>
                    <a href={file.downloadUrl} target="_blank"
                      >{file.originalFileName}</a
                    >
                  </li>
                {/each}
              </ul>
            {:else}
              -
            {/if}
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="d-sm-flex align-items-center justify-content-end">
        <div class="d-block">
          <button
            on:click={updateAssStatusModal}
            data-status="approved"
            class="btn btn-sm approved updateStatusBtn pd-x-15 btn-success btn-uppercase mg-l-5"
          >
            Approved <i data-feather="check" class="wd-10 mg-l-5"></i>
          </button>
          <button
            on:click={updateAssStatusModal}
            data-status="rejected"
            class="btn btn-sm rejected updateStatusBtn pd-x-15 btn-danger btn-uppercase mg-l-5"
          >
            Reject <i data-feather="x" class="wd-10 mg-l-5"></i>
          </button>
          <button
            on:click={updateAssStatusModal}
            data-status="resubmit"
            class="btn btn-sm resubmit updateStatusBtn pd-x-15 btn-warning btn-uppercase mg-l-5"
          >
            Resubmit <i data-feather="repeat" class="wd-10 mg-l-5"></i>
          </button>
          <button
            on:click={backBtn}
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          >
            Back
            <i data-feather="arrow-left" class="wd-10 mg-l-5"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="modal-add-remark"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content tx-14">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel6">Add Remark</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12">
          <label class="d-block">Remark</label>
          <textarea
            bind:value={assignmentRemark}
            type="text"
            class="form-control"
            placeholder="Enter your notes"
          />
        </div>
      </div>
      <fieldset class="form-fieldset">
        <legend>Add Attachment</legend>
        <div class="form-row">
          <div class="form-group col-md-12">
            <input
              type="file"
              bind:this={elem_uploadAttachmentInput}
              class="my-pond"
              data-instant-upload="false"
              data-allow-image-preview="false"
              name="filepond"
            />
          </div>
        </div>
      </fieldset>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary tx-12"
          data-dismiss="modal"
        >
          CLOSE
          <i data-feather="x" class="wd-10 mg-l-5"></i>
        </button>
        <button
          type="button"
          on:click={updateAssStatusBtn}
          class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          disabled={isLoading}
        >
          <ButtonLoader {isLoading} btnText={tempStatus} />
          <i data-feather="save" class="wd-10 mg-l-5"></i>
        </button>
      </div>
    </div>
  </div>
</div>
