<script>
  import { Router, links, Link, Route, navigate } from "svelte-routing";
  import { onMount, tick, afterUpdate } from "svelte";
  import { activeMenu } from "../../stores";
  import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
  import api_handleApiCalls from "../../utils/apiCallHandler.js";
  import { API_URL } from "../../../config.js"

  let demoDataTable;
  let studentDataTable;
  let emptyState = false;
  let assignmentObj = {
    topic: "",
  };
  let search = {
    value: "",
  };
  let assignmentList = [];
  let errorObj = {};
  let clearError = (key) => {
    delete errorObj[key];
    errorObj = { ...errorObj }; // reset state
  };
  const pageSize = 10;
  let length = pageSize;
  let assignedBatchesList = [];
  let batchDetails = [];
  let validateAssignment = () => {
    errorObj = {};
    if (!assignmentObj.topic) {
      var newErrorObj = {};
      if (!assignmentObj.topic) {
        newErrorObj.topic = "Assignment topic is required Field.";
      }
      errorObj = newErrorObj;
    }
    if (Object.keys(errorObj).length > 0) {
      return false;
    }
    return true;
  };

  let addUpdateAssignmentBtn = async () => {
    if (validateAssignment()) {
      var assignmentRes = await api_addUpdateAssignment(assignmentObj);
      if (assignmentRes.status) {
        // create success

        PNotify.success({
          title: "Success",
          text: assignmentRes.data[0],
          delay: 3000,
        });
        navigate("studentAssignment/edit/" + assignmentRes.data[1]);
      } else {
        PNotify.error({
          title: "Error",
          text: assignmentRes.errMsg,
          delay: 3000,
        });
      }
    }
  };

  async function api_addUpdateAssignment(data) {
    let action = "assignment.addUpdateAssignment";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  function hideModel(params) {
    _$("#assignment-add-modal").modal("hide");
    assignmentObj.topic = "";
  }
  function showModel(params) {
    _$("#assignment-add-modal").modal("show");
  }

  onMount(async () => {
    activeMenu.set("studentAssignment");

    var batchRes = await api_getBatch({});
    if (batchRes.status) {
      assignedBatchesList = batchRes.data.data;
    }
    bindDatatable();
  });
  async function api_getBatch(data) {
    let action = "batch.getBatchListByStudentId";
    var response = await api_handleApiCalls(action, data);
    return response;
  }

  async function deleteBtn(id) {
    let response = await api_deleteAssigment(id);
    if (response.status) {
      if (studentDataTable) {
        studentDataTable.draw();
      }
      pSuccess(null, response.data);
    } else {
      pError("Error", response.errMsg);
    }
  }

  async function api_deleteAssigment(id) {
    let action = "assignment.delete";
    var response = await api_handleApiCalls(action, { _id: id });
    return response;
  }

  const getBatchById = (batchInfo) => {
    return assignedBatchesList.find(obj => obj.batchId === batchInfo._id);
  };

  function bindDatatable() {
    studentDataTable = _$(demoDataTable).DataTable({
      responsive: true,
      language: {
        processing: "",
        searchPlaceholder: "Topic",
        sSearch: "",
        lengthMenu: "_MENU_ items/page",
      },
      ajax: function (data, callback, settings) {
        _$.ajax({
          url: API_URL,
          method: "post",
          data: {
            action: "assignment.listAssignmentByStudentId",
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: data.length,
              search: data.search.value,
            },
          },
          success: function (resData) {
            var result = [];
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.totalCount),
                recordsFiltered: Number(paginationData.filteredCount),
                data: paginationData.data,
              };
              if (!paginationData.data.length) {
                emptyState = true;
              }
              else emptyState = false;
              callback(result);
            } else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            emptyState = true;
          },
        });
      },
      columns: [
        {
          data: "assignmentDetails.topic",
          defaultContent: "<i>No data found<i>",
          render: function (data, type, full) {
            return `<div class= "btn-edit-assignment tx-capitalize" data-id="${full._id}">
                    ${data}
                    </div>`;
          },
        },
        {
          data: "batchInfo",
          defaultContent: "-",
          render: (data, type, full) => {
            var htmlstr = "";
            if (!full.batchInfo.length) {
              return "-";
            }
            for (let idx = 0; idx < full.batchInfo.length; idx++) {
              let batchInfo = full.batchInfo[idx];

              let batch = getBatchById(batchInfo);

              if (batch) {
                htmlstr += `
                       <div class="badge badge-secondary tx-capitalize" style="font-size:12px;margin-right:4px;margin-bottom:4px; text-align: center;">${batch.batchDetails.name}</div>
                       `;
              }
            }

            return htmlstr;
          },
        },
        // { data: 'mobileNo', defaultContent : '<i>No data found<i>' },

        {
          data: "assignmentDetails.createdOn",
          render: function (data, a, b, c) {
            let d = new Date(data);
            return moment(d).format("DD/MM/YYYY");
          },
        },
        {
          data: "_id",
          render: function (data, type, full) {
            let d
            if (full.assignmentDetails.publishDate) {
              d = new Date(full.assignmentDetails.publishDate);
              return d ? moment(d).format("DD/MM/YYYY") : " - ";

            }
            else {
              return " - "
            }
          },
        },

        {
          data: "assignmentDetails.submissionDate",
          render: function (data, a, b, c) {
            let d = new Date(data);
            return moment(d).format("DD/MM/YYYY");
          },
        },
        {
          data: "assignmentDetails.submissionTime",
          render: function (data, a, b, c) {
            let d = new Date(data);
            return moment(d).format("LT");
          },
        },
        {
          data: "assignmentStatus",
          render: function (data, a, b, c) {
            let show;
            if (data == "pending") {
              show = `<span class="badge badge-secondary">${data}</span>`;
            }
            if (data == "submitted") {
              show = `<span class="badge badge-primary">${data}</span>`;
            }
            if (data == "approved") {
              show = `<span class="badge badge-success">${data}</span>`;
            }
            if (data == "rejected") {
              show = `<span class="badge badge-danger">${data}</span>`;
            }
            if (data == "resubmit") {
              show = `<span class="badge badge-warning">${data}</span>`;
            }
            return show;
          },
        },
        // { data: ,                  },
        {
          data: "_id",
          render: function (data, a, b, c) {
            return `
                <div class="d-md-flex flex-row">
                    <a href="#" class="rename btn-edit-assignment mg-r-5" data-id="${data}"><i data-feather="edit" class="wd-20 mg-r-5"></i></a>
                </div>
                
                `;
          },
        },
      ],
      drawCallback: function () {
        feather.replace({ width: "1em", height: "1em" });
        _$(".btn-edit-assignment")
          .unbind("click")
          .click(function (e) {
            e.preventDefault();
            var assignmentId = _$(this).attr("data-id");

            navigate("studentAssignment/edit/" + assignmentId);
          });
        // _$('.btn-remove-assignment').unbind('click').click(function (e) {
        //     e.preventDefault();
        //     var assignmentId = _$(this).attr("data-id");
        //     pConfirm("Delete Assignment", "Are you sure you want to delete assignment?",
        //      function(){ deleteBtn(assignmentId)}
        //     )

        // })
      },
      processing: true,
      serverSide: true,
    });
    _$(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
    });
  }
</script>

<div class="content-body fadeIn">


  <div class="
      d-sm-flex
      align-items-center
      justify-content-between
      mg-b-20 mg-lg-b-25 mg-xl-b-30
    ">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Assignment</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Assignment</h4>
    </div>

  </div>



  <div data-label="Example" class="df-example demo-table">
    <table bind:this="{demoDataTable}" class="table" style="width:100%">
      <thead>
        <tr>
          <th class="wd-20p">Topic</th>
          <th class="wd-25p">Batch</th>
          <!-- <th class="wd-25p">Contact Number</th> -->
          <th class="wd-15p">Added On</th>
          <th class="wd-15p">Publish On</th>

          <th class="wd-15p">Submission Date</th>
          <th class="wd-15p">Submission Time</th>
          <th class="wd-15p">Status</th>
          <th class="wd-25p">Actions</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
    {#if emptyState }
    <div class="d-flex align-items-center justify-content-center" style="flex-direction: column">
      <div class="" style="width: 250px; margin-left: 100px">
        <img src="/images/empty.svg" class="img-fluid" alt="No Result" />
        <p class="tx-bold">
          No Assignments found.
        </p>
      </div>
    </div>
    {/if}
  </div>

</div>
