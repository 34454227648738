<script>
    import { Router, Link, Route } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import UserProfile from "./userProfile/myProfile.svelte";
    import { activeMenu } from "../stores";
    export let from="";
    onMount(function(){
    console.log("mounting systemUser list");
    activeMenu.set("myprofile"+from);
  })
</script>

<Router>
    
    <Route path="" >
        <UserProfile  ></UserProfile>
    </Route>
      
</Router>
<!-- <script>
    import { Router, Link, Route } from "svelte-routing";
    import List from "./list/list.svelte";
    import AddUpdate from "./addupdate/addupdate.svelte";
    import { onMount, tick } from "svelte";
    import { activeMenu } from "../stores";
  
    function init() {
      console.log("mounting systemUser list");
      activeMenu.set("systemuser");
    }
    onMount(init);
  </script>
  
  <Router>
    <Route path=":id" let:params>
      <AddUpdate id={params.id} />
    </Route>
    <Route path="">
      <List />
    </Route>
  </Router -->
  
