<script>
  import { onMount, tick } from "svelte";
  import { links } from "svelte-routing";
  import { activeMenu } from "../stores";
  import { userData } from "../stores";
  import ButtonLoader from "../utils/button_loader.svelte";
  import api_handleApiCalls from "../utils/apiCallHandler.js";
  import MODULES from "../utils/modules.js"
  import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";
  import { API_URL } from "../../config.js"

  export let from;
  let action;
  let isLoading = false;
  let isSaveLoading = false
  let disabled;
  let demoDataTable;
  let testDataTable;
  let modelId = 0;
  let receiptSeries = {
    _id: "",
    branchId: "",
    oldBranchId: "",
    oldPrefix: [],
    prefix: "",
    series: "",
    isOnline: false,
    isOffline: false,
    isPdc: false
  }

  let branchForRecipt;
  let selectedBranch = "";

  let errorDetails = {};

  let addNewReceiptSeriesModal;

  async function clearValidations() {
    errorDetails = {}
    await tick();
  }
  async function clearDateValidation() {
    delete errorDetails["series"];
    errorDetails = errorDetails;
    await tick();
  }

  async function clearReportValidation() {
    delete errorDetails["prefix"];
    errorDetails = errorDetails;
    await tick();
  }

  async function clearBranchValidation() {
    delete errorDetails["branch"];
    errorDetails = errorDetails;
    await tick();
  }

  function validate(data) {
    clearValidations();
    if (data.branchId == "") {
      errorDetails.branchId = "Please Select branch ";
    }
    if (!data.prefix) {
      errorDetails.prefix = " Prefix Should not be empty";
    }
    if (!data.series) {
      errorDetails.series = " Series Should not be empty";
    }
    if (!receiptSeries.isOffline && !receiptSeries.isOnline && !receiptSeries.isPdc) {
      pError(null, "Please select one mode of payment")
      return
    }
    if (Object.values(errorDetails).length > 0) {
      return false;
    } else {
      return true;
    }
  }
  let resetSeriesObject = function () {
    receiptSeries = {
      _id: "",
      branchId: "",
      prefix: "",
      oldPrefix: [],
      oldBranchId: "",
      series: "",
      isOnline: false,
      isOffline: false,
      isPdc: false
    }
    selectedBranch[0].selectize.setValue("");
  }

  async function showModal() {
    modelId = 0;
    feather.replace();
    receiptSeries = {
      _id: "",
      branchId: "",
      prefix: "",
      oldPrefix: [],
      oldBranchId: "",
      series: "",
      isOnline: false,
      isOffline: false,
      isPdc: false

    }
    // selectedBranch[0].selectize.setValue("");
    resetSeriesObject();
    clearValidations();
    await tick();
    _$(addNewReceiptSeriesModal).modal("show");
  }


  async function api_getReportById(_id) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var payload = JSON.stringify({
      action: "receiptSeries.fetchSingleReceiptSeries",
      accessToken: localStorage.getItem("accessToken"),
      data: { _id: _id },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };
    try {
      var resObj = await fetch(API_URL, requestOptions);
      var response = await resObj.json();
      return response.data;
    } catch (error) {
      console.error("error", error);
      return { error: error };
    }
  }

  async function api_getReceiptSeriesForEdit(data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var payload = JSON.stringify({
      action: "receiptSeries.seriesEdit",
      accessToken: localStorage.getItem("accessToken"),
      data: data
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };
    var resObj = await fetch(API_URL, requestOptions);
    var response = await resObj.json();
    if (response.errMsg) {
      PNotify.error({
        title: 'Error',
        text: response.errMsg,
        delay: 3000
      })
    } else {
      PNotify.success({
        title: 'success',
        text: "successfully updated a receipt series",
        delay: 3000
      })

    }

    return response.data;
  }
  async function api_deleteReceiptSeriesById(data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var payload = JSON.stringify({
      action: "receiptSeries.delete",
      accessToken: localStorage.getItem("accessToken"),
      data: data
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };
    var resObj = await fetch(API_URL, requestOptions);
    var response = await resObj.json();
    return response.data;
  }

  async function api_fetchInstituteBranchList(data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var payload = JSON.stringify({
      action: "instituteBranch.fetchInstituteBranchList",
      accessToken: localStorage.getItem("accessToken"),
      data: data,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };
    try {
      var resObj = await fetch(API_URL, requestOptions);
      var response = await resObj.json();
      return response;
    } catch (error) {
      console.error("error", error);
      return { error: error };
    }
  }

  let loadBranchBranch = async function () {

    var branchRes = await api_fetchInstituteBranchList({ start: 0, length: 100, sort: { name: 1 } });

    let branchArr = branchRes.data.data;

    selectedBranch = _$(branchForRecipt).selectize({
      maxItems: 1,
      valueField: '_id',
      labelField: 'name',
      searchField: 'name',
      options: branchArr,
      create: false,
      onChange: function (value) {
        receiptSeries.branchId = value;
      }
    });
  }

  function bindWheelDisable(params) {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number" &&
        document.elementFromPoint(event.x, event.y) == document.activeElement) {
        document.activeElement.blur();
      }
    });
  }
  function bindModalAnimations() {
    _$(addNewReceiptSeriesModal).on("show.bs.modal", function (event) {
      _$(this).addClass("effect-scale");
    });

    _$(addNewReceiptSeriesModal).on("hidden.bs.modal", function (e) {
      _$(this).removeClass("effect-scale");
    });

  }

  let onReceiptSeriesAdd = async function () {
    if (isLoading) return;
    isLoading = true;
    await tick()
    if (!validate(receiptSeries)) {
      isLoading = false;
      return false;
    }
    let action = "";
    if (receiptSeries._id) {
      action = "receiptSeries.edit";
    } else {
      action = "receiptSeries.add";
      delete receiptSeries._id;
    }
    let result = await api_handleApiCalls(action, receiptSeries)
    if (result.errMsg) {
      PNotify.error({
        title: 'Error',
        text: result.errMsg,
        delay: 3000
      })
    } else {
      PNotify.success({
        title: 'success',
        text: result.data.msg,
        delay: 3000
      })

    }
    isLoading = false;
    testDataTable.ajax.reload();
    resetSeriesObject();
    _$(addNewReceiptSeriesModal).modal("hide");
  }
  async function api_addReceiptSeries(data) {
    let response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({
        action: "receiptSeries.add",
        accessToken: localStorage.getItem("accessToken"),
        data: data,
      }),
    });
    let result = await response.json();
    if (result.errMsg) {
      PNotify.error({
        title: 'Error',
        text: result.errMsg,
        delay: 3000
      })
    } else {
      PNotify.success({
        title: 'success',
        text: "Receipt series added successfully",
        delay: 3000
      })
    }
    return result;
  }

  let editReceiptSeries = async function (_id) {

    var response = await api_handleApiCalls("receiptSeries.getReceiptById", { _id });
    if (response && response.data) {
      receiptSeries._id = response.data._id;
      receiptSeries.oldPrefix = response.data?.oldPrefix?.length ? [...response.data.oldPrefix] : [response.data.prefix];
      receiptSeries.prefix = response.data.prefix;
      receiptSeries.series = response.data.series;
      receiptSeries.oldBranchId = response.data.branchId || "",
        receiptSeries.branchId = response.data.branchId || "",

        selectedBranch[0].selectize.setValue(receiptSeries.branchId);  //selectize setValue

      receiptSeries.isOnline = response.data.isOnline,
        receiptSeries.isOffline = response.data.isOffline,
        receiptSeries.isPdc = response.data.isPdc
      await tick();
      feather.replace();
      _$(addNewReceiptSeriesModal).modal("show");
    } else {
      PNotify.error({
        title: 'Error',
        text: "Something went wrong",
        delay: 3000
      })
    }
  }
  let onReceiptSeriesEdit = async function () {
    if (isLoading) return;
    isLoading = true;
    await tick()
    let isValid = await validate(receiptSeries);
    if (!isValid) {
      isLoading = false;
      return false;
    }

    let res = await api_getReceiptSeriesForEdit(receiptSeries);
    isLoading = false;
    testDataTable.ajax.reload();
    resetSeriesObject();
    clearValidations();
    _$(addNewReceiptSeriesModal).modal("hide");
  }

  loadBranchBranch()

  function parseSorts(sortOrder) {
    // parses datatable order to mongodb sort object
    let sort = {};
    // sorting orders
    let order;
    if (sortOrder.dir === "asc") order = 1;
    else order = -1;
    if (sortOrder.column === 1) {
      sort.seqId = order;
    } else if (sortOrder.column === 2) {
      sort.prefix = order;
    } else if (sortOrder.column === 3) {
      sort.series = order;
    } else if (sortOrder.column === 4) {
      sort.createdOn = order;
    } else {
      sort._id = order;
    }
    return sort;
  }
  function bindDatatable() {
    testDataTable = _$(demoDataTable).DataTable({
      stateSave: true,
      "stateSaveCallback": function (settings, data) {
        localStorage.setItem(window.location.pathname, JSON.stringify(data))
      },
      "stateLoadCallback": function (settings) {
        return JSON.parse(localStorage.getItem(window.location.pathname))
      },
      responsive: true,
      language: {
        processing: "",
        searchPlaceholder: 'Search Receipt prefix',
        sSearch: '',
        lengthMenu: '_MENU_ Receipt Series/page',
      },
      ajax: function (data, callback, settings) {
        data.sort = parseSorts(data.order ? data.order[0] : { 0: "asc" });
        _$.ajax({
          url: API_URL,
          method: 'post',
          data: {
            action: "receiptSeries.list",
            accessToken: localStorage.getItem("accessToken"),
            data: {
              start: data.start,
              length: data.length,
              search: data.search.value,
              sort: data.sort
            }
          },
          success: function (resData) {

            var result = []
            if (resData.status) {
              var paginationData = resData.data;
              result = {
                recordsTotal: Number(paginationData.total_count),
                recordsFiltered: Number(paginationData.filtered_count),
                data: paginationData.data
              }
              if (resData?.data?.data.length) {
                // emptyState = true
              }
              callback(result);
            }
            else {
              console.log("Something went wrong");
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            // emptyState = true
          }
        })
      },
      columns: [
        {
          data: 'seqId', render: function (data, type, full) {
            return data;
          }
        },
        {
          data: 'prefix', render: function (data, type, full) {

            return `
              <div class="btn-edit-test" series-id="${full._id}"  style="max-height: 38px !important; overflow:hidden;">
                ${data}
              </div>
            `;
          }
        },
        {
          data: 'series', render: function (data, type, full) {

            return `
              <div class="btn-edit-test" series-id="${full._id}"  style="max-height: 38px !important; overflow:hidden;">
                ${data}
              </div>
            `;
          }
        },
        {
          data: 'isOnline', render: function (data, type, full) {
            let isOnlineStatus = full.isOffline && data ? "Online | Cash Default" : data ? "Online" : full.isOffline ? "Cash Default" : full.isPdc ? "PDC" : "-"
            return isOnlineStatus;
          }
        },
        {
          data: 'createdOn', render: function (data, type, full) {
            let date = convertDateObjToStr(data);
            date = date.split("-")
            date = date.reverse();
            date = date.join("-");
            return date;
          }
        },
        {
          data: '_id', render: function (data, type, full) {
            // <a href="#" class="rename d-none mg-r-5 btn-edit-test" series-id="${data}"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>
            var edit = ""
            var del = ""
            if (MODULES.hasAccess(MODULES.VIEW_RECEIPT_SERIES_PAYMENT) && !(MODULES.hasAccess(MODULES.DELETE_RECEIPT_SERIES_PAYMENT))) {
              edit = ` <a href="#" class="rename mg-r-5 btn-edit-test" series-id="${data}"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>`
            } else if (MODULES.hasAccess(MODULES.DELETE_RECEIPT_SERIES_PAYMENT) && !(MODULES.hasAccess(MODULES.VIEW_RECEIPT_SERIES_PAYMENT))) {
              del = ` <a href="#" class="rename mg-r-5 btn-delete-test" series-id="${data}"><i class="wd-20 mg-r-5" data-feather="trash-2"></i></a>`
            } else if (!(MODULES.hasAccess(MODULES.DELETE_RECEIPT_SERIES_PAYMENT)) && !(MODULES.hasAccess(MODULES.VIEW_RECEIPT_SERIES_PAYMENT))) {
              edit = ""
              del = ""
            } else {
              edit = ` <a href="#" class="rename mg-r-5 btn-edit-test" series-id="${data}"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>`
              del = `<a href="#" class="rename mg-r-5 btn-delete-test" series-id="${data}"><i class="wd-20 mg-r-5" data-feather="trash-2"></i></a>`
            }
            return `
              <div class="d-md-flex flex-row justify-content-center">
               ${edit}
               ${del}
              </div>
              `;
            // <a href="#" class="rename mg-r-5 btn-edit-test" series-id="${data}"><i class="wd-20 mg-r-5" data-feather="edit"></i></a>
            //   <a href="#" class="rename mg-r-5 btn-delete-test" series-id="${data}"><i class="wd-20 mg-r-5" data-feather="trash-2"></i></a>
          }
        },

      ],
      "drawCallback": function () {
        feather.replace()

        _$('.btn-edit-test').unbind('click').click(
          async function (e) {
            e.preventDefault();
            var seriesId = _$(this).attr("series-id");
            await editReceiptSeries(seriesId);
          })

        _$('.btn-delete-test').unbind('click').click(function (e) {
          e.preventDefault();
          var seriesId = _$(this).attr("series-id");
          const notice = PNotify.notice({
            title: "Delete Series",
            text: "Are you sure you want to delete this receipt series ?",
            hide: false,
            closer: false,
            sticker: false,
            modules: new Map([
              ...PNotify.defaultModules,
              [PNotifyConfirm, { confirm: true }],
            ]),
          })


          notice.on("pnotify:confirm", async () => {
            try {
              var deleteReceiptSeries = await api_deleteReceiptSeriesById({ _id: seriesId });
              if (deleteReceiptSeries) {
                PNotify.success({
                  title: 'Success',
                  text: "Receipt series deleted successfully",
                  delay: 3000
                })
              } else {
                PNotify.error({
                  title: 'Error',
                  text: deleteReceiptSeries.errMsg,
                  delay: 3000
                })
              }

            } catch (error) {
              console.error(error);
            }
            testDataTable.ajax.reload();
          })

        })
      },
      "processing": true,
      "serverSide": true,
    });
    _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
  }
  function convertDateObjToStr(date) {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var dateString = [year, month, day].join("-");
    return dateString;
  }
  onMount(function () {
    feather.replace();
    loadBranchBranch();
    bindDatatable()
    bindModalAnimations();
    bindWheelDisable();

  })

  function resetOtherMode() {
    receiptSeries.isOnline = false;
    receiptSeries.isOffline = false;
    receiptSeries.isPdc = !receiptSeries.isPdc;
  }
  function onClickToggleCashCheckbox() {
    receiptSeries.isOffline = !receiptSeries.isOffline;;
    receiptSeries.isPdc = false
  }
  function onClickToggleOnlineCheckbox() {
    receiptSeries.isOnline = !receiptSeries.isOnline;;
    receiptSeries.isPdc = false
  }
  let onReceiptSeriesAddAnother = async function () {
    if (isSaveLoading) return;
    isSaveLoading = true;
    await tick()
    if (!validate(receiptSeries)) {
      isSaveLoading = false;
      return false;
    }
    let action = "";
    if (receiptSeries._id) {
      action = "receiptSeries.edit";
    } else {
      action = "receiptSeries.add";
      delete receiptSeries._id;
    }
    let result = await api_handleApiCalls(action, receiptSeries)
    if (result.errMsg) {
      PNotify.error({
        title: 'Error',
        text: result.errMsg,
        delay: 3000
      })
    } else {
      PNotify.success({
        title: 'success',
        text: result.data.msg,
        delay: 3000
      })

    }
    isSaveLoading = false;
    testDataTable.ajax.reload();
    resetSeriesObject();
    // _$(addNewReceiptSeriesModal).modal("hide");
  }
</script>
<div class="content-body fadeIn">
  <!-- breadcrumbs -->
  <div class="d-sm-flex align-items-center justify-content-between mg-b-20
          mg-lg-b-25 mg-xl-b-30">
    <div>
      <nav use:links aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Payment</li>
          <li class="breadcrumb-item active" aria-current="page">Receipt Series</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Receipt Series</h4>
      <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search or add new receipt series </p>
    </div>
    <div class="d-block">
      <div class="d-sm-flex">
        {#if MODULES.hasAccess(MODULES.ADD_RECEIPT_SERIES_PAYMENT) }
        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" on:click={showModal}>
          <i data-feather="plus" class="wd-10 mg-r-5"></i> Add Series
        </button>
        {/if}
      </div>
    </div>
  </div>

  <hr class="full-width">
  <div data-label="Example" class="df-example ">
    <table bind:this={demoDataTable} class="table table-hover table-sm">
      <thead>
        <tr>
          <th style="text-align: center;">#</th>
          <th>Prefix</th>
          <th>Series</th>
          <th>Mode Type</th>
          <th>Created Date</th>
          <th style="text-align: center;">Action</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
</div>


<div class="modal effect-scale" bind:this={addNewReceiptSeriesModal} id="modal-new-batch" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered wd-sm-650" role="document">
    <div class="modal-content ">
      <div class="modal-body pd-0">
        <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
          <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="media align-items-center">
            <span class="tx-color-03 d-none d-sm-block"><i data-feather="trello" class="wd-60 ht-60"></i></span>
            <div class="media-body mg-sm-l-20">
              {#if modelId==0}
              <h4 class=" tx-18 tx-sm-20 mg-b-2">
                Receipt Series
              </h4>
              <p class="tx-13 tx-color-03 mg-b-0">Add new receipt series below</p>
              {:else}
              <h4 class="tx-18 tx-sm-20 mg-b-2">
                Edit receipt series
              </h4>
              <p class="tx-13 tx-color-03 mg-b-0">Edit the receipt series below</p>
              {/if}

            </div>
          </div><!-- media -->
        </div><!-- modal-header -->
        <hr class="full-width">


        <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>

        <div class="row no-gutters">
          <div class="col-12 bg-white rounded-right">
            <div class="ht-100p d-flex flex-column justify-content-center pd-l-25 pd-r-25 pd-b-25">

              <div class=" tx-color-02">
                <div class="form-group">
                  <label class="d-block pd-t-10 pd-b-0 mg-b-0">Branch
                    <span class="required-field">*</span>
                  </label>
                  <select bind:this={branchForRecipt} class="info slectizedd form-control"
                    class:parsley-error={errorDetails.branchId} placeholder="Select Branch">
                  </select>
                  {#if errorDetails.branchId}
                  <div class="parsley-errors-list">{errorDetails.branchId}</div>
                  {/if}


                  <label class="d-block pd-t-10 pd-b-0 mg-b-0">Prefix
                    <span class="required-field">*</span></label>
                  <input id="prefix" bind:value={receiptSeries.prefix} on:keyup={(e)=> e.key === 'Enter' &&
                  onReceiptSeriesAdd()}
                  on:focus={clearReportValidation}
                  type="text"
                  class="form-control first-input"
                  class:parsley-error={errorDetails.prefix}
                  placeholder="Enter prefix" />

                  {#if errorDetails.prefix}
                  <div class="parsley-errors-list">{errorDetails.prefix}</div>
                  {/if}

                  <label class="d-block pd-t-10 pd-b-0 mg-b-0">Series
                    <span class="required-field">*</span></label>
                  <input id="series" disabled={receiptSeries._id} type="number" bind:value={receiptSeries.series}
                    on:keyup={(e)=> e.key === 'Enter' && onReceiptSeriesAdd()}

                  on:focus={clearDateValidation}
                  class="form-control first-input"
                  class:parsley-error={errorDetails.series}
                  placeholder="Enter series" />
                  {#if errorDetails.series}
                  <div class="parsley-errors-list">{errorDetails.series}</div>
                  {/if}
                  <label class="d-block pd-t-10 pd-b-0 mg-b-0">Payment Mode
                    <span class="required-field">*</span></label>
                  {#if receiptSeries._id && receiptSeries.isPdc}
                  <div class="form-group row pd-l-25">
                    <label class="pd-t-20  pd-b-0 mg-b-0">PDC</label>
                    <input type="checkbox" on:click={resetOtherMode} bind:checked={receiptSeries.isPdc}
                      class="pd-t-30 custom-checkbox mg-l-10 mg-t-25" name="" id="default">
                  </div>

                  {:else if receiptSeries._id && !receiptSeries.isPdc}
                  <div class="form-group row pd-l-25">
                    <label class="pd-t-20 pd-b-0 mg-b-0">Cash Default</label>
                    <input type="checkbox" bind:checked={receiptSeries.isOffline}
                      class="custom-checkbox pd-t-30  mg-l-10  mg-t-25" name="" id="default">
                    <label class="pd-t-20 mg-l-40 pd-b-0 mg-b-0">Online Default</label>
                    <input type="checkbox" on:click={resetOtherMode} bind:checked={receiptSeries.isOnline}
                      class="pd-t-30 custom-checkbox mg-l-10 mg-t-25" name="" id="default">
                  </div>
                  {:else}
                  <!-- else content here -->
                  <div class="form-group row pd-l-25">
                    <label class="pd-t-20 pd-b-0 mg-b-0">Cash Default</label>
                    <input type="checkbox" on:click={onClickToggleCashCheckbox} bind:checked={receiptSeries.isOffline}
                      class="custom-checkbox pd-t-30  mg-l-10  mg-t-25" name="" id="default">
                    <label class="pd-t-20 mg-l-40 pd-b-0 mg-b-0">Online Default</label>
                    <input type="checkbox" on:click={onClickToggleOnlineCheckbox} bind:checked={receiptSeries.isOnline}
                      class="pd-t-30 custom-checkbox mg-l-10 mg-t-25" name="" id="default">
                    <label class="pd-t-20 mg-l-40 pd-b-0 mg-b-0">PDC</label>
                    <input type="checkbox" on:click={resetOtherMode} bind:checked={receiptSeries.isPdc}
                      class="pd-t-30 custom-checkbox mg-l-10 mg-t-25" name="" id="default">
                  </div>
                  {/if}


                </div>

              </div>
              <div class=" d-flex justify-content-end">
                {#if modelId==0}
                <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">
                  CANCEL
                  <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                {#if MODULES.hasAccess(MODULES.UPDATE_RECEIPT_SERIES_PAYMENT) }
                <button type="button" on:click={onReceiptSeriesAddAnother} disabled={isSaveLoading || isLoading}
                  class="btn btn-sm pd-x-15 tx-12 btn-primary btn-uppercase mg-l-5">
                  <ButtonLoader isLoading={isSaveLoading} btnText="Save & Add Another" />
                  <i data-feather="save" class="wd-10 mg-l-5"></i>
                </button>
                <button type="button" on:click={onReceiptSeriesAdd} disabled={isLoading || isSaveLoading}
                  class="btn btn-sm pd-x-15 tx-12 btn-primary btn-uppercase mg-l-5">
                  <ButtonLoader isLoading={isLoading} btnText="Save" />
                  <i data-feather="save" class="wd-10 mg-l-5"></i>
                </button>
                {/if}
                {:else}
                <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">
                  CANCEL
                  <i data-feather="x" class="wd-10 mg-l-5"></i>
                </button>
                {#if MODULES.hasAccess(MODULES.UPDATE_RECEIPT_SERIES_PAYMENT) }
                <button type="button" on:click={onReceiptSeriesAddAnother} disabled={isLoading}
                  class="btn btn-sm pd-x-15 tx-12 btn-primary btn-uppercase mg-l-5">
                  <ButtonLoader isLoading={isLoading} btnText="Save & Add Another" />
                  <i data-feather="save" class="wd-10 mg-l-5"></i>
                </button>
                <button type="button" on:click={onReceiptSeriesEdit}
                  class="btn btn-sm pd-x-15 tx-12 btn-primary btn-uppercase mg-l-5">
                  <ButtonLoader isLoading={isLoading} btnText="Save" />
                  <i data-feather="save" class="wd-10 mg-l-5"></i>
                </button>
                {/if}
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
