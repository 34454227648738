<script context="module">
  export function pConfirm(title, text, onOk = () => {}, onCancel = () => {}) {
    const notice = PNotify.notice({
      title: title ? title : "Confirmation Needed",
      text: text ? text : "Are you sure?",
      hide: false,
      closer: false,
      sticker: false,
      modules: new Map([
        ...PNotify.defaultModules,
        [PNotifyConfirm, { confirm: true }],
      ]),
    });
    notice.on("pnotify:confirm", onOk);
    notice.on("pnotify:cancel", onCancel);
  }

  export function pAlert(title, text) {
    PNotify.alert({
      title: title ? title : "Alert",
      text: text ? text : "",
      delay: 3000,
    });
  }

  export function pNotice(title, text) {
    PNotify.notice({
      title: title ? title : "Notice",
      text: text ? text : "",
      delay: 3000,
    });
  }

  export function pInfo(title, text) {
    PNotify.info({
      title: title ? title : "Info",
      text: text ? text : "",
      delay: 3000,
    });
  }

  export function pSuccess(title, text) {
    PNotify.success({
      title: title ? title : "Success",
      text: text ? text : "",
      delay: 3000,
    });
  }

  export function pError(title, text) {
    PNotify.error({
      title: title ? title : "Error",
      text: text ? text : "",
      delay: 3000,
    });
  }
</script>
