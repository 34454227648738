<script>
    import { links, link, navigate } from "svelte-routing";
    import { onMount, tick, afterUpdate } from "svelte";
    import { activeMenu } from "../../stores";
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import ButtonLoader from "../../utils/button_loader.svelte";
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import { API_URL } from "../../../config.js"

    let isLoadingList = true;
    let isLoading = false;
    let addNewClassModal;
    let studentGroupModal
    let groupName
    const classObj = { name: "" };
    let errorObj = {};
    let displayText = "Class List";
    let classesList = { data: [] };
    let search = { value: "" };
    let sessionDataTable;
    let elem_sessionDataTable
    const pageSize = 100;

    async function api_delete(data) {

        let action = "chat.deleteGroup";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_createChannel(data) {

        let action = 'chat.createChannel';
        var response = await api_handleApiCalls(action, data);
        return response;

    }


    async function showModal() {
        _$(studentGroupModal).modal("show");
    }

    function validate(name) {
        //   clear previous validations before validating anew
        clearValidations();

        if (!name) {
            errorObj.name = "Class name can not be empty";
            return false
        }

        var regexpStartingWithAlphabet = new RegExp(`^[A-Za-z0-9]`);

        name = name.trim();

        if (!name.match(regexpStartingWithAlphabet)) {

            errorObj.name = "Name must begin with A-Z or a-z characters";
        } else if (name.length < 3) {

            errorObj.name = "Name should be minimum 3 characters long";
        }

        if (Object.values(errorObj).length > 0) {
            return false;
        } else {
            return true;
        }
    }


    async function clearFields() {
        // form fields
        if (classObj._id) delete classObj._id;
        classObj.name = "";
        await clearValidations();
    }

    async function clearValidations() {
        errorObj = {};
        await tick();
    }

    function parseSorts(sortOrder) {
        // parses datatable order to mongodb sort object
        let sort = {};
        // sorting orders
        let order;
        if (sortOrder.dir === "asc") order = 1;
        else order = -1;
        // if (sortOrder.column === 1) {
        //   sort.name = order;
        // } else if (sortOrder.column === 2) {
        //   sort.createdOn = order;
        // } else {
        //   sort.seqId = order;
        // }
        sort.seqId = order;
        return sort;
    }

    async function createGroup() {
        if (isLoading) return;
        isLoading = true;
        await tick()
        if (!groupName) {
            errorObj.groupName = "Group name can not be empty";
            isLoading = false;
            return false
        }

        console.log('new channel ', groupName);

        let result = await api_createChannel({ name: groupName });
        if (result.status) {
            sessionDataTable.ajax.reload();
            pSuccess(null, "Successfully created a group");
            groupName = ""
        } else if (result.errMsg) {
            pError(null, result.errMsg);
        } else if (result.error) {
            pError(null, result.error);
        } else {
            pError(null, "Something went wrong");
        }
        _$(studentGroupModal).modal('hide');
        isLoading = false;
    }

    async function editSession(_id) {
        navigate(`/dashboard/doubtSession/new/${_id}`)
    }


    async function reload() {
        //   await fetchCourseList();
        await bindDatatable();
        feather.replace({ width: "1em", height: "1em" });
    }

    function bindDatatable() {
        sessionDataTable = _$(elem_sessionDataTable).DataTable({

            responsive: false,
            language: {
                processing: "",
                searchPlaceholder: 'Group Name',
                sSearch: '',
                lengthMenu: '_MENU_ Groups/page',
            },
            ajax: function (data, callback, settings) {
                data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
                _$.ajax({
                    url: API_URL,
                    method: 'post',
                    data: {
                        action: 'chat.getChannelList',
                        accessToken: localStorage.getItem("accessToken"),
                        data: {
                            start: data.start,
                            length: data.length,
                            search: data.search.value,
                            draw: data.draw,
                            sort: data.sort,
                        },

                    },
                    success: function (resData) {
                        var result = []
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.recordsTotal),
                                recordsFiltered: Number(paginationData.recordsFiltered),
                                data: paginationData.data
                            }
                            console.log("result>>>>>", result)
                            callback(result);
                        }
                        else {
                            console.log("Something went wrong");
                        }
                    }
                })
            },
            columns: [
                {
                    data: 'seqId', defaultContent: "-",
                    render: function (data, type, row, meta) {
                        return data;
                    }
                },
                {
                    data: 'name', render: function (data, type, row, meta) {

                        return data;
                    }
                },
                {
                    data: 'createdOn', render: function (data, type, row, meta) {
                        var d = convertDateObjToStr(data)
                        return `
                            <div style="text-align:center;">
                            ${d}
                            </div>
                            `;

                    }
                },
                {
                    data: '_id', render: function (data, type, row, meta) {
                        return `
                        <div class="d-md-flex flex-row justify-content-center">
                            <a href="#" class="rename btn-edit-group mg-r-5" group-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i data-feather="edit" class="wd-15 mg-r-5"></i></a>
                            <a href="#" class="delete btn-delete-group mg-r-5" group-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete"><i data-feather="trash" class="wd-15 mg-r-5"></i></a>
                        </div>
                    `;


                    }
                },

            ],
            "drawCallback": function () {
                feather.replace()
                _$('.btn-edit-group').unbind('click').click(function (e) {
                    e.preventDefault();
                    var groupId = _$(this).attr("group-id");
                    navigate(`/dashboard/students/groups/${groupId}`)

                })
                _$('.btn-delete-group').unbind('click').click(function (e) {
                    e.preventDefault();
                    var groupId = _$(this).attr("group-id");
                    const notice = PNotify.notice({
                        title: "Delete group",
                        text: "Are you sure you want to delete this group ?",
                        hide: false,
                        closer: false,
                        sticker: false,
                        modules: new Map([
                            ...PNotify.defaultModules,
                            [PNotifyConfirm, { confirm: true }],
                        ]),
                    })


                    notice.on("pnotify:confirm", async () => {
                        try {
                            var deleteSessionRes = await api_delete({ _id: groupId });
                            if (deleteSessionRes.status) {
                                PNotify.success({
                                    title: 'Success',
                                    text: "Group deleted successfully",
                                    delay: 3000
                                })
                            } else {
                                PNotify.error({
                                    title: 'Error',
                                    text: deleteSessionRes.errMsg,
                                    delay: 3000
                                })
                            }

                        } catch (error) {
                            console.error(error);
                        }
                        sessionDataTable.ajax.reload();

                    })

                })

                // bind actions 
            },
            "processing": true,
            "serverSide": true,
        });
        _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
    }


    function init() {
        activeMenu.set("students");
        reload();
    }

    onMount(init);

    afterUpdate(function () {
        feather.replace({ width: "1em", height: "1em" });
    });

    function convertDateObjToStr(date) {
        date = new Date(date);
        var month = "" + (date.getMonth() + 1);
        var day = "" + date.getDate();
        var year = date.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        var dateString = [day, month, year].join("-");
        return dateString;
    }


</script>

<style>
    /* .long-name-wrap {
      word-break: break-all;
      overflow-wrap: break-word;
      min-widht: 90%
      max-widht: 90%
    } */

    .classes-cards-wrap {
        flex-wrap: wrap;
    }

    .no-select {
        -webkit-user-select: none;
        /* Chrome all / Safari all */
        -moz-user-select: none;
        /* Firefox all */
        -ms-user-select: none;
        /* IE 10+ */
        user-select: none;
        /* Likely future */
    }
</style>


<div class="content-body fadeIn">
    <!-- <Link style="display: none;" to="about">About</Link> -->
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Groups</li>

                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">Groups</h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search groups or create a new group.</p>
        </div>
        <div class="d-block">
            <button class="btn pd-x-15 pd-y-8 btn-primary btn-uppercase mg-l-5 btn-sm" on:click={showModal}><i
                    data-feather="plus" class="wd-10 mg-r-5" />
                New Group
            </button>
        </div>
    </div>

    <hr class="full-width">
    <div>
        <table bind:this={elem_sessionDataTable} class="table">
            <thead>
                <tr>
                    <th style="width: 5%;">Sr. No.</th>
                    <th style="text-align: center; width: 15%;">Name</th>
                    <th style="width: 20%; text-align: center;">CreatedOn</th>
                    <th style="text-align: center; width: 10%;">Actions</th>
                </tr>
            </thead>
            <tbody></tbody>

        </table>

    </div>

</div>

<div class="modal effect-scale" bind:this={studentGroupModal} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="user-plus"
                            class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Groups</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Add students to the groups</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <div class="form-group">
                    <label class="d-block">Group Name <span class="required-field">*</span></label>
                    <input type="text" class="form-control" bind:value={groupName} placeholder="Enter group name"
                        on:focus={clearValidations}>
                    {#if errorObj.groupName}
                    <div class="parsley-errors-list">{errorObj.groupName}</div>
                    {/if}
                </div>

                <div class="d-md-flex justify-content-end mg-t-25">
                    <!-- <button disabled={isLoading} on:click={createGroup} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
                        data-dismiss="modal">
                        <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                        <i data-feather="filter" class="wd-10 mg-l-5"></i>
                    </button> -->
                    <button type="button" disabled={isLoading} on:click={createGroup}
                        class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <ButtonLoader isLoading={isLoading} btnText="Proceed" />
                        <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
