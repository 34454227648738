<script>
    import { onMount } from 'svelte';
    import { activeMenu } from "../stores";
    import { navigate } from 'svelte-routing';
    import * as constants from "../utils/constants";
    
    let boards = [
        { id: 'cbse', name: constants.CBSE },
        { id: 'maharashtra', name: constants.MAHARASHTRA_BOARD }
    ];
    
    onMount(() => {
        feather.replace();
        activeMenu.set('aptiBooster');
    });

    function navigateToClass(boardId) {
        navigate(`/dashboard/aptiBooster/class/${boardId}`);
    }
</script>

<div class="content-body fadeIn">
    <div class="container px-3">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Apti Booster</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Apti Booster Library</h4>
            </div>
        </div>

        <div class="row row-xs mg-b-20">
            {#each boards as board}
                <div class="col-sm-6 col-lg-4 col-xl-3 mg-b-20">
                    <div class="media media-folder board-item" 
                         on:click={() => navigateToClass(board.id)} 
                         role="button" 
                         tabindex="0">
                        <div class="avatar avatar-sm">
                            <div class="avatar-initial rounded-circle bg-white bd bd-1 bd-primary tx-capitalize tx-primary">
                                {board.name[0]}
                            </div>
                        </div>
                        <div class="media-body">
                            <h6>
                                <span class="link-02 tx-capitalize">
                                    {board.name}
                                </span>
                            </h6>
                        </div>
                    </div>
                </div>
            {/each}
        </div>
    </div>
</div>

<style>
    .board-item {
        cursor: pointer;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        padding: 15px;
        border-radius: 4px;
        transition: background-color 0.2s ease;
    }

    .board-item:active,
    .board-item:focus {
        -webkit-tap-highlight-color: transparent;
        outline: none;
        background: none;
    }

    .board-item:hover {
        background-color: #f5f5f5;
    }
</style>