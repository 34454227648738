<script>
    import { Router, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick, onDestroy } from 'svelte';
    import { _mobileNo, _verifyOtpToken, _otp, _registerOtpToken, _accessToken } from "../store.js";
    import { pSuccess, pError } from "../dashboard/utils/notify.svelte"
    import ButtonLoader from "../dashboard/utils/button_loader.svelte";

    let isLoading = false;
    let instNameEle;
    let instName;
    let instCodeEle;
    let instCode = "";
    let userFullNameEle;
    let userFullName;
    let userEmailIdEle;
    let userEmailId;



    let local_registerOtpToken;
    let local_mobileNo;

    let errorDetails = {};

    _registerOtpToken.subscribe(value => {
        local_registerOtpToken = value;
    });

    _mobileNo.subscribe(value => {
        local_mobileNo = value;
    });


    function clearFields() {
        instName = "";
        instCode = "";
        userFullName = "";
        userEmailId = "";
        clearValidations();
    }
    function clearError(fieldName) {
        delete errorDetails[fieldName]
        errorDetails = errorDetails
    }
    function clearValidations() {
        delete errorDetails.instName;
        delete errorDetails.instCode;
        delete errorDetails.userFullName;
        delete errorDetails.userEmailId;
    }
    function validate() {
        clearValidations();
        

        if (instName.length < 4) {
            errorDetails.instName = "Please enter valid Institute name";
        }
        if (instCode.length < 4) {
            errorDetails.instCode = "Please enter valid Institute code";
        }
        if (userFullName.length < 4) {
            errorDetails.userFullName = "Please enter valid Full name";
        }

        const EMAIL = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
        if (!EMAIL.test(userEmailId)) {
            errorDetails.userEmailId = "Please enter valid Email Id";
        }
        tick();
        if (Object.values(errorDetails).length > 0) {
            return false;
        } else {
            return true;
        }
    }

    async function registerOrg() {
        if(isLoading) return;
        isLoading = true;
        tick()
        const isValid = validate();
        if (isValid) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var payload = JSON.stringify({
                action: "auth.registerOrg",
                data: {
                    instName,
                    instCode: instCode.trim(),
                    userFullName,
                    userEmailId,
                    _regToken : local_registerOtpToken
                },
            });
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: payload,
            };
            try {
                var resObj = await fetch(API_URL, requestOptions);
                var response = await resObj.json();
                console.log(response);
                if(response.status){
                    window.localStorage.setItem("accessToken", response.data.accessToken)
                    _accessToken.set(response.data.accessToken);

                    let accessToken = response.data.accessToken;

                    var browser = (function () {
                        var test = function (regexp) { return regexp.test(window.navigator.userAgent) }
                        switch (true) {
                            case test(/edg/i): return "Microsoft Edge";
                            case test(/trident/i): return "Microsoft Internet Explorer";
                            case test(/firefox|fxios/i): return "Mozilla Firefox";
                            case test(/opr\//i): return "Opera";
                            case test(/ucbrowser/i): return "UC Browser";
                            case test(/samsungbrowser/i): return "Samsung Browser";
                            case test(/chrome|chromium|crios/i): return "Google Chrome";
                            case test(/safari/i): return "Apple Safari";
                            default: return "Other";
                        }
                    })();

                    var myHeaders1 = new Headers();
                    myHeaders1.append("Content-Type", "application/json");
                    var payload1 = JSON.stringify({
                        action: "auth.updateUserAccessToken",
                        accessToken: accessToken,
                        data: {
                            accessToken: accessToken,
                            deviceName: 'Browser',
                            deviceModel: browser
                        }
                    });
                    var requestOptions1 = {
                        method: "POST",
                        headers: myHeaders1,
                        body: payload1,
                    };

                    await fetch(API_URL, requestOptions1);

                    isLoading = false
                    pSuccess("", "Institute registration successful")
                    tick()
                    navigate("/signup/registration-successful");
                } else {
                    pError("", response.errMsg || response.error || "something went wrong")
                    console.error("something went wrong")
                }
                isLoading = false;
            } catch (error) {
                console.error("error", error);
                console.log({ error: error });
                isLoading= false;
            }
        }else{
            isLoading = false;
        }
        tick()
    }
    async function init() {
        clearFields();
        instNameEle.focus();
        console.log(local_registerOtpToken)
    }
    onMount(init);
    onDestroy(clearFields);


</script>

<div class="sign-wrapper mg-lg-r-50 mg-xl-r-60 fadeIn" style="flex: 1;">
    <div class="pd-t-20 wd-100p">
        <h4 class="tx-color-01 mg-b-5">Institute Information</h4>
        <p class="tx-color-03 tx-16 mg-b-40">It's free to signup and only takes a minute.</p>

        <div class="form-group">
            <label>Institute Name <span class="required-field">*</span> </label>
            <input 
            bind:this={instNameEle} 
            bind:value={instName} 
            type="text" 
            class="form-control"
            placeholder="Enter your Institute Name"
            on:keyup={(e) => e.key === 'Enter' && registerOrg()}
            class:parsley-error={errorDetails.instName}>
            {#if errorDetails.instName}
                <div class="parsley-errors-list">{errorDetails.instName}</div>
            {/if}
        </div>
        <div class="form-group">
            <label>Institute Code <span class="required-field">*</span> </label>
            <input 
            bind:this={instCodeEle} 
            bind:value={instCode} 
            type="text" 
            class="form-control"
            placeholder="Enter your Institute Code"
            on:keyup={(e) => e.key === 'Enter' && registerOrg()}
            class:parsley-error={errorDetails.instCode}>
            {#if errorDetails.instCode}
                <div class="parsley-errors-list">{errorDetails.instCode}</div>
            {/if}
        </div>
        <div class="form-group">
            <label>Your Full Name <span class="required-field">*</span> </label>
            <input 
            bind:this={userFullNameEle} 
            bind:value={userFullName} 
            type="text" 
            class="form-control"
            placeholder="Enter your Full Name"
            on:keyup={(e) => e.key === 'Enter' && registerOrg()}
            class:parsley-error={errorDetails.userFullName}>
            {#if errorDetails.userFullName}
                <div class="parsley-errors-list">{errorDetails.userFullName}</div>
            {/if}
        </div>
        <div class="form-group">
            <label>Your Email ID <span class="required-field">*</span> </label>
            <input 
            bind:this={userEmailIdEle} 
            bind:value={userEmailId} 
            type="text" 
            class="form-control"
            placeholder="Enter your Email Id"
            on:keyup={(e) => e.key === 'Enter' && registerOrg()}
            class:parsley-error={errorDetails.userEmailId}>
            {#if errorDetails.userEmailId}
                <div class="parsley-errors-list">{errorDetails.userEmailId}</div>
            {/if}
        </div>

        <div class="form-group tx-12">
            By clicking <strong>Create an account</strong> below, you agree to our terms of service and
            privacy statement.
        </div><!-- form-group -->


        <button on:click={registerOrg} disabled={isLoading} class="btn btn-brand-02 btn-block">
            {#if isLoading}
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Creating...
            {:else}
            Create Account
            {/if}
        </button>

        <!-- <button on:click={registerOrg} class="btn btn-brand-02 btn-block" disabled={isLoading}>
            {#if isLoading}
            
            {:else}
            
            {/if}
            <ButtonLoader isLoading={isLoading} btnText="Create Account"/>
        </button> -->
    </div>
</div><!-- sign-wrapper -->
