<script>
    import { Router, Link, Route } from "svelte-routing";
    import ListRoute from "./list/index.svelte";
    import AddUpdate from "./addupdate/index.svelte";
    import AssignBatch from "./assignbatch/index.svelte";
    import Group from "./addUpdateGroup/index.svelte";
    import AddUpdateGroup from "./addUpdateGroup/addUpdate.svelte";
    import MODULES from "../utils/modules.js" 
    import Forbidden from "../forbidden.svelte"
    import ArchievedList from "./archievedList/index.svelte"
    
</script>

<Router>
    

    <Route path="info/:id/batch-info" let:params>
        <AssignBatch studentId="{params.id}"></AssignBatch>
    </Route>

    <Route path="info/:id/batch-info/edit/:assignedId" let:params>
        {#if MODULES.hasAccess(MODULES.UPDATE_ASSIGNED_BATCH)}
        <AssignBatch isEdit={true} studentId="{params.id}" _id={params.assignedId}></AssignBatch>
        {:else}
        <Forbidden />
        {/if}
    </Route>

    <Route path="groups">
        <Group></Group>
    </Route>
    <Route path="archived">
        <ArchievedList ></ArchievedList>
    </Route>

    <Route path="groups/:id" let:params>
        <AddUpdateGroup _id="{params.id}"></AddUpdateGroup>
    </Route>

    <Route path="info/:id/batch-info/:batchid" let:params>
        <AssignBatch studentId="{params.id}" batchid="{params.batchid}"></AssignBatch>
    </Route>
    <Route path="info/:id/batch-info/assignNew/:batchid" let:params>
        
        <AssignBatch studentId="{params.id}" newBatchid="{params.batchid}" newAssign={true}></AssignBatch>
    </Route>
    
    <Route path="">
        <ListRoute></ListRoute>
    </Route>
    <Route path="search/:searchQuery" let:params>
        <ListRoute searchQuery="{params.searchQuery}" ></ListRoute>
    </Route>
</Router>



