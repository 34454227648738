<script>
    import { Router, Link, links, link, Route, navigate } from "svelte-routing";
    import { pConfirm, pSuccess, pError } from "../utils/notify.svelte";

    import { onMount, tick } from "svelte";
    // import CatSelect from "./categorySelect.svelte";
    import { activeMenu } from "../stores";
    import ButtonLoader from "../utils/button_loader.svelte"
    import api_handleApiCalls from "../utils/apiCallHandler.js";
    import MODULES from "../utils/modules.js"
    import { API_URL } from "../../config.js"

    let parentId;
    let showModal = false;
    let pdcDataTable;
    let modalCmp;
    let filterModalThis;
    let filterElemBatch
    let selectizeBatchInstance;
    let filerByBatches = []
    let batchesList = []
    let qType;
    let language, tags, pmarks, nmarks, questionType;
    let breadCrumb = [];
    let paymentObj = {}
    let btnDisable = false;
    let isLoading = false;

    let errorObj = {}
    let filterDate;
    let paymentDateStr = ""
    let elembranchSelect
    let elemBankSelect
    let elemReceiptType
    let receiptSeriesLists = [];
    let receiptId = "";
    let branchId = "";
    let branchListSelectArr = [];

    let currentPageData = [];
    let notdeposited = 0;
    let deposit = 0;
    let bounce = 0;
    let cleared = 0;
    let totalStatus = 0;
    let isEditMode = false;
    let ifListNotPresent = ""
    let paymentStatus = "DEFAULT";
    let currentStatus = "notdeposited";
    let allSelectedToBounce = new Set()
    let allSelectedToDeposit = new Set()
    let selectedToDeposit = [];
    let selectedToBounce = [];
    let isPopulateReceiptSeries = false
    let branchSelectizeInstance
    let receiptselectizeInstance
    let bankSelectizeInstance
    let branchListSelectArray = []
    let bankList = []
    let banksBranchName = ""
    let bankIFSCCode
    $: currentStatus, changeText()
    function changeText() {
        if (currentStatus == 'cleared') _$('#deposit').text('Cleared On')
        else _$('#deposit').text('Deposit On')
    }
    function updatePaymentDate() {
        paymentObj.paymentDate = convertDateStrToObj(paymentDateStr);

        // let isTodayDate = new Date(new Date().setHours(0,0,0,0))
        // if(paymentObj.paymentDate.getTime() == isTodayDate.getTime() ){
        //     populateBranchSelectize("","",false);
        // }else if(paymentObj.paymentType == "cheque"){ //this is to select the future date
        //     populateBranchSelectize("","",false);
        // }

    }

    function convertDateStrToObj(selectedDate) {
        selectedDate = selectedDate.split("-");
        var year = selectedDate[0];
        var month = selectedDate[1] - 1;
        var day = selectedDate[2];
        return new Date(year, month, day);
    }

    async function instituteBranchList() {
        var response = await api_handleApiCalls("instituteBranch.fetchInstituteBranchList", { start: 0, length: 100, sort: { name: 1 } })
        if (response.data && response.data.data.length) {
            branchListSelectArr = response.data.data;
        }

    }

    async function api_getBatchesList() {

        var resData = await api_handleApiCalls('batch.getMyBatches', {})
        if (resData.status) {
            return resData.data
        } else {
            return []
        }
    }

    function bindSelect2s() {
        selectizeBatchInstance = _$(filterElemBatch).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: "Select Batches",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: batchesList,
            onChange: function (values) {

                filerByBatches = values
                redrawPDCTable();
            }
        });
    }

    async function populateReceipt(branchId, mode) {
        //get recept list 
        if (mode == "isClear") {
            branchId = _$(elembranchSelect).val()
        }
        var response = await api_handleApiCalls('receiptSeries.list', {
            start: 0,
            length: 100,
            search: "",
            branchId: branchId
        });
        if (response.data && response.data.data.length) {
            receiptSeriesLists = response.data.data;


            if (mode == "isClear") {
                receiptSeriesLists = receiptSeriesLists.filter(item => !item.isPdc || item.isPdc == false)


            }


            if (receiptSeriesLists.length) receiptSeriesLists.map(item => item.label = `${item.prefix}${item.series}`);
        }
        else {
            receiptSeriesLists = []
            ifListNotPresent = "Please add receipt series first"
        }

        await tick()

        if (receiptselectizeInstance && receiptselectizeInstance.length) receiptselectizeInstance[0].selectize.destroy();
        receiptselectizeInstance = _$(elemReceiptType).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "label",
            searchField: ["prefix", "series"],
            delimiter: ",",
            persist: false,
            options: receiptSeriesLists,

            onChange: function (receiptIdValue) {
                populateReceiptNumberTemp()
            }
        });

    }

    async function getPdcStatus() {

        let action = "postDatedCheck.getStatusCount";
        var response = await api_handleApiCalls(action);
        if (response.data) {
            let { totalBounce, totalCleared, totalDeposit, totalNotdeposited, totalStatus } = response.data;
            notdeposited = totalNotdeposited;
            cleared = totalCleared;
            deposit = totalDeposit;
            bounce = totalBounce;
            totalStatus = totalStatus
        }

    }


    const selectAllUnSelectNotDeposited = async (checked, selectedArr, status) => {
        if (checked) {
            selectedArr.forEach(obj => allSelectedToDeposit.add(obj._id))
        } else {
            selectedArr.forEach(obj => allSelectedToDeposit.delete(obj._id))
        }
        allSelectedToDeposit = allSelectedToDeposit
        await tick()
        makeCheckAllNotDeposited(checked)
    }
    const makeCheckAllNotDeposited = (all) => {
        _$(".status-change")
            .toArray()
            .map((element) => {
                let elem_checkbox = _$(element);
                let dataId = elem_checkbox.attr("data-id");
                if (all) elem_checkbox.prop("checked", true);
                else elem_checkbox.prop("checked", false);

            });
    }


    function parseSorts(sortOrder) {
        // parses datatable order to mongodb sort object
        let sort = {};
        // sorting orders
        let order;
        if (sortOrder.dir === "desc") order = 1;
        else order = -1;
        if (sortOrder.column === 1) {
            sort.qId = order;
        } else if (sortOrder.column === 2) {
            sort._id = order;
        } else if (sortOrder.column === 3) {
            sort._id = order;
        } else if (sortOrder.column === 4) {
            sort._id = order;
        } else {
            sort._id = order;
        }
        return sort;
    }

    function bindDatatable() {
        let search = ''
        pdcDataTable = _$(pdcDataTable).DataTable({
            responsive: false,
            language: {
                processing: "",
                searchPlaceholder: 'Search Checks | Name',
                sSearch: '',
                lengthMenu: '_MENU_ Checks/page',
            },
            ajax: function (data, callback, settings) {
                data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
                var filterObj = { ...getFilterData() }
                filterObj.start = data.start
                filterObj.length = data.length
                filterObj.search = data.search.value
                filterObj.draw = data.draw
                filterObj.sort = data.sort;
                if (currentStatus) {
                    filterObj.currentStatus = currentStatus;
                }
                if (filterDate) {
                    filterObj.filterDate = filterDate;
                }
                if (filerByBatches.length) {
                    filterObj.filerByBatches = filerByBatches;
                }
                let callDebounce = debounce(async function () {
                    _$.ajax({
                        url: API_URL,
                        method: 'post',
                        data: {
                            action: 'postDatedCheck.list',
                            accessToken: localStorage.getItem("accessToken"),
                            data: filterObj
                        },
                        success: function (resData) {
                            var result = []
                            if (resData.status) {
                                var paginationData = resData.data;
                                currentPageData = paginationData.data;
                                result = {
                                    recordsTotal: Number(paginationData.totalCount),
                                    recordsFiltered: Number(paginationData.filteredCount),
                                    data: paginationData.data
                                }
                                if (resData?.data?.data.length) {
                                    // emptyState = true
                                }
                                callback(result);
                            }
                            else {
                                console.log("Something went wrong");
                            }
                        },
                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                            // emptyState = true
                        }
                    })

                }, 250);


                callDebounce()
            },
            columns: [
                {
                    data: '_id', render: function (data, type, full) {


                        if (full.status == "cleared") {
                            return full.userInfo.name
                        } else if (full.status == "notdeposited") {
                            return (
                                `<div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input status-change" id="${data}" data-status="${full.status}" data-id="${data}" ${selectedToDeposit.find(e => e == data) ? 'checked' : ""}>
                                        <label class="custom-control-label" for="${data}">${full.userInfo.name}</label>
                                        </div>`)

                        } else if (full.status == "deposit") {
                            return (
                                `<div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input status-bounce" id="${data}" data-amount="${full.installmentAmount}" data-status="${full.status}" data-id="${data}" ${selectedToBounce.find(e => e == data) ? 'checked' : ""}>
                                        <label class="custom-control-label" for="${data}">${full.userInfo.name}</label>
                                        </div>`)
                        } else if (full.status == "bounce") {
                            return full.userInfo.name
                        } else {
                            return full.userInfo.name
                        }
                    }
                },
                {
                    data: '_id', render: function (data, type, full) {

                        return full.batchInfo ? full.batchInfo.name : "-";
                    }
                },
                {
                    data: 'chequeNo', render: function (data, type, full) {
                        return data ? data : "-";
                    }
                },
                {
                    data: 'bankName', render: function (data, type, full) {
                        return `<div>${data} 
                            <span> ${full.chequeDepositedBranch ? "(" + full.chequeDepositedBranch + ")" : ""}</span>
                        </div>`


                    }
                },
                {
                    data: 'status', render: function (data, type, full) {
                        return data == "notdeposited" ? "Not Deposited" : data == "deposit" ? "Deposited" : data == "bounce" ? "Bounce" : data == "cleared" ? "Cleared" : "-"

                    }
                },
                {
                    data: 'pdcNo', render: function (data, type, full) {
                        return data ? data : "-"

                    }
                },
                {
                    data: 'receiptNo', render: function (data, type, full) {
                        return full.status == "cleared" ? data : "-";
                    }
                },
                {
                    data: 'createdOn', render: function (data, type, full) {
                        return convertDateObjToStrT(new Date(data))
                    }
                },

                {
                    data: '_id', render: function (data, type, full) {
                        let date
                        if (full.status == "cleared") {
                            date = full.ClearChequeDate ? convertDateObjToStrT(new Date(full.ClearChequeDate)) : convertDateObjToStrT(new Date(full.paymentDate))
                        }
                        else {
                            date = convertDateObjToStrT(new Date(full.paymentDate))
                        }
                        return date
                    }
                },
                {
                    data: '_id', render: function (data, a, b, c) {
                        return `
                      <div class="d-md-flex flex-row">
                          <a href="#" class="rename mg-r-5 btn-edit-cheque" cheque-id="${data}"  data-toggle="tooltip" data-placement="top" title="Edit"><i class="wd-15 mg-r-5" data-feather="edit"></i></a>
                      </div>
                      `;
                    }
                },

            ],
            "drawCallback": function () {
                feather.replace()
                checkIfAllSelected()
                checkIfAllNotDepositedSelected()
                _$('.btn-edit-cheque').unbind('click').click(function (e) {
                    e.preventDefault();
                    var checkId = _$(this).attr("cheque-id");
                    populateCheckPayment(checkId)
                    return false;
                })


                _$(".status-change")
                    .unbind("click")
                    .click(function (e) {
                        var dataId = _$(this).attr("data-id");
                        let status = _$(this).attr("data-status");

                        var checked = e.target.checked
                        selectNotDeposited(dataId, checked, status);
                    });


                _$(".status-bounce")
                    .unbind("click")
                    .click(function (e) {
                        var dataId = _$(this).attr("data-id");
                        let status = _$(this).attr("data-status");

                        var checked = e.target.checked
                        selectDeposited(dataId, checked, status);
                    });

                _$('.select-all-not-deposited').unbind("click").click(function (e) {
                    let status = _$(this).attr("data-status");
                    selectAllUnSelectNotDeposited(e.target.checked, currentPageData, status)
                });
                _$('.select-all-deposited').unbind("click").click(function (e) {
                    let status = _$(this).attr("data-status");

                    selectAllUnselectDeposited(e.target.checked, currentPageData, status)
                });
            },
            "processing": true,
            "serverSide": true,
        });
        _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
    }

    const selectAllUnselectDeposited = async (checked, selectedArr, status) => {
        if (checked) {
            selectedArr.forEach(obj => allSelectedToBounce.add(obj._id))
        } else {
            selectedArr.forEach(obj => allSelectedToBounce.delete(obj._id))
        }
        allSelectedToBounce = allSelectedToBounce
        await tick()
        makeCheckAll(checked)
    }

    const makeCheckAll = (all) => {
        _$(".status-bounce")
            .toArray()
            .map((element) => {
                let elem_checkbox = _$(element);
                let dataId = elem_checkbox.attr("data-id");
                if (all) elem_checkbox.prop("checked", true);
                else elem_checkbox.prop("checked", false);

            });
    }

    const checkIfAllSelected = () => {

        let selectedArr = _$(".status-bounce")
            .toArray()
            .filter((element) => {
                let elem_checkbox = _$(element);
                let itemId = elem_checkbox.attr("data-id");
                return allSelectedToBounce.has(itemId)
            });

        if (selectedArr.length == currentPageData.length && currentPageData.length != 0) _$('.select-all-deposited').prop("checked", true);

        else _$('.select-all-deposited').prop("checked", false);

    }
    const checkIfAllNotDepositedSelected = () => {

        let selectedNotDepositedArr = _$(".status-bounce")
            .toArray()
            .filter((element) => {
                let elem_checkbox = _$(element);
                let itemId = elem_checkbox.attr("data-id");
                return allSelectedToDeposit.has(itemId)
            });

        if (selectedNotDepositedArr.length == currentPageData.length && currentPageData.length != 0) _$('.select-all-not-deposited').prop("checked", true);

        else _$('.select-all-deposited').prop("checked", false);

    }
    async function selectDeposited(id, checked, status) {
        if (checked) {
            allSelectedToBounce.add(id)
        } else {
            allSelectedToBounce.delete(id)
        }
        _$('.select-all-deposited').prop("checked", false);

        allSelectedToBounce = allSelectedToBounce
        await tick()


    }

    async function selectNotDeposited(id, checked, status) {
        if (checked) {
            allSelectedToDeposit.add(id)
        } else {
            allSelectedToDeposit.delete(id)
        }
        _$('.select-all-not-deposited').prop("checked", false);

        allSelectedToDeposit = allSelectedToDeposit
        await tick()


    }
    function showHideFilterModal(action) {
        action ? (_$(filterModalThis).modal('show')) : (_$(filterModalThis).modal('hide'))
    }

    function getFilterData() {
        return {
            language,
            tags,
            pmarks,
            nmarks,
            questionType
        }
    }

    function applyFilter() {
        redrawPDCTable();
    }

    function redrawPDCTable() {
        isEditMode = false
        pdcDataTable.draw()
    }
    function clearFilter() {

        filterDate = null;
        selectizeBatchInstance[0].selectize.clear()
        redrawPDCTable();
    }



    function filterByDate() {
        redrawPDCTable()
    }
    function filterByStatus(status) {
        selectedToDeposit = [];
        if (status == "cleared") {
            currentStatus = 'cleared'
            filterDate = null
        } else if (status == "notdeposited") {
            currentStatus = 'notdeposited'
            filterDate = null
        } else if (status == "deposit") {
            currentStatus = 'deposit'
            filterDate = null
        } else if (status == "bounce") {
            currentStatus = 'bounce'
            filterDate = null
        } else {
            currentStatus = '';
            filterDate = null
        }
        redrawPDCTable();
    }
    let debounce = (f, w) => { let t; return function fn(...args) { const later = () => { t = null; f(...args); }; clearTimeout(t); t = setTimeout(later, w); }; };


    async function updateStatusToDeposit(params) {
        if (allSelectedToDeposit.size === 0) {
            alert("select at least one check to deposit");
            return
        }
        var r = confirm("Are you sure you want to deposit ?");
        if (r == true) {

            selectedToDeposit = Array.from(allSelectedToDeposit)
            var response = await api_handleApiCalls("postDatedCheck.updateStatus", { selectedToDeposit, status: "deposit" });
            if (response.status) {
                redrawPDCTable();
                allSelectedToDeposit = new Set()

                getPdcStatus();
                resetModal()
                //get count
            } else {
                allSelectedToDeposit = new Set()

            }
        }
    }

    async function updateStatusToBounce(params) {
        if (allSelectedToBounce.size === 0) {
            alert("select at least one check to bounce");
            return
        }
        var r = confirm("Are you sure you want to bounce ?");
        if (r == true) {
            selectedToBounce = Array.from(allSelectedToBounce)
            var response = await api_handleApiCalls("postDatedCheck.updateBounceStatus", { selectedToBounce, status: "bounce" });
            if (response.status) {
                redrawPDCTable();
                allSelectedToBounce = new Set()
                getPdcStatus();
                resetModal()
                //get count
            } else {
                allSelectedToBounce = new Set()

            }
        }
    }
    async function populateCheckPayment(checkId) {

        let action = "payment.getPaymentById";
        var response = await api_handleApiCalls(action, { _id: checkId });

        if (response.data) {
            const { batchId, branchId, bankName, chequeNo, paymentType, freezePayment, installmentAmount, paidAmount, paymentDate, pdcBranchId, pdcId, pdcNo, pdcPrefix,
                pdcSequence, prefix, receiptId, receiptNo, sequence, status, studentId, _id } = response.data;
            paymentObj = {
                batchId, branchId, paymentType, chequeNo, bankName, freezePayment, installmentAmount, paidAmount, paymentDate, pdcBranchId, pdcId, pdcNo, pdcPrefix,
                pdcSequence, prefix, receiptId, receiptNo, sequence, status, studentId, _id
            }
            //    instituteBranchList();
            bindSelect()
            paymentDateStr = convertDateObjToStr(new Date(paymentObj.paymentDate))
            if (paymentObj.status == "notdeposited" || paymentObj.status == "deposit" || paymentObj.status == "bounce") {

                populateReceipt(paymentObj.pdcBranchId, "edit")
            } else {
                populateReceipt(paymentObj.branchId, "edit")
            }
            _$(modalCmp).modal('show');
        }
    }


    let clearError = (key) => {
        delete errorObj[key];
        errorObj = { ...errorObj }; // reset state
    }

    let updatePaidStatus = function (params) {
        if (paymentObj.paidAmount && paymentObj.paidAmount != 0) {
            paymentStatus = "FREEZE";
        } else {
            paymentStatus = "DEFAULT";

        }
    }

    let populateReceiptNumber = function () {
        if (paymentObj.prefix && paymentObj.sequence) {
            paymentObj.receiptNo = paymentObj.prefix + paymentObj.sequence;
        }
    }

    let populateReceiptNumberTemp = function () {
        paymentObj.receiptId = _$(elemReceiptType).val();
        if (!paymentObj.receiptId) {
            return
        }
        let receiptObj = receiptSeriesLists.find(e => e._id == paymentObj.receiptId)
        paymentObj.prefix = receiptObj.prefix;
        paymentObj.sequence = receiptObj.series

        if (paymentObj.prefix && paymentObj.sequence) {
            paymentObj.receiptNo = paymentObj.prefix + paymentObj.sequence;
        }
    }

    onMount(async () => {
        feather.replace();
        activeMenu.set('pdc');
        getPdcStatus();
        bindDatatable();
        batchesList = await api_getBatchesList();
        bindSelect2s();
        isEditMode = false
        let allbankdetails = await api_getOrganizationBanksList();

        // Check if the response has the expected structure
        if (allbankdetails && allbankdetails.data) {
            bankList = allbankdetails.data.map(function (item) {
                return {
                    ifsc: item.ifsc,
                    name: item.bankName,
                    branch: item.branchName
                };
            });
        } else {
            // Handle the case where the data is not in the expected format
            bankList = []; // or handle the error as needed
            console.warn("Bank details not found or in unexpected format.");
        }

        //   bindSelect()

    })
    async function api_getOrganizationBanksList() {

        let result = await api_handleApiCalls("settings.fetchbank", {});
        if (result.status) {
            return result
        } else {
            return []
        }



    }
    function resetModal() {
        errorObj = {}
        branchSelectizeInstance[0].selectize.clear();

        bankSelectizeInstance[0].selectize.clear();

        banksBranchName = ""
        paymentObj.pdcBranchId = ""
        paymentObj.bankName = ""

        isEditMode = false;
        isPopulateReceiptSeries = false
        selectedToBounce = [];
        selectedToDeposit = [];
    }

    function convertDateObjToStr(date) {
        var month = '' + (date.getMonth() + 1);
        var day = '' + date.getDate();
        var year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var dateString = [year, month, day].join('-');
        return dateString;
    }
    function convertDateObjToStrT(date) {
        var month = '' + (date.getMonth() + 1);
        var day = '' + date.getDate();
        var year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var dateString = [day, month, year].join('-');
        return dateString;
    }
    let validatePayment = () => {
        errorObj = {};
        var newErrorObj = {};
        let CHEQUENOREGEX = /^[0-9]{6,6}$/

        if (!paymentObj.installmentAmount) {
            newErrorObj.installmentAmount = " Installment Amount is required Field.";
        }
        if (paymentObj.installmentAmount && paymentObj.installmentAmount < 0) {
            newErrorObj.installmentAmount = " Installment Amount cannot be less than Balance Amount 0";
        }

        if (!paymentObj.chequeNo) {
            newErrorObj.chequeNo = "Please enter cheque number";
        }
        if (!CHEQUENOREGEX.test(paymentObj.chequeNo)) {
            newErrorObj.chequeNo = "Please enter valid 6 digit cheque number."
        }
        if (!paymentObj.paymentDate) {
            newErrorObj.paymentDate = " Payment Date is required Field.";
        }


        errorObj = newErrorObj;

        if (Object.keys(errorObj).length > 0) {
            return false;
        }
        return true;

    }
    let validatePaymentClear = () => {
        if (paymentObj.status == "bounce") {
            errorObj = {};
            var newErrorObj = {};
            if (!paymentObj.installmentAmount) {
                newErrorObj.installmentAmount = "Installment Amount is required Field."

            }
            if (paymentObj.installmentAmount && paymentObj.installmentAmount < 0) {
                newErrorObj.installmentAmount = " Installment Amount cannot be less than 0 "

            }
            if (!paymentObj.chequeNo) {
                newErrorObj.chequeNo = "Please enter cheque number"

            }
            if (!paymentObj.paymentDate) {
                newErrorObj.paymentDate = "Payment Date is required Field."

            }
            if (!paymentObj.receiptNo) {
                newErrorObj.receiptNo = "Receipt No is required Field."

            }

            errorObj = newErrorObj;
            if (Object.keys(errorObj).length > 0) {
                return false;
            }
            return true
        }
        else {
            if (!paymentObj.branchId) {
                pError(null, "Branch is Required Field.");
                return
            }
            if (!paymentObj.receiptNo) {
                pError(null, "Receipt No is required Field.");
                return
            }
            return true;
        }


    }

    let handleUpdateClick = async function () {
        paymentObj.paidAmount
        if (validatePayment()) {
            var paymentRes = await api_handleApiCalls("payment.addUpdatePayment", paymentObj);
            if (paymentRes.status) {
                // create success
                PNotify.success({
                    title: 'Success',
                    text: paymentRes.data,
                    delay: 3000
                })
                _$(modalCmp).modal('hide');
                redrawPDCTable();
                // getPdcStatus();
                resetModal();

            } else {
                PNotify.error({
                    title: 'Error',
                    text: paymentRes.errMsg,
                    delay: 3000
                })
            }
        }
    }

    let handlePaymentClick = async function () {
        if (validatePaymentClear()) {

            let body = {
                _id: paymentObj._id,
                batchId: paymentObj.batchId,
                branchId: paymentObj.branchId,
                chequeNo: paymentObj.chequeNo,
                bankName: paymentObj.bankName,
                freezePayment: paymentObj.freezePayment,
                installmentAmount: paymentObj.installmentAmount,
                paymentDate: new Date(paymentObj.paymentDate),
                paymentType: paymentObj.paymentType,
                status: paymentObj.status,
                prefix: paymentObj.prefix,
                receiptId: paymentObj.receiptId,
                receiptNo: paymentObj.receiptNo,
                sequence: paymentObj.sequence,
                studentId: paymentObj.studentId,
                paidAmount: paymentObj.installmentAmount,
                chequeDepositedBranch: banksBranchName,
                bankIFSCCode: bankIFSCCode,
                ClearChequeDate: new Date()
            }

            var paymentRes = await api_handleApiCalls("payment.addUpdatePayment", body);
            if (paymentRes.status) {
                // create success
                PNotify.success({
                    title: 'Success',
                    text: paymentRes.data,
                    delay: 3000
                })
                _$(modalCmp).modal('hide');
                redrawPDCTable();
                getPdcStatus();
                resetModal();
                isPopulateReceiptSeries = false
            } else {
                PNotify.error({
                    title: 'Error',
                    text: paymentRes.errMsg,
                    delay: 3000
                })
            }
        }
    }

    function checkPdcStatus(paymentObj) {
        let status = (paymentObj.status == "notdeposited" || paymentObj.status == "deposit" || paymentObj.status == "bounce") ? true : false
        return status;
    }

    function setEditMode() {
        bindSelect()
        paymentObj.branchId = "";
        paymentObj.receiptId = "";
        isEditMode = true;
        ifListNotPresent = ""
        receiptSeriesLists = []
    }

    //   function onBranchChange(params) {
    //     paymentObj.pdcBranchId=""
    //     paymentObj.branchId = _$(elembranchSelect).val();
    //     receiptSeriesLists =[];
    //     paymentObj.receiptNo ="";
    //     isPopulateReceiptSeries = true
    //     populateReceipt("","isClear")
    //   }

    async function bindSelect() {
        ifListNotPresent = ""
        var response = await api_handleApiCalls("instituteBranch.fetchInstituteBranchList", { start: 0, length: 100, sort: { name: 1 } })

        if (response.data.data) {
            branchListSelectArray = response.data.data;
        }
        await tick();
        if (branchSelectizeInstance && branchSelectizeInstance.length) branchSelectizeInstance[0].selectize.destroy();
        branchSelectizeInstance = _$(elembranchSelect).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            persist: false,
            placeholder: "Select Branch",

            options: branchListSelectArray,
            render: {
                option_create: function (data, escape) {
                    return (
                        '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' +
                        escape(data.input) +
                        "</strong></div>"
                    );
                },
            },
            onChange: function (branchIdValue) {
                paymentObj.pdcBranchId = ""
                paymentObj.branchId = _$(elembranchSelect).val();
                receiptSeriesLists = [];
                paymentObj.receiptNo = "";
                isPopulateReceiptSeries = true
                ifListNotPresent = ""
                populateReceipt("", "isClear")
            }
        });




        bankSelectizeInstance = _$(elemBankSelect).selectize({
            preload: true,
            create: false,
            maxItems: 1,
            valueField: "ifsc",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            persist: false,
            placeholder: "Select Bank",
            options: bankList,
            render: {
                option: function (data, escape) {
                    return (
                        '<div style="padding-left:15px">' +
                        escape(data.name) + "<span>" + " ( " + escape(data.branch) + " ) " + "</span>" +
                        "</div>"
                    );
                },
                item: function (data, escape) {
                    return (
                        '<div>' +
                        escape(data.name) + "<span>" + " ( " + escape(data.branch) + " ) " + "</span>" +
                        "</div>"
                    );
                },
            },
            onChange: function (value) {
                bankIFSCCode = value
                if (bankIFSCCode) {
                    let orgBankName = bankList.find(item => item.ifsc === bankIFSCCode).name;
                    banksBranchName = bankList.find(item => item.ifsc === bankIFSCCode).branch
                    paymentObj.bankName = orgBankName
                }

            }
        });


    }
</script>
<style>
    .counter-nav {
        cursor: pointer
    }

    .selected {
        background-color: #b9ffd5;
    }
</style>
<div class="content-body">
    <div class="d-sm-flex align-items-center justify-content-between mg-b-25">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/dashboard/pdc">PDC</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        List
                    </li>
                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">
                PDC Management
            </h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0 mg-t-5">Manage post dated check</p>

        </div>
    </div>

    <hr class="full-width">

    <div class="d-flex flex-row mb-2">

        <div class="card card-body mr-2 counter-nav" style="max-width: 20vw;"
            class:selected="{currentStatus === 'notdeposited'}" on:click="{()=>filterByStatus(" notdeposited")}">
            <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Not Deposited</h6>
            <div class="d-flex flex-column d-lg-block d-xl-flex align-items-end">
                <h3 class="tx-normal align-self-center tx-rubik mg-b-0 mg-r-5 lh-1">{notdeposited}</h3>

            </div>
            <div class="chart-three">
                <div id="flotChart3" class="flot-chart ht-30">
                    <!-- {#if selectedToDeposit.length}
            <p class="tx-14 tx-color-03 mg-b-0"><span class="">check to deposit {selectedToDeposit.length}</span><i class="wd-15" data-feather="calendar"></i></p> 
                {/if} -->
                </div>
            </div><!-- chart-three -->
        </div>
        <div class="card card-body mr-2 counter-nav" style="max-width: 20vw;"
            class:selected="{currentStatus === 'deposit'}" on:click="{()=>filterByStatus(" deposit")}">
            <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Deposited</h6>
            <div class="d-flex flex-column d-lg-block d-xl-flex align-items-end">
                <h3 class="tx-normal align-self-center tx-rubik mg-b-0 mg-r-5 lh-1">{deposit}</h3>
                <!-- <p class="tx-11 tx-color-03 mg-b-0"><span class="">12.23.12 </span><i class="wd-15" data-feather="calendar"></i></p>  -->
            </div>
            <div class="chart-three">
                <div id="flotChart3" class="flot-chart ht-30">

                </div>
            </div><!-- chart-three -->
        </div>
        <div class="card card-body mr-2 counter-nav" style="max-width: 20vw;"
            class:selected="{currentStatus === 'bounce'}" on:click="{()=>filterByStatus(" bounce")}">
            <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Bounced</h6>
            <div class="d-flex flex-column d-lg-block d-xl-flex align-items-end">
                <h3 class="tx-normal align-self-center tx-rubik mg-b-0 mg-r-5 lh-1">{bounce}</h3>
                <!-- <p class="tx-11 tx-color-03 mg-b-0"><span class="">12.23.12 </span><i class="wd-15" data-feather="calendar"></i></p>  -->
            </div>
            <div class="chart-three">
                <div id="flotChart3" class="flot-chart ht-30">

                </div>
            </div><!-- chart-three -->
        </div>
        <div class="card card-body mr-2 counter-nav" style="max-width: 20vw;"
            class:selected="{currentStatus === 'cleared'}" on:click="{()=>filterByStatus(" cleared")}">
            <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Cleared</h6>
            <div class="d-flex flex-column d-lg-block d-xl-flex align-items-end">
                <h3 class="tx-normal align-self-center tx-rubik mg-b-0 mg-r-5 lh-1">{cleared}</h3>
                <!-- <p class="tx-11 tx-color-03 mg-b-0"><span class="">12.23.12 </span><i class="wd-15" data-feather="calendar"></i></p>  -->
            </div>
            <div class="chart-three">
                <div id="flotChart3" class="flot-chart ht-30">

                </div>
            </div><!-- chart-three -->
        </div>
        <!-- <div class="card card-body mr-2 counter-nav" style="max-width: 18vw;" 
 class:selected="{currentStatus === ''}"
	on:click="{()=>filterByStatus("")}"
  >
        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">All</h6>
        <div class="d-flex flex-column d-lg-block d-xl-flex align-items-end">
            <h3 class="tx-normal align-self-center tx-rubik mg-b-0 mg-r-5 lh-1">{totalStatus}</h3>
            <!-- <p class="tx-11 tx-color-03 mg-b-0"><span class="">12.23.12 </span><i class="wd-15" data-feather="calendar"></i></p>  -->
        <!-- </div>
        <div class="chart-three">
            <div id="flotChart3" class="flot-chart ht-30">
            
            </div>
        </div><!-- chart-three -->
        <!-- </div> -->
    </div>

    <div class="d-flex ">
        <div class="form-group">
            <label class="d-block">Filter :</label>
            <div class="d-flex">
                <input type="date" onkeydown="return false" on:change={filterByDate} bind:value={filterDate}
                    class="form-control follow-up-date" />
                <div class="form-group mg-l-10">
                    <select bind:this={filterElemBatch} class="form-control select2 wd-200" multiple="multiple">
                        <option>Select batches</option>
                    </select>
                </div>
                <button type="button" style="max-height: 40px;" class="btn btn-primary mg-l-20 mg-b-20"
                    on:click={clearFilter}>Reset</button>
            </div>
            <!-- <div class="ml-5 align-self-center demo-btn-group">
        <button type="button" class="btn btn-primary" style="margin-top: 10%;" on:click= {()=>{filterDate=null;filterByDate()}}>Reset</button>
    </div> -->

        </div>

        {#if currentStatus == "notdeposited"}
        <div class="d-flex">
            <div class="ml-5 align-self-center custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input  select-all-not-deposited" id="notdeposit">
                <label class="custom-control-label" for="notdeposit">Select All Not Deposited</label>
            </div>
            <div class="ml-5 align-self-center demo-btn-group">
                <button type="button" class="btn btn-primary" on:click={updateStatusToDeposit}>Deposit Now</button>
            </div>
        </div>
        {:else if currentStatus == "deposit"}
        <div class="d-flex">
            <div class="ml-5 align-self-center custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input select-all-deposited" id="deposit">
                <label class="custom-control-label" for="deposit">Select All Bounce</label>
            </div>
            <div class="ml-5 align-self-center demo-btn-group">
                <button type="button" class="btn btn-primary" on:click={updateStatusToBounce}>Bounce Now</button>
            </div>
        </div>
        {:else if currentStatus == "cleared"}
        <!-- else if content here -->
        {:else }
        <!-- else content here -->
        {/if}

    </div>


    <!-- <hr class="mg-y-20"> -->
    <div data-label="Example" class="df-example pdc-table">
        <table bind:this={pdcDataTable} class="table">
            <thead>
                <tr>
                    <th class="wd-10">Name</th>
                    <th class="wd-10">Batch</th>
                    <th class="wd-15">Check No</th>
                    <th class="wd-80">Bank Name</th>

                    <th class="wd-15">Status</th>
                    <th class="wd-15">Pdc no</th>
                    <th class="wd-15">Receipt no</th>
                    <th class="wd-10">Created On</th>
                    <th class="wd-10" id="deposit">Deposit On</th>
                    <th class="wd-10">Actions </th>
                </tr>
            </thead>
            <tbody></tbody>

        </table>
    </div>
</div>

<div class="modal effect-scale" bind:this={modalCmp} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mx-wd-sm-650" role="document">
        <div class="modal-content bd-0 bg-transparent">
            <div class="modal-body pd-0">
                <a href="" role="button" on:click={resetModal} class="close pos-absolute t-15 r-15 z-index-10"
                    data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="row no-gutters">

                    <div class="col-12 bg-white rounded-right">
                        <div class="ht-100p d-flex flex-column justify-content-center pd-20 pd-sm-30 pd-md-40">

                            {#if (isEditMode == true) }

                            <div class="form-group">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Cheque
                                    Date</label>
                                <input disabled={paymentObj.freezePayment || (paymentObj.status=="deposit" ||
                                    paymentObj.status=="bounce" ) } on:change={updatePaymentDate}
                                    bind:value={paymentDateStr} type="date" on:blur={()=>clearError("paymentDate")}
                                class="form-control" class:parsley-error={errorObj.paymentDate} placeholder="Select
                                Date"/>
                                {#if errorObj.paymentDate}
                                <div class="parsley-errors-list">{errorObj.paymentDate}</div>
                                {/if}
                            </div>

                            <div class="form-group">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Cheque
                                    No</label>
                                <input disabled={paymentObj.freezePayment || (paymentObj.status=="deposit" ||
                                    paymentObj.status=="bounce" )} bind:value={paymentObj.chequeNo} maxlength="6"
                                    type="text" on:change={()=>updatePaidStatus()} on:blur={()=>clearError("chequeNo")}
                                class="form-control" class:parsley-error={errorObj.chequeNo} placeholder="Enter Cheque
                                No"
                                />
                                {#if errorObj.chequeNo}
                                <div class="parsley-errors-list">{errorObj.chequeNo}</div>
                                {/if}
                            </div>

                            <div class="form-group">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Cheque
                                    Amount</label>
                                <input disabled={paymentObj.freezePayment || true}
                                    bind:value={paymentObj.installmentAmount} type="number"
                                    on:change={()=>updatePaidStatus()}
                                on:blur={()=>clearError("installmentAmount")} class="form-control"
                                class:parsley-error={errorObj.installmentAmount} placeholder="Enter Cheque Amount"/>
                                {#if errorObj.installmentAmount}
                                <div class="parsley-errors-list">{errorObj.installmentAmount}</div>
                                {/if}
                            </div>

                            <div class="form-group">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Organization
                                    Bank
                                    List</label>
                                <select disabled={paymentObj.freezePayment} bind:this={elemBankSelect}
                                    class="info slectizebranch form-control">
                                    <option value="">None</option>
                                </select>
                                {#if errorObj.bankListError}
                                <div class="parsley-errors-list">{errorObj.bankListError}</div>
                                {/if}
                            </div>
                            {#if (paymentObj.status == "deposit" || paymentObj.status == "bounce")}
                            <!-- Clear check -->
                            <div class="form-group">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Branch
                                    List<span class="required-field">*</span></label>
                                <select disabled={paymentObj.freezePayment} bind:this={elembranchSelect}
                                    class="info slectizebranch form-control">
                                    <option value="">None</option>
                                </select>
                                {#if errorObj.branchlistError}
                                <div class="parsley-errors-list">{errorObj.branchlistError}</div>
                                {/if}
                            </div>
                            <div class="parsley-errors-list">{ifListNotPresent || ""}</div>
                            {#if isPopulateReceiptSeries && receiptSeriesLists.length !=0}
                            <div class="form-group">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt
                                    Series</label>
                                <select disabled={paymentObj.freezePayment} bind:this={elemReceiptType}
                                    class="info slectizedd form-control"></select>
                                {#if errorObj.paymentType}
                                <div class="parsley-errors-list">{errorObj.paymentType}</div>
                                {/if}
                            </div>
                            <div class="form-group">
                                <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt
                                    No</label>
                                <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.receiptNo}
                                    type="text" on:change={()=>updatePaidStatus()} on:blur={()=>clearError("receiptNo")}
                                class="form-control" placeholder="Enter Receipt No"/>
                                {#if errorObj.receiptNo}
                                <div class="parsley-errors-list">{errorObj.receiptNo}</div>
                                {/if}
                            </div>
                            {/if}

                            {/if}

                            {:else}
                            <div class="row">
                                <div class="col-sm-6">
                                    <label class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Cheque
                                        Information</label>
                                    <h6 class="tx-15 mg-b-10"></h6>
                                    <p class="mg-b-0"></p>
                                    <p class="mg-b-0">Date : {paymentDateStr || " - "}</p>
                                    <p class="mg-b-0">Amount : {paymentObj.installmentAmount || " - "}</p>

                                    <p class="mg-b-0">
                                        Bank Name: {paymentObj.bankName ? paymentObj.bankName + " " : " - "}
                                        {banksBranchName ? "(" + banksBranchName + ")" : ""}
                                    </p>


                                </div><!-- col -->
                                <div class="col-sm-6 tx-right d-none d-md-block">
                                    <label class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Cheque
                                        Number</label>
                                    <h3 class="tx-normal tx-color-04 mg-b-10 tx-spacing--2">{paymentObj.chequeNo}</h3>
                                </div><!-- col -->
                                <div class="col-sm-6 col-lg-6 mg-t-40 mg-sm-t-0 mg-md-t-40">
                                    {#if (paymentObj.status == "deposit")}
                                    <!-- <div class="form-group">
                                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Cheque
                                            Date</label>
                                        <input disabled={paymentObj.freezePayment || (paymentObj.status == "deposit" || paymentObj.status == "bounce") } on:change={updatePaymentDate}
                                            bind:value={paymentDateStr} type="date" on:blur={()=>clearError("paymentDate")}
                                        class="form-control" class:parsley-error={errorObj.paymentDate} placeholder="Select
                                        Date"/>
                                        {#if errorObj.paymentDate}
                                        <div class="parsley-errors-list">{errorObj.paymentDate}</div>
                                        {/if}
                                    </div>
         -->
                                    <!-- <div class="form-group">
                                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Cheque
                                            No</label>
                                        <input disabled={paymentObj.freezePayment || (paymentObj.status == "deposit" || paymentObj.status == "bounce")} bind:value={paymentObj.chequeNo} type="text"
                                            on:change={()=>updatePaidStatus()} on:blur={()=>clearError("chequeNo")}
                                        class="form-control" class:parsley-error={errorObj.chequeNo} placeholder="Enter Cheque
                                        No"/>
                                        {#if errorObj.chequeNo}
                                        <div class="parsley-errors-list">{errorObj.chequeNo}</div>
                                        {/if}
                                    </div> -->

                                    <!-- <div class="form-group">
                                        <label class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Cheque
                                            Amount</label>
                                        <input disabled={paymentObj.freezePayment || true} bind:value={paymentObj.installmentAmount}
                                            type="number" on:change={()=>updatePaidStatus()}
                                        on:blur={()=>clearError("installmentAmount")} class="form-control"
                                        class:parsley-error={errorObj.installmentAmount} placeholder="Enter Cheque Amount"/>
                                        {#if errorObj.installmentAmount}
                                        <div class="parsley-errors-list">{errorObj.installmentAmount}</div>
                                        {/if}
                                    </div> -->

                                    <div class="form-group">
                                        <label
                                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Organization
                                            Bank
                                            List</label>
                                        <select disabled={paymentObj.freezePayment} bind:this={elemBankSelect}
                                            class="info slectizebranch form-control">
                                            <option value="">None</option>
                                        </select>
                                        {#if errorObj.bankListError}
                                        <div class="parsley-errors-list">{errorObj.bankListError}</div>
                                        {/if}
                                    </div>
                                    <div class="form-group">
                                        <label
                                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Branch
                                            List<span class="required-field">*</span></label>
                                        <select disabled={paymentObj.freezePayment} bind:this={elembranchSelect}
                                            class="info slectizebranch form-control">
                                            <option value="">None</option>
                                        </select>
                                        {#if errorObj.branchlistError}
                                        <div class="parsley-errors-list">{errorObj.branchlistError}</div>
                                        {/if}
                                    </div>
                                    <div class="parsley-errors-list">{ifListNotPresent || ""}</div>
                                    {#if isPopulateReceiptSeries && receiptSeriesLists.length !=0}
                                    <div class="form-group">
                                        <label
                                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt
                                            Series</label>
                                        <select disabled={paymentObj.freezePayment} bind:this={elemReceiptType}
                                            class="info slectizedd form-control"></select>
                                        {#if errorObj.paymentType}
                                        <div class="parsley-errors-list">{errorObj.paymentType}</div>
                                        {/if}
                                    </div>
                                    <div class="form-group">
                                        <label
                                            class="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Receipt
                                            No</label>
                                        <input disabled={paymentObj.freezePayment || true}
                                            bind:value={paymentObj.receiptNo} type="text"
                                            on:change={()=>updatePaidStatus()} on:blur={()=>clearError("receiptNo")}
                                        class="form-control" placeholder="Enter Receipt No"/>
                                        {#if errorObj.receiptNo}
                                        <div class="parsley-errors-list">{errorObj.receiptNo}</div>
                                        {/if}
                                    </div>

                                    {/if}

                                    {/if}

                                </div><!-- col -->
                                <div class="col-sm-6 col-lg-6 mg-t-40">
                                    <label class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Cheque
                                        Information</label>
                                    <ul class="list-unstyled lh-7">
                                        <li class="d-flex justify-content-between">
                                            <span>Status : </span>
                                            {#if paymentObj.status == "deposit"}
                                            <span>Deposit</span>
                                            {/if}
                                            {#if paymentObj.status == "notdeposited"}
                                            <span>Not Deposited</span>
                                            {/if}
                                            {#if paymentObj.status == "cleared"}
                                            <span>Cleared</span>
                                            {/if}
                                            {#if paymentObj.status == "bounce"}
                                            <span>Bounce</span>
                                            {/if}
                                        </li>
                                        <li class="d-flex justify-content-between">
                                            <span>Branch : </span>
                                            {#each branchListSelectArray as item}
                                            {#if ((checkPdcStatus(paymentObj) && item._id == paymentObj.pdcBranchId) ||
                                            item._id
                                            == paymentObj.branchId) }
                                            <option value={item._id} selected></option>
                                            <span>{item.name}</span>
                                            {/if}
                                            {/each}
                                        </li>
                                        <li class="d-flex justify-content-between">
                                            <span>Receipt Number : </span>
                                            <span>{paymentObj.receiptNo}</span>
                                        </li>
                                        <li class="d-flex justify-content-between">
                                            <span></span>
                                            <span></span>
                                        </li>
                                    </ul>
                                </div><!-- col -->
                            </div>
                            {/if}
                            <div class="modal-footer">
                                <button class="btn btn-secondary tx-12" data-dismiss="modal" on:click={resetModal}>
                                    <i data-feather="x" class="wd-10 mg-r-5"></i>
                                    CLOSE
                                </button>

                                <!-- {#if  isEditMode == false && (paymentObj.status == "notdeposited") && (paymentObj.status == "bounce") && (paymentObj.status == "cleared") } -->
                                <!-- content here -->
                                {#if isEditMode == false && !(paymentObj.status == "deposit") && !(currentStatus ===
                                'cleared')}
                                <button href="" on:click={setEditMode} class="btn btn-primary btn-uppercase">
                                    <i data-feather="arrow-right" class="wd-10 mg-r-5"></i>
                                    <ButtonLoader isLoading={isLoading} btnText="Edit" />
                                </button>
                                {/if}



                                {#if isEditMode == false && (paymentObj.status == "deposit")}
                                <!-- content here -->
                                <button href="" on:click={handlePaymentClick} class="btn btn-primary btn-uppercase">
                                    <i data-feather="arrow-right" class="wd-10 mg-r-5"></i>
                                    <ButtonLoader isLoading={isLoading} btnText="Clear Cheque" />
                                </button>
                                {/if}
                                {#if isEditMode == true && (paymentObj.status == "notdeposited")}
                                <!-- content here -->
                                <button href="" on:click={handleUpdateClick} class="btn btn-primary btn-uppercase">
                                    <i data-feather="arrow-right" class="wd-10 mg-r-5"></i>
                                    <ButtonLoader isLoading={isLoading} btnText="Update" />
                                </button>

                                {/if}
                                {#if isEditMode == true && (paymentObj.status == "deposit" || paymentObj.status ==
                                "bounce")}
                                <!-- content here -->
                                <button href="" on:click={handlePaymentClick} class="btn btn-primary btn-uppercase">
                                    <i data-feather="arrow-right" class="wd-10 mg-r-5"></i>
                                    <ButtonLoader isLoading={isLoading} btnText="Clear Cheque" />
                                </button>
                                {/if}
                            </div>
                        </div><!-- col -->
                    </div><!-- row -->
                </div><!-- modal-body -->
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div><!-- modal -->
</div>

<!-- Filter Modal -->
<div class="modal effect-scale" bind:this={filterModalThis} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">

                <div class="form-group">
                    <label class="d-block flex-grow-1">Question Type:</label>
                    <div class="d-md-flex">
                        <div class="custom-control custom-radio mg-r-15">
                            <input type="radio" id="multipleChoice" bind:group={questionType}
                                class="custom-control-input" value='multipleChoice'>
                            <label class="custom-control-label" for="multipleChoice">Multiple Choice</label>
                        </div>

                        <div class="custom-control custom-radio mg-r-15">
                            <input type="radio" bind:group={questionType} id="multipleResponse"
                                class="custom-control-input" value='multipleResponse'>
                            <label class="custom-control-label" for="multipleResponse">Multiple Response</label>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="d-block">Tags:</label>
                    <input type="text" bind:value={tags} class="form-control">
                </div>

                <div class="form-group">
                    <label class="d-block">Language:</label>
                    <input type="text" bind:value={language} class="form-control follow-up-date" />
                </div>

                <div class="form-group">
                    <label class="d-block">Positive Marks:</label>
                    <input type="number" bind:value={pmarks} class="form-control follow-up-date" />
                </div>

                <div class="form-group">
                    <label class="d-block">Negative Marks:</label>
                    <input type="number" bind:value={nmarks} class="form-control follow-up-date" />
                </div>

                <div class="d-md-flex justify-content-end mg-t-25">
                    <button disabled={isLoading} on:click={clearFilter}
                        class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal">CLEAR
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button disabled={isLoading} on:click={applyFilter} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
                        data-dismiss="modal">
                        <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                        <i data-feather="filter" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Modal -->
