<script>
    import { Router, links, Link, Route, navigate } from "svelte-routing";
    import { onMount, tick } from 'svelte';
    import { activeMenu } from "../../stores";
    import ButtonLoader from "../../utils/button_loader.svelte"
    import api_handleApiCalls from "../../utils/apiCallHandler.js";
    import { pConfirm, pSuccess, pError } from "../../utils/notify.svelte";
    import MODULES from "../../utils/modules.js"
    import { userData } from "../../stores";
    import { API_URL } from "../../../config.js"
    let localUserData = {
        fullName: "...",
        role: "...",
        avatar: "/images/man.png",
        access: {
            home: false,
            students: false,
            settings: false,
        },
    };

    const unsubscribe = userData.subscribe((value) => {
        localUserData = value;
    });


    let errorObj = {}


    let elemBatch;
    let selectedBatchId;
    let elemSelectClass

    let selectizeInstanceClass

    let elem_batchDataTable;
    let batchDataTable;
    let newBatchModal;
    let batchesList = [];
    let prefixList = [];
    let userList = [];
    let filterModalThis;
    let filterElemBatch
    let filterElemUser
    let filterStartDate
    let filterCreatedOn
    let batchFees
    let batchSubject;
    let selectizeBatchInstance
    // let selectizeUserlorInstance
    let gender
    let batchData = {
        name: "",
        amount: undefined,
        subject: [],
        code: "",
        startDate: new Date(),
        taxApplicable: false,
        feesIncludingTax: false,
        taxPercent: 0,
        totalPayableAmount: 0,
    }
    let isLoading = false;

    let subSameAsOthers = "no";
    let disableSameAsOtherSelection = false;

    let openCreateBatch = async function () {
        errorObj = {};
        batchData = {
            name: "",
            amount: undefined,
            subject: [],
            code: "",
            startDate: new Date(),
            taxApplicable: false,
            feesIncludingTax: false,
            taxPercent: 0,
            totalPayableAmount: 0,
        }
        _$('.chips-tag')[0].selectize.setValue([], false);

        let response = await api_getBaseFolderBatches();

        if (response.data.length == 0) {
            disableSameAsOtherSelection = true;
            subSameAsOthers = "no";
        } else {
            _$(elemBatch).selectize({
                preload: true,
                create: false,
                maxItems: null,
                valueField: "_id",
                labelField: "name",
                searchField: "name",
                delimiter: ",",
                persist: false,
                options: response.data,
                onChange: batchSelectOnChange
            });
        }
        _$(newBatchModal).modal('show');
    }

    let batchSelectOnChange = async (selectedBatchId) => {
        selectedBatchId = selectedBatchId
    }

    async function api_getBatchesList() {
        var resData = await api_handleApiCalls('batch.getBranchNameList', {})
        if (resData.status) {

            return resData.data
        } else {
            return []
        }
    }

    function clearFilter() {

        filterStartDate = null;
        selectizeBatchInstance[0].selectize.clear()
        filterCreatedOn = null;
        batchFees = null;
        batchSubject = null;
        batchDataTable.ajax.reload();

    }

    async function api_deleteBatchById(data) {
        let action = "batch.deleteBatchById";
        var response = await api_handleApiCalls(action, data);
        return response;

    }

    async function api_getCounselorUsers() {
        var resData = await api_handleApiCalls('systemUser.fetchSystemUserList', {})
        if (resData.status) {
            return resData.data.data
        } else {
            return []
        }
    }

    async function api_getClassList(data) {
        var resData = await api_handleApiCalls('instituteClass.fetchInstituteClasses', data)
        if (resData.status) {
            return resData.data
        } else {
            return []
        }
    }

    async function api_addBatch(data) {
        let action = "batch.add";
        var response = await api_handleApiCalls(action, data);
        if (response.errMsg) {
            pError(null, response.errMsg);
        }
        return response;
    }


    async function api_getBaseFolderBatches() {

        let action = "batch.getBaseFolderBatches";
        var response = await api_handleApiCalls(action);
        return response;

    }

    async function api_assignBatch(data) {

        let action = "systemUser.assignBatch";
        var response = await api_handleApiCalls(action, data);
        return response;

    }



    async function createBatch() {
        if (isLoading) return;
        isLoading = true;
        await tick()

        batchData.batchClass = _$(elemSelectClass)[0].selectize.getValue();

        var newErrorObj = {}
        if (!batchData.name && !Boolean(batchData.name)) {
            newErrorObj.batchName = "Batch name is required.";
        }
        if (!batchData.code) {
            newErrorObj.batchCode = "Batch code required";
        }
        if (batchData.code && batchData.code.length <= 2) {
            newErrorObj.batchCode = "Batch code must be more than 3 Characters";
        }

        if (!batchData.batchClass) {
            newErrorObj.batchClass = "Batch Class is required";
        }


        batchData.isRootBatch = (subSameAsOthers == "no" ? true : false);

        if (batchData.isRootBatch) {

            batchData.subject = _$('.chips-tag')[0].selectize.getValue();

            if (!batchData.subject || batchData.subject.length == 0) {
                newErrorObj.batchSubjects = "Please add at least one subject";
            } else {

            }

        } else {
            selectedBatchId = _$(elemBatch)[0].selectize.getValue();
            if (!selectedBatchId) {
                newErrorObj.selectedBatchId = "Please select a batch to sync with";
            } else {

            }
            batchData.syncFoldersWith = selectedBatchId;
        }

        if (batchData.amount != 0) {
            if (!batchData.amount) {
                newErrorObj.batchFee = "Batch fees is required.";
            } else if (batchData.amount < 0) {
                newErrorObj.batchFee = "Please enter valid batch Fee";
            }
            else {
            }
        }
        errorObj = newErrorObj
        if (Object.keys(errorObj).length > 0) {
            isLoading = false;
            return null;
        }

        batchData.totalPayableAmount = batchData.amount;
        if (batchData.amount == 0) {
            batchData.isFree = 'yes'
        }

        batchData.prefix = batchData.code;
        batchData.sequence = 1;

        var batchRes = await api_addBatch(batchData);
        if (batchRes.status) {
            // create success
            _$(newBatchModal).modal('hide');
            var result = await api_assignBatch({ userId: localUserData._id, batchId: batchRes.data.batchId });
            navigate(("batch/edit/" + batchRes.data.batchId))
            if (result.status) {
                PNotify.success({
                    title: 'Success',
                    text: "Batch added, please update additional data",
                    delay: 3000
                })
            } else {
                PNotify.error({
                    title: 'Error',
                    text: "Batch created but not assigned!",
                    delay: 3000
                })
            }

        }
        isLoading = false;
    }

    let updateBatchCode = () => {
        if (!batchData.code) batchData.code = (batchData.name).substr(0, 4).toUpperCase();
    }
    async function onNameChange() {
        var newErrorObj = {}
        if (!batchData.name && !Boolean(batchData.name)) {
            newErrorObj.batchName = "Please enter full name.";
        }
        if (!batchData.code || batchData.code.length < 2) {
            newErrorObj.batchCode = "Batch code must be more than 3 Characters";
        }
        if (batchData.amount != 0) {
            if (!batchData.amount) {
                newErrorObj.batchFee = "Please enter batch Fee";
            } else if (batchData.amount < 0) {
                newErrorObj.batchFee = "Please enter valid batch Fee";
            }
            else {

            }
        }

        errorObj = newErrorObj
    }

    function getFilterData() {
        let startDate = filterStartDate;
        let branches = _$(selectizeBatchInstance).val()
        let createdOn = filterCreatedOn;
        return {
            createdOn,
            batchFees,
            batchSubject,
            branches,
            startDate,
        }
    }

    async function getFilterDataList() {
        batchesList = await api_getBatchesList()
        userList = await api_getCounselorUsers()
        bindSelect2s()
    }

    function applyFilter() {
        batchDataTable.ajax.reload();
    }

    function parseSorts(sortOrder) {
        // parses datatable order to mongodb sort object
        let sort = {};
        // sorting orders
        let order;
        if (sortOrder.dir === "desc") order = 1;
        else order = -1;
        if (sortOrder.column === 0) {
            sort.name = order;
        } else if (sortOrder.column === 1) {
            sort.branchList = order;
        } else if (sortOrder.column === 2) {
            sort.subjectList = order;
        } else if (sortOrder.column === 3) {
            sort.classlist = order;
        } else if (sortOrder.column === 4) {
            sort.startDate = order;

        } else {
            sort._id = order;
        }
        return sort;
    }


    async function bindSelect2s() {

        selectizeBatchInstance = _$(filterElemBatch).selectize({
            preload: true,
            create: false,
            maxItems: null,
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            options: batchesList,
        });


        let resClass = await api_getClassList({
            orgId: localUserData.orgId
        })

        selectizeInstanceClass = _$(elemSelectClass).selectize({
            preload: true,
            create: false,
            maxItems: [1],
            valueField: "_id",
            labelField: "name",
            searchField: "name",
            placeholder: 'Select class',
            plugins: ["remove_button"],
            options: resClass,
        });

    }
    function showHideFilterModal(action) {
        action ? (_$(filterModalThis).modal('show')) : (_$(filterModalThis).modal('hide'))
    }

    onMount(() => {
        initDashboard();
        getFilterDataList();
        // initFileManager();            
        activeMenu.set('batch');
        var tags = _$('.chips-tag').selectize({
            delimiter: ',',
            plugins: ['remove_button'],
            persist: false,
            render: {
                option_create: function (data, escape) {
                    return '<div class="create" style="padding-left:15px"><i class="icon ion-md-add" style="font-size:14px;margin-right:10px;"></i><i style="font-size:12px;">Add New Subject : </i><strong>' + escape(data.input) + '</strong></div>';
                }
            },
            create: function (input) {
                return { value: input, text: input }
            }
        });


        batchDataTable = _$(elem_batchDataTable).DataTable({
            responsive: false,
            language: {
                processing: "",
                searchPlaceholder: 'Batch Name | Code',
                sSearch: '',
                lengthMenu: '_MENU_ Batches/page',
            },
            ajax: function (data, callback, settings) {
                data.sort = parseSorts(data.order ? data.order[0] : { 0: "desc" });
                var filterObj = { ...getFilterData() }
                filterObj.start = data.start
                filterObj.length = data.length
                filterObj.search = data.search.value
                filterObj.draw = data.draw
                filterObj.sort = data.sort
                filterObj.getStudentsCount = true
                _$.ajax({
                    url: API_URL,
                    method: 'post',
                    data: {
                        action: 'batch.listBatches',
                        accessToken: localStorage.getItem("accessToken"),
                        data: filterObj,
                        // getModuleSubjects: true,

                    },
                    success: function (resData) {
                        var result = []
                        if (resData.status) {
                            var paginationData = resData.data;
                            result = {
                                recordsTotal: Number(paginationData.total_count),
                                recordsFiltered: Number(paginationData.filtered_count),
                                data: paginationData.data
                            }
                            callback(result);
                        }
                        else {
                            console.log("Something went wrong");
                        }
                    }
                })
            },
            columns: [
                {
                    data: 'name', render: function (data, type, row, meta) {

                        if (MODULES.hasAccess(MODULES.VIEW_BATCH_DETAILS)) {

                            var htmlStr = `<div class="media btn-edit-batch" batch-id="${row._id}" style="cursor: pointer;">
                                <div class="media-body mg-l-10 "  >
                                    <h5 class="tx-15 mg-b-3  tx-capitalize" batch-id="${row._id}" style="max-height: 38px !important; overflow:hidden; cursor: pointer;">${data}</h5>
                                    <span class="tx-12 text-muted"></span>
                                    <span class="tx-12 text-muted"> ${row.code ? " Batch code : " + row.code : "&nbsp;"}</span>
                                </div><!-- media-body -->
                            </div>`
                            return htmlStr;
                        }
                        else {
                            var htmlStr = `<div class="media " batch-id="${row._id}" style="cursor: pointer;">
                                <div class="media-body mg-l-10 "  >
                                    <h5 class="tx-15 mg-b-3  tx-capitalize" batch-id="${row._id}" style="max-height: 38px !important; overflow:hidden; cursor: pointer;">${data}</h5>
                                    <span class="tx-12 text-muted"></span>
                                    <span class="tx-12 text-muted"> ${row.code ? " Batch code : " + row.code : "&nbsp;"}</span>
                                </div><!-- media-body -->
                            </div>`
                            return htmlStr;
                        }
                    }
                },
                {
                    data: 'branchList', render: function (data, type, row, meta) {

                        var branchList = `<div class="media">
                                <div class="media-body mg-l-10" style="text-align: center; width: 20%;">
                                    <h5 class="tx-15 mg-b-3">${row.branchList && row.branchList[0] ? row.branchList[0].name : "-"}</h5>
                                </div><!-- media-body -->
                            </div>`
                        return branchList;

                    }
                },
                // 
                // { data: 'code' },
                {
                    data: 'subjectList', render: function (data, type, row, meta) {
                        if (!data) return "";
                        return data.map(function (aSubject) {
                            if (!aSubject.name) {
                                return "";
                            }
                            return `<span class="badge badge-secondary" style="font-size:12px;margin-right:4px;margin-bottom:4px;">${aSubject.name}</span>`;
                        }).join("");


                    }
                },
                {
                    data: 'classList', render: function (data, type, row, meta) {

                        var classList = `<div class="media">
                                <div class="media-body mg-l-10" style="text-align: center; width: 20%;">
                                    <h5 class="tx-15 mg-b-3">${row.classList && row.classList[0] ? row.classList[0].name : "-"}</h5>
                                </div><!-- media-body -->
                            </div>`
                        return classList;

                    }
                },



                {
                    data: 'startDate', render: function (data, type, row, meta) {
                        if (!data) return "";
                        return new Date(data).toLocaleDateString('en-GB');

                    }
                },
                {
                    data: 'studentCount', render: function (data, type, row, meta) {
                        var view = ""
                        view = `<a href="#" class=" btn-view-batch-student mg-r-5" batch-id="${row._id}" data-toggle="tooltip" data-placement="top" title="View Batch Students"> ${data} <i data-feather="arrow-up-right" class="wd-15 mg-r-5"></i> </a>`
                        return `
                        <div class="d-md-flex flex-row justify-content-center">
                            ${view}
                        </div>
                    `;

                    }
                },
                {
                    data: '_id', render: function (data, type, row, meta) {
                        var view = ""
                        var archive = ""
                        if (MODULES.hasAccess(MODULES.VIEW_BATCH_DETAILS) && !(MODULES.hasAccess(MODULES.DELETE_BATCH))) {
                            view = `<a href="#" class="rename btn-edit-batch mg-r-5" batch-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i data-feather="edit" class="wd-15 mg-r-5"></i></a>`
                        } else if (MODULES.hasAccess(MODULES.DELETE_BATCH) && !(MODULES.hasAccess(MODULES.VIEW_BATCH_DETAILS))) {
                            archive = ` <a href="#" class="delete btn-delete-batch mg-r-5" batch-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete"><i data-feather="trash" class="wd-15 mg-r-5"></i></a>`
                        } else if (!(MODULES.hasAccess(MODULES.DELETE_BATCH)) && !(MODULES.hasAccess(MODULES.VIEW_BATCH_DETAILS))) {
                            view = ""
                            archive = ""
                        } else {
                            view = `<a href="#" class="rename btn-edit-batch mg-r-5" batch-id="${data}" data-toggle="tooltip" data-placement="top" title="Edit"><i data-feather="edit" class="wd-15 mg-r-5"></i></a>`
                            archive = `<a href="#" class="delete btn-delete-batch mg-r-5" batch-id="${data}" data-toggle="tooltip" data-placement="top" title="Delete"><i data-feather="trash" class="wd-15 mg-r-5"></i></a>`
                        }
                        return `
                        <div class="d-md-flex flex-row justify-content-center">
                            ${view}
                            ${archive}
                            ${!archive && !view ? "-" : ""}
                        </div>
                    `;

                    }
                },

            ],
            "drawCallback": function () {
                if (MODULES.hasAccess(MODULES.ADD_BATCH)) {
                    feather.replace()
                    _$('.btn-edit-batch').unbind('click').click(function (e) {
                        e.preventDefault();

                        var batchId = _$(this).attr("batch-id");
                        navigate(("batch/edit/" + batchId))

                    })
                    _$('.btn-view-batch-student').unbind('click').click(function (e) {
                        e.preventDefault();

                        var batchId = _$(this).attr("batch-id");
                        navigate(("/dashboard/students/search/" + JSON.stringify({
                            batches: [batchId]
                        })))

                    })
                    _$('.btn-delete-batch').unbind('click').click(function (e) {
                        e.preventDefault();
                        var batchId = _$(this).attr("batch-id");
                        const notice = PNotify.notice({
                            title: "Delete Batch",
                            text: "Are you sure you want to delete this batch ?",
                            hide: false,
                            closer: false,
                            sticker: false,
                            modules: new Map([
                                ...PNotify.defaultModules,
                                [PNotifyConfirm, { confirm: true }],
                            ]),
                        })

                        notice.on("pnotify:confirm", async () => {
                            try {
                                var deleteBatchRes = await api_deleteBatchById({ _id: batchId });
                                if (deleteBatchRes.status) {
                                    PNotify.success({
                                        title: 'Success',
                                        text: "Batch Deleted Successfully",
                                        delay: 3000
                                    })
                                } else {
                                    PNotify.error({
                                        title: 'Error',
                                        text: deleteBatchRes.errMsg,
                                        delay: 3000
                                    })
                                }

                            } catch (error) {
                                console.error(error);
                            }
                            batchDataTable.ajax.reload();

                        })

                    })

                } // bind actions 
            },

            "processing": true,
            "serverSide": true,
        });

        _$('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });

    });

</script>


<!-- Modal -->
<div class="modal effect-scale" bind:this={newBatchModal} id="modal-new-batch" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered wd-sm-650" role="document">
        <div class="modal-content ">
            <div class="modal-body pd-0 ">
                <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                    <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="media align-items-center">
                        <span class="tx-color-03 d-none d-sm-block"><i data-feather="grid"
                                class="wd-60 ht-60"></i></span>
                        <div class="media-body mg-sm-l-20">
                            <h4 class="tx-18 tx-sm-20 mg-b-2">New batch</h4>
                            <p class="tx-13 tx-color-03 mg-b-0">Create new batch</p>
                        </div>
                    </div><!-- media -->
                </div><!-- modal-header -->

                <hr class="full-width">
                <div class="row no-gutters">
                    <div class="col-12 bg-white rounded-right">
                        <div class="ht-100p d-flex flex-column pd-l-25 pd-r-25 pd-b-25">
                            <div class=" tx-color-02">
                                <div class="form-group">
                                    <label class="d-block">Name of Batch<span class="required-field">*</span></label>
                                    <input bind:value={batchData.name} on:change={()=>updateBatchCode()} type="text"
                                    class="form-control" class:parsley-error={errorObj.batchName}
                                    placeholder="Enter Batch Name"
                                    on:keyup={(e)=> e.key === 'Enter' && createBatch()}/>
                                    {#if errorObj.batchName}
                                    <div class="parsley-errors-list">{errorObj.batchName}</div>
                                    <!-- content here -->
                                    {/if}
                                </div>
                            </div>
                            <div class=" tx-color-02">
                                <div class="form-group">
                                    <label class="d-block">Batch Code<span class="required-field">*</span></label>
                                    <input bind:value={batchData.code} type="text" class="form-control"
                                        class:parsley-error={errorObj.batchCode} placeholder="Enter Batch Code"
                                        on:keyup={(e)=> e.key === 'Enter' && createBatch()}/>
                                    {#if errorObj.batchCode}
                                    <div class="parsley-errors-list">{errorObj.batchCode}</div>
                                    <!-- content here -->
                                    {/if}
                                </div>
                            </div>
                            <div class=" tx-color-02">
                                <div class="form-group">
                                    <label class="d-block">Batch Fees (Rs. 0 for Demo or Free Batch) <span
                                            class="required-field">*</span></label>
                                    <input bind:value={batchData.amount} type="number" class="form-control"
                                        class:parsley-error={errorObj.batchFee} placeholder="Enter Batch Fees"
                                        on:keyup={(e)=> e.key === 'Enter' && createBatch()}/>
                                    {#if errorObj.batchFee}
                                    <div class="parsley-errors-list">{errorObj.batchFee}</div>
                                    <!-- content here -->
                                    {/if}
                                </div>
                            </div>

                            <div class=" tx-color-02">
                                <div class="form-group">
                                    <label class="d-block">Class/Stream<span class="required-field">*</span></label>
                                    <select bind:this={elemSelectClass}></select>
                                    {#if errorObj.batchClass}
                                    <div class="parsley-errors-list">{errorObj.batchClass}</div>
                                    <!-- content here -->
                                    {/if}
                                </div>
                            </div>


                            {#if !disableSameAsOtherSelection}
                            <div class="row" style="margin-bottom: 20px;">
                                <div class="col-sm-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-6 m-0">
                                            <div class="custom-control custom-radio">
                                                <input type="radio" id="sameSubjectNo" value="no"
                                                    bind:group={subSameAsOthers} class="custom-control-input">
                                                <label class="custom-control-label" for="sameSubjectNo">Create new
                                                    Subject</label>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6 m-0">
                                            <div class="custom-control custom-radio">
                                                <input type="radio" disabled id="sameSubjectYes" value="yes"
                                                    bind:group={subSameAsOthers} name="sameSubject"
                                                    class="custom-control-input">
                                                <label class="custom-control-label" for="sameSubjectYes">Sync subject
                                                    with other batch</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/if}

                            <div class=" tx-color-02" class:hide={subSameAsOthers=="yes" }>
                                <div class="form-group" class:has-error={errorObj.batchSubjects}>
                                    <label for="inputEmail4">Add Subjects for this Batch<span
                                            class="required-field">*</span></label>
                                    <input class="info form-control chips-tag" type="text" data-role="tagsinput"
                                        name="chips-tag" on:keyup={(e)=> e.key === 'Enter' && createBatch()}
                                    placeholder="You can add Multiple subjects">
                                    {#if errorObj.batchSubjects}
                                    <div class="parsley-errors-list">{errorObj.batchSubjects}</div>
                                    <!-- content here -->
                                    {/if}
                                </div>
                            </div>
                            <div class=" tx-color-02" class:hide={subSameAsOthers=="no" }>

                                <div class="form-group" class:parsley-errors-select-custom={errorObj.selectedBatchId}>
                                    <label class="d-block">Select Batch to sync subjects<span
                                            class="required-field">*</span></label>
                                    <select bind:this={elemBatch} class="info slectizedd form-control">
                                    </select>
                                    {#if errorObj.selectedBatchId}
                                    <div class="parsley-errors-list">{errorObj.selectedBatchId}</div>
                                    <!-- content here -->
                                    {/if}
                                </div>
                                <!-- <div class="form-group">
                                    <label class="d-block"> <span
                                            class="required-field">*</span></label>

                                        <select bind:this={elemBatch} class="info slectizedd form-control">
                                        </select>
                                    {#if errorObj.selectedBatchId}
                                    <div class="parsley-errors-list">{errorObj.selectedBatchId}</div>
                                    {/if}
                                </div> -->
                            </div>

                            <div class=" d-flex justify-content-end ">
                                <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">
                                    CANCEL
                                    <i data-feather="x" class="wd-10 mg-l-5"></i>
                                </button>
                                <button type="button" disabled={isLoading} on:click={createBatch}
                                    class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                                    <ButtonLoader isLoading={isLoading} btnText="Proceed" />
                                    <i data-feather="arrow-right" class="wd-10 mg-l-5"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->


<!-- Filter Modal -->
<div class="modal effect-scale" bind:this={filterModalThis} tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pd-y-20 pd-x-20 pd-sm-x-20">
                <a href="" role="button" class="close pos-absolute t-15 r-15 z-index-10" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div class="media align-items-center">
                    <span class="tx-color-03 d-none d-sm-block"><i data-feather="filter" class="wd-60 ht-60"></i></span>
                    <div class="media-body mg-sm-l-20">
                        <h4 class="tx-18 tx-sm-20 mg-b-2">Filters</h4>
                        <p class="tx-13 tx-color-03 mg-b-0">Apply filter for sorting</p>
                    </div>
                </div><!-- media -->
            </div><!-- modal-header -->

            <hr class="full-width">
            <div class="modal-body pd-l-25 pd-r-25 pd-b-25 pd-t-0">
                <div class="form-group">
                    <label class="d-block">Starting On :</label>
                    <input type="date" onkeydown="return false" bind:value={filterStartDate}
                        class="form-control follow-up-date" />
                </div>


                <div class="form-group">
                    <label class="d-block">Created On :</label>
                    <input type="date" onkeydown="return false" bind:value={filterCreatedOn}
                        class="form-control follow-up-date" />
                </div>

                <div class="form-group">
                    <label class="d-block">Batch Fees :</label>
                    <input type="number" bind:value={batchFees} class="form-control" placeholder="Enter batch fees" />
                </div>

                <div class="form-group">
                    <label class="d-block">Batch Subject :</label>
                    <input type="text" bind:value={batchSubject} class="form-control"
                        placeholder="Enter batch subject" />
                </div>

                <div class="form-group">
                    <label class="d-block">Branches :</label>
                    <select disabled={isLoading} bind:this={filterElemBatch} class="form-control select2"
                        multiple="multiple">
                        <option>Select batches</option>
                    </select>
                </div>

                <div class="d-md-flex justify-content-end mg-t-25">
                    <button disabled={isLoading} on:click={clearFilter}
                        class="btn btn-sm pd-x-15 btn-secondary tx-12 mg-l-5" data-dismiss="modal">CLEAR
                        <i data-feather="x" class="wd-10 mg-l-5"></i>
                    </button>
                    <button disabled={isLoading} on:click={applyFilter} class="btn btn-sm pd-x-15 btn-primary mg-l-5"
                        data-dismiss="modal">
                        <ButtonLoader isLoading={isLoading} btnText="APPLY" />
                        <i data-feather="filter" class="wd-10 mg-l-5"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Modal -->


<div class="content-body fadeIn">
    <!-- <Link style="display: none;" to="about">About</Link> -->
    <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
            <nav use:links aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Batches</li>

                </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">Batches</h4>
            <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Search batch or add a suitable batch.</p>
        </div>
        <div class="d-block">
            {#if MODULES.hasAccess(MODULES.ADD_BATCH)}
            <button on:click={openCreateBatch} class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                <i data-feather="plus" class="wd-10 mg-r-5"></i> Add new BAtch
            </button>
            {/if}
            <button class="btn btn-sm mg-l-10 pd-x-15 btn-primary btn-uppercase"
                on:click={()=>{showHideFilterModal(true)}}> <i data-feather="filter" class="mg-r-5"></i>
                Filters</button>
            <!-- <a href="/dashboard/batch/add" use:links  class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="file"
                    class="wd-10 mg-r-5"></i> Add new Batch</a> -->
        </div>
    </div>

    <hr class="full-width">
    <div>
        <table bind:this={elem_batchDataTable} class="table" style="width:100%">
            <thead>
                <tr>
                    <th style="width: 20%;">Name</th>
                    <th style="text-align: center; width: 15%;">Branch Name</th>
                    <th style="width: 20%;">Subjects</th>
                    <th style="text-align: center; width: 20%;">Class</th>
                    <th style="width: 10%;">Start Date</th>
                    <th style="width: 10%;">Students</th>
                    <th style="text-align: center; width: 10%;">Actions</th>
                </tr>
            </thead>
            <tbody></tbody>

        </table>

    </div>

</div>
