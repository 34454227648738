<script>
    import { Router, Link, links, Route } from "svelte-routing";

    import { onMount, tick, createEventDispatcher } from "svelte";

    import STPcmp from "./component.svelte";
    const dispatch = createEventDispatcher();
    let modalCmp;

    let sptObject;
    $: sptObject && feather.replace();

    let onSelectedEssay = function () {
        dispatch('close', {
            sptObject: sptObject
        });
    }

    onMount(async () => {
        feather.replace();
        _$(modalCmp).modal('show');

        _$(modalCmp).on('hide.bs.modal', function (e) {
            dispatch('close', {
                sptObject: ""
            });
        })
    })
</script>





<div class="modal effect-scale" bind:this={modalCmp} id="pdf-add-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="    height: calc(100% - 100px);
    display: flex;
    overflow: auto;">
        <div class="modal-content tx-14" style="height: 100%;">
            <div class="modal-header" style=" flex-direction: column;">
                <div class="media-body mg-sm-l-20">
                  <h4 class="modal-title" id="exampleModalLabel6">Select an essay</h4>
                  <p class=" tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-5 mg-t-5">Please select a existing essay or
                    create and new essay and then select it</p>
                </div>
                 <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute;
                   top: 30px;
                  right: 30px;">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0" style="overflow: hidden;
    height: 100%;">
                <STPcmp bind:sptObject={sptObject} />
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary tx-12" data-dismiss="modal">
                    CLOSE <i data-feather="x" class="mg-l-5"></i></button>
                {#if !sptObject?.title}
                <button type="button" on:click={onSelectedEssay} class="btn btn-primary tx-13"
                    disabled >Link this Essay
                    <i data-feather="link" class="mg-l-5"></i>
                </button>
                {:else}
                <button type="button" on:click={onSelectedEssay} class="btn btn-primary tx-13" >
                    <i data-feather="link" class="mg-r-5"></i>Link this essay</button>
                {/if}
                
            </div>
        </div>
    </div>
</div>
